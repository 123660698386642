import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BtnComponent } from '@components/btn/btn.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { IDataPopup } from '@services/popup.service';
import { TColor } from '@models/ICssStyles';

@Component({
  selector: 'app-popup-confirm',
  standalone: true,
  imports: [CommonModule, BtnComponent, BtnWrapComponent, HeadTitleComponent],
  templateUrl: './popup-confirm.component.html',
  styleUrls: ['./popup-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupConfirmComponent {
  textTitle?: string;
  textTitle2?: string;
  text?: string;
  textBtnCancel: string = 'Cancel';
  textBtnApply: string = 'Apply';
  colorBtnCancel: TColor = 'white';
  colorBtnApply: TColor = 'blueDark';

  swapBtn = false; // если передаем true, то меняем местами кнопки
  marginTopForBtn = 32; // !!! отступ сверху от кнопок до текста.

  constructor(
    public dialogRef: MatDialogRef<PopupConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public dataPopup: IDataPopup,
  ) {
    this.textTitle = dataPopup.textTitle;
    this.textTitle2 = dataPopup.textTitle2;
    this.text = dataPopup.text;
    if (dataPopup.textBtnCancel) this.textBtnCancel = dataPopup.textBtnCancel;
    if (dataPopup.textBtnApply) this.textBtnApply = dataPopup.textBtnApply;
    if (dataPopup.colorBtnCancel) this.colorBtnCancel = dataPopup.colorBtnCancel;
    if (dataPopup.colorBtnApply) this.colorBtnApply = dataPopup.colorBtnApply;
    if (typeof dataPopup.swapBtn === 'boolean') this.swapBtn = dataPopup.swapBtn;
    if (dataPopup.marginTopForBtn) this.marginTopForBtn = dataPopup.marginTopForBtn;
  }

  close(type: boolean): void {
    this.dialogRef.close(type);
  }
}
