import { Pipe, PipeTransform } from '@angular/core';
import { TColor } from '@models/ICssStyles';
import { colorObj } from '@classes/CSS';

export interface InputObjStyles {
  wh?: string | number; // width && height
  w?: string | number; // width
  h?: string | number; // height
  fs?: string | number; // fontSize
  cur?: boolean; // cursor
  revH?: boolean; // 'transform: scale(-1, 1)' }; // &-reverse-horizontal
  revV?: boolean; // 'transform: scale(1, -1)' }; // &-reverse
  hid?: boolean; // visibility: inputStyles.hidden ? 'hidden' : 'visible'
  o3?: boolean; // opacity: .3
  o5?: boolean; // opacity: .5
  o7?: boolean; // // opacity: .7
  bcg?: TColor;

  marL?: string | number; // margin-left marginLeft
  marR?: string | number; // margin-right marginRight

  padL?: string | number; // padding-left paddingLeft
  padR?: string | number; // padding-right paddingRight
}

export interface IOutputObjStyles {
  width?: string;
  minWidth?: string;
  maxWidth?: string;

  height?: string;
  minHeight?: string;
  maxHeight?: string;

  fontSize?: string;

  cursor?: 'pointer' | 'default';
  transform?: string;

  visibility?: 'visible' | 'hidden';
  opacity?: number;

  background?: TColor;

  marginLeft?: string;
  marginRight?: string;

  paddingLeft?: string;
  paddingRight?: string;

  // =============================
  color?: string;
}

@Pipe({ name: 'getStyles', standalone: true, pure: false }) // !!! NO DELETE pure: false
export class GetStylesPipe implements PipeTransform {

  constructor(
    // private cd: ChangeDetectorRef,
  ) {
  }

  // !important со [style] не работает => это можно с помощью классов [class]=''
  // [style]='' приемущества в том, что в Other.css можно избавиться от многих классов
  // и сюда можно передавать значения для width/height, а в [class]='' придется создавать классы постоянно типа o-width 12px, o-width 38px и т.п.
  // поэтому чтобы избавиться от лишних классов в other.css, надо лучше использовать [style]=''

  // example [style]='{wh:w, cur:cur, hid:hidden, o5:true, revV:true}|getStyles'
  transform(inputStyles: InputObjStyles, test?: any): IOutputObjStyles {
    // if (test) {
    //   console.log('transform :', test, inputStyles)
    // }
    return this.getObjStyles(inputStyles, test);
  }

  getObjStyles(inputStyles: InputObjStyles, test?: any): IOutputObjStyles {
    let result: IOutputObjStyles = {};

    // === WIDTH && HEIGHT ===
    if (inputStyles.wh) result = { ...result, ...this.getWH(inputStyles.wh) };
    if (inputStyles.w) result = { ...result, ...this.getWidth(inputStyles.w) };
    if (inputStyles.h) result = { ...result, ...this.getHeight(inputStyles.h) };

    // === FONT-SIZE ===
    // if (objStyle.fs) result = { ...result, 'font-size': this.getStrPixels(objStyle.fs) };
    if (inputStyles.fs) result = { ...result, fontSize: this.getStrPixels(inputStyles.fs) };
    // if (inputStyles.fs && test) {
    // if ( test) {
    //   console.log(test, 'inputStyles.fs :', inputStyles.fs, ' === ', this.getStrPixels(inputStyles?.fs!))
    // }

    // === CURSOR ===
    if (inputStyles.cur) result = { ...result, cursor: inputStyles.cur ? 'pointer' : 'default' };

    // === REVERSE (TRANSFORM) ===
    if (inputStyles.revH) result = { ...result, transform: 'scale(-1, 1)' }; // &-reverse-horizontal { transform: scale(-1, 1) }
    if (inputStyles.revV) result = { ...result, transform: 'scale(1, -1)' }; // &-reverse { transform: scale(1, -1) }

    // === VISIBILITY ===
    if (inputStyles.hid) result = { ...result, visibility: inputStyles.hid ? 'hidden' : 'visible' };

    // === OPACITY ===
    if (inputStyles.o3) result = { ...result, opacity: 0.3 };
    if (inputStyles.o5) result = { ...result, opacity: 0.5 };
    if (inputStyles.o7) result = { ...result, opacity: 0.7 };

    // @ts-ignore
    if (inputStyles.bcg) result = { ...result, background: colorObj[inputStyles.bcg] };

    // === MARGIN ===
    if (inputStyles.marL) result = { ...result, marginLeft: this.getStrPixels(inputStyles.marL) };
    if (inputStyles.marR) result = { ...result, marginRight: this.getStrPixels(inputStyles.marR) };

    // === PADDING ===
    if (inputStyles.padL) result = { ...result, paddingLeft: this.getStrPixels(inputStyles.padL) };
    if (inputStyles.padR) result = { ...result, paddingRight: this.getStrPixels(inputStyles.padR) };

    // this.cd.markForCheck()
    // this.cd.detectChanges()

    // if (test) {
    // console.log('colorObj :', colorObj)
    // console.log('inputStyles :', test, inputStyles)
    // console.log('result :', test, result);
    // }
    return result;
  }

  // === WIDTH && HEIGHT ===
  getWH(wh: string | number): IOutputObjStyles {
    const wh_px = wh == 'initial' ? wh : this.getStrPixels(wh);
    return { width: wh_px, minWidth: wh_px, maxWidth: wh_px, height: wh_px, minHeight: wh_px, maxHeight: wh_px };
  }

  getWidth(w: string | number): IOutputObjStyles {
    const width = w == 'initial' ? w : this.getStrPixels(w);
    return { width, minWidth: width, maxWidth: width };
  }

  getHeight(h: string | number): IOutputObjStyles {
    const height = h == 'initial' ? h : this.getStrPixels(h);
    return { height, minHeight: height, maxHeight: height };
  }

  // === other ========
  getStrPixels(str: string | number): string {
    if (str?.toString()?.includes('%')) return str as string;
    if (this.pxIncludes(str) || str == 'auto') return str as string;
    return str.toString() + 'px';
  }

  pxIncludes(valueKeyObjStyle: string | number): boolean {
    return valueKeyObjStyle?.toString()?.includes('px');
  }

}
