<ng-container *ngIf='{MOBILE: (deviceService.isMobile$|async),
                      disabledActions: game?.gameStatus == "CANCELLED",
                     } as obj'>

  <div class='wrapperForGame wrapperGameInfo'>
    <div class='wrapperForGameBlock'>
      <headTitle class='headtitle headTitle--mobile' typeText='text2-blueDark'
                 text='Game #{{game?.gameNumber}} - {{game?.competition?.competitionName}}' typeText='text1-blueDark'
                 svgRightMargin='10' svgLeft='arrow_squareLeft&24'
                 (svgLeftEmit)='goToBack()'>
        <status *ngIf='game?.gameStatus == "CANCELLED"' [status]='game?.gameStatus' class='ngContentRight o-w-100 o-mg-t-12'></status>
      </headTitle>
      
      <div class='contentPreviewGame__title contentPreviewGame__title--mobile'>
        Game Information
      </div>

      <div *ngIf='(deviceService.isMobile$|async)' class='contentPreviewGame o-wrap contentPreviewGame--mobile'>


        <div *ngFor='let previewItem of (gameS.arrPreviewGame_mobile$|async)!; let isFirst = first'
             class='contentPreviewGame__previewItem contentPreviewGame__previewItem--{{previewItem?.title}} contentPreviewGame__previewItem--mobile'
             [class.contentPreviewGame__previewItem--first]='isFirst'>
          <span>{{ previewItem?.title }}</span>


          <div *ngIf='previewItem?.title == "Date & Time"'>
            <!-- Date -->
            <dateFormatted [date]='previewItem?.value!' type='date' format='fullDate'></dateFormatted>
            ,
            <!-- Time -->
            <dateFormatted [date]='previewItem?.value!' type='time' format='shortTime'></dateFormatted>
          </div>

          <span *ngIf='previewItem?.title == "Status"' [class.o-colorRed]='(previewItem?.value|lowercase)=="cancelled"'>
            {{ previewItem?.value }}
          </span>

          <div *ngIf='previewItem.title == "Location"'>
            <ng-container *ngIf='previewItem.location'>
              <span>{{ previewItem.locationName }} {{ previewItem?.courtName ? ' - ' + previewItem?.courtName : '' }}</span>
              <span>{{ previewItem.location?.street }}</span>
              <span>{{ previewItem.location|getLocationString:['city', 'state', 'zipcode'] }}</span>

            </ng-container>
            <span *ngIf='!previewItem.location'>TBD</span>
          </div>

          <span
            *ngIf='previewItem?.title !== "Date & Time" && previewItem?.title !== "Location" && previewItem?.title !== "Status"'>
            {{ previewItem?.value }}
          </span>
        </div>

        <div class='contentPreviewGame__previewItem contentPreviewGame__previewItem--mobile'>
          <span>Assignor</span>
          <div>
            <span>{{ game?.assignor|getUserName }}</span>
            <svgAndText [text]='(game?.assignor?.phone|formatPhone)||"-"' leftSvgName='phoneBlue&20'
                        typeText='text8-blueDark'></svgAndText>
            <svgAndText [text]='game?.assignor?.email||"-"' leftSvgName='mailBlue&20' typeText='text8-blueDark'>
            </svgAndText>
          </div>
        </div>

        <btn *ngIf='isEditable'  text='Edit' bcg='blueDark' (click)='goToEditGame()' [disabled]='obj.disabledActions' adminPermission
                             btnHeight='36'></btn>
      </div>
    </div>

    <!--    === OFFICIALS ============================================-->
    <div class='wrapperForGameBlock officialsGameInfo wrapperForGameBlock--mobile officialsGameInfo--mobile'>
      <div class='contentPreviewGame__title__official'>Officials</div>
      <div class='contentPreviewGame contentPreviewGame--mobile o-wrap'>        
        <div *ngFor='let go of game?.gameOfficials; let idxRole=index; let firstRole=first; let lastRole=last'
             class='contentPreviewGame__itemRole contentPreviewGame__itemRole--mobile'
             (mouseleave)='resetPopup()'>
          <ng-container class='mobilegameofficial'>
            <div class='official-mobile g-flex--space-between g-flex__item'>
              <div class='official-mobile g-flex g-flex--space-between g-flex__item'>
                <div class='g-flex'>
                  <photo *ngIf='go?.official?.id' [data]='go?.official' w='40'>
                  </photo>
                  <meSvg *ngIf='!go?.official?.id' [w]='40' svgName='user_circleGreen&48'>
                  </meSvg>
                  <div class='official-mobile__texts g-flex-column' [class.hover]='shouldShowOpoup(idxRole)'>
                    <div class='o-text7' style='display: flex;'>
                      <span class='contentPreviewGame__nameText'> {{
                          go?.official?.id ? (go.official|getUserName) :
                            "Available Role"
                        }}</span>
                      <div *ngIf='go?.official?.id' class='image-wrapper' (touchend)='setPhoneNumPopup(idxRole)' id='phone-image-wrapper'>
                        <img src='assets/vector-phone.svg' style='width: 16px; height: 16px; margin:4px' id='phone-image' />
                      </div>
                    </div>
                    <div *ngIf='go?.official?.id' class='phone-popup mobile-mode' #phonePopup>
                      <div *ngIf="(!meS.OFFICIAL) || (calculateAge(go?.official?.dateOfBirth || '') > 18)"
                           (click)='callPhoneNumber(go?.official?.phone)' class='phone-number'>
                        <img src='assets/phoneGray.svg' style='width: 14px; height: 24px; margin-left:5px' />
                        <div class='phone-font'>
                          <ng-container *ngIf='go?.official?.phone else emptyNumber'> {{
                              go?.official?.phone |
                                formatPhone
                            }}
                          </ng-container>
                        </div>
                      </div>
                      <div class='phone-number' (click)='callPhoneNumber(go?.official?.emergencyPhoneNumber)'>
                        <img src='assets/vector-red.svg' style='width: 14px; height: 24px; margin-left:5px' />
                        <div class='phone-font'>
                          <ng-container *ngIf='go?.official?.emergencyPhoneNumber else emptyNumber'> {{
                              go?.official?.emergencyPhoneNumber | formatPhone
                            }}
                          </ng-container>
                        </div>
                      </div>
                      <div class='emergencyName' *ngIf='go?.official?.emergencyPhoneNumber else emptyNumber'>{{
                          go?.official?.emergencyName
                        }}
                      </div>
                    </div>
                    <div class='o-text8'>Official #{{ go.officialPositionNumber }} {{ go.officialPositionName }}</div>
                  </div>
                </div>
                <div class='statusWrap'>
                  <meSvg [svgName]='go|getSvgForGoStatus'></meSvg>
                </div>
              </div>
            </div>
          </ng-container>

        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #emptyNumber>&nbsp; -</ng-template>

