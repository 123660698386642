import { Pipe, PipeTransform } from '@angular/core';
import { TSvgName } from '@components/__svg_img/svg/forSvg';

@Pipe({ name: 'getSvgNameForSort', standalone: true })
export class GetSvgNameForSortPipe implements PipeTransform {

  // !!! часто в проетке встречается. Поэтому сделал пайп
  transform(value?: boolean): TSvgName { // return "arrow_bottom&16" || "arrow_top&16"
    const typeArrow: 'top' | 'bottom' = value ? 'bottom' : 'top';
    return `arrow_${typeArrow}&16` as TSvgName;
  }

}
