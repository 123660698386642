import { Pipe, PipeTransform } from '@angular/core';
import { OtherService } from '@services/other.service';

// example => 'Monica Jon'|getFirstLetter => 'MJ'
@Pipe({ name: 'getFirstLetter', standalone: true})
export class GetFirstLetterPipe implements PipeTransform {

  constructor(
    private otherS: OtherService,
  ) {
  }

  // !!! amountLetters сколько букв взять из слова
  transform(value?: string, amountLetters = 1, forTest?: string): string {
    return this.otherS.getFirstLetter(value, amountLetters, forTest);
  }

}
