import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import Median from 'median-js-bridge';
import { BtnComponent } from '@components/btn/btn.component';
import { AuthorizationClientType, MainService } from '@services/main.service';
import { ILoginResponseDto } from '@app/auth/auth_models';
import { TUserSignup } from '@app/shared/models/user';

declare var LOG: any;

export interface IGoogleNativeResponse {
  idToken: string | undefined;
  clientType: AuthorizationClientType,
  typeOperation: 'signup' | 'logout' | 'signin';
}

type GoogleSigninData = {
  error?: string;
  idToken?: string;
  state?: string;
  type: 'google';
};

type GoogleSigninParams = {
  callback?: (data: GoogleSigninData) => void;
  redirectUri?: string;
  state?: string;
};

@Component({
  selector: 'app-google-authorization',
  standalone: true,
  imports: [CommonModule, BtnComponent],
  templateUrl: './google-authorization.component.html',
  styleUrls: ['./google-authorization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleAuthorizationComponent {
  @Input() typeOperation!: 'signup' | 'logout' | 'signin';
  @Output() googleResponse = new EventEmitter<IGoogleNativeResponse>();
  readonly isApple = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  readonly clientType: AuthorizationClientType;
  constructor(public mainService: MainService) {
    this.clientType = this.isApple ? 'ios' : 'web';
  }

  // googleSingin(idToken: string, clientType: AuthorizationClientType) {
  //   LOG("res 'googleSingin");
  //   this.mainService.signinWithGoogle(idToken, clientType).toPromise()
  //     .then((res: ILoginResponseDto | undefined) => {
  //       LOG("res 'googleSingin" + JSON.stringify(res));
  //       this.ourResponse.emit(res);
  //     })
  //     .catch((err) => {
  //       LOG('googleAuthorizationCallback error ' + JSON.stringify(err));
  //     });
  // }

  // googleSingUp(idToken: string, clientType: AuthorizationClientType) {
  //   LOG("res 'googleSingUp");
  //   this.ourResponse.emit({ idToken, clientType: this.clientType, typeOperation:this.typeOperation } );
  //   // this.mainService.signupWithGoogle({ email: idToken }, this.clientType).toPromise()
  //   //   .then((res: TUserSignup) => {
  //   //     LOG("res 'googleSingUp" + JSON.stringify(res));
  //   //     this.ourResponse.emit(res);
  //   //   })
  //   //   .catch((err) => {
  //   //     LOG('googleSingUp error ' + JSON.stringify(err));
  //   //   });
  // }

  googleAuthorizationCallback(data: GoogleSigninData) {
    LOG('googleAuthorizationCallback ');
    LOG(JSON.stringify(data));
    LOG('clientType ' + this.clientType);

    this.googleResponse.emit({ idToken: data.idToken, clientType: this.clientType, typeOperation: this.typeOperation });


    // if (this.typeOperation === 'signup') {
    //   this.googleSingUp(data.idToken!,this. clientType);
    // } else {
    //   this.googleSingin(data.idToken!, this.clientType);
    // }

  };

  onGoogleAuthorizationClick(): void {
    LOG("click 'googleAuthorization");
    const appleSigninParams: GoogleSigninParams = {
      callback: this.googleAuthorizationCallback.bind(this),
      redirectUri: undefined,
      state: undefined,
    }
    Median.socialLogin.google.login(appleSigninParams);
  }
}
