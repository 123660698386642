import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { Geo, IGeoAddress, ITimezone } from '@classes/geo';
import { MainService } from '@services/main.service';
import { LocationClass } from '@classes/Location';
import { map } from 'rxjs/operators';
import { ILocation, IVenue } from '@models/location';

export type TFieldLoc = 'city' | 'state' | 'street' | 'zipcode' | 'building' | 'latitude' | 'longitude' | 'timezone' | 'id' | 'streetLine2'

@Injectable({ providedIn: 'root' })
export class LocationService {
  private locSub$ = new BehaviorSubject<ILocation>(new LocationClass());
  loc$: Observable<ILocation> = this.locSub$.asObservable();

  // === create/edit Game ==============
  private venueSub$ = new BehaviorSubject<IVenue | undefined>(undefined);
  private venueListSub$ = new BehaviorSubject<Array<IVenue>>([]);
  private venueLoadingSub$ = new BehaviorSubject<boolean>(true);
  venue$: Observable<IVenue | undefined> = this.venueSub$.asObservable();
  venueList$: Observable<Array<IVenue>> = this.venueListSub$.asObservable();
  venueLoading$: Observable<boolean> = this.venueLoadingSub$.asObservable();

  // === create/edit Venue ==============
  private venueNameSub$ = new BehaviorSubject<string | undefined>(undefined);
  venueName$: Observable<string | undefined> = this.venueNameSub$.asObservable();

//   сделать сервис для этого. потому что не только с локацией надо проверять это. Там проверять обязательные поля
  locTouched$ = new BehaviorSubject<boolean>(false); // когда выбрал веню или адрес с гугла получил, то все поля адреса надо пометить как затронутые, чтобы показать ошибки
  locValid$ = new BehaviorSubject<boolean>(false); // если все поля обязательные заполнены и валидны => вернется true

  constructor(
    private mainS: MainService,
    private geo: Geo,
  ) {
  }

  // для получения полного адреса из текущего местоположения
  getMeLocation(): Observable<ILocation | null> {
    return from(this.geo.getAddress()).pipe(
      map((address: IGeoAddress | null) => {
        if (!address) {
          console.error('GEO getGeoAddress() ', 'err', address);
          return null;
        }
        const location: ILocation = {
          id: address?.id!, // string,
          city: address?.city!, // string,
          state: address?.state!, // string,
          street: address?.street!, // string,
          zipcode: address?.zipcode!, // string,
          building: address?.building!, // string,
          latitude: address?.coords?.lat, // string | number,
          longitude: address?.coords?.lng, // string | number,
          // timezone: address , // ITimezone,
        };
        this.setData(location);
        return location;
      }),
    );
  }

  // вызвать в конструкторе или в ngOnInit в родительском компоненте (в котором в Html есть дочерний <location></location>)
  // create/edit Game => venue    /////  create/edit Venue => venueName
  public setData(location?: ILocation, venue?: IVenue, venueName?: string): void {
    // if (venue?.addressDto) this.setVenue(venue);
    if (venue?.addressDto) this.venue = venue;
    // if (venueName || typeof venueName == 'string') this.setVenueName(venueName);
    if (venueName || typeof venueName == 'string') this.venueName = venueName;

    // если есть веню, то локацию берем из веню. Если нету, то из игры.
    // const locFromVenue = this.venueSub$.getValue()?.addressDto;
    // const loc = locFromVenue ? locFromVenue : location;
    // loc ? this.setLoc(loc) : this.setLoc(new LocationClass());

    this.loc = location!;
  }

  public addVenue(venue: IVenue): void {
    this.venueListSub$.next([...this.venueListSub$.getValue(), venue]);
  }

  public reset(): void {
    this.locSub$.next(new LocationClass());
    // this.locSub$.next(null);
    this.venueSub$.next(undefined);
    this.venueNameSub$.next(undefined);
    this.locTouched$.next(false);
    this.locValid$.next(false); ///
  }

  // если адрес от гугла получил, то веню надо обнулять
  public resetVenue(): void {
    this.venueSub$.next(undefined);
  }

  // вызывать только перед отправкой формы на сервер. Потому что после вызова этой функции сразу подсветятся ошибки на полях
  // проверка на правильность заполнения и все ли обязательные поля заполнены
  public checkErrorLoc(requiredFields: Array<TFieldLoc>): boolean {
    const loc = this.locSub$.getValue();
    const allFieldsIsFilled = !requiredFields?.find((field) => !loc![field]); // все обязательные поля заполнены
    const locValid = this.zipValid && allFieldsIsFilled;
    this.locValid$.next(locValid);
    this.locTouched$.next(true);
    return locValid;
  }

  // если есть зипкод и нет координат, то получаем координаты
  public getCoordsByZipcode(zipcode?: string): Promise<any> {
    return this.geo.getCoordsByZipcode(zipcode || this.zipcode!);
  }

  public getVenues(): void {
    // this.mainS.getVenues({ params: { page: 1, size: 100, sort: 'name,asc' } }).toPromise()
    //   .then((res: any) => {
    //     if (!res?.content) return;
    //     let venueList: Array<IVenue> = res?.content || [];
    //     venueList = venueList.filter(el => el.name && el.addressDto); ////// чтобы не выводить пустые объекты с одним только id
    //     venueList.forEach(el => (el.addressDto?.state in shortStates) && (el.addressDto.state = shortStates[el.addressDto.state])); // чтобы сокращать штаты США
    //     this.venueListSub$.next(venueList);
    //   })
    //   .catch(() => {
    //   })
    //   .finally(() => this.venueLoadingSub$.next(false));
  }

  // === GETTERS ==================
  get loc(): ILocation {
    return this.locSub$.getValue();
  }

  set loc(location: ILocation) {
    // если есть веню, то локацию берем из веню. Если нету, то из игры.
    const locFromVenue = this.venueSub$.getValue()?.addressDto;
    let loc = locFromVenue || location;
    if (!loc) loc = new LocationClass();
    this.locSub$.next({ ...this.locSub$.getValue(), ...loc }); // оставить старые значения
  }

  get locValid(): boolean {
    return this.locValid$.getValue();
  }

  get locInvalid(): boolean {
    return !this.locValid$.getValue();
  }

  get city() {
    return this.locSub$.getValue()?.city;
  }

  get state() {
    return this.locSub$.getValue()?.state;
  }

  get street() {
    return this.locSub$.getValue()?.street;
  }

  get zipcode() {
    return this.locSub$.getValue()?.zipcode;
  }

  get zipValid() {
    // zip должен быть 5 или меньше символов и не пустой
    return !!this.locSub$.getValue()?.zipcode && this.locSub$.getValue()?.zipcode?.length! <= (+this.mainS.maxlengthZipcode_5);
  }

  get building() {
    return this.locSub$.getValue()?.building;
  }

  get latitude() {
    return this.locSub$.getValue()?.latitude;
  }

  get longitude() {
    return this.locSub$.getValue()?.longitude;
  }

  get timezone(): ITimezone {
    return this.locSub$.getValue()?.timezone!;
  }

  get venue(): IVenue | undefined {
    return this.venueSub$.getValue();
  }

  set venue(venue: IVenue | undefined) {
    if (venue) this.venueSub$.next({ ...this.venueSub$.getValue(), ...venue }); // оставить старые значения
    if (!venue) this.venueSub$.next(venue);
    this.loc = this.venueSub$.getValue()?.addressDto!;
  }

  get venueName(): string | undefined {
    return this.venueNameSub$.getValue();
  }

  set venueName(venueName: string | undefined) {
    this.venueNameSub$.next(venueName || '');
  }

}
