import { createAction, props } from "@ngrx/store";
import { ClassSettingsRequest, IResponse } from "@models/response-and-request";
import { ClassGame } from "@app/dir_group_assignor/games/game";

export const setCurrentViewDropDownAction = createAction(
    '[Dashboard] Set current View Dropdown',
    props<{currentViewDropDown: string}>()
);

export const setGameStatusAction = createAction(
    '[Dashboard] Set Game Status',
    props<{gameStatus: string}>()
);

export const setSelfAssignedAction = createAction(
    '[Dashboard] Set Self Assigned',
    props<{selfAssigned: boolean|null}>()
);

export const loadAllGamesAction = createAction(
    '[Dashboard] Get All Games',
    props<{sortParams: any, settingParams: ClassSettingsRequest}>()
)

export const loadAllAssignAction = createAction(
    '[Dashboard] Get Assign',
    props<{sortParams: any, settingParams: ClassSettingsRequest}>()
)

export const loadAllGamesActionSuccess = createAction(
    '[Dashboard] Get All Games Success',
    props<{games: IResponse<ClassGame> | undefined}>()
)

export const loadAllGamesCountAction = createAction(
    '[Dashboard] Load All Games Count',
    props<{gamesCount: number}>()
)

export const setTotalTableElements = createAction(
    '[Dashboard] Set Total Table Elements Count',
    props<{totalGames: number}>()
)

export const loadAllData = createAction(
    '[Dashboard], Load All Data',
    props<{sortParams: any, settingParams: ClassSettingsRequest}>()
)

export const loadAssignData = createAction(
    '[Dashboard], Load Assign Data',
    props<{sortParams: any, settingParams: ClassSettingsRequest}>()
)

export const loadAssignDataSuccess = createAction(
    '[Dashboard] Load All Assign Data',
    props<{assignData: IResponse<ClassGame>|undefined}>()
);

export const loadDeclinedData = createAction(
    '[Dashboard], Load Declined Data',
    props<{sortParams: any, settingParams: ClassSettingsRequest}>()
)

export const loadUnassignedData = createAction(
    '[Dashboard], Load Unassigned Data',
    props<{sortParams: any, settingParams: ClassSettingsRequest}>()
)



export const loadDeclinedAssignDataSuccess = createAction(
    '[Dashboard] Loading Declined Assign Data',
    props<{declinedData: IResponse<ClassGame>|undefined}>()
)

export const loadUnassignedAssignDataSuccess = createAction(
    '[Dashboard] Loading Declined Assign Data',
    props<{unassignedData: IResponse<ClassGame>|undefined}>()
)

export const loadingConditionAssignData = createAction(
    '[Dashboard] Load Declined Assign Data',
    props<{condition: string}>()
)


// this.activeTile = activeTile?.isPending ? 'UNPUBLISHED': activeTile?.isDeclined ? 'DECLINED' : activeTile?.isRoles ? 'UNASSIGNED': '';

export const DashboardSubAssignorActions = {
    setCurrentViewDropDownAction, setGameStatusAction, loadAllGamesAction, loadAllGamesCountAction, loadAllGamesActionSuccess, setSelfAssignedAction,
    setTotalTableElements, loadAllData, loadAssignData, loadAssignDataSuccess,
    loadDeclinedAssignDataSuccess, loadUnassignedAssignDataSuccess, loadDeclinedData, loadUnassignedData, loadAllAssignAction
}
