import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SuccessDialogComponent } from '@app/dir_officials/page-my-wallet/components/success-dialog/success-dialog.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { CardLogoComponent } from '@components/card-logo/card-logo.component';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { ACCOUNT_EXTERNAL_FIELDS, AccountExternalModel } from '@models/account-external.model';
import { ICard } from '@models/ICard';
import { AccountApiService } from '@services/account.api.service';
import { FundService } from '@services/funds.service';
import { MeService } from '@services/me.service';
import { OtherService } from '@services/other.service';
import { PayoutApiService } from '@services/payout.api.service';
import { PopupService } from '@services/popup.service';
import { IResCreateTokenSuccess, IStripeEvent, StripeService, TFieldStripeForm } from '@services/stripe.service';
import { ACCOUNT_METHODS } from '@shared/constants/account-methods.constant';
import { CREDIT_CARD_TYPE, CREDIT_CARD_TYPE_REGEXP } from '@shared/constants/credit-card-type.constant';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { catchError, finalize, throwError } from 'rxjs';
import { FieldComponent } from '../../__drop_inputs_matSelect/field/field.component';
import { InputCtrlComponent } from '../../__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { BtnComponent } from '../../btn/btn.component';
import { AccountPaymentComponent } from '@components/account-payment/account-payment.component';
import { HeadTitleComponent } from '../../head-title/head-title.component';
import { MainService } from '@services/main.service';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { DeviceService } from '@services/device.service';
import { AddAnotherComponent } from '@components/add-another/add-another.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

interface IForStripeElem {
  name: TFieldStripeForm;
  elemRef: Element; // ссылка на HTML елемент, которая в страйп передается
  stripeElem?: any; // сюда сохраняется созданный страйпом элемент => this.stripeElements?.create('cardNumber', option)
  value?: string;
}

enum FORM_FIELDS {
  NAME = 'cardName',
  EXPIRE = 'cardExpiry',
  NUMBER = 'cardNumber',
  CVV = 'cardCvc',
  ZIP = 'zip',
  ROUTING = 'routing',
  ACCOUNT = 'account',
  ACCOUNT_CONFIRM = 'accountConfirm',
}

@UntilDestroy()
@Component({
  selector: 'app-popup-payment-method',
  standalone: true,
  templateUrl: './popup-payment-method.component.html',
  styleUrls: ['./popup-payment-method.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    HeadTitleComponent,
    BtnComponent,
    AccountPaymentComponent,
    FieldComponent,
    InputCtrlComponent,
    ReactiveFormsModule,
    NgxMaskDirective,
    CardLogoComponent,
    SvgComponent,
    SpinnerComponent,
    SuccessDialogComponent,
    BtnWrapComponent,
    AddAnotherComponent,
  ],
  providers: [provideNgxMask()],
})
export class PopupPaymentMethodComponent implements OnInit {
  isEdit: boolean = false;
  isNextPage: boolean = false;
  formCard: FormGroup = new FormGroup({
    [FORM_FIELDS.NAME]: new FormControl(null, Validators.required),
    [FORM_FIELDS.ZIP]: new FormControl(null, Validators.required),
    [FORM_FIELDS.EXPIRE]: new FormControl(),
    [FORM_FIELDS.NUMBER]: new FormControl(),
    [FORM_FIELDS.CVV]: new FormControl(),
  });
  formBank: FormGroup = new FormGroup({
    // Validators.minLength(9), Validators.maxLength(9)
    [FORM_FIELDS.ROUTING]: new FormControl(null, [Validators.required]),
    [FORM_FIELDS.ACCOUNT]: new FormControl(null, Validators.required),
    [FORM_FIELDS.ACCOUNT_CONFIRM]: new FormControl(null, Validators.required),
  });
  cardBrand?: CREDIT_CARD_TYPE;
  paymentMethod?: ACCOUNT_METHODS;

  ACCOUNT_METHODS = ACCOUNT_METHODS;
  FORM_FIELDS = FORM_FIELDS;
  ACCOUNT_EXTERNAL_FIELDS = ACCOUNT_EXTERNAL_FIELDS;

  isValidForm = false;
  stripeElements?: any;
  fieldsRef: IForStripeElem[] = []; // здесь все поля, включая те, которые здесь в HTML создаются
  noInitFields: TFieldStripeForm[] = [FORM_FIELDS.NAME, FORM_FIELDS.ZIP]; // это поля, которые не надо создавать с помощью страйпа. Эти поля здесь в HTML создаются
  selectedAccount: AccountExternalModel | null = null;
  isLoading = false;
  paymentMethodIsInstant: boolean = false; 
  showToggleSwitch: boolean = false; 
  @Input() isOn: boolean = false;
  @Output() toggle = new EventEmitter<boolean>();
  availablePayout: number | undefined;
  finalPayout: number | undefined;

  toggleSwitch() {
    this.isOn = !this.isOn;
    this.toggle.emit(this.isOn);
  }

  togglePaymentMethod() {
    this.paymentMethodIsInstant = !this.paymentMethodIsInstant;
    if (this.selectedAccount) {
      this.selectedAccount.method = this.paymentMethodIsInstant ? ACCOUNT_METHODS.INSTANT : ACCOUNT_METHODS.STANDARD;
    }
  }
  
  constructor(
    public dialogRef: MatDialogRef<PopupPaymentMethodComponent, ICard | null>,
    @Inject(MAT_DIALOG_DATA) public dataPopup: {
      balance: number;
      isPayout: boolean;
      isEdit: boolean;
      accounts?: AccountExternalModel[];
      isSelectPaymentType: boolean;
      balanceInstant: number;
    },
    public mainS: MainService,
    private fundS: FundService,
    private stripeS: StripeService,
    private accountApiS: AccountApiService,
    private payoutApiS: PayoutApiService,
    private otherS: OtherService,
    private changeDetectorRef: ChangeDetectorRef,
    private meService: MeService,
    public deviceS: DeviceService,
    private popupS: PopupService,
    private cd: ChangeDetectorRef,
  ) {
    this.isEdit = dataPopup.isEdit;
    this.availablePayout = dataPopup.balance;
  }

  ngOnInit() {
    this.fundS.setStripeFields([
      FORM_FIELDS.NUMBER,
      FORM_FIELDS.EXPIRE,
      FORM_FIELDS.CVV,
      FORM_FIELDS.NAME,
      FORM_FIELDS.ZIP,
    ]);

    this.formCard.get(FORM_FIELDS.NUMBER)?.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.cardBrand = Object.keys(CREDIT_CARD_TYPE_REGEXP).find(key => {
        return CREDIT_CARD_TYPE_REGEXP[key].test(value);
      }) as CREDIT_CARD_TYPE || CREDIT_CARD_TYPE.UNKNOWN;
    });
    if (this.dataPopup && this.dataPopup.accounts && this.dataPopup.accounts.length > 0) {
      const defaultAccount = this.dataPopup.accounts.find(account => account[ACCOUNT_EXTERNAL_FIELDS.DEFAULT] === true);
      if (defaultAccount) {
        this.onAccountSelected(defaultAccount);
      }
    }
  }

  close(): void {
    this.dialogRef.close(null);
  }

  onNext() {
    this.isNextPage = true;
  }

  add() {
    this.isEdit = false;
    this.dataPopup.isSelectPaymentType = true;
  }

  onSave() {
    // const form = this.paymentMethod === ACCOUNT_METHODS.INSTANT ? this.formCard : this.formBank;
    //
    // this.dialogRef.close({
    //   ...form.value,
    //   cardBrand: this.cardBrand,
    //   numberShort: form.value.number.substring(form.value.number.length - 4),
    // });

    if (this.paymentMethod === ACCOUNT_METHODS.INSTANT) {
      const additionalData = {
        currency: 'usd',
      };

      this.stripeS.stripe?.createToken(this.findField(FORM_FIELDS.NUMBER).stripeElem, { currency: 'usd' })
        .then((response: IResCreateTokenSuccess) => {
          if (response?.token?.id) {
            this.startLoader();

            this.accountApiS.connectExternal(response?.token?.id)
              .pipe(
                // catchError(err => {
                //   console.error('catchError :', err)
                //   // this.otherS.showNotification(false, undefined, err);
                //   this.stopLoader();
                //   if (err?.status === 200) this.close();
                //   return throwError(() => err);
                // }),
                finalize(() => this.stopLoader()),
                untilDestroyed(this),
              )
              .subscribe(data => {
                this.fundS.tokenFromStripe = data?.token?.id;
                this.close();
              });
          } else {
            this.otherS.showError('No TOKEN from Stripe.');
          }
        });
    } else {
      this.startLoader();

      this.accountApiS.connectBank(
        this.formBank.value[FORM_FIELDS.ACCOUNT],
        this.formBank.value[FORM_FIELDS.ROUTING],
      )
        .pipe(
          // catchError(err => {
          //   this.otherS.showNotification(false, undefined, err);
          //   this.stopLoader();
          //   this.close();
          //   return throwError(() => err);
          // }),
          // do not use above
          finalize(() => this.stopLoader()),
          untilDestroyed(this),
        )
        .subscribe(data => this.close());
    }
  }

  selectCard(account: AccountExternalModel): void {
    this.dataPopup.isPayout ? this.selectedAccount = account : null;
  }

  onSelectPaymentMethod(paymentMethod: ACCOUNT_METHODS) {
    this.paymentMethod = paymentMethod;

    this.dataPopup.isSelectPaymentType = false;

    if (this.selectedAccount && this.selectedAccount.availableMethods.includes(ACCOUNT_METHODS.INSTANT) && this.selectedAccount.availableMethods.includes(ACCOUNT_METHODS.STANDARD)) {
      this.showToggleSwitch = true;
      this.paymentMethodIsInstant = this.paymentMethod === ACCOUNT_METHODS.INSTANT;
  } else {
      this.showToggleSwitch = false;
  }

  this.dataPopup.isSelectPaymentType = false;

    setTimeout(() => this.initFields());
  }



  isSaveDisabled() {
    return this.paymentMethod === ACCOUNT_METHODS.INSTANT
      ? this.formCard.invalid || this.formCard.pristine
      : this.formBank.invalid || this.formBank.pristine || this.formBank.get(FORM_FIELDS.ACCOUNT)?.value !==
      this.formBank.get(FORM_FIELDS.ACCOUNT_CONFIRM)?.value;
  }

  onRemoveAccount(account: AccountExternalModel) {
    this.startLoader();

    this.accountApiS.deleteExternal(this.meService.meId, account[ACCOUNT_EXTERNAL_FIELDS.ID] || '')
      .pipe(
        // catchError(err => {
        //   this.otherS.showNotification(false, undefined, err);
        //   this.stopLoader();
        //   return throwError(() => err);
        // }),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.getAccounts();
      });
  }

  onSetDefaultAccount(account: AccountExternalModel) {
    this.startLoader();

    this.accountApiS.setDefault(this.meService.meId, account[ACCOUNT_EXTERNAL_FIELDS.ID] || '')
      .pipe(
        // catchError(err => {
        //   this.otherS.showNotification(false, undefined, err);
        //   this.stopLoader();
        //   return throwError(() => err);
        // }),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.getAccounts();
      });
  }

  private startLoader() {
    this.isLoading = true;

    this.changeDetectorRef.markForCheck();
  }

  private stopLoader() {
    this.isLoading = false;

    this.changeDetectorRef.markForCheck();
  }

  onPayout() {
    if(this.meService.GROUP_ASSIGNOR) {  
      if (this.selectedAccount && this.selectedAccount[ACCOUNT_EXTERNAL_FIELDS.ID]) {
      this.startLoader();
      const payoutMethod: string = this.selectedAccount.method || ACCOUNT_METHODS.STANDARD;
      this.payoutApiS.generalPayout(this.selectedAccount[ACCOUNT_EXTERNAL_FIELDS.ID], payoutMethod)
        .pipe(
          catchError(err => {
            this.stopLoader();
            return throwError(() => err);
          }),
          finalize(() => {
            this.stopLoader();
          }),
          untilDestroyed(this),
        )
        .subscribe(data => {
          this.dialogRef.close();
          if (this.selectedAccount) {
            this.popupS.open$(
              SuccessDialogComponent,
              {
                width: '560px',
                method: this.selectedAccount[ACCOUNT_EXTERNAL_FIELDS.METHOD],
              },
              false,
              'success-dialog',
            );
          }
          this.otherS.showNotification(true, data);
        });
    }
  } else {
    if (this.selectedAccount && this.selectedAccount[ACCOUNT_EXTERNAL_FIELDS.ID]) {
      this.startLoader();
      const payoutMethod: string = this.selectedAccount.method || ACCOUNT_METHODS.STANDARD;
      this.payoutApiS.full(this.meService.meId, this.selectedAccount[ACCOUNT_EXTERNAL_FIELDS.ID], payoutMethod)
        .pipe(
          catchError(err => {
            this.stopLoader();
            return throwError(() => err);
          }),
          finalize(() => {
            this.stopLoader();
          }),
          untilDestroyed(this),
        )
        .subscribe(data => {
          this.dialogRef.close();
          if (this.selectedAccount) {
            this.popupS.open$(
              SuccessDialogComponent,
              {
                width: '560px',
                method: this.selectedAccount[ACCOUNT_EXTERNAL_FIELDS.METHOD],
              },
              false,
              'success-dialog',
            );
          }
          this.otherS.showNotification(true, data);
        });
    }
  }
}
  // private getAccounts() {
  //   this.accountApiS.getAccounts(this.meService.meId)
  //     .pipe(
  //       finalize(() => this.stopLoader()),
  //       untilDestroyed(this),
  //     )
  //     .subscribe(accounts => {
  //       this.dataPopup.accounts = accounts;

  //       this.changeDetectorRef.detectChanges();
  //     });
  // }

  private getAccounts() {
    this.accountApiS.getAccounts(this.meService.meId)
      .pipe(
        finalize(() => this.stopLoader())
      )
      .subscribe(accounts => {
        this.dataPopup.accounts = accounts;

        // Check each account to determine if the toggle should be shown
        for (const account of accounts) {
          if (account.availableMethods.includes('INSTANT') && account.availableMethods.includes('STANDARD')) {
            this.selectedAccount = account; 
            this.showToggleSwitch = true; 
            this.paymentMethodIsInstant = account.method === 'INSTANT';
            break; 
          }
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  // Handle account selection
onAccountSelected(account: AccountExternalModel) {
  this.selectedAccount = account;

  // Update the toggle state based on the selected account's method.
  this.showToggleSwitch = account.availableMethods.includes('INSTANT') && account.availableMethods.includes('STANDARD');
  this.paymentMethodIsInstant = account.method === 'INSTANT';

  this.changeDetectorRef.detectChanges();
}

  private initFields(): void {
    this.fundS.fields?.forEach((field: TFieldStripeForm) => {
      const refLink = document.querySelector(`#${field}`);
      if (refLink) {
        this.fieldsRef.push({ name: field, elemRef: refLink });
      }
    });

    this.stripeElements = this.stripeS.stripe?.elements();
    const option = { style: this.stripeS.styles, classes: this.stripeS.classes };

    this.fieldsRef?.forEach((item) => {
      if (this.noInitFields?.includes(item.name)) {
        return;
      }

      item.stripeElem = this.stripeElements?.create(item.name, option);
      item.stripeElem?.mount(item.elemRef);
      item.stripeElem?.on('change', (event: IStripeEvent) => this.checkError(event));
    });
  }

  private checkError(event: IStripeEvent): void {
    const field: TFieldStripeForm | undefined = event?.elementType;

    this.isValidForm = !event?.error;

    this.changeDetectorRef.detectChanges();
  }

  private findField(fieldName: TFieldStripeForm): IForStripeElem {
    return this.fieldsRef?.find((field) => field.name == fieldName)!;
  }

  closeToggleSwitch() {
    this.showToggleSwitch = false;
  }

  getPayoutAmount(): string {
    if (this.meService.GROUP_ASSIGNOR) {
      if (!this.availablePayout || !this.selectedAccount) {
        return '';
      }
  
      let balanceInDollars = this.availablePayout / 100;
      let finalPayout = balanceInDollars;
  
      if (this.selectedAccount.method === ACCOUNT_METHODS.INSTANT) {
        finalPayout = this.dataPopup.balanceInstant;
      }
      this.finalPayout = finalPayout;
      return `$${finalPayout.toFixed(2)}`;
} else {
    if (!this.dataPopup.balance || !this.selectedAccount) {
      return '';
    }
  
    const balanceInDollars = this.dataPopup.balance / 100;
    let finalPayout = balanceInDollars;
  
    if (this.selectedAccount.method === ACCOUNT_METHODS.INSTANT) {
      finalPayout = this.dataPopup.balanceInstant;
    }
  
    this.finalPayout = finalPayout;
    return `$${finalPayout.toFixed(2)}`;
  }
  }
  
  
}
