import { ClassGame, ClassGameOfficial } from '@app/dir_group_assignor/games/game';
import { ClassUser } from '@models/user';
import {
  ClassDrop,
  ClassYesNo,
  getDropFromString,
  preparePropertyFromDropForSendToServer,
} from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { toTitleCase } from '@models/other';
import { UtilsService } from '@services/utils.service';

// === Report Status =======================
export type TReportStatus =
  'DUE' | // вновь созданный репорт
  'FLAGGED' | // если игра ещё не закончена => gameIsCompleted=='No' false
  'NEEDS_APPROVAL' | // после DUE. админу нужно подьвердить
  'APPROVED' | // админ подтведдил
  'ARCHIVED' |
  'PROCESSING';

export type TReportStatusForHtml = 'Due' | 'Flagged' | 'Needs Approval' | 'Approved' | 'Archived' | 'Approval Pending';
export const arrReportStatusString_forAdmin: Array<TReportStatusForHtml> = [
  'Due', 'Flagged', 'Needs Approval', 'Approved', 'Archived',
];
export const arrReportStatusString_forOfficial: Array<TReportStatusForHtml> = [
  'Due', 'Flagged', 'Approval Pending', 'Approved', 'Archived',
];

export type TNEEDS_APPROVAL_forHtml = Extract<TReportStatusForHtml, 'Needs Approval' | 'Approval Pending'>;
export const NEEDS_APPROVAL_forHtml_forAdmin: TNEEDS_APPROVAL_forHtml = 'Needs Approval';
export const NEEDS_APPROVAL_forHtml_forOfficial: TNEEDS_APPROVAL_forHtml = 'Approval Pending';

export enum EReportStatus {
  DUE = 'Due',
  FLAGGED = 'Flagged',
  NEEDS_APPROVAL = 'Needs Approval', // for official Approval Pending
  // NEEDS_APPROVAL = NEEDS_APPROVAL_forHtml,
  APPROVED = 'Approved',
  ARCHIVED = 'Archived',
}

export interface IResponse_getAmountReportsStatuses { // !!! с сервера в таком виде приходит
  status?: TReportStatus; // 'DUE' | 'FLAGGED' | 'NEEDS_APPROVAL' | 'APPROVED' | 'ARCHIVED'
  count?: number;
}

export class ClassReportStatusDrop extends ClassDrop {
  titleCase?: TReportStatusForHtml;
  upperCase?: TReportStatus;
  count?: number;

  constructor(reportStatus?: TReportStatus | TReportStatusForHtml, count?: number, isOfficial?: boolean) {
    super({});
    if (!reportStatus) return;
    const is_NEEDS_APPROVAL = reportStatus === 'NEEDS_APPROVAL' || reportStatus === 'Needs Approval' || reportStatus === 'Approval Pending';
    // !!! для судьи и админа отличается текст
    if (is_NEEDS_APPROVAL) {
      this.titleCase = isOfficial ? NEEDS_APPROVAL_forHtml_forOfficial : NEEDS_APPROVAL_forHtml_forAdmin;
    } else {
      this.titleCase = toTitleCase(reportStatus) as TReportStatusForHtml;
    }
    // !!! andrei изменить потом
    this.upperCase = reportStatus.toUpperCase()?.replace(' ', '_') as TReportStatus;
    if (is_NEEDS_APPROVAL) this.upperCase = 'NEEDS_APPROVAL';
    if (typeof count === 'number') this.count = count;
  }
}

export const arrReportStatus_forAdmin = arrReportStatusString_forAdmin.map(el => new ClassReportStatusDrop(el, 0));
export const arrReportStatus_forOfficial = arrReportStatusString_forOfficial.map(el => new ClassReportStatusDrop(el, 0));

export function getArrReportStatusDrop(isOfficial: boolean): Array<ClassReportStatusDrop> {
  if (isOfficial) return arrReportStatus_forOfficial;
  else return arrReportStatus_forAdmin;
}

export function getReportStatusDrop(reportStatus: TReportStatus, isOfficial: boolean): ClassReportStatusDrop {
  if (isOfficial) return arrReportStatus_forOfficial.find(el => el.upperCase === reportStatus)!;
  else return arrReportStatus_forAdmin.find(el => el.upperCase === reportStatus)!;
}

export class ClassReport {
  id?: string;
  status?: TReportStatus;
  reportStatusDrop?: ClassReportStatusDrop; // // andrei переименовать в statusDrop
  game?: ClassGame;
  payment?: string; ///////
  homeTeamScore?: number;
  awayTeamScore?: number;
  additionalComments?: string;
  // adjustmentStatus?: ClassAdjustmentStatus; // TAdjustmentStatus;
  // adjustmentStatusDrop?: ClassAdjustmentStatus;
  attendance?: Array<ClassAttendance>;
  updatedBy?: string; // id user который сделал апдейт
  gameIsCompleted?: boolean;
  gameIsCompletedDrop?: ClassYesNo;
  gameNotBegun?: boolean;

  isDraft?: boolean;

  isSelect?: boolean; // for checkbox

  constructor(report?: ClassReport) {
    if (!report) return {};
    Object.entries(report)!.forEach((el) => {
      const key = el[0] as keyof ClassReport;
      this[key] = el[1];
    });

    // === FOR DROPDOWN ============================================
    this.reportStatusDrop = new ClassReportStatusDrop(report.status, 0);
    this.game = new ClassGame(report.game);
    // this.adjustmentStatusDrop = new ClassAdjustmentStatus(report.adjustmentStatus);
    if (typeof report.gameIsCompleted === 'boolean') this.gameIsCompletedDrop = new ClassYesNo(report.gameIsCompleted);

    // !!! по idOfficial ищу в gameOfficial и добавляю этот gameOfficial в attendanceItem. Это нужно чтобы получить название роли и т.д. Потому что в attendance Этой инфы нет
    if (report?.game?.gameOfficials?.length) {
      const arrAttendance: Array<ClassAttendance> = report?.attendance || [];
      report?.game?.gameOfficials?.forEach((go) => {
        arrAttendance?.forEach((itemAttendance) => {
          if (go?.id === itemAttendance?.idGameOfficial) itemAttendance.go = UtilsService.deepClone(go);
        });
      });
      this.attendance = arrAttendance.sort((el1, el2) => {
        if (el1!.go!.officialPositionNumber! > el2!.go!.officialPositionNumber!) return 1;
        else return -1;
      });
    }
    if (report.attendance?.length) {
      this.attendance = report.attendance.map(el => new ClassAttendance(el));
    }

    // andrei==  need check. не проверил
    if (this.game?.gameOfficials?.length) {
      this.game.gameOfficials = this.game.gameOfficials.sort((el1, el2) => {
        if (el1!.officialPositionNumber! > el2!.officialPositionNumber!) return 1;
        else return -1;
      });
    }

    return { ...report, ...this };
  }

  // !!! перед отправкой на сервер доставать из IDrop.upperCase и записывать в соответствующий property
  // !!! И удалять IDrop, т.к. на сервер они не доллжны отправляться
  // !!! Все property которые в конце названия имеют "Drop" нужно в этом методе обрабатывать
  static preparePropertyFromDropForSendToServer(obj?: ClassReport): ClassReport {
    if (!obj) return {};
    const objForSendToServer: ClassReport = obj;

    // !!! prepare Property Without Drop In Name
    // !!! если в конструкторе текущего класса вызывал создание другого класса, то здесь надо вызвать preparePropertyFromDropForSendToServer
    objForSendToServer.game = ClassGame.preparePropertyFromDropForSendToServer(obj.game);

    if (obj.attendance) {
      objForSendToServer.attendance = obj.attendance?.map(el => ClassAttendance.preparePropertyFromDropForSendToServer(el));
    }

    // !!! если имя property после replace('Drop','') не совпадает c нужным для отправки. Например в этом классе statusAssignDrop => status
    if (objForSendToServer?.reportStatusDrop?.upperCase) {
      objForSendToServer.status = objForSendToServer.reportStatusDrop.upperCase;
      delete objForSendToServer.reportStatusDrop;
    }

    return preparePropertyFromDropForSendToServer(objForSendToServer);
  }
}

export class ClassAttendance {
  official?: ClassUser; // {id: ''}
  reporter?: boolean; // судья который submit нажал
  read?: boolean; // read/unread

  // !!! это относится НЕ к юзеру а к роли
  participated?: boolean; // участвовал ли судья в игре => present
  switched?: boolean; // если true, то отправлен запрос админу чтобы заменить данного юзера => replace
  switchIdentifier?: string; // idNewUser (или имя-строка, если юзера нет в системе,которого пригласили)
  switchedReferee?: ClassUser; //  NewUser // updatedBy?: ClassUser;

  stateOfficialAttendance?: TStateOfficialAttendance;

  idGameOfficial?: string; // !!! для того чтобы найти нужный go?: ClassGameOfficial в массиве gameOfficials
  go?: ClassGameOfficial; // !!! для того чтобы в репорт инфо показывать название роли и т.д.

  constructor(obj?: ClassAttendance) {
    if (!obj) return;
    Object.entries(obj)!.forEach((el) => {
      const key = el[0] as keyof ClassAttendance;
      this[key] = el[1];
    });

    // attendanceItem.stateOfficialAttendance = 'Present'; // !!! потому что админу не нужно подтверждать своё действие о замене. Поэтому статус сразу Present
    if (obj.participated && !obj.switched && !obj.switchIdentifier && !obj.switchedReferee) obj.stateOfficialAttendance = 'Present';
    // if (el.participated && el.switched && (el.switchIdentifier || el.switchedReferee)) el.stateOfficialAttendance = 'Replace';
    if (obj.participated && obj.switched && (obj.switchIdentifier || obj.switchedReferee)) obj.stateOfficialAttendance = 'Present'; // !!! потому что админу не нужно подтверждать своё действие о замене. Поэтому статус сразу Present
    if (!obj.participated && !obj.switched && !obj.switchIdentifier && !obj.switchedReferee) obj.stateOfficialAttendance = 'Absent';

    return { ...obj, ...this };
  }

  static preparePropertyFromDropForSendToServer(obj?: ClassAttendance): ClassAttendance {
    if (!obj) return {};
    const objForSendToServer: ClassAttendance = obj;
    return preparePropertyFromDropForSendToServer(objForSendToServer);
  }
}

export type TStateOfficialAttendanceUpperCase = 'PRESENT' | 'REPLACE' | 'ABSENT';
export type TStateOfficialAttendance = 'Present' | 'Replace' | 'Absent';
export const arrStateOfficialAttendanceString: Array<TStateOfficialAttendance> = ['Present', 'Replace', 'Absent'];
export type TStateOfficialAttendanceDrop = ClassDrop & {
  titleCase: TStateOfficialAttendance,
  upperCase: TStateOfficialAttendanceUpperCase
};
export const arrStateOfficialAttendanceDrop = arrStateOfficialAttendanceString.map(el => getDropFromString(el, 'report.ts arrStateOfficialAttendanceDrop')) as Array<TStateOfficialAttendanceDrop>;

export interface IGameRole {
  title?: string;
  amount?: number | null;
  id?: string;
  // checked?: boolean; // выбран в фильтрах
  isSelect?: boolean; // выбран в фильтрах
  name?: string;
}

export interface InviteRefereeToGame {
  email?: string, // если ввел емайл и нажал инвайт в PopupInviteComponent
  officialId?: string, // если в PopupInviteComponent нашелся юзер и на нем нажал invite
  gameOfficialId?: string,
  gameId?: string,
  gameRole?: IGameRole;
  repeated?: boolean // если повторно отправляешь приглос, то надо отправлять true
}
