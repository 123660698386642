import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITestArrForAnalytics } from '@app/dir_group_assignor/payments/modelsForPayment';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { GetStylesPipe } from '@pipes/css/get-styles.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RoundBalancePipe } from '@app/dir_group_assignor/payments/pipes/round-balance.pipe';

@Component({
  selector: 'blockChart[chart]',
  standalone: true,
  imports: [CommonModule, SvgAndTextComponent, SvgComponent, GetStylesPipe, MatTooltipModule, RoundBalancePipe],
  templateUrl: './block-chart.component.html',
  styleUrls: ['./block-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockChartComponent implements OnChanges {
  @Input() chart!: ITestArrForAnalytics;
  @Input() height: string | number = 172;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.chart?.currentValue) {
      // if (this.chart.title == 'Projected Outgoings') {
      //   console.log('chart :', this.chart, '  amount :', this.chart?.amount)
      // }
    }
  }
}
