export type TSvgNameAll =
  // === directory arrow =======================
  'arrow_bottom&16'
  | 'arrow_bottomRed&20'
  | 'arrow_chevronBottom&20'
  | 'arrow_chevronBottomGrey&20'
  | 'arrow_chevronBottomRed&20'
  | 'arrow_chevronLeftGrey&24'
  | 'arrow_chevronRightGrey&24'
  | 'arrow_chevronLeft&24'
  | 'arrow_chevronRight&24'
  | 'arrow_chevronTop&24'
  | 'arrow_download&20'
  | 'arrow_left&20'
  | 'arrow_logout&16'
  | 'arrow_logoutWhite&16'
  | 'arrow_right&20'
  | 'arrow_rightGreen&20'
  | 'arrow_rightRed&20'
  | 'arrow_spiral'
  | 'arrow_squareLeft&24'
  | 'arrow_squareRight&24'
  | 'arrow_switch&16'
  | 'arrow_switch_white&16'
  | 'arrow_switch_vertical&18'
  | 'arrow_top&16'
  | 'arrow_topGreen&16'
  | 'arrow_twoCircle&20'
  | 'arrow_twoCircle2&20'
  | 'arrow_square_left&32'
  | 'arrow-icon'


  // === directory bcgLogo =======================
  | 'bcgLogo_1'
  | 'bcgLogo_login'
  | 'bcgLogo_logoNotch'
  | 'bcgLogo_logoNotchCircleGreen'
  | 'bcgLogo_logoNotchCircleGrey'
  | 'bcgLogo_logoNotchGreen'
  | 'bcgLogo_logoNotchGrey'
  | 'bcgLogo_textNotch'
  | 'bcgLogo_textNotchGreen'

  // === directory bell =======================
  | 'bell_blueDark'
  | 'bell_hornSquare&44'
  | 'bell_hornWhite&20'
  | 'bell_hornBlueDark&20'
  | 'bell_white'

  // === directory calendar =======================
  | 'calendar_blueDark&20'
  | 'calendar_grey1&20'

  // === directory chx =======================
  | 'chx_disabled&20'
  | 'chx_false&20'
  | 'chx_true&20'

  // === directory circle =======================
  | 'circle_3points2&20'
  | 'circle_3points_grey1_2&20'
  | 'circle_chx1&24'
  | 'circle_chx2&16'
  | 'circle_chx3&20'
  | 'circle_chx4'
  | 'circle_chx6&24'
  | 'circle_chx7&16'
  | 'circle_crossRed&20'
  | 'circle_crossRed2&16'
  | 'circle_empty&20'
  | 'circle_empty2&20'
  | 'circle_emptyGrey&20'
  | 'circle_emptyGrey2&20'
  | 'circle_emptyOrange&20'
  | 'circle_emptyBlack&20'
  | 'circle_green&8'
  | 'circle_green2&8'
  | 'circle_grey1&8'
  | 'circle_grey2&8'
  | 'circle_blue1&8'
  | 'circle_info&20'
  | 'circle_orange&8'
  | 'circle_red&8'
  | 'circle_red2&8'

  // === directory clock =======================
  | 'clock_grey&20'
  | 'clock_red&20'

  // === directory cross =======================
  | 'cross_blueDark&24'
  | 'cross_green&24'
  | 'cross_grey&24'
  | 'cross_red&24'
  | 'cross_white&24'

  // === directory crown =======================
  | 'crown_brown&24'
  | 'crown_gold&24'
  | 'crown_grey&24'

  // === directory delete =======================
  | 'delete_blueDark&20'
  | 'delete_grey&20'
  | 'delete_grey1&20'
  | 'delete_red&20'
  | 'delete_square1&44'
  | 'delete_square2&44'

  // === directory download =======================
  | 'download_2&16'
  | 'download_3&44'
  | 'download_4&32'
  | 'download_5&20'
  | 'download_6&40'
  | 'download_7'

  // === directory eye =======================
  | 'eye_false&20'
  | 'eye_true&20'

  // === directory expand =======================
  | 'expand'

  // === directory flag =======================
  | 'flag_circleRed&48'
  | 'alert-triangle'

  // === directory lightning =======================
  | 'lightning_gold&20'
  | 'lightning_green&24'
  | 'lightning_grey&24'
  | 'lightning_lightRed&24'
  | 'lightning_Red&24'
  | 'lightning_lightGreen&24'
  | 'lightning_orange&24'

  // === directory line =======================
  | 'line_threeLineGrey&20'
  | 'line_threeLineWhite&20'
  | 'line_threeLineBlueDark&24'
  | 'line_menuForFilters&44'

  // === directory payment =======================
  | 'payment_amex'
  | 'payment_bankOfAmerica'
  | 'payment_bankTransfer'
  | 'payment_diners'
  | 'payment_discover'
  | 'payment_jcb'
  | 'payment_masterCard'
  | 'payment_payPal'
  | 'payment_stripe'
  | 'payment_unionpay'
  | 'payment_unknown'
  | 'payment_visa'
  | 'payment_webMoney'

  // === directory plus =======================
  | 'plus_1&20'
  | 'plus_circleGreen&20'
  | 'plus_green&20'
  | 'plus_grey&20'
  | 'plus_minusCircleRed&20'
  | 'plus_square&20'
  | 'plus_white&20'
  | 'plus_grey_new&20'

  // === directory question =======================
  | 'question_blueDark&16'
  | 'question_grey&16'
  | 'question_grey&20'
  | 'question_white&16'
  | 'question_grey_filled&24'
  | 'question_red&20'

  // === directory search =======================
  | 'search_grey&20'

  // === directory settings =======================
  | 'settings_1&16'
  | 'settings_2&16'
  | 'settings_3&16'

  // === directory sheet =======================
  | 'sheet_squareBlueDark&36'
  | 'sheet_squareGreen&36'

  // === directory star =======================
  | 'star_yellow&18'

  // === directory test =======================
  | 'test_avatar'
  | 'test_avatarEmpty'
  | 'test_avatarOne'
  | 'test_d3js'
  | 'test_d3js2'
  | 'test_d3js3'
  | 'test_officials'

  // === directory text =======================
  | 'text_viewBalanceAs'

  // === directory toggle =======================
  | 'toggle_false&36'
  | 'toggle_true&36'
  | 'toggle_true2&36'

  // === directory user =======================
  | 'user_1&16'
  | 'user_2&16'
  | 'user_3&24'
  | 'user_circleGreen&48'
  | 'user_circleWhite&40'
  | 'user_users&16'

  // не в папке ===================
  | 'attach'
  | 'comingSoon'
  | 'congrats'
  | 'dollarBlue'
  | 'editSquareBlue&36'
  | 'emptyAttentions'
  | 'google&24'
  | 'apple'
  | 'layersTwoBlue&16'
  | 'magicWand&20'
  | 'mailBlue&20'
  | 'moneyBlue&16'
  | 'phoneBlue&20'
  | 'rewards'
  | 'save&20'
  | 'saveAsDraft&20'
  | 'squareTwo&44'
  | 'squareTwo2&20'
  | 'existStripeAccount&20'

  | 'hourglass'
  | 'certificate'
  | 'ussf'
  | 'USSF-Primary'
  | 'vector-phone'
  | 'vector-instant'
  | 'green-checkmark'
  | 'red-cross'
  | 'squareLoadingBlue&32'
  | 'refresh'
  | 'message-text-square'
  | 'message-text-square-min'
  | 'turnOff'


// !!! исключения для которых размер нужно задавать здесь в компоненте
export type TExceptionsSvgName =
  'comingSoon' // width=97 height=22
  | 'text_viewBalanceAs';

export type TSvgName = TSvgNameAll | TExceptionsSvgName;

export const arrExceptionsSvgName: Array<TExceptionsSvgName | string> = ['comingSoon', 'text_viewBalanceAs'];

export const widthLogoPaySystem: number = 46;
export const heightLogoPaySystem: number = 32;
