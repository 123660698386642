<div class='wrapperPopupTime'>
  <div class='wrapperPopupTime__content'>
    <div class='wrapperPopupTime__hours'>
      <meSvg svgName='arrow_chevronTop&24' (click)='changeHours()'></meSvg>
      <div class='wrapperPopupTime__value'>{{hours}}</div>
      <meSvg svgName='arrow_chevronBottom&20' (click)='changeHours(false)'></meSvg>
    </div>

    <div class='wrapperPopupTime__dots'>:</div>

    <div class='wrapperPopupTime__minutes'>
      <meSvg svgName='arrow_chevronTop&24' (click)='changeMinutes()'></meSvg>
      <div class='wrapperPopupTime__value'>{{minutes}}</div>
      <meSvg svgName='arrow_chevronBottom&20' (click)='changeMinutes(false)'></meSvg>
    </div>

    <div class='wrapperPopupTime__AmPm'>
      <meSvg svgName='arrow_chevronTop&24' (click)='changeAmPm()'></meSvg>
      <div class='wrapperPopupTime__value'>{{amPm}}</div>
      <meSvg svgName='arrow_chevronBottom&20' (click)='changeAmPm()'></meSvg>
    </div>
  </div>

  <btnWrap type='col' marginCol='8'>
    <btn text='Confirm' bcg='blueDark' (emit)='close(false)' btnHeight='36'></btn>
    <btn text='Cancel' bcg='white' (emit)='close(true)' btnHeight='36'></btn>
  </btnWrap>
</div>