import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MainService } from '@services/main.service';
import { MeService } from '@services/me.service';
import { Router } from '@angular/router';
import { DashboardService } from '@app/dir_group_assignor/dashboard/dashboard.service';
import { CustomDatesService } from '@classes/CustomDates';
import { DeviceService } from '@services/device.service';
import { FiltersService } from '@components/filters/filters.service';
import { IFormDashboard } from '@app/dir_group_assignor/dashboard/components/dashboard-official/dashboard-official.component';
import { AppState } from '@app/store/app.state';
import { select, Store } from '@ngrx/store';
import { DashboardHomeAssignerActions } from '@app/store/dashboard/actions/dashboard-home-assigner-actions';
import {
  selectDashboardAllGames,
  selectDashboardAssignData,
  selectDashboardDeclinedData,
  selectDashboardUnassignedData,
} from '@app/store/dashboard/states/dashboard.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  GroupAssignorDashboardService,
} from '@app/dir_group_assignor/dashboard/components/dashboard-group-assignor/services/group-assignor-dashboard.service';
import { DashboardSubAssignorActions } from '@app/store/dashboard/actions/dashboard.actions';
import { HelperClass } from '@classes/Helper-Classes';
import { ClassSettingsRequest } from '@models/response-and-request';
import { selectHomeAssigner } from '@app/store/dashboard/states/dashboard-home-assigner';
import {
  arrBlockGamesForDashboard,
  IBlockGamesForDashboard,
  TypeBlockGamesForDashboard,
} from '@app/dir_group_assignor/dashboard/components/dashboard-group-assignor/models/group-assignor.models';

@UntilDestroy()
@Component({
  selector: 'app-group-assignor-tiles',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, SvgComponent],
  templateUrl: './group-assignor-tiles.component.html',
  styleUrls: ['./group-assignor-tiles.component.scss'],
  providers: [GroupAssignorDashboardService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupAssignorTilesComponent extends HelperClass implements OnInit {
  // arrForColumnComingSoon: Array<IBlockGamesForDashboard> = [
  //     {title: 'Total Games', value: 0, id: 'games'},
  //     {title: 'Pending Assignments', value: 0, id: 'pending'},
  //     {title: 'Declined Assignments', value: 0, id: 'declined'},
  //     {title: 'Unassigned Roles', value: 0, id: 'roles'},
  // ];
  readonly arrForColumnComingSoon = arrBlockGamesForDashboard;
  selectedTable: TypeBlockGamesForDashboard = 'totalGames';

  form!: FormGroup<IFormDashboard>;
  sortingParams: any = {
    sort: 'date,Asc',
  };

  animationState: 'swipe-left' | 'swipe-right' | null = null;

  activeTile!: string;

  @Input() settings: ClassSettingsRequest = new ClassSettingsRequest({
    size: 5,
    from: this.customDatesS.todayMidnightFormatDate,
  });


  constructor(
    private formBuilder: UntypedFormBuilder,
    public mainS: MainService,
    public meS: MeService,
    public router: Router,
    public dashboardS: DashboardService,
    public customDatesS: CustomDatesService,
    public deviceS: DeviceService,
    public filtersS: FiltersService, // for-filters===
    public cd: ChangeDetectorRef,
    public store: Store<AppState>,
    private readonly subDashboardService: GroupAssignorDashboardService,
  ) {
    super(cd);
    this.createForm();
  }

  ngOnInit(): void {
    this.store.dispatch(DashboardSubAssignorActions.loadAllData({
      sortParams: this.sortingParams,
      settingParams: this.settings,
    }));
    this.store.select(selectHomeAssigner).pipe(untilDestroyed(this)).subscribe(activeTile => {
      // this.selectedTable = activeTile?.isGame ? 'games' :
      //   activeTile?.isDeclined ? 'declined' :
      //     activeTile?.isPending ? 'pending' :
      //       activeTile?.isUnassigned ? 'roles' : 'games';
      this.selectedTable = activeTile?.isGame ? 'totalGames' :
        activeTile?.isDeclined ? 'declinedAssignments' :
          activeTile?.isPending ? 'pendingAssignments' :
            activeTile?.isUnassigned ? 'unassignedRoles' : 'totalGames';
    });
    this.subscriptions();
  }

  onSwipe(event: any, currentIndex: number) { // event: HammerInput
    if (event.type === 'swipeleft') {
      const nextIndex = currentIndex === this.arrForColumnComingSoon.length - 1 ? 0 : currentIndex + 1;
      this.selectTable(this.arrForColumnComingSoon[nextIndex]);
      this.animationState = 'swipe-left';
    } else {
      const prevIndex = currentIndex === 0 ? this.arrForColumnComingSoon.length - 1 : currentIndex - 1;
      this.selectTable(this.arrForColumnComingSoon[prevIndex]);
      this.animationState = 'swipe-right';
    }

    setTimeout(() => {
      this.animationState = null; // Сбрасываем состояние анимации после завершения
    }, 300);
  }

  selectTable(item: IBlockGamesForDashboard) {
    // if (item.id === 'games') {
    if (item.id === 'totalGames') {
      this.form.patchValue({ selfAssigned: false });
      this.store.dispatch(DashboardHomeAssignerActions.setActiveTileGameAction());
    }
    // if (item.id === 'pending') {
    if (item.id === 'pendingAssignments') {
      this.form.patchValue({ gameStatus: 'pending' });
      this.store.dispatch(DashboardHomeAssignerActions.setActiveTilePendingAction());
    }
    // if (item.id === 'declined') {
    if (item.id === 'declinedAssignments') {
      this.form.patchValue({ gameStatus: 'declined' });
      this.store.dispatch(DashboardHomeAssignerActions.setActiveTileDeclinedAction());
    }
    // if (item.id === 'roles') {
    if (item.id === 'unassignedRoles') {
      this.form.patchValue({ gameStatus: 'roles' });
      this.store.dispatch(DashboardHomeAssignerActions.setActiveTileRolesAction());
    }
    this.selectedTable = item.id;
  }

  getTooltipText(type: string): string {
    switch (type) {
      case 'accepted':
        return 'Games that are assigned and accepted in order of nearest date and time.';
      case 'unaccepted':
        return 'Games to accept assigned to you by your assignor.';
      case 'unacceptedselfassigned':
        return 'Games you had requested and pending response by your assignor.';
      default:
        return '';
    }
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      currentViewDropdown: [''],
      gameStatus: 'ACCEPTED',
      selfAssigned: null,
    });
  }

  private subscriptions(): void {
    this.store.pipe(
      select(selectDashboardAllGames),
      untilDestroyed(this),
    ).subscribe(res => {
      this.updateColumnValue(0, res?.totalElements);
    });
    this.store.pipe(
      select(selectDashboardAssignData),
      untilDestroyed(this),
    ).subscribe(data => {
      this.updateColumnValue(1, data.assignData.totalElements || 0);
    });
    this.store.pipe(
      select(selectDashboardDeclinedData),
      untilDestroyed(this),
    ).subscribe(res => {
      this.updateColumnValue(2, res.declinedData.totalElements);
    });
    this.store.pipe(
      select(selectDashboardUnassignedData),
      untilDestroyed(this),
    ).subscribe(res => {
      this.updateColumnValue(3, res.unassignedData.totalElements);
    });
  }

  private updateColumnValue(index: number, count: number = 0): void {
    this.arrForColumnComingSoon[index].value = count;
    this.cd.detectChanges();
  }
}
