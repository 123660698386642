import { ClassDrop, preparePropertyFromDropForSendToServer } from '@components/__drop_inputs_matSelect/dropdown/dropdown';

export class ClassAdjustmentStatus extends ClassDrop {
  id?: string;
  name?: string;
  officialPay?: number;
  assignorFee?: number;
  active?: boolean;
  inUse?: boolean;

  adjustmentStatusId?: string;
  adjustmentStatusName?: string;
  cancelDate?: string;
  cancelReason?: string;

  constructor(gameAdjustmentStatus?: ClassAdjustmentStatus) {
    super({
      titleCase: gameAdjustmentStatus?.name || gameAdjustmentStatus?.adjustmentStatusName,
      upperCase: gameAdjustmentStatus?.id || gameAdjustmentStatus?.adjustmentStatusId,
    });
    if (gameAdjustmentStatus) {
      Object.entries(gameAdjustmentStatus)?.forEach((el) => {
        const key = el[0] as keyof ClassAdjustmentStatus;
        this[key] = el[1];
      });
    }

    if (!this.id && gameAdjustmentStatus?.adjustmentStatusId) this.id = gameAdjustmentStatus?.adjustmentStatusId;
    if (!this.id && gameAdjustmentStatus?.adjustmentStatusId) console.error('this.id :', this.id);
  }

  static preparePropertyFromDropForSendToServer(obj?: ClassAdjustmentStatus, forTest?: string): ClassAdjustmentStatus {
    if (!obj) return {};
    delete obj.upperCase;
    delete obj.titleCase;
    delete obj.lowerCase;
    const result = preparePropertyFromDropForSendToServer(obj, undefined, forTest);
    return result;
  }
}

export const keyOfClassAdjustmentStatus: Array<keyof ClassAdjustmentStatus> = ['id', 'name', 'officialPay', 'assignorFee', 'active'];