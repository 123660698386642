<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              arrCurrentLink: gameS.arrCurrentLinkDrop,
              objCurrentLink: (gameS.currentLink$|async)!,
              currentLink: (gameS.currentLink$|async)?.currentLink!,
              dataTable: (dataTable$|async)!,
              arrContent: (arrContent$|async)!,
              loadingTable: (meTableS.loadingTable$|async),
            } as obj' class='o-wrapPage' wrapPage [widthPage]='widthPage' cdkDropListGroup cdkScrollable dataTable>

  <!--  === FILTERS & TITLE ===========================-->
  <div class='o-header'>
    <linkPage-searchFilter [needUseUnsavedChanges]='true'></linkPage-searchFilter>
    <div class='o-header__content'>
      <div class='o-header__title'>Assign</div>
      <div class='o-header__filters'>
        <!-- <app-filters></app-filters> -->
        <app-filters [assignComponent]="this"></app-filters>
        <div class='o-header__actions'>
          <ng-container [ngTemplateOutlet]='actionsPublishTemplate'></ng-container>
        </div>
      </div>
      <app-filtersSelected [assignComponent]="this"></app-filtersSelected>
    </div>
  </div>

  <!--  === TABLE games & table officials ===================================-->
  <div class='wrapContentAssign o-content'>
    <div class='wrapContentAssign__content'>

      <!--  === TABLE ===================================-->
      <div class='o-content t-wrapperTable'>
        <tableHeader></tableHeader>
        <div class='t-table t-tableVersion2' #tableList>
          <ng-container *ngFor='let gameItem of obj.arrContent; let first = first; let idxGameItem = index; let last = last'>
            <div #listItem class='t-table__item' itemTable [item]='gameItem'
                 [class.o-opacity]='gameItem.gameStatus === "CLOSED"'
                 [class.gameSelectedAssign]='route.snapshot?.queryParams?.gameId === gameItem.id'>

              <!-- !!! === gameNumber ===-->
              <div class='firstCeilForAssign' ceilTable [idxCeil]='0'>
                <span [class]='obj.desktop ? "t-fontBold":"t-textGameNumber-forTable"'>{{ gameItem.gameNumber }}</span>
                <meSvg *ngIf='gameItem.gameReport' (click)='router.navigate([urlReportInfo, gameItem.gameReport.id])'
                       class='o-cur-pointer'
                       [svgName]='gameItem.gameStatus == "CLOSED" ? "sheet_squareGreen&36":"sheet_squareBlueDark&36"'></meSvg>
              </div>

              <!-- !!! === Date & Time / Age & Level ===-->
              <app-tableDoubleCeil [idxCeil]='1' [text1]='gameItem.date_EE_MMMd_yyy' [text2]='gameItem.date_shortTime_timezone'
                                   [text3]='gameItem|getAgeGenderLevel:"ClassGame"'
                                   [text4]='gameItem.competition?.competitionName || "TBD"'
                                   [bordNone_forMobile]='true'></app-tableDoubleCeil>

              <!-- !!! === Location & Teams ===-->
              <app-tableDoubleCeil [idxCeil]='2' [text1]='gameItem|getLocNameCourtName'
                                   [text2]='gameItem.location?.address|getLocationString:["city","state"]'
                                   [matTooltipText1]='gameItem|matTooltipForLocation'
                                   [text3]='(gameItem.homeTeam?.teamName || "TBD") + " vs."'
                                   [text4]='gameItem.awayTeam?.teamName || "TBD"' [commaForMobile]='false'
                                   [bordNone_forMobile]='true'></app-tableDoubleCeil>

              <ceilAssignGameOfficial [game]='gameItem'></ceilAssignGameOfficial>
            </div>
            <!--            <div class='weqew'>showTableOfficialsForMobile=>{{assignS.showTableOfficialsForMobile}}</div>-->
          </ng-container>

          <app-tableEmpty [tableEmpty]='filtersS.searchValue|getTableEmpty' [path]='urlAssign'>
            <btnImportGames class='class_ngContent_btn_for_nothingList'></btnImportGames>
          </app-tableEmpty>
        </div>
      </div>

      <!--  === table officials ===================================-->
      <assignOfficialAvailability [class.o-none]='obj.mobile && !assignS.showTableOfficialsForMobile'
                                  [class.assignOfficialAvailabilityForMobile]='assignS.showTableOfficialsForMobile'></assignOfficialAvailability>

      <ng-container *ngIf='obj.mobile'>
        <div *ngIf='assignS.haveDragDrop || !(!assignS.haveDragDrop && !(obj.arrContent|showBtnPublish))' class='buttonsPublishForMobile'>
          <div class='buttonsPublishForMobile__line'></div>
          <ng-container [ngTemplateOutlet]='actionsPublishTemplate'></ng-container>
        </div>
      </ng-container>
    </div>

    <app-tableInfiniteLoading></app-tableInfiniteLoading>
    <paginationWithSettings *ngIf='meTableS.showPagination'></paginationWithSettings>
  </div>

  <!--  === actionsPublishTemplate ====================================================================-->
  <ng-template #actionsPublishTemplate>
    <btn text='Save as unpublished' bcg='white' (emit)='assignOfficials(false)'
         [disabled]='!assignS.haveDragDrop || meTableS.loadingTable || reqPending'></btn>
    <btn text='Publish' bcg='blueDark' (emit)='assignOfficials(true)'
         [disabled]='(!assignS.haveDragDrop && !(obj.arrContent|showBtnPublish)) || meTableS.loadingTable || reqPending'></btn>
  </ng-template>
</div>
