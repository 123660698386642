import { Injectable } from "@angular/core";
import { BaseApi } from "@services/base-api";
import { BehaviorSubject, Observable, shareReplay } from "rxjs";
import { ISenderList } from "./models/sender-list-model";
import { HttpClient } from "@angular/common/http";
import { OtherService } from "@services/other.service";

@Injectable({ providedIn: 'root' })
export class PopupAnnouncementGeneralService extends BaseApi {
    readonly apiOfficials = '/api/core/officials/v1';
    readonly apiAnnouncement = '/api/notification/v1/announcement';
    cachedData = new Map();

    loadingAnnouncementsSub$ = new BehaviorSubject<boolean>(false);

    constructor
        (
            public http: HttpClient,
            public otherS: OtherService,
        ) {
        super(http, otherS);
    }

    getAllContact(params: { [key: string]: any; } = {}): Observable<any> {
        this.loadingAnnouncementsSub$.next(true);
        const endPoint = `${this.apiOfficials}/contact/all`;
        const endpointWithParams = `${endPoint}?${this.serializeParams(params)}`;

        var returnPromise = this.get(endpointWithParams);

        returnPromise.subscribe((res) => {
            this.loadingAnnouncementsSub$.next(false);
            console.log(this.loadingAnnouncementsSub$.getValue())
            return res;
        });

        return returnPromise;
    }

    private serializeParams(params: { [key: string]: any; }): string {
        return Object.entries(params)
            .filter(([_, value]) => value !== undefined)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');
    }

    getContactList(): Observable<ISenderList> {
        this.loadingAnnouncementsSub$.next(true);
        const endPoint = `${this.apiOfficials}/contact/lists`;
        var returnPromise = this.get(endPoint).pipe(shareReplay());
        returnPromise.pipe((res) => {
            this.loadingAnnouncementsSub$.next(false);
            return res;
        });

        return returnPromise;
    }

    getCount(recipientGroupType: string): Observable<number> {
        const endPoint = `${this.apiAnnouncement}/count?recipientGroup=${recipientGroupType}`;
        return this.get(endPoint);
    }

    // getAllContactsByGameIds(gameIds: string[]): Observable<any> {
    //     const queryParamIds = gameIds.join(',');
    //     const endPoint = `${ this.apiOfficials }/contact/games?ids=${ queryParamIds }`;
    //     return this.get(endPoint);
    // }

    getAllContactsByGameIds(gameIds: string[]): Observable<any> {
        this.loadingAnnouncementsSub$.next(true);
        const queryParamIds = gameIds.join(',');
        const endPoint = `${this.apiOfficials}/contact/games`;
        var returnPromise = this.post(endPoint, gameIds);

        returnPromise.pipe((res) => {
            this.loadingAnnouncementsSub$.next(false);
            return res;
        });

        return returnPromise;
    }

}
