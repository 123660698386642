import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperClass, TForPagination } from '@classes/Helper-Classes';
import { HeadTitleComponent } from '../../shared/components/head-title/head-title.component';
import { InputCtrlComponent } from '../../shared/components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { SvgComponent } from '../../shared/components/__svg_img/svg/svg.component';
import { BtnComponent } from '../../shared/components/btn/btn.component';
import { SpinnerComponent } from '../../shared/components/spinner/spinner.component';
import { BehaviorSubject, EMPTY, forkJoin, Observable, of } from 'rxjs';
import { PhotosComponent } from '../../shared/components/__svg_img/photos/photos.component';
import { MatMenuModule } from '@angular/material/menu';
import { PaginationComponent } from '../../shared/components/pagination/pagination.component';
import { MainService } from '@services/main.service';
import { ClassSettingsRequest } from '@models/response-and-request';
import { ISortBy } from '@components/sortBy/sortBy';
import { NothingListComponent } from '../../shared/components/nothing-list/nothing-list.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { PhotoComponent } from '../../shared/components/__svg_img/photo/photo.component';
import { LinkPageComponent } from '../../shared/components/link-page/link-page.component';
import { PopupService } from '@services/popup.service';
import {
  PopupAnnouncementDetailsComponent,
} from '@components/__popup-windows/popup-announcement-details/popup-announcement-details.component';
import { PopupNewAnnouncementComponent } from '@components/__popup-windows/popup-new-announcement/popup-new-announcement.component';
import {
  PopupNewAnnouncementGeneralComponent,
} from '@components/__popup-windows/popup-new-announcement-general/popup-new-announcement-general.component';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { GetChxSvgNamePipe } from '@components/__svg_img/svg/pipes/get-chx-svg-name.pipe';
import { AnnouncementsService } from './announcements.service';
import { DateTimeFormatPipe } from '@pipes/date/date-time-format.pipe';
import { GetTimezoneFromDateFromServerPipe } from '@pipes/date/get-timezone-from-date-from-server.pipe';
import { OtherService } from '@services/other.service';
import { UtcToLocalPipe } from '@pipes/date/utc-to-local.pipe';
import { DeviceService } from '@services/device.service';
import { TitlecaseNoUnderscorePipe } from '@pipes/title-case-no-underscore.pipe';
import {
  AnnouncementItem,
  Announcements,
  currentLink_all_announcements,
  currentLinkDrop_viewAll_announcements,
  TCurrentLink_announcements,
  TCurrentLinkDrop_announcements,
} from '@app/dir_group_assignor/announcements/models/announcements';
import { ClassCeilTableHeader } from '@components/_table/meTable';
import { calculatePopupSizes } from '@models/other';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';
import { ComingSoonMobile } from "../dashboard/components/coming-soon-mobile/coming-soon-mobile.component";

@UntilDestroy()
@Component({
  selector: 'app-announcements',
  standalone: true,
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    AnnouncementsService,
    OtherService,
    MainService,
    DeviceService,
  ],
  imports: [
    CommonModule,
    HeadTitleComponent,
    InputCtrlComponent,
    SvgComponent,
    BtnComponent,
    SpinnerComponent,
    PhotosComponent,
    MatMenuModule,
    PaginationComponent,
    NothingListComponent,
    FormsModule,
    DropFormCtrlComponent,
    ReactiveFormsModule,
    PhotoComponent,
    LinkPageComponent,
    GetChxSvgNamePipe,
    DateTimeFormatPipe,
    GetTimezoneFromDateFromServerPipe,
    UtcToLocalPipe,
    TitlecaseNoUnderscorePipe,
    GetTableEmptyPipe,
    ComingSoonMobile
  ],
})
export class AnnouncementsComponent extends HelperClass implements OnInit {
  private destroy!: () => Observable<unknown>;
  private announcementsList$: Observable<Announcements> = EMPTY;

  announcementsListSub$ = new BehaviorSubject<AnnouncementItem[]>([] as AnnouncementItem[]);
  paginationData?: Announcements; // IResponse
  settings: ClassSettingsRequest & { competitions?: string; filter?: TCurrentLink_announcements; sent?: boolean; } = { page: 1, size: 10 }; //  sort: 'name,asc'
  form: FormGroup = new FormGroup({
    search: new FormControl(),
    competition: new FormControl(),
    group: new FormControl(),
  });

  isAllAnnouncementSelected = false;

  tableHeader: Array<ClassCeilTableHeader> = [
    { text: 'Subject Line', isChx: true },
    { text: 'Type' },
    { text: 'Method' },
    { text: 'Status' },
    { text: 'Date' },
    { text: 'Time' },
  ];

  // readonly class_ngContent_btn_for_nothingList = class_ngContent_btn_for_nothingList;

  // selectedType = 'all'; // Грант зачем создавать дополнительные переменные. Это значение хранится в announcementsS.currentLinkObj.currentLink

  constructor(
    public router: Router,
    public cd: ChangeDetectorRef,
    private popupS: PopupService,
    public announcementsS: AnnouncementsService,
    private otherS: OtherService,
    private mainS: MainService,
    public deviceS: DeviceService,
  ) {
    super(cd);
    this.announcementsS.currentLinkObj = { currentLink: currentLinkDrop_viewAll_announcements };
    this.getAnnouncementsList();
    this.subscribeToSearch();
  }

  ngOnInit() {
  }

  getUserDate(date: Date) {
    return this.otherS.convertDateUtcToUser(date);
  }

  get announcementsList(): AnnouncementItem[] {
    return this.announcementsListSub$.getValue();
  }

  set announcementsList(announcementItemArr: AnnouncementItem[]) {
    this.announcementsListSub$.next(announcementItemArr);
  }

  selectAll(): void {
    this.isAllAnnouncementSelected = !this.isAllAnnouncementSelected;
    this.announcementsListSub$.next(this.selectAllFromHelperClass(this.announcementsList));
  }

  selectItem(item: AnnouncementItem, event: any): void {
    event.stopPropagation();
    this.selectItemFromHelperClass(item, this.announcementsList);
  }

  paginationMethod(type: TForPagination, size?: number, page?: number, sortBy?: ISortBy): void {
    this.announcementsList$ = EMPTY;
    this.paginationMethodHelperClass(type, this.settings, size, page, sortBy);
    this.getAnnouncementsList();
  }

  linkPage(currentLinkDrop: TCurrentLinkDrop_announcements) {
    this.announcementsList$ = EMPTY;
    this.selectedItems = [];
    this.isSelectAll = false;
    this.announcementsS.currentLinkObj = { currentLink: currentLinkDrop };
    this.settings.filter = currentLinkDrop.upperCase;
    if (this.settings.filter === currentLink_all_announcements) delete this.settings.filter;
    this.getAnnouncementsList();
  }

  getAnnouncementsList(isArchive: boolean = false): void {
    if (this.announcementsList$ === EMPTY || isArchive) {
      this.startLoading();

      this.announcementsList$ = this.announcementsS.getListAnnouncement(this.settings)
        .pipe(
          untilDestroyed(this),
          catchError(() => EMPTY),
          finalize(() => this.endLoading()),
        );

      this.announcementsList$.pipe(untilDestroyed(this)).subscribe((response: Announcements) => {
        this.announcementsList = response.content;
        console.log(response.content);
        this.paginationData = response;
        this.cd.detectChanges();
      });
    }
  }

  subscribeToSearch(): void {
    this.subscribeToSearchFromHelperClass().pipe(untilDestroyed(this)).subscribe((searchValue: string) => {
      if (!searchValue) {
        delete this.settings.search;
        // return;
      }
      this.settings.search = searchValue;
      this.getAnnouncementsList();
    });
  }

  openDetails(announcementItem: AnnouncementItem): void {
    if (announcementItem?.userId) {
      this.mainS.getUserById(announcementItem?.userId).pipe(untilDestroyed(this)).subscribe(userData => {
        announcementItem.userData = userData || null;
        const { widthForPopup, heightForPopup } = calculatePopupSizes();
        this.popupS.open(PopupAnnouncementDetailsComponent, {
          announcementItem: announcementItem,
          width: widthForPopup,
          height: heightForPopup,
        }).then((res: any) => {
          if (!res) return;
          this.sendAnnouncement(announcementItem);
        });
      });
    }
  }

  sendAnnouncement(announcementItem: AnnouncementItem) {
    this.announcementsS.getAnnouncementById(announcementItem.id).pipe(untilDestroyed(this)).subscribe(item => {
      announcementItem = item;
      this.announcementProcessing(announcementItem);
    });
  }

  createAnnouncement(): void {
    this.announcementsS.getOfficialsLists()
      .pipe(
        switchMap(response => {
          const officialsLists = response.responseBody || [];
          return this.popupS.open$(PopupNewAnnouncementComponent, { officialsLists });
        }),
        untilDestroyed(this),
      )
      .subscribe((res: any) => {
        if (res) {
          switch (res.titleCase) {
            case 'By Availability':
              this.openPopup('availability');
              break;
            case 'By Date or Competition':
              this.openPopup('dateCompetition');
              break;
            case 'General':
              this.openPopup('general');
              break;
            default:
              break;
          }
        }
      });
  }

  createNewAnnouncement(formData: AnnouncementItem) {
    console.log(formData);

    this.announcementsList$ = EMPTY;

    const updateDraftAndCreate = () => {
      return this.announcementsS.updateDraftAnnouncement(formData)
        .pipe(
          untilDestroyed(this),
          switchMap(() => this.announcementsS.createDraftAnnouncement(formData.id, formData)),
        );
    };

    const createAnnouncement = () => {
      return this.announcementsS.createAnnouncement(formData)
        .pipe(
          untilDestroyed(this),
          switchMap((announcementId) => {
            if (announcementId) {
              if (formData.status === 'SENT' && !formData.isDrafted) {
                return this.announcementsS.createDraftAnnouncement(announcementId, formData);
              } else if (formData.status === 'DRAFT') {
                return of(null);
              }
            }
            return of(null);
          }),
          catchError(error => {
            console.error('Error:', error);
            return of(null);
          }),
        );
    };

    if (formData.id === '' || formData.isDrafted) {
      if (formData.status === 'SENT' && formData.isDrafted) {
        updateDraftAndCreate().pipe(untilDestroyed(this)).subscribe(() => this.getAnnouncementsList());
      } else {
        createAnnouncement().pipe(untilDestroyed(this)).subscribe(() => this.getAnnouncementsList());
      }
    } else {
      this.announcementsS.createDraftAnnouncement(formData.id, formData).pipe(untilDestroyed(this)).subscribe(() => this.getAnnouncementsList());
    }
  }

  updateDraftAnnouncement(formData: AnnouncementItem) {
    this.announcementsList$ = EMPTY;
    this.announcementsS.updateDraftAnnouncement(formData).pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.getAnnouncementsList();
      });
  }

  deleteAnnouncementById(announcementId: string) {
    this.announcementsList$ = EMPTY;
    this.announcementsS.deleteAnnouncement(announcementId).pipe(untilDestroyed(this))
      .subscribe(() => {
        this.getAnnouncementsList();
      });
  }

  deleteSelectedAnnouncement() {
    const deleteOnlyDrafts = this.selectedItems.filter((announcement: AnnouncementItem) => {
      return announcement.status === 'DRAFT' || this.announcementsS.is_currentLink_archived;
    });

    const deleteObservables = deleteOnlyDrafts.map(selectedItem =>
      this.announcementsS.deleteAnnouncement(selectedItem.id),
    );

    if (deleteObservables.length === 0) {
      this.resetSelectedItem();
    }

    // forkJoin for parallel deletion
    forkJoin(deleteObservables).pipe(untilDestroyed(this))
      .subscribe(() => {
        const deletedIds = deleteOnlyDrafts.map(({ id }) => id);
        this.announcementsList = this.announcementsList.filter(({ id }) => !deletedIds.includes(id));
        this.resetSelectedItem();
      });
    // Updating all list after deletion
    this.getAnnouncementsList();
  }

  archiveAnnouncementById(announcementId: string, restore: boolean = false) {
    this.announcementsList$ = EMPTY;
    this.announcementsS.archiveAnnouncement([announcementId], restore).pipe(untilDestroyed(this))
      .subscribe(() => this.getAnnouncementsList());
  }

  archiveAnnouncementByIds() {
    const ids = this.selectedItems.map((announcementItem: AnnouncementItem) => announcementItem.id);
    this.announcementsS.archiveAnnouncement([...ids]).pipe(untilDestroyed(this))
      .subscribe(() => this.getAnnouncementsList(true));
  }

  public openPopup(typeAnnouncement: string, announcementItem: AnnouncementItem | undefined = undefined) {
    // ToDo возможно потом не плохо было бы сделать сервис для подобных вещей
    const { widthForPopup, heightForPopup } = calculatePopupSizes();
    this.popupS
      .open$(PopupNewAnnouncementGeneralComponent, {
        typeAnnouncement,
        height: heightForPopup,
        width: widthForPopup,
        announcementItem,
      }, false)
      .pipe(untilDestroyed(this))
      .subscribe((res: any) => {
        if (!res) {
          this.announcementsList$ = EMPTY;
          this.getAnnouncementsList();
          return;
        }
        announcementItem = { ...announcementItem, ...res.value };
        this.announcementProcessing(announcementItem);
      });
  }

  private formatObjectProperty(obj: any, property: string): any {

    obj.recipientGroup === 'Certified Only'
      ? obj.recipientGroup = 'ALL_CERTIFIED_OFFICIALS'
      : obj.recipientGroup = obj.recipientGroup;

    const value = obj[property];

    if (value !== undefined && value !== null) {
      const formattedValue = value.toString().toUpperCase().replace(/\s+/g, '_');

      const newObj = { ...obj, [property]: formattedValue };
      return newObj;
    }

    return obj;
  }

  showMethodType(sms: boolean, email: boolean, urgent: boolean): string {
    if (sms) {
      return 'SMS';
    }
    if (email) {
      return 'Email';
    }
    return 'Urgent';
  }

  private announcementProcessing(announcementItem: AnnouncementItem | undefined): void {
    const formData = this.formatObjectProperty(announcementItem, 'recipientGroup');
    if (formData.id && formData.id !== '' && formData.draft === true) {
      this.updateDraftAnnouncement(formData);
    } else {
      this.createNewAnnouncement(formData);
    }
  }

}

// linkPage(event: string) {
//   this.announcementsList$ = EMPTY;
//   this.selectedItems = [];
//   this.isSelectAll = false;
//   const linkObject: Record<string, string | undefined> = {
//     'archived': 'archive',
//     'sent': 'sent',
//     'view all': 'all', // If skipped, it will not be added to query params
//   };
//
//   const prop = (linkObject as any)[event];
//
//   if (prop && prop === 'all') {
//     delete this.settings.filter;
//     this.selectedType = 'all';
//   }
//   if (prop && prop === 'archive') {
//     this.settings.filter = 'ARCHIVE';
//     this.selectedType = 'archived';
//   }
//   if (prop && prop === 'sent') {
//     this.settings.filter = 'SENT';
//     this.selectedType = 'sent';
//   }
//
//   this.getAnnouncementsList();
// }

// deleteSelectedAnnouncement() {
//   const deleteOnlyDrafts = this.selectedItems.filter((annoucement: AnnouncementItem) =>
//     annoucement.draft === true || this.selectedType === 'archived'
//   );
//
//   const deleteObservables = deleteOnlyDrafts.map(selectedItem =>
//     this.announcementsService.deleteAnnouncement(selectedItem.id),
//   );
//
//   if (deleteObservables.length === 0) {
//     this.resetSelectedItem();
//   }
//
//   // forkJoin for parallel deletion
//   forkJoin(deleteObservables)
//     .pipe(takeUntil(this.destroy()))
//     .subscribe(() => {
//       const deletedIds = deleteOnlyDrafts.map(({ id }) => id);
//       this.announcementsList = this.announcementsList.filter(({ id }) => !deletedIds.includes(id));
//       this.resetSelectedItem();
//     });
//   // Updating all list after deletion
//   this.getAnnouncementsList();
// }
