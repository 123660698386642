<!--<div class='wrapperAssignors o-wrapPage'>-->
<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              selectedItems: selectedItems$|async,
             } as obj' class='o-wrapPage wrapperAssignors' wrapPage [widthPage]='1076'>

  <!--  === FILTERS & TITLE ===========================-->
  <div class='o-header o-header--noHaveToggleCurrentPast'>
    <div class='o-header__linkPage'>
      <app-filtersSelectedIcon></app-filtersSelectedIcon>
    </div>
    <div class='o-header__content'>
      <div class='o-header__title'>My Assignors</div>
      <div class='o-header__filters'>
        <app-filters (emit)='emitSettingRequest($event)'></app-filters>
        <div class='o-header__actions'>
          <meSvg svgName='download_3&44' (click)='null' [disabled]='true'></meSvg>
        </div>
      </div>
      <app-filtersSelected></app-filtersSelected>
    </div>
  </div>

  <!--  === TABLE ==============================-->
  <div *ngIf='!(loading$|async); else spinner' class='o-content'>
    <!--  <ng-container *ngIf='!(loading$|async); else spinner'>-->
    <div class='o-table'>
      <div class='o-table__header'>
        <div *ngFor='let itemHeader of tableHeader; let idx = index;' class='o-table__header__item widthItem--{{idx+1}}'>
          {{ itemHeader.text }}
          <!--          <meSvg *ngIf='itemHeader.isActive' [svgName]='"arrow_top&16"' class='o-table__header__item__arrow'></meSvg>-->
        </div>
      </div>

      <div *ngIf='(assignorsListSub$|async)?.length; else nothingList' class='o-table__list'>
        <ng-container *ngFor='let assignorItem of (assignorsListSub$|async); let first = first; let idx = index; let last = last'>
          <div class='o-table__list__item'>
            <div class='name widthItem--1'>
              <photo class='mePhoto'></photo>

              <div class='o-table--itemColumn'><span class='o-text7'>{{ assignorItem.name }}</span><span>{{ assignorItem.email }}</span>
              </div>
            </div>
            <div class='widthItem--2 o-table--itemColumn o-noWrap'>
              <span>{{ assignorItem.groupName }}</span>
            </div>
            <div class='widthItem--3 o-table--itemColumn'>
              <span>{{ assignorItem.phone }}</span>
            </div>
            <div class='widthItem--4 o-table--itemColumn'>
              <span>{{ assignorItem.city }}</span>
            </div>
            <div class='widthItem--5' [matMenuTriggerFor]='menuForPoints' (click)='$event.stopPropagation()'>
              <meSvg svgName='circle_3points2&20'></meSvg>
              <mat-menu #menuForPoints='matMenu'>

                <button [ngStyle]='{width: "201px"}' class='hiddenMatOption'></button>
              </mat-menu>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <pagination *ngIf='(assignorsListSub$|async)?.length' [data]='data' (updatePage)='paginationMethod("updatePage",undefined,$event)'
                (updateResults)='paginationMethod("updateCountResults",$event)'></pagination>
    <!--  </ng-container>-->
  </div>
</div>

<ng-template #spinner>
  <spinner></spinner>
</ng-template>

<ng-template #nothingList>
  <!--  <nothingList typePage='transactions'></nothingList>-->
  <nothingList [tableEmpty]='filtersS.searchValue|getTableEmpty'></nothingList>
</ng-template>
