import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ReactWrapperComponent } from "./components/react-wrapper/react-wrapper.component";
import { ComingSoonMobile } from "../dashboard/components/coming-soon-mobile/coming-soon-mobile.component";
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { FiltersService } from '@components/filters/filters.service';
import { HelperClass } from '@classes/Helper-Classes';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { CommonModule } from '@angular/common';
import { EmbeddableTabbedList, embeddableTabList } from './embeddableList';
import { FiltersSelectedIconComponent } from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { ClassFilterDateRange, ClassFilterDrop, ClassFilterInput, IForClassForFilters } from '@components/filters/filters';
import { GameService } from '../games/game.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { BtnComponent } from '@components/btn/btn.component';
import { TChoosePeriod } from '@components/__drop_inputs_matSelect/date-range/dateRange';


@UntilDestroy()
@Component({
    selector: 'reporting',
    styleUrls: ['./reporting.component.scss'],
    standalone: true,
    templateUrl: './reporting.component.html',
    imports: [ComingSoonMobile, CommonModule, ReactWrapperComponent, WrapPageDirective,
        SvgComponent, BtnComponent, FiltersSelectedIconComponent, FiltersComponent,
        FiltersSelectedComponent, MatTooltipModule],
    providers: [FiltersService, SettingsRequestService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportingComponent extends HelperClass implements IForClassForFilters {
    variables: any | undefined = undefined;
    filtersReady: boolean = true;
    tabs: Array<EmbeddableTabbedList> = embeddableTabList;
    selectedTab: EmbeddableTabbedList = embeddableTabList[0];

    customDatePeriodRange: Array<TChoosePeriod> = ['Yesterday', 'Last 7 days', 'Last 2 weeks', 'Last month', 'Last 3 months',
        'Last 12 months', 'Custom Date Range'];

    constructor(
        public cd: ChangeDetectorRef,
        public filtersS: FiltersService, // for-filters===    
        public settingsRequestS: SettingsRequestService, // for-settings===
        public gameS: GameService,
    ) {
        super(cd);
        this.subscribeToSettings(); // for-settings===
        this.setFilters(); // for-filters===        
    }

    // === SETTINGS ===============================
    subscribeToSettings(): void {
        this.settingsRequestS.settings$.pipe(untilDestroyed(this)).subscribe((res) => {
            console.log(res);
            this.onVariableUpdated({
                date_from: { date: res.from },
                date_to: { date: res.to },
                selected_competition: res.competitions,
                search_text: res.search
            });
            this.cd.detectChanges();
        });
    }

    // === FILTERS ==========================================
    setFilters(): void {
        const search = new ClassFilterInput({
            visible: this.selectedTab.filtersAllowed.some(x => x == "search"),
            typeFilter: 'search'
        });
        const dateRange = new ClassFilterDateRange({
            visible: this.selectedTab.filtersAllowed.some(x => x == "dateRange"),
            typePeriod: 'past',
            arrPeriods: this.customDatePeriodRange,
            period: 'Last month',
            noDelete: true
        });
        const competition = new ClassFilterDrop({
            visible: this.selectedTab.filtersAllowed.some(x => x == "competition"),
            typeFilter: 'competitions',
            arrayForDropdown: this.gameS.arrCompetition$.getValue()
        });
        this.filtersS.setFilters([search, dateRange, competition], true, true);
    }

    onFinishLoadFilters = (value: boolean) => {
        this.filtersReady = value;
    }

    onVariableUpdated = (newVariables: any) => {
        this.variables = {
            ...this.variables,
            ...newVariables
        };
        this.cd.detectChanges();
    }

    onClickItemTab(item: EmbeddableTabbedList): void {
        this.selectedTab = item;
        this.setFilters();
    }
}
