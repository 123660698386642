<!--[svgName]='existChxTestPhone && !mainS.forProd ? (forTestPhone|getChxSvgName):undefined'-->
<!--(svgEmit)='disabled ? null : forTestPhone=!forTestPhone'-->
<!--[svgName]='existChxTestPhone && !mainS.forProd ? (forTestPhone|getChxSvgName):undefined'-->
<field [text]='text' class='wrapPhone'
       matTooltipSVG='This checkbox for testing.'>
  <!--  <ng-container *ngIf='!forTestPhone'>-->
  <!--  {{forTestPhone?"hiddenPhone":""}}-->
  <mat-form-field appearance='outline' class='o-matForm {{"o-matForm--"+bcg}}
                {{"o-matForm--"+({value:value,active:active,disabled:disabled,error:error}|getStateInput)}}'>

    <!--    <input matInput #inputRef [(ngModel)]='value' [placeholder]='text' [ngClass]='bcgLogo' type='tel' phone (ngModelChange)='modelChange($event)'>-->
    <input matInput #inputRef [(ngModel)]='phoneValue' [placeholder]='text|strCase:"capitalize"' [ngClass]='bcg' type='tel' phone
           (ngModelChange)='changeVal.next(dialCode+phoneValue);test()' [disabled]='disabled'>
  </mat-form-field>

  <!--  <error *ngIf='errRequired' text='Phone is required.'></error>-->
  <!--  <error *ngIf='invalid && !errRequired' text='Phone is invalid.'></error>-->
  <error *ngIf='value && !valid' text='Phone is invalid.'></error>
  <!--  </ng-container>-->

  <!--  === forTestPhone ========================-->
  <!--  <ng-container *ngIf='forTestPhone'>-->
  <!--    <input #inputRef [(ngModel)]='phoneValue' (ngModelChange)='changeVal.next(phoneValue!);test()'-->
  <!--           placeholder='This input for testing.' [ngClass]='bcg' type='number' class='o-input'>-->
  <!--  </ng-container>-->

</field>