import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HelperClass } from '@classes/Helper-Classes';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { ForTestService } from '@classes/forTest';
import { ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { MainService } from '@services/main.service';
import { MeService } from '@services/me.service';
import { CustomDatesService } from '@classes/CustomDates';
import { DashboardService } from '@app/dir_group_assignor/dashboard/dashboard.service';
import { DeviceService } from '@services/device.service';
import { FiltersService } from '@components/filters/filters.service';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store/app.state';
import {
  GroupAssignorDashboardService
} from '@app/dir_group_assignor/dashboard/components/dashboard-group-assignor/services/group-assignor-dashboard.service';
import { Router } from '@angular/router';
import { ClassSettingsRequest } from '@models/response-and-request';
import { DashboardSubAssignorActions } from '@app/store/dashboard/actions/dashboard.actions';
import { ClassFilterDateRange, ClassFilterDrop, ClassFilterInput } from '@components/filters/filters';
import { MeTableService } from '@components/_table/me-table.service';
import { ComingSoonMobile } from '@app/dir_group_assignor/dashboard/components/coming-soon-mobile/coming-soon-mobile.component';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import {
  FiltersSelectedIconComponent
} from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { GreetComponent } from '@app/dir_group_assignor/dashboard/components/greet/greet.component';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import { BtnComponent } from '@components/btn/btn.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { GetChxSvgNamePipe } from '@components/__svg_img/svg/pipes/get-chx-svg-name.pipe';

// interface IFormDashboardAdmin {
//   currentViewDropdown: FormControl<string>; // andrei ===
//   gameStatus: FormControl<string>;
//   selfAssigned?: FormControl<boolean>;
//   currentUser?: FormControl<ClassDrop>
// }

@UntilDestroy()
@Component({
  selector: 'app-dashboardAdmin',
  standalone: true,
  imports: [CommonModule, ComingSoonMobile, SpinnerComponent, ReactiveFormsModule, WrapPageDirective, FiltersSelectedIconComponent, GreetComponent, DropdownComponent, FiltersComponent, FiltersSelectedComponent, BtnComponent, SvgComponent, GetChxSvgNamePipe, FormsModule],
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.scss'],
  providers: [FiltersService, MeTableService, SettingsRequestService], // for-filters=== for-table=== for-settings===
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardAdminComponent extends HelperClass implements OnInit { // IForClassForFilters
  // form!: FormGroup<IFormDashboardAdmin>;
  selectedTable: any = 'totalGames';
  commingSoon = true; //After comming soon related content & logic can be removed from UI
  private readonly forTestS = inject(ForTestService);
  tittleName: string = 'Total Games';
  currentUsers: Array<ClassDrop> = [{
    titleCase: '',
    upperCase: ''
  }];
  currentUser: any; // !!! andrei===


  constructor(
    // private formBuilder: UntypedFormBuilder,
    public mainS: MainService,
    public meS: MeService,
    public customDatesS: CustomDatesService,
    public dashboardS: DashboardService,
    public deviceS: DeviceService,
    public filtersS: FiltersService, // for-filters===
    public cd: ChangeDetectorRef,
    private gameS: GameService,
    private settingsRequestS: SettingsRequestService,
    private store: Store<AppState>,
    private subDashboardService: GroupAssignorDashboardService,
    private router: Router,
  ) {
    super(cd);
    // console.log('DashboardAdminComponent :', this.router.url)
    this.setFilters(); // for-filters===
    // this.createForm();
    this.subDashboardService.getMyGroups().pipe(untilDestroyed(this)).subscribe((res) => {
      // console.log('subDashboardService.getMyGroups() :', res)
      if(!res) return;
      const {name} = res[0];
      this.currentUsers[0].titleCase = `Current view as Admin for ${name}`
      this.currentUsers[0].upperCase = `Current view as Admin for ${name}`
      // this.form.controls.currentUser?.setValue(this.currentUsers[0])
      this.cd.detectChanges();
    })
  }

  ngOnInit(): void {
  }

  // === FILTERS ==========================================
  settings: ClassSettingsRequest = new ClassSettingsRequest({ size: 5, from: this.customDatesS.todayMidnightFormatDate }); // for-filters===

  emitSettingRequest(settings: ClassSettingsRequest): void {
    this.settings = { ...this.settings, ...settings };
    this.store.dispatch(DashboardSubAssignorActions.loadAllData({sortParams: {}, settingParams: this.settings}));
    this.cd.detectChanges();
  }

  setFilters(): void {
    const search = new ClassFilterInput({ typeFilter: 'search' });
    const dateRange = new ClassFilterDateRange();
    const competition = new ClassFilterDrop({
      typeFilter: 'competitions',
      arrayForDropdown: this.gameS.arrCompetition$.getValue(),
    });
    this.filtersS.setFilters([search, dateRange, competition], false, false);
  }
}

// === FORM =======================
// private createForm(): void {
//   this.form = this.formBuilder.group({
//     // currentViewDropdown: [],
//     gameStatus: 'ACCEPTED',
//     selfAssigned: null,
//     currentUsers: {
//       tittleCase: '',
//       upperCase: ''
//     }
//   });
// }
// get ctrl(): IFormDashboardAdmin {
//   return this.form?.controls;
// }
