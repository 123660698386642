import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StrCasePipe } from '@pipes/string/str-case.pipe';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { IAssignorFees } from '@app/dir_group_assignor/payments/modelsForPayment';

@Component({
  selector: 'payoutFormat[payoutFormat]',
  standalone: true,
  imports: [CommonModule, StrCasePipe, SvgComponent],
  templateUrl: './payout-format.component.html',
  styleUrls: ['./payout-format.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PayoutFormatComponent {
  @Input() payoutFormat!: 'Notch Pay' | 'Check' | string;
}
