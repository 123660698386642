import { Pipe, PipeTransform } from '@angular/core';
import { getTableEmpty, ITableEmpty } from '@components/_table/meTable';
import { RoutesService } from '@services/routes.service';
import { MeService } from '@services/me.service';
import { DeviceService } from '@services/device.service';

@Pipe({ name: 'getTableEmpty', standalone: true })
export class GetTableEmptyPipe implements PipeTransform {

  constructor(
    private meS: MeService,
    private routesS: RoutesService,
    private deviceS: DeviceService,
  ) {
  }

  transform(search: string | undefined, forTest?: string): ITableEmpty {
    const path = this.routesS.urlWithoutQueryParams!; // example => 'competitions/list/cf2a0321-1e7f-4fb9-959f-731998cc8db'
    const meRole = this.meS.me?.roleCurrent!;
    const result = getTableEmpty(path, meRole, search, this.deviceS.isMobile, forTest);
    return result;
  }

}
