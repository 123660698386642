import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  arrLinkObj_meProfile,
  arrLinkObj_meProfileOfficial,
  arrLinkObj_profileUser,
  IDropItemProfile,
  rout_meProfile,
  rout_meProfileOfficial,
  rout_profileUser,
  TLink_profile_camelCase,
  TypePageProfile,
} from '@app/profile/profile';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { MainService } from '@services/main.service';
import { PopupCodeConfirmComponent } from '@components/__popup-windows/popup-code-confirm/popup-code-confirm.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { DeviceService } from '@services/device.service';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  widthForContactCol_1: string = '280px'; // theme/variables
  widthForContactCol_2: string = '512px'; // theme/variables
  typePageProfile$ = new BehaviorSubject<TypePageProfile | null>(null);
  arrLinkObj$ = new BehaviorSubject<Array<IDropItemProfile>>([]);
  currentSubLink$ = new BehaviorSubject<IDropItemProfile>({});

  constructor(
    private deviceService: DeviceService,
    private mainS: MainService,
    private router: Router,
    // private popupS: PopupService,
    private dialog: MatDialog,
  ) {
  }

  setData(typePageProfile: TypePageProfile, forTest?: string): void {
    this.typePageProfile$.next(typePageProfile);
    if (typePageProfile == 'meProfile') this.arrLinkObj$.next(arrLinkObj_meProfile);
    if (typePageProfile == 'meProfileOfficial') this.arrLinkObj$.next(arrLinkObj_meProfileOfficial);
    if (typePageProfile == 'profileUser') this.arrLinkObj$.next(arrLinkObj_profileUser);
    // if (typePageProfile == 'groupProfile') this.arrLinkObj$.next(arrLinkObj_groupProfile);
    this.checkCurrentDropItem();
    // console.log('setData typePageProfile$ :', typePageProfile, this.typePageProfile$.getValue())
    // console.log('setData currentSubLink$ :', typePageProfile, this.currentSubLink$.getValue())
  }

  navigateProfile(dropItemProfile: IDropItemProfile, typePageProfile: TypePageProfile, id?: string): void {
    // console.log('navigateProfile:', typePageProfile, '  dropItemProfile:', dropItemProfile, '  id:', id)
    const currentRout = this.getCurrentRout(typePageProfile);
    this.currentSubLink$.next(dropItemProfile);
    this.router.navigate([`${currentRout}/${dropItemProfile.upperCase}${id ? '/' + id : ''}`]);
  }

  // !!! id => для rout_profileUser & rout_groupProfile
  navigateByUrl(linkProfile: TLink_profile_camelCase, typePageProfile: TypePageProfile, id?: string): void {
    const currentRout = this.getCurrentRout(typePageProfile);
    const found_dropItem: IDropItemProfile = this.arrLinkObj$.getValue().find((dropItem) => dropItem.upperCase == linkProfile)!;
    this.currentSubLink$.next(found_dropItem);
    this.router.navigate([`${currentRout}/${linkProfile}${id ? '/' + id : ''}`]);
  }

  goToEdit(typePageProfile: TypePageProfile): void {
    if (typePageProfile == 'profileUser') return; // для этих страниц нет редактирования
    const currentRout = this.getCurrentRout(typePageProfile);
    this.router.navigate([`${currentRout}/edit`]);
  }

  getCurrentRout(typePageProfile: TypePageProfile): string { // return 'meProfile' | 'meProfileOfficial' | 'profileUser' | 'groupProfile'
    if (typePageProfile == 'meProfile') return rout_meProfile;
    if (typePageProfile == 'meProfileOfficial') return rout_meProfileOfficial;
    if (typePageProfile == 'profileUser') return rout_profileUser;
    // if (typePageProfile == 'groupProfile') return rout_groupProfile;
    return '';
  }

  checkCurrentDropItem(): void {
    const linkProfile_camelCase = this.router.url.split('/').filter(Boolean)[1] as TLink_profile_camelCase;
    const currentDropItem = this.arrLinkObj$.getValue().find(el => el.upperCase == linkProfile_camelCase)!;
    this.currentSubLink$.next(new ClassDrop(currentDropItem) as IDropItemProfile);
  }

  // === VERIFY EMAIL & PHONE ===================================
  sendCodeTo_email_or_phone(email?: string, phone?: string): Observable<any> {
    const width = this.deviceService.isDesktop ? '408px' : '343px';
    return this.mainS.resendCode((email || phone)!, email ? 'email' : 'phone')
      .pipe(
        switchMap((res) => {
          console.log('sendCodeTo_email_or_phone res :', res);
          const dataPopup: MatDialogConfig = { data: { email, phone }, width: width, maxWidth: width };
          return this.dialog.open(PopupCodeConfirmComponent, dataPopup).afterClosed();
        }),
      );
  }

}
