<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
            } as obj' class='wrapperFieldRow'
     [class.o-flex-column]='obj.mobile && forMobileFlexColumn' [style]='{padding: padding, alignItems: alignItems}' class="{{this.topclass}}">
  <div class='titleBlockFieldRow' [style]='objWidth'>
    <div class='titleBlockFieldRow__blockText'>
      <div *ngIf='text' class='o-{{typeText}}'>{{text}}</div>
      <div class="second-text" *ngIf='text2' class='o-{{typeText2}}'>{{text2}}</div>
    </div>
  </div>
  <ng-content></ng-content>
</div>