import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactItemComponent } from '@app/profile/additional_components/contact-item/contact-item.component';
import { GetAgeByDateOfBirthPipe } from '@pipes/get-age-by-date-of-birth.pipe';
import { GetLocationStringPipe } from '@pipes/location/get-location-string.pipe';
import { DeviceService } from '@services/device.service';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { OtherService } from '@services/other.service';
import { BtnComponent } from '@components/btn/btn.component';
import { ProfileService } from '@app/profile/profile.service';
import { MeProfileOfficialService } from '@app/profile/me-profile-official/me-profile-official.service';
import {
  TitleAndNavigationForProfileComponent,
} from '@app/profile/additional_components/title-and-navigation-for-profile/title-and-navigation-for-profile.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { FieldRowComponent } from '@components/__drop_inputs_matSelect/field-row/field-row.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HelperClass } from '@classes/Helper-Classes';

@Component({
  selector: 'app-me-profile-official-questions',
  standalone: true,
  imports: [CommonModule, ContactItemComponent, GetAgeByDateOfBirthPipe, GetLocationStringPipe, HeadTitleComponent, BtnComponent, TitleAndNavigationForProfileComponent, DropFormCtrlComponent, FieldRowComponent, ReactiveFormsModule],
  templateUrl: './me-profile-official-questions.component.html',
  styleUrls: ['./me-profile-official-questions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeProfileOfficialQuestionsComponent extends HelperClass {
  isEdit = false;

  constructor(
    // public meS: MeService,
    // public router: Router,
    public deviceS: DeviceService,
    public profileS: ProfileService,
    public meProfileOfficialS: MeProfileOfficialService,
    public otherS: OtherService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
  }

  submit(): void {
    // if (this.startRequest()) return;
    // this.form.markAllAsTouched();

    // const formValue = this.form.getRawValue();
    // const user: ClassUser = {
    //   ...formValue,
    // } as ClassUser;

    // user.address = { ...user.address, ...this.otherS.removeEmptyKeysFromObject(address) };

    // console.log('user :', user)
    // console.log('user address :', user.address)
    // this.endRequest()
    // return

    // this.mainS.updateUser(user).toPromise()
    //   .then(() => {
    //     // this.updateInitialValueForm(this.form)
    //     this.router.navigate([rout_meProfile]);
    //   })
    //   .catch(() => {
    //   })
    //   .finally(() => this.endRequest());
  }
}
