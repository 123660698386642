<div *ngIf='{ desktop: (deviceS.isDesktop$|async)!,
    mobile: (deviceS.isMobile$|async)!,
   } as obj' class='wrapperSetStatusForAssign'>
    <headTitle text="Game Notes" svgRight='cross_grey&24' (svgRightEmit)='dialogRef.close()' typeText='text4-blueDark'>
    </headTitle>

    <textarea [readonly]="data.isReadOnly" class="text" [(ngModel)]="data.text"
              placeholder="Enter game notes..."></textarea>

    <div class='characters-left'>
        <span class="characters-left__number">{{ (5000 - (data?.text?.length ?? 0)) | number: '1.0-0' }}</span>
        &nbsp;
        <span> characters left</span>
    </div>

    <btnWrap [swapBtn]='obj.mobile'>
        <btn text='Cancel' bcg='white' (emit)='dialogRef.close()'></btn>
        <btn *ngIf="data.isReadOnly==false" text='Save' bcg='blueDark' (emit)='save()'></btn>
    </btnWrap>

</div>