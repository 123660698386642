import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import Median from 'median-js-bridge';
import { BtnComponent } from '@components/btn/btn.component';
import { AppleSigninData, AppleSigninParams, AuthorizationAppleService } from '@services/authorization.apple.service';
import { MainService } from '@services/main.service';
import { ILoginResponseDto } from '@app/auth/auth_models';
import { urlDashboard } from '@app/app.module';
import { MeService } from '@services/me.service';
import { Router } from '@angular/router';
declare var LOG: any;

type GoogleSigninData = {
  error?: string;
  idToken?: string;
  state?: string;
  type: 'google';
};

type GoogleSigninParams = {
  callback?: (data: GoogleSigninData) => void;
  redirectUri?: string;
  state?: string;
};

@Component({
  selector: 'app-google-authorization',
  standalone: true,
  imports: [CommonModule, BtnComponent],
  templateUrl: './google-authorization.component.html',
  styleUrls: ['./google-authorization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleAuthorizationComponent {
  @Input() typeOperation!: 'signup' | 'logout' | 'signin';
  @Output() appleOurResponse = new EventEmitter<any>();
  readonly isNativeApp = Median.isNativeApp();
  readonly isApple = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  readonly showNativeAppleSing;
  constructor(private httpAuthorization: AuthorizationAppleService, public mainService: MainService, private meS: MeService, private router: Router,) {
    this.showNativeAppleSing = this.isNativeApp && this.isApple;
  }

  googleAuthorizationCallback(data: GoogleSigninData) {
    LOG('googleAuthorizationCallback ');
    LOG(JSON.stringify(data));
    LOG('------------------ googleAuthorizationCallback ------------------');

    data.idToken
    this.mainService.signinWithGoogle({ email: data?.idToken }).toPromise() // !!! email == id_token from google
      .then((res: ILoginResponseDto | undefined) => {
        LOG("res 'googleAuthorization" + JSON.stringify(res));
        this.loginResponce(res);
      })
      .catch((err) => {
        LOG('googleAuthorizationCallback error ' + JSON.stringify(err));
      });
  };

  private async loginResponce(res: ILoginResponseDto | undefined) {
    const loginMessageCode = res?.messageCode;
    LOG(" 'loginMessageCode" + JSON.stringify(res));
    if (loginMessageCode == "successfulLogin") {
      this.mainService.setTokenBeforeLogin({ token: res?.token ?? "" }, urlDashboard);
    } else if (loginMessageCode == "registrationIsNotCompleted") {
      this.meS.setMeUser(res?.user);
      if (res?.user?.roleCurrent == "OFFICIAL") {
        this.router.navigate(['/signupOfficial/'], { queryParams: { role: "OFFICIAL", userRegisteredStatus: res?.user?.userRegisteredStatus, groupId: res?.groupId } });
      } else {
        this.router.navigate(['/signup'], { queryParams: { userRegisteredStatus: res?.user?.userRegisteredStatus } });
      }
    }
  }


  onGoogleAuthorizationClick(): void {
    LOG("click 'googleAuthorization");
    try {
      const appleSigninParams: GoogleSigninParams = {
        callback: this.googleAuthorizationCallback.bind(this),
        redirectUri: undefined,
        state: undefined,
      }
      Median.socialLogin.google.login(appleSigninParams);
    } catch (error) {
      LOG("error 'googleAuthorization " + error);
    }
    LOG("click end 'googleAuthorization");
  }
}
