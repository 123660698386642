import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { GetStateInputPipe } from '@pipes/get-state-input.pipe';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { ErrorComponent } from '@components/__info_text_message_error_warning/error/error.component';
import { TColor } from '@models/ICssStyles';
import { BehaviorSubject } from 'rxjs';
import { CustomDatesService } from '@classes/CustomDates';
import { DateTimeService } from '@components/date-time/date-time.service';
import { GetStylesPipe } from '@pipes/css/get-styles.pipe';

// type ISelectDate = (Date & string) | string | null
type ISelectDate = Date | string

@Component({
  selector: 'selectDate',
  templateUrl: './select-date.component.html',
  styleUrls: ['./select-date.component.scss'],
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, GetStateInputPipe, MatDatepickerModule, MatInputModule, MatIconModule, SvgComponent, ErrorComponent, GetStylesPipe],
  providers: [MatDatepickerModule, DateTimeService],
})
export class SelectDateComponent implements OnChanges {
  @ViewChild('datePicker') datePicker!: MatDatepicker<any>; // было { static: false }

  @Input() formatDate: string = 'YYYY-MM-DD'; // Example format 'YYYY-MM-DDTHH:mm:ss.SSS' || 'YYYY-MM-DD' // return "2022-07-11T12:34:04.000"

  @Input() placeholder: string = '';
  @Input() required = false; // если дату надо обязательно, то передать сюда true

  @Input() bcg: TColor | 'transparent' = 'white'; // background // TColor = 'blueDark' | 'grey' | 'grey_1' | 'white' | 'blue' | 'green' | 'red';

  @Input() w: string | number = ''; // width // если надо ширину задать

  date$ = new BehaviorSubject<ISelectDate>('');

  dateTouched$ = new BehaviorSubject<boolean>(false);

  @Output() emit = new EventEmitter<string>();

  @Input() value?: string | Date;

  constructor(
    // public dateTimeS: DateTimeService,
    private datesS: CustomDatesService,
  ) {
    // this.dateTimeS.reset();
    // if (this.game.date) this.dateTimeS.setDateTime(this.game.date, true);
  }

  test() {
    // console.log('date$ :', this.date$.getValue());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value?.currentValue) { // "7 Jan 2023 2:30 PM CST" || "1970-07-16"
      // this.value = '7 Jan 2023 2:30 PM CST'
      // this.value = '1992-02-05'
      const isLongVariant: boolean = changes.value?.currentValue?.includes('PM') || changes.value?.currentValue?.includes('AM'); // если в таком формате "7 Jan 2023 2:30 PM CST"
      let date = isLongVariant
        ? this.datesS.convertStrFromServerToDate(this.value as string)
        // ? this.datesS.formatDate(this.formatDate, this.datesS.convertStrFromServerToDate(this.value as string))
        : changes.value?.currentValue?.includes('T') ? new Date(this.value!) : new Date(this.value + 'T00:00');
      // if (isLongVariant) date = (date as string)?.includes('T') ? new Date(date!) : new Date(date + 'T00:00')
      this.date$.next(date); // this.date$.next(this.value!);
    }
  }

  valueChangeDate(date: Date | string | null): void { // date == object Thu Dec 08 2022 00:00:00 GMT+0600 (Киргизия)
    if (!date) return;
    this.date$.next(date);
    // this.emit.emit(this.datesS.formatDate(this.formatDate, this.date$.getValue()));
    const resultEmit = this.datesS.formatDate(this.formatDate, this.date$.getValue());
    this.emit.emit(resultEmit);
  }

  get date(): Date | string {
    return this.date$.getValue();
  }

  set date(date: Date | string) {
    this.date$.next(date);
  }

  // вызывать только перед отправкой формы на сервер. Потому что после вызова этой функции сразу подсветятся ошибки на полях
  // проверка на правильность заполнения и все ли обязательные поля заполнены
  public checkErrorDate(): boolean {
    const dateValid = !!this.date$.getValue();
    this.dateTouched$.next(true);
    return dateValid;
  }

  reset(): void {
    // this.dateSub$.next(undefined);
    this.date$.next('');
    this.dateTouched$.next(false);
  }
}
