import { Component } from '@angular/core';
import { TextMessageService } from '@components/message-from-server/components/text-message.service';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
  standalone: true,
  providers: [TextMessageService],
})
export class ErrorMessageComponent {

  constructor(
    public textMessageS: TextMessageService,
  ) {
  }
}
