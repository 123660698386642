import { TColor } from '@models/ICssStyles';

export interface IBlockDashboard {
  // typeOnboarding: TOnboarding_SUB_ASSIGNOR | TOnboarding_OFFICIAL;
  typeOnboarding: keyof IOnboarding;
  step: number;
  title?: string;
  text?: string;
  btn_text?: string;
  active?: boolean;
  required?: boolean;
  completed?: boolean;
  skipped?: boolean;
  action?: () => void;
}

// === FOR SUB_ASSIGNOR ===========================
export const blockDashboard_createGroup: IBlockDashboard = {
  typeOnboarding: 'groupCreated',
  step: 1,
  title: '1. Create Group',
  text: 'Add your group profile information. We\'ll share these details with users you invite to join.',
  btn_text: 'Create Group',
  required: true,
  completed: false,
  skipped: false,
  active: false,
};
export const blockDashboard_setUpCompetition: IBlockDashboard = {
  typeOnboarding: 'competitionCreated',
  step: 2,
  title: '2. Set up a Competition',
  text: 'Please create a competition to get started and redeem your 20 free credits.',
  btn_text: 'Set up a competition',
  completed: false,
  skipped: false,
  required: false,
  active: false,
};
export const blockDashboard_importGames: IBlockDashboard = {
  typeOnboarding: 'gamesCreated',
  step: 3,
  title: '3. Import Games',
  text: 'Almost there! Import a game schedule, or you can choose to skip this and do it later.',
  btn_text: 'Import Games',
  completed: false,
  skipped: false,
  required: false,
  active: false,
};
export const blockDashboard_inviteOfficials: IBlockDashboard = {
  typeOnboarding: 'officialsInvited',
  step: 4,
  title: '4. Invite Officials',
  text: 'Add officials to your group. Alternatively, add any Sub-Assignors who will assign specific competitions from within your group.',
  btn_text: 'Invite Officials',
  completed: false,
  skipped: false,
  required: false,
  active: false,
};
export const blockDashboard_assignOfficialsToGames: IBlockDashboard = {
  typeOnboarding: 'officialsAssigned',
  step: 5,
  title: '5. Assign Officials to Games',
  text: 'Woohoo! Now it\'s time to start assigning games to officials who have joined your group! Have questions or need help? Chat with us directly from your dashboard. We\'re so glad you\'re here!',
  btn_text: 'Assign Officials',
  completed: false,
  skipped: false,
  required: false,
  active: false,
};
export const lengthArrBlockDashboard_subAssignor = 5;

// === FOR OFFICIAL ===========================
export const blockDashboard_availability: IBlockDashboard = {
  typeOnboarding: 'availability',
  step: 1,
  title: '1. Availability',
  text: 'Set your weekly availability and block out any dates otherwise you will be marked as unavailable to your assignor.',
  btn_text: 'Set Availability',
  required: false,
  completed: false,
  skipped: false,
  active: false,
};
export const blockDashboard_payouts: IBlockDashboard = {
  typeOnboarding: 'payouts',
  step: 2,
  title: '2. Payouts',
  text: 'Competitions that use Notch Pay mean we\'ll send your money to you as quickly as the same day! Choose how you want to get paid.',
  btn_text: 'Add a Payout Method',
  completed: false,
  skipped: false,
  required: false,
  active: false,
};
export const lengthArrBlockDashboard_official = 2;

export type TOnboarding_SUB_ASSIGNOR = 'competitionCreated' | 'gamesCreated' | 'groupCreated' | 'officialsInvited' | 'officialsAssigned';
export type TOnboarding_OFFICIAL = 'availability' | 'payouts';

export interface IOnboarding {
  competitionCreated?: boolean;
  gamesCreated?: boolean;
  groupCreated?: boolean;
  officialsInvited?: boolean;
  officialsAssigned?: boolean;

  availability?: boolean; // for official
  payouts?: boolean; // for official
}

// === Константы ==================================
export const subtitleDashboard_forOfficial = 'Welcome to Notch! Kickstart your new experience as an official with our checklist to help you get started and we\'ll do the rest';
export const subtitleDashboard_forAdmin = 'Kickstart your new experience with our checklist to help you get started and we\'ll do the rest.';

// export const gamesHeaderTitles = {
//   accepted: {
//     title: 'You do not have any Accepted Games',
//     description: 'We recommend you check your availability settings and go to your Games section to see if there <br> are any open assignments.',
//   },
//   unaccepted: {
//     title: 'You do not have any Games to Accept',
//     description: 'We recommend you check your availability settings and go to your Games section to see if there <br> are any open assignments.',
//   },
//   unacceptedselfassigned: {
//     title: 'You do not have any Games Requested',
//     description: 'We recommend you check your availability settings and go to your Games section to see if there <br> are any open assignments.',
//   },
// };

export const arrForColumn: Array<{ title: string, value: string | number, id: string; }> = [
  { title: 'Upcoming Games', value: 12, id: 'upcomingGames' },
  { title: 'Pending Assignments', value: 3, id: 'pendingAssignments' },
  { title: 'Pending Response', value: 3, id: 'pendingResponse' },
];
export const arrForLine1: Array<{ title: string, value: string | number, id: string; }> = [
  { title: 'Official Payments', value: 800, id: 'officialPayments' },
  { title: 'Assignor Fees', value: 250, id: '' },
  { title: 'Total Payments', value: 1250, id: '' },
  { title: 'Notch Pay Balance', value: 2250, id: '' },
];
export const arrForLine2: Array<{ title: string, value: string | number, id: string; }> = [
  { title: 'Total Games', value: 200, id: 'totalGames' },
  { title: 'Pending Assignments', value: 40, id: '' },
  { title: 'Declined Assignments', value: 23, id: '' },
  { title: 'Unassigned Roles', value: 8, id: '' },
];

export const attentions: Array<{
  innerHTML: string;
  id: number;
  buttons: Array<{
    text: string;
    type: TColor;
  }>;
}> = [
  {
    innerHTML: '<b>New Assignment:</b> You’ve been assigned the following game by Monika Svilpe in EDINA SOCCER CLUB 2011 GIRLS GREEN:</br></br>Fri, Jan 6, 2023 - 7:45 PM</br></br>#1056478 - United FC Rush U14 Girls Select vs. Edina Soccer Club 2011 Girls Green - U13 - M - Regional',
    id: 1,
    buttons: [
      {
        text: 'Decline',
        type: 'red',
      },
      {
        text: 'Accept',
        type: 'blueDark',
      },
    ],
  },
  {
    innerHTML: 'New Group Invitation: You’ve been invited to join TCSL Winter League by Monika Svilpe.',
    id: 2,
    buttons: [
      {
        text: 'Ignore Invitation',
        type: 'white',
      },
      {
        text: 'Join Group',
        type: 'blueDark',
      },
    ],
  },
  {
    innerHTML: '<b>Need Attention:</b> Fri, Jan 6, 2023 - 7:45 PM</br></br>#1056478 - United FC Rush U14 Girls Select vs. Edina Soccer Club 2011 Girls Green - U13 - M - Regional</br></br>A post game report is now ready to be submitted to your assignor.',
    id: 3,
    buttons: [
      {
        text: 'Submit Report',
        type: 'blueDark',
      },
    ],
  },
];
