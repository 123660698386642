import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass, TForPagination } from '@classes/Helper-Classes';
import { ClassSettingsRequest } from '@models/response-and-request';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ISortBy } from '@components/sortBy/sortBy';
import { BtnComponent } from '@components/btn/btn.component';
import { GetFirstLetterPipe } from '@pipes/get-first-letter.pipe';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { MatMenuModule } from '@angular/material/menu';
import { PaginationComponent } from '@components/pagination/pagination.component';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { StatusComponent } from '@components/status/status.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  tableHeaderOfficialsFees,
} from '@app/dir_group_assignor/payments/components/payments-officials-fees/payments-officials-fees.component';
import { PaymentService } from '@app/dir_group_assignor/payments/payment.service';
import { OtherService } from '@services/other.service';
import { RoundBalancePipe } from '@app/dir_group_assignor/payments/pipes/round-balance.pipe';
import { GetChxSvgNamePipe } from '@components/__svg_img/svg/pipes/get-chx-svg-name.pipe';
import { TransferModel } from '@models/transfer.model';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import { FiltersSelectedIconComponent } from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { LinkPageComponent } from '@components/link-page/link-page.component';
import { DeviceService } from '@services/device.service';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-payments-officials-fees-all',
  standalone: true,
  imports: [CommonModule, BtnComponent, GetFirstLetterPipe, HeadTitleComponent, InputCtrlComponent, MatMenuModule, PaginationComponent, SpinnerComponent, StatusComponent, SvgComponent, FormsModule, RoundBalancePipe, GetChxSvgNamePipe, ReactiveFormsModule, DropdownComponent, FiltersComponent, FiltersSelectedComponent, FiltersSelectedIconComponent, LinkPageComponent, WrapPageDirective],
  templateUrl: './payments-officials-fees-all.component.html',
  styleUrls: ['./payments-officials-fees-all.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class PaymentsOfficialsFeesAllComponent extends HelperClass implements OnInit {
  readonly tableHeader = tableHeaderOfficialsFees;
  settings: ClassSettingsRequest = new ClassSettingsRequest();

  constructor(
    public payS: PaymentService,
    public router: Router,
    public otherS: OtherService,
    public deviceS: DeviceService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    // this.payS.getLinkPaymentsCamelCase(this.router.url);
    // this.createForm();
    // this.subscribeToForm();
    // this.ctrl.paginationAndSort.patchValue({ page: 1, size: 25 });
  }

  ngOnInit() {
    // this.getDataForHTML();
    this.subscribeToSearch();
  }

  getDataForHTML(): void {
    // if (!this.settings?.competitions) return; // !!! здесь обязательно надо дождаться когда будет competition
    forkJoin([this.payS.getTransfers(this.settings)]).pipe(untilDestroyed(this))
      .subscribe((res) => {
        // console.log('getDataForHTML :', res);
      });
  }

  // === SEARCH =======================
  subscribeToSearch(): void {
    this.subscribeToSearchFromHelperClass().pipe(untilDestroyed(this)).subscribe((searchValue: string) => {
      if (!searchValue) delete this.settings.search;
      this.settings.search = searchValue;
      this.getDataForHTML();
    });
  };

  // === CHECKBOX SELECT ==================================
  selectItem(item: TransferModel, event: any): void {
    event.stopPropagation();
    this.selectItemFromHelperClass(item, this.payS.transfers$.getValue());
  }

  selectAll(): void {
    this.payS.transfers$.next(this.selectAllFromHelperClass(this.payS.transfers$.getValue()));
  }

  // === PAGINATION & SORTING =====================
  paginationMethod(type: TForPagination, size?: number, page?: number, sortBy?: ISortBy): void {
    this.settings = { ...this.settings, ...this.paginationMethodHelperClass(type, this.settings, size, page, sortBy) };
    this.getDataForHTML();
  }

}

// === FILTERS Search только для транзакций. Фильтры для всего ==========================================
// settings: ClassSettingsRequest = {}; // for-filters===
// setFilters(): void {
//   const search = new ClassFilterInput({ typeFilter: 'search' });
//   this.filtersS.setFilters([search]);
// }
// emitSettingRequest(settings: ClassSettingsRequest): void {
//   this.settings = { ...this.settings, ...settings };
//   this.getDataForHTML();
// }

