import {
  ClassCompetition,
  ClassCompetitionAgeGroup,
  ClassCompetitionLevel,
  ClassCompetitionLocation,
  ClassCompetitionTeam,
  TGameTypeDrop,
  TGameTypeUpperCase,
  TGenderDrop,
  TGenderUpperCase,
} from '@app/dir_group_assignor/competitions/ClassCompetition';
import { ClassUser } from '@models/user';
import { ClassReport } from '@app/dir_group_assignor/reports/report';
import {
  ClassDrop,
  getDropFromString,
  preparePropertyFromDropForSendToServer,
} from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { IForTable } from '@components/_table/meTable';
import { EValuesForAngularPipe } from '@classes/CustomDates';
import { UtilsService } from '@services/utils.service';
import { ClassAdjustmentStatus } from '@app/group-profile/components/group-profile-adjustment-statuses/adjustmentStatus';

// === Status Game =========================
// 'PENDING' // For official. Такого статуса нет, но нужно показывать PENDING если в GameOfficial selfAssigned==true & status=='UNACCEPTED'
// 'CANCELLED';  // game is not available to view by officials /
export type TStatusGame = 'ACTIVE' | 'CLOSED' | 'CANCELLED';
export const arrStatusGameString: Array<TStatusGame> = ['ACTIVE', 'CLOSED', 'CANCELLED'];
export const arrStatusGameString_withoutCanceled: Array<TStatusGame> = ['ACTIVE', 'CLOSED']; // Array<Exclude<TStatusGame, 'CANCELLED'>>

// === Assign Status ============================
export type TStatusAssignForSendToServer = 'UNASSIGNED' | 'UNACCEPTED' | 'ACCEPTED' | 'DECLINED' | 'UNPUBLISHED';
export type TStatusAssign = 'UNASSIGNED' | 'ASSIGNED / UNACCEPTED' | 'ASSIGNED / ACCEPTED'
  | 'ASSIGNED / DECLINED' | 'ASSIGNED / UNPUBLISHED';
export type TStatusAssignLower = 'Unassigned' | 'Assigned / Unaccepted'
  | 'Assigned / Accepted' | 'Assigned / Declined' | 'Assigned / Unpublished';
export const dropStatusAssign_ACCEPTED: IDropStatusAssign = { titleCase: 'Assigned / Accepted', upperCase: 'ACCEPTED' };
export const dropStatusAssign_UNACCEPTED: IDropStatusAssign = { titleCase: 'Assigned / Unaccepted', upperCase: 'UNACCEPTED' };
export const dropStatusAssign_DECLINED: IDropStatusAssign = { titleCase: 'Assigned / Declined', upperCase: 'DECLINED' };
export const dropStatusAssign_UNPUBLISHED: IDropStatusAssign = { titleCase: 'Assigned / Unpublished', upperCase: 'UNPUBLISHED' };
export const dropStatusAssign_UNASSIGNED: IDropStatusAssign = { titleCase: 'Unassigned', upperCase: 'UNASSIGNED' };
export type IDropStatusAssign = ClassDrop & { titleCase: TStatusAssignLower, upperCase: TStatusAssignForSendToServer };
export const arrDropStatusAssign: Array<IDropStatusAssign> = [
  dropStatusAssign_ACCEPTED, dropStatusAssign_UNACCEPTED, dropStatusAssign_DECLINED, dropStatusAssign_UNPUBLISHED, dropStatusAssign_UNASSIGNED,
];

// !!! assignStatuses == ACCEPTED,DECLINED,UNACCEPTED
export function getArrDropStatusAssignFromString(assignStatuses?: string): Array<IDropStatusAssign> {
  if (!assignStatuses) return [];
  const arrAssignStatuses = assignStatuses.split(',') as Array<TStatusAssignForSendToServer>;
  return getArrDropStatusAssign(arrAssignStatuses);
}

export function getArrDropStatusAssign(arrAssignStatuses: Array<TStatusAssignForSendToServer>): Array<IDropStatusAssign> {
  const result: Array<IDropStatusAssign> = [];
  arrAssignStatuses?.forEach(el => {
    result.push(getDropStatusAssign(el));
  });
  return result;
}

export function getDropStatusAssign(assignStatus?: TStatusAssignForSendToServer | TStatusAssign | TStatusAssignLower): IDropStatusAssign {
  if (assignStatus?.toUpperCase().includes('ACCEPTED') && !assignStatus?.toUpperCase().includes('UNACCEPTED')) return dropStatusAssign_ACCEPTED;
  if (assignStatus?.toUpperCase().includes('UNACCEPTED')) return dropStatusAssign_UNACCEPTED;
  if (assignStatus?.toUpperCase().includes('DECLINED')) return dropStatusAssign_DECLINED;
  if (assignStatus?.toUpperCase().includes('UNPUBLISHED')) return dropStatusAssign_UNPUBLISHED;
  if (assignStatus?.toUpperCase().includes('UNASSIGNED')) return dropStatusAssign_UNASSIGNED;
  return dropStatusAssign_UNASSIGNED;
}

export function getStatusAssign(assignStatus: TStatusAssignForSendToServer | TStatusAssign | TStatusAssignLower): TStatusAssignForSendToServer {
  if (assignStatus.toUpperCase().includes('ACCEPTED')) return 'ACCEPTED';
  if (assignStatus.toUpperCase().includes('UNACCEPTED')) return 'UNACCEPTED';
  if (assignStatus.toUpperCase().includes('DECLINED')) return 'DECLINED';
  if (assignStatus.toUpperCase().includes('UNPUBLISHED')) return 'UNPUBLISHED';
  if (assignStatus.toUpperCase().includes('UNASSIGNED')) return 'UNASSIGNED';
  console.error('getStatusAssign() :', assignStatus);
  return 'UNASSIGNED';
}

export enum CertDropDown {
  US_SOCCER_LEARNING_CENTRE = 'United States Soccer Federation(USSF)',
}

export const arrCertifications: Array<string> = [
  'Please Select',
  'United States Soccer Federation (USSF)',
  'USA Volleyball',
  'USA Hockey',
];

export class ClassCertDropDown extends ClassDrop {
  titleCase?: TCertDropDown_forHtml;
  upperCase?: TCertDropDown;

  constructor(certDropDown?: TCertDropDown) {
    super({
      titleCase: certDropDown ? CertDropDown[certDropDown] : '',
      upperCase: certDropDown,
    });
  }
}

export class ClassSignupCertDropDown extends ClassDrop {
  titleCase?: string;
  upperCase?: string;

  constructor(arrCertifications?: string) {
    super({
      titleCase: arrCertifications || '',
      upperCase: arrCertifications ? arrCertifications.toUpperCase() : '',
    });
  }
}

export type TCertDropDown_forHtml = keyof typeof CertDropDown;
export type TCertDropDown = keyof typeof CertDropDown;

export const arrCertDropDownString: Array<TCertDropDown> = Object.keys(CertDropDown) as TCertDropDown[];
export const arrCertDropDownDrop: Array<ClassCertDropDown> = arrCertDropDownString.map(el => new ClassCertDropDown(el));
export const arrSignupCertDropDown: Array<ClassSignupCertDropDown> = arrCertifications.map(el => new ClassSignupCertDropDown(el));

// === GAME =============================================
export class ClassGame implements IForTable {
  id?: string;
  competition?: ClassCompetition;
  // competitionId?: string; // id оригинального компетишна 5.02.24 Тимур сказал удалить
  gameNumber?: string;
  location?: ClassCompetitionLocation; // ILocation; // { id?: string; };
  currentCourtName?: string; // for create game  => это в объекте локации (game.location)
  ageGroup?: ClassCompetitionAgeGroup; // { id?: string; };
  levels?: ClassCompetitionLevel; // { id?: string; };
  homeTeam?: ClassCompetitionTeam; // { id?: string; };
  awayTeam?: ClassCompetitionTeam; // { id?: string; };
  duration?: number | string;
  assignor?: ClassUser; //    ClassUser  ClassCompetitionUser ; // { id?: string; };
  createdBy?: string;

  date?: string;
  timezone_abbrev?: string; // CDT с сервера приходит дата "17 Nov 2024 1:30 PM EST". Из неё достаю timezone_abbrev
  date_EE_MMMd_yyy?: string; // Fri, Jan 6, 2023
  // date_shortTime?: string; // 7:45 PM // h:mm a
  date_shortTime_timezone?: string; // 7:45 PM EST // h:mm a + this.otherS.getTimezoneFromDateFromServer(el.date)
  // date_formatted?: string;
  dateReal?: Date | null;
  dateDMY?: string | null;

  gameStatus?: TStatusGame;
  officialAssignments?: boolean;

  gender?: TGenderUpperCase; // для отправки на сервер. При отправке на сервер достаётся из genderDrop.upperCase
  genderDrop?: TGenderDrop; // для дропдауна => при получении с сервера это значение назначается
  gameDescription?: string;
  // adjustmentStatus?: string; // Тимур убрал 16.05.24
  // gameAdjustmentStatus?: ClassAdjustmentStatus_forGame; // {adjustmentStatusId?: string;cancelReason?: string;}
  gameAdjustmentStatus?: ClassAdjustmentStatus; // {adjustmentStatusId?: string;cancelReason?: string;}
  // cancelReason?: string; // Тимур убрал 16.05.24

  gameOfficials?: Array<ClassGameOfficial>;
  isPendingGoForOfficial?: boolean; // !!! for official => getMeGoFromGame|isPendingGoForOfficial

  // report?: ClassReport;
  gameReport?: ClassReport;

  gameType?: TGameTypeUpperCase; // для отправки на сервер. При отправке на сервер достаётся из IDrop.upperCase
  gameTypeDrop?: TGameTypeDrop; // для дропдауна => при получении с сервера это значение назначается

  isSelect?: boolean; // for checkbox
  // fieldOrCourt?: string; // for create game
  // currentCourtName?: string; // for create game

  ageGenderLevel?: string; // this.otherS.getAgeGenderLevel(game, 'ClassGame')
  locNameCourtName?: string; // this.otherS.getLocNameCourtName(game)
  cityState?: string; // this.otherS.getLocationString(game.location?.address, ['city', 'state'])
  matTooltipForLocation?: string; // this.otherS.getMatTooltipForLocation(game)
  text_assignGO_outOf_goLength?: string; // for admin => `${this.otherS.getAssignGO(game)?.length} out of ${game.gameOfficials?.length}`
  text_noAssignGO_outOf_goLength?: string; // for official => `${this.otherS.getNoAssignGO(game)?.length} out of ${game.gameOfficials?.length}`
  amountFreeRoles?: number; // !!! количество свободных(НЕзаасайненых ролей)

  isShowAllGO?: boolean; // по умоляанию показывать GO 4 штуки на странице Assign

  // !!! fromServer => после ответа сервера
  constructor(game?: ClassGame, user?: ClassUser) {
    if (!game) return {};
    Object.entries(game)!.forEach((el) => {
      const key = el[0] as keyof ClassGame;
      this[key] = el[1];
    });

    if (game.date) {
      const timezone_abbrev = UtilsService.getTimezone_abbrev(game.date);
      if (timezone_abbrev) {
        this.timezone_abbrev = timezone_abbrev;
        this.date_EE_MMMd_yyy = UtilsService.datePipe_transform(game.date)!;
        this.date_shortTime_timezone = UtilsService.datePipe_transform(game.date, EValuesForAngularPipe['shortTime']) + ` ${timezone_abbrev}`;
      }
    }

    if (user?.roleCurrent === 'OFFICIAL') {
      const meGo = UtilsService.getMeGoFromGame(user.id!, game);
      this.isPendingGoForOfficial = UtilsService.isPendingGoForOfficial(meGo);
    }
    this.ageGenderLevel = UtilsService.getAgeGenderLevel(game, 'ClassGame');
    this.locNameCourtName = UtilsService.getLocNameCourtName(game);
    this.cityState = UtilsService.getLocationString(game.location?.address, ['city', 'state']);
    this.matTooltipForLocation = UtilsService.getMatTooltipForLocation(game);
    this.text_assignGO_outOf_goLength = `${UtilsService.getAssignGO(game)?.length} out of ${game.gameOfficials?.length}`; // for admin
    this.text_noAssignGO_outOf_goLength = `${UtilsService.getNoAssignGO(game)?.length} out of ${game.gameOfficials?.length}`; // for official
    this.amountFreeRoles = UtilsService.getAmountFreeRoles(game);

    // === FOR DROPDOWN ============================================
    this.gameOfficials = game.gameOfficials?.map(el => new ClassGameOfficial(el)) || [];
    this.competition = new ClassCompetition(game.competition);
    this.location = new ClassCompetitionLocation(game.location);
    this.ageGroup = new ClassCompetitionAgeGroup(game.ageGroup);
    this.levels = new ClassCompetitionLevel(game.levels);
    this.homeTeam = new ClassCompetitionTeam(game.homeTeam);
    this.awayTeam = new ClassCompetitionTeam(game.awayTeam);
    this.genderDrop = getDropFromString(game.gender!, 'ClassGame game.gender') as TGenderDrop; // для дропдауна => при получении с сервера это значение назначается
    this.gameTypeDrop = getDropFromString(game.gameType!, 'ClassGame game.gameType') as TGameTypeDrop; // для дропдауна => при получении с сервера это значение назначается
    if (game.gameAdjustmentStatus) this.gameAdjustmentStatus = new ClassAdjustmentStatus(game.gameAdjustmentStatus);
    // for test
    // if (!game.gameAdjustmentStatus) {
    //   this.gameAdjustmentStatus = new ClassAdjustmentStatus({
    //     active: true,
    //     assignorFee: 9,
    //     id: "782425d1-717d-4de0-9c17-85739f9d90ca",
    //     inUse: true,
    //     name: "w Adj  Status 2",
    //     officialPay: 99,
    //
    //     adjustmentStatusId : 'TEST adjustmentStatusId',
    //     adjustmentStatusName: 'TEST adjustmentStatusName',
    //     cancelDate: '10-07-2024',
    //     cancelReason: 'TEST cancelReason',
    //     // cancelReason: '',
    //   });
    // }

    // === other =======================
    if (game.duration) this.duration = +game.duration;

    return { ...game, ...this };
  }

  // !!! перед отправкой на сервер доставать из IDrop.upperCase и записывать в соответствующий property
  // !!! И удалять IDrop, т.к. на сервер они не доллжны отправляться
  // !!! Все property которые в конце названия имеют "Drop" нужно в этом методе обрабатывать
  static preparePropertyFromDropForSendToServer(obj?: ClassGame): ClassGame {
    if (!obj) return {};
    const objForSendToServer: ClassGame = obj;

    // !!! prepare Property Without Drop In Name
    // !!! если в конструкторе текущего класса вызывал создание другого класса, то здесь надо вызвать preparePropertyFromDropForSendToServer
    objForSendToServer.gameOfficials = obj.gameOfficials?.map(el => ClassGameOfficial.preparePropertyFromDropForSendToServer(el));
    objForSendToServer.competition = ClassCompetition.preparePropertyFromDropForSendToServer(obj.competition);
    objForSendToServer.location = ClassCompetitionLocation.preparePropertyFromDropForSendToServer(obj.location);
    objForSendToServer.ageGroup = ClassCompetitionAgeGroup.preparePropertyFromDropForSendToServer(obj.ageGroup);
    objForSendToServer.levels = ClassCompetitionLevel.preparePropertyFromDropForSendToServer(obj.levels);
    objForSendToServer.homeTeam = ClassCompetitionTeam.preparePropertyFromDropForSendToServer(obj.homeTeam);
    objForSendToServer.awayTeam = ClassCompetitionTeam.preparePropertyFromDropForSendToServer(obj.awayTeam);

    if (objForSendToServer.gameAdjustmentStatus) objForSendToServer.gameAdjustmentStatus = ClassAdjustmentStatus.preparePropertyFromDropForSendToServer(obj?.gameAdjustmentStatus);

    return preparePropertyFromDropForSendToServer(objForSendToServer);
  }
}

// !!! только эти проперти нужно отправлять на сервер при cancelGame
export type IClassGame_forCancelGameForSendToServer = Pick<ClassGame, 'gameAdjustmentStatus' | 'gameStatus' | 'id'>;

// === GameOfficial ===========================
export type TStatusGameOfficial = TStatusAssignForSendToServer | TStatusAssign;

export class ClassGameOfficial {
  id?: string;
  gameId?: string;
  officialFee?: number; // from payScales
  officialPositionName?: string; // ClassCompetitionOfficialLabelsItem.label
  officialPositionNumber?: number; // ClassCompetitionOfficialLabelsItem.indexNumber
  official?: ClassUser; // заасайненный судья на данную роль-GameOfficial
  // official_isSaveInServer?: boolean; // для страницы Assign. Там нужно понимать сохранен ли уже на сервере заасайненный судья ИЛИ пока что только в текущем массиве есть изменения и ещё не было отправки на сервер. Это нужно чтобы понимать 1. Set status можно ли сделать (т.к. только для сохраненой роли можно). 2. Удаление роли с сервера или из роли здесь локально без отправки не сервер
  officialId_savedInServer?: string; // для страницы Assign. Там нужно понимать сохранен ли уже на сервере заасайненный судья ИЛИ пока что только в текущем массиве есть изменения и ещё не было отправки на сервер. Это нужно чтобы понимать 1. Set status можно ли сделать (т.к. только для сохраненой роли можно). 2. Удаление роли с сервера или из роли здесь локально без отправки не сервер
  status?: TStatusAssignForSendToServer;
  statusAssignDrop?: IDropStatusAssign; // andrei переименовать в statusDrop
  selfAssigned?: boolean;

  isSelect?: boolean;

  constructor(gameOfficial: ClassGameOfficial) {
    if (!gameOfficial) return {};
    Object.entries(gameOfficial)?.forEach((el) => {
      const key = el[0] as keyof ClassGameOfficial;
      this[key] = el[1];
    });

    this.statusAssignDrop = getDropStatusAssign(gameOfficial.status!);
    // !!! чтобы устанавливать только после ответа сервера.
    // if (gameOfficial.official && typeof gameOfficial.official_isSaveInServer !== 'boolean') this.official_isSaveInServer = true;
    if (gameOfficial.official && typeof gameOfficial.officialId_savedInServer !== 'string') this.officialId_savedInServer = gameOfficial.official.id!;
    return { ...gameOfficial, ...this };
  }

  // !!! перед отправкой на сервер доставать из IDrop.upperCase и записывать в соответствующий property
  // !!! И удалять IDrop, т.к. на сервер они не доллжны отправляться
  // !!! Все property которые в конце названия имеют "Drop" нужно в этом методе обрабатывать
  static preparePropertyFromDropForSendToServer(obj?: ClassGameOfficial): ClassGameOfficial {
    if (!obj) return {};
    const objForSendToServer: ClassGameOfficial = obj;

    // !!! если имя property после replace('Drop','') не совпадает c нужным для отправки. Например в этом классе statusAssignDrop => status
    if (objForSendToServer?.statusAssignDrop?.upperCase) {
      objForSendToServer.status = objForSendToServer.statusAssignDrop.upperCase;
      delete objForSendToServer.statusAssignDrop;
    }
    // if (typeof objForSendToServer.official_isSaveInServer === 'boolean') delete objForSendToServer.official_isSaveInServer;
    delete objForSendToServer.officialId_savedInServer;
    return preparePropertyFromDropForSendToServer(objForSendToServer);
  }
}

export interface ISendObjForGetGameOfficialsForCreateGame {
  competition?: { id: string; };
  ageGroup?: { id: string; };
  levels?: { id: string; };
  gameType?: TGameTypeUpperCase;
}

export interface IOfficialsAssign {
  officialsAssign?: Array<IOfficialsAssignItem>;
  publish?: boolean;
  isReport?: boolean;
}

export interface IOfficialsAssignItem {
  gameOfficialId?: string;
  officialId?: string;
}

export type IForApiMethod_updateOfficials = Pick<ClassGameOfficial, 'id' | 'status'>;
