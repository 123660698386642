import { Injectable } from '@angular/core';

@Injectable()
export class TextMessageService {

  constructor() {
  }

  redirect() {
    if (window?.top?.location) window.top.location.href = '/payments/payments-in';
  }
}
