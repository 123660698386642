import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { SelectDateComponent } from '@components/date-time/select-date/select-date.component';
import { SelectTimeComponent } from '@components/date-time/select-time/select-time.component';
import { ErrorComponent } from '@components/__info_text_message_error_warning/error/error.component';
import { GetStateInputPipe } from '@pipes/get-state-input.pipe';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { TColor } from '@models/ICssStyles';
import { BehaviorSubject, Observable } from 'rxjs';
import { DateTimeService, ITime, THours, TMeridiem, TMinutes } from '@components/date-time/date-time.service';
import { CustomDatesService } from '@classes/CustomDates';
import { DisableAutofillDirective } from '@directives/diable-autofill.directive';
import { NgxMaterialTimepickerComponent, NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelperClass } from '@classes/Helper-Classes';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { PopupService } from '@services/popup.service';
import { PopupTimeComponent } from '@components/popup-time/popup-time.component';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'dateTime',
  standalone: true,
  imports: [CommonModule, FieldComponent, SelectDateComponent, SelectTimeComponent, ErrorComponent, GetStateInputPipe, MatDatepickerModule,
    MatFormFieldModule, MatIconModule, MatInputModule, SvgComponent, DisableAutofillDirective, ReactiveFormsModule, FormsModule, InputCtrlComponent, NgxMaterialTimepickerModule],
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss'],
  providers: [MatDatepickerModule, DateTimeService,
    // {
    // provide: MAT_SELECT_CONFIG,
    // provide: MAT_MENU_DEFAULT_OPTIONS,
    // provide: MAT_,
    // useValue: { overlayPanelClass: 'forOverlayPanelDateTime' },
    // },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimeComponent extends HelperClass implements OnChanges, OnInit {
  @ViewChild('datePicker', { static: false }) datePicker!: MatDatepicker<any>;

  @Input() valueStrFromServer?: string; // string 31 Jul 2023 5:26 AM CDT

  private dateSub$ = new BehaviorSubject<Date | undefined>(undefined);
  date$: Observable<Date | undefined> = this.dateSub$.asObservable();
  // date$: Observable<string | undefined> = this.dateSub$.asObservable();

  @Input() formatDate: string = 'YYYY-MM-DD'; // Example format 'YYYY-MM-DDTHH:mm:ss.SSS' || 'YYYY-MM-DD' // return "2022-07-11T12:34:04.000"

  // @Input() placeholder: string = '';
  @Input() required = false; // если дату надо обязательно, то передать сюда true

  @Input() bcg: TColor | 'transparent' = 'white'; // background // TColor = 'blueDark' | 'grey' | 'grey_1' | 'white' | 'blue' | 'green' | 'red';

  dateTouched$ = new BehaviorSubject<boolean>(false);

  // === TIME =======================================
  @ViewChild('inputRef') inputRef?: ElementRef;
  @ViewChild('timePicker') timePicker?: NgxMaterialTimepickerComponent;

  // @Input() required = false;
  timeValue: string = ''; // string 2:20 AM
  timeObj?: ITime;
  time_24_format: string = ''; // string 14:20


  dateValid: boolean = false;
  dateForSendToServer?: string;
  @Output() emit = new EventEmitter<string>();

  @Input() errorClass = false; // если надо принудительно задать класс error

  constructor(
    public dateTimeS: DateTimeService,
    private datesS: CustomDatesService,
    private customDatesS: CustomDatesService,
    private popupS: PopupService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    // this.dateTimeS.reset();
    // if (this.game.date) this.dateTimeS.setDateTime(this.game.date, true);
  }

  test(www?: any) {
    // console.log('TEST timeValue :', www, this.timeValue);
    // console.log('errorClass :', this.errorClass)
    // console.log('errorClass && !timeValue :', this.errorClass && !this.timeValue)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.valueStrFromServer?.currentValue) {
      console.log('valueStrFromServer :', this.valueStrFromServer)
      this.date = this.customDatesS.convertStrFromServerToDate(this.valueStrFromServer!);
      this.timeObj = this.customDatesS.getTimeFromDate(this.date);
      this.timeValue = `${this.timeObj.hourValue}:${this.timeObj.minuteValue} ${this.timeObj.meridiem}`;
      this.convertTimeStringToTimeObj();
      this.getDateForSendToServer();
    }
  }

  ngOnInit() {
    this.cssClassTime = (this.errorClass && !this.timeValue) ? 'error' : 'filled';
    // console.log('cssClassTime :', this.cssClassTime)
    this.cd.detectChanges();
    // this.cd.markForCheck()
  }

  valueChangeDate(date: Date): void { // date == object Thu Dec 08 2022 00:00:00 GMT+0600 (Киргизия)
    // console.log('valueChangeDate :', date)
    this.dateSub$.next(date);
    // console.log('dateSub$ :', this.dateSub$.getValue())
    this.emit.emit(this.datesS.formatDate(this.formatDate, this.date));
    this.convertTimeStringToTimeObj();
    this.getDateForSendToServer();
  }

  // вызывать только перед отправкой формы на сервер. Потому что после вызова этой функции сразу подсветятся ошибки на полях
  // проверка на правильность заполнения и все ли обязательные поля заполнены
  public checkErrorDate(): boolean {
    const dateValid = !!this.dateSub$.getValue();
    this.dateTouched$.next(true);
    return dateValid;
  }

  reset(): void {
    this.dateSub$.next(undefined);
    this.dateTouched$.next(false);
  }

  // === TIME =================================
  cssClassTime: '' | 'filled' | 'disabled' | 'error' | 'empty' = 'empty';

  openPopupTime(): void {
    this.popupS.open(PopupTimeComponent, { width: '554', time: this.timeValue }, true, 'panelClass_DateTime')
      .then((resTime: string) => { // resTime == 2:00 PM
        if (!resTime) return;
        const hours = this.customDatesS.hours12to24(resTime?.split(':')[0], resTime?.split(' ')[1]?.trim() as 'AM' | 'PM')?.toString();
        this.timeValue = resTime;
        this.time_24_format = hours + ':' + resTime?.split(' ')[0]?.split(':')[1]; // 14:20
        this.getDateForSendToServer();
        this.emit.emit(this.datesS.formatDate(this.formatDate, this.date)); ///
        this.cssClassTime = (this.errorClass && !this.timeValue) ? 'error' : 'filled';
        this.cd.detectChanges();
      });
  }

  ngModelChangeTime(timeValue: string): void { // timeValue ==  string 2:20 AM
    this.convertTimeStringToTimeObj();
    this.getDateForSendToServer();
  }

  convertTimeStringToTimeObj(): void {
    let arr = this.timeValue.split(' '); //  ['3:19', 'AM']
    let arrTime = arr[0].split(':'); // ['3', '19']
    this.timeObj = {
      hourValue: arrTime[0] as THours, // THours;
      minuteValue: arrTime[1] as TMinutes, // TMinutes;
      meridiem: arr[1] as TMeridiem, // TMeridiem;
    };
    this.convertFormat_12hours_to_24hours();
  }

  convertFormat_12hours_to_24hours(): void {
    if (this.timeObj?.hourValue === '12') this.timeObj.hourValue = '00';
    // @ts-ignore
    if (this.timeObj?.meridiem === 'PM') this.timeObj.hourValue = parseInt(this.timeObj!.hourValue, 10) + 12;
    this.time_24_format = `${this.timeObj?.hourValue}:${this.timeObj?.minuteValue}`;
  }

  // === OTHER =========================================
  // getDatePlusTimeString(): void {
  getDateForSendToServer(): void {
    const datePlusTimeObj = this.getDatePlusTime(); // Date
    this.dateForSendToServer = this.customDatesS.formatDate('YYYY-MM-DDTHH:mm:ss.SSS', datePlusTimeObj);
    this.dateValid = !!this.timeValue && !!this.dateSub$.getValue() && !!this.dateForSendToServer && this.dateForSendToServer?.toLowerCase() !== 'invalid date';
  }

  getDatePlusTime(): Date {
    let date = this.date;
    let time_24_format = this.time_24_format; // string 14:20
    const arr = time_24_format?.split(':')!;
    const hours = arr[0];
    const minutes = arr[1];
    return this.customDatesS.datePlusHoursMinutes(hours, minutes, date);
  }

  // === GETTERS & SETTERS =======================
  get date(): Date | undefined {
    return this.dateSub$.getValue();
  }

  set date(date: Date | undefined) {
    this.dateSub$.next(date);
  }

}
