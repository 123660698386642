import { Injectable } from '@angular/core';

declare let window: any;

interface Scripts {
  name: string;
  src: string;
}

const ScriptsStore: Scripts[] = [
  { name: 'stripeCheckout', src: 'https://checkout.stripe.com/checkout.js' },
  { name: 'stripeV3', src: 'https://js.stripe.com/v3/' },
];

@Injectable({ providedIn: 'root' })
export class LoadScriptsService {
  constructor() {
  }

  loadScripts(): void { // AppComponent constructor
    ScriptsStore.forEach((item) => {
      if (!window.document.getElementById(item.name)) {
        const script = window.document.createElement('script');
        script.id = item.name;
        script.type = 'text/javascript';
        script.src = item.src;

        window.document.body.appendChild(script);
      }
    });
  }
}
