<div class='wrapperEditCharts' cdkDropList (cdkDropListDropped)='dragDropEventEnd($event)'>
  <headTitle text='Edit Charts' svgRight='cross_grey&24' (svgRightEmit)='close(false)' typeText='text4-blueDark'></headTitle>

  <div class='contentEditCharts'>
    <div *ngFor='let item of arrForAnalytics' class='contentEditCharts__item' cdkDrag>
      <meSvg svgName='line_threeLineGrey&20'></meSvg>
      <div class='{{item.isAdded ? "o-color-blueDark":""}}'>{{item.title}}</div>
      <meSvg *ngIf='item.title != "Total Payouts" && item.title != "Projected Outgoings"'
             [svgName]='item.isAdded ? "plus_minusCircleRed&20":"plus_circleGreen&20"' (click)='addItem(item)'></meSvg>
    </div>
  </div>

  <btnWrap class='o-mg-t-32'>
    <btn text='Cancel' bcg='white' (emit)='close(false)'></btn>
    <btn text='Save' bcg='blueDark' (emit)='close(true)'></btn>
  </btnWrap>
</div>
