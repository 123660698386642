import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '@services/utils.service';

// andrei delete later
@Pipe({ name: 'formatPhone', standalone: true })
export class FormatPhonePipe implements PipeTransform {

  // return +X (XXX) XXX-XXXX // !!! работает только для телефонов у которых префикс из одной цифры
  transform(phone?: string): string {
    return UtilsService.getFormatPhone(phone);
  }

}
