<div class='containerTooltip'
     (touchend)='showTooltip=true'
     (mouseover)='showTooltip=true'
     (mouseleave)='forTest_notClosedPopup ? null : showTooltip=false'>

  <div #triggerRef class='containerTooltip__trigger'>
    <ng-content select='.ngContent_trigger'></ng-content>
  </div>

  <div *ngIf='showTooltipSub$|async' class='popupForContainerTooltip popupForContainerTooltip--{{position}}'
       [style]='{w:width}|getStyles' [ngStyle]='stylePosition'>
    <ng-content select='.ngContent_tooltip'></ng-content>
  </div>

</div>
