<div class='wrapperPagination wrapperPagination--{{position}}'>
  <!--  number: 1    текущая страница . с нуля начинается -->
  <!--  numberOfElements: 8   сколько получил элементов  -->
  <!--  size: 10  сколько запросили элементов -->
  <!--  totalElements: 18  сколько всего элементов на сервере -->
  <!--  totalPages: 2  сколько всего страниц на сервере-->
  <div class='wrapItemPage'>
    <div class='wrapItemPage__item {{currentPage === 1 ? "itemPageDisabled":""}}' (click)='prevPage()'>
      <meSvg svgName='arrow_left&20' [disabled]='currentPage === 1'></meSvg>
      Previous
    </div>
    <ng-container *ngFor='let itemPage of arrayFromNumber(data?.totalPages!); let idx=index; let last=last'>
      <!--    <ng-container *ngFor='let itemPage of arrayFromNumberIdx(data?.totalPages!); let idx=index; let last=last'>-->
      <div *ngIf='idx < 3 && !last' class='wrapItemPage__item {{currentPage===itemPage ? "itemPageDisabled":""}}'
           (click)='currentPage===itemPage ? null : goPage(itemPage)'>{{itemPage}}</div>

      <div *ngIf='currentPage === itemPage && idx >= 3 && !last' class='wrapItemPage__item itemPageDisabled'>{{itemPage}}</div>

      <div *ngIf='idx === 3 && !last' class='wrapItemPage__item o-cur-default'>...</div>
      <div *ngIf='last' class='wrapItemPage__item {{currentPage===itemPage ? "itemPageDisabled":""}}'
           (click)='currentPage===itemPage ? null : goPage(itemPage)'>{{itemPage}}</div>
    </ng-container>
    <div class='wrapItemPage__item {{currentPage === data?.totalPages ? "itemPageDisabled":""}}' (click)='nextPage()'>Next
      <meSvg svgName='arrow_right&20' [disabled]='currentPage === data?.totalPages'></meSvg>
    </div>
  </div>

  <div *ngIf='!forPopup' class='resultPerPage'>
    <div class='resultPerPage__drop' [matMenuTriggerFor]='menu' #trigger='matMenuTrigger'>{{data?.size}}
      <meSvg svgName='arrow_chevronBottom&20' [revV]='trigger.menuOpen'></meSvg>
    </div>
    Results per page of {{data?.totalElements}}
  </div>

</div>


<!-- MAT MENU  -->
<mat-menu #menu='matMenu'>
  <button *ngFor='let res of arrForDropDownAmountOfElements' (click)='data?.size == res ? null : updateResultsMethod(res)'
          [ngClass]='{"o-colorGreenLight o-cur-default": data?.size == res}'>{{res}} results per page
  </button>
</mat-menu>