<div class="info-list ">
    <ng-container *ngFor="let item of list; let i = index">
        <div class="info-list__default_item"
             *ngIf="i === this.selectedTile || (deviceService.isDesktop$ | async)"
             (swipeleft)="onSwipe($event, selectedTile)"
             (swiperight)="onSwipe($event, selectedTile)">
            <ng-container *ngTemplateOutlet="item"></ng-container>
        </div>
    </ng-container>
</div>

<div class="info-list__tiles">
    <div *ngFor="let tile of list; let i = index"
         class="tile__dot"
         (click)="selectTile(i)"
         (swipeleft)="onSwipe($event, i)"
         (swiperight)="onSwipe($event, i)"
         [ngClass]="{ isActive: selectedTile === i }">
    </div>
</div>
<!-- <div *ngFor="let item of itemList">
    <ng-container *ngTemplateOutlet="item"></ng-container>
</div> -->