import { ChangeDetectorRef, Component, forwardRef, Input, NgZone, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MainService } from '@services/main.service';
import { LocationService, TFieldLoc } from '@components/__blocks/location/location.service';
import { OtherService } from '@services/other.service';
import { PopupService } from '@services/popup.service';
import { HelperClass } from '@classes/Helper-Classes';
import { CommonModule } from '@angular/common';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { GetIdxPipe } from '@pipes/array/get-idx.pipe';
import { InputAddressComponent } from '@components/__drop_inputs_matSelect/input-address/input-address.component';
import { SelectTimezoneComponent } from '@components/__drop_inputs_matSelect/select-timezone/select-timezone.component';
import { TColor } from '@models/ICssStyles';
import { RoutesService } from '@services/routes.service';
import { ILocation } from '@models/location';
import { BtnComponent } from '@components/btn/btn.component';
import { PhotoComponent } from '@components/__svg_img/photo/photo.component';
import { FieldRowComponent } from '@components/__drop_inputs_matSelect/field-row/field-row.component';
import { DeviceService } from '@services/device.service';
import { ProfileService } from '@app/profile/profile.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UtilsService } from '@services/utils.service';

// !!! если надо заменить текст в <field [text]=''>
interface IForReplaceNameField {
  // export type TFieldLoc = 'city' | 'state' | 'street' | 'zipcode' | 'building' | 'latitude' | 'longitude' | 'timezone' | 'id' | 'streetLine2'
  street?: string;
  streetLine2?: string;
}

@UntilDestroy()
@Component({
  selector: 'location[fields][page]',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FieldComponent, DropFormCtrlComponent, FormsModule, InputCtrlComponent, GetIdxPipe, InputAddressComponent, SelectTimezoneComponent, BtnComponent, PhotoComponent, FieldRowComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationComponent),
      multi: true,
    },
  ],
})
export class LocationComponent extends HelperClass implements OnInit, OnChanges {
  @Input() page!: 'createGame' | 'registration' | 'contact'; // дизайн разный на этих страницах
  @Input() bcg: TColor | 'transparent' = 'white'; // background // TColor = 'blueDark' | 'grey' | 'grey_1' | 'white' | 'blue' | 'green' | 'red';
  @Input() titleForFieldVenue?: string;
  @Input() showVenueValue = false; // create/edit game
  @Input() showVenueName = false; // create/edit venue

  // !!! ВАЖНО !!! в каком порядке передашь название полей в массиве, в таком порядке они отрисуются в Html. (За счет css-класса o-order-{{index}})
  @Input() fields: Array<TFieldLoc> = []; // список всех полей
  @Input() requiredFields: Array<TFieldLoc> = []; // список обязательных полей
  @Input() replaceNameField?: IForReplaceNameField; // !!! если надо заменить текст в <field [text]=''>

  form!: UntypedFormGroup; // здесь можно было без формы сделать

  @Input() needTitleField = true;
  @Input() needPlaceholder = true;

  @Input() widthForFieldRow?: number | string;

  @Input() isFocus = false; // если true передал значит надо фокус сделать на этом поле

  constructor(
    public mainS: MainService,
    public otherS: OtherService,
    public locationS: LocationService,
    public popupS: PopupService,
    private formBuilder: UntypedFormBuilder,
    public deviceS: DeviceService,
    public profileS: ProfileService,
    public routesS: RoutesService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes?.page?.currentValue) {
    //   if (this.page == 'registration') {
    //     this.needTitleField = false;
    //     this.needPlaceholder = true;
    //   }
    // }
    // this.cd.detectChanges()
  }

  private data: any;
  ngOnInit(): void {
    this.createForm();
    if (this.requiredFields?.length) this.setValidatorRequired();
    this.subscribeToLoc();
    this.subscribeToLocTouched();
  }

  // юзер меняет отдельные поля в адресе
  // !!! типизировать event
  changeField(event: any, ctrl: TFieldLoc) {
    // console.log('event :', ctrl, typeof event, event)
    if (this.locationS.venue) this.locationS.resetVenue(); // venue убираю, потому что адрес получен от гугла

    // street => event == object ILocation // id у адреса тоже убираю, потому что адрес получен от гугла
    if (ctrl == 'street') {
      if (typeof event == 'string') this.locationS.setData({ street: event, id: '' });
      else this.locationS.setData({ ...event.value, id: '' });
    }

    // timezone => event == object ITimezone // id у адреса тоже убираю, потому что адрес получен от гугла
    if (ctrl == 'timezone') {
      this.locationS.setData({ timezone: event, id: '' });
    }

    // city/state/zipcode => event?.value == string // id у адреса тоже убираю, потому что адрес получен от гугла
    if ((ctrl == 'city' || ctrl == 'state' || ctrl == 'zipcode' || ctrl == 'building' || ctrl == 'streetLine2') && typeof event === 'string') {
      this.locationS.setData({ [ctrl]: event, id: '' });
    }

    // console.log('changeField == form :', ctrl,  event, '===', this.form.value)
    // console.log('changeField == this.locationS.loc :', ctrl, event, '===', this.locationS.loc)
    this.locationS.checkErrorLoc([]);

  }

  openPopupVenue(): void {
    // this.popupS.open(PopupVenueComponent, { typeMethodForVenue: 'new' }).then((res: IVenue) => {
    //   if (!res) return;
    //   this.locationS.addVenue(res);
    //   this.locationS.venue = res;
    //   this.cd.detectChanges();
    // });
    this.routesS.navigate('/league-settings/venues');
  }

  // === FORM ==============
  private createForm(): void {
    const loc = this.locationS.loc;
    this.form = this.formBuilder.group({});

    this.fields.forEach((field) => {
      this.form.addControl(field, this.formBuilder.control(loc[field] ? loc[field] : '',
        this.requiredFields?.includes(field) ? Validators.required : null));
    });
    this.cd.detectChanges();
  }

  get ctrl(): { [key: string]: AbstractControl } {
    return this.form?.controls;
  }

  private setValidatorRequired(): void {
    this.requiredFields?.forEach((fieldLoc) => {
      this.form?.controls[fieldLoc].setValidators(Validators.required);
      if (fieldLoc == 'zipcode') {
        // Validators.minLength(+this.mainS.maxlengthZipcode_5)
        this.form?.controls?.zipcode?.setValidators([Validators.required, Validators.maxLength(+this.mainS.maxlengthZipcode_5)]);
      }
    });
  }

  // === SUBSCRIBE ====================
  // San Francisco International Airport в этом адресе точно есть zipcode
  private subscribeToLoc(): void {
    this.locationS.loc$?.pipe(untilDestroyed(this)).subscribe((loc?: ILocation) => {
      // console.log('subscribeToLoc :', loc)
      // если нет loc, значит reset нажали в родительском компоненте
      UtilsService.isEmptyObj(loc) ? this.form.reset() : this.form.patchValue(loc!);
      // console.log('form :', this.form.value)
      // console.log('this.locationS.loc :', this.locationS.loc)
    });
  }

  private subscribeToLocTouched(): void {
    this.locationS.locTouched$?.pipe(untilDestroyed(this)).subscribe((locTouched: boolean) => {
      if (locTouched) this.form.markAllAsTouched();
    });
  }

}
