import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { OtherService } from '@services/other.service';
import { MainService } from '@services/main.service';
import {
  IResCreatePaymentMethodError,
  IResCreatePaymentMethodSuccess,
  IResCreateTokenSuccess,
  ISendObjForCreatePaymentMethod,
  IStripeEvent,
  StripeService,
  TFieldStripeForm,
} from '@services/stripe.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CommonModule } from '@angular/common';
import { GetArrStringFromObjectPipe } from '@pipes/get-arr-string-from-object.pipe';
import { CheckValidAllFieldsFromObjectPipe } from '@pipes/get-valid-all-fields-from-object.pipe';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';
import { GetStateInputPipe } from '@pipes/get-state-input.pipe';
import { HelperClass } from '@classes/Helper-Classes';
import { ErrorComponent } from '@components/__info_text_message_error_warning/error/error.component';
import { FundService } from '@services/funds.service';
import { IPaymentMethod, ISendObjCreatePaymentMethod } from '@app/dir_group_assignor/payments/modelsForPayment';
import { PaymentService } from '@app/dir_group_assignor/payments/payment.service';
import { MeService } from '@services/me.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { DeviceService } from '@services/device.service';

interface IForStripeElem {
  name: TFieldStripeForm;
  elemRef: Element;
  stripeElem?: any;
  value?: string;
}

@UntilDestroy()
@Component({
  selector: 'stripePopup',
  templateUrl: './stripe-popup.component.html',
  styleUrls: ['./stripe-popup.component.scss'],
  standalone: true,
  imports: [CommonModule, GetArrStringFromObjectPipe, CheckValidAllFieldsFromObjectPipe, BtnWrapComponent, BtnComponent, GetStateInputPipe, ErrorComponent, HeadTitleComponent],
})
export class StripePopupComponent extends HelperClass implements AfterViewInit {

  @Input() textApply?: string;
  @Input() textCancel?: string;

  fieldsRef: Array<IForStripeElem> = [];
  noInitFields: Array<TFieldStripeForm> = ['cardName', 'zip'];

  stripeElements?: any;

  @Input() oneBtn = true;

  // === FOR ADMIN =====================
  @Input() competitionId: string = '';
  @Input() isCreatePayMethod = false;
  @Output() createPayMethod = new EventEmitter<IPaymentMethod>();

  // === FOR OFFICIAL =====================
  @Input() isCreateToken = false;
  @Output() createToken = new EventEmitter();

  @Output() cancel = new EventEmitter<any>();

  validForm: { [key in TFieldStripeForm]?: boolean } = {};
  errMessages: { [key in TFieldStripeForm]?: string } = {};

  nameCardActive = false;
  zipActive = false;

  constructor(
    public payS: PaymentService,
    public fundS: FundService,
    private stripeS: StripeService,
    private mainS: MainService,
    private meS: MeService,
    private otherS: OtherService,
    public deviceS: DeviceService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
  }

  ngAfterViewInit() {
    this.setValidFormErrMessage();
    this.initFields();
  }

  setValidFormErrMessage(): void {
    this.fundS.fields?.forEach((field: TFieldStripeForm) => {
      this.validForm[field] = false;
      this.errMessages[field] = '';
    });
    this.cd.detectChanges();
  }

  createPaymentMethod(): void {
    if (this.startRequest()) return;
    const sendObjForStripe: ISendObjForCreatePaymentMethod = {
      type: 'card',
      card: { ...this.cardExpiry?.stripeElem, ...this.cardCvc?.stripeElem, ...this.cardNumber?.stripeElem },
      billing_details: { name: this.cardName?.value },
    };

    this.stripeS.createPaymentMethod(sendObjForStripe).pipe(
      switchMap((res) => {
        const sendObjCreatePaymentMethod: ISendObjCreatePaymentMethod = {};
        const res_stripeS_createPaymentMethod: IResCreatePaymentMethodSuccess | IResCreatePaymentMethodError | null = res;
        sendObjCreatePaymentMethod.paymentMethod = (res_stripeS_createPaymentMethod as IResCreatePaymentMethodSuccess)?.paymentMethod?.id;
        console.log('sendObjCreatePaymentMethod :', sendObjCreatePaymentMethod);
        if (!sendObjCreatePaymentMethod.paymentMethod) {
          return of(null);
        } else return this.mainS.createPaymentMethodForCompetition(sendObjCreatePaymentMethod, this.competitionId!);
      }),
      untilDestroyed(this),
    ).subscribe((res: IPaymentMethod | null) => {
      this.clear();
      if (!res) return;
      this.payS.addPayMethod(res);
      this.createPayMethod.emit(res);
      this.endRequest();
    },
      (err) => {
        console.error('error :', err);
        this.endRequest();
      });
  }

  initFields(): void {
    this.fundS.fields?.forEach((field: TFieldStripeForm) => {
      const refLink = document.querySelector(`#${field}`);
      if (refLink) this.fieldsRef.push({ name: field, elemRef: refLink });
    });

    this.stripeElements = this.stripeS.stripe?.elements();
    const option = { style: this.stripeS.styles, classes: this.stripeS.classes };

    this.fieldsRef?.forEach((item) => {
      if (this.noInitFields?.includes(item.name)) return;
      item.stripeElem = this.stripeElements?.create(item.name, option);
      item.stripeElem?.mount(item.elemRef);
      item.stripeElem?.on('change', (event: IStripeEvent) => this.checkErr(event));
    });
  }

  changeInput(target: any): void {
    const stripeEvent: IStripeEvent = { elementType: 'cardName', error: { message: '' } };
    if (target?.value?.trim()) {
      stripeEvent.complete = true;
      stripeEvent.empty = false;
      stripeEvent.error ? stripeEvent.error.message = '' : null;
      const cardNameElem = this.cardName;
      if (cardNameElem) cardNameElem.value = target?.value?.trim();
    } else {
      stripeEvent.complete = false;
      stripeEvent.empty = true;
      stripeEvent.error ? stripeEvent.error.message = 'Enter name on card.' : null;
    }
    this.checkErr(stripeEvent);
  }

  changeInputZip(target: any): void {
    const stripeEvent: IStripeEvent = { elementType: 'zip', error: { message: '' } };
    if (target?.value?.trim()) {
      stripeEvent.complete = true;
      stripeEvent.empty = false;
      stripeEvent.error ? stripeEvent.error.message = '' : null;
      const zipElem = this.zip;
      if (zipElem) zipElem.value = target?.value?.trim();
    } else {
      stripeEvent.complete = false;
      stripeEvent.empty = true;
      stripeEvent.error ? stripeEvent.error.message = 'Enter Zip Code.' : null;
    }
    this.checkErr(stripeEvent);
  }

  checkErr(event: IStripeEvent): void {
    const field: TFieldStripeForm | undefined = event?.elementType;
    if (!field || !this.fundS.fields.includes(field) || !this.errMessages || !this.validForm) return;
    this.errMessages[field] = event?.error?.message ? event?.error?.message : '';
    if (event?.error?.message || !event?.complete || event?.empty) this.validForm[field] = false;
    if (!event?.error?.message && event?.complete && !event?.empty) this.validForm[field] = true;
    this.cd.detectChanges();
  }

  clear(): void {
    this.fieldsRef?.forEach((field) => {
      if (this.noInitFields.includes(field.name)) {
        field.value = '';
        // @ts-ignore
        if (field.elemRef) field.elemRef.value = '';
      } else {
        field.stripeElem?.clear();
      }
    });
    this.cd.detectChanges();
  }

  private findField(fieldName: TFieldStripeForm): IForStripeElem {
    return this.fieldsRef?.find((field) => field.name == fieldName)!;
  }

  createTokenMethod(): void {
    if (this.startRequest()) return;
    const additionalData = {
      currency: 'usd',
    };

    this.stripeS.stripe?.createToken(this.cardNumber?.stripeElem, additionalData) // с сайта пример
      .then((res: IResCreateTokenSuccess) => {
        if (!res?.token?.id) {
          this.otherS.showError('No TOKEN from Stripe.');
          this.endRequest();
          return;
        }
        if (res?.token?.id) {
          this.fundS.tokenFromStripe = res?.token?.id;
          this.createToken.emit();
        }
      })
      .catch((err: any) => this.endRequest());
  }

  // === GETTERS ==============================
  get cardName(): IForStripeElem {
    return this.findField('cardName');
  }

  get cardNumber(): IForStripeElem {
    return this.findField('cardNumber');
  }

  get cardExpiry(): IForStripeElem {
    return this.findField('cardExpiry');
  }

  get cardCvc(): IForStripeElem {
    return this.findField('cardCvc');
  }

  get zip(): IForStripeElem {
    return this.findField('zip');
  }

}
