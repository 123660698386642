<div class='o-drop-down' [matMenuTriggerFor]='menu'
     (menuOpened)='isOpenMenu=true' (menuClosed)='isOpenMenu=false' [class.o-openDrop]='isOpenMenu'>
  <meSvg *ngIf='typeView=="svgCalendar"' svgName='calendar_blueDark&20'></meSvg>
  <span class='o-noWrap'>{{fromTo_formatted || period || placeholder}}</span>
  <meSvg *ngIf='typeView=="drop"' svgName='arrow_chevronBottom&20' [revV]='isOpenMenu' class='o-mg-l-auto-important'></meSvg>
</div>

<mat-menu #menu='matMenu'>
  <ng-container
    *ngFor='let periodItem of arrPeriods?.length ? arrPeriods : (typePeriod =="current" ? arrPeriodsForCurrent : arrPeriodsForPast)'>
    <button (click)='period == "Custom Date Range" || period !== periodItem ? choosePeriod(periodItem):null'
            [ngClass]='{"o-colorGreenLight o-cur-default": period === periodItem}'>{{periodItem}}</button>
  </ng-container>
  <button [ngStyle]='(elRef|getWidthMatMenu)' class='hiddenMatOption'></button>
</mat-menu>
