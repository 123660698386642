<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              selectedItems: selectedItems$|async,
              arrCurrentLink: announcementsS.arrCurrentLinkDrop_announcements,
              objCurrentLink: (announcementsS.currentLink$|async)!,
              currentLink: (announcementsS.currentLink$|async)?.currentLink!,
             } as obj' class='wrapperAnnouncements show-only-on-desktop'>
  <linkPage [arrLink]='obj.arrCurrentLink' [link]='obj.currentLink' (emit)='linkPage($event)'></linkPage>

  <div class='announcement-wrapper'>
    <div class='headerAnnouncements'>
      <headTitle text='Announcements'
                 typeText='text1-blueDark'></headTitle>
      <div class='headerAnnouncements__right'>
        <btn fontSize='16' text='New Announcement +' bcg='newGreen' [btnWidth]='205' (emit)='createAnnouncement()'></btn>
        <btn *ngIf='(selectedItems$|async)?.length && !announcementsS.is_currentLink_sent' (click)='deleteSelectedAnnouncement()'
             text='Delete' bcg='red' [btnWidth]='85'></btn>
        <btn *ngIf='(selectedItems$ | async)?.length && announcementsS.is_currentLink_sent' (click)='archiveAnnouncementByIds()'
             text='Archive' bcg='white' [btnWidth]='85'></btn>
      </div>
    </div>

    <ng-container *ngIf='!(loading$|async); else spinner'>
      <div class='o-table'>
        <div class='o-table__header'>
          <!--ToDO вот сюда смотреть зачем это понадобилось https://notch.atlassian.net/browse/NOT30-328-->
          <div *ngFor='let itemHeader of tableHeader; let idx = index;'
               class='o-table__header__item'
               [class.widthItem--custom]="itemHeader.text === 'Type'"
               [ngClass]="itemHeader.text !== 'Type' ? 'widthItem--' + (idx+1) : ''">
            <meSvg *ngIf='itemHeader.isChx'
                   [svgName]='isSelectAll | getChxSvgName'
                   class='o-table__header__item__chx'
                   (click)='selectAll()'></meSvg>
            {{ itemHeader.text }}
          </div>
        </div>

        <div *ngIf='(announcementsListSub$ | async)?.length; else nothingList'
             class='o-table__list'>
          <ng-container *ngFor='let announcementsItem of (announcementsListSub$ | async); let first = first; let idx = index; let last = last'>
            <div class='o-table__list__item' (click)='openDetails(announcementsItem)'>
              <div class='o-table__list__item__name widthItem--1'>
                <meSvg [svgName]='announcementsItem?.isSelect | getChxSvgName'
                       (click)='selectItem(announcementsItem, $event)'></meSvg>
                <span [style.opacity]="!!announcementsItem?.subject ? '1' : '0.5'">{{ announcementsItem?.subject || 'No Subject Line' }}</span>
                <span class='o-table__list__item__alert'
                      *ngIf='announcementsItem?.urgent'>
                  <meSvg [svgName]="'alert-triangle'"></meSvg>
                </span>
              </div>
              <!--ToDO вот сюда смотреть зачем это понадобилось https://notch.atlassian.net/browse/NOT30-328-->
              <div class='widthItem--custom o-table--itemColumn o-noWrap'>
                <span>{{ announcementsItem.type | titlecaseNoUnderscore: true }}</span>
              </div>
              <div class='widthItem--custom o-table--itemColumn o-noWrap'>
                <span class='announcement-type'>{{ showMethodType(announcementsItem.sms, announcementsItem.email, announcementsItem.urgent) }}</span>
              </div>
              <div class='widthItem--3 o-table--itemColumn column-status-padding'>
                <span [ngClass]="{
                  'draft' : announcementsItem?.status === 'DRAFT',
                  'sent' : announcementsItem?.status === 'SENT' || announcementsItem?.status === 'FAIL',
                  'archived' : announcementsS.is_currentLink_archived,
                  }"
                      class='status'>
                  {{
                  announcementsItem?.status === 'FAIL'
                  ? 'Sent'
                  : announcementsS.is_currentLink_archived
                  ? 'Archived'
                  :
                  (announcementsItem?.status | titlecase)
                  }}
                </span>
              </div>
              <div class='widthItem--5 o-table--itemColumn column-padding'>
                <span>{{ (announcementsItem.lastModified | utcToLocal) | dateTimeFormat : 'mediumDate' }}</span>
              </div>
              <div class='widthItem--7 o-table--itemColumn'>
                <span>{{ (announcementsItem.lastModified | utcToLocal) | dateTimeFormat : 'shortTime' }}</span>
              </div>
              <div class='widthItem--6'
                   [matMenuTriggerFor]='menuForPoints'
                   (click)='$event.stopPropagation()'>
                <meSvg svgName='circle_3points2&20'></meSvg>
                <mat-menu #menuForPoints='matMenu'>
                  <ng-container *ngIf="announcementsItem.status === 'DRAFT' && !announcementsS.is_currentLink_archived">
                    <button (click)='openDetails(announcementsItem)'>View</button>
                    <button (click)='openPopup("general", announcementsItem)'>Edit</button>
                    <button (click)='deleteAnnouncementById(announcementsItem.id)'>Delete</button>
                  </ng-container>
                  <ng-container *ngIf="(announcementsItem.status === 'SENT' || announcementsItem?.status === 'FAIL') && !announcementsS.is_currentLink_archived">
                    <button (click)='openDetails(announcementsItem)'>View</button>
                    <button (click)='archiveAnnouncementById(announcementsItem.id)'>Move to Archive</button>
                  </ng-container>
                  <ng-container *ngIf='announcementsS.is_currentLink_archived'>
                    <button (click)='openDetails(announcementsItem)'>View</button>
                    <button (click)='archiveAnnouncementById(announcementsItem.id, true)'>Move to Sent</button>
                    <button (click)='deleteAnnouncementById(announcementsItem.id)'>Delete</button>
                  </ng-container>
                </mat-menu>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <pagination *ngIf='(announcementsListSub$|async)?.length'
                  [data]='paginationData'
                  (updatePage)='paginationMethod("updatePage",undefined,$event)'
                  (updateResults)='paginationMethod("updateCountResults",$event)'></pagination>
    </ng-container>
  </div>

</div>

<ng-template #spinner>
  <spinner></spinner>
</ng-template>

<ng-template #nothingList>
  <nothingList [tableEmpty]='undefined|getTableEmpty:"app-announcements"'>
    <btn text='New Announcement +' fontSize='16' bcg='newGreen' (emit)='createAnnouncement()'
         [btnWidth]='(deviceS.isDesktop$ | async) ? 209 : "100%"' [btnHeight]='deviceS.btnHeightForNothingList'
         class='class_ngContent_btn_for_nothingList'></btn>
  </nothingList>
</ng-template>

<coming-soon-mobile class="show-only-on-mobile"></coming-soon-mobile>