import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactItemComponent } from '@app/profile/additional_components/contact-item/contact-item.component';
import { PhotoComponent } from '@components/__svg_img/photo/photo.component';
import { DeviceService } from '@services/device.service';
import { MeService } from '@services/me.service';
import { GetLocationStringPipe } from '@pipes/location/get-location-string.pipe';
import { BtnComponent } from '@components/btn/btn.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { MeProfileService } from '@app/profile/me-profile/meProfile.service';
import { OtherService } from '@services/other.service';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { GetAgeByDateOfBirthPipe } from '@pipes/get-age-by-date-of-birth.pipe';
import { BooleanToStringPipe } from '@pipes/string/boolean-to-string.pipe';
import { ProfileService } from '@app/profile/profile.service';
import { FormatPhonePipe } from '@pipes/phone/format-phone.pipe';
import {
  TitleAndNavigationForProfileComponent,
} from '@app/profile/additional_components/title-and-navigation-for-profile/title-and-navigation-for-profile.component';
import { HelperClass } from '@classes/Helper-Classes';
import { LocationService, TFieldLoc } from '@components/__blocks/location/location.service';
import { ILocation } from '@models/location';
import { ClassUser } from '@models/user';
import { rout_meProfile } from '@app/profile/profile';
import { CustomValidators } from '@classes/CustomValidators';
import { MainService } from '@services/main.service';
import { Router } from '@angular/router';
import { FieldRowComponent } from '@components/__drop_inputs_matSelect/field-row/field-row.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { LocationComponent } from '@components/__blocks/location/location.component';
import { SelectDateComponent } from '@components/date-time/select-date/select-date.component';
import { PhoneComponent } from '@components/__drop_inputs_matSelect/phone/phone.component';
import { ReplacePipe } from '@pipes/string/replace.pipe';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UtilsService } from '@services/utils.service';

interface IMeProfileForm {
  pictureUrl: FormControl<string>;
  firstName: FormControl<string>;
  secondName: FormControl<string>;
  dateOfBirth: FormControl<string>;
  dateOfBirth_formatted: FormControl<string>;
  socialSecurity: FormControl<string>; // SNN XX-XXX-8888
  email: FormControl<string>;
  phone: FormControl<string>;

  address: FormControl<ILocation>;
}

@UntilDestroy()
@Component({
  selector: 'app-me-profile-info',
  standalone: true,
  imports: [CommonModule, ContactItemComponent, PhotoComponent, GetLocationStringPipe, BtnComponent, HeadTitleComponent,
    DropFormCtrlComponent, FormsModule, GetAgeByDateOfBirthPipe, BooleanToStringPipe, FormatPhonePipe, TitleAndNavigationForProfileComponent, FieldRowComponent, InputCtrlComponent, LocationComponent, ReactiveFormsModule, SelectDateComponent, PhoneComponent, ReplacePipe],
  templateUrl: './me-profile-info.component.html',
  styleUrls: ['./me-profile-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeProfileInfoComponent extends HelperClass {
  isEdit = false;
  form!: FormGroup<IMeProfileForm>;
  readonly rout_meProfile = rout_meProfile;
  widthInputForDesktop: number | string = 244;
  widthLeftField: number | string = 312;
  fields: Array<TFieldLoc> = ['street', 'streetLine2', 'city', 'state', 'zipcode']; // список всех полей которые надо отобразить
  requiredFields: Array<TFieldLoc> = []; // список обязательных полей

  constructor(
    private mainS: MainService,
    public meS: MeService,
    public router: Router,
    public deviceS: DeviceService,
    public profileS: ProfileService,
    public meProfileS: MeProfileService,
    public otherS: OtherService,
    private formBuilder: UntypedFormBuilder,
    public locationS: LocationService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.locationS.reset();
    this.locationS.setData(this.meS.me?.address || undefined);
    this.checkDevice();
    this.createForm();
  }

  submit(): void {
    if (this.startRequest()) return;
    this.form.markAllAsTouched();
    // const locValid = this.locationS.checkErrorLoc(this.requiredFields);
    // if (!locValid) return;

    const formValue = this.form.getRawValue();
    const user: ClassUser = {
      ...formValue,
      // address: this.otherS.removeEmptyKeysFromObject(address),
    } as ClassUser;

    const loc: ILocation = this.locationS.loc;
    const address: ILocation = { // GroupAddressDto
      // mailingAddress: this.ctrl.mailingAddress.value,
      // streetAddress: loc.street,
      ...user.address,
      street: loc.street,
      city: loc.city,
      state: loc.state,
      zipcode: loc.zipcode,
      streetLine2: loc.streetLine2,
      timezone: loc.timezone,
    };
    // console.log('address :', address);
    // console.log('address ?.streetLine2:', address?.streetLine2);

    // user.address = { ...user.address, ...this.otherS.removeEmptyKeysFromObject(address) };
    user.address = UtilsService.removeEmptyKeysFromObject(address);

    delete user.email; // !!! для этих есть отдельный запрос
    delete user.phone; // !!! для этих есть отдельный запрос

    // console.log('user :', user)
    // console.log('user dateOfBirth :', user.dateOfBirth)
    // console.log('user address :', user.address)
    // console.log('user address?.streetLine2 :', user.address?.streetLine2)
    // this.meS.setMeUser({ ...user })
    // this.createForm()
    // this.isEdit = false
    // this.endRequest()
    // return

    this.mainS.updateUser(user).toPromise()
      .then(() => {
        this.createForm(); // !!! после апдейта юзера проще заново форму создать, чем апдейтить каждый контрол
        this.isEdit = false;
      })
      .catch(() => {
      })
      .finally(() => this.endRequest());
  }

  sendCodeTo_email_or_phone(type: 'email' | 'phone', phoneValue?: string): void {
    if (this.startRequest()) return;
    let email: string = '';
    let phone: string = '';
    if (type == 'email') email = this.ctrl.email?.value;
    if (type == 'phone') phone = phoneValue!;
    this.profileS.sendCodeTo_email_or_phone(email, phone).toPromise()
      .then((res) => {
        // console.log('sendCodeTo_email_or_phone :', type, res);
        // if (type == 'email') this.ctrl.email.patchValue(phoneValue!);
        if (type == 'phone') this.ctrl.phone.patchValue(phoneValue!);
        // console.log('ctrl.email :', this.ctrl.email?.value)
        // console.log('ctrl.phone :', this.ctrl.phone?.value)
      })
      .catch((err) => {
        console.log('err :', err);
      })
      .finally(() => this.endRequest());
  }

  // === FORM ==================================
  createForm(): void {
    const me: ClassUser = this.meS.me!;
    // console.log('me :', me);
    // console.log('me dateOfBirth_formatted :', me?.dateOfBirth_formatted);
    this.form = this.formBuilder.group({
      pictureUrl: [me?.pictureUrl, [Validators.required]],
      firstName: [me?.firstName, [Validators.required]],
      secondName: [me?.secondName, [Validators.required]],
      dateOfBirth: [me?.dateOfBirth, [Validators.required]],
      dateOfBirth_formatted: [me?.dateOfBirth_formatted, [Validators.required]],
      socialSecurity: [me?.socialSecurity, [Validators.required]],
      // email: [{ value: me?.email, disabled: true }, [Validators.required, CustomValidators.patternEmail]],
      // phone: [{ value: me?.phone ? me?.phone : '-', disabled: true }, [Validators.required]],
      email: [me?.email ? me?.email : '-', [Validators.required, CustomValidators.patternEmail]],
      phone: [me?.phone ? me?.phone : '-', [Validators.required]],
      address: [me?.address, [Validators.required]],
      startOfficiating: [me?.startOfficiating, [Validators.required]],
      certified: [me?.certified ? 'Yes' : 'No', [Validators.required]], //'Are you currently certified as an official?'
      certificationName: [me?.certificationName, [Validators.required]],
      emergencyName: [me?.emergencyName, [Validators.required]],
      emergencyRelationship: [me?.emergencyRelationship, [Validators.required]],
      emergencyPhoneNumber: [me?.emergencyPhoneNumber ? '+' + me?.emergencyPhoneNumber : '-', [Validators.required]],
    });
    // this.checkFormChanges(this.form);
  }

  get ctrl(): IMeProfileForm {
    return this.form?.controls;
  }

  checkDevice(): void {
    this.deviceS.isDesktop$.pipe(untilDestroyed(this)).subscribe((isDesktop) => {
      this.widthInputForDesktop = isDesktop ? 244 : '100%';
      this.widthLeftField = isDesktop ? 312 : '100%';
      // widthInputForDesktop: number | string = 244;
      // widthLeftField: number = 312;
    });
  }

  cancel(): void {
    this.createForm();
    this.isEdit = false;
    this.locationS.reset();
    this.locationS.setData(this.meS.me?.address || undefined);
    this.cd.detectChanges();
  }

}
