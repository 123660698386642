import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@app/auth/auth.service';
import { MainService } from '@services/main.service';
import { MeService } from '@services/me.service';
import { LocationService } from '@components/__blocks/location/location.service';
import { RoutesService } from '@services/routes.service';
import { HelperClass } from '@classes/Helper-Classes';
import { BtnComponent } from '@components/btn/btn.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { InvalidControlsPipe } from '@pipes/invalid-controls.pipe';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClassUser, UserRegisteredStatus } from '@models/user';

interface IForm {
  firstName: FormControl<string>;
  secondName: FormControl<string>;
  preferredName?: FormControl<string>;
}

@UntilDestroy()
@Component({
  selector: 'nameStep',
  standalone: true,
  imports: [CommonModule, BtnComponent, FieldComponent, FormsModule, InputCtrlComponent, InvalidControlsPipe, ReactiveFormsModule],
  templateUrl: './name-step.component.html',
  styleUrls: ['./name-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NameStepComponent extends HelperClass {
  form!: FormGroup<IForm>;

  @Input()
  set user(value: Pick<ClassUser, 'firstName' | 'secondName' | 'preferredName'> | undefined) {
    this.createForm(value);
  }

  @Output() nextStepEvent = new EventEmitter<{ firstName: string, secondName: string, preferredName?: string }>();
  @Output() backStepEvent = new EventEmitter<void>();
  @Output() emit = new EventEmitter<{ firstName: string, secondName: string, preferredName?: string }>();

  constructor(
    public authS: AuthService,
    private formBuilder: UntypedFormBuilder,
    public mainS: MainService,
    private meS: MeService,
    public locationS: LocationService,
    public routesS: RoutesService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    // this.createForm();
    // this.subscribeToForm();
  }

  emitMethod(): void {
    const sendObj: ClassUser = {
      id: this.meS.me?.id,
      firstName: this.form.value.firstName,
      secondName: this.form.value.secondName,
      preferredName: this.form.value.preferredName || '',
      userRegisteredStatus: UserRegisteredStatus.NAME_ENTERED
    };

    this.mainS.fillUserInfo(sendObj).toPromise()
      .then((res) => {
        this.nextStepEvent.emit(this.form.getRawValue());
        this.emit.emit(this.form.getRawValue());
        this.cd.detectChanges();
      })
      .finally(() => this.endRequest());
  }



  // === FORM =================
  createForm(user: ClassUser | undefined): void {
    this.form = this.formBuilder.group({
      firstName: [user?.firstName, [Validators.required, Validators.minLength(3)]],
      secondName: [user?.secondName, [Validators.required, Validators.minLength(3)]],
      preferredName: [user?.preferredName, [Validators.minLength(3)]],
    });
    this.cd.detectChanges();
  }

  get ctrl(): IForm {
    return this.form?.controls;
  }

  // subscribeToForm(): void {
  //   this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((res) => {
  //     // console.log('subscribeToForm :', res)
  //     this.cd.detectChanges(); // !!! НЕ УДАЛЯТЬ. После signupWithGoogle не срабатывает пайп invalidControls
  //   });
  // }

}
