import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClassUser } from '@models/user';
import { FormatPhonePipe } from '@pipes/phone/format-phone.pipe';

@Component({
  selector: 'tooltipPhone[user]',
  standalone: true,
  imports: [CommonModule, FormatPhonePipe],
  templateUrl: './tooltip-phone.component.html',
  styleUrls: ['./tooltip-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipPhoneComponent {
  @Input() user?: ClassUser;

  constructor() {
  }

  callPhoneNumber(phoneNumber?: string) {
    if (phoneNumber) {
      window.location.href = 'tel:' + phoneNumber;
    }
  }
}
