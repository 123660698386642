<!--*ngIf='competitionS.isLoad.groups; else spinner'-->
<div class='wrapperCompetitions wrapperCompetitionsAgeGroups'>
  <competitionsNavigation page='ageGroups'></competitionsNavigation>
  <form class='wrapper wrapperCompetitionsGeneralInfo' [style.width]="!enableSelfRequestService.enableSelfRequestSubject ? '995px': '1099px'" [formGroup]='form'>
    <div class='headerSetupCompetition'>
      <svgAndText text='Age Groups' leftSvgName='arrow_squareLeft&24' typeText='text1-blueDark'
                  (leftSvgEmit)='competitionS.goToBack()'></svgAndText>
      <div class='headerSetupCompetition__btns'>
        <btn text='Save & Close' bcg='white' btnHeight='36' (click)='competitionsBtnsEmit(false)'
             [disabled]='!form.valid || reqPending'></btn>
        <btn text='Save & Proceed to Next Step' bcg='blueDark' btnHeight='36' (click)='competitionsBtnsEmit(true)'
             [disabled]='!form.valid || reqPending'></btn>
      </div>
    </div>

    <div class='tableSetupCompetition contentSetupCompetition'>
      <div class='tableSetupCompetition__header'>
          <div *ngFor='let headerItem of form.controls?.arrTableHeader?.value; let i=index'>
              <ng-container *ngIf="headerItem !== 'Self-Request Limit' || (headerItem === 'Self-Request Limit' && (enableSelfRequestService.enableSelfRequestSubject && isEnableSelfRequest))">
                  <div [style.white-space]="headerItem !== 'Self-Request Limit' ? 'no-wrap': 'normal'" class='tableSetupCompetition__header__item tableItem--{{!enableSelfRequestService.enableSelfRequestSubject && headerItem=="Official Self-Assign"? i : i+1}}{{enableSelfRequestService.enableSelfRequestSubject ? "a" : ""}}'>
                      {{ headerItem }}
                      <meSvg *ngIf='headerItem=="Self-Request Limit"' svgName='question_grey&16'
                             [matTooltip]='"Allow officials to request unassigned roles for this competition. Assignor approval is required."'></meSvg>
                      <meSvg *ngIf='headerItem=="Official Self-Assign"' svgName='question_grey&16'
                             [matTooltip]='"Activate this feature to enable self-assign permissions for this age group. Go to the `Officials` section to enable the permission to your eligible officials."'></meSvg>
                  </div>
              </ng-container>
          </div>
      </div>

      <div formArrayName='arrControls' class='tableSetupCompetition__content'>
        <div *ngFor='let item of arrControls?.controls; let idx = index; let first=first' [formGroupName]='idx'
             class='tableSetupCompetition__content__item'>
          <inputCtrl formControlName='gameAgeDescription' class='tableItem--1' placeholder='Game Age description'></inputCtrl>
          <app-dropdown formControlName='gamePeriodDrop' [array]='competitionS.arrGamePeriodDrop' placeholder='Game Period'
                        class='tableItem--2'></app-dropdown>
          <inputCtrl formControlName='periodLength' class='tableItem--{{enableSelfRequestService.enableSelfRequestSubject ? "3a": "3"}}' [isTextCenter]='true' placeholder='Period'
                     [isNumber]='true'></inputCtrl>
          <inputCtrl formControlName='totalDurations' class='tableItem--{{enableSelfRequestService.enableSelfRequestSubject ? "4a": "4"}}' [isTextCenter]='true' placeholder='Total Duration'
                     [isNumber]='true'></inputCtrl>
            <chx *ngIf="enableSelfRequestService.enableSelfRequestSubject" formControlName='officialSelfRequest' [text]='item.value?.officialSelfRequest ? "Active":"Inactive"'
                 class='tableItem--{{enableSelfRequestService.enableSelfRequestSubject ? "5a": "5"}} o-pad-r-15'></chx>
<!--          Попросили убрать, пока не понимаю почему-->
<!--          <chx formControlName='officialSelfAssign' [text]='item.value?.officialSelfAssign ? "Active":"Inactive"'-->
<!--               class='tableItem&#45;&#45;5 o-pad-r-15'></chx>-->
          <div class='tableItem--6 tableItem--actions'>
            <meSvg svgName='squareTwo&44' (click)='copyItem(item)'></meSvg>
            <!--            <meSvg [svgName]='first ? "delete_square2&44":"delete_square1"'-->
            <!--                   (click)='first ? null : form.controls.arrControls?.removeAt(idx)'></meSvg>-->
            <deleteItemForCompetitions page='ageGroups' [id]='item?.value?.id!' [disabled]='arrControls?.controls?.length == 1'
                                       (responseEmit)='form.controls.arrControls?.removeAt(idx)'></deleteItemForCompetitions>
          </div>
        </div>
      </div>
    </div>

    <btnAndLine text='Add New +' [bcg]='competitionS.bcg' class='o-mg-t-32' (emit)='addNew()'></btnAndLine>
  </form>
</div>

<!--<ng-template #spinner>-->
<!--  <mat-spinner diameter='40'></mat-spinner>-->
<!--</ng-template>-->
