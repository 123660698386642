
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnComponent } from '@components/btn/btn.component';
import { NothingListComponent } from '@components/nothing-list/nothing-list.component';
import { Router, RouterLink } from '@angular/router';
import { DeviceService } from '@services/device.service';
import {
  GroupAssignorTilesComponent,
} from '@app/dir_group_assignor/dashboard/components/dashboard-group-assignor/components/group-assignor-tiles/group-assignor-tiles.component';
import {
  GroupAssignorGamesTableComponent,
} from '@app/dir_group_assignor/dashboard/components/dashboard-group-assignor/components/group-assignor-games-tabel/group-assignor-games-table.component';
import {
  GroupAssignorTableComponent,
} from '@app/dir_group_assignor/dashboard/components/dashboard-group-assignor/components/group-assignor-table/group-assignor-table.component';
import { AppState } from '@app/store/app.state';
import { select, Store } from '@ngrx/store';
import { selectHomeAssigner, selectIsGame } from '@app/store/dashboard/states/dashboard-home-assigner';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { ClassSettingsRequest, IResponse } from '@models/response-and-request';
import { ClassFilterDateRange, ClassFilterDrop, ClassFilterInput } from '@components/filters/filters';
import { FiltersService } from '@components/filters/filters.service';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { ClassGame } from '@app/dir_group_assignor/games/game';
import { AdminPermissionDirective } from '@directives/admin-permission.directive';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';
import { TableEmptyComponent } from '@components/_table/table-empty/table-empty.component';
import {
  selectDashboardAllGames,
  selectDashboardAssignData,
  selectDashboardDeclinedData,
  selectDashboardUnassignedData,
} from '@app/store/dashboard/states/dashboard.state';
import { TypePathForTable, urlAssign, urlGames } from '@app/app.module';
import { AssignService } from '@app/dir_group_assignor/assign/assign.service';
import {
  arrBlockGamesForDashboard,
  TypeBlockGamesForDashboard,
} from '@app/dir_group_assignor/dashboard/components/dashboard-group-assignor/models/group-assignor.models';
import { BtnPrimaryComponent } from '@components/buttons/btn-primary/btn-primary.component';
import { AuthenticatorDirective } from '@directives/authenticator-hide.directive';

@UntilDestroy()
@Component({
  selector: 'app-dashboard-home-group-assignor',
  standalone: true,
  imports: [CommonModule, BtnComponent, NothingListComponent, RouterLink, GroupAssignorTilesComponent, GroupAssignorGamesTableComponent, GroupAssignorTableComponent, FiltersComponent, AdminPermissionDirective, GetTableEmptyPipe, TableEmptyComponent, BtnPrimaryComponent, AuthenticatorDirective],
  templateUrl: './dashboard-home-group-assignor.component.html',
  styleUrls: ['./dashboard-home-group-assignor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardHomeGroupAssignorComponent implements OnInit, OnChanges {
  isGame$: Observable<boolean> = this.store.pipe(untilDestroyed(this), select(selectIsGame));
  readonly arrForSelectorGames = arrBlockGamesForDashboard;
  itemIdSelectedGames: TypeBlockGamesForDashboard = 'totalGames';

  activeRoute: TypePathForTable = urlGames;
  queryParams: ClassSettingsRequest = {};
  @Input() settings!: ClassSettingsRequest;
  assignData!: IResponse<ClassGame>;
  isActiveTile: boolean = true;
  activeTileName!: string;

  constructor(
    public deviceService: DeviceService,
    public store: Store<AppState>,
    public filtersS: FiltersService,
    private gameS: GameService,
    public router: Router,
    private settingsRequestS: SettingsRequestService,
    private assignS: AssignService,
    public cd: ChangeDetectorRef,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('settings' in changes) {
      this.settings = changes.settings.currentValue;
      this.formationUrl();
    }
  }

  ngOnInit(): void {
    this.subscriptions();
    this.store.select(selectHomeAssigner).pipe(untilDestroyed(this)).subscribe(activeTile => {
      this.isActiveTile = activeTile?.isGame;
      // this.itemIdSelectedGames = activeTile?.isPending ? 'pending' : activeTile?.isDeclined ? 'declined' : activeTile?.isUnassigned ? 'unassigned' : 'games';
      this.itemIdSelectedGames = activeTile?.isPending ? 'pendingAssignments' : activeTile?.isDeclined ? 'declinedAssignments' : activeTile?.isUnassigned ? 'unassignedRoles' : 'totalGames';
      this.activeTileName = activeTile?.isPending ? 'UNACCEPTED,UNPUBLISHED' : activeTile?.isDeclined ? 'DECLINED' : activeTile?.isUnassigned ? 'UNASSIGNED' : '';
      this.formationUrl();
    });
    this.setFilters();
  }

  private formationUrl() {
    const { page, size, from, to, competitions, search } = this.settings;
    this.queryParams.page = page || undefined;
    this.queryParams.size = size || undefined;
    this.queryParams.from = from || undefined;
    this.queryParams.to = to || undefined;
    this.queryParams.competitions = competitions || undefined;
    this.queryParams.search = search || undefined;
    this.queryParams.assignStatuses = this.activeTileName;
    this.activeRoute = this.isActiveTile ? urlGames : urlAssign;
  }

  manageAll(): void {
    if (this.activeRoute === urlAssign) {
      this.assignS.manageAllAndGoToAssign(this.itemIdSelectedGames);
    } else {
      this.router.navigate([this.activeRoute], { queryParams: this.queryParams });
    }
  }

  setFilters(): void {
    const search = new ClassFilterInput({ typeFilter: 'search' });
    const dateRange = new ClassFilterDateRange({ typePeriod: 'current' });
    const competition = new ClassFilterDrop({
      typeFilter: 'competitions',
      arrayForDropdown: this.gameS.arrCompetition$.getValue(),
      disabled: !this.gameS.arrCompetition$.getValue()?.length,
      valueDrop: this.gameS.arrCompetition$.getValue()?.find(el => el.id === this.settingsRequestS.settings.competitions),
    });
    this.filtersS.setFilters([search, dateRange, competition], false, false);
  }

  private subscriptions(): void {
    this.store.pipe(
      select(selectDashboardAllGames),
      untilDestroyed(this),
    ).subscribe(res => {
      this.updateColumnValue(0, res?.totalElements);
    });
    this.store.pipe(
      select(selectDashboardAssignData),
      untilDestroyed(this),
    ).subscribe(data => {
      this.updateColumnValue(1, data.assignData.totalElements || 0);
    });
    this.store.pipe(
      select(selectDashboardDeclinedData),
      untilDestroyed(this),
    ).subscribe(res => {
      this.updateColumnValue(2, res.declinedData.totalElements);
    });
    this.store.pipe(
      select(selectDashboardUnassignedData),
      untilDestroyed(this),
    ).subscribe(res => {
      this.updateColumnValue(3, res.unassignedData.totalElements);
    });
  }

  private updateColumnValue(index: number, count: number = 0): void {
    this.arrForSelectorGames[index].value = count;
    this.cd.detectChanges();
  }

  getSelectedItem(): { title: string, value: string | number, id: string; } | undefined {
    return this.arrForSelectorGames.find(x => x.id === this.itemIdSelectedGames);
  }
}
