import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetStylesPipe } from '@pipes/css/get-styles.pipe';

@Component({
  selector: 'tooltipDefault[contentInnerHTML]',
  standalone: true,
  imports: [CommonModule, GetStylesPipe],
  templateUrl: './tooltip-default.component.html',
  styleUrls: ['./tooltip-default.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipDefaultComponent implements OnChanges {
  // @Input() text?: string;
  @Input() contentInnerHTML?: string;
  @Input() textCenter = false;

  // @Input() width!: number;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes?.contentInnerHTML?.currentValue) {
    //   const a = this.contentInnerHTML?.includes('<div')
    //   const b = this.contentInnerHTML?.includes('<span')
    //   console.log('contentInnerHTML :', a, b, this.contentInnerHTML)
    // }
  }
}
