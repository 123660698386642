export type TColor = 'transparent' | 'blueDark' | 'bluedark' | 'grey' | 'greyLight' | 'greyLightNew' | 'greyLightNew2'
  | 'grey_1' | 'grey_2' | 'grey_3' | 'grey_3_5' | 'grey_4' | 'grey_5'
  | 'white' | 'blue' | 'green' | 'newGreen' | 'greenLight' | 'greenLight2' | 'red' | 'yellow';
export type TAmountTypesText = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10'; // количество разновидностей текста в mixins.scss

export type TClassForText =
  'text1-blueDark' | 'text2-blueDark' | 'text3-blueDark' | 'text4-blueDark' |
  'text5-blueDark' | 'text6-blueDark' | 'text7-blueDark' | 'text8-blueDark' | 'text9-blueDark' | 'text10-blueDark' | 'text12-blueDark' | 'text13-blueDark' |

  'text1-grey1' | 'text2-grey1' | 'text3-grey1' | 'text4-grey1' |
  'text5-grey1' | 'text6-grey1' | 'text7-grey1' | 'text8-grey1' | 'text9-grey1' | 'text10-grey1' |

  'text1-white' | 'text2-white' | 'text3-white' | 'text4-white' |
  'text5-white' | 'text6-white' | 'text7-white' | 'text8-white' | 'text9-white' | 'text10-white' |

  'text1-newGreen' | 'text2-newGreen' | 'text3-newGreen' | 'text4-newGreen' |
  'text5-newGreen' | 'text6-newGreen' | 'text7-newGreen' | 'text8-newGreen' | 'text9-newGreen' | 'text10-newGreen' |

  'text1-greenLight' | 'text2-greenLight' | 'text3-greenLight' | 'text4-greenLight' |
  'text5-greenLight' | 'text6-greenLight' | 'text7-greenLight' | 'text8-greenLight' | 'text9-greenLight' | 'text10-greenLight' |

  'text1-red' | 'text2-red' | 'text3-red' | 'text4-red' |
  'text5-red' | 'text6-red' | 'text7-red' | 'text8-red' | 'text9-red' | 'text10-red';


// 'text1-' | 'text2-' | 'text3-' | 'text4-' |
// 'text5-' | 'text6-' | 'text7-' | 'text8-' |


// === WIDTH ==========================================
export interface IWidth {
  width?: string;
  minWidth?: string;
  maxWidth?: string;
}

export interface IHeight {
  height?: string;
  minHeight?: string;
  maxHeight?: string;
}

export class ClassWidth implements IWidth, IHeight {
  width: string;
  minWidth: string;
  maxWidth: string;
  height?: string;
  minHeight?: string;
  maxHeight?: string;

  constructor(width: number | string, height: number | string = 'auto') { // если string то можно передать %
    if (typeof width == 'string' && width.includes('%')) {
      this.width = this.minWidth = this.maxWidth = width;
    } else {
      this.width = this.minWidth = this.maxWidth = width + 'px';
    }

    if (typeof height === 'string' && height === 'auto') {
      this.height = this.minHeight = this.maxHeight = height;
    } else if (typeof height == 'string' && height.includes('%')) {
      this.height = this.minHeight = this.maxHeight = height;
    } else {
      this.height = this.minHeight = this.maxHeight = height + 'px';
    }
  }
}

export class ClassHeight implements IHeight {
  height?: string;
  minHeight?: string;
  maxHeight?: string;

  constructor(height: number | string) { // если string то можно передать %
    if (typeof height === 'string' && height === 'auto') {
      this.height = this.minHeight = this.maxHeight = height;
    } else if (typeof height == 'string' && height.includes('%')) {
      this.height = this.minHeight = this.maxHeight = height;
    } else {
      this.height = this.minHeight = this.maxHeight = height + 'px';
    }
  }
}

// === BUTTON ===========================================
export type TypeCssClass_forBtn = 'square-blueDark' | 'square-greenLight' | 'square-newGreen' | 'square-white' | 'square-red'
  | 'square-grey1' | 'square-grey2' | 'square-grey3' | 'square-grey3_5' | 'square-grey4';
