<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              selectedItems: selectedItems$|async,
              arrCurrentLink: announcementsS.arrCurrentLinkDrop_announcements,
              objCurrentLink: (announcementsS.currentLink$|async)!,
              currentLink: (announcementsS.currentLink$|async)?.currentLink!,
             } as obj' class='o-wrapPage show-only-on-desktop'>
  <ng-container *ngIf="obj.desktop" [ngTemplateOutlet]=' linkPageTemplate'></ng-container>

  <div class='o-header'>
    <div class='o-header__content'>
      <headTitle text='Announcements' typeText='text1-blueDark'></headTitle>
      <ng-container *ngIf="!obj.desktop" [ngTemplateOutlet]='linkPageTemplate'></ng-container>

      <div class='o-header__actions' [authenticator]="[ 'GROUP_ASSIGNOR','SUB_ASSIGNOR']">
        <btn fontSize='16' text='New Announcement +' bcg='newGreen' [btnWidth]='obj.desktop ? 205 : "100%"'
          (emit)='createAnnouncement()'>
        </btn>
        <btn *ngIf='obj.desktop && (selectedItems$|async)?.length && !announcementsS.is_currentLink_sent'
          (click)='deleteSelectedAnnouncement()' text='Delete' bcg='red' [btnWidth]='85'></btn>
        <btn *ngIf='obj.desktop && (selectedItems$ | async)?.length && announcementsS.is_currentLink_sent'
          (click)='archiveAnnouncementByIds()' text='Archive' bcg='white' [btnWidth]='85'></btn>
      </div>
    </div>
  </div>

  <div class='wrapContentAssign o-content'>
    <ng-container *ngIf='!(loading$|async) || obj.mobile; else spinner'>
      <div class='o-table'>
        <div class='o-table__header'>
          <div *ngFor='let itemHeader of tableHeader; let idx = index;' class='o-table__header__item'
            [class.widthItem--custom]="itemHeader.text === 'Type'"
            [ngClass]="itemHeader.text !== 'Type' ? 'widthItem--' + (idx+1) : ''">
            <meSvg *ngIf='itemHeader.isChx && !isReadOnly' [svgName]='isSelectAll | getChxSvgName'
              class='o-table__header__item__chx' (click)='selectAll()'></meSvg>
            {{ itemHeader.text }}
          </div>
        </div>

        <div *ngIf='(announcementsListSub$ | async)?.length; else nothingList' class='o-table__list'>
          <ng-container *ngFor='let announcementsItem of (announcementsListSub$ | async); 
                  let first = first; let idx = index; let last = last'>
            <div class='o-table__list__item' (click)='obj.desktop ? openDetails(announcementsItem) : null'>
              <div class='o-table__list__item__name widthItem--1'>
                <meSvg *ngIf='obj.desktop && !isReadOnly' [svgName]='announcementsItem?.isSelect | getChxSvgName'
                  (click)='selectItem(announcementsItem, $event)'></meSvg>
                <span [style.opacity]="!!announcementsItem?.subject ? '1' : '0.5'">{{ announcementsItem?.subject ||
                  'No Subject Line' }}</span>
                <span class='o-table__list__item__alert' *ngIf='announcementsItem?.urgent'>
                  <meSvg [svgName]="'alert-triangle'"></meSvg>
                </span>
              </div>

              <div class='widthItem--custom o-table--itemColumn o-noWrap'>
                <p *ngIf='obj.mobile' class='t-table__item__wrapCeil__titleFromHeader'>Type</p>
                <span>{{ announcementsItem.type | titlecaseNoUnderscore: true }}</span>
              </div>
              <div class='widthItem--custom o-table--itemColumn o-noWrap'>
                <p *ngIf='obj.mobile' class='t-table__item__wrapCeil__titleFromHeader'>Method</p>
                <span class='announcement-type'>{{ showMethodType(announcementsItem.sms, announcementsItem.email,
                  announcementsItem.urgent) }}</span>
              </div>
              <div class='widthItem--3 o-table--itemColumn column-status-padding'>
                <p *ngIf='obj.mobile' class='t-table__item__wrapCeil__titleFromHeader'>Status</p>
                <span [ngClass]="{
                  'draft' : announcementsItem?.status === 'DRAFT',
                  'sent' : announcementsItem?.status === 'SENT' || announcementsItem?.status === 'FAIL',
                  'archived' : announcementsS.is_currentLink_archived,
                  }" class='status'>
                  {{
                  announcementsItem?.status === 'FAIL'
                  ? 'Sent'
                  : announcementsS.is_currentLink_archived
                  ? 'Archived'
                  :
                  (announcementsItem?.status | titlecase)
                  }}
                </span>
              </div>
              <div class='widthItem--5 o-table--itemColumn column-padding'>
                <p *ngIf='obj.mobile' class='t-table__item__wrapCeil__titleFromHeader'>Date</p>
                <span>{{ (announcementsItem.lastModified | utcToLocal) | dateTimeFormat : 'mediumDate' }}</span>
              </div>
              <div class='widthItem--7 o-table--itemColumn'>
                <p *ngIf='obj.mobile' class='t-table__item__wrapCeil__titleFromHeader'>Time</p>
                <span>{{ (announcementsItem.lastModified | utcToLocal) | dateTimeFormat : 'shortTime' }}</span>
              </div>
              <div *ngIf='obj.mobile' class='t-actionsItemTable t-actionsItemTable--{{obj.desktop}}' stopPropagation>
                <btn text='View' bcg='blueDark' (click)='openDetails(announcementsItem)' btnHeight='36'></btn>

                <btn [authenticator]="['GROUP_ASSIGNOR', 'SUB_ASSIGNOR']" #btnMobileActionsRef text='More Options'
                  bcg='white' [matMenuTriggerFor]='menuForPoints' btnHeight='36'></btn>
              </div>
              <div *ngIf='obj.desktop' class='widthItem--6' [matMenuTriggerFor]='menuForPoints'
                (click)='$event.stopPropagation()'>
                <meSvg svgName='circle_3points2&20' [authenticator]="[ 'GROUP_ASSIGNOR','SUB_ASSIGNOR']"></meSvg>
              </div>

              <mat-menu #menuForPoints='matMenu'>
                <ng-container *ngIf="announcementsItem.status === 'DRAFT' && !announcementsS.is_currentLink_archived">
                  <button (click)='openDetails(announcementsItem)'>View</button>
                  <button (click)='openPopup("general", announcementsItem)'>Edit</button>
                  <button (click)='deleteAnnouncementById(announcementsItem.id)'>Delete</button>
                </ng-container>
                <ng-container
                  *ngIf="(announcementsItem.status === 'SENT' || announcementsItem?.status === 'FAIL') && !announcementsS.is_currentLink_archived">
                  <button (click)='openDetails(announcementsItem)'>View</button>
                  <button (click)='archiveAnnouncementById(announcementsItem.id)'>Move to Archive</button>
                </ng-container>
                <ng-container *ngIf='announcementsS.is_currentLink_archived'>
                  <button (click)='openDetails(announcementsItem)'>View</button>
                  <button (click)='archiveAnnouncementById(announcementsItem.id, true)'>Move to Sent</button>
                  <button (click)='deleteAnnouncementById(announcementsItem.id)'>Delete</button>
                </ng-container>
              </mat-menu>
            </div>
          </ng-container>

        </div>
      </div>
      <pagination *ngIf='(announcementsListSub$|async)?.length && !obj.mobile' [data]='paginationData'
        (updatePage)='paginationMethod("updatePage",undefined,$event)'
        (updateResults)='paginationMethod("updateCountResults",$event)'></pagination>

    </ng-container>
  </div>
  <div *ngIf="(loading$|async) && obj.mobile" class="spinner-container">
    <spinner></spinner>
  </div>
  <div *ngIf="obj.mobile" #sentinel></div>
  <ng-template #linkPageTemplate>
    <linkPage [arrLink]='obj.arrCurrentLink' [link]='obj.currentLink' (emit)='linkPage($event)'></linkPage>
  </ng-template>

</div>

<ng-template #spinner>
  <spinner></spinner>
</ng-template>

<ng-template #nothingList>

  <nothingList *ngIf='!(loading$|async)'
    [tableEmpty]='undefined|getTableEmptyForAnnouncements:announcementsS.currentLinkObj.currentLink?.upperCase ?? "ALL"'>
    <btn [authenticator]="[ 'GROUP_ASSIGNOR','SUB_ASSIGNOR']" text='New Announcement +' fontSize='16' bcg='newGreen'
      (emit)='createAnnouncement()' [btnWidth]='(deviceS.isDesktop$ | async) ? 209 : "100%"'
      [btnHeight]='deviceS.btnHeightForNothingList' class='class_ngContent_btn_for_nothingList'></btn>
  </nothingList>
</ng-template>

<coming-soon-mobile class="show-only-on-mobile"></coming-soon-mobile>