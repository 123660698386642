import { Pipe, PipeTransform } from '@angular/core';
import { OtherService } from '@services/other.service';

@Pipe({ name: 'isCorrectEmail', standalone: true })
export class IsCorrectEmailPipe implements PipeTransform {

  constructor(
    private otherS: OtherService,
  ) {
  }

  transform(str?: string): boolean {
    return this.otherS.isCorrectEmail(str);
  }

}
