import { Injectable } from "@angular/core";
import { BaseApi } from "@services/base-api";
import { Observable, shareReplay } from "rxjs";
import { ISenderList } from "./models/sender-list-model";

@Injectable({providedIn: 'root'})
export class PopupAnnouncementGeneralService extends BaseApi {
    readonly apiOfficials = '/api/core/officials/v1';
    readonly apiAnnouncement = '/api/notification/v1/announcement';
    cachedData = new Map();

    getAllContact(params: { [key: string]: any; } = {}): Observable<any> {
        const endPoint = `${ this.apiOfficials }/contact/all`;
        const endpointWithParams = `${ endPoint }?${ this.serializeParams(params) }`;

        return this.get(endpointWithParams);
    }

    private serializeParams(params: { [key: string]: any; }): string {
        return Object.entries(params)
            .filter(([_, value]) => value !== undefined)
            .map(([key, value]) => `${ encodeURIComponent(key) }=${ encodeURIComponent(value) }`)
            .join('&');
    }

    getContactList(): Observable<ISenderList> {
        const endPoint = `${ this.apiOfficials }/contact/lists`;
        return this.get(endPoint).pipe(shareReplay());
    }

    getCount(recipientGroupType: string): Observable<number> {
        const endPoint = `${ this.apiAnnouncement }/count?recipientGroup=${ recipientGroupType }`;
        return this.get(endPoint);
    }

    getAllContactsByGameIds(gameIds: string[]): Observable<any> {
        const queryParamIds = gameIds.join(',');
        const endPoint = `${ this.apiOfficials }/contact/games?ids=${ queryParamIds }`;
        return this.get(endPoint);
    }

}
