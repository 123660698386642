<!-- <div style="display: block;background: red;">
  <div>current step == {{step}}</div>
  <div (click)='step=step+1'>step + 1</div>
  <div (click)='step=step-1'>step - 1</div>
</div> -->


<!-- <form [formGroup]='form' autocomplete='off' novalidate class='wrapperSignup o-wrapper'> -->
<div class='wrapperSignup o-wrapper'>
  <img src='assets/bcgLogo/bcgLogo_textNotchGreen.svg' alt='notch' class='authLogo'>
  <!--  type='GROUP_ASSIGNOR'-->
  <emailPassStep *ngIf='step == 1' (emit)='emailPassStep($event)'></emailPassStep>

  <codeConfirmStep *ngIf='step == 2 || step == 6' [type]='step == 2 ? "email":"phone"' (nextStepEvent)='nextStepEvent()' (backStepEvent)='backStepEvent()' [value]='step == 2 ? this.meS.me?.email! : this.meS.me?.phone!'></codeConfirmStep>

  <nameStep *ngIf='step == 3' (nextStepEvent)='changeValNameStep($event)' [user]="this.meS.me"></nameStep>

  <locationStep *ngIf='step == 4' (nextStepEvent)='nextStepEvent()' (backStepEvent)='backStepEvent()'></locationStep>

  <phoneStep *ngIf='step == 5' (nextStepEvent)='phoneStepEvent($event)' (backStepEvent)='backStepEvent()'></phoneStep>
</div>
<!-- </form> -->