import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { GetChxSvgNamePipe } from '@components/__svg_img/svg/pipes/get-chx-svg-name.pipe';
import { StopPropagationDirective } from '@directives/stop-propagation.directive';
import { TSvgName } from '@components/__svg_img/svg/forSvg';
import { DeviceService } from '@services/device.service';
import { MeTableService } from '@components/_table/me-table.service';
import { ClassUser } from '@models/user';
import { PhotoComponent } from '@components/__svg_img/photo/photo.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CeilTableDirective } from '@components/_table/directives/ceil-table.directive';

@Component({
  selector: 'app-tableCeilUser[user]',
  standalone: true,
  imports: [CommonModule, SvgComponent, GetChxSvgNamePipe, StopPropagationDirective, PhotoComponent, MatTooltipModule, CeilTableDirective],
  templateUrl: './table-ceil-user.component.html',
  styleUrls: ['./table-ceil-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableCeilUserComponent {
  @Input() user!: ClassUser; // IForTable
  @Input() text2?: string;
  @Input() showCheckbox: boolean = true;
  @Input() svgRight?: TSvgName | null = null;
  @Input() matTooltip_svgRight?: string;
  @Output() emit = new EventEmitter();

  constructor(
    public deviceS: DeviceService,
    public meTableS: MeTableService<ClassUser>,
    // public cd: ChangeDetectorRef,
  ) {
  }

}
