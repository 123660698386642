import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnComponent } from '@components/btn/btn.component';
import { TColor } from '@models/ICssStyles';
import { GetStylesPipe } from '@pipes/css/get-styles.pipe';

@Component({
  selector: 'btnAddAnother[page]',
  standalone: true,
  imports: [CommonModule, BtnComponent, GetStylesPipe],
  templateUrl: './btn-add-another.component.html',
  styleUrls: ['./btn-add-another.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnAddAnotherComponent implements OnChanges {
  @Input() page!: 'competition';
  @Output() emit = new EventEmitter();

  text = 'Add Another +';
  bcg: TColor = 'newGreen';
  btnWidth = '159';
  btnHeight = '36';

  constructor(
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.page.currentValue == 'competition') {
      this.text = 'Add Another +';
      this.bcg = 'newGreen';
      this.btnWidth = '159';
      this.btnHeight = '36';
    }
    this.cd.detectChanges();
  }
}
