<div class='defaultPopupWrapper'>
  <headTitle text='Add Crew Member' svgRight='cross_grey&24' (svgRightEmit)='close(false)' typeText='text4-blueDark'></headTitle>
  <div class='defaultPopupWrapper__subTitle'>Enter an official fee to the new role and save changes.</div>

  <field text='Crew Member - Official #'>
    <inputCtrl [ngModel]='officialPositionNumber' [disabled]='true'></inputCtrl>
  </field>
  <field text='Official Fee'>
    <inputCtrl [(ngModel)]='officialFee' [isNumber]='true' [showDollar]='true'></inputCtrl>
  </field>

  <btnWrap>
    <btn text='Cancel' bcg='white' [spinner]='reqPending' (emit)='close(false)'></btn>
    <btn text='Save Changes' bcg='blueDark' [disabled]='reqPending || !officialFee || !officialPositionNumber' (emit)='submit()'></btn>
  </btnWrap>
</div>