import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class GlobasConst {
    public get dateTimeFormat(): string {
        return 'MMM d, y, h:mm a';
    }
    public get dateFormat(): string {
        return 'MMM d, y';
    }

    public get timeFormat(): string {
        return 'h:mm a';
    }

    public get moneyFormat(): any {
        return {
            currencyFormat: 'USD',
            currencyDisplay: 'symbol',
            currencyPrecision: '1.2-2',
        }
    }
}