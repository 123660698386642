<div class='wrapAddressCourtName' (click)='forFocusInput()'>
  <ng-container *ngIf='{existItems: (arrCourtName|excludeArrOfArr:"id")?.length} as obj'>
    <ng-container *ngIf='arrCourtName|excludeArrOfArr:"id"'>
      <div *ngFor='let item of arrCourtName|excludeArrOfArr:"id"; let idx = index' class='itemCourtName'>
        {{item[1]}}
        <meSvg *ngIf="!disabled" svgName='cross_blueDark&24' (click)='removeItem(item, idx)' w='12'></meSvg>
      </div>
    </ng-container>

    <div *ngIf='allowNew' class='itemCourtName itemCourtName--new {{obj.existItems ? "":"itemCourtName--existItems"}}'>
      <input [disabled]="!!disabled" #inputRef [(ngModel)]='inputValue' (input)='input($event)' (keydown)='keydownNewItemCourtName($event)' maxlength='60'
             [placeholder]='obj.existItems ? "":placeholder' style="background-color: transparent;">
    </div>
  </ng-container>
</div>