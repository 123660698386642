import { Injectable } from '@angular/core';
import { ProfileService } from '@app/profile/profile.service';
import { TypePageProfile } from '@app/profile/profile';

@Injectable({ providedIn: 'root' })
export class MeProfileService {
  readonly typePageProfile: TypePageProfile = 'meProfile';

  constructor(
    private profileS: ProfileService,
  ) {
  }

}
