import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass } from '@classes/Helper-Classes';
import { DeviceService } from '@services/device.service';
import { MainService } from '@services/main.service';
import { OtherService } from '@services/other.service';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { MeService } from '@services/me.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomDatesService } from '@classes/CustomDates';
import { ClassGame, ClassGameOfficial } from '@app/dir_group_assignor/games/game';
import { BtnComponent } from '@components/btn/btn.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { Geo } from '@classes/geo';
import { IDataPopup, PopupService } from '@services/popup.service';
import { PopupCancelGameComponent } from '@app/dir_group_assignor/games/components/popup-cancel-game/popup-cancel-game.component';
import { MatMenuModule } from '@angular/material/menu';
import { PhotoComponent } from '@components/__svg_img/photo/photo.component';
import { GetUserNamePipe } from '@pipes/get-name.pipe';
import { GetFirstLetterEachWordPipe } from '@pipes/string/get-first-letter-each-word.pipe';
import {
  IPopupEditFeeForGO,
  PopupEditFeeForGOComponent,
} from '@app/dir_group_assignor/games/components/popup-edit-fee-for-go/popup-edit-fee-for-go.component';
import {
  IDataPopupAddCrewMember,
  IResponsePopupAddCrewMember,
  PopupAddCrewMemberComponent,
} from '@app/dir_group_assignor/games/components/popup-add-crew-member/popup-add-crew-member.component';
import { GetLocationStringPipe } from '@pipes/location/get-location-string.pipe';
import { StatusComponent } from '@components/status/status.component';
import { DateFormattedComponent } from '@components/__date/date-formatted/date-formatted.component';
import { FormatPhonePipe } from '@pipes/phone/format-phone.pipe';
import { GetSvgForGoStatusPipe } from '@pipes/gameOfficial/get-svg-for-go-status.pipe';
import { urlGameInfo, urlReportInfo } from '@app/app.module';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TooltipDirective } from '@directives/tooltip.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AdminPermissionDirective } from '@directives/admin-permission.directive';
import { from, of, switchMap } from 'rxjs';
import { MatTooltipForActionsForCanceledGamePipe } from '@pipes/game_and_report/mat-tooltip-for-actions-for-canceled-game.pipe';
import { GetListGameForPossibleGameToCancelledPipe } from '@pipes/game_and_report/get-list-game-for-possible-game-to-cancelled.pipe';
import { IsPossibleGameToCancelledPipe } from '@pipes/game_and_report/is-possible-game-to-cancelled.pipe';
import { CancelationDetailsComponent } from '@app/dir_group_assignor/games/components/cancelation-details/cancelation-details.component';
import { IsPossibleEditFeePipe } from '@pipes/gameOfficial/is-possible-edit-fee.pipe';
import { GameNotesService } from '../../game-notes.service';
import { GameNotesComponent } from '../game-notes/game-notes.component';
import { MatDialog } from '@angular/material/dialog';

@UntilDestroy()
@Component({
  selector: 'app-game-info',
  standalone: true,
  imports: [GameNotesComponent, CommonModule, BtnComponent, BtnWrapComponent, HeadTitleComponent, SvgAndTextComponent, SvgComponent, MatMenuModule,
    PhotoComponent, GetUserNamePipe, GetFirstLetterEachWordPipe, GetLocationStringPipe, StatusComponent, DateFormattedComponent, FormatPhonePipe, GetSvgForGoStatusPipe, TooltipDirective, MatTooltipModule, AdminPermissionDirective, MatTooltipForActionsForCanceledGamePipe, GetListGameForPossibleGameToCancelledPipe, IsPossibleGameToCancelledPipe, CancelationDetailsComponent, IsPossibleEditFeePipe],
  templateUrl: './game-info.component.html',
  styleUrls: ['./game-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameInfoComponent extends HelperClass implements OnInit, AfterViewInit {
  game?: ClassGame;
  isEditable = false;
  readonly urlGameInfo = urlGameInfo;
  readonly urlReportInfo = urlReportInfo;
  currentPhonePopup = -1;
  gameDescription?: GameNotesService;

  constructor(
    private mainS: MainService,
    public otherS: OtherService,
    public gameS: GameService,
    public meS: MeService,
    private route: ActivatedRoute,
    public router: Router,
    public customDatesS: CustomDatesService,
    public geo: Geo,
    public popupS: PopupService,
    public cd: ChangeDetectorRef,
    public deviceS: DeviceService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private dialog: MatDialog,
  ) {
    super(cd);
    this.hoveredRow = null;
  }

  ngOnInit() {
    this.route.params.pipe(untilDestroyed(this)).subscribe((params) => {
      if (params?.idGameForPath) this.getGame(params?.idGameForPath);
    });

    this.isEditable = this.meS.meRole !== 'OFFICIAL';
  }

  ngAfterViewInit() {
    document.addEventListener('touchstart', (event: TouchEvent) => {
      const phonePopup = this.elementRef.nativeElement.querySelector('.official-mobile__texts .phone-popup.mobile-mode');
      const target = (event && event.target as any) || null;
      const id = (target['id']) || '';

      if (phonePopup && !phonePopup.contains(event.target) && (id.indexOf('phone-image') === -1)) {
        setTimeout(() => {
          this.hidePhoneNumber();
        }, 100);
        // Testing the timeout for closing popup
      }
    });
  }

  popupGameNotes(isReadOnlySource: boolean) {
    let isReadOnly = isReadOnlySource;
    if (this.gameDescription!.isReadOnly == true)
      isReadOnly = true;
    let width = '343px';
    if (this.deviceS.isDesktop)
      width = '480px';
    return this.dialog.open(GameNotesComponent, { width: width, data: { text: this.gameDescription!.text, isReadOnly: isReadOnly } }).afterClosed().toPromise().then(text => {
      if (!isReadOnly && text !== undefined) {
        this.game!.gameDescription = text;
        this.gameDescription = GameNotesService.CreateByGame(this.game!);
        const gameToServer = { ...this.game };
        const date = new Date(gameToServer!.date!);
        gameToServer.date = date.toISOString().replace('Z', '');;

        this.mainS.methodGame(gameToServer!, 'put', false).toPromise()
          .then((res?: ClassGame) => {
            if (!res)
              console.error('popupGameNotes EditGameComponent :');
          });
      }
    });
  }

  updateView(): void {
    this.gameS.checkPreviewGame(this.game!);
    this.cd.detectChanges();
  }


  calculateAge(dateOfBirth: string): number {
    const birthDate = new Date(dateOfBirth);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - birthDate.getFullYear();

    // Adjust age based on the month and day
    if (currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
      return age - 1;
    }

    return age;
  }


  getGame(gameId: string): void {
    if (this.startRequest()) return;
    this.mainS.methodGame({ id: gameId }, 'get').toPromise() // !!! email == id_token from google
      .then((res?: ClassGame) => {
        if (!res)
          return;
        this.game = res;
        this.gameDescription = GameNotesService.CreateByGame(this.game);
        this.geo.checkLocForGoggleMapFromILocation(false, this.game?.location?.address);
        this.updateView();
      })
      .catch((err) => {
      })
      .finally(() => this.endRequest());
  }

  goToEditGame(isClone = false): void {
    this.gameS.goToEditGame(this.game!, isClone).then((res?: ClassGame) => {
      if (!res) return;
      if (isClone) this.router.navigate([urlGameInfo, this.game?.id]);
      if (!isClone) {
        this.game = res;
        this.gameS.checkPreviewGame(this.game!);
      }
      this.cd.detectChanges();
    });
  }

  cancelGame(): void {
    this.popupS.open(PopupCancelGameComponent, { games: [this.game!] })
      .then((res: Array<ClassGame>) => {
        if (!res) return;
        this.game = res[0];
        this.getGame(this.game.id!); // после cancelGame не появлялась кнопка View Report, потому что не приходит game?.gameReport в mainS.cancelGames
      });
  }


  // === POPUP ===================================
  openPopupEditFeeForGO(go: ClassGameOfficial): void {
    if (this.startRequest()) return;
    this.popupS.open(PopupEditFeeForGOComponent, {
      id: go.id!,
      officialFee: go.officialFee || 0,
    }).then((res: IPopupEditFeeForGO | null) => {
      if (res) this.game!.gameOfficials = this.otherS.replaceElemArrayById(this.game!.gameOfficials!, {
        ...go,
        officialFee: res.officialFee,
      });
      this.endRequest();
    });
  }

  openPopupAddCrewMember(): void {
    if (this.startRequest()) return;
    const dataPopup: IDataPopupAddCrewMember = { game: this.game };
    this.popupS.open(PopupAddCrewMemberComponent, dataPopup)
      .then((res: boolean) => {
        this.endRequest();
        if (res) this.getGame(this.game?.id!);
      });
  }

  // !!! удалить роль(ClassGameOfficial) можно только если судья не заасайнен на эту роль
  openPopupRemoveRole(go: ClassGameOfficial): void {
    if (this.startRequest()) return;
    const dataPopup: IDataPopup = { textTitle: 'Remove role', text: 'Are you sure you want to delete this role?', textBtnApply: 'Remove' };
    from(this.popupS.openPopupConfirm(dataPopup))
      .pipe(
        switchMap((res: boolean) => {
          // console.log('openPopupConfirm res :', res);
          return res ? this.mainS.removeRoleForGO({ id: go.id }) : of(null);
        }),
        untilDestroyed(this),
      )
      .subscribe((res: Array<IResponsePopupAddCrewMember> | null) => {
        this.endRequest();
        if (!res) return;
        this.getGame(this.game?.id!); // this.game!.gameOfficials = this.game!.gameOfficials?.filter((el) => el.id !== go.id);
      });
  }


  // === OTHER =====================
  hoveredRow: string | null = null; // Initialize hoveredRow variable
  showPhoneNumber: number | boolean = false;
  showImageHover: boolean = true;
  popupHovered: boolean = false;


  callPhoneNumber(phoneNumber: string | undefined) {
    if (phoneNumber) {
      window.location.href = 'tel:' + phoneNumber;
    }
    this.showPhoneNumber = false;
  }

  setPhoneNumPopup(go: number) {
    const phoneWrapper = this.elementRef.nativeElement.querySelector('.phone-popup-' + go);

    if (phoneWrapper && phoneWrapper.getBoundingClientRect().bottom > (window.innerHeight - 120)) {
      var phonePopUp = this.elementRef.nativeElement.querySelector('.phone-popup.mobile-mode.phone-popup-' + go);
      if (phonePopUp)
        phonePopUp.classList.add('phone-relocation-popup');
    }

    this.showPhoneNumber = go;
  }

  shouldShowOpoup(go: number) {
    return this.showPhoneNumber === go;
  }

  imageHovered(event: any, idxRole: number = -1) {
    const { target } = event;
    target.parentElement.parentElement.classList.add('hover');
    this.currentPhonePopup = idxRole;
  }

  hidePhoneNumber(): void {
    this.showPhoneNumber = true;

    document.querySelectorAll('.official-mobile__texts').forEach(e => {
      e.classList.remove('hover');
    });

    document.querySelectorAll('.phone-popup.mobile-mode').forEach(e => {
      e.classList.remove('phone-relocation-popup');
    });
  }


  popupLeave(event: any) {
    const { target } = event;
    target.parentElement.classList.remove('hover');
    this.showPhoneNumber = false;
  }

  resetPopup() {
    if (this.deviceS.isMobile) return;
    this.showPhoneNumber = false;
    this.currentPhonePopup = -1;
  }

}


