import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from '@components/__info_text_message_error_warning/error/error.component';
import { ControlValueAccessor, FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { GetStateInputPipe } from '@pipes/get-state-input.pipe';
import { GetStylesPipe } from '@pipes/css/get-styles.pipe';
import { GetWidthMatMenuPipe } from '@pipes/get-width-mat-menu.pipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionComponent } from '@components/mat-option/mat-option.component';
import { MatOptionModule } from '@angular/material/core';
import { MAT_SELECT_CONFIG, MatSelect, MatSelectModule } from '@angular/material/select';
import { NoSpaceDirective } from '@directives/no-space.directive';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { TSvgName } from '@components/__svg_img/svg/forSvg';
import { OtherService } from '@services/other.service';
import { MainService } from '@services/main.service';
import { ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';

@Component({
  selector: 'app-dropdown[array]',
  standalone: true,
  imports: [CommonModule, ErrorComponent, FormsModule, GetStateInputPipe, GetStylesPipe, GetWidthMatMenuPipe, MatFormFieldModule,
    MatInputModule, MatOptionComponent, MatOptionModule, MatSelectModule, NoSpaceDirective, SvgComponent, ReactiveFormsModule],
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'forOverlayPanelDropdown' },
    },
  ],
})
export class DropdownComponent<T extends ClassDrop & { cssClass?: string; icon?: string }> implements ControlValueAccessor, OnChanges {
  @ViewChild('selectRef') selectRef?: MatSelect;
  @Input() array?: Array<T>;
  @Input() multi = false; // если true передал, то надо чтобы в formControlName был массив Array<T extends IDrop>
  @Input() placeholder: string = '';
  @Input() showAlwaysPlaceholder: boolean = false; // если true => то не надо в дропдауне показывать выбраное значение. Тоесть показывать всегда placeholder
  @Input() needSortByName: boolean = true; // нужно ли сортировать выпадающий список
  @Input() disableSorting: boolean = false;

  // @Input() arrayString?: Array<string>; // !!! использовать только в крайнем случае. Например при создании/редактировании игры есть courtNames и в таких случаях лучше использовать массив строк

  // === CSS STYLES =====================================================
  @Input() width: string | number = '';  // если надо ширину для дропдауна задать

  // === SVG ==========================================================
  @Input() svgPrefix?: TSvgName; // в самом дропдауне
  @Input() svgSuffix?: TSvgName; // в самом дропдауне
  @Input() widthSvgPrefix?: string | number; // размер для svg в самом дропдауне
  @Input() widthSvgSuffix?: string | number; // размер для svg в самом дропдауне

  // === FOR ADD NEW ITEM =============================================
  @Input() addNewItem = false;
  newItemValue = '';

  // === OUTPUT - EMITTER ===============================================
  // @Output() otherMatOptionEmit = new EventEmitter<T>(); // FOR ADD NEW ITEM // !!! пока не удалять. сейчас это не работает. позже сделаю

  // можно импольсозвать один из этих трех EventEmitter
  // !!! changeVal() используется для фильтров, т.к. там может быть multi == false или true
  @Output() changeVal = new EventEmitter<Array<T> | T>(); // !!! если multi == true то вернется массив. Если multi == false то вернется объект
  @Output() changeValObj = new EventEmitter<T>(); // !!! if multi == false
  @Output() changeValArray = new EventEmitter<Array<T>>(); // !!! if multi == true

  @Input() forTest: any = false;

  constructor(
    public otherS: OtherService,
    public mainS: MainService,
    public ngControl: NgControl,
    public elRef: ElementRef,
    private cd: ChangeDetectorRef,
  ) {
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.array?.currentValue) {
      // if (this.forTest) {
      //   this.array?.forEach(el => console.log('ngOnChanges :', el?.titleCase))
      // }
      if (!this.disableSorting) {
        this.sortListByName();
      }
    }
  }

  checkClosedDrop(isOpened?: boolean): void {
    // if (typeof isOpened !== 'boolean') return;
  }

  newItemChange(e: T): void {
    this.value = e;
  }

  // === sort list for dropdown by name =====================
  private sortListByName(): void {
    if (this.needSortByName) {
      if (Array.isArray(this.array) && this.array?.length) {
        const clonedArray = this.otherS.deepClone(this.array) as Array<T>; // !!! для того чтобы только здесь в дропдауне сортировка была. Без этого на странице репортов сортировка также для linkPage
        this.array = clonedArray?.sort((a, b) => {
          const firstComparatorValue = a.titleCase?.trim() || '';
          const secondComparatorValue = b.titleCase?.trim() || '';
          const parseValue = (value: string) => parseInt(value, 10);
          const numA = parseValue(firstComparatorValue);
          const numB = parseValue(secondComparatorValue);
          const isANumber = !isNaN(numA);
          const isBNumber = !isNaN(numB);
          if (isANumber && isBNumber) {
            return numA - numB;
          } else if (isANumber) {
            return -1;
          } else if (isBNumber) {
            return 1;
          } else {
            return firstComparatorValue.localeCompare(secondComparatorValue);
          }
        });
      }
    }
    this.cd.detectChanges(); // !!! NO DELETE
    this.cd.markForCheck(); // !!! NO DELETE
  }

  // === GETTERS & SETTERS =======================
  set value(value: T | Array<T>) { // если multiple = true , то value = массив
    if (typeof value === 'undefined') return;
    this.changeVal.next(value);
    if (!Array.isArray(value)) this.changeValObj.emit(value); // !!! if multi == false
    if (Array.isArray(value)) this.changeValArray.emit(value); // !!! if multi == true
    this.onChange(value); // чтобы в родит.компонент передать // this.ngControl.control?.setValue(value) || patchValue(value);
    this.onTouched();  // чтобы в родит.компонент передать
    // if (this.forTest) {
    //   console.log('SET value :', this.forTest, value)
    //   console.log('SET value :', this.forTest, this.value)
    // }
  }

  get value(): T | Array<T> {
    return this.ngControl.control?.value;
  }

  get errRequired(): boolean {
    return !!this.ngControl.control?.hasError('required') && !!this.ngControl.control?.touched;
  }

  get active(): boolean {
    return !!this.selectRef?.panelOpen;
  }

  get disabled(): boolean {
    return !!this.ngControl?.control?.disabled;
  }

  // === FOR ControlValueAccessor ===========================
  writeValue(value: T | Array<T>): void { // если multiple = true , то value = массив
    // if (this.forTest) {
    //   console.error(' ======================================== writeValue :', this.forTest, ' value:', value, ' this.array:', this.array)
    // }
    this.sortListByName();
  }

  setDisabledState(isDisabled: boolean): void {
    this.cd.detectChanges(); // !!! NO DELETE. Без этого не срабатывает при изменении в родительском компоненте
  }

  private onChange!: (value: T | Array<T>) => void;
  private onTouched!: () => void;

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
