<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
            mobile: (deviceS.isMobile$|async),
            arrCurrentLink: officialsS.arrCurrentLinkDrop_officials,
            objCurrentLink: (officialsS.currentLink$|async)!,
            currentLink: (officialsS.currentLink$|async)?.currentLink!,
            dataTable: (dataTable$|async)!,
            arrContent: (arrContent$|async),
            loadingTable: (meTableS.loadingTable$|async),
            } as obj' class='o-wrapPage' wrapPage [widthPage]='widthPage' cdkScrollable dataTable>
  <!--    (emitInfiniteLoading)='emitSettingRequest($event,"infiniteLoading")'-->

  <!--  === FILTERS & TITLE ===========================-->
  <div class='o-header'>
    <linkPage-searchFilter></linkPage-searchFilter>
    <div class='o-header__content'>
      <div class='o-header__title'>Officials</div>
      <div class='o-header__filters'>
        <!--          <app-filters (emit)='emitSettingRequest($event,"filters")'></app-filters>-->
        <app-filters></app-filters>
        <div class='o-header__actions'>
          <div *ngIf='officialsS.is_currentLink_active && deviceS.isDesktop' [authenticator]="[ 'GROUP_ASSIGNOR','SUB_ASSIGNOR']">
            <btn *ngIf='meTableS.selectedItems_ids?.length' text='Add to List' bcg='white'
                 (emit)='officialsS.openPopupGroupList(meTableS.selectedItems)' [btnWidth]='115'></btn>
            <btn *ngIf='meTableS.selectedItems_ids?.length' text='New List +' bcg='newGreen'
                 (emit)='officialsS.openPopupCreateGroup(meTableS.selectedItems)' [btnWidth]='110'></btn>
            <btn [authenticator]="[ 'GROUP_ASSIGNOR','SUB_ASSIGNOR']"
                 *ngIf='!meTableS.selectedItems_ids?.length'
                 text='New Official +' bcg='newGreen'
                 (emit)='inviteOfficials()' [btnWidth]='137'></btn>
          </div>
          <ng-container *ngIf='officialsS.is_currentLink_pending'>
            <btn *ngIf='meTableS.selectedItems_ids?.length' text='Remove All' bcg='blueDark' (emit)='deleteOfficialsForPagePending()'
                 [btnWidth]='117'></btn>
          </ng-container>

        </div>
      </div>
      <app-filtersSelected></app-filtersSelected>
    </div>
    <ng-container *ngIf='officialsS.is_currentLink_active && deviceS.isMobile'>
      <btn
           [authenticator]="[ 'GROUP_ASSIGNOR','SUB_ASSIGNOR']"
           *ngIf='!meTableS.selectedItems_ids?.length'
           text='New Official +' bcg='newGreen'
           (emit)='inviteOfficials()' [btnWidth]='"100%"' [btnHeight]='deviceS.isDesktop ? 44 : 36'></btn>
    </ng-container>
  </div>

  <!--  === TABLE ==============================-->
  <div class='o-content t-wrapperTable'>
    <!--      <tableHeader (emitSettingRequest)='emitSettingRequest($event,"sorting")'></tableHeader>-->
    <tableHeader></tableHeader>
    <div class='t-table'>
      <ng-container *ngFor='let item of obj.arrContent; let idx=index; let first=first;  let last=last'>
        <ng-container>
          <div [ngClass]="{'t-table-last-item' : last }" class='t-table__item' [class.o-flex-row]='obj.mobile' itemTable [item]='item'>
            <!-- === PAGE ACTIVE ================================-->
            <ng-container *ngIf='officialsS.is_currentLink_active'>
              <app-tableCeilUser
                                 [showCheckbox]="authenticatorService.isAllow(['SUB_ASSIGNOR','GROUP_ASSIGNOR'])"
                                 [user]='item' [svgRight]='item.stripeAccount ? "existStripeAccount&20":null'
                                 matTooltip_svgRight='Payment account ID created'
                                 (emit)='profileS.navigateByUrl("contactInfo",rout_profileUser,item.id)'>
                <div *ngIf='obj.mobile' class='t-table__item__wrapCeil__ceilColumn ngContentTableCeilUser'>
                  <div *ngIf='obj.mobile' class='t-table__item__wrapCeil__ceilColumn__line1'>Age {{ item.age }}</div>
                  <div *ngIf='obj.mobile' class='table__item__wrapCeil__ceilColumn svgRightForMobile'>
                    <!-- <meSvg [svgName]='item.certified ? "circle_chx2&16":"circle_crossRed2&16"'></meSvg> -->
                    <meSvg *ngIf='item.certified' [svgName]="'circle_chx2&16'"
                           [notchToolTipClassName]="'official-availability'" [notchTooltip]='generateTooltip(item)'></meSvg>
                    <meSvg *ngIf='!item.certified'
                           [svgName]="'circle_crossRed2&16'"></meSvg>
                  </div>
                </div>
              </app-tableCeilUser>
              <ng-container *ngIf='obj.desktop'>
                <app-tableCeil [idxCeil]='1' [text1]='item.age' [colorText]='item.age_color' [isCentered]='true'
                               [matTooltipText]='item.age_less16 ? "Proof-of-age not provided":""'></app-tableCeil>
                <app-tableCeil [idxCeil]='2' [text1]='item.phone_formatted'></app-tableCeil>
                <app-tableCeil [idxCeil]='3' [text1]='item.address?.city+", "+item.address?.state'></app-tableCeil>
                <div ceilTable [idxCeil]='4' class='t-table__item__wrapCeil t-isCentered'>
                  <meSvg *ngIf='item.certified' [svgName]="'circle_chx2&16'"
                         [notchToolTipClassName]="'official-availability'"
                         [notchTooltip]='generateTooltip(item)'></meSvg>
                  <meSvg *ngIf='!item.certified' [svgName]="'circle_crossRed2&16'"></meSvg>
                </div>
                <app-tableCeil [idxCeil]='5' [text1]='item.lastActive_formatted'></app-tableCeil>
              </ng-container>
            </ng-container>

            <!-- === PAGE PENDING ================================-->
            <ng-container *ngIf='officialsS.is_currentLink_pending'>
              <app-tableCeilUser [user]='item' (emit)='profileS.navigateByUrl("contactInfo",rout_profileUser,item.id)'>
                <status *ngIf='obj.mobile' [status]='item.status!|titlecase' class='ngContentTableCeilUser o-mg-t-4'></status>
              </app-tableCeilUser>
              <div *ngIf='obj.desktop' ceilTable [idxCeil]='1' class='t-table__item__wrapCeil'>
                <status [status]='item.status!|titlecase'></status>
              </div>
            </ng-container>

            <!--  === ACTIONS FOR ITEM TABLE ==========================================-->
            <app-tableCeilActions [matMenuTriggerFor]='menuItemTable' stopPropagation></app-tableCeilActions>
            <mat-menu #menuItemTable='matMenu' class='t-table-actions-menu'>
              <ng-container [ngTemplateOutlet]='actionsForItemTable'></ng-container>
            </mat-menu>
            <ng-template #actionsForItemTable>
              <ng-container *ngIf='officialsS.is_currentLink_active'>
                <button (click)='profileS.navigateByUrl("contactInfo",rout_profileUser,item.id)'>View Profile</button>
                <a *ngIf='item?.phone' href='tel:{{item?.phone}}' class='actions-table-link'>Call</a>
                <a *ngIf='item?.email' href='mailto:{{item?.email}}' target='_blank' class='actions-table-link'>Email</a>
                <button [authenticator]="[ 'GROUP_ASSIGNOR','SUB_ASSIGNOR']" (click)='officialsS.openPopupCreateGroup([item])'>Add to List</button>
              </ng-container>

              <ng-container *ngIf='officialsS.is_currentLink_pending'>
                <button (click)='null'>Resend</button>
              </ng-container>

              <button [authenticator]="[ 'GROUP_ASSIGNOR','SUB_ASSIGNOR']" (click)='officialsS.is_currentLink_active ? deleteOfficial(item?.id) : deleteOfficialsForPagePending(item?.id)'>
                Remove
              </button>
              <button [ngStyle]='{width: "200px"}' class='hiddenMatOption'></button>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>
      <!--      <app-tableEmpty (btnEmit)='inviteOfficials()'></app-tableEmpty>-->
      <app-tableEmpty [tableEmpty]='filtersS.searchValue|getTableEmptyForOfficials:officialsS.is_currentLink_active'>
        <btn [authenticator]="[ 'GROUP_ASSIGNOR','SUB_ASSIGNOR']"
             bcg='newGreen'
             text='New Official +' [btnHeight]='deviceS.btnHeightForNothingList'
             (emit)='inviteOfficials()' class='class_ngContent_btn_for_nothingList'></btn>
      </app-tableEmpty>
    </div>
  </div>

  <app-tableInfiniteLoading></app-tableInfiniteLoading>
  <!--    <pagination *ngIf='meTableS.showPagination' [data]='obj.dataTable' (emit)='emitSettingRequest($event,"pagination")'></pagination>-->
  <paginationWithSettings *ngIf='meTableS.showPagination'></paginationWithSettings>
</div>