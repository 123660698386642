import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { DeviceService } from '@services/device.service';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { HelperClass, TForPagination } from '@classes/Helper-Classes';
import { ClassSettingsRequest, IResponse } from '@models/response-and-request';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UtilsService } from '@services/utils.service';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  standalone: true,
  imports: [CommonModule, MatMenuModule, SvgComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent<T> extends HelperClass implements OnChanges {
  @Input() data?: IResponse<T>;
  @Input() position: 'left' | 'right' = 'right';
  @Input() forPopup = false;  // для попап окон
  // @Input() needUseSettingsDynamicUrl = false;

  @Output() updatePage = new EventEmitter<number>(); // andrei удалить потом, чтобы использовать новый emit.emit(ClassSettingsRequest)
  @Output() updateResults = new EventEmitter<number>(); // andrei удалить потом, чтобы использовать новый emit.emit(ClassSettingsRequest)
  @Output() emit = new EventEmitter<ClassSettingsRequest>();

  currentPage = 1; // data?.number

  arrForDropDownAmountOfElements: Array<number> = [];

  protected readonly arrayFromNumber = UtilsService.arrayFromNumber;
  protected readonly arrayFromNumberIdx = UtilsService.arrayFromNumberIdx;

  constructor(
    public deviceS: DeviceService,
    public cd: ChangeDetectorRef,
    public router: Router
  ) {
    super(cd);
  }

  updatePaginationSizes(): void {
    this.deviceS.isDesktop$.pipe(untilDestroyed(this)).subscribe((res) => {
      if (this.router.url.includes('/games')) {
        let defaultSizes = [50, 75, 100, 125, 150, 250, 500, 1000];
        // const totalElements = this.data?.totalElements;
        // if (totalElements && totalElements > 1000) {
        //   if (totalElements <= 2000) {
        //     defaultSizes.push(totalElements);
        //   } else {
        //     defaultSizes.push(2000);
        //   }
        // }
        res ? this.arrForDropDownAmountOfElements = defaultSizes : this.arrForDropDownAmountOfElements = [5, 10, 20];
      } else {
        res ? this.arrForDropDownAmountOfElements = [50, 75, 100, 125, 150] : this.arrForDropDownAmountOfElements = [5, 10, 20];
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.data?.currentValue) {
      if (typeof this.data?.number === 'number') this.currentPage = this.data?.number + 1;
    }
    this.updatePaginationSizes();
  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.updatePage.emit(this.currentPage);
      this.paginationMethod('updatePage', undefined, this.currentPage);
    }
  }

  goPage(itemPage: number): void {
    if (this.currentPage === itemPage) return;
    this.currentPage = itemPage;
    this.updatePage.emit(this.currentPage);
    this.paginationMethod('updatePage', undefined, this.currentPage);
  }

  nextPage(): void {
    if (this.currentPage < this.data?.totalPages!) {
      this.currentPage += 1;
      this.updatePage.emit(this.currentPage);
      this.paginationMethod('updatePage', undefined, this.currentPage);
    }
  }

  updateResultsMethod(num: number): void {
    this.updateResults.emit(num);
    this.paginationMethod('updateCountResults', num);
  }

  // === PAGINATION =============================
  paginationMethod(type: TForPagination, size?: number, page?: number): void {
    const settings: ClassSettingsRequest = {};
    if (type == 'updateCountResults') {
      settings.size = size;
      settings.page = 1;
    }
    if (type == 'updatePage') settings.page = page;
    this.emit.emit({ ...settings, typeEmitSetting: 'pagination' });
  }

}

