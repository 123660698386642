<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              arrTransfers: (payS.transfers$|async),
             } as obj' class='o-wrapPage o-wrapPage--noMarginTopAndNoPadding' wrapPage [widthPage]='1300'>

  <div class='o-header'>
    <div class='o-header__content'>
      <div class='o-header__title'>
        <meSvg svgName='arrow_squareLeft&24' (click)='router.navigate(["payments/officialsFees"])'></meSvg>
        Recent Transactions
      </div>

      <div class='totalAmountText'>Total Amount<span>$40k</span></div>

      <div class='headerAssignorFeesAll'>
        <inputCtrl [(ngModel)]='search' placeholder='Search' svgPrefix='search_grey&20'></inputCtrl>
        <meSvg svgName='download_3&44' (click)='null' [disabled]='true'></meSvg>
      </div>
    </div>
  </div>

  <div *ngIf='!(loading$|async); else spinner' class='o-content'>
    <!--  <ng-container *ngIf='!(loading$|async); else spinner'>-->
    <div class='o-table'>
      <div class='o-table__header'>
        <div *ngFor='let itemHeader of tableHeader; let idx = index;' class='o-table__header__item widthItem--{{idx+1}}'>
          <meSvg *ngIf='itemHeader.isChx' [svgName]='isSelectAll|getChxSvgName' class='o-table__header__item__chx'
                 (click)='selectAll()'></meSvg>
          {{itemHeader.text}}
<!--          <meSvg *ngIf='itemHeader.isActive' [svgName]='"arrow_top&16"' class='o-table__header__item__arrow'></meSvg>-->
        </div>
      </div>

      <div class='o-table__list'>
        <ng-container *ngFor='let item of obj.arrTransfers; let first = first; let idx = index; let last = last'>
          <div class='o-table__list__item'>
            <div class='table__list__item__name widthItem--1'>
              <meSvg [svgName]='item?.isSelect|getChxSvgName' (click)='selectItem(item, $event)'></meSvg>
              <span>{{item.gameNumber}}</span>
            </div>
            <div class='widthItem--2'>
              <span>{{item.createdAt|date:'MMM d, yyyy'}}</span> &nbsp;
              <span>at {{item.createdAt|date:'shortTime'}}</span>
            </div>
            <div class='widthItem--3'>
              <!--                <payoutFormat [payoutFormat]='item.payoutFormat!'></payoutFormat>-->
            </div>
            <div class='widthItem--4'>
              <!--              <span>+{{item.total|currency}}</span>-->
            </div>
            <div class='widthItem--5'>
              <!--              <span>+{{item.total|currency}}</span>-->
            </div>
            <div class='widthItem--6'>
              <span>+{{item.total|currency}}</span>
            </div>
            <div class='widthItem--7'>
              <btn text='View' bcg='white' [btnWidth]='87' [btnHeight]='36'></btn>
            </div>
            <div class='widthItem--8'>
              <!--              [matMenuTriggerFor]='menuForPoints' (click)='$event.stopPropagation()'-->
              <meSvg svgName='arrow_chevronRight&24'></meSvg>
              <!--              <mat-menu #menuForPoints='matMenu'>-->
              <!--                                  <button (click)='null'></button>-->
              <!--              </mat-menu>-->
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <pagination *ngIf='obj.arrTransfers?.length' [data]='(payS.transfersData$|async)!'
                (updatePage)='paginationMethod("updatePage",undefined,$event)'
                (updateResults)='paginationMethod("updateCountResults",$event)'></pagination>
    <!--  </ng-container>-->
  </div>
</div>

<ng-template #spinner>
  <spinner></spinner>
</ng-template>
