import { Pipe, PipeTransform } from '@angular/core';
import { CompetitionService } from '@app/dir_group_assignor/competitions/competition.service';

@Pipe({ name: 'isShowCrewOfficial', standalone: true })
export class IsShowCrewOfficialPipe implements PipeTransform {

  constructor(
    private competitionS: CompetitionService,
  ) {
  }

  transform(columnNumber: number, forTest: string): boolean {
    return this.competitionS.isShowCrewOfficial(columnNumber,forTest);
  }

}
