<!--*ngIf='competitionS.isLoad.seasons; else spinner'-->
<div class='wrapperCompetitions'>
  <competitionsNavigation page='seasons'></competitionsNavigation>
  <div class='wrapperCompetitionsBlock wrapperCompetitionsSeasons wrapFieldsSetupCompetition' [formGroup]='form'>
    <div class='headerSetupCompetition'>
      <svgAndText text='New Seasons' leftSvgName='arrow_squareLeft&24' typeText='text1-blueDark'
                  (leftSvgEmit)='competitionS.goToBack()'></svgAndText>
      <div *ngIf='competitionS.competition?.id' class='headerSetupCompetition__btns'>
        <btn text='Save & Close' bcg='white' btnHeight='36' (click)='competitionsBtnsEmit(false)'
             [disabled]='!form.valid || reqPending'></btn>
        <btn text='Save & Proceed to Next Step' bcg='blueDark' btnHeight='36' (click)='competitionsBtnsEmit(true)'
             [disabled]='!form.valid || reqPending'></btn>
      </div>
    </div>

    <div formArrayName='arrControls' class='contentSetupCompetition'>
      <div *ngFor='let item of arrControls?.controls; let idx = index; let first=first; let last = last' [formGroupName]='idx'
           class='contentSetupCompetition__itemSeason'>
        <div class='contentSetupCompetition__itemSeason__name'>
          <field text='Name'>
            <inputCtrl formControlName='name' placeholder='Name' [isFocus]='true'></inputCtrl>
          </field>
          <!--          <meSvg [svgName]='first ? "delete_square2":"delete_square1"'-->
          <!--                 (click)='first ? null : form.controls.arrControls?.removeAt(idx)'></meSvg>-->
          <deleteItemForCompetitions page='seasons' [id]='item?.value?.id!' [disabled]='arrControls?.controls?.length == 1'
                                     (responseEmit)='form.controls.arrControls?.removeAt(idx)'></deleteItemForCompetitions>
        </div>

        <startEndDate [startEndDate]='{startDate: item.controls.startDate?.value, endDate: item.controls.endDate?.value}'
                      (emit)='valueChangeDate(item ,$event)'></startEndDate>
        <btnAddAnother *ngIf='last' page='competition' (emit)='addNew()'></btnAddAnother>
      </div>

      <btnWrap *ngIf='!competitionS.competition?.id' type='col'>
        <btn text='Save & Proceed to Next Step' bcg='blueDark' (click)='competitionsBtnsEmit(true)'
             [disabled]='!form.valid || reqPending'></btn>
        <btn text='Save & Close' bcg='white' (click)='competitionsBtnsEmit(false)' [disabled]='!form.valid || reqPending'></btn>
      </btnWrap>
    </div>
  </div>
</div>

<!--<ng-template #spinner>-->
<!--  <mat-spinner diameter='40'></mat-spinner>-->
<!--</ng-template>-->

