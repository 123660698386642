<div class='wrapperFiltersSelected'>
  <ng-container *ngFor='let item of (filtersS.arrFilters$|async)'>
    <ng-container *ngIf='{ filterInput: (item|typeScriptForHtmlForFilters:"ClassFilterInput"),
                           filterDateRange: (item|typeScriptForHtmlForFilters:"ClassFilterDateRange"),
                           filterDrop: (item|typeScriptForHtmlForFilters:"ClassFilterDrop")} as objItem'>

      <div *ngIf='item|valueFromSelectedFilter' class='wrapperFiltersSelected__item o-order-{{item.index}}'>
        {{item|valueFromSelectedFilter}}
        <!--          {{objItem.filterDrop?.noDelete}}-->
        <!--        <meSvg *ngIf='item.noDelete' svgName='cross_blueDark&24' [w]='14' (click)='filtersS.deleteSelectedFilter(item)'></meSvg>-->
        <meSvg *ngIf='!objItem.filterDrop?.noDelete' svgName='cross_blueDark&24' [w]='14'
               (click)='filtersS.deleteSelectedFilter(item)'></meSvg>
      </div>

    </ng-container>
  </ng-container>

  <div *ngIf='null|canResetFilters' class='wrapperFiltersSelected__clearFilters'
       (click)='onResetFilters()'>
    Clear Filters
  </div>
</div>

