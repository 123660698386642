<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
            } as obj' class='wrapperLinkPageSearchFilter'
     [class.wrapperLinkPageSearchFilter--noHaveLinkPage]='!haveLinkPage'>
  <!--  === linkPageWithSettings ===-->
  <ng-container *ngIf='haveLinkPage'>
    <linkPage *ngIf='obj.desktop' [arrLink]='arrLink' [link]='currentLinkDrop' (emit)='linkPageEmit($event)'
              [needUseUnsavedChanges]='needUseUnsavedChanges'></linkPage>
    <app-dropdown *ngIf='obj.mobile' [ngModel]='currentLinkDrop' [array]='arrLink'
                  (changeValObj)='linkPageEmit($event)' [needSortByName]='false'></app-dropdown>
  </ng-container>

  <!--  === filtersSearchWithSelectedIcon ===-->
  <div *ngIf='obj.mobile' class='wrapperLinkPageSearchFilter__filtersSearchWithSelectedIcon'>
    <ng-container *ngIf='!filterText' [ngTemplateOutlet]='filtersS.filtersRef!.filtersTemplate_searchRef!'></ng-container>
    <app-filtersSelectedIcon [filterText]="filterText"></app-filtersSelectedIcon>
  </div>

</div>