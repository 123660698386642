<div class='t-table__item__wrapCeil__ceilRow wrapperTableCeilUser' ceilTable [idxCeil]='0'>
  <meSvg *ngIf='deviceS.isDesktop' [svgName]='user.isSelect|getChxSvgName' class='t-table__item__wrapCeil__ceilRow__chx'
         (click)='meTableS.selectItem(user)' stopPropagation></meSvg>

  <photo [data]='user' (click)='emit.emit()'></photo>

  <div class='wrapperTableCeilUser__content'>
    <div class='wrapperTableCeilUser__content__name'>
      <div [class]='deviceS.isMobile ? "t-fontBold o-cur-pointer wrap-ellipsis" : "t-fontBold o-cur-pointer o-noWrap"' (click)='emit.emit()'>{{user.nameFormatted}}</div>
      <meSvg *ngIf='svgRight' [svgName]='svgRight' [matTooltip]='matTooltip_svgRight || ""' class='o-mg-l-5'></meSvg>
    </div>

<!--    <ng-container *ngIf='deviceS.isMobile'>-->
      <ng-content select='.ngContentTableCeilUser'></ng-content>
<!--    </ng-container>-->
  </div>

</div>