<div class='overview'>
  <h3 *ngIf='(deviceService.isDesktop$ | async) as obj' class='overview__head'>Overview</h3>
  <div class='overview__content'>
    <app-group-assignor-tiles></app-group-assignor-tiles>
  </div>
</div>

<div class='title-section'>
  <h4 class='table_head'>
    {{ getSelectedItem()?.title }}
    <p *ngIf='(deviceService.isMobile$ | async) as obj'>
      ({{ getSelectedItem()?.value }})
    </p>
  </h4>
  <btn [fontSize]='14' *ngIf='(deviceService.isDesktop$) | async' text='Manage All' bcg='blueDark'
       (emit)='manageAll()' [btnHeight]='36' [btnWidth]=' 105'></btn>
</div>
<app-group-assignor-games-table *ngIf='isGame$ | async' [settings]='settings'></app-group-assignor-games-table>
<app-group-assignor-table *ngIf='!(isGame$ | async)' [settings]='settings'></app-group-assignor-table>