import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DeviceService } from '@services/device.service';
import {
  arrTypeFilter,
  ClassFilterDateRange,
  ClassFilterDrop,
  ClassFilterInput,
  TKeyofSettingsRequest_forFilters,
  TypeFilter_dateRange,
  TypeFilter_drop,
  TypeFilter_string,
} from '@components/filters/filters';
import { FiltersService } from '@components/filters/filters.service';
import { debounceTime } from 'rxjs/operators';
import { AGE_ITEM, agesItems, ClassDrop, distanceItems } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { ClassSettingsRequest } from '@models/response-and-request';
import {
  arrPeriodsForCurrent,
  arrPeriodsForPast,
  customDateRange,
  IDatePeriod,
  TChoosePeriod,
} from '@components/__drop_inputs_matSelect/date-range/dateRange';
import { DateRangeService } from '@components/__drop_inputs_matSelect/date-range/date-range.service';
import { MeService } from '@services/me.service';
import { TAgeForOfficials } from '@app/dir_group_assignor/officials/officials';
import { arrPayoutFormatDrop } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { UtilsService } from '@services/utils.service';
import { IDateRange } from '@models/IDates';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { GetMatTooltipForSearchPipe } from '@components/filters/pipes/get-mat-tooltip-for-search.pipe';
import { ForFilterItemDirective } from '@components/filters/directives/for-filter-item.directive';
import { DateRangeComponent } from '@components/__drop_inputs_matSelect/date-range/date-range.component';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { SortByComponent } from '@components/sortBy/sort-by.component';
import {
  FiltersSelectedIconComponent,
} from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';
import { ContainerTooltipComponent } from '@components/__tooltip/container-tooltip/container-tooltip.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { TooltipDefaultComponent } from '@components/__tooltip/tooltip-default/tooltip-default.component';
import { TooltipSearchComponent } from '@components/__tooltip/tooltip-search/tooltip-search.component';
import { Router } from '@angular/router';
import { UnsavedChangesService } from '@services/unsaved-changes.service';
import { AssignComponent } from '@app/dir_group_assignor/assign/assign.component';
import { FiltersSelectedComponent } from '../components_additional/filters-selected/filters-selected.component';
import { AccountApiService } from '@services/account.api.service';

interface IFiltersForm {
  search: FormControl<string>;
  dateRange: FormControl<ClassFilterDateRange>;
  competitions: FormControl<Array<ClassDrop> | ClassDrop>;
  paymentMethod: FormControl<Array<ClassDrop> | ClassDrop>;
  status: FormControl<Array<ClassDrop> | ClassDrop>;
  [key: string]: FormControl<any>;
}

@UntilDestroy()
@Component({
  selector: 'app-add-filters',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, HeadTitleComponent, InputCtrlComponent, GetMatTooltipForSearchPipe, ForFilterItemDirective, DateRangeComponent, DropdownComponent, SortByComponent, FiltersSelectedIconComponent, BtnWrapComponent, BtnComponent, ContainerTooltipComponent, SvgComponent, TooltipDefaultComponent, TooltipSearchComponent, FiltersSelectedIconComponent, FiltersSelectedComponent],
  templateUrl: './add-filters.component.html',
  styleUrls: ['./add-filters.component.scss'],
  providers: [DateRangeService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddFiltersComponent implements OnInit, AfterViewInit {
  @ViewChild('inputField') inputField!: ElementRef;
  @HostBinding('class.o-none') get class_o_none(): boolean {
    const showFilters = this.deviceS.isDesktop$.getValue() || this.filtersS.showFilters_forMobile$.getValue();
    return !showFilters;
  }
  @ViewChild('dropdownMenu', { static: false }) dropdownMenu!: ElementRef;

  @ViewChild('filtersTemplate_searchRef') filtersTemplate_searchRef?: TemplateRef<any>;

  form!: FormGroup<IFiltersForm>;
  showFiltersDropdown: boolean = false;
  activeSubMenu: string | null = null;

  showAlwaysPlaceholder: boolean = true;
  settingsRequestForEmitter: ClassSettingsRequest = {};
  @Output() emit = new EventEmitter<ClassSettingsRequest>();
  @Input() assignComponent!: AssignComponent;
  showElement: boolean = true;
  selectedDateRange: string | null = null;
  selectedCompetition: string | null = null;
  selectedPaymentMethod: string | null = null;
  selectedStatus: string | null = null;




  constructor(
    public meS: MeService,
    public filtersS: FiltersService,
    public deviceS: DeviceService,
    private dateRangeS: DateRangeService,
    private settingsRequestS: SettingsRequestService,
    private elementRef: ElementRef,
    private cd: ChangeDetectorRef,
    private unsavedChangesService: UnsavedChangesService,
    private router: Router,
    private accountApiS: AccountApiService
  ) {
    this.filtersS.filterAddsRef = this;
    this.createForm();
  }

  ngOnInit() {
    this.subscribeToSelectedFilters();
    const currentUrl = this.router.url;
    if (currentUrl.includes('balances')) {
      this.showElement = false;
    }
    this.initializeSelectedFilters();
  }



  getCompetitionsList(): ClassDrop[] {
    const competitions = this.filtersS.findFilter('competitions')?.valueDrop;
    if (!competitions) return [];
    return Array.isArray(competitions) ? competitions : [competitions];
  }


  ngAfterViewInit() {
    if (this.inputField) {
      console.log('Input field is initialized', this.inputField);
    }
  }

  initializeSelectedFilters(): void {
    const dateRangeFilter = this.filtersS.findFilter("dateRange") as ClassFilterDateRange | undefined;
    const competitionFilter = this.filtersS.findFilter("competitions") as ClassFilterDrop | undefined;
    const paymentMethodFilter = this.filtersS.findFilter("paymentMethod") as ClassFilterDrop | undefined;


    // ✅ Ensure arrPeriods always contains the correct historical options
    if (dateRangeFilter) {
      dateRangeFilter.arrPeriods = [...ClassFilterDateRange.templatePreviosData];
    }

    // ✅ Restore the previously selected date range
    this.selectedDateRange = (dateRangeFilter?.period && dateRangeFilter.arrPeriods?.includes(dateRangeFilter.period))
      ? dateRangeFilter.period
      : null;

    // ✅ Restore selected competition
    const competitionValue = competitionFilter?.valueDrop;
    this.selectedCompetition = Array.isArray(competitionValue)
      ? competitionValue[0]?.upperCase ?? null
      : competitionValue?.upperCase ?? null;

    // ✅ Restore selected payment method
    const paymentValue = paymentMethodFilter?.valueDrop;
    this.selectedPaymentMethod = Array.isArray(paymentValue)
      ? paymentValue[0]?.upperCase ?? null
      : paymentValue?.upperCase ?? null;

    const statusOptions = [
      new ClassDrop({ titleCase: 'OPEN', upperCase: 'OPEN' }),
      new ClassDrop({ titleCase: 'PENDING', upperCase: 'PENDING' }),
      new ClassDrop({ titleCase: 'FAILED', upperCase: 'FAILED' }),
      new ClassDrop({ titleCase: 'SUCCEEDED', upperCase: 'SUCCEEDED' }),
      new ClassDrop({ titleCase: 'CANCELLED', upperCase: 'CANCELLED' }),
      new ClassDrop({ titleCase: 'REFUNDED', upperCase: 'REFUNDED' }),
      new ClassDrop({ titleCase: 'INCOMPLETE', upperCase: 'INCOMPLETE' }),
    ];

    const statusFilter = new ClassFilterDrop({
      typeFilter: 'status',
      arrayForDropdown: statusOptions,
    });

    // ✅ Update arrFiltersSub$ - remove existing status filter if any
    const existingFilters = this.filtersS.arrFiltersSub$.getValue()
      .filter(f => f.typeFilter !== 'status');

    // ✅ Add status filter
    this.filtersS.arrFiltersSub$.next([...existingFilters, statusFilter]);

    this.filtersS.updateFilter('status', {
      arrayForDropdown: statusOptions
    }, 'initializeSelectedFilters - Status Options');



    // ✅ Load payment methods dynamically
    const userId = this.meS.userId;
    this.accountApiS.getAccounts(userId).pipe(untilDestroyed(this)).subscribe((accounts) => {
      const paymentMethodOptions = accounts.map(account => new ClassDrop({
        titleCase: `****${account.last4}`,
        upperCase: account.id
      }));

      this.filtersS.updateFilter('paymentMethod', {
        arrayForDropdown: paymentMethodOptions
      }, 'initializeSelectedFilters - Payment Methods');
    });
  }




  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    if (
      this.showFiltersDropdown &&
      this.elementRef.nativeElement &&
      !this.elementRef.nativeElement.contains(event.target)
    ) {
      this.showFiltersDropdown = false;
      this.activeSubMenu = null;
    }
  }

  subscribeToSelectedFilters(): void {
    this.filtersS.arrFilters$
      .pipe(
        debounceTime(90),
        untilDestroyed(this),
      )
      .subscribe((arr) => {
        if (!arr?.length) return;

        const currentSettingsRequest = this.settingsRequestS.settings;

        arr.forEach((el) => {
          const typeFilter = el.typeFilter as TKeyofSettingsRequest_forFilters;

          // ✅ TYPE: dateRange filter check FIXED
          if (el.typeFilter === 'dateRange') {
            const filterDateRange = new ClassFilterDateRange(el as ClassFilterDateRange);
            const datePeriod: IDatePeriod = filterDateRange.period !== customDateRange
              ? this.dateRangeS.checkDatePeriod(filterDateRange, 'FiltersComponent')?.datePeriod!
              : filterDateRange.datePeriod!;
            this.ctrl.dateRange.patchValue(filterDateRange);
            currentSettingsRequest.from = datePeriod?.from instanceof Date ? datePeriod.from.toISOString() : datePeriod?.from || '';
            currentSettingsRequest.to = datePeriod?.to instanceof Date ? datePeriod.to.toISOString() : datePeriod?.to || '';

          }

          if (this.filtersS.is_TypeFilter_drop(typeFilter!)) {
            const valueDrop = (el as ClassFilterDrop).valueDrop;

            if (Array.isArray(valueDrop) && valueDrop.length) {
              const arrValues: string[] = valueDrop
                .map(drop => drop?.upperCase)
                .filter((val): val is string => !!val);

              if (el.typeFilter === 'paymentMethod') {
                currentSettingsRequest.paymentMethod = arrValues.length ? arrValues[0] : '';
              } else if (el.typeFilter === 'status') {
                currentSettingsRequest.status = arrValues.length ? arrValues[0] : '';
              } else if (el.typeFilter === 'competitions') {
                currentSettingsRequest.competitions = arrValues.join(',') || '';
              } else {
                currentSettingsRequest[typeFilter] = arrValues.join(',') as any;
              }
            } else if (valueDrop && !Array.isArray(valueDrop)) {
              const singleUpperCase = (valueDrop as ClassDrop).upperCase;
              if (el.typeFilter === 'paymentMethod') {
                currentSettingsRequest.paymentMethod = singleUpperCase || '';
              } else if (el.typeFilter === 'competitions') {
                currentSettingsRequest.competitions = singleUpperCase || '';
              } else if (el.typeFilter === 'status') {
                currentSettingsRequest.status = singleUpperCase || '';
              } else {
                currentSettingsRequest[typeFilter] = singleUpperCase as any;
              }
            } else {
              if (el.typeFilter === 'paymentMethod') {
                currentSettingsRequest.paymentMethod = '';
              } else if (el.typeFilter === 'competitions') {
                currentSettingsRequest.competitions = '';
              } else if (el.typeFilter === 'status') {
                currentSettingsRequest.status = '';
              }
            }
          }
        });

        this.cd.detectChanges();

        const updated_arrTypeFilter = [...arrTypeFilter, 'fromAge', 'toAge'];

        const existChanges_settingsOnlyForFilters = !UtilsService.compareTwoObjects(
          UtilsService.removeEmptyKeysFromObject(currentSettingsRequest),
          UtilsService.removeEmptyKeysFromObject(this.settingsRequestS.settings),
          updated_arrTypeFilter as Array<keyof ClassSettingsRequest>,
        );

        const current_dateRange: IDateRange = UtilsService.removeEmptyKeysFromObject({
          from: currentSettingsRequest.from,
          to: currentSettingsRequest.to,
        });
        const old_dateRange: IDateRange = UtilsService.removeEmptyKeysFromObject({
          from: this.settingsRequestS.settings.from,
          to: this.settingsRequestS.settings.to,
        });
        const existChanges_dateRange = !UtilsService.compareTwoObjects(current_dateRange, old_dateRange);

        const existChanges = existChanges_settingsOnlyForFilters || existChanges_dateRange;

        if (!this.filtersS.needUseDynamicFilters) {
          this.methodForEmitSettingRequest(UtilsService.deepClone(currentSettingsRequest));
          return;
        }

        if (!existChanges) return;

        currentSettingsRequest.typeEmitSetting = 'filters';
        this.settingsRequestS.updateSettings(currentSettingsRequest, 'filters');
        console.log('🔥 subscribeToSelectedFilters triggered with:', arr);
      });
  }


  changeFilter_TypeFilter_string(typeFilter_string: TypeFilter_string, valueString: string): void {
    if (!this.ctrl[typeFilter_string]) console.error('FiltersComponent changeFilter():', typeFilter_string, ' valueString:', valueString); // !!! NO DELETE
    this.filtersS.updateFilter(typeFilter_string, {
      valueString,
      forTest: ' changeFilter_TypeFilter_string ',
    }, ' changeFilter_TypeFilter_string ');
    this.ctrl[typeFilter_string].patchValue(valueString);
  }

  changeFilter_TypeFilter_drop(typeFilter_drop: TypeFilter_drop, filterDrop: Array<ClassDrop> | ClassDrop): void {

    this.activeSubMenu = null;
    this.showFiltersDropdown = false;

    const selected = Array.isArray(filterDrop) ? filterDrop[0]?.upperCase : (filterDrop as ClassDrop)?.upperCase;
    if (typeFilter_drop === 'competitions') {
      this.selectedCompetition = selected || null;
      console.log("🔥 Selected Competition Updated:", this.selectedCompetition);
    }
    if (typeFilter_drop === 'paymentMethod') {
      this.selectedPaymentMethod = selected || null;
      console.log("💳 Selected Payment Method Updated:", this.selectedPaymentMethod);
    }
    if (typeFilter_drop === 'status') {
      this.selectedStatus = selected || null;
    }


    const patchAndUpdate = () => {
      const control = this.ctrl[typeFilter_drop as keyof IFiltersForm] as FormControl<Array<ClassDrop> | ClassDrop>;
      if (!control) {
        console.error('FiltersComponent changeFilter(): Invalid Control for', typeFilter_drop);
        return;
      }
      this.filtersS.updateFilter(typeFilter_drop, { valueDrop: filterDrop }, 'changeFilter_TypeFilter_drop');
      control.patchValue(filterDrop);
    };

    if (this.unsavedChangesService.unsavedChanges) {
      this.unsavedChangesService.openPopup_filters().then((proceed: boolean) => {
        if (proceed) {
          this.assignComponent.assignOfficials(false);
          patchAndUpdate();
        } else {
          this.unsavedChangesService.unsavedChanges = false;
          patchAndUpdate();
        }
      });
    } else {
      patchAndUpdate();
    }
  }





  changeFilter_TypeFilter_dateRange(option: TChoosePeriod): void {
    this.activeSubMenu = null;
    this.showFiltersDropdown = false;

    // ✅ Store the selected option globally
    this.selectedDateRange = option;

    // ✅ Ensure arrPeriods always contains past options
    const responseDropDateRange = new ClassFilterDateRange({ period: option });
    responseDropDateRange.arrPeriods = [...ClassFilterDateRange.templatePreviosData];

    if (this.unsavedChangesService.unsavedChanges) {
      this.unsavedChangesService.openPopup_filters().then((proceed: boolean) => {
        if (proceed) {
          this.assignComponent.assignOfficials(false);
          this.updateDateRangeFilter(responseDropDateRange);
        } else {
          this.unsavedChangesService.unsavedChanges = false;
          this.updateDateRangeFilter(responseDropDateRange);
        }
      });
    } else {
      this.updateDateRangeFilter(responseDropDateRange);
    }
  }

  // 🔥 Always reset dropdown to past options
  private updateDateRangeFilter(responseDropDateRange: ClassFilterDateRange): void {
    responseDropDateRange.arrPeriods = ClassFilterDateRange.templatePreviosData;

    // ✅ Make sure we store correct selection in filtersS
    const updatedFindFilter = this.filtersS.updateFilter('dateRange', responseDropDateRange, 'changeFilter_TypeFilter_dateRange');
    this.ctrl.dateRange.patchValue(updatedFindFilter);
  }

  methodForEmitSettingRequest(currentSettingsRequest: ClassSettingsRequest): void {
    const existChanges = !UtilsService.compareTwoObjects(this.settingsRequestForEmitter, currentSettingsRequest);
    if (existChanges) {
      this.settingsRequestForEmitter = UtilsService.deepClone(currentSettingsRequest) as ClassSettingsRequest;
      this.emit.emit({ ...this.settingsRequestForEmitter, page: 0 });
    }
  }


  // === FORM ==================================
  createForm(): void {
    this.form = new FormGroup<IFiltersForm>({} as IFiltersForm);
    arrTypeFilter.forEach((el) => this.form.addControl(el, new FormControl()));
  }

  get ctrl(): IFiltersForm {
    return this.form?.controls;
  }


  handleFocus(): void {
    if (this.inputField) {
      this.inputField.nativeElement.focus();
    }
  }
  onResetFilters(): void {
    if (this.unsavedChangesService.unsavedChanges) {
      this.unsavedChangesService.openPopup_filters().then((proceed: boolean) => {
        if (proceed) {
          this.assignComponent.assignOfficials(false);
          this.filtersS.resetSelectedFilters();
        } else {
          this.unsavedChangesService.unsavedChanges = false;
          this.filtersS.resetSelectedFilters();
        }
      });
    } else {
      this.filtersS.resetSelectedFilters();
    }
  }



  toggleFiltersDropdown(): void {
    this.showFiltersDropdown = !this.showFiltersDropdown;
    this.activeSubMenu = null;
    if (this.showFiltersDropdown) {
     // this.initializeSelectedFilters();
    }
  }

  /**
   * Toggles the visibility of a submenu
   * @param menu Name of the submenu to open/close
   */
  toggleSubMenu(menu: string): void {
    this.activeSubMenu = this.activeSubMenu === menu ? null : menu;
  }
}
