<div *ngIf='{desktop: (deviceS.isDesktop$|async), mobile: (deviceS.isMobile$|async)} as obj'
     class='o-wrapPage wrapperDashboard'>
  <!--  <div class='wrapperDashboard__title'>{{null|getGreet}}</div>-->
  <app-greet class='o-header__title'></app-greet>
  <div class='subtitleDashboard'>{{null|getSubtitleDashboard}}</div>

  <div class='wrapBlockDashboard' [class.wrapBlockDashboard--desktop]='obj.desktop'>
    <div *ngFor='let item of (dashboardS.arrBlockDashboard$|async)'
         class='blockDashboard {{item.active && !item.skipped ? "blockDashboard--active":""}}
                               {{item.completed && !item.skipped ? "blockDashboard--completed":""}}
                               {{!item.active && !item.completed && !item.skipped ? "blockDashboard--disabled":""}}'>
      <div class='blockDashboard__title'>{{item.title}}<span>{{item.required ? '*' : ''}}</span></div>
      <div class='blockDashboard__text'>{{item.text}}</div>
      <div class='blockDashboard__btns'>
        <btn [text]='item.completed && !item.skipped ? "Completed" : item.btn_text!'
             [bcg]='item.completed && !item.skipped ? "newGreen":"white"'
             [svgRight]='item.completed && !item.skipped ? "circle_chx1&24":"arrow_chevronRight&24"'
             (emit)='item.action!()' [btnHeight]='obj.mobile ? 36:44'
             [disabled]='!item.active && !item.skipped'></btn>

        <div *ngIf='!item.required && !item.completed' class='skipTheStep o-noWrap {{item.active ? "o-cur-pointer":""}}'
             (click)='item.active ? dashboardS.nextStep(item.step, true) : null'>Skip Step
        </div>
      </div>
    </div>
  </div>
</div>
