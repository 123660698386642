import { MainService } from './main.service';
import { Injectable } from '@angular/core';
import { WebSocketSubject } from 'rxjs/webSocket';
// declare var StompJs: any;
// import { Client, Message } from '@stomp/stompjs';
import { Stomp } from '@stomp/stompjs';
import { MeService } from '@services/me.service';

declare var SockJS: any;
// import * as Stomp from '@stomp/stompjs';
// import SockJS from 'sockjs-client';

@Injectable({ providedIn: 'root' })
export class WebsocketService {
  balanceSocket!: WebSocketSubject<any>;
  // wsConfig: WebSocketSubjectConfig<any> = { url: this.createAddress() + '/api/chat' };
  public socket: any = null;
  public stompClient: any = null;
  public stompClient1: any = null;
  public stompClientLog: any = null; // activity log
  public stompClientMessages: any = null;
  public msg = [];
  interval: any = '';
  interval1: any = '';
  intervalMessage: any = '';
  data: any = null;
  client: any = null;

  dataMessages: any = null;

  constructor(
    private mainS: MainService,
    private meS: MeService,
  ) {
  }

  // createWebSocket(): void {
  //   this.socket = new SockJS('/api/chat');
  //   this.stompClient = Stomp.over(this.socket);
  //   this.stompClient.configure({
  //     reconnectDelay: 5000,
  //   });
  // }

  // for balance
  connect() {
    // this.interval = setInterval(() => {
    // if (!this.socket) {
    //   this.createWebSocket();
    // }
    // if (this.stompClient != null) {
    //   this.stompClient.disconnect();
    // }
    const socket = new SockJS('/api/chat');
    // this.socket = new SockJS('/api/chat');
    this.stompClient = Stomp.over(socket);
    this.stompClient.debug = () => {
    }; // выключить консоль логи в браузере
    this.stompClient.configure({
      reconnectDelay: 5000,
    });
    const header = {
      Authorization: `Bearer ${localStorage.getItem('jwt_token')!}`,
    };

    // this.stompClient.connect(header, (frame: any) => {
    //   this.stompClient.subscribe(
    //     '/user/queue/reply',
    //     (hello: any) => {
    //       this.data = JSON.parse(hello.body); // {OFFICIAL: {available: 0, payoutsDue: 0, pending: 0}, LEAGUE_ADMIN: {available: 0, payoutsDue: 0, pending: 0}}
    //       if (!this.meS.me) {
    //         return;
    //       }
    //       const role = this.meS.me?.roleCurrent;
    //       if (!role) {
    //         console.error('me?.roleCurrent :','err', role);
    //         return;
    //       }
    //
    //       this.meS.availableBalanceIsColorRed.next(this.data[role].lowBalance);
    //       delete this.data[role].lowBalance;
    //
    //       let tmpBalance = this.data[role];
    //       for (let key in this.data[role]) {
    //         tmpBalance[key] = this.data[role][key] / 100;
    //         tmpBalance[key] = this.data[role][key].toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    //       }
    //
    //       this.meS.setBalance(tmpBalance);
    //     },
    //     header,
    //   );
    // });
  }

  reloadActivityLog(): void {
    this.stompClientLog.disconnect();
    this.connectActivity();
  }

  // for activity log
  connectActivity() {
    // if (this.stompClientLog != null) this.stompClientLog.disconnect();
    // const socket = new SockJS('/api/activitylogservice'); /////////
    // this.stompClientLog = Stomp.over(socket);
    // this.stompClientLog.debug = () => {
    // }; // выключить консоль логи в браузере
    // this.stompClientLog.configure({ reconnectDelay: 5000 });
    // const header = { Authorization: `Bearer ${localStorage.getItem('jwt_token')!}` };
    // this.stompClientLog.connect(header, (frame: any) => {
    //   this.stompClientLog.subscribe('/user/activity/reply',
    //     (res: any) => {
    //       const data = JSON.parse(res.body);
    //       this.meS.setLog(data);
    //     },
    //     header,
    //   );
    // });
  }

  // for messages
  createSocketMessages(): void {
    // const socket = new SockJS('/api/messageservice');
    // this.stompClientMessages = Stomp.over(socket);
    // this.stompClientMessages.debug = () => {
    // }; // выключить консоль логи в браузере
    // this.stompClientMessages.configure({ reconnectDelay: 5000 });
    // const header = { Authorization: `Bearer ${localStorage.getItem('jwt_token')!}` };
    //
    // this.stompClientMessages.connect(header, (frame: any) => {
    //   this.stompClientMessages.subscribe(
    //     '/user/chats/reply',
    //     (res: any) => {
    //       this.dataMessages = JSON.parse(res.body);
    //       if (!this.meS.me) return;
    //       this.meS.setMessages(this.dataMessages);
    //     },
    //     header,
    //   );
    // });
  }

  sendToServerForMessage(): void {
    this.stompClient1.send('/user/chats/reply', {}, 'Some message');
  }

  // disconnect() {
  //     if (this.stompClient != null) {
  //         this.stompClient.disconnect();
  //     }
  //
  //     this.setConnected(false);
  // }
  //
  // createAddress(): string {
  //     let hostStr = '';
  //     if (window.location.host.includes('localhost')) {
  //         hostStr = 'ws://3.135.188.127:8080';
  //     } else {
  //         hostStr = 'ws://3.135.188.127:8080';
  //     }
  //
  //     //  return 'wss://' + hostStr;
  //     return hostStr;
  // }

  setConnected(connected: boolean) {
    //  this.disabled = !connected;

    if (connected) {
      //  this.greetings = [];
    }
  }

  sendToServer(): void {
    this.stompClient1.send('/user/activity/reply', {}, 'Some message');
  }

  createSocket(): void {
    this.connect();
    this.connectActivity();
  }

  // ========================
  // sendToServer(sendObj: any): void {
  //   this.stompClient1.send('/app/activitylogservice', {}, JSON.stringify(sendObj));
  // }

  // sendSum(sum: any): void {
  //     const data = { sum };
  //     this.balanceSocket.next(data);
  // }
}
