<div #wrapperAuthRef class='wrapperAuth'>
  <div class='testMatMenu' [matMenuTriggerFor]='menu'></div>
  <mat-menu #menu='matMenu'>
    <div (click)='forTestDeleteUser()'>delete me user</div>
  </mat-menu>

  <img #imageBcgRef src='assets/bcgLogo/bcgLogo_login.svg' alt='' class='wrapperAuth__imageBcg'>

  <div #contentAuthRef class='contentAuth'>
    <router-outlet></router-outlet>
  </div>
</div>
