import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { HelperClass } from '@classes/Helper-Classes';

@Component({
  selector: 'app-popup-switch-payout',
  standalone: true,
  imports: [CommonModule, HeadTitleComponent, FieldComponent, DropFormCtrlComponent, ReactiveFormsModule, FormsModule, InputCtrlComponent, BtnWrapComponent, BtnComponent, DropdownComponent],
  templateUrl: './popup-switch-payout.component.html',
  styleUrls: ['./popup-switch-payout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class PopupSwitchPayoutComponent extends HelperClass implements OnInit {
  currentType: 'weekly' | 'biweekly' | 'monthly' | 'biweekly_transition_to_monthly' | 'weekly_transition_to_monthly' | null;
  newType: 'weekly' | 'biweekly' | 'monthly' | 'biweekly_transition_to_monthly' | 'weekly_transition_to_monthly' | null;

  constructor(
    public dialogRef: MatDialogRef<PopupSwitchPayoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { currentType: 'weekly' | 'biweekly' |'monthly' | 'biweekly_transition_to_monthly' | 'weekly_transition_to_monthly' | null; newType: 'weekly' | 'biweekly' | 'monthly'  | 'biweekly_transition_to_monthly' | 'weekly_transition_to_monthly' },
    public cd: ChangeDetectorRef
  ) {
    super(cd);
    this.currentType = data.currentType;
    this.newType = data.newType;
    this.currentType = data?.currentType
    this.newType = data?.newType 
  }



  ngOnInit() {
  }
  

  confirmChange(): void {
    this.dialogRef.close(true); 
  }

  closePopup(): void {
    this.dialogRef.close(false); 
  }
}