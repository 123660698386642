import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ControlValueAccessor, FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { OtherService } from '@services/other.service';
import { MainService } from '@services/main.service';
import { TClassForText } from '@models/ICssStyles';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';

@Component({
  selector: 'toggle',
  standalone: true,
  imports: [CommonModule, MatSlideToggleModule, ReactiveFormsModule, FormsModule, SvgComponent, SvgAndTextComponent],
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent implements ControlValueAccessor, OnChanges, AfterViewInit {
  @Input() text = '';
  @Input() typeText: TClassForText = 'text7-blueDark';
  @Input() isBlue = false;

  // === FOR [(ngModel)] ===========================
  @Input() isNgModel = false; // !!! использовать если нет formControlName, тоесть если [(ngModel)]='' (если ngModel не забывать [ngModelOptions]='{standalone: true}')
  @Input() disabled = false; // !!! использовать если ngModel==true

  @Output() changeVal = new EventEmitter<boolean>();

  private onChange!: (value: boolean) => void;
  private onTouched!: () => void;

  constructor(
    public otherS: OtherService,
    public mainS: MainService,
    public ngControl: NgControl,
    public elRef: ElementRef,
    private cd: ChangeDetectorRef,
  ) {
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isNgModel && changes?.disabled?.currentValue) {
      this.setDisabledState(this.disabled);
    }
  }

  ngAfterViewInit() {
    if (this.ngControl?.control) {
      if (this.isNgModel) return; // !!! если true , то значит использовал ngModel. Тоесть из formControl не надо доставать значение
      this.setDisabledState(this.ngControl.control.disabled);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.detectChanges();
  }

  writeValue(value: boolean): void {
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  // === GETTERS & SETTERS =======================
  set value(value: boolean) {
    // this.ngControl.control?.setValue(value); !!! эта штука не нужна, она дублировала функцию onChange и в результате получался двойной вызов в valueChanges
    this.onChange(value); // чтобы в родит.компонент передать
    this.onTouched(); // чтобы в родит.компонент передать
    this.changeVal.emit(value);
    this.cd.detectChanges();
  }

  get value(): boolean {
    return this.ngControl.control?.value;
  }
}
