import { CommonModule, CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ClassPayMethodAndAmount, IPaymentMethod, TypePay } from '@app/dir_group_assignor/payments/modelsForPayment';
import { PaymentService } from '@app/dir_group_assignor/payments/payment.service';
import { HelperClass } from '@classes/Helper-Classes';
import { PopupPaymentMethodComponent } from '@components/__popup-windows/popup-payment-metod/popup-payment-method.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { BtnComponent } from '@components/btn/btn.component';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { StatusComponent } from '@components/status/status.component';
import { StripeFormComponent } from '@components/stripe-form/stripe-form.component';
import { ACCOUNT_EXTERNAL_FIELDS, AccountExternalModel } from '@models/account-external.model';
import { AccountApiService } from '@services/account.api.service';
import { MeService } from '@services/me.service';
import { OtherService } from '@services/other.service';
import { catchError, finalize, of, throwError } from 'rxjs';
import { PhotosComponent } from '@components/__svg_img/photos/photos.component';
import { MatMenuModule } from '@angular/material/menu';
import { PaginationComponent } from '@components/pagination/pagination.component';
import { NothingListComponent } from '@components/nothing-list/nothing-list.component';
import { FormsModule } from '@angular/forms';
import { IDataPopup, PopupService } from '@services/popup.service';
import { AccountPaymentComponent } from '@components/account-payment/account-payment.component';
import { SwitcherBtnComponent } from '@components/switcher-btn/switcher-btn.component';
import { GetFirstLetterPipe } from '@pipes/get-first-letter.pipe';
import { GetAgeGenderLevelPipe } from '@pipes/get-age-gender-level.pipe';
import { GetLocNameCourtNamePipe } from '@pipes/location/get-loc-name-court-name.pipe';
import { GetLocationStringPipe } from '@pipes/location/get-location-string.pipe';
import { MatTooltipForLocationPipe } from '@pipes/location/mat-tooltip-for-location.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DateFormattedComponent } from '@components/__date/date-formatted/date-formatted.component';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { MyWalletService } from '@app/dir_officials/page-my-wallet/my-wallet.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceService } from '@services/device.service';
import { MyWalletTableOfficialComponent } from '../my-wallet-table-official/my-wallet-table-official.component';
import { MainService } from '@services/main.service';

@UntilDestroy()
@Component({
  selector: 'app-myWallet',
  standalone: true,
  templateUrl: './my-wallet-official.component.html',
  styleUrls: ['./my-wallet-official.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, HeadTitleComponent, InputCtrlComponent, SvgComponent, BtnComponent, SpinnerComponent, PhotosComponent,
    MatMenuModule, PaginationComponent, NothingListComponent, FormsModule, AccountPaymentComponent, SwitcherBtnComponent, CurrencyPipe,
    StatusComponent, StripeFormComponent, GetFirstLetterPipe, GetAgeGenderLevelPipe, GetLocNameCourtNamePipe, GetLocationStringPipe,
    MatTooltipForLocationPipe, MatTooltipModule, DateFormattedComponent, RouterLink, MyWalletTableOfficialComponent, WrapPageDirective],
})
export class MyWalletOfficialComponent extends HelperClass implements OnInit {
  accounts: AccountExternalModel[] = [];
  accountDefault?: AccountExternalModel;
  balance = 0.00;
  balanceInstant = 0.00;
  intransit= 0;
  totalAssigningFees: number | undefined = 0;


  autoPayoutEnabled = false;
  autoPayoutType: 'weekly' | 'monthly' | null = null;
  showAddNewCard = false;
  selectedMethod?: IPaymentMethod;
  payMethodAndAmount: ClassPayMethodAndAmount = new ClassPayMethodAndAmount();

  constructor(
    public router: Router,
    public myWalletS: MyWalletService,
    private popupS: PopupService,
    private accountApiS: AccountApiService,
    public meS: MeService,
    public paymentS: PaymentService,
    public otherS: OtherService,
    public deviceS: DeviceService,
    public mainS: MainService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
  }

  ngOnInit() {
    this.getAccounts();
    this.getBalanceInstant();
    this.getBalance();
    this.getScheduledPayout();
    this.getTotalAssigningFees();
  }

  getBalance(): void {
    this.accountApiS.getBalance().pipe(untilDestroyed(this))
      .subscribe(balance => {
         this.balance = balance/100;
        this.cd.detectChanges();
      });
  }

  private getTotalAssigningFees(): void {
    this.mainS.getTotalAssigningFees({}) 
      .pipe(
        untilDestroyed(this),
        catchError(err => {
          console.error('Failed to fetch total assigning fees:', err);
          return of(undefined);
        }),
        finalize(() => this.cd.detectChanges())
      )
      .subscribe(fees => {
        this.totalAssigningFees = fees ? fees / 100 : 0;
      });
  }
  

  getBalanceInstant(): void {
    this.accountApiS.getBalanceInstant().pipe(untilDestroyed(this))
      .subscribe(response => {
      //  this.balance = response.standardAvailable.amount;
        this.intransit = response.inTransit.amount;
        this.balanceInstant = response.instantAvailable.amount;
        this.cd.detectChanges();
      });
  }
  onPaymentMethodActionClick(): void {
    let options: IDataPopup = { width: '480px' };
    if (this.accounts.length) {
      options.isEdit = true;
      options.accounts = this.accounts;
    } else {
      options.isSelectPaymentType = true;
    }
    this.popupS.open$(PopupPaymentMethodComponent, options).pipe(untilDestroyed(this))
      .subscribe(() => {
        this.getAccounts();
      });
  }

  selectPayMethod(isSelect: boolean, payMethod: IPaymentMethod, typePay?: TypePay | string): void {
    if (isSelect) {
      const newMethods = this.paymentS.methodsSub$.getValue().map((el) => {
        if (el.id != payMethod.id) {
          return { ...el, isSelect: false };
        }
        return el;
      });
      this.paymentS.methodsSub$.next(newMethods);
      this.selectedMethod = this.paymentS.methodsSub$.getValue().find(el => el.isSelect);
      this.cd.detectChanges();
    }
  }

  getCurrentMethods(addNewMethod?: IPaymentMethod): void {
    if (addNewMethod) {
      addNewMethod.isSelect = true;
    }
    this.paymentS.getCurrentMethods(addNewMethod);
    const methods = this.paymentS.methodsSub$.getValue();
    if (methods.length) {
      this.payMethodAndAmount = new ClassPayMethodAndAmount(methods[0], 0);
    }
    this.showAddNewCard = false;
    if (addNewMethod) {
      this.selectPayMethod(true, { ...addNewMethod, isSelect: true });
    }
    window.scrollTo(0, 0);
    setTimeout(() => this.cd.detectChanges());
  }

  onPayout(): void {
    this.router.navigate(['/myWalletOfficial/payment-method'], {
      queryParams: {
        isEdit: true,
        isPayout: true,
        accounts: JSON.stringify(this.accounts), // Convert array to string
        balance: this.balance,
        balanceInstant: this.balanceInstant
      }
    });
  }


  goToSchedulePage(): void {
    this.router.navigate(['/myWalletOfficial/payment-method-schedule'], {
      queryParams: {
        isEdit: true,
        isPayout: true,
        accounts: JSON.stringify(this.accounts),
        balance: this.balance,
        balanceInstant: this.balanceInstant
      }
    });
  }


  goToManagePage(): void {
    this.router.navigate(['/myWalletOfficial/manage-payment-method'], {
      state: { accountDefault: this.accountDefault }
    });
  }

  private getAccounts() {
    if (this.startLoading()) return;
    this.accountApiS.getAccounts(this.meS.meId)
      .pipe(
        catchError(err => {
          this.otherS.showNotification(false, undefined, err);
          this.endLoading();
          return throwError(() => err);
        }),
        finalize(() => this.endLoading()),
        untilDestroyed(this),
      )
      .subscribe(accounts => {
        this.accounts = accounts;
        this.accountDefault = this.accounts.find(it => it[ACCOUNT_EXTERNAL_FIELDS.DEFAULT]);

        this.cd.detectChanges();
      });
  }

  private getScheduledPayout(): void {
    this.mainS.getScheduledPayout()
      .pipe(
        catchError(error => {
          if (error.status === 404) {
            console.log('No scheduled payout found');
            this.autoPayoutEnabled = false;
            this.autoPayoutType = null;
          }
          return of(null);
        }),
        untilDestroyed(this),
        finalize(() => this.cd.detectChanges())
      )
      .subscribe(response => {
        if (response && response.enabled) {
          this.autoPayoutEnabled = true;
          this.autoPayoutType = response.type.toLowerCase() as 'weekly' | 'monthly';
        } else {
          this.autoPayoutEnabled = false;
          this.autoPayoutType = null;
        }
      });
  }

}
