import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { RouterLink } from '@angular/router';
import { TSvgName } from '@components/__svg_img/svg/forSvg';

@Component({
  selector: 'error[text]',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  standalone: true,
  imports: [CommonModule, SvgComponent, RouterLink],
})
export class ErrorComponent {
  @Input() text!: string;
  @Input() svgAltText?: TSvgName;
  @Input() centerText = false;

  @Input() link?: string; // передать ссылку если после текста об ошибке надо текст-ссылку добавить
  @Input() textLink?: string;
}
