import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TColor } from '@models/ICssStyles';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class LineComponent {
  // color: TColor = 'grey_2';
}
