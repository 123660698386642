import { inject, Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { UnsavedChangesService } from '@services/unsaved-changes.service';
import { PopupService } from '@services/popup.service';
import { DeviceService } from '@services/device.service';

export interface CanComponentDeactivate<T> {
  // canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
  canDeactivate: () => Promise<boolean> | boolean;
}

@Injectable({ providedIn: 'root' })
export class UnsavedChangesGuard implements CanDeactivate<CanComponentDeactivate<unknown>> {
  private unsavedChangesS = inject(UnsavedChangesService);
  private popupS = inject(PopupService);

  constructor(
    private deviceS: DeviceService,
  ) {
  }

  canDeactivate(): Promise<boolean> | boolean {
    const canDeactivate = this.method_canDeactivate ? this.method_canDeactivate() : true;
    if (canDeactivate instanceof Promise) {
      canDeactivate.then((result: boolean) => this.unsavedChangesS.unsavedChanges = !result);
    }
    return canDeactivate;
  }

  private method_canDeactivate(): Promise<boolean> | boolean {
    if (this.unsavedChangesS.unsavedChanges) return this.popupS.openPopupUnsaved(this.deviceS.isMobile);
    return true;
  }

  // canDeactivate(component: CanComponentDeactivate<unknown>): Observable<boolean> | Promise<boolean> | boolean {
  //   const canDeactivate = component.canDeactivate ? component.canDeactivate() : true;
  //   if (canDeactivate instanceof Promise) {
  //     canDeactivate.then((result: boolean) => {
  //       // this.unsavedChangesS.setUnsavedChanges(!result);
  //       this.unsavedChangesS.unsavedChanges = !result;
  //     });
  //   }
  //   return canDeactivate;
  // }

}
