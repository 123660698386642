import { ClassDrop, preparePropertyFromDropForSendToServer } from '@components/__drop_inputs_matSelect/dropdown/dropdown';

export class ClassAdjustmentStatus extends ClassDrop {
  id?: string; // "406f33f3-a494-4d44-b9fc-b3ce11c04061",
  name?: string; // "Adjustment Status",
  officialPay?: number; // 100.00,
  assignorFee?: number; // 50.00,
  active?: boolean; // true
  inUse?: boolean; // !!! используется ли данный AdjustmentStatus в играх. Если да, то запретить редактирование active и удаление

  // !!! for report.game.gameAdjustmentStatus для деталки репорта в блоке CANCELLED DETAILS
  adjustmentStatusId?: string;
  adjustmentStatusName?: string;
  // officialPay?: number; // 100.00,
  // assignorFee?: number; // 50.00,
  cancelDate?: string;
  cancelReason?: string;

  constructor(gameAdjustmentStatus?: ClassAdjustmentStatus) {
    // if (!gameAdjustmentStatus) return
    super({
      titleCase: gameAdjustmentStatus?.name || gameAdjustmentStatus?.adjustmentStatusName,
      upperCase: gameAdjustmentStatus?.id || gameAdjustmentStatus?.adjustmentStatusId,
    });
    // if (!adjustmentStatus) console.error('======================================= :', adjustmentStatus)
    // if (!gameAdjustmentStatus) return {};
    if (gameAdjustmentStatus) {
      Object.entries(gameAdjustmentStatus)?.forEach((el) => {
        const key = el[0] as keyof ClassAdjustmentStatus;
        this[key] = el[1];
      });
    }

    // if (!gameAdjustmentStatus) console.error('gameAdjustmentStatus :', gameAdjustmentStatus);
    // if (gameAdjustmentStatus) console.log('gameAdjustmentStatus :', Object.entries(gameAdjustmentStatus));
    // !!! for report.game.gameAdjustmentStatus для деталки репорта в блоке CANCELLED DETAILS
    if (!this.id && gameAdjustmentStatus?.adjustmentStatusId) this.id = gameAdjustmentStatus?.adjustmentStatusId; // чтобы в дропдауне показать выбраный на странице деталки репорта в блоке CANCELLED DETAILS
    if (!this.id && gameAdjustmentStatus?.adjustmentStatusId) console.error('this.id :', this.id);
    // if (!this.name && gameAdjustmentStatus?.adjustmentStatusName) this.name = gameAdjustmentStatus?.adjustmentStatusName;
    // console.error('adjustmentStatus :', adjustmentStatus, ' RESULT :', this)
    // return UtilsService.removeEmptyKeysFromObject(this)
  }

  // !!! в деталке репорта в блоке CANCELLED DETAILS надо отправлять на сервер новый интерфейс (например adjustmentStatusId вместо id)
  // static prepare_for_report_game_adjustmentStatus(obj: ClassAdjustmentStatus): ClassAdjustmentStatus {
  //   if (!obj) return {};
  //   if (obj.id && !obj.adjustmentStatusId) obj.adjustmentStatusId = obj.id;
  //   return preparePropertyFromDropForSendToServer(obj);
  // }

  static preparePropertyFromDropForSendToServer(obj?: ClassAdjustmentStatus, forTest?: string): ClassAdjustmentStatus {
    if (!obj) return {};
    delete obj.upperCase;
    delete obj.titleCase;
    delete obj.lowerCase;
    const result = preparePropertyFromDropForSendToServer(obj, undefined, forTest);
    // if (forTest) console.log('preparePropertyFromDropForSendToServer :', forTest, Object.entries(result))
    return result;
  }
}

// export type ClassAdjustmentStatus_forGame = Pick<ClassAdjustmentStatus, 'adjustmentStatusName' | 'adjustmentStatusId' | 'cancelReason' | 'cancelDate'>
// export class ClassAdjustmentStatus_forGame extends ClassDrop {
// export class ClassAdjustmentStatus_forGame {
//   // !!! id => это для того чтобы записать сюда из adjustmentStatusId при получении с сервера из report.game.gameAdjustmentStatus
//   // !!! потому что для дропдауна на странице деталки репорта в блоке CANCELLED DETAILS нужно показать в дропдауне выбраный adjustmentStatus. А вдропдауне сверка происходит по id
//   id?: string;
//
//   adjustmentStatusId?: string; // !!! НЕ приходит здесь report.game.gameAdjustmentStatus
//   adjustmentStatusName?: string;
//   officialPay?: number; // 100.00,
//   assignorFee?: number; // 50.00,
//   cancelDate?: string;
//   cancelReason?: string;
//   constructor(adjustmentStatus?: ClassAdjustmentStatus_forGame) {
//     // super({ titleCase: adjustmentStatus?.adjustmentStatusName, upperCase: adjustmentStatus?.adjustmentStatusId });
//     // !!! НЕ приходит здесь report.game.gameAdjustmentStatus => поэтому Тимур сказал по имени делать поиск в дропдауне в reportInfo 16/05/24
//     // super({ titleCase: adjustmentStatus?.adjustmentStatusName, upperCase: adjustmentStatus?.adjustmentStatusName });
//     if (!adjustmentStatus) return {};
//     Object.entries(adjustmentStatus)?.forEach((el) => {
//       const key = el[0] as keyof ClassAdjustmentStatus_forGame;
//       this[key] = el[1];
//     });
//     this.id = adjustmentStatus?.adjustmentStatusId
//   }
// }

export const keyOfClassAdjustmentStatus: Array<keyof ClassAdjustmentStatus> = ['id', 'name', 'officialPay', 'assignorFee', 'active'];

// export type TAdjustmentStatus_forHtml = '1/3 Assignor Fee' | '1/2 Assignor Fee' | '2/3 Assignor Fee' | 'Full'
//   | 'Club/League Reschedule (Officials Assigned)' | 'Team Forfeit (Officials On-Site)' | 'Weather/Match Began';
// export type TAdjustmentStatus = 'ASSIGNOR_FEE_1_3' | 'ASSIGNOR_FEE_1_2' | 'ASSIGNOR_FEE_2_3' | 'FULL'
//   | 'CLUB_LEAGUE_RESCHEDULE_OFFICIALS_ASSIGNED' | 'TEAM_FORFEIT_OFFICIALS_ON_SITE' | 'WEATHER_MATCH_BEGAN';
// export const arrAdjustmentStatusString: Array<TAdjustmentStatus> = [
//   'ASSIGNOR_FEE_1_3', 'ASSIGNOR_FEE_1_2', 'ASSIGNOR_FEE_2_3', 'FULL', 'CLUB_LEAGUE_RESCHEDULE_OFFICIALS_ASSIGNED',
//   'TEAM_FORFEIT_OFFICIALS_ON_SITE', 'WEATHER_MATCH_BEGAN',
// ];
// export const arrAdjustmentStatusDrop: Array<ClassAdjustmentStatus> = arrAdjustmentStatusString.map(el => new ClassAdjustmentStatus(el));

