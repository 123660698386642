import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceService } from '@services/device.service';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { FormsModule } from '@angular/forms';
import { ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { UnsavedChangesService } from '@services/unsaved-changes.service';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';

@Component({
  selector: 'linkPage[arrLink][link]',
  standalone: true,
  imports: [CommonModule, DropFormCtrlComponent, FormsModule, DropdownComponent],
  templateUrl: './link-page.component.html',
  styleUrls: ['./link-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkPageComponent<T extends ClassDrop> {
  @Input() arrLink: Array<T> = [];
  @Input() link?: T; // !!! текущий выбраный
  @Input() needUseUnsavedChanges = false; // !!! запретить переключать вкладку если есть НЕсохраненные данные на текущей странице
  @Output() emit = new EventEmitter<T>();

  constructor(
    public deviceS: DeviceService,
    private unsavedChangesS: UnsavedChangesService,
  ) {
  }

  onClickItem(item: T): void {
    if (this.link?.upperCase === item?.upperCase) return; // !!! если нажал на текущий выбраный то не надо эмитить
    if (this.needUseUnsavedChanges && this.unsavedChangesS.unsavedChanges) { // !!! запретить переключать вкладку если есть НЕсохраненные данные на текущей странице
      this.unsavedChangesS.openPopup().then((res) => {
        if (res) this.changeLink(item); // !!! разрешил переключить вкладку в попап окне
      });
    } else {
      this.changeLink(item);
    }
  }

  private changeLink(item: T): void {
    this.link = item;
    this.emit.emit(this.link);
  }
}
