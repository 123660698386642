import { Pipe, PipeTransform } from '@angular/core';
import { ClassFilterDateRange, ClassFilterDrop, ClassFilterInput, TAllInterfacesFilters } from '@components/filters/filters';

type TFor_setTypePipe = 'ClassFilterInput' | 'ClassFilterDateRange' | 'ClassFilterDrop';

@Pipe({ name: 'typeScriptForHtmlForFilters', standalone: true })
export class TypeScriptForHtmlForFiltersPipe implements PipeTransform {

  transform(item: TAllInterfacesFilters, type: 'ClassFilterInput'): ClassFilterInput
  transform(item: TAllInterfacesFilters, type: 'ClassFilterDateRange'): ClassFilterDateRange
  transform(item: TAllInterfacesFilters, type: 'ClassFilterDrop'): ClassFilterDrop
  transform(item: TAllInterfacesFilters, type: TFor_setTypePipe): TAllInterfacesFilters {
    return item;
  }

}
