import { Injectable } from '@angular/core';
import { TypePageProfile } from '@app/profile/profile';
import { ProfileService } from '@app/profile/profile.service';

@Injectable({ providedIn: 'root' })
export class MeProfileOfficialService {
  readonly typePageProfile: TypePageProfile = 'meProfileOfficial';

  constructor(
    private profileS: ProfileService,
  ) {
  }
}
