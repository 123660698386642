import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { OtherService } from '@services/other.service';
import { UtilsService } from '@services/utils.service';

export interface RequestOptions {
  error?: { message: string };
  headers?: HttpHeaders | { [header: string]: string | string[] };
  params?: HttpParams | { [param: string]: string | string[] };
  reportProgress?: boolean;
  withCredentials?: boolean;
  responseType?: any;
  observe?: any;
}

export interface INewRequest {
  message: string;
  responseBody: any;
  status: number;
}

export type THttpMethod = 'get' | 'post' | 'patch' | 'put' | 'delete';
@Injectable({ providedIn: 'root' })
export class BaseApi {
  private baseUrl: string = environment.apiUrl;

  private forDownloadCSV = false;

  constructor(
    public http: HttpClient,
    public otherS: OtherService,
  ) {
  }

  private getUrl(url: string = ''): string {
    return this.baseUrl + url;
  }

  private getOptions(tempOptions: any): RequestOptions {
    const options: RequestOptions = {};
    if (tempOptions.headers && Object.keys(tempOptions.headers).length) options.headers = new HttpHeaders(tempOptions.headers);
    if (tempOptions.params && Object.keys(tempOptions.params).length) options.params = tempOptions.params;
    if (tempOptions.withCredentials) options.withCredentials = tempOptions.withCredentials;
    if (tempOptions.reportProgress) options.reportProgress = tempOptions.reportProgress;
    if (tempOptions.observe) options.observe = tempOptions.observe;
    if (tempOptions.responseType) options.responseType = tempOptions.responseType;
    return options;
  }

  public get(url: string = '', options = {}): Observable<any> {
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    options = {
      headers: headers,
      ...options
    };
    return this.prepareResponse('get', url, {}, options);
  }

  public post(url: string = '', data: any = {}, options = {}): Observable<any> {
    return this.prepareResponse('post', url, data, options);
  }

  public patch(url: string = '', data: any, options = {}): Observable<any> {
    return this.prepareResponse('patch', url, data, options);
  }

  public put(url: string = '', data: any = {}, options = {}): Observable<any> {
    return this.prepareResponse('put', url, data, options);
  }

  public delete(url: string = '', options = {}): Observable<any> {
    return this.prepareResponse('delete', url, {}, options);
  }

  private prepareResponse(method: THttpMethod, url: string = '', data: any = {}, options: any = {}): Observable<any> {
    if (options?.forDownloadCSV) this.forDownloadCSV = true;

    let resultObservable: Observable<any>;
    if (method === 'get') resultObservable = this.http.get(this.getUrl(url), options);
    if (method === 'post' || method === 'put' || method === 'patch') {
      resultObservable = this.http[method](this.getUrl(url), data, this.getOptions(options));
    }
    if (method === 'delete') resultObservable = this.http.delete(this.getUrl(url), this.getOptions(options));

    return UtilsService.responseFromServerHandler(resultObservable!);
  }

}
