<form *ngIf='{ desktop: (deviceS.isDesktop$|async),
                         mobile: (deviceS.isMobile$|async),
                         pageContact: page=="contact",
                         paddingForPageContact: "0 0 20px 0"
                        } as obj' [formGroup]='form' autocomplete='off' [class]='page'>
  <!--    === *ngIf='!obj.pageContact' ==================-->
  <ng-container *ngIf='!obj.pageContact'>
    <!--      === BUILDING =============================-->
    <field *ngIf='fields.includes("building")' [text]='needTitleField?"Field or Court":""'
           [class]='"o-order-"+(fields|getIdx:"building")'>
      <ng-container [ngTemplateOutlet]='buildingRef'></ng-container>
    </field>
    <!--      === STREET =============================-->
    <field *ngIf='fields.includes("street")'
           [text]='replaceNameField?.street ? replaceNameField!.street! : (needTitleField ? "Street Address*":"")'
           [class]='"o-order-"+(fields|getIdx:"street")'>
      <ng-container [ngTemplateOutlet]='streetRef'></ng-container>
    </field>
    <!--      === STREET LINE 2 =============================-->
    <field *ngIf='fields.includes("streetLine2")'
           [text]='replaceNameField?.streetLine2 ? replaceNameField!.streetLine2! : (needTitleField ? "Address Line 2":"")'
           [class]='"o-order-"+(fields|getIdx:"streetLine2")'>
      <ng-container [ngTemplateOutlet]='streetLine2Ref'></ng-container>
    </field>
    <!--      === CITY =============================-->
    <field *ngIf='fields.includes("city")' [text]='needTitleField?"City":""' [class]='"o-order-"+(fields|getIdx:"city")'>
      <ng-container [ngTemplateOutlet]='cityRef'></ng-container>
    </field>
    <!--      === STATE =============================-->
    <field *ngIf='fields.includes("state")' [text]='needTitleField?"State":""' [class]='"o-order-"+(fields|getIdx:"state")'>
      <ng-container [ngTemplateOutlet]='stateRef'></ng-container>
    </field>
    <!--      === ZIPCODE =============================-->
    <field *ngIf='fields.includes("zipcode")' [text]='needTitleField?"Zip Code":""' [class]='"o-order-"+(fields|getIdx:"zipcode")'>
      <ng-container [ngTemplateOutlet]='zipcodeRef'></ng-container>
    </field>
    <!--      === TIMEZONE =============================-->
    <field *ngIf='fields.includes("timezone")' [text]='needTitleField?"Timezone":""' [class]='"o-order-"+(fields|getIdx:"timezone")'
           class='fieldTimezone'>
      <ng-container [ngTemplateOutlet]='timezoneRef'></ng-container>
    </field>
  </ng-container>

  <!--  !!! andrei ng-template -->
  <!--    === *ngIf='obj.pageContact' ==================-->
  <ng-container *ngIf='obj.pageContact'>
    <ng-container *ngIf='{widthField: obj.desktop ? profileS.widthForContactCol_2:"100%"} as objForPageContact'>
      <!--    === Residential Address => street ================================================-->
      <fieldRow *ngIf='fields.includes("street")'
                [text]='replaceNameField?.street ? replaceNameField!.street! : (needTitleField ? "Residential Address":"")'
                [class]='"o-order-"+(fields|getIdx:"street")' [padding]='obj.paddingForPageContact' [width]='widthForFieldRow'>
        <!--        <ng-container [ngTemplateOutlet]='streetRef' [ngTemplateOutletContext]='{width: objForPageContact.widthField}'></ng-container>-->
        <inputAddress formControlName='street' (changeVal)='changeField($event, "street")' [bcg]='bcg' [isFocus]='isFocus'
                      [placeholder]='replaceNameField?.street ? replaceNameField!.street! : ""'
                      [w]='objForPageContact.widthField'></inputAddress>
      </fieldRow>

      <!--    === Street Address Line 2 ================================================-->
      <fieldRow *ngIf='fields.includes("streetLine2")'
                [text]='replaceNameField?.streetLine2 ? replaceNameField!.streetLine2! : (needTitleField ? "Address Line 2":"")'
                [class]='"o-order-"+(fields|getIdx:"streetLine2")' [padding]='obj.paddingForPageContact' [width]='widthForFieldRow'>
        <ng-container [ngTemplateOutlet]='streetLine2Ref' [ngTemplateOutletContext]='{width: objForPageContact.widthField}'></ng-container>
      </fieldRow>

      <!--    === CITY ================================================-->
      <fieldRow *ngIf='fields.includes("city")'
                [text]='needTitleField ? "City":""'
                [class]='"o-order-"+(fields|getIdx:"city")' [padding]='obj.paddingForPageContact' [width]='widthForFieldRow'>
        <ng-container [ngTemplateOutlet]='cityRef' [ngTemplateOutletContext]='{width: objForPageContact.widthField}'></ng-container>
      </fieldRow>
      <!--    === State and Zip Code ================================================-->
      <ng-container *ngIf='obj.desktop'>
        <fieldRow *ngIf='fields.includes("state") && fields.includes("zipcode")'
                  text='State and Zip Code' [class]='"o-order-"+(fields|getIdx:"state")' [padding]='obj.paddingForPageContact'
                  [width]='widthForFieldRow'>
          <div class='stateAndZipcode' [style]='{width: objForPageContact.widthField}'>
            <ng-container [ngTemplateOutlet]='stateRef' [ngTemplateOutletContext]='{width: 240}'></ng-container>
            <ng-container [ngTemplateOutlet]='zipcodeRef' [ngTemplateOutletContext]='{width: 240, marginLeft32: true}'></ng-container>
          </div>
        </fieldRow>
      </ng-container>
      <ng-container *ngIf='obj.mobile'>
        <fieldRow *ngIf='fields.includes("state")'
                  text='State' [class]='"o-order-"+(fields|getIdx:"state")' [padding]='obj.paddingForPageContact'
                  [width]='widthForFieldRow'>
          <ng-container [ngTemplateOutlet]='stateRef' [ngTemplateOutletContext]='{width: "100%"}'></ng-container>
        </fieldRow>
        <fieldRow *ngIf='fields.includes("zipcode")'
                  text='Zip Code' [class]='"o-order-"+(fields|getIdx:"zipcode")' [padding]='obj.paddingForPageContact'
                  [width]='widthForFieldRow'>
          <ng-container [ngTemplateOutlet]='zipcodeRef' [ngTemplateOutletContext]='{width: "100%"}'></ng-container>
        </fieldRow>
      </ng-container>
    </ng-container>
  </ng-container>


  <!--  === BUILDING =============================-->
  <ng-template #buildingRef let-width='width'>
    <inputCtrl formControlName='building' nameField='Field or Court' placeholder='Enter a field or court number'
               (changeVal)='changeField($event, "building")' [bcg]='bcg' [w]='width'></inputCtrl>
  </ng-template>

  <!--  === STREET =============================-->
  <ng-template #streetRef let-width='width'>
    <inputAddress formControlName='street' (changeVal)='changeField($event, "street")' [bcg]='bcg' [isFocus]='isFocus'
                  [placeholder]='replaceNameField?.street ? replaceNameField!.street! : ""'
                  [w]='width'></inputAddress>
  </ng-template>

  <!--  === STREET LINE 2 =============================-->
  <ng-template #streetLine2Ref let-width='width'>
    <inputCtrl formControlName='streetLine2' (changeVal)='changeField($event, "streetLine2")' [bcg]='bcg'
               [placeholder]='replaceNameField?.streetLine2 ? replaceNameField!.streetLine2! : (needPlaceholder ? "Address Line 2":"")'
               [w]='width'></inputCtrl>
  </ng-template>

  <!--  === CITY =============================-->
  <ng-template #cityRef let-width='width'>
    <inputCtrl formControlName='city' (changeVal)='changeField($event, "city")' [bcg]='bcg'
               [placeholder]='needPlaceholder ? "City":""' [w]='width'></inputCtrl>
  </ng-template>

  <!--  === STATE =============================-->
  <ng-template #stateRef let-width='width'>
    <inputCtrl formControlName='state' (changeVal)='changeField($event, "state")' [bcg]='bcg'
               [placeholder]='needPlaceholder ? "State":""' [w]='width'></inputCtrl>
  </ng-template>

  <!--  === ZIPCODE =============================-->
  <ng-template #zipcodeRef let-width='width' let-marginLeft32='marginLeft32'>
    <inputCtrl formControlName='zipcode' (changeVal)='changeField($event, "zipcode")' [bcg]='bcg'
               [placeholder]='needPlaceholder ? "Zip Code":""' nameField='zipcode' [strCase]='null' [w]='width'
               [class.o-mg-l-32]='marginLeft32'></inputCtrl>
  </ng-template>

  <!--  === TIMEZONE =============================-->
  <ng-template #timezoneRef let-width='width'>
    <select-timezone formControlName='timezone' (changeVal)='changeField($event, "timezone")' [bcg]='bcg'></select-timezone>
  </ng-template>

</form>
