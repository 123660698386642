import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '@services/utils.service';

@Pipe({ name: 'existChangesArray', standalone: true })
export class ExistChangesArrayPipe implements PipeTransform {

  // !!! id в объекте должен быть !!!
  // !!! needCheck_length==true => надо ли учитывать длинну обоих массивов (тоесть если длинна массивов разная то вернется false, тоесть массивы НЕ равны)
  // !!! передать 2 массива объектов
  // !!! если все значения у объектов одинаковы, то возвращается true
  // !!! arrIncludesKeys если передал массив ключей, то нужно сравнивать только по эти ключам из массива arrKeys
  transform<T extends Object>(arr_1: Array<T>, arr_2: Array<any>, arrKeys?: Array<keyof T>): boolean {
    // arr_1.forEach((el) => {
    //   console.log('111 el :', Object.entries(el));
    // });
    // arr_2.forEach((el) => {
    //   console.log('222 el :', Object.entries(el));
    // });
    const existChanges = !UtilsService.compareTwoArrayObject(arr_1, arr_2, true, arrKeys); // !!! были изменения в массиве
    // console.log('existChanges :', existChanges)
    return existChanges;
  }

}
