import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MyWalletService {
  readonly widthPage = 1022;

  constructor() {
  }

  // === isViewAll ========================
  private isViewAllSub$ = new BehaviorSubject<boolean>(false);
  isViewAll$ = this.isViewAllSub$.asObservable();

  set isViewAll(isViewAll: boolean) {
    this.isViewAllSub$.next(isViewAll);
  }

  get isViewAll(): boolean {
    return this.isViewAllSub$.getValue();
  }

}
