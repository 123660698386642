import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationEnd,
  NavigationExtras,
  Params,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { RoutesService, TUrlWithoutQueryParams } from '@services/routes.service';
import { MeService } from '@services/me.service';
import { authRoutes } from '@app/app.module';
import { filter } from 'rxjs/operators';
import { arrUserRole, arrUserRoleUpperCase, ClassUser, EUserRole, TUserRoleLowercase, TUserRoleUpperCase } from '@models/user';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UtilsService } from '@services/utils.service';
import { AuthService } from '@app/auth/auth.service';

@UntilDestroy()
@Injectable()
export class AuthGuard implements CanActivate {
  amount = 1;

  // http://localhost:4400/v1/signup?role=ADMIN&groupId=d58907c2-f437-4045-84a4-68da2ff2aef5&key=501bdd3d-2b83-46f8-ab87-19b0ef2da0c2
  // export type TUserRoleUpperCase = 'OFFICIAL' | 'GROUP_ASSIGNOR' | 'SUB_ASSIGNOR' | 'ADMIN';
  // propertyForRoleAdminForQueryParams = 'ADMIN'; // !!! Тимур потом даст ссылку и надо посмотреть как будет написано в этой ссылке
  userRoleFromQueryParams?: TUserRoleUpperCase;

  constructor(
    private authS: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    // private mainS: MainService,
    // private localStorageS: LocalStorageService,
    private routesS: RoutesService,
    private meS: MeService,
    // private httpClient: HttpClient,
  ) {
    this.subscribeToRouterEvents();
  }

  // !!! http://localhost:4400/v1/signup?role=ADMIN&groupId=d58907c2-f437-4045-84a4-68da2ff2aef5&key=501bdd3d-2b83-46f8-ab87-19b0ef2da0c2
  // !!! http://localhost:4400/v1/signup?role=OFFICIAL&groupId=d58907c2-f437-4045-84a4-68da2ff2aef5&key=8903085c-e605-4f7d-9215-5cc77cff6266
  // !!! signup?role=OFFICIAL&groupId=a39041e6-5619-4b2a-bf46-e3e305bf41c3&key=123
  // !!! signupOfficial?role=OFFICIAL&groupId=44bebc93-026c-4d37-b3d3-3d3a852c82eb&key=3961e608-dd7a-4bbd-96e3-cc43c30d6675
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      // Extract query params NOT30-838
    const queryParams = next?.queryParams;
    const url = state.url;
    if (url.includes('signup')) {
      if ('role' in queryParams) {
        return of(true);
      } else {
        this.router.navigate(['/']);
        return of(false);
      }
    }
    // return of(true)
    // console.log('canActivate next:', next, '   next?.queryParams:', next?.queryParams);
    // console.log('canActivate state:', state, '  state?.root?.queryParams:', state?.root?.queryParams)
    // console.log('this.routesS.urlOnly :', this.routesS.urlOnly)
    // this.routesS.setQueryParams_TO_localStorage_forRegistration(next?.queryParams);
    UtilsService.setQueryParams_forRegistration(next?.queryParams);
    // this.routesS.setQueryParams_FROM_localStorage_forRegistration();
    this.authS.queryParams_forRegistration = UtilsService.getQueryParams_forRegistration();
    // console.log('authS.queryParams_forRegistration :', this.authS.queryParams_forRegistration);
    this.getUrlAndRole(state.url);
    if (authRoutes.includes(this.routesS.urlOnly)) { // !!! для регистрации/логина всегда разрешать. Это здесь потому что Гард навешан на эти роуты, для того чтобы роль добавлять в url
      // console.log('для регистрации/логина всегда разрешать :', this.routesS.urlOnly)
      return of(true);
    }

    if (localStorage.getItem('jwt_token')) { // если есть токен, то разрешить доступ к старнице
      // console.log('AuthGuard если есть токен, то разрешить доступ к старнице :', state.url, !!localStorage.getItem('jwt_token'))
      return of(true);
    } else if (state?.root?.queryParams?.role) {
      // console.log('return of(true) :', state?.root?.queryParams?.role)
      return of(true);
    } else { // если нет токена, редирект на стр.логина
      // console.log('routesS.navigate (\'/login\') :', )
      this.routesS.navigate('/login');
      return of(false);
    }
  }

  // === ROUTER EVENTS ===========================
  subscribeToRouterEvents(): void {
    // const queryParams = forkJoin([this.route.queryParams, this.routesS.queryParamsObj$])
    // const queryParams = combineLatest([this.route.queryParams, this.routesS.queryParamsObj$])
    const onNavigationEnd$ = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        untilDestroyed(this),
      );
    combineLatest([onNavigationEnd$, this.meS.me$, this.route.queryParams])
      .subscribe((res: Array<any>) => { // [NavigationEnd, queryParams, ClassUser, ClassUser] // NavigationEnd.urlAfterRedirects
        // console.log('subscribeToRouterEvents :', res?.length, res, '   res[0]:', res[0], '   res[1]:', res[1], '   res[2]:', res[2] );
        if (!res?.length) return;
        if (res[0]?.url?.includes('login')) return; // !!! goToLogin чтобы работал на странице signup

        const jwt_token = localStorage.getItem('jwt_token');
        // console.log('jwt_token :', jwt_token)

        const navigationEnd: NavigationEnd = res[0];
        const meUser: ClassUser = res[1];
        const queryParams: object = res[2];
        // console.log('queryParams :', queryParams);
        // console.log('routesS.queryParamsObj$ :', this.routesS.queryParamsObj$.getValue())

        this.getUrlAndRole(navigationEnd.url);
        this.checkQueryParams(navigationEnd.urlAfterRedirects, queryParams);
        // const queryParamsObj = this.routesS.queryParamsObj$.getValue();
        const queryParamsObj = this.authS.queryParams_forRegistration;
        if (queryParamsObj?.role) {
          // this.userRoleFromQueryParams = queryParamsObj?.role?.toUpperCase() as TUserRoleUpperCase
          this.userRoleFromQueryParams = arrUserRoleUpperCase.find(el => el === queryParamsObj?.role?.toUpperCase());
          // console.log('userRoleFromQueryParams :', this.userRoleFromQueryParams)
        }
        // console.log('111111111111 :', queryParamsObj?.role, this.routesS.urlOnly)
        // if (authRoutes.includes(this.routesS.urlOnly) && queryParamsObj?.role?.toLowerCase() === 'official') {
        if (authRoutes.includes(this.routesS.urlOnly) && queryParamsObj?.role?.toUpperCase() === 'OFFICIAL') {
          // console.error('router.navigate ([\'signupOfficial\'] :', )
          this.router.navigate(['signupOfficial'], { queryParams: queryParamsObj ? queryParamsObj : null });
        }
        if (!jwt_token && authRoutes.includes(this.routesS.urlOnly) && queryParamsObj?.role?.toUpperCase() === 'ADMIN') {
          // console.error('router.navigate ([\'signup\'] :', )
          // this.router.navigate(['signupAdmin'], { queryParams: queryParamsObj ? queryParamsObj : null });
          this.router.navigate(['signup'], { queryParams: queryParamsObj ? queryParamsObj : null });
        }
      });
  }

  checkQueryParams(url: string, queryParams: Params): void {
    // console.log('checkQueryParams url:', url, '  queryParams:', queryParams);
    if (Object.entries(queryParams).length) {
      // this.routesS.queryParamsObj$.next(queryParams);
      this.authS.queryParams_forRegistration = queryParams;
    }
  }

  getUrlAndRole(url: string): void {
    const arr = url?.split('?')?.filter(Boolean)[0]?.split('/')?.filter(Boolean); // ['official', 'signup'] || ['signup']
    this.routesS.urlOnly = arr?.filter(Boolean)[arr.length - 1]; // url без роли и без QueryParams

    this.routesS.urlWithoutQueryParams = url?.split('?')[0]?.replace('/', '') as TUrlWithoutQueryParams; // /official/signup || /signup
    let roleInUrl = this.routesS.urlWithoutQueryParams?.split('/')?.filter(Boolean)?.[0] || '';
    roleInUrl = arrUserRole.includes(roleInUrl?.replace('/', '') as TUserRoleLowercase) ? roleInUrl.replace('/', '') : '';
    this.routesS.roleInUrl$.next(roleInUrl);
    // console.log('routesS.urlOnly :', this.routesS.urlOnly)
    // console.log('roleInUrl :', roleInUrl)
    // console.log('routesS.urlWithoutQueryParams :', this.routesS.urlWithoutQueryParams)
    // console.log('routesS.roleInUrl$ :', this.routesS.roleInUrl$.getValue())
    // console.log('queryParamsObj$ :', this.routesS.queryParamsObj$.getValue())
  }

  // !!! добавлять в адресную строку роль юзера
  checkUserRole(url: string, role?: TUserRoleUpperCase): void { // id, url, urlAfterRedirects
    if (!role) return;

    const urlIncludesGroupAssignor = this.routesS.urlWithoutQueryParams?.toLowerCase()?.includes(EUserRole.group_assignor);
    const urlIncludesSubAssignor = this.routesS.urlWithoutQueryParams?.toLowerCase()?.includes(EUserRole.sub_assignor);
    const urlIncludesOfficial = this.routesS.urlWithoutQueryParams?.toLowerCase()?.includes(EUserRole.official);
    const urlIncludesUserRole = urlIncludesGroupAssignor || urlIncludesSubAssignor || urlIncludesOfficial;

    const roleInUrl = this.routesS.roleInUrl$.getValue();
    // const queryParamsObj = this.routesS.queryParamsObj$.getValue();
    const queryParamsObj = this.authS.queryParams_forRegistration;
    const existQueryParams = queryParamsObj ? Object.entries(queryParamsObj)?.length : false;
    if (!urlIncludesUserRole || (roleInUrl !== queryParamsObj?.role)) {
      // if (role || existQueryParams) {
      if (existQueryParams) {
        const prevUrl = '?prevUrl=%2Fadmin%2F';
        const arrPath = url?.split(prevUrl); // ["http://localhost:4400/admin/mainpage","games"]
        this.routesS.previousUrl = '/' + (arrPath[1] || '');
        // const extras: NavigationExtras | undefined = this.routesS.queryParamsString ? { queryParams: this.routesS.queryParamsObj } : undefined;

        const extras: NavigationExtras | undefined = existQueryParams ? { queryParams: queryParamsObj } : undefined;
        // this.routesS.navigate(`${this.routesS.queryParamsObj.role || role}/${this.routesS.urlOnly}`, extras);

        if (existQueryParams) {
          // console.log('111 :', existQueryParams);
          this.routesS.navigate(`${queryParamsObj?.role}/${this.routesS.urlOnly}`);
        } else if (role) {
          // console.log('222 :', role);
          this.routesS.navigate(`${role}/${this.routesS.urlOnly}`);
        } else {
          // console.log('333  routesS.navigate(`/${this.routesS.urlOnly}`):', this.routesS.urlOnly);
          this.routesS.navigate(`/${this.routesS.urlOnly}`);
        }
      }
    }
  }

  getQueryParams(url: string): object {
    const queryParams: any = {};
    const hashes = url.split('?')[1];
    const hash = hashes.split('&');

    for (let i = 0; i < hash.length; i++) {
      const params = hash[i].split('=');
      queryParams[params[0]] = params[1];
    }
    return queryParams;
  }

}

//
// // !!! добавлять в адресную строку роль юзера
//   checkUserRole(event: NavigationEnd): void { // id, url, urlAfterRedirects
//     this.meS.me$?.subscribe((me?: ClassUser) => {
//   const url = event.urlAfterRedirects; // /admin/profile/account || /official/profile/account
//   const urlIncludesSue = url?.includes('/sue/'); // в будущем пригодится
//   const urlIncludesGroupAssignor = url?.includes('/GROUP_ASSIGNOR/');
//   const urlIncludesSubAssignor = url?.includes('/SUB_ASSIGNOR/');
//   const urlIncludesOfficial = url?.includes('/official/');
//   // const isAdmin = me?.roleCurrent == 'LEAGUE_ADMIN';
//   // const isOfficial = me?.roleCurrent == 'OFFICIAL';
//   const role: TUserRole = me?.roleCurrent!;
//
//   if (!urlIncludesSue && !authRoutes.includes(event?.url?.split('?')[0])) {
//     // if ((!urlIncludesGroupAssignor && !urlIncludesSubAssignor && !urlIncludesOfficial) || (urlIncludesAdmin && isOfficial) || (urlIncludesOfficial && isAdmin)) {
//     if (!urlIncludesGroupAssignor && !urlIncludesSubAssignor && !urlIncludesOfficial) {
//       const prevUrl = '?prevUrl=%2Fadmin%2F';
//       const arrPath = url?.split(prevUrl); // ["http://localhost:4400/admin/mainpage","games"]
//       this.routesS.previousUrl = '/' + (arrPath[1] || '');
//       this.routesS.navigate(`${role}/${url?.split('?')[0]}`);
//     }
//   }
//   });
//   }

// checkQueryParams(queryParams: Params): void {
//   if (Object.entries(queryParams).length) {
//     this.routesS.queryParamsObj = queryParams;
//     console.log('checkQueryParams routesS.queryParamsObj :', this.routesS.queryParamsObj)
//   } else {
//     this.routesS.queryParamsObj = null;
//   }
//   console.log('checkQueryParams :', Object.entries(queryParams).length, queryParams, this.routesS.queryParamsObj)
// }
