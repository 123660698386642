<div class='wrapperAutoTopup' [formGroup]='form' *ngIf='{arrPayMethods: (payS.allMethodsSub$|async)} as obj'>
  <headTitle text='{{topup ? "Edit":"Add"}} Auto Top-Up' svgRight='cross_grey&24' (svgRightEmit)='close()'
             typeText='text4-blueDark'></headTitle>
  <div class='subTitleAutoTopup'>Manage how much you want to recharge your Notch Pay balance to on a recurring schedule and please allow 24
    hours for daily top-up schedules to begin.
  </div>

  <field text='Recharge Balance to'>
    <inputCtrl formControlName='resultBalance' placeholder='$0.00' [isFocus]='true' [isNumber]='true' [showDollar]='true'></inputCtrl>
  </field>

  <field text='Top-Up Schedule'>
    <app-dropdown formControlName='day' [array]='arrTopUpSchedule' placeholder='Top-Up Schedule' (changeValObj)='changeDay($event)'></app-dropdown>
  </field>

  <field *ngIf='arrOn?.length' text='On'>
    <app-dropdown formControlName='on' [array]='arrOn'></app-dropdown>
  </field>

  <field text='Primary Payment Method'>
    <payMethodDrop *ngIf='obj.arrPayMethods?.length' formControlName='primaryMethod'></payMethodDrop>
    <btn *ngIf='!obj.arrPayMethods?.length' text='Add New Payment Method' bcg='white' svgLeft='plus_1&20' [btnHeight]='56'
         (emit)='addPaymentMethod()' textPosition='left'></btn>
  </field>

  <field text='Backup Payment Method'>
    <payMethodDrop *ngIf='obj.arrPayMethods?.length' formControlName='backupMethod'></payMethodDrop>
    <btn *ngIf='!obj.arrPayMethods?.length' text='Add New Backup Payment Method' bcg='white'
         [svgLeft]='ctrl.primaryMethod.value ? "plus_1&20":"plus_grey&20"' (emit)='addPaymentMethod()'
         [disabled]='!ctrl.primaryMethod.value' [btnHeight]='56' textPosition='left'></btn>
  </field>

  <div class='bottomTextAutoTopup'>Your auto top-up will begin on {{'{Date}'}} at {{'{Time}'}}.</div>

  <btnWrap>
    <btn text='Cancel' bcg='white' (emit)='close()' [btnWidth]='188'></btn>
    <btn text='Confirm' bcg='blueDark' (emit)='submit()' [btnWidth]='188' [disabled]='!form.valid'></btn>
  </btnWrap>

</div>