<div class='o-wrapPage'>
  <div class="linkPage">
  <!-- <linkPage [arrLink]='payS.linkPaymentsItems' [link]='payS.currentLink!' (emit)='payS.navigate($event)'></linkPage> -->
  </div> 
  <div class='o-content'>
    <router-outlet></router-outlet>
  </div>
</div>



