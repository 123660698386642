import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MeService } from '@services/me.service';
import { MainService } from '@services/main.service';
import React from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent } from './ReactComponent';
import { SpinnerComponent } from "../../../../shared/components/spinner/spinner.component";
import { CommonModule } from '@angular/common';
import { createRoot } from 'react-dom/client';

@Component({
    selector: 'react-wrapper',
    styleUrls: ['./react-wrapper.component.scss'],
    standalone: true,
    templateUrl: './react-wrapper.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [CommonModule, SpinnerComponent]
})
export class ReactWrapperComponent implements OnChanges, OnDestroy, OnInit {
    @ViewChild('reactComponentPlaceholder') reactComponentPlaceholder!: ElementRef;
    embeddableToken: string | undefined;
    fetchingData: boolean | undefined;

    @Input() embeddableId!: string;
    @Input() variables!: any;
    @Input() isLoading!: boolean;
    @Input() onVariableUpdated!: (newVariables:any)=>void;
    @Input() setLoadFinished!: (value:boolean)=>void;
    
    constructor(
        public mainS: MainService,
        public meS: MeService
    ) { }

    ngOnInit(): void {
        this.generateTokenForEmbeddable();        
    }

    ngOnChanges({embeddableId, isLoading, variables}: SimpleChanges) : void {
        if (embeddableId && embeddableId.currentValue !== embeddableId.previousValue)
            this.generateTokenForEmbeddable();

        if (isLoading !== undefined && isLoading.currentValue !== isLoading.previousValue)        
            this.render();

        if (variables && variables.currentValue !== variables.previousValue)        
            this.render();
    }

    generateTokenForEmbeddable(): void {
        this.fetchingData = true;
        this.mainS.getMeArrCurrentGroup().toPromise()
                .then(() =>
                    this.mainS.embeddableAuth(this.meS.meCurrentGroup?.id ?? "", this.embeddableId).toPromise()
                        .then((resp) => {
                            this.embeddableToken = resp?.token
                            this.fetchingData = false;
                            this.render();
        }))
    }

    ngOnDestroy(){
        ReactDOM.unmountComponentAtNode(this.reactComponentPlaceholder.nativeElement);
    }

    private render(){        
        if (!this.isLoading && !this.fetchingData)
        {
            const root: any = createRoot(this.reactComponentPlaceholder.nativeElement);
            root.render(React.createElement(ReactComponent, {
                embeddableToken: this.embeddableToken ?? "",
                variables: this.variables,
                onVariableUpdated: this.onVariableUpdated,
                setLoadFinished: (value: boolean) => {                    
                    if (this.setLoadFinished)
                        this.setLoadFinished(value);                    
                }
            }));
        }
        
    }
}
