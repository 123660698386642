import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class IntercomService {
  existIntercom = false;

  constructor() { }

  intercom(name: string, email: string): void {
    if (this.existIntercom) return;
    // @ts-ignore
    window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: "mde53su1",
      name, // Full name
      email, // Email address
      created_at: new Date() // Signup date as a Unix timestamp
    };
    this.existIntercom = true;
    (function(){var w=window; // @ts-ignore
      var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/mde53su1';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  }
}
