<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
  mobile: (deviceS.isMobile$|async),
  search: filtersS.findFilter("search"),
  dateRange: filtersS.findFilter("dateRange"),
  groupId: filtersS.findFilter("groupId"),
  competitions: filtersS.findFilter("competitions"),
  levels: filtersS.findFilter("levels"),
  ages: filtersS.findFilter("ages"),
  assignStatuses: filtersS.findFilter("assignStatuses"),
  distance: filtersS.findFilter("distance"),
  zipcode: filtersS.findFilter("zipcode"),
  activeCompetitions: filtersS.findFilter("activeCompetitions"),
  competitionPreferences: filtersS.findFilter("competitionPreferences"),
  location: filtersS.findFilter("location"),
  officialList: filtersS.findFilter("officialList"),
  officialStatus: filtersS.findFilter("officialStatus"),
  seasons: filtersS.findFilter("seasons"),
  payoutFormat: filtersS.findFilter("payoutFormat"),
  showFilters_forMobile: (filtersS.showFilters_forMobile$|async),
} as obj'  [formGroup]='form' class="filters-container">

<div class="addFilter">
<div>
  <ng-container *ngIf='!(""|getMatTooltipForSearch)'>
    <inputCtrl *ngIf='obj.search?.typeFilter == "search" && obj.search.visible' formControlName='search'
               placeholder='Search' svgPrefix='search_grey&20' [w]='obj.desktop ? 270:"100%"'
               (changeVal)='changeFilter_TypeFilter_string(obj.search?.typeFilter!,$event)' forFilterItem
               [filter]='obj.search' [class.hiddenFilters]='obj.showFilters_forMobile' [isFocus]='!obj.mobile'></inputCtrl>
  </ng-container>

  <ng-container *ngIf='(""|getMatTooltipForSearch)'>
    <app-containerTooltip #containerTooltipRef position='bottomLeft' [width]='216'>
      <inputCtrl *ngIf='obj.search?.typeFilter == "search" && obj.search.visible' formControlName='search'
                 placeholder='Search' svgPrefix='search_grey&20' [w]='obj.desktop ? 270:"100%"'
                 (changeVal)='changeFilter_TypeFilter_string(obj.search?.typeFilter!,$event);containerTooltipRef.showTooltip=false'
                 forFilterItem [filter]='obj.search' [class.hiddenFilters]='obj.showFilters_forMobile' [isFocus]='true'
                 class='ngContent_trigger' (focus)="handleFocus()" [isFocus]='false' #inputField></inputCtrl>
      <tooltipSearch class='ngContent_tooltip' (emit)='containerTooltipRef.showTooltip=false'></tooltipSearch>
    </app-containerTooltip>
  </ng-container>
</div>


  <div class="filters-button" (click)="toggleFiltersDropdown()">
    <meSvg svgName='line_menuForFilters&44' w='18'></meSvg> Add Filters
  </div>

  <app-filtersSelectedIcon></app-filtersSelectedIcon>

</div>

  <!-- Filters Dropdown -->
  <div class="dropdown-menu" *ngIf="showFiltersDropdown">
    <div class="dropdown-item" (click)="toggleSubMenu('dateRange')" [class.selected]="activeSubMenu === 'dateRange'" >
      Date Range
    </div>
    <div class="dropdown-item" (click)="toggleSubMenu('competitions')" [class.selected]="activeSubMenu === 'competitions'">
      Competitions
    </div>
    <div class="dropdown-item" (click)="toggleSubMenu('paymentMethod')" [class.selected]="activeSubMenu === 'paymentMethod'">
      Method
    </div>
    <div class="dropdown-item" (click)="toggleSubMenu('status')" [class.selected]="activeSubMenu === 'status'">
      Status
    </div>
  </div>

  <!-- Submenus -->
  <div class="dropdown-submenu" *ngIf="activeSubMenu === 'dateRange'">
    <div *ngFor="let option of filtersS.findFilter('dateRange')?.arrPeriods || []" 
         class="dropdown-item" 
         [class.selected]="option === selectedDateRange"
         (click)="changeFilter_TypeFilter_dateRange(option)">
      {{ option }}
    </div>
  </div>
  <div class="dropdown-submenu" *ngIf="activeSubMenu === 'competitions'">
    <div *ngFor="let option of obj.competitions?.arrayForDropdown || []" 
         class="dropdown-item"
         [class.selected]="option?.upperCase === selectedCompetition"
         (click)="changeFilter_TypeFilter_drop('competitions', option)">
      {{ option?.titleCase }}
    </div>
  </div>
<!-- ✅ Payment Method Submenu -->
<div class="dropdown-submenu" *ngIf="activeSubMenu === 'paymentMethod'">
  <div *ngFor="let option of (filtersS.findFilter('paymentMethod')?.arrayForDropdown || [])"
       class="dropdown-item"
       [class.selected]="option?.upperCase === selectedPaymentMethod"
       (click)="changeFilter_TypeFilter_drop('paymentMethod', option)">
    {{ option?.titleCase }}
  </div>
</div>

<div class="dropdown-submenu" *ngIf="activeSubMenu === 'status'">
  <div *ngFor="let option of (filtersS.findFilter('status')?.arrayForDropdown || [])"
       class="dropdown-item"
       [class.selected]="option?.upperCase === selectedStatus"
       (click)="changeFilter_TypeFilter_drop('status', option)">
    {{ option?.titleCase }}
  </div>
</div>
