import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { HelperClass } from '@classes/Helper-Classes';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { AuthService } from '../auth.service';
import { CommonModule } from '@angular/common';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CustomValidators } from '@classes/CustomValidators';
import { BtnComponent } from '@components/btn/btn.component';
import { untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    BtnComponent,
    RouterLink,
    ReactiveFormsModule,
    FieldComponent,
    InputCtrlComponent,
    SvgComponent,
  ],
})
export class CreatePasswordComponent extends HelperClass implements OnInit {
  form!: FormGroup<any>;
  userEmail!: string;
  securityKey!: string;
  isPasswordChanged: boolean = false;
  isError: boolean = false;
  errorStatus: string = '';

  constructor
  (
    public cdr: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
  ) {
    super(cdr);
    this.createForm();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe((queryParams) => {
      this.userEmail = queryParams['email'].replaceAll(' ', '+');
      this.securityKey = queryParams['key'];
    });
  }

  createForm(): void {
    this.form = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(7), CustomValidators.patternPassword]],
      confirmPassword: ['', [Validators.required, Validators.minLength(7), CustomValidators.patternPassword, CustomValidators.checkPassMatch]],
    });
  }

  updatePassword() {
    if (!!this.userEmail && !!this.securityKey) {
      const email = this.userEmail;
      const key = this.securityKey;
      const password = this.form.value.password;

      this.authService.updatePassword({ email, key, password }).pipe(untilDestroyed(this)).subscribe({
        next: (response: any) => this.handleSuccessResponse(response),
        error: (error: any) => this.handleErrorResponse(error),
      });
    }
  }

navigateToLogin() {
  this.router.navigate(['/login']).then(() => {
    window.location.href = '/login'; 
  });
}

  private handleSuccessResponse(res: any) {
    if (res && res.status === 200) {
      this.isPasswordChanged = true;
      this.cdr.detectChanges();
    }
  }

  private handleErrorResponse(res: any) {
    this.isError = true;
    this.errorStatus = res?.error?.message;
    this.cdr.detectChanges();
    this.router.navigate(['/resetPassword']);
    console.error('Unexpected response status:', res ? res.status : 'unknown');
  }

}
