import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SuccessDialogComponent } from '@app/dir_officials/page-my-wallet/components/success-dialog/success-dialog.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { CardLogoComponent } from '@components/card-logo/card-logo.component';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { ACCOUNT_EXTERNAL_FIELDS, AccountExternalModel } from '@models/account-external.model';
import { AccountApiService } from '@services/account.api.service';
import { FundService } from '@services/funds.service';
import { MeService } from '@services/me.service';
import { OtherService } from '@services/other.service';
import { PayoutApiService } from '@services/payout.api.service';
import { PopupService } from '@services/popup.service';
import { IResCreateTokenSuccess, IStripeEvent, StripeService, TFieldStripeForm } from '@services/stripe.service';
import { ACCOUNT_METHODS } from '@shared/constants/account-methods.constant';
import { CREDIT_CARD_TYPE} from '@shared/constants/credit-card-type.constant';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { catchError, finalize, of, throwError } from 'rxjs';
import { FieldComponent } from '../../__drop_inputs_matSelect/field/field.component';
import { InputCtrlComponent } from '../../__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { BtnComponent } from '../../btn/btn.component';
import { AccountPaymentComponent } from '@components/account-payment/account-payment.component';
import { HeadTitleComponent } from '../../head-title/head-title.component';
import { MainService } from '@services/main.service';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { DeviceService } from '@services/device.service';
import { AddAnotherComponent } from '@components/add-another/add-another.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { ManagePaymentMethodComponent } from '@app/dir_officials/page-my-wallet/components/manage-payment-method/manage-payment-method.component';
import { InstantSuccessDialogComponent } from '@app/dir_officials/page-my-wallet/components/instant-success-dialog/instant-success-dialog.component';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { ClassPaymentMethod } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { PopupSwitchPayoutComponent } from '@app/dir_group_assignor/games/components/popup-switch-payout/popup-switch-payout.component';
import { MatDialog } from '@angular/material/dialog';

interface IForStripeElem {
  name: TFieldStripeForm;
  elemRef: Element;
  stripeElem?: any;
  value?: string;
}

enum FORM_FIELDS {
  NAME = 'cardName',
  EXPIRE = 'cardExpiry',
  NUMBER = 'cardNumber',
  CVV = 'cardCvc',
  ZIP = 'zip',
  ROUTING = 'routing',
  ACCOUNT = 'account',
  ACCOUNT_CONFIRM = 'accountConfirm',
}

@UntilDestroy()
@Component({
  selector: 'app-popup-payment-method-schedule',
  standalone: true,
  templateUrl: './payment-method-schedule.component.html',
  styleUrls: ['./payment-method-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    HeadTitleComponent,
    BtnComponent,
    AccountPaymentComponent,
    FieldComponent,
    InputCtrlComponent,
    ReactiveFormsModule,
    NgxMaskDirective,
    CardLogoComponent,
    SvgComponent,
    SpinnerComponent,
    SuccessDialogComponent,
    BtnWrapComponent,
    AddAnotherComponent,
    ManagePaymentMethodComponent,
    DropdownComponent,
    FormsModule,
    InstantSuccessDialogComponent
  ],
  providers: [provideNgxMask()],
})
export class paymentMethodScheduleComponent implements OnInit {
  isEdit: boolean = false;
  isPayout!: boolean;
  isSelectPaymentType!: boolean;
  balance!: number;  // No default value
  balanceInstant!: number;  // No default value
  instantAvailable!: number;
  standardAvailable!: number;
  accounts: AccountExternalModel[] = [];
  accountDefault?: AccountExternalModel;
  selectedAccount?: AccountExternalModel;
  selectedPayout: 'standard' | 'auto' = 'standard';
  selectedAutoPayout: 'weekly' | 'biweekly' | 'monthly' | null = null;
  selectedAutoPayoutTemp:'weekly'  | 'biweekly' | 'monthly' | null = null;
  isPopupVisible: boolean = false;
  selectedPaymentMethod?: ClassPaymentMethod;
  paymentMethodsForDropdown: ClassPaymentMethod[] = [];

  isNextPage: boolean = false;
  formCard: FormGroup = new FormGroup({
    [FORM_FIELDS.NAME]: new FormControl(null, Validators.required),
    [FORM_FIELDS.ZIP]: new FormControl(null, Validators.required),
    [FORM_FIELDS.EXPIRE]: new FormControl(),
    [FORM_FIELDS.NUMBER]: new FormControl(),
    [FORM_FIELDS.CVV]: new FormControl(),
  });
  formBank: FormGroup = new FormGroup({
    [FORM_FIELDS.ROUTING]: new FormControl(null, [Validators.required]),
    [FORM_FIELDS.ACCOUNT]: new FormControl(null, Validators.required),
    [FORM_FIELDS.ACCOUNT_CONFIRM]: new FormControl(null, Validators.required),
  });
  cardBrand?: CREDIT_CARD_TYPE;
  paymentMethod?: ACCOUNT_METHODS;

  ACCOUNT_METHODS = ACCOUNT_METHODS;
  FORM_FIELDS = FORM_FIELDS;
  ACCOUNT_EXTERNAL_FIELDS = ACCOUNT_EXTERNAL_FIELDS;

  isValidForm = false;
  stripeElements?: any;
  fieldsRef: IForStripeElem[] = [];
  noInitFields: TFieldStripeForm[] = [FORM_FIELDS.NAME, FORM_FIELDS.ZIP];
  isLoading = false;
  paymentMethodIsInstant: boolean = false;
  showToggleSwitch: boolean = false;
  @Input() isOn: boolean = false;
  @Output() toggle = new EventEmitter<boolean>();
  availablePayout: number | undefined;
  finalPayout: number | undefined;
  isOneTimePayoutSelected: boolean = false;
  oneTimePayoutSelected: boolean = false;

  showInstantSuccessPage = false;
  popupType: 'payout' | 'paymentMethod' | 'oneTimePay' | 'autopayout' = 'paymentMethod';

  toggleSwitch() {
    this.isOn = !this.isOn;
    this.toggle.emit(this.isOn);
  }

  togglePaymentMethod() {
    this.paymentMethodIsInstant = !this.paymentMethodIsInstant;
    if (this.selectedAccount) {
      this.selectedAccount.method = this.paymentMethodIsInstant ? ACCOUNT_METHODS.INSTANT : ACCOUNT_METHODS.STANDARD;
    }
  }


  dataPopup = {
    instantAvailable: 0,
    standardAvailable: 0,
    balance: 0,
    balanceInstant: 0,
    isPayout: false,
    isEdit: false,
    isSelectPaymentType: false,
    accounts: [] as AccountExternalModel[],
  };



  constructor(

    public mainS: MainService,
    private fundS: FundService,
    private stripeS: StripeService,
    private accountApiS: AccountApiService,
    private payoutApiS: PayoutApiService,
    public otherS: OtherService,
    private changeDetectorRef: ChangeDetectorRef,
    private meService: MeService,
    public deviceS: DeviceService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private popupS: MatDialog,
    private router: Router
  ) {

  }

  scheduledPayoutData: { type: string; enabled: boolean; } | null = null;

  ngOnInit(): void {
    this.selectedPayout = 'standard';
    if (this.selectedPayout === 'standard') {
      this.isOneTimePayoutSelected = true;
      this.oneTimePayoutSelected = false;
    }
    this.isLoading = true;
    this.mainS.getScheduledPayout()
      .pipe(untilDestroyed(this),
        catchError(error => {
          if (error.status === 404) {
            this.scheduledPayoutData = null;
            this.selectedAutoPayout = 'weekly'
          }
          return of(null);
        }),
        finalize(() => this.isLoading = false))
      .subscribe(response => {
        if (response && response.enabled) {
          this.scheduledPayoutData = response;
          this.selectedPayout = 'auto'; // Set auto payout if enabled
          if ((response.type === 'WEEKLY_TRANSITION_TO_MONTHLY') || (response.type === 'BIWEEKLY_TRANSITION_TO_MONTHLY'))
            this.selectedAutoPayout = 'monthly';
          else 
           this.selectedAutoPayout = response.type.toLowerCase() as 'weekly' | 'biweekly' | 'monthly';
          this.processAccountsForDropdown();
        } else {
          this.selectedAutoPayout = 'weekly'
        }
      });
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      this.dataPopup = {
        instantAvailable: params['instantAvailable'] !== undefined ? +params['instantAvailable'] : 0,
        standardAvailable: params['standardAvailable'] !== undefined ? +params['standardAvailable'] : 0,
        balance: params['balance'] !== undefined ? +params['balance'] : 0,
        balanceInstant: params['balanceInstant'] !== undefined ? +params['balanceInstant'] : 0,
        isPayout: params['isPayout'] === 'true',
        isEdit: params['isEdit'] === 'true',
        isSelectPaymentType: params['isSelectPaymentType'] === 'true',
        accounts: params['accounts'] ? JSON.parse(params['accounts']) : [],
      };
      this.processAccountsForDropdown();


      if (this.dataPopup.accounts.length > 0) {
        const defaultAccount = this.dataPopup.accounts.find(account => account[ACCOUNT_EXTERNAL_FIELDS.DEFAULT] === true);
        if (defaultAccount) {
          this.onAccountSelected(defaultAccount);
        }
      } else {
        // console.warn('⚠ No accounts found in dataPopup');
      }
      this.fetchBalances();

    });
  }

  get scheduledPayoutType(): string {
    return this.scheduledPayoutData?.type ? this.scheduledPayoutData.type.toLowerCase() : ''; // Returns an empty string if undefined
  }

  showSuccessPage(type: 'payout' | 'paymentMethod' | 'oneTimePay' | 'autopayout') {
    this.popupType = type;
    this.showInstantSuccessPage = true;
  }

  handleSuccessClose() {
    this.showInstantSuccessPage = false;
    if(this.selectedPayout === 'standard') this.router.navigate(['/myWalletOfficial']); 
  }
  
  saveScheduledPayout(): void {
    if (!this.selectedAutoPayout || !this.selectedAccount) {
      return;
    }

    const payload = {
      type: this.selectedAutoPayout.toUpperCase(),
      enabled: true,
    };

    this.isLoading = true;

    this.mainS.updateScheduledPayout(payload)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
          this.cd.detectChanges();
        })
      )
      .subscribe(
        response => {
          console.log('Scheduled payout updated successfully:', response);
          this.scheduledPayoutData = {
            type: response.type,
            enabled: response.enabled
          };

          this.selectedPayout = 'auto';
          this.oneTimePayoutSelected = false; 
          if ((response.type === 'WEEKLY_TRANSITION_TO_MONTHLY') || (response.type === 'BIWEEKLY_TRANSITION_TO_MONTHLY'))
            this.selectedAutoPayout = 'monthly';
          else 
            this.selectedAutoPayout = response.type.toLowerCase() as 'weekly' | 'biweekly' | 'monthly';
          this.showSuccessPage('autopayout');
          this.cd.detectChanges();
        },
        error => {
          console.error('Failed to update scheduled payout:', error);
        }
      );
  }


  processAccountsForDropdown(): void {
    this.paymentMethodsForDropdown = this.dataPopup.accounts.map(account => {
      return new ClassPaymentMethod({
        paymentBrand: account.title || 'Unknown',
        last4: account.last4 ? `**** ${account.last4}` : '****',
        titleCase: `${account.title} ${account.last4 ? `**** ${account.last4}` : '****'}`,
        upperCase: account.id
      });
    });
  }

  onPaymentMethodChange(selectedPaymentMethod: ClassPaymentMethod): void {
    this.selectedPaymentMethod = selectedPaymentMethod;
  }

  goBackToSchedulePayout() {
    this.oneTimePayoutSelected = false;
    this.isOneTimePayoutSelected = false;
  }

  setPayout(type: 'standard' | 'auto') {
    this.selectedPayout = type;
    if (type === 'standard') {
      this.isOneTimePayoutSelected = true;
      this.oneTimePayoutSelected = false;
    } else {
      this.isOneTimePayoutSelected = false;
      this.oneTimePayoutSelected = false;
    }
    this.cd.detectChanges();
  }

  setAutoPayout(type: 'weekly'  | 'biweekly' | 'monthly'): void {
    if (this.selectedAutoPayout !== type) {
      this.selectedAutoPayoutTemp = type; 


    const currentType = this.scheduledPayoutData?.type?.toLowerCase() as 'weekly' | 'biweekly' | 'monthly';

    // Return early if switching between weekly <-> biweekly
    const isWeeklyToBiweekly =
    (this.selectedAutoPayout === 'weekly' && this.selectedAutoPayoutTemp === 'biweekly') ||
    (this.selectedAutoPayout === 'biweekly' && this.selectedAutoPayoutTemp === 'weekly') ||
    (currentType === 'weekly' && type === 'biweekly') ||
    ( currentType === 'biweekly' && type === 'weekly');

    // Safe check for scheduledPayoutData before accessing .enabled
    const isEnabled = this.scheduledPayoutData?.enabled ?? false;

    if (isWeeklyToBiweekly || !isEnabled || currentType === type) {
      this.confirmPayoutChange();
      return;
    }

      const dialogRef = this.popupS.open(PopupSwitchPayoutComponent, {
        data: {
          // currentType: this.selectedAutoPayout,
          currentType: currentType,
          newType: type
        }
      });
      
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.confirmPayoutChange();
        } else {
          this.cancelPayoutChange();
        }
      });
    }
  }
  
  
  confirmPayoutChange(): void {
    this.selectedAutoPayout = this.selectedAutoPayoutTemp; 
    this.cd.detectChanges();
  }

  cancelPayoutChange(): void {
    this.selectedAutoPayoutTemp = null; 
    this.cd.detectChanges();
  }

  selectOneTimePayout() {
    this.isOneTimePayoutSelected = true;
  }

  showOneTimePayoutDetails() {
    if (this.isOneTimePayoutSelected) {
      this.oneTimePayoutSelected = true;
    }
  }

  proceedToAutoPayoutSetup(): void {
    this.oneTimePayoutSelected = true;
  }


  close(): void {

  }

  onNext() {
    this.isNextPage = true;
  }

  add() {
    this.isEdit = false;
    this.dataPopup.isSelectPaymentType = true;
  }

  onSave() {

    if (this.paymentMethod === ACCOUNT_METHODS.INSTANT) {
      const additionalData = {
        currency: 'usd',
      };

      this.stripeS.stripe?.createToken(this.findField(FORM_FIELDS.NUMBER).stripeElem, { currency: 'usd' })
        .then((response: IResCreateTokenSuccess) => {
          if (response?.token?.id) {
            this.startLoader();

            this.accountApiS.connectExternal(response?.token?.id)
              .pipe(
                finalize(() => this.stopLoader()),
                untilDestroyed(this),
              )
              .subscribe(data => {
                this.fundS.tokenFromStripe = data?.token?.id;
                this.close();
              });
          } else {
            this.otherS.showError('No TOKEN from Stripe.');
          }
        });
    } else {
      this.startLoader();

      this.accountApiS.connectBank(
        this.formBank.value[FORM_FIELDS.ACCOUNT],
        this.formBank.value[FORM_FIELDS.ROUTING],
      )
        .pipe(
          finalize(() => this.stopLoader()),
          untilDestroyed(this),
        )
        .subscribe(data => this.close());
    }
  }

  selectCard(account: AccountExternalModel): void {
    this.dataPopup.isPayout ? this.selectedAccount = account : null;
  }

  onSelectPaymentMethod(paymentMethod: ACCOUNT_METHODS) {
    this.paymentMethod = paymentMethod;

    this.dataPopup.isSelectPaymentType = false;

    if (this.selectedAccount && this.selectedAccount.availableMethods.includes(ACCOUNT_METHODS.INSTANT) && this.selectedAccount.availableMethods.includes(ACCOUNT_METHODS.STANDARD)) {
      this.showToggleSwitch = true;
      this.paymentMethodIsInstant = this.paymentMethod === ACCOUNT_METHODS.INSTANT;
    } else {
      this.showToggleSwitch = false;
    }

    this.dataPopup.isSelectPaymentType = false;

    setTimeout(() => this.initFields());
  }



  isSaveDisabled() {
    return this.paymentMethod === ACCOUNT_METHODS.INSTANT
      ? this.formCard.invalid || this.formCard.pristine
      : this.formBank.invalid || this.formBank.pristine || this.formBank.get(FORM_FIELDS.ACCOUNT)?.value !==
      this.formBank.get(FORM_FIELDS.ACCOUNT_CONFIRM)?.value;
  }

  onRemoveAccount(account: AccountExternalModel) {
    this.startLoader();

    this.accountApiS.deleteExternal(this.meService.meId, account[ACCOUNT_EXTERNAL_FIELDS.ID] || '')
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.getAccounts();
      });
  }

  onSetDefaultAccount(account: AccountExternalModel) {
    this.startLoader();

    this.accountApiS.setDefault(this.meService.meId, account[ACCOUNT_EXTERNAL_FIELDS.ID] || '')
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.getAccounts();
      });
  }

  private startLoader() {
    this.isLoading = true;

    this.changeDetectorRef.markForCheck();
  }

  private stopLoader() {
    this.isLoading = false;

    this.changeDetectorRef.markForCheck();
  }


  onPayout() {
    if (!this.selectedAccount || !this.selectedAccount[ACCOUNT_EXTERNAL_FIELDS.ID]) {
      return;
    }
  
    this.startLoader();
    const payoutMethod = ACCOUNT_METHODS.STANDARD; // Always use STANDARD payout method
  
    const payoutObservable = this.meService.GROUP_ASSIGNOR
      ? this.payoutApiS.generalPayout(this.selectedAccount[ACCOUNT_EXTERNAL_FIELDS.ID], payoutMethod)
      : this.payoutApiS.full(this.meService.meId, this.selectedAccount[ACCOUNT_EXTERNAL_FIELDS.ID], payoutMethod);
  
    payoutObservable
      .pipe(
        catchError(err => {
          this.stopLoader();
          return throwError(() => err);
        }),
        finalize(() => this.stopLoader()),
        untilDestroyed(this),
      )
      .subscribe(data => {
        this.showSuccessPage('oneTimePay');
  
        // Show success notification
        this.otherS.showNotification(true, data);
      });
  }
  


  private getAccounts() {
    this.accountApiS.getAccounts(this.meService.meId)
      .pipe(
        finalize(() => this.stopLoader())
      )
      .subscribe(accounts => {
        this.dataPopup.accounts = accounts;

        for (const account of accounts) {
          if (account.availableMethods.includes('INSTANT') && account.availableMethods.includes('STANDARD')) {
            this.selectedAccount = account;
            this.showToggleSwitch = true;
            this.paymentMethodIsInstant = account.method === 'INSTANT';
            break;
          }
        }
      });
  }

  onAccountSelected(account: AccountExternalModel) {
    this.selectedAccount = account;

    this.showToggleSwitch = account.availableMethods.includes('INSTANT') && account.availableMethods.includes('STANDARD');
    this.paymentMethodIsInstant = account.method === 'INSTANT';
  }

  private initFields(): void {
    this.fundS.fields?.forEach((field: TFieldStripeForm) => {
      const refLink = document.querySelector(`#${field}`);
      if (refLink) {
        this.fieldsRef.push({ name: field, elemRef: refLink });
      }
    });

    this.stripeElements = this.stripeS.stripe?.elements();
    const option = { style: this.stripeS.styles, classes: this.stripeS.classes };

    this.fieldsRef?.forEach((item) => {
      if (this.noInitFields?.includes(item.name)) {
        return;
      }

      item.stripeElem = this.stripeElements?.create(item.name, option);
      item.stripeElem?.mount(item.elemRef);
      item.stripeElem?.on('change', (event: IStripeEvent) => this.checkError(event));
    });
  }

  private checkError(event: IStripeEvent): void {
    const field: TFieldStripeForm | undefined = event?.elementType;

    this.isValidForm = !event?.error;

    this.changeDetectorRef.detectChanges();
  }

  private findField(fieldName: TFieldStripeForm): IForStripeElem {
    return this.fieldsRef?.find((field) => field.name == fieldName)!;
  }

  closeToggleSwitch() {
    this.showToggleSwitch = false;
  }

  getPayoutAmount(): string {
      if (!this.dataPopup.balance || !this.selectedAccount) {
        return '';
      }

      const balanceInDollars = this.dataPopup.balance;
      let finalPayout = (this.dataPopup.balance - 1.99)
      this.finalPayout = Math.max(0, finalPayout);
      return `$${finalPayout.toFixed(2)}`;
  }

  fetchBalances() {
    this.accountApiS.getBalanceInstant().pipe(untilDestroyed(this)).subscribe(response => {
      const { standardAvailable, instantAvailable } = response;

      this.dataPopup.standardAvailable = standardAvailable.amount;
      this.dataPopup.instantAvailable = instantAvailable.amount;

      this.changeDetectorRef.detectChanges();
    });
  }

  getQuickPayFee(): number {
    return 1.99;
  }

  turnOffAutoSetup(): void {
    if (!this.selectedAutoPayout) {
      return;
    }

    const payload = {
      type: this.selectedAutoPayout.toUpperCase(), // 'WEEKLY' or 'MONTHLY'
      enabled: false, // Set enabled to false
      externalAccountId: this.selectedAccount?.id // Payment method ID
    };
    this.isLoading = true;

    this.mainS.updateScheduledPayout(payload)
      .pipe(untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
          this.cd.detectChanges();
        })
      )
      .subscribe(
        response => {
          console.log('Auto payout turned off successfully:', response);
          this.selectedAutoPayout = null;
          this.scheduledPayoutData = null;

          this.cd.detectChanges();
        },
        error => {
          console.error('Failed to turn off auto payout:', error);
        }
      );
  }

  cancelChanges(): void {
    if (!this.scheduledPayoutData) {
      return;
    }
    this.selectedAutoPayout = this.scheduledPayoutData.type.toLowerCase() as 'weekly' | 'monthly';
    this.cd.detectChanges();
  }

}
