import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnComponent } from '@components/btn/btn.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleSignUpComponent } from '@components/google/components/google-sign-up/google-sign-up.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { InvalidControlsPipe } from '@pipes/invalid-controls.pipe';
import { HelperClass } from '@classes/Helper-Classes';
import { AuthService } from '@app/auth/auth.service';
import { MainService } from '@services/main.service';
import { MeService } from '@services/me.service';
import { LocationService } from '@components/__blocks/location/location.service';
import { RoutesService } from '@services/routes.service';
import { BtnBackComponent } from '@components/btn-back/btn-back.component';
import { CodeConfirmComponent } from '@components/code-confirm/code-confirm.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UserRegisteredStatus } from '@models/user';
import { environment } from '@env/environment';

@UntilDestroy()
@Component({
  selector: 'codeConfirmStep[type][value]',
  standalone: true,
  imports: [CommonModule, BtnComponent, FieldComponent, FormsModule, GoogleSignUpComponent, InputCtrlComponent, InvalidControlsPipe, ReactiveFormsModule, BtnBackComponent, CodeConfirmComponent],
  templateUrl: './code-confirm-step.component.html',
  styleUrls: ['./code-confirm-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeConfirmStepComponent extends HelperClass {
  @Input() type!: 'phone' | 'email';
  @Input() value: string | undefined; // !!! phone value || email value
  validationCode = '';
  @Output() nextStepEvent = new EventEmitter<void>();
  @Output() backStepEvent = new EventEmitter<void>();
  @Output() emit = new EventEmitter<string | 'back' | 'next'>();

  constructor(
    public authS: AuthService,
    public mainS: MainService,
    private meS: MeService,
    public locationS: LocationService,
    public routesS: RoutesService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
  }

  // step == 2 codeConfirm || step == 6 codeConfirm
  verifyCode(): void {

    const verifyCodeCallback = () => {
      if (this.type == 'phone') {
        if (this.meS.me?.roleCurrent == 'OFFICIAL') {
          this.nextStepEvent.emit();
          this.emit.emit('next')
          this.mainS.changeUserRegisteredStatus(UserRegisteredStatus.MOBILE_VERIFIED);
        }
        else {
          this.mainS.confirmMe('');
        }
      }

      if (this.type == 'email') {
        this.nextStepEvent.emit();
        this.emit.emit('next');
        this.mainS.changeUserRegisteredStatus(UserRegisteredStatus.EMAIL_VERIFIED);
      }
    }

    if (!this.validationCode || !this.value) return;
    if (this.startRequest()) return;

    const itIsTestNumber = environment.production == false && this.validationCode?.toString().indexOf("1111") > -1;
    if (itIsTestNumber) {
      verifyCodeCallback()
    }
    this.mainS.verifyCode({ resource: this.value, code: this.validationCode }, this.type).toPromise()
      .then(() => { verifyCodeCallback(); })
      .catch((err) => {
      })
      .finally(() => this.endRequest());
  }


  // step == 2 codeConfirm || step == 6 codeConfirm
  resendCode(): void {
    if (this.startRequest()) return;
    this.mainS.resendCode(this.value!, this.type).toPromise()
      .then((res) => {
      })
      .catch((err) => {
      })
      .finally(() => this.endRequest());
  }

}
