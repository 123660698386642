import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoPaySystemComponent } from '@app/dir_group_assignor/payments/additional-components/logo-pay-system/logo-pay-system.component';
import { ChxComponent } from '@components/chx/chx.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IPaymentMethod } from '@app/dir_group_assignor/payments/modelsForPayment';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';

@Component({
  selector: 'payMethod[payMethod]',
  standalone: true,
  imports: [CommonModule, LogoPaySystemComponent, ChxComponent, ReactiveFormsModule, FormsModule, SvgComponent],
  templateUrl: './pay-method.component.html',
  styleUrls: ['./pay-method.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayMethodComponent {
  @Input() payMethod!: IPaymentMethod;
  @Input() width!: string | number;
  @Input() height!: string | number;

  @Input() isChx: boolean = false;
  @Input() chxWidth: number | string = 20;

  @Input() isEnlarge: boolean = false;
  @Input() revV: boolean = false; // !!! for isEnlarge

  @Input() isBorder: boolean = true; // !!! для выпадающего списка передавать false

  @Input() isHover: boolean = false;

  @Output() emit: EventEmitter<boolean> = new EventEmitter<boolean>(); // !!! click на всём блоке

  clickEmitPayMethod(): void {
    this.payMethod.isSelect = !this.payMethod.isSelect;
    this.emit.emit(!!this.payMethod.isSelect);
  }
}
