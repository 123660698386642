import { Pipe, PipeTransform } from "@angular/core";


@Pipe({
    name: 'currencyCents',
    standalone: true
})
export class CurrencyCentsPipe implements PipeTransform {

    transform(amountCents: number | null | undefined): string | null {
        if (amountCents == null)
            return "";
        const currencyCode: string = 'USD';
        const display: string = 'symbol';
        const digitsInfo: string = '1.2-2'
        const amountDollars = amountCents / 100; // Convert cents to dollars
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(amountDollars);
    }
}
