import { ChangeDetectorRef, Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { TPath } from '@app/app.module';
import { MeService } from '@services/me.service';
import { Router } from '@angular/router';
import { RoutesService } from '@services/routes.service';

// !!! если директива делает [disabled]='true' то в html [disabled]='false' НЕ сработает
// !!! если директива делает [disabled]='false' то в html [disabled]='true' сработает
@Directive({ selector: '[adminPermission]', exportAs: 'adminPermission', standalone: true })
export class AdminPermissionDirective implements OnInit {
  @Input() type_forPermission: 'hidden' | 'disabled' | 'none' = 'disabled'; // !!! если нет пермишенов то нужно скрыть или просто disabled текущий ElementRef
  path?: TPath; // example => /game || /competitions
  isBtn = false; // for BtnComponent
  isSvg = false; // for SvgComponent

  @Input() forTest?: string;

  constructor(
    private meS: MeService,
    private routesS: RoutesService,
    private router: Router,
    private elRef: ElementRef,
    private r: Renderer2,
    private cd: ChangeDetectorRef,
  ) {
  }

  @HostListener('mousedown', ['$event']) onClick(event: MouseEvent) { // !!! НЕ МЕНЯТЬ mousedown (click не ставить)
    if (!this.needDisabled_and_tooltip()) return;
    if (this.type_forPermission === 'hidden' || this.type_forPermission === 'none') return;
    // event.preventDefault();
    // event.stopPropagation();
    this.r.setStyle(this.elRef.nativeElement, 'pointer-events', 'none'); // !!! this.r.setStyle(this.elRef.nativeElement, 'pointer-events', 'auto');
    setTimeout(() => {
      this.elRef.nativeElement.style.removeProperty('pointer-events');
    }, 200);
  }

  ngOnInit() {
    this.path = this.routesS.path;
    this.hidden_or_disabled();
  }

  // !!! если вернулось true, то надо показывать tooltip и disabled элемент
  needDisabled_and_tooltip(): boolean {
    if (!this.path || !this.meS.me?.roleCurrent) {
      console.error(' AdminPermissionDirective roleCurrent :', this.meS.me?.roleCurrent);
      console.error(' AdminPermissionDirective path :', this.path);
    }
    const result = this.meS.ADMIN && !this.meS.adminPermission(this.path!);
    return result;
  }

  hidden_or_disabled(): void {
    if (!this.needDisabled_and_tooltip()) return;
    if (this.type_forPermission === 'none') { // полностью убрать из HTML
      this.r.addClass(this.elRef.nativeElement, 'o-hiddenAbsolute');
      return;
    }
    if (this.type_forPermission === 'hidden') { // скрыть
      this.r.addClass(this.elRef.nativeElement, 'o-hidden');
      return;
    }
    const button = this.elRef.nativeElement.querySelector('button'); // for BtnComponent
    this.isBtn = !!button;

    const wrapperSvg = this.elRef.nativeElement.querySelector('.wrapperSvg');
    this.isSvg = !!wrapperSvg;

    if (this.isBtn) { // for BtnComponent
      this.r.setAttribute(button, 'disabled', 'true');
    } else { // for SvgComponent & another html-element
      this.r.addClass(this.elRef.nativeElement, 'o-disabled');
    }
  }


  // === HOVER TOOLTIP ==========================================
  private tooltipElement!: HTMLElement | null;
  private tooltipCssClassName = 'custom-tooltip'; // o-tooltip
  private tooltipCssClassName_withoutAfter = 'custom-tooltip--without-after';

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.needDisabled_and_tooltip()) return;
    if (this.type_forPermission === 'hidden' || this.type_forPermission === 'none') return;
    this.showTooltip();
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (!this.needDisabled_and_tooltip()) return;
    if (this.type_forPermission === 'hidden' || this.type_forPermission === 'none') return;
    this.hideExistingTooltips();
    this.hideTooltip();
  }

  @HostListener('touchend') onTouchEnd() {
    if (!this.needDisabled_and_tooltip()) return;
    if (this.type_forPermission === 'hidden' || this.type_forPermission === 'none') return;
    this.showTooltip();
  }

  private showTooltip() {
    this.hideExistingTooltips();
    this.tooltipElement = this.r.createElement('div');
    this.r.addClass(this.tooltipElement, this.tooltipCssClassName);
    this.r.addClass(this.tooltipElement, this.tooltipCssClassName_withoutAfter);
    if (this.tooltipElement) {
      this.tooltipElement.innerHTML = this.meS.matTooltip_adminPermission;
      const rect = this.elRef.nativeElement.getBoundingClientRect();
      this.r.appendChild(document.body, this.tooltipElement);
      const top = rect.top + window.scrollY - this.tooltipElement.offsetHeight;
      const left = rect.left - this.tooltipElement.offsetWidth / 2;

      let topForTooltip = top + 77 + 6;
      if (this.isBtn) topForTooltip += 10;
      this.r.setStyle(this.tooltipElement, 'top', `${topForTooltip}px`);
      this.r.setStyle(this.tooltipElement, 'left', `${left + rect.width / 2}px`);
    }
  }

  private hideTooltip() {
    if (this.tooltipElement) {
      this.r.removeChild(document.body, this.tooltipElement);
      this.tooltipElement = null;
    }
  }

  private hideExistingTooltips() {
    const existingTooltips = document.querySelectorAll(`.${this.tooltipCssClassName}`);
    existingTooltips.forEach((tooltip) => {
      this.r.removeChild(this.r.parentNode(tooltip), tooltip);
      this.r.removeChild(document.body, tooltip);
      this.r.removeChild(document.body, tooltip);
    });
    this.cd.detectChanges();
  }

}
