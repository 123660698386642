import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AssignService } from '@app/dir_group_assignor/assign/assign.service';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { DeviceService } from '@services/device.service';
import { MainService } from '@services/main.service';
import { MeService } from '@services/me.service';
import { GlobasConst } from '@shared/constants/global-const';
import { HeadTitleComponent } from "../../../../shared/components/head-title/head-title.component";
import { BtnComponent } from "../../../../shared/components/btn/btn.component";
import { SvgComponent } from "../../../../shared/components/__svg_img/svg/svg.component";
import { CurrencyCentsPipe } from '@app/dir_group_assignor/payments/pipes/currency-cents.pipe';
import { ConverTransfersStatusToVisualText, GameTransfersDetailsDto } from '@app/dir_group_assignor/payments/components/payments-assignor-fees/game-transfers-details-dto';
import { StatusComponent } from '@components/status/status.component';

@Component({
  selector: 'app-transaction-info',
  standalone: true,
  imports: [CommonModule, CurrencyCentsPipe, HeadTitleComponent, BtnComponent, SvgComponent, StatusComponent],
  templateUrl: './transaction-info.component.html',
  styleUrls: ['./transaction-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionInfoComponent implements OnInit {
  ConverTransfersStatus = ConverTransfersStatusToVisualText;
  getTimeStringAsDate(timeString: string | undefined | null): Date | null {
    if (timeString == null)
      return null;
    const today = new Date();
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    today.setHours(hours, minutes, seconds);
    return today;
  }

  gameData?: GameTransfersDetailsDto;
  constructor(

    public globalConst: GlobasConst,
    private mainService: MainService,
    public meS: MeService,
    public gameS: GameService,
    private route: ActivatedRoute,
    public router: Router,
    public deviceS: DeviceService,
    public assignS: AssignService,
    public cd: ChangeDetectorRef) {
    var t = meS.me;
  }

  ngOnInit(): void {
    const transactionId = this.route.snapshot.queryParams['gameId'];
    this.mainService.getPaymentDetails(transactionId).subscribe((x: GameTransfersDetailsDto) => {
      this.gameData = x as GameTransfersDetailsDto;
      this.cd.markForCheck();
    });
  }

  goBack() {
    window.history.back();
  }


  isNotPaidOrIntransit(): boolean {
    const status = this.gameData?.overallTransfersStatus;
    return status !== 'PAID' && status !== 'INTRANSIT';
  }
  
}


