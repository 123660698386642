import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';

import { AuthorizationClientType, MainService } from '@services/main.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ErrorComponent } from '@components/__info_text_message_error_warning/error/error.component';
import { HelperClass } from '@classes/Helper-Classes';
import { CustomValidators } from '@classes/CustomValidators';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { BtnComponent } from '@components/btn/btn.component';
import { AuthService } from '@app/auth/auth.service';
import { GoogleSignUpComponent } from '@components/google/components/google-sign-up/google-sign-up.component';
import { IResFromGoogleForSignup } from '@services/google-people.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ClassUser, UserRegisteredStatus } from '@models/user';
import { RoutesService } from '@services/routes.service';
import { IRequest_forRegistrationAndLogin, ILoginResponseDto, LoginResponseMessage } from '@app/auth/auth_models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceService } from '@services/device.service';
import { Router } from '@angular/router';
import { urlDashboard } from '@app/app.module';
import { MeService } from '@services/me.service';
import { environment } from '@env/environment';
import { AppleSingUpComponent } from './apple-sing-up/apple-sing-up.component';
import { AuthorizationAppleService } from '@services/authorization.apple.service';
import { GoogleAuthorizationComponent, IGoogleNativeResponse } from './google-authorization/google-authorization.component';
import Median from 'median-js-bridge';

interface ILoginForm {
  email: FormControl<string>;
  password: FormControl<string>;

  firstName: FormControl<string>;
  secondName: FormControl<string>;
  preferredName?: FormControl<string>;
}

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterLink, MatIconModule, MatProgressSpinnerModule, ErrorComponent, FieldComponent, InputCtrlComponent, BtnComponent, GoogleSignUpComponent, AppleSingUpComponent, GoogleAuthorizationComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent extends HelperClass implements OnInit, OnDestroy {
  form!: FormGroup<ILoginForm>;
  textAfterSent = ''; // после отправки на сервер

  errorMessage: string | null = null;
  loginMessageCode: LoginResponseMessage | undefined;
  isProd = environment.production;
  readonly isNativeApp = Median.isNativeApp();
  constructor(
    private meS: MeService,
    private router: Router,
    public authS: AuthService,
    private formBuilder: UntypedFormBuilder,
    public mainS: MainService,
    public routesS: RoutesService,
    public cd: ChangeDetectorRef,
    public deviceService: DeviceService,
    public route: ActivatedRoute,
    public httpAuthorization: AuthorizationAppleService,
  ) {
    super(cd);
    localStorage.clear();
    this.createForm();
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((res) => this.errorMessage = null);

  }
  ngOnInit(): void {
    this.mainS.logoutSilent();
    setTimeout(() => {
      this.deviceService.isZoomContent.next(DeviceService.noneZoomContent);
    }, 0);
  }
  ngOnDestroy(): void {
    this.deviceService.isZoomContent.next(DeviceService.defaultZoomContent);
  }

  ourAuthorizationResponse(res: any): void {
    if (this.startRequest())
      return;
    try {
      this.loginResponce(res);
    } finally {
      this.endRequest();
    }
  }

  signinWithGoogleNative(res: IGoogleNativeResponse) {
    this.signinWithGoogle({ id_token: res.idToken }, res.clientType);
  }

  signinWithGoogle(resFromGoogleForSignup: IResFromGoogleForSignup, clientType: AuthorizationClientType): void {
    if (this.startRequest()) return;

    const queryParams_forRegistration = this.authS.queryParams_forRegistration;
    this.mainS.signinWithGoogle(resFromGoogleForSignup?.id_token!, clientType,
      queryParams_forRegistration?.groupId,
      queryParams_forRegistration?.role
    ).toPromise()
      .then((res: ILoginResponseDto | undefined) => {
        this.loginResponce(res);
      })
      .catch((err) => {
      })
      .finally(() => {
        this.endRequest();
        // this.form.markAsUntouched();
        this.cd.detectChanges();
      });
  }

  // === FORM ==================================
  createForm(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, CustomValidators.patternEmail]],
      password: ['', [Validators.required, Validators.minLength(7), CustomValidators.patternPassword]],
    });
  }

  get ctrl(): ILoginForm {
    return this.form?.controls;
  }


  private async loginResponce(res: ILoginResponseDto | undefined) {
    const queryParams_forRegistration = this.authS.queryParams_forRegistration;
    if (queryParams_forRegistration?.role === 'OFFICIAL' && queryParams_forRegistration?.groupId) {
      localStorage.setItem('temp_queryParams_forRegistration_groupId', queryParams_forRegistration.groupId);
    }

    this.loginMessageCode = res?.messageCode;
    if (this.loginMessageCode == "successfulLogin") {
      this.mainS.setTokenBeforeLogin({ token: res?.token ?? "" }, urlDashboard);
    } else if (this.loginMessageCode == "registrationIsNotCompleted") {
      this.meS.setMeUser(res?.user);
      if (res?.user?.roleCurrent == "OFFICIAL") {
        this.router.navigate(['/signupOfficial/'], { queryParams: { role: "OFFICIAL", userRegisteredStatus: res?.user?.userRegisteredStatus, groupId: res?.groupId } });
      } else {
        this.router.navigate(['/signup'], { queryParams: { userRegisteredStatus: res?.user?.userRegisteredStatus } });
      }
    }
  }

  sendForm(): void {
    this.form.markAllAsTouched();
    if (!this.form.valid) return;
    if (this.startRequest()) return;

    const sendObj: ClassUser = this.form.getRawValue();
    const queryParams_forRegistration = this.authS.queryParams_forRegistration;
    // !!! NO DELETE . Посмотреть для чего это
    if (queryParams_forRegistration?.role === 'OFFICIAL' && queryParams_forRegistration?.groupId) {
      localStorage.setItem('temp_queryParams_forRegistration_groupId', queryParams_forRegistration.groupId);
    }

    this.mainS.login(sendObj as IRequest_forRegistrationAndLogin).toPromise()
      .then((res: ILoginResponseDto | undefined) => {
        this.loginResponce(res);
      })
      .finally(() => this.endRequest());
  }

  resend(): void {
    this.textAfterSent = this.ctrl.email?.value;
    if (this.startRequest()) return;
  }

}
