import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MainService } from '@services/main.service';
import {
  arrLinkObj_groupProfile,
  getLinkObj_groupProfile,
  ICurrentLink_groupProfile,
  IRequest_for_inviteUserToGroup,
  TCurrentLinkDrop_groupProfile,
  TLink_groupProfile_camelCase,
} from '@app/group-profile/groupProfile';
import { MeService } from '@services/me.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { rout_groupProfile } from '@app/app.module';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class GroupProfileService {
  // readonly typePageProfile: TypePageProfile = 'groupProfile';

  // === CURRENT GROUP =====================================
  // private groupSub$ = new BehaviorSubject<ClassGroup>({});
  // group$ = this.groupSub$.asObservable()
  // this.router.navigate([rout_groupProfile_info, this.meS.meCurrentGroup?.id]);
  // getGroupById(idGroup: string): Observable<ClassGroup | null> {
  //   if (this.group$.getValue()?.id === idGroup) return of(null); // !!! чтобы при переключении вкладок повторно не запрашивать сервер
  //   return this.mainS.getGroupById(idGroup).pipe(
  //     tap((res) => {
  //       if (res) this.group$.next(res);
  //     }),
  //   );
  // }
  // get group(): ClassGroup {
  //   // return this.groupSub$.getValue()?.id || '';
  //   return this.groupSub$.getValue()
  // }

  // === CURRENT LINK ===============================================
  readonly arrLinkObj_groupProfile = arrLinkObj_groupProfile;
  private currentLinkSub$ = new BehaviorSubject<ICurrentLink_groupProfile>({});
  currentLink$ = this.currentLinkSub$.asObservable();

  updateCurrentLink(currentLink: ICurrentLink_groupProfile) {
    this.currentLinkSub$.next({ ...this.currentLinkSub$.getValue(), ...currentLink });
  }

  set currentLinkObj(currentLinkObj: ICurrentLink_groupProfile) {
    const currentLink = currentLinkObj.currentLink;
    const newCurrentLink: ICurrentLink_groupProfile = { currentLink };
    this.currentLinkSub$.next(newCurrentLink);
  }

  get currentLinkObj(): ICurrentLink_groupProfile {
    return this.currentLinkSub$.getValue();
  }

  // !!! вызывать после того как прочитаны queryParams из url (если из нет то по дефолту установится currentLinkDrop_current)
  setCurrentLink_groupProfile(currentLink?: TLink_groupProfile_camelCase): void {
    if (currentLink && currentLink === this.currentLinkObj.currentLink?.upperCase) return; // !!! если текущее значение === значению которое поступило, то не надо заново устанавливать
    const currentLinkDrop: TCurrentLinkDrop_groupProfile = getLinkObj_groupProfile(currentLink!);
    this.currentLinkObj = { currentLink: currentLinkDrop };
  }

  constructor(
    private mainS: MainService,
    private meS: MeService,
    private router: Router,
    private route: ActivatedRoute,
    // private forTestS: ForTestService,
    // private profileS: ProfileService,
  ) {
    // this.groupSub$ = this.meS.meCurrentGroup$
    // this.groupSub$.next(this.meS.meCurrentGroup!)
    // console.log('groupSub$ :', this.meS.meCurrentGroup)
    // this.subscribeToEvents();
    // setTimeout(() => {
    // console.log('arrLinkObj_groupProfile :', this.arrLinkObj_groupProfile);
    // console.log('currentLink :', this.currentLinkObj?.currentLink);
    // }, 111);
  }

  // subscribeToEvents(): void {
  //   this.router.events.pipe(
  //     tap((event) => console.log('event :', event)),
  //     filter((event: any) => {
  //       return event instanceof ResolveStart || event instanceof ResolveEnd
  //         || event instanceof NavigationSkipped || event instanceof NavigationEnd;
  //     }),
  //     tap((event) => console.log('event :', event)),
  //     map((event: NavigationEnd) => event.url),
  //     untilDestroyed(this),
  //   )
  //     .subscribe((res) => {
  //       console.log('res :', res)
  //       const currentLink = getLink_groupProfile_camelCase_fromUrl(res);
  //       console.log('currentLink :', currentLink)
  //       console.log('currentLinkObj.currentLink :', this.currentLinkObj.currentLink)
  //       if (this.currentLinkObj.currentLink?.upperCase === currentLink) return; // !!! если текущий currentLink === поступившему, то ничего не надо делать
  //       this.setCurrentLink_groupProfile(currentLink);
  //       console.error('currentLinkObj.currentLink :', this.currentLinkObj.currentLink);
  //     });
  // }

  navigateBy_groupProfile_camelCase(currentLink?: TLink_groupProfile_camelCase): void {
    if (!currentLink) {
      console.error('groupProfileS.navigate() :', currentLink);
      return;
    }
    this.router.navigate([rout_groupProfile + '_' + currentLink]);
  }

  navigateBy_currentLinkDrop(currentLinkDrop?: TCurrentLinkDrop_groupProfile): void {
    if (!currentLinkDrop) {
      console.error('groupProfileS.navigateBy_currentLinkDrop() :', currentLinkDrop);
      return;
    }
    this.navigateBy_groupProfile_camelCase(currentLinkDrop.upperCase);
    // this.router.navigate([rout_groupProfile+"_"+currentLinkDrop.upperCase])
  }

  inviteUserToGroup(sendObj: IRequest_for_inviteUserToGroup): Observable<null> {
    return this.mainS.inviteUserToGroup(sendObj);
  }

  // getLink_for_inviteUserToGroup(userRoleUpperCase: TUserRoleUpperCase): Observable<string> {
  //   return this.mainS.getLink_for_inviteUserToGroup(userRoleUpperCase).pipe(
  //     tap((res) => {
  //       // https://v3.beta.joinnotch.com/v1/signup?role=ADMIN&groupId=d58907c2-f437-4045-84a4-68da2ff2aef5&key=4de91826-106d-4845-a736-a74f1bf6074a
  //       console.log('getLink_for_inviteUserToGroup :', res);
  //     }),
  //   );
  // }

}
