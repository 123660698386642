<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
             } as obj' class='o-wrapPage o-wrapPage--noMarginTopAndNoPadding' wrapPage [widthPage]='1300'>

  <!--  === FILTERS & TITLE ===========================-->
  <div class='o-header o-header--noHaveToggleCurrentPast'>
    <div class='o-header__linkPage'>
      <app-filtersSelectedIcon></app-filtersSelectedIcon>
    </div>
    <div class='o-header__content'>
      <div class='o-header__title'>
        <!--      <headTitle text='Payers' typeText='text1-blueDark' svgLeft='arrow_squareLeft&24' (svgLeftEmit)='otherS.locationBack()'></headTitle>-->
        <meSvg svgName='arrow_squareLeft&24' (click)='router.navigate(["payments/payers"])'></meSvg>
        Payers
      </div>
      <div class='o-header__filters'>
        <div *ngIf='{payer: (payer$|async)} as objPayer' class='headerPayerInfo__photo'>
          <photo [data]='objPayer.payer' w='56'></photo>
          <div class='headerPayerInfo__photo__text'>
            <span>{{ objPayer.payer?.payer|getUserName }}</span>
            <span>{{ objPayer.payer?.competition?.competitionName }}</span>
          </div>
        </div>

        <div class='o-header__actions'>
          <meSvg svgName='download_3&44' (click)='null' [disabled]='true'></meSvg>
        </div>
        <app-filters (emit)='emitSettingRequest($event)'></app-filters>
      </div>
      <app-filtersSelected></app-filtersSelected>
    </div>
  </div>

  <!--  <headTitle text='Payers' typeText='text1-blueDark' svgLeft='arrow_squareLeft&24' (svgLeftEmit)='otherS.locationBack()'></headTitle>-->
  <!--    <div class='headerPayerInfo'>-->
  <!--      <div class='headerPayerInfo__photo'>-->
  <!--        <photo [data]='payer' w='56'></photo>-->
  <!--        <div class='headerPayerInfo__photo__text'>-->
  <!--          <span>{{payer?.payer|getUserName}}</span>-->
  <!--          <span>{{payer?.competition?.competitionName}}</span>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class='headerPayerInfo__actions'>-->
  <!--        <meSvg svgName='download_3&44' (click)='null'></meSvg>-->
  <!--        <dropDateRange [arrPeriods]='payS.arrPeriods' (changePeriod)='ctrl.datePeriod.patchValue($event)'></dropDateRange>-->
  <!--        <dropFormCtrl formControlName='status' [listStr]='[]' placeholder='Status' w='103'></dropFormCtrl>-->
  <!--      </div>-->
  <!--    </div>-->

  <div class='o-content'>
    <div class='wrapBlockPayerInfo'>
      <div class='wrapBlockPayerInfo__item'>
        <div class='wrapBlockPayerInfo__item__title'>Game Count</div>
        <div class='wrapBlockPayerInfo__item__number'>207</div>
      </div>
      <div class='wrapBlockPayerInfo__item'>
        <div class='wrapBlockPayerInfo__item__title'>Billed Amount</div>
        <div class='wrapBlockPayerInfo__item__number'>$35.5k</div>
      </div>
      <div class='wrapBlockPayerInfo__item'>
        <div class='wrapBlockPayerInfo__item__title'>Official Fees</div>
        <div class='wrapBlockPayerInfo__item__number'>$20k</div>
      </div>
      <div class='wrapBlockPayerInfo__item'>
        <div class='wrapBlockPayerInfo__item__title'>Assigning Revenue</div>
        <div class='wrapBlockPayerInfo__item__number'>$15k</div>
      </div>
    </div>

    <div class='headerTablePayerInfo'>
      <div class='headerTablePayerInfo__title'>Recent Transactions</div>
      <div class='headerTablePayerInfo__actions'>
        <meSvg svgName='download_3&44' (click)='null' [disabled]='true'></meSvg>
        <!--      <inputCtrl formControlName='search' placeholder='Search' svgPrefix='search_grey&20' w='320'></inputCtrl>-->
        <inputCtrl [(ngModel)]='search' placeholder='Search' svgPrefix='search_grey&20' w='320'></inputCtrl>
        <btn text='Mark as paid' bcg='blueDark' btnWidth='133' (emit)='null'></btn>
      </div>
    </div>

    <div class='o-table o-mg-t-16'>
      <div class='o-table__header'>
        <div *ngFor='let itemHeader of tableHeader; let idx = index;' class='o-table__header__item widthItem--{{idx+1}}'>
          <meSvg *ngIf='itemHeader.isChx' [svgName]='isSelectAll|getChxSvgName' class='o-table__header__item__chx'
                 (click)='selectAll()'></meSvg>
          {{ itemHeader.text }}
          <!--          <meSvg *ngIf='itemHeader.isActive' [svgName]='"arrow_top&16"' class='o-table__header__item__arrow'></meSvg>-->
        </div>
      </div>
      <div *ngIf='(payS.fakeAssignorFees$|async)?.length; else nothingList' class='o-table__list'>
        <ng-container *ngFor='let item of (payS.fakeAssignorFees$|async); let first = first; let idx = index; let last = last'>
          <div class='o-table__list__item'>
            <div class='widthItem--1'>
              <meSvg [svgName]='item?.isSelect|getChxSvgName' (click)='selectItem(item, $event)'></meSvg>
              <span>{{ item.game?.gameNumber }}</span>
            </div>
            <div class='widthItem--2'>
              <span>{{ item.game?.date|date:'MMM d, yyyy' }}</span> &nbsp;
              <span>at {{ item.game?.date|date:'shortTime' }}</span>
            </div>
            <div class='widthItem--3'>
              <payoutFormat [payoutFormat]='item.payoutFormat!'></payoutFormat>
            </div>
            <div class='widthItem--4'>
              <span>+{{ item.amount|currency }}</span>
            </div>
            <div class='widthItem--5'>
              <span>+{{ item.amount|currency }}</span>
            </div>
            <div class='widthItem--6'>
              <span>+{{ item.amount|currency }}</span>
            </div>
            <div class='widthItem--7'>
              <btn text='View' bcg='white' [btnWidth]='87' [btnHeight]='36'></btn>
            </div>
            <div class='widthItem--8'>
              <!--              [matMenuTriggerFor]='menuForPoints' (click)='$event.stopPropagation()'-->
              <meSvg svgName='arrow_chevronRight&24'></meSvg>
              <!--              <mat-menu #menuForPoints='matMenu'>-->
              <!--                                  <button (click)='null'></button>-->
              <!--              </mat-menu>-->
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <pagination *ngIf='(payS.fakeAssignorFees$|async)?.length' [data]='(payS.dataArrFakeAssignorFees$|async)!'
                (updatePage)='paginationMethod("updatePage",undefined,$event)'
                (updateResults)='paginationMethod("updateCountResults",$event)'></pagination>
  </div>
</div>

<ng-template #spinner>
  <spinner></spinner>
</ng-template>

<ng-template #nothingList>
  <!--  <nothingList typePage='payers'></nothingList>-->
  <nothingList [tableEmpty]='filtersS.searchValue|getTableEmpty'>
    <btn bcg='newGreen' text='New Competition +' [btnHeight]='deviceS.btnHeightForNothingList'
         (emit)='router.navigate([urlSetupCompetition])' class='class_ngContent_btn_for_nothingList'></btn>
  </nothingList>
</ng-template>
