import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TCompetitionUserStatus } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { TUserStatus } from '@models/user';
import { TReportStatus } from '@app/dir_group_assignor/reports/report';
import { StrCasePipe } from '@pipes/string/str-case.pipe';
import { TypeStringCase } from '@services/lodash.service';

@Component({
  selector: 'status',
  standalone: true,
  imports: [CommonModule, StrCasePipe],
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnChanges {
  @Input() status?: TCompetitionUserStatus | TUserStatus | TReportStatus | string;
  @Input() strCase?: TypeStringCase; // 'camel' | 'capitalize' | 'kebab' | 'lower' | 'snake' | 'escape' | 'unescape' | 'trim' | 'truncate'

  @Input() text?: string;
  @Input() color?: string;
  statusClass = this.status;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.status?.currentValue) {
      this.statusClass = this.status?.replace(' ', '_').toUpperCase();
      if (this.status === 'CANCELLED') this.status = 'CANCELED';
      if (this.status === 'PROCESSING') this.status = 'Processing';
    }
  }
}
