<div *ngIf='{ desktop: (deviceS.isDesktop$|async)!,
              mobile: (deviceS.isMobile$|async)!,
              report: (reportS.report$|async),
              DUE: (reportS.report$|async)?.status === "DUE",
              FLAGGED: (reportS.report$|async)?.status === "FLAGGED",
              NEEDS_APPROVAL: (reportS.report$|async)?.status === "NEEDS_APPROVAL",
              APPROVED: (reportS.report$|async)?.status === "APPROVED",
              ARCHIVED: (reportS.report$|async)?.status === "ARCHIVED",
              game_CANCELED: (reportS.report$|async)?.game?.gameStatus === "CANCELLED",
              noAssignGameOfficials: ((reportS.report$|async)?.game|getNoAssignGO),
             } as obj' class='wrapperForReport wrapperReportInfo' [formGroup]='form'>

  <div class='wrapperForReportBlock'>
    <headTitle text='Post Game Report / Game #{{obj.report?.game?.gameNumber}}' typeText='text1-blueDark'
               svgLeft='arrow_squareLeft&24' (svgLeftEmit)='otherS.goToBack()'>
      <status *ngIf='obj.desktop' [status]='obj.report?.status!' strCase='lower' class='ngContentRight o-mg-l-20'></status>
    </headTitle>

    <status *ngIf='obj.mobile' [status]='obj.report?.status!' strCase='lower' class='ngContentRight o-mg-l-20'></status>

    <div *ngIf='obj.FLAGGED' class='forFlaggedBlock o-wrap'>
      <meSvg svgName='flag_circleRed&48'></meSvg>
      <div class='forFlaggedBlock__forText'>
        <div class='forFlaggedBlock__forText__title'>Flagged Report Reason</div>
        <div class='forFlaggedBlock__forText__text'>
          <!--          {{ctrl.gameIsCompleted.value == "No" ? "Game did not complete":"Changes made to officials"}}-->
          {{ obj.report?.gameIsCompleted ? "Changes made to officials" : "Game did not complete" }}
        </div>
      </div>
      <meSvg *ngIf='!meS.OFFICIAL' svgName='circle_3points2&20' [matMenuTriggerFor]='menuForFlaggedBlock'
             class='forFlaggedBlock__points'></meSvg>
      <mat-menu #menuForFlaggedBlock='matMenu'>
        <button (click)='null'>Message reporter</button>
        <button (click)='null'>Share</button>
      </mat-menu>
    </div>

    <div class='o-wrap contentPreviewReport {{obj.FLAGGED ? "contentPreviewReport--forStatusFlagged":""}}'>
      <div class='contentPreviewReport__title'>
        {{ obj.report?.game?.competition?.competitionName }} - {{ obj.report?.game?.homeTeam?.teamName }}
        vs. {{ obj.report?.game?.awayTeam?.teamName }}
      </div>
      <div class='contentPreviewReport__previewContainer'>


        <div class='contentPreviewReport__previewItem'>
          <span>Date<ng-container *ngIf='obj.mobile'> & Time</ng-container></span>
          <div>
            <dateFormatted [date]='obj.report?.game?.date!' type='date' format='E, LLL d, yyyy'></dateFormatted>
            <ng-container *ngIf='obj.mobile'>
              <dateFormatted [date]='obj.report?.game?.date!' type='time' format='shortTime'></dateFormatted>
            </ng-container>
          </div>
        </div>

        <div *ngIf='obj.desktop' class='contentPreviewReport__previewItem'><span>Time</span>
          <dateFormatted [date]='obj.report?.game?.date!' type='time' format='shortTime'></dateFormatted>
        </div>

        <div class='contentPreviewReport__previewItem'><span>Age / Level</span>
          <ng-container *ngIf='{levelValue: obj.report?.game?.levels?.level|getLevelValue:1} as objLevel'>
            <span>{{ obj.report?.game?.ageGroup?.gameAgeDescription }} {{
                objLevel.levelValue ? "/ " +
                  objLevel.levelValue : ""
              }}</span>
          </ng-container>
        </div>
        <div class='contentPreviewReport__previewItem'><span>Gender</span>
          <span>{{ obj.report?.game?.gender|getFirstLetter }}</span>
        </div>
        <div class='contentPreviewReport__previewItem'><span>Competition</span>
          <span>{{ obj.report?.game?.competition?.competitionName }}</span>
        </div>
        <div class='contentPreviewReport__previewItem'><span>Duration</span>
          <span>{{ obj.report?.game?.duration }} min.</span>
        </div>
        <div class='contentPreviewReport__previewItem contentPreviewReport__previewItem--location'><span>Location</span>
          <div>
            <span>{{ obj.report?.game?.location?.name }}</span>
            <span>{{ obj.report?.game?.location?.address?.street }}</span>
            <span>{{ obj.report?.game?.location?.address?.city }} {{ obj.report?.game?.location?.address?.state }} {{
                obj.report?.game?.location?.address?.zipcode
              }}</span>
            <btn *ngIf='!meS.OFFICIAL' text='Get Directions' bcg='blueDark' (click)='geo.goToGoogleMap()'
                 btnHeight='36'></btn>
          </div>
        </div>
        <div class='contentPreviewReport__previewItem'><span>Payment</span>
          <span>{{ obj.report?.payment }}</span>
        </div>
        <div class='contentPreviewReport__previewItem contentPreviewReport__previewItem--assignor'>
          <span class='title'>Assignor</span>
          <div id='assignor' class='contentPreviewReport__previewItem__assignor'>
            <span>{{ meS?.me|getUserName }}</span>
            <svgAndText [marginForLeftSvg]='obj.mobile ? 8 : 14' [text]='meS.mePhone' leftSvgName='phoneBlue&20'
                        typeText='text8-blueDark'></svgAndText>
            <svgAndText [marginForLeftSvg]='obj.mobile ? 8 : 14' [text]='meS.meEmail' leftSvgName='mailBlue&20'
                        typeText='text8-blueDark'></svgAndText>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--    === GAME DETAILS ============================================-->
  <div *ngIf='!obj.game_CANCELED' class='o-wrap wrapperForReportBlock gameDetailsReportInfo'>
    <div class='gameDetailsReportInfo__title'>Game Details</div>
    <div class='gameDetailsReportInfo__container'>


      <field text='Did this game complete?*'>
        <div *ngIf='showGameCompleteError && form.controls.gameIsCompletedDrop.invalid' class='forFlaggedBlock__forText__text'>
          Report is missing information: Did this game complete?
        </div>
        <app-dropdown formControlName='gameIsCompletedDrop' [array]='arrYesNoDrop' placeholder='Select Answer'
                      (changeValObj)='changeGameIsCompleted($event)'></app-dropdown>
      </field>

      <ng-container [ngTemplateOutlet]='adjustmentStatusTemplate'></ng-container>

      <field text='Final Score'>
        <div class='finalScore'>
          <inputCtrl formControlName='team_1' [w]='obj.mobile ? "100%" : 320'></inputCtrl>
          <inputCtrl class='o-text5' formControlName='homeTeamScore' w='50' [isNumber]='true' [isTextCenter]='true'
                     [defaultAlwaysZero]='true' (changeVal)='changeVal($event,"homeTeamScore")' [maxLength]='3'></inputCtrl>
        </div>
        <div class='finalScore o-mg-t-8-important'>
          <inputCtrl formControlName='team_2' [w]='obj.mobile ? "100%" : 320'></inputCtrl>
          <inputCtrl class='o-text5' formControlName='awayTeamScore' w='50' [isNumber]='true' [isTextCenter]='true'
                     (changeVal)='changeVal($event,"awayTeamScore")' [maxLength]='3' [defaultAlwaysZero]='true'></inputCtrl>
        </div>
      </field>

      <chx *ngIf='ctrl.gameIsCompletedDrop.value && !ctrl.gameIsCompletedDrop.value?.valueBoolean'
           [formControl]='ctrl.gameNotBegun' text='Game did not begin' class='gameNotBegun'
           (changeVal)='changeVal($event,"gameNotBegun")'></chx>

      <field text='Additional Comments'>
        <inputCtrl formControlName='ctrlTextarea' placeholder='Additional Comments' type='textarea' height='150'
                   [fixedHeightWithScroll]='true' [showErrText]='true' [maxLength]='205'>
          <div class='ngContent_inputCtrl'><span>{{ 205 - ctrl.ctrlTextarea.value.length! }}</span> characters left</div>
        </inputCtrl>
      </field>
    </div>
    <ng-container [ngTemplateOutlet]='officialAttendancesTemplate'></ng-container>
  </div>

  <!--    === CANCELLED DETAILS ============================================-->
  <div *ngIf='obj.game_CANCELED' class='o-wrap wrapperForReportBlock gameDetailsReportInfo cancelledDetails'>
    <div class='gameDetailsReportInfo__title'>Cancelation Details</div>
    <div class='gameDetailsReportInfo__container'>
      <div class='gameDetailsReportInfo__dateCancellation'>
        <!--      https://notch.atlassian.net/browse/NOT30-516  => “Cancelation“ should be with one L-->
        <span>Date of Cancelation</span>
        <span>{{ obj.report?.game?.gameAdjustmentStatus?.cancelDate!|date:'fullDate' }}</span>
      </div>

      <ng-container [ngTemplateOutlet]='adjustmentStatusTemplate'></ng-container>

      <field text='Teams' class='cancelledDetails__teams'>
        <!--      https://notch.atlassian.net/browse/NOT30-516  => The size of teams fields should be 320px-->
        <inputCtrl formControlName='team_1' [disabled]='true' [w]='obj.mobile ? "100%" : 320'></inputCtrl>
        <inputCtrl formControlName='team_2' [disabled]='true' [w]='obj.mobile ? "100%" : 320'></inputCtrl>
      </field>

      <field text='Cancelation Reason (Optional)'>
        <inputCtrl formControlName='ctrlTextarea'
                   placeholder='It’s optional to enter a reason and inform any assigned officials why the game was canceled…'
                   type='textarea' height='150'></inputCtrl>
      </field>
    </div>
    <ng-container [ngTemplateOutlet]='officialAttendancesTemplate'></ng-container>
  </div>


  <!--  === Attendances Template =========-->
  <ng-template #adjustmentStatusTemplate>
    <field *ngIf='!meS.OFFICIAL' text='Adjustment Status'>
      <div *ngIf='showAdjustmentStatusError' class='forFlaggedBlock__forText__text'>
        Report is missing information: Select Adjustment Status
      </div>
      <app-dropdown formControlName='gameAdjustmentStatus' [array]='arrAdjustmentStatus'
                    placeholder='Select Adjustment Status' [needSortByName]='false'></app-dropdown>
    </field>
  </ng-template>

  <!--  === Attendances Template =========-->
  <ng-template #officialAttendancesTemplate>
    <div class='officialAttendances'>
      <div *ngIf='obj.report?.attendance?.length || obj.noAssignGameOfficials?.length'
           class='officialAttendances__title'>
        Official Attendances
      </div>
      <div class='officialAttendances__content'>
        <!--      === ATTENDANCE заасайненые роли =================================-->
        <ng-container *ngFor='let attendanceItem of obj.report?.attendance; let lastAttendance=last; let isFirst=first'>
          <!--        // user: attendanceItem.official || attendanceItem.switchedReferee,-->
          <ng-container *ngIf='{ present: attendanceItem.stateOfficialAttendance=="Present",
                               replace: attendanceItem.stateOfficialAttendance=="Replace",
                               absent: attendanceItem.stateOfficialAttendance=="Absent",
                              } as objAttendance'>
            <!-- !!! для сортировки по officialPositionNumber o-order-{{attendanceItem.go?.officialPositionNumber}} -->
            <div class='officialAttendances__item o-order-{{attendanceItem.go?.officialPositionNumber}}'>
              <div class='officialAttendances__item__photo'>
                <photo [data]='attendanceItem.official' [w]="obj.mobile ? '40' : '48'"></photo>
                <div *ngIf='meS.OFFICIAL && isFirst'
                     class='reporter-status g-flex g-flex--align-center g-flex--justify-center'>R
                </div>
              </div>
              <div class='officialAttendances__item__info'>
                <div *ngIf='attendanceItem.official' class='officialAttendances__item__info__name'>{{
                    attendanceItem.official|getUserName
                  }}
                </div>
                <div *ngIf='!attendanceItem.official && attendanceItem.switchIdentifier'
                     class='officialAttendances__item__info__name'>
                  {{ attendanceItem.switchIdentifier }}
                </div>
                <div class='officialAttendances__item__info__role'>
                  Official #{{ attendanceItem.go?.officialPositionNumber }} - {{
                    attendanceItem.go?.officialPositionName
                  }}
                </div>
              </div>
              <dropFormCtrl [(ngModel)]='attendanceItem.stateOfficialAttendance' [h]="obj.mobile ? '36' : '44'"
                            [listStr]='arrStateOfficialAttendanceString' placeholder='Select' [ngModelOptions]='{standalone: true}'
                            (changeVal)='changeVal($event,"stateOfficialAttendance",attendanceItem)' [svgPrefix]='(objAttendance.present ? "circle_chx3&20":undefined)
                            || (objAttendance.replace ? "arrow_twoCircle2&20":undefined)
                            || (objAttendance.absent ? "circle_crossRed&20":undefined)'
                            [disabled]='obj.APPROVED || isDisabledForEdit'></dropFormCtrl>
            </div>

            <!--          !!! чтобы кнопки были в самом низу. проверка чтобы не было пустых(НЕзаасайненых) ролей-->
            <ng-container *ngIf='lastAttendance && !obj.noAssignGameOfficials?.length' [ngTemplateOutlet]='btnWrapRef'
                          [ngTemplateOutletContext]='{attendanceItem: attendanceItem}'></ng-container>
          </ng-container>
        </ng-container>

        <!--      === GameOfficials НЕзаасайненые роли // noAssignGameOfficials: (report?.game|getNoAssignGO) =================================-->
        <ng-container *ngFor='let go of obj.noAssignGameOfficials; let lastGO=last'
                      class='officialAttendances__noAssign'>
          <div class='officialAttendances__item o-order-{{go?.officialPositionNumber}}'>
            <meSvg svgName='user_circleGreen&48'></meSvg>
            <div class='officialAttendances__item__info'>
              <div class='officialAttendances__item__info__name'>Unassigned</div>
              <div class='officialAttendances__item__info__role'>Official #{{ go.officialPositionNumber }}- {{
                  go.officialPositionName
                }}
              </div>
            </div>
            <btn text='Add Official' bcg='newGreen' (emit)='openPopupAssign(null,go)' [btnHeight]="obj.mobile ? '36' : '44'"
                 [disabled]='obj.APPROVED || isDisabledForEdit'></btn>
          </div>

          <ng-container *ngIf='lastGO' [ngTemplateOutlet]='btnWrapRef'
                        [ngTemplateOutletContext]='{go: go}'></ng-container>
        </ng-container>
      </div>
    </div>

  </ng-template>


  <!--  === BUTTONS =================================================================-->
  <ng-template #btnWrapRef let-attendanceItem='attendanceItem' let-go='go'>
    <ng-container *ngIf='!isDisabledForEdit'>
      <btnWrap *ngIf='obj.DUE'>
        <!--      !!! Save as draft => это сохранение без изменения статуса. например если. Due и ты инфу какую то поменял но не хочет сабмитеть к ассайнеру а добавить что то еще завтра-->
        <btn text='Save as draft' bcg='white' (emit)='updateReport(true)' svgLeft='saveAsDraft&20' [btnHeight]="obj.mobile ? '36' : '44'"
             [disabled]='reqPending'></btn>
        <btn text='Submit' bcg='blueDark' (emit)='updateReport()' [disabled]='reqPending && !form.valid'
             [btnHeight]="obj.mobile ? '36' : '44'"></btn>
      </btnWrap>
      <btnWrap *ngIf='obj.FLAGGED'>
        <btn text='Cancel' bcg='white' routerLink='/reports' [disabled]='reqPending' [btnHeight]="obj.mobile ? '36' : '44'"></btn>
        <btn text='Save Changes' bcg='blueDark' (emit)='updateReport()' [disabled]='reqPending && !form.valid'
             [btnHeight]="obj.mobile ? '36' : '44'"></btn>
      </btnWrap>
      <btnWrap *ngIf='obj.NEEDS_APPROVAL'>
        <btn text='Save Changes' bcg='white' (emit)='updateReport()' [disabled]='reqPending && !form.valid'
             [btnHeight]="obj.mobile ? '36' : '44'"></btn>
        <btn text='Approve & Pay' bcg='blueDark' (emit)='openPopupApprove()' [disabled]='reqPending || alreadyApprove'
             [btnHeight]="obj.mobile ? '36' : '44'"></btn>
      </btnWrap>
    </ng-container>
  </ng-template>
</div>