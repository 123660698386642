<div class='wrapperPopupForOfficialForRequestToRole'>
  <!--  <div class='werewr' (click)='typeResponse = null'>typeResponse = null</div>-->
  <!--  <div class='werewr' (click)='typeResponse = "success"'>typeResponse = success</div>-->
  <!--  <div class='werewr' (click)='typeResponse = "error"'>typeResponse = error</div>-->
  <headTitle *ngIf='!typeResponse' text='Which position?' svgRight='cross_grey&24' (svgRightEmit)='close(false)'
             typeText='text4-blueDark'></headTitle>
  <headTitle *ngIf='typeResponse' [text]='typeResponse == "success" ? "Success!" : "Error!"' svgRight='cross_grey&24'
             (svgRightEmit)='close(false)' typeText='text4-blueDark'></headTitle>

  <div *ngIf='!typeResponse' class='wrapRoles_popupForOfficialForRequestToRole'>
    <div *ngFor='let goItem of arrGo; let idx=index' class='role_popupForOfficialForRequestToRole'>
      <meSvg [svgName]='goItem.isSelect|getChxSvgName' (click)='selectItem(goItem)'></meSvg>
      <div class='role_popupForOfficialForRequestToRole__info'>
        <span>Official #{{goItem.officialPositionNumber}} ({{goItem.officialPositionName}}) - </span>
        <span> {{goItem.officialFee|currency}}</span>
      </div>
    </div>
  </div>

  <div *ngIf='typeResponse == "success"' class='subtitle_PopupForOfficialForRequestToRole'>Your request has been sent to the Assignor.</div>

  <btnWrap *ngIf='!typeResponse' type='col'>
    <btn text='Submit Request' bcg='blueDark' (emit)='requestAssignOfficial()' [disabled]='!gameOfficialId'></btn>
    <btn text='Cancel' bcg='white' (emit)='close(false)'></btn>
  </btnWrap>

  <btnWrap *ngIf='typeResponse' type='col'>
    <btn [text]='typeResponse == "success" ? "Got it!" : "Close"' [bcg]='typeResponse == "success" ? "blueDark" : "red"'
         (emit)='close(typeResponse == "success")'></btn>
  </btnWrap>
</div>