export interface IActiveTile {
  isGame: boolean;
  isPending: boolean;
  isDeclined: boolean;
  isUnassigned: boolean;
}

// export type TypeBlockGamesForDashboard = 'games' | 'pending' | 'declined' | 'roles';
export type TypeBlockGamesForDashboard = 'totalGames' | 'pendingAssignments' | 'declinedAssignments' | 'unassignedRoles';
export type TitleBlockGamesForDashboard = 'Total Games' | 'Pending Assignments' | 'Declined Assignments' | 'Unassigned Roles';

export interface IBlockGamesForDashboard {
  title: TitleBlockGamesForDashboard;
  value: string | number;
  id: TypeBlockGamesForDashboard;
}

export const arrBlockGamesForDashboard: Array<IBlockGamesForDashboard> = [
  { title: 'Total Games', value: 0, id: 'totalGames' },
  { title: 'Pending Assignments', value: 0, id: 'pendingAssignments' },
  { title: 'Declined Assignments', value: 0, id: 'declinedAssignments' },
  { title: 'Unassigned Roles', value: 0, id: 'unassignedRoles' },
];
