<!--<div class='eeee' (click)='typePage === "createGame" ? typePage = "gamePreview" : typePage = "createGame"'>WWWWWWWWWWWWW</div>-->
<div *ngIf='{createGame: typePage == "createGame", gamePreview: typePage == "gamePreview"} as obj'
     class='wrapperCreateGame' [formGroup]='form'>
  <div *ngIf='obj.createGame' class='wrapperForGameBlock'
       [class.wrapperForGameBlock--desktop]='(deviceS.isDesktop$ | async)'>
    <headTitle text='Single Game Entry' svgLeft='arrow_squareLeft&24' (svgLeftEmit)='goToBack()' typeText='text1-blueDark'></headTitle>

    <div class='o-wrap contentCreateGame'>
      <div class='blockCreateGame'>
        <field text='Competition' class='o-w-100'>
          <app-dropdown formControlName='competition' [array]='(gameS.arrCompetition$|async)!' placeholder='Select Competition'
                        (changeValObj)='changeCompetition($event)'></app-dropdown>
        </field>
      </div>

      <div class='blockCreateGame'>
        <field text='Game #' svgName='question_grey&16'
               matTooltipSVG='You can enter a game number or leave it blank and our system will generate a game number.'>
          <inputCtrl formControlName='gameNumber' placeholder='Game #' [isNumber]='true'></inputCtrl>
        </field>
        <field text='Game Type'>
          <app-dropdown formControlName='gameTypeDrop' [array]='competitionS.arrGameTypeDrop' placeholder='Select Game Type'></app-dropdown>
        </field>
      </div>

      <line></line>

      <div class='blockCreateGame'>
        <dateTime #dateTimeRef [valueStrFromServer]='ctrl.date?.value'></dateTime>
      </div>

      <div class='blockCreateGame'>
        <field text='Game Location'>
          <app-dropdown formControlName='location' [array]='(gameS.locations$|async)!' placeholder='Select Game Location'
                        (changeValObj)='changeLocation($event)'></app-dropdown>
        </field>
        <field text='Field or Court'>
          <!--          <dropFormCtrl formControlName='currentCourtName' [listStr]='arrFieldOrCourt' placeholder='Field or Court'></dropFormCtrl>-->
          <app-dropdown formControlName='currentCourtNameDrop' [array]='ctrl.courtNamesDrop.value'
                        placeholder='Field or Court'></app-dropdown>
        </field>
      </div>

      <div class='blockCreateGame'>
        <field text='Age Groups'>
          <app-dropdown formControlName='ageGroup' [array]='(gameS.ageGroups$|async)!' placeholder='Age Groups'></app-dropdown>
        </field>
        <field text='Level'>
          <!--          andrei replaceDefaultNameMatOption='N/A'-->
          <app-dropdown formControlName='levels' [array]='(gameS.levels$|async)!' placeholder='Level'></app-dropdown>
        </field>
      </div>

      <div class='blockCreateGame'>
        <field text='Gender'>
          <app-dropdown formControlName='genderDrop' [array]='arrGenderDrop' placeholder='Gender'></app-dropdown>
        </field>
      </div>

      <line></line>

      <field text='Home Team'>
        <app-dropdown formControlName='homeTeam' [array]='(gameS.teams$|async)!' placeholder='Home Team'></app-dropdown>
      </field>

      <field text='Away Team'>
        <app-dropdown formControlName='awayTeam' [array]='(gameS.teams$|async)!' placeholder='Away Team'></app-dropdown>
      </field>

      <field text='Description' class='o-w-100'>
        <inputCtrl type='textarea' formControlName='gameDescription' placeholder='Description' height='150'></inputCtrl>
      </field>

      <btnWrap type='col' marginCol='20'>
        <btn text='Preview' bcg='blueDark' (emit)='goToPreview()' [disabled]='!form?.valid || !dateTimeRef?.dateValid'> </btn>
        <btn text='Cancel' bcg='white' (emit)='resetForm()'></btn>
      </btnWrap>
    </div>
  </div>

  <!--  ======= PREVIEW ===================-->
  <div *ngIf='obj.gamePreview' class='wrapperForGame wrapperCreateGame wrapperForGameBlockPreview'>
    <div class='wrapperForGameBlock'
         [class.wrapperForGameBlock--desktop]='(deviceS.isDesktop$ | async)'>
      <headTitle text='Game Preview' svgLeft='arrow_squareLeft&24' (svgLeftEmit)='typePage="createGame"'
                 typeText='text1-blueDark'></headTitle>

      <div *ngIf="(deviceS.isMobile$ | async)" class='contentPreviewGame__title'>Game Information</div>

      <div class='contentPreviewGame o-wrap contentPreviewGamemobile'>
        <ng-container *ngIf="(deviceS.isDesktop$ | async)">
          <div class='contentPreviewGame__title'>Game Information</div>
          <div *ngFor='let previewItem of (gameS.arrPreviewGame$|async)!'
               class='contentPreviewGame__previewItem {{previewItem.title == "Location" ? "contentPreviewGame__previewItem--location":""}}'>
            <span>{{previewItem.title}}</span>
            <span *ngIf='previewItem.title !== "Date" && previewItem.title !== "Time" && previewItem.title !== "Location"'>
              {{previewItem.value}}
            </span>
            <span *ngIf='previewItem.title == "Date"'>{{previewItem.value|date:'fullDate'}}</span>
            <span *ngIf='previewItem.title == "Time"'>{{previewItem.value|date:'shortTime'}}</span>

            <div *ngIf='previewItem.title == "Location"'>
              <span>{{previewItem.locationName}}</span>
              <span>{{previewItem.location?.street}}</span>
              <span>{{previewItem.location?.city}} {{previewItem.location?.state}} {{previewItem.location?.zipcode}}</span>
              <btn text='Get Directions' bcg='blueDark' (click)='geo.goToGoogleMap()' btnHeight='36'></btn>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="(deviceS.isMobile$ | async)">
          <div *ngFor='let previewItem of (gameS.arrPreviewGameCreate_mobile$|async)! let isFirst = first'
               class='contentPreviewGame__previewItem_mobile contentPreviewGame__previewItem contentPreviewGame__previewItem--{{previewItem?.title}}'
               [class.contentPreviewGame__previewItem--first]='isFirst'>
            <span>{{previewItem.title}}</span>

            <div *ngIf='previewItem?.title == "Date & Time"' style="display:flex; flex-direction: row">
              <span class="dateTime">{{previewItem.value|date:'fullDate'}}</span>, <span class="dateTime" style="padding-left: 4px !important;">{{previewItem.value|date:'shortTime'}}</span>
            </div>
            <span *ngIf='previewItem.title !== "Date & Time" && previewItem.title !== "Location"'>
              {{previewItem.value}}
            </span>
            <span *ngIf='previewItem?.title == "Status"' [class.o-colorRed]='(previewItem?.value|lowercase)=="cancelled"'>
              {{previewItem?.value}}
            </span>
            <div *ngIf='previewItem.title == "Location"'>
              <span>{{previewItem.locationName}}</span>
              <span>{{previewItem.location?.street}}</span>
              <span>{{previewItem.location?.city}} {{previewItem.location?.state}} {{previewItem.location?.zipcode}}</span>
            </div>
          </div>
        </ng-container>

        <div class='contentPreviewGame__previewItem'>
          <span>Assignor</span>
          <div>
            <span class="dateTime">{{meS?.me?.firstName}} {{meS?.me?.secondName}}</span>
            <svgAndText [text]='meS.mePhone' leftSvgName='phoneBlue&20' typeText='text8-blueDark'></svgAndText>
            <svgAndText [text]='meS.meEmail' leftSvgName='mailBlue&20' typeText='text8-blueDark'></svgAndText>
          </div>
        </div>
      </div>
    </div>

    <!--    === OFFICIALS ============================================-->
    <div *ngIf="(deviceS.isDesktop$ | async)" class='wrapperForGameBlock officialsGameInfo '
         [class.wrapperForGameBlock--desktop]='(deviceS.isDesktop$ | async)'>
      <!--      !!! не удалять. Это для дизайна-->
      <!-- <div class='officialsGameInfo__bts'></div> -->

      <div class='contentPreviewGame o-wrap contentPreviewGame__officials'>
        <div class='contentPreviewGame__title'>Officials</div>
        <div *ngFor='let go of arrGO; let idxRole=index'
             class='contentPreviewGame__itemRole contentPreviewGame__itemRole_mobile'>
          <meSvg svgName='user_circleWhite&40'></meSvg>
          <div class='contentPreviewGame__itemRole__column1'>
            <div>Available Role</div>
            <span>Official #{{go.officialPositionNumber}} {{go.officialPositionName}}</span>
          </div>
          <div class='contentPreviewGame__itemRole__column2'>
            <div>{{go.officialFee ? (go.officialFee|currency) : '-'}}</div>
            <span>Official Fee</span>
          </div>
          <div class='contentPreviewGame__itemRole__column3'>
            <meSvg svgName='circle_empty&20'></meSvg>
            Unassigned
          </div>
        </div>
      </div>

      <btnWrap type='col' marginCol='20'>
        <btn class="submit" text='Submit' bcg='blueDark' (emit)='submit()' [spinner]='reqPending'></btn>
        <btn class="cancel" text='Cancel' bcg='white' (emit)='typePage="createGame"' [spinner]='reqPending'></btn>
      </btnWrap>
    </div>



    <div *ngIf="(deviceS.isMobile$ | async)" class='wrapperForGameBlock officialsGameInfo officialsGameInfo_mobile'>
      <div class='contentPreviewGame__title'>Officials</div>
      <div class='contentPreviewGame o-wrap'>
        <div *ngFor='let go of arrGO; let idxRole=index'
             class='contentPreviewGame__itemRole contentPreviewGame__itemRole_mobile'>
          <div class="contentPreviewGame__itemRole_details">
            <meSvg svgName='user_circleWhite&40'></meSvg>
            <div class='contentPreviewGame__itemRole__column1'>
              <div>Available Role</div>
              <span>Official #{{go.officialPositionNumber}} {{go.officialPositionName}}</span>
              <div>{{go.officialFee ? (go.officialFee|currency) : '-'}}</div>
            </div>
          </div>
          <div class='contentPreviewGame__itemRole__column3'>
            <meSvg svgName='circle_empty&20'></meSvg>
          </div>
        </div>
      </div>

      <btnWrap type='col' marginCol='20'>
        <btn text='Submit' bcg='blueDark' (emit)='submit()' [spinner]='reqPending'></btn>
        <btn text='Cancel' bcg='white' (emit)='typePage="createGame"' [spinner]='reqPending'></btn>
      </btnWrap>
    </div>
  </div>
</div>