import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetStylesPipe } from '@pipes/css/get-styles.pipe';
import { GetPayMethodNamePipe } from '@app/dir_group_assignor/payments/pipes/get-pay-method-name.pipe';
import { IPaymentMethod } from '@app/dir_group_assignor/payments/modelsForPayment';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { TSvgName } from '@components/__svg_img/svg/forSvg';
import { FundService } from '@services/funds.service';

@Component({
  selector: 'logoPaySystem[payMethod]',
  standalone: true,
  imports: [CommonModule, GetStylesPipe, GetPayMethodNamePipe, SvgComponent],
  templateUrl: './logo-pay-system.component.html',
  styleUrls: ['./logo-pay-system.component.scss'],
})
export class LogoPaySystemComponent implements OnChanges {
  @Input() payMethod?: IPaymentMethod;
  // @Input() width!: string | number;
  // @Input() height!: string | number;

  svgName!: TSvgName;

  arrPayMethod: Array<string> = ['amex', 'diners', 'discover', 'jcb', 'masterCard', 'unionpay', 'visa', 'stripe', 'webMoney', 'payPal']; // !!! для этих есть SVG

  constructor(
    private fundS: FundService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.payMethod?.currentValue) {
      this.svgName = `payment_${this.fundS.checkPayLogo(this.payMethod)}` as TSvgName;
    }
  }
}
