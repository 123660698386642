import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { TColor } from '@models/ICssStyles';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GetStylesPipe } from '@pipes/css/get-styles.pipe';
import { TSvgName } from '@components/__svg_img/svg/forSvg';
import { MeService } from '@services/me.service';
import { DeviceService } from '@services/device.service';
import { Router } from '@angular/router';

@Component({
  selector: 'btn[text][bcg]',
  templateUrl: './btn.component.html',
  styleUrls: ['./btn.component.scss'],
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule, SvgComponent, MatTooltipModule, GetStylesPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnComponent implements OnChanges, OnInit {
  // @Input() page_adminPermission!: TPath; // !!! если надо проверить резрешены ли админу действия на текущей странице. Если запрещено то disabled

  @Input() routerLink!: any;
  @Input() text!: string; // текст для кнопки
  @Input() bcg!: TColor; // цвета кнопки

  @Input() svgLeft?: TSvgName; // если надо иконку вставить в кнопку. Передавать название altText для <meSvg [altText]='svgApply'></meSvg>
  @Input() svgLeftWidth?: string | number;

  @Input() svgRight?: TSvgName; // если надо иконку вставить в кнопку. Передавать название altText для <meSvg [altText]='svgApply'></meSvg>
  @Input() svgRightWidth?: string | number;

  // если спиннер есть, то disabled устанавливается true. Поэтому можно в [spinner]=true передавать вместо [disabled]=true
  @Input() spinner?: boolean = false; // если идет запрос на сервер показывать спиннер
  @Input() disabled?: boolean = false;

  @Input() bordRadius: 'circle' | 'square' = 'square';
  @Input() matTooltip: string = '';

  @Input() btnWidth?: string | number; // если надо ограничить ширину кнопок для десктопа. Для мобилы всегда будет 100%
  _btnHeight: string | number | null = null;
  get btnHeight(): string | number {
    if (this._btnHeight == null) {
      if (this.deviceService.isMobile)
        this._btnHeight = 36;
      else
        this._btnHeight = 44;
    }
    return (this._btnHeight as any);
  }
  @Input() set btnHeight(val: string | number) {
    this._btnHeight = val;

  }// = 44;
  // @Input() fontSize: 5 | 7 = 7;
  @Input() fontSize?: string | number;

  @Input() textPosition: 'left' | 'center' = 'center';

  @Input() cursorDefault = false; // !!! по дефолту cursor==pointer. если надо поставить cursor==default то нужно передать сюда true

  @Output() emit = new EventEmitter();

  @Input() forTest?: string;


  constructor(
    public router: Router,
    private meS: MeService,
    private deviceService: DeviceService,
    public elRef: ElementRef,
    private cd: ChangeDetectorRef,
  ) {
  }
  ngOnInit(): void {


  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes?.disabled?.currentValue) {
    //   if (this.forTest) {
    //     console.error('disabled :',this.forTest, this.disabled)
    //   }
    // }
    // if (changes?.page_adminPermission?.currentValue) {
    //   const adminPermission = this.meS.adminPermission(this.page_adminPermission);
    //   if (typeof adminPermission === 'boolean' && !adminPermission) { // !!! админу запрещено действия на текущей странице
    //     this.disabled = true;
    //     this.matTooltip = this.meS.matTooltip_adminPermission;
    //   }
    // }
    this.cd.detectChanges();
  }

  click() {
    this.emit.emit();
    this.onRouterLinkClick();
  }

  onRouterLinkClick(): void {
    if (!this.disabled && this.routerLink) {
      this.router.navigate([this.routerLink]);
      // console.log('Navigating to:', this.routerLink); 
    }
  }

}
