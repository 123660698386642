<ng-container *ngIf="!showInstantSuccessPage">
  <div *ngIf="{ desktop: (deviceS.isDesktop$ | async)!, mobile: (deviceS.isMobile$ | async)! } as obj"
    class="payment-method-page">

    <div *ngIf="!oneTimePayoutSelected">

      <headTitle [class.headTitle--desktop]="obj.desktop" [class.headTitle--mobile]="obj.mobile" text="Schedule Payout"
        typeText="text12-blueDark" [svgLeft]="obj.desktop ? 'arrow_squareLeft&24' : 'arrow_squareLeft&24'"
        (svgLeftEmit)="otherS.goToBack()">
      </headTitle>


      <!-- Standard Payout and Auto Payout Toggle -->
      <div class="payout-selection">
        <span [class.active]="selectedPayout === 'standard'" (click)="setPayout('standard')">Standard Payout</span>
        <span class="payout-with-circle" [class.active]="selectedPayout === 'auto'" (click)="setPayout('auto')">
          <a *ngIf="scheduledPayoutData?.enabled" class="circle-container">
            <a class="payout-circle"></a>
          </a>
          Auto Payout
        </span>
      </div>

      <!-- Selection Bar -->
      <div class="payout-bar">
        <div [class.active]="selectedPayout === 'standard'" class="payout-indicator"></div>
        <div [class.active]="selectedPayout === 'auto'" class="payout-indicator"></div>
      </div>

      <!-- Standard Payout Content -->
      <div class="content" *ngIf="selectedPayout === 'standard' && !oneTimePayoutSelected">
        <div class='payment-type g-flex g-flex--align-center' [class.selected]="isOneTimePayoutSelected"
          (click)="selectOneTimePayout()">
          <div class="standard">
            <span class="left">One-Time Standard Payout</span>
            <span class="right">$1.99 Fee</span>
          </div>
        </div>
        <div class="standard-disclaimer">Arrives in 1-2 business days</div>
        <btn style="margin-top: 20px" [text]='"Proceed"' bcg='blueDark' (click)="showOneTimePayoutDetails()"
          [disabled]="!isOneTimePayoutSelected">
        </btn>
      </div>

      <!-- Auto Payout Content -->
      <div *ngIf="selectedPayout === 'auto'" class="content">
        <div class='payment-type g-flex g-flex--align-center' [class.selected]="selectedAutoPayout === 'weekly'"
          (click)="setAutoPayout('weekly')">
          <div class="standard">
            <span class="left">Weekly</span>
            <span class="right">$1.99/Payout</span>
          </div>
        </div>
        <!-- Bi-Weekly Option -->
        <div class='payment-type g-flex g-flex--align-center' style="margin-top: 12px"
          [class.selected]="selectedAutoPayout === 'biweekly'" (click)="setAutoPayout('biweekly')">
          <div class="standard">
            <span class="left">Bi-Weekly</span>
            <span class="right">$1.99/Payout</span>
          </div>
        </div>

        <!-- Monthly Option -->
        <div class='payment-type g-flex g-flex--align-center' style="margin-top: 12px"
          [class.selected]="selectedAutoPayout === 'monthly'" (click)="setAutoPayout('monthly')">
          <div class="standard">
            <span class="left">Monthly</span>
            <span class="right">Free</span>
          </div>
        </div>
        <div *ngIf="selectedAutoPayout === 'weekly'" class="standard-disclaimer">Initiates each Monday at 2:00 PM
          Eastern. Arrives 1-3 business days.</div>
        <div *ngIf="selectedAutoPayout === 'biweekly'" class="standard-disclaimer">Initiates every other Monday starting
          mm/dd/yy at 2:00 PM Eastern. Arrives 1-3 business days.</div>
        <div *ngIf="selectedAutoPayout === 'monthly'" class="standard-disclaimer">Initiates each 1st of the month
          between 4:00-7:00 PM Eastern on the last day of the month. Arrives 1-3 business days.</div>
      </div>


      <div class="payment-method-container"
        *ngIf="selectedPayout === 'auto' && (selectedAutoPayout === 'weekly' || selectedAutoPayout === 'monthly' || selectedAutoPayout === 'biweekly')">
        <!-- Show Proceed Button If Auto Payout is NOT enabled -->
        <btn *ngIf="!scheduledPayoutData?.enabled" style="margin-top: 20px" [text]="'Proceed'" bcg='blueDark'
          (click)="proceedToAutoPayoutSetup()">
        </btn>

        <!-- Show Save Button ONLY If User Changes Selection -->
        <div
          *ngIf="scheduledPayoutData?.enabled && ( scheduledPayoutType !== selectedAutoPayout && !(scheduledPayoutType === 'WEEKLY_TRANSITION_TO_MONTHLY' && selectedAutoPayout === 'monthly') && !(scheduledPayoutType === 'BIWEEKLY_TRANSITION_TO_MONTHLY' && selectedAutoPayout === 'monthly'))">
          <btn style="margin-top: 20px" [text]="'Save Changes'" bcg='blueDark' (click)="saveScheduledPayout()">
          </btn>

          <!-- Cancel Button: Only Visible When Save Button is Present -->
          <btn style="margin-top: 10px" [text]="'Cancel'" bcg="white" (click)="cancelChanges()">
          </btn>
        </div>


        <!-- Show Turn Off Auto Payout If No Changes Are Made -->
        <btn
          *ngIf="scheduledPayoutData?.enabled && (scheduledPayoutType === selectedAutoPayout || (scheduledPayoutType === 'WEEKLY_TRANSITION_TO_MONTHLY' && selectedAutoPayout === 'monthly') || (scheduledPayoutType === 'BIWEEKLY_TRANSITION_TO_MONTHLY' && selectedAutoPayout === 'monthly'))"
          class="btn-payout" text="Turn Off Auto Payout" [svgLeft]="'turnOff'" bcg="white" (click)="turnOffAutoSetup()">
        </btn>
      </div>
    </div>

    <!-- One-Time Payout Detailed Section (Shown after clicking One-Time Payout) -->
    <div *ngIf="oneTimePayoutSelected">
      <headTitle [class.headTitle--desktop]="obj.desktop" [class.headTitle--mobile]="obj.mobile"
        [text]="selectedPayout === 'auto' ? 'Auto Payout' : 'One-Time Payout'" typeText="text1-blueDark"
        [svgLeft]="obj.desktop ? 'arrow_squareLeft&24' : 'arrow_squareLeft&24'"
        (svgLeftEmit)="goBackToSchedulePayout()">
      </headTitle>

      <div *ngIf="dataPopup.isPayout && dataPopup.balance" class="o-text1 balance">
        {{ (dataPopup.balance) | currency:'USD' }}
      </div>
      <div class="o-text1 balanceFee">
        {{ selectedPayout === 'auto' ? 'Current Balance' : '$1.99 Fee. Arrives 1-2 business days.' }}
      </div>


      <app-manage-payment-method [accounts]="dataPopup.accounts" [accountDefault]="accountDefault"
        [selectedAccount]="selectedAccount" (removeAccount)="onRemoveAccount($event)"
        (defaultAccount)="onSetDefaultAccount($event)" [hideHeadTitle]="true">
      </app-manage-payment-method>

      <btnWrap *ngIf="!isEdit || dataPopup.isPayout" [type]="obj.desktop ? 'row' : 'col'">
        <span class="instant-fee" *ngIf="selectedPayout === 'auto' && selectedAutoPayout === 'weekly'">
          ${{ getQuickPayFee() | number:'1.2-2' }} Per Payout Fee
        </span>
        <span *ngIf="selectedPayout === 'standard'" class="instant-fee">
          ${{ getQuickPayFee() | number:'1.2-2' }} Instant Fee
        </span>
        <btn *ngIf="selectedPayout === 'standard' && dataPopup.isPayout" class="btn-payout"
          [text]="'Proceed to Payout ' + getPayoutAmount()"
          [disabled]="!selectedAccount || isLoading || finalPayout === 0 || dataPopup.balance === 0" bcg="blueDark"
          (click)="onPayout()">
        </btn>
        <btn *ngIf="selectedPayout === 'auto' && dataPopup.isPayout" class="btn-payout" text="Save Changes"
          [disabled]="!selectedAccount || isLoading" bcg="blueDark" (click)="saveScheduledPayout()">
        </btn>
      </btnWrap>
    </div>

    <spinner *ngIf="!isEdit && isLoading" [diameter]="20" [isNoMargin]="true"></spinner>

  </div>
</ng-container>

<ng-container *ngIf="showInstantSuccessPage">
  <app-instant-success-dialog [popupType]="popupType" (closeEvent)="handleSuccessClose()">
  </app-instant-success-dialog>
</ng-container>