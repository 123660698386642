<!--<div class='wrapperCreateGroupList {{(isOpenPopupGroupListSub$|async) ? "wrapperCreateGroupList&#45;&#45;hidden":""}}'>-->
<div class='wrapperCreateGroupList' [formGroup]='form'>
  <meSvg svgName='test_avatarEmpty'></meSvg>
  <div class='titleAddOfficialsToGroup'>Add your officials to group list</div>
  <div class='subTitleAddOfficialsToGroup'>Search for officials below and add them to a list to be able to communicate directly to specific
    officials within your database of officials.
  </div>

  <field text='Name of Group List'>
    <!--    (click)='officialsS.openPopupGroupList()'-->
    <!--    <div class='groupListValue'>{{(officialsS.groupListItem$|async)?.name || 'Top Officials'}}</div>-->

    <!--    <inputCtrl [(ngModel)]='listName' placeholder=''></inputCtrl>-->
    <inputCtrl formControlName='listName' placeholder=''></inputCtrl>
  </field>

  <field text='Officials'>
    <div class='listOfficialsAddOfficialsToGroup'>
      <div *ngFor='let item of ctrl.listOfficials?.value; let idx=index;' class='listOfficialsAddOfficialsToGroup__item'>
        <photo [data]='item' w='16' fs='8'></photo>
        <span class="text-ellipsis">{{item|getUserName}}</span>
        <meSvg svgName='cross_grey&24' (click)='deleteItem(idx)'></meSvg>
      </div>
    </div>
  </field>

  <div class="popup-actions">
    <btn text='Cancel' bcg='white' (click)='close(false)' [btnWidth]='deviceS.isMobile ? "95%": "170px"' [disabled]='reqPending'></btn>
    <!--    [disabled]='!listName || listName.length < 4 || !listOfficials.length'-->
    <btn [text]='"Add " + ctrl.listOfficials?.value?.length + " Officials"' bcg='blueDark' (click)='submit()' [btnWidth]='deviceS.isMobile ? "95%": "170px"'
          [disabled]='!form.valid || reqPending'></btn>
  </div>
</div>