import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '@app/store/app.state';
import { selectHomeAssigner, selectIsGame } from '@app/store/dashboard/states/dashboard-home-assigner';
import { ClassSettingsRequest } from '@models/response-and-request';

@Injectable({
  providedIn: 'root',
})
export class ManageAllService {
  isGame$: Observable<boolean> = this.store.pipe(select(selectIsGame));
  queryParams: ClassSettingsRequest = {};
  activeRoute: string = 'games'; // default route
  activeTileName: string = 'ALL'; // default tile name

  constructor(private router: Router, private store: Store<AppState>) {}

  setSettings(settings: ClassSettingsRequest): void {
    this.store.select(selectHomeAssigner).subscribe(activeTile => {
      this.activeRoute = activeTile?.isGame ? 'games' : 'assign';
      this.activeTileName = activeTile?.isPending ? 'UNACCEPTED,UNPUBLISHED' : 
                           activeTile?.isDeclined ? 'DECLINED' : 
                           activeTile?.isUnassigned ? 'UNASSIGNED' : 'ALL';
      this.formationUrl(settings);
    });
    if (this.activeRoute !== 'games') {
      this.queryParams.assignStatuses = this.activeTileName;
    } else {
      delete this.queryParams.assignStatuses;
    }
  }

  private formationUrl(settings: ClassSettingsRequest): void {
    const { page, size, from, to, competitions, search } = settings;
    this.queryParams.page = page || undefined;
    this.queryParams.size = size || undefined;
    this.queryParams.from = from || undefined;
    this.queryParams.to = to || undefined;
    this.queryParams.competitions = competitions || undefined;
    this.queryParams.search = search || undefined;
    this.queryParams.assignStatuses = this.activeTileName;
  }

  manageAll(): void {
    this.router.navigate([this.activeRoute], { queryParams: this.queryParams });
  }
}
