<div class='wrapperMyWalletItemTableForMobile'>
  <div class='headerMyWalletItemTableForMobile'>
    <span>{{myWalletItemTableForMobile.createdAt_isToday ? 'Today' : myWalletItemTableForMobile.createdAt_formatted_2}}</span>
    <span>{{(myWalletItemTableForMobile.allTotal ? myWalletItemTableForMobile.allTotal / 100 : 0)|currency}}</span>
  </div>

  <div class='contentMyWalletItemTableForMobile'>
    <div *ngFor='let item of myWalletItemTableForMobile.arrayTransfer' class='contentMyWalletItemTableForMobile__item'>
      <div class='contentMyWalletItemTableForMobile__item__head'>
        <div>{{item.officialPositionName}} - #{{item.gameNumber}}</div>
        <div>{{item.total_formatted}}</div>
      </div>

      <span>{{item.competitionName}}</span>
      <span>{{item.ageGenderLevel}}</span>
      <status [status]="transactionType=== 'OFFLINE' ? 'Offline' : item.status"></status>
    </div>
  </div>
</div>
