import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TColor } from '@models/ICssStyles';
import { BehaviorSubject, Observable } from 'rxjs';
import { RoutesService } from '@services/routes.service';
import { IQueryParams_forRegistration } from '@app/auth/auth_models';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly authPath = 'api/auth/v1';

  bcgApplyBtn: TColor = 'newGreen';

  // === для регистрации по ссылке ===============
  private queryParams_forRegistrationSub$ = new BehaviorSubject<IQueryParams_forRegistration | null>(null);
  queryParams_forRegistration$ = this.queryParams_forRegistrationSub$.asObservable();

  set queryParams_forRegistration(queryParams_forRegistration: IQueryParams_forRegistration | null) {
    this.queryParams_forRegistrationSub$.next(queryParams_forRegistration);
  }

  get queryParams_forRegistration(): IQueryParams_forRegistration | null {
    return this.queryParams_forRegistrationSub$.getValue();
  }

  // queryParams_forRegistration?: IQueryParams_forRegistration;
  // userRole?: TUserRoleUpperCase;
  // key?: string;
  // email?: string;
  // groupId?: string;
  // competitionId?: string;

  constructor(
    private httpClient: HttpClient,
    private routesS: RoutesService,
  ) {
    // console.log('queryParams_forRegistration :', this.queryParams_forRegistration);
    // console.log('routesS.getData_fromQueryParams() :', this.routesS.getData_fromQueryParams())
    // if (this.routesS.getData_fromQueryParams()) {
    // this.queryParams_forRegistration = this.routesS.getData_fromQueryParams();
    // this.userRole = this.queryParams_forRegistration.role;
    // this.key = this.queryParams_forRegistration.key;
    // this.email = this.queryParams_forRegistration.email;
    // this.groupId = this.queryParams_forRegistration.groupId;
    // this.competitionId = this.queryParams_forRegistration.competitionId;
    // }
  }

  // private newPassword = new BehaviorSubject<string>('');
  // newPassword$ = this.newPassword.asObservable();
  // setNewPassword(newPassword: string): void {
  //   this.newPassword.next(newPassword);
  // }
  // getNewPassword(): string {
  //   return this.newPassword.getValue();
  // }

  resetPassword(email: string): Observable<any> {
    const endPoint = `${this.authPath}/recoverPass`;
    return this.httpClient.post(endPoint, { email }, { observe: 'response' });
  }

  updatePassword(data: { email: string, key: string, password: string; }): Observable<any> {
    const endPoint = `${this.authPath}/updatePass`;
    return this.httpClient.post(endPoint, { ...data }, { observe: 'response' });
  }

}
