import { AfterContentChecked, Component, ContentChildren, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnComponent } from '@components/btn/btn.component';
import { DeviceService } from '@services/device.service';

@Component({
  selector: 'btnWrap',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './btn-wrap.component.html',
  styleUrls: ['./btn-wrap.component.scss'],
})
export class BtnWrapComponent implements AfterContentChecked {
  @Input() type: 'col' | 'row' = 'row'; // для desktop. для мобилы всегда будет 'col'
  @Input() marginCol: '8' | '12' | '20' | '24' | '30' = '12'; // когда кнопки вертикально, то какой отступ между ними ждолжен быть
  @Input() alwaysType?: 'col' | 'row' | undefined = undefined; // для любых экранов всегда будет применяться
  @Input() isAnnouncement: boolean = false;

  @Input() swapBtn = false; // если передаем true, то меняем местами кнопки

  @ContentChildren(BtnComponent) contentChildren?: any;

  amountBtn: number = 0; // количество кнопок
  chet: boolean = true; // если нечетное количество, то ширина кнопки должна быть 100%

  constructor(
    public deviceS: DeviceService,
  ) {
  }

  ngAfterContentChecked() {
    this.amountBtn = this.contentChildren?.length;
    this.chet = !(this.contentChildren?.length % 2);
  }

}
