import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChxComponent } from '@components/chx/chx.component';
import { LogoPaySystemComponent } from '@app/dir_group_assignor/payments/additional-components/logo-pay-system/logo-pay-system.component';
import { IPaymentMethod } from '@app/dir_group_assignor/payments/modelsForPayment';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { OtherService } from '@services/other.service';
import { MainService } from '@services/main.service';
import { HelperClass } from '@classes/Helper-Classes';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { MatMenuModule } from '@angular/material/menu';
import { PaymentService } from '@app/dir_group_assignor/payments/payment.service';
import { GetWidthMatMenuPipe } from '@pipes/get-width-mat-menu.pipe';
import { PayMethodComponent } from '@app/dir_group_assignor/payments/additional-components/pay-method/pay-method.component';

@Component({
  selector: 'payMethodDrop',
  standalone: true,
  imports: [CommonModule, ChxComponent, LogoPaySystemComponent, SvgComponent, MatMenuModule, GetWidthMatMenuPipe, PayMethodComponent],
  templateUrl: './pay-method-drop.component.html',
  styleUrls: ['./pay-method-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayMethodDropComponent extends HelperClass implements ControlValueAccessor {
  @ViewChild('selectRef') selectRef?: MatSelect;
  @Output() changeVal: EventEmitter<IPaymentMethod> = new EventEmitter<IPaymentMethod>();

  constructor(
    public otherS: OtherService,
    public mainS: MainService,
    public payS: PaymentService,
    public ngControl: NgControl,
    public elRef: ElementRef,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  selectPayMethod(payMethod: IPaymentMethod): void {
    this.value = payMethod;
  }

  // === GETTERS & SETTERS =======================
  set value(value: IPaymentMethod) {
    if (!value) return;
    this.changeVal.emit(value);
    this.ngControl.control?.setValue(value);  // this.ngControl.control?.patchValue(value);
    this.onChange(value); // чтобы в родит.компонент передать
    this.onTouched();  // чтобы в родит.компонент передать
  }

  get value(): IPaymentMethod {
    return this.ngControl.control?.value;
  }

  // ============================
  private onChange!: (value: IPaymentMethod) => void;
  private onTouched!: () => void;

  writeValue(value: IPaymentMethod): void {
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }
}
