import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TStateInput } from '@pipes/get-state-input.pipe';
import { ClickWithoutElemDirective } from '@directives/click-without-elem.directive';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { GetStylesPipe } from '@pipes/css/get-styles.pipe';
import {
  arrExceptionsSvgName,
  heightLogoPaySystem,
  TExceptionsSvgName,
  TSvgName,
  widthLogoPaySystem,
} from '@components/__svg_img/svg/forSvg';
import { MeService } from '@services/me.service';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'meSvg[svgName]',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss'],
  standalone: true,
  imports: [CommonModule, GetStylesPipe, NgOptimizedImage, MatTooltipModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgComponent implements OnChanges, AfterViewInit {
  @ViewChild('wrapSvg') wrapSvg?: ElementRef;

  // @Input() page_adminPermission!: TPath; // !!! если надо проверить резрешены ли админу действия на текущей странице. Если запрещено то disabled
  @Input() textMatTooltip: string = '';
  @Input() asButton22?: boolean = false;
  // !!! создавать SVG с именем в котором присутствует ПЕРЕД символом "_" название папки "arrow_circle"
  // !!! если SVG не в папке, то не надо в названии SVG использовать символ "_"
  // !!! полный путь до SVG должен быть таким например  "assets/arrow/arrow_circle&24.svg"
  // !!! передавать svgName "arrow_circle". Потом здесь в компоненте проверяется наличие символа "_" чтобы определить в какой папке находится SVG
  // !!! символ "_" в имени SVG используется ТОЛЬКО для того чтобы определять в какой папке SVG лежит. В других случаях НЕ использовать символ "_" в названии SVG
  // !!! символ "&" используется чтобы в названии файла указать размер SVG по дефолту. Но можно просто передать сюда в компонент => @Input() w
  @Input() svgName!: TSvgName;
  @Input() w?: string | number = ''; // если передано, то устанавливаем ширину и высоту лого (если высота не передана сюда в компонент)
  @Input() h?: string | number = ''; // если передано, то устанавливаем высоту лого
  @Input() cur?: boolean = false; // cursor: cur ? pointer : default
  @Input() o5?: boolean = false; // opacity: .5;
  @Input() o3?: boolean = false; // opacity: .3;
  @Input() hid?: boolean = false; // visibility: hidden
  @Input() revV?: boolean = false; // 'transform: scale(1, -1)'
  @Input() disabled?: boolean = false; // курсор по дефолту и прозрачность добавляется
  @Input() addClassForDirective = false; // директива отслеживает нажатие мимо окна, но дочерние компоненты считает что мимо меню нажал

  @Input() stateInput?: TStateInput; // !!! если понадобится изменять цвет SVG , то надо потом это сделать здесь в этом компоненте

  @Output() emit = new EventEmitter<void>();
  @Output() emitData: EventEmitter<{}> = new EventEmitter<{}>();

  fullPathForSvg?: string;
  objectFit: 'cover' | 'initial' = 'initial';
  fileName = '';
  files: File[] = [];
  fileSize: number = 0;

  @Input() forTest?: string;

  constructor(
    private meS: MeService,
    private r: Renderer2,
    private cd: ChangeDetectorRef,
  ) {
    // this.svgName = 'comingSoon'
  }

  @HostListener('click', ['$event']) click(): void {
    if (!this.disabled) this.emit.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.svgName?.currentValue) {
      if (!this.svgName) {
      }
      // if (this.svgName.includes('payment_')) {
      //   console.log('svgName 111 :', this.svgName.includes('payment_'), this.svgName )
      // }
      this.getFullPathForSvg();
      this.getSizeForSvg();
      // if (this.svgName.includes('payment_')) {
      //   console.log('svgName 222 :', this.svgName.includes('payment_'), this.svgName )
      // }
      this.getSizeForExceptionsSvg();
      // this.getObjectFit();
      // this.cd.detectChanges()
    }

    // if (changes?.page_adminPermission?.currentValue) {
    //   const adminPermission = this.meS.adminPermission(this.page_adminPermission);
    //   if (typeof adminPermission === 'boolean' && !adminPermission) { // !!! админу запрещено действия на текущей странице
    //     this.disabled = true;
    //     this.matTooltip = this.meS.matTooltip_adminPermission;
    //   }
    // }
    this.cd.detectChanges();
  }

  ngAfterViewInit() {
    this.addClassForClickWithoutElemDirective();
  }

  handleFileInput(event: any): void {
    if (this.disabled) return;
    this.files = event.target.files;

    if (this.files && this.files.length > 0) {
      for (let i = 0; i < this.files.length; i++) {
        const currentFile: File = this.files[i];
        const fileName: string = currentFile.name;
        this.fileSize += currentFile.size;
        this.emitData.emit({ file: currentFile, fileName: fileName });
      }
    }
  }

  private addClassForClickWithoutElemDirective(): void {
    setTimeout(() => {
      if (!this.addClassForDirective || !this.wrapSvg) return;
      const parentElement = this.wrapSvg?.nativeElement;
      const firstImage = parentElement?.querySelector('img');
      if (firstImage) this.r.addClass(firstImage, 'ClickWithoutElemDirective');
    });
  }

  private getFullPathForSvg(): void {
    const existDirectory: boolean = this.svgName.includes('_');
    const directory = existDirectory ? this.svgName.split('_')[0] + '/' : '';
    this.fullPathForSvg = directory ? directory + this.svgName : this.svgName;
    // if (this.svgName.includes('payment_')) {
    //   console.log('svgName :', this.svgName, '  directory :', directory, '  fullPathForSvg :', this.fullPathForSvg)
    // }
  }

  private getSizeForSvg(): void {
    // !!! если не передали сюда в компонент размер SVG (w) и если есть в названии файла заданый дефолтный размер SVG, то устанавливается здесь размер SVG
    if (!this.w && this.svgName.includes('&')) {
      const existWidthInSvgName = this.svgName.includes('&');
      const endSvgName = existWidthInSvgName ? this.svgName.split('&')[1] : '';
      this.w = endSvgName.split('.')[0];
    }
  }

  private getSizeForExceptionsSvg(): void {
    if (!arrExceptionsSvgName.includes(this.svgName) && !this.svgName.includes('payment_')) return;
    const exceptionsSvgName: TExceptionsSvgName = this.svgName as TExceptionsSvgName;
    // console.log('exceptionsSvgName :', exceptionsSvgName)
    if (exceptionsSvgName == 'comingSoon') {
      this.w = 97;
      this.h = 22;
    }

    if (this.svgName.includes('payment_')) {
      if (!this.w) this.w = widthLogoPaySystem;
      if (!this.h) this.h = heightLogoPaySystem;
      this.objectFit = 'cover';
    }
    // if (exceptionsSvgName == 'text_viewBalanceAs') {
    //   this.w =
    //   this.h =
    // }
  }

}
