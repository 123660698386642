import { ACCOUNT_METHODS } from '@shared/constants/account-methods.constant';
import { CREDIT_CARD_TYPE } from '@shared/constants/credit-card-type.constant';

// export enum ACCOUNT_METHODS {
//   STANDARD = 'STANDARD',
//   INSTANT = 'INSTANT',
// }
// export type TAccount_Methods = 'STANDARD' | 'INSTANT';

// export interface IAccountExternal {
//   id?: string;
//   method?: string;
//   title?: string;
//   type?: string;
//   last4?: string;
//   defaultForCurrency?: boolean;
//   expMonth?: string;
//   expYear?: string;
// }

// export class AccountExternalModel implements IAccountExternal {
//   id?: string;
//   method?: ACCOUNT_METHODS;
//   title?: string;
//   defaultForCurrency?: boolean;
//   last4?: string;
//   type?: CREDIT_CARD_TYPE;
//   expMonth?: string;
//   expYear?: string;
//
//   constructor(data: Partial<AccountExternalModel> = {}) {
//     Object.assign(this, data, {
//       [ACCOUNT_EXTERNAL_FIELDS.TYPE]: data[ACCOUNT_EXTERNAL_FIELDS.METHOD] === ACCOUNT_METHODS.STANDARD
//         ? 'stripe'
//         : data[ACCOUNT_EXTERNAL_FIELDS.TITLE]?.toLowerCase(),
//     });
//   }
// }

export enum ACCOUNT_EXTERNAL_FIELDS {
  ID = 'id',
  METHOD = 'method',
  TITLE = 'title',
  TYPE = 'type',
  LAST4 = 'last4',
  DEFAULT = 'defaultForCurrency',
  EXP_MONTH = 'expMonth',
  EXP_YEAR = 'expYear',
}
export class AccountExternalModel {
  [ACCOUNT_EXTERNAL_FIELDS.ID]?: string;
  [ACCOUNT_EXTERNAL_FIELDS.METHOD]?: ACCOUNT_METHODS;
  [ACCOUNT_EXTERNAL_FIELDS.TITLE]?: string;
  [ACCOUNT_EXTERNAL_FIELDS.DEFAULT]?: boolean;
  [ACCOUNT_EXTERNAL_FIELDS.LAST4]?: string;
  [ACCOUNT_EXTERNAL_FIELDS.TYPE]?: CREDIT_CARD_TYPE;
  [ACCOUNT_EXTERNAL_FIELDS.EXP_MONTH]?: string;
  [ACCOUNT_EXTERNAL_FIELDS.EXP_YEAR]?: string;
  availableMethods: string[] = [];

  constructor(data: Partial<AccountExternalModel> = {}) {
    Object.assign(this, data, {
      [ACCOUNT_EXTERNAL_FIELDS.TYPE]: data[ACCOUNT_EXTERNAL_FIELDS.METHOD] === ACCOUNT_METHODS.STANDARD
        ? 'stripe'
        : data[ACCOUNT_EXTERNAL_FIELDS.TITLE]?.toLowerCase(),
    });
  }
}
