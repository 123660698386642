import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { DeviceService } from '@services/device.service';
import { LoadScriptsService } from '@services/load-scripts.service';
import { ISticker, StickerComponent } from '@components/__svg_img/sticker/sticker.component';
import { NotificationService } from '@services/notification.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { colorObj } from '@classes/CSS';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // standalone: true,
  // imports: [CommonModule, RouterOutlet, RouterModule, RouterLink],
})
export class AppComponent implements AfterViewInit {
  @ViewChild('container', { read: ViewContainerRef }) container!: ViewContainerRef;
  componentsReferences: Array<ComponentRef<StickerComponent>> = [];
  uniqueKey = 0;
  title = 'notch';

  constructor(
    private router: Router,
    private componentFactoryResolver: ComponentFactoryResolver,
    private notificationS: NotificationService,
    private scriptsService: LoadScriptsService,
    private cd: ChangeDetectorRef,
    public deviceService: DeviceService,
  ) {
    this.scriptsService.loadScripts();

    // this.router.events
    //   .subscribe((event: any) => {
    //     console.log('URL changed to:', event.urlAfterRedirects);
    //     console.log(event);
    //   });

    // this.setTitle();
    // this.getQueryParams();
    // const errorResponse: IErrorResponse = {message: 'aaaaaaaaaa', errorDetails: 'bbbbbbbbbbbbbbb'}
    // setTimeout(() => this.notificationS.passNote('errorObj.message!', { status: 'error' }), 777)
    // setTimeout(() => this.notificationS.showNotification_error(errorResponse), 777)
  }

  ngAfterViewInit() {
    this.listenMessages();

    this.deviceService.isMobile$.pipe(untilDestroyed(this)).subscribe(isMobile => {
      document.body.style.background = isMobile ? colorObj.white : colorObj.grey_4;
    });
  }

  listenMessages(): void {
    this.notificationS.notification$.pipe(untilDestroyed(this)).subscribe((res) => {
      this.createComponent(res);
      this.notificationS.passNote('');
    },
    );
    this.cd.markForCheck();
  }

  createComponent(res: ISticker): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(StickerComponent);
    const componentRef: ComponentRef<StickerComponent> = this.container?.createComponent(componentFactory);
    if (!componentRef) return;
    componentRef.instance.message = res.message || '';
    componentRef.instance.status = res.settings?.status!;
    if (res.settings?.icon) componentRef.instance.icon = res.settings.icon;
    componentRef.instance.settings = { uniqueKey: ++this.uniqueKey, parentRef: this };
    componentRef.instance.errorResponse = res.errorResponse;
    if (res.forReportApprove) componentRef.instance.forReportApprove = res.forReportApprove;
    this.componentsReferences.push(componentRef);
    this.cd.markForCheck();
  }

  removeComponent(key: number): void {
    if (!this.container.length) return;
    const componentRef = this.componentsReferences.filter((x) => x.instance.settings.uniqueKey === key)[0];
    componentRef.destroy();
    this.componentsReferences = this.componentsReferences.filter((x) => x.instance.settings.uniqueKey !== key);
  }

  // setTitle(): void {
  //   const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));
  //   merge(onNavigationEnd) // this.translateService.onLangChange, Change page title on navigation or language change, based on route data
  //     .pipe(
  //       tap((res: any) => {
  //         console.error('AppComponent router.events:', 'log', res?.url); // !!! не удалять. Пусть отслеживается. Если опять зациклится, чтобы увидеть в консоли
  //       }),
  //       map(() => {
  //         let route = this.route;
  //         while (route.firstChild) route = route.firstChild;
  //         return route;
  //       }),
  //       filter((route) => route.outlet === 'primary'),
  //       switchMap((route) => route.data),
  //       takeUntil(this.destroySub),
  //     )
  //     .subscribe((event) => { // {title: 'Notch :: Signup'}
  //       if (event?.title) this.titleService.setTitle(event?.title);
  //     });
  // }

  // getQueryParams(): void {
  // this.route.queryParams.pipe(takeUntil(this.destroySub)).subscribe((res: any) => {
  // https://v3.beta.joinnotch.com/invite?role=official&group_id=1235&email=mike@peller.tech
  // {email:"mike@peller.tech",group_id:"1235",role:"official",}
  // console.log('queryParams res :', res);
  // });
  // }

}
