import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { BtnComponent } from '@components/btn/btn.component';
import { HelperClass } from '@classes/Helper-Classes';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { CompetitionService } from '@app/dir_group_assignor/competitions/competition.service';
import { BtnAndLineComponent } from '@components/btn-and-line/btn-and-line.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import {
  arrKeyofOnlyOfficial,
  ClassCompetition,
  ClassCompetitionAgeGroup,
  ClassCompetitionLevel,
  ClassCompetitionPayScale,
  ClassCompetitionPayScaleCrew,
  getKeyofOnlyOfficialByNumber,
  getTotalRate,
  maximumCrewSizeCompetition,
  TGameTypeDrop,
  TKeyofOnlyOfficial,
} from '@app/dir_group_assignor/competitions/ClassCompetition';
import { MainService } from '@services/main.service';
import { CompetitionsNavigationComponent } from '@app/dir_group_assignor/competitions/helperComponentsCompetitions/competitions-navigation/competitions-navigation.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { THttpMethod } from '@services/base-api';
import { StrCasePipe } from '@pipes/string/str-case.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { GetWidthMatMenuPipe } from '@pipes/get-width-mat-menu.pipe';
import { LineComponent } from '@components/line/line.component';
import { DeleteItemForCompetitionsComponent } from '@app/dir_group_assignor/competitions/helperComponentsCompetitions/delete-item-for-competitions/delete-item-for-competitions.component';
import { OtherService } from '@services/other.service';
import { LodashService } from '@services/lodash.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { CheckActiveService } from '@app/dir_group_assignor/competitions/services/checkActiveService';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ArrayFromNumberPipe } from '@pipes/array/array-from-number.pipe';
import { GetCrewArrayForPayScalesPipe } from '@app/dir_group_assignor/competitions/components/competitions-pay-scales/pipes/get-crew-array-for-pay-scales.pipe';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';
import { UtilsService } from '@services/utils.service';
import { IsShowCrewOfficialPipe } from '@app/dir_group_assignor/competitions/components/competitions-pay-scales/pipes/is-show-crew-official.pipe';
import { debounceTime } from 'rxjs/operators';
import { CustomValidators } from '@classes/CustomValidators';
import { AuthenticatorDirective, AuthenticatorService } from '@directives/authenticator-hide.directive';

interface IFormCompetitionsPayScales {
  arrTableHeader?: FormControl<Array<string>>;
  arrControls?: FormArray<FormGroup<IFormItemCompetitionsPayScales>>;
}

export interface IFormItemCompetitionsPayScales {
  id?: FormControl<string>;
  competitionId?: FormControl<string>;
  gameTypeDrop?: FormControl<TGameTypeDrop>;
  age?: FormControl<ClassCompetitionAgeGroup>;
  level?: FormControl<ClassCompetitionLevel>;
  crew?: FormArray<FormGroup<IFormItemCompetitionsPayScalesCrew>>;
}

export interface IFormItemCompetitionsPayScalesCrew {
  official1?: FormControl<number>;
  official2?: FormControl<number>;
  official3?: FormControl<number>;
  official4?: FormControl<number>;
  official5?: FormControl<number>;
  official6?: FormControl<number>;
  official7?: FormControl<number>;
  official8?: FormControl<number>;
  official9?: FormControl<number>;
  official10?: FormControl<number>;
  groupAssignorRate: FormControl<number>;
  groupAssignorRateVisible: FormControl<boolean>;
  subAssignorRate: FormControl<number>;
  otherRate: FormControl<number>;
  totalRate: FormControl<number>;

  payScaleId: FormControl<string>;
  id: FormControl<string>;

  serialNumberCrewItem: FormControl<number>; // !!! с сервера не приходит => количество official1, official2 и т.д.. Тоесть это порядковый номер в каком порядке отображать (начинается с 1)
}

@UntilDestroy()
@Component({
  selector: 'competitions-pay-scales',
  standalone: true,
  imports: [
    CommonModule,
    FieldComponent,
    InputCtrlComponent,
    ReactiveFormsModule,
    BtnComponent,
    SvgAndTextComponent,
    BtnAndLineComponent,
    DropFormCtrlComponent,
    SvgComponent,
    CompetitionsNavigationComponent,
    MatProgressSpinnerModule,
    StrCasePipe,
    MatMenuModule,
    GetWidthMatMenuPipe,
    LineComponent,
    DeleteItemForCompetitionsComponent,
    MatTooltipModule,
    DropdownComponent,
    ArrayFromNumberPipe,
    GetCrewArrayForPayScalesPipe,
    IsShowCrewOfficialPipe,
    AuthenticatorDirective,
  ],
  templateUrl: './competitions-pay-scales.component.html',
  styleUrls: ['./competitions-pay-scales.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetitionsPayScalesComponent extends HelperClass implements OnInit {
  form!: FormGroup<IFormCompetitionsPayScales>;
  arrRequiredCtrl: Array<string> = ['groupAssignorRate', 'subAssignorRate', 'otherRate'];
  isReadOnly!: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public competitionS: CompetitionService,
    public mainS: MainService,
    private apiCompetitionS: ApiCompetitionService,
    public lodashS: LodashService,
    public otherS: OtherService,
    private checkActiveGames: CheckActiveService,
    public cd: ChangeDetectorRef,
    private authenticatorService: AuthenticatorService
  ) {
    super(cd);
    this.createForm();
  }

  ngOnInit() {
    this.subscribeToHeaderPayScales();
    this.subscribeToForm();
    this.isReadOnly = !this.authenticatorService.isAllow(['GROUP_ASSIGNOR', 'SUB_ASSIGNOR']);
    this.isReadOnly ? this.form.disable() : this.form.enable();
  }

  copyItem(item: FormGroup<IFormItemCompetitionsPayScales>): void {
    const value = this.otherS.deepClone(item.value);
    value.id = null;
    for (const c of value.crew) {
      c.id = null;
      c.payScaleId = null;
    }
    this.addNew(value);
    this.cd.detectChanges();
  }

  isCloneEnable(payScale: FormGroup): boolean {
    const result = !(payScale?.get('gameTypeDrop')?.value?.upperCase && payScale.get('age')?.value?.upperCase);
    return !result;
  }

  // === PAY SCALES ===========================
  addNew(item?: ClassCompetitionPayScale): void {
    const newPayScale = new ClassCompetitionPayScale(item!, this.competitionS.competition?.id!);
    if (!newPayScale.level?.id) {
      newPayScale.level = this.competitionS.defaultLevel; // !!! при создании нового payScale (button Add new +) нужно по деволту сразу установить level=='ALL' inside payScale
    }

    const newFormGroupPayScale: FormGroup<IFormItemCompetitionsPayScales> = this.formBuilder.group({
      ...newPayScale,
      crew: this.formBuilder.array([]),
    });
    newFormGroupPayScale.controls.gameTypeDrop?.addValidators(CustomValidators.requiredDrop);
    newFormGroupPayScale.controls.age?.addValidators(CustomValidators.requiredDrop);
    newFormGroupPayScale.controls.level?.addValidators(CustomValidators.requiredDrop);

    // !!! количество crewItem добавляется из payScale.crew.length
    for (const [idxCrew, crewItem] of newPayScale.crew!.entries()) {
      console.log('newPayScale.crew!.entries:', crewItem);
      // !!! при загрузке страницы Миша сказал только 1 crew показывать
      // !!! если есть id, значит получен массив с сервера. Значит надо crew показывать все которые пришли с сервера
      // !!! если нет id, то надо crew показывать только один
      // if (idxCrew > 0 && !newPayScale?.id) continue;
      const formCrewItem = this.addNewCrewItemForm(newFormGroupPayScale, crewItem);
      (newFormGroupPayScale.get('crew') as FormArray<FormGroup<IFormItemCompetitionsPayScalesCrew>>).push(formCrewItem);
    }

    this.arrControls.push(newFormGroupPayScale);
    setTimeout(() => this.cd.detectChanges());
  }

  // === FORM ==============
  private createForm(): void {
    this.form = this.formBuilder.group({
      arrTableHeader: new FormControl([]) as FormControl<Array<string>>,
      arrControls: this.formBuilder.array([]),
    });
    this.form.controls.arrTableHeader?.setValue(this.competitionS.arrTableHeaderPayScales);

    this.competitionS.payScales?.forEach((el) => {
      const crewArray: FormArray<FormGroup<IFormItemCompetitionsPayScalesCrew>> = this.formBuilder.array([]);
      this.arrControls.push(
        this.formBuilder.group({
          ...el,
          crew: crewArray,
          payScaleId: el?.id || '',
        })
      );
    });

    // !!! from setForm()
    this.competitionS.payScales?.forEach((el, idxArrControls) => {
      this.arrControls.clear();
      this.competitionS.competition?.payScales?.forEach((item) => this.addNew(item));
    });

    this.checkTotalRate('  createForm ');
  }

  private subscribeToForm(): void {
    this.form.valueChanges.pipe(untilDestroyed(this), debounceTime(300)).subscribe((res) => {
      this.checkTotalRate('  subscribeToForm ');
    });
  }

  get arrControls(): FormArray<FormGroup<IFormItemCompetitionsPayScales>> {
    return this.form?.controls?.arrControls!;
  }

  // === CREW ===========================
  addNewCrewItemForm(payScaleForm: FormGroup<IFormItemCompetitionsPayScales>, crewItem: ClassCompetitionPayScaleCrew): FormGroup<IFormItemCompetitionsPayScalesCrew> {
    const formCrewItem: FormGroup<IFormItemCompetitionsPayScalesCrew> = this.formBuilder.group({
      id: [crewItem?.id],
      payScaleId: [crewItem?.payScaleId || payScaleForm?.getRawValue()?.id],
      groupAssignorRate: [crewItem?.groupAssignorRate],
      groupAssignorRateVisible: [crewItem?.groupAssignorRateVisible],
      subAssignorRate: [crewItem?.subAssignorRate],
      otherRate: [crewItem?.otherRate],
      totalRate: [crewItem?.totalRate || getTotalRate(crewItem)], // totalRate: new FormControl({ value: crewItem.totalRate || getTotalRate(crewItem), disabled: true }),
      serialNumberCrewItem: [crewItem?.serialNumberCrewItem], // getSerialNumberCrewItem()
    });

    if (!crewItem.serialNumberCrewItem) {
      console.error('здесь обязательно нужен crewItem.serialNumberCrewItem :', crewItem.serialNumberCrewItem, crewItem);
    }
    // !!! массив берётся из maximumCrewSizeCompetition, потому что минимум 'official1','official2' и и.т. должно быть равно competition.maxCrewSize
    UtilsService.arrayFromNumber(maximumCrewSizeCompetition).forEach((elMaxCrewSize) => {
      if (this.competitionS.isShowCrewOfficial(elMaxCrewSize, 'TS')) {
        const keyofOnlyOfficial = getKeyofOnlyOfficialByNumber(elMaxCrewSize) as TKeyofOnlyOfficial;
        const disabledCrewOfficial = crewItem.serialNumberCrewItem! < elMaxCrewSize;
        const ctrlCrewOfficial = new FormControl({
          value: disabledCrewOfficial ? null : crewItem[keyofOnlyOfficial] || 0,
          disabled: disabledCrewOfficial,
        }) as FormControl<number>;
        formCrewItem.addControl(keyofOnlyOfficial, ctrlCrewOfficial);
      }
    });
    return formCrewItem;
  }

  changeCrewSize(idxPayScale: number, valueCrewSize: string | number): void {
    valueCrewSize = +valueCrewSize;
    const payScaleFormGroup: FormGroup<IFormItemCompetitionsPayScales> = this.form.controls.arrControls?.controls[idxPayScale]!;
    const crewFormArray: FormArray<FormGroup<IFormItemCompetitionsPayScalesCrew>> = payScaleFormGroup?.controls?.crew!;
    const crewArrayLength: number = crewFormArray.controls?.length;
    if (crewArrayLength === valueCrewSize) return; // Ничего не делать. количество crew === выбраный в дропдауне crew равны.

    if (crewArrayLength < valueCrewSize) {
      // Добавление нового crew. количество crew < выбраного в дропдауне crew.
      const amountAddedElems: number = valueCrewSize - crewArrayLength; // количество crew которые надо добавить
      UtilsService.arrayFromNumber(amountAddedElems).forEach((num) => {
        const newItemCrew = new ClassCompetitionPayScaleCrew({}, crewArrayLength + num, payScaleFormGroup?.value?.id!);
        const formCrewItem = this.addNewCrewItemForm(payScaleFormGroup, newItemCrew);
        (payScaleFormGroup.get('crew') as FormArray<FormGroup<IFormItemCompetitionsPayScalesCrew>>).push(formCrewItem); // payScaleFormGroup?.controls.crew!.controls.push(formCrewItem); // не работает подписка на форму
      });
    }

    if (crewArrayLength > valueCrewSize) {
      // удаление crew. количество crew < выбраного в дропдауне crew.
      const amountRemoveElems: Array<number> = Array.from(Array(crewArrayLength - valueCrewSize).keys()); // количество crew которые надо удалить
      const idxRemoveElemsCrew: Array<number> = amountRemoveElems.map((el) => crewArrayLength - el - 1);
      idxRemoveElemsCrew.forEach((el) => crewFormArray.removeAt(el)); // remove crew
    }
    this.cd.detectChanges();
  }

  // !!! при изменении значения у crew.official[number] надо у всех предыдущих в ЭТОМ же столбце устанавливать такое же значение (ЕСЛИ оно было пустое)
  changeValueCrewItem(payScaleItemForm: FormGroup<IFormItemCompetitionsPayScales>, crewItemForm: FormGroup<IFormItemCompetitionsPayScalesCrew>, currentNumberColumn: number, crewValue: string): void {
    const currentNumberRow = crewItemForm.value.serialNumberCrewItem!;
    payScaleItemForm.controls.crew?.controls?.forEach((crewItem) => {
      const numberRow = crewItem.value.serialNumberCrewItem!;
      if (numberRow < currentNumberRow) {
        const keyofOnlyOfficial = getKeyofOnlyOfficialByNumber(currentNumberColumn) as TKeyofOnlyOfficial;
        const ctrlCrewOfficial = crewItem.controls[keyofOnlyOfficial];
        const ctrlCrewOfficialValue = UtilsService.getNumberFromStringWithDollar(ctrlCrewOfficial?.value);
        if (!ctrlCrewOfficial?.disabled && !ctrlCrewOfficialValue) {
          // !!! менять у предыдущих ТОЛЬКО если не дисаблед и если там ещё нет значения
          ctrlCrewOfficial?.patchValue(+crewValue);
        }
      }
    });
  }

  // === HEADERS ===========================
  private subscribeToHeaderPayScales(): void {
    this.competitionS.arrTableHeaderPayScales$.pipe(untilDestroyed(this)).subscribe((res) => {
      this.form.controls.arrTableHeader?.setValue(this.competitionS.arrTableHeaderPayScales);
    });
  }

  // === competitions Btns Emit =========================
  async competitionsBtnsEmit(isNext: boolean): Promise<void> {
    const competitionId = this.competitionS?.competition?.id;
    const isActiveModal = await this.checkActiveGames.checkActive(competitionId, true);
    if (isActiveModal) {
      if (!competitionId) this.methodCompetitionPayScales(isNext, 'post'); // create
      if (competitionId) this.methodCompetitionPayScales(isNext, 'put'); // update
    }
  }

  methodCompetitionPayScales(isNext = false, httpMethod: THttpMethod): void {
    if (this.startRequest()) return;
    const sendObj: Pick<ClassCompetition, 'payScales' | 'id'> = {
      id: this.competitionS.competition?.id,
      payScales: this.arrControls.getRawValue(),
    };
    this.competitionS.addCompetitionIdBeforeSendToServer(sendObj.payScales);

    sendObj.payScales?.forEach((payScale, idx_payScale) => {
      payScale?.crew?.forEach((crew, idx_crew) => {
        arrKeyofOnlyOfficial.forEach((keyofOnlyOfficial, idx_keyofOnlyOfficial) => {
          const numberOfficial = +keyofOnlyOfficial.replace('official', ''); // 1,2,3...
          if (numberOfficial <= idx_crew + 1) {
            if (!crew[keyofOnlyOfficial]) crew[keyofOnlyOfficial] = 0;
          }
        });
      });
    });

    this.apiCompetitionS
      .methodCompetitionPayScales(sendObj, httpMethod)
      .toPromise()
      .then((res?: Pick<ClassCompetition, 'payScales'>) => {
        if (!res) return;
        this.competitionS.competition.payScales = res.payScales;
        isNext ? this.competitionS.nextStep('payScales') : this.competitionS.goToDashboard();
      })
      .catch((err) => { })
      .finally(() => this.endRequest());
  }

  // === OTHER ===============================
  checkTotalRate(str: string): void {
    this.arrControls?.controls.forEach((payScaleItem: FormGroup<IFormItemCompetitionsPayScales>) => {
      payScaleItem?.controls?.crew?.controls?.forEach((crewItem: FormGroup<IFormItemCompetitionsPayScalesCrew>) => {
        const totalRate = getTotalRate(crewItem.getRawValue(), str);
        crewItem.controls?.totalRate?.patchValue(totalRate, { emitEvent: false, onlySelf: true });
      });
    });
    setTimeout(() => this.cd.detectChanges());
  }
}
