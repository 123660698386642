<div class='wrapperImportGames'>
  <headTitle *ngIf="deviceS.isDesktop$ | async" text='Imports' typeText='text1-blueDark' class='wrapperImportGames__headTitle'></headTitle>
  <div *ngIf="deviceS.isMobile$ | async" class="imports-title">Imports</div>
  <div class='contentImportGames o-wrap'>
    <!-- <headTitle text='Upload game schedules' svgRight='cross_grey&24' (svgRightEmit)='router.navigate(["/games"])' typeText='text4-blueDark'>
    </headTitle> -->

    <div class="o-text4-blueDark">
      <span>Please choose from the competitions already added to your group to <br> upload game schedules</span>
    </div>

    <field text='Competition' class='o-mg-t-20'>
      <app-dropdown [(ngModel)]='selectedCompetition' [array]='(gameS.arrCompetition$|async)!' placeholder='Competition'></app-dropdown>
    </field>

    <!-- <div class='o-blockUploadFile'>
      <div class='o-blockUploadFile__title'>Download the CSV template to format your games file</div>
      <a href='assets/_CSV/CSV_default_Template.csv' download='Default_Template.csv'
        class='o-btn o-btn-square-newGreen'>
        <meSvg svgName='arrow_download&20'></meSvg>
        Download Game Import CSV Template
      </a>
    </div> -->

    <!--    <div class='blockImportGames'>-->
    <!--      <div class='blockImportGames__title'>Drag and drop your _CSV file to start uploading</div>-->
    <!--      <div class='blockImportGames__title'>or</div>-->
    <!--      <btn text='Upload _CSV File' bcg='newGreen' svgLeft='download'></btn>-->
    <!--    </div>-->
    <!--    <csvImport [file]='file' (changeFile)='changeFile($event)'></csvImport>-->
    <!-- <csvImport (changeFile)='changeFile($event)' [selectedCompetition]="selectedCompetition" [userId]="userData?.id">
    </csvImport> -->   
    <div class="imports-button">
      <btn class="box-item" text='Cancel' bcg='white' (emit)='router.navigate(["/games"])' [disabled]='reqPending'></btn>
      <btn *ngIf="!selectedCompetition" class="box-item" text='Next Step' bcg='blueDark' (emit)='router.navigate(["/games"])' [disabled]='!selectedCompetition'></btn>
    <csv-import-csvbox *ngIf="selectedCompetition" class='o-btn box-item o-btn-square-blueDark' [user]="userData?.id" [selectedCompetition]="selectedCompetition"></csv-import-csvbox>
    </div>
  </div>
</div>

