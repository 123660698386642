import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'replace', standalone: true })
export class ReplacePipe implements PipeTransform {

  // !!!  например надо исключить + и - => symbols == ['+','-']
  transform(str: string, symbols: Array<string>): string {
    if (!str) return '';
    let result = str;
    symbols.forEach((el) => {
      result = result?.replace(el, '');
    });
    return result;
  }

}
