import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { FormsModule } from '@angular/forms';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IDataPopup } from '@services/popup.service';
import { HelperClass } from '@classes/Helper-Classes';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CopyLinkComponent } from '@components/copy-link/copy-link.component';

// export interface IDataPopup_forCustomizeInvitation extends IDataPopup {
// userRoleUpperCase: TUserRoleUpperCase;
// !!! === на разных страницах отправляется разный запрос на сервер для получения ссылки для регистрации
// path: TPath_for_invitePeoples;
// }

@UntilDestroy()
@Component({
  selector: 'app-popup-customize-invitation',
  standalone: true,
  imports: [CommonModule, HeadTitleComponent, FieldComponent, InputCtrlComponent, FormsModule, SvgAndTextComponent, BtnWrapComponent, BtnComponent, SvgComponent, MatTooltipModule, CopyLinkComponent],
  templateUrl: './popup-customize-invitation.component.html',
  styleUrls: ['./popup-customize-invitation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupCustomizeInvitationComponent extends HelperClass {
  // path: TPath_for_invitePeoples;
  // userRole: TUserRoleUpperCase;
  noteValue = '';
  // competitionId?: string
  // competition?: ClassCompetition; // !!! для получения ссылки для регистрации юзера в компетишн (страница competitions/users)

  constructor(
    // private mainS: MainService,
    public dialogRef: MatDialogRef<PopupCustomizeInvitationComponent>,
    @Inject(MAT_DIALOG_DATA) public dataPopup: IDataPopup, // {text: string}
    // @Inject(MAT_DIALOG_DATA) public dataPopup: IDataPopup_forCustomizeInvitation, // {text: string}
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    // this.path = dataPopup.path;
    // this.userRole = dataPopup.userRoleUpperCase;
    this.noteValue = dataPopup.text || '';
    // if (dataPopup.competition) this.competition = dataPopup.competition;
    // console.log('userRole :', this.userRole);
  }

  sendInvitation(): void {
    this.close(this.noteValue);
  }

  close(data: any): void {
    this.dialogRef.close(data);
  }
}

// getLinkOfficialToGroup(): void {
//   if (this.startRequest()) return;
// let observable: Observable<any>;
// if (this.path === 'officials' || this.path === 'dashboard') {
//   observable = this.mainS.getLinkOfficialToGroup(); // !!! для получения ссылки для регистрации судьи
// } else if (this.path === 'competitions/users') {
//   observable = this.mainS.getLink_for_inviteUserToCompetition(this.competition?.id!, this.userRole);
// } else if (this.path === 'groupProfile_userManagement') {
//   observable = this.mainS.getLink_for_inviteUserToGroup(this.userRole); // !!! для получения ссылки для регистрации юзера (пока что только для админа)
// } else {
//   console.error('PopupCustomizeInvitationComponent getLinkOfficialToGroup:', this.path);
// }
//
// // https://v3.beta.joinnotch.com/v1/signup?role=OFFICIAL&groupId=a39041e6-5619-4b2a-bf46-e3e305bf41c3&key=829bf99b-6a0e-464a-a635-83dddbfe6a6e
// observable!.pipe(
//   finalize(() => this.endRequest()),
//   untilDestroyed(this),
// ).subscribe((res) => {
//   res ? navigator.clipboard.writeText(res) : null;
// });
// }
