<!--group: (groupProfileS.group$|async),-->
<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              arrCurrentLink: groupProfileS.arrLinkObj_groupProfile,
              objCurrentLink: (groupProfileS.currentLink$|async)!,
              currentLink: (groupProfileS.currentLink$|async)?.currentLink!,
              dataTable: (dataTable$|async)!,
              arrContent: (arrContent$|async),
              loadingTable: (meTableS.loadingTable$|async),
              group: (meS.meCurrentGroup$|async),
             } as obj' class='o-wrapPage'
     wrapPage [widthPage]='widthPage' cdkScrollable dataTable>
  <!--  wrapperGroupProfileInfo wrapperGroupProfileManagement-->

  <!--  <div class='headerContact'>-->
  <!--    <headTitle text='User Management' typeText='text1-blueDark'></headTitle>-->
  <!--    <ng-container [ngTemplateOutlet]='actionsTemplate'></ng-container>-->
  <!--  </div>-->

  <!--  === FILTERS & TITLE ===========================-->
  <div class='o-header'>
    <!--    (emit)='groupProfileS.navigateBy_currentLinkDrop($event)'-->
    <linkPage-searchFilter [currentLinkDrop]='obj.currentLink'></linkPage-searchFilter>
    <div class='o-header__content'>
      <div class='o-header__title'>User Management</div>
      <div class='o-header__filters'>
        <app-filters></app-filters>
        <div class='o-header__actions'>
          <btn text='New User +' bcg='newGreen' [btnWidth]='obj.mobile ? "100%":118' [btnHeight]='obj.mobile ? 36:""'
               (click)='inviteUserToGroup()'></btn>
        </div>
      </div>
      <app-filtersSelected></app-filtersSelected>
    </div>
  </div>

  <!--  === TABLE ==============================-->
  <div class='o-content t-wrapperTable'>
    <tableHeader></tableHeader>
    <div class='t-table'>
      <ng-container *ngFor='let item of obj.arrContent; let idx=index'>
        <div *ngIf='{ } as objGame' class='t-table__item' itemTable [item]='item'>
          <app-tableCeilUser [user]='item' (emit)='profileS.navigateByUrl("contactInfo",rout_profileUser,item.id)'></app-tableCeilUser>
          <div ceilTable [idxCeil]='1' class='t-table__item__wrapCeil'>
            <btn [text]='item.roleCurrent!|userRoleToTitleCase' [bcg]='item.roleCurrent === "SUB_ASSIGNOR" ? "blueDark":"grey_3"'
                 btnHeight='32' [matMenuTriggerFor]='menuItem_roleCurrent'></btn>
            <mat-menu #menuItem_roleCurrent='matMenu'>
              <button (click)='null'>Edina Soocer Club</button>
              <button (click)='null'>Richfield Soccer Association</button>
              <btn text='Assign New Role +' bcg='newGreen' btnHeight='36' btnWidth='206' (click)='assignNewRole(item)'></btn>
            </mat-menu>
          </div>
          <!--           andrei == dateOfBirth_formatted => date added-->
          <app-tableCeil [idxCeil]='2' [text1]='item.dateOfBirth_formatted'></app-tableCeil>
          <div class='t-header__ceil' ceilTable [idxCeil]='3'>
            <!--            [matMenuTriggerFor]='menuItem_managePermissions'-->
            <btn #btn_managePermissionsRef text='Manage Permissions' bcg='white' btnHeight='36' btnWidth='168'></btn>
            <mat-menu #menuItem_managePermissions='matMenu'>
              <!--              <button [ngStyle]='(btn_managePermissionsRef|getWidthMatMenu)' class='hiddenMatOption'></button>-->
              <button (click)='assignAsPayor(item)'>Assign as payor</button>
              <button (click)='removeAsPayor(item)'>Remove as payor</button>
              <button (click)='removeFromCompetition(item)'>Remove from competition</button>
            </mat-menu>
          </div>

          <!--  === ACTIONS FOR ITEM TABLE ==========================================-->
          <app-tableCeilActions *ngIf='obj.desktop' [matMenuTriggerFor]='menuItemTable' stopPropagation></app-tableCeilActions>
          <mat-menu #menuItemTable='matMenu'>
            <ng-container [ngTemplateOutlet]='actionsForItemTable'></ng-container>
          </mat-menu>
          <div *ngIf='obj.mobile' class='t-actionsItemTable t-actionsItemTable--{{obj.desktop}}' stopPropagation>
            <btn #btnMobileActionsRef text='More Options' bcg='white' [matMenuTriggerFor]='menuItemTable' btnHeight='36'></btn>
          </div>

          <ng-template #actionsForItemTable>
            <button [ngStyle]='(btnMobileActionsRef|getWidthMatMenu)' class='hiddenMatOption'></button>
            <button (click)='router.navigate([rout_profileUser+"/contactInfo",item?.id])'>View profile</button>
            <button (click)='assignNewRole(item)'>Assign new role</button>
            <button (click)='removeFromGroup(item)'>Remove from group</button>
          </ng-template>
        </div>
      </ng-container>
      <!--      <app-tableEmpty (btnEmit)='inviteUserToGroup()'></app-tableEmpty>-->
      <app-tableEmpty [tableEmpty]='filtersS.searchValue|getTableEmpty'>
        <btn bcg='newGreen' text='New User +' [btnHeight]='deviceS.btnHeightForNothingList'
             (emit)='inviteUserToGroup()' class='class_ngContent_btn_for_nothingList'></btn>
      </app-tableEmpty>
    </div>
  </div>

  <app-tableInfiniteLoading></app-tableInfiniteLoading>
  <paginationWithSettings *ngIf='meTableS.showPagination'></paginationWithSettings>
</div>


