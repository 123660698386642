import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { MainService } from '@services/main.service';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private mainS: MainService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let cloneRequest = req.clone({});
    const jwt_token = localStorage.getItem('jwt_token');
    const url = {
      signin: req.url.endsWith('signin'),
      login: req.url.includes('login'),
      signup: req.url.includes('signup'),
      registration: req.url.includes('registration'),
    };

    if (jwt_token && !(url.signin || url.login || url.signup || url.registration)) {
      cloneRequest = req.clone({ headers: req.headers.append('Authorization', `Bearer ${jwt_token}`) });
    }
    //PLEASE DO NOT DELETE THE COMMENT WE USE IT FOR LOCAL TESTING !!!
    // cloneRequest = cloneRequest.clone({
    //   headers: cloneRequest.headers
    //     .set('user-id', '20fb6b63-2d27-4157-8fb4-5af5c3c4d950')
    //     .set('role-current', 'GROUP_ASSIGNOR')
    // });
    // cloneRequest.headers.keys().forEach((name: string) => {
    //   console.log(`+ ${req.url} ${name}: ${cloneRequest.headers.get(name)}`);
    // });


    return next.handle(cloneRequest).pipe(
      tap((event: HttpEvent<any>) => {
      },
        (err: any) => {
          // When 401 error is returned redirect always to login
          if (err instanceof HttpErrorResponse && err.status === 401) this.mainS.logout();
        },
      ),
    );

  }

}

// ==============================================
// ==============================================
// ==============================================

// export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn) => {
//   let cloneRequest = req.clone({});
//   const jwt_token = localStorage.getItem('jwt_token');
//   const url = {
//     signin: req.url.includes('signin'),
//     login: req.url.includes('login'),
//     signup: req.url.includes('signup'),
//     recovery: req.url.includes('recovery'),
//     forgot: req.url.includes('forgot'),
//     anonymous: req.url.includes('anonymous'), // ??
//   };
//
//   if (jwt_token && !(url.signin || url.login || url.signup || url.recovery || url.forgot || url.anonymous)) {
//     cloneRequest = req.clone({ headers: req.headers.append('Authorization', `Bearer ${jwt_token}`) });
//   }
//
//   return next(cloneRequest)
//     .pipe(
//       tap((event: HttpEvent<any>) => {
//           // console.log('authInterceptor event:', event)
//         },
//         (err: any) => {
//           // console.log('authInterceptor err:', err);
//           if (err instanceof HttpErrorResponse && err.status === 401) MainService.logout();
//         },
//       ),
//     );
// };

// export const httpInterceptorProviders = [
//   {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
// ];