import { Pipe, PipeTransform } from '@angular/core';
import { FiltersService } from '@components/filters/filters.service';

@Pipe({ name: 'canResetFilters', standalone: true, pure: false }) // !!! NO DELETE pure: false
export class CanResetFiltersPipe implements PipeTransform {

  constructor(
    private filtersS: FiltersService,
  ) {
  }

  // !!! можно ли удалить фильтры. Если например 1 значение компетишна и его удалить нельзя, то вернется false
  // !!! если вернется true знаит можно удалить
  transform(a: null): boolean {
    return this.filtersS.canResetFilters();
  }

}
