<!--*ngIf='competitionS.isLoad.teams; else spinner'-->
<div class='wrapperCompetitions'>
  <competitionsNavigation page='teams'></competitionsNavigation>
  <div class='wrapperCompetitionsBlock wrapperCompetitionsTeams' [formGroup]='form'>
    <div class='headerSetupCompetition'>
      <svgAndText text='New Teams' leftSvgName='arrow_squareLeft&24' typeText='text1-blueDark'
                  (leftSvgEmit)='competitionS.goToBack()'></svgAndText>
      <div *ngIf='competitionS.competition?.id' class='headerSetupCompetition__btns'>
        <btn text='Save & Close' bcg='white' btnHeight='36' (click)='competitionsBtnsEmit(false)'
             [disabled]='!form.valid || reqPending'></btn>
        <btn text='Save & Proceed to Next Step' bcg='blueDark' btnHeight='36' (click)='competitionsBtnsEmit(true)'
             [disabled]='!form.valid || reqPending'></btn>
      </div>
    </div>

    <div formArrayName='arrControls' class='contentSetupCompetition'>
      <ng-container *ngFor='let item of arrControls?.controls; let idx=index; let first=first;  let last=last'>
        <div [formGroupName]='idx' class='contentSetupCompetition__itemTeam contentSetupCompetition__itemTeam--{{last?"last":""}}'>
          <field text='Team Name {{idx+1}}'>
            <inputCtrl formControlName='teamName' placeholder='Name' [isFocus]='true'></inputCtrl>
          </field>
          <deleteItemForCompetitions page='teams' [id]='item?.value?.id!' [disabled]='arrControls?.controls?.length == 1'
                                     (responseEmit)='form.controls.arrControls?.removeAt(idx)'></deleteItemForCompetitions>
        </div>
        <btnAddAnother *ngIf='last' page='competition' (emit)='addNew()'></btnAddAnother>
      </ng-container>

      <btnWrap *ngIf='!competitionS.competition?.id' type='col'>
        <btn text='Save & Proceed to Next Step' bcg='blueDark' (click)='competitionsBtnsEmit(true)'
             [disabled]='!form.valid || reqPending'></btn>
        <btn text='Save & Close' bcg='white' (click)='competitionsBtnsEmit(false)' [disabled]='!form.valid || reqPending'></btn>
      </btnWrap>
    </div>


  </div>
</div>

<!--<ng-template #spinner>-->
<!--  <mat-spinner diameter='40'></mat-spinner>-->
<!--</ng-template>-->
