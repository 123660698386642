import { ElementRef, Injectable } from '@angular/core';
import { google } from 'google-maps';
import { OtherService } from '@services/other.service';
import { ILocForGoogleMap, LocForGoogleMapClass } from '@classes/Location';
import { BehaviorSubject } from 'rxjs';
import { ILocation } from '@models/location';

import axios from 'axios';
// declare var axios: any;

export type TTypeTimezone = 'Utc' | 'User' | 'Game'; // в проекте 3 типа таймзоны.

export interface IResponseTimezone {
  dstOffset: number; // 3600
  rawOffset: number; // -21600
  status: string; // "OK"
  timeZoneId: string; // "America/Chicago"
  timeZoneName: string; // "Central Daylight Time"
}

// массив таких объектов в файле src/app/shared/models/fullListTimezone.ts
export interface ITimezone {
  // shortName: string;
  // timeZoneName: string;
  // value: string;
  // shortValue: string;
  abbrev: string; // 'CDT'
  id: string; // 'Central Daylight Time'
  name: string; // 'America/Chicago'
  value: number;
}

export interface IGeoAddress {
  house?: string; // номер дома
  street?: string; // улица
  districtOfCity?: string; // район города
  building?: string;
  city?: string; // город
  state?: string; // штат/регион
  country?: string; // страна
  zipcode?: string; // индекс
  fullAddress?: string; // полный адрес тесктом
  place_id?: string; // уникальный ключ локации типа такого 'UYT43KUHGBsjhJHA'
  coords?: google.maps.LatLngLiteral; // координаты
  latLng?: google.maps.LatLng; // координаты
  states?: Array<string>; // [ регион страны, район региона ]
  id?: string;
}

export type TCoordsForBuildRoute = string | google.maps.LatLng | google.maps.LatLngLiteral | google.maps.Place;

@Injectable({ providedIn: 'root' })
export class Geo {
  // apiKeyTimeZone = 'AIzaSyC7H66e63vF5l1WirATAHY5BS6lYgvkIf4';
  apiKeyTimeZone = 'AIzaSyDn8BKUdA8K5Pgs9KdvCJf-0djrVXlTLWQ';

  private map?: Promise<Readonly<google.maps.Map<Element>>>;
  private mapOptions?: google.maps.MapOptions;

  private addressUser: IGeoAddress | null = null;
  // testAddressUser: { [key: string]: string } = {};

  private markers: Array<google.maps.Marker> = [];

  // === GOGGLE MAP ====================
  divForMap?: ElementRef;
  markerLogo?: string;
  private locationForGoogleMapSub$ = new BehaviorSubject<ILocForGoogleMap>(new LocForGoogleMapClass());
  locationForGoogleMap$ = this.locationForGoogleMapSub$.asObservable();

  constructor(
    private otherS: OtherService,
  ) {
  }

  // === GOGGLE MAP ====================
  goToGoogleMap() {
    window.open(`https://www.google.com/maps/place/?q=place_id:${this.locForGoggleMap?.placeIdOfGame}`, '_blank');
  }

  checkLocForGoggleMapFromILocation(isDrawMap: boolean, loc?: ILocation, divForMap?: ElementRef, markerLogo?: string): void {
    this.divForMap = divForMap;
    if (markerLogo) this.markerLogo = markerLogo;
    if (loc?.street) {
      this.locForGoggleMap = { address: `${loc?.city}, ${loc?.street}, ${loc?.state}, ${loc?.zipcode}` };
      if (this.locForGoggleMap?.address) this.drawMap111(isDrawMap);
    } else {
      this.locForGoggleMap = new LocForGoogleMapClass();
    }
  }

  get locForGoggleMap(): ILocForGoogleMap {
    return this.locationForGoogleMapSub$.getValue();
  }

  set locForGoggleMap(locForGoggleMap: ILocForGoogleMap) {
    this.locationForGoogleMapSub$.next({ ...this.locForGoggleMap, ...locForGoggleMap });
  }

  private drawMap111(isDrawMap = true): void {
    if (!this.locForGoggleMap?.address) return;
    // if (isDrawMap && !this.divForMap) return
    if (isDrawMap && this.divForMap) {
      this.divForMap.nativeElement.style.height = +this.divForMap!.nativeElement.offsetWidth + 'px';
    }
    const markers: Array<string> = [];
    this.markerLogo ? markers.push(this.markerLogo) : markers.push('/assets/geo-1.svg');
    this.drawMapForGameInfo(this.divForMap?.nativeElement, this.locForGoggleMap?.address!, markers, isDrawMap).then(res => {
      if (res?.place_id) this.locForGoggleMap = { placeIdOfGame: res.place_id };
      if (res?.distance) this.locForGoggleMap = { distanceFromUserToGame: res.distance };
    });
  }

  // === END GOGGLE MAP ============

  // отрисовываем карту без текущего местоположения. Только адрес игры в центре + расстояние (если юзер разрешит определить его местоположение)
  // drawMapForGameInfo(elRef: Element, address: string, markers: Array<string>): Promise<string | null> {
  drawMapForGameInfo(elRef: Element, address: string, markers: Array<string>, isDrawMap = true): Promise<{
    place_id: string,
    distance: string
  }> {
    // mapOption = {styles?: MapTypeStyle[];}

    // MapTypeStyle {
    // 'all' | 'geometry' | 'geometry.fill' | 'geometry.stroke' | 'labels'
    // | 'labels.icon' | 'labels.text' | 'labels.text.fill' | 'labels.text.stroke';
    // elementType: MapTypeStyleElementType - свойство указанного объекта для выбора.
    // Элементы - это части объекта, включая метки и геометрию.
    // Если вы не укажете элемент, будут выбраны все элементы объекта.
    //     elementType?: MapTypeStyleElementType;

    // featureType: MapTypeStyleFeatureType - функции, которые нужно выбрать для данной модификации стиля.
    // 'all' | 'administrative' | 'administrative.country' | 'administrative.land_parcel'
    //     | 'administrative.locality' | 'administrative.neighborhood' | 'administrative.province' | 'landscape'
    //     | 'landscape.man_made' | 'landscape.natural' | 'landscape.natural.landcover' | 'landscape.natural.terrain'
    //     | 'poi' | 'poi.attraction' | 'poi.business' | 'poi.government' | 'poi.medical' | 'poi.park' | 'poi.place_of_worship'
    //     | 'poi.school' | 'poi.sports_complex' | 'road' | 'road.arterial' | 'road.highway' | 'road.highway.controlled_access'
    //     | 'road.local' | 'transit' | 'transit.line' | 'transit.station' | 'transit.station.airport'
    //     | 'transit.station.bus' | 'transit.station.rail' | 'water';
    // Объекты - это географические характеристики на карте, включая дороги, парки, водоемы и т. Д.
    // Если вы не укажете функцию, будут выбраны все функции.
    //     featureType?: MapTypeStyleFeatureType;

    //     stylers?: MapTypeStyler[];
    // }
    // visibility( on,, offили simplified) f20a0a 00ff09 fbff00
    const customStolesForMap: google.maps.MapTypeStyle[] = [
      // { elementType: "geometry", stylers: [{ color: "#f20a0a" }] }, // e1ebf8
      { featureType: 'administrative', stylers: [{ color: '#e1ebf8' }] },

      { featureType: 'landscape', stylers: [{ color: '#e1ebf8' }] },

      { elementType: 'labels.text.fill', stylers: [{ color: '#4e545f' }] },
      { elementType: 'labels.text.stroke', stylers: [{ color: '#e1ebf8' }] },

      { featureType: 'poi', stylers: [{ color: '#d6dfef' }] }, // выбирает все достопримечательности
      { featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'off' }] }, // выбирает все достопримечательности
      { featureType: 'poi.school', stylers: [{ color: '#d6dfef' }] },
      { featureType: 'poi.park', stylers: [{ color: '#cad2e2' }] },
      { featureType: 'poi.sports_complex', stylers: [{ color: '#d5deee' }] },

      { featureType: 'road', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
      { featureType: 'road', elementType: 'geometry.fill', stylers: [{ color: '#f3fdfd' }] },
      { featureType: 'road', elementType: 'geometry.stroke', stylers: [{ color: '#f3fdfd' }] },
      // { featureType: "road.highway", elementType: "labels.text.fill", stylers: [{ color: '#4e545f' }] },
      // { featureType: "road.highway", elementType: "labels.text.stroke", stylers: [{ color: '#e1ebf8' }] },
      { featureType: 'road.local', elementType: 'labels.text.fill', stylers: [{ color: '#888f9b' }] },
      { featureType: 'road.highway', elementType: 'geometry', stylers: [{ color: '#bcc4d3' }] },

      { featureType: 'transit', stylers: [{ visibility: 'off' }] },

      { featureType: 'water', stylers: [{ color: '#aab2c0' }] },
    ];

    // !!! переделать потом
    // const drawMap =  this.drawMap(elRef);
    const drawMap = isDrawMap ? this.drawMap(elRef) : null;

    const userCoords: Promise<google.maps.LatLngLiteral | null> = this.getCurrentLocation()
      .then(geoCoords => this.geoCoordsToLatLngLiteral(geoCoords, 'coords'))
      .catch((res: null) => res);

    const addressOfGame: Promise<{ coords: google.maps.LatLngLiteral, place_id: string }> = this.getGeoAddress({ address })
      // .then(geoAddressOfGame => geoAddressOfGame.coords as google.maps.LatLngLiteral);
      .then(geoAddressOfGame => ({
        coords: geoAddressOfGame.coords as google.maps.LatLngLiteral,
        place_id: geoAddressOfGame.place_id as string,
      }));

    return Promise.all([drawMap, userCoords, addressOfGame])
      .then(([map, coordsCurrentLoc, addressGame]) => {
        this.mapOptions = { center: addressGame.coords, zoom: 16, styles: customStolesForMap };/////////////
        this.addMarker(addressGame.coords, markers[0]);
        // this.addMarker(addressGame.coords);
        this.setBoundsOfMap();
        this.setMapOptions(this.mapOptions);

        // задаем маркеру класс, чтобы изменить его в CSS
        // if (markers[0]) {
        //     let myoverlay = new google.maps.OverlayView();
        //     myoverlay.draw = function() {
        //         this.getPanes().markerLayer.className = 'marker-of-game';
        //     };
        //     myoverlay.setMap(map);
        // }

        if (coordsCurrentLoc) {
          return this.calcDistance({ location: coordsCurrentLoc }, { location: addressGame.coords })
            .then(res => ({ place_id: addressGame.place_id, distance: res }));
        } else {
          return { place_id: addressGame.place_id, distance: '' };
        }
      });
  }

  // отрисовываем карту вместе с текущим местоположением + маркеры + маршрут + расстояние
  // public drawMapForPageGamesInfo(elRef: Element, address: string, markers: Array<string>): Promise<number> {
  //     const drawMap = this.drawMap(elRef);
  //     const userCoords: Promise<GeolocationCoordinates> = this.getCurrentLocation();
  //     // const userCoords222: Promise<IGeoAddress> = this.getGeoAddress({ componentRestrictions: { postalCode: '02116'} });
  //     const addressOfGame: Promise<IGeoAddress> = this.getGeoAddress({ address });
  //
  //     return Promise.all([drawMap, userCoords, addressOfGame]).then(res => {
  //         // this.mapOptions = { center: { lat: res[1].latitude as number, lng: res[1].longitude as number }, zoom: 12 };
  //         this.mapOptions = { center: this.geoCoordsToLatLngLiteral(res[1], 'coords'), zoom: 12 };
  //         const point1 = this.geoCoordsToLatLngLiteral(res[1], 'coords');
  //         const point2 = res[2].coords as google.maps.LatLngLiteral;
  //         this.addMarker(point1);
  //         // с маркерами потом разобраться. Игр может быть много на карте
  //         this.addMarker(point2, markers[0]);
  //         this.setBoundsOfMap();
  //         this.drawMapAndBuildRoute(point1, point2);
  //         this.setMapOptions(this.mapOptions);
  //         return this.calcDistance({ location: point1 }, { location: point2 });
  //     });
  // }

  // для получения полного адреса из текущего местоположения
  public getAddress(): Promise<IGeoAddress> {
    return new Promise<IGeoAddress>((resolve, reject) => {
      this.getCurrentLocation()
        .then(coords => {
          this.getGeoAddress({ location: { lat: coords.latitude, lng: coords.longitude } })
            .then(address => resolve(address))
            .catch((err) => reject(err));
        })
        .catch((err) => reject(err));
    });
  }

  public autoCompletePlaces(input: HTMLInputElement): void {
    // console.log('autoCompletePlaces input:', input)
    const coordsCenterOfUsa: google.maps.LatLngLiteral = { lat: 40.696587, lng: -100.155468 };
    const center: google.maps.LatLngLiteral = coordsCenterOfUsa;
    const defaultBounds: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral = { // ограничивающая рамка со сторонами 0.1 === 10 км от центральной точки
      north: center.lat + 15,
      south: center.lat - 15,
      east: center.lng + 25,
      west: center.lng - 25,
    };
    const options = {
      bounds: defaultBounds,
      componentRestrictions: { country: 'us' },
      // fields: ['address_components', 'icon', 'formatted_address', 'plus_code'],
      strictBounds: true,
      // types: ['address'],
    };
    // console.log('autoCompletePlaces options:', options)
    const autocomplete = new google.maps.places.Autocomplete(input, options);
    // console.log('autocomplete :', autocomplete)
    // autocomplete.setFields(['address_component', 'formatted_address']);

    // let place = autocomplete.getPlace(); //  google.maps.places.PlaceResult

    // if (place?.address_components) {
    //     for (let i = 0; i < place.address_components.length; i++) {
    //         for (let j = 0; j < place.address_components[i].types.length; j++) {
    //             if (place.address_components[i].types[j] == "postal_code") {
    //                 // document.getElementById('postal_code').innerHTML = place.address_components[i].long_name;
    //             }
    //         }
    //     }
    // }

    // return place;
  }

  // public setAddressFromGoogle(str: string): Promise<{ [key: string]: string }> {
  public setAddressFromGoogle(str: string): Promise<{ [key: string]: any }> {
    // console.log('setAddressFromGoogle :', str);
    // let result: { [key: string]: string } = {};
    let result: any = {};

    return new Promise<{ [p: string]: string }>((resolve, reject) => {
      this.getGeoAddress({ address: str })
        .then(fullAddress => {
          // console.log('getGeoAddress fullAddress :', fullAddress);
          if (fullAddress) {
            result = {
              street: fullAddress.fullAddress!.trim().split(',')[0],
              city: fullAddress.fullAddress!.trim().split(',')[1],
              zipcode: fullAddress.zipcode as string,
              state: (fullAddress.states as Array<string>)[0],
              coords: fullAddress.coords,
            };
          }
          resolve(result);
        })
        .catch((err) => {
          // console.log('getGeoAddress err :', err);
          reject(null);
        });
    });
  }

  public getTimezone(coords: { latitude?: number; longitude?: number }): Promise<ITimezone> {
    const config = {
      method: 'get',
      url: `https://maps.googleapis.com/maps/api/timezone/json?location=${coords.latitude}%2C${coords.longitude}&timestamp=${new Date().getTime() / 1000}&key=${this.apiKeyTimeZone}`,
      headers: {},
    };
    // return axios(config);
    return new Promise((resolve, reject) => {
      axios(config)
        .then((response: any) => {
          if (response?.data?.errorMessage) reject(response?.data?.errorMessage);
          if (response.status !== 200) reject(null);
          const res: IResponseTimezone = response?.data;
          const timezone = this.otherS.convertGoogleTimezoneToITimezone(res);
          resolve(timezone);
        })
        .catch((err: any) => reject(err));
    });
  }

  public getCoordsByZipcode(zipcode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getGeoAddress({ address: zipcode })
        .then(res => resolve(res))
        .catch(() => reject(null));
    });
  }

  private geoCoordsToLatLngLiteral(coords: GeolocationCoordinates | IGeoAddress, str: 'coords' | 'address'): google.maps.LatLngLiteral {
    if (str === 'coords') {
      return {
        lat: (coords as GeolocationCoordinates).latitude,
        lng: (coords as GeolocationCoordinates).longitude,
      };
    } else {
      return (coords as IGeoAddress).coords as google.maps.LatLngLiteral;
    }
  }

  // получаем this.coordsUserLocation (текущее местоположение пользователя)
  public getCurrentLocation(): Promise<GeolocationCoordinates> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (pos) => resolve(pos.coords),
        (err) => { // если запрещено отслеживание местоположения
          // this.otherS.showError('You disabled geolocation in your browser.')
          reject(null);
        },
      );
    });
  }

  // получаем полный адрес из координат { lat: 59.9363022, lng: 30.320975 }
  // или из полного адреса "Невский пр., 20а, Санкт-Петербург, Россия, 191186" в каком порядке дом, улицы и т.д не имеет значения
  public getGeoAddress(request: google.maps.GeocoderRequest): Promise<IGeoAddress> {
    // console.log('public getGeoAddress :', request);
    return new Promise<IGeoAddress>((resolve, reject) => {
      new google.maps.Geocoder().geocode(request, (results: Array<google.maps.GeocoderResult>, status: any) => {
        // console.log('google.maps.Geocoder().geocode :', results, status);
        if (status == google.maps.GeocoderStatus.OK) {
          let geoResult = results.find(res => res.types?.find(type => type === 'street_address' || type === 'plus_code')) as google.maps.GeocoderResult;
          !geoResult && (geoResult = results[0]);
          const address = geoResult.address_components as google.maps.GeocoderAddressComponent[];

          // Но здесь некоторые ключи объектов будут такие === 'administrative_area_level_2'
          // подробнее о их значении здесь https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
          // address.forEach(addr => this.testAddressUser[(addr.types[0] as string)] = addr.long_name);

          // START 2-вариант
          this.addressUser = {
            fullAddress: geoResult.formatted_address, // полный адрес тесктом
            place_id: geoResult.place_id, // уникальный ключ локации типа такого 'UYT43KUHGBsjhJHA'
            latLng: (geoResult.geometry as google.maps.GeocoderGeometry).location, // координаты LatLng
            coords: (geoResult.geometry as google.maps.GeocoderGeometry).location.toJSON(), // координаты LatLngLiteral
          };
          address.forEach((addr) => {
            addr.types.forEach(type => {
              type === 'street_number' && (this.addressUser!.house = addr.long_name);
              type === 'route' && (this.addressUser!.street = addr.long_name);
              type === 'sublocality' && (this.addressUser!.districtOfCity = addr.long_name);
              type === 'locality' && (this.addressUser!.city = addr.long_name);
              type === 'country' && (this.addressUser!.country = addr.long_name);
              type === 'postal_code' && (this.addressUser!.zipcode = addr.long_name);
              if (type.includes('administrative_area_level')) {
                !this.addressUser!.states ? this.addressUser!.states = [addr.long_name]
                  : this.addressUser!.states.unshift(addr.long_name);
                this.addressUser!.state = this.addressUser!.states[0];
              }
            });
          });
          // END 2-вариант

          // START 3-вариант
          // this.addressUser = {
          //     house: address.find(res => res.types.find(el => el === 'street_number'))?.long_name,
          //     street: address.find(res => res.types.find(el => el === 'route'))?.long_name,
          //     districtOfCity: address.find(res => res.types.find(el => el === 'sublocality'))?.long_name,
          //     city: address.find(res => res.types.find(el => el === 'locality'))?.long_name,
          //     state: address.find(res => res.types.find(el => el === 'street_number'))?.long_name,
          //     country: address.find(res => res.types.find(el => el === 'country'))?.long_name,
          //     zipcode: address.find(res => res.types.find(el => el === 'postal_code'))?.long_name,
          //     fullAddress: geoResult.formatted_address, // полный адрес тесктом
          //     place_id: geoResult.place_id, // уникальный ключ локации типа такого 'UYT43KUHGBsjhJHA'
          //     latLng: (geoResult.geometry as google.maps.GeocoderGeometry).location, // координаты
          // };
          // END 3-вариант

          resolve(this.addressUser);
        } else {
          reject(null);
        }
      });
    });
  }

  // FOR DRAWING A MAP. Если параметр center в options не передать, карта не отрисуется.
  // НО можно позже вызвать метод this.setMapOptions() и туда передать и всё отрисуется
  public drawMap(elRef: Element, options?: any): Promise<google.maps.Map> { // StreetViewPanorama
    const mapOptions = options || null;
    // this.mapOptions = { zoom: 12, backgroundColor: '#ff0000', mapTypeId: google.maps.MapTypeId.ROADMAP, };
    // return this.map = new Promise(resolve => resolve(new google.maps.Map(elRef, this.mapOptions)));
    return this.map = new Promise(resolve => resolve(new google.maps.Map(elRef, mapOptions)));
  }

  public setMapOptions(options: google.maps.MapOptions): void {
    this.mapOptions = { ...options };
    this.map?.then((map) => {
      map.setOptions(this.mapOptions as google.maps.MapOptions);
    });
  }

  // построение маршрута от точки start до точки end
  // если start === null , тогда start = текущее метоположение юзера
  public drawMapAndBuildRoute(start: TCoordsForBuildRoute, end: TCoordsForBuildRoute, elRef?: Element): void {
    if (!this.map && elRef) this.drawMap(elRef);
    this.map?.then(map => {
      const service = new google.maps.DirectionsService();
      const renderer = new google.maps.DirectionsRenderer();
      renderer.setMap(map);
      let request = {
        origin: start, // string | LatLng | LatLngLiteral | Place
        destination: end, // string | LatLng | LatLngLiteral | Place
        travelMode: google.maps.TravelMode.DRIVING, // BICYCLING | DRIVING | TRANSIT | TWO_WHEELER | WALKING
      };
      service.route(request, res => renderer.setDirections(res));
    });
  }

  // добавляем маркер на карту. imgName чтобы изменить вид маркера
  public addMarker(coords: google.maps.LatLngLiteral, imgName?: string): void {
    this.map?.then((map) => {
      // let img = '';
      // if (imgName) {
      //     if (imgName?.endsWith('.jpg') || imgName?.endsWith('.png')) {
      //         img = `${imgName}`;
      //     } else if (imgName?.endsWith('.svg')) {
      //         img = `./assets/${imgName}.svg`;
      //     }
      // }

      const icon = {
        // url: img || null,
        url: imgName,
        scaledSize: new google.maps.Size(30, 30),
        // origin: new google.maps.Point(0,0),
        // anchor: new google.maps.Point(0, 0),
        // shape:{ coords:[60,60,60], type:'circle' },
        // optimized: false, //set optimized to false otherwise the marker  will be rendered via canvas and is not accessible via CSS
      };

      const marker = new google.maps.Marker({
        position: coords,
        // icon: img,
        icon: icon,
        // icon: imgName,
        map: map,
        // shape: { type: 'circle'},
        // shape:{ coords:[17,17,18], type:'circle' },
        optimized: false, //set optimized to false otherwise the marker  will be rendered via canvas and is not accessible via CSS
      } as google.maps.ReadonlyMarkerOptions);

      // const myoverlay = new google.maps.OverlayView();
      // myoverlay.draw = function () {
      //     this.getPanes().markerLayer.id='markerLayer77777777777777777777777';
      //     this.getPanes().markerLayer.className='markerLayer77777777777777777777';
      // };
      // myoverlay.setMap(map);
      //
      // this.markers.push(marker);
    });
  }

  // ограничиваем размер карты так, чтобы все маркеры, которые мы добавляем, видны были на карте
  public setBoundsOfMap(): void {
    this.map?.then(map => {
      const bounds = new google.maps.LatLngBounds();
      this.markers.forEach(marker => bounds.extend(marker.getPosition() as google.maps.LatLng));
      map.fitBounds(bounds);
    });
  }

  // инфомационное окно с текстом
  public addInfoWindow(coords: google.maps.LatLngLiteral, text: string): void {
    this.map?.then(map => {
      new google.maps.InfoWindow({
        map: map,
        content: text,
        position: coords,
      } as google.maps.InfoWindowOptions);
    });
  }

  // расстояние между двумя координатами. возвращает число в метрах
  // address1 и address2 должны быть одного типа из этих : address1.address || address1.location || address1.placeId
  public calcDistance(address1: google.maps.GeocoderRequest, address2: google.maps.GeocoderRequest): Promise<string> {
    const coords1 = new Promise(resolve => this.getGeoAddress(address1).then(geoAddress => resolve(geoAddress.latLng)));
    const coords2 = new Promise(resolve => this.getGeoAddress(address2).then(geoAddress => resolve(geoAddress.latLng)));
    return Promise.all([coords1, coords2] as Array<Promise<google.maps.LatLng>>).then(res => {
      return (google.maps.geometry.spherical.computeDistanceBetween(res[0], res[1]) / 1000).toFixed(3);
    });
  }

}
