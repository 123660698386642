import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BooleanToStringPipe } from '@pipes/string/boolean-to-string.pipe';
import { BtnComponent } from '@components/btn/btn.component';
import { ContactItemComponent } from '@app/profile/additional_components/contact-item/contact-item.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { GetAgeByDateOfBirthPipe } from '@pipes/get-age-by-date-of-birth.pipe';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { PhotoComponent } from '@components/__svg_img/photo/photo.component';
import { Router } from '@angular/router';
import { DeviceService } from '@services/device.service';
import { OtherService } from '@services/other.service';
import { MeProfileOfficialService } from '@app/profile/me-profile-official/me-profile-official.service';
import { MeService } from '@services/me.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { GetLocationStringPipe } from '@pipes/location/get-location-string.pipe';
import { ProfileService } from '@app/profile/profile.service';
import { FormatPhonePipe } from '@pipes/phone/format-phone.pipe';
import {
  TitleAndNavigationForProfileComponent,
} from '@app/profile/additional_components/title-and-navigation-for-profile/title-and-navigation-for-profile.component';
import { ILocation } from '@models/location';
import { LocationService, TFieldLoc } from '@components/__blocks/location/location.service';
import { arrEmergencyRelationshipDrop, ClassUser } from '@models/user';
import { rout_meProfileOfficial } from '@app/profile/profile';
import { CustomValidators } from '@classes/CustomValidators';
import { HelperClass } from '@classes/Helper-Classes';
import { MainService } from '@services/main.service';
import { FieldRowComponent } from '@components/__drop_inputs_matSelect/field-row/field-row.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { LocationComponent } from '@components/__blocks/location/location.component';
import { SelectDateComponent } from '@components/date-time/select-date/select-date.component';
import { PhoneComponent } from '@components/__drop_inputs_matSelect/phone/phone.component';
import { ReplacePipe } from '@pipes/string/replace.pipe';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { arrYesNoDrop, ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UtilsService } from '@services/utils.service';
import { UssfCertPipe } from '@pipes/ussfCert/ussf-cert.pipe';
import { SvgComponent } from '../../../shared/components/__svg_img/svg/svg.component';
import { GetChxSvgNamePipe } from '../../../shared/components/__svg_img/svg/pipes/get-chx-svg-name.pipe';
import { IsLessAge18Pipe } from '@pipes/user/is-less-age18.pipe';

interface IMeProfileOfficialForm {
  gameReminderOn: FormControl<boolean>;
  pictureUrl: FormControl<string>;
  firstName: FormControl<string>;
  secondName: FormControl<string>;
  dateOfBirth: FormControl<string>;
  dateOfBirth_formatted: FormControl<string>;
  socialSecurity: FormControl<string>; // SNN XX-XXX-8888
  email: FormControl<string>;
  phone: FormControl<string>;
  certified: FormControl<boolean>;

  address: FormControl<ILocation>;

  // startOfficiating: FormControl<string>; // year // What year did you start officiating?
  // certified: FormControl<'Yes' | 'No'>; // Are you currently certified as an official?
  // certifiedDrop: FormControl<ClassYesNo>; // Are you currently certified as an official?
  // certificationName: FormControl<string>;
  // certificationNameDrop: FormControl<ClassDrop | null>;
  emergencyName: FormControl<string>;
  // emergencyRelationship: FormControl<string>;
  emergencyRelationshipDrop: FormControl<ClassDrop>;
  emergencyPhoneNumber: FormControl<string>;
  secondaryEmail?: FormControl<string>;
  organizationUserId: FormControl<string>;
  organization: FormControl<string>;
  licenseName: FormControl<string>;

}

@UntilDestroy()
@Component({
  selector: 'app-me-profile-official-info',
  standalone: true,
  templateUrl: './me-profile-official-info.component.html',
  styleUrls: ['./me-profile-official-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, BooleanToStringPipe, BtnComponent, ContactItemComponent, DropFormCtrlComponent, GetAgeByDateOfBirthPipe,
    HeadTitleComponent, PhotoComponent, FormsModule, GetLocationStringPipe, FormatPhonePipe, TitleAndNavigationForProfileComponent, ReactiveFormsModule, FieldRowComponent, InputCtrlComponent, LocationComponent, SelectDateComponent, PhoneComponent, ReplacePipe, DropdownComponent, UssfCertPipe, SvgComponent, GetChxSvgNamePipe, IsLessAge18Pipe],
})
export class MeProfileOfficialInfoComponent extends HelperClass {
  sameAsPrimaryEmail: boolean | undefined;
  sameAsPrimaryEmailOnClicked() {
    this.sameAsPrimaryEmail = !this.sameAsPrimaryEmail;
    if (this.sameAsPrimaryEmail)
      this.form.controls.secondaryEmail!.disable();
    else
      this.form.controls.secondaryEmail!.enable();

    this.form.controls.secondaryEmail!.reset();
  }
  isEdit = false;
  form!: FormGroup<IMeProfileOfficialForm>;
  defaultFormValue?: { [key: string]: any }; // {[key: keyof IMeProfileOfficialForm]: any}

  readonly arrYesNoDrop = arrYesNoDrop;
  // readonly arrCertificationNameDrop = arrCertificationNameDrop;
  readonly arrEmergencyRelationshipDrop = arrEmergencyRelationshipDrop;
  // protected readonly arrCertificationName = arrCertificationName;
  // protected readonly arrEmergencyRelationship = arrEmergencyRelationship;
  readonly rout_meProfileOfficial = rout_meProfileOfficial;
  widthInputForDesktop: number | string = 244;
  widthLeftField: number | string = 312;
  fields: Array<TFieldLoc> = ['street', 'streetLine2', 'city', 'state', 'zipcode'];
  requiredFields: Array<TFieldLoc> = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    public mainS: MainService,
    public meS: MeService,
    public router: Router,
    public deviceS: DeviceService,
    public meProfileOfficialS: MeProfileOfficialService,
    public profileS: ProfileService,
    public otherS: OtherService,
    public locationS: LocationService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.locationS.reset();
    this.locationS.setData(this.meS.me?.address || undefined);
    this.checkDevice();
    this.createForm();
  }

  submit(): void {
    if (this.startRequest()) return;
    this.form.markAllAsTouched();

    const formValue = this.form.getRawValue();
    const organizationUserId = this.form.controls.organizationUserId.value;
    const user: ClassUser = {
      ...formValue,
      organizationUserData: {
        organization: 'US_SOCCER_LEARNING_CENTRE',
        organizationUserId: organizationUserId,
        refereeExperience: 0,
      }
    } as ClassUser;

    const loc: ILocation = this.locationS.loc;
    const address: ILocation = {
      ...user.address,
      street: loc.street,
      city: loc.city,
      state: loc.state,
      zipcode: loc.zipcode,
      streetLine2: loc.streetLine2,
      timezone: loc.timezone,
    };

    user.address = UtilsService.removeEmptyKeysFromObject(address);

    if (this.sameAsPrimaryEmail)
      user.secondaryEmail = user.email;

    delete user.email;
    delete user.phone;

    this.mainS.updateUser(user).toPromise()
      .then(() => {
        this.createForm();
        this.isEdit = false;
      })
      .catch(() => {
      })
      .finally(() => this.endRequest());
  }

  sendCodeTo_email_or_phone(type: 'email' | 'phone', phoneValue?: string): void {
    if (this.startRequest()) return;
    let email: string = '';
    let phone: string = '';
    if (type == 'email') email = this.ctrl.email?.value;
    if (type == 'phone') phone = phoneValue!;
    this.profileS.sendCodeTo_email_or_phone(email, phone).toPromise()
      .then((res) => {
        if (type == 'phone') this.ctrl.phone.patchValue(phoneValue!);
      })
      .catch((err) => {
        console.log('err :', err);
      })
      .finally(() => this.endRequest());
  }

  // === FORM ==================================
  createForm(): void {
    const me: ClassUser = this.meS.me!;
    const isLessAge18 = UtilsService.isLessAge18(me?.dateOfBirth!);
    // console.log('API Response:', me);
    const organizationMapping: { [key: string]: string } = {
      'US_SOCCER_LEARNING_CENTRE': 'United States Soccer Federation (USSF)',
    };
    const organizationValue = me?.organizationUserData?.organization;
    const organizationDisplayValue = organizationValue && organizationMapping[organizationValue]
      ? organizationMapping[organizationValue]
      : organizationValue;

    this.form = this.formBuilder.group({
      gameReminderOn: [me?.gameReminderOn],
      pictureUrl: [me?.pictureUrl],
      firstName: [me?.firstName, [Validators.required]],
      secondName: [me?.secondName, [Validators.required]],
      dateOfBirth: [me?.dateOfBirth, [Validators.required]],
      dateOfBirth_formatted: [me?.dateOfBirth_formatted, [Validators.required]],
      socialSecurity: [me?.socialSecurity, [Validators.required]],
      email: [me?.email || '-', [Validators.required, CustomValidators.patternEmail]],
      phone: [me?.phone || '-', [Validators.required]],
      address: [me?.address, [Validators.required]],
      // startOfficiating: [me?.startOfficiating, [Validators.required]],
      // certified: [me?.certified ? 'Yes' : 'No', [Validators.required]], //'Are you currently certified as an official?'
      // certifiedDrop: [me?.certifiedDrop, [Validators.required]], //'Are you currently certified as an official?'
      // certificationName: [me?.certificationName, [Validators.required]],
      // certificationNameDrop: [me?.certificationNameDrop, [Validators.required]],
      // certificationNameDrop: [{
      //   value: me?.certifiedDrop?.valueBoolean ? me?.certificationNameDrop : undefined,
      //   disabled: !me?.certifiedDrop?.valueBoolean,
      // }, [Validators.required]],
      emergencyName: [me?.emergencyName, [Validators.required]],
      // emergencyRelationship: [me?.emergencyRelationship, [Validators.required]],
      emergencyRelationshipDrop: [me?.emergencyRelationshipDrop, [Validators.required]],
      emergencyPhoneNumber: [me?.emergencyPhoneNumber ? '+' + me?.emergencyPhoneNumber : '-', [Validators.required]],
      // secondaryEmail: [me?.secondaryEmail, [Validators.required, CustomValidators.patternEmail]],
      organizationUserId: [me?.organizationUserData?.organizationUserId],
      organization: [organizationDisplayValue],
      licenseName: [me?.mainLicense?.license?.name],
      certified: [me?.certified],
    });
    if (isLessAge18) {
      const ctrl = new FormControl({
        value: me?.secondaryEmail!,
        disabled: false,
      }, {
        validators: [
          Validators.required,
          CustomValidators.patternEmail,
          CustomValidators.notEqualValidator(this.meS.meEmail, "Please use a different email. It cannot be the same as the one used for login."),
        ]
      }) as FormControl<string>;
      this.form.addControl('secondaryEmail', ctrl);
      if (me?.secondaryEmail == null || me?.secondaryEmail == this.meS.meEmail) {
        this.sameAsPrimaryEmail = true;
        this.form.controls.secondaryEmail!.disable();
      }
    }
    // if (!me?.certifiedDrop?.valueBoolean) this.ctrl.certificationNameDrop.disable();
    this.defaultFormValue = this.form.getRawValue();

    // this.subscribeToForm();
    // this.subscribeTo_certifiedDrop();
    // this.subscribeTo_certificationNameDrop();
    this.form.markAllAsTouched(); // !!! NO DELETE чтобы ошибку подсвечивать сразу при открытии страницы. С Мишей так договорились 28.06.24
  }

  get ctrl(): IMeProfileOfficialForm {
    return this.form?.controls;
  }

  // subscribeTo_certifiedDrop(): void {
  //   this.ctrl.certifiedDrop.valueChanges.pipe(untilDestroyed(this)).subscribe((res) => {
  //     // console.log('certifiedDrop :', res)
  //     this.ctrl.certificationNameDrop.patchValue(null);
  //     if (!res?.valueBoolean) {
  //       // this.ctrl.certificationNameDrop.reset({}, this.formCustomS.optionsForPatchValue);
  //       // this.ctrl.certificationNameDrop.reset()
  //       // this.ctrl.certificationNameDrop.patchValue(null);
  //       this.ctrl.certificationNameDrop.disable();
  //     } else {
  //       this.ctrl.certificationNameDrop.enable();
  //     }
  //     // console.log('certificationNameDrop :', this.ctrl.certificationNameDrop.valid, this.ctrl.certificationNameDrop.value)
  //     this.cd.detectChanges();
  //   });
  // }

  // subscribeTo_certificationNameDrop(): void {
  //   this.ctrl.certificationNameDrop.valueChanges.pipe(untilDestroyed(this)).subscribe((res) => {
  //     console.log('certificationNameDrop :', this.ctrl.certificationNameDrop.valid, res)
  //   });
  // }

  // subscribeToForm(): void {
  //   this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((res) => {
  //     // this.ctrl.certificationNameDrop.patchValue(null, this.formCustomS.optionsForPatchValue);
  //     this.ctrl.certificationNameDrop.reset(null, this.formCustomS.optionsForPatchValue);
  //     if (!res.certifiedDrop?.valueBoolean) {
  //       // this.ctrl.certificationNameDrop.reset({}, this.formCustomS.optionsForPatchValue);
  //       // this.ctrl.certificationNameDrop.patchValue(new ClassDrop({}), this.formCustomS.optionsForPatchValue);
  //       // this.ctrl.certificationNameDrop.patchValue({}, this.formCustomS.optionsForPatchValue);
  //       this.ctrl.certificationNameDrop.disable(this.formCustomS.optionsForPatchValue);
  //     } else {
  //       this.ctrl.certificationNameDrop.enable(this.formCustomS.optionsForPatchValue);
  //     }
  //     console.log('certificationNameDrop :', this.ctrl.certificationNameDrop.valid, this.ctrl.certificationNameDrop.value)
  //     console.log('========================================  :', Object.entries(res));
  //     this.cd.detectChanges();
  //   });
  // }

  checkDevice(): void {
    this.deviceS.isDesktop$.pipe(untilDestroyed(this)).subscribe((isDesktop) => {
      this.widthInputForDesktop = isDesktop ? 244 : '100%';
      this.widthLeftField = isDesktop ? 312 : '100%';
    });
  }

  gameReminderOnClicked() {
    this.ctrl.gameReminderOn.patchValue(!this.ctrl.gameReminderOn.value);
  }

  cancel(): void {
    this.createForm();
    this.isEdit = false;
    this.locationS.reset();
    this.locationS.setData(this.meS.me?.address || undefined);
    this.cd.detectChanges();
  }

  onCertificationIdInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value;
    value = value.replace(/-/g, '');
    value = value.slice(0, 16);
    inputElement.value = value;
    this.form.controls.organizationUserId.setValue(value);
  }
}
