// export type TDefaultNameMatOption = '--- ALL ---' | '--- N/A ---' | TDefaultName_choosePeriod;
// export type TDefaultNameMatOption_forSendToServer = 'ALL';
// export const defaultNameMatOption_forSendToServer: TDefaultNameMatOption_forSendToServer = 'ALL';
// export const defaultNameMatOption_ALL: Extract<TDefaultNameMatOption, '--- ALL ---'> = '--- ALL ---';
// export const defaultNameMatOption_NA: Extract<TDefaultNameMatOption, '--- N/A ---'> = '--- N/A ---';
// export const arr_defaultNameMatOption: Array<TDefaultNameMatOption> = ['--- ALL ---', '--- N/A ---', defaultName_choosePeriod];

import { TAgeForOfficials } from '@app/dir_group_assignor/officials/officials';
import { ClassSettingsRequest } from '@models/response-and-request';
import { TSvgName } from '@components/__svg_img/svg/forSvg';
import { const_NA, removeEmptyKeysFromObject, toTitleCase } from '@models/other';
import { UtilsService } from '@services/utils.service';

export class ClassDrop {
  titleCase?: string; // !!! используется для отображения в выпадающем списке // TDefaultNameMatOption
  upperCase?: string; // !!! используется для отправки на сервер (например id игры или статус.upperCase) // TDefaultNameMatOption_forSendToServer
  lowerCase?: string;
  svgRightMatOption?: TSvgName; // !!! если нужно показать SVG в выпадающем списке на этом элементе
  count?: number; // for reports links
  valueBoolean?: boolean; // иногда нужно. например для gameIsCompleted приходит с бэка boolean и отправлять на бэк нужно boolean. А в дропдауне надо показывать текст 'Yes' | 'No'
  itemCount?: number; // !!! не знаю для чего это. Грант сделал для PopupNewAnnouncementGeneralComponent

  constructor(drop: ClassDrop & { id?: string }) {
    this.titleCase = drop.titleCase;
    // this.upperCase = drop?.id || drop.upperCase;
    this.upperCase = drop.upperCase;
    this.lowerCase = drop.lowerCase || drop.titleCase?.toLowerCase();

    if (drop?.svgRightMatOption) this.svgRightMatOption = drop.svgRightMatOption;
    if (typeof drop?.count === 'number') this.count = drop.count;

    return { ...this, ...drop };
  }
}

export function getArrayDropFromString(arrayString: Array<string>, forTest: string): Array<ClassDrop> {
  if (!arrayString?.length) {
    // console.error('getArrayDropFromString :', forTest, '  => ', arrayString);
    return [];
  }
  return arrayString.map(el => getDropFromString(el, forTest));
}

export function getArrayDropFromStringCaseSensitive(arrayString: Array<string>): Array<ClassDrop> {
  return arrayString?.map(string => new ClassDrop({
    titleCase: string,
    upperCase: string
  }));
}

export function getDropFromString(string: string, forTest: string): ClassDrop {
  return new ClassDrop({
    titleCase: string === const_NA || string === 'ALL' ? string : toTitleCase(string),
    upperCase: string?.toUpperCase(),
  });
}

// !!! перед отправкой на сервер доставать из IDrop.upperCase и записывать в соответствующий property
// !!! И удалять IDrop, т.к. на сервер они не доллжны отправляться
// !!! Все property которые в конце названия имеют "Drop" нужно в этом методе обрабатывать
// !!! arrExcludesProperty => property-исключения которые нужно обрабатывать для отправки на сервер по-другому, тоесть в этом методе НЕ надо обрабатывать такие property. Например sports = "BASKETBALL,BASEBALL"
// !!! в этом метоже обрабатываются также ClassYesNo
export function preparePropertyFromDropForSendToServer<T extends object>(obj?: T, arrExcludesProperty?: Array<keyof T>, forTest?: string): T {
  // if (obj) console.log('preparePropertyFromDropForSendToServer :', obj, Object.entries(obj));
  const objForSendToServer = obj as T;

  Object.entries(obj!)?.forEach(el => { // ['keyof game', 'value of game']
    if (el[0].includes('Drop')) {
      if (forTest) console.error('============== el 111 :', el);
      const nameWithDrop = el[0] as keyof T; // example 'gameTypeDrop'
      const valueDrop = el[1] as ClassDrop; // example 'gameTypeDrop'

      if (arrExcludesProperty?.includes(nameWithDrop)) { // в этом методе НЕ надо обрабатывать такие property
        console.log('preparePropertyFromDropForSendToServer ', el);
        return;
      }

      const nameForSendToServer = (nameWithDrop as string)?.replace('Drop', '') as keyof T; // example 'gameType'

      if (!nameForSendToServer) return;

      // !!! для массивов Array<IDrop> // example courtNamesDrop prepare to => courtNames
      if (Array.isArray(objForSendToServer[nameWithDrop])) {
        if (el[1]?.length) {
          objForSendToServer[nameForSendToServer] = el[1]?.map((drop: ClassDrop) => drop.upperCase);
        }
        delete objForSendToServer[nameWithDrop];
        return;
      }

      if ((valueDrop as ClassYesNo)?.typeCurrentClass === 'ClassYesNo') {
        // @ts-ignore
        objForSendToServer[nameForSendToServer] = (valueDrop as ClassYesNo).valueBoolean;
        delete objForSendToServer[nameWithDrop];
        return;
      }

      if (valueDrop?.upperCase) {
        // @ts-ignore
        objForSendToServer[nameForSendToServer] = valueDrop.upperCase;
        delete objForSendToServer[nameWithDrop];
      }

      if (Array.isArray(valueDrop)) {
        if (!valueDrop?.length) delete objForSendToServer[nameWithDrop]; // !!! удаление пустых массивов
      }

    }
  });

  delete (objForSendToServer as ClassDrop)?.titleCase;
  delete (objForSendToServer as ClassDrop)?.upperCase;
  delete (objForSendToServer as ClassDrop)?.lowerCase;
  delete (objForSendToServer as ClassDrop)?.svgRightMatOption;
  delete (objForSendToServer as ClassDrop)?.count;

  // === other ==============
  delete (objForSendToServer as any)?.checked;
  delete (objForSendToServer as any)?.isSelect;
  delete (objForSendToServer as any)?.classCompetition_string;
  delete (objForSendToServer as any)?.periodForDateRange;
  delete (objForSendToServer as any)?.typeBlockGamesForDashboard;

  // const sendObjWithout
  return removeEmptyKeysFromObject(objForSendToServer, forTest);
}

// === Drop for number ==========================================
export function getDropFromNumber(number: number): ClassDrop {
  return new ClassDrop({ titleCase: toTitleCase(number?.toString()), upperCase: number?.toString()?.toUpperCase() });
}

export function getArrayDropFromNumber(lengthForArray: number): Array<ClassDrop> {
  const arrayNumber: Array<number> = UtilsService.arrayFromNumber(lengthForArray);
  return arrayNumber.map(el => getDropFromNumber(el));
}

export function addTextForArray(arr: Array<ClassDrop>, text: string, addToBeginning: boolean): Array<ClassDrop> {
  const newItem: ClassDrop = { titleCase: text, upperCase: text.toUpperCase() };
  const modifyNumberArr = arr.map(item => {
    const selfRequestsTextTitleCase = item.titleCase === '1' ? ' request per day' : ' requests per day';
    const selfRequestsTextUpperCase = item.upperCase === '1' ? ' request per day' : ' requests per day';
    return {
      titleCase: item.titleCase + selfRequestsTextTitleCase,
      upperCase: item.upperCase + selfRequestsTextUpperCase,
    };
  });
  if (addToBeginning) {
    return [{ titleCase: '0', upperCase: '0' }, ...modifyNumberArr, newItem];
  } else {
    return [...modifyNumberArr, newItem];
  }
}

// === DefaultNameMatOption ========================
export type TDefaultNameMatOption = 'ALL';
export const defaultNameMatOption: TDefaultNameMatOption = 'ALL';

export class ClassDropForNameDefault implements ClassDrop {
  titleCase?: string; // !!! используется для отображения в выпадающем списке // TDefaultNameMatOption
  upperCase?: string; // !!! используется для отправки на сервер (например id игры или статус.upperCase) // TDefaultNameMatOption_forSendToServer
  id?: string;

  constructor(id?: string) {
    this.titleCase = defaultNameMatOption;
    // if (id) this.id = id;
    this.id = this.upperCase = id ? id : defaultNameMatOption;
  }
}

export function findDefaultDrop<T>(arr: Array<T & ClassDropForNameDefault>): T | undefined {
  return arr?.find(el => el.titleCase?.toUpperCase() === defaultNameMatOption);
}

// === Distance =================================================================
export const distanceItems: Array<ClassDrop> = [
  new ClassDrop({ titleCase: '< 5 miles', upperCase: '5' }),
  new ClassDrop({ titleCase: '< 10 miles', upperCase: '10' }),
  new ClassDrop({ titleCase: '< 20 miles', upperCase: '20' }),
];

export function getCurrentDistanceItem(distance: number | string): ClassDrop {
  const result = distanceItems.find(el => el.upperCase == distance)!;
  if (!result) {
    console.error('getCurrentDistanceItem :', ' distance:', distance);
  }
  return result;
}

// === Ages =================================================================
export type TAgesItem = ClassDrop & { titleCase: TAgeForOfficials, upperCase: '1' | '2' | '3' | '4' | '5' | '6' };
export const agesItems: Array<TAgesItem> = [
  { titleCase: '<15', upperCase: '1' },
  { titleCase: '16-17', upperCase: '2' },
  { titleCase: '18-25', upperCase: '3' },
  { titleCase: '26-35', upperCase: '4' },
  { titleCase: '36-45', upperCase: '5' },
  { titleCase: '46>', upperCase: '6' },
];
export const AGE_ITEM: { [key: string]: Pick<ClassSettingsRequest, 'fromAge' | 'toAge'>; } = {
  '1': { toAge: '15' },
  '2': { fromAge: '16', toAge: '17' },
  '3': { fromAge: '18', toAge: '25' },
  '4': { fromAge: '26', toAge: '35' },
  '5': { fromAge: '36', toAge: '45' },
  '6': { fromAge: '46' },
};

export function getCurrentAgeItemByFromAgeAndToAge(fromAge?: number | string, toAge?: number | string): TAgesItem | undefined {
  let result: TAgesItem | undefined = undefined;
  agesItems.forEach((el) => {
    let findAgeItemDrop_byFromAge: TAgesItem | undefined = undefined;
    let findAgeItemDrop_byToAge: TAgesItem | undefined = undefined;
    if (el.titleCase.includes(fromAge?.toString()!)) findAgeItemDrop_byFromAge = el;
    if (el.titleCase.includes(toAge?.toString()!)) findAgeItemDrop_byToAge = el;

    if (findAgeItemDrop_byFromAge) result = findAgeItemDrop_byFromAge;
    if (findAgeItemDrop_byToAge) result = findAgeItemDrop_byToAge;
  });
  if (!result) {
    console.error('getCurrentAgeItem :', ' fromAge:', fromAge, ' toAge:', toAge);
  }
  return result;
}


// ===== 'Yes' | 'No' from boolean =========
export type TYesNo = 'Yes' | 'No';

export class ClassYesNo extends ClassDrop {
  typeCurrentClass: 'ClassYesNo' = 'ClassYesNo';
  titleCase!: TYesNo;
  upperCase!: TYesNo;
  valueBoolean!: boolean;

  constructor(type?: boolean) {
    super({ titleCase: type ? 'Yes' : 'No', upperCase: type ? 'Yes' : 'No', valueBoolean: !!type });
  }
}

export const arrYesNoDrop: Array<ClassYesNo> = [
  new ClassYesNo(true),
  new ClassYesNo(false),
];
