import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IResponse } from '@models/response-and-request';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { arrDropStatusAssign, ClassGame } from '@app/dir_group_assignor/games/game';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { BtnComponent } from '@components/btn/btn.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { PhotosComponent } from '@components/__svg_img/photos/photos.component';
import { MatMenuModule } from '@angular/material/menu';
import { OtherService } from '@services/other.service';
import { IDataPopup, PopupService } from '@services/popup.service';
import { PopupCancelGameComponent } from '@app/dir_group_assignor/games/components/popup-cancel-game/popup-cancel-game.component';
import {
  PopupNewAnnouncementGeneralComponent,
} from '@components/__popup-windows/popup-new-announcement-general/popup-new-announcement-general.component';
import { PopupConfirmComponent } from '@components/__popup-windows/popup-confirm/popup-confirm.component';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { DeviceService } from '@services/device.service';
import { ClassCeilTableHeader, IForClassForTable } from '@components/_table/meTable';
import { TableHeaderComponent } from '@components/_table/table-header/table-header.component';
import {
  FiltersSelectedIconComponent,
} from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { FiltersService } from '@components/filters/filters.service';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { ClassFilterDateRange, ClassFilterDrop, ClassFilterInput, IForClassForFilters } from '@components/filters/filters';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { MeTableService } from '@components/_table/me-table.service';
import { IOutputObjStyles } from '@pipes/css/get-styles.pipe';
import { ItemTableDirective } from '@components/_table/directives/item-table.directive';
import { CeilTableDirective } from '@components/_table/directives/ceil-table.directive';
import { TableCeilComponent } from '@components/_table/table-ceil/table-ceil.component';
import { TableCeilAssignorComponent } from '@components/_table/table-ceil-assignor/table-ceil-assignor.component';
import { TableCeilActionsComponent } from '@components/_table/table-ceil-actions/table-ceil-actions.component';
import { TableCeilChxComponent } from '@components/_table/table-ceil-chx/table-ceil-chx.component';
import { urlCreateGame, urlGameImport, urlGameInfo, urlGames } from '@app/app.module';
import { TableEmptyComponent } from '@components/_table/table-empty/table-empty.component';
import { BehaviorSubject, from, Observable, timer } from 'rxjs';
import { HelperClass } from '@classes/Helper-Classes';
import { TableBtnNumberComponent } from '@components/_table/table-btn-number/table-btn-number.component';
import { TableInfiniteLoadingComponent } from '@components/_table/table-infinite-loading/table-infinite-loading.component';
import { DataTableDirective } from '@components/_table/directives/data-table.directive';
import { calculatePopupSizes } from '@models/other';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CdkScrollable } from '@angular/cdk/overlay';
import { StopPropagationDirective } from '@directives/stop-propagation.directive';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { PaginationWithSettingsComponent } from '@components/__settingsRequest/pagination-with-settings/pagination-with-settings.component';
import { GetWidthMatMenuPipe } from '@pipes/get-width-mat-menu.pipe';
import { LinkPageSearchFilterComponent } from '@components/__settingsRequest/link-page-search-filter/link-page-search-filter.component';
import { ForTestService } from '@classes/forTest';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MeService } from '@services/me.service';
import { AdminPermissionDirective } from '@directives/admin-permission.directive';
import { AdminPermissionPipe } from '@pipes/adminPermission/admin-permission.pipe';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';
import { GetListGameForPossibleGameToCancelledPipe } from '@pipes/game_and_report/get-list-game-for-possible-game-to-cancelled.pipe';
import { AssignService } from '@app/dir_group_assignor/assign/assign.service';
import { GetListGameForPossibleGameToDeletePipe } from '@pipes/game_and_report/get-list-game-for-possible-game-to-delete.pipe';
import { IsPossibleGameToCancelledPipe } from '@pipes/game_and_report/is-possible-game-to-cancelled.pipe';
import { IsPossibleGameToDeletePipe } from '@pipes/game_and_report/is-possible-game-to-delete.pipe';
import { MainService } from '@services/main.service';
import { BtnImportGamesComponent } from '@app/dir_group_assignor/games/components/btn-import-games/btn-import-games.component';
import { colorObj } from '@classes/CSS';
import { GetMatTooltipCancelReasonPipe } from '@pipes/game_and_report/get-mat-tooltip-cancel-reason.pipe';
import { GameNotesComponent } from '../game-notes/game-notes.component';
import { MatDialog } from '@angular/material/dialog';
import { TableCellGameNotesComponent } from '@components/_table/table-cell-game-notes/table-cell-game-notes.component';

@UntilDestroy()
@Component({
  selector: 'app-games',
  standalone: true,
  imports: [CommonModule, WrapPageDirective, CdkScrollable, DataTableDirective, DropdownComponent,
    FormsModule, FiltersSelectedIconComponent, FiltersComponent, FiltersSelectedComponent, TableHeaderComponent, TableCeilChxComponent,
    TableBtnNumberComponent, ItemTableDirective, TableCeilComponent, PhotosComponent, MatMenuModule, TableCeilActionsComponent,
    StopPropagationDirective, CeilTableDirective, TableEmptyComponent, TableInfiniteLoadingComponent, PaginationWithSettingsComponent, TableCellGameNotesComponent,
    SvgComponent, BtnComponent, RouterLink, GetWidthMatMenuPipe, TableCeilAssignorComponent, InputCtrlComponent, ReactiveFormsModule, LinkPageSearchFilterComponent, MatTooltipModule, AdminPermissionDirective, AdminPermissionPipe, GetTableEmptyPipe, GetListGameForPossibleGameToCancelledPipe, GetListGameForPossibleGameToDeletePipe, IsPossibleGameToCancelledPipe, IsPossibleGameToDeletePipe, BtnImportGamesComponent, GetMatTooltipCancelReasonPipe],
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss'],
  providers: [FiltersService, MeTableService, SettingsRequestService], // for-filters=== for-table=== for-settings===
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// !!! IForClassForFilters for-filters=== // !!! IForClassForTable for-table===
export class GamesComponent extends HelperClass implements IForClassForFilters, IForClassForTable<ClassGame> {
  @ViewChild('btnMobileActionsRef') btnMobileActionsRef?: BtnComponent;
  dataTable$ = new BehaviorSubject<IResponse<ClassGame>>({}); // for-table===
  arrContent$ = new BehaviorSubject<Array<ClassGame>>([]); // for-table===

  readonly urlGames = urlGames;
  readonly urlGameInfo = urlGameInfo;
  readonly urlCreateGame = urlCreateGame;
  readonly urlGameImport = urlGameImport;
  readonly widthPage = 1300;

  adminPermission = false;
  isCurrentLink$: Observable<boolean>;
  isCurrentLink: boolean = false;
  downloadDisabled = false;

  constructor(
    private mainS: MainService,
    public meS: MeService,
    public gameS: GameService,
    private otherS: OtherService,
    private popupS: PopupService,
    private route: ActivatedRoute,
    public router: Router,
    public deviceS: DeviceService,
    public filtersS: FiltersService, // for-filters===
    public meTableS: MeTableService<ClassGame>, // for-table===
    public settingsRequestS: SettingsRequestService, // for-settings===
    public forTestS: ForTestService,
    public assignS: AssignService,
    public cd: ChangeDetectorRef,
    private dialog: MatDialog,
  ) {
    super(cd);
    this.setAllData();
    this.isCurrentLink$ = this.gameS.currentLink$.pipe(
      map(currentLinkObj => currentLinkObj.currentLink?.upperCase === 'current')
    );
  }

  ngOnInit() {
    this.isCurrentLink$.subscribe(isCurrent => {
      this.isCurrentLink = isCurrent;
    });
  }
  // !!! если есть в фильтрах компетишн, то обязательно надо в AppModule к роуту прикрутить CompetitionsForFiltersResolver
  setAllData(): void {
    this.adminPermission = this.meS.ADMIN ? this.meS.adminPermission(urlGames) : true;
    this.setDataForTable(); // for-table===
    this.setFilters(); // for-filters===
    this.settingsRequestS.setAllData(this.isFirstLoadPageSub$); // for-settingsDynamicUrl=== создание ссылки, чтобы можно было использовать в сервисе
    this.gameS.setCurrentLink_games(this.settingsRequestS.settings.currentLink_games); // for-currentLink===
    this.subscribeToSettings(); // for-settings===
  }

  // === SETTINGS ===============================
  subscribeToSettings(): void {
    this.settingsRequestS.settings$.pipe(
      switchMap((res) => this.meTableS.getSubject_forGetGamesList()),
      untilDestroyed(this),
    ).subscribe((res) => {
      this.cd.detectChanges();
    });
  }

  // === FILTERS ==========================================
  setFilters(): void {
    // this.filtersS.currentPath = urlGames;
    const search = new ClassFilterInput({ typeFilter: 'search' });
    const dateRange = new ClassFilterDateRange();
    const competition = new ClassFilterDrop({
      typeFilter: 'competitions',
      arrayForDropdown: this.gameS.arrCompetition$.getValue(),
      // valueDrop: this.gameS.arrCompetition$.getValue()?.find(el => el.id === this.settingsRequestS.settings.competitions),
    });
    const assignStatus = new ClassFilterDrop({
      typeFilter: 'assignStatuses',
      arrayForDropdown: arrDropStatusAssign,
      multi: true,
    });
    this.filtersS.setFilters([search, dateRange, competition, assignStatus], true, true);
  }

  // === TABLE ======================================================
  readonly styleForGame_CANCELLED: IOutputObjStyles = { color: colorObj.red, opacity: 0.5 }; // or #F12B2C50
  setDataForTable(): void {
    this.meTableS.dataTable$ = this.dataTable$; // !!! создание ссылки. Чтобы можно было использовать в MeTableService
    this.meTableS.arrContent$ = this.arrContent$; // !!! создание ссылки. Чтобы можно было использовать в MeTableService
    const arrayCeilHeader: Array<ClassCeilTableHeader> = [
      new ClassCeilTableHeader({ text: 'Game', isChx: this.adminPermission }), // isChx: true
      new ClassCeilTableHeader({ text: 'Date & Time' }),
      new ClassCeilTableHeader({ text: 'Age & Level' }),
      new ClassCeilTableHeader({ text: 'Location' }),
      new ClassCeilTableHeader({ text: 'Teams' }),
      new ClassCeilTableHeader({ text: '' }),//notes
      new ClassCeilTableHeader({ text: 'Officials' }),
      new ClassCeilTableHeader({ sortBy: true }),
    ];
    const arrWidthCeilTable: Array<number> = [152, 187, 186, 209, 236, 55, 177, this.meTableS.minWidthFor_sortByCeil];
    this.meTableS.setArrTypeSorting(['byGameNumber', 'byDate', 'byGameLocation']); // !!! for-sorting===
    this.meTableS.setDataForTable(arrWidthCeilTable, arrayCeilHeader, this.widthPage, true);
  }

  popupGameNotes(item: ClassGame) {
    let width = '343px';
    if (this.deviceS.isDesktop)
      width = '480px';
    return this.dialog.open(GameNotesComponent, { width: width, data: { text: item.gameDescription, isReadOnly: true } });
  }

  deleteGameFromServer(game?: ClassGame, idx?: number): void {
    const arrIds: Array<string> = game ? [game?.id!] : this.meTableS.selectedItems_ids;
    if (!arrIds?.length) return;
    if (this.startRequest()) return;
    const dataPopup: IDataPopup = {
      width: '400px',
      textTitle: 'Delete Games',
      text: `Are you sure you want to delete the selected game${arrIds?.length > 1 ? 's' : ''} that’s irreversible?`,
      textBtnCancel: 'Go Back',
      textBtnApply: 'Delete',
      colorBtnApply: 'red',
    };
    const gamesForSendToServer = game ? [game] : this.meTableS.selectedItems;
    this.popupS.open(PopupConfirmComponent, dataPopup).then((res: Array<ClassGame>) => {
      if (!res) return this.endRequest();
      this.mainS.deleteGames(gamesForSendToServer)
        .pipe(
          switchMap(() => this.meTableS.getSubject_forGetGamesList()),
          untilDestroyed(this),
        )
        .subscribe((res) => {
          this.endRequest();
        });
    });
  }

  cancelGames(gameItem?: ClassGame, idx?: number): void {
    const dataPopup: IDataPopup = { games: gameItem ? [gameItem] : this.meTableS.arrContent?.filter(el => this.meTableS.selectedItems_ids.includes(el?.id!)) };

    from(this.popupS.open(PopupCancelGameComponent, dataPopup))
      .pipe(
        switchMap(() => this.meTableS.getSubject_forGetGamesList()),
        untilDestroyed(this),
      )
      .subscribe((res) => {
        if (!res) {
          this.meTableS.endLoadingTable();
          return;
        }
        this.meTableS.resetSelectedItems();
        this.cd.detectChanges();
      });
  }

  goToEditGame(game: ClassGame, idx: number, isClone = false): void {
    this.gameS.goToEditGame(game, isClone).then((res?: ClassGame) => {
      if (!res) return;
      isClone ? this.meTableS.addNewItem(res) : this.meTableS.replaceItemByIdx(res, idx);
      this.cd.detectChanges();
    });
  }

  openAnnouncementModal() {
    const gameIds = this.meTableS.selectedItems_ids;
    const { widthForPopup, heightForPopup } = calculatePopupSizes();
    this.popupS.open$(PopupNewAnnouncementGeneralComponent, {
      typeAnnouncement: 'BY_GAMES', width: widthForPopup,
      height: heightForPopup, gameIds,
    })
      .pipe(
        filter((res) => !!res), // Filter out falsy values
        map((res) => res?.value), // Use map with optional chaining to extract 'value'
        switchMap((announcementData) => {
          if (announcementData.draft === true) {
            return this.gameS.createDraftAnnouncement(announcementData, gameIds); // Return the observable directly
          } else {
            return this.gameS.createDraftAnnouncement(announcementData, gameIds).pipe(
              mergeMap((id) => { // Use mergeMap to handle the Subscription case
                return this.gameS.sendDraftAnnouncement(announcementData, id);
              }),
            );
          }
        }),
        untilDestroyed(this),
      )
      .subscribe(() => this.router.navigate(['/announcements']));
  }

  downloadExcel() {
    if (this.downloadDisabled) {
      return;
    }
    this.downloadDisabled = true;
    const filterSettings = this.settingsRequestS.settings;
    const options = { params: filterSettings };

    const fileName = this.isCurrentLink ? 'Notch Current Games Export.xlsx' : 'Notch Past Games Export.xlsx';


    if (this.meS.ADMIN) {
      this.mainS.exportAdminGamesToExcel(options, fileName).subscribe({
        next: () => {
          timer(10000).subscribe(() => {
            this.downloadDisabled = false;
          });
        },
        error: () => {
          this.downloadDisabled = false;
        }
      });
    } else {
      this.mainS.exportGamesToExcel(options, fileName).subscribe({
        next: () => {
          timer(10000).subscribe(() => {
            this.downloadDisabled = false;
          });
        },
        error: () => {
          this.downloadDisabled = false;
        }
      });
    }

  }
}
