<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              arrCurrentLink: groupProfileS.arrLinkObj_groupProfile,
              objCurrentLink: (groupProfileS.currentLink$|async)!,
              currentLink: (groupProfileS.currentLink$|async)?.currentLink!,
              group: (meS.meCurrentGroup$|async),
              arrContent: (adjustmentStatusesS.arrContent$|async)!,
              existChangesArray: (adjustmentStatusesS.arrContent$|async)!|existChangesArray:arrControls.getRawValue():keyOfClassAdjustmentStatus,
             } as obj' class='o-wrapPage' wrapPage [widthPage]='widthPage' [formGroup]='form'>

  <!--  === TITLE ===========================-->
  <div class='o-header'>
    <linkPage [arrLink]='obj.arrCurrentLink' [link]='obj.currentLink'
              (emit)='groupProfileS.navigateBy_currentLinkDrop($event)'></linkPage>

    <div *ngIf='!mainS.forProd' class='o-header__content'>
      <div class='o-header__title'>Adjustment Statuses</div>
      <div class='o-header__subTitle'>
        <span>Use these adjustment statuses to alter the default payments in a specific scenario</span>
        <meSvg svgName='question_grey&16' [matTooltip]='adjustmentStatusesS.matTooltip_for_subTitle'></meSvg>
      </div>
      <div class='o-header__filters'>
        <div class='o-header__actions'>
          <ng-container [ngTemplateOutlet]='btnTemplate'></ng-container>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf='!mainS.forProd; else comingSoonTemplate'>
    <!--  === TABLE ==============================-->
    <div class='o-content t-wrapperTable'>
      <tableHeader></tableHeader>
      <div formArrayName='arrControls' class='t-table'>
        <ng-container *ngFor='let item of arrControls?.controls; let idx=index; let first=first;  let last=last'>
          <ng-container *ngIf='{isDefaultAdjustmentStatus: (item.value|isDefaultAdjustmentStatus),
                            matTooltip_for_btn_if_inUse: item.value?.inUse ? matTooltip_for_btn_if_inUse : "",
                           } as objItem'>
            <div [formGroupName]='idx' class='t-table__item' itemTable [item]='item'>
              <div ceilTable [idxCeil]='0' class='t-table__item__wrapCeil'>
                <inputCtrl formControlName='name' placeholder='Name' [isFocus]='!idx'></inputCtrl>
              </div>
              <div ceilTable [idxCeil]='1' class='t-table__item__wrapCeil t-isCentered'>
                <!--            [w]='155'-->
                <inputCtrl formControlName='officialPay' placeholder='Official Pay' [showPercent]='true' [defaultAlwaysZero]='true'
                           [isNumber]='true' [isTextCenter]='true' [maxNumber]='100'></inputCtrl>
              </div>
              <div ceilTable [idxCeil]='2' class='t-table__item__wrapCeil t-isCentered'>
                <inputCtrl formControlName='assignorFee' placeholder='Assignor Fee' [showPercent]='true' [defaultAlwaysZero]='true'
                           [isNumber]='true' [isTextCenter]='true' [maxNumber]='100'></inputCtrl>
              </div>
              <div ceilTable [idxCeil]='3' class='t-table__item__wrapCeil t-isCentered'>
                <chx formControlName='active' text='Active' [matTooltip]='objItem.matTooltip_for_btn_if_inUse'
                     [cur]='!item.value?.inUse && !objItem.isDefaultAdjustmentStatus'></chx>
              </div>
              <div ceilTable [idxCeil]='4' class='t-table__item__wrapCeil t-isCentered'>
                <meSvg svgName='delete_square1&44' [matTooltip]='objItem.matTooltip_for_btn_if_inUse'
                       (emit)='item.value?.id ? deleteItem(item, idx) : arrControls.removeAt(idx)'
                       [disabled]='objItem.isDefaultAdjustmentStatus || item.value?.inUse'
                       [cur]='!objItem.isDefaultAdjustmentStatus && !item.value?.inUse'></meSvg>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <app-tableEmpty [tableEmpty]='undefined|getTableEmpty:"app-group-profile-adjustment-statuses"'></app-tableEmpty>
      </div>
    </div>

    <btnAndLine text='Add New +' bcg='newGreen' class='o-mg-t-32' (emit)='addNewCtrl()' [w]='widthPage'></btnAndLine>
  </ng-container>

  <ng-template #btnTemplate>
    <btn text='Save' bcg='blueDark' [btnWidth]='obj.mobile ? "100%":65'
         [disabled]='!obj.existChangesArray || reqPending || !form.valid'
         (click)='addAndUpdateAdjustmentStatuses()'></btn>
  </ng-template>

</div>

<ng-template #comingSoonTemplate>
  <coming-soon-mobile></coming-soon-mobile>
</ng-template>

