import { ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { TPath } from '@app/app.module';

export type TypePageProfile = Extract<TPath, 'meProfile' | 'meProfileOfficial' | 'profileUser'>;
export const rout_meProfile = 'meProfile'; // админ смотрит свой профиль
export const rout_meProfileOfficial = 'meProfileOfficial'; // судья смотрит свой профиль
export const rout_profileUser = 'profileUser'; // когда просматриваешь чужого судью (например со страницы /officials)
// export const rout_profileUser_contactInfo: 'profileUser/contactInfo' = `${rout_profileUser}/contactInfo`;

export interface IDropItemProfile extends ClassDrop {
  titleCase?: TLink_meProfile | TLink_meProfileOfficial | TLink_profileUser;
  upperCase?: TLink_meProfile_camelCase | TLink_meProfileOfficial_camelCase | TLink_profileUser_camelCase;
}

export type TLink_profile = TLink_meProfile | TLink_meProfileOfficial | TLink_profileUser;
export type TLink_profile_camelCase = TLink_meProfile_camelCase | TLink_meProfileOfficial_camelCase | TLink_profileUser_camelCase;

// === ADMIN =================================================================
export type TLink_meProfile = 'My Profile' | 'Account' | 'Billing' | 'System Preferences';
export type TLink_meProfile_camelCase = 'info' | 'edit' | 'account' | 'billing' | 'systemPreferences';

export const arrLinkObj_meProfile: Array<IDropItemProfile> = [
  { titleCase: 'My Profile', upperCase: 'info' },
  { titleCase: 'Account', upperCase: 'account' },
  // { title: 'Billing', value: 'billing' },
  // { title: 'System Preferences', value: 'systemPreferences' },
];

// === OFFICIAL =================================================================
export type TLink_meProfileOfficial = 'My Profile' |  'Certifications' | 'Account Settings' |'Questions' | 'System Preferences';
export type TLink_meProfileOfficial_camelCase = 'info' | 'edit' | 'certifications' | 'accountSettings' | 'questions' | 'systemPreferences';

export const arrLinkObj_meProfileOfficial: Array<IDropItemProfile> = [
  { titleCase: 'My Profile', upperCase: 'info' },
  { titleCase: 'Certifications', upperCase: 'certifications' },
  { titleCase: 'Account Settings', upperCase: 'accountSettings' },
  // { title: 'Questions', value: 'questions' },
  // { title: 'System Preferences', value: 'systemPreferences' },
];

// === another official => когда просматриваешь чужого судью (например со страницы /officials) ===========
export type TLink_profileUser = 'Contact Info' | 'Certifications' |'Availability';
export type TLink_profileUser_camelCase = 'contactInfo' | 'Certifications' | 'availability';

export const arrLinkObj_profileUser: Array<IDropItemProfile> = [
  { titleCase: 'Contact Info', upperCase: 'contactInfo' },
  { titleCase: 'Certifications', upperCase: 'certifications' },
  // { title: 'Availability', value: 'availability' },
];


