import { Pipe, PipeTransform } from '@angular/core';
import { MeService } from '@services/me.service';
import { TPath } from '@app/app.module';

@Pipe({ name: 'adminPermission', standalone: true })
export class AdminPermissionPipe implements PipeTransform {

  constructor(
    private meS: MeService,
  ) {
  }

  transform(page: TPath): boolean {
    return this.meS.adminPermission(page);
  }

}
