import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { SelectDateComponent } from '@components/date-time/select-date/select-date.component';
import { HelperClass } from '@classes/Helper-Classes';
import { UtilsService } from '@services/utils.service';
import { UntilDestroy } from '@ngneat/until-destroy';

export interface IStartEndDate {
  // startDate?: Date;
  // endDate?: Date;
  startDate?: string;
  endDate?: string;
}

@UntilDestroy()
@Component({
  selector: 'startEndDate',
  standalone: true,
  imports: [CommonModule, FieldComponent, SelectDateComponent],
  templateUrl: './start-end-date.component.html',
  styleUrls: ['./start-end-date.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartEndDateComponent extends HelperClass implements OnChanges, OnInit {
  @ViewChild('startDateRef') startDateRef?: SelectDateComponent;
  @ViewChild('endDateRef') endDateRef?: SelectDateComponent;

  @Input() startEndDate: IStartEndDate = { startDate: '', endDate: '' };
  @Input() disabled: boolean = false;
  @Output() emit = new EventEmitter<IStartEndDate>();

  constructor(
    // public dateTimeS: DateTimeService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.startEndDate?.currentValue) {
      setTimeout(() => {
        if (this.startDateRef && this.startEndDate.startDate) {
          // this.startDateRef.date = this.getDateInUTC(this.startEndDate.startDate);
          this.startDateRef.date = UtilsService.setOffset(this.startEndDate.startDate);
        }
        if (this.endDateRef && this.startEndDate.endDate) {
          // this.endDateRef.date = this.getDateInUTC(this.startEndDate.endDate);
          this.endDateRef.date = UtilsService.setOffset(this.startEndDate.endDate);
        }
      });
    }
  }

  ngOnInit() {
  }

  valueChangeDate(date: string, type: 'startDate' | 'endDate'): void {
    this.startEndDate[type] = date;
    this.emit.emit(this.startEndDate);
  }

}
