<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              tableEmpty: (meTableS.tableEmpty$|async)!,
              loading: (meTableS.loadingTable$|async),
              isShowGifLoading: (path|isShowGifLoading:isRequestAssignOfficial),
             } as obj' class='wrapperTableEmpty'>

  <ng-container *ngIf='!obj.loading'>
    <div *ngIf='tableEmpty.title' class='wrapperTableEmpty__title'>{{ tableEmpty.title }}</div>
    <div *ngIf='tableEmpty.subTitle' class='wrapperTableEmpty__subTitle'>{{ tableEmpty.subTitle }}</div>
    <ng-container *ngIf='tableEmpty.showBtn'>
      <!--  !!! not work <ng-content select='.{{class_ngContent_btn_for_nothingList}}'></ng-content>-->
      <ng-content select='.class_ngContent_btn_for_nothingList'></ng-content>
    </ng-container>
  </ng-container>

  <!--  <spinner *ngIf='obj.loading && !path'></spinner>-->
  <spinner *ngIf='obj.loading && !obj.isShowGifLoading'></spinner>

  <!--  === FOR page /openGames =================================-->
  <div *ngIf='obj.isShowGifLoading' class='wrapperTableEmpty__showGifLoading'>
    <img src='assets/calendar/calendar.gif' alt=''>
    <div class='wrapperTableEmpty__title'>
      <!--      Checking your existing assignments. Just a moment…-->
      {{ isRequestAssignOfficial ? 'We’re updating your availability!' : 'Checking your existing assignments. Just a moment…' }}
    </div>
    <!--        <div class='wrapperTableEmpty__subTitle'>-->
    <!--          {{ isRequestAssignOfficial ? 'One moment...' : 'We’re just getting everything ready in your open games! Just a moment...' }}-->
    <!--        </div>-->
  </div>

  <!--  <div *ngIf='obj.loading && showGifLoading' class='wrapperTableEmpty__showGifLoading'>-->
  <!--    <img src='assets/calendar/calendar.gif'>-->
  <!--    <div class='wrapperTableEmpty__title'>Checking your existing assignments!</div>-->
  <!--    <div class='wrapperTableEmpty__subTitle'>-->
  <!--      We’re just getting everything ready in your open games! Just a moment…-->
  <!--    </div>-->
  <!--  </div>-->

</div>