<ng-container *ngIf='{isCreateGroup: (meS.onboarding$|async)?.groupCreated} as obj'>
  <div class='wrapperMenuMeUser {{obj.isCreateGroup ? "":"wrapperNoCreateGroup"}}'>
    <div class='itemMenuMeUserMeInfo'>
      <photo [data]='meS.me' class='mePhoto' w='40'></photo>
      <div class='itemMenuMeUserMeInfo__nameRole'>
        <span class='itemMenuMeUserMeInfo__nameRole__name o-noWrap'>{{meS.me|getUserName}}</span>
        <span class='itemMenuMeUserMeInfo__nameRole__role'>{{meS.meRole|strCase:'capitalize'}}</span>
      </div>
    </div>

    <div (click)='goToMyProfile()' class='itemMenuMeUser'>
      <svgAndText text='Settings' leftSvgName='settings_1&16' typeText='text7-blueDark'></svgAndText>
    </div>

    <div *ngIf='meS.GROUP_ASSIGNOR' (click)='obj.isCreateGroup ? goToMyGroup():null' class='itemMenuMeUser'>
      <svgAndText text='Admin Tools' leftSvgName='settings_2&16' typeText='text7-blueDark'></svgAndText>
    </div>


    <div *ngIf='meS.OFFICIAL && !mainS.forProd' (click)="router.navigate(['myAssignors'])" class='itemMenuMeUser'>
      <svgAndText text='My Assignors' leftSvgName='user_users&16' typeText='text7-blueDark'></svgAndText>
    </div>

    <div (click)="router.navigate([_urlPayments_assignorFees])" class='itemMenuMeUser'
      style="border-top: 1px solid #D0D5DD;"  *ngIf='(meS.GROUP_ASSIGNOR || meS.SUB_ASSIGNOR) && !mainS.isBetaEnv'>
      <svgAndText text='Assignor Fees' leftSvgName='dollarBlue' typeText='text7-blueDark'></svgAndText>
    </div>

    <!-- NOT30-1208 -->
    <div (click)="router.navigate(['/myWalletOfficial'])" class='itemMenuMeUser'
    style="border-top: 1px solid #D0D5DD;"  *ngIf='(meS.GROUP_ASSIGNOR || meS.SUB_ASSIGNOR) && mainS.isBetaEnv'>
    <svgAndText text='Assignor Fees' leftSvgName='dollarBlue' typeText='text7-blueDark'></svgAndText>
    </div>

    <div (click)='doLogOut()' class='itemMenuMeUser o-cur-pointer-important'>
      <svgAndText text='Log out' leftSvgName='arrow_logout&16' typeText='text7-blueDark'></svgAndText>
    </div>
  </div>
</ng-container>