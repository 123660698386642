import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnComponent } from '@components/btn/btn.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';

// @UntilDestroy()
@Component({
  selector: 'app-payments-rewards',
  standalone: true,
  imports: [CommonModule, BtnComponent, BtnWrapComponent, SvgComponent],
  templateUrl: './payments-rewards.component.html',
  styleUrls: ['./payments-rewards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentsRewardsComponent {
  constructor(
    // public payS: PaymentService,
  ) {
  }

}
