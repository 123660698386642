<h2 class='header'>{{header}}</h2>
<ul class='games'>
  <li class='games__item'
      [class.cancelled]="game.gameStatus === 'CANCELLED'"
      *ngFor='let game of data'>
    <!-- gameDescription -->
    <div [ngStyle]="{ 'visibility': game.gameDescription && game.gameDescription.length>0 ? 'visible' : 'collapse' }"
         class="game-notes-button">
      <meSvg [asButton22]="false" class="gameNotesSvg" svgName='message-text-square'
             (emit)='gameNotesPopupService.popupGameNotesReadOnly(game)' adminPermission></meSvg>
    </div>

    <div class='games__item__number'>#{{game.gameNumber}}</div>

    <div class='games__item__row'>
      <h4 class='games__item__row__header'>Date & Time</h4>
      <dateFormatted [date]='game.date!'
                     type='date'
                     format='EE, MMM d, yyy'></dateFormatted>
      <dateFormatted [date]='game.date!'
                     type='time'
                     format='shortTime'></dateFormatted>
    </div>

    <div class='games__item__row'>
      <h4 class='games__item__row__header'>Age & Level</h4>
      <p class='games__item__row__details'>{{game|getAgeGenderLevel:'ClassGame'}}</p>
    </div>

    <div class='games__item__row'>
      <h4 class='games__item__row__header'>Location</h4>
      <p class='games__item__row__details'>
        <span>{{game|getLocNameCourtName}}</span>,
        <span [matTooltip]='game|matTooltipForLocation'>
          {{game.location?.address|getLocationString:['city', 'state']}}
        </span>
      </p>
    </div>

    <div class='games__item__row'>
      <h4 class='games__item__row__header'>Teams</h4>
      <p class='games__item__row__details'>
        <span>{{game.homeTeam?.teamName}} vs. </span><span>{{game.awayTeam?.teamName}}</span>
      </p>
    </div>

    <div class='games__item_controls'
         *ngIf="type === 'unaccepted' && game.gameStatus !== 'CANCELLED'">
      <button (click)="emitSetStatus($event, game, 'ACCEPTED')"
              class='games__item_controls__btn games__item_controls__btn--accept'>Accept
      </button>
      <button (click)="emitSetStatus($event, game, 'DECLINED')"
              class='games__item_controls__btn games__item_controls__btn--decline'>Decline
      </button>
    </div>

    <button *ngIf="type !== 'unaccepted'"
            routerLink='/{{urlGameInfo}}{{game.id}}'
            class='games__item_details'>
      View Full Details
    </button>
  </li>
</ul>