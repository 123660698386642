import { Pipe, PipeTransform } from '@angular/core';
import { ClassGame } from '@app/dir_group_assignor/games/game';
import { UtilsService } from '@services/utils.service';

@Pipe({ name: 'isPossibleGameToCancelled', standalone: true })
export class IsPossibleGameToCancelledPipe implements PipeTransform {

  // !!! разрешено ли сделать игру CANCELLED
  transform(game: ClassGame): boolean {
    return UtilsService.isPossibleGameToCancel(game);
  }

}
