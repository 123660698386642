<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              arrCurrentLink: (reportS.arrCurrentLinkSub$|async)!,
              objCurrentLink: (reportS.currentLink$|async)!,
              currentLink: (reportS.currentLink$|async)?.currentLink!,
              NEEDS_APPROVAL: reportS.currentLinkObj.currentLink?.upperCase==="NEEDS_APPROVAL",
              dataTable: (dataTable$|async)!,
              arrContent: (arrContent$|async),
              loadingTable: (meTableS.loadingTable$|async),
             } as obj' class='o-wrapPage' wrapPage [widthPage]='widthPage' cdkScrollable dataTable>

  <!--  === FILTERS & TITLE ===========================-->
  <div class='o-header'>
    <linkPage-searchFilter></linkPage-searchFilter>
    <div class='o-header__content'>
      <div class='o-header__title'>Post Game Reports</div>
      <div class='o-header__filters'>
        <app-filters></app-filters>
        <div class='o-header__actions'>
          <meSvg *ngIf='obj.arrContent?.length' svgName='download_3&44' (click)='null' [disabled]='true'></meSvg>
          <!--            !!! Миша сказал для прода скрыть эту кнопку (встречается в этом компоненте в 3х местах)-->
          <!--              убрал 21.05.24 btnWidth='121'  https://notch.atlassian.net/browse/NOT30-516 текст не влезал -->
          <btn *ngIf='obj.NEEDS_APPROVAL && meTableS.selectedItems?.length'
               [text]='meTableS.selectedItems?.length! > 1 ? textForBtn_approve+" All":textForBtn_approve' bcg='blueDark'
               (emit)='openPopupApprove()'
          ></btn>
        </div>
      </div>
      <app-filtersSelected></app-filtersSelected>
    </div>
  </div>

  <!--  === TABLE ==============================-->
  <div class='o-content t-wrapperTable'>
    <tableHeader></tableHeader>
    <div class='t-table'>
      <ng-container *ngFor='let item of obj.arrContent; let idx=index'>
        <div *ngIf='{ gameCANCELLED: item.game?.gameStatus == "CANCELLED" } as objReport'
             class='t-table__item' itemTable [item]='item' [urlNavigate]='urlReportInfo'
             [style]='objReport.gameCANCELLED ? styleForGame_CANCELLED : null'>
          <app-tableCeilChx *ngIf='obj.desktop' [item]='item' [text]='item.game?.gameNumber'></app-tableCeilChx>
          <!-- !!! this component app-tableBtnNumber ONLY FOR MOBILE for table -->
          <span *ngIf='obj.mobile' [style]='objReport.gameCANCELLED ? styleForGame_CANCELLED : null'>#{{ item.game?.gameNumber }}</span>
          <app-tableCeil [idxCeil]='1' [text1]='item.game?.date_EE_MMMd_yyy' [text2]='item.game?.date_shortTime_timezone'></app-tableCeil>
          <app-tableCeil [idxCeil]='2' [text1]='item.game?.ageGenderLevel'></app-tableCeil>
          <app-tableCeil [idxCeil]='3' [text1]='item.game?.competition?.competitionName || "TBD"'></app-tableCeil>
          <app-tableCeil [idxCeil]='4' [text1]='(item.game?.homeTeam?.teamName || "TBD") + " vs."'
                         [text2]='item.game?.awayTeam?.teamName || "TBD"' [commaForMobile]='false'
                         [className]='obj.mobile && ((item.game?.homeTeam?.teamName?.length ?? 0) + (item.game?.awayTeam?.teamName?.length ?? 0)) > 40 ? "teams_multiline" : "teams_singleline"'></app-tableCeil>
          <div ceilTable [idxCeil]='5' class='t-table__item__wrapCeil table_item_status'>
            <!--              убрал 21.05.24 [btnWidth]='meS.OFFICIAL ? 150:90' https://notch.atlassian.net/browse/NOT30-516 текст не влезал -->
            <div *ngIf='obj.mobile' class='status_header'>Status</div>
            <status *ngIf='obj.desktop ? item.status !== "NEEDS_APPROVAL" : obj.mobile' [status]='item.status' strCase='lower'></status>
            <btn *ngIf='item.status === "NEEDS_APPROVAL"' [text]='meS.OFFICIAL ? "Approval Pending" : textForBtn_approve'
                 [bcg]='objReport.gameCANCELLED ? "red" : "blueDark"' [btnHeight]='obj.desktop ? 32 : 36' [cursorDefault]='meS.OFFICIAL'
                 (click)='openPopupApprove(item)' stopPropagation fontSize='14'></btn>
          </div>

          <!--  === ACTIONS FOR ITEM TABLE ==========================================-->
          <app-tableCeilActions *ngIf='obj.desktop' [matMenuTriggerFor]='menuItemTable' stopPropagation></app-tableCeilActions>
          <mat-menu #menuItemTable='matMenu'>
            <ng-container [ngTemplateOutlet]='actionsForItemTable'></ng-container>
          </mat-menu>
          <btn *ngIf='obj.mobile' text='Manage' [bcg]='obj.desktop ? "blueDark" : (item.status == "NEEDS_APPROVAL" ? "white" : "blueDark")'
               (emit)='router.navigate([urlReportInfo,item?.id])' btnHeight='36'></btn>

          <ng-template #actionsForItemTable>
            <button [ngStyle]='{width: "200px"}' class='hiddenMatOption'></button>
            <button (click)='router.navigate([urlReportInfo,item?.id])'>View</button>
            <button *ngIf='item.status === "NEEDS_APPROVAL" && !meS.OFFICIAL' (click)='openPopupApprove(item)'>
              {{ textForBtn_approve }}
            </button>
            <!--                  <button (click)='goToEditGame(gameItem, idx)'>Edit</button>-->
            <!--                  <button *ngIf='!objGameItem.gameCANCELLED' (click)='router.navigate(["/assign"])'>Go to Assign</button>-->
            <!--                  <button [ngStyle]='{width: "201px"}' class='hiddenMatOption'></button>-->
          </ng-template>
        </div>
      </ng-container>
      <app-tableEmpty [tableEmpty]='filtersS.searchValue|getTableEmpty'></app-tableEmpty>
    </div>
  </div>

  <app-tableInfiniteLoading></app-tableInfiniteLoading>
  <!--    <pagination *ngIf='meTableS.showPagination' [data]='obj.dataTable' (emit)='emitSettingRequest($event,"pagination")'></pagination>-->
  <paginationWithSettings *ngIf='meTableS.showPagination'></paginationWithSettings>
</div>
