import { format } from 'date-fns';

export interface IAvailabilityTimes {
  start: string;
  end: string;
}

export class ClassAvailability {
  id?: string;
  enabled?: boolean;
  weekday?: string;
  times?: IAvailabilityTimes[];

  constructor(classAvailability?: ClassAvailability) {
    if (!classAvailability) return {};
    Object.entries(classAvailability)!.forEach((el) => {
      const key = el[0] as keyof ClassAvailability;
      this[key] = el[1];
    });

    if (!classAvailability.times) console.log('ClassAvailability.times:', classAvailability.times);
    this.times = classAvailability.times?.map((time: IAvailabilityTimes) => {
      if (!time.start || !time.end) {
        console.error('GET /api/core/officials/v1?userId=${userId} :', time, '  time.start :', time.start, '  time.end :', time.end);
        return time;
      } else {
        return {
          start: format(new Date(`01-01-01 ${time.start}`), 'HH:mm'),
          end: format(new Date(`01-01-01 ${time.end}`), 'HH:mm'),
        };
      }
    }) || [];
  }
}
