import { Pipe, PipeTransform } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import {
  IFormItemCompetitionsPayScales,
  IFormItemCompetitionsPayScalesCrew,
} from '@app/dir_group_assignor/competitions/components/competitions-pay-scales/competitions-pay-scales.component';

@Pipe({ name: 'getCrewArrayForPayScales', standalone: true })
export class GetCrewArrayForPayScalesPipe implements PipeTransform {

  transform(payScale: FormGroup<IFormItemCompetitionsPayScales>): Array<FormGroup<IFormItemCompetitionsPayScalesCrew>> {
    return (payScale.get('crew') as FormArray)?.controls as Array<FormGroup<IFormItemCompetitionsPayScalesCrew>>;
  }

}
