<div class='wrapperForgotPassword'>
    <img src='assets/bcgLogo/bcgLogo_textNotchGreen.svg'
        alt='notch'
        class='authLogo'>
    <ng-container *ngIf="!isEmailSended">
        <div class='title-block'>
            <div>
                <h2>Forgot Password?</h2>
                <p>No worries, we'll send you reset instructions.</p>
            </div>
        </div>
        <form [formGroup]='form'
            (ngSubmit)="submitResetPassword()">
            <field text='Email*'>
                <inputCtrl formControlName='email'
                    placeholder='Email address'
                    [isFocus]='true'>
                </inputCtrl>
            </field>

            <span class="error-message"
                *ngIf="errorMessage">{{errorMessage}}
            </span>

            <btn [bcg]="'newGreen'" text='Reset Password' [disabled]="form.invalid || isResendDisabled"></btn>
        </form>
    </ng-container>

    <div *ngIf="isEmailSended"
        class="check-email">
        <div>
            <h2>Check your email</h2>
            <p>We sent a password reset link to <b>{{userEmail}}</b></p>
        </div>

        <div class="resend-block">
            Didn't receive the email?
            <button [disabled]="isResendDisabled" (click)="submitResetPassword()">Click to resend</button>
        </div>
    </div>

    <div class="back-to-login">
        <a (click)="navigateToLogin()">
            <meSvg svgName='arrow_left&20' [h]='16' [w]='16'> </meSvg>
            Back to Log In
        </a>
    </div>
</div>