import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass } from '@classes/Helper-Classes';
import { MainService } from '@services/main.service';
import { IResponse } from '@models/response-and-request';
import { OtherService } from '@services/other.service';
import { BehaviorSubject } from 'rxjs';
import { ClassUser } from '@models/user';
import { PopupService } from '@services/popup.service';
import {
  IPopupInvitePeoples,
  PopupInvitePeoplesComponent,
} from '@components/__popup-windows/popup-invite-peoples/popup-invite-peoples.component';
import { OfficialsService } from '@app/dir_group_assignor/officials/officials.service';
import { switchMap } from 'rxjs/operators';
import { rout_profileUser } from '@app/profile/profile';
import { ProfileService } from '@app/profile/profile.service';
import { FiltersService } from '@components/filters/filters.service';
import { ClassFilterDrop, ClassFilterInput, IForClassForFilters, TAllInterfacesFilters } from '@components/filters/filters';
import { DeviceService } from '@services/device.service';
import { ClassGroupListItem } from '@models/IGroup';
import { ICurrentLink_officials } from '@app/dir_group_assignor/officials/officials';
import { ClassCeilTableHeader, IForClassForTable } from '@components/_table/meTable';
import { MeTableService } from '@components/_table/me-table.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { CdkScrollable } from '@angular/cdk/overlay';
import { DataTableDirective } from '@components/_table/directives/data-table.directive';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import {
  FiltersSelectedIconComponent,
} from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { BtnComponent } from '@components/btn/btn.component';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import { TableHeaderComponent } from '@components/_table/table-header/table-header.component';
import { ItemTableDirective } from '@components/_table/directives/item-table.directive';
import { TableCeilUserComponent } from '@components/_table/table-ceil-user/table-ceil-user.component';
import { TableCeilComponent } from '@components/_table/table-ceil/table-ceil.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { CeilTableDirective } from '@components/_table/directives/ceil-table.directive';
import { StatusComponent } from '@components/status/status.component';
import { StopPropagationDirective } from '@directives/stop-propagation.directive';
import { TableCeilActionsComponent } from '@components/_table/table-ceil-actions/table-ceil-actions.component';
import { MatMenuModule } from '@angular/material/menu';
import { TableEmptyComponent } from '@components/_table/table-empty/table-empty.component';
import { TableInfiniteLoadingComponent } from '@components/_table/table-infinite-loading/table-infinite-loading.component';
import { PaginationWithSettingsComponent } from '@components/__settingsRequest/pagination-with-settings/pagination-with-settings.component';
import { ComingSoonMobile } from '@app/dir_group_assignor/dashboard/components/coming-soon-mobile/coming-soon-mobile.component';
import { FormsModule } from '@angular/forms';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { RouterModule } from '@angular/router';
import { UtilsService } from '@services/utils.service';
import { ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { ForTestService } from '@classes/forTest';
import { LinkPageSearchFilterComponent } from '@components/__settingsRequest/link-page-search-filter/link-page-search-filter.component';
import { AdminPermissionDirective } from '@directives/admin-permission.directive';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';
import { GetTableEmptyForOfficialsPipe } from '@components/_table/pipes/get-table-empty-for-officials.pipe';
import { TooltipDirective } from '@directives/tooltip.directive';
import { AuthenticatorDirective, AuthenticatorService } from '@directives/authenticator-hide.directive';

@UntilDestroy()
@Component({
  selector: 'app-officials',
  standalone: true,
  imports: [
    AuthenticatorDirective,
    CommonModule, WrapPageDirective, CdkScrollable, DataTableDirective, DropdownComponent,
    FiltersSelectedIconComponent, FiltersComponent, BtnComponent, FiltersSelectedComponent, TableHeaderComponent, ItemTableDirective,
    TableCeilUserComponent, TableCeilComponent, SvgComponent, CeilTableDirective, StatusComponent, StopPropagationDirective,
    TableCeilActionsComponent, MatMenuModule, TableEmptyComponent, TableInfiniteLoadingComponent, PaginationWithSettingsComponent,
    ComingSoonMobile, FormsModule, RouterModule, LinkPageSearchFilterComponent, AdminPermissionDirective, GetTableEmptyPipe, GetTableEmptyForOfficialsPipe, TooltipDirective],
  templateUrl: './officials.component.html',
  styleUrls: ['./officials.component.scss'],
  providers: [FiltersService, MeTableService, SettingsRequestService], // for-filters=== for-table=== for-settings===
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// !!! IForClassForFilters for-filters=== // !!! IForClassForTable for-table===
export class OfficialsComponent extends HelperClass implements IForClassForFilters, IForClassForTable<ClassUser>, OnInit {
  dataTable$ = new BehaviorSubject<IResponse<ClassUser>>({}); // for-table===
  arrContent$ = new BehaviorSubject<Array<ClassUser>>([]); // for-table===
  readonly rout_profileUser = rout_profileUser;
  widthPage: 1300 | 696 = 1300;

  // readonly class_ngContent_btn_for_nothingList = class_ngContent_btn_for_nothingList;

  constructor(
    public authenticatorService: AuthenticatorService,
    public mainS: MainService,
    public profileS: ProfileService,
    public officialsS: OfficialsService,
    public otherS: OtherService,
    public popupS: PopupService,
    public deviceS: DeviceService,
    public filtersS: FiltersService, // for-filters===
    public meTableS: MeTableService<ClassUser>, // for-table===
    public settingsRequestS: SettingsRequestService, // for-settings===
    private forTestS: ForTestService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.officialsS.methodGroupList(null, 'get');
    this.setAllData();
  }

  ngOnInit() {
    this.subscribeToCurrentLink();
    this.subscribeToGroupList();
  }

  // !!! если есть в фильтрах компетишн, то обязательно надо в AppModule к роуту прикрутить CompetitionsForFiltersResolver
  setAllData(): void {
    this.setDataForTable(); // for-table===
    this.setFilters(); // for-filters===
    this.settingsRequestS.setAllData(this.isFirstLoadPageSub$); // for-settingsDynamicUrl=== создание ссылки, чтобы можно было использовать в сервисе
    this.officialsS.setCurrentLink_officials(this.settingsRequestS.settings.currentLink_officials); // for-currentLink===
    this.subscribeToSettings(); // for-settings===
  }

  // === SETTINGS ===============================
  subscribeToSettings(): void {
    this.settingsRequestS.settings$.pipe(
      // switchMap((res) => {
      //   return this.mainS.getListOfficials({ params: this.settingsRequestS.settingsForSendToServer }, this.settingsRequestS.settings.currentLink_officials!); // this.officialsS.currentLinkObj?.currentLink?.upperCase
      // }),
      switchMap((res) => this.meTableS.getSubject_getListOfficials()),
      untilDestroyed(this),
    ).subscribe((res) => {
      // this.meTableS.setDataAfterResponseServer(res, this.settingsRequestS.settings.typeEmitSetting); // for-table===
      // this.meTableS.endLoadingTable();
      this.cd.detectChanges();
    });
  }

  subscribeToCurrentLink(): void {
    this.officialsS.currentLink$.pipe(untilDestroyed(this)).subscribe((res: ICurrentLink_officials) => {
      if (!res?.currentLink) return;
      this.widthPage = res.currentLink?.upperCase === 'ACTIVE' ? 1300 : 696;
      this.arrFilters.forEach((el) => {
        if (el.typeFilter === 'search') {
          el = UtilsService.mergeObjects(el, this.filtersS.arrFilters.find(filter => filter.typeFilter === 'search')!);
          (el as ClassFilterInput).valueString = undefined;
          (el as ClassFilterInput).valueFromUrl = undefined;
        }
        const filterDrop = el as ClassFilterDrop;
        if (el.typeFilter === 'officialStatus') { // !!! for linkPage=='Pending'
          el = UtilsService.mergeObjects(el, this.filtersS.arrFilters.find(filter => filter.typeFilter === 'officialStatus')!);
          if (!this.officialsS.is_currentLink_pending) {
            filterDrop.valueDrop = undefined;
            filterDrop.valueFromUrl = undefined;
          }
          el.visible = this.officialsS.is_currentLink_pending;
        }
        if (el.typeFilter === 'ages') { // !!! for linkPage=='Active'
          el = UtilsService.mergeObjects(el, this.filtersS.arrFilters.find(filter => filter.typeFilter === 'ages')!);
          if (this.officialsS.is_currentLink_pending) {
            filterDrop.valueDrop = undefined;
            filterDrop.valueFromUrl = undefined;
          }
          el.visible = !this.officialsS.is_currentLink_pending;
        }
        if (el.typeFilter === 'officialList') { // !!! for linkPage=='Active'
          el = UtilsService.mergeObjects(el, this.filtersS.arrFilters.find(filter => filter.typeFilter === 'officialList')!);
          if (this.officialsS.is_currentLink_pending) {
            filterDrop.valueDrop = undefined;
            filterDrop.valueFromUrl = undefined;
          }
          el.visible = !this.officialsS.is_currentLink_pending;
        }
      });
      this.filtersS.resetSelectedFilters(this.arrFilters); // !!! при переключении вкладки нужно resetFilters, потому что для этих вкладок разные фильтры
      this.settingsRequestS.setSettingsByDefault({ sort: this.settingsRequestS.settings.sort });
      this.setDataForTable(); // for-table===
    });
  }

  subscribeToGroupList(): void {
    this.officialsS.groupList$.pipe(untilDestroyed(this)).subscribe((arrGroupListItem: Array<ClassGroupListItem>) => {
      if (!arrGroupListItem?.length) return;
      let currentFilter_officialList = this.filtersS.arrFilters.find(filter => filter.typeFilter === 'officialList') as ClassFilterDrop;
      const arrId_officialList_fromQueryParams = currentFilter_officialList?.valueFromUrl?.split(',');
      const arrDrop_officialList_fromQueryParams = arrGroupListItem?.filter((arrGroupListItem: ClassGroupListItem) => arrId_officialList_fromQueryParams?.includes(arrGroupListItem.id!)) as Array<ClassDrop>;
      const officialList = new ClassFilterDrop({
        typeFilter: 'officialList',
        arrayForDropdown: arrGroupListItem,
        multi: true,
        disabled: !arrGroupListItem?.length,
        forTest: ' subscribeToGroupList ',
        valueDrop: arrDrop_officialList_fromQueryParams,
        visible: !this.officialsS.is_currentLink_pending,
      }); // 'active'
      currentFilter_officialList = UtilsService.mergeObjects(currentFilter_officialList, officialList);
      this.filtersS.updateAllFilters([currentFilter_officialList], '  subscribeToGroupList ');
    });
  }

  // === FILTERS ==========================================
  arrFilters: Array<TAllInterfacesFilters> = [];

  setFilters(): void {
    const search = new ClassFilterInput({ typeFilter: 'search', forTest: '  setFilters ' });
    const ages = new ClassFilterDrop({
      typeFilter: 'ages',
      visible: !this.officialsS.is_currentLink_pending,
      forTest: '  setFilters ',
    }); // 'active' arrayForDropdown: agesItems
    // !!! NO DELETE потом надо будет вернуть это
    // const competitionPreferences = new ClassFilterDrop({ typeFilter: 'competitionPreferences', disabled: true }); // 'active'
    // const location = new ClassFilterDrop({ typeFilter: 'location', disabled: true }); // 'active'
    const officialList = new ClassFilterDrop({
      typeFilter: 'officialList',
      arrayForDropdown: this.officialsS.groupList,
      multi: true,
      disabled: true,
      visible: !this.officialsS.is_currentLink_pending,
    }); // 'active'
    const officialStatus = new ClassFilterDrop({
      typeFilter: 'officialStatus',
      disabled: true,
      visible: this.officialsS.is_currentLink_pending,
    }); // 'pending'
    this.arrFilters = [search, officialStatus, ages, officialList];
    this.filtersS.setFilters(this.arrFilters, true, true);
  }

  // === TABLE ======================================================
  setDataForTable(): void {
    const pageIsActive = this.officialsS.is_currentLink_active;
    this.meTableS.dataTable$ = this.dataTable$; // !!! создание ссылки. Чтобы можно было использовать в MeTableService
    this.meTableS.arrContent$ = this.arrContent$; // !!! создание ссылки. Чтобы можно было использовать в MeTableService
    const arrayCeilHeader__pageActive: Array<ClassCeilTableHeader> = [
      new ClassCeilTableHeader({ text: 'Name', isChx: this.authenticatorService.isAllow(['GROUP_ASSIGNOR', 'SUB_ASSIGNOR']) }),
      new ClassCeilTableHeader({ text: 'Age', isCentered: true }),
      new ClassCeilTableHeader({ text: 'Phone' }),
      new ClassCeilTableHeader({ text: 'City / State' }),
      new ClassCeilTableHeader({ text: 'Certified', isCentered: true }),
      new ClassCeilTableHeader({ text: 'Last Sign In' }), // было Last Active
      new ClassCeilTableHeader({ sortBy: true }),
    ];
    const arrayCeilHeader__pagePending: Array<ClassCeilTableHeader> = [
      new ClassCeilTableHeader({ text: 'Recipient', isChx: true }),
      new ClassCeilTableHeader({ text: 'Status' }),
      new ClassCeilTableHeader({ text: '' }),
    ];
    const arrWidthCeilTable__pageActive: Array<number> = [416, 108, 193, 193, 138, 154, this.meTableS.minWidthFor_sortByCeil]; // !!! ширина для каждой ячейки для Desktop => сумма должна совпадать с this.widthPage
    const arrWidthCeilTable__pagePending: Array<number> = [416, 182, this.meTableS.minWidthFor_sortByCeil]; // !!! ширина для каждой ячейки для Desktop => сумма должна совпадать с this.widthPage
    this.meTableS.setArrTypeSorting(['byName']); // !!! for-sorting===
    pageIsActive ? this.meTableS.setDataForTable(arrWidthCeilTable__pageActive, arrayCeilHeader__pageActive, this.widthPage, true)
      : this.meTableS.setDataForTable(arrWidthCeilTable__pagePending, arrayCeilHeader__pagePending, this.widthPage, true);
  }

  // === OTHER ACTIONS ============================
  inviteOfficials(): void {
    const dataPopup: IPopupInvitePeoples = {
      width: '480px',
      path: 'officials',
    };
    this.popupS.open(PopupInvitePeoplesComponent, dataPopup).then((res: any) => {
      if (!res) return;
    });
  }

  // for page Active
  deleteOfficial(idOfficial?: string): void {
    if (!idOfficial) return;
    if (this.startRequest()) return;
    this.mainS.deleteOfficial(idOfficial).toPromise()
      .then((res: any) => this.meTableS.deleteItemById(idOfficial))
      .catch((err) => {
      })
      .finally(() => this.endRequest());
  }

  // for page Pending
  deleteOfficialsForPagePending(idOfficial?: string): void {
    if (this.startRequest()) return;
    const arrIds: Array<string> = idOfficial ? [idOfficial] : this.meTableS.selectedItems_ids;
    this.mainS.deleteOfficialsForPagePending(arrIds).toPromise()
      .then((res: any) => this.meTableS.deleteArrItemsById(arrIds))
      .catch((err) => {
      })
      .finally(() => this.endRequest());
  }

  generateTooltip(userItem: ClassUser): string {
    const licenseName = userItem.mainLicense?.license?.name || 'No license';
    const experience = userItem.organizationUserData?.refereeExperience !== undefined ? `${userItem.organizationUserData.refereeExperience}` : 'No experience';
    return `<ul><li> Referee Grade: ${licenseName}</li><li> Referee Experience: ${experience}&nbspyears</li></ul>`;
  }

}
