import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { HelperClass } from '@classes/Helper-Classes';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { CompetitionService } from '@app/dir_group_assignor/competitions/competition.service';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { PopupCustomizeInvitationComponent } from '@components/popup-customize-invitation/popup-customize-invitation.component';
import { IDataPopup, PopupService } from '@services/popup.service';
import { BtnAddAnotherComponent } from '@components/btn-add-another/btn-add-another.component';
import {
  CompetitionsNavigationComponent,
} from '@app/dir_group_assignor/competitions/helperComponentsCompetitions/competitions-navigation/competitions-navigation.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MainService } from '@services/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  arrUserRoleDrop_forInvite,
  ClassCompetitionUser,
  IResponseCompetitionUsers,
} from '@app/dir_group_assignor/competitions/ClassCompetition';
import { ClassUser, TUserRoleDrop } from '@models/user';
import { ExcludeStrPipe } from '@pipes/exclude-str.pipe';
import {
  CompetitionsListUsersComponent,
} from '@app/dir_group_assignor/competitions/components/competitions-users/competitionsListUsers/competitions-list-users.component';
import { BehaviorSubject, delay, finalize, of, switchMap } from 'rxjs';
import {
  DeleteItemForCompetitionsComponent,
} from '@app/dir_group_assignor/competitions/helperComponentsCompetitions/delete-item-for-competitions/delete-item-for-competitions.component';
import { CheckExistUsersPipe } from '@app/dir_group_assignor/competitions/components/competitions-users/pipes/check-exist-users.pipe';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { ErrorComponent } from '@components/__info_text_message_error_warning/error/error.component';
import { GetWidthMatMenuPipe } from '@pipes/get-width-mat-menu.pipe';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CopyLinkComponent } from '@components/copy-link/copy-link.component';
import { OtherService } from '@services/other.service';
import { EMAIL_REGEXP } from '@classes/CustomValidators';
import { ForTestService } from '@classes/forTest';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';

interface IFormCompetitionsUsers {
  arrControls?: FormArray<FormGroup<IFormItemCompetitionsUsers>>;
  invitationText: FormControl<string>;
}

interface IFormItemCompetitionsUsers {
  id: FormControl<string>;
  competitionId: FormControl<string>;
  name: FormControl<string>;
  // role: FormControl<string>;
  roleDrop: FormControl<TUserRoleDrop>;
  // groupCurrent: FormControl<string>;

  // !!! после того как в дропдауне выбрал юзера, то надо сюда записать userId & email
  userId: FormControl<string>;
  email: FormControl<string>;
}

@UntilDestroy()
@Component({
  selector: 'competitionsUsers',
  standalone: true,
  imports: [CommonModule, FieldComponent, ReactiveFormsModule, InputCtrlComponent, SvgComponent, SvgAndTextComponent, BtnWrapComponent, BtnComponent, DropFormCtrlComponent, BtnAddAnotherComponent, CompetitionsNavigationComponent, MatProgressSpinnerModule, ExcludeStrPipe, CompetitionsListUsersComponent, DeleteItemForCompetitionsComponent, CheckExistUsersPipe, MatMenuModule, ErrorComponent, GetWidthMatMenuPipe, DropdownComponent, CopyLinkComponent],
  templateUrl: './competitions-users.component.html',
  styleUrls: ['./competitions-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetitionsUsersComponent extends HelperClass implements OnInit {
  form!: FormGroup<IFormCompetitionsUsers>;
  readonly arrUserRoleDrop_forInvite = arrUserRoleDrop_forInvite;
  pageUsersSub$ = new BehaviorSubject<'newUsers' | 'listUsers'>('listUsers'); // !!! было newUsers 11/04/24

  get pageUsers(): 'newUsers' | 'listUsers' {
    return this.pageUsersSub$.getValue();
  }

  // когда покинул инпут, вызывается метод поиска.
  // если в ответ пришел массив юзеров, то записываю в этот массив, чтобы отобразить дропдаун
  // в дропдауне выбирает юзера. Нажимает сохранить. И при отправке на сервер надо добавить userId из объекта юзера(id) который получил при поиске из массива
  usersForDropDown: Array<ClassCompetitionUser & ClassUser> = [];
  // usersForDropDown: Array<ClassCompetitionUser> = [];
  searchText = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    public competitionS: CompetitionService,
    public popupS: PopupService,
    private mainS: MainService,
    private apiCompetitionS: ApiCompetitionService,
    private otherS: OtherService,
    private route: ActivatedRoute,
    private router: Router,
    private forTestS: ForTestService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.createForm();
    // this.route.params.pipe(untilDestroyed(this)).subscribe((params) => this.competitionS.checkParams(params));
  }

  ngOnInit() {
    // this.subscribeToCompetition();
  }

  openPopupCustomizeInvitation(): void {
    // const dataPopup: IDataPopup_forCustomizeInvitation = {
    const dataPopup: IDataPopup = {
      width: '480px',
      // path: 'competitions/users',
      // userRoleUpperCase: 'ADMIN', // !!! в попап окне есть copy link для получения ссылкы для регистрации. И в запросе на сервер надо передавать роль юзера
      text: this.form.controls?.invitationText?.value || '',
      // competition: this.competitionS.competition,
    };

    this.popupS.open(PopupCustomizeInvitationComponent, dataPopup).then((res: any) => {
      if (typeof res !== 'string') return;
      this.form.controls?.invitationText?.patchValue(res);
      this.cd.detectChanges();
    });
  }

  // === FORM ==============
  private createForm(): void {
    this.form = this.formBuilder.group({ arrControls: this.formBuilder.array([]), invitationText: [''] });
    this.arrControls.push(this.formBuilder.group({
      competitionId: this.competitionS.competition?.id || '',
      id: '',
      name: ['', Validators.required],
      roleDrop: ['', Validators.required],
      userId: '',
      email: '',
    }));
    // this.arrControls.controls?.forEach((el, idx) => {
    //   console.log('el :', idx, el.value)
    // })

    // !!! from subscribeToCompetition inside this.competitionS.getCompetitionUsers()
    if (!this.arrControls.controls?.length) this.addNew(undefined, ' subscribeToCompetition ');
  }

  get arrControls(): FormArray<FormGroup<IFormItemCompetitionsUsers>> {
    return this.form?.controls?.arrControls!;
  }

  addNew(item?: ClassCompetitionUser, forTest?: string): void {
    const newFormGroup: FormGroup<IFormItemCompetitionsUsers> = this.formBuilder.group({
      id: [item?.id || ''],
      competitionId: [item?.competitionId || ''],
      name: [item?.name || '', Validators.required],
      // role: [item?.role || '', Validators.required],
      roleDrop: [item?.roleDrop || '', Validators.required],
      // groupCurrent: [item?.groupCurrent || ''],
      userId: [''],
      email: [''],
    });
    this.arrControls.push(newFormGroup);
    // console.log('this.arrControls.controls[0] :', forTest, this.arrControls.controls[0]);
  }

  addNewUserFromDropDown(itemCtrl: FormGroup<IFormItemCompetitionsUsers>, itemDrop: ClassUser): void {
    // console.log('itemCtrl :', itemCtrl?.value);
    // console.log('itemDrop :', itemDrop);
    // interface IFormItemCompetitionsUsers {
    //   id: FormControl<string>;
    //   competitionId: FormControl<string>;
    //   name: FormControl<string>;
    //   roleDrop: FormControl<TUserRoleDrop>;
    //   // !!! после того как в дропдауне выбрал юзера, то надо сюда записать userId & email
    //   userId: FormControl<string>;
    //   email: FormControl<string>;
    // }
    const propertyName_isEmail = EMAIL_REGEXP.test(itemDrop.name || '');
    // console.log('propertyName_isEmail :', propertyName_isEmail)
    if (propertyName_isEmail) itemDrop.email = itemDrop.name;
    delete itemDrop.name;

    const newUserFromDrop = {
      competitionId: this.competitionS.competition?.id || '',
      id: itemDrop.id,
      userId: itemDrop.userId,
      email: itemDrop.email,
      // name: itemDrop.firstName || itemDrop.name,
      name: itemDrop.email,
      roleDrop: itemCtrl.value.roleDrop,
    };
    // console.log('newUserFromDrop :', newUserFromDrop);
    // const isEmail = EMAIL_REGEXP.test(sendObj.users[].email || sendObj.users[].name || '');
    itemCtrl.patchValue(newUserFromDrop);
    // console.log('itemCtrl :', itemCtrl?.value);
    this.searchText = '';
    this.cd.detectChanges();
    // this.cd.markForCheck();
  }

  // !!! если есть matMenuTrigger то это страница для приглашения юзеров. Иначе это страница списка юзеров
  searchUsersInCompetition(searchText: string, matMenuTrigger?: MatMenuTrigger): void {
    // console.log('searchText :', searchText);
    // console.log('matMenuTrigger :', matMenuTrigger);
    // if (searchText?.trim()?.length < 3) return;
    this.searchText = searchText;
    if (!searchText?.trim()) return;
    of(searchText).pipe(
      delay(300),
      switchMap((res) => {
        // console.log('switchMap :', res);
        return this.apiCompetitionS.searchUsersInCompetition(searchText, this.competitionS.competition.id!);
      }),
      untilDestroyed(this),
    ).subscribe((res) => {
      // console.log('searchUsersInCompetition :', res)
      if (matMenuTrigger) { // это страница для приглашения юзеров
        matMenuTrigger?.closeMenu();
        // this.usersForDropDown = content as Array<ClassCompetitionUser & ClassUser>;
        this.usersForDropDown = res;
        this.cd.detectChanges(); // !!! не удалять
        matMenuTrigger?.openMenu();
      } else { // это страница списка юзеров
        this.competitionS.competition.users = res;
      }
      // console.log('users?.length :', this.competitionS.users?.length)
    });
    // this.methodCompetitionUsers('get', searchText, matMenuTrigger);
  }

  inviteUsersInCompetition(): void {
    if (this.startRequest()) return;
    const sendObj: IResponseCompetitionUsers = { users: this.form?.getRawValue().arrControls };
    if (this.form.controls?.invitationText?.value) sendObj.invitationText = this.form.controls?.invitationText?.value;
    this.competitionS.addCompetitionIdBeforeSendToServer(sendObj.users!);

    // !!! происходит проверка => строка из поля "Name or Email" это емайл или name
    sendObj.users?.forEach((el) => {
      // const isEmail = EMAIL_REGEXP.test(sendObj.users[].email || sendObj.users[].name || '');
      // const propertyEmail_isEmail = EMAIL_REGEXP.test(el.email || '');
      const propertyName_isEmail = EMAIL_REGEXP.test(el.name || '');
      if (propertyName_isEmail) el.email = el.name;
      delete el.name;
    });

    // console.log('sendObj.users :', sendObj.users)
    // this.endRequest();
    // return;

    this.competitionS.inviteUsersInCompetition(sendObj)
      .pipe(
        finalize(() => this.endRequest()),
        untilDestroyed(this),
      )
      .subscribe((res) => {
        this.pageUsersSub$.next('listUsers');
      });
  }

  // !!! перенести в PIPE
  checkExistError(itemCtrl: FormGroup<IFormItemCompetitionsUsers>): boolean {
    // const isEmail = EMAIL_REGEXP.test(itemCtrl?.value?.email || itemCtrl?.value?.name || '');
    // const isEmail = EMAIL_REGEXP.test(itemCtrl?.value?.email || itemCtrl?.value?.name || '');
    const isEmail = EMAIL_REGEXP.test(itemCtrl?.value?.name || '');
    // console.log('isEmail :', isEmail)
    if (isEmail) return false;
    // console.log('usersForDropDown?.length :', this.usersForDropDown?.length)
    // console.log('searchText :', this.searchText)
    // console.log('userId :', itemCtrl?.controls?.userId?.value)
    const result = !this.usersForDropDown?.length && !!this.searchText && !itemCtrl?.controls?.userId?.value;
    // console.log('itemCtrl :', itemCtrl?.value,  ' isEmail:', isEmail, ' result:', result)
    // console.log('name :', itemCtrl?.value?.name ,  'email:' , itemCtrl?.value?.email,   ' result:', result)
    return result;
    // return !this.usersForDropDown?.length && !!this.searchText && !itemCtrl?.controls?.userId?.value;
  }

  // === other =======================
  openPageNewUser(): void {
    this.pageUsersSub$.next('newUsers');
    this.createForm();
    this.searchText = '';
    this.cd.detectChanges();
  }

}

// subscribeToCompetition(): void {
//   this.competitionS.competition$.pipe(untilDestroyed(this)).subscribe((competition?: ClassCompetition) => {
//     if (!competition?.id || this.competitionS.isLoad.users) return;  // уже был загружен список юзеров
//     this.competitionS.getCompetitionUsers().toPromise().then((res?: Array<ClassCompetitionUser>) => {
//       if (!this.arrControls.controls?.length) this.addNew(undefined, ' subscribeToCompetition ');
//       // this.checkPageUsers(); // !!! пока не удалять
//       this.cd.detectChanges();
//     });
//   });
// }