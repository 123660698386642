<div class="show-only-on-desktop">
  <div class='o-wrapPage o-wrapPage--noMarginTopAndNoPadding' wrapPage [widthPage]='1200'>

    <!--  === FILTERS & TITLE ===========================-->
    <div class='o-header'>
      <div class='o-header__content'>
        <div class='o-header__title'>Reports</div>
      </div>
    </div>

    <div class='o-content'>
      <react-wrapper embeddableId='99c2b429-70df-4d56-9691-a3d7090745fb' [setLoadFinished]="onFinishLoadFilters"
        [onVariableUpdated]="onVariableUpdated" style="z-index: 9;"></react-wrapper>
      <div class='wrapperTab'>
        <ng-container *ngFor='let tab of tabs'>

          <div class="itemTab" [class.wrapperTab--activeItem]='(tab === selectedTab)' (click)='onClickItemTab(tab)'>
            {{tab.tabName}}
          </div>
        </ng-container>
      </div>
      <react-wrapper [embeddableId]="selectedTab.embeddableId" [isLoading]="!filtersReady"
        [variables]="variables"></react-wrapper>
    </div>
  </div>
</div>
<coming-soon-mobile class="show-only-on-mobile"></coming-soon-mobile>