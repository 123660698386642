import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainService } from '@services/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceService } from '@services/device.service';
import { ProfileService } from '@app/profile/profile.service';
import { GroupProfileService } from '@app/group-profile/group-profile.service';
import { MeService } from '@services/me.service';
import { OtherService } from '@services/other.service';
import { UntypedFormBuilder } from '@angular/forms';
import { LocationService } from '@components/__blocks/location/location.service';
import { FiltersService } from '@components/filters/filters.service';
import { MeTableService } from '@components/_table/me-table.service';
import { ClassUser } from '@models/user';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { PopupService } from '@services/popup.service';
import { HelperClass } from '@classes/Helper-Classes';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { CdkScrollable } from '@angular/cdk/overlay';
import { DataTableDirective } from '@components/_table/directives/data-table.directive';
import { LinkPageSearchFilterComponent } from '@components/__settingsRequest/link-page-search-filter/link-page-search-filter.component';
import { ComingSoonMobile } from '@app/dir_group_assignor/dashboard/components/coming-soon-mobile/coming-soon-mobile.component';

@UntilDestroy()
@Component({
  selector: 'app-group-profile-csv-import-history',
  standalone: true,
  imports: [CommonModule, WrapPageDirective, CdkScrollable, DataTableDirective, LinkPageSearchFilterComponent, ComingSoonMobile],
  templateUrl: './group-profile-csv-import-history.component.html',
  styleUrls: ['./group-profile-csv-import-history.component.scss'],
  providers: [FiltersService, MeTableService, SettingsRequestService], // for-filters=== for-table=== for-settings===
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// !!! IForClassForFilters for-filters=== // !!! IForClassForTable for-table===
// IForClassForFilters, IForClassForTable<ClassUser>,
export class GroupProfileCsvImportHistoryComponent extends HelperClass implements OnInit {

  // readonly rout_profileUser = rout_profileUser;
  readonly widthPage = 1131;

  constructor(
    private mainS: MainService,
    public router: Router,
    public route: ActivatedRoute,
    public deviceS: DeviceService,
    public profileS: ProfileService,
    public groupProfileS: GroupProfileService,
    public meS: MeService,
    public otherS: OtherService,
    private formBuilder: UntypedFormBuilder,
    public locationS: LocationService,
    public filtersS: FiltersService, // for-filters===
    public meTableS: MeTableService<ClassUser>, // for-table===
    public settingsRequestS: SettingsRequestService, // for-settings===
    private gameS: GameService, // !!! перенести потом массив компетишнов в CompetitionService
    private popupS: PopupService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    // this.groupProfileS.setCurrentLink_groupProfile('csvImportHistory'); // for-currentLink===
    // this.setAllData();
  }

  ngOnInit() {
  }

}
