import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';

@Component({
  selector: 'btnBack',
  standalone: true,
  imports: [CommonModule, SvgComponent],
  templateUrl: './btn-back.component.html',
  styleUrls: ['./btn-back.component.scss']
})
export class BtnBackComponent {
  @Input() text?: string;
}
