<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              arrCurrentLink: groupProfileS.arrLinkObj_groupProfile,
              objCurrentLink: (groupProfileS.currentLink$|async)!,
              currentLink: (groupProfileS.currentLink$|async)?.currentLink!,
              group: (meS.meCurrentGroup$|async),
             } as obj' class='o-wrapPage'
     wrapPage [widthPage]='widthPage' cdkScrollable dataTable>
  <!--  === FILTERS & TITLE ===========================-->
  <div class='o-header'>
    <linkPage-searchFilter [currentLinkDrop]='obj.currentLink'></linkPage-searchFilter>
<!--    <div class='o-header__content'>-->
<!--      <div class='o-header__title'>User Management</div>-->
<!--      <div class='o-header__filters'>-->
<!--        <app-filters></app-filters>-->
<!--        <div class='o-header__actions'>-->
<!--          <btn text='New User +' bcg='newGreen' [btnWidth]='obj.mobile ? "100%":118' [btnHeight]='obj.mobile ? 36:""'-->
<!--               (click)='inviteUserToGroup()'></btn>-->
<!--        </div>-->
<!--      </div>-->
<!--      <app-filtersSelected></app-filtersSelected>-->
<!--    </div>-->
  </div>

  <coming-soon-mobile></coming-soon-mobile>
</div>
