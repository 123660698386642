import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass } from '@classes/Helper-Classes';
import { DeviceService } from '@services/device.service';
import { MainService } from '@services/main.service';
import { IResponse } from '@models/response-and-request';
import { Router } from '@angular/router';
import { arrDropStatusAssign, ClassGame } from '@app/dir_group_assignor/games/game';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { MeService } from '@services/me.service';
import { BehaviorSubject, switchMap } from 'rxjs';
import { FiltersService } from '@components/filters/filters.service';
import { ClassCeilTableHeader, IForClassForTable } from '@components/_table/meTable';
import { ClassFilterDateRange, ClassFilterDrop, ClassFilterInput, IForClassForFilters } from '@components/filters/filters';
import { urlGameInfo, urlMyGames, urlOpenGames } from '@app/app.module';
import { MeTableService } from '@components/_table/me-table.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { CdkScrollable } from '@angular/cdk/overlay';
import { DataTableDirective } from '@components/_table/directives/data-table.directive';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import {
  FiltersSelectedIconComponent,
} from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import { TableHeaderComponent } from '@components/_table/table-header/table-header.component';
import { TableCeilChxComponent } from '@components/_table/table-ceil-chx/table-ceil-chx.component';
import { ItemTableDirective } from '@components/_table/directives/item-table.directive';
import { GetMeGoFromGamePipe } from '@pipes/gameOfficial/get-me-go-from-game.pipe';
import { IsPendingGoForOfficialPipe } from '@pipes/gameOfficial/is-pending-go-for-official.pipe';
import { TableCeilComponent } from '@components/_table/table-ceil/table-ceil.component';
import { TableBtnNumberComponent } from '@components/_table/table-btn-number/table-btn-number.component';
import { PhotosComponent } from '@components/__svg_img/photos/photos.component';
import { CeilTableDirective } from '@components/_table/directives/ceil-table.directive';
import { StopPropagationDirective } from '@directives/stop-propagation.directive';
import { TableCeilActionsComponent } from '@components/_table/table-ceil-actions/table-ceil-actions.component';
import { MatMenuModule } from '@angular/material/menu';
import { TableInfiniteLoadingComponent } from '@components/_table/table-infinite-loading/table-infinite-loading.component';
import { TableEmptyComponent } from '@components/_table/table-empty/table-empty.component';
import { PaginationWithSettingsComponent } from '@components/__settingsRequest/pagination-with-settings/pagination-with-settings.component';
import { FormsModule } from '@angular/forms';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { LinkPageSearchFilterComponent } from '@components/__settingsRequest/link-page-search-filter/link-page-search-filter.component';
import { AdminPermissionDirective } from '@directives/admin-permission.directive';
import { BtnComponent } from '@components/btn/btn.component';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';
import { MatDialog } from '@angular/material/dialog';
import { GameNotesComponent } from '@app/dir_group_assignor/games/components/game-notes/game-notes.component';
import { GameNotesPopupService } from '@services/game-notes-popup.service';
import { TableCellGameNotesComponent } from '@components/_table/table-cell-game-notes/table-cell-game-notes.component';

@UntilDestroy()
@Component({
  selector: 'app-my-games',
  standalone: true,
  imports: [CommonModule, WrapPageDirective, CdkScrollable, DataTableDirective, DropdownComponent,
    FiltersSelectedIconComponent, FiltersComponent, FiltersSelectedComponent, TableHeaderComponent, TableCeilChxComponent,
    ItemTableDirective, GetMeGoFromGamePipe, IsPendingGoForOfficialPipe, TableCeilComponent, TableBtnNumberComponent, PhotosComponent,
    CeilTableDirective, StopPropagationDirective, TableCeilActionsComponent, MatMenuModule, TableInfiniteLoadingComponent,
    TableEmptyComponent, PaginationWithSettingsComponent, FormsModule, SvgComponent, LinkPageSearchFilterComponent, AdminPermissionDirective, BtnComponent, GetTableEmptyPipe, TableCellGameNotesComponent],
  templateUrl: './page-my-games.component.html',
  styleUrls: ['./page-my-games.component.scss'],
  providers: [FiltersService, MeTableService, SettingsRequestService], // for-filters=== for-table=== for-settings===
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// !!! IForClassForFilters for-filters=== // !!! IForClassForTable for-table===
export class PageMyGamesComponent extends HelperClass implements IForClassForFilters, IForClassForTable<ClassGame> {
  dataTable$ = new BehaviorSubject<IResponse<ClassGame>>({}); // for-table===
  arrContent$ = new BehaviorSubject<Array<ClassGame>>([]); // for-table===
  readonly urlMyGames = urlMyGames;
  readonly urlOpenGames = urlOpenGames;
  // readonly class_ngContent_btn_for_nothingList = class_ngContent_btn_for_nothingList;
  readonly urlGameInfo = urlGameInfo;
  readonly widthPage = 1300;

  constructor(
    private meS: MeService,
    private mainS: MainService,
    public gameS: GameService,
    public router: Router,
    public deviceS: DeviceService,
    // private route: ActivatedRoute,
    public filtersS: FiltersService, // for-filters===
    public meTableS: MeTableService<ClassGame>, // for-table===
    public settingsRequestS: SettingsRequestService, // for-settings===
    public cd: ChangeDetectorRef,
    public gameNotesPopupService: GameNotesPopupService
  ) {
    super(cd);
    this.setAllData();
  }

  // !!! если есть в фильтрах компетишн, то обязательно надо в AppModule к роуту прикрутить CompetitionsForFiltersResolver
  setAllData(): void {
    this.setDataForTable(); // for-table===
    this.setFilters(); // for-filters===
    this.settingsRequestS.setAllData(this.isFirstLoadPageSub$); // for-settingsDynamicUrl=== создание ссылки, чтобы можно было использовать в сервисе
    this.gameS.setCurrentLink_games(this.settingsRequestS.settings.currentLink_games); // for-currentLink===
    this.subscribeToSettings(); // for-settings===
  }

  // === SETTINGS ===============================
  subscribeToSettings(): void {
    this.settingsRequestS.settings$.pipe(
      // !!! myGames:true добавляется в get_defaultSettings_games()
      switchMap((res) => this.meTableS.getSubject_forGetGamesList()),
      untilDestroyed(this),
    ).subscribe((res) => {
      this.cd.detectChanges();
    });
  }

  // === FILTERS ==========================================
  setFilters(): void {
    const search = new ClassFilterInput({ typeFilter: 'search' });
    const dateRange = new ClassFilterDateRange();
    const groupId = new ClassFilterDrop({ typeFilter: 'groupId', arrayForDropdown: this.meS.me?.officialGroups });
    const competition = new ClassFilterDrop({
      typeFilter: 'competitions',
      arrayForDropdown: this.gameS.arrCompetition$.getValue(),
      disabled: !this.settingsRequestS.settings.groupId,
      // valueDrop: this.gameS.arrCompetition$.getValue()?.find(el => el.id === this.settingsRequestS.settings.competitions),
    });
    const assignStatus = new ClassFilterDrop({
      typeFilter: 'assignStatuses',
      arrayForDropdown: arrDropStatusAssign,
      multi: true,
    });
    this.filtersS.setFilters([search, dateRange, groupId, competition, assignStatus], true, true);
  }

  // === TABLE ======================================================
  setDataForTable(): void {
    this.meTableS.dataTable$ = this.dataTable$; // !!! создание ссылки. Чтобы можно было использовать в MeTableService
    this.meTableS.arrContent$ = this.arrContent$; // !!! создание ссылки. Чтобы можно было использовать в MeTableService
    const arrayCeilHeader: Array<ClassCeilTableHeader> = [
      new ClassCeilTableHeader({ text: 'Game', isChx: true }),
      new ClassCeilTableHeader({ text: 'Date & Time' }),
      new ClassCeilTableHeader({ text: 'Age & Level' }),
      new ClassCeilTableHeader({ text: 'Location' }),
      new ClassCeilTableHeader({ text: 'Teams' }),
      new ClassCeilTableHeader({ text: '' }),//notes
      new ClassCeilTableHeader({ text: 'Officials' }),
      new ClassCeilTableHeader({ sortBy: true }),
    ];
    const arrWidthCeilTable: Array<number> = [152, 187, 186, 209, 236, 55, 177, this.meTableS.minWidthFor_sortByCeil];
    this.meTableS.setArrTypeSorting(['byGameNumber', 'byDate', 'byGameLocation']); // !!! for-sorting===
    this.meTableS.setDataForTable(arrWidthCeilTable, arrayCeilHeader, this.widthPage, true);
  }

  // === OTHER ACTIONS ============================
  setStatus(gameItem: ClassGame, status: string): void {
    if (gameItem.id) {
      this.mainS.setOfficialStatus({ gameId: gameItem.id, status }).pipe(untilDestroyed(this))
        .subscribe((res) => {
          this.meTableS.updateGameOfficial(res); // !!! раньше было this.getGamesList(null)
        });
    }
  }

}

// subscribeToCurrentLink(): void {
//   this.gameS.currentLink$.pipe(untilDestroyed(this)).subscribe((res) => {
//     if (!res?.currentLink) return;
//     this.settings = { ...this.settings, ...new ClassSettingsRequest() }; // for-table===  для того чтобы page & size по дефолту поставить при переключении вкладок
//     this.settings.sort = this.gameS.getSortByDateForSettingRequestByDefault();
//     if (res.currentLink?.upperCase === currentLink_current) {
//       this.arrPeriods = ['Today', 'Tomorrow', 'Next 7 days', 'Next 2 weeks', 'Next month', 'Next 3 months', 'Next 12 months', 'Custom Date Range'];
//     }
//     if (res.currentLink?.upperCase === currentLink_past) {
//       this.arrPeriods = ['Yesterday', 'Last 7 days', 'Last 4 weeks', 'Last month', 'Last 3 months', 'Last 12 months', 'Custom Date Range'];
//     }
//     const filterDateRange = new ClassFilterDateRange({ typePeriod: res.currentLink?.upperCase, arrPeriods: this.arrPeriods });
//     this.filtersS.resetSelectedFilters([filterDateRange]); // for-filters===
//     this.cd.detectChanges();
//   });
// }
// getGamesList(type: TypeEmitSettingRequest | null): void {
//   this.meTableS.startLoadingTable(); // for-table===
//   this.settings.myGames = true;
//   this.mainS.getGamesList({ params: this.settings })
//     .pipe(
//       finalize(() => { // for-table===
//         this.meTableS.endLoadingTable();
//         this.cd.detectChanges();
//       }),
//       untilDestroyed(this),
//     )
//     .subscribe((res?: IResponse<ClassGame>) => {
//     });
// }
// subscribeToQueryParams(): void {
//   this.route.queryParams.pipe(
//     switchMap((params) => forkJoin([
//       of(params),
//       this.mainS.getArrCompetition(params?.groupId ? { params: { groupId: params?.groupId } } : undefined),
//     ])),
//     untilDestroyed(this),
//   )
//     .subscribe(([params, resCompetitions]) => {
//       const arrCompetitions: Array<ClassCompetition> = resCompetitions?.content || [];
//       // this.settings = { ...this.settings, ...params };
//       const search_filterDrop = new ClassFilterInput({ valueString: params?.search?.trim() });
//       const findGroup = this.meS.me?.officialGroups?.find(group => group.id === params?.groupId) || null;
//       const groupId_filterDrop = new ClassFilterDrop({
//         typeFilter: 'groupId',
//         arrayForDropdown: this.meS.me?.officialGroups || [],
//         valueDrop: findGroup!,
//       });
//
//       const findCompetition = arrCompetitions?.find(competition => competition.id === params?.competitions);
//       // this.gameS.competition = findCompetition || null;
//       const competitions_filterDrop = new ClassFilterDrop({
//         typeFilter: 'competitions',
//         arrayForDropdown: arrCompetitions || [],
//         valueDrop: findCompetition!,
//         disabled: !findGroup,
//       });
//
//       const assignStatusDrop = new ClassFilterDrop({
//         typeFilter: 'assignStatuses',
//         arrayForDropdown: arrDropStatusAssign,
//         multi: true,
//         valueDrop: [],
//       });
//       let statusAssignForSendToServer: TStatusAssignForSendToServer | null = null;
//       let statusAssignLower: TStatusAssignLower | null = null;
//       if (params?.assignStatuses?.includes('ACCEPTED')) {
//         statusAssignForSendToServer = 'ACCEPTED';
//         statusAssignLower = 'Assigned / Accepted';
//         const valueDrop: ClassDrop = { titleCase: statusAssignLower, upperCase: statusAssignForSendToServer };
//         (assignStatusDrop.valueDrop as Array<ClassDrop>)?.push(valueDrop);
//       }
//       if (params?.assignStatuses?.includes('UNACCEPTED') && (params?.selfAssigned === 'false' || params?.selfAssigned === 'true')) {
//         statusAssignForSendToServer = 'UNACCEPTED';
//         statusAssignLower = 'Assigned / Unaccepted';
//         const valueDrop: ClassDrop = { titleCase: statusAssignLower, upperCase: statusAssignForSendToServer };
//         (assignStatusDrop.valueDrop as Array<ClassDrop>)?.push(valueDrop);
//       }
//
//       this.filtersS.updateAllFilters([search_filterDrop, groupId_filterDrop, competitions_filterDrop, assignStatusDrop]); // for-filters===
//       this.cd.detectChanges();
//     });
// }
// getArrCompetition(groupId: string): void {
//   this.mainS.getArrCompetition(groupId ? { params: { groupId } } : undefined).toPromise()
//     .then((res) => {
//       const arrCompetitions = res?.content || [];
//       const competitions_filterDrop = new ClassFilterDrop({
//         typeFilter: 'competitions',
//         arrayForDropdown: arrCompetitions || [],
//         valueDrop: undefined,
//         disabled: false,
//       });
//       this.filtersS.updateAllFilters([competitions_filterDrop]);
//     })
//     .catch(err => {
//     });
// }
// emitSettingRequest(settingsRequest: ClassSettingsRequest, type: TypeEmitSettingRequest): void {
//   const isNewSelectedGroup = this.settings.groupId !== settingsRequest.groupId; // выбрана новая группа в дропдауне
//   if (isNewSelectedGroup && settingsRequest.groupId) this.getArrCompetition(settingsRequest.groupId);
//   if (!settingsRequest.groupId) { // если удалил группу из фильтра
//     const competitions_filterDrop = new ClassFilterDrop({
//       typeFilter: 'competitions',
//       arrayForDropdown: [],
//       valueDrop: undefined,
//       disabled: true,
//     });
//     this.filtersS.updateAllFilters([competitions_filterDrop]);
//   }
//   this.settings = { ...this.settings, ...settingsRequest };
//   this.getGamesList(type);
// }