import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { arrDropStatusAssign, IDropStatusAssign } from '@app/dir_group_assignor/games/game';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeviceService } from '@services/device.service';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { FormsModule } from '@angular/forms';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';

@Component({
  selector: 'app-popup-set-status-for-assign',
  standalone: true,
  imports: [CommonModule, FieldComponent, DropdownComponent, FormsModule, BtnWrapComponent, BtnComponent],
  templateUrl: './popup-set-status-for-assign.component.html',
  styleUrls: ['./popup-set-status-for-assign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupSetStatusForAssignComponent {
  arrDropStatusAssign = arrDropStatusAssign.filter((el) => el.upperCase === 'ACCEPTED' || el.upperCase == 'DECLINED');
  selectedStatusAssignDrop?: IDropStatusAssign;

  constructor(
    public deviceS: DeviceService,
    public dialogRef: MatDialogRef<PopupSetStatusForAssignComponent>,
    @Inject(MAT_DIALOG_DATA) public dataPopup: null,
    public cd: ChangeDetectorRef,
  ) {
  }

  close(): void {
    if (this.selectedStatusAssignDrop) {
      this.dialogRef.close(this.selectedStatusAssignDrop.upperCase);
    } else {
      this.dialogRef.close();
    }
  }
}
