import { Pipe, PipeTransform } from '@angular/core';
import { ClassAdjustmentStatus } from '@app/group-profile/components/group-profile-adjustment-statuses/adjustmentStatus';
import { const_NA } from '@models/other';

@Pipe({ name: 'isDefaultAdjustmentStatus', standalone: true })
export class IsDefaultAdjustmentStatusPipe implements PipeTransform {

  transform(classAdjustmentStatus: ClassAdjustmentStatus): boolean {
    return classAdjustmentStatus?.name === const_NA;
  }

}
