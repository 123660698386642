import { Pipe, PipeTransform } from '@angular/core';
import { OtherService } from '@services/other.service';

@Pipe({ name: 'getArrStringFromObject', standalone: true })
export class GetArrStringFromObjectPipe implements PipeTransform {

  constructor(
    private otherS: OtherService,
  ) {
  }

  // object == {cardNumber: 'aaa', cardExpiry: 'bbb', name: '', cardCvc: 'ddd'}
  // если есть текст, то вернется массив => например ['aaa', 'bbb', 'ddd']
  transform(object: any): Array<string> {
    return this.otherS.getArrStringFromObject(object);
  }

}
