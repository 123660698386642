import { Injectable } from '@angular/core';
import { ClassSettingsRequest } from '@models/response-and-request';
import { UtilsService } from '@services/utils.service';
import { BehaviorSubject } from 'rxjs';
import { currentPath_isGames, rout_groupProfile, TypePathForTable, urlCompetitionsList, urlOfficials, urlReports } from '@app/app.module';
import { OtherService } from '@services/other.service';
import { currentLink_past, TCurrentLink, TCurrentLinkDrop } from '@classes/dictionary';
import { TypeSortTable } from '@components/sortBy/sortBy';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomDatesService } from '@classes/CustomDates';
import { TypeEmitSettingRequest } from '@components/_table/meTable';
import { MeService } from '@services/me.service';
import { ClassReportStatusDrop, TReportStatus } from '@app/dir_group_assignor/reports/report';
import { TCurrentLink_officials, TCurrentLinkDrop_officials } from '@app/dir_group_assignor/officials/officials';
import { ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { debounceTime } from 'rxjs/operators';
import { urlPayments_allTransactions } from '@app/url-const';

@UntilDestroy()
@Injectable()
export class SettingsRequestService {
  isFirstLoadPageSub$?: BehaviorSubject<boolean>; 
  is_currentPath_allTransactions: boolean;
  get isFirstLoadPage(): boolean {
    if (!this.isFirstLoadPageSub$) return true; 
    return this.isFirstLoadPageSub$.getValue();
  }

  currentPath?: TypePathForTable;
  is_currentPath_games = false; // 'games' | 'assign'
  is_currentPath_reports = false; // 'reports'
  is_currentPath_officials = false; // 'officials'
  is_currentPath_competitions = false; // !!! for page /competitions/list
  is_currentPath_groupProfile = false; // !!! for page /groupProfile

  constructor(
    private meS: MeService,
    private router: Router,
    private route: ActivatedRoute,
    private otherS: OtherService,
    private datesS: CustomDatesService,
  ) {
    this.isFirstLoadPageSub$?.next(true);
    this.currentPath = this.otherS.getCurrentPath();
    this.is_currentPath_games = currentPath_isGames(this.currentPath);
    this.is_currentPath_reports = this.currentPath === urlReports;
    this.is_currentPath_officials = this.currentPath === urlOfficials;
    this.is_currentPath_competitions = this.currentPath === urlCompetitionsList;
    this.is_currentPath_groupProfile = this.currentPath.includes(rout_groupProfile);
    this.is_currentPath_allTransactions = this.currentPath.includes(urlPayments_allTransactions);
    this.currentPath.includes('myWalletOfficial/all');
  }
  private checkBackArrow(queryParams: ClassSettingsRequest): ClassSettingsRequest {
    if (!queryParams) return queryParams;
    const updatedQueryParams = { ...queryParams };
    Object.entries(this.settings)?.filter((el) => el[0] !== 'typeEmitSetting')?.forEach((el_currentSettings) => {
      const key_currentSettings = el_currentSettings[0];
      const value_currentSettings = el_currentSettings[1];
      const findProperty_queryParams = Object.entries(queryParams)?.find((el_queryParams) => {
        return (el_queryParams[0] === key_currentSettings) && (el_queryParams[0] !== 'typeEmitSetting');
      });
      if (!findProperty_queryParams) {
        // @ts-ignore
        updatedQueryParams[key_currentSettings] = '';
      }
    });
    return updatedQueryParams;
  }

  subscribeToQueryParams(): void {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((queryParams: ClassSettingsRequest) => {
      const res = this.isFirstLoadPage ? queryParams : this.checkBackArrow(queryParams);
      const existChanges = !UtilsService.compareTwoObjects(
        UtilsService.removeEmptyKeysFromObject(res),
        UtilsService.removeEmptyKeysFromObject(this.settings),
      );
      if (!existChanges) return;

      if (res) { 
        const tempSettings = { ...res };
        if (this.is_currentPath_games) {
          this.checkActualDate_byTodayUTC(tempSettings);
          if (tempSettings.currentLink_games === 'past') {
            if (!tempSettings.to) delete tempSettings.from;
          } else {
            if (!tempSettings.from) delete tempSettings.to;
          }

          const default_and_queryParams_settings: ClassSettingsRequest = {
            ...this.get_defaultSettings_games(res.currentLink_games!),
            ...tempSettings,
          };

          this.updateSettings(default_and_queryParams_settings); // +default
        } else if (this.is_currentPath_reports) {

          const default_and_queryParams_settings: ClassSettingsRequest = {
            ...this.get_defaultSettings_reports(res.currentLink_reports!),
            ...tempSettings,
          };
          this.updateSettings(default_and_queryParams_settings); // +default
        } else if (this.is_currentPath_officials) {

          const default_and_queryParams_settings: ClassSettingsRequest = { ...this.get_defaultSettings_officials(res.currentLink_officials!), ...tempSettings };
          this.updateSettings(default_and_queryParams_settings); // +default
        } else if (this.is_currentPath_competitions) {

          const default_and_queryParams_settings: ClassSettingsRequest = { ...this.get_defaultSettings_competitions(), ...tempSettings };
          this.updateSettings(default_and_queryParams_settings); // +default
        } else if (this.is_currentPath_groupProfile) {
          const default_and_queryParams_settings: ClassSettingsRequest = { ...this.get_defaultSettings_groupProfile(), ...tempSettings };
          this.updateSettings(default_and_queryParams_settings); // +default
        } else if (this.is_currentPath_allTransactions) { 
          const default_and_queryParams_settings: ClassSettingsRequest = {
            ...this.get_defaultSettings_reports(res.currentLink_reports!),
            ...tempSettings,
          };
          this.updateSettings(default_and_queryParams_settings); 
        } else {
          const default_and_queryParams_settings: ClassSettingsRequest = { ...this.get_defaultSettings_groupProfile(), ...tempSettings };
          this.updateSettings(default_and_queryParams_settings); // +default
          console.error('!is_currentPath_games & !is_currentPath_reports & !is_currentPath_officials', this.is_currentPath_games, this.is_currentPath_reports, this.is_currentPath_officials, this.is_currentPath_allTransactions);
        }
      } else {
        if (this.is_currentPath_games) {
          const defaultSettings_games = this.get_defaultSettings_games(null);
          this.updateSettings(defaultSettings_games); // +default
        }
        if (this.is_currentPath_reports) {
          const defaultSettings_reports = this.get_defaultSettings_reports(null);
          this.updateSettings(defaultSettings_reports); // +default
        }
        if (this.is_currentPath_officials) {
          const defaultSettings_officials = this.get_defaultSettings_officials(null);
          this.updateSettings(defaultSettings_officials); // +default
        }
        if (this.is_currentPath_competitions) {
          const defaultSettings_competitions = this.get_defaultSettings_competitions();
          this.updateSettings(defaultSettings_competitions); // +default
        }
        if (this.is_currentPath_groupProfile) {
          const defaultSettings_groupProfile = this.get_defaultSettings_groupProfile();
          this.updateSettings(defaultSettings_groupProfile); // +default
        }

        if (this.is_currentPath_allTransactions) { 
    
          const defaultSettings_forPaymentsAllTransactions = this.get_defaultSettings_reports(null);
          this.updateSettings(defaultSettings_forPaymentsAllTransactions); // +default
        }
      }
    },
    );
  };

  subscribeToSettings(): void {
    this.settingsSub$.pipe(untilDestroyed(this)).subscribe((res: ClassSettingsRequest) => {
      const queryParams = UtilsService.deepClone(UtilsService.removeEmptyKeysFromObject(res)); // UtilsService.removeEmptyKeysFromObject(res)
      delete queryParams.typeEmitSetting;
      if (this.is_currentPath_officials) {
        if (res.currentLink_officials === 'PENDING') delete queryParams.sort;
      }
      this.router.navigate([this.currentPath], { queryParams });
    });
  }

  // andrei==============
  infiniteLoading: boolean = false; // only for "infiniteLoading"
  check_infiniteLoading(): boolean { // pipe isInfiniteLoading
    return this.infiniteLoading || this.settings.typeEmitSetting === 'infiniteLoading';
  }

  getTypeEmitSetting(): TypeEmitSettingRequest | undefined { // pipe getTypeEmitSetting
    return this.settings.typeEmitSetting;
  }

  updateSettings(settings: ClassSettingsRequest, typeEmitSettingOld?: TypeEmitSettingRequest, forTest?: string): void {
    const typeEmitSetting = typeEmitSettingOld || settings.typeEmitSetting || this.settings.typeEmitSetting;
    let updatedSettings = UtilsService.removeEmptyKeysFromObject({
      ...this.settings, ...settings,
      typeEmitSetting,
    } as ClassSettingsRequest);
    if (typeEmitSetting === 'filters') {
      updatedSettings.page = 1; 
    }


    if (typeEmitSetting === 'currentLink') { 
      updatedSettings.page = 1; 
      if (this.is_currentPath_games) {
        if (updatedSettings.currentLink_games === 'past') { 
          if (!updatedSettings.to) {
            delete updatedSettings.from;
          }
        } else {
          if (!updatedSettings.from) {
            delete updatedSettings.to;
            updatedSettings.from = this.get_defaultSettings_games(updatedSettings.currentLink_games!).from;  
          }
        }

        const sort_isArray = Array.isArray(updatedSettings.sort);
        if (sort_isArray) { 
          if (updatedSettings.sort!.length === 1) { // ['date,Desc']
            if (updatedSettings.sort?.includes('date,Asc') || updatedSettings.sort?.includes('date,Desc')) {
              updatedSettings.sort = this.get_defaultSettings_games(updatedSettings.currentLink_games!).sort;
            }
          }
        } else { // 'date,Desc'
          if (updatedSettings.sort === 'date,Asc' || updatedSettings.sort === 'date,Desc') {
            updatedSettings.sort = this.get_defaultSettings_games(updatedSettings.currentLink_games!).sort;
          }
        }
      }
    }

    updatedSettings.typeEmitSetting = settings.typeEmitSetting as TypeEmitSettingRequest;
    const existChanges = !this.otherS.compareTwoObjects(
      UtilsService.removeEmptyKeysFromObject({ ...updatedSettings }),
      UtilsService.removeEmptyKeysFromObject({ ...this.settings, typeEmitSetting: typeEmitSetting || settings.typeEmitSetting }),
    );
    if (existChanges) this.settings = updatedSettings;
  }

  linkPageEmit(item: ClassDrop): void { // TCurrentLinkDrop | ClassReportStatusDrop
    if (this.is_currentPath_games) {
      const currentLinkDrop_curPast = item as TCurrentLinkDrop;
      this.updateSettings({
        page: 1, // !!! при переключении вкладки нужно сбрасывать страницу
        currentLink_games: currentLinkDrop_curPast?.upperCase,
        typeEmitSetting: 'currentLink',
      }, 'currentLink');
    }

    if (this.is_currentPath_reports) {
      const currentLinkDrop_reports = item as ClassReportStatusDrop;
      this.updateSettings({
        page: 1, 
        currentLink_reports: currentLinkDrop_reports?.upperCase,
        typeEmitSetting: 'currentLink',
        statuses: currentLinkDrop_reports?.upperCase,
      }, 'currentLink');
    }

    if (this.is_currentPath_officials) {
      const currentLinkDrop_officials = item as TCurrentLinkDrop_officials;
      this.updateSettings({
        page: 1, 
        currentLink_officials: currentLinkDrop_officials?.upperCase,
        typeEmitSetting: 'currentLink',
      }, 'currentLink');
    }
  }

  deletePropertyFromSettings(keySettings: keyof ClassSettingsRequest): void {
    const updatedSettings = { ...this.settings };
    delete updatedSettings[keySettings];
    this.settings = updatedSettings;
  }

  checkActualDate_byTodayUTC(settings: ClassSettingsRequest): void {
    const { from: from_default, to: to_default } = this.get_defaultSettings_games(settings?.currentLink_games!);
    if (settings?.currentLink_games === 'current') {
      if (settings?.from && this.datesS.checkActualDate_byTodayUTC('isBefore', settings?.from)) {
        settings.from = from_default;
      }
      if (settings?.to && this.datesS.checkActualDate_byTodayUTC('isBefore', settings?.to)) {
        settings.to = to_default;
      }
    }
    if (settings?.currentLink_games === 'past') {
      if (settings?.from && this.datesS.checkActualDate_byYesterdayUTC('isAfter', settings?.from)) {
        settings.from = from_default;
      }
      if (settings?.to && this.datesS.checkActualDate_byYesterdayUTC('isAfter', settings?.to)) {
        settings.to = to_default;
      }
    }
  }

  setDefaultSettings_byCurrentPath(): void {
    if (this.is_currentPath_games) this.defaultSettings = this.get_defaultSettings_games(null);
    if (this.is_currentPath_reports) this.defaultSettings = this.get_defaultSettings_reports(null);
    if (this.is_currentPath_officials) this.defaultSettings = this.get_defaultSettings_officials(null);
    if (this.is_currentPath_competitions) this.defaultSettings = this.get_defaultSettings_competitions();
  }

  // === DEFAULT SETTINGS ==============================
  readonly defaultSettingsSub$ = new BehaviorSubject<ClassSettingsRequest>(new ClassSettingsRequest()); // !!! устанавливать в конструкторе
  defaultSettings$ = this.defaultSettingsSub$.asObservable();

  set defaultSettings(defaultSettings: ClassSettingsRequest) {
    this.defaultSettingsSub$.next(UtilsService.removeEmptyKeysFromObject(defaultSettings));
  }

  get defaultSettings(): ClassSettingsRequest {
    return UtilsService.removeEmptyKeysFromObject(this.defaultSettingsSub$.getValue());
  }

  readonly valueFor_debounceTime_forSettings = 333;
  readonly settingsSub$ = new BehaviorSubject<ClassSettingsRequest>(new ClassSettingsRequest()); 
  readonly settings$ = this.settingsSub$.asObservable()
    .pipe(
      debounceTime(this.valueFor_debounceTime_forSettings),
      untilDestroyed(this),
    );

  set settings(settings: ClassSettingsRequest) {
    this.settingsSub$.next(UtilsService.removeEmptyKeysFromObject(settings));
  }

  get settings(): ClassSettingsRequest {
    const settings = this.settingsSub$.getValue();
    return UtilsService.removeEmptyKeysFromObject(settings);
  }

  get settingsForSendToServer(): ClassSettingsRequest {
    const settings = this.settings;
    const settingsForSendToServer = UtilsService.deletePropertyFromObject(settings, ['typeEmitSetting', 'currentLink_games', 'currentLink_reports', 'currentLink_officials']);
    if (this.settings.currentLink_officials === 'PENDING') delete settingsForSendToServer.sort;
    return UtilsService.removeEmptyKeysFromObject(settingsForSendToServer);
  }

  updateSettings_and_addSettingsByDefault(needReplaceCurrentProperty: boolean): void {
    if (needReplaceCurrentProperty) {
      this.settings = UtilsService.removeEmptyKeysFromObject({ ...this.settings, ...this.defaultSettings });
    } else {
      this.settings = UtilsService.removeEmptyKeysFromObject({ ...this.defaultSettings, ...this.settings });
    }
  }

  setSettingsByDefault(settings?: ClassSettingsRequest): void {
    let defaultSettings: ClassSettingsRequest;
    if (this.is_currentPath_games) defaultSettings = this.get_defaultSettings_games(this.settings.currentLink_games as TCurrentLink);
    if (this.is_currentPath_reports) defaultSettings = this.get_defaultSettings_reports(this.settings.currentLink_reports as TReportStatus);
    if (this.is_currentPath_officials) defaultSettings = this.get_defaultSettings_officials(this.settings.currentLink_officials as TCurrentLink_officials);
    if (this.is_currentPath_competitions) defaultSettings = this.get_defaultSettings_competitions();
    if (this.is_currentPath_groupProfile) defaultSettings = this.get_defaultSettings_groupProfile();
    if (settings) defaultSettings = UtilsService.mergeObjects(defaultSettings!, settings); 
    this.settings = UtilsService.removeEmptyKeysFromObject(defaultSettings!);
  }

  // ============================================================================

  resetSortByDefault(): void {
    let sortDefault: TypeSortTable | '' = '';
    if (this.is_currentPath_games) {
      sortDefault = this.settings.currentLink_games === currentLink_past ? 'date,Desc' : 'date,Asc';
    } else if (this.is_currentPath_reports) {
      sortDefault = 'game.date,Desc';
    } else if (this.is_currentPath_officials) {
      sortDefault = 'firstName,Asc';
    } else if (this.is_currentPath_competitions) {
      sortDefault = 'competitionName,Asc';
    } else {
      sortDefault = '';
    }
    this.updateSettings({ sort: sortDefault, typeEmitSetting: 'resetSortByDefault' }, 'resetSortByDefault');
  }

  getSortByDateForSettingRequestByDefault(currentLink: TCurrentLink | TReportStatus | TCurrentLink_officials | 'forCompetitions' | null)
    : Extract<TypeSortTable, 'date,Asc' | 'date,Desc' | 'game.date,Desc' | 'firstName,Asc' | 'competitionName,Asc'> | undefined {
    if (this.is_currentPath_games) {
      return currentLink === currentLink_past ? 'date,Desc' : 'date,Asc';
    } else if (this.is_currentPath_reports) {
      return 'game.date,Desc';
    } else if (this.is_currentPath_officials) {
      return 'firstName,Asc';
    } else if (this.is_currentPath_competitions) {
      return 'competitionName,Asc';
    } else {
      return undefined;
    }
  }

  // === DEFAULT SETTINGS REQUEST ==================================
  get_defaultSettings_games(currentLink_games: TCurrentLink | null): ClassSettingsRequest {
    const typeEmitSetting = this.settings.typeEmitSetting;
    const sort = this.getSortByDateForSettingRequestByDefault(currentLink_games) ? [this.getSortByDateForSettingRequestByDefault(currentLink_games)!] : undefined;
    const defaultSettings_games = new ClassSettingsRequest({
      typeEmitSetting,
      sort,
      currentLink_games: currentLink_games || 'current',
    });
    if (currentLink_games === 'current' || !currentLink_games) defaultSettings_games.from = this.datesS.todayMidnightFormatDate_UTC;
    if (currentLink_games === 'past') defaultSettings_games.to = this.datesS.todayMidnightFormatDate_UTC; // !!! было yesterdayMidnightFormatDate_UTC 8.04.24
    if (this.meS.OFFICIAL) {
      if (this.currentPath === 'myGames') defaultSettings_games.myGames = true;
      if (this.currentPath === 'openGames') defaultSettings_games.myGames = false;
    }

    return defaultSettings_games;
  }

  get_defaultSettings_reports(currentLink_reports: TReportStatus | null): ClassSettingsRequest {
    const sort = this.getSortByDateForSettingRequestByDefault(currentLink_reports) ? [this.getSortByDateForSettingRequestByDefault(currentLink_reports)!] : undefined;
    const defaultSettings_games = new ClassSettingsRequest({
      currentLink_reports: currentLink_reports || 'DUE',
      statuses: currentLink_reports || 'DUE',
      sort,
    });
    if (this.meS.OFFICIAL) {
      defaultSettings_games.myGames = true;
    }
    return defaultSettings_games;
  }

  get_defaultSettings_officials(currentLink_officials: TCurrentLink_officials | null): ClassSettingsRequest {
    const sort = this.getSortByDateForSettingRequestByDefault(currentLink_officials) ? [this.getSortByDateForSettingRequestByDefault(currentLink_officials)!] : undefined;
    const defaultSettings_officials = new ClassSettingsRequest({
      currentLink_officials: currentLink_officials || 'ACTIVE',
    });
    if (currentLink_officials === 'ACTIVE') defaultSettings_officials.sort = sort;
    return defaultSettings_officials;
  }

  get_defaultSettings_competitions(): ClassSettingsRequest {
    const sort = this.getSortByDateForSettingRequestByDefault('forCompetitions') ? [this.getSortByDateForSettingRequestByDefault('forCompetitions')!] : undefined;
    const defaultSettings_competitions = new ClassSettingsRequest({ sort });
    return defaultSettings_competitions;
  }

  get_defaultSettings_groupProfile(): ClassSettingsRequest {
    const defaultSettings_groupProfile = new ClassSettingsRequest({});
    return defaultSettings_groupProfile;
  }

  setAllData(isFirstLoadPageSub$: BehaviorSubject<boolean>, forTest?: string): void {
    this.setDefaultSettings_byCurrentPath();
    this.subscribeToSettings();
    this.subscribeToQueryParams();
    this.isFirstLoadPageSub$ = isFirstLoadPageSub$;
  }

}

