import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClassMyWalletItemTableForMobile } from '@app/dir_officials/page-my-wallet/my-wallet';
import { StatusComponent } from '@components/status/status.component';

@Component({
  selector: 'myWalletItemTableForMobile[myWalletItemTableForMobile]',
  standalone: true,
  imports: [CommonModule, StatusComponent],
  templateUrl: './my-wallet-item-table-for-mobile.component.html',
  styleUrls: ['./my-wallet-item-table-for-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyWalletItemTableForMobileComponent {
  @Input() myWalletItemTableForMobile!: ClassMyWalletItemTableForMobile;
  @Input() transactionType: string | undefined
}
