<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              user: (profileUserS.user$|async),
              fullAddress: (profileUserS.user$|async)?.address|getLocationString,
              width1_forContactItem: 239,
             } as obj' class='wrapperContact wrapperContactAvailability'>

  <div *ngIf='obj.desktop' class='headerContact'>
    <headTitle text='Availability' typeText='text1-blueDark' svgLeft='arrow_squareLeft&24'
               (svgLeftEmit)='otherS.locationBack()'></headTitle>
    <!--    <ng-container [ngTemplateOutlet]='actionsTemplate'></ng-container>-->
  </div>

  <titleAndNavigationForProfile *ngIf='obj.mobile' [typePageProfile]='profileUserS.typePageProfile'
                                [idUser]='profileUserS.id'></titleAndNavigationForProfile>

  <div class='contentContactAvailability'>
    <div class='contentContact'>
      <div class='titleContactAvailability'>Availability</div>
      <div *ngFor='let item of arrAvailable; let last=last'
           class='itemContactAvailability itemContactAvailability--{{last ? "last":""}}'>
        <span>{{item.title}}</span>
        <div>{{item.time}}</div>
      </div>
    </div>

    <div class='contentContact'>
      <div class='titleContactAvailability'>Not Available</div>
      <div *ngFor='let item of arrNotAvailable; let last=last'
           class='itemContactAvailability itemContactAvailability--{{last ? "last":""}}'>
        <span>{{item.time}}</span>
        <div>{{item.value}}</div>
      </div>
    </div>
  </div>

</div>