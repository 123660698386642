import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { OtherService } from '@services/other.service';
import { MainService } from '@services/main.service';
import {
  IResCreatePaymentMethodError,
  IResCreatePaymentMethodSuccess,
  IResCreateTokenSuccess,
  ISendObjForCreatePaymentMethod,
  IStripeEvent,
  StripeService,
  TFieldStripeForm,
} from '@services/stripe.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CommonModule } from '@angular/common';
import { GetArrStringFromObjectPipe } from '@pipes/get-arr-string-from-object.pipe';
import { CheckValidAllFieldsFromObjectPipe } from '@pipes/get-valid-all-fields-from-object.pipe';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';
import { GetStateInputPipe } from '@pipes/get-state-input.pipe';
import { HelperClass } from '@classes/Helper-Classes';
import { ErrorComponent } from '@components/__info_text_message_error_warning/error/error.component';
import { FundService } from '@services/funds.service';
import { IPaymentMethod, ISendObjCreatePaymentMethod } from '@app/dir_group_assignor/payments/modelsForPayment';
import { PaymentService } from '@app/dir_group_assignor/payments/payment.service';
import { MeService } from '@services/me.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

interface IForStripeElem {
  name: TFieldStripeForm;
  elemRef: Element; // ссылка на HTML елемент, которая в страйп передается
  stripeElem?: any; // сюда сохраняется созданный страйпом элемент => this.stripeElements?.create('cardNumber', option)
  value?: string;
}

@UntilDestroy()
@Component({
  selector: 'stripeForm',
  templateUrl: './stripe-form.component.html',
  styleUrls: ['./stripe-form.component.scss'],
  standalone: true,
  imports: [CommonModule, GetArrStringFromObjectPipe, CheckValidAllFieldsFromObjectPipe, BtnWrapComponent, BtnComponent, GetStateInputPipe, ErrorComponent],
})
export class StripeFormComponent extends HelperClass implements AfterViewInit {
  // Funds  за админа
  // ACH - это банковский счет
  // при нажатии на ACH загружается библиотека Plaid

  // add funds as admin: 4242 4242 4242 4242 222 02/22,
  // add funds plaid: user - user_good, password - pass_good  code= 1234
  // connect account with card: 4000 0566 5566 5556
  // connect account with ACH: accountNumber = 000999999991
  // DoB = 1901-01-01
  // routingNumber = 110000000
  // socialNumber = 0000

  // карта только такая для теста 4000056655665556
  // это дебетовая, кредитные коннект не принимает

  @Input() textApply?: string;
  @Input() textCancel?: string;

  fieldsRef: Array<IForStripeElem> = []; // здесь все поля, включая те, которые здесь в HTML создаются
  noInitFields: Array<TFieldStripeForm> = ['cardName', 'zip']; // это поля, которые не надо создавать с помощью страйпа. Эти поля здесь в HTML создаются

  stripeElements?: any; // для каждой формы новые элементы должны создаваться. Иначе ошибка будет в консоли

  @Input() oneBtn = true; // для админа 1 кнопка, для судьи 2 кнопки

  // === FOR ADMIN =====================
  // @Input() competition: ClassCompetition | null = null;
  @Input() competitionId: string = '';
  @Input() isCreatePayMethod = false;
  @Output() createPayMethod = new EventEmitter<IPaymentMethod>();

  // === FOR OFFICIAL =====================
  @Input() isCreateToken = false;
  @Output() createToken = new EventEmitter(); // token полученный со страйпа записывается в FundService

  @Output() cancel = new EventEmitter<any>();

  validForm: { [key in TFieldStripeForm]?: boolean } = {};
  errMessages: { [key in TFieldStripeForm]?: string } = {};

  nameCardActive = false;
  zipActive = false;

  constructor(
    public payS: PaymentService,
    public fundS: FundService,
    private stripeS: StripeService,
    private mainS: MainService,
    private meS: MeService,
    private otherS: OtherService,
    // private focusMonitor: FocusMonitor,
    // private r: Renderer2,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
  }

  ngAfterViewInit() {
    this.setValidFormErrMessage();
    this.initFields();

    // console.log('meS.groupCurrent :', this.meS.groupCurrent);
    // console.log('competition?.id :', this.competition?.competitionName, this.competition?.id);
    // this.mainS.getPaymentMethodsGroup().subscribe((res) => {
    //   console.log('res :', res);
    // });
  }

  setValidFormErrMessage(): void {
    this.fundS.fields?.forEach((field: TFieldStripeForm) => {
      this.validForm[field] = false;
      this.errMessages[field] = '';
    });
    this.cd.detectChanges();
  }

  // !!! если нет созданого пай.метода для группы, то сначала надо создать его
  // !!! после этого создается пай.метод для компетишна
  createPaymentMethod(): void {
    // const res: IPaymentMethod = fakePaymentMethod
    // this.payS.addPayMethod(res);
    // this.createPayMethod.emit(res);
    // return
    if (this.startRequest()) return;
    const sendObjForStripe: ISendObjForCreatePaymentMethod = {
      type: 'card',
      card: { ...this.cardExpiry?.stripeElem, ...this.cardCvc?.stripeElem, ...this.cardNumber?.stripeElem },
      billing_details: { name: this.cardName?.value },
    };

    this.stripeS.createPaymentMethod(sendObjForStripe).pipe(
      switchMap((res) => {
        const sendObjCreatePaymentMethod: ISendObjCreatePaymentMethod = {};
        const res_stripeS_createPaymentMethod: IResCreatePaymentMethodSuccess | IResCreatePaymentMethodError | null = res;
        sendObjCreatePaymentMethod.paymentMethod = (res_stripeS_createPaymentMethod as IResCreatePaymentMethodSuccess)?.paymentMethod?.id;
        console.log('sendObjCreatePaymentMethod :', sendObjCreatePaymentMethod);
        if (!sendObjCreatePaymentMethod.paymentMethod) { // !!! в случае ошибки от страйпа
          // this.otherS.showNotification(false, (res as IResCreatePaymentMethodError).message)
          return of(null);
        } else return this.mainS.createPaymentMethodForCompetition(sendObjCreatePaymentMethod, this.competitionId!);
      }),
      untilDestroyed(this),
    ).subscribe((res: IPaymentMethod | null) => {
        this.clear();
        if (!res) return;
        this.payS.addPayMethod(res);
        this.createPayMethod.emit(res);
        this.endRequest();
      },
      (err) => {
        console.error('error :', err);
        this.endRequest();
      });

    // !!! пока не удалять. From 2 version
    // this.stripeS.createPaymentMethod(sendObjForStripe)
    //   .pipe(
    //     switchMap((res: any) => { // !!! res == IPaymentMethod | IResCreatePaymentMethodError | null
    //       if (!res || res?.error) {
    //         return of(null);
    //       } else {
    //         // !!! from 2 version return this.mainS.savePaymentMethod({ stripeToken: (res as IResCreatePaymentMethodSuccess)?.paymentMethod?.id! });
    //         return this.mainS.createPaymentMethodForGroup({ paymentMethod: (res as IResCreatePaymentMethodSuccess)?.paymentMethod?.id! }, this.meS.groupCurrent);
    //       }
    //     }),
    //     takeUntil(this.destroySub),
    //   ).toPromise()
    //   .then((res?: IResCreatePaymentMethod | null) => {
    //     this.clear();
    //     if (res) {
    //       this.payS.addPayMethod(res);
    //       this.createPayMethod.emit(res);
    //     }
    //   })
    //   .catch((err) => {
    //   })
    //   .finally(() => this.endRequest());
  }

  initFields(): void {
    this.fundS.fields?.forEach((field: TFieldStripeForm) => {
      const refLink = document.querySelector(`#${field}`);
      if (refLink) this.fieldsRef.push({ name: field, elemRef: refLink });
    });

    this.stripeElements = this.stripeS.stripe?.elements();
    const option = { style: this.stripeS.styles, classes: this.stripeS.classes };

    this.fieldsRef?.forEach((item) => {
      if (this.noInitFields?.includes(item.name)) return; // исключить поля которые для страйпа не нужны
      item.stripeElem = this.stripeElements?.create(item.name, option);
      item.stripeElem?.mount(item.elemRef);
      item.stripeElem?.on('change', (event: IStripeEvent) => this.checkErr(event));
    });
  }

  changeInput(target: any): void {
    const stripeEvent: IStripeEvent = { elementType: 'cardName', error: { message: '' } };
    if (target?.value?.trim()) {
      stripeEvent.complete = true;
      stripeEvent.empty = false;
      stripeEvent.error ? stripeEvent.error.message = '' : null;
      const cardNameElem = this.cardName;
      if (cardNameElem) cardNameElem.value = target?.value?.trim();
    } else {
      stripeEvent.complete = false;
      stripeEvent.empty = true;
      stripeEvent.error ? stripeEvent.error.message = 'Enter name on card.' : null;
    }
    this.checkErr(stripeEvent);
  }

  changeInputZip(target: any): void {
    const stripeEvent: IStripeEvent = { elementType: 'zip', error: { message: '' } };
    if (target?.value?.trim()) {
      stripeEvent.complete = true;
      stripeEvent.empty = false;
      stripeEvent.error ? stripeEvent.error.message = '' : null;
      const zipElem = this.zip;
      if (zipElem) zipElem.value = target?.value?.trim();
    } else {
      stripeEvent.complete = false;
      stripeEvent.empty = true;
      stripeEvent.error ? stripeEvent.error.message = 'Enter Zip Code.' : null;
    }
    this.checkErr(stripeEvent);
  }

  checkErr(event: IStripeEvent): void {
    const field: TFieldStripeForm | undefined = event?.elementType;
    if (!field || !this.fundS.fields.includes(field) || !this.errMessages || !this.validForm) return;
    this.errMessages[field] = event?.error?.message ? event?.error?.message : '';
    if (event?.error?.message || !event?.complete || event?.empty) this.validForm[field] = false;
    if (!event?.error?.message && event?.complete && !event?.empty) this.validForm[field] = true;
    this.cd.detectChanges();
  }

  clear(): void {
    this.fieldsRef?.forEach((field) => {
      if (this.noInitFields.includes(field.name)) {
        field.value = '';
        // @ts-ignore
        if (field.elemRef) field.elemRef.value = '';
      } else {
        field.stripeElem?.clear();
      }
    });
    this.cd.detectChanges();
  }

  private findField(fieldName: TFieldStripeForm): IForStripeElem {
    return this.fieldsRef?.find((field) => field.name == fieldName)!;
  }

  // === FOR OFFICIAL ======================
  createTokenMethod(): void {
    if (this.startRequest()) return;
    const additionalData = {
      currency: 'usd',
      // name: this.cardName?.value, // у судьи нет в дизайне поля для имени
      // address_line1: address1 ? address1.value : undefined,
      // address_city: city ? city.value : undefined,
      // address_state: state ? state.value : undefined,
      // address_zip: zip ? zip.value : undefined,
    };

    this.stripeS.stripe?.createToken(this.cardNumber?.stripeElem, additionalData) // с сайта пример
      .then((res: IResCreateTokenSuccess) => {
        if (!res?.token?.id) {
          this.otherS.showError('No TOKEN from Stripe.');
          this.endRequest();
          return;
        }
        if (res?.token?.id) {
          this.fundS.tokenFromStripe = res?.token?.id;
          this.createToken.emit();
          // this.createToken.emit({
          //   token: res?.token?.id,
          //   // cardNumber: this.cardNumber, cardExpiry: this.cardExpiry, cardCvc: this.cardCvc, // Саня сказал эти данные сам из страпа берёт
          // });
        }
      })
      .catch((err: any) => this.endRequest());
  }

  // === GETTERS ==============================
  get cardName(): IForStripeElem {
    return this.findField('cardName');
  }

  get cardNumber(): IForStripeElem {
    return this.findField('cardNumber');
  }

  get cardExpiry(): IForStripeElem {
    return this.findField('cardExpiry');
  }

  get cardCvc(): IForStripeElem {
    return this.findField('cardCvc');
  }

  get zip(): IForStripeElem {
    return this.findField('zip');
  }

}
