<div #wrapSvg
     class='wrapperSvg'
     [class.wrapperSvg--disabled]='disabled'
     [style]='{width: w+"px", maxWidth: w+"px", minWidth: w+"px", height: (h||w)+"px", maxHeight: (h||w)+"px", minHeight: (h||w)+"px"}'
     [matTooltip]='textMatTooltip'>

     <!-- <img *ngIf="fullPathForSvg !== 'attach'"
       src='assets/{{fullPathForSvg}}.svg'
       [alt]=fullPathForSvg
       [class.o-cur-pointer]='cur'
       [class.o-hidden]='hid'
       [class.o-opacity]='o5'
       [class.o-opacity-30]='o3'
       [class.o-reverse]='revV'
       [style]='{width: w+"px", maxWidth: w+"px", minWidth: w+"px", height: (h||w)+"px", maxHeight: (h||w)+"px", minHeight: (h||w)+"px", objectFit: objectFit }'> -->

     <img *ngIf="fullPathForSvg !== 'attach'"
          src='assets/{{fullPathForSvg}}.svg'
          [alt]=fullPathForSvg
          [class.o-cur-pointer]='cur'
          [class.o-hidden]='hid'
          [class.o-opacity]='o5'
          [class.o-opacity-30]='o3'
          [class.o-reverse]='revV'
          [class.as-button-22]='asButton22'>
     <div *ngIf="fullPathForSvg === 'attach'"
          class='attachment-icon'>
          <input type='file'
                 multiple
                 (change)='handleFileInput($event)' />
     </div>
</div>