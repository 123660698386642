import { Pipe, PipeTransform } from '@angular/core';
import { ClassGame } from '@app/dir_group_assignor/games/game';
import { UtilsService } from '@services/utils.service';

@Pipe({ name: 'getListGameForPossibleGameToCancelled', standalone: true })
export class GetListGameForPossibleGameToCancelledPipe implements PipeTransform {

  // !!! вернуть ТОЛЬКО те игры, которые можно сделать CANCELLED
  transform(arrGame?: Array<ClassGame>): Array<ClassGame> {
    return UtilsService.getListGameForPossibleGameToCancelled(arrGame);
  }

}
