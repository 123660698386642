import { Directive, HostListener } from '@angular/core';

@Directive({ selector: '[stopPropagation]', standalone: true })
export class StopPropagationDirective {

  @HostListener('click', ['$event']) clickForItem($event: Event): void {
    $event.stopPropagation();
  }

}
