import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass } from '@classes/Helper-Classes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MeService } from '@services/me.service';
import { OtherService } from '@services/other.service';
import { PopupService } from '@services/popup.service';
import { DeviceService } from '@services/device.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { SelectDateComponent } from '@components/date-time/select-date/select-date.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import {
  arrStatusGameString,
  ClassGame,
  ClassGameOfficial,
  IOfficialsAssign,
  TStatusAssignForSendToServer,
} from '@app/dir_group_assignor/games/game';
import { MainService } from '@services/main.service';
import { BehaviorSubject } from 'rxjs';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { PaginationComponent } from '@components/pagination/pagination.component';
import { PhotosComponent } from '@components/__svg_img/photos/photos.component';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { CdkDrag, CdkDragHandle, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';
import { NothingListComponent } from '@components/nothing-list/nothing-list.component';
import { ClassUser } from '@models/user';
import { GetUserNamePipe } from '@pipes/get-name.pipe';
import { GetAgeByDateOfBirthPipe } from '@pipes/get-age-by-date-of-birth.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DropDateRangeComponent } from '@components/__drop_inputs_matSelect/drop-date-range/drop-date-range.component';
import { GetFirstLetterEachWordPipe } from '@pipes/string/get-first-letter-each-word.pipe';
import { GetSelectedElemsPipe } from '@pipes/get-selected-elems.pipe';
import { LinkPageComponent } from '@components/link-page/link-page.component';
import { CustomDatesService } from '@classes/CustomDates';
import { PopupComponent } from '@components/__popup-windows/popup/popup.component';
import { GetFirstLetterPipe } from '@pipes/get-first-letter.pipe';
import { GetLevelValuePipe } from '@pipes/get-level-value.pipe';
import { GetLocationStringPipe } from '@pipes/location/get-location-string.pipe';
import { tap } from 'rxjs/operators';
import { GetChxSvgNamePipe } from '@components/__svg_img/svg/pipes/get-chx-svg-name.pipe';
import { GetSvgForGoStatusPipe } from '@pipes/gameOfficial/get-svg-for-go-status.pipe';
import { DateFormattedComponent } from '@components/__date/date-formatted/date-formatted.component';
import { GetAgeGenderLevelPipe } from '@pipes/get-age-gender-level.pipe';
import { GetLocNameCourtNamePipe } from '@pipes/location/get-loc-name-court-name.pipe';
import { MatTooltipForLocationPipe } from '@pipes/location/mat-tooltip-for-location.pipe';
import { RoundMilesPipe } from '@pipes/round-miles.pipe';
import { ScrollDispatcher } from '@angular/cdk/overlay';
import { StrCaseForArrayPipe } from '@pipes/array/str-case-for-array.pipe';
import { FiltersService } from '@components/filters/filters.service';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { ClassFilterDateRange, ClassFilterDrop, ClassFilterInput, IForClassForFilters } from '@components/filters/filters';
import { UnsavedChangesService } from '@services/unsaved-changes.service';
import { CanComponentDeactivate } from '@app/core/guards/unsaved-changes.guard';
import { ShowBtnPublishPipe } from '@app/dir_group_assignor/assign/pipes/show-btn-publish.pipe';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { currentLinkDrop_current } from '@classes/dictionary';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { ClassCeilTableHeader } from '@components/_table/meTable';
import { TooltipDirective } from '@directives/tooltip.directive';
import { urlGameImport, urlGameInfo, urlReportInfo } from '@app/app.module';
import { ClassSettingsRequest, IResponse } from '@models/response-and-request';
import { ForFilterItemDirective } from '@components/filters/directives/for-filter-item.directive';
import { AssignService } from '@app/dir_group_assignor/assign/assign.service';
import { AssignComponent } from '@app/dir_group_assignor/assign/assign.component';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { selectHomeAssigner } from '@app/store/dashboard/states/dashboard-home-assigner';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MeTableService } from '@components/_table/me-table.service';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { DashboardSubAssignorActions } from '@app/store/dashboard/actions/dashboard.actions';
import {
  selectDashboardAssignData,
  selectDashboardDeclinedData,
  selectDashboardUnassignedData,
} from '@app/store/dashboard/states/dashboard.state';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';
import { TableCeilComponent } from '../../../../../../shared/components/_table/table-ceil/table-ceil.component';
import { BtnImportGamesComponent } from '@app/dir_group_assignor/games/components/btn-import-games/btn-import-games.component';
import { ManageAllService } from '@shared/manage-all.service';
import { AuthenticatorService } from '@directives/authenticator-hide.directive';

@UntilDestroy()
@Component({
  selector: 'app-group-assignor-table',
  standalone: true,
  imports: [
    CommonModule,
    InputCtrlComponent,
    DropFormCtrlComponent,
    ReactiveFormsModule,
    SelectDateComponent,
    BtnWrapComponent,
    BtnComponent,
    HeadTitleComponent,
    SvgComponent,
    FormsModule,
    SpinnerComponent,
    PaginationComponent,
    PhotosComponent,
    SvgAndTextComponent,
    CdkDragHandle,
    CdkDrag,
    CdkDropList,
    NothingListComponent,
    CdkDropListGroup,
    GetUserNamePipe,
    GetAgeByDateOfBirthPipe,
    MatTooltipModule,
    DropDateRangeComponent,
    GetFirstLetterEachWordPipe,
    GetSelectedElemsPipe,
    LinkPageComponent,
    GetFirstLetterPipe,
    GetLevelValuePipe,
    GetLocationStringPipe,
    GetChxSvgNamePipe,
    GetSvgForGoStatusPipe,
    DateFormattedComponent,
    GetAgeGenderLevelPipe,
    GetLocNameCourtNamePipe,
    MatTooltipForLocationPipe,
    RoundMilesPipe,
    StrCaseForArrayPipe,
    FiltersComponent,
    ShowBtnPublishPipe,
    WrapPageDirective,
    TooltipDirective,
    DropdownComponent,
    ForFilterItemDirective,
    RouterLink,
    GetTableEmptyPipe,
    TableCeilComponent,
    BtnImportGamesComponent,
  ],
  templateUrl: './group-assignor-table.component.html',
  styleUrls: ['./group-assignor-table.component.scss'],
  providers: [AssignService, FiltersService, MeTableService, SettingsRequestService], // for-filters=== for-table=== for-settings===
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupAssignorTableComponent extends HelperClass implements IForClassForFilters, OnInit, OnDestroy, OnChanges, AfterViewChecked,
  CanComponentDeactivate<AssignComponent> {
  @ViewChild('tableList', { static: false }) tableList?: ElementRef;
  @ViewChildren('listItem') listItems!: QueryList<ElementRef>;
  data?: IResponse<ClassGame>;
  gameListSub$ = new BehaviorSubject<Array<ClassGame>>([]);
  readonly urlReportInfo = urlReportInfo;
  readonly urlGameInfo = urlGameInfo;
  arrContent$ = new BehaviorSubject<Array<ClassGame>>([]); // for-table===

  selectedGameId: string | undefined = undefined;
  lastAssignee: any = null;

  tableHeader: Array<ClassCeilTableHeader> = [
    { text: 'Game' },
    { text: 'Date & Time / Age & Level' },
    { text: 'Location & Teams' },
    { text: 'Officials' },
  ];

  officials: Array<ClassUser> = [];
  settingsOfficials: ClassSettingsRequest = { page: 1, size: 100 };
  draggedOfficial!: ClassUser;
  loadingOfficials = false;
  isAllOfficialsLoaded = false;

  amountForShowAllGO = 4; // по умоляанию показывать GO 4 штуки

  chxIgnoreAvailability = false;

  disabledBtn = true; // !!! если не было ещё драгДропа (переноса судьи на роль), то должно быть disabled
  queryParamsObj: any = {};
  loadingSidebar = false;
  isOfficialUnAssigned: boolean = false;
  isUnAssigned: boolean = false;
  loadInitialOfficialList = true;
  isOfficialControlsEnable = true;
  selectedOfficialStatuses: string[] = [];
  sortingParams: any = {};
  currentSelectedOfficial: ClassGameOfficial[] = [];
  public searchFilter: ClassFilterInput;

  readonly urlGameImport = urlGameImport;

  isReadOnly: boolean = true;
  constructor(
    public authenticatorService: AuthenticatorService,
    public mainS: MainService,
    public gameS: GameService,
    public meS: MeService,
    private otherS: OtherService,
    private popupS: PopupService,
    public deviceS: DeviceService,
    public dateS: CustomDatesService,
    public router: Router,
    private r: Renderer2,
    public scrollDispatcher: ScrollDispatcher,
    public filtersS: FiltersService,
    private unsavedChangesService: UnsavedChangesService,
    private route: ActivatedRoute,
    public cdr: ChangeDetectorRef,
    public store: Store<AppState>,
    public meTableS: MeTableService<ClassGame>,
    public assignS: AssignService,
    private manageAllService: ManageAllService,
  ) {
    super(cdr);
    this.isReadOnly = !authenticatorService.isAllow(['GROUP_ASSIGNOR', 'SUB_ASSIGNOR'])
    this.isFirstLoadPage = true;
    this.gameS.currentLinkObj = { currentLink: currentLinkDrop_current };
    this.subscribeToSearch();

    this.route.queryParams
      .pipe(
        untilDestroyed(this),
        tap(params => {
          if (params && params.competition) {
            this.queryParamsObj = { ...params, competition: JSON.parse(params.competition) };
            if (this.queryParamsObj.currentLink) gameS.currentLinkObj = { currentLink: this.queryParamsObj.currentLink };
          }
        }),
      )
      .subscribe();
    this.searchFilter = this.filtersS.findFilter('search');
  }


  public activeTile!: string;

  ngOnInit() {
    this.store.select(selectHomeAssigner).pipe(
      untilDestroyed(this),
    ).subscribe(activeTile => {
      this.isFirstLoadPage = true;
      this.loading$.next(true);
      this.activeTile = activeTile?.isPending ? 'UNACCEPTED' : activeTile?.isDeclined ? 'DECLINED' : activeTile?.isUnassigned ? 'UNASSIGNED' : '';

      // @ts-ignore
      let loadAction;
      let selectSelector;
      let dataField: string = '';

      switch (this.activeTile) {
        case 'UNACCEPTED':
          loadAction = DashboardSubAssignorActions.loadAssignData({ sortParams: this.sortingParams, settingParams: this.settings });
          selectSelector = selectDashboardAssignData;
          dataField = 'assignData';
          break;
        case 'UNASSIGNED':
          loadAction = DashboardSubAssignorActions.loadUnassignedData({ sortParams: this.sortingParams, settingParams: this.settings });
          selectSelector = selectDashboardUnassignedData;
          dataField = 'unassignedData';
          break;
        case 'DECLINED':
          loadAction = DashboardSubAssignorActions.loadDeclinedData({ sortParams: this.sortingParams, settingParams: this.settings });
          selectSelector = selectDashboardDeclinedData;
          dataField = 'declinedData';
          break;
        default:
          return;
      }

      // Check if data is already present in the store
      // @ts-ignore
      this.store.dispatch(loadAction);
      // @ts-ignore
      this.store.select(selectSelector).pipe(
        untilDestroyed(this),
      ).subscribe(res => {
        // @ts-ignore
        const data = res[dataField];
        this.data = data;
        if (data?.content) {
          this.gameListSub$.next(data.content);
          this.resetSelectedItem();
          this.cd.detectChanges();
        }
        setTimeout(() => {
          this.loading$.next(false);
        }, 650);

      });
    });

    this.subscribeToCurrentLink();
    this.setFilters();
    this.isFirstLoadPage = false;
    this.manageAllService.setSettings(this.settings);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('settings' in changes && !this.isFirstLoadPage) {
      this.settings = changes.settings.currentValue;
      if (!this.settings.from) {
        this.settings.from = this.dateS.todayMidnightFormatDate;
      }
      this.emitSettingRequest(this.settings);
      //  Чето фильтры себя странно ведут
      // this.store.dispatch(DashboardSubAssignorActions.loadAllData({sortParams: this.sortingParams, settingParams: this.settings}));
      // this.cd.detectChanges();
    }
  }

  // Check if there are unsaved changes
  canDeactivate() {
    return true;
  }

  ngAfterViewChecked() {
    if (this.tableList && this.queryParamsObj?.gameId) {
      const selectedItem = this.listItems.find(item => item.nativeElement.classList.contains('selected'));

      if (selectedItem) {
        selectedItem.nativeElement.scrollIntoView({ behavior: 'instant', block: 'center', inline: 'start' });
      }
    }
  }


  subscribeToCurrentLink(): void {
    this.gameS.currentLink$.pipe(untilDestroyed(this)).subscribe((res) => {
      if (!res?.currentLink) return;
      this.settings.sort = this.gameS.getSortByDateForSettingRequestByDefault();
      const filterDateRange = new ClassFilterDateRange({ typePeriod: res.currentLink?.upperCase }); // arrPeriods: this.arrPeriods
      this.filtersS.resetSelectedFilters([filterDateRange]); // for-filters===
      this.cd.detectChanges();
    });
  };

  scrollToTop(tableList: HTMLDivElement, isLast: boolean): void {
    if (isLast) {
      const currentScrollPosition = tableList.scrollTop;
      const newScrollPosition = currentScrollPosition + 50;
      tableList.scroll({ top: newScrollPosition, behavior: 'smooth' });
    }
  }

  selectOfficial(gameId: string, official: any = null, gameItem: ClassGame, finalElem: HTMLDivElement, selected: Array<ClassGameOfficial>) {
    if (this.isReadOnly)
      return;
    if (finalElem.style.opacity !== '1') {
      return;
    }
    //  Активный не активный офишиал
    // Короче эта шляпа нужна была для проверки на кнопках, но она отрабатывает криво и надо бы подумать как избавться от этого
    const officialPosition = this.currentSelectedOfficial.indexOf(official);
    if (officialPosition !== -1) {
      this.currentSelectedOfficial.splice(officialPosition, 1);
    } else {
      this.currentSelectedOfficial.push(official);
    }
    this.currentSelectedOfficial = this.currentSelectedOfficial.filter(official => selected.includes(official));
    this.lastAssignee = {
      gameItem,
      goItem: official,
      finalElem,
    };
    if (gameItem.gameStatus === 'CLOSED') return;

    this.settingsOfficials.page = 1;
    const isUnAssign = this.checkIsUnAssigned(official);
    this.isUnAssigned = isUnAssign;

    if (!isUnAssign) this.chxIgnoreAvailability = false;

    if (this.isOfficialUnAssigned !== isUnAssign) {
      this.loadInitialOfficialList = true;
    }

    const isStatusChanged = this.detectStatusChange(official, gameId);

    if ((this.selectedGameId !== gameId || isStatusChanged)) {
      this.resetAllSelectedOfficials();
      this.officials = [];
      this.isAllOfficialsLoaded = false;
      this.isOfficialUnAssigned = isUnAssign;

      if (isUnAssign) {
        this.loadInitialOfficialList = false;
      }
    }

    this.selectedGameId = gameId;

    if (this.loadInitialOfficialList && (this.selectedOfficialStatuses.length === 0 || !this.selectedOfficialStatuses.includes('UNASSIGNED'))) {
      this.isUnAssigned = false;
      this.chxIgnoreAvailability = false;
      this.isOfficialUnAssigned = false;
      this.officials = [];
      this.cdr.detectChanges();
      // this.getListOfficials();
      this.isAllOfficialsLoaded = false;
    } else {
      this.getAvailableGame(gameId);
    }
  }

  getAvailableGame(gameId: string | undefined, isSearch: boolean = false) {
    if (!this.chxIgnoreAvailability) {
      this.settingsOfficials.size = undefined;
      this.settingsOfficials.page = undefined;
    } else {
      this.settingsOfficials.size = 100;
      !this.settingsOfficials.page ? this.settingsOfficials.page = 1 : null;
    }

    this.queryParamsObj = null;
    if (!this.loadInitialOfficialList) this.fetchOfficials(gameId, isSearch);
  }

  getGamesList(): void {
    this.startLoading(); // !!! if (this.startLoading()) return; // !!! не надо здесь. Потому что фильтры можно быстро выбирать => поступит следующее значение не успеет загрузиться ответ от сервера
    const gameStatuses: string = arrStatusGameString?.filter((el) => el !== 'CANCELLED' && el === 'ACTIVE')?.join(',');
    this.settings.size = 5;
    if (this.activeTile === 'UNACCEPTED') {
      this.settings.status = 'UNACCEPTED';
      this.settings.assignStatuses = 'UNACCEPTED';
    }
    if (this.activeTile === 'DECLINED') {
      this.settings.status = 'DECLINED';
      this.settings.assignStatuses = 'DECLINED';
    }
    if (this.activeTile === 'UNASSIGNED') {
      this.settings.status = 'UNASSIGNED';
      this.settings.assignStatuses = 'UNASSIGNED';
    }
    // this.meTableS.getSubject_forGetGamesList(this.settings)
    this.mainS.getGamesList({
      params: {
        ...this.settings,
        gameStatuses, ...this.sortingParams,
      },
    })
      .toPromise()
      .then((res?: any) => {
        this.data = res;
        if (!res?.content) return;
        this.gameListSub$.next(res.content);
        this.resetSelectedItem();
      })
      .catch((res) => {
      })
      .finally(() => {
        this.loading$.next(false);
        this.endLoading();
      });
  };

  // === POPUP ===============================
  setStatusProcess(arrSelectedGO: Array<ClassGameOfficial>, status?: TStatusAssignForSendToServer): void {
    if (this.startRequest()) return;
    if (!!status) {
      this.changeStatusProcess(arrSelectedGO, status);
      return;
    }
  };

  private changeStatusProcess(arrSelectedGO: Array<ClassGameOfficial>, status: TStatusAssignForSendToServer) {
    const arrGoForSendToServer: Array<ClassGameOfficial> = arrSelectedGO?.map(el => {
      return { id: el.id, status: status };
    });
    this.mainS.updateOfficials(arrGoForSendToServer).toPromise()
      .then((res) => {
        arrSelectedGO.forEach((goItem) => goItem.status = status);
        const newGameList: Array<ClassGame> = this.gameListSub$.getValue()?.map((gameItem) => {
          if (gameItem.gameOfficials?.length) gameItem.gameOfficials = this.otherS.replaceItemsInArrayById(gameItem.gameOfficials, arrSelectedGO);
          return { ...gameItem };
        });
        // Нужна переделка, идея какая надо либо избавиться от эммитера либо переписать процесс получения каунтера и тогда можно будет избавиться от вызова экшена
        this.store.dispatch(DashboardSubAssignorActions.loadAllAssignAction({
          sortParams: this.sortingParams,
          settingParams: this.settings,
        }));
        this.emitSettingRequest(this.settings);
        this.gameListSub$.next(newGameList);
      })
      .catch((err) => {
      })
      .finally(() => this.endRequest());
  }

  removeOfficials(arrSelectedGO: Array<ClassGameOfficial>, gameItem: ClassGame): void {
    if (this.startRequest()) return;
    this.popupS.open(PopupComponent, this.gameS.dataPopupForRemoveOfficialFromGO).then((resPopup: boolean) => {
      if (!resPopup) {
        this.endRequest();
        return;
      }
      const arrGoForSendToServer: Array<string> = arrSelectedGO?.map(el => el.id!); // sendObjArray ids gameOfficials
      this.mainS.removeOfficials(arrGoForSendToServer).toPromise()
        .then((res) => {
          arrSelectedGO.forEach((goItem) => {
            goItem.status = 'UNASSIGNED';
            delete goItem.official;
          });

          //  New version: After removing oficial need to update oficial lists
          this.isOfficialUnAssigned = true;
          this.loadInitialOfficialList = false;
          this.isUnAssigned = true;
          // Because, after removing an official, the status transitions to 'UNASSIGNED', we have added this status accordingly.
          this.selectedOfficialStatuses = [];
          this.selectedOfficialStatuses.push('UNASSIGNED');
          this.getAvailableGame(gameItem.id || '', true);
          this.store.dispatch(DashboardSubAssignorActions.loadAllAssignAction({
            sortParams: this.sortingParams,
            settingParams: this.settings,
          }));
          this.emitSettingRequest(this.settings);
        })
        .catch((err) => {
        })
        .finally(() => this.endRequest());
    });
  };

  // === FILTERS ==========================================
  @Input()
  settings: ClassSettingsRequest = new ClassSettingsRequest({ size: 5, from: this.dateS.todayMidnightFormatDate });

  setFilters(): void {
    const search = new ClassFilterInput({ typeFilter: 'search' });
    const dateRange = new ClassFilterDateRange({ typePeriod: 'current' });
    const competition = new ClassFilterDrop({ typeFilter: 'competitions' });
    this.filtersS.setFilters([search, dateRange, competition], false, false);
  }

  // !!! for filters & pagination & sorting & infiniteLoading
  emitSettingRequest(settingsRequest: ClassSettingsRequest): void {
    this.settings = { ...this.settings, ...settingsRequest };
    if (this.activeTile !== 'UNACCEPTED') {
      this.settings.assignStatuses = this.activeTile;
    } else {
      this.settings.assignStatuses = '';
    }
    this.getGamesList();
  };

  // === SEARCH =======================
  subscribeToSearch(): void { // !!! FOR OFFICIALS ===
    this.subscribeToSearch2FromHelperClass().pipe(untilDestroyed(this)).subscribe((searchValue: string) => {
      if (!searchValue) {
        delete this.settingsOfficials.search;
        this.isAllOfficialsLoaded = false;
      }
      this.settingsOfficials.search = searchValue;
    });
  };

  private resetAllSelectedOfficials() {
    if (!this.data?.content) return;
    this.data.content.forEach(game => {
      game.gameOfficials?.forEach(official => official.isSelect = false);
    });
    this.gameListSub$.next(this.data.content);
  }

  private checkIsUnAssigned(official: ClassGameOfficial): boolean {
    return official?.status === 'UNASSIGNED' && official?.isSelect !== true;
  }

  private fetchOfficials(gameId: string | undefined, isSearch: boolean = false): void {
    this.loadingOfficials = true;
    if (isSearch) {
      this.officials = [];
      this.settingsOfficials.page = 1;
    }

    // ===============================
    // const sendObj: ClassSettingsRequest = { ...this.settingsOfficials, gameId, ignoreAvailability: this.chxIgnoreAvailability };
    const sendObj: ClassSettingsRequest = { ...this.settingsOfficials, gameId };

    // this.assignService.getGameByIdAndIgnoreAvailability({params: sendObj}, isSearch)
    this.mainS.getListOfficials({ params: sendObj }, 'ACTIVE')
      .pipe(
        untilDestroyed(this),
        tap((response) => {
          this.handleOfficialsResponse(response);
          this.cdr.detectChanges();
        }),
      )
      .subscribe((response: IResponse<ClassUser>) => {
        this.updateOfficials(response);
        this.loadingSidebar = false;
        this.loadingOfficials = false;
        this.cdr.detectChanges();
      });
  }

  private handleOfficialsResponse(response: any): void {
    if (!response?.content || response.content.length === 0) {
      this.isAllOfficialsLoaded = true;
      this.loadingOfficials = false;
      this.cdr.detectChanges();
    }
  }

  private updateOfficials(response: IResponse<ClassUser>): void {
    if (!response?.content || response?.content?.length === 0) {
      return;
    }
    this.officials = this.officials.filter(official => official.availabilityByGame !== undefined);
    const uniqueOfficials = response.content.filter(newOfficial => {
      return !this.officials.some(existingOfficial => JSON.stringify(existingOfficial) === JSON.stringify(newOfficial));
    });
    this.officials = [...this.officials, ...uniqueOfficials];
    this.cdr.detectChanges();
  }

  private detectStatusChange(official: ClassGameOfficial, gameId: string) {
    if (gameId !== this.selectedGameId) {
      this.selectedOfficialStatuses = [];
    }
    let initialLength: number = 0;
    let finalLength: number = 0;

    if (!official.isSelect) {
      const filterCondition = official.status === 'UNASSIGNED' ? (status: string) => status === 'UNASSIGNED' : (status: string) => status !== 'UNASSIGNED';

      if (official.status === 'UNASSIGNED') {
        initialLength = this.selectedOfficialStatuses.length;
        this.selectedOfficialStatuses = this.selectedOfficialStatuses.filter(filterCondition);
        finalLength = this.selectedOfficialStatuses.length;
        this.selectedOfficialStatuses.push(official.status);

        if (initialLength === 0 && finalLength === 0) {
          finalLength = this.selectedOfficialStatuses.length;
        }
      }

      if (official.status !== 'UNASSIGNED' && !!official.status) {
        initialLength = this.selectedOfficialStatuses.length;
        this.selectedOfficialStatuses = this.selectedOfficialStatuses.filter(filterCondition);
        finalLength = this.selectedOfficialStatuses.length;
        this.selectedOfficialStatuses.push(official.status);

        if (initialLength === 0 && finalLength === 0) {
          finalLength = this.selectedOfficialStatuses.length;
        }
      }
    } else {
      initialLength = this.selectedOfficialStatuses.length;
      this.selectedOfficialStatuses.pop();
      if (initialLength === 0 && finalLength === 0) {
        return true;
      } else {
        return false;
      }
    }

    return initialLength !== finalLength;
  }

  ngOnDestroy() {
  }

  isActiveElement(opacity: string, element: ClassGameOfficial, checkOpacity: boolean = true): boolean {
    switch (this.activeTile) {
      case 'UNACCEPTED':
        return element.status === 'UNACCEPTED' ? !element.selfAssigned : true;
      case 'DECLINED':
        return element.status !== 'DECLINED';
      case 'UNASSIGNED':
        return element.status !== 'UNASSIGNED';
      default:
        return checkOpacity ? opacity !== '1' : true;
    }
  }

  checkSelectedElems(selectedElems: Array<any>, isSelfAssign: boolean = false): boolean {
    if (selectedElems.length === 0) return true;

    // Получаем уникальные статусы из выбранных элементов
    const uniqueStatuses = selectedElems.length > 1 ? Array.from(new Set(selectedElems.map(elem => elem.status))) : selectedElems;

    // Если количество уникальных статусов больше 1, значит у объектов разные статусы
    if (uniqueStatuses.length > 1) return true;
    return selectedElems.length === 0;
  }

  assignOfficials(selectedItems: Array<any>): void {
    if (this.startRequest()) return;
    this.arrContent$.next(selectedItems);
    const sendObj: IOfficialsAssign = { publish: true, officialsAssign: [] };
    selectedItems?.map(game => {
      sendObj.officialsAssign?.push({ officialId: game?.official?.id, gameOfficialId: game.id });
    });

    this.mainS.assignOfficials(sendObj)
      .pipe(
        tap((res) => {
          this.selectedGameId = '';
          // this.assignS.deleteCache();
          this.mainS.deleteApiCache(this.mainS.apiOfficials);
          this.disabledBtn = true;
          this.endRequest();
        }),
        untilDestroyed(this),
      )
      .subscribe((res) => {
        this.emitSettingRequest(this.settings);
      });
  };

  getSelectedStatus(selectedElems: Array<ClassGameOfficial>): string | undefined | boolean {
    if (!selectedElems || selectedElems.length === 0) {
      return false;
    }
    let filteredArr: ClassGameOfficial[] = [];
    if (this.activeTile === 'UNACCEPTED') {
      filteredArr = selectedElems.filter(el => el.status === 'UNACCEPTED' || el.status === 'UNPUBLISHED');
    }
    if (this.activeTile === 'DECLINED') {
      filteredArr = selectedElems.filter(el => el.status === 'DECLINED');
    }
    if (this.activeTile === 'UNASSIGNED') {
      filteredArr = selectedElems.filter(el => el.status === 'UNASSIGNED');
    }
    if (filteredArr.length === 0) return false;
    // Получаем статус последнего выбранного официала
    const currentElem = filteredArr[filteredArr.length - 1];
    return currentElem.status === 'UNACCEPTED' ? currentElem.selfAssigned ? 'First' : 'Second' : 'UNPUBLISHED';

  }

  resendOfficial(selectedElems: Array<ClassGameOfficial>) {
    selectedElems.forEach(elem => {
      if (elem?.official?.id && elem.gameId) {
        this.mainS.resendOfficials(elem.official.id, elem.gameId).pipe(untilDestroyed(this)).subscribe(res => {
          console.log(res);
        });
      }
    });
  }

  manageAll(): void {
    this.manageAllService.manageAll();
  }
}

// goToAssign(objGameItem: ClassGame): void {
//   const goToAssign: IGoToAssign = {
//   currentLink: this.gameS.currentLinkObj.currentLink?.upperCase,
//   search: objGameItem.gameNumber,
//   gameId: objGameItem.id,
//   // competition: JSON.stringify({ ...objGameItem.competition, id: objGameItem.competitionId }),
//   competition: JSON.stringify(objGameItem.competition),
//   periodForDateRange: 'All time',
// };
// this.router.navigate(['/assign'], { queryParams: goToAssign });
// }
