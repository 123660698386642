<!--*ngIf='competitionS.isLoad.officialLabels; else spinner'-->
<div class='wrapperCompetitions'>
  <competitionsNavigation page='levels'></competitionsNavigation>
  <div class='wrapperCompetitionsBlock wrapperCompetitionsOfficialLabels' [formGroup]='form'>
    <div class='headerSetupCompetition'>
      <svgAndText text='Official Labels' leftSvgName='arrow_squareLeft&24' typeText='text1-blueDark'
                  (leftSvgEmit)='competitionS.goToBack()'></svgAndText>
      <div *ngIf='competitionS.competition?.id' class='headerSetupCompetition__btns'>
        <btn text='Save & Close' bcg='white' btnHeight='36' (click)='competitionsBtnsEmit(false)'
             [disabled]='!form.valid || reqPending || isReadOnly'></btn>
        <btn text='Save & Proceed to Next Step' bcg='blueDark' btnHeight='36' (click)='competitionsBtnsEmit(true)'
             [disabled]='!form.valid || reqPending || isReadOnly'></btn>
      </div>
    </div>
    <div class='wrapperCompetitionsBlock__subtitle'>How do you want your officials referenced in the system? Indicate the appropriate title
      for each official dependent on how big the crew is for a specific game.
    </div>


    <div formArrayName='arrControls' class='contentSetupCompetition'>
      <div class='titleOfficialLabels'>Official Labels</div>
      <div class='headersOfficialLabels'>
        <div
             *ngFor='let headerItem of form.controls?.arrTableHeader?.value; let idxHeader=index; let firstHeader=first;  let lastHeader=last'
             class='headersOfficialLabels__item {{firstHeader ? "o-flex-jc-initial":""}}'>
          {{ headerItem }}
          <meSvg *ngIf='headerItem=="Default Crew Size" || headerItem=="Official Self-Assign"' svgName='question_grey&16'></meSvg>
        </div>
      </div>

      <div *ngFor='let item of arrControls?.controls; let idxItem=index; let firstItem=first;  let lastItem=last' [formGroupName]='idxItem'
           class='tableItemLabel'>
        <div class='tableItemLabel__item o-flex-jc-initial'>Official #{{ idxItem + 1 }}</div>
        <ng-container formArrayName='labels'>
          <ng-container [formGroupName]='idxLabelItem'
                        *ngFor='let labelItem of getArrLabels(item); let idxLabelItem=index; let firstLabelItem=first;  let lastLabelItem=last'>
            <div class='tableItemLabel__item'>
              <!--              <span class='w'>{{arrControls?.length ? arrControls[idxItem]?.label?.value: ''}}=={{idxItem}}=={{idxLabelItem}}</span>-->
              <!--              <span class='w'>{{getArrLabels(item)![] }}=={{idxItem}}=={{idxLabelItem}}</span>-->
              <inputCtrl *ngIf='idxLabelItem >= idxItem' formControlName='label' height='32'
                         [isTextCenter]='true' fontSize='14' [bcg]='labelItem?.controls?.label?.disabled ? "grey_4":"white"'
                         [placeholder]='idxItem > idxLabelItem ? "":"-"'></inputCtrl>
              <div *ngIf='idxItem > idxLabelItem' class='tableItemLabel__item__noneInput'>--</div>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <btnWrap *ngIf='!competitionS.competition?.id' type='col'>
        <btn text='Save & Proceed to Next Step' bcg='blueDark' (click)='competitionsBtnsEmit(true)'
             [disabled]='!form.valid || reqPending || isReadOnly'></btn>
        <btn text='Save & Close' bcg='white' (click)='competitionsBtnsEmit(false)' [disabled]='!form.valid || reqPending || isReadOnly'></btn>
      </btnWrap>
    </div>
  </div>
</div>

<!--<ng-template #spinner>-->
<!--  <mat-spinner diameter='40'></mat-spinner>-->
<!--</ng-template>-->