import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceService } from '@services/device.service';
import { OtherService } from '@services/other.service';
import { ProfileService } from '@app/profile/profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { LocationService } from '@components/__blocks/location/location.service';
import { HelperClass } from '@classes/Helper-Classes';
import { BtnComponent } from '@components/btn/btn.component';
import { FiltersService } from '@components/filters/filters.service';
import { MeTableService } from '@components/_table/me-table.service';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { BehaviorSubject, switchMap } from 'rxjs';
import { IResponse } from '@models/response-and-request';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { rout_profileUser } from '@app/profile/profile';
import { ClassUser } from '@models/user';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { CdkScrollable } from '@angular/cdk/overlay';
import { DataTableDirective } from '@components/_table/directives/data-table.directive';
import { LinkPageSearchFilterComponent } from '@components/__settingsRequest/link-page-search-filter/link-page-search-filter.component';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import { TableHeaderComponent } from '@components/_table/table-header/table-header.component';
import { TableCeilUserComponent } from '@components/_table/table-ceil-user/table-ceil-user.component';
import { ItemTableDirective } from '@components/_table/directives/item-table.directive';
import { CeilTableDirective } from '@components/_table/directives/ceil-table.directive';
import { StatusComponent } from '@components/status/status.component';
import { TableCeilComponent } from '@components/_table/table-ceil/table-ceil.component';
import { MatMenuModule } from '@angular/material/menu';
import { TableCeilActionsComponent } from '@components/_table/table-ceil-actions/table-ceil-actions.component';
import { GetWidthMatMenuPipe } from '@pipes/get-width-mat-menu.pipe';
import { TableEmptyComponent } from '@components/_table/table-empty/table-empty.component';
import { TableInfiniteLoadingComponent } from '@components/_table/table-infinite-loading/table-infinite-loading.component';
import { PaginationWithSettingsComponent } from '@components/__settingsRequest/pagination-with-settings/pagination-with-settings.component';
import { StopPropagationDirective } from '@directives/stop-propagation.directive';
import { ClassFilterDrop, ClassFilterInput, IForClassForFilters } from '@components/filters/filters';
import { ClassCeilTableHeader, IForClassForTable } from '@components/_table/meTable';
import { MainService } from '@services/main.service';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { UserRoleToTitleCasePipe } from '@pipes/user/user-role-to-title-case.pipe';
import { GroupProfileService } from '@app/group-profile/group-profile.service';
import { MeService } from '@services/me.service';
import {
  IPopupInvitePeoples,
  PopupInvitePeoplesComponent,
} from '@components/__popup-windows/popup-invite-peoples/popup-invite-peoples.component';
import { IDataPopup, PopupService } from '@services/popup.service';
import { PopupAssignNewRoleComponent } from '@components/__popup-windows/popup-assign-new-role/popup-assign-new-role.component';
import { PopupConfirmComponent } from '@components/__popup-windows/popup-confirm/popup-confirm.component';
import { IRequest_for_inviteUserToGroup } from '@app/group-profile/groupProfile';
import { AdminPermissionDirective } from '@directives/admin-permission.directive';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';
import { rout_groupProfile } from '@app/app.module';

@UntilDestroy()
@Component({
  selector: 'app-group-profile-management',
  standalone: true,
  imports: [CommonModule, WrapPageDirective, CdkScrollable, DataTableDirective, LinkPageSearchFilterComponent, FiltersComponent, BtnComponent, FiltersSelectedComponent, TableHeaderComponent, TableCeilUserComponent, ItemTableDirective, CeilTableDirective, StatusComponent, TableCeilComponent, MatMenuModule, TableCeilActionsComponent, GetWidthMatMenuPipe, TableEmptyComponent, TableInfiniteLoadingComponent, PaginationWithSettingsComponent, StopPropagationDirective, UserRoleToTitleCasePipe, AdminPermissionDirective, GetTableEmptyPipe],
  templateUrl: './group-profile-management.component.html',
  styleUrls: ['./group-profile-management.component.scss'],
  providers: [FiltersService, MeTableService, SettingsRequestService], // for-filters=== for-table=== for-settings===
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// !!! IForClassForFilters for-filters=== // !!! IForClassForTable for-table===
export class GroupProfileManagementComponent extends HelperClass implements IForClassForFilters, IForClassForTable<ClassUser>, OnInit {
  @ViewChild('btnMobileActionsRef') btnMobileActionsRef?: BtnComponent;
  dataTable$ = new BehaviorSubject<IResponse<ClassUser>>({}); // for-table===
  arrContent$ = new BehaviorSubject<Array<ClassUser>>([]); // for-table===

  // readonly class_ngContent_btn_for_nothingList = class_ngContent_btn_for_nothingList;
  readonly rout_groupProfile = rout_groupProfile;
  readonly rout_profileUser = rout_profileUser;
  readonly widthPage = 956;

  constructor(
    private mainS: MainService,
    public router: Router,
    public route: ActivatedRoute,
    public deviceS: DeviceService,
    public profileS: ProfileService,
    public groupProfileS: GroupProfileService,
    public meS: MeService,
    public otherS: OtherService,
    private formBuilder: UntypedFormBuilder,
    public locationS: LocationService,
    public filtersS: FiltersService, // for-filters===
    public meTableS: MeTableService<ClassUser>, // for-table===
    public settingsRequestS: SettingsRequestService, // for-settings===
    private gameS: GameService, // !!! перенести потом массив компетишнов в CompetitionService
    private popupS: PopupService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    // this.locationS.reset();
    // this.locationS.setData(this.groupProfileS.group$.getValue()?.groupAddress || undefined);
    this.setAllData();
  }

  ngOnInit() {
    // setTimeout(() => {
    //     console.log('arrLinkObj_groupProfile :', this.groupProfileS.arrLinkObj_groupProfile);
    //     console.log('currentLink :', this.groupProfileS.currentLinkObj?.currentLink);
    // }, 777)
  }

  // !!! если есть в фильтрах компетишн, то обязательно надо в AppModule к роуту прикрутить CompetitionsForFiltersResolver
  setAllData(): void {
    this.setDataForTable(); // for-table===
    this.setFilters(); // for-filters===
    this.settingsRequestS.setAllData(this.isFirstLoadPageSub$); // for-settingsDynamicUrl=== создание ссылки, чтобы можно было использовать в сервисе
    this.groupProfileS.setCurrentLink_groupProfile('userManagement'); // for-currentLink===
    this.subscribeToSettings(); // for-settings===
  }

  // === SETTINGS ===============================
  subscribeToSettings(): void {
    this.settingsRequestS.settings$.pipe(
      // switchMap((res) => {
      //   return this.mainS.getListUsersForGroup({ params: this.settingsRequestS.settingsForSendToServer }, this.meS.meCurrentGroup?.id!); // this.groupProfileS.idGroup
      // }),
      switchMap((res) => this.meTableS.getSubject_for_groupProfile_userManagement()),
      untilDestroyed(this),
    ).subscribe((res) => {
      // this.meTableS.setDataAfterResponseServer(res, this.settingsRequestS.settings.typeEmitSetting); // for-table===
      // this.meTableS.endLoadingTable();
      this.cd.detectChanges();
    });
  }

  // === FILTERS ==========================================
  setFilters(): void {
    const search = new ClassFilterInput({ typeFilter: 'search' });
    // const roles = new ClassFilterDrop({typeFilter: '', visible: });
    const competitions = new ClassFilterDrop({
      typeFilter: 'competitions',
      arrayForDropdown: this.gameS.arrCompetition$.getValue() || [],
    });
    this.filtersS.setFilters([search, competitions], true, true);
  }

  // === TABLE ======================================================
  setDataForTable(): void {
    this.meTableS.dataTable$ = this.dataTable$; // !!! создание ссылки. Чтобы можно было использовать в MeTableService
    this.meTableS.arrContent$ = this.arrContent$; // !!! создание ссылки. Чтобы можно было использовать в MeTableService
    const arrayCeilHeader: Array<ClassCeilTableHeader> = [
      new ClassCeilTableHeader({ text: 'Name', isChx: true }),
      new ClassCeilTableHeader({ text: 'Role' }),
      new ClassCeilTableHeader({ text: 'Date Added' }),
      new ClassCeilTableHeader({ text: '', isCentered: true }),
      new ClassCeilTableHeader({ text: '', isCentered: true }),
    ];
    const arrWidthCeilTable: Array<number> = [356, 162, 153, 217, 68]; // !!! ширина для каждой ячейки для Desktop => сумма должна совпадать с this.widthPage
    this.meTableS.setDataForTable(arrWidthCeilTable, arrayCeilHeader, this.widthPage, true);
  }


  // === ACTIONS FOR mManage Permissions ================
  assignAsPayor(item: ClassUser): void {
    console.log('assignAsPayor :', item);
  }

  removeAsPayor(item: ClassUser): void {
    console.log('removeAsPayor :', item);
  }

  removeFromCompetition(item: ClassUser): void {
    console.log('removeFromCompetition :', item);
  }

  // === ACTIONS FOR ITEM TABLE ================================
  assignNewRole(item: ClassUser): void {
    console.log('assignNewRole :', item);
    const dataPopup: IDataPopup = { width: '480px', user: item };
    this.popupS.open(PopupAssignNewRoleComponent, dataPopup).then((res: any) => {
      console.log('open(PopupAssignNewRoleComponent :', res);
      if (!res) return;
    });
  }

  removeFromGroup(item: ClassUser): void {
    console.log('removeFromGroup :', item);
    const dataPopup: IDataPopup = {
      width: '480px',
      textTitle: 'Remove from group',
      text: `Are you sure you want to permanently remove ${item.nameFormatted} from your group? This will be irreversible.`,
      textBtnApply: 'Remove User',
      colorBtnApply: 'red',
    };
    this.popupS.open(PopupConfirmComponent, dataPopup).then((res: any) => {
      console.log('open(PopupAssignNewRoleComponent :', res);
      if (!res) return;
    });
  }

  // === OTHER ACTIONS ========================================
  inviteUserToGroup(): void {
    const dataPopup: IDataPopup & IPopupInvitePeoples = {
      width: '480px',
      typeInvite: 'inviteUserToGroup',
      titleText: 'Invite New User',
      subTitleText: 'Invitations are sent by email and they\'ll be pending until they accept your invite to join your group and an account has been created.',
      show_dropUserRole: true,
      path: 'groupProfile_userManagement',
    };
    this.popupS.open(PopupInvitePeoplesComponent, dataPopup).then((res: IRequest_for_inviteUserToGroup) => {
      console.log('open (PopupInvitePeoplesComponent) :', res);
      if (!res) return;
    });
  }
}
