import { Pipe, PipeTransform } from '@angular/core';
import { OtherService } from '@services/other.service';
import { ClassGame, ClassGameOfficial } from '@app/dir_group_assignor/games/game';
import { MeService } from '@services/me.service';

@Pipe({ name: 'getMeGoFromGame', standalone: true })
export class GetMeGoFromGamePipe implements PipeTransform {

  constructor(
    private meS: MeService,
    private otherS: OtherService,
  ) {
  }

  // !!! for official. Получить ClassGameOfficial в котором заасайнен
  transform(game: ClassGame): ClassGameOfficial | undefined {
    return this.otherS.getMeGoFromGame(this.meS.meId, game);
  }

}
