import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnComponent } from '@components/btn/btn.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDataPopup } from '@services/popup.service';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';

@Component({
  selector: 'popupCongrats',
  standalone: true,
  imports: [CommonModule, BtnComponent, BtnWrapComponent],
  templateUrl: './popup-congrats.component.html',
  styleUrls: ['./popup-congrats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupCongratsComponent {
  constructor(
    // public mainS: MainService,
    // public routesS: RoutesService,
    public dialogRef: MatDialogRef<PopupCongratsComponent>,
    @Inject(MAT_DIALOG_DATA) public dataPopup?: IDataPopup,
  ) {
  }

  close(): void {
    this.dialogRef.close();
    // this.routesS.navigate()
  }
}
