import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TColor } from '@models/ICssStyles';
import { GetStylesPipe } from '@pipes/css/get-styles.pipe';
import { BtnComponent } from '@components/btn/btn.component';

@Component({
  selector: 'btnAndLine[text][bcg]',
  standalone: true,
  imports: [CommonModule, GetStylesPipe, BtnComponent],
  templateUrl: './btn-and-line.component.html',
  styleUrls: ['./btn-and-line.component.scss'],
})
export class BtnAndLineComponent {
  @Input() text!: string;
  @Input() bcg!: TColor; // цвета кнопки
  @Input() colorLine: TColor = 'grey_2';

  @Input() w?: string | number = '';

  @Output() emit = new EventEmitter()
}
