import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { HelperClass } from '@classes/Helper-Classes';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { CompetitionService } from '@app/dir_group_assignor/competitions/competition.service';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { BtnComponent } from '@components/btn/btn.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { ClassCompetition, ClassCompetitionLevel } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { MainService } from '@services/main.service';
import { BtnAddAnotherComponent } from '@components/btn-add-another/btn-add-another.component';
import {
  CompetitionsNavigationComponent,
} from '@app/dir_group_assignor/competitions/helperComponentsCompetitions/competitions-navigation/competitions-navigation.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { THttpMethod } from '@services/base-api';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import {
  DeleteItemForCompetitionsComponent,
} from '@app/dir_group_assignor/competitions/helperComponentsCompetitions/delete-item-for-competitions/delete-item-for-competitions.component';
import { ClassYesNo, defaultNameMatOption } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { CheckActiveService } from '@app/dir_group_assignor/competitions/services/checkActiveService';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';
import { AuthenticatorDirective, AuthenticatorService } from '@directives/authenticator-hide.directive';

interface IFormCompetitionsLevels {
  arrControls?: FormArray<FormGroup<IFormItemCompetitionsLevels>>;
}

interface IFormItemCompetitionsLevels {
  id?: FormControl<string>;
  competitionId?: FormControl<string>;
  level?: FormControl<string>;
  // certificationRequired?: FormControl<string>;
  certificationRequiredDrop?: FormControl<ClassYesNo>;
}

@UntilDestroy()
@Component({
  selector: 'competitionsLevels',
  standalone: true,
  imports: [AuthenticatorDirective, CommonModule, SvgAndTextComponent, FieldComponent, InputCtrlComponent, BtnComponent, ReactiveFormsModule, BtnWrapComponent, DropFormCtrlComponent, BtnAddAnotherComponent, CompetitionsNavigationComponent, MatProgressSpinnerModule, SvgComponent, DeleteItemForCompetitionsComponent, DropdownComponent],
  templateUrl: './competitions-levels.component.html',
  styleUrls: ['./competitions-levels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetitionsLevelsComponent extends HelperClass implements OnInit {
  form!: FormGroup<IFormCompetitionsLevels>;
  defaultNameMatOption = defaultNameMatOption;
  isReadOnly!: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder, // UntypedFormBuilder, FormBuilder
    public competitionS: CompetitionService,
    public mainS: MainService,
    private apiCompetitionS: ApiCompetitionService,
    public route: ActivatedRoute,
    public cd: ChangeDetectorRef,
    private readonly checkActiveService: CheckActiveService,
    private authenticatorService: AuthenticatorService
  ) {
    super(cd);
    // this.route.params.pipe(untilDestroyed(this)).subscribe((params) => this.competitionS.checkParams(params));
    this.createForm();
  }

  ngOnInit() {
    this.isReadOnly = !this.authenticatorService.isAllow(['GROUP_ASSIGNOR', 'SUB_ASSIGNOR']);
    this.isReadOnly ? this.form.disable() : this.form.enable();
  }

  // === FORM ==============
  private createForm(): void {
    this.form = this.formBuilder.group({
      arrControls: this.formBuilder.array([]),
    });
    this.competitionS.levels?.forEach((el) => {
      this.arrControls.push(this.formBuilder.group({
        ...el,
        competitionId: this.competitionS.competition?.id || '',
      }));
    });

    // !!! from setForm()
    this.competitionS.levels?.forEach((el, idxArrControls) => {
      this.arrControls.clear();
      this.competitionS.competition?.levels?.forEach((item) => this.addNew(item));
    });
    // !!! from subscribeToCompetition inside this.competitionS.getCompetitionLevels
    const competition = this.competitionS.competition;
    const defaultLevel = competition?.levels?.find((el) => el?.level == defaultNameMatOption);
    if (competition?.levels?.length && competition?.levels?.length < 2 && defaultLevel) this.addNew();
    setTimeout(() => this.cd.detectChanges());
  }

  get arrControls(): FormArray<FormGroup<IFormItemCompetitionsLevels>> {
    return this.form?.controls?.arrControls!;
  }

  addNew(item?: ClassCompetitionLevel): void {
    const newFormGroup: FormGroup<IFormItemCompetitionsLevels> = this.formBuilder.group({
      id: [item?.id || ''],
      competitionId: [item?.competitionId || ''],
      level: [item?.level || '', Validators.required],
      certificationRequiredDrop: [item?.certificationRequiredDrop || new ClassYesNo(false)],
    });
    this.arrControls.push(newFormGroup);
    // this.cd.detectChanges();
  }

  // === competitions Btns Emit =========================
  async competitionsBtnsEmit(isNext: boolean): Promise<void> {
    const competitionId = this.competitionS?.competition?.id;
    const isActiveModal = await this.checkActiveService.checkActive(competitionId, true);
    if (isActiveModal) {
      if (!competitionId) this.methodCompetitionLevels(isNext, 'post'); // create
      if (competitionId) this.methodCompetitionLevels(isNext, 'put'); // update
    }
  }

  methodCompetitionLevels(isNext = false, httpMethod: THttpMethod): void {
    if (this.startRequest()) return;
    const sendObj: { id: string, levels: Array<ClassCompetitionLevel> } = {
      id: this.competitionS.competition?.id!,
      levels: this.form.getRawValue().arrControls?.map((el) => ({
        ...el,
        // certificationRequired: el.certificationRequired ? el.certificationRequired?.toLowerCase() === 'yes' : 'no',
        // certificationRequired: el.certificationRequiredDrop?.valueBoolean,
      })) as Array<ClassCompetitionLevel>,
    };

    this.competitionS.addCompetitionIdBeforeSendToServer(sendObj.levels);

    // this.apiCompetitionS.methodCompetitionLevels(sendObj, this.competitionS.competition?.id!, httpMethod).toPromise()
    this.apiCompetitionS.methodCompetitionLevels(sendObj, httpMethod).toPromise()
      .then((res?: Pick<ClassCompetition, 'levels'>) => {
        if (!res?.levels) return;
        this.competitionS.competition.levels = res.levels;
        isNext ? this.competitionS.nextStep('levels') : this.competitionS.goToDashboard();
      })
      .catch((err: any) => {
      })
      .finally(() => this.endRequest());
  }

}

// subscribeToCompetition(): void {
//   this.competitionS.competition$.pipe(untilDestroyed(this)).subscribe((competition?: ClassCompetition) => {
//     if (!competition) return;
//     if (this.competitionS.levels?.length) this.setForm('111');
//     this.competitionS.getCompetitionLevels(this.competitionS.competition.id!).toPromise().then((res?: Array<ClassCompetitionLevel>) => {
//       this.setForm('222');
//       const defaultLevel = competition?.levels?.find((el) => el?.level == defaultNameMatOption);
//       if (competition?.levels?.length && competition?.levels?.length < 2 && defaultLevel) this.addNew();
//     });
//   });
// }
// setForm(str: string): void {
//   this.competitionS.levels?.forEach((el, idxArrControls) => {
//     this.arrControls.clear();
//     this.competitionS.competition?.levels?.forEach((item) => this.addNew(item));
//   });
//   setTimeout(() => this.cd.detectChanges());
// }
