import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AccountExternalModel } from '@models/account-external.model';
import { IUnavailableDate } from '@models/IUnavailableDate';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IHeight, IWidth, TClassForText, TColor } from '@models/ICssStyles';
import { ClassGame, ClassGameOfficial } from '@app/dir_group_assignor/games/game';
import { PopupConfirmComponent } from '@components/__popup-windows/popup-confirm/popup-confirm.component';
import { ClassReport } from '@app/dir_group_assignor/reports/report';
import { ClassUser, TUserRoleUpperCase } from '@models/user';
import { TCurrentLink } from '@classes/dictionary';
import { AnnouncementItem, Announcements } from '@app/dir_group_assignor/announcements/models/announcements';
import { ClassCompetition } from '@app/dir_group_assignor/competitions/ClassCompetition';

export interface IDataPopup {
  game?: ClassGame;
  games?: Array<ClassGame>;
  go?: ClassGameOfficial;
  arrGo?: Array<ClassGameOfficial>;
  width?: string;
  unavailableDate?: IUnavailableDate;
  currentLink?: TCurrentLink; // 'current' | 'past'

  competition?: ClassCompetition;

  height?: string;
  isClone?: boolean; // !!! если isClone то при клонировании id игры НЕ отправлять и запрос должен быть POST - для создания игры
  id?: string;
  officialFee?: number;
  officialsLists?: [];

  title?: string | { [key: string]: string; }; // !!! иправить потом это гавнокод Гранта
  titleText?: string;
  subTitleText?: string;
  titleCenter?: boolean; // если true, то текст title по середине делать
  content?: string;
  cancelGameNotes?: boolean;
  notesIsRequired?: boolean;

  textBtnApply?: string;
  textBtnCancel?: string;
  colorBtnApply?: TColor;
  colorBtnCancel?: TColor;
  marginTopForBtn?: number;

  showBtnApply?: boolean;
  showBtnCancel?: boolean;

  editNotesGame?: boolean;
  notes?: string;
  disabledNotes?: boolean;

  type?: 'row' | 'col'; // расположение кнопок (горизонтально или вертикально)
  // color?: 'green' | 'blue'; // цвет кнопки apply
  color?: TColor; // цвет кнопки apply
  swapBtn?: boolean; // если передаем true, то меняем местами кнопки // по умолчанию белая(cancel) справа

  emojiForReport?: boolean;

  textTitle?: string;
  typeTextTitle?: TClassForText;
  textTitle2?: string;
  text?: string;
  placeholder?: string;

  email?: string;
  phone?: string;

  message?: string;
  titleBlock?: string;
  colorMessage?: TColor;

  gameId?: string;
  transactionFees?: number | string;
  totalCoins?: number | string;

  forAssign?: boolean; // для того чтобы не приглашать судей, а сразу их ассайнить на роль. Используется в деталке репорта

  eventUploadLogo?: Event;

  nameComponent?: string; // only for PopupForAnyoneComponent ===================

  time?: string; // for PopupTimeComponent
  isEdit?: boolean;
  announcement?: Announcements;
  announcementItem?: AnnouncementItem;
  typeAnnouncement?: string;

  report?: ClassReport;
  isReport?: boolean;
  user?: ClassUser;
  userRoleUpperCase?: TUserRoleUpperCase;
  arrIds?: Array<string>;
  gameIds?: Array<string>;

  accounts?: AccountExternalModel[];
  isSelectPaymentType?: boolean;
  isPayout?: boolean;
  balance?: number;
  balanceInstant?: number;
  method?: string;
  controlButtons?: {
    'goBack'?: { text: string; };
    'proceed'?: { text: string; };
  };
  popupType?: String;
  data?: {
    currentType: 'weekly' | 'biweekly' | 'monthly' | null;
    newType: 'weekly' | 'biweekly' | 'monthly';
  };
}

export type IDataPopup_forPopupConfirm = Pick<IDataPopup, 'textTitle' | 'textTitle2' | 'text' | 'textBtnApply' | 'textBtnCancel' | 'swapBtn' | 'marginTopForBtn'>

@Injectable({ providedIn: 'root' })
export class PopupService {
  // widthForPopup$ задаётся в DeviceService

  public widthForPopup$ = new BehaviorSubject<IWidth>({ minWidth: '400px', maxWidth: '400px' }); // panelClass для cdk-overlay-pane
  public heightForPopup$ = new BehaviorSubject<IHeight>({ minHeight: 'auto', maxHeight: 'auto' }); // panelClass для cdk-overlay-pane

  get widthPopup(): IWidth {
    return this.widthForPopup$.getValue();
  }

  get heightPopup(): IHeight {
    return this.heightForPopup$.getValue();
  }

  // // если надо перед закрытием текущей страницы вывести попап окно. И там если нажал ОК то ушел со страницы, если нажал Cancel, то остался на странице
  // beforeLeavingCurrentPage = { state: false, titleForPopup: '' };

  constructor(
    private dialog: MatDialog,
  ) {
  }

  // public async newOpen(popupComponent: any, dataPopup: IDataPopup, disableClose = false, panelClass?: string | string[]) {
  //   // let promise: new Promise() | null = null;
  //   // let promise = new Promise()
  //   let promise;
  //   if (popupComponent) promise = this.dialog.open(popupComponent, this.getDataPopup(dataPopup, disableClose, panelClass)).afterClosed().toPromise();
  //   else promise = new Promise(resolve => resolve({ noPopup: true }));
  //
  //   let result = await promise;
  //   return result
  // }

  // если type==null => не показывать popup // example => this.popupService.open('PopupComponent', dataPopup)
  public open(popupComponent: any, dataPopup: IDataPopup, disableClose = false, panelClass?: string | string[]): Promise<any> {
    // const popup = this.dialog.open(popupComponent, this.getDataPopup(dataPopup, disableClose, panelClass))
    if (popupComponent)
      return this.dialog.open(popupComponent, this.getDataPopup(dataPopup, disableClose, panelClass)).afterClosed().toPromise();
    else return new Promise(resolve => resolve({ noPopup: true }));
  }

  public open$(
    popupComponent: any,
    dataPopup: IDataPopup,
    disableClose = false,
    panelClass?: string | string[],
  ): Observable<any> {
    // console.log(dataPopup);

    return popupComponent
      ? this.dialog.open(popupComponent, this.getDataPopup(dataPopup, disableClose, panelClass)).afterClosed()
      : of({ noPopup: true });
  }

  private getDataPopup(dataPopup: IDataPopup, disableClose = false, panelClass?: string | string[]): MatDialogConfig {
    const widthForPopup = document.documentElement.clientWidth > 1024 && dataPopup.width ? {
      minWidth: dataPopup.width,
      maxWidth: dataPopup.width,
    } : this.widthForPopup$.getValue();
    // console.log(widthForPopup.minWidth);
    const heightForPopup = dataPopup.height ? {
      minHeight: dataPopup.height,
      maxHeight: dataPopup.height,
    } : this.heightForPopup$.getValue();

    // return { ...this.widthForPopup$.getValue(), data: dataPopup, disableClose, panelClass, autoFocus: false };
    return { ...widthForPopup, ...heightForPopup, data: dataPopup, disableClose, panelClass, autoFocus: false };
  }

  // === POPUP CONFIRM =========================================
  openPopupConfirm(dataPopup: IDataPopup_forPopupConfirm, disableClose = false): Promise<any> {
    return this.open(PopupConfirmComponent, dataPopup, disableClose);
  }

  openPopupUnsaved(swapBtn: boolean): Promise<boolean> {
    const dataPopup: IDataPopup_forPopupConfirm = {
      textTitle: 'You have unsaved assignments.',
      textTitle2: 'Are you sure you want to leave page?',
      text: 'We recommend you go back and save your assignments or you’ll lose your progress. This is irreversible!',
      textBtnApply: 'I Understand',
      textBtnCancel: 'Go Back & Save',
      swapBtn,
      marginTopForBtn: 24,
    };
    return this.openPopupConfirm(dataPopup, true);
  }

  openPopupUnsavedForFilter(swapBtn: boolean): Promise<boolean> {
    const dataPopup: IDataPopup_forPopupConfirm = {
      textTitle: 'You have unsaved assignments.',
      textTitle2: '',
      text: 'Would you like to save your assignments or you’ll lose your progress?',
      textBtnApply: 'Save Progress',
      textBtnCancel: 'I Understand',
      swapBtn,
      marginTopForBtn: 24,
    };
    return this.openPopupConfirm(dataPopup, true);
  }

  openPopupUnsavedForAdjustmentStatuses(swapBtn: boolean): Promise<boolean> {
    const dataPopup: IDataPopup_forPopupConfirm = {
      textTitle: 'You have an unsaved adjustment status. Are you sure you want to leave page?',
      textTitle2: '',
      text: 'We recommend you go back and save your adjustment statuses or you’ll lose your progress.',
      textBtnApply: 'I Understand',
      textBtnCancel: 'Go Back & Save',
      swapBtn,
      marginTopForBtn: 24,
    };
    return this.openPopupConfirm(dataPopup, true);
  }

  closeAllPopups(): void {
    this.dialog.closeAll();
    // this.activePopups$.next([]);
  }

  // // === BeforeLeavingCurrentPage =====================
  // resetBeforeLeavingCurrentPage(): void {
  //   this.beforeLeavingCurrentPage = { state: false, titleForPopup: '' };
  // }
  //
  // async checkConfirmCanExitDialog(): Promise<boolean> {
  //   if (this.beforeLeavingCurrentPage.state) {
  //     let result = true;
  //     await this.open(PopupComponent, { title: this.beforeLeavingCurrentPage.titleForPopup }).then((res) => {
  //       result = res;
  //       if (result) this.resetBeforeLeavingCurrentPage();
  //     });
  //     return result;
  //   } else {
  //     return true;
  //   }
  // }

}
