import { Injectable } from '@angular/core';
import { BehaviorSubject, delay, Observable, of } from 'rxjs';
import { ClassCeilTableHeader, IForTable, ITableEmpty, TypeEmitSettingRequest } from '@components/_table/meTable';
import { OtherService } from '@services/other.service';
import { ClassSettingsRequest, IResponse } from '@models/response-and-request';
import { TSvgName } from '@components/__svg_img/svg/forSvg';
import { DeviceService } from '@services/device.service';
import { TypeSorting, TypeSortTable } from '@components/sortBy/sortBy';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClassGame, ClassGameOfficial } from '@app/dir_group_assignor/games/game';
import { UtilsService } from '@services/utils.service';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { MainService } from '@services/main.service';
import { catchError, map, tap } from 'rxjs/operators';
import { ClassCompetition } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { MeService } from '@services/me.service';
import { TPath } from '@app/app.module';
import { RoutesService } from '@services/routes.service';
import { ClassUser } from '@models/user';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';
import { UserGameTransferInfoDto } from '@app/dir_group_assignor/payments/models/user-game-transfer-info-dto';


@UntilDestroy()
@Injectable()
export class MeTableService<T> {
  path: TPath;
  needUseDynamicFilters = false;

  svgNameForActions: TSvgName = 'circle_3points_grey1_2&20';

  minWidthFor_sortByCeil = 98; // !!! меньше нельзя
  // heightHeaderTable: number = 43;
  minHeightItemTable: number = 72;
  // paddingTopBottom_ItemTable: number = 12;
  paddingLeft_forCeil: number = 24;

  get showPagination(): boolean {
    const result = !!this.arrContent?.length && this.dataTable && !this.loadingTable && this.deviceS.isDesktop;
    return result;
  }

  // === WIDTH FOR EVERY CEIL for Desktop =====================================
  arrWidthCeilTable$ = new BehaviorSubject<Array<number>>([]);

  get arrWidthCeilTable(): Array<number> {
    return this.arrWidthCeilTable$.getValue();
  }

  // === HEADER ==============================
  arrHeaderTable$ = new BehaviorSubject<Array<ClassCeilTableHeader>>([]);

  // === TABLE CONTENT ===================================
  dataTable$ = new BehaviorSubject<IResponse<T>>({});
  arrContent$ = new BehaviorSubject<Array<T & IForTable>>([]);

  get dataTable(): IResponse<T> {
    return this.dataTable$.getValue();
  }

  get arrContent(): Array<T & IForTable> {
    return this.arrContent$.getValue();
  }

  // === !!! for GameOfficial (написал по быстрому. работоспособность не проверял)
  updateGameOfficial(newGo: ClassGameOfficial): void {
    let currentGame: ClassGame | undefined;
    let currentGo: ClassGameOfficial | undefined;
    this.arrContent.forEach((game: ClassGame) => {
      if (game.id === newGo.gameId) {
        currentGame = game;
        currentGo = game.gameOfficials?.find(go => go.id === newGo.id);
      }
    });
    if (!currentGo) {
      console.error('updateGameOfficial НЕ НАЙДЕН GameOfficial :', '  currentGo :', currentGo, '  newGo :', newGo);
      return;
    }
    if (!currentGame) {
      console.error('updateGameOfficial НЕ НАЙДЕН currentGame :', '  currentGame :', currentGame);
      return;
    }
    currentGame = {
      ...currentGame,
      gameOfficials: UtilsService.replaceElemArrayById(currentGame.gameOfficials!, { ...currentGo, ...newGo }),
    };
    this.replaceElemArrayById(currentGame as T & IForTable);
  }

  replaceElemArrayById(newObj: T & IForTable): void {
    if (!newObj) {
      console.error('replaceElemArrayById НЕ НАЙДЕН newObj :', '  newObj :', newObj);
      return;
    }
    const arrGame = UtilsService.replaceElemArrayById(this.arrContent, newObj);
    this.arrContent$.next(arrGame);
  }

  // === if no have arrContent.length ==========================
  tableEmpty$ = new BehaviorSubject<ITableEmpty>({});

  // === for sorting ============================================
  arrTypeSorting: Array<TypeSorting> = []; // !!! какие типы сортировки показывать в выпадающем списке сортировки
  currentTypeSort: Array<TypeSortTable> = []; // это когда из url поступили значения, то нужно выделить текущий выбраный в выпадающем списке в сортировке-->

  // === SETTINGS ===========================
  subscribeToSettings(): void {
    this.settingsRequestS.settingsSub$.pipe(untilDestroyed(this)).subscribe((res) => {
      if (this.settingsRequestS.is_currentPath_games || this.settingsRequestS.is_currentPath_reports
        || this.settingsRequestS.is_currentPath_officials || this.settingsRequestS.is_currentPath_competitions
        || this.settingsRequestS.is_currentPath_groupProfile) {
        this.currentTypeSort = res.sort as Array<TypeSortTable> || []; // !!! for sorting // for-settingsDynamicUrl===
      }
    });
  }

  constructor(
    private routesS: RoutesService,
    private mainS: MainService,
    private apiCompetitionS: ApiCompetitionService,
    private meS: MeService,
    private otherS: OtherService,
    private deviceS: DeviceService,
    private settingsRequestS: SettingsRequestService, // for-settings===
  ) {
    this.path = this.routesS.path!;
    this.subscribeToSettings();
  }

  // !!! после ответе сервера надо обработать и установить данные
  setDataAfterResponseServer(res?: IResponse<T & IForTable>, type?: TypeEmitSettingRequest | null, page?: TPath): void {
    this.dataTable$.next(res || {}); // for-table===
    const content = res?.content || [];

    if (this.meS.ADMIN && !this.meS.adminPermission(page!)) { // !!! для админа нужно проверять permissions => разрешены ли действия с играми
      content?.forEach((el) => el.hiddenChx = true);
    }

    if (this.deviceS.isDesktop) { // !!! для Desktop есть пагинация и поэтому массив данных полностью обновляется
      // this.arrContent$.next(content);
      // this.resetSelectedItems(' setDataAfterResponseServer ');
      if (this.needSave_selectedItems_byChangeSettings) {
        this.checkSelectedItemsForArrContent(content);
        this.checkIsSelectedAll();
      } else {
        this.arrContent$.next(content);
        this.resetSelectedItems(' setDataAfterResponseServer ');
      }
    } else { // !!! для мобилы при скролле вниз страницы нужно добавлять новый массив к существующему
      if (type === 'infiniteLoading' || this.settingsRequestS.check_infiniteLoading()) { // !!! если это бесконечный скролл для мобилы, то надо длбавлять данные в уже существуюший массив
        this.settingsRequestS.infiniteLoading = false;
        this.arrContent$.next([...this.arrContent$.getValue(), ...content]);
      } else { // !!! если из фильтров новый запрос поступил, то нужно перезаписать текущий массив
        this.arrContent$.next(content);
      }
    }
    this.endLoadingTable(); // add 11.05.24
  }

  setDataForTable(arrWidthCeilTable: Array<number>, arrHeaderTable: Array<ClassCeilTableHeader>,
    widthPage: number, needUseDynamicFilters: boolean, tableEmpty?: ITableEmpty | null,
    needSave_selectedItems_byChangeSettings?: boolean): void {
    this.needSave_selectedItems_byChangeSettings = needSave_selectedItems_byChangeSettings || false;
    this.needUseDynamicFilters = needUseDynamicFilters;
    this.arrWidthCeilTable$.next(arrWidthCeilTable);
    this.arrHeaderTable$.next(arrHeaderTable);
    if (tableEmpty) this.tableEmpty$.next(tableEmpty);
    this.consoleLogIfNotCorrectWidthCeil(widthPage);
  }

  // === FOR SORTING ===============================
  // !!! при загрузке страницы
  setArrTypeSorting(arrTypeSorting: Array<TypeSorting>): void {
    this.arrTypeSorting = arrTypeSorting;
  }

  // === SELECTED ITEMS ========================
  isSelectAll$ = new BehaviorSubject<boolean>(false);
  private selectedItemsForAllPagesSub$ = new BehaviorSubject<Array<T & IForTable>>([]); // !!! only for needSave_selectedItems_byChangeSettings==true
  // !!! если надо при переключении пагинации сохранять выбраные элементы. Выбраные элементы будут добавляться в selectedItemsForAllPagesSub$
  private needSave_selectedItems_byChangeSettings = false;

  // !!! при переключении пагинации в setDataAfterResponseServer (после того как получен новый массив с сервера) =>
  // !!! => надо проверять если есть такой элемент в selectedItemsForAllPagesSub$, если есть то надо обновить arrContent
  checkSelectedItemsForArrContent(responseFromServer: Array<T & IForTable>): void {
    const updatedArrContent: Array<T & IForTable> = responseFromServer.map((el) => {
      if (this.selectedIdsForAllPages.includes(el.id!)) return { ...el, isSelect: true };
      return el;
    });
    this.arrContent$.next(updatedArrContent);
  }

  get selectedItemsForAllPages(): Array<T & IForTable> {
    return this.selectedItemsForAllPagesSub$.getValue();
  }

  get selectedIdsForAllPages(): Array<string> {
    return this.selectedItemsForAllPagesSub$.getValue().map(el => el.id!);
  }

  // !!! вызывается в selectAll() & selectItem()
  private checkSelectedItem(elem: T & IForTable): void {
    if (!this.needSave_selectedItems_byChangeSettings) return;
    if (elem.isSelect) { // !!! если выбран, то нужно добавить в массив selectedItemsForAllPages
      // !!! если такого элемента ещё нет, то нужно добавить
      if (!this.selectedItemsForAllPages.find(selectedItem => selectedItem.id === elem.id)) {
        this.selectedItemsForAllPagesSub$.next([...this.selectedItemsForAllPages, elem]);
      }
    } else { // !!! если снят выбор, то нужно удалить из массива selectedItemsForAllPages
      const selectedItems_withoutCurrentElem = this.selectedItemsForAllPages.filter(selectedItem => selectedItem.id !== elem.id);
      this.selectedItemsForAllPagesSub$.next(selectedItems_withoutCurrentElem);
    }
  }

  selectAll(): void {
    if (this.loadingTable$.getValue() || !this.arrContent?.length) return; // !!! если идет запрос на сервер или контента нет, то чекбокс неактивный
    this.isSelectAll$.next(!this.isSelectAll$.getValue());
    const arrContent = this.arrContent$.getValue();
    arrContent?.forEach(el => {
      el.isSelect = this.isSelectAll$.getValue();
      this.checkSelectedItem(el);
    });
    this.arrContent$.next(arrContent);
  }

  private checkIsSelectedAll(): void {
    this.isSelectAll$.next(this.otherS.isSelectAllItems(this.arrContent));
  }

  selectItem(item: T & IForTable): void {
    const arrContent = this.arrContent$.getValue();
    arrContent?.forEach(el => {
      if (el.id === item.id) item.isSelect = !item.isSelect;
      this.checkSelectedItem(el);
    });
    this.arrContent$.next(arrContent);
    this.checkIsSelectedAll();
  }

  get selectedItems(): Array<T> {
    const result: Array<T> = [];
    this.arrContent?.forEach(el => {
      if (el.isSelect) result.push(el);
    });
    const sendObj_settings: ClassSettingsRequest = { ...this.settingsRequestS.settingsForSendToServer };
    if (this.needSave_selectedItems_byChangeSettings && sendObj_settings.statuses != 'NEEDS_APPROVAL') {
      return this.selectedItemsForAllPages;
    } else {
      return result;
    }
  }

  // !!! array id selected items
  get selectedItems_ids(): Array<string> {
    const result: Array<string> = [];
    this.arrContent?.forEach(el => {
      if (el.isSelect) result.push(el.id!);
    });
    if (this.needSave_selectedItems_byChangeSettings) {
      return this.selectedIdsForAllPages;
    } else {
      return result;
    }
  }

  // !!! вызывается в deleteArrItemsById() & deleteArrSelectedItems() & setDataAfterResponseServer()
  resetSelectedItems(forTest?: string): void { // после загрузки нового массива с сервера
    const arrContent = this.arrContent$.getValue()?.map(el => {
      return { ...el, isSelect: false }; // !!! of all checkbox
    });
    this.arrContent$.next(arrContent);
    this.isSelectAll$.next(false);
  }

  // === OTHER =================================
  // замена item в массиве по индексу
  replaceItemByIdx(newItem: T & IForTable, idx: number): void {
    const arrContent = this.arrContent$.getValue();
    if (!arrContent?.length) return;
    arrContent[idx] = newItem;
    this.arrContent$.next(arrContent);
  }

  // удаление item по индексу
  deleteItemByIdx(idx: number): void {
    const arrContent = this.arrContent$.getValue()?.filter((el, i) => idx !== i);
    if (!arrContent?.length) this.otherS.reload(); // если удален последний то перезагрузить страницу, чтобы получить новый массив с сервера
    this.arrContent$.next(arrContent);
  }

  // удаление item по ID
  deleteItemById(id: string): void {
    const arrContent = this.arrContent$.getValue()?.filter((el) => id !== el?.id);
    if (!arrContent?.length) this.otherS.reload(); // если удален последний то перезагрузить страницу, чтобы получить новый массив с сервера
    this.arrContent$.next(arrContent);
  }

  // удаление нескольких items по ID
  deleteArrItemsById(arrIds: Array<string>): void {
    const new_arrItems = this.arrContent$.getValue()?.filter((el) => !arrIds?.includes(el?.id!));
    if (!new_arrItems?.length) this.otherS.reload(); // если удален последний то перезагрузить страницу, чтобы получить новый массив с сервера
    this.resetSelectedItems(' deleteArrItemsById ');
    this.arrContent$.next(new_arrItems);
  }

  // удаление из текущего массива нескольких items которые отмечены чекбоксом (тоесть удаление выбраных элементов)
  deleteArrSelectedItems(): void {
    const new_arrItems = this.arrContent$.getValue()?.filter((el) => !this.selectedItems_ids?.includes(el?.id!));
    if (!new_arrItems?.length) this.otherS.reload(); // если удален последний то перезагрузить страницу, чтобы получить новый массив с сервера
    this.resetSelectedItems(' deleteArrSelectedItems ');
    this.arrContent$.next(new_arrItems);
  }

  // добавление нового item в начало/конец списка
  addNewItem(newItem: T & IForTable, type: 'start' | 'end' = 'start'): void {
    const arrContent = this.arrContent$.getValue();
    if (!arrContent?.length) return;
    type === 'start' ? arrContent.unshift(newItem) : arrContent.push(newItem);
    this.arrContent$.next(arrContent);
  }

  // === LOADING DATA FOR TABLE ========================= для загрузки данных с сервера
  // === loading => если идет запрос на сервер, то нужно блокировать чекбокс и сортировку
  loadingTable$ = new BehaviorSubject<boolean>(false);

  get loadingTable(): boolean {
    return this.loadingTable$.getValue();
  }

  private set loadingTable(value: boolean) {
    this.loadingTable$.next(value);
  }

  startLoadingTable(): boolean {
    if (this.loadingTable) {
      return true;
    } else {
      this.loadingTable = true;
      return false;
    }
  }

  endLoadingTable(): void {
    this.loadingTable = false;
  }

  // === OTHER =========================================
  // !!! page_width_matches_the_table => ширина страницы совпадает с шириной суммы ячеек таблицы
  private consoleLogIfNotCorrectWidthCeil(widthPage: number): void {
    // const page_width_matches_the_table = this.arrWidthCeilTable.reduce((acc, curValue) => acc + curValue) === widthPage;
    // if (!page_width_matches_the_table) console.error(' === !!! the width of the page does NOT match the width of the sum of the table cells !!! === :');
  }

  // !!! WARNING === these methods ONLY work with dynamic filters (SettingsRequestService) ==================================================
  // !!! WARNING === эти методы работают ТОЛЬКО с динамическими фильтрами (SettingsRequestService) ==================================================
  // !!! delay - for openGames. Чтобы анимация при запросе на сервер gif отобразилась минимум весь цикл
  getSubject_forGetGamesList(settings?: ClassSettingsRequest | null, delayForRequest?: number): Observable<IResponse<ClassGame>> {
    if (!this.needUseDynamicFilters) {
      console.error('!!! WARNING ===  getSubject_forGetGamesList() // you cannot use this method without dynamic filters // нельзя использовать этот метод без динамических фильтров :');
      return of({});
    }
    this.startLoadingTable(); // for-table===
    const sendObj_settings: ClassSettingsRequest = { ...this.settingsRequestS.settingsForSendToServer, ...settings };
    return this.getSubject_for_allMethod(this.mainS.getGamesList({ params: sendObj_settings }), delayForRequest);
  }

  getSubject_forGetReports(): Observable<IResponse<ClassGame>> {
    if (!this.needUseDynamicFilters) {
      console.error('!!! WARNING === getSubject_forGetReports() // you cannot use this method without dynamic filters // нельзя использовать этот метод без динамических фильтров :');
      return of({});
    }
    this.startLoadingTable(); // for-table===
    const sendObj_settings: ClassSettingsRequest = { ...this.settingsRequestS.settingsForSendToServer };
    if (sendObj_settings.statuses === 'NEEDS_APPROVAL') {
      sendObj_settings.statuses += ',' + 'PROCESSING';
    }
    return this.getSubject_for_allMethod(this.mainS.getReports({ params: sendObj_settings }));
  }

  // !!! for page /competitions/list
  getSubject_forGetCompetitionList(settings?: ClassSettingsRequest): Observable<IResponse<ClassCompetition>> {
    if (!this.needUseDynamicFilters) {
      console.error('!!! WARNING ===  getSubject_forGetCompetitionList() // you cannot use this method without dynamic filters // нельзя использовать этот метод без динамических фильтров :');
      return of({});
    }
    this.startLoadingTable(); // for-table===
    const sendObj_settings: ClassSettingsRequest = { ...this.settingsRequestS.settingsForSendToServer, ...settings };
    return this.getSubject_for_allMethod(this.apiCompetitionS.getArrCompetition({ params: sendObj_settings }));
  }

  // !!! for page /groupProfile_userManagement
  getSubject_for_groupProfile_userManagement(settings?: ClassSettingsRequest): Observable<IResponse<ClassUser>> {
    if (!this.needUseDynamicFilters) {
      console.error('!!! WARNING === getSubject_for_groupProfile_userManagement() // you cannot use this method without dynamic filters // нельзя использовать этот метод без динамических фильтров :');
      return of({});
    }
    this.startLoadingTable(); // for-table===
    const sendObj_settings: ClassSettingsRequest = { ...this.settingsRequestS.settingsForSendToServer, ...settings };
    return this.getSubject_for_allMethod(this.mainS.getListUsersForGroup({ params: sendObj_settings }, this.meS.meCurrentGroup?.id!));
  }

  getSubject_getListOfficials(): Observable<IResponse<ClassUser>> {
    if (!this.needUseDynamicFilters) {
      console.error('!!! WARNING === getSubject_getListOfficials() // you cannot use this method without dynamic filters // нельзя использовать этот метод без динамических фильтров :');
      return of({});
    }
    this.startLoadingTable(); // for-table===
    const sendObj_settings: ClassSettingsRequest = { ...this.settingsRequestS.settingsForSendToServer };
    return this.getSubject_for_allMethod(
      this.mainS.getListOfficials({ params: sendObj_settings }, this.settingsRequestS.settings.currentLink_officials!),
    );
  }

  //  need correct rename this method
  //  delay - for openGames. Чтобы анимация при запросе на сервер gif отобразилась минимум весь цикл
  private getSubject_for_allMethod<K>(response_observable: Observable<IResponse<K>>, delayForRequest?: number): Observable<IResponse<K>> {
    if (!this.needUseDynamicFilters) {
      console.error('!!! WARNING ===  getSubject_forGetGamesList() // you cannot use this method without dynamic filters // нельзя использовать этот метод без динамических фильтров :');
      return of({});
    }

    return response_observable.pipe(
      delay(delayForRequest || 0), // !!! delay - for openGames. Чтобы анимация при запросе на сервер gif отобразилась минимум весь цикл
      tap((res) => {
        if (this.isNeedUpdatePage(res))
          return;
        // !!! это добавил 18.06.24 => возможно теперь в компоненте не надо вызывать meTableS.setDataAfterResponseServer(). Проверить потом надо
        this.setDataAfterResponseServer(res as IResponse<T & IForTable>);
        this.settingsRequestS.isFirstLoadPageSub$?.next(false);
        this.endLoadingTable(); // for-table=== // andrei надо ли оно здесь, т.к. теперь вызывается в родит.компоненте в subscribe
      }),
      // !!! не надо finalize использовать для endLoadingTable & isFirstLoadPageSub$
      untilDestroyed(this),
      catchError((error) => {
        this.settingsRequestS.isFirstLoadPageSub$?.next(false);
        this.endLoadingTable(); // for-table=== // andrei надо ли оно здесь, т.к. теперь вызывается в родит.компоненте в subscribe
        return of(error);
      }),
    );
  }

  // !!! проверка - если на сервере есть данные, но сейчас поступил пустой массив И если в респонсе на сервере действительно есть данные - то надо заново отправлять запрос на сервер с page=1
  private isNeedUpdatePage<K>(res: IResponse<K>): boolean {
    if (!this.settingsRequestS.isFirstLoadPage) return false;
    // !!! для мобилы если это НЕ первая страница то при первой загрузке страницы установить page=1
    if (this.settingsRequestS.isFirstLoadPage && this.deviceS.isMobile && res?.number !== 0) {
      const currentSettings = this.settingsRequestS.settings;
      this.settingsRequestS.settings = { ...currentSettings, page: 1 };
      return true;
    }
    return false;
  }

  public getSubject_forAssignorFees(settings?: ClassSettingsRequest | null, delayForRequest?: number): Observable<IResponse<UserGameTransferInfoDto>> {
    if (!this.needUseDynamicFilters) {
      console.error('!!! WARNING ===  getSubject_forGetGamesList() // you cannot use this method without dynamic filters // нельзя использовать этот метод без динамических фильтров :');
      return of({});
    }
    this.startLoadingTable(); // for-table===
    const sendObj_settings: ClassSettingsRequest = { ...this.settingsRequestS.settingsForSendToServer, ...settings };
    return this.getSubject_for_allMethod(this.mainS.getAssignorFeesList({ params: sendObj_settings }), delayForRequest);
  }



  public getSubject_forAssignorFeesV2(settings?: ClassSettingsRequest | null, delayForRequest?: number): Observable<IResponse<UserGameTransferInfoDto>> {
    if (!this.needUseDynamicFilters) {
      return of({});
    }
    this.startLoadingTable(); 
    const sendObj_settings: ClassSettingsRequest = { ...this.settingsRequestS.settingsForSendToServer, ...settings };
  
    return this.getSubject_for_allMethod(
      this.mainS.getAssignorFeesListV2({ params: sendObj_settings }).pipe(
        map((response: IResponse<UserGameTransferInfoDto>) => ({
          ...response,
          content: (response.content || []).map((item: any) => ({
            ...item,
            transferAmount: item.transferTotal?.amount ?? 0,
            assignorName: item.assignorName
          }))
        }))
      ),
      delayForRequest
    );
  }
  


}
