import { Injectable } from "@angular/core";
import { catchError, map, Observable, of, tap } from "rxjs";
import { BaseApi } from "./base-api";
import { MeService } from "./me.service";
import { ClassUser, TUserRoleUpperCase, TUserSignup } from "@models/user";
import { AuthService } from "@app/auth/auth.service";
import { MainService } from "./main.service";
import { ILoginResponseDto } from "@app/auth/auth_models";

declare var LOG: any;

@Injectable({ providedIn: 'root' })
export class AuthorizationAppleService {
    constructor(private baseApi: BaseApi, private meService: MeService, private authS: AuthService, private mainService: MainService) {

    }

    public appleSingIn(sendObj: Pick<ClassUser, 'email'>): Observable<ILoginResponseDto> {
        LOG("appleSingIn START");
        LOG("appleSingIn sendObj " + JSON.stringify(sendObj));
        sendObj = ClassUser.preparePropertyFromDropForSendToServer(sendObj);
        return this.baseApi.put(`/api/auth/v1/signinWithApple`, sendObj);

    }

    public appleSingUp(data: AppleSigninData): Observable<TUserSignup | null> {
        LOG("appleSing-Up START");
        let request_forRegistrationAndLogin = { ...data, ...this.authS.queryParams_forRegistration };
        if (!request_forRegistrationAndLogin.role || (request_forRegistrationAndLogin.role && request_forRegistrationAndLogin.role.toUpperCase() === 'GROUP-ASSIGNOR')) {
            delete request_forRegistrationAndLogin.role;
        }

        // request_forRegistrationAndLogin = { ...request_forRegistrationAndLogin, email: data?.idToken! };
        LOG("appleSingUp request_forRegistrationAndLogin " + JSON.stringify(request_forRegistrationAndLogin));
        return this.baseApi.put(`/api/auth/v1/signupWithApple`, request_forRegistrationAndLogin)
            .pipe(
                tap((res: ClassUser) => {
                    LOG("appleSingUp response  " + JSON.stringify(res));
                    const user: ClassUser = { ...res, id: res?.id };
                    if (request_forRegistrationAndLogin.role)
                        user.roleCurrent = request_forRegistrationAndLogin.role as TUserRoleUpperCase;
                    if (request_forRegistrationAndLogin.groupId || res.groupId)
                        user.groupId = request_forRegistrationAndLogin.groupId || res.groupId;
                    this.meService.setMeUser(user, 'MainService appleSingUp');
                    this.mainService.saveGroupId(request_forRegistrationAndLogin.role, user.groupId, res.id);
                    LOG("appleSingUp response END");
                }),
                map(
                    res => {
                        const r = new ClassUser({ ...res, ...{ firstName: data.firstName ?? '', secondName: data.lastName ?? '', email: data.email } });
                        return r;
                    }
                ),
                catchError(x => {
                    LOG("appleSingUp catchError " + x);
                    return of(null);
                })
            );
    }

}

export type AppleSigninData = {
    email?: string,
    code?: string;
    error?: string;
    idToken?: string;
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
    state?: string | null;
    type: 'apple';
};

export type AppleSigninParams = {
    callback?: (data: AppleSigninData) => void;
    redirectUri?: string;
    state?: string;
};
