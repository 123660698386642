import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { OtherService } from '@services/other.service';
import { ITimezone } from '@classes/geo';
import { shortListTimezone } from '@models/fullListTimezone';
import { LocationService } from '@components/__blocks/location/location.service';
import { MAT_SELECT_CONFIG, MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { GetStateInputPipe } from '@pipes/get-state-input.pipe';
import { GetStartEndLetterPipe } from '@pipes/string/get-start-end-letter.pipe';
import { GetStartEndWordFromStringPipe } from '@pipes/string/get-start-end-word-from-string.pipe';
import { GetWidthMatMenuPipe } from '@pipes/get-width-mat-menu.pipe';
import { ErrorComponent } from '@components/__info_text_message_error_warning/error/error.component';
import { TColor } from '@models/ICssStyles';

@Component({
  selector: 'select-timezone',
  templateUrl: './select-timezone.component.html',
  styleUrls: ['./select-timezone.component.scss'],
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, GetStateInputPipe, MatSelectModule, ReactiveFormsModule, GetStartEndLetterPipe, GetStartEndWordFromStringPipe, GetWidthMatMenuPipe, ErrorComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectTimezoneComponent),
      multi: true,
    },
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'forOverlayPanelSelectTimezone' },
    },
  ],
})
export class SelectTimezoneComponent implements ControlValueAccessor, AfterViewInit {
  @Input() bcg: TColor | 'transparent' = 'white'; // background // TColor = 'blueDark' | 'grey' | 'grey_1' | 'white' | 'blue' | 'green' | 'red';

  formCtrl = new UntypedFormControl(null, Validators.required);
  listTimezone: Array<ITimezone> = shortListTimezone as Array<ITimezone>;

  @Output() changeVal = new EventEmitter<ITimezone>();

  // private onChange!: (value: string) => void;
  private onChange!: (value: ITimezone) => void;
  private onTouched!: () => void;

  constructor(
    public locationS: LocationService,
    public otherS: OtherService,
    public elRef: ElementRef,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngAfterViewInit() {
    this.sortListByName();
  }

  writeValue(timezone: ITimezone): void {
    if (!timezone) {
      this.formCtrl.reset();
      return;
    }

    let shortTimezone = this.otherS.editTimezone(this.listTimezone.find(el => el?.value === timezone?.value));
    // если не найдена таймзона в текущем short списке, то берем из полного списка редактируем и добавляем
    if (!shortTimezone) {
      // было так до 8.03.23 // shortTimezone = fullListTimezone.find(el => el?.id === timezone?.id)!;
      shortTimezone = this.otherS.findTimezoneFromList(timezone);
      if (shortTimezone) this.listTimezone.push(this.otherS.editTimezone(shortTimezone)!);
    }

    // this.formCtrl.setValue(shortTimezone || timezone, { emitEvent: false }); // this.selectCtrl.value === ITimezone
    this.formCtrl.setValue(shortTimezone || timezone); // this.selectCtrl.value === ITimezone
    this.cd.detectChanges();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  // === sort list for dropdown by name =====================
  private sortListByName(): void {
    this.listTimezone = this.listTimezone?.sort(function(a, b) {
      if (a.abbrev?.trim()! < b.abbrev?.trim()!) {
        return -1;
      }
      if (a.abbrev?.trim()! > b.abbrev?.trim()!) {
        return 1;
      }
      return 0;
    });
    this.cd.detectChanges(); // !!! NO DELETE
    this.cd.markForCheck(); // !!! NO DELETE
  }

  // === GETTERS & SETTERS =======================
  get value(): string {
    return this.formCtrl?.value;
  }

  get errRequired(): boolean {
    return !!this.formCtrl?.hasError('required') && (!!this.formCtrl?.touched || this.locationS.locTouched$.getValue());
  }

  get valid(): boolean {
    return !!this.formCtrl?.valid;
  }

  get touched(): boolean {
    return !!this.formCtrl?.touched;
  }

  get disabled(): boolean {
    return !!this.formCtrl?.disabled;
  }

}
