<div class='wrapperPopupCreateGroup' [formGroup]='form'>
  <headTitle text='Create Group' svgRight='cross_grey&24' (svgRightEmit)='close()' typeText='text4-blueDark'></headTitle>

<!--  !!! пока что не удалять. Может быть скажут вернуть https://notch.atlassian.net/browse/NOT30-176  -->
<!--  <div class='groupLogo'>Group Logo-->
<!--    <div class='groupLogo__uploadLogo'>-->
<!--      <img [src]='ctrl.logo?.value||"assets/download/download_7.svg"' alt=''>-->
<!--      <uploadLogo (emit)='ctrl.logo?.patchValue($event)'>-->
<!--        <div *ngIf='deviceS.isDesktop$|async' class='contentForUploadLogo'>-->
<!--          <div>Click to upload <span>or drag and drop</span></div>-->
<!--          <span>SVG, PNG, JPG or GIF (max. 800x400px)</span>-->
<!--        </div>-->
<!--        <div *ngIf='deviceS.isMobile$|async' class='contentForUploadLogoMobile o-btn-square-white'>-->
<!--          <meSvg svgName='download_5&20'></meSvg>-->
<!--          Upload photo-->
<!--        </div>-->
<!--      </uploadLogo>-->
<!--    </div>-->
<!--  </div>-->

  <div class='wrapFields'>
    <field text='Group Name' [ctrl]='ctrl.name'>
      <inputCtrl formControlName='name' placeholder='Group Name' [isFocus]='true'></inputCtrl>
    </field>
    <field text='Company Legal Name' [ctrl]='ctrl.companyLegalName'>
      <inputCtrl formControlName='companyLegalName' placeholder='Company Legal Name'></inputCtrl>
    </field>
    <field text='Email' [ctrl]='ctrl.email'>
      <inputCtrl formControlName='email' placeholder='Email'></inputCtrl>
    </field>
    <!--      this.phoneComponent?.forLibrary?.isValidNumber()-->
    <!--      (changeVal)='ctrl.phone.patchValue($event)' -->
    <!--    <phone #phoneComponent formControlName='phone' (changeVal)='changePhone($event)'></phone>-->
    <!--    formControlName='phone'-->
    <!--    [(ngModel)]='testPhone'-->
    <!--    (changeVal)='changePhone($event)'-->
    <phone #phoneComponent [phoneValue]='ctrl.phone?.value'></phone>
    <!--    <field text='Game Type' [ctrl]='ctrl.gameType'>-->
    <!--      <dropFormCtrl [listStr]='competitionS.arrGameType' formControlName='gameType' placeholder='Game Type'></dropFormCtrl>-->
    <!--    </field>-->
    <!--    <field text='Mailing Address' [ctrl]='ctrl.mailingAddress'>-->
    <!--      <inputCtrl formControlName='mailingAddress' placeholder='Mailing Address'></inputCtrl>-->
    <!--    </field>-->
    <location [fields]='fields' [requiredFields]='requiredFields' page='createGame'
              [replaceNameField]='{street:"Mailing Address*"}'></location>
  </div>

  <btn text='Complete' bcg='blueDark' (click)='sendForm()' class='btnSendForm' [disabled]='!form.valid || locationS.locInvalid'></btn>
</div>
