<!--<div class='wrapToggleBankCard'>-->
<!--  <ng-container *ngIf='{bank: (fundS.link$|async)!.bank, card: (fundS.link$|async)!.card} as link'>-->
<!--    <meSvg [svgName]='link.bank ? "bank_active":"bank"' (click)='toggleLink(true)' [class.o-cur-pointer]='link.card'></meSvg>-->
<!--    <meSvg [svgName]='link.card ? "card_active":"card"' (click)='toggleLink(false)' [class.o-cur-pointer]='link.bank'></meSvg>-->
<!--  </ng-container>-->
<!--</div>-->

<div class='toggleBalancePay'>
  <div class='toggleBalancePay__item {{(fundS.link$|async)?.bank ? "toggleBalancePay__item--active":""}}'
       (click)='toggleLink(true)'>ACH
  </div>
  <div class='toggleBalancePay__item {{(fundS.link$|async)?.card ? "toggleBalancePay__item--active":""}}'
       (click)='toggleLink(false)'>Card
  </div>
</div>
