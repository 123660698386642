import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadTitleComponent } from '../../head-title/head-title.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BtnComponent } from '../../btn/btn.component';
import { AccountPaymentComponent } from '@components/account-payment/account-payment.component';
import { FieldComponent } from '../../__drop_inputs_matSelect/field/field.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TitlecaseNoUnderscorePipe } from '@pipes/title-case-no-underscore.pipe';
import { AnnouncementItem } from '@app/dir_group_assignor/announcements/models/announcements';
import {
  PopupAnnouncementGeneralService
} from "@components/__popup-windows/popup-new-announcement-general/popup-new-announcement-general.service";
import { Observable, of } from "rxjs";
import { IOfficialListItem } from "@components/__popup-windows/popup-new-announcement-general/models/form-models";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: 'app-popup-announcement-details',
    standalone: true,
    templateUrl: './popup-announcement-details.component.html',
    styleUrls: ['./popup-announcement-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        HeadTitleComponent,
        BtnComponent,
        AccountPaymentComponent,
        FieldComponent,
        TitlecaseNoUnderscorePipe,
    ],
})
export class PopupAnnouncementDetailsComponent implements OnInit {
    announcementItem: AnnouncementItem;
    sanitizedText!: SafeHtml;
    method!: string;
    recipientsCounter!: Observable<number>;
    //  ToDo в конструкторе что то не отработало, пришлось сделать так, тех долг
    private readonly popupAnnouncementGeneralService: PopupAnnouncementGeneralService = inject(PopupAnnouncementGeneralService);

    constructor(
        public sanitizer: DomSanitizer,
        public dialogRef: MatDialogRef<PopupAnnouncementDetailsComponent>,
        private readonly cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public dataPopup: {announcementItem: AnnouncementItem;},
    ){
        this.announcementItem = dataPopup.announcementItem;
        this.processText();
    }

    ngOnInit(): void{
        // Вывод метода отсылки
        this.method = this.announcementItem.sms ? 'SMS' : (this.announcementItem.email ? 'Email' : 'Urgent');
        const excludeEmails = this.announcementItem.excludeEmails || [];
        const certifiedOnly = this.announcementItem.recipientGroup === "ALL_CERTIFIED_OFFICIALS";
        if (this.announcementItem.type === 'BY_GAMES' && this.announcementItem.gameIds?.length) {
            this.getAllContactsByGameIds(this.announcementItem.gameIds, excludeEmails);
        } else {
        const contactObservable = certifiedOnly
            ? this.popupAnnouncementGeneralService.getAllContact({ certifiedOnly: true })
            : this.popupAnnouncementGeneralService.getAllContact({ certifiedOnly: false });

        contactObservable.pipe(untilDestroyed(this)).subscribe((res) => {
            const recipientList: IOfficialListItem[] = res;
            // @ts-ignore
            const recipientsCounter = recipientList.filter(item => !excludeEmails?.includes(item.email)).length;
            this.recipientsCounter = of(recipientsCounter);
            this.cdr.detectChanges();
            });
        }
    }

    getAllContactsByGameIds(gameIds: string[], excludeEmails: string[]) {
        this.popupAnnouncementGeneralService.getAllContactsByGameIds(gameIds).pipe(untilDestroyed(this)).subscribe(
            (officialLists: IOfficialListItem[]) => {
                const recipientList = officialLists.filter(item => item.email && !excludeEmails.includes(item.email));
                this.recipientsCounter = of(recipientList.length);
                this.cdr.detectChanges();
            },
        );
    }

    processText(){
        if(this.announcementItem.text) {
            const formattedText = this.announcementItem.text.replace(/\n/g, '<br>');
            this.sanitizedText = this.sanitizer.bypassSecurityTrustHtml(formattedText);
        }
    }

    close(data: Array<string>|null): void{
        this.dialogRef.close(data);
    }
}
