import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { PhotoComponent } from '@components/__svg_img/photo/photo.component';
import { DeviceService } from '@services/device.service';
import { MeService } from '@services/me.service';
import { Router, RouterLink } from '@angular/router';
import { HelperClass } from '@classes/Helper-Classes';
import { StrCasePipe } from '@pipes/string/str-case.pipe';
import { MainService } from '@services/main.service';
import { BtnComponent } from '@components/btn/btn.component';
import { ProgressBarComponent } from '@components/progress-bar/progress-bar.component';
import { GetUserNamePipe } from '@pipes/get-name.pipe';
import { ProfileService } from '@app/profile/profile.service';
import { rout_meProfile, rout_meProfileOfficial } from '@app/profile/profile';
import { UnsavedChangesService } from '@services/unsaved-changes.service';
import { PopupService } from '@services/popup.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { rout_groupProfile_info } from '@app/app.module';
import { urlPayments_assignorFees, urlPayments_paymentDetails } from '@app/url-const';
@UntilDestroy()
@Component({
  selector: 'menuMeUser',
  standalone: true,
  imports: [CommonModule, SvgAndTextComponent, PhotoComponent, StrCasePipe, BtnComponent, ProgressBarComponent, RouterLink, GetUserNamePipe],
  templateUrl: './menu-me-user.component.html',
  styleUrls: ['./menu-me-user.component.scss'],
  // providers: [UnsavedChangesService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuMeUserComponent extends HelperClass {
  public _urlPayments_assignorFees = urlPayments_assignorFees;
  constructor(
    public mainS: MainService,
    public deviceS: DeviceService,
    public meS: MeService,
    public router: Router,
    public profileS: ProfileService,
    private unsavedChangesS: UnsavedChangesService,
    private popupS: PopupService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    // this.deviceS.listenResize().pipe(takeUntil(this.destroySub)).subscribe((isMobile) => {
    //   if (!isMobile) this.showMenu = false;
    // });
  }

  goToMyProfile(): void {
    if (this.meS.OFFICIAL) {
      // this.meProfileOfficialS.navigateByUrl('info');
      this.profileS.navigateByUrl('info', rout_meProfileOfficial);
    } else if (this.meS.GROUP_ASSIGNOR) {
      // this.meS.onboarding$.getValue()?.groupCreated ? this.meProfileS.navigateByUrl('info') : null;
      this.meS.onboarding$.getValue()?.groupCreated ? this.profileS.navigateByUrl('info', rout_meProfile) : null;
    } else if (this.meS.ADMIN) {
      this.profileS.navigateByUrl('info', rout_meProfile);
    }
  }

  goToMyGroup(): void {
    // this.profileS.navigateByUrl('info', rout_groupProfile, this.meS.meCurrentGroup?.id);
    // this.router.navigate([rout_groupProfile_info, this.meS.meCurrentGroup?.id]);
    this.router.navigate([rout_groupProfile_info]);
  }

  doLogOut() {
    if (this.unsavedChangesS.unsavedChanges) {
      this.popupS.openPopupUnsaved(this.deviceS.isMobile).then((promptStatus: boolean) => {
        if (promptStatus) {
          this.unsavedChangesS.unsavedChanges = false;
          this.mainS.logout();
        }
      });
    } else {
      this.mainS.logout();
      this.unsavedChangesS.unsavedChanges = false;
    }
  }

}

// doLogOut() {
//   if (this.unsavedChangesS.hasUnsavedChanges()) {
//     this.popupS.openPopupUnsaved().then((promptStatus: boolean) => {
//       if (promptStatus) {
//         this.unsavedChangesS.setUnsavedChanges(false);
//         this.mainS.logout();
//       }
//     });
//   } else {
//     this.mainS.logout();
//     this.unsavedChangesS.setUnsavedChanges(false);
//   }
// }
