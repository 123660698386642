<div class='wrapperGroupList'>
  <headTitle text='Group Lists' svgRight='cross_grey&24' (svgRightEmit)='close(false)' typeText='text4-blueDark'></headTitle>
  <div class='subTitleGroupList'>Select the group list to which you would like to add the official.</div>

  <div class='groupList'>
    <div *ngFor='let item of (officialsS.groupList$|async)' class='groupList__item'>
      <meSvg [svgName]='item.active|getChxSvgName' (click)='selectGroupListItem(item)'></meSvg>
      <div class='groupList__item__block'>
        <div class='groupList__item__block__name'>{{item.listName}}</div>
        <div class='groupList__item__block__amount'>{{item.officialCount}} officials</div>
      </div>
    </div>
  </div>

  <btnWrap>
    <btn text='Cancel' bcg='white' (click)='close(false)' btnWidth='170'></btn>
    <btn text='Confirm' bcg='blueDark' (click)='submit()' btnWidth='170'></btn>
  </btnWrap>
</div>