import { ActionReducer, combineReducers } from "@ngrx/store";
import { DashboardState } from "@app/store/dashboard/models/dashboard.models";
import { homeAssignerReducer } from "@app/store/dashboard/reducers/home-group-assignor.reducers";
import { subAssignorReducer } from "@app/store/dashboard/reducers/group-assignor.reducers";
import { dashboardGameTableReducer } from "@app/store/dashboard/reducers/dashboard-game-table.reducer";


export const dashboardReducer: ActionReducer<DashboardState> = combineReducers({
    subAssignor: subAssignorReducer,
    homeAssignerActiveTile: homeAssignerReducer,
    dashboardGameTable: dashboardGameTableReducer
});

