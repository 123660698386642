import { Pipe, PipeTransform } from '@angular/core';
import { TSvgName } from '@components/__svg_img/svg/forSvg';

@Pipe({ name: 'getChxSvgName', standalone: true })
export class GetChxSvgNamePipe implements PipeTransform {

  // !!! слишком часто в проетке встречается чекбокс. Поэтому сделал пайп
  transform(value?: boolean): TSvgName {
    return `chx_${!!value}&20` as TSvgName;
  }

}
