import { TSvgName } from '@components/__svg_img/svg/forSvg';
import { ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';

// andrei need delete
export interface ISortBy {  // { title: 'Date ascending', value: 'game.createdDate,asc' }
  title: string,
  value: string,
}

export type TypeSortTable =
  'date,Asc' | 'date,Desc' | // games
  'currentCourtName,Asc' | 'currentCourtName,Desc' | // games
  'gameLocation.name,Asc' | 'gameLocation.name,Desc' | // games
  'gameNumber,Asc' | 'gameNumber,Desc' | // games
  'competitionName,Asc' | 'competitionName,Desc' | // competitions/list
  'firstName,Asc' | 'firstName,Desc' | // officials
  'game.date,Asc' | 'game.date,Desc' | // reports
  'game.gameNumber,Asc' | 'game.gameNumber,Desc' // reports

// export enum SortOrder {
//   Asc = 'Asc',
//   Desc = 'Desc',
// }

export interface ISortBySettings {
  sortOrder: {
    field: string,
    order: string;
    cascadeFields?: string[];
    cascadeSortOrder?: string;
  };
}

// export interface ISortOrder {
//   sortOrder: SortOrder;
// }

// !!! добавил это чтобы не переделывать компонент сортировки
// export type TypePageForSorting = 'games' | 'competitions' | 'officials' | 'assign' | 'reports' | 'myGames' | 'openGames';
export type TypeSorting = 'byGameNumber' | 'byDate' | 'byGameLocation' | 'byName' | 'byCompetitionName'
  | 'byGameNumber_forReport' | 'byDate_forReport';

export type TypeCascadeField = 'date' | 'currentCourtName';

export interface ISortingOptions {
  typeSorting?: TypeSorting;
  id?: string,
  name: string,
  field: string;
  svgName?: TSvgName,
  isSelected?: boolean,
  sortBy?: 'Asc' | 'Desc';
  children?: Omit<ISortingOptions, 'field'>[];

  sortValueForSendToServer?: Array<TypeSortTable>; // !!! для отправки на сервер
}

export const SortingOptions: Array<ISortingOptions & ClassDrop> = [
  {
    typeSorting: 'byGameNumber',
    id: 'gameNumber',
    name: 'Game No.',
    field: 'Game',
    children: [
      {
        name: 'Ascending',
        svgName: 'arrow_chevronBottom&20',
        isSelected: false,
        sortBy: 'Asc',
        sortValueForSendToServer: ['gameNumber,Asc'],
      },
      {
        name: 'Descending',
        svgName: 'arrow_chevronTop&24',
        isSelected: false,
        sortBy: 'Desc',
        sortValueForSendToServer: ['gameNumber,Desc'],
      },
    ],
  },
  {
    typeSorting: 'byDate',
    id: 'date',
    name: 'Date & Time',
    field: 'Date & Time',
    children: [
      {
        name: 'Ascending',
        svgName: 'arrow_chevronBottom&20',
        isSelected: false,
        sortBy: 'Asc',
        sortValueForSendToServer: ['date,Asc'],
      },
      {
        name: 'Descending',
        svgName: 'arrow_chevronTop&24',
        isSelected: false,
        sortBy: 'Desc',
        sortValueForSendToServer: ['date,Desc'],
      },
    ],
  },
  {
    typeSorting: 'byGameLocation',
    id: 'gameLocation.name',
    name: 'By Location',
    field: 'Location',
    children: [
      {
        name: 'Location A to Z',
        isSelected: false,
        sortBy: 'Asc',
        sortValueForSendToServer: ['gameLocation.name,Asc', 'currentCourtName,Asc', 'date,Asc'],
      },
      {
        name: 'Location Z to A',
        isSelected: false,
        sortBy: 'Desc',
        sortValueForSendToServer: ['gameLocation.name,Desc', 'currentCourtName,Desc', 'date,Desc'],
      },
    ],
  },
  {
    typeSorting: 'byName',
    id: 'firstName',
    name: 'By Name',
    field: 'Name',
    children: [
      {
        name: 'Ascending',
        svgName: 'arrow_chevronBottom&20',
        isSelected: false,
        sortBy: 'Asc',
        sortValueForSendToServer: ['firstName,Asc'],
      },
      {
        name: 'Descending',
        svgName: 'arrow_chevronTop&24',
        isSelected: false,
        sortBy: 'Desc',
        sortValueForSendToServer: ['firstName,Desc'],
      },
    ],
  },
  {
    typeSorting: 'byCompetitionName',
    id: 'competitionName',
    name: 'By Name',
    titleCase: 'By Name',
    field: 'Name',
    children: [
      {
        name: 'Ascending',
        svgName: 'arrow_chevronBottom&20',
        isSelected: false,
        sortBy: 'Asc',
        sortValueForSendToServer: ['competitionName,Asc'],
      },
      {
        name: 'Descending',
        svgName: 'arrow_chevronTop&24',
        isSelected: false,
        sortBy: 'Desc',
        sortValueForSendToServer: ['competitionName,Desc'],
      },
    ],
  },
  {
    typeSorting: 'byGameNumber_forReport',
    id: 'game.gameNumber',
    name: 'Game No.',
    field: 'Game',
    children: [
      {
        name: 'Ascending',
        svgName: 'arrow_chevronBottom&20',
        isSelected: false,
        sortBy: 'Asc',
        sortValueForSendToServer: ['game.gameNumber,Asc'],
      },
      {
        name: 'Descending',
        svgName: 'arrow_chevronTop&24',
        isSelected: false,
        sortBy: 'Desc',
        sortValueForSendToServer: ['game.gameNumber,Desc'],
      },
    ],
  },
  {
    typeSorting: 'byDate_forReport',
    id: 'game.date',
    name: 'Date & Time',
    field: 'Date & Time',
    children: [
      {
        name: 'Ascending',
        svgName: 'arrow_chevronBottom&20',
        isSelected: false,
        sortBy: 'Asc',
        sortValueForSendToServer: ['game.date,Asc'],
      },
      {
        name: 'Descending',
        svgName: 'arrow_chevronTop&24',
        isSelected: false,
        sortBy: 'Desc',
        sortValueForSendToServer: ['game.date,Desc'],
      },
    ],
  },
];
