<ng-container class='o-wrapPage wrapperWallet managePayment' *ngIf='{DESKTOP: (deviceS.isDesktop$|async),
                      MOBILE: (deviceS.isMobile$|async),
                     } as obj'>

  <ng-container *ngIf="!hideHeadTitle">
  <div class="paymentTitle">
    <headTitle [class.headTitle--desktop]="obj.DESKTOP" [class.headTitle--mobile]="obj.MOBILE" text="Payment Methods"
      typeText="text1-blueDark" [svgLeft]="obj.DESKTOP ? 'arrow_squareLeft&24' : 'arrow_squareLeft&24'"
      (svgLeftEmit)="otherS.goToBack()">
    </headTitle>
  </div>
  </ng-container>


  <div class="paymentMethodList">
    <ng-container *ngIf="accounts.length || accountDefault; else noPaymentMethods">
      <div class='no-cards-label paymentMargin o-text5 g-flex--align-center'>Payment Default</div>
    </ng-container>

    <ng-template #noPaymentMethods>
      <div class='no-cards-label paymentMargin o-text5 g-flex--align-center'>Payment Methods</div>
    </ng-template>

    <div class='card-container paymentMargin g-flex' [class.g-flex--align-center]='!accounts.length'>
      <ng-container *ngIf='(accounts.length || accountDefault); else noAccountsTpl'>
        <app-account-payment *ngIf='accountDefault' [isDisabled]='loading' [isManagePage]="true" class='g-flex__item'
          [account]='accountDefault' mode='view'></app-account-payment>
      </ng-container>

      <ng-template #noAccountsTpl>
        <div class='noAccount no-cards-label o-text7 g-flex g-flex--align-center' (click)="openAddPaymentMethodPopup()">
          <div class='noAccountSub'>
            <meSvg svgName='plus_1&20'></meSvg>
            <div>Add New Payment Method</div>
          </div>
        </div>
      </ng-template>
    </div>

    <!-- Show all payment methods -->
    <div *ngIf="accounts.length" class="all-payment-methods paymentMargin">
      <h3 class="paymentMarginLeft">Your Payment Methods</h3>
      <div class="payment-methods-list">
        <div *ngFor="let account of accounts" class="card-container g-flex"
          [class.card-container--selected]="account === selectedAccount" (click)="onAccountSelected(account)">
          <app-account-payment class="g-flex__item" [account]="account" mode="edit"
            [isSelected]="account === selectedAccount" [isManagePage]="true" [isDisabled]="loading"
            (removeAccount)="onRemoveAccount($event)" (defaultAccount)="onSetDefaultAccount($event)">
          </app-account-payment>
        </div>
        <div class='no-cards-label o-text7 g-flex g-flex--align-center' (click)="openAddPaymentMethodPopup()">
          <meSvg svgName='plus_1&20'></meSvg>
          <div>Add Payment Method</div>
        </div>
      </div>
    </div>
    <div *ngIf="!accounts.length">
      <div class='no-cards-label o-text7 g-flex g-flex--align-center' (click)="openAddPaymentMethodPopup()"
        style="margin: 16px;">
        <meSvg svgName='plus_1&20'></meSvg>
        <div>Add New Payment Method</div>
      </div>
    </div>
  </div>
</ng-container>