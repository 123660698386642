<div #finalElem *ngIf='{desktop: (deviceS.isDesktop$|async)!,
                        mobile: (deviceS.isMobile$|async)!,
                        ACCEPTED: go.status == "ACCEPTED",
                        UNACCEPTED: go.status == "UNACCEPTED",
                        DECLINED: go.status == "DECLINED",
                        UNASSIGNED: go.status == "UNASSIGNED"
                       } as obj'
     class='assignGo' [class.assignGoSelected]='game.id === assignS.selectedGame?.id && go.isSelect'
     cdkDrag [cdkDragData]='go' [cdkDragDisabled]='true'
     [id]='"idxRole_"+idxGo'
     cdkDropList [cdkDropListData]='game?.gameOfficials'
     [cdkDropListConnectedTo]='assignS.cdkDropListRef!'
     appDirectiveForAssignGameOfficial [game]='game' [go]='go'
     (click)='obj.desktop ? selectGO_forDesktop() : selectGO_forMobile()'>

  <div class='assignGoLeft'>
    <meSvg [svgName]='go|getSvgForGoStatus'></meSvg>
    Official #{{ go.officialPositionNumber }}
    ({{ go.officialPositionName|getFirstLetterEachWord:2 }})
  </div>

  <div class='assignGoRight o-noWrap'>
    <span *ngIf='!go.official && !obj.UNASSIGNED' class='o-noWrap'>{{ go.status | titlecase }}</span>
    <span *ngIf='!go.official && obj.UNASSIGNED'>Unassigned</span>
    <span *ngIf='go.official' class='o-noWrap'>{{ go.official|getUserName }}</span>
    <meSvg *ngIf='obj.desktop' svgName='arrow_chevronRight&24' [h]='20' [w]='20'></meSvg>
  </div>

</div>