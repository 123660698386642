<div class="wrapperLogin">
  <img src="assets/bcgLogo/bcgLogo_textNotchGreen.svg" alt="notch" class="authLogo" />
  <div class="authText">
    Sign in to Notch
    <div>Enter your email and password below</div>
  </div>

  <form *ngIf="!textAfterSent" [formGroup]="form" autocomplete="off" novalidate>
    <field text="Email*">
      <inputCtrl formControlName="email" placeholder="Email address" [isFocus]="true"></inputCtrl>
      <error *ngIf="loginMessageCode === 'userNotFound'" [text]="'We couldn\'t find an account associated with the email address you provided. Please try a different email.'" [centerText]="false"> </error>
    </field>

    <field>
      <div class="o-text7-blueDark o-flex-space-between o-mg-b-6">Password*</div>
      <inputCtrl formControlName="password" placeholder="Password"> </inputCtrl>
      <error *ngIf="loginMessageCode == 'wrongPassword'" [text]="'Invalid password. Try again or click the &quot;Forgot Password?&quot; link to reset it.'" [centerText]="false"> </error>
    </field>
  </form>

  <div class="o-text7-blueDark o-flex-space-between o-mg-t-12 extraMargin">
    <a [routerLink]="['/forgot-password']">Forgot Password?</a>
  </div>

  <btn text="Sign in" [bcg]="authS.bcgApplyBtn" [spinner]="reqPending" (emit)="sendForm()"></btn>
  <div class="divider">OR</div>

  @if (isNativeApp) {
    <app-google-authorization class="o-mg-t-12" typeOperation="signin" (googleResponse)="signinWithGoogleNative($event)"></app-google-authorization>
  } @else {
    <googleSignup class="o-mg-t-16" (emit)="signinWithGoogle($event,'web')" type="signin"></googleSignup>
  }
  <app-apple-sing-up class="o-mg-t-12" typeOperation="signin" (appleOurResponse)="ourAuthorizationResponse($event)"></app-apple-sing-up>
  <error *ngIf="errorMessage" [text]="errorMessage" [centerText]="true"></error>

  <div class="authBottomText" *ngIf="this.isProd == false">Don't have an account? <a [routerLink]="['/signup']" class="o-color{{ authS.bcgApplyBtn }}">Register now</a></div>
</div>
