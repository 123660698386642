import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { urlCreateGame, urlGameImport, urlGameInfo } from '@app/app.module';
import { TableHeaderComponent } from '@components/_table/table-header/table-header.component';
import { TableCeilChxComponent } from '@components/_table/table-ceil-chx/table-ceil-chx.component';
import { TableBtnNumberComponent } from '@components/_table/table-btn-number/table-btn-number.component';
import { TableCeilComponent } from '@components/_table/table-ceil/table-ceil.component';
import { PhotosComponent } from '@components/__svg_img/photos/photos.component';
import { TableCeilActionsComponent } from '@components/_table/table-ceil-actions/table-ceil-actions.component';
import { MatMenuModule } from '@angular/material/menu';
import { BtnComponent } from '@components/btn/btn.component';
import { TableEmptyComponent } from '@components/_table/table-empty/table-empty.component';
import { ClassSettingsRequest, IResponse } from '@models/response-and-request';
import { ClassCeilTableHeader, IForClassForTable, TypeEmitSettingRequest } from '@components/_table/meTable';
import { BehaviorSubject, finalize } from 'rxjs';
import { ClassGame } from '@app/dir_group_assignor/games/game';
import { MainService } from '@services/main.service';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { OtherService } from '@services/other.service';
import { IDataPopup, PopupService } from '@services/popup.service';
import { MeService } from '@services/me.service';
import { Router, RouterLink } from '@angular/router';
import { DeviceService } from '@services/device.service';
import { FiltersService } from '@components/filters/filters.service';
import { MeTableService } from '@components/_table/me-table.service';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { ItemTableDirective } from '@components/_table/directives/item-table.directive';
import { IOutputObjStyles } from '@pipes/css/get-styles.pipe';
import { CeilTableDirective } from '@components/_table/directives/ceil-table.directive';
import { PopupConfirmComponent } from '@components/__popup-windows/popup-confirm/popup-confirm.component';
import { HelperClass } from '@classes/Helper-Classes';
import { PopupCancelGameComponent } from '@app/dir_group_assignor/games/components/popup-cancel-game/popup-cancel-game.component';
import { currentLink_current, currentLink_past, currentLinkDrop_current } from '@classes/dictionary';
import { ClassCompetition } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { ClassFilterDateRange, ClassFilterDrop } from '@components/filters/filters';
import { TChoosePeriod } from '@components/__drop_inputs_matSelect/date-range/dateRange';
import { DataTableDirective } from '@components/_table/directives/data-table.directive';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { LinkPageComponent } from '@components/link-page/link-page.component';
import { PaginationComponent } from '@components/pagination/pagination.component';
import { StopPropagationDirective } from '@directives/stop-propagation.directive';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { TableInfiniteLoadingComponent } from '@components/_table/table-infinite-loading/table-infinite-loading.component';
import { FormsModule } from '@angular/forms';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomDatesService } from '@classes/CustomDates';
import { AdminPermissionDirective } from '@directives/admin-permission.directive';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';
import { AssignService } from '@app/dir_group_assignor/assign/assign.service';
import { IsPossibleGameToCancelledPipe } from '@pipes/game_and_report/is-possible-game-to-cancelled.pipe';
import { IsPossibleGameToDeletePipe } from '@pipes/game_and_report/is-possible-game-to-delete.pipe';
import { BtnImportGamesComponent } from '@app/dir_group_assignor/games/components/btn-import-games/btn-import-games.component';
import { colorObj } from '@classes/CSS';
import { ManageAllService } from '@shared/manage-all.service';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';
import { TableCellGameNotesComponent } from '@components/_table/table-cell-game-notes/table-cell-game-notes.component';
import { SafariBottomComponent } from "../../../../../../shared/safari-bottom/safari-bottom.component";
import { AuthenticatorDirective, AuthenticatorService } from '@directives/authenticator-hide.directive';

@UntilDestroy()
@Component({
  selector: 'app-group-assignor-games-table',
  standalone: true,
  imports: [CommonModule, TableHeaderComponent, TableCeilChxComponent, TableBtnNumberComponent, TableCeilComponent,
    PhotosComponent, TableCeilActionsComponent, MatMenuModule, BtnComponent, TableEmptyComponent, WrapPageDirective,
    ItemTableDirective, CeilTableDirective, DataTableDirective, DropdownComponent, FiltersComponent,
    LinkPageComponent, PaginationComponent, RouterLink,
    StopPropagationDirective, SvgComponent, TableInfiniteLoadingComponent, FormsModule,
    AdminPermissionDirective, GetTableEmptyPipe, IsPossibleGameToCancelledPipe,
    IsPossibleGameToDeletePipe, BtnImportGamesComponent, TableCellGameNotesComponent,
    SafariBottomComponent, AuthenticatorDirective],
  templateUrl: './group-assignor-games-table.component.html',
  styleUrls: ['./group-assignor-games-table.component.scss'],
  // !!! НЕ надо здесь, т.к. это дочерний компонент providers: [FiltersService, MeTableService, SettingsRequestService], // for-filters=== for-table=== for-settings===
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupAssignorGamesTableComponent extends HelperClass implements IForClassForTable<ClassGame>, OnInit, OnChanges {
  dataTable$ = new BehaviorSubject<IResponse<ClassGame>>({}); // for-table===
  arrContent$ = new BehaviorSubject<Array<ClassGame>>([]); // for-table===
  readonly urlGameInfo = urlGameInfo;
  // readonly urlDashboardGame = urlDashboardGame;
  readonly urlCreateGame = urlCreateGame;
  readonly widthPage = 1300;
  totalElements: number = 0;
  sortingParams: any = {};

  readonly urlGameImport = urlGameImport;
  isReadOnly!: boolean;

  // readonly class_ngContent_btn_for_nothingList = class_ngContent_btn_for_nothingList;

  constructor(
    public mainS: MainService,
    public apiCompetitionS: ApiCompetitionService,
    public gameS: GameService,
    public otherS: OtherService,
    public popupS: PopupService,
    public meS: MeService,
    public router: Router,
    public deviceS: DeviceService,
    public filtersS: FiltersService, // for-filters===
    public meTableS: MeTableService<ClassGame>, // for-table===
    public cd: ChangeDetectorRef,
    public store: Store<AppState>,
    public dateS: CustomDatesService,
    public assignS: AssignService,
    private manageAllService: ManageAllService,
    public authenticatorService: AuthenticatorService
  ) {
    super(cd);
    this.setDataForTable(); // for-table===
    this.gameS.currentLinkObj = { currentLink: currentLinkDrop_current };
    this.getArrCompetition();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('settings' in changes) {
      //  Поведение фильтров надо смотреть
      this.emitSettingRequest(changes.settings.currentValue, 'filters');
    }
  }

  ngOnInit() {
    this.subscribeToCurrentLink();
    this.manageAllService.setSettings(this.settings);
    this.isReadOnly = !this.authenticatorService.isAllow(['GROUP_ASSIGNOR', 'SUB_ASSIGNOR']);
  }

  getGamesList(type: TypeEmitSettingRequest | null): void {
    this.meTableS.startLoadingTable(); // for-table===
    const settings: ClassSettingsRequest = { ...this.settings, sort: 'date,Asc', size: 5 };
    this.mainS.getGamesList({ params: settings }) // this.meTableS.getSubject_forGetGamesList(this.settings)
      .pipe(
        finalize(() => { // for-table===
          this.meTableS.endLoadingTable();
          this.cd.detectChanges();
        }),
        untilDestroyed(this),
      )
      .subscribe((res?: IResponse<ClassGame>) => {
        this.totalElements = res?.totalElements || 0;
        if (res) {
          const dataTable = res;
          dataTable.content = res.content?.filter(game => game.gameStatus === 'ACTIVE') || [];
          this.meTableS.setDataAfterResponseServer(dataTable, type); // for-table===
          this.cd.detectChanges();
        }
      });
  }

  // !!! если game не передал значит это удаление несокльких, которые отмечены чекбоксом
  deleteGameFromServer(game?: ClassGame, idx?: number): void {
    const arrIds: Array<string> = game ? [game?.id!] : this.meTableS.selectedItems_ids;
    if (!arrIds?.length) return;
    if (this.startRequest()) return;
    const dataPopup: IDataPopup = {
      width: '400px',
      textTitle: 'Delete Games',
      text: `Are you sure you want to delete the selected game${arrIds?.length > 1 ? 's' : ''} that’s irreversible?`,
      textBtnCancel: 'Go Back',
      textBtnApply: 'Delete',
      colorBtnApply: 'red',
    };
    const gamesForSendToServer = game ? [game] : this.meTableS.selectedItems;
    this.popupS.open(PopupConfirmComponent, dataPopup).then((res: Array<ClassGame>) => {
      if (!res) return this.endRequest();
      this.mainS.deleteGames(gamesForSendToServer).pipe(untilDestroyed(this))
        .subscribe((arrIds: Array<string>) => {
          game ? this.meTableS.deleteItemByIdx(idx!) : this.meTableS.deleteArrSelectedItems();
          this.endRequest();
        });
    });
  }

  cancelGames(gameItem?: ClassGame, idx?: number): void {
    const dataPopup: IDataPopup = { games: gameItem ? [gameItem] : this.meTableS.arrContent?.filter(el => this.meTableS.selectedItems_ids.includes(el?.id!)) };
    this.popupS.open(PopupCancelGameComponent, dataPopup).then((res: Array<ClassGame>) => {
      if (!res) return;
      const newGameList = gameItem && (idx || idx === 0) ? this.otherS.replaceElemArrayByIdx(this.meTableS.arrContent, res[0], idx) : res;
      this.arrContent$.next(newGameList);
      this.meTableS.resetSelectedItems();
      this.cd.detectChanges();
    });
  }

  // === OTHER =====================
  // !!! если isClone то при клонировании id игры НЕ отправлять и запрос должен быть POST - для создания игры
  goToEditGame(game: ClassGame, idx: number, isClone = false): void {
    this.gameS.goToEditGame(game, isClone).then((res?: ClassGame) => {
      if (!res) return;
      isClone ? this.meTableS.addNewItem(res) : this.meTableS.replaceItemByIdx(res, idx);
      this.cd.detectChanges();
    });
  }

  // === FILTERS ==========================================
  @Input()
  settings: ClassSettingsRequest = new ClassSettingsRequest(); // for-filters===
  arrPeriods: Array<TChoosePeriod> = []; // FOR dropDateRange

  subscribeToCurrentLink(): void {
    this.gameS.currentLink$.pipe(untilDestroyed(this)).subscribe((res) => {
      if (!res?.currentLink) return;
      this.settings = { ...this.settings, ...new ClassSettingsRequest() }; // for-table===  для того чтобы page & size по дефолту поставить при переключении вкладок
      this.settings.sort = this.gameS.getSortByDateForSettingRequestByDefault();
      if (res.currentLink?.upperCase === currentLink_current) {
        this.arrPeriods = ['Today', 'Tomorrow', 'Next 7 days', 'Next 2 weeks', 'Next month', 'Next 3 months', 'Next 12 months', 'Custom Date Range'];
      }
      if (res.currentLink?.upperCase === currentLink_past) {
        this.arrPeriods = ['Yesterday', 'Last 7 days', 'Last 4 weeks', 'Last month', 'Last 3 months', 'Last 12 months', 'Custom Date Range'];
      }
      const filterDateRange = new ClassFilterDateRange({
        typePeriod: res.currentLink?.upperCase,
        arrPeriods: this.arrPeriods,
      });
      this.filtersS.resetSelectedFilters([filterDateRange]); // for-filters===
      this.cd.detectChanges();
    });
  }

  getArrCompetition(): void {
    this.apiCompetitionS.getArrCompetition().toPromise()
      .then((res?: IResponse<ClassCompetition>) => {
        if (!res?.content?.length) return;
        const filterDrop: ClassFilterDrop = { arrayForDropdown: res?.content };
        this.filtersS.updateFilter('competitions', filterDrop);
        this.gameS.arrCompetition$.next(res?.content);
      })
      .catch(err => {
      });
  }

  // === TABLE ======================================================
  readonly styleForGame_CANCELLED: IOutputObjStyles = { color: colorObj.red, opacity: 0.5 }; // or #F12B2C50

  setDataForTable(): void {
    this.meTableS.dataTable$ = this.dataTable$; // !!! создание ссылки. Чтобы можно было использовать в MeTableService
    this.meTableS.arrContent$ = this.arrContent$; // !!! создание ссылки. Чтобы можно было использовать в MeTableService
    const arrayCeilHeader: Array<ClassCeilTableHeader> = [
      new ClassCeilTableHeader({ text: 'Game', isChx: true }),
      new ClassCeilTableHeader({ text: 'Date & Time' }),
      new ClassCeilTableHeader({ text: 'Age & Level' }),
      new ClassCeilTableHeader({ text: 'Location' }),
      new ClassCeilTableHeader({ text: 'Teams' }),
      new ClassCeilTableHeader({ text: '' }),
      new ClassCeilTableHeader({ text: 'Officials' }),
      new ClassCeilTableHeader({ text: '' }),
    ];
    const arrWidthCeilTable: Array<number> = [152, 187, 186, 209, 236, 55, 177, this.meTableS.minWidthFor_sortByCeil];
    this.meTableS.arrTypeSorting = ['byGameNumber', 'byDate', 'byGameLocation']; // !!! for sorting
    this.meTableS.setDataForTable(arrWidthCeilTable, arrayCeilHeader, this.widthPage, false);
  }

  // !!! for filters & pagination & sorting & infiniteLoading
  emitSettingRequest(settingsRequest: ClassSettingsRequest, type: TypeEmitSettingRequest): void {
    this.settings = { ...this.settings, ...settingsRequest };
    this.settings.gameStatuses = 'ACTIVE';
    // this.settings.status = 'ACTIVE';
    this.getGamesList(type);
  }

  manageAll(): void {
    this.manageAllService.manageAll();
  }

}

// goToAssign(objGameItem: ClassGame): void {
//   const goToAssign: IGoToAssign = {
//   currentLink: this.gameS.currentLinkObj.currentLink?.upperCase,
//   search: objGameItem.gameNumber,
//   gameId: objGameItem.id,
//   // competition: JSON.stringify({ ...objGameItem.competition, id: objGameItem.competitionId }),
//   competition: JSON.stringify(objGameItem.competition),
//   periodForDateRange: 'All time',
// };
// this.router.navigate(['/assign'], { queryParams: goToAssign });
// }
