import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceService } from '@services/device.service';
import { MeTableService } from '@components/_table/me-table.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CeilTableDirective } from '@components/_table/directives/ceil-table.directive';
import { GetChxSvgNamePipe } from '@components/__svg_img/svg/pipes/get-chx-svg-name.pipe';
import { SortByComponent } from '@components/sortBy/sort-by.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';

@UntilDestroy()
@Component({
  selector: 'tableHeader',
  standalone: true,
  imports: [CommonModule, CeilTableDirective, GetChxSvgNamePipe, SortByComponent, SvgComponent],
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableHeaderComponent<T> {
  @HostBinding('class.o-none') get hiddenForMobile(): boolean {
    return this.deviceS.isMobile;
  }

  // @Output() emitSettingRequest = new EventEmitter<ClassSettingsRequest>(); // !!! испольтзуется только там где нет динамических фильтров в url

  constructor(
    private deviceS: DeviceService,
    public meTableS: MeTableService<T>,
  ) {
  }

  // === CHECKBOX ========================
  selectAll(): void {
    this.meTableS.selectAll();
  }

  // !!! испольтзуется только там где нет динамических фильтров в url
  // emitSettingsMethod(settings: ClassSettingsRequest): void {
  //   const { sort } = settings;
  //   this.settingsRequestS.updateSettings({ sort, typeEmitSetting: 'sorting' }, 'sorting');
  // }
}
