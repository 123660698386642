<div *ngIf='{typePageProfile: profileS.typePageProfile$|async} as obj'
     class='wrapperContactItem wrapperContactItem--{{obj.typePageProfile}}'>
  <div class='wrapperContactItem__text1 wrapperContactItem--{{obj.typePageProfile}}__text1'
       [style]='{width: width1+"px", maxWidth: width1+"px", minWidth: width1+"px"}'>
    {{text1}}
    <meSvg *ngIf='svgName1' [svgName]='svgName1' [matTooltip]='matTooltipSVG1' [w]='widthSvg1'></meSvg>
  </div>
  <div class='wrapperContactItem__text2 wrapperContactItem--{{obj.typePageProfile}}__text2'
       [style]='{width: width2, maxWidth: width2, minWidth: width2}'>
    {{text2 || '-'}}
    <meSvg *ngIf='svgName1' [svgName]='svgName1' [matTooltip]='matTooltipSVG1' [w]='widthSvg2'></meSvg>
  </div>
</div>