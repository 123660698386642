import { Pipe, PipeTransform } from '@angular/core';
import { AssignService } from '@app/dir_group_assignor/assign/assign.service';
import { ClassUser } from '@models/user';
import { UtilsService } from '@services/utils.service';

@Pipe({ name: 'isUserAssignedInSelectedGameAndSavedInServer', standalone: true })
export class IsUserAssignedInSelectedGameAndSavedInServerPipe implements PipeTransform {

  constructor(
    private assignS: AssignService,
  ) {
  }

  // !!! заасайнен ли судья в текущую выбраную игру И сохранен на сервере
  transform(official: ClassUser): boolean {
    return UtilsService.isUserAssignedInCurrentSelectedGame_andSavedInServer(this.assignS.selectedGame!, official.id!);
  }
}
