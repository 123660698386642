<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
             } as obj' class='o-wrapPage o-wrapPage--noMarginTopAndNoPadding' wrapPage [widthPage]='1300'>

  <div class='o-header'>
    <div class='o-header__content'>
      <div class='o-header__title'>
        <meSvg svgName='arrow_squareLeft&24' (click)='router.navigate(["payments/balances"])'></meSvg>
        Balance Transactions
      </div>

      <div class='headerAssignorFeesAll'>
        <inputCtrl [(ngModel)]='search' placeholder='Search' svgPrefix='search_grey&20'></inputCtrl>
        <meSvg svgName='download_3&44' (click)='null' [disabled]='true'></meSvg>
      </div>
    </div>
  </div>

  <div *ngIf='!(loading$|async); else spinner' class='o-content'>
    <div class='o-table'>
      <div class='o-table__header'>
        <div *ngFor='let itemHeader of tableHeader; let idx = index;' class='o-table__header__item widthItem--{{idx+1}}'>
          <meSvg *ngIf='itemHeader.isChx' [svgName]='isSelectAll|getChxSvgName' class='o-table__header__item__chx'
                 (click)='selectAll()'></meSvg>
          {{itemHeader.text}}
          <!--          <meSvg *ngIf='itemHeader.isActive' [svgName]='"arrow_top&16"' class='o-table__header__item__arrow'></meSvg>-->
        </div>
      </div>

      <div class='o-table__list'>
        <ng-container *ngFor='let item of (payS.payments$|async); let first = first; let idx = index; let last = last'>
          <!--          *ngIf='{PAID: item.status == "PAID"} as objItem'-->
          <div class='o-table__list__item'>
            <div class='widthItem--1'>
              <meSvg [svgName]='item?.isSelect|getChxSvgName' (click)='selectItem(item, $event)'></meSvg>
              <span>{{item.type}}</span>
            </div>
            <div class='widthItem--2 o-table--itemColumn o-noWrap'>
              <!--              <div>{{item|getAgeGenderLevel}}</div>-->
              <!--              <div>{{item.competition?.competitionName}}</div>-->
            </div>
            <div class='widthItem--3'
                 [class.o-colorGreen]='item.total?.toString()?.includes("+")'
                 [class.o-colorRed]='item.total?.toString()?.includes("-")'>
              {{item.total_formatted|currency}}
            </div>
            <div class='widthItem--4'>
              <!--              andrei== статус не приходит. Миша сказал пока что так сделать  -->
              <status [status]='item.status ? item.status : "SUCCEEDED"'></status>
            </div>
            <div class='widthItem--5'>
              <span>{{item.createdAt|date:'EE, MMM d, yyy'}}</span><span>{{item.createdAt|date:'shortTime'}}</span>
            </div>
            <div class='widthItem--6'>
              <btn text='Pay Now' bcg='blueDark' (emit)='null' [btnWidth]='89' [disabled]='true'></btn>
            </div>
            <div class='widthItem--7' [matMenuTriggerFor]='menuForPoints' (click)='$event.stopPropagation()'>
              <meSvg svgName='circle_3points2&20'></meSvg>
              <mat-menu #menuForPoints='matMenu'>
                <!--                <button (click)='null'></button>-->
              </mat-menu>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <ng-container [ngTemplateOutlet]='pagination'></ng-container>
  </div>
</div>

<ng-template #spinner>
  <spinner></spinner>
</ng-template>

<ng-template #pagination>
  <pagination *ngIf='(payS.payments$|async)?.length' [data]='(payS.dataPayments$|async)!'
              (updatePage)='paginationMethod("updatePage",undefined,$event)'
              (updateResults)='paginationMethod("updateCountResults",$event)'></pagination>
</ng-template>
