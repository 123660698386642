import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DashboardSubAssignorActions } from '@app/store/dashboard/actions/dashboard.actions';
import { switchMap } from 'rxjs';
import { debounceTime, map, mergeMap } from 'rxjs/operators';
import { ClassSettingsRequest, IResponse } from '@models/response-and-request';
import { ClassGame } from '@app/dir_group_assignor/games/game';
import {
  GroupAssignorDashboardService,
} from '@app/dir_group_assignor/dashboard/components/dashboard-group-assignor/services/group-assignor-dashboard.service';

@Injectable()
export class DashboardEffect {
  private readonly value_debounceTime = 100; // !!! https://notch.atlassian.net/browse/NOT30-719 => чтобы не было canceled запросов на сервер

  constructor(
    private actions$: Actions,
    private subDashboardService: GroupAssignorDashboardService,
  ) {
  }

  loadAllData$ = createEffect(() => this.actions$.pipe(
    debounceTime(this.value_debounceTime),
    ofType(DashboardSubAssignorActions.loadAllData),
    mergeMap(({ sortParams, settingParams }) => {
      return [
        // Вызов эффектов для получения данных для табличек в дашбюорде
        DashboardSubAssignorActions.loadAllGamesAction({ sortParams, settingParams }),
        DashboardSubAssignorActions.loadAssignData({ sortParams, settingParams }),
        DashboardSubAssignorActions.loadDeclinedData({ sortParams, settingParams }),
        DashboardSubAssignorActions.loadUnassignedData({ sortParams, settingParams }),
      ];
    }),
  ));

  loadAllAssign$ = createEffect(() => this.actions$.pipe(
    debounceTime(this.value_debounceTime),
    ofType(DashboardSubAssignorActions.loadAllAssignAction),
    mergeMap(({ sortParams, settingParams }) => {
      return [
        // Вызов эффектов для получения данных для табличек в дашбюорде
        DashboardSubAssignorActions.loadAssignData({ sortParams, settingParams }),
        DashboardSubAssignorActions.loadDeclinedData({ sortParams, settingParams }),
        DashboardSubAssignorActions.loadUnassignedData({ sortParams, settingParams }),
      ];
    }),
  ));

  loadAllGamesData$ = createEffect(() => this.actions$.pipe(
    ofType(DashboardSubAssignorActions.loadAllGamesAction),
    switchMap((action) => {
      const { sortParams, settingParams } = action;
      sortParams.sort = 'date,Asc';
      const settings = new ClassSettingsRequest(settingParams);
      settings['gameStatuses'] = 'ACTIVE';
      return this.subDashboardService.getGamesList(settings, sortParams, true).pipe(
        switchMap((response: IResponse<ClassGame> | undefined) => [
          //  Передача полченных данных в редюсер
          DashboardSubAssignorActions.loadAllGamesActionSuccess({ games: response }),
          DashboardSubAssignorActions.setTotalTableElements({ totalGames: response?.totalElements || 0 }),
        ]),
      );
    }),
  ));

  loadAssignData$ = createEffect(() => this.actions$.pipe(
    ofType(DashboardSubAssignorActions.loadAssignData),
    switchMap((action) => {
      const { sortParams, settingParams } = action;
      sortParams.sort = 'date,Asc';
      const paramsToSend = { ...sortParams, gameStatus: 'ACTIVE', assignStatus: 'UNACCEPTED' };
      const settings = new ClassSettingsRequest(settingParams);
      settings.status = 'UNACCEPTED';
      settings.assignStatuses = 'UNACCEPTED,UNPUBLISHED';
      return this.subDashboardService.getGamesList(settings, paramsToSend).pipe(
        map((response: IResponse<ClassGame> | undefined) => {
          return DashboardSubAssignorActions.loadAssignDataSuccess({ assignData: response });
        }),
      );
    }),
  ));

  loadDeclinedData$ = createEffect(() => this.actions$.pipe(
    ofType(DashboardSubAssignorActions.loadDeclinedData),
    switchMap((action) => {
      const { sortParams, settingParams } = action;
      sortParams.sort = 'date,Asc';
      const paramsToSend = { ...sortParams, assignStatuses: 'DECLINED' };
      const settings = new ClassSettingsRequest(settingParams);
      settings.status = 'DECLINED';
      settings.assignStatuses = 'DECLINED';
      return this.subDashboardService.getGamesList(settings, paramsToSend).pipe(
        map((response: IResponse<ClassGame> | undefined) => {
          return DashboardSubAssignorActions.loadDeclinedAssignDataSuccess({ declinedData: response });
        }),
      );
    }),
  ));

  loadUnassignedData$ = createEffect(() => this.actions$.pipe(
    ofType(DashboardSubAssignorActions.loadUnassignedData),
    switchMap((action) => {
      const { sortParams, settingParams } = action;
      sortParams.sort = 'date,Asc';
      const paramsToSend = { ...sortParams, assignStatuses: 'UNASSIGNED' };
      const settings = new ClassSettingsRequest(settingParams);
      settings.status = 'UNASSIGNED';
      settings.assignStatuses = 'UNASSIGNED';
      return this.subDashboardService.getGamesList(settings, paramsToSend).pipe(
        map((response: IResponse<ClassGame> | undefined) => {
          return DashboardSubAssignorActions.loadUnassignedAssignDataSuccess({ unassignedData: response });
        }),
      );
    }),
  ));

}
