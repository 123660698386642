import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceService } from '@services/device.service';
import { RouterOutlet } from '@angular/router';
import { ProfileUserService } from '@app/profile/profile-user/profile-user.service';
import { LinkPageComponent } from '@components/link-page/link-page.component';
import { ProfileService } from '@app/profile/profile.service';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { FormsModule } from '@angular/forms';
import { OtherService } from '@services/other.service';

// @UntilDestroy()
@Component({
  selector: 'app-profile-user',
  standalone: true,
  imports: [CommonModule, LinkPageComponent, RouterOutlet, DropFormCtrlComponent, HeadTitleComponent, FormsModule],
  templateUrl: './profile-user.component.html',
  styleUrls: ['./profile-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileUserComponent {
  constructor(
    public deviceS: DeviceService,
    public otherS: OtherService,
    public profileUserS: ProfileUserService,
    public profileS: ProfileService,
    public cd: ChangeDetectorRef,
  ) {
    this.profileS.setData(this.profileUserS.typePageProfile);
  }

}
