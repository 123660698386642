import { createAction } from "@ngrx/store";

export const setActiveTileGameAction = createAction('[Dashboard] Set Active Tile Game');
export const setActiveTilePendingAction = createAction('[Dashboard] Set Active Tile Pending');
export const setActiveTileRolesAction = createAction('[Dashboard] Set Active Tile Roles');
export const setActiveTileDeclinedAction = createAction('[Dashboard] Set Active Tile Declined');
export const resetActiveTilesAction = createAction('[Dashboard] Reset Active Tiles');

export const DashboardHomeAssignerActions = {
    setActiveTileGameAction,setActiveTilePendingAction,setActiveTileRolesAction,setActiveTileDeclinedAction,resetActiveTilesAction
}
