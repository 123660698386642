import { ITimezone } from '@classes/geo';
import { ILocation } from '@models/location';

export class LocationClass implements ILocation {
  id?: string;
  city?: string;
  state?: string;
  street?: string;
  zipcode?: string;
  building?: string;
  latitude?: string | number;
  longitude?: string | number;
  timezone?: ITimezone;
}

export interface ILocForGoogleMap {
  address?: string;
  distanceFromUserToGame?: string;
  placeIdOfGame?: string;
}

export class LocForGoogleMapClass implements ILocForGoogleMap {
  address = '';
  distanceFromUserToGame = '';
  placeIdOfGame = '';
}
