<div class='wrapperPopup'>
  <headTitle text='Announcement Type' svgRight='cross_grey&24' (svgRightEmit)='close(null)' typeText='text4-blueDark'></headTitle>

  <div class='content'>
    <div class='content-item'>
      <div class='o-text7'>Type</div>
      <app-dropdown [(ngModel)]='type' [array]='arrAssignStatus' placeholder='Select Type'></app-dropdown>
    </div>
  </div>

  <div class='actionsPopup'>
    <btn text='Cancel' bcg='white' (emit)='close(null)'></btn>
    <btn text='Confirm' bcg='blueDark' (emit)='close(type)'></btn>
  </div>
</div>