<div class='wrapperEmailPass' [formGroup]='form'>
  <div class='authText'>Create an account
    <div class='authBottomText'>Already have an account? <a (click)='goToLogin()'>Log in</a></div>
  </div>
  <field text='Email*'>
    <inputCtrl formControlName='email' placeholder='Email' [isFocus]='true' [showErrText]='true'></inputCtrl>
  </field>
  <field text='Password*'>
    <inputCtrl formControlName='password' placeholder='Create a password' [viewPassword]='viewPassword'
      (viewPasswordEmit)='viewPassword=$event' [showErrText]='true'></inputCtrl>
  </field>
  <field text='Re-Enter Password*'>
    <inputCtrl formControlName='confirmPassword' placeholder='Re-Enter password' nameField='confirmPassword'
      [viewPassword]='viewPassword' (viewPasswordEmit)='viewPassword=$event' [showErrText]='true'></inputCtrl>
  </field>
  <btn text='Get started' [bcg]='authS.bcgApplyBtn' [spinner]='reqPending' (emit)='signUp()' class='o-mg-t-24'
    [disabled]='form|invalidControls:["email","password","confirmPassword"]'></btn>
    <div class="divider">OR</div>
  @if (isNativeApp) {
    <app-google-authorization class="o-mg-t-12" [typeOperation]="'signup'" (googleResponse)="signupWithGoogleNative($event)"></app-google-authorization>
  } @else {
    <googleSignup class='o-mg-t-16' (emit)="signupWithGoogle($event,'web')" type='signup'></googleSignup>
  }
  <app-apple-sing-up class='o-mg-t-12' (appleOurResponse)='ourAuthorizationResponse($event)'
                     typeOperation='signup'></app-apple-sing-up>

  <div class='terms'> By creating an account you agree to our
    <span> <a href="https://www.joinnotch.com/terms-of-service" target="_blank">
        terms of use
      </a></span>
  </div>
</div>
