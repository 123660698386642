<div *ngIf='{ desktop: (deviceS.isDesktop$|async), mobile: (deviceS.isMobile$|async),} as obj'>
  <div *ngIf='arrLink?.length || link' class='wrapperLinkPage'>
    <div *ngIf='!obj.mobile'>
      <ng-container *ngFor='let item of arrLink'>
        <ng-container *ngIf='{activeItem: item.titleCase && item.titleCase == link?.titleCase} as obj'>
          <ng-container *ngIf='{FLAGGED: item.upperCase === "FLAGGED" && obj.activeItem} as objForReports'>
            <div class='wrapperLinkPage' [class.wrapperLinkPage--activeItem]='obj.activeItem'
              [class.wrapperLinkPage--bcgRed10]='objForReports.FLAGGED' (click)='onClickItem(item)'>
              {{item.titleCase}}
              <!-- === count => for reports ===-->
              <span *ngIf='item.count || item.count == 0' class='countLinkPage'
                [class.countLinkPage--activeItem]='obj.activeItem'
                [class.countLinkPage--bcgRed]='objForReports.FLAGGED'>
                {{item.count}}
              </span>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <app-dropdown *ngIf='obj.mobile' [ngModel]='link' [array]='arrLink' (changeValObj)='onClickItem($event)'>
    </app-dropdown>
  </div>
</div>