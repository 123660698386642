<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              dataTable: (dataTable$|async)!,
              arrContent: (arrContent$|async),
              loadingTable: (meTableS.loadingTable$|async)!,
             } as obj' class='o-wrapPage' wrapPage [widthPage]='widthPage' cdkScrollable dataTable>


  <!--  === FILTERS & TITLE ===========================-->
  <!--  o-header&#45;&#45;noHaveToggleCurrentPast-->
  <div class='o-header o-header--noHaveLinkPage'>
    <!--    <div class='o-header__linkPage'>-->
    <!--      <app-filtersSelectedIcon></app-filtersSelectedIcon>-->
    <!--    </div>-->
    <linkPage-searchFilter [haveLinkPage]='false'></linkPage-searchFilter>
    <div class='o-header__content'>
      <div class='o-header__title'>Open Games</div>
      <div class='o-header__filters'>
        <app-filters></app-filters>
      </div>
      <app-filtersSelected></app-filtersSelected>
    </div>
  </div>

  <!--  === TABLE ==============================-->
  <div class='o-content t-wrapperTable'>
    <!--    <tableHeader (emitSettingRequest)='emitSettingRequest($event,"sorting")'></tableHeader>-->
    <tableHeader></tableHeader>
    <div class='t-table t-tableVersion2'>
      <ng-container *ngIf='!isRequestAssignOfficial'>
        <ng-container *ngFor='let item of obj.arrContent; let idx=index'>
          <div *ngIf='{ gameCANCELLED: item?.gameStatus == "CANCELLED",
                      showBtn_showMore: item?.gameOfficials?.length ? item!.gameOfficials!.length > amountForShowAllGO : false,
                    } as objGame' class='t-table__item' itemTable [item]='item'>
            <div *ngIf='obj.desktop' class='t-fontBold' ceilTable [idxCeil]='0'>{{ item.gameNumber }}</div>
            <!-- !!! this component app-tableBtnNumber ONLY FOR MOBILE for table -->
            <app-tableBtnNumber [game]='item'></app-tableBtnNumber>
            <app-tableDoubleCeil [idxCeil]='1' [text1]='item.date_EE_MMMd_yyy' [text2]='item.date_shortTime_timezone'
                                 [text3]='item|getAgeGenderLevel:"ClassGame"'
                                 [text4]='item.competition?.competitionName || "TBD"'></app-tableDoubleCeil>
            <app-tableDoubleCeil [idxCeil]='2' [text1]='item|getLocNameCourtName'
                                 [text2]='item.location?.address|getLocationString:["city","state"]'
                                 [matTooltipText1]='item|matTooltipForLocation'
                                 [text3]='(item.homeTeam?.teamName || "TBD") + " vs."'
                                 [text4]='item.awayTeam?.teamName || "TBD"' [commaForMobile]='false'></app-tableDoubleCeil>

            <!--          === OFFICIALS CEIL ================================-->
            <div *ngIf='obj.desktop' ceilTable [idxCeil]='3' class='officialsCeil'>
              <ng-container *ngFor='let go of item?.gameOfficials; let index=index'>
                <ng-container *ngIf='{ ACCEPTED: go.status === "ACCEPTED",
                                     UNACCEPTED: go.status === "UNACCEPTED",
                                     DECLINED: go.status === "DECLINED",
                                     UNASSIGNED: go.status === "UNASSIGNED"
                                    } as goObj'>
                  <div *ngIf='item.isShowAllGO || index < amountForShowAllGO' class='officialsCeil__item'
                       [class.officialsCeil__item--itemSelected]='go.isSelect'
                       [class.assigned]='!goObj.UNASSIGNED'
                       [class.o-cur-pointer]='goObj.UNASSIGNED'
                       (click)='goObj.UNASSIGNED ? selectOfficial(go,item?.gameOfficials,item.id||"") : null'>
                    <div [id]="'index_' + index" class='officialsCeil__itemCdkDropList'>
                      <div class='officialsCeil__item__left o-text7'>
                        <meSvg [svgName]='go|getSvgForGoStatus'></meSvg>
                        Official #{{ go.officialPositionNumber }} ({{ go.officialPositionName|getFirstLetterEachWord:2 }})
                      </div>
                      <div class='officialsCeil__item__right o-noWrap'>
                        <span *ngIf='go.official && !goObj.UNASSIGNED'>{{ go.official|getUserName }}</span>
                        <div *ngIf='goObj.UNASSIGNED' class='g-flex g-flex--align-center'>
                          <div *ngIf='go.officialFee; else noPriceTpl' class='g-flex g-flex--align-center'>
                            <div>Available</div>
                            <div [notchTooltip]='item.competition?.payoutFormat||""' class='officialsCeil__fee o-text7'>
                              {{ go.officialFee|currency }}
                            </div>
                          </div>
                          <ng-template #noPriceTpl>
                            <div>-</div>
                          </ng-template>
                        </div>
                        <meSvg svgName='arrow_chevronRight&24' (click)='router.navigate([urlGameInfo,item?.id])'></meSvg>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <div *ngIf='objGame.showBtn_showMore' class='goOfficialActions goOfficialActions__btnLine2 o-text7'
                   (click)='item.isShowAllGO = !item.isShowAllGO'>
                {{ item.isShowAllGO ? "Show Less" : "Show More" }}
              </div>
            </div>

            <app-tableCeil *ngIf='obj.mobile' [idxCeil]='3' replaceTitleFromHeader_forMobile='Open Roles'
                           [text1]='item.text_noAssignGO_outOf_goLength' [bordNone_forMobile]='true' [commaForMobile]='false'></app-tableCeil>

            <div class='btnTableForOpenGames' [class.o-bord-none]='obj.mobile' ceilTable [idxCeil]='4'>
              <!-- === !!! Миша - для desktop есть disabled, для мобилы невозможно, т.к. для мобилы нет возможности выбрать роль  -->
              <btn *ngIf='obj.desktop' [btnWidth]='87' [btnHeight]='36' text='Request' bcg='blueDark'
                   [disabled]='!(item?.gameOfficials|isSelect)' (click)='requestAssignOfficial(item?.gameOfficials)'></btn>
              <btn *ngIf='obj.mobile' [btnWidth]='"100%"' [btnHeight]='36' text='Request' bcg='blueDark'
                   (click)='requestForMobile_openPopup(item?.gameOfficials)'></btn>
              <!-- <btn [btnWidth]='obj.desktop ? 87:"100%"' [btnHeight]='36' text='Request' bcg='blueDark'-->
              <!-- [disabled]='obj.desktop ? !(item?.gameOfficials|isSelect) : false' -->
              <!-- (click)='obj.desktop ? request(item?.gameOfficials) : requestForMobile_openPopup(item?.gameOfficials)'></btn>-->
            </div>
          </div>
        </ng-container>
      </ng-container>
      <app-tableEmpty [tableEmpty]='filtersS.searchValue|getTableEmpty'
                      [isRequestAssignOfficial]='isRequestAssignOfficial' [path]='urlOpenGames'></app-tableEmpty>
    </div>
  </div>
  
  <app-tableInfiniteLoading *ngIf='!isRequestAssignOfficial'></app-tableInfiniteLoading>
  <!--  <pagination *ngIf='meTableS.showPagination' [data]='obj.dataTable' (emit)='emitSettingRequest($event,"pagination")'></pagination>-->
  <paginationWithSettings *ngIf='meTableS.showPagination'></paginationWithSettings>
</div>