import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountExternalModel } from '@models/account-external.model';
import { ClassSettingsRequest } from '@models/response-and-request';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PayoutApiService {
  url = '/api/payments/v1/payout';

  constructor(private http: HttpClient) { }

  full(userId: string, externalAccountId: string, payoutMethod: string): Observable<any> {
    return this.http.post(`${this.url}/official/full`, null, {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'text',
      params: {
        // userId,
        externalAccountId,
        payoutMethod: payoutMethod,
      },
    })
  }

  generalPayout(externalAccountId: string, payoutMethod: string): Observable<any> {
    return this.http.post(`${this.url}`, null, {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'text',
      params: {
        externalAccountId,
        payoutMethod,
      },
    });
  }

  payout(): Observable<any> {
    return this.http.post(`${this.url}/?externalAccountId=zyx`, null, {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'text',
      params: {
      },
    })
  }
}
