import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DashboardState } from "@app/store/dashboard/models/dashboard.models";

export const selectDashboardState = createFeatureSelector<DashboardState>('dashboard');

export const selectSubAssignorState = createSelector(
    selectDashboardState,
    (state) => state?.subAssignor
);

export const selectCurrentViewDropDown = createSelector(
    selectSubAssignorState,
    (state) => state?.currentViewDropdown
);

export const selectGameStatus = createSelector(
    selectSubAssignorState,
    (state) => state?.gameStatus
)

export const selectSelfAssigned = createSelector(
    selectSubAssignorState,
    (state) => state?.selfAssigned
)

export const selectDashboardTotalGameCount = createSelector(
    selectDashboardState,
    (state) => state?.dashboardGameTable?.totalGames
)

export const selectDashboardAllGames = createSelector(
    selectDashboardState,
    (state) => state?.dashboardGameTable?.games
)

export const selectDashboardAssignData = createSelector(
    selectDashboardState,
    (state) => ({
        ...state,
        assignData: { ...state?.subAssignor?.assignData }
    })
);

export const selectDashboardAssignDataCount = createSelector(
    selectSubAssignorState,
    (state) => state?.assignData?.totalElements
)

export const selectDashboardDeclinedData = createSelector(
    selectSubAssignorState,
    (state) => ({
        ...state,
        declinedData: {...state?.declinedData}
    })
);


export const selectDashboardDeclinedDataCount = createSelector(
    selectSubAssignorState,
    (state) => state?.declinedData?.totalElements
)

export const selectDashboardUnassignedData = createSelector(
    selectSubAssignorState,
    (state) => {
        return {
            ...state,
            unassignedData:{...state?.unassignedData},
        }
    }
)

export const selectDashboardUnassignedDataCount = createSelector(
    selectDashboardState,
    (state) => state?.subAssignor.unassignedData?.totalElements
)
