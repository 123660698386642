<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              arrCurrentLink: gameS.arrCurrentLinkDrop,
              objCurrentLink: (gameS.currentLink$|async)!,
              currentLink: (gameS.currentLink$|async)?.currentLink!,
             } as obj' class='o-wrapPage' wrapPage [widthPage]='1393' cdkDropListGroup>

  <!--  === FILTERS & TITLE ===========================-->
  <div class='o-header'>
    <div class='wrapContentAssign o-content'>
      <div class='wrapContentAssign__content'>
        <div class='o-table'>
          <div class='o-table__header' *ngIf='deviceS.isDesktop$ | async'>
            <div *ngFor='let itemHeader of tableHeader; let idx = index; let last = last'
                 class='o-table__header__item widthItem--{{idx+1}}'>
              {{ itemHeader.text }}
            </div>
          </div>


          <div *ngIf='!(loading$|async); else spinner' #tableList [class]='obj.desktop ? "o-table__list apply-scrolling" : "o-table__list"'>
            <ng-container *ngIf='(gameListSub$|async)?.length; else nothingList'>
              <ng-container
                            *ngFor='let gameItem of (gameListSub$ | async); let first = first; let idxGameItem = index; let last = last'>
                <div #listItem (click)='scrollToTop(tableList, last)' *ngIf='{
                                  gameCANCELLED: gameItem.gameStatus == "CANCELLED",
                                  existSelectedElems: (gameItem?.gameOfficials | getSelectedElems)?.length,
                                  selectedElems: (gameItem?.gameOfficials | getSelectedElems),
                                  showBtn_showMore: gameItem?.gameOfficials?.length ? gameItem!.gameOfficials!.length > amountForShowAllGO : false,
                                  } as objGameItem'
                     class='o-table__list__item o-table__list__item--{{objGameItem.gameCANCELLED ? "colorGrey3":""}}'
                     [class.o-opacity]='gameItem.gameStatus == "CLOSED"'
                     [class.selected]='gameItem.id === queryParamsObj?.gameId'>
                  <!-- !!! === gameNumber ===-->
                  <div class='o-table__list__ipad'>
                    <div class='o-table__list__item__name widthItem--1'>
                      <span *ngIf="deviceS.isDesktop$ | async">{{ gameItem.gameNumber }}</span>
                      <span *ngIf="deviceS.isMobile$| async">#{{ gameItem.gameNumber }}</span>
                      <meSvg *ngIf='(deviceS.isDesktop$ | async) && gameItem.gameReport'
                             (click)='router.navigate([urlReportInfo, gameItem.gameReport.id])' class='o-cur-pointer'
                             [svgName]='gameItem.gameStatus == "CLOSED" ? "sheet_squareGreen&36":"sheet_squareBlueDark&36"'>
                      </meSvg>
                    </div>

                    <div class='widthItem--2 o-table--itemColumn o-noWrap' *ngIf="deviceS.isDesktop$ | async">
                      <dateFormatted [date]='gameItem.date!' type='date' format='E, LLL d, yyyy'></dateFormatted>
                      <dateFormatted [date]='gameItem.date!' type='time' format='shortTime'></dateFormatted>
                      <div class='o-table--itemColumn__twoLine'>
                        <span>{{ gameItem|getAgeGenderLevel:'ClassGame' }}</span>
                        <span>{{ gameItem.competition?.competitionName || 'TBD' }}</span>
                      </div>
                    </div>

                    <div class='widthItem--3 o-table--itemColumn__twoLine' *ngIf="deviceS.isMobile$ | async">
                      <h4>Date & Time</h4>
                      <span>{{gameItem.date_EE_MMMd_yyy}}, {{gameItem.date_shortTime_timezone}}</span>
                      <h4>Age & Level</h4>
                      <span>{{gameItem|getAgeGenderLevel:'ClassGame'}}, {{gameItem.competition?.competitionName || 'TBD'}}</span>
                      <h4>Location</h4>
                      <span>
                        {{ gameItem|getLocNameCourtName }}, {{ gameItem.location?.address|getLocationString:['city', 'state'] }}
                      </span>
                      <h4>Teams</h4>
                      <span>
                        {{ gameItem.homeTeam?.teamName || 'TBD' + " vs." + gameItem.awayTeam?.teamName || 'TBD' }}
                      </span>
                      <h4>Officials</h4>
                      <span>
                        {{ gameItem.text_assignGO_outOf_goLength }}
                      </span>
                    </div>

                    <!-- !!! === Location & Teams ===-->
                    <div class='widthItem--3 o-table--itemColumn o-pad-0' *ngIf="deviceS.isDesktop$ | async">
                      <h4 *ngIf="deviceS.isMobile$ | async">Location</h4>
                      <span class='paddingForLocationsAndTeams'>{{ gameItem|getLocNameCourtName }}</span>

                      <span [matTooltip]='gameItem|matTooltipForLocation' class='paddingForLocationsAndTeams'>
                        {{ gameItem.location?.address|getLocationString:['city', 'state'] }}
                      </span>

                      <div class='o-table--itemColumn__twoLine teamsBlock'>
                        <div>
                          <span class='o-hidden'>3</span>
                          <meSvg svgName='star_yellow&18' class='o-hidden'></meSvg>
                          <span>{{ gameItem.homeTeam?.teamName || 'TBD' }}</span>
                        </div>
                        <div>
                          <span class='o-hidden'>4</span>
                          <meSvg svgName='star_yellow&18' class='o-hidden'></meSvg>
                          <span>{{ gameItem.awayTeam?.teamName || 'TBD' }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class='widthItem--4 o-table--itemColumn officialsCeil' *ngIf="deviceS.isDesktop$ | async">
                      <ng-container *ngFor='let go of gameItem?.gameOfficials;let idxRole=index'>
                        <ng-container *ngIf='{ACCEPTED: go.status == "ACCEPTED",
                                        UNACCEPTED: go.status == "UNACCEPTED",
                                        DECLINED: go.status == "DECLINED",
                                        UNASSIGNED: go.status == "UNASSIGNED"} as goObj'>

                          <div #finalElem *ngIf='gameItem.isShowAllGO || idxRole < amountForShowAllGO'
                               class='officialsCeil__item'
                               [class.officialsCeil__item--itemSelected]='!isReadOnly && (finalElem.style.opacity !== "1" ? false : go.isSelect) '
                               [style.opacity]="activeTile === 'UNACCEPTED' && go.status !== 'UNACCEPTED' && go.status !== 'UNPUBLISHED' || activeTile === 'DECLINED' && go.status !== 'DECLINED' ||
                                   activeTile === 'UNASSIGNED' && go.status !== 'UNASSIGNED'? '0.5': '1'"
                               (click)='gameItem.gameStatus=="CLOSED" ? null : go.isSelect=!go.isSelect'>
                            <div (click)='selectOfficial(gameItem.id || "", go, gameItem, finalElem, objGameItem.selectedElems)'
                                 [id]='"idxRole_"+idxRole'
                                 class='officialsCeil__itemCdkDropList'>
                              <div class='officialsCeil__item__left'>
                                <meSvg [svgName]='go|getSvgForGoStatus'></meSvg>
                                Official #{{ go.officialPositionNumber }}
                                ({{ go.officialPositionName|getFirstLetterEachWord:2 }})
                              </div>
                              <div class='officialsCeil__item__right o-noWrap'>
                                <span *ngIf='!go.official && !goObj.UNASSIGNED'>{{ go.status | titlecase }}</span>
                                <span *ngIf='!go.official && goObj.UNASSIGNED'>Unassigned</span>
                                <span *ngIf='go.official'>{{ go.official|getUserName }}</span>
                                <meSvg svgName='arrow_chevronRight&24' [h]='20' [w]='20' (click)='null'>
                                </meSvg>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                  <div class="item-action-button">
                    <btn text='Manage'
                         bcg='blueDark'
                         (click)='assignS.goToAssign(gameItem)'
                         btnHeight='36'
                         *ngIf="deviceS.isMobile$ | async"
                         [disabled]="isReadOnly"></btn>
                    <btn text='Manage All'
                         [disabled]="isReadOnly"
                         bcg='blueDark'
                         (click)='manageAll()'
                         btnHeight='36' *ngIf="last && (deviceS.isMobile$ | async)" style="padding-top: 10px; border-top: 1px solid #D0D5DD; margin-top: 10px"></btn>
                  </div>

                  <div class="manage-buttons" [ngSwitch]="activeTile" *ngIf="!isReadOnly && (deviceS.isDesktop$ | async)">
                    <div *ngSwitchCase="'UNACCEPTED'" class='manage-buttons-items'>
                      <!--                        <ng-container *ngIf="getSelectedStatus(objGameItem.selectedElems) === 'First' || objGameItem.selectedElems.length === 0"-->
                      <ng-container *ngIf="getSelectedStatus(objGameItem.selectedElems) === 'First'">
                        <btn [fontSize]='14' [disabled]='checkSelectedElems(objGameItem.selectedElems)' text='Accept' bcg='blueDark'
                             (click)='setStatusProcess(objGameItem.selectedElems, "ACCEPTED")'
                             [btnHeight]='36' [btnWidth]=' 105'></btn>
                        <btn text='Decline' bcg='red' (click)='setStatusProcess(objGameItem.selectedElems, "DECLINED")'
                             [btnHeight]='36' [btnWidth]=' 105' [fontSize]='14'
                             [disabled]='checkSelectedElems(objGameItem.selectedElems)'></btn>
                      </ng-container>
                      <div>
                        <div *ngIf="!isReadOnly && getSelectedStatus(objGameItem.selectedElems) === 'UNPUBLISHED'"
                             [style.opacity]="getSelectedStatus(objGameItem.selectedElems) === 'UNPUBLISHED' ? '1' : '0'"
                             class='manage-buttons-items'>
                          <btn [fontSize]='14' [disabled]='checkSelectedElems(objGameItem.selectedElems)' text='Publish'
                               bcg='blueDark' (click)='assignOfficials(objGameItem.selectedElems)'
                               [btnHeight]='36' [btnWidth]=' 105'></btn>
                          <btn text='Remove' bcg='red' (click)='removeOfficials(objGameItem.selectedElems, gameItem)'
                               [btnHeight]='36' [btnWidth]=' 105' [fontSize]='14'
                               [disabled]='checkSelectedElems(objGameItem.selectedElems)'></btn>
                        </div>
                        <div *ngIf="!isReadOnly && getSelectedStatus(objGameItem.selectedElems) === 'Second'" class='manage-buttons-items'>
                          <ng-container>
                            <btn [fontSize]='14' [disabled]='checkSelectedElems(objGameItem.selectedElems)' text='Resend'
                                 bcg='blueDark' (click)='resendOfficial(objGameItem.selectedElems)'
                                 [btnHeight]='36' [btnWidth]='105'></btn>
                            <btn text='Manage' bcg='white' (click)='assignS.goToAssign(gameItem)'
                                 [btnHeight]='36' [btnWidth]='105' [fontSize]='14'
                                 [disabled]='isReadOnly || checkSelectedElems(objGameItem.selectedElems) '></btn>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!isReadOnly && activeTile === 'DECLINED'" class='manage-buttons-items'>
                      <btn [fontSize]='14' text='Manage' bcg='blueDark'
                           [disabled]='isReadOnly'
                           (click)='assignS.goToAssign(gameItem)'
                           [btnHeight]='36' [btnWidth]=' 105'></btn>
                      <btn text='Remove' bcg='white' (click)='removeOfficials(objGameItem.selectedElems, gameItem)'
                           [btnHeight]='36' [btnWidth]=' 105' [fontSize]='14'
                           [disabled]='checkSelectedElems(objGameItem.selectedElems)'></btn>
                    </div>
                    <div *ngIf="!isReadOnly && activeTile === 'UNASSIGNED'" class='manage-buttons-items'>
                      <btn [fontSize]='14'
                           [disabled]='isReadOnly'
                           text='Manage' bcg='blueDark' (click)='assignS.goToAssign(gameItem)'
                           [btnHeight]='36' [btnWidth]=' 105'></btn>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>

      </div>
    </div>
  </div>

  <ng-template #nothingList>
    <!--    <nothingList class='o-table' typePage='assign'></nothingList>-->
    <nothingList [tableEmpty]='filtersS.searchValue|getTableEmpty'>
      <btnImportGames class='class_ngContent_btn_for_nothingList'></btnImportGames>
    </nothingList>
  </ng-template>
  <ng-template #spinner>
    <spinner></spinner>
  </ng-template>
</div>