<div class='wrapperPopupAssign'>
  <headTitle text='Assign' svgRight='cross_grey&24' (svgRightEmit)='dialogRef.close(false)' typeText='text4-blueDark'></headTitle>

  <inputCtrl [(ngModel)]='search' placeholder='Enter Referee Name or Email' svgPrefix='search_grey&20' [isFocus]='true'></inputCtrl>

  <div *ngIf='!(loading$|async); else spinner' class='wrapOfficialsPopupAssign'>
    <div *ngFor='let official of officials' class='wrapOfficialsPopupAssign__item'>
      <div class='wrapOfficialsPopupAssign__item__info'>
        <photo *ngIf='!(deviceService.isMobile$|async)' [data]='official'></photo>
        <div class='wrapOfficialsPopupAssign__item__info__name'>
          <span>{{ official|getUserName }}</span>
          <span>{{ official.email }}</span>
        </div>
      </div>

      <btn text='Assign' bcg='newGreen' (emit)='assignOfficials(official)' btnHeight='29' btnWidth='79' [disabled]='reqPending'></btn>
    </div>
  </div>

  <!--  <pagination *ngIf='officials?.length' [data]='data' (updatePage)='paginationMethod("updatePage",undefined,$event)'-->
  <!--              (updateResults)='paginationMethod("updateCountResults",$event)' [forPopup]='true'></pagination>-->

  <line *ngIf='officials?.length'></line>

  <field text='Not a Notch Member?'>
    <inputCtrl [(ngModel)]='email' placeholder='Enter email'></inputCtrl>
  </field>

  <btnWrap>
    <btn text='Cancel' bcg='white' (emit)='dialogRef.close(false)' [disabled]='reqPending'></btn>
    <btn text='Done' bcg='blueDark' (emit)='inviteByEmail()' [disabled]='!email || reqPending'></btn>
  </btnWrap>

</div>

<ng-template #spinner>
  <spinner></spinner>
</ng-template>
