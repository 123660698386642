<ng-container *ngIf="!showInstantSuccessPage">
<div *ngIf="{ desktop: (deviceS.isDesktop$ | async)!, mobile: (deviceS.isMobile$ | async)! } as obj"
  class="payment-method-page">

  <headTitle [class.headTitle--desktop]="obj.desktop" [class.headTitle--mobile]="obj.mobile" text="Instant Payout"
    typeText="text12-blueDark" [svgLeft]="obj.desktop ? 'arrow_squareLeft&24' : 'arrow_squareLeft&24'"
    (svgLeftEmit)="otherS.goToBack()">
  </headTitle>


  <div *ngIf="dataPopup.isPayout && dataPopup.balance" class="o-text1 balance">
    {{ (dataPopup.balance) | currency:'USD' }}
  </div>
  <div class="o-text1 balanceFee">2% or min $3.99. Arrives in minutes.</div>

  <app-manage-payment-method [accounts]="dataPopup.accounts" [accountDefault]="accountDefault"
    [selectedAccount]="selectedAccount" (removeAccount)="onRemoveAccount($event)"
    (defaultAccount)="onSetDefaultAccount($event)" [hideHeadTitle]="true">
  </app-manage-payment-method>

  <btnWrap *ngIf="!isEdit || dataPopup.isPayout" [type]="obj.desktop ? 'row' : 'col'">
    <span class="instant-fee">
      ${{ getQuickPayFee() | number:'1.2-2' }} Instant Fee
    </span>
    <btn *ngIf="dataPopup.isPayout" class="btn-payout" [text]="'Proceed to Payout ' + getPayoutAmount()"
      [disabled]="!selectedAccount || isLoading || finalPayout === 0" bcg="blueDark" (click)="onPayout()">
    </btn>
  </btnWrap>

  <spinner *ngIf="!isEdit && isLoading" [diameter]="20" [isNoMargin]="true"></spinner>

</div>
</ng-container>

<ng-container *ngIf="showInstantSuccessPage">
  <app-instant-success-dialog
    [popupType]="popupType"
    (closeEvent)="handleSuccessClose()">
  </app-instant-success-dialog>
</ng-container>