import { Component } from '@angular/core';
import { TextMessageService } from '@components/message-from-server/components/text-message.service';

@Component({
  selector: 'app-success-message',
  templateUrl: './success-message.component.html',
  styleUrls: ['./success-message.component.scss'],
  standalone: true,
  providers: [TextMessageService],
})
export class SuccessMessageComponent {

  constructor(
    public textMessageS: TextMessageService,
  ) {
  }
}
