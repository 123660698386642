<div class='wrapperField wrapperField--{{page}}'>
  <!--  если title не передал, то можно передать ngContentTop-->
  <ng-content select='.ngContentTop'></ng-content>

  <div class='titleBlockField titleBlockField--{{page}}'>
    <!--    *ngIf='text'-->
    <div *ngIf='haveText'
         class='titleField titleField--{{page}} o-{{typeText}} {{margin ? "o-mg-b-" + margin : "o-mg-b-6"}}'>
      {{text}}<span *ngIf='star || ctrl?.validator' class='o-colorRed'>*</span>
      <meSvg *ngIf='svgName' [svgName]='svgName' [matTooltip]='matTooltipSVG||""' (emitData)='emitFile($event)'
             (click)='svgEmit.emit()' [w]='w'></meSvg>
    </div>
    <ng-content select='.ngTitleRight'></ng-content>
  </div>

  <ng-content></ng-content>
  <ng-content select='.ngContentBottom'></ng-content>
</div>