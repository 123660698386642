<div *ngIf='{ desktop: (deviceS.isDesktop$|async)!,
              mobile: (deviceS.isMobile$|async)!,
            } as obj' class='defaultPopupWrapper'>
  <headTitle text='Cancel the game' svgRight='cross_grey&24' (svgRightEmit)='close()' typeText='text4-blueDark'></headTitle>

  <div class='defaultPopupWrapper__subTitle'>
    To cancel please provide an adjustment status and the option to add a reason that is sent to any assigned officials.
  </div>

  <field text='Adjustment Status'>
    <app-dropdown [(ngModel)]='gameAdjustmentStatus' [array]='arrAdjustmentStatus' placeholder='Adjustment Status'
                  [needSortByName]='false'></app-dropdown>
  </field>

  <!--  <field text='Reason (Optional)' class='reasonCancelGame'>-->
  <field text='Reason (Optional)'>
    <!--    formControlName='adjustmentStatus'  miHeightTextarea='150px'  -->
    <!--    placeholder='There was a conflict and a fight between the teams and the game had to be canceled.'-->
    <inputCtrl [(ngModel)]='cancelReason' type='textarea' height='150'
               placeholder='It’s optional to enter a reason and inform any assigned officials why the game was canceled…'></inputCtrl>
  </field>

  <div *ngIf='gameAdjustmentStatus?.officialPay === 0 || !gameAdjustmentStatus; else bottomTextCancelGame'
       class='defaultPopupWrapper__textBottom'>
    Are you sure you want to proceed and cancel? <br>
    This action is irreversible.
  </div>
  <ng-template #bottomTextCancelGame>
    <div *ngIf='gameAdjustmentStatus?.officialPay !== 0' class='defaultPopupWrapper__textBottom'>
      Are you sure you want to proceed and cancel? <br>
      You've selected an adjustment status that will transfer a payment once approved as a post game report.
    </div>
  </ng-template>

  <btnWrap [swapBtn]='obj.mobile'>
    <btn text='Return' bcg='white' [disabled]='reqPending' (emit)='close()'></btn>
    <!--    || !cancelReason-->
    <btn text='Cancel' bcg='red' [disabled]='reqPending || !gameAdjustmentStatus' (emit)='cancelGames()'></btn>
  </btnWrap>
</div>