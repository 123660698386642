<div class='wrapperPhoneStep'>
  <div class='authText'>
    <div>
      Please provide your cell phone
      <br>
      <ng-container *ngIf="isAdmin==false">
        We'll want to verify this information to ensure your assignor can contact you and you can choose
        to opt-in to receive SMS game reminders.
      </ng-container>
    </div>
  </div>

  <phone #phoneComponent (changeVal)='phone=$event' [isFocus]='true'></phone>
  <div class='gameReminderOn' (click)='gameReminderOn=!gameReminderOn'>
    <meSvg [svgName]='gameReminderOn | getChxSvgName'></meSvg>
    {{isAdmin==true? 'Opt-in to receive SMS messages':'Text me game reminders'}}
  </div>

  <div *ngIf="isAdmin" class='disclaim-text-1'>
    By providing your phone number, you agree to receive SMS updates and notifications from Notch. Message and data
    rates may apply. Text STOP to unsubscribe at any time.
  </div>

  <ng-container *ngIf="isAdmin==false">
    <div class='disclaim-text-1'>
      By entering my cell phone number, I agree to receive game reminders from Notch.
    </div>

    <div class='disclaim-text-2'>
      Message frequency varies by assignments. Message + data rates may apply.
      Reply STOP to unsubscribe or HELP for help. View
      <span style='font-weight: 600;'>privacy policy </span> <a class='a-privacy-policy' target='_blank'
        href='https://www.joinnotch.com/privacy'>here</a> .
    </div>
  </ng-container>



  <btn text='Verify Cellphone' [bcg]='authS.bcgApplyBtn' [spinner]='reqPending' (emit)='sendCodeOnPhone()'
    class='o-mg-t-24 o-mg-b-24' [disabled]='phoneComponent.invalid'></btn>
  <btnBack text='Back' (click)='backStepEvent.emit();emit.emit("back");'></btnBack>


</div>