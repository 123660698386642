<div class='wrapperPopup'>
  <div class="head-line">
    <headTitle text='Viewable to/Recipients'
      svgRight='cross_grey&24'
      (svgRightEmit)='closeDialog(null)'
      typeText='text4-blueDark'></headTitle>
    <div class="o-text8">The following recipients will receive your message</div>
  </div>
  <div class="content">
    <inputCtrl [(ngModel)]='search'
      (changeVal)="changeVal($event)"
      placeholder='Search'
      svgPrefix='search_grey&20'>
    </inputCtrl>
    <div class="o-text7">Viewable to/Recipients*</div>
    <div>
      <div class='o-table__header'>
        <div *ngFor='let itemHeader of tableHeader; let idx = index;'
          class='o-table__header__item widthItem--{{idx+1}}'>
          {{itemHeader.text}}
        </div>
      </div>

      <div class='o-table__list'>
        <ng-container *ngFor='let item of filteredTable || tableItems; let first = first; let idx = index; let last = last'>
          <div class='o-table__list__item'>
            <div class='name widthItem--1'>
              <div class="o-table--itemColumn">
                <span class="o-text7">{{item.firstName}} {{item.secondName}}</span>
              </div>
            </div>
            <div class='widthItem--2 o-table--itemColumn o-noWrap'>
              <span>{{item.email}}</span>
            </div>
            <div (click)="removeEmailFromList(item.email)"
              class='widthItem--3 o-text7-red cur'>
              Remove
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class='actionsPopup'>
    <btn text='Close'
      bcg='white'
      (emit)='closeDialog(null)'></btn>
  </div>
</div>