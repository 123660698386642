import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '@services/utils.service';

@Pipe({ name: 'getAgeByDateOfBirth', standalone: true })
export class GetAgeByDateOfBirthPipe implements PipeTransform {

  // !!! dateFromServer == 2023-08-17
  transform(dateFromServer: string): number | string {
    return UtilsService.getAgeByDateOfBirth(dateFromServer);
  }

}
