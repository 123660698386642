<div class='wrapperPopupCustomizeInvitation'>
  <headTitle text='Customize your invitation' svgRight='cross_grey&24' (svgRightEmit)='close(null)' typeText='text4-blueDark'></headTitle>
  <div class='wrapperPopupCustomizeInvitation__subTitle'>Add a personal note to your invitation</div>

  <!--  <div class='notePopupCustomizeInvitation'>-->
  <!--  </div>-->
  <field text='Note' class='o-mg-t-20 o-mg-b-32'>
    <inputCtrl [(ngModel)]='noteValue' placeholder='Add a personal note…' type='textarea' height='150' [isFocus]='true'></inputCtrl>
  </field>

  <div class='actionsPopupCustomizeInvitation'>
    <!--    <svgAndText text='Copy link' leftSvgName='squareTwo2&20' typeText='text5-blueDark' (leftSvgEmit)='getLinkOfficialToGroup()'></svgAndText>-->
    <!--    <meSvg svgName='clock_red&20' class='o-mg-l-7' matTooltip='Link will be expired in 7 days'></meSvg>-->
    <!--    <copyLink [showClock]='true' [path]='path' [userRole]='userRole' [competition]='competition'></copyLink>-->
    <div class='btnWrapPopupCustomizeInvitation'>
      <btn text='Cancel' bcg='white' (emit)='close(null)'></btn>
      <btn text='Save' bcg='blueDark' (emit)='sendInvitation()'></btn>
    </div>
  </div>
</div>