import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { HelperClass } from '@classes/Helper-Classes';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { TColor } from '@models/ICssStyles';
import { BtnComponent } from '../../../shared/components/btn/btn.component';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-attentions',
  standalone: true,
  templateUrl: './attentions.component.html',
  styleUrls: ['./attentions.component.scss'],
  imports: [CommonModule, HeadTitleComponent, BtnComponent],
})
export class AttentionsComponent extends HelperClass implements OnInit {
  attentions: Array<{
    innerHTML: string;
    id: number;
    buttons: Array<{
      text: string;
      type: TColor;
    }>
  }> = [];

  constructor(
    public cd: ChangeDetectorRef,
    private location: Location,
  ) {
    super(cd);
  }

  ngOnInit() {
    this.attentions = (this.location.getState() as any).attentions || [];
  }

  goToBack(): void {
    window.history.back();
  }

}
