import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'roundBalance', standalone: true })
export class RoundBalancePipe implements PipeTransform {

  transform(value?: string | number, forTest?: string): string {
    const valueString: string = value?.toString()?.replace('$', '') || '';
    if (!valueString) return `$0`;
    const valueNumber: number = +valueString;
    // if (test) {
    //   console.log('value :', typeof value, value)
    //   console.log('valueString :', typeof  valueString, valueString)
    //   console.log('valueNumber :', typeof  valueNumber, valueNumber)
    // }
    if (valueNumber >= 1000) {
      // if (test) {
      //   console.log('result 111 :', '$' + this.rounding(valueNumber)/1000 + 'k')
      // }
      return '$' + this.rounding(valueNumber) / 1000 + 'k';
    } else {
      // console.log('result 222 :', '$' + valueNumber)
      return '$' + valueNumber;
    }
  }

  rounding(val: number): number {
    return Math.floor(val / 100) * 100;
    // return Math.round(val / 100) * 100;
  }

}
