import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { DateFormattedComponent } from '@components/__date/date-formatted/date-formatted.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { BtnComponent } from '@components/btn/btn.component';
import { NothingListComponent } from '@components/nothing-list/nothing-list.component';
import { ClassGroup } from '@models/IGroup';
import { ClassGame, ClassGameOfficial } from '@app/dir_group_assignor/games/game';
import { GetAgeGenderLevelPipe } from '@pipes/get-age-gender-level.pipe';
import { GetUserNamePipe } from '@pipes/get-name.pipe';
import { GetLocNameCourtNamePipe } from '@pipes/location/get-loc-name-court-name.pipe';
import { GetLocationStringPipe } from '@pipes/location/get-location-string.pipe';
import { MatTooltipForLocationPipe } from '@pipes/location/mat-tooltip-for-location.pipe';
import { GetFirstLetterEachWordPipe } from '@pipes/string/get-first-letter-each-word.pipe';
import { DeviceService } from '@services/device.service';
import { MainService } from '@services/main.service';
import { MeService } from '@services/me.service';
import { OtherService } from '@services/other.service';
import { Observable } from 'rxjs';
import { GetSvgForGoStatusPipe } from '@pipes/gameOfficial/get-svg-for-go-status.pipe';
import { urlGameInfo } from '@app/app.module';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UtilsService } from '@services/utils.service';
import { GetTableEmptyForDashboardPipe } from '@components/_table/pipes/get-table-empty-for-dashboard.pipe';
import { FiltersService } from '@components/filters/filters.service';

interface IFormDashboard {
  search: FormControl<string>;
  viewDateRange: FormControl<string>;
  group: FormControl<ClassGroup>;
  competition: FormControl<string>;
  currentViewDropdown: FormControl<string>;
  gameStatus: FormControl<string>;
  selfAssigned?: FormControl<boolean>;
  from?: FormControl<string>;
  to?: FormControl<string>;
}

// !!! мне пришлось сделать эту херню чтобы не ломать текущий функционал. Т.к. рефакторить код после Гранта очень долго
export type TSelectedTable_dashboard_forOfficial = 'accepted' | 'unaccepted' | 'unacceptedselfassigned'

@UntilDestroy()
@Component({
  selector: 'app-unaccepted-games',
  templateUrl: './unaccepted-games.component.html',
  styleUrls: ['./unaccepted-games.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
    RouterLink,

    BtnComponent,
    DateFormattedComponent,
    SvgComponent,
    NothingListComponent,

    GetAgeGenderLevelPipe,
    GetLocNameCourtNamePipe,
    GetLocationStringPipe,
    GetFirstLetterEachWordPipe,
    GetUserNamePipe,
    MatTooltipForLocationPipe,
    GetSvgForGoStatusPipe,
    GetTableEmptyForDashboardPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnacceptedGamesComponent {
  private destroy!: () => Observable<unknown>;
  readonly urlGameInfo = urlGameInfo;
  // readonly class_ngContent_btn_for_nothingList = class_ngContent_btn_for_nothingList;

  private _gameData: Array<ClassGame> = [];
  private _formData!: any;
  private _headerData: Array<{ title: string, isSelect: boolean, sort: boolean; isActive: boolean; }> = [];
  private _selectedTable!: TSelectedTable_dashboard_forOfficial;
  private _unAcceptedSelfAssign!: boolean;

  @Input()
  get gameData() {
    return this._gameData;
  }

  set gameData(gameData) {
    this._gameData = gameData;
  }

  @Input()
  get formData() {

    return this._formData;
  }

  set formData(formData) {
    this._formData = formData;
  }

  @Input()
  get headerData() {
    return this._headerData;
  }

  set headerData(headerData) {
    this._headerData = headerData;
  }

  @Input()
  get selectedTable() {
    return this._selectedTable;
  }

  set selectedTable(selectedTable: 'accepted' | 'unaccepted' | 'unacceptedselfassigned') {
    this._selectedTable = selectedTable;
  }

  @Input()
  get unAcceptedSelfAssign() {
    return this._unAcceptedSelfAssign;
  }

  set unAcceptedSelfAssign(unAcceptedSelfAssign) {
    this._unAcceptedSelfAssign = unAcceptedSelfAssign;
  }

  @Output() status: EventEmitter<{ event: Event, game: ClassGame, status: string; }> = new EventEmitter();

  amountForShowAllGO = 4;

  constructor(
    private otherS: OtherService,
    public deviceS: DeviceService,
    public mainS: MainService,
    public meS: MeService,
    public filtersS: FiltersService,
    public router: Router,
  ) {
  }

  arrForColumnComingSoon: Array<{ title: string, value: string | number, id: string; }> = [
    { title: 'Accepted Games', value: 0, id: 'accepted' },
    { title: 'Games to Accept', value: 0, id: 'unaccepted' },
    { title: 'Games Requested', value: 0, id: 'unacceptedselfassigned' },
  ];

  viewAllText(gamesCount: number) {
    const count = (gamesCount && gamesCount) > 5 ? ('(' + gamesCount + ')') : '';
    return `View All ${count}`;
  }

  routeToMyGames() {
    let queryParams: any = {};

    for (const prop in this.formData) {
      typeof this.formData[prop] === 'object'
        ? queryParams[prop] = this.formData[prop]['id']
        : queryParams[prop] = this.formData[prop];
    }

    let mappedParams = this.mapPropertyNames(queryParams,
      {
        'group': 'groupId',
        'competition': 'competitions',
        'gameStatus': 'assignStatuses',
      }, ['assignStatuses']);

    mappedParams = UtilsService.removeEmptyKeysFromObject(mappedParams);

    this.router.navigate(
      ['/myGames'],
      {
        queryParams: mappedParams,
      },
    );
  }

  setStatus(event: Event, game: ClassGame, status: string) {
    event.stopPropagation();
    this.status.emit({ event, game, status });
  }

  getTitleById(id: string): string {
    const item = this.arrForColumnComingSoon.find(item => item.id === id);
    return item ? item.title : '';
  }

  selectOfficial(game: ClassGameOfficial, gameOfficials?: ClassGameOfficial[]) {
    const isSelected = game.isSelect;

    if (gameOfficials) {
      gameOfficials.forEach(it => it.isSelect = false);
    }

    game.isSelect = !isSelected;
  }

  private mapPropertyNames(originalObject: Record<string, any>, propertyMap: Record<string, string>, propertiesToUppercase: string[] = []): Record<string, any> {
    const result: Record<string, any> = {};

    for (const originalKey in originalObject) {
      if (originalObject.hasOwnProperty(originalKey) && propertyMap.hasOwnProperty(originalKey)) {
        const mappedKey = propertyMap[originalKey];
        result[mappedKey] = propertiesToUppercase.includes(mappedKey)
          ? originalObject[originalKey].toUpperCase()
          : originalObject[originalKey];
      } else {
        result[originalKey] = originalObject[originalKey];
      }
    }

    return result;
  }

}

// getTitleByProp(prop: 'accepted' | 'unaccepted' | 'unacceptedselfassigned', object: any = null) {
//   return object[prop].title;
// }
// getDescriptionByProp(prop: 'accepted' | 'unaccepted' | 'unacceptedselfassigned') {
//   return this.gamesHeaderTitles[prop].description;
// }
// gamesHeaderTitles = {
//   accepted: {
//     title: 'You do not have any Accepted Games',
//     description: 'We recommend you check your availability settings and go to your Games section to see if there <br> are any open assignments.',
//   },
//   unaccepted: {
//     title: 'You do not have any games to accept',
//     description: 'We recommend you check your availability settings and go to your Games section to see if there <br> are any open assignments.',
//   },
//   unacceptedselfassigned: {
//     title: 'You do not have any Games Requested',
//     description: 'We recommend you check your availability settings and go to your Games section to see if there <br> are any open assignments.',
//   },
// };
