<div class='wrapperEmailPass' [formGroup]='form'>
  <div class='authText'>Create an account
    <div>Please enter your details</div>
  </div>
  <field text='Email*'>
    <inputCtrl formControlName='email' placeholder='Email' [isFocus]='true' [showErrText]='true'></inputCtrl>
  </field>
  <field text='Password*'>
    <inputCtrl formControlName='password' placeholder='Create a password' [viewPassword]='viewPassword'
      (viewPasswordEmit)='viewPassword=$event' [showErrText]='true'></inputCtrl>
  </field>
  <field text='Re-Enter Password*'>
    <inputCtrl formControlName='confirmPassword' placeholder='Re-Enter password' nameField='confirmPassword'
      [viewPassword]='viewPassword' (viewPasswordEmit)='viewPassword=$event' [showErrText]='true'></inputCtrl>
  </field>
  <btn text='Get started' [bcg]='authS.bcgApplyBtn' [spinner]='reqPending' (emit)='signUp()' class='o-mg-t-24'
    [disabled]='form|invalidControls:["email","password","confirmPassword"]'></btn>
  <googleSignup class='o-mg-t-16' (emit)='signupWithGoogle($event)' type='signup'></googleSignup>
  <app-apple-sing-up class='o-mg-t-12' (appleOurResponse)='appleOurResponse($event)'
    typeOperation='signup'></app-apple-sing-up>

  <div class='textCaptcha'>The site is protected by reCAPTCHA and the
    <span>Privacy Policy</span> and <span>Terms of Service apply</span>
  </div>

  <div class='textCaptcha'>By clicking “Get Started”, I agree to Notch’s <br> TOS and Privacy Policy</div>

  <div class='authBottomText'>Already have an account? <a (click)='goToLogin()'>Log in</a></div>
</div>