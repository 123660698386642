<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              me: (meS.me$|async),
              width1_forContactItem: 239,
             } as obj' class='wrapperMeProfileInfo wrapperMeProfileInfo--{{isEdit}}' [formGroup]='form'>

  <div *ngIf='obj.desktop' class='headerContact'>
    <headTitle text='My Profile' typeText='text1-blueDark' svgLeft='arrow_squareLeft&24' (svgLeftEmit)='otherS.locationBack()'></headTitle>
    <ng-container [ngTemplateOutlet]='actionsTemplate'></ng-container>
  </div>

  <titleAndNavigationForProfile *ngIf='obj.mobile' [typePageProfile]='meProfileS.typePageProfile'
                                [showNavigation]='!isEdit'></titleAndNavigationForProfile>

  <div *ngIf='!isEdit' class='contentContact' [class.contentContact--desktop]='obj.desktop'>
    <photo [data]='obj.me' [w]='obj.desktop ? "160" : "96"' fs='48' typeText='text1-blueDark' class='contentContact__photo'></photo>
    <div class='contentContact__name'>{{ ctrl.firstName?.value }} {{ ctrl.secondName?.value }}</div>

    <ng-container *ngIf='obj.mobile' [ngTemplateOutlet]='actionsTemplate'></ng-container>

    <div class="container">
      <contactItem text1='Date of Birth' [text2]='ctrl.dateOfBirth_formatted?.value' [width1]='obj.width1_forContactItem'></contactItem>
      <contactItem text1='Social Security Number (SSN)' [text2]='ctrl.socialSecurity?.value'
                   [width1]='obj.width1_forContactItem'></contactItem>
      <contactItem text1='Email' [text2]='ctrl.email?.value' [width1]='obj.width1_forContactItem'></contactItem>
      <contactItem text1='Phone Number' [text2]='ctrl.phone?.value|formatPhone' [width1]='obj.width1_forContactItem'></contactItem>

      <!--    === ADDRESS =======================================================-->
      <contactItem text1='Residential Address' [text2]='ctrl.address?.value?.street'
                   [width1]='obj.width1_forContactItem'></contactItem>
      <contactItem text1='Street Address Line 2' [text2]='ctrl.address?.value?.streetLine2'
                   [width1]='obj.width1_forContactItem'></contactItem>
      <contactItem text1='City & State' [text2]='ctrl.address?.value?.city + ", " + ctrl.address?.value?.state'
                   [width1]='obj.width1_forContactItem'></contactItem>
      <contactItem text1='Zip Code' [text2]='ctrl.address?.value?.zipcode' [width1]='obj.width1_forContactItem'
                   class='o-bordNone'></contactItem>
    </div>

  </div>

  <!--  === FOR EDIT ============================================-->
  <div *ngIf='isEdit' class='contentContact' [class.contentContact--desktop]='obj.desktop'>
    <fieldRow text='Personal info' text2='Update your personal details here.'
              class='o-itemEditContact o-itemEditContact' typeText='text4-blueDark'></fieldRow>

    <fieldRow text='' text2='This will be displayed on your profile.' class='hide-on-mobile o-itemEditContact o-itemEditContact--no-border'
              [width]='widthLeftField' alignItems='center'>
      <div class='o-itemEditContact__photo'>
        <photo [data]='obj.me' w='64'></photo>
        <!--        !!! NO DELETE ============================-->
        <!--                <btn text='Upload new photo' bcg='blueDark' (emit)='null' btnHeight='36' btnWidth='155' [disabled]='true'></btn>-->
        <!--                <btn text='Delete' bcg='white' (emit)='null' btnHeight='36' btnWidth='76' [disabled]='true'></btn>-->
      </div>
    </fieldRow>
    <div class="panel-standart-mobile margin-top-mobile-16" style="padding-top: 16px;">
      <fieldRow text='First Name' class='o-itemEditContact' [width]='widthLeftField'>
        <inputCtrl formControlName='firstName' placeholder='First Name' [w]='widthInputForDesktop'></inputCtrl>
      </fieldRow>
      <fieldRow text='Last Name' class='o-itemEditContact o-itemEditContact--border' [width]='widthLeftField'>
        <inputCtrl formControlName='secondName' placeholder='Second Name' [w]='widthInputForDesktop'></inputCtrl>
      </fieldRow>
      <fieldRow text='Social Security Number (SSN)' class='o-itemEditContact o-pad-b-0' [width]='widthLeftField'>
        <inputCtrl formControlName='socialSecurity' placeholder='Enter Last 4 Digits' [isNumber]='true' [allowFirstZero]='true'
                   [maxLength]='4' [w]='widthInputForDesktop'></inputCtrl>
      </fieldRow>
      <fieldRow text='Date of Birth' class='o-itemEditContact o-itemEditContact--border' [width]='widthLeftField'>
        <selectDate placeholder='Select Date of Birth' [value]='ctrl.dateOfBirth?.value' (emit)='ctrl.dateOfBirth.patchValue($event)'
                    [w]='widthInputForDesktop'></selectDate>
      </fieldRow>
      <fieldRow text='Email Address' class='o-itemEditContact o-pad-b-0' [width]='widthLeftField'>
        <div class='o-itemEditContact__col_2'>
          <inputCtrl formControlName='email' placeholder='Email' [showErrText]='true'></inputCtrl>
          <btn *ngIf='ctrl.email?.valid && ctrl.email?.value !== obj.me?.email' text='Confirm' bcg='blueDark'
               (emit)='sendCodeTo_email_or_phone("email")' btnWidth='84' btnHeight='36' [disabled]='reqPending'></btn>
        </div>
      </fieldRow>
      <fieldRow text='' class='o-itemEditContact o-itemEditContact--border' [width]='widthLeftField'>
        <div class='o-itemEditContact__col_2'>
          <!--        [existChxTestPhone]='true'-->
          <phone #phoneComponent [phoneValue]='ctrl.phone?.value' [disabled]='!!ctrl.phone?.disabled'></phone>
          <btn *ngIf='phoneComponent?.valid && (phoneComponent.value|replace:["+"]) !== (obj.me?.phone!|replace:["+"])'
               text='Confirm' bcg='blueDark' (emit)='sendCodeTo_email_or_phone("phone",phoneComponent.value)' btnWidth='311'
               btnHeight='36' [disabled]='reqPending'></btn>
          <!--        <inputCtrl formControlName='phone' placeholder='-'></inputCtrl>-->
        </div>
      </fieldRow>
      <location page='contact' [fields]='fields' [requiredFields]='requiredFields'
                class='o-itemEditContact o-pad-b-0' [widthForFieldRow]='widthLeftField'
                [replaceNameField]='{street: "Residential Address",streetLine2:"Street Address Line 2"}'></location>
    </div>

    <ng-container *ngIf='obj.mobile' [ngTemplateOutlet]='actionsTemplate'></ng-container>
  </div>
  <!--  === ACTIONS BUTTONS ===========================================-->
  <ng-template #actionsTemplate>
    <div *ngIf='!isEdit' class='headerContact__btns'>
      <!--      (click)='profileS.goToEdit(meProfileS.typePageProfile)'-->
      <btn text='Edit' bcg='blueDark' [btnWidth]='obj.mobile ? "100%":58' [btnHeight]='obj.mobile ? 36:""'
           (click)='isEdit=true'></btn>
    </div>
    <div *ngIf='isEdit' class='headerContact__btns'>
      <btn text='Save' bcg='blueDark' (click)='submit()' [spinner]='reqPending' [btnWidth]='obj.mobile ? "100%":65'></btn>
      <btn text='Cancel' bcg='white' (click)='cancel()' [disabled]='reqPending' [btnWidth]='obj.mobile ? "100%":89'></btn>
    </div>
  </ng-template>
</div>