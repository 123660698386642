import { Injectable } from '@angular/core';
import { ClassGame } from '@app/dir_group_assignor/games/game';
import {
  ClassCompetition,
  ClassCompetitionAgeGroup,
  ClassCompetitionLevel,
  ClassCompetitionLocation,
  ClassCompetitionTeam,
} from '@app/dir_group_assignor/competitions/ClassCompetition';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MainService } from '@services/main.service';
import { IRequestOptions, IResponse } from '@models/response-and-request';
import { EditGameComponent } from '@app/dir_group_assignor/games/components/edit-game/edit-game.component';
import { IDataPopup, PopupService } from '@services/popup.service';
import { OtherService } from '@services/other.service';
import { TChoosePeriod } from '@components/__drop_inputs_matSelect/drop-date-range/drop-date-range.component';
import { ILocation } from '@models/location';
import { catchError, tap } from 'rxjs/operators';
import { BaseApi } from '@services/base-api';
import { HttpClient } from '@angular/common/http';
import {
  arrCurrentLinkDrop,
  currentLink_current,
  currentLink_past,
  currentLinkDrop_current,
  currentLinkDrop_past,
  TCurrentLink,
  TCurrentLinkDrop,
} from '@classes/dictionary';
import { AnnouncementItem } from '@app/dir_group_assignor/announcements/models/announcements';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';

// == CreateGameComponent =================
// 1. exist IdGame == Edit Game
// 2. NO exist IdGame == Create Game
// == GameInfoComponent ====================
// 1. exist IdGame == Game Detail Info
// 2. NO exist IdGame == Game Preview (after create game)
// type TypeGamePage = 'createGame' | 'editGame' | 'gameInfo' | 'gamePreview' | '';

export interface IPreviewGame {
  title: string;
  value?: string;

  locationName?: string;
  courtName?: string;
  location?: ILocation;
}

// type TBehaviorSubjectGameService_forDropdown = 'ageGroups$' | 'levels$' | 'locations$' | 'teams$';
// type TKeyOfCompetition_forDropdown = Pick<ClassCompetition, 'ageGroups' | 'levels' | 'locations' | 'teams'>;
// type TKeyOfGame_forDropdown = Pick<ClassGame, 'ageGroup' | 'levels' | 'location' | 'awayTeam' | 'homeTeam'>;

// !!! здесь все зависимости от CurrentLink = 'current' | 'past'
export interface ICurrentLinkGames {
  currentLink?: TCurrentLinkDrop;
  defaultPeriod?: TChoosePeriod; // for dropDateRange
  arrPeriods?: Array<TChoosePeriod>; // for dropDateRange
}

@Injectable({ providedIn: 'root' })
export class GameService extends BaseApi {
  readonly apiAnnouncement = '/api/notification/v1/announcement';

  dataPopupForRemoveOfficialFromGO: IDataPopup = {
    textTitle: 'Remove',
    text: 'Are you sure you want to remove official from this assignment?',
    textBtnApply: 'Remove',
    textBtnCancel: 'Return',
    colorBtnApply: 'red',
    colorBtnCancel: 'white',
    // swapBtn: true, // https://notch.atlassian.net/browse/NOT30-688 for assign при удалении судьи с роли
    ...this.popupS.widthPopup,
  };

  // === CURRENT LINK ===============================================
  arrCurrentLinkDrop = arrCurrentLinkDrop;
  currentLink$ = new BehaviorSubject<ICurrentLinkGames>({});

  updateCurrentLink(currentLink: ICurrentLinkGames) {
    this.currentLink$.next({ ...this.currentLink$.getValue(), ...currentLink });
  }

  // !!! вызывать после того как прочитаны queryParams из url (если из нет то по дефолту установится currentLinkDrop_current)
  setCurrentLink_games(currentLink?: TCurrentLink): void {
    if (currentLink && currentLink === this.currentLinkObj.currentLink?.upperCase) return; // !!! если текущее значение === значению которое поступило, то не надо заново устанавливать
    const currentLinkDrop: TCurrentLinkDrop = currentLink === 'past' ? currentLinkDrop_past : currentLinkDrop_current;
    this.currentLinkObj = { currentLink: currentLinkDrop };
  }

  set currentLinkObj(currentLinkObj: ICurrentLinkGames) {
    const currentLink = currentLinkObj.currentLink!;
    const newCurrentLink: ICurrentLinkGames = {
      currentLink,
      defaultPeriod: currentLink.upperCase == currentLink_current ? 'Next 7 days' : 'Last 7 days',
    };
    this.currentLink$.next(newCurrentLink);
  }

  get currentLinkObj(): ICurrentLinkGames {
    return this.currentLink$.getValue();
  }

  get is_currentLink(): boolean {
    return this.currentLinkObj.currentLink?.upperCase === currentLink_current;
  }

  get is_pastLink(): boolean {
    return this.currentLinkObj.currentLink?.upperCase === currentLink_past;
  }

  // COMPETITION ============================================
  arrCompetition$ = new BehaviorSubject<Array<ClassCompetition>>([]);
  competition$ = new BehaviorSubject<ClassCompetition | null>(null);

  set competition(currentLinkObj: ClassCompetition | null) {
    this.competition$.next(currentLinkObj);
  }

  get competition(): ClassCompetition | null {
    return this.competition$.getValue();
  }

  ageGroups$ = new BehaviorSubject<Array<ClassCompetitionAgeGroup>>([]);
  levels$ = new BehaviorSubject<Array<ClassCompetitionLevel>>([]);
  locations$ = new BehaviorSubject<Array<ClassCompetitionLocation>>([]);
  teams$ = new BehaviorSubject<Array<ClassCompetitionTeam>>([]);

  arrPreviewGame$ = new BehaviorSubject<Array<IPreviewGame>>([]);
  arrPreviewGame_mobile$ = new BehaviorSubject<Array<IPreviewGame>>([]);
  arrPreviewGameCreate_mobile$ = new BehaviorSubject<Array<IPreviewGame>>([]);

  constructor(
    private mainS: MainService,
    private apiCompetitionS: ApiCompetitionService,
    public otherS: OtherService,
    private popupS: PopupService,
    public http: HttpClient,
  ) {
    super(http, otherS);
  }

  reset(): void {
    this.arrCompetition$.next([]);
    this.competition$.next(null);
    this.ageGroups$.next([]);
    this.levels$.next([]);
    this.locations$.next([]);
    this.teams$.next([]);
    this.arrPreviewGame$.next([]);
    this.arrPreviewGame_mobile$.next([]);
    this.arrPreviewGameCreate_mobile$.next([]);
  }

  getArrCompetition(groupId?: string, needUse_cachedData = false): Observable<IResponse<ClassCompetition>> {
    let requestOptions: IRequestOptions | undefined = undefined;
    if (groupId) requestOptions = { params: { groupId } };
    return this.apiCompetitionS.getArrCompetition(requestOptions, needUse_cachedData)
      .pipe(
        tap((res: IResponse<ClassCompetition>) => {
          this.arrCompetition$.next(res?.content || []);
        }),
        catchError(() => {
          this.arrCompetition$.next([]);
          return of({});
        }),
      );
  }

  changeValCompetition(competition?: ClassCompetition): Observable<ClassCompetition | undefined> {
    if (!competition) {
      this.reset(); // !!! если нет компетишна то и массивы для выпадающих списков тоже надо удалить
      return of(undefined);
    }
    this.competition$.next(competition);
    return this.getCompetition(competition);
  }

  // !!! game?: ClassGame нужен потому что при получении нового списка для дропдаунов в этих списках может не быть текущего выбраного (напримерр нет выбраного левела в новом списке который получили по id выбраного компетишна)
  // !!! тоесть при открытии edit game в игре может быть сохранен например левел, которого нет в новом списке левелов который получили по id выбраного компетишна
  // !!! тоесть если game передал то текущий сохраненый в игре например левел добавлять в массив левелов полученых по id текущего компетишна
  getCompetition(competition?: ClassCompetition, game?: ClassGame): Observable<ClassCompetition | undefined> {
    if (!competition?.id) {
      this.reset(); // !!! если нет компетишна то и массивы для выпадающих списков тоже надо удалить
      return of(undefined);
    }
    return this.apiCompetitionS.getCompetition(competition?.id)
      .pipe(
        tap((res?: ClassCompetition) => {
          this.competition$.next(res || null);
          // !!! andrei переделать потом
          const ageGroups = this.competition$.getValue()?.ageGroups || [];
          const existCurrentItem = ageGroups.find(el => el.id === game?.ageGroup?.id);
          const newArr = game?.ageGroup && !existCurrentItem ? [game.ageGroup, ...ageGroups] : ageGroups;
          this.ageGroups$.next(newArr);

          const levels = this.competition$.getValue()?.levels || [];
          const existCurrentItem2 = levels.find(el => el.id === game?.levels?.id);
          const newArr2 = game?.levels && !existCurrentItem2 ? [game.levels, ...levels] : levels;
          this.levels$.next(newArr2);

          const locations = this.competition$.getValue()?.locations || [];
          const existCurrentItem3 = locations.find(el => el.id === game?.location?.id);
          const newArr3 = game?.location && !existCurrentItem3 ? [game.location, ...locations] : locations;
          this.locations$.next(newArr3);

          const teams = this.competition$.getValue()?.teams || [];
          const existCurrentItem4 = teams.find(el => el.id === game?.homeTeam?.id);
          const existCurrentItem5 = teams.find(el => el.id === game?.awayTeam?.id);
          const newArr4 = (game?.homeTeam || game?.awayTeam) && (!existCurrentItem4 || !existCurrentItem5) ? [game.homeTeam || {}, game.awayTeam || {}, ...teams] : teams;
          this.teams$.next(newArr4);
        }),
      );
  }

  checkPreviewGame(game: ClassGame): void {
    const loc = game?.location?.address;
    const street = loc?.street ? loc?.street + ', ' : '';
    const city = loc?.city ? loc?.city + ', ' : '';
    const state = loc?.state ? loc?.state + ', ' : '';
    const zipcode = loc?.zipcode ? loc?.zipcode + ', ' : '';
    const timezoneId = loc?.timezone?.abbrev ? loc?.timezone?.abbrev + ', ' : '';
    let locStr = `${street} ${city} ${state} ${zipcode} ${timezoneId}`?.trim();
    locStr = locStr?.substring(0, locStr.length - 1);
    // !!! const date = this.customDatesS.formatDate('YYYY-MM-DDTHH:mm:ss.SSS', game?.date)

    // !!! если null то показывать TBD. // если ALL то N/A.
    // !!! age + Gender (first letter)
    const gender: string = this.otherS.getFirstLetter(game?.gender);
    const age: string = game?.ageGroup?.gameAgeDescription + ' - ' + gender;
    const level: string = this.otherS.getLevelValue(game?.levels?.level, 2);
    const duration: string = game?.duration ? game?.duration + ' min.' : '-';

    const gameStatusValue = game?.gameStatus === 'CANCELLED' ? 'Canceled' : game?.gameStatus;

    const arrPreviewGame: Array<IPreviewGame> = [
      { title: 'Competition', value: game?.competition?.competitionName }, // this.otherS.toTitleCase(game?.competition?.competitionName!)
      { title: 'Game #', value: game?.gameNumber || 'Auto generating' },
      { title: 'Date', value: game?.date! },
      { title: 'Time', value: game?.date! },
      { title: 'Status', value: this.otherS.toTitleCase(gameStatusValue!) || '' },
      { title: 'Age Groups', value: age },
      { title: 'Level', value: level },
      { title: 'Duration', value: duration },
      { title: 'Home Team', value: game?.homeTeam?.teamName ? game?.homeTeam?.teamName : 'TBD' },
      { title: 'Away Team', value: game?.awayTeam?.teamName ? game?.awayTeam?.teamName : 'TBD' },
      {
        title: 'Location',
        location: game?.location?.address,
        locationName: game?.location?.name,
        courtName: game?.location?.currentCourtName,
      },
    ];

    const arrPreviewGame_mobile: Array<IPreviewGame> = [
      { title: 'Date & Time', value: game?.date! },
      { title: 'Status', value: this.otherS.toTitleCase(gameStatusValue!) || '' },
      { title: 'Age Groups', value: age },
      { title: 'Level', value: level },
      { title: 'Duration', value: duration },
      { title: 'Home Team', value: game?.homeTeam?.teamName ? game?.homeTeam?.teamName : 'TBD' },
      { title: 'Away Team', value: game?.awayTeam?.teamName ? game?.awayTeam?.teamName : 'TBD' },
      {
        title: 'Location',
        location: game?.location?.address,
        locationName: game?.location?.name,
        courtName: game?.location?.currentCourtName,
      },
    ];

    const arrPreviewGameCreate_mobile: Array<IPreviewGame> = [
      { title: 'Date & Time', value: game?.date! },
      { title: 'Status', value: this.otherS.toTitleCase(gameStatusValue!) || '' },
      { title: 'Level', value: level },
      { title: 'Competition', value: game?.competition?.competitionName },
      { title: 'Duration', value: duration },
      { title: 'Home Team', value: game?.homeTeam?.teamName ? game?.homeTeam?.teamName : 'TBD' },
      { title: 'Away Team', value: game?.awayTeam?.teamName ? game?.awayTeam?.teamName : 'TBD' },
      { title: 'Location', location: game?.location?.address },
    ];

    this.arrPreviewGame$.next(arrPreviewGame);
    this.arrPreviewGame_mobile$.next(arrPreviewGame_mobile);
    this.arrPreviewGameCreate_mobile$.next(arrPreviewGameCreate_mobile);
  }

  // !!! если isClone то при клонировании id игры НЕ отправлять и запрос должен быть POST - для создания игры
  goToEditGame(game: ClassGame, isClone = false): Promise<ClassGame | undefined> {
    return this.popupS.open(EditGameComponent, { game, isClone, width: '640' });
  }

  // deleteGameFromServer(arrGames: Array<ClassGame>): Promise<Array<string> | undefined> {
  //   return this.mainS.deleteGames(arrGames)?.toPromise();
  // }

  // === OTHER ===========================================
  // This part is dublicated from announcement service, later need to move in to common service
  createDraftAnnouncement(newAnnouncement: AnnouncementItem, gameIds: string[]): Observable<any> {
    const url = `${this.apiAnnouncement}`;
    newAnnouncement.gameIds = gameIds;
    const formData = this.prepareAnnouncementData(newAnnouncement);
    return this.post(url, formData);
  }

  sendDraftAnnouncement(newAnnouncement: AnnouncementItem, announcementId: string): Observable<any> {
    const url = `${this.apiAnnouncement}/draft/${announcementId}`;
    const formData = this.prepareAnnouncementData(newAnnouncement);
    return this.post(url, formData);
  }

  private prepareAnnouncementData(newAnnouncement: AnnouncementItem): FormData {
    if (newAnnouncement.text) {
      newAnnouncement.text = newAnnouncement.text.replace(/\n/g, '<br>');
    }
    const formData = this.createFormData(newAnnouncement);
    this.appendFilesToFormData(newAnnouncement.files, formData);
    return formData;
  }

  private createFormData(newAnnouncement: AnnouncementItem): FormData {
    const formData = new FormData();
    const jsonBlob = new Blob([JSON.stringify(newAnnouncement)], { type: 'application/json' });
    formData.append('json', jsonBlob);
    return formData;
  }

  private appendFilesToFormData(files: File[] | undefined, formData: FormData): void {
    files?.forEach((file: File) => formData.append('file', file, file.name));
  }

  // andrei delete after finish dynamic filters
  // !!! для страниц myGames, games, assign. При переключении вкладок (current/past) нужно сортировку по дате отправлять на сервер
  // !!! вызывать этот метод нужно при переключении вкладок
  getSortByDateForSettingRequestByDefault(): 'date,Asc' | 'date,Desc' {
    return this.is_pastLink ? 'date,Desc' : 'date,Asc';
  }
}

