import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltersService } from '@components/filters/filters.service';

@Component({
  selector: 'tooltipSearch',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tooltip-search.component.html',
  styleUrls: ['./tooltip-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipSearchComponent {
  @Output() emit = new EventEmitter();

  constructor(
    public filtersS: FiltersService,
    private cd: ChangeDetectorRef,
  ) {
  }

}
