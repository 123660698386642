import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { HelperClass } from '@classes/Helper-Classes';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { CompetitionService } from '@app/dir_group_assignor/competitions/competition.service';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';
import { IStartEndDate, StartEndDateComponent } from '@components/date-time/start-end-date/start-end-date.component';
import { BtnAddAnotherComponent } from '@components/btn-add-another/btn-add-another.component';
import {
  CompetitionsNavigationComponent,
} from '@app/dir_group_assignor/competitions/helperComponentsCompetitions/competitions-navigation/competitions-navigation.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MainService } from '@services/main.service';
import { ActivatedRoute } from '@angular/router';
import { THttpMethod } from '@services/base-api';
import { ClassCompetition, ClassCompetitionPayScale, ClassCompetitionSeason } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { SelectDateComponent } from '@components/date-time/select-date/select-date.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import {
  DeleteItemForCompetitionsComponent,
} from '@app/dir_group_assignor/competitions/helperComponentsCompetitions/delete-item-for-competitions/delete-item-for-competitions.component';
import { CheckActiveService } from '@app/dir_group_assignor/competitions/services/checkActiveService';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';

interface IFormCompetitionsSeasons {
  arrControls?: FormArray<FormGroup<IFormItemCompetitionsSeasons>>;
}

interface IFormItemCompetitionsSeasons {
  id: FormControl<string>;
  competitionId: FormControl<string>;
  name: FormControl<string>;
  startDate: FormControl<string>;
  endDate: FormControl<string>;
}

@UntilDestroy()
@Component({
  selector: 'competitions-seasons',
  standalone: true,
  imports: [CommonModule, FieldComponent, ReactiveFormsModule, InputCtrlComponent, SvgAndTextComponent, BtnWrapComponent, BtnComponent, StartEndDateComponent, BtnAddAnotherComponent, CompetitionsNavigationComponent, MatProgressSpinnerModule, SelectDateComponent, SvgComponent, DeleteItemForCompetitionsComponent],
  templateUrl: './competitions-seasons.component.html',
  styleUrls: ['./competitions-seasons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetitionsSeasonsComponent extends HelperClass implements OnInit {
  form!: FormGroup<IFormCompetitionsSeasons>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public competitionS: CompetitionService,
    private mainS: MainService,
    private apiCompetitionS: ApiCompetitionService,
    private route: ActivatedRoute,
    public cd: ChangeDetectorRef,
    private readonly checkActiveGames: CheckActiveService,
  ) {
    super(cd);
    // this.dateTimeS.reset();
    // this.route.params.pipe(untilDestroyed(this)).subscribe((params) => this.competitionS.checkParams(params));
    this.createForm();
  }

  ngOnInit() {
    // this.subscribeToCompetition();
  }

  // === DATE ===============
  valueChangeDate(item: FormGroup<IFormItemCompetitionsSeasons>, startEndDate: IStartEndDate): void {
    // console.log('valueChangeDate :', item?.value?.startDate, startEndDate?.startDate)
    item.controls.startDate.patchValue(startEndDate.startDate || '');
    item.controls.endDate.patchValue(startEndDate.endDate || '');
    this.cd.detectChanges();
  }

  // === FORM ==============
  private createForm(): void {
    this.form = this.formBuilder.group({ arrControls: this.formBuilder.array([]) });
    this.competitionS.seasons?.forEach((el) => {
      // console.log('el :', el)
      this.arrControls.push(this.formBuilder.group({
        ...el,
        competitionId: this.competitionS.competition?.id || '',
      }));
    });
    // this.competitionS.seasons?.forEach((season) => {
    //   this.arrControls.push(this.formBuilder.group({ ...season }));
    // });

    // !!! from setForm()
    this.competitionS.seasons?.forEach((el, idxArrControls) => {
      this.arrControls.clear();
      this.competitionS.competition?.seasons?.forEach((item) => this.addNew(item));
    });
    setTimeout(() => this.cd.detectChanges());
  }

  get arrControls(): FormArray<FormGroup<IFormItemCompetitionsSeasons>> {
    return this.form?.controls?.arrControls!;
  }

  addNew(item?: ClassCompetitionSeason): void {
    const newFormGroup: FormGroup<IFormItemCompetitionsSeasons> = this.formBuilder.group({
      id: [item?.id || ''],
      competitionId: [item?.competitionId || ''],
      name: [item?.name || '', Validators.required],
      startDate: [item?.startDate || '', Validators.required],
      endDate: [item?.endDate || '', Validators.required],
    });
    this.arrControls.push(newFormGroup);
  }

  // === competitions Btns Emit =========================
  async competitionsBtnsEmit(isNext: boolean): Promise<void> {
    const competitionId = this.competitionS?.competition?.id;
    const isActiveModal = await this.checkActiveGames.checkActive(competitionId, true);
    if (isActiveModal) {
      if (!competitionId) this.methodCompetitionSeasons(isNext, 'post'); // create
      if (competitionId) this.methodCompetitionSeasons(isNext, 'put'); // update
    }
  }

  methodCompetitionSeasons(isNext = false, httpMethod: THttpMethod): void {
    if (this.startRequest()) return;
    const sendObj: { id: string, seasons: Array<ClassCompetitionPayScale> } = {
      id: this.competitionS.competition?.id!,
      seasons: this.form?.getRawValue().arrControls as Array<ClassCompetitionPayScale>,
    };
    this.competitionS.addCompetitionIdBeforeSendToServer(sendObj.seasons);

    // this.apiCompetitionS.methodCompetitionSeasons(sendObj, this.competitionS.competition?.id!, httpMethod).toPromise()
    this.apiCompetitionS.methodCompetitionSeasons(sendObj,  httpMethod).toPromise()
      .then((res?: Pick<ClassCompetition, 'seasons'>) => {
        if (!res) return;
        this.competitionS.competition.seasons = res.seasons;
        isNext ? this.competitionS.nextStep('seasons') : this.competitionS.goToDashboard();
      })
      .catch((err) => {
      })
      .finally(() => this.endRequest());
  }

}

// subscribeToCompetition(): void {
//   this.competitionS.competition$.pipe(untilDestroyed(this)).subscribe((competition?: ClassCompetition) => {
//     if (!competition) return;
//     if (this.competitionS.seasons?.length) this.setForm('111');
//     this.competitionS.getCompetitionSeasons().toPromise().then((res?: Array<ClassCompetitionSeason>) => this.setForm('222'));
//   });
// }
// setForm(str: string): void {
//   this.competitionS.seasons?.forEach((el, idxArrControls) => {
//     this.arrControls.clear();
//     this.competitionS.competition?.seasons?.forEach((item) => this.addNew(item));
//   });
//   setTimeout(() => this.cd.detectChanges());
// }
