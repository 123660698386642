<div class='wrapperPhotos'
  [style]='{justifyContent: position,
               height: +widthPhoto+10+"px",
               maxHeight: +widthPhoto+10+"px",
               minHeight: +widthPhoto+10+"px",
              }'>
  <div *ngFor='let item of photos; let idx=index; let last=last'
    class='wrapperPhotos__photo'
    [style]='{width: widthPhoto+"px",
              maxWidth: widthPhoto+"px",
              minWidth: widthPhoto+"px",
              height: widthPhoto+"px",
              maxHeight: widthPhoto+"px",
              minHeight: widthPhoto+"px",
              zIndex: idx + 1,
              left: idx*27 + "px"
            }'
    [matTooltip]='item.matTooltip||""'>
    <div class='wrapperPhotos__photo__wrap'>
      <div class='wrapperPhotos__photo__wrap__img'>
        <img *ngIf='item.photo && !item.textForPlus'
          [src]='item.photo'
          [alt]='item.name'
          class='logoPhotos'>
        <div *ngIf='!item.photo && item.name && !item.textForPlus &&  item.status !== "UNASSIGNED"'
          class='logoPhotos'>
          {{item.name|getFirstLetter:2}}
        </div>
        <div *ngIf='!item.name && item.status === "UNASSIGNED"'
          class='logoPhotos unassigned'>
          <img [src]='unAssignedUserUrl'
            [alt]='item.name'>
        </div>
        <div *ngIf='item.textForPlus'
          class='logoPhotos'>
          {{item.textForPlus}}
        </div>
      </div>

    </div>
    <meSvg *ngIf='showSmallCircle && item.svgIcon && !item.textForPlus'
      [svgName]='item.svgIcon'
      [class]='item.positionSvgIcon'
      w='16'>
    </meSvg>
  </div>
</div>