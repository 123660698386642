<!-- <div class='test'>
  <div>current step == {{ step }}</div>
  <div (click)='step=step+1'>step + 1</div>
  <div (click)='step=step-1'>step - 1</div>
  <div (click)='step=7'>step = 7</div>
  <div (click)='step=10'>step = 10</div>

</div> -->

<form [formGroup]='form' autocomplete='off' novalidate class='wrapperSignup o-wrapper'>
  <img *ngIf='this.step!=UserRegisteredStatus.INVITATION_EXPIRED' src='assets/bcgLogo/bcgLogo_textNotchGreen.svg' alt='notch' class='authLogo'>

  <ng-container *ngIf='isShowStep([UserRegisteredStatus.INVITATION_EXPIRED])'>
    <div style="color: #07254A">
      <img src='assets/official-signup-token-expired-img.svg' style="    height: 200px;    margin-left: auto;    margin-right: auto;">
      <h1 style="font-family: Gilroy;font-size: 24px;font-weight: 600;line-height: 32px;text-align: center;margin-top: 19px;">The invite link is invalid or has expired!</h1>
      <p style="font-family: Gilroy;font-size: 16px;font-weight: 400;line-height: 24px;text-align: center;margin-left: 21px;margin-right: 20px;margin-top: 5px;">
        You'll need to try using a different link to join Notch. Please reach out to your assignor who can send you a new invite link.
      </p>
      <btn text='Got It' [bcg]='authS.bcgApplyBtn' [spinner]='reqPending' (click)='this.routesS.navigate(["/login"]);' class='o-mg-t-24'></btn>
    </div>
  </ng-container>

  <emailPassStep *ngIf='isShowStep([UserRegisteredStatus.PENDING])' (emit)='emailPassStep($event)'></emailPassStep>

  <codeConfirmStep *ngIf='isShowStep([UserRegisteredStatus.EMAIL_VERIFIED,UserRegisteredStatus.MOBILE_VERIFIED])'
                   [type]='step == UserRegisteredStatus.EMAIL_VERIFIED ? "email":"phone"'
                   [value]='step == UserRegisteredStatus.EMAIL_VERIFIED ? meS?.me?.email : meS?.me?.phone'
                   (nextStepEvent)='nextStepEvent()'
                   (backStepEvent)='backStepEvent()'>
  </codeConfirmStep>

  <!--  step == 3 First Name   Second Name  Preferred Name -->
  <!--  <nameStep *ngIf='step == 3' (emit)='changeValNameStep($event)'></nameStep>-->
  <!-- <nameStep *ngIf='step|checkStepSignupOfficial:"userName"' (emit)='changeValNameStep($event)'></nameStep> -->
  <nameStep *ngIf='isShowStep([UserRegisteredStatus.NAME_ENTERED])'
            (nextStepEvent)='changeValNameStep($event)' [user]="this.meS?.me"></nameStep>

  <!--  step == 4 location-->
  <!--  andrei=== !! pipe после нажатия на кнопку спиннер и не переходит к след шагу. Миша закрыл девтулс и перешел к вводу телефна-->
  <!--  <locationStep *ngIf=' step==4' (emit)='step=step+1' [user]='{firstName:ctrl.firstName?.value, secondName:ctrl.secondName?.value, preferredName:ctrl.preferredName?.value}'>
    </locationStep>-->
  <!-- <locationStep *ngIf='step|checkStepSignupOfficial:"location"' (emit)='step=step+1'></locationStep> -->
  <locationStep *ngIf='isShowStep([UserRegisteredStatus.RESIDENTIAL_ADDRESS_ENTERED])'
                (nextStepEvent)='nextStepEvent()' (backStepEvent)='backStepEvent()'></locationStep>

  <!--  step == 5 phone-->
  <!--  <phoneStep *ngIf='step == 5' (emit)='emit($event, "phoneStep")'></phoneStep>-->
  <!-- for test [existChxTestPhone]='true'-->
  <!-- <phoneStep *ngIf='step|checkStepSignupOfficial:"phone"' (emit)='emit($event)'></phoneStep> -->
  <phoneStep *ngIf='isShowStep([UserRegisteredStatus.MOBILE_ENTERED])'
             (nextStepEvent)='phoneStepEvent($event)' (backStepEvent)='backStepEvent()'></phoneStep>

  <!--  step == 7 dateOfBirth socialSecurityNumber -->
  <!--  <ng-container *ngIf='step == 8'>-->
  <!-- <ng-container *ngIf='step|checkStepSignupOfficial:"dateOfBirth"'> -->
  <ng-container *ngIf='isShowStep([UserRegisteredStatus.DATE_OF_BIRTH])'>
    <div class='authText'>
      <div>We need this information so you can get paid. Your personal information is secure and encrypted.</div>
    </div>
    <field text='Date of Birth'>
      <selectDate placeholder='MM / DD / YYYY' (emit)='ctrl.dateOfBirth.patchValue($event)'></selectDate>
    </field>
    <field text='Social Security Number'>
      <inputCtrl formControlName='socialSecurity' placeholder='Enter Last 4 Digits' [isNumber]='true' [maxLength]='4'
                 forTest='socialSecurity' [allowFirstZero]='true'></inputCtrl>
    </field>
    <chx formControlName='chxStep_8' text='I`ll do it later'></chx>
    <btn text='Next' [bcg]='authS.bcgApplyBtn' [spinner]='reqPending' (click)='saveOfficial(saveUser());nextStepEvent();' class='o-mg-t-24'
         [disabled]='form|invalidControls:["dateOfBirth" ,"socialSecurityNumber"]'></btn>
  </ng-container>

  <!--  step == 8 emergencyName emergencyRelationship emergencyPhone -->
  <!--  <ng-container *ngIf='step == 7'>-->
  <!-- <ng-container *ngIf='step|checkStepSignupOfficial:"emergencyContact"'> -->
  <ng-container *ngIf='isShowStep([UserRegisteredStatus.EMERGENCY_CONTACT])'>
    <div class='authText'>
      <div>Enter an emergency contact. This information will be added to your profile.</div>
    </div>
    <field text='Name'>
      <inputCtrl formControlName='emergencyName' placeholder='Name' [isFocus]='true'></inputCtrl>
    </field>
    <field text='Relationship'>
      <app-dropdown formControlName='emergencyRelationshipDrop' [array]='arrEmergencyRelationshipDrop' placeholder='Relationship'
                    [addNewItem]='true'></app-dropdown>
    </field>
    <!-- for test [existChxTestPhone]='true'-->
    <phone #phoneComponent (changeVal)='ctrl.emergencyPhoneNumber?.patchValue($event)'></phone>

    <field *ngIf='ctrl.dateOfBirth.value|isLessAge18' text='Email*' class='wrapperSignup__secondaryEmail'>
      <inputCtrl formControlName='secondaryEmail' placeholder='Email' [showErrText]='true'></inputCtrl>
      <div class='wrapperSignup__secondaryEmail__text'>
        A secondary email will be used to ensure additional party will receive platform notifications and communication sent by your
        assignor
      </div>
    </field>

    <btn text='Next' [bcg]='authS.bcgApplyBtn' [spinner]='reqPending' (click)='saveOfficial(saveUserStatus());nextStepEvent();' class='o-mg-t-24'
         [disabled]='(form|invalidControls:["emergencyName", "emergencyRelationship","secondaryEmail"]) || phoneComponent.invalid'></btn>
  </ng-container>

  <!--  step == 9 startOfficiating certified -->
  <!--  <ng-container *ngIf='step == 9'>-->
  <!-- <ng-container *ngIf='step|checkStepSignupOfficial:"startOfficiating"'> -->
  <ng-container *ngIf='isShowStep([UserRegisteredStatus.START_OFFICIATING])'>
    <div class='authText'>
      <div>Enter more details on your experience as an official.</div>
    </div>
    <!-- <field text='What year did you start officiating?'>
      <inputCtrl formControlName='startOfficiating' placeholder='Select Year' [isNumber]='true' [maxLength]='4'></inputCtrl>
    </field> -->

    <field text='Have you obtained a formal certification from a sport’s governing body or organized authority?'>
      <app-dropdown formControlName='certifiedDrop' [array]='arrYesNoDrop'></app-dropdown>
    </field>

    <div class='authText o-mg-t-8-important'>
      <div style='text-align: left !important;'>The details you enter here will help your assignor verify your certification status.
      </div>
    </div>

    <field *ngIf='ctrl.certifiedDrop?.value?.valueBoolean' text='Under which governing body did you obtain a certification?'>
      <app-dropdown formControlName='certificationNameDrop' [array]='arrSignupCertDropDown' placeholder='Please Select'></app-dropdown>
    </field>
    <field *ngIf="ctrl.certifiedDrop?.value?.valueBoolean && ctrl.certificationNameDrop.value?.upperCase === 'UNITED STATES SOCCER FEDERATION (USSF)'">
      <div class='tooltip-container'>
        <span class='field-text'>Enter USSF ID</span>
        <div class='tooltip-icon'>
          <img src='assets/vector-phone.svg' style='height: 16px; width: 16px;' />
          <div class='hover-box'>
            Search for USSF ID number <a href='https://learning.ussoccer.com/directory' target='_blank'
               style='text-decoration: underline;'>here</a>
          </div>
        </div>
      </div>

      <div class='input-container'>
        <p class='error-message'>{{ certificateRegistrationErrorMessage }}</p>
        <inputCtrl formControlName='CertificateRegistration' placeholder='Enter I.D. Registration'
                   (input)='removeHyphens($event)'
                   [ngClass]="{'invalid': isNextButtonDisabled() && (form.get('CertificateRegistration')?.dirty || form.get('CertificateRegistration')?.touched),
                      'valid': !isNextButtonDisabled() && form.get('CertificateRegistration')?.dirty && !isCertificateRegistrationEmpty()}" />
        <div class='checkmark-container' *ngIf='!certificateRegistrationErrorMessage'>
          <img src='assets/green-checkmark.svg'
               *ngIf="!isNextButtonDisabled() && form.get('CertificateRegistration')?.dirty && !isCertificateRegistrationEmpty()"
               class='checkmark' />
          <img src='assets/red-cross.svg'
               *ngIf="isNextButtonDisabled() && (form.get('CertificateRegistration')?.dirty || form.get('CertificateRegistration')?.touched)"
               class='checkmark' />
        </div>
      </div>
      <div class='error-message'
           *ngIf="isNextButtonDisabled() && (form.get('CertificateRegistration')?.dirty || form.get('CertificateRegistration')?.touched)">
        USSF ID must contain 16 digits
      </div>
      <div class='valid-message'
           *ngIf="!isNextButtonDisabled() && form.get('CertificateRegistration')?.dirty && !isCertificateRegistrationEmpty()">
        USSF ID successfully entered!
      </div>
    </field>
    <btn text='Next' [bcg]='authS.bcgApplyBtn' [spinner]='reqPending' (click)='nextStep_for_startOfficiating()' class='o-mg-t-24'
         [disabled]='isNextButtonDisabled()'></btn>
  </ng-container>

  <!--    step == 10 acceptTerms Complete Registration-->
  <!--  <ng-container *ngIf='step == 10'>-->
  <!-- <ng-container *ngIf='step|checkStepSignupOfficial:"lastStep"'> -->
  <ng-container *ngIf='isShowStep([UserRegisteredStatus.REGISTERED])'>
    <div class='o-text4-blueDark o-flexCenter o-mg-t-35'>Congrats!
      <img src='assets/congrats_2.svg' alt='' class='o-mg-l-8'>
    </div>
    <div class='o-text6-blueDark o-mg-t-12 o-text-center'>
      You have been successfully added to the <span class='o-text5-blueDark o-text-center'>{{ groupName }}</span> group!
    </div>
    <btn text='Complete Registration' [bcg]='authS.bcgApplyBtn' [spinner]='reqPending' (click)='signUpOfficial()' class='o-mg-t-24'></btn>
  </ng-container>

</form>