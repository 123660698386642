import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { BtnComponent } from '@components/btn/btn.component';
import {  IResponse } from '@models/response-and-request';
import { BehaviorSubject } from 'rxjs';
import { HelperClass } from '@classes/Helper-Classes';
import { StatusComponent } from '@components/status/status.component';
import { DeviceService } from '@services/device.service';
import { ClassCeilTableHeader, IForClassForTable } from '@components/_table/meTable';
import { MeTableService } from '@components/_table/me-table.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FiltersService } from '@components/filters/filters.service';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { GlobasConst } from '@shared/constants/global-const';
import { Router } from '@angular/router';
import { UserGameTransferInfoDto } from '@app/dir_group_assignor/payments/models/user-game-transfer-info-dto';
import { ClassFilterDateRange, ClassFilterDrop, IForClassForFilters } from '@components/filters/filters';
import { urlPayments_allTransactions, urlPayments_paymentDetails } from '@app/url-const';
import { TransferType } from '@app/dir_group_assignor/payments/models/transfer-type';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';
import { MainService } from '@services/main.service';
import { MeService } from '@services/me.service';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { CurrencyCentsPipe } from '@app/dir_group_assignor/payments/pipes/currency-cents.pipe';

@UntilDestroy()
@Component({
  selector: 'myWalletTableOfficial',
  standalone: true,
  imports: [CommonModule, SvgComponent, BtnComponent, StatusComponent, CurrencyCentsPipe],
  templateUrl: './my-wallet-table-official.component.html',
  styleUrls: ['./my-wallet-table-official.component.scss'],
  providers: [FiltersService, MeTableService, SettingsRequestService], 
  changeDetection: ChangeDetectionStrategy.OnPush,
})


export class MyWalletTableOfficialComponent extends HelperClass implements IForClassForFilters, IForClassForTable<UserGameTransferInfoDto> {
  dataTable$ = new BehaviorSubject<IResponse<UserGameTransferInfoDto>>({});
  arrContent$ = new BehaviorSubject<Array<UserGameTransferInfoDto>>([]);
  readonly widthPage = 1071;

  _urlPayments_allTransactions = urlPayments_allTransactions;
  transactionType: TransferType = "STRIPE";

  constructor(
    public globalConst: GlobasConst,
    private apiCompetitionS: ApiCompetitionService,
    private mainS: MainService,
    public meS: MeService,
    public gameS: GameService,
    public router: Router,
    public deviceS: DeviceService,
    public filtersS: FiltersService,
    public meTableS: MeTableService<UserGameTransferInfoDto>,
    public settingsRequestS: SettingsRequestService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.loadAssignorFeesTable();
  }

  ngOnInit() {
  }


  viewAllClicked(): void {
    this.router.navigate(['/myWalletOfficial/all'], {
    });
  }


  setFilters(): void {
    const dateRange = new ClassFilterDateRange({ arrPeriods: ClassFilterDateRange.templatePreviosData });
    const competition = new ClassFilterDrop({ typeFilter: 'competitions' });

    this.apiCompetitionS.getArrCompetition().toPromise()
      .then((res) => {
        const arrCompetitions = res?.content || [];
        const competitions_filterDrop = new ClassFilterDrop({
          typeFilter: 'competitions',
          arrayForDropdown: arrCompetitions || [],
          valueDrop: this.gameS.arrCompetition$.getValue()?.find(el => el.id === this.settingsRequestS.settings.competitions),
          disabled: false,
        });
        this.filtersS.updateAllFilters([competitions_filterDrop]);
      })
      .catch(err => {
      });

    this.filtersS.setFilters([dateRange, competition], true, true);
  }


  setDataForTable(): void {
    this.meTableS.dataTable$ = this.dataTable$;
    this.meTableS.arrContent$ = this.arrContent$;

    const arrayCeilHeader: Array<ClassCeilTableHeader> = [
      new ClassCeilTableHeader({ text: 'Game' }),
      new ClassCeilTableHeader({ text: 'Competition' }),
      new ClassCeilTableHeader({ text: 'Transaction Date' }),
      new ClassCeilTableHeader({ text: 'Amount' }),
      new ClassCeilTableHeader({ text: 'Status' }),
      new ClassCeilTableHeader({ text: '' }),
    ];

    const arrWidthCeilTable: Array<number> = [120, 371, 194, 120, 115, 135, this.meTableS.minWidthFor_sortByCeil];
    this.meTableS.setArrTypeSorting(['byGameNumber']);
    this.meTableS.setDataForTable(arrWidthCeilTable, arrayCeilHeader, this.widthPage, true);
  }

  viewDetailsClicked(gameId: string, isButton: boolean) {
    if (this.deviceS.isMobile || isButton)
      this.router.navigate(['myWalletOfficial/info'], { queryParams: { gameId: gameId } });
  }

  loadAssignorFeesTable() {
    this.mainS.getAssignorFeesListV2({
      params: {
        page: 1,
        size: 5,
      }
    }).subscribe(x => {
      const processedContent = (x.content || []).map((item: any) => ({
        ...item,
        transferAmount: item.transferTotal?.amount ?? 0
      }));
      this.arrContent$.next(processedContent);
    });
  }
}

