import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDataPopup } from '@services/popup.service';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { CodeConfirmComponent } from '@components/code-confirm/code-confirm.component';
import { BtnComponent } from '@components/btn/btn.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { HelperClass } from '@classes/Helper-Classes';
import { MainService } from '@services/main.service';
import { MeService } from '@services/me.service';

@Component({
  selector: 'app-popup-code-confirm',
  standalone: true,
  imports: [CommonModule, HeadTitleComponent, CodeConfirmComponent, BtnComponent, BtnWrapComponent],
  templateUrl: './popup-code-confirm.component.html',
  styleUrls: ['./popup-code-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupCodeConfirmComponent extends HelperClass {
  type!: 'email' | 'phone';
  email?: string;
  phone?: string;
  text_headTitle = '';

  validationCode = '';

  constructor(
    private mainS: MainService,
    private meS: MeService,
    public dialogRef: MatDialogRef<PopupCodeConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public dataPopup: IDataPopup,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    if (dataPopup.email) {
      this.type = 'email';
      this.email = dataPopup.email;
      this.text_headTitle = 'Please check your email.';
    }
    if (dataPopup.phone) {
      this.type = 'phone';
      this.phone = dataPopup.phone;
      this.text_headTitle = 'Please check your phone number.';
    }
  }

  verifyCode(): void {
    if (!this.validationCode) return;
    if (this.startRequest()) return;
    this.mainS.verifyCode({ resource: (this.email || this.phone)!, code: this.validationCode }, this.type).toPromise()
      .then((res) => {
        if (this.type == 'email') this.meS.setMeUser({ email: this.email });
        if (this.type == 'phone') this.meS.setMeUser({ phone: this.phone });
      })
      .catch((err) => {
      })
      .finally(() => {
        this.close(true);
        this.endRequest();
      });
  }

  resendCode(): void {
    if (this.startRequest()) return;
    this.mainS.resendCode((this.email || this.phone)!, this.type).toPromise()
      .then((res) => {
      })
      .catch((err) => {
      })
      .finally(() => this.endRequest());
  }

  close(type: boolean): void {
    this.dialogRef.close(type);
  }
}
