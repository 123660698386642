import { Pipe, PipeTransform } from '@angular/core';
import { getUserRoleTitleCase, TUserRoleLowercase, TUserRoleTitleCase, TUserRoleUpperCase } from '@models/user';

@Pipe({ name: 'userRoleToTitleCase', standalone: true })
export class UserRoleToTitleCasePipe implements PipeTransform {

  transform(userRole: TUserRoleUpperCase | TUserRoleLowercase | TUserRoleTitleCase): TUserRoleTitleCase {
    return getUserRoleTitleCase(userRole);
  }

}
