import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'getIdx', standalone: true })
export class GetIdxPipe implements PipeTransform {

  // возвращает индекс найденого элемента или -1 если не найдено
  // если передать массив объектов, тогда надо передать key по которому искать значение и сравнивать с value
  // transform<T>(array: Array<T>, value: string|number, key?: keyof T): number {
  transform(array: Array<any>, value: string | number, key?: string): number {
    if (!array?.length) return -1;
    let result: number = -1;
    if (key) { // передал массив объектов
      array.forEach((el, idx) => {
        if (el[key] === value) result = idx;
      });
    } else { // передал массив строк или чисел
      array.forEach((el, idx) => {
        if (el === value) result = idx;
      });
    }
    return result;
  }

}
