export class EmbeddableTabbedList {
    embeddableId: string = ""; 
    tabName?: string;
}

export const embeddableTabList: Array<EmbeddableTabbedList> = [
    {
        tabName: "Games",
        embeddableId: "a0e747c0-e60c-4c0d-8be8-0742e7c014f9"
    }, 
    {
        tabName: "Officials",
        embeddableId: "65b358a7-bd83-415b-b3a5-b3180109db7b"
    }, 
    // {
    //     tabName: "Financial",
    //     embeddableId: ""
    // }
]