export enum CREDIT_CARD_TYPE {
  AMEX = 'amex',
  DINERS_CLUB = 'diners',
  DISCOVER = 'discover',
  JCB = 'jcb',
  UNION_PAY = 'unionpay',
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  MAESTRO = 'maestro',
  UNKNOWN = 'unknown',
  BC_GLOBAL = 'bcg',
  CARTE_BLANCHE = 'carte_blanche',
  INSTA_PAYMENT = 'insta',
  KOREAN_LOCAL = 'koreanlocal',
  LASER = 'laser',
  SOLO = 'solo',
  SWITCH = 'switch',
}

export const CREDIT_CARD_TYPE_REGEXP: { [name: string]: RegExp } = {
  [CREDIT_CARD_TYPE.AMEX]: /^3[47][0-9]{13}$/,
  [CREDIT_CARD_TYPE.DINERS_CLUB]: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
  [CREDIT_CARD_TYPE.DISCOVER]: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
  [CREDIT_CARD_TYPE.JCB]: /^(?:2131|1800|35\d{3})\d{11}$/,
  [CREDIT_CARD_TYPE.MAESTRO]: /^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/,
  [CREDIT_CARD_TYPE.MASTERCARD]: /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
  [CREDIT_CARD_TYPE.UNION_PAY]: /^(62[0-9]{14,17})$/,
  [CREDIT_CARD_TYPE.VISA]: /^4[0-9]{12}(?:[0-9]{3})?$/,
  // [CREDIT_CARD_TYPE.INSTA_PAYMENT]: /^63[7-9][0-9]{13}$/,
  // [CREDIT_CARD_TYPE.KOREAN_LOCAL]: /^9[0-9]{15}$/,
  // [CREDIT_CARD_TYPE.LASER]: /^(6304|6706|6709|6771)[0-9]{12,15}$/,
  // [CREDIT_CARD_TYPE.BC_GLOBAL]: /^(6541|6556)[0-9]{12}$/,
  // [CREDIT_CARD_TYPE.CARTE_BLANCHE]: /^389[0-9]{11}$/,
  // [CREDIT_CARD_TYPE.SOLO]: /^(6334|6767)[0-9]{12}|(6334|6767)[0-9]{14}|(6334|6767)[0-9]{15}$/,
  // [CREDIT_CARD_TYPE.SWITCH]: /^(4903|4905|4911|4936|6333|6759)[0-9]{12}|(4903|4905|4911|4936|6333|6759)[0-9]{14}|(4903|4905|4911|4936|6333|6759)[0-9]{15}|564182[0-9]{10}|564182[0-9]{12}|564182[0-9]{13}|633110[0-9]{10}|633110[0-9]{12}|633110[0-9]{13}$/,
};
