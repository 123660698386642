import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'titlecaseNoUnderscore',
    standalone: true,
    pure: false
})
export class TitlecaseNoUnderscorePipe implements PipeTransform {
    transform(value: string, titleCaseAll: boolean = false): string {
        if (!value) return value;

        if (titleCaseAll) {
            // Remove underscores and convert to title case for all words
            return value.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
        } else {
            // Only title case the first word
            return value.replace(/_/g, ' ').toLowerCase().replace(/\b\w/, (char) => char.toUpperCase());
        }
    }
}