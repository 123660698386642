import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass } from '@classes/Helper-Classes';
import { MainService } from '@services/main.service';
import { OtherService } from '@services/other.service';
import { MeService } from '@services/me.service';
import { RouterLink } from '@angular/router';
import { Geo } from '@classes/geo';
import { IDataPopup, PopupService } from '@services/popup.service';
import { BtnComponent } from '@components/btn/btn.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { GetUserNamePipe } from '@pipes/get-name.pipe';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { MatMenuModule } from '@angular/material/menu';
import { PhotoComponent } from '@components/__svg_img/photo/photo.component';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { arrStateOfficialAttendanceString, ClassAttendance, ClassReport } from '@app/dir_group_assignor/reports/report';
import { ReportService } from '@app/dir_group_assignor/reports/report.service';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ClassGameOfficial } from '@app/dir_group_assignor/games/game';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { GetFirstLetterPipe } from '@pipes/get-first-letter.pipe';
import { StatusComponent } from '@components/status/status.component';
import { PopupAssignComponent } from '@app/dir_group_assignor/assign/components/popup-assign/popup-assign.component';
import { GetNoAssignGOPipe } from '@pipes/gameOfficial/get-no-assign-go.pipe';
import { ClassUser } from '@models/user';
import { ChxComponent } from '@components/chx/chx.component';
import { DeviceService } from '@services/device.service';
import { DateFormattedComponent } from '@components/__date/date-formatted/date-formatted.component';
import { GetLevelValuePipe } from '@pipes/get-level-value.pipe';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { arrYesNoDrop, ClassYesNo } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClassAdjustmentStatus } from '@app/group-profile/components/group-profile-adjustment-statuses/adjustmentStatus';
import { AdjustmentStatusesService } from '@app/group-profile/components/group-profile-adjustment-statuses/adjustment-statuses.service';
import { FormCustomService } from '@services/form-custom.service';
import { UtilsService } from '@services/utils.service';
import { from, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

interface IReportInfoForm {
  gameIsCompletedDrop: FormControl<ClassYesNo | undefined>; // only for gameDetails
  gameAdjustmentStatus: FormControl<ClassAdjustmentStatus | undefined>;
  team_1: FormControl<string>;
  team_2: FormControl<string>;
  homeTeamScore: FormControl<number>; // only for gameDetails
  awayTeamScore: FormControl<number>; // only for gameDetails
  ctrlTextarea: FormControl<string>;
  gameNotBegun: FormControl<boolean>; // only for gameDetails
}

@UntilDestroy()
@Component({
  selector: 'app-report-info',
  standalone: true,
  imports: [CommonModule, BtnComponent, BtnWrapComponent, GetUserNamePipe, HeadTitleComponent, MatMenuModule, PhotoComponent,
    SvgAndTextComponent, SvgComponent, FieldComponent, DropFormCtrlComponent, FormsModule, ReactiveFormsModule, InputCtrlComponent,
    RouterLink, GetFirstLetterPipe, StatusComponent, GetNoAssignGOPipe, ChxComponent, DateFormattedComponent, GetLevelValuePipe, DropdownComponent],
  templateUrl: './report-info.component.html',
  styleUrls: ['./report-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportInfoComponent extends HelperClass implements OnInit {
  form!: FormGroup<IReportInfoForm>;
  arrStateOfficialAttendanceString = arrStateOfficialAttendanceString; // andrei
  arrYesNoDrop = arrYesNoDrop;
  isDisabledForEdit = true;
  arrAdjustmentStatus: Array<ClassAdjustmentStatus> = [];
  showGameCompleteError: boolean = false;
  showAdjustmentStatusError:boolean = false;

  alreadyApprove = false;

  constructor(
    private formBuilder: FormBuilder, // UntypedFormBuilder || FormBuilder || NonNullableFormBuilder
    private mainS: MainService,
    public otherS: OtherService,
    public reportS: ReportService,
    public meS: MeService,
    public geo: Geo,
    private popupS: PopupService,
    public deviceS: DeviceService,
    private adjustmentStatusesS: AdjustmentStatusesService,
    private formS: FormCustomService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.createForm();
  }

  ngOnInit() {
    this.getAdjustmentStatuses();
  }

  changeVal(event: any, type: 'gameIsCompleted' | 'stateOfficialAttendance' | 'homeTeamScore' | 'awayTeamScore' | 'gameNotBegun', attendanceItem?: ClassAttendance): void {
    if (!event && typeof event != 'boolean') return;
    const report = this.reportS.report;

    if (type == 'stateOfficialAttendance') {
      if (attendanceItem?.stateOfficialAttendance == 'Present') {
        attendanceItem.participated = true;
        attendanceItem.switched = false;
        attendanceItem.switchIdentifier = undefined;
        attendanceItem.switchedReferee = undefined;
      }
      if (attendanceItem?.stateOfficialAttendance == 'Replace') {
        this.openPopupAssign(attendanceItem);
      }
      if (attendanceItem?.stateOfficialAttendance == 'Absent') {
        attendanceItem.participated = false;
        attendanceItem.switched = false;
        attendanceItem.switchIdentifier = undefined;
        attendanceItem.switchedReferee = undefined;
      }
    }

    if (type == 'homeTeamScore' || type == 'awayTeamScore') {
      if (!event) this.ctrl[type]?.patchValue(0);
    }

    if (type == 'gameNotBegun' && report?.status == 'DUE') {
      if (event) {
        this.ctrl.homeTeamScore.disable();
        this.ctrl.awayTeamScore.disable();

        if (this.meS.OFFICIAL) {
          this.ctrl.homeTeamScore.setValue(0);
          this.ctrl.awayTeamScore.setValue(0);
        }
      } else {
        this.ctrl.homeTeamScore.enable();
        this.ctrl.awayTeamScore.enable();
      }
    }

    this.cd.detectChanges();
  }

  changeGameIsCompleted(yesNoDrop: ClassYesNo): void {
    yesNoDrop.valueBoolean ? this.ctrl.gameAdjustmentStatus?.disable() : this.ctrl.gameAdjustmentStatus?.enable();
    this.cd.detectChanges();
  }

  // === FORM ===============================
  createForm(): void {
    const OFFICIAL = this.meS.OFFICIAL;
    const report = this.reportS.report;
    const game_CANCELED = report?.game?.gameStatus === 'CANCELLED';
    const DUE = report?.status === 'DUE';
    const FLAGGED = report?.status === 'FLAGGED';
    const APPROVED = report?.status === 'APPROVED';

    const gameIsCompletedDrop = report?.gameIsCompletedDrop;

    let disabledAdjustmentStatus: boolean = gameIsCompletedDrop?.valueBoolean || !gameIsCompletedDrop || APPROVED;
    if (report?.game?.gameAdjustmentStatus && !APPROVED) disabledAdjustmentStatus = false;

    const value_for_ctrlTextarea = game_CANCELED ? (report?.game?.gameAdjustmentStatus?.cancelReason || '') : (report?.additionalComments || '');

    this.checkDisabledForEdit();
    this.form = this.formBuilder.nonNullable.group({ // new FormGroup<IReportInfoForm> || this.formBuilder.nonNullable.group || this.formBuilder.group
      gameIsCompletedDrop: this.formS.getControl_nonNullable(gameIsCompletedDrop, APPROVED),
      gameAdjustmentStatus: this.formS.getControl_nonNullable(report?.game?.gameAdjustmentStatus, disabledAdjustmentStatus, FLAGGED), // было this.report?.adjustmentStatus, 16/05/24
      team_1: this.formS.getControl_nonNullable(report?.game?.homeTeam?.teamName || '', true),
      team_2: this.formS.getControl_nonNullable(report?.game?.awayTeam?.teamName || '', true),
      homeTeamScore: this.formS.getControl_nonNullable(report?.homeTeamScore || 0, APPROVED),
      awayTeamScore: this.formS.getControl_nonNullable(report?.awayTeamScore || 0, APPROVED),
      ctrlTextarea: this.formS.getControl_nonNullable(value_for_ctrlTextarea, APPROVED),
      gameNotBegun: this.formS.getControl_nonNullable(!!report?.gameNotBegun, this.isDisabledForEdit || APPROVED),
    });

    // === SET REQUIRED CONTROLS (в зависимости от статуса игры == CANCELED) ===================
    if (!game_CANCELED) { // !!! GAME DETAILS
      this.ctrl.gameIsCompletedDrop.addValidators(Validators.required);
      this.ctrl.homeTeamScore.addValidators(Validators.required);
      this.ctrl.awayTeamScore.addValidators(Validators.required);
    }

    if (this.isDisabledForEdit) this.form.disable();
    this.reportS.checkAttendance();

    this.form.controls.gameAdjustmentStatus.valueChanges
    .pipe(untilDestroyed(this))
    .subscribe((value) => {
      if (value) {
        this.showAdjustmentStatusError = false;
        this.cd.detectChanges(); 
      }
    });
  }

  get ctrl(): IReportInfoForm {
    return this.form?.controls;
  }

  // === METHOD REPORT =========================
  updateReport(isDraft?: boolean): void {
    if (this.startRequest()) return;
    // Check if gameIsCompletedDrop is answered
    if (!this.ctrl.gameIsCompletedDrop?.value) {
      // Show error message or handle the error state (e.g., display an error message)
      console.error('Report is missing information: Did this game complete?');
      this.showGameCompleteError = true;
      this.endRequest(); // End the request process
      return;
    }
    this.showGameCompleteError = false;
    if (this.ctrl.gameIsCompletedDrop.value?.valueBoolean === false && !this.ctrl.gameAdjustmentStatus?.value) {
      console.error('Report is missing information: Select Adjustment Status');
      this.showAdjustmentStatusError = true;
      this.endRequest();
      return;
    }
    this.showAdjustmentStatusError = false;
    const report = this.reportS.report;
    const game_CANCELED = report?.game?.gameStatus === 'CANCELLED';
    let sendObj: ClassReport = { ...report, ...this.form.getRawValue() };

    const ctrlValue_adjustmentStatus = this.ctrl.gameAdjustmentStatus?.value;

    if (!game_CANCELED) {
      sendObj = {
        ...sendObj,
        // ...this.form.getRawValue(),
        id: report?.id,
        gameIsCompleted: this.ctrl.gameIsCompletedDrop.value?.upperCase === 'Yes',
        homeTeamScore: this.ctrl.homeTeamScore?.value ? +this.ctrl.homeTeamScore?.value : 0,
        awayTeamScore: this.ctrl.awayTeamScore?.value ? +this.ctrl.awayTeamScore?.value : 0,
        status: report?.status,
        isDraft: isDraft,
        gameNotBegun: this.ctrl.gameNotBegun?.value,
        additionalComments: this.ctrl.ctrlTextarea?.value,
      };
      if (ctrlValue_adjustmentStatus && sendObj.game) {
        sendObj.game.gameAdjustmentStatus = {
          ...sendObj.game?.gameAdjustmentStatus,
          adjustmentStatusName: ctrlValue_adjustmentStatus?.name || ctrlValue_adjustmentStatus?.adjustmentStatusName,
          adjustmentStatusId: ctrlValue_adjustmentStatus?.id || ctrlValue_adjustmentStatus?.adjustmentStatusId, // ?: string;
          cancelReason: this.ctrl.ctrlTextarea?.value, // ?: string;
        };
      }
    }

    if (game_CANCELED) {
      sendObj = {
        ...sendObj,
        id: report?.id,
        game: {
          ...sendObj.game,
          id: report?.game?.id,
          gameAdjustmentStatus: {
            adjustmentStatusName: ctrlValue_adjustmentStatus?.name || ctrlValue_adjustmentStatus?.adjustmentStatusName,
            adjustmentStatusId: ctrlValue_adjustmentStatus?.id || ctrlValue_adjustmentStatus?.adjustmentStatusId, // ?: string;
            cancelReason: this.ctrl.ctrlTextarea?.value, // ?: string;
          },
        },
      };
      delete sendObj.additionalComments; // only for gameDetails
    }

    const arrAttendance: Array<ClassAttendance> = report?.attendance || [];
    if (arrAttendance?.length) {
      sendObj.attendance = arrAttendance.map(el => ({ ...UtilsService.removeEmptyKeysFromObject(el) }));
    }

    delete (sendObj as any).team_1;
    delete (sendObj as any).team_2;
    delete (sendObj as any).gameAdjustmentStatus; // в репорте не должно быть gameAdjustmentStatus

    this.reportS.updateReport(sendObj)
      .pipe(
        tap(() => this.isDisabledForEdit = true), // !!! NO DELETE. Нужно перерасчет сделать какие кнопки показывать. Поэтому после получения репорта заново назначится значение для isDisabledForEdit в методе checkDisabledForEdit()
        switchMap((res) => this.reportS.getReport(this.reportS.report?.id!)),
        untilDestroyed(this),
      )
      .subscribe(res => {
        const updatedReport = this.reportS.report;
        this.createForm();
        this.geo.checkLocForGoggleMapFromILocation(false, updatedReport?.game?.location?.address);
        this.endRequest();
      });
  }

  // !!! PopupAssignComponent => btn Assign = > метод который используется на странице Assign
  // !!! PopupAssignComponent => по емайлу => inviteRefereeToGame() из 2 версии
  // !!! если есть attendanceItem, то эта роль есть в attendance(тоесть эта роль заасайнена в данный момент)
  // !!! если нет attendanceItem, то это фсайн судьи на пустую роль
  openPopupAssign(attendanceItem: ClassAttendance | null, go?: ClassGameOfficial): void {
    if (this.startRequest()) return;
    const report = this.reportS.report;
    const dataPopup: IDataPopup = {
      game: report?.game,
      go: go || attendanceItem?.go, // ClassGameOfficial;
      user: attendanceItem?.official, // string;
      report: report, // string;
      isReport: true,
      arrIds: report?.attendance?.map((el) => el.official?.id!), // !!! массив id судей которые уже заасайнены в данной игре
      width: '500px',
    };
    // !!! если res==ClassUser то пригласил существующего в системе юзера. Если res==string то это емайл юзера которого нет в системе.
    from(this.popupS.open(PopupAssignComponent, dataPopup))
      .pipe(
        switchMap((res?: ClassUser | string) => this.reportS.getReport(this.reportS.report?.id!)),
        untilDestroyed(this),
      )
      .subscribe(res => {
        this.endRequest();
      });
  }

  openPopupApprove(): void {
    const arrIdsReport: Array<string> = [this.reportS.report?.id!];
    if (this.startRequest()) return;
    this.reportS.openPopupApprove(arrIdsReport).then((resPopup: boolean) => {
      if (!resPopup) return this.endRequest();
      this.mainS.approveReport(arrIdsReport, 1000)
        .pipe(
          switchMap((res) => {
            this.alreadyApprove = true;
            this.cd.detectChanges();
            return this.reportS.getReport(this.reportS.report?.id!);
          }),
          untilDestroyed(this),
        )
        .subscribe(res => {
          this.endRequest();
        });
    });
  }

  // === AdjustmentStatuses ===============
  getAdjustmentStatuses(): void {
    this.adjustmentStatusesS.methodAdjustmentStatuses('get', true)
      .pipe(untilDestroyed(this))
      .subscribe((res: Array<ClassAdjustmentStatus>) => {
        this.arrAdjustmentStatus = res || [];
        this.cd.detectChanges();
      });
  }

  // === OTHER ==========================
  checkDisabledForEdit(): void {
    const DUE = this.reportS.report?.status === 'DUE';
    this.isDisabledForEdit = this.meS.OFFICIAL && (!DUE || (DUE && !this.reportS.officialOnFirstPosition));
  }
}
