<div *ngIf='{
      cssClass: "o-matForm--"+({value:value,active:active,disabled:disabled,error:errRequired}|getStateInput)
  } as obj' class='wrapDropdown' [style]='{w:width}|getStyles'>
  <mat-form-field floatLabel='auto' class='o-matForm o-matForm--white {{obj.cssClass}} {{obj.cssClass}}--text6-blueDark'>

    <meSvg *ngIf='svgPrefix' matPrefix [svgName]='svgPrefix' [w]='widthSvgPrefix'></meSvg>
    <meSvg *ngIf='svgSuffix' matSuffix [svgName]='svgSuffix' [w]='widthSvgSuffix'></meSvg>


    <mat-select #selectRef [class.enlargeReverse]='!!selectRef?.panelOpen'
                [(ngModel)]='value'
                [placeholder]='placeholder' [multiple]='multi' disableOptionCentering
                class='is-announcements'
                [disabled]='!!ngControl.control?.disabled'
                (openedChange)='checkClosedDrop($event)' [compareWith]='otherS.compareObj'>
      <mat-option [ngStyle]='(elRef|getWidthMatMenu:forTest)' class='hiddenMatOption'></mat-option>



      <ng-container *ngFor='let item of array; let idx = index; let first = first; let last = last'>
        <mat-option [value]='item' [ngClass]="item.cssClass">
            <div class='matOptionWithSvg'>
                <img *ngIf='item.icon' [src]='item.icon' alt='icon' class='method-icon' />
                {{ selectRef?.panelOpen ? item.titleCase : (showAlwaysPlaceholder ? placeholder : item.titleCase) }}
                <meSvg *ngIf='item.svgRightMatOption' [svgName]='item.svgRightMatOption'></meSvg>
                <ng-container *ngIf='item.count'>({{ item.count }})</ng-container>
            </div>
        </mat-option>
    </ng-container>

    </mat-select>
  </mat-form-field>

  <ng-content></ng-content>
</div>