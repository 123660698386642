import { ILocation } from '@models/location';
import { ClassUser } from '@models/user';
import { ClassDrop, preparePropertyFromDropForSendToServer } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { ClassCompetitionLocation } from '@app/dir_group_assignor/competitions/ClassCompetition';

export class ClassGroup extends ClassDrop {
  id?: string;
  name?: string;
  companyLegalName?: string;
  logo?: string;
  email?: string;
  phone?: string;
  gameType?: string; // TGameType
  // groupAddress?: GroupAddressDto;
  groupAddress?: ILocation;
  groupAssignor?: ClassUser; // ТИмур для страницы редактирования группы
  groupAssignorId?: string;

  // ===
  mailingAddress?: string; //  Тимур page==Group Profile
  groupTermsConditions?: string;

  constructor(group?: ClassGroup) {
    super({ titleCase: group?.name, upperCase: group?.id });
    if (!group) return {};
    Object.entries(group)?.forEach((el) => {
      const key = el[0] as keyof ClassGroup;
      this[key] = el[1];
    });

    // === FOR DROPDOWN ============================================
    this.groupAddress = new ClassCompetitionLocation(group.groupAddress);
    return { ...group, ...this };
  }

  // !!! перед отправкой на сервер доставать из IDrop.upperCase и записывать в соответствующий property
  // !!! И удалять IDrop, т.к. на сервер они не доллжны отправляться
  // !!! Все property которые в конце названия имеют "Drop" нужно в этом методе обрабатывать
  static preparePropertyFromDropForSendToServer(obj?: ClassGroup): ClassGroup {
    if (!obj) return {};
    const objForSendToServer: ClassGroup = obj;

    // !!! prepare Property Without Drop In Name
    // !!! если в конструкторе текущего класса вызывал создание другого класса, то здесь надо вызвать preparePropertyFromDropForSendToServer
    objForSendToServer.groupAddress = ClassCompetitionLocation.preparePropertyFromDropForSendToServer(obj.groupAddress);

    return preparePropertyFromDropForSendToServer(objForSendToServer);
  }
}

export function getArrClassGroup(arrClassGroup_fromServer: Array<ClassGroup>): Array<ClassGroup> {
  return arrClassGroup_fromServer?.map((el) => new ClassGroup(el));
}

export class ClassGroupListItem extends ClassDrop {
  id?: string;
  listName?: string;
  officialCount?: number;

  officialIds?: Array<string>;

  active?: boolean;

  constructor(groupListItem?: ClassGroupListItem) {
    super({ titleCase: groupListItem?.listName, upperCase: groupListItem?.id });
    if (!groupListItem) return {};
    Object.entries(groupListItem)?.forEach((el) => {
      const key = el[0] as keyof ClassGroupListItem;
      this[key] = el[1];
    });

    return { ...groupListItem, ...this };
  }

  // !!! перед отправкой на сервер доставать из IDrop.upperCase и записывать в соответствующий property
  // !!! И удалять IDrop, т.к. на сервер они не доллжны отправляться
  // !!! Все property которые в конце названия имеют "Drop" нужно в этом методе обрабатывать
  static preparePropertyFromDropForSendToServer(obj?: ClassGroupListItem): ClassGroupListItem {
    if (!obj) return {};
    const objForSendToServer: ClassGroupListItem = obj;
    return preparePropertyFromDropForSendToServer(objForSendToServer);
  }
}

