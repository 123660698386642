import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TColor } from '@models/ICssStyles';
import { MatInputModule } from '@angular/material/input';
import { ErrorComponent } from '@components/__info_text_message_error_warning/error/error.component';
import { GetStateInputPipe } from '@pipes/get-state-input.pipe';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { HelperClass } from '@classes/Helper-Classes';
import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { GetSelectedElemPipe } from '@pipes/get-selected-elem.pipe';
import { MAT_MENU_DEFAULT_OPTIONS, MatMenuModule } from '@angular/material/menu';
import { GetWidthMatMenuPipe } from '@pipes/get-width-mat-menu.pipe';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { PhoneDirective } from '@components/__drop_inputs_matSelect/phone/directives/phone.directive';
import { StrCasePipe } from '@pipes/string/str-case.pipe';
import { GetChxSvgNamePipe } from '@components/__svg_img/svg/pipes/get-chx-svg-name.pipe';
import { MainService } from '@services/main.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import intlTelInput from 'intl-tel-input';

export interface IObjPrefix {
  abbr: string,
  value: string,
  isSelect: boolean
}

@UntilDestroy()
@Component({
  selector: 'phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, FormsModule, ErrorComponent, GetStateInputPipe, FieldComponent, GetSelectedElemPipe, MatMenuModule, GetWidthMatMenuPipe, SvgComponent, PhoneDirective, StrCasePipe, GetChxSvgNamePipe],
  // changeDetection: ChangeDetectionStrategy.OnPush, // touched no work
  providers: [
    {
      // provide: MAT_SELECT_CONFIG,
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'forOverlayPanelPhone' },
    },
  ],
})
export class PhoneComponent extends HelperClass implements OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('inputRef') inputRef?: ElementRef;
  @Input() text = 'Phone number';
  @Input() bcg: TColor | 'transparent' = 'white'; // background // TColor = 'blueDark' | 'grey' | 'grey_1' | 'white' | 'blue' | 'green' | 'red';
  @Input() isFocus = false; // если true передал значит надо фокус сделать на этом поле

  @Input() disabled = false;

  // phoneValue?: string;
  @Input() phoneValue?: string;
  forLibrary?: intlTelInput.Plugin; // intl-tel-input

  @Output() changeVal = new EventEmitter<string>();

  // forTestPhone = false;
  // @Input() existChxTestPhone = false;

  constructor(
    public mainS: MainService,
    public renderer: Renderer2,
    private focusMonitor: FocusMonitor,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
  }

  // testPhoneInput(event: any) {
  // console.log('event :', typeof event?.target?.value, event?.target?.value)
  // this.changeVal.next(event?.target?.value);
  // }

  test(e?: any) {
    //   console.log('e :', e)
    //   console.log('countryData :', this.countryData)
    //   console.log('forLibrary getNumber() :', this.forLibrary?.getNumber());
    //   console.log('input value :', this.inputRef?.nativeElement?.value);
    //   console.log('this.forLibrary?.getNumber() :', this.forLibrary?.getNumber(), '  countryData :', this.countryData,
    //     '  dialCode :', this.dialCode);
    //   console.log('countryData :', this.countryData)
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('changes :', changes)
    if (changes?.phoneValue?.currentValue) {
      // console.log('ngOnChanges phoneValue :', this.phoneValue)
    }
    if (changes?.isFocus?.currentValue) {
      setTimeout(() => {
        this.inputRef?.nativeElement?.focus();
      });
    }
    // if (changes?.existChxTestPhone?.currentValue) {
    //   console.log('ngOnChanges existChxTestPhone :', this.existChxTestPhone)
    // }
  }

  ngAfterViewInit() {
    this.subscribeToFocused();
    this.setForLibrary();
    // console.log('ngAfterViewInit inputRef?.nativeElement :', this.inputRef?.nativeElement?.value)
    // console.log('ngAfterViewInit phoneValue :', this.phoneValue)
    this.inputRef?.nativeElement.addEventListener('countrychange', (e: any) => { // вызывается при выборе страны
      this.phoneValue = this.forLibrary?.getNumber()?.replace('+', '');
      // console.log('ngAfterViewInit phoneValue :', this.phoneValue)
      this.changeVal.next(this.dialCode + this.phoneValue);
    });
  }

  setForLibrary(): void {
    // console.log('setForLibrary inputRef?.nativeElement :', this.inputRef?.nativeElement?.value)
    // console.log('setForLibrary phoneValue :', this.phoneValue)
    const optionsForLibrary: any = { //intlTelInput.Options 
      formatOnDisplay: false,
      preferredCountries: this.mainS.forProd ? ['us'] : ['us', 'ca'],
      onlyCountries: this.mainS.forProd ? ['us'] : ['us', 'ca', 'ru', 'kg', 'in', 'lv', 'th'],
      initialCountry: 'us',
      // hiddenInput: "full", ///
      // separateDialCode: true,
      utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js',
      useFullscreenPopup: false
    };
    this.forLibrary = intlTelInput(this.inputRef?.nativeElement, optionsForLibrary);
    if (this.phoneValue) this.forLibrary.setNumber(this.phoneValue);
  }

  // === GETTERS & SETTERS library ========== 
  get countryData(): intlTelInput.CountryData {
    return this.forLibrary!.getSelectedCountryData();
  }

  get dialCode(): string {
    return this.countryData.dialCode;
  }

  subscribeToFocused(): void {
    this.focusMonitor.monitor(this.inputRef?.nativeElement, true).pipe(untilDestroyed(this))
      .subscribe((origin: FocusOrigin) => this.active = !!origin);
  }

  modelChange(e: any): void {
    // console.log('modelChange :', e)
    // console.log('phoneValue :', this.phoneValue)
    // console.log('dialCode :', this.dialCode)
    // console.log('this.forLibrary?.getNumber() :', this.forLibrary?.getNumber())
    this.changeVal.next(this.dialCode + this.phoneValue);
  }

  // === GETTERS & SETTERS =======================
  set value(value: string) {
    // if (this.inputRef?.nativeElement) {
    // this.changePrefix.emit(this.dialCode);
    // }
    // console.log('this.forLibrary?.getNumber() :', this.forLibrary?.getNumber(), '  countryData :', this.countryData,
    //   '  dialCode :', this.dialCode);
    // console.log('countryData :', this.countryData)
    // console.log('forLibrary getNumber() :', this.forLibrary?.getNumber());
    // console.log('input value :', this.inputRef?.nativeElement?.value);
    this.changeVal.next(this.forLibrary?.getNumber()!);
    this.cd.detectChanges();
  }

  get value(): string {
    // return this.ngControl.control?.value || '';
    return this.forLibrary?.getNumber() || '';
  }

  get error(): boolean {
    // if (this.forTestPhone) return false; // !!! для тестовых номеров не надо ошибку показывать и проверку на валидность тоже не надо
    if (!this.mainS.forProd) return false; // !!! для беты/стегинга НЕ надо ошибку показывать и проверку на валидность тоже не надо
    return this.invalid && !!this.value;
  }

  get valid(): boolean {
    // if (this.forTestPhone) return true; // !!! для тестовых номеров не надо ошибку показывать и проверку на валидность тоже не надо
    if (!this.mainS.forProd) return true; // !!! для беты/стегинга НЕ надо ошибку показывать и проверку на валидность тоже не надо
    return !!this.forLibrary?.isValidNumber();
  }

  get invalid(): boolean {
    // if (this.forTestPhone) return false; // !!! для тестовых номеров не надо ошибку показывать и проверку на валидность тоже не надо
    if (!this.mainS.forProd) return false; // !!! для беты/стегинга НЕ надо ошибку показывать и проверку на валидность тоже не надо
    return !this.forLibrary?.isValidNumber();
  }

  set active(value: boolean) {
    this.renderer.setProperty(this.inputRef?.nativeElement, 'active', value);
  }

  get active(): boolean {
    return !!this.inputRef?.nativeElement?.active;
  }

  // set disabled(value: boolean) {
  //   this.renderer.setProperty(this.inputRef?.nativeElement, 'disabled', value);
  // }

  // get disabled(): boolean {
  //   return !!this.inputRef?.nativeElement?.disabled;
  // }

  ngOnDestroy(): void {
    this.forLibrary?.destroy();
  }
}

// changePrefix(prefix: IObjPrefix) {
//   console.log('value :', this.value)
//   console.log('prefix :', prefix)
//   // console.log('prefixRef :', prefixRef.nativeElement)
//   // console.log('prefix :', prefix)
//
//   // this.phoneS.arrPrefix.forEach((el) => el.isSelect = el.value == prefix.value) ///
//   // this.phone = (this.currentPrefix.value+this.value).replace('+','')
//   // this.changeVal.emit(this.phone)
//   // this.cd.detectChanges()
// }


// inputNumber(e: any): void {
//   if (typeof e.target.value == 'string') {
//   this.value = e.target.value;
//   // this.phone = (this.currentPrefix.value+this.value).replace('+','')
//   // this.changeVal.emit(this.phone)
//   // this.cd.detectChanges()
// }
// }

// inputFocus(): void {
//   // setTimeout(() => {
//   console.log('============  getNumber :', this.forLibrary!.getNumber(),
//     '  getExtension :', this.forLibrary!.getExtension(),
//     '  getNumberType :', this.forLibrary!.getNumberType(),
//     '  getSelectedCountryData :', this.forLibrary!.getSelectedCountryData(),
//     '  isValidNumber :', this.forLibrary!.isValidNumber())
//   // },111)
// }