import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClassGame, CertDropDown, arrCertDropDownDrop, ClassCertDropDown } from '@app/dir_group_assignor/games/game';
import { CommonModule, DatePipe } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { LinkPageComponent } from '@components/link-page/link-page.component';
import { DeviceService } from '@services/device.service';
import { GetLocationStringPipe } from '@pipes/location/get-location-string.pipe';
import { BtnComponent } from '@components/btn/btn.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MeProfileService } from '@app/profile/me-profile/meProfile.service';
import { ProfileService } from '@app/profile/profile.service';
import { OtherService } from '@services/other.service';
import { MainService } from '@services/main.service';
import { IDataPopup, PopupService } from '@services/popup.service';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { TooltipDirective } from '@directives/tooltip.directive';
import { TitleAndNavigationForProfileComponent } from '@app/profile/additional_components/title-and-navigation-for-profile/title-and-navigation-for-profile.component';
import { rout_meProfile, rout_meProfileOfficial, TypePageProfile } from '@app/profile/profile';
import { MeService } from '@services/me.service';
import { ClassUser } from '@models/user';
import { ProfileUserService } from '@app/profile/profile-user/profile-user.service';
import { untilDestroyed } from '@ngneat/until-destroy';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { const_NA, TypeNA } from '@models/other';
import { MeProfileOfficialService } from '@app/profile/me-profile-official/me-profile-official.service';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';

@Component({
  selector: 'app-me-profile-certificate',
  standalone: true,
  imports: [CommonModule, RouterOutlet, LinkPageComponent, GetLocationStringPipe, BtnComponent, HeadTitleComponent, DropFormCtrlComponent,
    FormsModule, ReactiveFormsModule, FieldComponent, InputCtrlComponent, BtnWrapComponent, DropdownComponent, TooltipDirective, TitleAndNavigationForProfileComponent, SpinnerComponent, SvgComponent],
  templateUrl: './me-profile-certificate.component.html',
  styleUrls: ['./me-profile-certificate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeProfileCertificateComponent {
  typePageProfile!: TypePageProfile;
  myLicense: any = null;
  getUser: any = null;
  name: string = '';
  isError404: boolean = false;
  certified: boolean = false;
  organizationUserData: any = {};
  showUserLicenses: boolean = false;
  userLicenses: any = {};
  userLicenses_taggle: any = {};
  cancelReason: string = '';
  games: Array<any> = [];
  adjustmentDrop: string;
  arrAdjustmentDrop: any;
  arrCertDropDownDrop = arrCertDropDownDrop;
  certDropDown: ClassCertDropDown | undefined;
  value: string = '';
  isAddCertPopupOpen: boolean = false; 
  isDeleteConfirmationOpen: boolean = false;
  // arrAdjustmentStatusDrop = arrAdjustmentStatusDrop;
  // adjustmentStatusDrop?: ClassAdjustmentStatus;
  userAge: number | undefined;
  userId: string | undefined;
  officialUserId: any;
  loading: boolean = false;

  constructor(
    public deviceS: DeviceService,
    private mainS: MainService,
    public otherS: OtherService,
    public meProfileS: MeProfileService,
    public profileS: ProfileService,
    public cd: ChangeDetectorRef,
    public popupS: PopupService,
    public datePipe: DatePipe,
    private router: Router,
    public meS: MeService,
    private route: ActivatedRoute,
    public profileUserS: ProfileUserService,
    public meProfileOfficialS: MeProfileOfficialService,
  ) {
    
    this.certDropDown = arrCertDropDownDrop[0]; 
   this.adjustmentDrop = 'US_SOCCER_LEARNING_CENTRE';
   this.arrAdjustmentDrop = CertDropDown.US_SOCCER_LEARNING_CENTRE;
   this.checkCurrentRout();
   console.log('this.meS.userId', this.meS.userId);

   this.meS.me$.subscribe((user?: ClassUser) => {
    console.log('user', user?.age);
    this.userAge = user?.age !== undefined ? (typeof user.age === 'string' ? parseInt(user.age) : user.age) : undefined;

   });


    this.games = []; 
    this.certDropDown = {}; 
    this.adjustmentDrop = ''; 
    this.arrAdjustmentDrop = {}; 
  }

  removeHyphens() {
    // Replace hyphens with an empty string
    this.value = this.value.replace(/-/g, '');
    if (this.value.length > 16) {
      this.value = this.value.slice(0, 16);
    }
  }
  

  getUserIdFromQueryParams(): void {
    this.route.params.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res?.idUser) this.profileUserS.getContactById(res.idUser);
    });
  }

  checkCurrentRout(): void {
    if (this.router.url?.includes(rout_meProfile)) this.typePageProfile = rout_meProfile;
    if (this.router.url?.includes(rout_meProfileOfficial)) this.typePageProfile = rout_meProfileOfficial;
  }

  ngOnInit() {
    this.loading = true;
    this.route.params.subscribe(params => {
      this.userId = params['idUser']; 
      console.log('User ID:', this.userId); 
  
      if (this.userId) {
        this.mainS.getOfficialByUserIdCert(this.userId).subscribe(
          (response: any) => {
            console.log('response', response)
            console.log(response.userId)
            const officialUserId = response?.userId;
            console.log(officialUserId)
              this.officialUserId = officialUserId; 
              this.loadOfficialUserLicenses(officialUserId);
              this.loading = false;
          },
          (error: any) => {
            console.error('Error fetching official user:', error);
            this.loading = false;
          }
        );
      } else {
        this.loadUserLicenses();
      }
    });

  }


  loadOfficialUserLicenses(userId: string) {
    this.mainS.getOfficialUserLicenses(userId).subscribe(
      (res: any) => {
        this.myLicense = res;
        this.organizationUserData = res.organizationUserData;
        this.certified = res.certified;
        this.userLicenses = res.userLicenses;
        // this.userLicenses_taggle = res.userLicenses.slice(1).filter((license: any) =>
        //       ['safety_1', 'safety_2', 'safety_3'].includes(license.license.id)
        //     );
        this.userLicenses_taggle = res.userLicenses.slice(1);
        // if (this.userAge && this.userAge > 18) {
        //   this.userLicenses_taggle = res.userLicenses.slice(1).filter((license: any) =>
        //     ['safety_1', 'safety_2', 'safety_3'].includes(license.license.id)
        //   );
        // } else {
        //   this.userLicenses_taggle = res.userLicenses.slice(1).filter((license: any) =>
        //     license.license.id === 'safety_3'
        //   );
        // }
        this.isError404 = false;
        this.cd.detectChanges(); 
      },
      (err: any) => {
        if (err.status === 404) {
          this.isError404 = true;
          this.cd.detectChanges(); 
        }
      }
    );
  }

  
  loadUserLicenses() {
    this.loading = true;
    console.log('this.loading',this.loading)
    this.mainS.getUserLicenses().subscribe(
      (res: any) => {
        this.myLicense = res;
        this.organizationUserData = res.organizationUserData;
        this.certified = res.certified;
        this.userLicenses = res.userLicenses;
        if (this.userAge && this.userAge > 18) {
          this.userLicenses_taggle = res.userLicenses.slice(1).filter((license: any) =>
            ['safety_1', 'safety_2', 'safety_3'].includes(license.license.id)
          );
        } else {
          this.userLicenses_taggle = res.userLicenses.slice(1).filter((license: any) =>
            license.license.id === 'safety_3'
          );
        }
        this.isError404 = false;
        this.loading = false;
        console.log('this.loading',this.loading)
        this.cd.detectChanges(); 
      },
      (err: any) => {
        this.loading = false;
        console.log('this.loading',this.loading)
        if (err.status === 404) {
          this.isError404 = true;
          this.cd.detectChanges(); 
        }
      }
    );
  }


  openAddCertPopup() {
    this.isAddCertPopupOpen = true; 
    this.togglePageDull(true);
    
  }

  closeAddCertPopup() {
    this.isAddCertPopupOpen = false; 
    this.togglePageDull(false);
  }

  togglePageDull(isDull: boolean) {
    if (isDull) {
      document.body.classList.add('popup-open');
    } else {
      document.body.classList.remove('popup-open');
    }
  }

  isInvalidUSSFID(): boolean {
    return !!(this.value && (this.value.length !== 16 || isNaN(Number(this.value))));
  }
  
  
  isSaveDisabled(): boolean {
    return this.isInvalidUSSFID() || this.value.trim() === '';
  }

  addCertificate() {
    if (this.userId) {
      // If userId is present, use addOfficialUserOrganizationId
      this.mainS.addOfficialUserOrganizationId(this.value, this.officialUserId!).subscribe((res: any) => {
        console.log('res', res);
        if (res == null) {
          this.closeAddCertPopup();
          this.loadOfficialUserLicenses(this.officialUserId!);
          this.cd.detectChanges(); 
        }
      });
    } else {
      // If userId is not present, use addUserOrganizationId
      this.mainS.addUserOrganizationId(this.value).subscribe((res: any) => {
        console.log('res', res);
        if (res == null) {
          this.closeAddCertPopup();
          this.loadUserLicenses();
          this.cd.detectChanges(); 
        }
      });
    }
  }
  

  deleteCertificate(){
      this.isDeleteConfirmationOpen = true;
      this.togglePageDull(true);
  }

  closeDeleteConfirmation() {
    this.isDeleteConfirmationOpen = false;
    this.togglePageDull(false);
  }

  confirmDeleteCertificate() {
    if (this.userId) {
      this.mainS.deletOfficialeUserLicenses(this.officialUserId!).subscribe((res: any) => {
        console.log('res', res);
        this.loadOfficialUserLicenses(this.officialUserId!);
        this.closeDeleteConfirmation();
        this.cd.detectChanges(); 
      });
    } else {
      this.mainS.deleteUserLicenses().subscribe((res: any) => {
        console.log('res', res);
        this.loadUserLicenses();
        this.closeDeleteConfirmation();
        this.cd.detectChanges(); 
      });
    }
  }

  close(games?: Array<any>): void {
    // Implement close functionality
  }

  saveData() {
    this.mainS.addUserOrganizationId(this.name).subscribe((res: any) => {
      console.log('res', res);
    })
  }

  getFormattedDate(dateString: string | null): string {
    if (!dateString) return ''; 
    const date = new Date(dateString);
    return this.datePipe.transform(date, 'MMMM d, y') || ''; 
  }

  getIssueDate(): string {
    if (this.userLicenses && this.userLicenses.length > 0) {
      return this.getFormattedDate(this.userLicenses[0].issueDate);
    }
    return '';
  }

  getIssueName(): string {
    if (this.userLicenses && this.userLicenses.length > 0) {
      return this.userLicenses[0].license.name;
    }
    return '';
  }

  getExpiryDate(): string | TypeNA {
    if (this.userLicenses && this.userLicenses.length > 0) {
      return this.getFormattedDate(this.userLicenses[0].expiryDate) || const_NA;
    }
    return const_NA;
  }

  toggleViewMore() {
    this.showUserLicenses = !this.showUserLicenses;
  }
}
