import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { ClassWidth, IWidth, TClassForText } from '@models/ICssStyles';
import { DeviceService } from '@services/device.service';

@Component({
  selector: 'fieldRow[text]',
  standalone: true,
  imports: [CommonModule, SvgComponent],
  templateUrl: './field-row.component.html',
  styleUrls: ['./field-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldRowComponent implements OnChanges {
  // @Input() page?: 'createGame' | 'registration' | 'contact'; // дизайн разный на этих страницах. Сейчас другой дизайн только для 'contact'
  @Input() text!: string;
  @Input() topclass: string = '';
  @Input() typeText: TClassForText = 'text7-blueDark';

  @Input() text2: string = '';
  @Input() typeText2: TClassForText = 'text8-blueDark';

  @Input() width?: number | string; // !!! что-то из этого надо обязательно передать 33 || 100%
  @Input() objWidth?: IWidth; // !!! что-то из этого надо обязательно передать

  // @Input() isBorderBottom: boolean = false;
  @Input() padding: string = ''; // '1px 2px 3px 4px'

  @Input() forMobileFlexColumn: boolean = true; // !!!

  @Input() alignItems: 'flex-start' | 'center' = 'flex-start';

  // можно передавать звёздочку так text=="anyText*" или так [ctrl]='ctrl.email' email: ['', [Validators.required]],
  // @Input() ctrl?: FormControl; // если required, то показывать *
  // star?: boolean; // символ *

  // @Input() svgName?: string;
  // @Input() matTooltipSVG?: string;

  // @Output() svgEmit = new EventEmitter();

  constructor(
    public deviceS: DeviceService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.width?.currentValue) {
      this.objWidth = new ClassWidth(this.width!);
      // console.log('width :', this.width, this.objWidth)
    }
    // this.star = this.text?.includes('*');
    // if (this.star) this.text = this.text?.replace('*', '');
  }
}
