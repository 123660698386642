import { Injectable } from '@angular/core';
import { ClassUser } from '@models/user';
import { ClassSettingsRequest, IResponse } from '@models/response-and-request';
import { BehaviorSubject, finalize, from, Observable, of, switchMap, tap } from 'rxjs';
import { CdkDropList } from '@angular/cdk/drag-drop';
import {
  ClassGame,
  ClassGameOfficial,
  IForApiMethod_updateOfficials,
  IOfficialsAssign,
  TStatusAssignForSendToServer,
} from '@app/dir_group_assignor/games/game';
import { PopupService } from '@services/popup.service';
import { UnsavedChangesService } from '@services/unsaved-changes.service';
import { MainService } from '@services/main.service';
import {
  PopupSetStatusForAssignComponent,
} from '@app/dir_group_assignor/assign/components/popup-set-status-for-assign/popup-set-status-for-assign.component';
import { PopupComponent } from '@components/__popup-windows/popup/popup.component';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { UtilsService } from '@services/utils.service';
import { IGoToAssign } from '@app/dir_group_assignor/assign/models/assign';
import { Router } from '@angular/router';
import { urlAssign } from '@app/app.module';
import {
  TypeBlockGamesForDashboard,
} from '@app/dir_group_assignor/dashboard/components/dashboard-group-assignor/models/group-assignor.models';
import { DeviceService } from '@services/device.service';

@Injectable({ providedIn: 'root' })
export class AssignService {
  // !!! если нажата кнопка Inspect, то показывать судей с gameId
  // !!! менять значение этого флага при нажатии на другую заасайненую роль, чтобы снова показывать всех судей(без gameId)
  btnInspectIsActive = false;

  // !!! для мобилы показывать эту кнопку если выбрана только 1 роль
  get isShowBtnInspect(): boolean {
    if (this.deviceS.isDesktop) {
      return true;
    } else {
      return this.selectedArrGo_assigned_and_savedInServer.length === 1;
    }
  }

  readonly amountForShowAllGO = 4; // по умолчанию показывать GO 4 штуки
  haveDragDrop = false; // было disabledBtn = true; !!! если не было ещё драгДропа (переноса судьи на роль), то должно быть disabled

  showTableOfficialsForMobile = false;

  constructor(
    private deviceS: DeviceService,
    private mainS: MainService,
    private popupS: PopupService,
    private unsavedChangesS: UnsavedChangesService,
    private gameS: GameService,
    private router: Router,
  ) {
  }

  // !!! т.к. сервис глобальный, то нужно скидывать все значения при повторном открытии страницы
  resetAllForFirstOpenPage(): void {
    this.haveDragDrop = false;
    this.showTableOfficialsForMobile = false;
    this.settingsOfficials = new ClassSettingsRequest({ page: 1, size: 30 });
    this.isFirstLoadOfficials = true;
  }

  // === GAMES ==========================================
  dataTable$ = new BehaviorSubject<IResponse<ClassGame>>({}); // for-table===
  arrContent$ = new BehaviorSubject<Array<ClassGame>>([]); // for-table===

  // !!! игра над которой в данный момент производятся действия (например выбор gameOfficial selectGo())
  // !!! если перешел с другой страницы goToAssign(), то надо в игру добавить флаг isSelect, чтобы выделить эту игру зеленым бордером
  // !!! если начал любые действия с другой игрой, то зеленый бордер убрать (тоесть флаг isSelect удалить)
  get selectedGame(): ClassGame | undefined {
    return this.arrContent$.getValue()?.find(el => el.isSelect);
  }

  private updateGame(game: ClassGame | undefined, isNextPage: boolean): void {
    const current_idSelectedGame = this.selectedGame?.id;
    const currentValue_isShowAllOfficials = this.isShowAllOfficials;

    const unSelected_arrContent = this.unSelectAllGameAndAllGo();
    const updated_arrContent = UtilsService.replaceElemArrayById(unSelected_arrContent, { ...game, isSelect: !!game }); // !!! если game то надо выбрать её. если не передал, то значит не надо делать выбраной игру
    this.arrContent$.next(updated_arrContent);

    // !!! изменен ли тип списка судей. тоесть до этого был список всех судей и теперь список судей полученных по gameId
    const changeType_listOfficials: boolean = currentValue_isShowAllOfficials !== this.isShowAllOfficials;

    // !!! игра до этого была выбрана и теперь другую игру выбрал
    const changeSelectedGame: boolean = !!current_idSelectedGame && this.selectedGame?.id !== current_idSelectedGame;

    // !!! если выбран сейчас занятая роль и на вторую занятую роль нажал
    // !!! или наоборот НЕзанятая роль выбрана и опять нажал на НЕзанятую роль
    // !!! также условие - в этой же выбраной игре действия происходят
    // !!! в этом случае просто надо обновить список this.dataOfficials && this.officials БЕЗ запроса на сервер
    if (!changeSelectedGame && !changeType_listOfficials) {
      return;
    } else {
      this.settingsOfficials.page = 1;
    }

    // !!! список судей надо обновлять в зависсимости от выбраной роли(go).
    // !!! если выбрана роль с заасайненым И сохраненным на сервере судьей => нужно отобразить список всех судей, полученных с сервера БЕЗ gameId
    // !!! если выбрана роль с заасайненым И НЕ сохраненным на сервере судьей => нужно отобразить список судей, полученных с сервера по gameId
    // !!! если выбрана свободная роль(без судьи) => нужно отобразить список судей, полученных с сервера по gameId
    if (this.isFirstLoadOfficials) return; // !!! при первой загрузке страницы не надо, т.к. в AssignOfficialAvailabilityComponent в subscribeToSearch отправляется запрос
    this.getListOfficials(isNextPage).toPromise().then((res) => {
    });
  }

  // !!! isSelect=false для всех игр и для ролей внутри этих игр
  private unSelectAllGameAndAllGo(): Array<ClassGame> {
    const arrContent: Array<ClassGame> = this.arrContent$.getValue()?.map((el: ClassGame) => {
      const updated_gameOfficials = el.gameOfficials?.map(go => ({ ...go, isSelect: false }));
      return { ...el, gameOfficials: updated_gameOfficials, isSelect: false };
    });
    return arrContent;
  }

  // === SELECTED arr GameOfficial ============================================
  // !!! выбраные роли (GameOfficial). Здесь могут быть список ролей только одного типа => имеется ввиду что или НЕзанятые роли UNASSIGNED или занятые роли (!!go.official).
  // !!! Совместно занятые и НЕзанятые быть в этом массиве НЕ должны. Потому что список судей показывается в зависимости от того какие роли выбраны (занятые или НЕзанятые)
  get selectedArrGo(): Array<ClassGameOfficial> {
    return this.selectedGame?.gameOfficials?.filter(el => el.isSelect) || [];
  }

  // !!! выбраные GO в массиве занятые и сохранены на сервере
  get selectedArrGo_assigned_and_savedInServer(): Array<ClassGameOfficial> {
    return this.selectedArrGo.filter(go => UtilsService.isSaveInServer_officialAssign(go)); //  && go.isSelect
  }

  // !!! выбраные GO в массиве занятые и НЕ сохранены на сервере
  get selectedArrGo_assigned_and_not_savedInServer(): Array<ClassGameOfficial> {
    return this.selectedArrGo.filter(go => !!go.official && !UtilsService.isSaveInServer_officialAssign(go)); //  && go.isSelect
  }

  // !!! вызывается при нажатии на GameOfficial
  // !!! для мобилы НЕ надо выключать (isSelect) выбраную роль, т.к. можно заново нажать чтобы выбрать другого судью на роль
  selectGO(game: ClassGame, go: ClassGameOfficial, isNextPage: boolean): void {
    if (game.gameStatus === 'CLOSED') return; // of(null)
    this.btnInspectIsActive = false; // !!! менять значение этого флага при нажатии на другую заасайненую роль, чтобы снова показывать всех судей(без gameId)
    // const isUserAssignedInGame_and_not_savedInServer = this.isUserAssignedInGame_and_not_savedInServer(game, go.official?.id!) // !!! заасайнен ли судья в игру И НЕ сохранен на сервере
    // const isUserAssignedInGame_andSavedInServer = this.isUserAssignedInGame_andSavedInServer(game, go.official?.id!) // !!! заасайнен ли судья в игру И сохранен на сервере
    const updated_gameOfficials: Array<ClassGameOfficial> = game.gameOfficials || [];

    // !!! есть ли хоть 1 выбраная роль(go)
    const isHave_selectedArrGo_in_updated_gameOfficials: boolean = !!updated_gameOfficials.find(el => el.isSelect);
    const updated_game: ClassGame | undefined = isHave_selectedArrGo_in_updated_gameOfficials
      ? { ...game, gameOfficials: updated_gameOfficials }
      : undefined;
    // console.log('showTableOfficialsForMobile :', this.showTableOfficialsForMobile)
    this.updateGame(updated_game, isNextPage);
  }


  // === OFFICIALS ===============================================================
  cdkDropListRef?: CdkDropList<any>;
  settingsOfficials = new ClassSettingsRequest({ page: 1, size: 30 }); // !!! Тимур сказал поставить 30 (было 100) 6.03.2024
  dataAllOfficials: IResponse<ClassUser> | null = null;
  allOfficials: Array<ClassUser> | null = null; // !!! судьи полученые БЕЗ gameId, тоесть при загрузке страницы ИЛИ если выбрана занятая роль
  dataOfficialsByGameId: IResponse<ClassUser> | null = null;
  officialsByGameId: Array<ClassUser> | null = null; // !!! судьи полученые по gameId, тоесть когда выбран свободная роль (go.status === 'UNASSIGNED' && !go.official)
  isFirstLoadOfficials = true; // !!! после получения списка allOfficials меняется на false
  loadingOfficialsSub$ = new BehaviorSubject<boolean>(false);

  // !!! при загрузке страницы на экране судьи полученные с сервера БЕЗ gameId
  // !!! при выборе ЗАНЯТОЙ роли == !this.selectedArrGo_is_UNASSIGNED => на экране судьи полученные с сервера БЕЗ gameId
  // !!! при выборе свободной роли == this.selectedArrGo_is_UNASSIGNED => на экране судьи полученные с сервера с gameId !!!
  get isShowAllOfficials(): boolean {
    if (this.isFirstLoadOfficials) return true; // !!! при загрузке страницы на экране судьи полученные с сервера БЕЗ gameId
    if (this.btnInspectIsActive) return false; // !!! если нажата кнопка Inspect, то показывать судей с gameId
    return !!this.selectedArrGo_assigned_and_savedInServer?.length || !this.selectedArrGo?.length;
  }

  get dataOfficials(): IResponse<ClassUser> | null {
    return this.isShowAllOfficials ? this.dataAllOfficials : this.dataOfficialsByGameId;
  }

  get officials(): Array<ClassUser> {
    return (this.isShowAllOfficials ? this.allOfficials : this.officialsByGameId) || [];
  }

  get loadingOfficials(): boolean {
    return this.loadingOfficialsSub$.getValue();
  }

  getListOfficials(isNextPage: boolean): Observable<IResponse<ClassUser> | null> {
    this.loadingOfficialsSub$.next(true);

    // надо у settingsOfficials менять потому что при переключении пагинации больше всегда остается page==1
    if (isNextPage)
      this.settingsOfficials.page!++;
    else
      this.settingsOfficials.page = 1;

    if (this.isShowAllOfficials) {
      delete this.settingsOfficials.gameId;
    } else {
      this.settingsOfficials.gameId = this.selectedGame?.id;
      if (!this.selectedGame?.id) console.error('getListOfficials selectedGame 222:', this.selectedGame?.id, this.selectedGame);
    }

    return this.mainS.getListOfficials({ params: this.settingsOfficials }, 'ACTIVE', false)
      .pipe(
        tap((res: IResponse<ClassUser>) => {
          if (this.isShowAllOfficials) {
            this.dataAllOfficials = res;
            this.allOfficials = isNextPage ? [...this.allOfficials || [], ...res?.content || []] : res?.content || [];
          } else {
            this.dataOfficialsByGameId = res;
            this.officialsByGameId = isNextPage ? [...this.officialsByGameId || [], ...res?.content || []] : res?.content || [];
          }
          this.isFirstLoadOfficials = false;
        }),
        finalize(() => {
          this.loadingOfficialsSub$.next(false);
        }),
      );
  }

  assignOfficials(isPublish: boolean): Observable<null> {
    // !!! перед ассайном судьи надо сначала удалить с ролей судей, которые сохранены на сервере и были заменены
    const arrGoIdsForDeleteFromServer: Array<string> = [];

    const sendObj: IOfficialsAssign = { publish: isPublish, officialsAssign: [] };
    this.arrContent$.getValue()?.map((game) => {
      game?.gameOfficials?.forEach((go: ClassGameOfficial, idx) => {
        if (go?.official?.id) sendObj.officialsAssign?.push({ officialId: go?.official?.id, gameOfficialId: go?.id });
        if (go?.officialId_savedInServer && go?.officialId_savedInServer !== go.official?.id) { // !!! эти судьи в этих ролях сохранены на сервере и были заменены
          arrGoIdsForDeleteFromServer.push(go.id!);
        }
      });
    });

    const observable$ = arrGoIdsForDeleteFromServer.length
      ? this.mainS.removeOfficials(arrGoIdsForDeleteFromServer).pipe(switchMap((res) => this.mainS.assignOfficials(sendObj)))
      : this.mainS.assignOfficials(sendObj);

    return observable$.pipe(
      tap((res) => {
        this.unsavedChangesS.unsavedChanges = false;
        this.mainS.deleteApiCache(this.mainS.apiOfficials);
        this.haveDragDrop = false; // this.disabledBtn = true
      }),
    );
  };

  // !!! установка статуса для роли (запрос на сервер)
  openPopupSetStatus(): Observable<Array<IForApiMethod_updateOfficials> | null> {
    // !!! роли могут быть выбраны и сохраненные на сервере и НЕсохраненные. Отправлять на сервер нужно только те которые уже сохранены на сервере.
    // const arrSelectedGO_savedInServer = this.selectedArrGo.filter(el => !!el.official_isSaveInServer); // !!! в этих выбраных ролях заасайненые судьи уже сохранены на сервере
    const arrSelectedGO_savedInServer = this.selectedArrGo.filter(el => !!el.officialId_savedInServer); // !!! в этих выбраных ролях заасайненые судьи уже сохранены на сервере
    if (arrSelectedGO_savedInServer?.length) { // !!! удаление ролей с сервера, т.к. эти роли уже сохранены на сервере
      console.log('SEND TO SERVER :', arrSelectedGO_savedInServer);
      return from(this.popupS.open(PopupSetStatusForAssignComponent, { width: '400px' }))
        .pipe(
          switchMap((resPopup?: TStatusAssignForSendToServer) => {
            if (resPopup) {
              return this.mainS.updateOfficials(arrSelectedGO_savedInServer.map(el => ({ id: el.id, status: resPopup })))
                .pipe(
                  tap((res) => { // !!! ids gameOfficials
                    // !!! изменить статус у выбраных ролей
                    const updated_gameOfficials = this.selectedGame?.gameOfficials?.map((currentGo) => {
                      const goFromServer = res?.find(elemResponse => elemResponse.id === currentGo.id); // !!! id который с сервера вернулся => это id роли у которой нужно изменить статус
                      if (goFromServer) {
                        const updated_findGo: ClassGameOfficial = { ...currentGo, ...goFromServer };
                        return updated_findGo;
                      } else {
                        return currentGo;
                      }
                    }) || [];
                    const updated_game: ClassGame = { ...this.selectedGame, gameOfficials: updated_gameOfficials };
                    this.updateGame(updated_game, false);
                  }),
                );
            } else { // !!! в попап окне нажал Cancel
              return of(null);
            }
          }),
        );
    } else {
      return of(null);
    }
  };

  openPopupRemoveOfficials(): Observable<Array<string> | null> {
    // !!! роли могут быть выбраны и сохраненные на сервере и НЕсохраненные. Отправлять на сервер нужно только те которые уже сохранены на сервере.
    // const arrSelectedGO_savedInServer = this.selectedArrGo.filter(el => !!el.official_isSaveInServer); // !!! в этих выбраных ролях заасайненые судьи уже сохранены на сервере
    const arrSelectedGO_savedInServer = this.selectedArrGo.filter(el => !!el.officialId_savedInServer); // !!! в этих выбраных ролях заасайненые судьи уже сохранены на сервере
    if (arrSelectedGO_savedInServer?.length) { // !!! удаление ролей с сервера, т.к. эти роли уже сохранены на сервере
      return from(this.popupS.open(PopupComponent, this.gameS.dataPopupForRemoveOfficialFromGO))
        .pipe(
          switchMap((resPopup?: boolean) => {
            if (resPopup) {
              return this.mainS.removeOfficials(arrSelectedGO_savedInServer.map(el => el.id!))
                .pipe(
                  tap((res) => { // !!! ids gameOfficials
                    this.mainS.deleteApiCache(this.mainS.apiOfficials); // !!! очистить кэш после удаления, чтобы заново делать запрос судей с сервера
                    // !!! удалить судью с выбраных ролей
                    const updated_gameOfficials = this.selectedGame?.gameOfficials?.map((currentGo) => {
                      if (res?.includes(currentGo.id!)) { // !!! id который с сервера вернулся => это id роли с которой нужно удалить судью
                        const updated_findGo: ClassGameOfficial = { ...currentGo, official: undefined, status: 'UNASSIGNED' };
                        return updated_findGo;
                      } else {
                        return currentGo;
                      }
                    }) || [];
                    const updated_game: ClassGame = { ...this.selectedGame, gameOfficials: updated_gameOfficials };
                    this.updateGame(updated_game, false);
                  }),
                );
            } else { // !!! в попап окне нажал Cancel
              return of(null);
            }
          }),
        );
    } else {
      return of(null);
    }
  };

  // !!! удаление всех заасайненых судей которые НЕ сохранены на сервере. Тоесть удаление без запроса на сервер. НО в данный момент можно выбирать ТОЛЬКО ОДНУ такую роль
  deleteOfficialsWithoutRequest(): void {
    const exist_selectedArrGo_assigned_and_not_savedInServer = !!this.selectedArrGo_assigned_and_not_savedInServer?.length;
    if (!exist_selectedArrGo_assigned_and_not_savedInServer) return; // !!! проверка есть уже в Html. Здесь эта проверка на всякий случай. Возможно придется в другом месте использовать этот метод удаления
    const updated_gameOfficials: Array<ClassGameOfficial> = this.selectedGame?.gameOfficials?.map((currentGo) => {
      const findGo = this.selectedArrGo_assigned_and_not_savedInServer.find(selectedGo => currentGo.id === selectedGo.id);
      if (findGo) {
        const updated_findGo: ClassGameOfficial = { ...findGo, official: undefined, status: 'UNASSIGNED' };
        return updated_findGo;
      } else {
        return currentGo;
      }
    }) || [];
    const updated_game: ClassGame = { ...this.selectedGame, gameOfficials: updated_gameOfficials };
    this.updateGame(updated_game, false);
  }

  // === OTHER =======================================================
  goToAssign(gameItem: ClassGame): void {
    const goToAssign: IGoToAssign = {
      currentLink_games: this.gameS.currentLinkObj.currentLink?.upperCase || 'current',
      // search: gameItem.gameNumber,
      // gameId: gameItem.id, // !!! нужно выбраную игру зеленым бордером
    };
    if (gameItem.gameNumber) goToAssign.search = gameItem.gameNumber;
    if (gameItem.id) goToAssign.gameId = gameItem.id; // !!! нужно выбраную игру зеленым бордером
    this.router.navigate([urlAssign], { queryParams: goToAssign });
  }

  // !!! for btn 'manage all' on dashboard
  manageAllAndGoToAssign(typeBlockGamesForDashboard: TypeBlockGamesForDashboard): void {
    let settings: IGoToAssign = {};
    switch (typeBlockGamesForDashboard) {
      // !!! для 'games' не надо. Потому что там редирект на страницу игр
      // case 'totalGames':
      //   settings = { gameStatuses: 'ACTIVE', status: 'ACTIVE' };
      //   break;
      case 'pendingAssignments':
        settings = { gameStatuses: 'ACTIVE', status: 'UNACCEPTED', assignStatuses: 'UNACCEPTED,UNPUBLISHED' };
        break;
      case 'declinedAssignments':
        settings = { gameStatuses: 'ACTIVE', status: 'DECLINED', assignStatuses: 'DECLINED' };
        break;
      case 'unassignedRoles':
        settings = { gameStatuses: 'ACTIVE', status: 'UNASSIGNED', assignStatuses: 'UNASSIGNED' };
        break;
    }
    this.router.navigate([urlAssign], { queryParams: settings });
  }

}
