<!--[class]='"o-btn-"+bordRadius+"-"+bcg'-->
<button [disabled]='disabled || spinner' [class.o-cur-default-important]='disabled || spinner || cursorDefault'
        class='o-noWrap {{"o-btn-"+bordRadius+"-"+bcg}} {{textPosition=="center" ? "o-flexCenter":"o-flex-jc-start-important"}}'
        (click)='click()' [matTooltip]='matTooltip'
        [style]='{w:btnWidth, h:btnHeight, fs: fontSize}|getStyles:forTest'>
      <meSvg *ngIf='svgLeft' [svgName]='svgLeft' class='o-mg-r-10' [disabled]='disabled || spinner' [w]='svgLeftWidth'></meSvg>
      {{text}}
      <mat-spinner *ngIf='spinner' diameter='25' strokeWidth='3'></mat-spinner>
      <meSvg *ngIf='svgRight' [svgName]='svgRight' class='{{text ? "o-mg-l-7":""}}' [disabled]='disabled || spinner'
             [w]='svgRightWidth'></meSvg>
</button>