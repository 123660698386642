<div *ngIf='{ desktop: (deviceS.isDesktop$|async), mobile: (deviceS.isMobile$|async), loadingTable: (meTableS.loadingTable$|async), dataTable: (dataTable$|async)!, arrContent: (arrContent$|async) } as obj' class='o-wrapPage o-balances' wrapPage [widthPage]='1280' cdkScrollable dataTable>

  <!--  === FILTERS & TITLE ===========================-->
  <div class='o-header'>
    <div class='o-header__linkPage'>
      <app-dropdown [ngModel]='currentMobileLink' [array]='competitionsArray' (changeValObj)='onCompetitionChange($event)'></app-dropdown>
    </div>
    <div class='o-header__content'>
      <div class='o-header__title'>{{ currentLink.titleCase }}</div>
      <div class='o-header__filters'>
        <app-filters (emit)='emitSettingRequest($event)' [selectedCompetition]="getCompetitionName(settings.competitions)"></app-filters>
      </div>
      <app-filtersSelected></app-filtersSelected>
    </div>
  </div>

  <div class='o-content'>
    <div class='contentTopPaymentsBalances' *ngIf="obj.desktop">
      <div class='contentTopPaymentsBalances__item o-block'>

        <div class="svgAndTextWrapper">
          <span class="o-text5-blueDark">Notch Pay Balance</span>
          <div class="tooltip-container">
            <img src="assets/question/question_grey&16.svg" alt="tooltip">
            <div class="tooltip-content">Available Notch Pay balance to transfer and pay game fees for post game reports approved.</div>
          </div>
        </div>
        <!-- <svgAndText text='Notch Pay Balance' rightSvgName='question_grey&16' typeText='text5-blueDark' marginForRightSvg='auto'></svgAndText> -->
        <div class='contentTopPaymentsBalances__item__info'>
          <div class='contentTopPaymentsBalances__item__info__amount'>{{ (payS.balance$|async)?.current | currency }}</div>
        </div>
      </div>

      <div class='contentTopPaymentsBalances__item o-block'>
        <!-- <svgAndText text='Pending Inflows' rightSvgName='question_grey&16' typeText='text5-blueDark' marginForRightSvg='auto'></svgAndText> -->

        <div class="svgAndTextWrapper">
          <span class="o-text5-blueDark">Pending Inflows</span>
          <div class="tooltip-container">
            <img src="assets/question/question_grey&16.svg" alt="tooltip">
            <div class="tooltip-content">Payments in transit and not yet available in your Notch Pay balance.</div>
          </div>
        </div>

        
        <div class='contentTopPaymentsBalances__item__info'>
          <div class='contentTopPaymentsBalances__item__info__amount'>{{ (payS.balance$|async)?.pending | currency }}</div>
        </div>
      </div>
      <div class='contentTopPaymentsBalances__itemDue o-block'>
        <div class="contentTopPaymentsBalances__textArrow">
          <!-- <svgAndText text='Pending Outflows' rightSvgName='question_grey&16' typeText='text5-blueDark'></svgAndText> -->
          <div class="svgAndTextWrapper">
            <span class="o-text5-blueDark">Pending Outflows</span>
            <div class="tooltip-container">
              <img src="assets/question/question_grey&16.svg" alt="tooltip">
              <div class="tooltip-content">Pending outflows are your projected outgoings for active game assignments not yet started or approved in post game. Go to Reports to view more details.</div>
            </div>
          </div>

          <div class='contentTopPaymentsBalances__item__info__arrows'>
            <meSvg svgName='arrow_squareLeft&24' (click)='toggleProjectedOutgoingsPeriod("left")'></meSvg>
            <meSvg svgName='arrow_squareRight&24' (click)='toggleProjectedOutgoingsPeriod("right")'></meSvg>
          </div>
        </div>
        <div class='contentTopPaymentsBalances__item__info'>
          <div class='contentTopPaymentsBalances__item__info__amount'>{{ ((payS.projectedOutgoings$|async)?.totalFees) | currency:'USD':true }}</div>
          <div class='contentTopPaymentsBalances__item__info__period'>
            <small style="padding-left: 8px;">{{ getCurrentProjectedOutgoingsPeriod() }}</small>
          </div>
        </div>
      </div>



      <div class='contentTopPaymentsBalances__autoTopUp'>
        <div class='contentTopPaymentsBalances__autoTopUp'
          *ngIf='!((!(payS.allMethodsSub$ | async) || !settings.competitions || settings.competitions === "all" || !showAutoTopupSection))'>
          <div  class='contentTopPaymentsBalances__autoTopUp__info o-block'
          (click)='goToBalancesAutoTopup()'>
          <svgAndText text='Auto Top-Up' leftSvgName='arrow_twoCircle&20' typeText='text7-blueDark'></svgAndText>
          <div [ngClass]="{
            'status-button--on': autoTopupStatus === 'On', 
            'status-button--off': autoTopupStatus === 'Off'
        }">
            {{ autoTopupStatus }}
        </div>
        </div>
        <!-- <div *ngIf="!isAllowedUser()" class='tooltip-container-comingSoon' else show this>
          <div class='contentTopPaymentsBalances__autoTopUp__info o-block'
            [ngClass]="{'o-disabledBtn': !(payS.allMethodsSub$ | async) || settings.competitions === 'all'}"
            (click)='payS.openPopupAutoToPup()' style="pointer-events: none;">
            <svgAndText text='Auto Top-Up' leftSvgName='arrow_twoCircle&20' typeText='text7-blueDark'></svgAndText>
          </div>
          <div class='tooltip-content-comingSoon'>Coming Soon <img style="margin-left: 10px" src='assets/vector-lock.svg'>
          </div>
        </div> -->
          <btn text='Add Funds' bcg='newGreen' btnHeight='50' fontSize='16' (emit)='goToBalancesPay()'
            [disabled]='!(payS.allMethodsSub$ | async) || !settings.competitions || settings.competitions === "all"'></btn>
        </div>
      </div>
    </div>
    <app-payment-balances-mobile *ngIf="obj.mobile"></app-payment-balances-mobile>
    <div class='o-header-linksearch'>
      <linkPage [arrLink]="arrPaymentStatusLink" [link]="{ titleCase: getSelectedTitleCase(), upperCase: selectedStatusLink }" (emit)="filterPayments($event.upperCase)"></linkPage>
      <app-dropdown *ngIf="obj.mobile" [ngModel]="currentPaymentStatus" [disableSorting]="true" [array]="arrPaymentStatusLink" (changeValObj)="onPaymentStatusChange($event)"></app-dropdown>
      <div class="searchDownload" style="display: flex;">
        <inputCtrl class="searchBar" style="margin-right: 10px;"  [formControl]="searchControl" placeholder="Search" svgPrefix="search_grey&20" [w]='(deviceS.isDesktop$|async) ? 270:"40%"'></inputCtrl>
        <!-- <meSvg svgName='download_3&44' (click)='null' [disabled]='true' adminPermission></meSvg> -->
      </div>
    </div>

    <div class="transactionsPaymentsBalances">
      <div class="tablePaymentsBalances o-table">
        <div class="o-table__header">
          <div *ngFor="let itemHeader of tableHeader; let idx = index;" class="o-table__header__item widthItem--{{idx+1}}">
            <span>{{ itemHeader.text }}</span>
            <ng-container *ngIf="itemHeader.hoverText">
              <div class="tooltip-container">
                <svgAndText [text]="''" rightSvgName="question_grey&16" typeText="text5-blueDark" marginForRightSvg="auto">
                </svgAndText>
                <div class="tooltip-content">{{ itemHeader.hoverText }}</div>
              </div>
            </ng-container>
          </div>
        </div>
        <!-- <div *ngIf="isLoading | async" class="spinner-container">
          <spinner></spinner>
        </div> -->
        <ng-container *ngIf="!(isLoading | async) || !obj.desktop">
          <div *ngIf="(payS.payments$ | async)?.length; else nothingList" class="o-table__list">
            <ng-container *ngFor="let itemPayment of (arrContent$ | async); let idx = index">
              <div class="o-table__list__item">
                <div class="widthItem--1">
                  <span *ngIf="obj.desktop">{{ itemPayment.type }}</span>
                  <span *ngIf="obj.mobile">{{ itemPayment.initiatorName }}</span>
                  <span *ngIf="obj.mobile">{{ itemPayment.total_formatted | currency }} </span>
                </div>
                <div class="widthItem--2" [class.o-colorGreen]="itemPayment.total?.toString()?.includes('+')"
                  [class.o-colorRed]="itemPayment.total?.toString()?.includes('-')">
                  <span *ngIf="obj.desktop">{{ itemPayment.total_formatted | currency }}</span>
                  <span *ngIf="obj.mobile">{{ itemPayment.competitionName }}</span>
                </div>
                <div class="widthItem--3">
                  <span *ngIf="obj.desktop">{{ itemPayment.fee_formatted | currency }}</span>
                  <div *ngIf="obj.mobile">
                    <span>{{ itemPayment.createdAt | date:'MMM d, yyyy' }}</span> &nbsp;
                    <span>{{ itemPayment.createdAt | date:'shortTime' }}</span>
                  </div>
                  <div *ngIf="obj.mobile"><small>{{ itemPayment.reference }}</small></div>

                </div>
                <div class="widthItem--4">
                  <div *ngIf="obj.desktop" class="widthItem--4__main">{{ itemPayment.initiatorName }}</div>
                  <div *ngIf="obj.desktop">{{ itemPayment.competitionName }}</div>
                </div>
                <div class="widthItem--5">
                  <!-- <status [status]="itemPayment.status ? itemPayment.status : ''"></status> -->
                  <span   class="clickable-status incomplete-status" *ngIf="itemPayment.status === 'INCOMPLETE'" (click)="handlePaymentStatusClick(itemPayment)" class="clickable-status">
                    <status [status]="itemPayment.status"></status>
                  </span>
                  <status *ngIf="itemPayment.status !== 'INCOMPLETE'" [status]="itemPayment.status"></status>
                </div>
                <div class="widthItem--6" *ngIf="obj.desktop">
                  <div>
                    <span>{{ itemPayment.createdAt | date:'MMM d, yyyy' }}</span> &nbsp;
                    <span>{{ itemPayment.createdAt | date:'shortTime' }}</span>
                  </div>
                  <div><small>{{ itemPayment.reference }}</small></div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <div *ngIf="isLoading | async" class="spinner-container">
          <spinner></spinner>
        </div>
      </div>
    </div>
    

    <paginationWithSettings *ngIf="meTableS.showPagination" (updatePage)="paginationMethod($event)"></paginationWithSettings>
    <div  *ngIf="obj.mobile" #sentinel></div>
    
    <app-tableInfiniteLoading *ngIf="meTableS.showPagination"></app-tableInfiniteLoading>



  </div>
</div>


<ng-template #spinner>
  <spinner></spinner>
</ng-template>

<ng-template #nothingList>
  <nothingList [tableEmpty]='filtersS.searchValue | getTableEmpty'></nothingList>
</ng-template>

<!-- <ng-template #pagination>
  <pagination *ngIf='(payS.payments$ | async)?.length' [data]='(payS.dataPayments$ | async)!' (updatePage)='paginationMethod("updatePage", undefined, $event)' (updateResults)='paginationMethod("updateCountResults", $event)'></pagination>
</ng-template> -->
