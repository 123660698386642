import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceService } from '@services/device.service';
import { ClassHeight, ClassWidth } from '@models/ICssStyles';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface ISwitcherBtn {
  btn1: boolean;
  btn2: boolean;
}

@UntilDestroy()
@Component({
  selector: 'switcherBtn[text1][text2]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './switcher-btn.component.html',
  styleUrls: ['./switcher-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitcherBtnComponent implements OnChanges, OnInit {
  @Input() text1!: string;
  @Input() text2!: string;

  @Input() width: number | null = null;
  @Input() height: number = 44;
  // @Input() heightForMobile: number | null = null; // !!! если высота кнопок для desktop и для мобилы отличается то передать сюда значение
  style = {};
  // classWidth?: ClassWidth;
  // classHeight?: ClassHeight;

  btnActive: ISwitcherBtn = { btn1: true, btn2: false };

  @Output() emit = new EventEmitter<ISwitcherBtn>();

  constructor(
    public deviceS: DeviceService,
    public cd: ChangeDetectorRef,
  ) {
    this.subscribeToDevice();
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes?.width?.currentValue) {
    // this.style = { ...this.style, ...new ClassWidth(this.width!) };
    // }
    // if (changes?.height?.currentValue) {
    // this.style = { ...this.style, ...new ClassHeight(this.height!) };
    // }

    if (changes?.width?.currentValue || changes?.height?.currentValue) {
      this.setWidthHeight();
    }
  }

  ngOnInit() {
    // this.style = { ...this.style, ...new ClassHeight(this.height!) };
    this.setWidthHeight();
  }

  subscribeToDevice(): void {
    this.deviceS.isDesktop$.pipe(untilDestroyed(this)).subscribe((isDesktop) => {
      this.setWidthHeight();
      // if (this.width && isDesktop) {
      //   this.style = { ...this.style, ...new ClassWidth(this.width) };
      // }
      // if (!isDesktop) {
      //   this.style = { ...this.style, ...new ClassWidth('100%') };
      // }
      // this.style = { ...this.style, ...new ClassHeight(this.height) };
    });
  }

  changeActiveBtn(type: 'btn1' | 'btn2'): void {
    if ((type == 'btn1' && this.btnActive.btn1) || (type == 'btn2' && this.btnActive.btn2)) return; // если на активной нажал
    this.btnActive.btn1 = !this.btnActive.btn1;
    this.btnActive.btn2 = !this.btnActive.btn2;
    this.emit.emit(this.btnActive);
  }

  private setWidthHeight(): void {
    if (this.width && this.deviceS.isDesktop) {
      this.style = { ...this.style, ...new ClassWidth(this.width) };
    }
    if (!this.deviceS.isDesktop) {
      this.style = { ...this.style, ...new ClassWidth('100%') };
    }
    this.style = { ...this.style, ...new ClassHeight(this.height) };
  }

}
