<div class="show-only-on-desktop">
  <div class='o-wrapPage o-wrapPage--noMarginTopAndNoPadding' wrapPage [widthPage]='1200'>
    <div class='wrapperTab'>
      <ng-container *ngFor='let tab of tabs'>

        <div class="itemTab" [class.wrapperTab--activeItem]='(tab === selectedTab)' (click)='onClickItemTab(tab)'>
          {{tab.tabName}}
        </div>
      </ng-container>
    </div>
    <!--  === FILTERS & TITLE ===========================-->
    <div class='o-header'>
      <div class='o-header__linkPage'>
        <app-filtersSelectedIcon></app-filtersSelectedIcon>
      </div>
      <div class='o-header__content'>
        <div class='o-header__title'>Reports</div>
        <div class='o-header__filters'>
          <app-filters></app-filters>
        </div>
        <app-filtersSelected></app-filtersSelected>
      </div>
    </div>

    <div class='o-content'>

      <react-wrapper [embeddableId]="selectedTab.embeddableId" [isLoading]="!filtersReady"
        [variables]="variables"></react-wrapper>
    </div>
  </div>
</div>
<coming-soon-mobile class="show-only-on-mobile"></coming-soon-mobile>