<div *ngIf='{desktop: (deviceS.isDesktop$|async), mobile: (deviceS.isMobile$|async)} as obj' class='card g-flex-column'>
  <div class='card-top g-flex g-flex--align-start'>
    <app-card-logo [height]='44' [cardBrand]='account[ACCOUNT_EXTERNAL_FIELDS.TYPE]'></app-card-logo>

    <div class='card-details'>
      <div class='card-details__type o-text7 g-flex g-flex--align-center'>
        <div class='o-text-capitalize'>{{ account[ACCOUNT_EXTERNAL_FIELDS.TITLE] }}</div>
        <div>ending in {{ account[ACCOUNT_EXTERNAL_FIELDS.LAST4] }}</div>
      </div>


      <span *ngIf='account[ACCOUNT_EXTERNAL_FIELDS.METHOD] === ACCOUNT_METHODS.INSTANT' class='o-text8'>
        Expiry {{ account[ACCOUNT_EXTERNAL_FIELDS.EXP_MONTH] }}/{{ account[ACCOUNT_EXTERNAL_FIELDS.EXP_YEAR] }}
      </span>

      <div *ngIf="mode !== 'payout' && !meS.GROUP_ASSIGNOR" class='card-details-actions g-flex g-flex--align-center'>
        <ng-container *ngIf="mode === 'edit'; else viewModeLabelTpl">
          <span class='o-text7 o-colorBlueDark' [class.disabled]='isDisabled'
                (click)='account[ACCOUNT_EXTERNAL_FIELDS.DEFAULT] ? null : setDefaultPayment(account)'>
            {{ account[ACCOUNT_EXTERNAL_FIELDS.DEFAULT] ? 'Default' : 'Set as default' }}
          </span>
          <span class='o-text7 o-colorRed' [class.disabled]='isDisabled' (click)='removePayment(account)'>Remove</span>
        </ng-container>

        <ng-template #viewModeLabelTpl><span class='o-text7'>Default</span></ng-template>
      </div>

      <div *ngIf='meS.GROUP_ASSIGNOR' class='card-details-actions g-flex g-flex--align-center'>
        <ng-container>
          <!-- <span class='o-text7 o-colorBlueDark' [class.disabled]='isDisabled' style="color: #98A2B3;"
                (click)='account[ACCOUNT_EXTERNAL_FIELDS.DEFAULT] ? null : setDefaultPayment(account)'>
            {{ account[ACCOUNT_EXTERNAL_FIELDS.DEFAULT] ? 'Default' : 'Set as default' }}
          </span> -->
          <span class='o-text7' [ngClass]="{'o-colorBlueDark': account[ACCOUNT_EXTERNAL_FIELDS.DEFAULT]}"
            [ngStyle]="!account[ACCOUNT_EXTERNAL_FIELDS.DEFAULT] ? {'color': '#98A2B3'} : {}" [class.disabled]="isDisabled"
            (click)="account[ACCOUNT_EXTERNAL_FIELDS.DEFAULT] ? null : setDefaultPayment(account)">
            {{ account[ACCOUNT_EXTERNAL_FIELDS.DEFAULT] ? 'Default' : 'Set as default' }}
          </span>
          <span class='o-text7' style="color: #98A2B3;"> Edit </span>
          <span class='o-text7 o-colorRed' [class.disabled]='isDisabled' (click)='removePayment(account)'>Remove</span>
        </ng-container>

        <ng-template #viewModeLabelTpl><span class='o-text7'>Default</span></ng-template>
      </div>
    </div>
  </div>

</div>
<div class='buttons'>
  <ng-container [ngSwitch]='mode'>
    <ng-template *ngIf='deviceS.isDesktop$|async' ngSwitchCase='view'>
      <btn text='Edit' bcg='white' [btnWidth]='58' [disabled]='isDisabled' (emit)='onEdit()'></btn>
      <!--      <meSvg svgName="points"></meSvg>-->
    </ng-template>
    <!--    <ng-template ngSwitchCase="payout"><chx [formControl]="checkboxControl" (changeVal)='chxChange($event)'></chx></ng-template>-->
    <ng-template ngSwitchCase='payout'>
      <meSvg [svgName]='checkboxControl.value ? "chx_true&20" : "chx_false&20"'></meSvg>
    </ng-template>
    <!--<ng-template ngSwitchDefault>
      <meSvg
        svgName="arrow_right&20"
        (click)="goToNext()"></meSvg>
    </ng-template>-->
  </ng-container>
</div>
