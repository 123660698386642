import { Pipe, PipeTransform } from '@angular/core';
import { ClassGame } from '@app/dir_group_assignor/games/game';
import { UtilsService } from '@services/utils.service';

@Pipe({ name: 'isPossibleEditFee', standalone: true })
export class IsPossibleEditFeePipe implements PipeTransform {

  // !!! разрешено ли менять fee
  transform(game?: ClassGame): boolean {
    return UtilsService.isPossibleEditFee(game);
  }

}
