import { Pipe, PipeTransform } from '@angular/core';
import { OtherService } from '@services/other.service';
import { ClassGame } from '@app/dir_group_assignor/games/game';

@Pipe({ name: 'getLocNameCourtName', standalone: true })
export class GetLocNameCourtNamePipe implements PipeTransform {

  constructor(
    private otherS: OtherService,
  ) {
  }

  transform(game?: ClassGame): string {
    return this.otherS.getLocNameCourtName(game);
  }

}
