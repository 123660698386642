import { Injectable } from "@angular/core";
import { formatDate } from '@angular/common';


@Injectable({ providedIn: 'root' })
export class GlobasConst {
    public get dateTimeFormat(): string {
        return 'MMM d, y, h:mm a';
    }
    public get dateFormat(): string {
        return 'MMM d, y';
    }

    public formatDateFromServer(dateString: string | undefined): string {
        if (!dateString) return '';
    
        // Extract the date part from the input string
        const dateParts = dateString.split(' ');
        if (dateParts.length < 4) return dateString; 
    
        const day = dateParts[0]; // e.g., '3'
        const month = dateParts[1]; // e.g., 'Jan'
        const year = dateParts[2]; // e.g., '2025'
    
        // Reformat to 'Jan 3, 2025'
        return `${month} ${parseInt(day, 10)}, ${year}`;
    }
    
    public get timeFormat(): string {
        return 'h:mm a';
    }

    public get moneyFormat(): any {
        return {
            currencyFormat: 'USD',
            currencyDisplay: 'symbol',
            currencyPrecision: '1.2-2',
        }
    }


    public formatDateForServer(dateString: string | undefined): string {
        if (!dateString) return '';
    
        // ✅ Expected input: "9 Mar 2025 6:05 PM EDT"
        const dateParts = dateString.split(' ');
    
        if (dateParts.length < 5) return dateString; // Ensure we have enough parts
    
        const [day, month, year, time, period] = dateParts;
    
        // ✅ Convert month name to number
        const monthNumber = ("Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec".split(" ").indexOf(month) + 1)
            .toString().padStart(2, "0");
    
        // ✅ Convert time to 24-hour format
        let [hours, minutes] = time.split(":").map(Number);
        if (period === "PM" && hours !== 12) hours += 12;
        if (period === "AM" && hours === 12) hours = 0;
        const time24 = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
    
        // ✅ Format to "YYYY-MM-DDTHH:mm:ss"
        return `${year}-${monthNumber}-${day.padStart(2, "0")}T${time24}:00.000`;
    }

    
    public formatToEST(dateInput: string | Date | undefined): string {
        if (!dateInput) return '';
      
        let date: Date;
      
        if (typeof dateInput === 'string') {
          // Remove microseconds (".845882") — optional
          const cleaned = dateInput.replace(/\.(\d+)\s/, ' '); // e.g., "31 Mar 2025 5:52:56 PM -07:00"
          date = new Date(cleaned);
        } else {
          date = dateInput;
        }
      
        if (isNaN(date.getTime())) return '';
      
        // ✅ Convert to New York time using Intl
        const formatter = new Intl.DateTimeFormat('en-US', {
          timeZone: 'America/New_York',
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
        });
      
        return formatter.format(date);
      }
      
}