import { Pipe, PipeTransform } from '@angular/core';
import { ClassGame } from '@app/dir_group_assignor/games/game';
import { UtilsService } from '@services/utils.service';

@Pipe({ name: 'isPossibleGameToDelete', standalone: true })
export class IsPossibleGameToDeletePipe implements PipeTransform {

  // !!! разрешено ли удалить игру
  transform(game: ClassGame): boolean {
    return UtilsService.isPossibleGameToDelete(game);
  }

}
