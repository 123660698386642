<div class="competitionListMain">
  <ng-container *ngIf="showCompetitionsSelectedPage; else mainPageContent">
    <app-competitions-selected-page
                                    [selectedCompetitions]="selectedCompetitions"
                                    (back)="returnToListPage()">
    </app-competitions-selected-page>
  </ng-container>

  <ng-template #mainPageContent>
    <ng-container *ngIf='(deviceS.isDesktop$ | async) as obj'>
      <div *ngIf='{desktop: (deviceS.isDesktop$|async),
               mobile: (deviceS.isMobile$|async),
               dataTable: (dataTable$|async)!,
               arrContent: (arrContent$|async),
               loadingTable: (meTableS.loadingTable$|async),
               } as obj' class='o-wrapPage' wrapPage [widthPage]='widthPage' cdkScrollable dataTable>
        <div class='o-header o-header--noHaveLinkPage'>
          <linkPage-searchFilter [haveLinkPage]='false'></linkPage-searchFilter>
          <div class='o-header__content'>
            <div class='o-header__title'>Competitions</div>
            <div class='o-header__filters'>
              <app-filters></app-filters>
              <div class='o-header__actions'>
                <ng-container *ngIf="meTableS.selectedItems.length > 0; else newCompetitionButton">
                  <!-- "Go" Button -->
                  <btn
                       text="Invite User"
                       bcg="newGreen"
                       (emit)="goToCompetitionButton()"
                       [btnWidth]="113">
                  </btn>
                </ng-container>

                <ng-template #newCompetitionButton>
                  <btn
                       [disabled]="isReadOnly"
                       text="New Competition +"
                       bcg="newGreen"
                       (emit)="competitionS.reset(); router.navigate([urlSetupCompetition])"
                       [btnWidth]="178">
                  </btn>
                </ng-template>
              </div>

            </div>
            <app-filtersSelected></app-filtersSelected>
          </div>
        </div>
        <div class='o-content t-wrapperTable'>
          <tableHeader></tableHeader>
          <div class='t-table'>
            <ng-container *ngFor='let item of obj.arrContent; let idx=index'>
              <div class='t-table__item' itemTable [item]='item'>
                <!-- [hiddenChx]='false' -->
                <app-tableCeilChx *ngIf='meS.GROUP_ASSIGNOR' [item]='item' [text]='item.competitionName'></app-tableCeilChx>
                <app-tableCeilChx *ngIf='!meS.GROUP_ASSIGNOR' [item]='item' [text]='item.competitionName' [hiddenChx]='true'></app-tableCeilChx>
                <!--            <app-tableCeil [idxCeil]='0' [text1]='item.competitionName'></app-tableCeil>-->
                <app-tableCeil [idxCeil]='1' [text1]='item.teamsSize || 0'></app-tableCeil>
                <app-tableCeil [idxCeil]='2' [text1]='item.locationsSize || 0'></app-tableCeil>
                <app-tableCeil [idxCeil]='3' [text1]='item.subAssignors?.[0]'>
                  <div *ngIf='item.subAssignors && item.subAssignors!.length > 1' class='ngContent_row amountUsersInCompetition'>
                    <!-- +{{ item.subAssignors!.length - 1 }} -->
                    <span
                          *ngIf="item.subAssignors && item.subAssignors.length > 1"
                          class="tooltip-container"
                          matTooltip="{{ item.subAssignors.join('\n') }}"
                          matTooltipClass="custom-tooltip"
                          [matTooltipShowDelay]="300">
                      +{{ item.subAssignors.length - 1 }}
                    </span>
                  </div>
                </app-tableCeil>
                <app-tableCeil [idxCeil]='4' [text1]='item.admins?.[0]'>
                  <div *ngIf='item.admins && item.admins!.length > 1' class='ngContent_row amountUsersInCompetition'>
                    <span
                          *ngIf="item.admins && item.admins.length > 1"
                          class="tooltip-container"
                          matTooltip="{{ item.admins.join('\n') }}"
                          matTooltipClass="custom-tooltip"
                          [matTooltipShowDelay]="300">
                      +{{ item.admins!.length - 1 }}
                    </span>
                  </div>
                </app-tableCeil>
                <div class='t-header__ceil btnTableForOpenGames' [class.o-bord-none]='obj.mobile' ceilTable [idxCeil]='5'>
                  <btn text='View' bcg='white' btnHeight='36' btnWidth='87' (emit)='goToCompetition(item)'></btn>
                </div>
              </div>
            </ng-container>
            <app-tableEmpty [tableEmpty]='filtersS.searchValue|getTableEmpty'>
              <btn bcg='newGreen' text='New Competition +' [btnHeight]='deviceS.btnHeightForNothingList'
                   (emit)='router.navigate([urlSetupCompetition])' class='class_ngContent_btn_for_nothingList' adminPermission></btn>
            </app-tableEmpty>
          </div>
        </div>
        <ng-container>
        </ng-container>

        <app-tableInfiniteLoading></app-tableInfiniteLoading>
        <paginationWithSettings *ngIf='meTableS.showPagination'></paginationWithSettings>
      </div>
    </ng-container>
    <coming-soon-mobile *ngIf='(deviceS.isMobile$ | async) as obj'></coming-soon-mobile>
  </ng-template>
</div>