import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadTitleComponent } from '../../head-title/head-title.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BtnComponent } from '../../btn/btn.component';
import { AccountPaymentComponent } from '@components/account-payment/account-payment.component';
import { FieldComponent } from '../../__drop_inputs_matSelect/field/field.component';
import { DropFormCtrlComponent } from '../../__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { SelectDateComponent } from '../../date-time/select-date/select-date.component';
import { SvgComponent } from '../../__svg_img/svg/svg.component';
import { ClassCompetition } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { AddressCourtNameComponent } from '../../__drop_inputs_matSelect/address-court-name/address-court-name.component';
import { ChxComponent } from '../../chx/chx.component';
import { PopupService } from '@services/popup.service';
import { PopupRecepients } from '../popup-recepients/popup-recepients.component';
import { PopupAnnouncementGeneralService } from './popup-new-announcement-general.service';
import { AnnouncementItem } from '@app/dir_group_assignor/announcements/models/announcements';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { ClassDrop, getArrayDropFromString } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { ForTestService } from '@classes/forTest';
import { IFormAnnouncement, IOfficialListItem } from '@components/__popup-windows/popup-new-announcement-general/models/form-models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { SkeletonComponent } from "../../skeleton/skeleton.component";
import { DeviceService } from '@services/device.service';

// ToDO переработать полностью надо
@UntilDestroy()
@Component({
  selector: 'app-popup-new-announcement-general',
  standalone: true,
  templateUrl: './popup-new-announcement-general.component.html',
  styleUrls: ['./popup-new-announcement-general.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, HeadTitleComponent, BtnComponent, AccountPaymentComponent, FieldComponent, DropFormCtrlComponent, SelectDateComponent, SvgComponent, ReactiveFormsModule, InputCtrlComponent, AddressCourtNameComponent, ChxComponent, DropdownComponent, SpinnerComponent, SkeletonComponent],
  providers: [PopupAnnouncementGeneralService],
})
export class PopupNewAnnouncementGeneralComponent implements OnInit {
  form!: FormGroup<IFormAnnouncement>;
  // Грант замени плиз statusList на statusListDrop
  statusList: string[] = ['Available', 'Unavailable', 'Availability Unknown'];
  statusListDrop: Array<ClassDrop> = getArrayDropFromString(['Available', 'Unavailable', 'Availability Unknown'], 'PopupNewAnnouncementGeneralComponent statusListDrop');
  // competitionsList: string[] = ['All Competitions', 'Lakeville Fall Cup', 'Minnesota NPL', 'Minnesota 2023'];
  competitionsListDrop: Array<ClassCompetition> = [new ClassDrop({
    titleCase: 'All Competitions',
    upperCase: 'All Competitions',
  }), this.forTestS.fakeCompetition, this.forTestS.fakeCompetition];
  officialLists!: IOfficialListItem[];
  isSendDisable = true;
  isSendAsDraftDisable = true;

  sendToList: string[] = ['All Officials', 'All Users', 'Certified Only'];
  sendToListObj: Array<ClassDrop> = [];
  files: any[] = [];
  temporaryExcludedEmails: string[] = [];

  typeAnnouncement: string = '';
  messageMaxLength = 5000;

  excludedEmails: string[] = [];
  byteArray: Uint8Array = new Uint8Array([]);
  removedFileIds: string[] = [];
  isCertifiedOnly = false;
  isUrgentDisable: boolean = false;
  currentOptionInDropDown: string = '';
  isLoadingContacts: boolean = false;
  textAreaMaxSize = 500;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dataPopup: {
      typeAnnouncement: string;
      announcementItem: AnnouncementItem;
      gameIds: string[];
      height: number;
    },
    public dialogRef: MatDialogRef<PopupNewAnnouncementGeneralComponent>,
    private formBuilder: UntypedFormBuilder,
    public popupAnnouncementGeneralService: PopupAnnouncementGeneralService,
    private popupS: PopupService,
    private forTestS: ForTestService,
    public cdr: ChangeDetectorRef,
    public deviceS: DeviceService,
  ) {
    this.typeAnnouncement = dataPopup.typeAnnouncement;
    this.createForm();
    if (dataPopup.announcementItem?.attachments?.length) {
      dataPopup.announcementItem.attachments.forEach((el: any) => this.convertBytesToFile(el.bytes, el));
    }
    this.textAreaMaxSize = parseInt(dataPopup.height.toString()) - 446;
  }

  isUrgent = false;
  isEmail = true;
  isSms = false;

  messageTextLength = 0;

  ngOnInit(): void {

    if (this.dataPopup?.announcementItem?.recipientGroup) {
      let recipientGroup = {};

      switch (this.dataPopup?.announcementItem?.recipientGroup?.titleCase || this.dataPopup?.announcementItem?.recipientGroup) {
        case 'ALL_CERTIFIED_OFFICIALS':
        case 'Certified Only':
          recipientGroup = {
            titleCase: 'Certified Only',
            upperCase: 'CERTIFIED ONLY',
          };
          break;
        case 'ALL_USERS':
        case 'ALL_USERS':
          recipientGroup = {
            titleCase: 'All Users',
            upperCase: 'ALL USERS',
          };
          break;
        case 'ALL_OFFICIALS':
        case 'All Officials':
          recipientGroup = {
            titleCase: 'All Officials',
            upperCase: 'ALL OFFICIALS',
          };
          break;
        default:
          // Handle the case where the value does not match any of the specified cases
          break;
      }

      this.dataPopup.announcementItem.recipientGroup = recipientGroup;
    }

    this.createForm(this.dataPopup as unknown as UntypedFormBuilder);
    // ToDo надо полностью пересмотреть построение формы, любые изменения вели к утечки памяти
    this.form.get('urgent')?.valueChanges.pipe(untilDestroyed(this)).subscribe(urgent => this.handleCheckboxChange('urgent', urgent));
    this.form.get('email')?.valueChanges.pipe(untilDestroyed(this)).subscribe(email => this.handleCheckboxChange('email', email));
    this.form.get('sms')?.valueChanges.pipe(untilDestroyed(this)).subscribe(sms => this.handleCheckboxChange('sms', sms));
    //  ToDo ну тут по другому уже никак надо переписывать срочно компонент
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      if ((this.isEmail || this.isSms) && value.text != '') {
        this.isSendDisable = this.checkIsDisableSend(this.form.value as AnnouncementItem);
      } else {
        this.isSendDisable = true;
      }
    });
    this.initializeSendToListObj();
    this.isCertifiedOnly = this.dataPopup?.announcementItem?.recipientGroup?.titleCase === 'Certified Only';
    if (this.dataPopup.gameIds) {
      this.getAllContactsByGameIds(this.dataPopup.gameIds);
    } else {
      this.getAllContacts(this.isCertifiedOnly);
    }
  }

  private handleCheckboxChange(checkboxName: 'urgent' | 'email' | 'sms', value: boolean): void {
    if (value) {
      if (checkboxName === 'sms') {
        const otherCheckboxes = ['urgent', 'email', 'sms'].filter(name => name !== checkboxName);
        otherCheckboxes.forEach(name => this.form.get(name)?.setValue(false, { emitEvent: false }));
        this.isUrgentDisable = true;
      } else {
        this.isUrgentDisable = false;
        this.form.get('sms')?.setValue(false, { emitEvent: false });
      }
      switch (checkboxName) {
        case 'urgent':
          this.isUrgent = true;
          break;
        case 'email':
          this.isEmail = true;
          break;
        case 'sms':
          this.isSms = true;
          break;
      }
    } else {
      switch (checkboxName) {
        case 'urgent':
          this.isUrgent = false;
          break;
        case 'email':
          this.isEmail = false;
          this.isUrgentDisable = !value;
          this.form.get('urgent')?.setValue(false, { emitEvent: false });
          break;
        case 'sms':
          this.isSms = false;
          break;
      }
    }
    if (checkboxName !== 'urgent') {
      this.isSendDisable = this.checkIsDisableSend(this.form.value as AnnouncementItem);
      this.checkIsDisableSendAsDraft(this.form.value as AnnouncementItem);
    }
    this.cdr.detectChanges();
  }

  close(status: string): void {
    this.form.patchValue({ status });
    if (status === 'DRAFT') {
      this.form.patchValue({ draft: true });
    }
    if (status === 'SENT') {
      this.form.patchValue({ draft: false });
    }
    if (!this.form.get('excludeEmails')?.value) {
      this.form.patchValue({ excludeEmails: [] });
    }
    if (!this.form.get('officialListIds')?.value) {
      this.form.patchValue({ officialListIds: [] });
    }

    this.form.patchValue({ excludeEmails: this.excludedEmails as [] });

    this.form.patchValue({ recipientGroup: this.form.value?.recipientGroup?.titleCase });

    if (status) {
      this.dialogRef.close(this.form);
    } else {
      this.dialogRef.close(null);
    }
  }

  createForm(formData: any = null): void {
    if (formData && formData.announcementItem) {
      this.patchFormDataToForm(formData.announcementItem);
    } else {
      this.initializeEmptyForm(formData);
    }
  }

  private initializeSendToListObj() {
    // const announcementTypes = ['ALL_OFFICIALS', 'ALL_USERS', 'ALL_CERTIFIED_OFFICIALS'];
    // const requests = announcementTypes.map(type => this.popupAnnouncementGeneralService.getCount(type));

    // forkJoin(requests).pipe(untilDestroyed(this)).subscribe(responses => {
    this.sendToListObj = [
      {
        titleCase: 'All Officials',
        upperCase: 'ALL OFFICIALS',
      },
      {
        titleCase: 'All Users',
        upperCase: 'ALL USERS',
      },
      {
        titleCase: 'Certified Only',
        upperCase: 'CERTIFIED ONLY',
      },
    ];
    // responses.forEach((res, index) => {
    //   this.sendToListObj[index]['count'] = res;
    // });
    this.cdr.detectChanges();
    // });
  }

  private checkIsDisableSendAsDraft(announcementItem: AnnouncementItem) {
    announcementItem?.recipientGroup !== '' || this.dataPopup.typeAnnouncement === 'BY_GAMES'
      ? this.isSendAsDraftDisable = false
      : this.isSendAsDraftDisable = true;
  }

  private checkIsDisableSend(announcementItem: AnnouncementItem): boolean {
    return !((announcementItem?.recipientGroup?.titleCase && announcementItem?.text) || this.dataPopup.typeAnnouncement === 'BY_GAMES') || (!this.isSms && !this.isEmail);
  }

  private patchFormDataToForm(announcementItem: any): void {
    if (announcementItem.text) {
      announcementItem.text = announcementItem.text.replace(/<br>/g, '\n');
    }

    announcementItem.recipientGroup = announcementItem?.recipientGroup;

    this.form.patchValue({ ...announcementItem });

    this.checkIsDisableSendAsDraft(announcementItem);
    this.isSendDisable = this.checkIsDisableSend(announcementItem);
  }

  private initializeEmptyForm(formData: any): void {
    this.form = this.formBuilder.group({
      id: formData?.id || '',
      userId: formData?.userId || '',
      files: new FormControl<File[]>([]),
      status: '',
      subject: '',
      competition: '',
      recipientGroup: formData?.recipientGroup?.titleCase || '',
      message: '',
      text: '',
      urgent: false,
      email: this.deviceS.isDesktop,
      emails: [],
      sms: !this.deviceS.isDesktop,
      draft: false,
      type: this.typeAnnouncement.toUpperCase(),
      officialListIds: [],
      excludeEmails: formData?.excludedEmails || [],
      attachments: formData?.attachments || [],
      attachmentRemove: [],
      isDrafted: formData?.isDrafted || false,
    });
  }

  changeVal(ev: any) {
    if (this.dataPopup?.announcementItem?.excludeEmails) {
      this.dataPopup.announcementItem.excludeEmails = [];
    }
    this.currentOptionInDropDown = ev.titleCase;
    if (ev.titleCase === 'Certified Only') {
      this.getAllContacts(true);
    } else {
      this.getAllContacts(false);
    }
  }

  getAllContacts(certifiedOnly: boolean = false): void {
    if (this.dataPopup?.announcementItem?.type === 'BY_GAMES' && this.dataPopup?.announcementItem?.gameIds?.length) {

      this.popupAnnouncementGeneralService.getAllContactsByGameIds(this.dataPopup.announcementItem.gameIds).pipe(untilDestroyed(this)).subscribe(
        officialLists => {
          const emails = officialLists.map((list: IOfficialListItem) => list.email);
          this.officialLists = this.filterExcludedEmails(officialLists);
          this.isSendAsDraftDisable = !this.officialLists.length;

          if (this.officialLists.length === 0) {
            this.officialLists = officialLists;
          } else {
            this.isSendDisable = !((this.form.value.recipientGroup && this.form.value.text) || this.dataPopup.announcementItem.type === 'BY_GAMES') || (!this.isSms && !this.isEmail);
            this.isSendAsDraftDisable = !(this.form.value.recipientGroup || this.dataPopup.announcementItem.type === 'BY_GAMES');
          }
          this.cdr.detectChanges();
        },
        error => {
          console.error('Error fetching game-specific contacts:', error);
        }
      );
    } else {
      this.popupAnnouncementGeneralService.getAllContact({ certifiedOnly }).pipe(untilDestroyed(this)).subscribe(
        officialLists => {
          const emails = officialLists.map((list: IOfficialListItem) => list.email);
          this.officialLists = this.filterExcludedEmails(officialLists);
          if (this.officialLists.length === 0) {
            this.officialLists = officialLists;
          }
          this.cdr.detectChanges();
        },
        error => {
          console.error('Error fetching regular contacts:', error);
        }
      );
    }
  }

  getAllContactsByGameIds(gameIds: string[]) {
    this.isLoadingContacts = true;
    this.popupAnnouncementGeneralService.getAllContactsByGameIds(gameIds).pipe(untilDestroyed(this)).subscribe(
      officialLists => {
        const emails = officialLists.map((list: IOfficialListItem) => list.email);
        this.officialLists = this.filterExcludedEmails(officialLists);
        this.isSendAsDraftDisable = !this.officialLists.length;

        if (this.officialLists.length === 0) {
          this.officialLists = officialLists;
        }
        this.isLoadingContacts = false;
        this.cdr.detectChanges();
      },
      error => {
        this.isLoadingContacts = false;
      }
    );
  }

  private filterExcludedEmails(officialLists: IOfficialListItem[]): IOfficialListItem[] {
    const excludedEmails: string[] | undefined = this.dataPopup?.announcementItem?.excludeEmails;
    this.excludedEmails = excludedEmails || [];

    if (excludedEmails && excludedEmails.length > 0 && officialLists?.length > 0) {
      officialLists = officialLists.filter((item: IOfficialListItem) => !excludedEmails.includes(item.email!));
      return officialLists;
    }

    return officialLists;
  }

  removeEmailFromList(email: string) {
    if (this.officialLists?.length) {
      this.officialLists = this.officialLists.filter((item: IOfficialListItem) => item.email !== email);
      this.excludedEmails.push(email);
      this.temporaryExcludedEmails.push(email);

      if (this.officialLists.length === 0) {
        this.isSendDisable = true;
        this.isSendAsDraftDisable = true;
      } else {
        this.isSendDisable = !((this.form.value.recipientGroup && this.form.value.text) || this.dataPopup.announcementItem.type === 'BY_GAMES') || (!this.isSms && !this.isEmail);
        this.isSendAsDraftDisable = !(this.form.value.recipientGroup || this.dataPopup.announcementItem.type === 'BY_GAMES');
      }
      this.cdr.detectChanges();
    }
  }

  get showRecipients(): boolean {
    if (this.typeAnnouncement === 'general') {
      if (this.dataPopup?.announcementItem?.type === 'BY_GAMES') {
        return true;
      } else {
        const value = this.form.get('recipientGroup')?.value as any;
        const recipientGroup = value?.titleCase;

        return !!recipientGroup;
      }
    } else {
      return true;
    }
  }

  openRecepientsPopup() {
    this.popupS.open(PopupRecepients, { width: '640px', officialsLists: this.officialLists as [] }).then((res: any) => {
      if (!res) return;

      this.excludedEmails = res.excludeEmails;
      this.form.patchValue({ excludeEmails: res.excludeEmails || [] });
      this.officialLists = res?.recipients || [];
      this.cdr.detectChanges();
    });
  }

  getMessage(text: string) {
    this.messageTextLength = text.length;

    if (text.endsWith('\n')) {
      text = text.slice(0, -1);
      text += '<br>';
    }

    const currentPayload = this.form.getRawValue();

    const newPayload = {
      ...currentPayload,
      text: text.replace(/\n/g, '<br>'),
    };

    // this.form.setValue({ ...newPayload });
  }

  getChecked() {
    if (this.form.get('sms')?.value) {
      this.isSms = true;
      this.messageMaxLength = 160;
    } else {
      this.isSms = false;
      this.messageMaxLength = 5000;
    }
    this.cdr.detectChanges();
  }

  emitFile(data: any): void {
    const file: File | undefined = data.file;

    if (file) {
      this.addFile(file);
      this.updateFormFilesField();
    }
  }

  private addFile(file: File): void {
    this.files.push(file);
    this.removeDuplicateFiles();
  }

  private removeDuplicateFiles(): void {
    this.files = Array.from(new Set([...this.files]));
  }

  private updateFormFilesField(): void {
    this.form.patchValue({ files: [...this.files] });
  }

  deleteFileByName(fileName: string, fileId: string) {
    this.removedFileIds.push(fileId);

    this.files = this.files.filter(file => file.name !== fileName);

    const updatedFormValues = {
      attachmentRemove: this.removedFileIds,
      attachments: this.files,
      files: this.files,
    };

    this.form.patchValue(updatedFormValues);
  }

  private convertBytesToFile(byteArray: string, attachmentObject: any): void {
    const uint8Array = this.convertBase64ToUint8Array(byteArray);

    if (uint8Array) {
      const file = this.createCustomFile(uint8Array, attachmentObject);
      this.files.push(file);
      this.updateFormFiles();
    }
  }

  private createCustomFile(uint8Array: Uint8Array, attachmentObject: any): File {
    const blob = new Blob([uint8Array.buffer], { type: 'application/octet-stream' });
    const file = new File([blob], attachmentObject.name);
    (file as any).id = attachmentObject.id;
    return file;
  }

  private updateFormFiles(): void {
    this.form.patchValue({ files: [...this.files] });
  }

  private convertBase64ToUint8Array(base64String: string): Uint8Array | null {
    try {
      const binaryString = atob(base64String);
      const uint8Array = new Uint8Array(binaryString.length);

      for (let i = 0; i < binaryString.length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
      }

      return uint8Array;
    } catch (error) {
      console.error('Error converting base64 to Uint8Array:', error);
      return null;
    }
  }

  // textAreaProcess(isGeneralPage: boolean): string {
  //   if (isGeneralPage) {
  //     return !this.isSms && !this.showRecipients ? '470' :
  //       this.showRecipients && !this.isSms ? '370' :
  //         !this.showRecipients && this.isSms ? '570' :
  //           '470';
  //   }
  //   return !this.isSms && !this.showRecipients ? '450' :
  //     this.showRecipients && !this.isSms ? '450' :
  //       !this.showRecipients && this.isSms ? '550' :
  //         '550';

  // }
}
