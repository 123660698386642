<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              arrCurrentLink: gameS.arrCurrentLinkDrop,
              objCurrentLink: (gameS.currentLink$|async)!,
              currentLink: (gameS.currentLink$|async)?.currentLink!,
              dataTable: (dataTable$|async)!,
              arrContent: (arrContent$|async),
              loadingTable: (meTableS.loadingTable$|async)             
             } as obj' class='o-wrapPage st-pading-from-menu viewAllTrasaction' wrapPage [widthPage]='widthPage' cdkScrollable  dataTable>

  <div class='o-header '>
    <div class='o-header__content'>
      <div class='o-header__title'>
        <headTitle [text]="'All Transactions'" svgLeft='arrow_squareLeft&24' (svgLeftEmit)='goBack()'
          typeText='text1-blueDark'></headTitle>
        <!-- <div class="st-message st-green st-ml12">
          {{mainS.totalElements}}
          <span class="hide-on-mobile">Transactions</span>
        </div> -->
      </div>
      <div class='o-header__filters'>
        <div class="filter-panel">
          <app-filters *ngIf="obj.mobile"></app-filters>
          <app-add-filters *ngIf="obj.desktop"></app-add-filters>
        </div>
      </div>
      <app-filtersSelected></app-filtersSelected>

      <linkPage-searchFilter [haveLinkPage]="false"></linkPage-searchFilter>


    </div>
  </div>

  <div class='o-content t-wrapperTable'  *ngIf="obj.desktop">
    <div class="st-table-header">
      <div class="st-table-header__ceil" style="width:150px;">
        Type
      </div>
      <div class="st-table-header__ceil" style="width: 330px;">
        Description
      </div>
      <div class="st-table-header__ceil" style="width: 200px;">
        Transaction Date
      </div>
      <div class="st-table-header__ceil st-center" style="width: 115px;">
        Amount
      </div>
      <div class="st-table-header__ceil st-center" style="width: 115px;">
        Status
      </div>
    </div>
    <div class='t-table-v2' >
      <ng-container *ngFor='let item of obj.arrContent; let idx=index'>
        <div class='t-table-v2__item ' (click)="viewDetailsClicked(item.gameId,false)">

          <div class='t-table-v2__item__wrapCeil item-game-number' style="width: 150px;">
            <div>{{item.displayTransactionType}}</div>
          </div>

          <div class='t-table-v2__item__wrapCeil competitionName-transfer-cents' style="width: 330px;">
            <div class="competition-name" style="font-weight: 600;">
              <div> {{item.competitionName}}</div>
            </div>
            <div>
              {{item.assignorName}}
            </div>
          </div>

          <div class='t-table-v2__item__wrapCeil' style="width: 200px;">
            {{ globalConst.formatToEST(item.transferCreatedAt) }} EST
          </div>
          <div class=' t-table-v2__item__wrapCeil st-center st-600' style="width: 120px;"  [ngClass]="{ 'text-red': item.displayTransactionType === 'Paid' }">
            <span>
              {{ item.displayTransactionType === 'Paid' ? '-' : '+' }}
              {{ item.transferAmount | currency: 'USD' }}
            </span>
          </div>


          <div class='t-table-v2__item__wrapCeil st-center' style="width: 135px;">
            <!-- <status [status]="item.displayOperationStatus"></status> -->
            <status [status]="item.displayOperationStatus ? item.displayOperationStatus : item.transferStatus"></status>
          </div>
          <div class=' t-table-v2__item__wrapCeil st-center' style="width: 90px;">
            <meSvg svgName='arrow-icon' (click)="viewDetailsClicked(item.gameId, true)" class="view-arrow-icon"></meSvg>
          </div>
        </div>
      </ng-container>
      <div class="t-table-v2-tableEmpty" *ngIf="(obj?.arrContent?.length??0)==0">
        Transactions will appear here
      </div>
    </div>
  </div>

  <div class="mobile-transactions" *ngIf="obj.mobile">
    <ng-container *ngFor="let item of obj.arrContent">
      <div class="transaction-card" (click)="viewDetailsClicked(item.gameId, false)">
        <div class="transaction-left">
          <div class="transfer-type">{{item.displayTransactionType}}</div>
          <div class="transaction-date">   {{ globalConst.formatToEST(item.transferCreatedAt) }} EST </div>
        </div>
        <div class="transaction-right">
          <div class="amount" [ngClass]="{ 'text-red': item.displayTransactionType === 'Paid' }">
            <span>
              {{ item.displayTransactionType === 'Paid' ? '-' : '+' }}
              {{ item.transferAmount | currency: 'USD' }}
            </span></div>
          <div class="competition-name">{{ item.competitionName }}</div>
        </div>
      </div>
    </ng-container>


    <div class="t-table-v2-tableEmpty" *ngIf="(obj?.arrContent?.length??0)==0">
      Transactions will appear here
    </div>
  </div>
  <app-tableInfiniteLoading></app-tableInfiniteLoading>
  <paginationWithSettings *ngIf='meTableS.showPagination'></paginationWithSettings>
</div>