import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { Router } from '@angular/router';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClassCompetition } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { BtnComponent } from '@components/btn/btn.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { CsvImportComponent } from '@app/dir_group_assignor/games/components/csv-import/csv-import.component';
import { CsvImportCsvboxComponent } from '../csv-import-csvbox/csv-import-csvbox.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { HelperClass } from '@classes/Helper-Classes';
import { MainService } from '@services/main.service';
import { OtherService } from '@services/other.service';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { DeviceService } from '@services/device.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-import-games',
  standalone: true,
  imports: [CommonModule, HeadTitleComponent, FieldComponent, DropFormCtrlComponent, ReactiveFormsModule, FormsModule, BtnComponent, BtnWrapComponent, CsvImportComponent, SvgComponent, DropdownComponent, CsvImportCsvboxComponent],
  templateUrl: './import-games.component.html',
  styleUrls: ['./import-games.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportGamesComponent extends HelperClass implements OnInit {
  selectedCompetition?: ClassCompetition;

  sendObj: { file: any, spec: string } | null = null;
  userData: any = null;

  constructor(
    public mainS: MainService,
    public gameS: GameService,
    public otherS: OtherService,
    public router: Router,
    public cd: ChangeDetectorRef,
    public deviceS: DeviceService,
  ) {
    super(cd);
    this.getArrCompetition();
  }

  ngOnInit(): void {
    this.mainS.getMe().toPromise()
      .then((meUser) => {
        this.userData = meUser;
        console.log('this.userData :>> ', this.userData);
      });
  }

  getArrCompetition(): void {
    this.gameS.getArrCompetition(undefined).pipe(untilDestroyed(this)).subscribe((res) => {
    });
  }

  // selectFile(): void {
  //   this.fileInput = this.document.createElement('input');
  //   this.fileInput.type = 'file';
  //   this.fileInput.accept = '.csv, text/csv';
  //   fromEvent(this.fileInput, 'change').pipe(takeUntil(this.destroySub), first())
  //     .subscribe((event: any) => {
  //         const target = event.target as HTMLInputElement;
  //         const selectedFile = target.files![0];
  //         this.test(selectedFile);
  //         this.showError = false;
  //         // this.defaultTemplateIsUsing = false; ///////
  //         this.cd.detectChanges();
  //         // this.setDefaultTemplateIsUsing(); //////////
  //       },
  //       (err) => {
  //         this.cd.markForCheck();
  //         this.otherS.showNotification(false, undefined, err);
  //       });
  //   this.fileInput.click();
  // }

  uploadFile(): void {
    this.mainS.uploadCsv(this.getFormData(this.sendObj), this.selectedCompetition?.id!, { headers: 'multipart/form-data' }).toPromise()
      .then((res) => {
        // this.fileInput = null;
        // this.uploadData = null;
        this.reqPending = false;
        this.cd.markForCheck();
        this.otherS.showNotification(true, res);
        this.router.navigate(['/games']);
      })
      .catch((err: any) => {
        // this.errorFromServer = true;
        // this.fileInput = null;
        // this.uploadData = null;
        this.reqPending = false;
        this.cd.markForCheck();
        // err?.status == 418 ? this.openPopupCsvShowError(err?.error?.responseBody) : this.otherS.showNotification(false, undefined, err);
      })
      .finally(() => this.endRequest());
  }

  // openPopupCsvShowError(errObj: { count: number, csvExceptionDtoList: Array<{ columnName: string, message: string, stringNumber: number }> }): void {
  //   this.dialog.open(PopupCsvShowErrorComponent, {
  //     ...this.popupS.widthForPopup$.getValue(),
  //     data: { messages: this.transformError418(errObj.csvExceptionDtoList), count: errObj.count },
  //   });
  // }

  // transformError418(arr: Array<{ columnName: string, message: string, stringNumber: number }>): Array<{ text1: string, text2: string }> {
  //   const result: Array<{ text1: string, text2: string }> = [];
  //   arr.forEach((el: any) => {
  //     const idxStart = el.message?.indexOf('\'');
  //     const idxEnd = el.message?.lastIndexOf('\'');
  //     const dataStr = el.message?.slice(idxStart - 1, idxEnd + 1);
  //     result.push({
  //       text1: `Your data format is unusable: Text ${dataStr} in row ${el.stringNumber}.`,
  //       text2: `Please refer to our format example.`,
  //     });
  //   });
  //   return result;
  // }

  getFormData(object: any): any {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  }

  // checkRequiredFields(): boolean {
  //   for (let csvData of this.listCsvData) {
  //     // if ((csvData.required && !csvData.headerFromCsv) || !this.timezone) { // пока что не удалять. Может придется вернуть таймзону
  //     if (csvData.required && !csvData.headerFromCsv) {
  //       return (this.showError = true);
  //     }
  //   }
  //   return (this.showError = false);
  // }

  // changeFile(file: any) {
  //   console.log('changeFile :', file);
  //   this.file = file;
  // }
  // changeFile(sendObj:{file: any, spec: { [key: string]: string }} | null) {
  changeFile(sendObj: { file: any, spec: string } | null) {
    // console.log('changeFile :', sendObj);
    this.sendObj = sendObj;
  }

}
