import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MainService } from '@services/main.service';
import {
  arrOnForMonthly,
  arrOnForWeekly,
  arrTopUpSchedule,
  IPaymentMethod,
  ITopup,
  TypePay,
} from '@app/dir_group_assignor/payments/modelsForPayment';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { HelperClass } from '@classes/Helper-Classes';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';
import { PayMethodDropComponent } from '@app/dir_group_assignor/payments/additional-components/pay-method-drop/pay-method-drop.component';
import { PaymentService } from '@app/dir_group_assignor/payments/payment.service';
import { OtherService } from '@services/other.service';
import { Router } from '@angular/router';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { ClassDrop, getDropFromString } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { FormCustomService } from '@services/form-custom.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UtilsService } from '@services/utils.service';

interface IPopupAutoTopupForm {
  primaryMethod: FormControl<IPaymentMethod>;
  backupMethod: FormControl<IPaymentMethod>;
  day: FormControl<ClassDrop>;
  enabled: FormControl<boolean>;
  id: FormControl<string>;
  resultBalance: FormControl<number>;
  on: FormControl<ClassDrop>;
  // timezone: FormControl<number>; // посмотреть потом в каком виде приходит таймзона здесь
  // topupIsExist: FormControl<boolean>;
}

@UntilDestroy()
@Component({
  selector: 'app-popup-auto-topup',
  standalone: true,
  imports: [CommonModule, HeadTitleComponent, FieldComponent, DropFormCtrlComponent, FormsModule, ReactiveFormsModule, InputCtrlComponent,
    BtnWrapComponent, BtnComponent, PayMethodDropComponent, DropdownComponent],
  templateUrl: './popup-auto-topup.component.html',
  styleUrls: ['./popup-auto-topup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupAutoTopupComponent extends HelperClass {
  form!: FormGroup<IPopupAutoTopupForm>;
  topup?: ITopup;
  arrTopUpSchedule = arrTopUpSchedule;
  arrOn: Array<ClassDrop> = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private formCustomS: FormCustomService,
    public mainS: MainService,
    public payS: PaymentService,
    public otherS: OtherService,
    private router: Router,
    public dialogRef: MatDialogRef<PopupAutoTopupComponent>,
    public cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public dataPopup?: ITopup,
  ) {
    super(cd);
    if (dataPopup) this.topup = dataPopup;
    this.createForm();
  }

  submit(): void {
    const day: string = this.ctrl.day.value?.upperCase!;
    const on: string = this.ctrl.on.value?.titleCase!;
    let sendObj: ITopup = { ...this.form.getRawValue(), day, on, resultBalance: this.ctrl.resultBalance.value * 100 };
    sendObj = UtilsService.removeEmptyKeysFromObject(sendObj);
    this.dialogRef.close(sendObj);
    // !!! this.mainS.().toPromise()
    //   .then((res?: ITopup) => {
    //   })
    //   .catch(() => {
    //   });
  }

  selectPayMethod(isSelect: boolean, payMethod: IPaymentMethod, typePay: TypePay | string): void {
    console.log('selectPayMethod :', isSelect, payMethod, typePay);
  }

  addPaymentMethod(): void {
    this.close();
    this.router.navigate(['payments/balances/pay']);
  }

  // === FORM ==================================
  createForm(): void {
    const allMethods: Array<IPaymentMethod> = this.payS.allMethodsSub$.getValue() || [];
    const primaryMethod = this.topup?.primaryMethod ? this.topup?.primaryMethod : allMethods[0];
    const backupMethod = this.topup?.backupMethod ? this.topup?.backupMethod : allMethods[0];
    this.form = this.formBuilder.group({
      primaryMethod: [primaryMethod, [Validators.required]],
      backupMethod: [backupMethod],
      day: [this.topup?.day ? getDropFromString(this.topup?.day, 'PopupAutoTopupComponent createForm topup?.day') : '', [Validators.required]],
      enabled: [this.topup?.enabled],
      id: [this.topup?.id],
      resultBalance: [this.topup?.resultBalance ? this.topup?.resultBalance / 100 : '', [Validators.required]],
      on: [this.topup?.on],
      // timezone: [this.topup?.timezone, [Validators.required]],
      // topupIsExist: [this.topup?.topupIsExist, [Validators.required]],
    });
    if (this.ctrl.day.value) this.changeDay(this.ctrl.day.value);
  }

  get ctrl(): IPopupAutoTopupForm {
    return this.form?.controls;
  }

  changeDay(day: ClassDrop) {
    if (day?.lowerCase === 'daily' || !day?.lowerCase) {
      this.arrOn = [];
      this.ctrl.on.clearValidators();
    }

    if (day?.lowerCase === 'weekly') {
      this.arrOn = arrOnForWeekly;
      this.ctrl.on.addValidators(Validators.required);
    }
    if (day?.lowerCase === 'monthly') {
      this.arrOn = arrOnForMonthly;
      this.ctrl.on.addValidators(Validators.required);
    }
    this.ctrl.on.patchValue(this.arrOn[0], this.formCustomS.optionsForPatchValue);
  }

  close(): void {
    this.dialogRef.close();
  }

}

// subscribeToForm(): void {
//   this.form.valueChanges.pipe(takeUntil(this.destroySub)).subscribe((res) => {
// this.checkArrOn();
// this.cd.detectChanges();
// });
// }