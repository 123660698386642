import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList, TemplateRef } from '@angular/core';
import { DeviceService } from '@services/device.service';

@Component({
  selector: 'app-info-list',
  templateUrl: './info-list.component.html',
  styleUrls: ['./info-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  standalone: true,
})
export class InfoListComponent {
  @Input() selectedTile: number = 0;
  @Input() list: TemplateRef<any>[] = [];

  constructor(public deviceService: DeviceService) {

  }
  selectTile(index: number) {
    this.selectedTile = index;
  }

  onSwipe(event: any, currentIndex: number) {
    if (event.type === 'swipeleft') {
      const nextIndex = currentIndex === this.list.length - 1 ? 0 : currentIndex + 1;
      this.selectTile(nextIndex);
    } else if (event.type === 'swiperight') {
      const prevIndex = currentIndex === 0 ? this.list.length - 1 : currentIndex - 1;
      this.selectTile(prevIndex);
    }
  }
}
