import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LogoPaySystemComponent } from '@app/dir_group_assignor/payments/additional-components/logo-pay-system/logo-pay-system.component';
import { CardLogoComponent } from '@components/card-logo/card-logo.component';
import { ACCOUNT_EXTERNAL_FIELDS, AccountExternalModel } from '@models/account-external.model';
import { ACCOUNT_METHODS } from '@shared/constants/account-methods.constant';
import { SvgComponent } from '../__svg_img/svg/svg.component';
import { BtnComponent } from '../btn/btn.component';
import { ChxComponent } from '../chx/chx.component';
import { DeviceService } from '@services/device.service';
import { FormCustomService } from '@services/form-custom.service';
import { MeService } from '@services/me.service';

export interface TabInterface {
  title: string;
  isSelected?: boolean;
}

@Component({
  selector: 'app-account-payment',
  standalone: true,
  templateUrl: './account-payment.component.html',
  styleUrls: ['./account-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SvgComponent,
    BtnComponent,
    ChxComponent,
    FormsModule,
    ReactiveFormsModule,
    LogoPaySystemComponent,
    CardLogoComponent,
  ],
  host: {
    class: 'g-flex g-flex--space-between g-flex--align-start',
  },
})
export class AccountPaymentComponent implements OnChanges {
  @Input() mode: 'view' | 'edit' | 'default' | 'payout' = 'default';
  @Input() account!: AccountExternalModel;
  @Input() isSelected = false;
  @Input() isDisabled = false;

  @Output() edit = new EventEmitter<any>();
  @Output() next = new EventEmitter<any>();
  @Output() removeAccount = new EventEmitter<AccountExternalModel>();
  @Output() defaultAccount = new EventEmitter<AccountExternalModel>();

  @Output() accountSelected = new EventEmitter<AccountExternalModel>();
  onSelectAccount() {
    this.accountSelected.emit(this.account);
  }

  checkboxControl = new FormControl(false);

  ACCOUNT_EXTERNAL_FIELDS = ACCOUNT_EXTERNAL_FIELDS;
  ACCOUNT_METHODS = ACCOUNT_METHODS;

  constructor(
    public deviceS: DeviceService,
    private formCustomS: FormCustomService,
    private cd: ChangeDetectorRef,
    public meS: MeService,
  ) {
  }

  ngOnChanges({ isSelected }: SimpleChanges) {
    if (isSelected && isSelected.currentValue !== isSelected.previousValue) {
      this.checkboxControl.patchValue(this.isSelected);
      // this.cd.detectChanges()
    }
  }

  onEdit(): void {
    this.edit.emit();
  }

  goToNext(): void {
    this.next.emit();
  }

  removePayment(account: AccountExternalModel) {
    this.removeAccount.emit(account);
  }

  setDefaultPayment(account: AccountExternalModel) {
    this.defaultAccount.emit(account);
  }
}
