import { ChangeDetectorRef, Directive, ElementRef, HostBinding, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { MeTableService } from '@components/_table/me-table.service';
import { DeviceService } from '@services/device.service';
import { Router } from '@angular/router';
import { IForTable } from '@components/_table/meTable';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';

@Directive({ selector: '[itemTable]', standalone: true })
export class ItemTableDirective<T> implements OnInit {
  @HostBinding('class.o-none') get hideThisComponent(): boolean {
    // !!! скрыть если loadingTable
    // !! НО для мобилы для infiniteLoading НЕ надо скрывать
    // return this.meTableS.loadingTable;
    return this.meTableS.loadingTable && !this.settingsRequestS.check_infiniteLoading() && !this.deviceS.isMobile;
  }

  // === CURRENT ITEM ======================================
  @Input() item?: T & IForTable;

  // === URL NAVIGATE ==================================
  // !!! вередавать сюда url без id. Example: для router.navigate(["game/info/",game?.id])
  // !!! передать сюда в urlForNavigate только "game/info/"
  @Input() urlNavigate?: string;

  @HostListener('click', ['$event']) clickForItem(): void {
    if (this.deviceS.isMobile) return; // для мобилы не надо редирект при нажатии на item. Для мобилы есть отдельная кнопка
    if (this.urlNavigate) this.router.navigate([`${this.urlNavigate}`, this.item?.id]);
  }

  constructor(
    private r: Renderer2,
    private elRef: ElementRef,
    private deviceS: DeviceService,
    public router: Router,
    public meTableS: MeTableService<T>,
    private settingsRequestS: SettingsRequestService, // for-settings===
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.setStylesForItem();
  }

  // === STYLES =================================
  setStylesForItem(): void {
    this.r.setStyle(this.elRef.nativeElement, 'minHeight', this.meTableS.minHeightItemTable + 'px');
  }

}
