<div class='wrapperCodeConfirmStep'>
  <ng-container *ngIf='{ value: value,
                         authText: type == "email" ? "Check your email" : "Check your SMS",
                         authText2: type == "email" ? "We sent a verification link to" : "We sent a verification code to",
                         btnText: type == "email" ? "Verify email" : "Verify Cellphone",
                         authBottomText: type == "email" ? "Didn’t receive the email?" : "Didn’t receive the code?"
                       } as objForCodeConfirm'>
    <div class='authText'>{{objForCodeConfirm.authText}}
      <div>{{objForCodeConfirm.authText2}}<span>{{objForCodeConfirm.value}}</span></div>
    </div>
    <codeConfirm class='o-mg-t-30' (emit)='validationCode=$event'></codeConfirm>
    <!--    [disabled]='ctrl.validationCode?.value?.length! < 4-->
    <btn [text]='objForCodeConfirm.btnText' [bcg]='authS.bcgApplyBtn' [spinner]='reqPending' class='o-mg-t-30'
         (emit)='verifyCode()' [disabled]='validationCode?.length! < 4'></btn>
    <div class='authBottomText'>{{objForCodeConfirm.authBottomText}}
      <a (click)='resendCode()'>Click to resend</a>
    </div>
    <btnBack text='Back' (click)='backStepEvent.emit();emit.emit("back");'></btnBack>
  </ng-container>
</div>