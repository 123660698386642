import { AfterViewChecked, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({ selector: '[wrapPage]', standalone: true })
export class WrapPageDirective<T> implements AfterViewChecked {
  // @Input() childComponent_for_wrapPage?: ElementRef;

  @Input() set widthPage(width: number) {
    this.width = width;
    // this.checkStyles(this.elRef?.nativeElement);
    this.checkStyles();
  }

  width?: number;

  constructor(
    private elRef: ElementRef,
    private r: Renderer2,
  ) {
  }

  ngAfterViewChecked() {
    const paginationRef = this.elRef?.nativeElement?.querySelector('pagination');
    if (paginationRef) this.setWidth(paginationRef);
    // if (this.childComponent_for_wrapPage?.nativeElement) {
    //   this.checkStyles(this.childComponent_for_wrapPage?.nativeElement);
    // }
  }

  // checkStyles(nativeElement: any) {
  checkStyles(): void {
    const arrChildrenNodes_forWrap: Array<any> = Array.from(this.elRef?.nativeElement?.children); // Array<nativeElement>
    // const arrChildrenNodes_forWrap: Array<any> = Array.from(nativeElement?.children); // Array<nativeElement>
    // console.log('arrChildrenNodes_forWrap :', arrChildrenNodes_forWrap)
    let arrChildrenNodes_forHeaderContent: Array<any> = [];

    arrChildrenNodes_forWrap?.forEach((el) => {
      // console.log('el :', el)
      // console.log('o-content :', el?.classList?.contains('o-content'))
      // console.log('o-header :', el?.classList?.contains('o-header'))
      if (el?.classList?.contains('o-content')) this.setWidth(el);
      if (el?.classList?.contains('o-header')) {
        arrChildrenNodes_forHeaderContent = Array.from(el?.children);
        const header__content = el.querySelector('.o-header');
        if (header__content) this.setWidth(header__content);
      }
    });

    arrChildrenNodes_forHeaderContent?.forEach((el) => {
      const headerContent = el?.classList?.contains('o-header__content') ? el : null;
      if (headerContent) {
        this.setWidth(headerContent);
        // this.r.setStyle(headerContent, 'marginLeft', 'auto'); // в css есть это
        // this.r.setStyle(headerContent, 'marginRight', 'auto'); // в css есть это
      }
    });
  }

  setWidth(nativeElement: any): void {
    if (!this.width) return;
    this.r.setStyle(nativeElement, 'width', this.width + 'px');
    this.r.setStyle(nativeElement, 'minWidth', this.width + 'px');
    this.r.setStyle(nativeElement, 'maxWidth', this.width + 'px');
  }

}
