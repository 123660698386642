import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DeviceService } from '@services/device.service';
import {
  arrTypeFilter,
  ClassFilterDateRange,
  ClassFilterDrop,
  ClassFilterInput,
  TKeyofSettingsRequest_forFilters,
  TypeFilter_dateRange,
  TypeFilter_drop,
  TypeFilter_string,
} from '@components/filters/filters';
import { FiltersService } from '@components/filters/filters.service';
import { debounceTime } from 'rxjs/operators';
import { AGE_ITEM, agesItems, ClassDrop, distanceItems } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { ClassSettingsRequest } from '@models/response-and-request';
import {
  arrPeriodsForCurrent,
  arrPeriodsForPast,
  customDateRange,
  IDatePeriod,
} from '@components/__drop_inputs_matSelect/date-range/dateRange';
import { DateRangeService } from '@components/__drop_inputs_matSelect/date-range/date-range.service';
import { MeService } from '@services/me.service';
import { TAgeForOfficials } from '@app/dir_group_assignor/officials/officials';
import { arrPayoutFormatDrop } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { UtilsService } from '@services/utils.service';
import { IDateRange } from '@models/IDates';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { GetMatTooltipForSearchPipe } from '@components/filters/pipes/get-mat-tooltip-for-search.pipe';
import { ForFilterItemDirective } from '@components/filters/directives/for-filter-item.directive';
import { DateRangeComponent } from '@components/__drop_inputs_matSelect/date-range/date-range.component';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { SortByComponent } from '@components/sortBy/sort-by.component';
import {
  FiltersSelectedIconComponent,
} from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';
import { ContainerTooltipComponent } from '@components/__tooltip/container-tooltip/container-tooltip.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { TooltipDefaultComponent } from '@components/__tooltip/tooltip-default/tooltip-default.component';
import { TooltipSearchComponent } from '@components/__tooltip/tooltip-search/tooltip-search.component';
import { Router } from '@angular/router';
import { UnsavedChangesService } from '@services/unsaved-changes.service';
import { AssignComponent } from '@app/dir_group_assignor/assign/assign.component';

// type IObjFiltersForm = { [key in TypeFilter]: FormControl;}
interface IFiltersForm {
  search: FormControl<string>;
  zipcode: FormControl<string>;
  dateRange: FormControl<ClassFilterDateRange>;
  competitions: FormControl<Array<ClassDrop> | ClassDrop>;
  assignStatuses: FormControl<Array<ClassDrop> | ClassDrop>;
  groupId: FormControl<Array<ClassDrop> | ClassDrop>;
  levels: FormControl<Array<ClassDrop> | ClassDrop>;
  ages: FormControl<Array<ClassDrop> | ClassDrop>;
  distance: FormControl<Array<ClassDrop> | ClassDrop>;
  activeCompetitions: FormControl<Array<ClassDrop> | ClassDrop>;
  competitionPreferences: FormControl<Array<ClassDrop> | ClassDrop>;
  location: FormControl<Array<ClassDrop> | ClassDrop>;
  officialList: FormControl<Array<ClassDrop> | ClassDrop>;
  officialStatus: FormControl<Array<ClassDrop> | ClassDrop>;
  seasons: FormControl<Array<ClassDrop> | ClassDrop>;
  payoutFormat: FormControl<Array<ClassDrop> | ClassDrop>;
  paymentMethod: FormControl<Array<ClassDrop> | ClassDrop>; 
  status: FormControl<Array<ClassDrop> | ClassDrop>;  
}

@UntilDestroy()
@Component({
  selector: 'app-filters',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, HeadTitleComponent, InputCtrlComponent, GetMatTooltipForSearchPipe, ForFilterItemDirective, DateRangeComponent, DropdownComponent, SortByComponent, FiltersSelectedIconComponent, BtnWrapComponent, BtnComponent, ContainerTooltipComponent, SvgComponent, TooltipDefaultComponent, TooltipSearchComponent],
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  providers: [DateRangeService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersComponent implements OnInit, AfterViewInit {
  @ViewChild('inputField') inputField!: ElementRef;
  @HostBinding('class.o-none') get class_o_none(): boolean {
    const showFilters = this.deviceS.isDesktop$.getValue() || this.filtersS.showFilters_forMobile$.getValue();
    return !showFilters;
  }

  @ViewChild('filtersTemplate_searchRef') filtersTemplate_searchRef?: TemplateRef<any>; 

  form!: FormGroup<IFiltersForm>;
  showAlwaysPlaceholder: boolean = true; 
  settingsRequestForEmitter: ClassSettingsRequest = {};
  @Output() emit = new EventEmitter<ClassSettingsRequest>();
  @Input() selectedCompetition: string = 'Competition';
  @Input() assignComponent!: AssignComponent;
  showElement: boolean = true;

  readonly agesItems = agesItems;
  readonly distanceItems = distanceItems;
  readonly arrPayoutFormatDrop = arrPayoutFormatDrop;

  constructor(
    public meS: MeService,
    public filtersS: FiltersService,
    public deviceS: DeviceService,
    private dateRangeS: DateRangeService,
    private settingsRequestS: SettingsRequestService, 
    private cd: ChangeDetectorRef,
    private unsavedChangesService: UnsavedChangesService,
    private router: Router
  ) {
    this.filtersS.filtersRef = this;
    this.createForm();
  }

  ngOnInit() {
    this.subscribeToSelectedFilters();
    const currentUrl = this.router.url;

    if (currentUrl.includes('balances')) {
      this.showElement = false;
    }
  }

  ngAfterViewInit() {
    if (this.inputField) {
      console.log('Input field is initialized', this.inputField);
    }
  }

  subscribeToSelectedFilters(): void {
    this.filtersS.arrFilters$
      .pipe(
        debounceTime(90),
        untilDestroyed(this),
      )
      .subscribe((arr) => {
        if (!arr?.length) return;
        const currentSettingsRequest = this.settingsRequestS.settings;
        let groupId_drop: ClassFilterDrop | undefined = undefined;
        let competitions_drop: ClassFilterDrop | undefined = undefined;
        arr.forEach((el) => {
          const typeFilter = el.typeFilter as TKeyofSettingsRequest_forFilters;

          if (el.disabled) this.ctrl[typeFilter].disable();
          if (!el.disabled) this.ctrl[typeFilter].enable();

          if (this.filtersS.is_TypeFilter_string(typeFilter!)) {
            const valueString = (el as ClassFilterInput).valueString || '';
            this.ctrl[typeFilter].patchValue(valueString);
            currentSettingsRequest[typeFilter] = valueString as any; // TPayoutFormatUpperCase | string
          }

          if (this.filtersS.is_TypeFilter_dateRange(typeFilter!)) {
            const filterDateRange = new ClassFilterDateRange(el as ClassFilterDateRange);
            if (this.settingsRequestS.is_currentPath_reports || window.location.href.includes('/reporting')) { 
              delete filterDateRange.typePeriod;
            }
            const datePeriod: IDatePeriod = filterDateRange.period !== customDateRange ?
              this.dateRangeS.checkDatePeriod(filterDateRange, 'FiltersComponent subscribeToSelectedFilters')?.datePeriod!
              : filterDateRange.datePeriod!;
            this.ctrl[filterDateRange.typeFilter as TypeFilter_dateRange].patchValue(filterDateRange);
            currentSettingsRequest.from = datePeriod?.from as string || '';
            currentSettingsRequest.to = datePeriod?.to as string || '';

            if (filterDateRange?.typePeriod) {
              const isAssignorFeesPage = window.location.href.includes('/payment/') || window.location.href.includes('myWalletOfficial/all')
              if (isAssignorFeesPage) {
                filterDateRange.arrPeriods = ClassFilterDateRange.templatePreviosData;
              } else {
                filterDateRange.arrPeriods = filterDateRange?.typePeriod === 'current' ? arrPeriodsForCurrent : arrPeriodsForPast;
              }
            }
          }

          if (this.filtersS.is_TypeFilter_drop(typeFilter!)) {

            if (el.typeFilter === 'ages') {
              const valueDrop = (el as ClassFilterDrop).valueDrop;
              this.ctrl.ages.patchValue(valueDrop!);
              const { fromAge, toAge } = this.get_fromAge_toAge(valueDrop as ClassDrop);
              currentSettingsRequest.fromAge = fromAge;
              currentSettingsRequest.toAge = toAge;
            } else {
              const valueDrop = (el as ClassFilterDrop).valueDrop;
              if (el.typeFilter === 'groupId') groupId_drop = el;
              if (el.typeFilter === 'competitions') competitions_drop = el;
              this.ctrl[(typeFilter as TypeFilter_drop)].patchValue(valueDrop!);
              if ((valueDrop as Array<ClassDrop>)?.length || (valueDrop as Array<ClassDrop>)?.length === 0) {
                const arrValues: Array<string> = [];
                (valueDrop as Array<ClassDrop>)?.forEach(el => {
                  if (el.upperCase) arrValues.push(el.upperCase);
                });
                currentSettingsRequest[typeFilter as TKeyofSettingsRequest_forFilters] = (arrValues?.join(',') || '') as any;
              } else {
                currentSettingsRequest[typeFilter as TKeyofSettingsRequest_forFilters] = (valueDrop as ClassDrop)?.upperCase as any;
              }
            }
          }
        });

        this.checkGroupId(groupId_drop!, competitions_drop!);
        this.cd.detectChanges(); 

        const updated_arrTypeFilter = [...arrTypeFilter, 'fromAge', 'toAge'];
        const existChanges_settingsOnlyForFilters = !UtilsService.compareTwoObjects(
          UtilsService.removeEmptyKeysFromObject(currentSettingsRequest),
          UtilsService.removeEmptyKeysFromObject(this.settingsRequestS.settings),
          updated_arrTypeFilter as Array<keyof ClassSettingsRequest>, 
        );
        const current_dateRange: IDateRange = UtilsService.removeEmptyKeysFromObject({
          from: currentSettingsRequest.from,
          to: currentSettingsRequest.to,
        });
        const old_dateRange: IDateRange = UtilsService.removeEmptyKeysFromObject({
          from: this.settingsRequestS.settings.from,
          to: this.settingsRequestS.settings.to,
        });
        const existChanges_dateRange = !UtilsService.compareTwoObjects(current_dateRange, old_dateRange);
        const existChanges = existChanges_settingsOnlyForFilters || existChanges_dateRange;

        if (!this.filtersS.needUseDynamicFilters) {
          this.methodForEmitSettingRequest(UtilsService.deepClone(currentSettingsRequest));
          return;
        }

        if (!existChanges) return; 
        currentSettingsRequest.typeEmitSetting = 'filters';

        if (groupId_drop && !(groupId_drop as ClassFilterDrop)?.valueDrop) {

          currentSettingsRequest.competitions = '';
        }

        this.settingsRequestS.updateSettings(currentSettingsRequest, 'filters');
      });
  }

  changeFilter_TypeFilter_string(typeFilter_string: TypeFilter_string, valueString: string): void {
    if (!this.ctrl[typeFilter_string]) console.error('FiltersComponent changeFilter():', typeFilter_string, ' valueString:', valueString); // !!! NO DELETE
    this.filtersS.updateFilter(typeFilter_string, {
      valueString,
      forTest: ' changeFilter_TypeFilter_string ',
    }, ' changeFilter_TypeFilter_string ');
    this.ctrl[typeFilter_string].patchValue(valueString);
  }


  changeFilter_TypeFilter_drop(typeFilter_drop: TypeFilter_drop, filterDrop: Array<ClassDrop> | ClassDrop): void {

    if (this.unsavedChangesService.unsavedChanges) {
      this.unsavedChangesService.openPopup_filters().then((proceed: boolean) => {
        if (proceed) {
          this.assignComponent.assignOfficials(false);
          if (!this.ctrl[typeFilter_drop as keyof IFiltersForm]) {
            console.error('FiltersComponent changeFilter():', typeFilter_drop, ' filterDrop:', filterDrop); // !!! NO DELETE
          }

          const control = this.ctrl[typeFilter_drop as keyof IFiltersForm] as FormControl<Array<ClassDrop> | ClassDrop>;
          this.filtersS.updateFilter(typeFilter_drop, { valueDrop: filterDrop }, 'changeFilter_TypeFilter_drop');
          control.patchValue(filterDrop);
        } else {
          this.unsavedChangesService.unsavedChanges = false;

          if (!this.ctrl[typeFilter_drop as keyof IFiltersForm]) {
            console.error('FiltersComponent changeFilter():', typeFilter_drop, ' filterDrop:', filterDrop); // !!! NO DELETE
          }

          const control = this.ctrl[typeFilter_drop as keyof IFiltersForm] as FormControl<Array<ClassDrop> | ClassDrop>;
          this.filtersS.updateFilter(typeFilter_drop, { valueDrop: filterDrop }, 'changeFilter_TypeFilter_drop');
          control.patchValue(filterDrop);
        }
      });
    } else {
      if (!this.ctrl[typeFilter_drop as keyof IFiltersForm]) {
        console.error('FiltersComponent changeFilter():', typeFilter_drop, ' filterDrop:', filterDrop); // !!! NO DELETE
      }

      const control = this.ctrl[typeFilter_drop as keyof IFiltersForm] as FormControl<Array<ClassDrop> | ClassDrop>;
      this.filtersS.updateFilter(typeFilter_drop, { valueDrop: filterDrop }, 'changeFilter_TypeFilter_drop');
      control.patchValue(filterDrop);
    }
  }


  changeFilter_TypeFilter_dateRange(responseDropDateRange: ClassFilterDateRange): void {
    if (this.unsavedChangesService.unsavedChanges) {
      this.unsavedChangesService.openPopup_filters().then((proceed: boolean) => {
        if (proceed) {
          this.assignComponent.assignOfficials(false);
          const updatedFindFilter = this.filtersS.updateFilter('dateRange', responseDropDateRange, '  changeFilter_TypeFilter_dateRange');
          this.ctrl.dateRange.patchValue(updatedFindFilter);
        } else {
          this.unsavedChangesService.unsavedChanges = false;
          const updatedFindFilter = this.filtersS.updateFilter('dateRange', responseDropDateRange, '  changeFilter_TypeFilter_dateRange');
          this.ctrl.dateRange.patchValue(updatedFindFilter);
        }
      });
    } else {
      const updatedFindFilter = this.filtersS.updateFilter('dateRange', responseDropDateRange, '  changeFilter_TypeFilter_dateRange');
      this.ctrl.dateRange.patchValue(updatedFindFilter);
    }
  }

  methodForEmitSettingRequest(currentSettingsRequest: ClassSettingsRequest): void {
    const existChanges = !UtilsService.compareTwoObjects(this.settingsRequestForEmitter, currentSettingsRequest); 
    if (existChanges) {
      this.settingsRequestForEmitter = UtilsService.deepClone(currentSettingsRequest) as ClassSettingsRequest;

      this.emit.emit({ ...this.settingsRequestForEmitter, page: 0 });
    }
  }


  createForm(): void {
    this.form = new FormGroup<IFiltersForm>({} as IFiltersForm);
    arrTypeFilter.forEach((el) => this.form.addControl(el, new FormControl()));
  }

  get ctrl(): IFiltersForm {
    return this.form?.controls;
  }

  private get_fromAge_toAge(valueDrop?: ClassDrop): Pick<ClassSettingsRequest, 'fromAge' | 'toAge'> {
    if (!valueDrop) return { fromAge: '', toAge: '' };
    const agesItem = valueDrop as ClassDrop & { titleCase: TAgeForOfficials };
    if (agesItem.upperCase) return AGE_ITEM[agesItem.upperCase];
    return { fromAge: '', toAge: '' };
  }

  private checkGroupId(groupId_drop: ClassFilterDrop, competitions_drop: ClassFilterDrop): void {
    if (groupId_drop && !competitions_drop) {
      console.error('NO HAVE COMPETITIONS IN FILTERS DROP :');
      return;
    }

    if (groupId_drop && !groupId_drop?.valueDrop) {
      competitions_drop.valueDrop = undefined;
      this.ctrl.competitions.reset();
      this.ctrl.competitions.disable();
    }
  }
  handleFocus(): void {
    if (this.inputField) {
      this.inputField.nativeElement.focus();
    }
  }
  onResetFilters(): void {
    if (this.unsavedChangesService.unsavedChanges) {
      this.unsavedChangesService.openPopup_filters().then((proceed: boolean) => {
        if (proceed) {
          this.assignComponent.assignOfficials(false);
          this.filtersS.resetSelectedFilters();
        } else {
          this.unsavedChangesService.unsavedChanges = false;
          this.filtersS.resetSelectedFilters();
        }
      });
    } else {
      this.filtersS.resetSelectedFilters();
    }
  }
}
