import { Pipe, PipeTransform } from '@angular/core';
import { ClassUser } from '@models/user';
import { OtherService } from '@services/other.service';

// andrei delete later
@Pipe({ name: 'getUserName', standalone: true })
export class GetUserNamePipe implements PipeTransform {
  constructor(
    private otherS: OtherService,
  ) {
  }

  transform(user?: ClassUser): string {
    return this.otherS.getUserName(user);
  }

}
