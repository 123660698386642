<div class='wrapperCompetitionsNavigation wrapperCompetitionsNavigation--{{page}}'>
  <div class='wrapLinksCompetitionsNavigation'>
    <div *ngFor='let item of arrNavigationCompetitions; let idx = index'
         class='wrapLinksCompetitionsNavigation__link
         {{!competitionS.competition?.id && idx!=0 ? "wrapLinksCompetitionsNavigation__disabled":""}}'
         [routerLink]='!competitionS.competition?.id && idx!=0 ? null:
         "/competitions/"+item.url+"/"+(competitionS.competition?.id || str_setup_competition)'
         [routerLinkActive]='!competitionS.competition?.id && idx!=0 ? "":"active"'>
      {{ item.title }}
    </div>
  </div>
</div>
