import { Pipe, PipeTransform } from '@angular/core';
import { MeTableService } from '@components/_table/me-table.service';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { TPath, urlOpenGames } from '@app/app.module';

@Pipe({ name: 'isShowGifLoading', standalone: true, pure: false }) // !!! NO DELETE pure: false
export class IsShowGifLoadingPipe<T> implements PipeTransform {
  page?: number;
  size?: number;

  constructor(
    public settingsRequestS: SettingsRequestService, // for-settings===
    // public filtersS: FiltersService, // for-filters===
    public meTableS: MeTableService<T>,
  ) {
  }

  // !!! isRequestAssignOfficial для openGames
  transform(path?: TPath, isRequestAssignOfficial?: boolean): unknown {
    const loading = this.meTableS.loadingTable;
    const isFirstLoadPage = this.settingsRequestS.isFirstLoadPage;
    const is_urlOpenGames = path === urlOpenGames;
    const isChangePagination = this.isChangePagination();

    let result = false;
    // !!! показывать анимацию только при первой загрузке страницы и при нажатии на кнопку Request
    // if (is_urlOpenGames && loading && isFirstLoadPage) {
    if (is_urlOpenGames && loading && (isFirstLoadPage || isRequestAssignOfficial)) {
      result = true;
    } else {
      result = false;
    }

    return result;
  }

  // !!! пришлось пока что так сделать чтобы отследить были ли изменения в пагинации. т.к. typeEmitSetting сейчас в фильтрах НЕправильно работает
  isChangePagination(): boolean {
    let result = false;
    if (this.page != this.settingsRequestS.settings.page) result = true;
    if (this.size != this.settingsRequestS.settings.size) result = true;
    this.page = this.settingsRequestS.settings.page;
    this.size = this.settingsRequestS.settings.size;
    return result;
  }

}
