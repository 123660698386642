import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SuccessDialogComponent } from '@app/dir_officials/page-my-wallet/components/success-dialog/success-dialog.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { CardLogoComponent } from '@components/card-logo/card-logo.component';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { ACCOUNT_EXTERNAL_FIELDS, AccountExternalModel } from '@models/account-external.model';
import { ICard } from '@models/ICard';
import { AccountApiService } from '@services/account.api.service';
import { FundService } from '@services/funds.service';
import { MeService } from '@services/me.service';
import { OtherService } from '@services/other.service';
import { PayoutApiService } from '@services/payout.api.service';
import { PopupService } from '@services/popup.service';
import { IResCreateTokenSuccess, IStripeEvent, StripeService, TFieldStripeForm } from '@services/stripe.service';
import { ACCOUNT_METHODS } from '@shared/constants/account-methods.constant';
import { CREDIT_CARD_TYPE, CREDIT_CARD_TYPE_REGEXP } from '@shared/constants/credit-card-type.constant';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { catchError, finalize, throwError } from 'rxjs';
import { FieldComponent } from '../../__drop_inputs_matSelect/field/field.component';
import { InputCtrlComponent } from '../../__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { BtnComponent } from '../../btn/btn.component';
import { AccountPaymentComponent } from '@components/account-payment/account-payment.component';
import { HeadTitleComponent } from '../../head-title/head-title.component';
import { MainService } from '@services/main.service';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { DeviceService } from '@services/device.service';
import { AddAnotherComponent } from '@components/add-another/add-another.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { ManagePaymentMethodComponent } from '@app/dir_officials/page-my-wallet/components/manage-payment-method/manage-payment-method.component';
import { InstantSuccessDialogComponent } from '@app/dir_officials/page-my-wallet/components/instant-success-dialog/instant-success-dialog.component';

interface IForStripeElem {
  name: TFieldStripeForm;
  elemRef: Element; 
  stripeElem?: any; 
  value?: string;
}

enum FORM_FIELDS {
  NAME = 'cardName',
  EXPIRE = 'cardExpiry',
  NUMBER = 'cardNumber',
  CVV = 'cardCvc',
  ZIP = 'zip',
  ROUTING = 'routing',
  ACCOUNT = 'account',
  ACCOUNT_CONFIRM = 'accountConfirm',
}

@UntilDestroy()
@Component({
  selector: 'app-popup-payment-method',
  standalone: true,
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    HeadTitleComponent,
    BtnComponent,
    AccountPaymentComponent,
    FieldComponent,
    InputCtrlComponent,
    ReactiveFormsModule,
    NgxMaskDirective,
    CardLogoComponent,
    SvgComponent,
    SpinnerComponent,
    SuccessDialogComponent,
    BtnWrapComponent,
    AddAnotherComponent,
    ManagePaymentMethodComponent,
    InstantSuccessDialogComponent
  ],
  providers: [provideNgxMask()],
})
export class paymentMethodComponent implements OnInit {



  isEdit: boolean = false;
  isPayout!: boolean;
  isSelectPaymentType!: boolean;
  balance!: number;  // No default value
  balanceInstant!: number;  // No default value
  instantAvailable!: number;
  standardAvailable!: number;
  accounts: AccountExternalModel[] = [];
  accountDefault?: AccountExternalModel;
  selectedAccount?: AccountExternalModel;


  //isEdit: boolean = false;
  isNextPage: boolean = false;
  formCard: FormGroup = new FormGroup({
    [FORM_FIELDS.NAME]: new FormControl(null, Validators.required),
    [FORM_FIELDS.ZIP]: new FormControl(null, Validators.required),
    [FORM_FIELDS.EXPIRE]: new FormControl(),
    [FORM_FIELDS.NUMBER]: new FormControl(),
    [FORM_FIELDS.CVV]: new FormControl(),
  });
  formBank: FormGroup = new FormGroup({
    [FORM_FIELDS.ROUTING]: new FormControl(null, [Validators.required]),
    [FORM_FIELDS.ACCOUNT]: new FormControl(null, Validators.required),
    [FORM_FIELDS.ACCOUNT_CONFIRM]: new FormControl(null, Validators.required),
  });
  cardBrand?: CREDIT_CARD_TYPE;
  paymentMethod?: ACCOUNT_METHODS;

  ACCOUNT_METHODS = ACCOUNT_METHODS;
  FORM_FIELDS = FORM_FIELDS;
  ACCOUNT_EXTERNAL_FIELDS = ACCOUNT_EXTERNAL_FIELDS;

  isValidForm = false;
  stripeElements?: any;
  fieldsRef: IForStripeElem[] = []; 
  noInitFields: TFieldStripeForm[] = [FORM_FIELDS.NAME, FORM_FIELDS.ZIP];
  isLoading = false;
  paymentMethodIsInstant: boolean = false;
  showToggleSwitch: boolean = false;
  @Input() isOn: boolean = false;
  @Output() toggle = new EventEmitter<boolean>();
  availablePayout: number | undefined;
  finalPayout: number | undefined;

  toggleSwitch() {
    this.isOn = !this.isOn;
    this.toggle.emit(this.isOn);
  }

  togglePaymentMethod() {
    this.paymentMethodIsInstant = !this.paymentMethodIsInstant;
    if (this.selectedAccount) {
      this.selectedAccount.method = this.paymentMethodIsInstant ? ACCOUNT_METHODS.INSTANT : ACCOUNT_METHODS.STANDARD;
    }
  }


  dataPopup = {
    instantAvailable: 0,
    standardAvailable: 0,
    balance: 0,
    balanceInstant: 0,
    isPayout: false,
    isEdit: false,
    isSelectPaymentType: false,
    accounts: [] as AccountExternalModel[],
  };



  constructor(
    public mainS: MainService,
    private fundS: FundService,
    private stripeS: StripeService,
    private accountApiS: AccountApiService,
    private payoutApiS: PayoutApiService,
    public otherS: OtherService,
    private changeDetectorRef: ChangeDetectorRef,
    private meService: MeService,
    public deviceS: DeviceService,
    private popupS: PopupService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      this.dataPopup = {
        instantAvailable: params['instantAvailable'] !== undefined ? +params['instantAvailable'] : 0,
        standardAvailable: params['standardAvailable'] !== undefined ? +params['standardAvailable'] : 0,
        balance: params['balance'] !== undefined ? +params['balance'] : 0,
        balanceInstant: params['balanceInstant'] !== undefined ? +params['balanceInstant'] : 0,
        isPayout: params['isPayout'] === 'true',
        isEdit: params['isEdit'] === 'true',
        isSelectPaymentType: params['isSelectPaymentType'] === 'true',
        accounts: params['accounts'] ? JSON.parse(params['accounts']) : [],
      };


      if (this.dataPopup.accounts.length > 0) {
        const defaultAccount = this.dataPopup.accounts.find(account => account[ACCOUNT_EXTERNAL_FIELDS.DEFAULT] === true);
        if (defaultAccount) {
          this.onAccountSelected(defaultAccount);
        }
      } else {
      }
      this.fetchBalances();

    });
  }


  close(): void {

  }

  onNext() {
    this.isNextPage = true;
  }

  add() {
    this.isEdit = false;
    this.dataPopup.isSelectPaymentType = true;
  }

  onSave() {

    if (this.paymentMethod === ACCOUNT_METHODS.INSTANT) {
      const additionalData = {
        currency: 'usd',
      };

      this.stripeS.stripe?.createToken(this.findField(FORM_FIELDS.NUMBER).stripeElem, { currency: 'usd' })
        .then((response: IResCreateTokenSuccess) => {
          if (response?.token?.id) {
            this.startLoader();

            this.accountApiS.connectExternal(response?.token?.id)
              .pipe(
                finalize(() => this.stopLoader()),
                untilDestroyed(this),
              )
              .subscribe(data => {
                this.fundS.tokenFromStripe = data?.token?.id;
                this.close();
              });
          } else {
            this.otherS.showError('No TOKEN from Stripe.');
          }
        });
    } else {
      this.startLoader();

      this.accountApiS.connectBank(
        this.formBank.value[FORM_FIELDS.ACCOUNT],
        this.formBank.value[FORM_FIELDS.ROUTING],
      )
        .pipe(
          finalize(() => this.stopLoader()),
          untilDestroyed(this),
        )
        .subscribe(data => this.close());
    }
  }

  selectCard(account: AccountExternalModel): void {
    this.dataPopup.isPayout ? this.selectedAccount = account : null;
  }

  onSelectPaymentMethod(paymentMethod: ACCOUNT_METHODS) {
    this.paymentMethod = paymentMethod;

    this.dataPopup.isSelectPaymentType = false;

    if (this.selectedAccount && this.selectedAccount.availableMethods.includes(ACCOUNT_METHODS.INSTANT) && this.selectedAccount.availableMethods.includes(ACCOUNT_METHODS.STANDARD)) {
      this.showToggleSwitch = true;
      this.paymentMethodIsInstant = this.paymentMethod === ACCOUNT_METHODS.INSTANT;
    } else {
      this.showToggleSwitch = false;
    }

    this.dataPopup.isSelectPaymentType = false;

    setTimeout(() => this.initFields());
  }



  isSaveDisabled() {
    return this.paymentMethod === ACCOUNT_METHODS.INSTANT
      ? this.formCard.invalid || this.formCard.pristine
      : this.formBank.invalid || this.formBank.pristine || this.formBank.get(FORM_FIELDS.ACCOUNT)?.value !==
      this.formBank.get(FORM_FIELDS.ACCOUNT_CONFIRM)?.value;
  }

  onRemoveAccount(account: AccountExternalModel) {
    this.startLoader();

    this.accountApiS.deleteExternal(this.meService.meId, account[ACCOUNT_EXTERNAL_FIELDS.ID] || '')
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.getAccounts();
      });
  }

  onSetDefaultAccount(account: AccountExternalModel) {
    this.startLoader();

    this.accountApiS.setDefault(this.meService.meId, account[ACCOUNT_EXTERNAL_FIELDS.ID] || '')
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.getAccounts();
      });
  }

  private startLoader() {
    this.isLoading = true;

    this.changeDetectorRef.markForCheck();
  }

  private stopLoader() {
    this.isLoading = false;

    this.changeDetectorRef.markForCheck();
  }

showInstantSuccessPage = false;
popupType: 'payout' | 'paymentMethod' | 'oneTimePay' | 'autopayout' = 'paymentMethod';

showSuccessPage(type: 'payout' | 'paymentMethod' | 'oneTimePay' | 'autopayout') {
  this.popupType = type;
  this.showInstantSuccessPage = true;
}

handleSuccessClose() {
  this.showInstantSuccessPage = false;
  this.router.navigate(['/myWalletOfficial']); 
}

  onPayout() {
    if (!this.selectedAccount || !this.selectedAccount[ACCOUNT_EXTERNAL_FIELDS.ID]) {
      return;
    }
  
    this.startLoader();
    const payoutMethod = ACCOUNT_METHODS.INSTANT; 
  
    const payoutObservable = this.meService.GROUP_ASSIGNOR
      ? this.payoutApiS.generalPayout(this.selectedAccount[ACCOUNT_EXTERNAL_FIELDS.ID], payoutMethod)
      : this.payoutApiS.full(this.meService.meId, this.selectedAccount[ACCOUNT_EXTERNAL_FIELDS.ID], payoutMethod);
  
    payoutObservable
      .pipe(
        catchError(err => {
          this.stopLoader();
          return throwError(() => err);
        }),
        finalize(() => this.stopLoader()),
        untilDestroyed(this),
      )
      .subscribe(data => {
        this.showSuccessPage('payout'); 
        this.otherS.showNotification(true, data);
      });
  }

  private getAccounts() {
    this.accountApiS.getAccounts(this.meService.meId)
      .pipe(
        finalize(() => this.stopLoader())
      )
      .subscribe(accounts => {
        this.dataPopup.accounts = accounts;

        for (const account of accounts) {
          if (account.availableMethods.includes('INSTANT') && account.availableMethods.includes('STANDARD')) {
            this.selectedAccount = account;
            this.showToggleSwitch = true;
            this.paymentMethodIsInstant = account.method === 'INSTANT';
            break;
          }
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  onAccountSelected(account: AccountExternalModel) {
    this.selectedAccount = account;

    this.showToggleSwitch = account.availableMethods.includes('INSTANT') && account.availableMethods.includes('STANDARD');
    this.paymentMethodIsInstant = account.method === 'INSTANT';

    this.changeDetectorRef.detectChanges();
  }

  private initFields(): void {
    this.fundS.fields?.forEach((field: TFieldStripeForm) => {
      const refLink = document.querySelector(`#${field}`);
      if (refLink) {
        this.fieldsRef.push({ name: field, elemRef: refLink });
      }
    });

    this.stripeElements = this.stripeS.stripe?.elements();
    const option = { style: this.stripeS.styles, classes: this.stripeS.classes };

    this.fieldsRef?.forEach((item) => {
      if (this.noInitFields?.includes(item.name)) {
        return;
      }

      item.stripeElem = this.stripeElements?.create(item.name, option);
      item.stripeElem?.mount(item.elemRef);
      item.stripeElem?.on('change', (event: IStripeEvent) => this.checkError(event));
    });
  }

  private checkError(event: IStripeEvent): void {
    const field: TFieldStripeForm | undefined = event?.elementType;

    this.isValidForm = !event?.error;

    this.changeDetectorRef.detectChanges();
  }

  private findField(fieldName: TFieldStripeForm): IForStripeElem {
    return this.fieldsRef?.find((field) => field.name == fieldName)!;
  }

  closeToggleSwitch() {
    this.showToggleSwitch = false;
  }

  getPayoutAmount(): string {

      if (!this.dataPopup.balance || !this.selectedAccount) {
        return '';
      }

      const balanceInDollars = this.dataPopup.balance;
      let finalPayout = this.dataPopup.balanceInstant

      if (this.selectedAccount.method === ACCOUNT_METHODS.INSTANT) {
        finalPayout = this.dataPopup.balanceInstant;
      }

      this.finalPayout = finalPayout;
      return `$${finalPayout.toFixed(2)}`;
    
  }

  fetchBalances() {
    this.accountApiS.getBalanceInstant().pipe(untilDestroyed(this)).subscribe(response => {
      const { standardAvailable, instantAvailable } = response;

      this.dataPopup.standardAvailable = standardAvailable.amount;
      this.dataPopup.instantAvailable = instantAvailable.amount;

      this.changeDetectorRef.detectChanges();
    });
  }

  getQuickPayFee(): number {
    if (this.dataPopup.standardAvailable != null && this.dataPopup.instantAvailable != null) {
      return this.dataPopup.standardAvailable - this.dataPopup.instantAvailable;
    }
    return 3.99;
  }
}
