import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { FormsModule } from '@angular/forms';
import { HelperClass, TForPagination } from '@classes/Helper-Classes';
import { MainService } from '@services/main.service';
import { ClassSettingsRequest, IResponse } from '@models/response-and-request';
import { ClassUser } from '@models/user';
import { PhotoComponent } from '@components/__svg_img/photo/photo.component';
import { GetUserNamePipe } from '@pipes/get-name.pipe';
import { BtnComponent } from '@components/btn/btn.component';
import { ISortBy } from '@components/sortBy/sortBy';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { LineComponent } from '@components/line/line.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { IDataPopup } from '@services/popup.service';
import { InviteRefereeToGame } from '@app/dir_group_assignor/reports/report';
import { ClassGameOfficial, IOfficialsAssign, IOfficialsAssignItem } from '@app/dir_group_assignor/games/game';
import { currentLinkActive_officials } from '@app/dir_group_assignor/officials/officials';
import { DeviceService } from '@services/device.service';

@Component({
  selector: 'app-popup-assign',
  standalone: true,
  imports: [CommonModule, HeadTitleComponent, InputCtrlComponent, FormsModule, GetUserNamePipe, BtnComponent, FieldComponent, LineComponent, BtnWrapComponent, SpinnerComponent, PhotoComponent],
  templateUrl: './popup-assign.component.html',
  styleUrls: ['./popup-assign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupAssignComponent extends HelperClass {
  data?: IResponse<ClassUser>;
  officials: Array<ClassUser> = [];
  settingsOfficials: ClassSettingsRequest = { page: 1, size: 5 };

  email = '';
  isMobile = false;
  isReport: boolean = false;
  gameId?: string;
  go?: ClassGameOfficial;
  idOldUser?: string;
  reportId?: string;
  arrIds?: Array<string>; // !!! массив id судей которые уже заасайнены в данной игре

  constructor(
    private mainS: MainService,
    public dialogRef: MatDialogRef<PopupAssignComponent>,
    @Inject(MAT_DIALOG_DATA) public dataPopup: IDataPopup,
    public cd: ChangeDetectorRef,
    public deviceService: DeviceService,
  ) {
    super(cd);
    this.deviceService.isMobile$.subscribe((_isMobile: boolean) => {
      this.isMobile = _isMobile;
    });
    if (dataPopup.isReport) this.isReport = dataPopup.isReport;
    this.gameId = dataPopup.game?.id;
    this.go = dataPopup.go;
    this.idOldUser = dataPopup.user?.id;
    this.reportId = dataPopup.report?.id;
    this.arrIds = dataPopup.arrIds || [];
    this.subscribeToSearch();
  }

  getListOfficials(): void {
    // !!! this.startLoading() здесь не надо
    this.startLoading();
    this.mainS.getListOfficials({ params: this.settingsOfficials }, currentLinkActive_officials).toPromise()
      .then((res?: IResponse<ClassUser>) => {
        if (!res?.content) return;
        this.data = res;
        // !!! исключить судей которые уже заасайнены в данной игре
        this.officials = res?.content?.filter((el: ClassUser) => !this.arrIds?.includes(el.id!));
      })
      .catch((res) => {
      })
      .finally(() => this.endLoading());
  }

  assignOfficials(official: ClassUser): void {
    if (this.startRequest()) return;
    const officialsAssignItem: IOfficialsAssignItem = { officialId: official.id, gameOfficialId: this.go?.id };
    const sendObj: IOfficialsAssign = { publish: true, officialsAssign: [officialsAssignItem] };
    if (this.isReport) sendObj.isReport = true;

    this.mainS.assignOfficials(sendObj).toPromise()
      .then((res?: null) => {
        this.dialogRef.close(official);
      })
      .catch((err) => {
      })
      .finally(() => this.endRequest());
  }

  // =================================

  // !!! IGameRole нету в IGameOfficials для инвайта по емайлу
  inviteByEmail(): void {
    // if (this.startRequest()) return;
    // const sendObj: InviteRefereeToGame = {
    //   gameId: this.gameId!,
    //   gameRole: { id: this.go?.gameRole?.id },
    //   gameOfficialId: this.go?.id,
    //   email: this.ctrlEmail.value?.trim(),
    // };
    //
    // this.invite(sendObj)
    //   .then((res: { id: string, name: string }) => { // res => null, если существующих юзеров пригласил // {id:string,name:string} => если по емайлу пригласил
    //     if (!res) {
    //       console.error('POST /api/admins/gameinvite // приглос по емайлу должен быть ответ {id:string,name:string}', 'err', res);
    //       return;
    //     }
    //     this.invitedUsers.push(res);
    //   })
    //   .catch((err: any) => {
    //   })
    //   .finally(() => this.endRequest());
  }

  // !!! NO DELETE => for inviteByEmail
  invite(sendObj: InviteRefereeToGame): Promise<any> {
    // res => null, если существующих юзеров пригласил // {id:string,name:string} => если по емайлу пригласил
    return this.mainS.inviteRefereeToGame(sendObj).toPromise();
  }

  // === SEARCH =======================
  subscribeToSearch(): void {
    this.subscribeToSearchFromHelperClass().subscribe((searchValue: string) => {
      if (!searchValue) delete this.settingsOfficials.search;
      this.settingsOfficials.search = searchValue;
      this.getListOfficials();
    });
  }

  //  === PAGINATION & SORTING =====================
  paginationMethod(type: TForPagination, size?: number, page?: number, sortBy?: ISortBy): void {
    this.paginationMethodHelperClass(type, this.settingsOfficials, size, page, sortBy);
    this.getListOfficials();
  }

}
