import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { LinkPageComponent } from '@components/link-page/link-page.component';
import { DeviceService } from '@services/device.service';
import { GetLocationStringPipe } from '@pipes/location/get-location-string.pipe';
import { BtnComponent } from '@components/btn/btn.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MeProfileService } from '@app/profile/me-profile/meProfile.service';
import { ProfileService } from '@app/profile/profile.service';
import { OtherService } from '@services/other.service';

@Component({
  selector: 'app-me-profile',
  standalone: true,
  imports: [CommonModule, RouterOutlet, LinkPageComponent, GetLocationStringPipe, BtnComponent, HeadTitleComponent, DropFormCtrlComponent,
    FormsModule, ReactiveFormsModule],
  templateUrl: './meProfile.component.html',
  styleUrls: ['./meProfile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeProfileComponent {
  constructor(
    // public meS: MeService,
    public deviceS: DeviceService,
    // private mainS: MainService,
    // public router: Router,
    // private route: ActivatedRoute,
    // private lodashS: LodashService,
    public otherS: OtherService,
    public meProfileS: MeProfileService,
    public profileS: ProfileService,
    public cd: ChangeDetectorRef,
  ) {
    this.profileS.setData(this.meProfileS.typePageProfile, 'MeProfileComponent');
  }
}
