import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass } from '@classes/Helper-Classes';
import { MainService } from '@services/main.service';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { OtherService } from '@services/other.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDataPopup } from '@services/popup.service';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { BtnComponent } from '@components/btn/btn.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { FormsModule } from '@angular/forms';
import { ClassGameOfficial } from '@app/dir_group_assignor/games/game';

export interface IRequestPopupAddCrewMember {
  gameId?: string;
  officialPositionNumber?: string;
  officialFee?: string;
}

export type IDataPopupAddCrewMember = Pick<IDataPopup, 'game'>;

export type IResponsePopupAddCrewMember = Pick<ClassGameOfficial, 'gameId' | 'id' | 'officialFee' | 'officialPositionName' | 'officialPositionNumber' | 'status'>

@Component({
  selector: 'app-popup-add-crew-member',
  standalone: true,
  imports: [CommonModule, HeadTitleComponent, BtnComponent, BtnWrapComponent, FieldComponent, InputCtrlComponent, FormsModule],
  templateUrl: './popup-add-crew-member.component.html',
  styleUrls: ['./popup-add-crew-member.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupAddCrewMemberComponent extends HelperClass {
  officialPositionNumber: number = 0; // officialPositionName
  officialFee: number = 0;

  constructor(
    private mainS: MainService,
    public gameS: GameService,
    public otherS: OtherService,
    public dialogRef: MatDialogRef<PopupAddCrewMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public dataPopup: IDataPopupAddCrewMember,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.check_officialPositionNumber();
  }

  check_officialPositionNumber(): void {
    const gameOfficials_length = this.dataPopup.game?.gameOfficials?.length || 0;
    this.officialPositionNumber = gameOfficials_length + 1 || 1;
  }

  submit(): void {
    if (this.startRequest()) return;
    const sendObj: IRequestPopupAddCrewMember = {
      gameId: this.dataPopup.game?.id,
      officialPositionNumber: this.officialPositionNumber.toString(),
      officialFee: this.officialFee.toString(),
    };
    // this.endRequest();
    // console.log('sendObj 111:', sendObj.officialFee, sendObj);
    // return;
    this.mainS.addCrewMember(sendObj).toPromise()
      .then((res?: Array<IResponsePopupAddCrewMember>) => {
        // console.log('addCrewMember :', res?.length, res)
        this.close(true);
      })
      .catch(() => {
      })
      .finally(() => this.endRequest());
  }

  close(type: boolean): void {
    this.dialogRef.close(type);
  }
}
