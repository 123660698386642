import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: 'progressBar[value]',
  standalone: true,
  imports: [CommonModule, MatProgressBarModule],
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input() value!: number;
  @Input() color: 'blueDark' | 'newGreen' = 'blueDark'; // TColor
  @Input() bcg: 'white' | 'grey_3' = 'white'; // TColor
  @Input() height: number | string = 22;
  @Input() showPercent = false;
}
