<div class='wrapperCreatePassword'>
    <img src='assets/bcgLogo/bcgLogo_textNotchGreen.svg' alt='notch' class='authLogo'>

    <div *ngIf="!isPasswordChanged" class="title-block">
        <div>
            <h2>Set your password</h2>
            <p>Your new password must be different to previously used passwords</p>
        </div>
    </div>

    <div *ngIf="isPasswordChanged" class="title-block">
        <div>
            <h2>Password reset</h2>
            <p>Your password has been successfully reset. Click below to log in magically</p>
        </div>
    </div>

    <form *ngIf="!isPasswordChanged" #ngForm="ngForm" [formGroup]='form' (ngSubmit)="updatePassword()">
        <field text='Password*' class="password-field">
            <inputCtrl [showErrText]="true" formControlName='password' placeholder='Password'>
            </inputCtrl>
        </field>

        <field text='Confirm Password*' class="confirm-password-field">
            <inputCtrl [showErrText]="true" formControlName='confirmPassword' placeholder='Confirm Password'>
            </inputCtrl>
        </field>

        <p *ngIf="isPasswordChanged" class="success-response o-mg-t-6">The password was successfully changed.</p>
        <p *ngIf="isError" class="error-response o-mg-t-6">{{errorStatus}}</p>

        <btn *ngIf="!isPasswordChanged" [bcg]="'newGreen'" text='Reset Password' [disabled]="form.invalid"></btn>
    </form>

    <btn *ngIf="isPasswordChanged" class="continue" (click)="navigateToLogin()" [bcg]="'newGreen'" text='Continue'></btn>

    <div class="back-to-login">
        <a (click)="navigateToLogin()">
            <meSvg
                svgName='arrow_left&20'
                [h]='16'
                [w]='16'>
            </meSvg>
            Back to Log In
        </a>
    </div>
</div>