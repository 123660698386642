// dashboard-group-assignor.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { dashboardReducer } from "@app/store/dashboard/reducers/dashboard.reducer";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('dashboard', dashboardReducer),
    ],
})
export class DashboardGroupAssignorModule {}
