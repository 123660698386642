import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { ReportService } from '@app/dir_group_assignor/reports/report.service';
import { OfficialsService } from '@app/dir_group_assignor/officials/officials.service';
import { DeviceService } from '@services/device.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { arrCurrentLinkDrop, currentLinkDrop_current, currentLinkDrop_past, TCurrentLinkDrop } from '@classes/dictionary';
import { ClassReportStatusDrop } from '@app/dir_group_assignor/reports/report';
import {
  arrCurrentLinkDrop_officials,
  currentLinkDropActive_officials,
  currentLinkDropPending_officials,
  TCurrentLinkDrop_officials,
} from '@app/dir_group_assignor/officials/officials';
import { FiltersService } from '@components/filters/filters.service';
import { LinkPageComponent } from '@components/link-page/link-page.component';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import {
  FiltersSelectedIconComponent,
} from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { FormsModule } from '@angular/forms';
import { arrLinkObj_groupProfile, TCurrentLinkDrop_groupProfile } from '@app/group-profile/groupProfile';
import { GroupProfileService } from '@app/group-profile/group-profile.service';

// !!! важно. этот компонент используется в том случае если на текущей странице есть динамические фильтры (в URL).
// !!! important. this component is used if the current page has dynamic filters (in the URL).
@UntilDestroy()
@Component({
  selector: 'linkPage-searchFilter',
  standalone: true,
  imports: [CommonModule, LinkPageComponent, DropdownComponent, FiltersSelectedIconComponent, FormsModule],
  templateUrl: './link-page-search-filter.component.html',
  styleUrls: ['./link-page-search-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkPageSearchFilterComponent implements OnChanges, OnInit, AfterViewChecked { // !!! T === TCurrentLinkDrop' | 'ClassReportStatusDrop' | 'TCurrentLinkDrop_officials
  @HostBinding('class.o-none') get hideThisComponent(): boolean {
    return this.deviceS.isDesktop && !this.haveLinkPage; // !!! для десктопа если нет linkPage (переключателя вкладок)
  }

  // @Input() type: 'linkPage' | 'dropdown' | null = null;
  // @Input() arrLink: Array<T & ClassDrop> = []; // !!! передавать сюда ТОЛЬКО если нужно использовать EventEmitter, чтобы родительский компонент понимал какой тип эмитится (for typeScript)
  // currentLinkDrop?: T & ClassDrop; // !!! текущий выбраный
  // @Output() emit = new EventEmitter<T & ClassDrop>(); // ClassDrop
  // !!! TCurrentLinkDrop' | 'ClassReportStatusDrop' | 'TCurrentLinkDrop_officials
  arrLink: Array<ClassDrop> = []; // !!! передавать сюда ТОЛЬКО если нужно использовать EventEmitter, чтобы родительский компонент понимал какой тип эмитится (for typeScript)
  @Input() currentLinkDrop?: ClassDrop; // !!! текущий выбраный => определяется здесь в компоннете (из queryParams достаю). Но нпример для страниц groupProfile_userManagement нет в queryParams названия текущей вкладки и поэтому надо передавать сюда из родительского компонента
  @Input() haveLinkPage = true;
  @Input() filterText = '';
  @Input() needUseUnsavedChanges = false;
  @Output() emit = new EventEmitter<ClassDrop>(); // !!! использовать в крайних случаях. Потому что этот компонент напрямую в SettingsRequestService апдейтит settings

  constructor(
    private gameS: GameService,
    private reportS: ReportService,
    private officialsS: OfficialsService,
    private groupProfileS: GroupProfileService,
    public deviceS: DeviceService,
    public filtersS: FiltersService, // for-filters===
    private settingsRequestS: SettingsRequestService, // for-settings===
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes?.currentLinkDrop?.currentValue) {
    //   console.log('currentLinkDrop :', this.currentLinkDrop)
    // this.cd.detectChanges()
    // }
  }

  ngOnInit() {
    this.subscribeToSettings();
    this.getArrLink_byCurrentLink();
  }

  ngAfterViewChecked() {
    this.cd.detectChanges(); // !!! NO DELETE
  }

  subscribeToSettings(): void {
    this.settingsRequestS.settingsSub$.pipe(untilDestroyed(this)).subscribe((res) => {
      // console.log('LinkPageSearchFilter subscribeToSettings :', res)
      if (this.settingsRequestS.is_currentPath_games) {
        this.currentLinkDrop = res?.currentLink_games === 'past' ? currentLinkDrop_past : currentLinkDrop_current;
        this.cd.detectChanges();
      }
      if (this.settingsRequestS.is_currentPath_reports) {
        this.currentLinkDrop = new ClassReportStatusDrop(res?.currentLink_reports || 'DUE');
        this.cd.detectChanges();
      }
      if (this.settingsRequestS.is_currentPath_officials) {
        // console.log('res?.currentLink_officials :', res?.currentLink_officials, res)
        this.currentLinkDrop = res?.currentLink_officials === 'PENDING' ? currentLinkDropPending_officials : currentLinkDropActive_officials;
        this.cd.detectChanges();
      }
      // if (this.settingsRequestS.is_currentPath_groupProfile) {
      //   this.currentLinkDrop = getLinkObj_groupProfile(res?.currentLink_groupProfile!);
      //   console.log('currentLinkDrop :', this.currentLinkDrop);
      //   this.cd.detectChanges();
      // }
    });
  }

  getArrLink_byCurrentLink(): void {
    // console.log('is_currentPath_games :', this.settingsRequestS.is_currentPath_games)
    // console.log('is_currentPath_reports :', this.settingsRequestS.is_currentPath_reports)
    // console.log('is_currentPath_officials :', this.settingsRequestS.is_currentPath_officials)
    // console.log('is_currentPath_groupProfile :', this.settingsRequestS.is_currentPath_groupProfile)
    if (this.settingsRequestS.is_currentPath_games) this.arrLink = arrCurrentLinkDrop;
    if (this.settingsRequestS.is_currentPath_reports) {
      this.reportS.arrCurrentLinkSub$.pipe(untilDestroyed(this)).subscribe((arrCurrentLink) => {
        // this.arrLink = arrCurrentLink as Array<T & ClassReportStatusDrop>;
        this.arrLink = arrCurrentLink;
      });
    }
    if (this.settingsRequestS.is_currentPath_officials) this.arrLink = arrCurrentLinkDrop_officials;
    if (this.settingsRequestS.is_currentPath_groupProfile) this.arrLink = arrLinkObj_groupProfile;
    // console.log('arrLink :', this.arrLink)
    this.cd.detectChanges();
  }

  linkPageEmit(item: ClassDrop): void { // TCurrentLinkDrop | ClassReportStatusDrop
    // console.log('linkPageEmit item:', item)
    // console.log('linkPageEmit currentLinkDrop :', this.currentLinkDrop)
    if (this.currentLinkDrop?.upperCase === item?.upperCase) return; // !!! если нажал на текущий выбраный то не надо эмитить
    this.currentLinkDrop = item;
    this.settingsRequestS.linkPageEmit(item);
    if (this.settingsRequestS.is_currentPath_games) this.gameS.currentLinkObj = { currentLink: item as TCurrentLinkDrop };
    if (this.settingsRequestS.is_currentPath_reports) this.reportS.currentLinkObj = { currentLink: item as ClassReportStatusDrop };
    if (this.settingsRequestS.is_currentPath_officials) this.officialsS.currentLinkObj = { currentLink: item as TCurrentLinkDrop_officials };
    if (this.settingsRequestS.is_currentPath_groupProfile) {
      this.groupProfileS.currentLinkObj = { currentLink: item as TCurrentLinkDrop_groupProfile };
      this.groupProfileS.navigateBy_currentLinkDrop(item as TCurrentLinkDrop_groupProfile);
    }
    this.emit.emit(this.currentLinkDrop);

    // if (this.settingsRequestS.is_currentPath_games) {
    //   const currentLinkDrop_curPast = item as TCurrentLinkDrop;
    //   this.settingsRequestS.updateSettings({
    //     page: 1, // !!! при переключении вкладки нужно сбрасывать страницу
    //     currentLink_games: currentLinkDrop_curPast?.upperCase,
    //     typeEmitSetting: 'currentLink',
    //   }, 'currentLink');
    //   this.gameS.currentLinkObj = { currentLink: currentLinkDrop_curPast };
    // }
    // if (this.settingsRequestS.is_currentPath_reports) {
    //   const currentLinkDrop_reports = item as ClassReportStatusDrop;
    //   // const currentLinkDrop_reports = item;
    //   this.settingsRequestS.updateSettings({
    //     page: 1, // !!! при переключении вкладки нужно сбрасывать страницу
    //     currentLink_reports: currentLinkDrop_reports?.upperCase,
    //     typeEmitSetting: 'currentLink',
    //     statuses: currentLinkDrop_reports?.upperCase,
    //   }, 'currentLink');
    //   // this.reportS.setCurrentLink_reports(currentLinkDrop_reports.upperCase)
    //   this.reportS.currentLinkObj = { currentLink: currentLinkDrop_reports };
    // }
    // if (this.settingsRequestS.is_currentPath_officials) {
    //   const currentLinkDrop_officials = item as TCurrentLinkDrop_officials;
    //   this.settingsRequestS.updateSettings({
    //     page: 1, // !!! при переключении вкладки нужно сбрасывать страницу
    //     currentLink_officials: currentLinkDrop_officials?.upperCase,
    //     typeEmitSetting: 'currentLink',
    //   }, 'currentLink');
    //   // this.reportS.setCurrentLink_reports(currentLinkDrop_reports.upperCase)
    //   this.officialsS.currentLinkObj = { currentLink: currentLinkDrop_officials };
    // }
    // this.emit.emit(this.currentLinkDrop);
  }
}