import { ChangeDetectorRef, Directive, ElementRef, HostListener, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { UtilsService } from '@services/utils.service';

@Directive({
  selector: '[notchTooltip]',
  exportAs: 'notchTooltip',
  standalone: true,
})
export class TooltipDirective implements OnChanges {
  @Input('notchTooltip') tooltipText: string = '';
  // @Input() isUpperCaseForFirstLetters: boolean = true;
  @Input('notchToolTipClassName') tooltipClassName = '';

  private tooltipElement!: HTMLElement | null;
  isShowTooltip = false;
  #additionalText: string = ' (Fastest)';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    public cdr: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes?.tooltipText?.currentValue) {
    //     console.log('tooltipText :', this.tooltipText)
    // }
  }

  @HostListener('mouseenter') onMouseEnter() {
    // this.tooltipText = this.isUpperCaseForFirstLetters ? UtilsService.formattedLetters(this.tooltipText) : this.tooltipText;
    // пока не понятно нужно ли это везде поэтому пока что так
    if (this.tooltipText === 'Notch Pay') this.tooltipText += this.#additionalText;
    this.showTooltip();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hideExistingTooltips();
    this.hideTooltip();
    if (this.tooltipText === 'Notch Pay (Fastest)') {
      this.tooltipText = this.tooltipText.replace(this.#additionalText, '');
    }
  }

  @HostListener('touchend') onTouchEnd() {
    this.showTooltip();
  }

  private showTooltip() {
    this.hideExistingTooltips();
    this.tooltipElement = this.renderer.createElement('div');
    this.renderer.addClass(this.tooltipElement, 'custom-tooltip');
    if (this.tooltipClassName) {
      this.renderer.addClass(this.tooltipElement, this.tooltipClassName);
    }
    if (this.tooltipElement) {
      this.tooltipElement.innerHTML = this.tooltipText;
      const rect = this.el.nativeElement.getBoundingClientRect();
      this.renderer.appendChild(document.body, this.tooltipElement);
      // const top = rect.top + window.scrollY - this.tooltipElement.offsetHeight + 10;
      const top = rect.top + window.scrollY - this.tooltipElement.offsetHeight;
      const left = rect.left - this.tooltipElement.offsetWidth / 2 - 7;
      // console.log(' scrollY :', window.scrollY)
      // console.log(' rect.top :', rect.top)
      // console.log(' rect.left :', rect.left)
      // console.log(' offsetWidth :', this.tooltipElement.offsetWidth)
      // console.log(' offsetHeight :', this.tooltipElement.offsetHeight)
      // console.log('result top :', top)
      // console.log('result left :', left)

      this.renderer.setStyle(this.tooltipElement, 'top', `${top - 15}px`);
      this.renderer.setStyle(this.tooltipElement, 'left', `${left + 15}px`);
      this.isShowTooltip = true;
    }
  }

  hideTooltip() {
    if (this.tooltipElement) {
      this.renderer.removeChild(document.body, this.tooltipElement);
      this.tooltipElement = null;
      this.isShowTooltip = false;
    }
  }

  private hideExistingTooltips() {
    const existingTooltips = document.querySelectorAll('.custom-tooltip');
    existingTooltips.forEach((tooltip) => {
      this.renderer.removeChild(this.renderer.parentNode(tooltip), tooltip);
      this.renderer.removeChild(document.body, tooltip);
      this.renderer.removeChild(document.body, tooltip);
    });
    this.cdr.detectChanges();
  }
}
