import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { HelperClass } from '@classes/Helper-Classes';
import { CompetitionService } from '@app/dir_group_assignor/competitions/competition.service';
import { MainService } from '@services/main.service';
import { OtherService } from '@services/other.service';
import { TUrlNavigationCompetition } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { IDataPopup_forPopupConfirm, PopupService } from '@services/popup.service';
import { from, of, switchMap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'deleteItemForCompetitions[page][disabled][id]',
  standalone: true,
  imports: [CommonModule, SvgComponent],
  templateUrl: './delete-item-for-competitions.component.html',
  styleUrls: ['./delete-item-for-competitions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteItemForCompetitionsComponent extends HelperClass {
  @Input() page!: TUrlNavigationCompetition;
  @Input() disabled!: boolean;
  @Input() id!: string;
  @Output() responseEmit = new EventEmitter<string>(); // id deleted item

  constructor(
    public competitionS: CompetitionService,
    public mainS: MainService,
    public otherS: OtherService,
    private popupS: PopupService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
  }

  deleteItem(): void {
    if (!this.id) { // !!! не сохранен на сервере. Не надо запрос на сервер
      this.responseEmit.emit();
      return;
    }

    if (this.startRequest()) return;
    const dataPopup: IDataPopup_forPopupConfirm = {
      textTitle: 'Please confirm to delete.',
      textBtnApply: 'Proceed',
    };

    from(this.popupS.openPopupConfirm(dataPopup, true))
      .pipe(
        switchMap((resPopup: boolean) => {
          if (resPopup) {
            return this.competitionS.deleteItem(this.id, this.page).pipe(
              tap(res => this.responseEmit.emit()),
            );
          } else {
            return of(null);
          }
        }),
        untilDestroyed(this),
      )
      .subscribe((res) => {
        this.endRequest();
      });
  }

}
