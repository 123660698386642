import { Pipe, PipeTransform } from '@angular/core';
import { ClassGame } from '@app/dir_group_assignor/games/game';

@Pipe({ name: 'showBtnPublish', standalone: true })
export class ShowBtnPublishPipe implements PipeTransform {

  // https://notch.atlassian.net/browse/NOT30-322
  // если на игре хоть одна роль unpublished то кнопка Publish должна быть активна.
  // если вернется true то значит есть unpublished, тоесть кнопка активна
  transform(games: Array<ClassGame>): boolean {
    let result = false;
    games?.forEach((el) => {
      el?.gameOfficials?.forEach((go) => {
        if (go?.status === 'UNPUBLISHED') result = true;
      });
    });
    return result;
  }
}
