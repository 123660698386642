import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClassGame } from '@app/dir_group_assignor/games/game';
import { GetMatTooltipCancelReasonPipe } from '@pipes/game_and_report/get-mat-tooltip-cancel-reason.pipe';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MeService } from '@services/me.service';
import { DeviceService } from '@services/device.service';
import { AdjustmentStatusesService } from '@app/group-profile/components/group-profile-adjustment-statuses/adjustment-statuses.service';

@Component({
  selector: 'cancelationDetails[game]',
  standalone: true,
  imports: [CommonModule, GetMatTooltipCancelReasonPipe, DropdownComponent, FieldComponent, ReactiveFormsModule, FormsModule],
  templateUrl: './cancelation-details.component.html',
  styleUrls: ['./cancelation-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelationDetailsComponent {
  @Input() game?: ClassGame;

  constructor(
    public meS: MeService,
    public deviceS: DeviceService,
    public adjustmentStatusesS: AdjustmentStatusesService,
    // public cd: ChangeDetectorRef,
  ) {
  }

}
