import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DeviceService } from '@services/device.service';
import { OtherService } from '@services/other.service';
import { ProfileUserService } from '@app/profile/profile-user/profile-user.service';
import { BtnComponent } from '@components/btn/btn.component';
import { ContactItemComponent } from '@app/profile/additional_components/contact-item/contact-item.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { GetLocationStringPipe } from '@pipes/location/get-location-string.pipe';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { PhotoComponent } from '@components/__svg_img/photo/photo.component';
import { FormsModule } from '@angular/forms';
import { BooleanToStringPipe } from '@pipes/string/boolean-to-string.pipe';
import { GetAgeByDateOfBirthPipe } from '@pipes/get-age-by-date-of-birth.pipe';
import { ProfileService } from '@app/profile/profile.service';
import { FormatPhonePipe } from '@pipes/phone/format-phone.pipe';
import {
  TitleAndNavigationForProfileComponent,
} from '@app/profile/additional_components/title-and-navigation-for-profile/title-and-navigation-for-profile.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { TUserRoleUpperCase } from '@models/user';
import { UssfCertPipe } from '@pipes/ussfCert/ussf-cert.pipe';

@UntilDestroy()
@Component({
  selector: 'app-profile-user-info',
  standalone: true,
  imports: [CommonModule, BtnComponent, ContactItemComponent, DropFormCtrlComponent, GetLocationStringPipe, HeadTitleComponent,
    PhotoComponent, FormsModule, BooleanToStringPipe, GetAgeByDateOfBirthPipe, FormatPhonePipe, TitleAndNavigationForProfileComponent, UssfCertPipe],
  templateUrl: './profile-user-info.component.html',
  styleUrls: ['./profile-user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileUserInfoComponent {
  // !!! если GROUP_ASSIGNOR смотрит ADMIN, то в этом случае надо скрыть некоторую инфу
  // whoWatching?: TUserRoleUpperCase;  // !!! кто просматривает чужой профиль (me)
  userRole?: TUserRoleUpperCase; // !!! чей профиль смотрит
  // isMe = false; // !!! если GROUP_ASSIGNOR смотрит ADMIN, то в этом случае надо скрыть некоторую инфу

  constructor(
    public route: ActivatedRoute,
    public deviceS: DeviceService,
    public profileS: ProfileService,
    public profileUserS: ProfileUserService,
    public otherS: OtherService,
    public cd: ChangeDetectorRef,
  ) {
    this.getUserIdFromQueryParams();
  }

  getUserIdFromQueryParams(): void {
    combineLatest({ params: this.route.params, queryParams: this.route.queryParams }).pipe(untilDestroyed(this))
      .subscribe((res) => {
        const idUser = res?.params?.idUser;
        // this.whoWatching = res?.queryParams?.whoWatching;
        this.userRole = res?.queryParams?.userRole;
        if (idUser) this.profileUserS.getContactById(idUser, this.userRole);
      });

    // this.route.params.pipe(untilDestroyed(this)).subscribe((res) => {
    //   console.log('route.params :', res)
    //   if (res?.idUser) this.profileUserS.getContactById(res.idUser, res?.userRole);
    // });
    // this.route.queryParams.pipe(untilDestroyed(this)).subscribe((res) => {
    //   console.log('queryParams :', res)
    //   if (res?.idUser) this.profileUserS.getContactById(res.idUser, res?.userRole);
    // });
  }
}
