import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { IJwtTokenDecoder, MeService } from '@services/me.service';
import { banRoutes_for_ADMIN, banRoutes_for_GROUP_ASSIGNOR, banRoutes_for_OFFICIAL, banRoutes_for_SUB_ASSIGNOR, urlDashboard } from '@app/app.module';
import { TUserRoleUpperCase } from '@models/user';

@Injectable({ providedIn: 'root' })
export class BanRouteGuard {
  constructor(
    private meS: MeService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const jwtTokenDecoder: IJwtTokenDecoder | null = this.meS.decode_jwt_token();
    if (!jwtTokenDecoder) return true;
    const userRole: TUserRoleUpperCase | undefined = jwtTokenDecoder?.['role-current'];
    if (!userRole) {
      console.error('!!! BanRouteGuard userRole :', userRole);
      return true;
    }

    let thisRoutNeedBan = false;

    switch (userRole) {
      case 'OFFICIAL':
        banRoutes_for_OFFICIAL.forEach(el => {
          if (state?.url?.split('?')[0]?.includes(el)) thisRoutNeedBan = true;
        });
        break;

      case 'GROUP_ASSIGNOR':
        banRoutes_for_GROUP_ASSIGNOR.forEach(el => {
          if (state?.url?.split('?')[0]?.includes(el)) thisRoutNeedBan = true;
        });
        break;

      case 'SUB_ASSIGNOR':
        banRoutes_for_SUB_ASSIGNOR.forEach(el => {
          if (state?.url?.split('?')[0]?.includes(el)) thisRoutNeedBan = true;
        });
        break;

      case 'ADMIN':
        banRoutes_for_ADMIN.forEach(el => {
          if (state?.url?.split('?')[0]?.includes(el)) thisRoutNeedBan = true;
        });
        break;

      default:
        console.warn(`Unhandled role: ${userRole}`);
    }

    if (thisRoutNeedBan) {
      this.router.navigate([urlDashboard]);
      return false;
    } else {
      return true;
    }
  }

}
