import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeTableService } from '@components/_table/me-table.service';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { MatMenuModule } from '@angular/material/menu';
import { CeilTableDirective } from '@components/_table/directives/ceil-table.directive';
import { DeviceService } from '@services/device.service';

@Component({
  selector: 'app-tableCeilActions',
  standalone: true,
  imports: [CommonModule, SvgComponent, MatMenuModule, CeilTableDirective],
  templateUrl: './table-ceil-actions.component.html',
  styleUrls: ['./table-ceil-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableCeilActionsComponent<T> {
  // @HostBinding('class.t-actionsItemTable--true') get isDesktopView(): boolean {
  //   return this.deviceS.isDesktop;
  // }
  // @HostBinding('class.t-actionsItemTable--false') get hideThisComponent(): boolean {
  //   return !this.deviceS.isDesktop;
  // }
  @Input() disabled = false;

  constructor(
    public meTableS: MeTableService<T>,
    public deviceS: DeviceService,
    public cd: ChangeDetectorRef,
  ) {
  }
}
