import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OtherService } from '@services/other.service';
import { PopupService } from '@services/popup.service';
import { TSvgName } from '@components/__svg_img/svg/forSvg';
import { ProfileService } from '@app/profile/profile.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  // !!!
  // selector: 'contactItem[text1][width1]',
  selector: 'contactItem[text1]',
  standalone: true,
  imports: [CommonModule, SvgComponent, MatTooltipModule],
  templateUrl: './contact-item.component.html',
  styleUrls: ['./contact-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactItemComponent implements OnChanges {
  // typePageProfile: TypePageProfile | null = null
  @Input() text1: string = '';
  @Input() text2?: string | null = '';
  @Input() width1: string | number = ''; // если передано, то устанавливаем ширину для левого блока
  // @Input() width2: string | number = ''; // если передано, то устанавливаем ширину для правого блока
  width2: string = '';

  @Input() svgName1?: TSvgName;
  @Input() matTooltipSVG1: string = '';
  @Input() widthSvg1?: string | number = ''; // если передано, то устанавливаем ширину и высоту svg

  @Input() svgName2?: TSvgName;
  @Input() matTooltipSVG2: string = '';
  @Input() widthSvg2?: string | number = ''; // если передано, то устанавливаем ширину и высоту svg

  // @Input() isBorderBottom = true; // для последнего элемента не нужен бордер => передавать false

  constructor(
    public profileS: ProfileService,
    public otherS: OtherService,
    public popupS: PopupService,
    public cd: ChangeDetectorRef,
  ) {
    // this.subscribeToTypePageProfile()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.width1?.currentValue) {
      this.width2 = `calc(100% - ${this.width1}px)`;
      // this.cd.detectChanges()
    }
  }

  // subscribeToTypePageProfile(): void {
  //   this.profileS.typePageProfile$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
  //   })
  // }
}
