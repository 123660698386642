import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/overlay';
import { DeviceService } from '@services/device.service';
import { ClassSettingsRequest } from '@models/response-and-request';
import { MeTableService } from '@components/_table/me-table.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { UtilsService } from '@services/utils.service';
import { FiltersService } from '@components/filters/filters.service';

@UntilDestroy()
@Directive({ selector: '[dataTable]', standalone: true })
export class DataTableDirective<T> {
  @Input() needScroll = true; // в некоторых случаях не нужно отправлять запрос на сервер при скролле (например на страницах myWallet & payments)

  constructor(
    private elRef: ElementRef,
    private r: Renderer2,
    private meTableS: MeTableService<T>,
    private scrollDispatcher: ScrollDispatcher,
    private deviceS: DeviceService,
    private settingsRequestS: SettingsRequestService, // for-settings===
    private filtersS: FiltersService, // for-filters===
    private cd: ChangeDetectorRef,
  ) {
    this.subscribeToScroll();
    this.subscribeTo_showFilters_forMobile();
  }

  // === SCROLL для того чтобы на мобиле загружать контент для таблиц при скролле ==========================
  private currentPositionScroll: number = 0;
  // andrei delete after finish dynamic filters
  @Output() emitInfiniteLoading = new EventEmitter<Pick<ClassSettingsRequest, 'page'>>();

  private subscribeToScroll(): void {
    this.scrollDispatcher.scrolled().pipe(untilDestroyed(this))
      .subscribe((res: void | CdkScrollable) => {
        if (!this.needScroll) return; // в некоторых случаях не нужно отправлять запрос на сервер при скролле (например на страницах myWallet & payments)
        const dataTable = this.meTableS.dataTable;
        if (this.deviceS.isDesktop) return; // !!! загрузка при скролле нужно только для мобилы
        if (this.meTableS.loadingTable) return; // !!! если ещё не получен ответ с сервера, то не нужно эмитить
        // if (!this.meTableS.dataTable?.content?.length) return; // !!! если уже закончился контент на сервере, то не нужно эмитить
        const isLastPage = UtilsService.isLastPage(dataTable);
        // console.log('isLastPage :', isLastPage)
        if (isLastPage) return; // !!! если уже закончился контент на сервере, то не нужно эмитить
        if (!res) return;
        const scrollingNativeElement = res.getElementRef().nativeElement;
        const scrollTop = scrollingNativeElement.scrollTop;
        const clientHeight = scrollingNativeElement.clientHeight;
        const scrollHeight = scrollingNativeElement.scrollHeight;
        // if (this.checkScrollDirection(scrollTop) === 'top') {
        //   console.error('scrollTop :', )
        // }
        if (this.checkScrollDirection(scrollTop) === 'top') return; // !!! если вверх скролл то НЕ надо эмитить

        if ((scrollTop + clientHeight + 100) > scrollHeight) { // !!! вызывается когда проскроллил в самый низ страницы
          if (typeof dataTable?.number !== 'number') {
            console.error('WrapPageDirective => dataTable.number:', dataTable?.number);
          } else {
            // console.log('dataTable :', dataTable.number, dataTable.totalPages)
            const page = (dataTable.number + 1) + 1;
            // this.emitInfiniteLoading.emit({ page });
            // console.log('DIRECTIVE content?.length:', dataTable?.content?.length,
            //   ' => loadingTable :', this.meTableS.loadingTable,
            //   '  isFirstLoadPage :', this.settingsRequestS.isFirstLoadPageSub$?.getValue(),
            //   '  typeEmitSetting :', this.settingsRequestS.settings.typeEmitSetting);
            // this.meTableS.delayForTest = true

            if (this.meTableS.needUseDynamicFilters) {
              const newSettings: ClassSettingsRequest = { page, typeEmitSetting: 'infiniteLoading' };
              this.settingsRequestS.infiniteLoading = true;
              this.settingsRequestS.updateSettings(UtilsService.removeEmptyKeysFromObject({
                ...newSettings,
                typeEmitSetting: 'infiniteLoading',
              }), 'infiniteLoading', ' DataTableDirective ');
              // this.cd.detectChanges()
            } else {
              this.emitInfiniteLoading.emit({ page });
            }
          }
        }
      });
  }

  // !!! определить направление скролла. Если вверх то НЕ надо эмитить
  private checkScrollDirection(scroll: number): 'down' | 'top' {
    let result: 'down' | 'top';
    result = scroll > this.currentPositionScroll ? 'down' : 'top';
    this.currentPositionScroll = scroll;
    return result;
  }

  // !!! if opened filters for mobile => need lock(ban) scroll parent component
  private subscribeTo_showFilters_forMobile(): void {
    this.filtersS.showFilters_forMobile$.pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (res) {
          this.r.setStyle(this.elRef.nativeElement, 'height', '90%');
          this.r.setStyle(this.elRef.nativeElement, 'minHeight', '90%');
          this.r.setStyle(this.elRef.nativeElement, 'maxHeight', '90%');
          this.r.setStyle(this.elRef.nativeElement, 'overflow-y', 'hidden');
        } else {
          this.r.removeStyle(this.elRef.nativeElement, 'height');
          this.r.removeStyle(this.elRef.nativeElement, 'minHeight');
          this.r.removeStyle(this.elRef.nativeElement, 'maxHeight');
          this.r.removeStyle(this.elRef.nativeElement, 'overflow-y');
        }
      });
  }

}
