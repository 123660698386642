import { Pipe, PipeTransform } from '@angular/core';
import { CompetitionService } from '@app/dir_group_assignor/competitions/competition.service';

@Pipe({ name: 'checkExistUsers', standalone: true, pure: false }) // NO DELETE pure: false
export class CheckExistUsersPipe implements PipeTransform {

  constructor(
    public competitionS: CompetitionService,
  ) {
  }

  transform(any: any): boolean {
    return this.checkExistUsers();
  }

  checkExistUsers(): boolean {
    const existUsers = this.competitionS.users?.filter((el) => {
      if (el?.name?.trim() || el?.role?.trim()) return el;
      return null;
    })?.length;
    return !!existUsers;
  }

}
