<div class='wrapInputAddress' [style]='{w:w}|getStyles'>
  <input #inputRef [(ngModel)]='value'
         class='o-input' [ngClass]='"o-input--"+bcg'
         (keyup.enter)='addressFromGoogle($event.target)' (focusout)='addressFromGoogle($event.target)'
         [class]='"o-input--"+({value:value,active:active,disabled:disabled,error:error}|getStateInput)'
         [placeholder]='placeholder ? placeholder : "Street Address"'
         [maxLength]='mainS.maxlengthInput'>

  <error *ngIf='errRequired' text='This field is required.'></error>
  <!--  <error *ngIf='errMaxLength' text='This field must be at most {{mainS.maxlengthInput}} characters.'></error>-->
</div>
