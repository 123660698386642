import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'utcToLocal',
  standalone: true,
  pure: false
})
export class UtcToLocalPipe implements PipeTransform {

  transform(value: string | Date, format: string = 'medium'): string | null {
    const datePipe = new DatePipe('en-US');

    // Parse the UTC date
    const utcDate = typeof value === 'string' ? new Date(value) : value;

    // Get the user's timezone offset in minutes
    const userTimezoneOffset = new Date().getTimezoneOffset();

    // Calculate the local date by adding the offset
    const localDate = new Date(utcDate.getTime() - userTimezoneOffset * 60000);

    // Use the DatePipe to format the local date
    return datePipe.transform(localDate, format);
  }
}
