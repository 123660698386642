import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MainService } from '@services/main.service';
import { ControlValueAccessor, FormsModule, NgControl } from '@angular/forms';
import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { Geo, IGeoAddress, ITimezone } from '@classes/geo';
import { shortListTimezone } from '@models/fullListTimezone';
import { TFieldLoc } from '@components/__blocks/location/location.service';
import { CommonModule } from '@angular/common';
import { GetStateInputPipe } from '@pipes/get-state-input.pipe';
import { ErrorComponent } from '@components/__info_text_message_error_warning/error/error.component';
import { TColor } from '@models/ICssStyles';
import { GetStylesPipe } from '@pipes/css/get-styles.pipe';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'inputAddress',
  templateUrl: './input-address.component.html',
  styleUrls: ['./input-address.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, GetStateInputPipe, ErrorComponent, GetStylesPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputAddressComponent implements ControlValueAccessor, OnChanges, AfterViewInit {
  @ViewChild('inputRef') inputRef?: ElementRef;
  @Input() bcg: TColor | 'transparent' = 'white'; // background // TColor = 'blueDark' | 'grey' | 'grey_1' | 'white' | 'blue' | 'green' | 'red';
  @Input() isFocus = false; // если true передал значит надо фокус сделать на этом поле
  @Input() placeholder = '';

  @Input() w: string | number = ''; // width // если надо ширину задать

  @Output() changeVal = new EventEmitter<{ value?: string | IGeoAddress | ITimezone, ctrl: TFieldLoc }>();

  isExistInputRef = false;
  readonly listTimezone: Array<ITimezone> = shortListTimezone as Array<ITimezone>;

  private onChange!: (value: string) => void;
  private onTouched!: () => void;

  constructor(
    public mainS: MainService,
    public ngControl: NgControl,
    public renderer: Renderer2,
    private focusMonitor: FocusMonitor,
    private geo: Geo,
    public cd: ChangeDetectorRef,
  ) {
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  // === GEO ===============
  addressFromGoogle(target: any): void {
    // console.log('addressFromGoogle :', target?.value);
    const value = target?.value;
    if (!value && typeof value == 'string') this.changeVal.emit({ value, ctrl: 'street' }); // если стёр адрес

    if (value && typeof value == 'string') {
      // console.log('addressFromGoogle :', target?.value);
      this.geo.setAddressFromGoogle(value.trim())
        .then((address: IGeoAddress) => {
          // console.log('setAddressFromGoogle :', address);
          const coords = { latitude: +address?.coords?.lat!, longitude: +address?.coords?.lng! };
          const newLoc = { ...address, latitude: coords.latitude, longitude: coords.longitude };
          this.changeVal.emit({ value: newLoc, ctrl: 'street' });
          this.getTimezone(coords);
        })
        .catch((err: null) => {
          // this.locationS.loc$.next({}) && this.addressFromGoogle.emit(err); =>  обнулять данные не получается,
          // потому что когда юзер просто мимо нажал (чтоб закрылся список адресов),
          // то приходит ошиька. Но в этом случаем не надо обнулять адрес
          // this.changeField(this.locationS.loc, 'street'); // поэтому если ошибка с гугла, то возвращаю прежнее значение
          // this.changeVal.emit({value: this.locationS.loc, ctrl:'street'}); // поэтому если ошибка с гугла, то возвращаю прежнее значение
          this.changeVal.emit({ value: undefined, ctrl: 'street' }); // поэтому если ошибка с гугла, то возвращаю прежнее значение
        })
        // .finally(() => this.locationS.checkErrorLoc([])); // если зипкод не пришел, сразу отобразить ошибку
        .finally(() => {
        }); // если зипкод не пришел, сразу отобразить ошибку
    }
  }

  private getTimezone(coords: { latitude?: number; longitude?: number }): void {
    this.geo.getTimezone(coords)
      // .then((timezone: ITimezone) => this.changeField(timezone, 'timezone'))
      .then((timezone: ITimezone) => this.changeVal.emit({ value: timezone, ctrl: 'timezone' }))
      .catch(() => {
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('ngOnChanges :', changes)
    if (changes?.touch?.currentValue) this.touched = true; // чтобы из родит.компонент получить и отреагировать
    // if (changes?.touch?.currentValue) this.onTouched(); // чтобы в родит.компонент передать

    if (changes?.isFocus?.currentValue) {
      setTimeout(() => {
        this.inputRef?.nativeElement?.focus();
      });
    }

    if (changes?.placeholder?.currentValue) {
      this.placeholder = this.placeholder?.replace('*', '');
    }
  }

  ngAfterViewInit() {
    // console.log('this.inputRef?.nativeElement :', this.inputRef?.nativeElement);
    if (this.inputRef?.nativeElement && !this.isExistInputRef) { // чтобы только один раз отработало это
      this.isExistInputRef = true;
      this.geo.autoCompletePlaces(this.inputRef?.nativeElement);
    }

    if (this.ngControl?.control) {
      this.setDisabledState(this.ngControl.control.disabled);
      this.subscribeToFocused();
    }
  }

  writeValue(value: string): void {
    // console.log('writeValue :', value)
    // this.formCtrl.setValue(value, { emitEvent: false });
    // this.setState()
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.inputRef?.nativeElement) this.disabled = isDisabled;
  }

  subscribeToFocused(): void {
    this.focusMonitor.monitor(this.inputRef?.nativeElement, true).pipe(untilDestroyed(this))
      .subscribe((origin: FocusOrigin) => {
        // console.log('focusMonitor.monitor :', origin)
        this.active = !!origin;
        this.cd.detectChanges();
      });
  }


  // === GETTERS & SETTERS =======================
  set value(value: string) {
    if (this.inputRef?.nativeElement) {
      if (value[0] === ' ') this.inputRef.nativeElement.value = ''; // запрет пробелов в начале строки

      if (typeof this.inputRef?.nativeElement?.value == 'string') {
        this.changeVal.emit(this.inputRef.nativeElement.value);
        // this.ngControl.control?.patchValue(this.inputRef.nativeElement.value); // в чем разница протестить
        this.ngControl.control?.setValue(this.inputRef.nativeElement.value); // в чем разница протестить
        this.onChange(this.inputRef.nativeElement.value); // чтобы в родит.компонент передать
        this.onTouched();  // чтобы в родит.компонент передать
        // this.setState()
        // this.inputRef.nativeElement.value ? this.setState('filled', true) : this.setState('empty', true);
      }
    }
  }

  get value(): string {
    // console.log('value :', this.ngControl.control?.value)
    return this.ngControl.control?.value;
  }

  get errMaxLength(): boolean {
    // return this.ngControl.control?.value?.length >= this.maxLength;
    return this.ngControl.control?.value?.length >= this.mainS.maxlengthInput;
  }

  get errRequired(): boolean {
    return !!this.ngControl.control?.hasError('required') && !!this.ngControl.control?.touched;
  }

  get error(): boolean {
    // return (this.errRequired || this.errMaxLength);
    return this.errRequired;
  }

  get valid(): boolean {
    return !!this.ngControl.control?.valid;
  }

  get invalid(): boolean {
    return !!this.ngControl.control?.invalid;
  }

  get touched(): boolean {
    return !!this.ngControl.control?.touched;
  }

  set touched(touched: boolean) {
    this.onTouched(); // чтобы в родит.компонент передать
  }

  get untouched(): boolean {
    return !!this.ngControl.control?.untouched;
  }

  set active(value: boolean) {
    this.renderer.setProperty(this.inputRef?.nativeElement, 'active', value);
  }

  get active(): boolean {
    return !!this.inputRef?.nativeElement?.active;
  }

  set disabled(value: boolean) {
    this.renderer.setProperty(this.inputRef?.nativeElement, 'disabled', value);
    // this.setState('disabled', value);
    // this.setState()
  }

  get disabled(): boolean {
    return !!this.inputRef?.nativeElement?.disabled;
  }

}
