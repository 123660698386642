import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetStylesPipe } from '@pipes/css/get-styles.pipe';
import { GetFirstLetterPipe } from '@pipes/get-first-letter.pipe';
import { TClassForText } from '@models/ICssStyles';

@Component({
  selector: 'photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss'],
  standalone: true,
  imports: [CommonModule, GetStylesPipe, GetFirstLetterPipe],
})
export class PhotoComponent {
  @Input() data?: any; //     ClassUser | ClassCompetitionUser;
  @Input() w = '40'; // если передано, то устанавливаем размер лого
  @Input() fs = '16'; // если передано, то устанавливаем font-size
  @Input() typeText: TClassForText = 'text5-blueDark';
  @Input() adminLeagues?: Array<any> | null; // если передано, то показывать флаг
  @Input() forTest?: any;
}
