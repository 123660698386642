export type TInvalidDate = 'Invalid date';
export const invalidDate: TInvalidDate = 'Invalid date';

export type TCustomDateRange = 'Custom Date Range';
export const customDateRange: TCustomDateRange = 'Custom Date Range';

// !!! andrei если это не буду использовать то удалить это
// !!! эта нужно для того чтобы проверять в ClassFilterDateRange правильно ли утсановлен arrPeriods в зависимости от typePeriod('current'|'past')
export type TPeriodDateRange_tomorrow = 'Tomorrow';
export type TPeriodDateRange_yesterday = 'Yesterday';
export const periodDateRange_tomorrow: TPeriodDateRange_tomorrow = 'Tomorrow';
export const periodDateRange_yesterday: TPeriodDateRange_yesterday = 'Yesterday'; // !!! эта константа нужна для того чтобы проверять в ClassFilterDateRange правильно ли утсановлен arrPeriods в зависимости от typePeriod('current'|'past')

export type TChoosePeriod = TCustomDateRange | TPeriodDateRange_yesterday | 'Today' | TPeriodDateRange_tomorrow
  | 'Last 7 days' | 'Last 2 weeks' | 'Last 4 weeks' | 'Last month' | 'Last 30 days' | 'Last 3 months' | 'Last 12 months'
  | 'Next 7 days' | 'Next 2 weeks' | 'Next 4 weeks' | 'Next month' | 'Next 30 Days' | 'Next 3 months' | 'Next 12 months'
  | 'Month to Date' | 'Quarter to Date' | 'Year to Date';

export const arrPeriodsForCurrent: Array<TChoosePeriod> = ['Today', periodDateRange_tomorrow, 'Next 7 days', 'Next 2 weeks', 'Next month', 'Next 3 months', 'Next 12 months', customDateRange];
export const arrPeriodsForPast: Array<TChoosePeriod> = [periodDateRange_yesterday, 'Last 7 days', 'Last 2 weeks', 'Last 4 weeks', 'Last month', 'Last 3 months', 'Last 12 months', customDateRange];

export interface IDatePeriod {
  from?: string | Date;
  to?: string | Date;
}

type IObjAmountDaysForChoosePeriod = {
  [key in TChoosePeriod]: number | TChoosePeriod;
}
export const objAmountDaysForChoosePeriod: IObjAmountDaysForChoosePeriod = {
  'Last 7 days': -7,
  'Last 2 weeks': -14,
  'Last 4 weeks': -28,
  'Last month': -30,
  'Last 30 days': -30,
  'Last 3 months': -90,
  'Last 12 months': -365,
  'Next 7 days': 7,
  'Next 2 weeks': 14,
  'Next 4 weeks': 28,
  'Next month': 30,
  'Next 30 Days': 30,
  'Next 3 months': 90,
  'Next 12 months': 365,
  'Month to Date': 'Month to Date',
  'Quarter to Date': 'Quarter to Date',
  'Year to Date': 'Year to Date',
  // 'Yesterday': 'Yesterday',
  'Yesterday': periodDateRange_yesterday,
  'Today': 'Today',
  // 'Tomorrow': 'Tomorrow',
  'Tomorrow': periodDateRange_tomorrow,
  'Custom Date Range': 'Custom Date Range',
};
