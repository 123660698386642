import { Pipe, PipeTransform } from '@angular/core';
import { getTableEmpty_forPageOfficials, ITableEmpty } from '@components/_table/meTable';

@Pipe({ name: 'getTableEmptyForOfficials', standalone: true })
export class GetTableEmptyForOfficialsPipe implements PipeTransform {

  // constructor(
  // private deviceS: DeviceService,
  // ) {
  // }

  transform(search: string | undefined, is_currentLink_active: boolean, forTest?: string): ITableEmpty {
    return getTableEmpty_forPageOfficials(search, is_currentLink_active, forTest);
  }

}
