<div class="overview">
  <h3 *ngIf="deviceService.isDesktop$ | async as obj" class="overview__head">Overview</h3>
  <div class="overview__content">
    <app-group-assignor-tiles></app-group-assignor-tiles>
  </div>
</div>

<div class="title-section">
  <h4 class="table_head">
    {{ getSelectedItem()?.title }}
    <p *ngIf="deviceService.isMobile$ | async as obj">({{ getSelectedItem()?.value }})</p>
  </h4>
  <app-btn-primary class="hide-on-mobile" [authenticator]="['GROUP_ASSIGNOR', 'SUB_ASSIGNOR']" (emit)="manageAll()">Manage All</app-btn-primary>
  <app-btn-primary class="hide-on-mobile" [authenticator]="['ADMIN']" (emit)="this.router.navigate(['games'])">View Games</app-btn-primary>
</div>
<app-group-assignor-games-table *ngIf="isGame$ | async" [settings]="settings"></app-group-assignor-games-table>
<app-group-assignor-table *ngIf="!(isGame$ | async)" [settings]="settings"></app-group-assignor-table>
