import { Pipe, PipeTransform } from '@angular/core';
import { ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { ClassFilterDateRange } from '@components/filters/filters';
import { ISortingOptions } from '@components/sortBy/sortBy';

export type TStateInput = 'empty' | 'filled' | 'active' | 'disabled' | 'error' | '';

@Pipe({ name: 'getStateInput', standalone: true, pure: false }) // !!! NO DELETE pure: false
export class GetStateInputPipe implements PipeTransform {

  transform(state: {
    value?: string | Array<string> | ClassDrop | Array<ClassDrop> | ClassFilterDateRange | ISortingOptions,
    active?: boolean,
    disabled?: boolean,
    error?: boolean,
  }, forTest?: string): TStateInput {
    // if (forTest) {
    //   console.log('transform 111:', forTest, state)
    // }
    let result: TStateInput = '';
    if (state.error) result = 'error';
    if (state.disabled) result = 'disabled';
    if (state.active) result = 'active';
    const condition = !state.active && !state.disabled && !state.error;  // !!! если disabled приоритетней чем filled
    // const condition = !state.active && !state.error; // !!! если filled приоритетней чем disabled
    // if (forTest) {
    // console.log('state.value :', typeof state.value, state.value);
    // }
    if (typeof state.value == 'string' || typeof state.value == 'number') {
      const value: string = state.value?.toString();
      if (condition) result = !!value ? 'filled' : 'empty';
    } else if (typeof state.value !== 'string' && (state.value as Array<string>)?.length) { // for Array<string>
      const value: Array<string> = state.value as Array<string>;
      if (condition) result = value?.length ? 'filled' : 'empty';
    } else if ((state.value as ClassFilterDateRange)?.typeFilter == 'dateRange') { // ClassFilterDateRange
      const value = state.value as ClassFilterDateRange;
      const existValue: boolean = !!value?.period || !!value?.datePeriod || !!value?.fromTo_formatted;
      if (condition) result = existValue ? 'filled' : 'empty';
    } else { // for IDropItem
      // const value: IDropItem = state.value as IDropItem;
      const valueDrop: ClassDrop = state.value as ClassDrop;
      // const existValue: boolean = !!value?.name || !!value?.title || !!value?.id || !!value?.name || !!valueDrop?.titleCase || !!valueDrop?.upperCase;
      const existValue: boolean = !!valueDrop?.titleCase || !!valueDrop?.upperCase;
      if (condition) result = existValue ? 'filled' : 'empty';
    }
    // if (forTest) {
    //   console.log('transform 222:', forTest, state, ' ==', result)
    // }
    return result;
  }
}
