<div class='wrapperPhoto' [style]='{wh:w}|getStyles'
     [class.photo-size-big]="fs=='48'"
     [class.photo-size-media]="fs=='16'"
     [class.photo-size-small]="fs=='8'">
  <img *ngIf='data?.pictureUrl' [src]='data?.pictureUrl' alt=''>
  <div *ngIf='!data?.pictureUrl && (data?.firstName || data?.name)' class='o-{{typeText}} wrapperPhoto__name'>
    {{ (data?.firstName || data?.name) | getFirstLetter}}{{ data?.secondName | getFirstLetter}}
  </div>
  <div *ngIf='!data?.pictureUrl && !(data?.firstName || data?.name)' class='wrapperPhoto__defaultLogo'>
    <!--    <img *ngIf='!data?.pictureUrl && !(data?.firstName || data?.name)' src='assets/bcgLogo/bcgLogo_logoNotchCircleGrey.svg' alt=''>-->
    <img src='assets/bcgLogo/bcgLogo_logoNotchGrey.svg' alt=''>
  </div>
  <!--  <img *ngIf='!data?.pictureUrl && !(data?.firstName || data?.name)' src='assets/bcgLogo/bcgLogo_logoNotchCircleGreen.svg' alt=''>-->
</div>

<!--<div class='wrapperPhoto' [style]='{wh:w,fs:fs}|getStyles'>-->
<!--  <img *ngIf='data?.pictureUrl' [src]='data?.pictureUrl' alt=''>-->
<!--  <div *ngIf='!data?.pictureUrl && (data?.firstName || data?.name)'>{{ data?.firstName | getFirstLetter }}{{ data?.secondName | getFirstLetter }}</div>-->
<!--  <img *ngIf='!data?.pictureUrl && !data?.firstName' src='assets/bcgLogo/bcgLogo_logoNotchCircleGreen.svg' alt=''>-->
<!--</div>-->