import { Pipe, PipeTransform } from '@angular/core';
import { OtherService } from '@services/other.service';

@Pipe({ name: 'includes', standalone: true })
export class IncludesPipe implements PipeTransform {

  constructor(
    private otherS: OtherService,
  ) {
  }

  // 1 вариант: должен равняться одному из массива
  // DUE => (status == "DUE") || (status == "DRAFT") => true
  // OPEN => (status == "DUE") || (status == "DRAFT") => false
  // return ["DUE","DRAFT"].includes(DUE)  => true
  // return ["DUE","DRAFT"].includes(OPEN) => false

  // 2 вариант: НЕ должен равняться НИ одному из массива
  // DUE => (status != "DUE") && (status != "DRAFT") => false
  // OPEN => (status != "DUE") && (status != "DRAFT") => true
  // return !["DUE","DRAFT"].includes(DUE)  => false
  // return !["DUE","DRAFT"].includes(OPEN) => true
  transform(arr?: Array<any>, str?: any): boolean { // arr == Array<string | TReportStatus | TGameStatus | TPayoutStatus> // str?: string
    return this.otherS.includes(arr, str);
  }

}
