<ng-container *ngIf='{existErrMessage: !!(errMessages|getArrStringFromObject)?.length} as obj'>

  <div class='stripeBlock'>
    <headTitle text='Add New Payment Method' svgRight='cross_grey&24' typeText='text4-blueDark' (click)="cancel.emit()">
    </headTitle>
    <div class='wrapFields'>
      <div class="wrapFields__block fieldStripe fieldStripe--cardName">
        <div class="wrapFields__block__title">Name on Card</div>
        <input #inputRef id="cardName" class="o-input" type="text" required="true" autocomplete="name"
          (input)="changeInput($event.target)" placeholder="Name on Card">
      </div>
      <div class="wrapFields__block fieldStripe fieldStripe--zip">
        <div class="wrapFields__block__title">Zip Code</div>
        <input id="zip" class="o-input" type="number" required="true" autocomplete="postal-code"
          (input)="changeInputZip($event.target)" placeholder="Zip Code">
      </div>
      <div class="wrapFields__block fieldStripe fieldStripe--cardNumber">
        <div class="wrapFields__block__title">Card Number</div>
        <div id="cardNumber" class="o-input StripeElement"></div>
      </div>
      <div class="wrapFields__block fieldStripe fieldStripe--cardExpiry">
        <div class="wrapFields__block__title">Expiry Date</div>
        <div id="cardExpiry" class="o-input StripeElement"></div>
      </div>
      <div class="wrapFields__block fieldStripe fieldStripe--cardCvc">
        <div class="wrapFields__block__title">CVV</div>
        <div id="cardCvc" class="o-input StripeElement"></div>
      </div>
    </div>

    <div *ngIf='obj.existErrMessage' class='o-flex-column o-mg-b-20' role='alert'>
      <error *ngFor='let message of errMessages|getArrStringFromObject' [text]='message'></error>
    </div>

    <!--    FOR ADMIN -->
    <div *ngIf='isCreatePayMethod' class='btnsBalancePay'>
      <btn *ngIf='textCancel' [text]='textCancel' bcg='white' (emit)='cancel.emit()'
        [ngClass]="{'desktop-btn': deviceS.isDesktop$ | async, 'mobile-btn': deviceS.isMobile$ | async}" btnHeight='40'>
      </btn>
      <btn *ngIf='textApply' [text]='textApply' bcg='blueDark' (emit)='createPaymentMethod()' [spinner]='reqPending'
        [ngClass]="{'desktop-btn': deviceS.isDesktop$ | async, 'mobile-btn': deviceS.isMobile$ | async}" btnHeight='40'
        [disabled]='obj.existErrMessage || !(validForm|checkValidAllFieldsFromObject)'></btn>
    </div>

    <!--    FOR OFFICIAL -->
    <ng-container *ngIf='isCreateToken'>
      <btn *ngIf='textCancel' [text]='textCancel' bcg='white' (emit)='cancel.emit()'></btn>
      <btn [text]='textApply!' bcg='green' (emit)='createTokenMethod()' [spinner]='reqPending'
        [disabled]='obj.existErrMessage || !(validForm|checkValidAllFieldsFromObject)'></btn>
    </ng-container>

  </div>

</ng-container>