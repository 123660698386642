import { Pipe, PipeTransform } from '@angular/core';
import { MeService } from '@services/me.service';
import { subtitleDashboard_forAdmin, subtitleDashboard_forOfficial } from '@app/dir_group_assignor/dashboard/dashboard';

@Pipe({ name: 'getSubtitleDashboard', standalone: true })
export class GetSubtitleDashboardPipe implements PipeTransform {

  constructor(
    private meS: MeService,
  ) {
  }

  transform(a: null): string {
    return this.meS.OFFICIAL ? subtitleDashboard_forOfficial : subtitleDashboard_forAdmin;
  }

}
