import { Pipe, PipeTransform } from '@angular/core';
import { OtherService } from '@services/other.service';

@Pipe({ name: 'getLevelValue', standalone: true })
export class GetLevelValuePipe implements PipeTransform {

  constructor(
    private otherS: OtherService,
  ) {
  }

  // !!! если null то показывать TBD. // если ALL то N/A.
  // !!! если hiddenIfNull==true && ('TBD' || N/A) => return ''
  // !!! addDash => если надо добавить тире перед/после
  // transform(level?: string, hiddenIfNull = false, addDash?: 'before' | 'after'): string {
  //   return this.otherS.getLevelValue(level, hiddenIfNull, addDash);
  // }
  transform(level: string | undefined, variant: 1 | 2): string {
    return this.otherS.getLevelValue(level, variant);
  }

}
