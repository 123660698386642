import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LogoPaySystemComponent } from '@app/dir_group_assignor/payments/additional-components/logo-pay-system/logo-pay-system.component';
import { CardLogoComponent } from '@components/card-logo/card-logo.component';
import { ACCOUNT_EXTERNAL_FIELDS, AccountExternalModel } from '@models/account-external.model';
import { ACCOUNT_METHODS } from '@shared/constants/account-methods.constant';
import { SvgComponent } from '../__svg_img/svg/svg.component';
import { BtnComponent } from '../btn/btn.component';
import { ChxComponent } from '../chx/chx.component';
import { DeviceService } from '@services/device.service';
import { FormCustomService } from '@services/form-custom.service';
import { MeService } from '@services/me.service';
import { SkeletonComponent } from '@components/skeleton/skeleton.component';
import { IDataPopup, PopupService } from '@services/popup.service';
import { SuccessDialogComponent } from '@app/dir_officials/page-my-wallet/components/success-dialog/success-dialog.component';

export interface TabInterface {
  title: string;
  isSelected?: boolean;
}

@Component({
  selector: 'app-account-payment',
  standalone: true,
  templateUrl: './account-payment.component.html',
  styleUrls: ['./account-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SvgComponent,
    BtnComponent,
    ChxComponent,
    FormsModule,
    ReactiveFormsModule,
    LogoPaySystemComponent,
    CardLogoComponent,
    SkeletonComponent,
  ],
  host: {
    class: 'g-flex g-flex--space-between g-flex--align-start',
  },
})
export class AccountPaymentComponent implements OnChanges {
  [x: string]: any;
  @Input() isManagePage: boolean = false; 
  @Input() mode: 'view' | 'edit' | 'default' | 'payout' = 'default';
  @Input() account!: AccountExternalModel;
  @Input() isSelected = false;
  @Input() isDisabled = false;

  @Output() edit = new EventEmitter<any>();
  @Output() next = new EventEmitter<any>();
  @Output() removeAccount = new EventEmitter<AccountExternalModel>();
  @Output() defaultAccount = new EventEmitter<AccountExternalModel>();

  @Output() accountSelected = new EventEmitter<AccountExternalModel>();

  onSelectAccount() {
    this.accountSelected.emit(this.account);
  }

  loadingAccountId: string | null | undefined = null;
  checkboxControl = new FormControl(false);

  ACCOUNT_EXTERNAL_FIELDS = ACCOUNT_EXTERNAL_FIELDS;
  ACCOUNT_METHODS = ACCOUNT_METHODS;

  constructor(
    public deviceS: DeviceService,
    private formCustomS: FormCustomService,
    private cd: ChangeDetectorRef,
    public meS: MeService,
    public popupS: PopupService
  ) {
  }

  ngOnChanges({ isSelected }: SimpleChanges) {
    if (isSelected && isSelected.currentValue !== isSelected.previousValue) {
      this.checkboxControl.patchValue(this.isSelected);
      // this.cd.detectChanges()
    }
  }

  onEdit(): void {
    this.edit.emit();
  }

  goToNext(): void {
    this.next.emit();
  }

  removePayment(account: AccountExternalModel) {
    this.removeAccount.emit(account);
  }

  setDefaultPayment(account: AccountExternalModel) {
    this.defaultAccount.emit(account);
  }

  setDefaultPaymentNew(account: AccountExternalModel) {
    this.loadingAccountId = account[ACCOUNT_EXTERNAL_FIELDS.ID];
    this.defaultAccount.emit(account);
    setTimeout(() => {
      this.loadingAccountId = null;
      this.cd.detectChanges();
    }, 1000);
  }


  removePaymentNew(account: AccountExternalModel) {
    const dataPopup: IDataPopup = {
      textTitle: 'Remove Payment Method',
      text: 'Are you sure you want to remove this payment method?',
      textBtnApply: 'Remove',
      textBtnCancel: 'Cancel',
      colorBtnApply: 'red',
      colorBtnCancel: 'white',
      width: this.popupS.widthPopup?.minWidth ?? '400px', 
    };
  
    this.popupS.openPopupConfirm(dataPopup).then((confirmed: boolean) => {
      if (confirmed) {
        this.loadingAccountId = account[ACCOUNT_EXTERNAL_FIELDS.ID];
        this.removeAccount.emit(account);
  
        setTimeout(() => {
          this.loadingAccountId = null;
          this.cd.detectChanges();
          this.popupS.open$(
            SuccessDialogComponent,
            {
              width: '560px',
              popupType: 'removePayment'
            },
            false,
            'success-dialog',
          );
        }, 1000);
      }
    });
  }


  closeMenu() {
    this.showMenu = false;
    this.cd.detectChanges();
  }


  toggleMenu(event: Event, account: AccountExternalModel) {
    event.stopPropagation();

    if (this.selectedAccount === account && this.showMenu) {
      this.showMenu = false;
      this.selectedAccount = null;
    } else {
      this.selectedAccount = account;
      this.showMenu = true;
    }


    document.querySelectorAll('.dropdown-menu').forEach((menu) => {
      if (menu !== this.dropdownMenu?.nativeElement) {
        (menu as HTMLElement).style.display = 'none';
      }
    });

    this.cd.detectChanges();
  }



  @HostListener('document:click', ['$event'])
  closeMenuOnOutsideClick(event: Event) {
    console.log('Clicked outside detected', event.target);


    if ((event.target as HTMLElement).closest('.menu-container')) {
      return;
    }

    console.log('Closing menu due to outside click');
    this.showMenu = false;
    this.selectedAccount = null;
    this.cd.detectChanges();
  }

}
