import { CommonModule, CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { HelperClass } from '@classes/Helper-Classes';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { BtnComponent } from '@components/btn/btn.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { ACCOUNT_EXTERNAL_FIELDS, AccountExternalModel } from '@models/account-external.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AccountApiService } from '@services/account.api.service';
import { MeService } from '@services/me.service';
import { OtherService } from '@services/other.service';
import { MyWalletService } from '../../my-wallet.service';
import { PopupService } from '@services/popup.service';
import { PaymentService } from '@app/dir_group_assignor/payments/payment.service';
import { DeviceService } from '@services/device.service';
import { PhotosComponent } from '@components/__svg_img/photos/photos.component';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { MyWalletTableComponent } from '../my-wallet-table/my-wallet-table.component';
import { DateFormattedComponent } from '@components/__date/date-formatted/date-formatted.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTooltipForLocationPipe } from '@pipes/location/mat-tooltip-for-location.pipe';
import { StatusComponent } from '@components/status/status.component';
import { MatMenuModule } from '@angular/material/menu';
import { PaginationComponent } from '@components/pagination/pagination.component';
import { NothingListComponent } from '@components/nothing-list/nothing-list.component';
import { FormsModule } from '@angular/forms';
import { AccountPaymentComponent } from '@components/account-payment/account-payment.component';
import { SwitcherBtnComponent } from '@components/switcher-btn/switcher-btn.component';
import { GetLocationStringPipe } from '@pipes/location/get-location-string.pipe';
import { GetLocNameCourtNamePipe } from '@pipes/location/get-loc-name-court-name.pipe';
import { GetAgeGenderLevelPipe } from '@pipes/get-age-gender-level.pipe';
import { GetFirstLetterPipe } from '@pipes/get-first-letter.pipe';
import { StripeFormComponent } from '@components/stripe-form/stripe-form.component';
import { AddAnotherComponent } from '@components/add-another/add-another.component';
import { PopupPaymentAddComponent } from '@components/__popup-windows/popup-payment-add/popup-payment-add.component';

@UntilDestroy()
@Component({
  selector: 'app-manage-payment-method',
  standalone: true,
  templateUrl: './manage-payment-method.component.html',
  styleUrls: ['./manage-payment-method.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, HeadTitleComponent, InputCtrlComponent, SvgComponent, BtnComponent, SpinnerComponent, PhotosComponent,
    MatMenuModule, PaginationComponent, NothingListComponent, FormsModule, AccountPaymentComponent, SwitcherBtnComponent, CurrencyPipe,
    StatusComponent, StripeFormComponent, GetFirstLetterPipe, GetAgeGenderLevelPipe, GetLocNameCourtNamePipe, GetLocationStringPipe,
    MatTooltipForLocationPipe, MatTooltipModule, DateFormattedComponent, RouterLink, MyWalletTableComponent, WrapPageDirective, AddAnotherComponent, AddAnotherComponent],
})




export class ManagePaymentMethodComponent extends HelperClass implements OnInit {

  @Input() accounts: AccountExternalModel[] = [];
  @Input() accountDefault?: AccountExternalModel;
  @Input() selectedAccount?: AccountExternalModel;
  @Output() removeAccount = new EventEmitter<AccountExternalModel>();
  @Output() defaultAccount = new EventEmitter<AccountExternalModel>();
  @Input() hideHeadTitle: boolean = false;

  private _loading = false;
  public get loading() {
    return this._loading;
  }
  public set loading(value) {
    this._loading = value;
  }

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public myWalletS: MyWalletService,
    private popupS: PopupService,
    private accountApiS: AccountApiService,
    private meS: MeService,
    public paymentS: PaymentService,
    public otherS: OtherService,
    public deviceS: DeviceService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
  }

  ngOnInit(): void {
    if (history.state.accountDefault) {
      this.accountDefault = history.state.accountDefault;
    }
    if (!this.selectedAccount && this.accounts.length > 0) {
      this.selectedAccount = this.accounts.find(account => account.defaultForCurrency) || this.accounts[0];
    }
    this.getAccounts();
  }

  goBack(): void {
    this.router.navigate(['/myWalletOfficial']);
  }



  private getAccounts(): void {
    this.loading = true;
    this.accountApiS.getAccounts(this.meS.meId)
      .subscribe({
        next: (accounts) => {
          this.accountDefault = accounts.find(it => it[ACCOUNT_EXTERNAL_FIELDS.DEFAULT]) || undefined; // Fix type issue


          this.accounts = accounts.filter(it => it[ACCOUNT_EXTERNAL_FIELDS.ID] !== this.accountDefault?.[ACCOUNT_EXTERNAL_FIELDS.ID]);

          this.selectedAccount = this.accountDefault;
          this.loading = false;
          this.cd.detectChanges();
        },
        error: (err) => {
          this.otherS.showNotification(false, undefined, err);
          this.loading = false;
        }
      });
  }



  onAccountSelected(account: AccountExternalModel) {
    if (this.selectedAccount?.[ACCOUNT_EXTERNAL_FIELDS.ID] !== account[ACCOUNT_EXTERNAL_FIELDS.ID]) {
      this.selectedAccount = account;
      //  this.onSetDefaultAccount(account);
      this.cd.detectChanges();
    }
  }

  // API call to set default account
  onSetDefaultAccount(account: AccountExternalModel) {
    this.loading = true;
    this.accountApiS.setDefault(this.meS.meId, account[ACCOUNT_EXTERNAL_FIELDS.ID] || '')
      .subscribe({
        next: () => {
          this.accountDefault = account;
          this.getAccounts(); // Refresh accounts list
        },
        error: (err) => {
          this.otherS.showNotification(false, undefined, err);
        },
        complete: () => {
          this.loading = false;
        }
      });
  }



  // Remove account method
  onRemoveAccount(account: AccountExternalModel) {
    this.loading = true;
    this.accountApiS.deleteExternal(this.meS.meId, account[ACCOUNT_EXTERNAL_FIELDS.ID] || '')
      .subscribe({
        next: () => {
          this.getAccounts();
        },
        error: (err) => {
          this.otherS.showNotification(false, undefined, err);
        },
        complete: () => {
          this.loading = false;
        }
      });
  }



  openAddPaymentMethodPopup(): void {
    this.popupS.open$(PopupPaymentAddComponent, {
      width: '480px',
      isSelectPaymentType: true,
      isEdit: false,
      isPayout: false,
      accounts: this.accounts,
    }).subscribe((result) => {
      this.getAccounts();
      this.cd.detectChanges();
    });
  }

}

