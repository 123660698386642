import { Pipe, PipeTransform } from '@angular/core';
import { MeService } from '@services/me.service';
import { OtherService } from '@services/other.service';

@Pipe({ name: 'getGreet', standalone: true })
export class GetGreetPipe implements PipeTransform {

  constructor(
    private meS: MeService,
    private otherS: OtherService,
  ) {
  }

  transform(a: null): string {
    // return `${this.otherS.setGreeting()}, ${this.meS.me?.firstName}!`;
    return this.otherS.setGreeting();
  }

}
