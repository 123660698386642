<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              arrHeaderTable: (meTableS.arrHeaderTable$|async),
             } as obj' class='t-table__item__wrapCeil' [class.t-isCentered]='isCentered' ceilTable [idxCeil]='idxCeil'
  [bordNone_forMobile]='bordNone_forMobile'>

  <div *ngIf='obj.mobile && obj.arrHeaderTable' class='t-table__item__wrapCeil__titleFromHeader'>
    {{replaceTitleFromHeader_forMobile || obj.arrHeaderTable[idxCeil]?.text}}
  </div>

  <div class='t-table__item__wrapCeil__ceilColumn'>
    <div *ngIf='text1' class='t-table__item__wrapCeil__ceilColumn__line1 o-noWrap o-color-{{colorText}}'
      [matTooltip]='matTooltipText||""'>{{text1}}</div>
    <div *ngIf='obj.mobile && commaForMobile && text1 && text2' class='comma_forTableCeil'>,</div>
    <div *ngIf='text2' class='t-table__item__wrapCeil__ceilColumn__line2 o-noWrap' [matTooltip]='matTooltipText||""'>
      <span *ngIf='obj.mobile'>&nbsp;</span>
      {{text2}}
    </div>

    <meSvg *ngIf='svgRightForMobile && obj.mobile' [svgName]='svgRightForMobile'
           class='t-table__item__wrapCeil__ceilColumn__svgRightForMobile'></meSvg>
<!-- <meSvg *ngIf="svgRightForMobile && obj.mobile && checkGameStatus()" [svgName]="svgRightForMobile"
  class="t-table__item__wrapCeil__ceilColumn__svgRightForMobile">
</meSvg> -->



  </div>

</div>