import { Pipe, PipeTransform } from '@angular/core';
import { FundService } from '@services/funds.service';
import { IPaymentMethod } from '@app/dir_group_assignor/payments/modelsForPayment';

@Pipe({ name: 'getPayMethodName', standalone: true })
export class GetPayMethodNamePipe implements PipeTransform {
  constructor(
    private fundS: FundService,
  ) {
  }

  //  !!! delete pure false
  // чтобы иконку карты/банка устанавливать
  transform(payMethod?: IPaymentMethod): 'bankTransfer' | 'stripe' | string {
    return this.fundS.checkPayLogo(payMethod);
  }

}
