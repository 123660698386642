import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnBackComponent } from '@components/btn-back/btn-back.component';
import { BtnComponent } from '@components/btn/btn.component';
import { LocationComponent } from '@components/__blocks/location/location.component';
import { PhoneComponent } from '@components/__drop_inputs_matSelect/phone/phone.component';
import { HelperClass } from '@classes/Helper-Classes';
import { AuthService } from '@app/auth/auth.service';
import { MainService } from '@services/main.service';
import { FormsModule } from '@angular/forms';
import { SvgComponent } from '../../../shared/components/__svg_img/svg/svg.component';
import { GetChxSvgNamePipe } from '../../../shared/components/__svg_img/svg/pipes/get-chx-svg-name.pipe';
import { MeService } from '@services/me.service';
import { ClassUser } from '@models/user';
import { forkJoin } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '@env/environment';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'phoneStep',
  standalone: true,
  templateUrl: './phone-step.component.html',
  styleUrls: ['./phone-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, BtnBackComponent, BtnComponent, LocationComponent, PhoneComponent,
    FormsModule, SvgComponent, GetChxSvgNamePipe],
})
export class PhoneStepComponent extends HelperClass {
  @ViewChild('phoneComponent') phoneComponent?: PhoneComponent;
  @Output() nextStepEvent = new EventEmitter<string>();
  @Output() backStepEvent = new EventEmitter<void>();
  @Output() emit = new EventEmitter<string | 'back'>();
  phone = '';
  gameReminderOn = false;
  isAdmin: boolean;

  constructor(
    private meS: MeService,
    public authS: AuthService,
    public mainS: MainService,
    public cd: ChangeDetectorRef,
    router: Router,
  ) {
    super(cd);
    this.isAdmin = router.url.includes('/signupOfficial') == false;
  }

  // step == 5 phone
  sendCodeOnPhone(): void {
    // if (!this.phone || (!this.phoneComponent?.forLibrary?.isValidNumber() && !this.phoneComponent?.forTestPhone)) return;
    const notValidPhone = !this.phone || (!this.phoneComponent?.forLibrary?.isValidNumber());
    const itIsTestNumber = environment.production == false && this.phone?.toString().indexOf("1555") > -1;
    if (!itIsTestNumber && notValidPhone)
      return;
    if (this.startRequest()) return;
    forkJoin([
      this.mainS.fillUserInfo({ gameReminderOn: this.gameReminderOn, id: this.meS.me?.id } as ClassUser).pipe(untilDestroyed(this)),
      this.mainS.sendCodeOnPhone(this.phone)]).pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.nextStepEvent.emit(this.phone);
        this.emit.emit(this.phone);
        this.endRequest();
      },
        (error) => {
          this.endRequest();
        });
  }

}
