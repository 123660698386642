<!--*ngIf='(competitionS.isLoad$|async)?.about; else spinner'-->
<!--*ngIf='competitionS.competition; else spinner'-->
<div class='wrapperCompetitions'>
  <competitionsNavigation page='about'></competitionsNavigation>
  <form class='wrapperCompetitionsBlock wrapperCompetitionsGeneralInfo' [formGroup]='form' (keydown.enter)='$event.preventDefault()'>
    <div class='headerSetupCompetition'>
      <svgAndText text='General Info'
                  leftSvgName='arrow_squareLeft&24' typeText='text1-blueDark'
                  (leftSvgEmit)='competitionS.goToBack()'></svgAndText>
      <div *ngIf='competitionS.competition?.id' class='headerSetupCompetition__btns'>
        <!--        <btn text='Reset' bcgLogo='white' btnHeight='36' (click)='competitionS.reset()' [spinner]='reqPending'></btn>-->
        <btn text='Save & Close' bcg='white' btnHeight='36' (click)='competitionsBtnsEmit(false)'
             [disabled]='!form.valid || reqPending'></btn>
        <btn text='Save & Proceed to Next Step' bcg='blueDark' btnHeight='36' (click)='competitionsBtnsEmit(true)'
             [disabled]='!form.valid || reqPending'></btn>
      </div>
    </div>

    <div class='contentSetupCompetition'>
      <div class='wrapFieldsSetupCompetition'>
        <field text='Competition Name'>
          <inputCtrl formControlName='competitionName' placeholder='Competition Name' [isFocus]='true'></inputCtrl>
        </field>
        <field text='Sports'>
          <app-dropdown formControlName='sportsDrop' [array]='competitionS.arrSportDrop' placeholder='Select Sport(s)'
                        [multi]='true'></app-dropdown>
        </field>
        <field text='Competition Type'>
          <!--          <dropFormCtrl formControlName='competitionType' placeholder='Competition Type' [listStr]='competitionS.arrCompetitionType'-->
          <!--                        [addNewItem]='true' (otherMatOptionEmit)='otherMatOptionEmit($event,"competitionType")'-->
          <!--                        (changeVal)='cd.detectChanges()'></dropFormCtrl>-->
          <!--          [addNewItem]='true' (otherMatOptionEmit)='otherMatOptionEmit($event,"competitionType")'-->
          <app-dropdown formControlName='competitionTypeDrop' [array]='competitionS.arrCompetitionTypeDrop'
                        placeholder='Competition Type'></app-dropdown>
        </field>
        <field text='Default Game Type' svgName='question_grey&16'
               matTooltipSVG='We recommend you enter the most common game type for this competition that will be utilized by default when adding a new game unless a different game type is entered.'>
          <!--          <dropFormCtrl formControlName='gameType' placeholder='Select Default' [listStr]='competitionS.arrGameType'-->
          <!--                        (changeVal)='cd.detectChanges()'></dropFormCtrl>-->
          <app-dropdown formControlName='gameTypeDrop' [array]='competitionS.arrGameTypeDrop' placeholder='Select Default'></app-dropdown>
        </field>
          <field text='Enable Self-Request?' svgName='question_grey&16' *ngIf="isSelfRequest"
                 matTooltipSVG='Allow officials to request unassigned roles for this competition. Assignor approval is required.'>
              <app-dropdown formControlName='enableSelfRequest' [array]='competitionS.enableSelfRequest' placeholder='Select Default'></app-dropdown>
          </field>
          <field text='Self-Request Limit Per Day' *ngIf="isSelfRequest">
              <app-dropdown formControlName='selfApplyLimit' [array]='competitionS.selfApplyLimit' placeholder='Add Self-Request Limit Per Day'></app-dropdown>
          </field>
        <field text='Maximum Crew Size' svgName='question_grey&16'
               matTooltipSVG='Enter the maximum crew size for this competition that will be utilized by the system when adding your pay scales.'>
          <!--          <dropFormCtrl formControlName='maxCrewSize' placeholder='Maximum Crew Size' [listStr]='competitionS.maxCrewSize'></dropFormCtrl>-->
          <app-dropdown formControlName='maxCrewSizeDrop' [array]='competitionS.arrMaxCrewSizeDrop'
                        placeholder='Maximum Crew Size'></app-dropdown>
        </field>
        <field text='Payout Format'>
          <!--          <dropFormCtrl formControlName='payoutFormat' placeholder='Payout Format' [listStr]='competitionS.payoutFormat'-->
          <!--                        [addNewItem]='true' (otherMatOptionEmit)='otherMatOptionEmit($event,"payoutFormat")'></dropFormCtrl>-->
          <!--          andrei !!! [addNewItem]='true' (otherMatOptionEmit)='otherMatOptionEmit($event)' -->
          <app-dropdown formControlName='payoutFormatDrop' [array]='competitionS.arrPayoutFormatDrop'
                        placeholder='Payout Format'></app-dropdown>
        </field>
      </div>

      <btnWrap *ngIf='!competitionS.competition?.id' type='col'>
        <btn text='Save & Proceed to Next Step' bcg='blueDark' (click)='competitionsBtnsEmit(true)'
             [disabled]='!form.valid || reqPending'></btn>
        <btn text='Save & Close' bcg='white' (click)='competitionsBtnsEmit(false)' [disabled]='!form.valid || reqPending'></btn>
      </btnWrap>
    </div>
  </form>
</div>

<ng-template #spinner>
  <mat-spinner diameter='40'></mat-spinner>
</ng-template>


