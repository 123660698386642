<ng-container
              *ngIf='{DESKTOP: (deviceS.isDesktop$|async),
                      MOBILE: (deviceS.isMobile$|async),
                      game_CANCELLED: game?.gameStatus === "CANCELLED",
                     } as obj'>
  <div class="wrapperForGame wrapperGameInfo" [class.wrapperGameInfo--desktop]="obj.DESKTOP">
    <div class="wrapperForGameBlock" [class.wrapperForGameBlock--desktop]="obj.DESKTOP">
      <headTitle
                 class="headtitle"
                 [class.headTitle--desktop]="obj.DESKTOP"
                 [class.headTitle--mobile]="obj.MOBILE"
                 [typeText]="obj.MOBILE ? 'text2-blueDark' : 'text1-blueDark'"
                 text="Game #{{ game?.gameNumber }} - {{ game?.competition?.competitionName }}"
                 typeText="text1-blueDark"
                 [svgLeft]="obj.DESKTOP ? 'arrow_squareLeft&24' : 'arrow_squareLeft&24'"
                 (svgLeftEmit)="otherS.goToBack()">
        <status *ngIf="obj.game_CANCELLED" [status]="game?.gameStatus" class="ngContentRight" [class.o-mg-l-15]="obj.DESKTOP" [class.o-w-100]="obj.MOBILE" [class.o-mg-t-12]="obj.MOBILE"></status>
      </headTitle>

      <div *ngIf="obj.MOBILE" class="contentPreviewGame__title" [class.contentPreviewGame__title--desktop]="obj.DESKTOP" [class.contentPreviewGame__title--mobile]="obj.MOBILE">
        Game Information

        <div *ngIf="obj.game_CANCELLED == true" class="asButton22 game-notes-button editsquare">
          <meSvg [asButton22]="false" class="gameNotesSvg" svgName="message-text-square" (emit)="popupGameNotes()" adminPermission></meSvg>
        </div>
        <div *ngIf="isEditable && obj.game_CANCELLED == false" class="asButton22 editsquare">
          <meSvg [asButton22]="true" svgName="editSquareBlue&36" (emit)="goToEditGame()" [disabled]="obj.game_CANCELLED || reqPending" adminPermission></meSvg>
        </div>
      </div>

      <div *ngIf="obj.DESKTOP" class="contentPreviewGame o-wrap" [class.contentPreviewGame--desktop]="obj.DESKTOP" [class.contentPreviewGame--mobile]="obj.MOBILE">
        <div class="contentPreviewGame__title" [class.contentPreviewGame__title--desktop]="obj.DESKTOP" [class.contentPreviewGame__title--mobile]="obj.MOBILE">
          Game Information
          <div class="asButton22" *ngIf="isEditable && obj.game_CANCELLED == false">
            <meSvg [asButton22]="true" svgName="editSquareBlue&36" (emit)="goToEditGame()" [disabled]="obj.game_CANCELLED || reqPending" adminPermission></meSvg>
          </div>
          <div class="asButton22 game-notes-button editsquare" *ngIf="obj.game_CANCELLED == true">
            <meSvg [asButton22]="true" class="gameNotesSvg" svgName="message-text-square" (emit)="popupGameNotes()" adminPermission></meSvg>
          </div>
        </div>

        <div
             *ngFor="let previewItem of (gameS.arrPreviewGame$ | async)!; let isFirst = first"
             class="contentPreviewGame__previewItem contentPreviewGame__previewItem--{{ previewItem?.title }}"
             [class.contentPreviewGame__previewItem--mobile]="obj.MOBILE"
             [class.contentPreviewGame__previewItem--desktop]="obj.DESKTOP"
             [class.contentPreviewGame__previewItem--first]="isFirst">
          <span>{{ previewItem?.title }}</span>
          <dateFormatted *ngIf="previewItem?.title == 'Date'" [date]="previewItem?.value!" type="date" format="fullDate"></dateFormatted>
          <dateFormatted *ngIf="previewItem?.title == 'Time'" [date]="previewItem?.value!" type="time" format="shortTime"></dateFormatted>

          <span *ngIf="previewItem?.title == 'Status'" [class.o-colorRed]="(previewItem?.value | lowercase) == 'canceled'">
            {{ previewItem?.value }}
          </span>

          <div *ngIf="previewItem.title == 'Location'">
            <ng-container *ngIf="previewItem.location">
              <span>{{ previewItem.locationName }} {{ previewItem?.courtName ? ' - ' + previewItem?.courtName : '' }}</span>
              <span>{{ previewItem.location?.street }}</span>
              <span>{{ previewItem.location | getLocationString : ['city', 'state', 'zipcode'] }}</span>
              <btn text="Get Directions" bcg="blueDark" (click)="geo.goToGoogleMap()" btnHeight="36"></btn>
            </ng-container>
            <span *ngIf="!previewItem.location">TBD</span>
          </div>

          <span *ngIf="previewItem?.title !== 'Time' && previewItem?.title !== 'Date' && previewItem?.title !== 'Location' && previewItem?.title !== 'Status'">
            {{ previewItem?.value }}
          </span>
        </div>

        <div class="contentPreviewGame__previewItem" [class.contentPreviewGame__previewItem--mobile]="obj.MOBILE">
          <span>Assignor</span>
          <div>
            <span>{{ game?.assignor | getUserName }}</span>
            <svgAndText [text]="(game?.assignor?.phone | formatPhone) || '-'" leftSvgName="phoneBlue&20" typeText="text8-blueDark"></svgAndText>
            <svgAndText [text]="game?.assignor?.email || '-'" leftSvgName="mailBlue&20" typeText="text8-blueDark"> </svgAndText>
          </div>
        </div>
      </div>

      <div *ngIf="obj.MOBILE" class="contentPreviewGame o-wrap" [class.contentPreviewGame--desktop]="obj.DESKTOP" [class.contentPreviewGame--mobile]="obj.MOBILE">
        <div
             *ngFor="let previewItem of (gameS.arrPreviewGame_mobile$ | async)!; let isFirst = first"
             class="titleDataAndTimeMobile contentPreviewGame__previewItem contentPreviewGame__previewItem--{{ previewItem?.title }}"
             [class.contentPreviewGame__previewItem--mobile]="obj.MOBILE"
             [class.contentPreviewGame__previewItem--desktop]="obj.DESKTOP"
             [class.contentPreviewGame__previewItem--first]="isFirst">
          <span>{{ previewItem?.title }}</span>
          <div *ngIf="previewItem?.title == 'Date & Time'">
            <!-- Date -->
            <dateFormatted [date]="previewItem?.value!" type="date" format="fullDate"></dateFormatted>
            ,
            <!-- Time -->
            <dateFormatted [date]="previewItem?.value!" type="time" format="shortTime"></dateFormatted>
          </div>

          <span *ngIf="previewItem?.title == 'Status'" [class.o-colorRed]="(previewItem?.value | lowercase) == 'canceled'">
            {{ previewItem?.value }}
          </span>

          <div *ngIf="previewItem.title == 'Location'">
            <ng-container *ngIf="previewItem.location">
              <span>{{ previewItem.locationName }} {{ previewItem?.courtName ? ' - ' + previewItem?.courtName : '' }}</span>
              <span>{{ previewItem.location?.street }}</span>
              <span>{{ previewItem.location | getLocationString : ['city', 'state', 'zipcode'] }}</span>
            </ng-container>
            <span *ngIf="!previewItem.location">TBD</span>
          </div>

          <span *ngIf="previewItem?.title !== 'Date & Time' && previewItem?.title !== 'Location' && previewItem?.title !== 'Status'">
            {{ previewItem?.value }}
          </span>
        </div>

        <div class="contentPreviewGame__previewItem" [class.contentPreviewGame__previewItem--mobile]="obj.MOBILE">
          <span>Assignor</span>
          <div>
            <span>{{ game?.assignor | getUserName }}</span>
            <svgAndText [text]="(game?.assignor?.phone | formatPhone) || '-'" leftSvgName="phoneBlue&20" typeText="text8-blueDark"></svgAndText>
            <svgAndText [text]="game?.assignor?.email || '-'" leftSvgName="mailBlue&20" typeText="text8-blueDark"> </svgAndText>
          </div>
        </div>

        <div *ngIf="obj.MOBILE && isEditable" class="officialsGameInfo__bts" [class.officialsGameInfo__bts--mobile]="obj.MOBILE" [class.g-flex-column]="obj.MOBILE">
          <ng-container [ngTemplateOutlet]="actionsTemplate" [ngTemplateOutletContext]="{ btnHeight: obj.DESKTOP ? 44 : 36 }"></ng-container>
        </div>
      </div>
    </div>

    <!--    === OFFICIALS ============================================-->
    <div class="wrapperForGameBlock officialsGameInfo" [class.wrapperForGameBlock--desktop]="obj.DESKTOP" [class.wrapperForGameBlock--mobile]="obj.MOBILE" [class.officialsGameInfo--mobile]="obj.MOBILE">
      <div *ngIf="obj.DESKTOP" class="officialsGameInfo__bts" [class.officialsGameInfo__bts--mobile]="obj.MOBILE" [class.g-flex-column]="obj.MOBILE">
        <ng-container *ngIf="isEditable" [ngTemplateOutlet]="actionsTemplate"></ng-container>
      </div>

      <!--    === Cancelation Details ===========================================-->
      <cancelationDetails *ngIf="obj.game_CANCELLED" [game]="game"></cancelationDetails>

      <!-- Game Notes -->
      <ng-container *ngIf="obj.game_CANCELLED == false">
        <div *ngIf="obj.MOBILE" class="contentPreviewGame__title" [class.contentPreviewGame__title--desktop]="obj.DESKTOP" [class.contentPreviewGame__title--mobile]="obj.MOBILE">
          Game Notes
          <div class="asButton22">
            <meSvg [asButton22]="true" class="" *ngIf="isEditable" svgName="editSquareBlue&36" (emit)="popupGameNotes()" [disabled]="obj.game_CANCELLED || reqPending" adminPermission></meSvg>
          </div>
        </div>
        <div class="contentPreviewGame o-wrap game-notes-div" [class.contentPreviewGame--mobile]="obj.MOBILE" [class.contentPreviewGame__block2]="obj.DESKTOP">
          <div class="game-notes">
            <div *ngIf="obj.DESKTOP" class="contentPreviewGame__title" [class.contentPreviewGame__title--desktop]="obj.DESKTOP" [class.contentPreviewGame__title--mobile]="obj.MOBILE">
              Game Notes
              <div class="asButton22">
                <meSvg [asButton22]="true" *ngIf="isEditable" svgName="editSquareBlue&36" (emit)="popupGameNotes()" [disabled]="obj.game_CANCELLED || reqPending" adminPermission></meSvg>
              </div>
            </div>
            <div *ngIf="this.gameDescription?.hasValue == false" class="game-description-empty">No game notes have been added.</div>
            <div class="text" *ngIf="this.gameDescription?.hasValue">
              {{ this.gameDescription?.readOnlyText }}
              <a *ngIf="this.gameDescription?.isOver" style="font-weight: 600" (click)="popupGameNotes()"> Read More</a>
            </div>
          </div>
        </div>
      </ng-container>

      <div *ngIf="obj.MOBILE" class="contentPreviewGame__title contentPreviewGame__title__official">Officials</div>
      <div class="contentPreviewGame o-wrap" [class.contentPreviewGame--mobile]="obj.MOBILE" [class.contentPreviewGame__block2]="obj.DESKTOP">
        <div *ngIf="obj.DESKTOP" class="contentPreviewGame__title">Officials</div>

        <div
             *ngFor="let go of game?.gameOfficials; let idxRole = index; let firstRole = first; let lastRole = last"
             class="contentPreviewGame__itemRole"
             [class.contentPreviewGame__itemRole--mobile]="obj.MOBILE"
             [class.contentPreviewGame__itemRole--desktop]="obj.DESKTOP"
             (mouseleave)="resetPopup()">
          <ng-container *ngIf="obj.DESKTOP; else mobileTpl">
            <photo *ngIf="go?.official?.id" class="user" [data]="go?.official" w="40"> </photo>
            <meSvg *ngIf="!go?.official?.id" class="user" svgName="user_3&24"> </meSvg>
            <div class="contentPreviewGame__itemRole__column1 o-noWrap" [class.hover]="showPhoneNumber && currentPhonePopup === idxRole" (touchend)="showPhoneNumber = !showPhoneNumber">
              <div class="contentPreviewGame__name">
                <span (mouseleave)="resetPopup()" class="contentPreviewGame__nameText"> {{ go?.official?.id ? (go.official | getUserName) : 'Available Role' }} </span>
                <div *ngIf="go?.official?.id" class="image-wrapper" (mouseenter)="imageHovered($event, idxRole)">
                  <img src="assets/vector-phone.svg" style="width: 20px; height: 20px; margin: 2px; padding: 3px" />
                </div>
              </div>

              <div *ngIf="go?.official?.id && currentPhonePopup === idxRole && obj.DESKTOP" class="phone-popup" (mouseleave)="resetPopup()" (mouseenter)="(currentPhonePopup === idxRole)">
                <!--                !!! Manasa need delete calculateAge and use IsLessAge18Pipe-->
                <div *ngIf="!meS.OFFICIAL || calculateAge(go?.official?.dateOfBirth || '') > 18" class="phone-number" (click)="callPhoneNumber(go?.official?.phone)">
                  <img src="assets/phoneGray.svg" style="width: 14px; height: 24px; margin: 0 5px" />
                  <div class="phone-font">
                    <ng-container *ngIf="go?.official?.phone; else emptyNumber"> {{ go?.official?.phone | formatPhone }}</ng-container>
                  </div>
                </div>
                <div class="phone-number" (click)="callPhoneNumber(go?.official?.emergencyPhoneNumber)">
                  <img src="assets/vector-red.svg" style="width: 14px; height: 24px; margin: 0 5px" />
                  <div class="phone-font">
                    <ng-container *ngIf="go?.official?.emergencyPhoneNumber; else emptyNumber"> {{ go?.official?.emergencyPhoneNumber | formatPhone }} </ng-container>
                  </div>
                </div>

                <div class="emergencyName" *ngIf="go?.official?.emergencyPhoneNumber; else emptyNumber">{{ go?.official?.emergencyName }}</div>
              </div>

              <!--          go.officialPositionName|getFirstLetterEachWord-->
              <span>Official #{{ go.officialPositionNumber }} {{ go.officialPositionName }}</span>
            </div>

            <div class="contentPreviewGame__itemRole__column2">
              <div>{{ go.officialFee || 0 | currency }}</div>
              <span>Official Fee</span>
            </div>

            <div class="contentPreviewGame__itemRole__column3">
              <meSvg [svgName]="go | getSvgForGoStatus"></meSvg>
              {{ go.status | titlecase }}
            </div>

            <div [authenticator]="['GROUP_ASSIGNOR', 'SUB_ASSIGNOR']" class="contentPreviewGame__itemRole__dots o-mg-l-8">
              <meSvg *ngIf="isEditable" svgName="circle_3points2&20" [matMenuTriggerFor]="menu" adminPermission [class.o-hidden]="!menu?.items?.length" [class.o-disabledClick]="reqPending"></meSvg>
              <mat-menu #menu="matMenu">
                <button *ngIf="game | isPossibleEditFee" (click)="openPopupEditFeeForGO(go)" mat-menu-item>Edit Fee</button>
                <button *ngIf="!go.official && lastRole && !firstRole && (game | isPossibleEditFee)" (click)="openPopupRemoveRole(go)" mat-menu-item>Remove Role</button>
              </mat-menu>
            </div>
          </ng-container>

          <ng-template #mobileTpl class="mobilegameofficial">
            <div class="official-mobile g-flex--space-between g-flex__item">
              <div class="official-mobile g-flex g-flex--space-between g-flex__item">
                <div class="g-flex">
                  <photo *ngIf="go?.official?.id" [data]="go?.official" w="40"> </photo>
                  <meSvg *ngIf="!go?.official?.id" [w]="40" svgName="user_circleGreen&48"> </meSvg>
                  <div class="official-mobile__texts g-flex-column phone-popup-{{ idxRole }}" [class.hover]="shouldShowOpoup(idxRole)">
                    <div class="o-text7 official-fee">{{ go.officialFee ? (go.officialFee | currency) : '-' }}</div>
                    <div class="o-text7 name" style="display: flex">
                      <span class="contentPreviewGame__nameText"> {{ go?.official?.id ? (go.official | getUserName) : 'Available Role' }}</span>
                      <div *ngIf="go?.official?.id" class="image-wrapper" (touchend)="setPhoneNumPopup(idxRole)" id="phone-image-wrapper">
                        <img src="assets/vector-phone.svg" style="width: 16px; height: 16px; margin: 4px" id="phone-image" />
                      </div>
                    </div>
                    <div *ngIf="go?.official?.id" class="phone-popup mobile-mode phone-popup-{{ idxRole }}" #phonePopup>
                      <div *ngIf="!meS.OFFICIAL || calculateAge(go?.official?.dateOfBirth || '') > 18" (click)="callPhoneNumber(go?.official?.phone)" class="phone-number">
                        <img src="assets/phoneGray.svg" style="width: 14px; height: 24px; margin-left: 5px" />
                        <div class="phone-font">
                          <ng-container *ngIf="go?.official?.phone; else emptyNumber"> {{ go?.official?.phone | formatPhone }} </ng-container>
                        </div>
                      </div>
                      <div class="phone-number" (click)="callPhoneNumber(go?.official?.emergencyPhoneNumber)">
                        <img src="assets/vector-red.svg" style="width: 14px; height: 24px; margin-left: 5px" />
                        <div class="phone-font">
                          <ng-container *ngIf="go?.official?.emergencyPhoneNumber; else emptyNumber"> {{ go?.official?.emergencyPhoneNumber | formatPhone }} </ng-container>
                        </div>
                      </div>
                      <div class="emergencyName" *ngIf="go?.official?.emergencyPhoneNumber; else emptyNumber">{{ go?.official?.emergencyName }}</div>
                    </div>
                    <div class="o-text8 official-number">Official #{{ go.officialPositionNumber }} {{ go.officialPositionName }}</div>
                  </div>
                </div>
                <div *ngIf="obj.DESKTOP" class="contentPreviewGame__itemRole__column3">
                  <meSvg [svgName]="go | getSvgForGoStatus"></meSvg>
                  {{ go.status | titlecase }}
                </div>
                <div *ngIf="obj.MOBILE" class="statusWrap">
                  <meSvg [svgName]="go | getSvgForGoStatus"></meSvg>
                </div>
              </div>

              <div style="gap: 12px; padding-top: 12px" class="g-flex-column" *ngIf="obj.MOBILE && !meS.OFFICIAL">
                <div *ngIf="game | isPossibleEditFee" style="height: 36px" class="mat-mdc-tooltip-trigger o-btn-square-white o-flexCenter o-noWrap" (click)="openPopupEditFeeForGO(go)" adminPermission>Edit Fee</div>
                <div
                     style="height: 36px"
                     class="mat-mdc-tooltip-trigger o-btn-square-red o-flexCenter o-noWrap"
                     *ngIf="!go.official && lastRole && !firstRole && (game | isPossibleEditFee)"
                     (click)="openPopupRemoveRole(go)"
                     adminPermission>
                  Remove Official
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>

      <btnWrap type="col">
        <btn *ngIf="game?.gameReport?.id" text="View Report" bcg="blueDark" (emit)="router.navigate([urlReportInfo, game?.gameReport?.id])" [btnHeight]="obj.DESKTOP ? 44 : 36"></btn>
        <btn
             *ngIf="meS.GROUP_ASSIGNOR && game?.competition?.maxCrewSize! > game?.gameOfficials?.length!"
             text="Add Crew Member +"
             bcg="newGreen"
             (emit)="openPopupAddCrewMember()"
             [disabled]="obj.game_CANCELLED || reqPending"
             adminPermission
             [btnHeight]="obj.DESKTOP ? 44 : 36"></btn>
      </btnWrap>
    </div>
  </div>

  <ng-template #actionsTemplate let-btnHeight="btnHeight">
    <btn text="Clone" bcg="white" (emit)="goToEditGame(true)" [btnWidth]="obj.DESKTOP ? '80' : '100%'" [btnHeight]="btnHeight || 44" [disabled]="reqPending || isReadOnly" adminPermission></btn>
    <btn
         text="Cancel"
         bcg="red"
         (emit)="cancelGame()"
         [btnWidth]="obj.DESKTOP ? '80' : '100%'"
         [btnHeight]="btnHeight || 44"
         [disabled]="isReadOnly || reqPending || !(game! | isPossibleGameToCancelled)"
         adminPermission
         [matTooltip]="game | matTooltipForActionsForCanceledGame"></btn>
  </ng-template>
</ng-container>
<ng-template #emptyNumber>&nbsp; -</ng-template>