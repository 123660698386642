import { CommonModule, CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ClassPayMethodAndAmount, IPaymentMethod, TypePay } from '@app/dir_group_assignor/payments/modelsForPayment';
import { PaymentService } from '@app/dir_group_assignor/payments/payment.service';
import { HelperClass } from '@classes/Helper-Classes';
import { PopupPaymentMethodComponent } from '@components/__popup-windows/popup-payment-metod/popup-payment-method.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { BtnComponent } from '@components/btn/btn.component';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { StatusComponent } from '@components/status/status.component';
import { StripeFormComponent } from '@components/stripe-form/stripe-form.component';
import { ACCOUNT_EXTERNAL_FIELDS, AccountExternalModel } from '@models/account-external.model';
import { AccountApiService } from '@services/account.api.service';
import { MeService } from '@services/me.service';
import { OtherService } from '@services/other.service';
import { catchError, filter, finalize, throwError } from 'rxjs';
import { PhotosComponent } from '@components/__svg_img/photos/photos.component';
import { MatMenuModule } from '@angular/material/menu';
import { PaginationComponent } from '@components/pagination/pagination.component';
import { NothingListComponent } from '@components/nothing-list/nothing-list.component';
import { FormsModule } from '@angular/forms';
import { IDataPopup, PopupService } from '@services/popup.service';
import { AccountPaymentComponent } from '@components/account-payment/account-payment.component';
import { SwitcherBtnComponent } from '@components/switcher-btn/switcher-btn.component';
import { GetFirstLetterPipe } from '@pipes/get-first-letter.pipe';
import { GetAgeGenderLevelPipe } from '@pipes/get-age-gender-level.pipe';
import { GetLocNameCourtNamePipe } from '@pipes/location/get-loc-name-court-name.pipe';
import { GetLocationStringPipe } from '@pipes/location/get-location-string.pipe';
import { MatTooltipForLocationPipe } from '@pipes/location/mat-tooltip-for-location.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DateFormattedComponent } from '@components/__date/date-formatted/date-formatted.component';
import { MyWalletTableComponent } from '@app/dir_officials/page-my-wallet/components/my-wallet-table/my-wallet-table.component';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { MyWalletService } from '@app/dir_officials/page-my-wallet/my-wallet.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceService } from '@services/device.service';

@UntilDestroy()
@Component({
  selector: 'app-myWallet',
  standalone: true,
  templateUrl: './my-wallet.component.html',
  styleUrls: ['./my-wallet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, HeadTitleComponent, InputCtrlComponent, SvgComponent, BtnComponent, SpinnerComponent, PhotosComponent,
    MatMenuModule, PaginationComponent, NothingListComponent, FormsModule, AccountPaymentComponent, SwitcherBtnComponent, CurrencyPipe,
    StatusComponent, StripeFormComponent, GetFirstLetterPipe, GetAgeGenderLevelPipe, GetLocNameCourtNamePipe, GetLocationStringPipe,
    MatTooltipForLocationPipe, MatTooltipModule, DateFormattedComponent, RouterLink, MyWalletTableComponent, WrapPageDirective],
})
export class MyWalletComponent extends HelperClass implements OnInit {
  accounts: AccountExternalModel[] = [];
  accountDefault?: AccountExternalModel;
  balance = 0.00;
  balanceInstant = 0.00;

  showAddNewCard = false;
  selectedMethod?: IPaymentMethod;
  payMethodAndAmount: ClassPayMethodAndAmount = new ClassPayMethodAndAmount();

  constructor(
    public router: Router,
    public myWalletS: MyWalletService,
    private popupS: PopupService,
    private accountApiS: AccountApiService,
    private meS: MeService,
    public paymentS: PaymentService,
    public otherS: OtherService,
    public deviceS: DeviceService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
  }

  ngOnInit() {
    this.getAccounts();
    this.getBalanceInstant();
    this.getBalance();
  }

  getBalance(): void {
    this.accountApiS.getBalance().pipe(untilDestroyed(this))
      .subscribe(balance => {
        this.balance = balance;
        this.cd.detectChanges();
      });
  }

  getBalanceInstant(): void {
    this.accountApiS.getBalanceInstant().pipe(untilDestroyed(this))
      .subscribe(response => {
        this.balanceInstant = response.instantAvailable.amount; 
        this.cd.detectChanges();
      });
  }
  onPaymentMethodActionClick(): void {
    let options: IDataPopup = { width: '480px' };
    if (this.accounts.length) {
      options.isEdit = true;
      options.accounts = this.accounts;
    } else {
      options.isSelectPaymentType = true;
    }
    this.popupS.open$(PopupPaymentMethodComponent, options).pipe(untilDestroyed(this))
      .subscribe(() => {
        this.getAccounts();
      });
  }

  selectPayMethod(isSelect: boolean, payMethod: IPaymentMethod, typePay?: TypePay | string): void {
    if (isSelect) {
      const newMethods = this.paymentS.methodsSub$.getValue().map((el) => {
        if (el.id != payMethod.id) {
          return { ...el, isSelect: false };
        }
        return el;
      });
      this.paymentS.methodsSub$.next(newMethods);
      this.selectedMethod = this.paymentS.methodsSub$.getValue().find(el => el.isSelect);
      this.cd.detectChanges();
    }
  }

  getCurrentMethods(addNewMethod?: IPaymentMethod): void {
    if (addNewMethod) {
      addNewMethod.isSelect = true;
    }
    this.paymentS.getCurrentMethods(addNewMethod);
    const methods = this.paymentS.methodsSub$.getValue();
    if (methods.length) {
      this.payMethodAndAmount = new ClassPayMethodAndAmount(methods[0], 0);
    }
    this.showAddNewCard = false;
    if (addNewMethod) {
      this.selectPayMethod(true, { ...addNewMethod, isSelect: true });
    }
    window.scrollTo(0, 0);
    setTimeout(() => this.cd.detectChanges());
  }

  onPayout() {
    this.popupS.open$(PopupPaymentMethodComponent,
      { isEdit: true, isPayout: true, accounts: this.accounts, width: '480px', balance: this.balance , balanceInstant: this.balanceInstant},
    )
      .pipe(
        filter(data => !!data),
        untilDestroyed(this),
      )
      .subscribe(data => {
        console.log(data);
      });
  }

  private getAccounts() {
    if (this.startLoading()) return;
    this.accountApiS.getAccounts(this.meS.meId)
      .pipe(
        catchError(err => {
          this.otherS.showNotification(false, undefined, err);
          this.endLoading();
          return throwError(() => err);
        }),
        finalize(() => this.endLoading()),
        untilDestroyed(this),
      )
      .subscribe(accounts => {
        this.accounts = accounts;
        this.accountDefault = this.accounts.find(it => it[ACCOUNT_EXTERNAL_FIELDS.DEFAULT]);

        this.cd.detectChanges();
      });
  }
}
