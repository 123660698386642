import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberFormat',
    standalone: true,
    pure: false
})
export class NumberFormatPipe implements PipeTransform {

    transform(value: number, roundDown = false): string {
        if (value === null || value === undefined || isNaN(value)) {
            return '';
        }

        const absValue = Math.abs(value);

        if (absValue < 1e3) {
            return this.formatWithDollarSign(value, 2);
        } else if (absValue < 1e6) {
            const formattedValue = (value / 1e3);
            return this.formatWithDollarSign(formattedValue, 2, 'K');
        } else if (absValue < 1e9) {
            const formattedValue = (value / 1e6);
            return this.formatWithDollarSign(formattedValue, 2, 'M');
        } else if (absValue < 1e12) {
            const formattedValue = (value / 1e9);
            return this.formatWithDollarSign(formattedValue, 2, 'B');
        } else {
            const formattedValue = (value / 1e12);
            return this.formatWithDollarSign(formattedValue, 2, 'T');
        }
    }

    private formatWithDollarSign(value: number, decimalPlaces: number, suffix: string = ''): string {
        const isNegative = value < 0;
        const roundedValue = isFinite(value) ? Math.floor(Math.abs(value) * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces) : 0;

        // Use toFixed to ensure two decimal places
        const formattedValue = roundedValue.toFixed(decimalPlaces);

        // Convert back to a number to remove trailing zeros
        const numericValue = Number(formattedValue);

        const dollarSign = isNegative ? '- $' : '$';

        // Ensure there are always two decimal places
        const trimmedValue = Math.abs(numericValue - Math.round(numericValue)) < 1e-10 ? numericValue.toFixed(decimalPlaces) : numericValue.toString();

        return `${ dollarSign }${ trimmedValue }${ suffix }`;
    }
}