import { Pipe, PipeTransform } from '@angular/core';
import { OtherService } from '@services/other.service';

@Pipe({ name: 'getTimezoneFromDateFromServer', standalone: true })
export class GetTimezoneFromDateFromServerPipe implements PipeTransform {

  constructor(
    private otherS: OtherService,
  ) {
  }

  // !!! с сервера приходит в таком формате "30 Nov 2023 12:00 AM PST" . Этот метод возвращает таймзону (например PST)
  transform(dateFromServer: string): string {
    return this.otherS.getTimezoneFromDateFromServer(dateFromServer);
  }

}
