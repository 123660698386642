<div class='wrapperSvgAndText'>
  <!--  (click)='emitleftSvg();emitRighttSvg()'-->
  <meSvg *ngIf='leftSvgName' [svgName]='leftSvgName' [style]='{marR: marginForLeftSvg}|getStyles' [disabled]='disable'
         (click)='emitLeftSvg()' [w]='w' [cur]='!disable'></meSvg>
  <div [ngClass]="{'wrapperSvgAndText__text--disabled' : disable}" class='wrapperSvgAndText__text o-noWrap o-{{typeText}}'
       [class.o-cur-default]='disable'>
    {{ text }}
  </div>
  <meSvg *ngIf='rightSvgName' [svgName]='rightSvgName' [disabled]='disable' [style]='{marL: marginForRightSvg}|getStyles'
         (click)='emitRightSvg()' [w]='w' [cur]='!disable'></meSvg>
</div>