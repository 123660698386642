import { ClassTransfer } from '@models/transfer.model';

export class ClassMyWalletItemTableForMobile {
  createdAt_formatted_2?: string; // September 19, 2023
  createdAt_isToday?: boolean;
  allTotal?: number; // сумма всех total из текущего массива items
  arrayTransfer?: Array<ClassTransfer> = [];

  constructor(classTransfer: ClassTransfer) {
    this.createdAt_formatted_2 = classTransfer.createdAt_formatted_2;
    this.createdAt_isToday = classTransfer.createdAt_isToday;
    this.arrayTransfer = [classTransfer];
  }
}

