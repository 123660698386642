<!--group: (groupProfileS.group$|async),-->
<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              group: (meS.meCurrentGroup$|async),
              arrCurrentLink: groupProfileS.arrLinkObj_groupProfile,
              objCurrentLink: (groupProfileS.currentLink$|async)!,
              currentLink: (groupProfileS.currentLink$|async)?.currentLink!,
             } as obj' class='o-wrapPage wrapperGroupProfile wrapperGroupProfileInfo' [formGroup]='form'>
  <!-- o-wrapPage  wrapperGroupProfileInfo&#45;&#45;{{isEdit}}-->

  <!--  <div class='wrapperGroupProfile__linkPage'>-->
  <linkPage [arrLink]='obj.arrCurrentLink' [link]='obj.currentLink' (emit)='groupProfileS.navigateBy_currentLinkDrop($event)'></linkPage>
  <!--  </div>-->

  <div class='wrapperGroupProfile__content wrapperGroupProfile__content--{{isEdit}}'>
    <div *ngIf='obj.desktop' class='headerContact'>
      <!--      svgLeft='arrow_squareLeft&24' (svgLeftEmit)='otherS.locationBack()'-->
      <headTitle text='Group Profile' typeText='text1-blueDark'></headTitle>
      <ng-container [ngTemplateOutlet]='actionsTemplate'></ng-container>
    </div>

    <!--  <titleAndNavigationForProfile *ngIf='obj.mobile' typePageProfile='groupProfile'-->
    <!--                                [showNavigation]='!isEdit' [idUser]='groupProfileS.idGroup'></titleAndNavigationForProfile>-->

    <div *ngIf='!isEdit' class='contentContact' [class.contentContact--desktop]='obj.desktop'>
      <photo [data]='obj.group' [w]='obj.desktop ? "160" : "96"' fs='48' typeText='text1-blueDark' class='contentContact__photo'></photo>
      <div class='contentContact__name'>{{ctrl.companyLegalName?.value}}</div>
      <contactItem [width1]="width1_forContactItem" text1='Contact Name' [text2]='ctrl.name?.value'></contactItem>
      <contactItem [width1]="width1_forContactItem" text1='Company Legal Name' [text2]='ctrl.companyLegalName?.value'></contactItem>
      <contactItem [width1]="width1_forContactItem" text1='Email' [text2]='ctrl.email?.value'></contactItem>
      <contactItem [width1]="width1_forContactItem" text1='Phone No' [text2]='ctrl.phone?.value'></contactItem>
      <contactItem [width1]="width1_forContactItem" text1='Mailing Address' [text2]=''></contactItem>
      <contactItem [width1]="width1_forContactItem" text1='City & State' [text2]='ctrl.groupAddress?.value|getLocationString:["city","state"]'></contactItem>
      <contactItem [width1]="width1_forContactItem" text1='Zip Code' [text2]='ctrl.groupAddress?.value?.zipcode'></contactItem>
      <contactItem [width1]="width1_forContactItem" text1='Group Terms & Conditions' svgName1='question_grey&16'
                   [svgName2]='ctrl.groupTermsConditions?.value ? "toggle_true&36":"toggle_false&36"'></contactItem>
    </div>

    <!--  === FOR EDIT ============================================-->
    <div *ngIf='isEdit' class='contentContact' [class.contentContact--desktop]='obj.desktop'>
      <div class='o-itemEditContact o-itemEditContact--border'>
        <div class='o-itemEditContact__col_1 o-itemEditContact__title'>
          <div>Group Details<span>Manage your group details and click save to publish any changes</span></div>
        </div>
        <!--      <btn text='Save' bcg='blueDark' [spinner]='reqPending' (emit)='submit()' btnHeight='40'></btn>-->
      </div>
      <!--    === GROUP NAME => GROUP PROFILE ==================-->
      <fieldRow text='Group Name' class='o-itemEditContact o-pad-b-0'>
        <inputCtrl formControlName='name' placeholder='Group Name'></inputCtrl>
      </fieldRow>
      <!--    === Company Legal Name => GROUP PROFILE ==================-->
      <fieldRow text='Company Legal Name' class='o-itemEditContact o-itemEditContact--border'>
        <inputCtrl formControlName='companyLegalName' placeholder='Company Legal Name'></inputCtrl>
      </fieldRow>
      <!--    === Group Assignor => GROUP PROFILE ==================-->
      <fieldRow text='Group Assignor' class='o-itemEditContact o-itemEditContact--border'>
        <div class='o-itemEditContact__logoNameBtn'>
          <photo [data]='obj.group' w='64'></photo>
          {{ctrl.groupAssignor?.value?.firstName}} {{ctrl.groupAssignor?.value?.secondName}}
          <btn text='Transfer Ownership' bcg='blueDark' [spinner]='reqPending' (emit)='null' btnHeight='36' btnWidth='160'></btn>
        </div>
      </fieldRow>
      <!--    === Group Logo => GROUP PROFILE ==================-->
      <div class='o-itemEditContact o-itemEditContact--border'>
        <div class='o-itemEditContact__col_1'>Group Logo<span>This will be displayed on your profile.</span></div>
        <div class='o-itemEditContact__photo'>
          <photo [data]='obj.group' w='64'></photo>
          <btn text='Upload new logo' bcg='blueDark' [spinner]='reqPending' (emit)='null' btnHeight='36' btnWidth='155'></btn>
          <btn text='Delete' bcg='white' [spinner]='reqPending' (emit)='null' btnHeight='36' btnWidth='76'></btn>
        </div>
      </div>
      <!--    === Address GROUP PROFILE ==================-->
      <location page='contact' [fields]='fields' [requiredFields]='requiredFields'
                class='o-itemEditContact o-pad-b-0 o-itemEditContact--border'
                [replaceNameField]='{street: "Mailing address",streetLine2:"Address Line 2"}'></location>
    </div>
  </div>

  <ng-container *ngIf='obj.mobile' [ngTemplateOutlet]='actionsTemplate'></ng-container>


  <!--  === ACTIONS BUTTONS ===========================================-->
  <ng-template #actionsTemplate>
    <div *ngIf='!isEdit' class='headerContact__btns'>
      <!--      <btn text='Edit' bcg='blueDark' [btnWidth]='obj.mobile ? "100%":58' [btnHeight]='obj.mobile ? 36:""' (click)='isEdit=true'></btn>-->
    </div>
    <div *ngIf='isEdit' class='headerContact__btns'>
      <btn text='Cancel' bcg='white' (click)='isEdit=false' [disabled]='reqPending' [btnWidth]='obj.mobile ? "100%":89'></btn>
      <btn text='Save' bcg='blueDark' (click)='submit()' [spinner]='reqPending' [btnWidth]='obj.mobile ? "100%":65'></btn>
    </div>
  </ng-template>
</div>