import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { TSvgName } from '@components/__svg_img/svg/forSvg';
import { TColor } from '@models/ICssStyles';

@Component({
  selector: 'addAnother[color]',
  standalone: true,
  imports: [CommonModule, SvgComponent],
  templateUrl: './add-another.component.html',
  styleUrls: ['./add-another.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddAnotherComponent implements OnChanges {
  @Input() color!: Extract<TColor, 'blueDark' | 'newGreen'>;
  svgLeft?: Extract<TSvgName, 'plus_1&20' | 'plus_green&20'>;
  @Input() disabled?: boolean = false;
  @Output() emit = new EventEmitter<any>();

  constructor(
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.color?.currentValue) {
      if (this.color === 'blueDark') {
        this.svgLeft = 'plus_1&20';
        this.cd.detectChanges();
      }
      if (this.color === 'newGreen') {
        this.svgLeft = 'plus_green&20';
        this.cd.detectChanges();
      }
    }
  }
}
