import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Resolve, Router } from '@angular/router';

import { Observable, of } from 'rxjs';

import { MainService } from '@services/main.service';
import { MeService } from '@services/me.service';
import { DashboardService } from '@app/dir_group_assignor/dashboard/dashboard.service';
import { ClassUser } from '@models/user';

@Injectable({ providedIn: 'root' })
export class UserResolver implements Resolve<any> {
  constructor(
    private mainS: MainService,
    private meS: MeService,
    private route: ActivatedRoute,
    private router: Router,
    private dashboardS: DashboardService,
  ) {
  }

  resolve(): Observable<ClassUser | null> {
    return this.mainS.getMe().pipe(
      tap((meUser: ClassUser | null) => {
        // console.log('UserResolver :', meUser)
        this.meS.setMeUser(meUser);
      }),
      catchError(() => of(null)),
    );
  }

  // !!! mainS.getOnboarding отсюда не нужно вызывать
  // !!! потому что при переходе по ссылке для регистрации судьи получается 401 ошибка и после этого отправляется на страницу логина
  // resolve(): Observable<any> {
  //   return forkJoin([
  //     this.mainS.getMe(),
  //     this.mainS.getOnboarding(),
  //   ]).pipe(
  //     tap(([meUser, onboarding]) => {
  //       this.meS.setMeUser(meUser);
  //       this.meS.onboarding = onboarding;
  //       this.dashboardS.setCompletedBlockDashboard(onboarding);
  //     }),
  //     catchError(() => of(null)),
  //   );
  // }
}
