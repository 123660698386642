import { UtilsService } from '@services/utils.service';

export const lineBreak = '\n';

export type TypeNA = 'N/A'; // !!! с сервера приходит
export const const_NA: TypeNA = 'N/A'; // !!! с сервера приходит

// !!! первая буква каждого слова
export function toTitleCase(string: string): string {
  if (!string) return '';
  return string.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    },
  );
}

// === for Classes. Use in constructor. fill all property ===========================
export type TValueObj<T> = T[keyof T];

export const MaxSizeForAnnouncementPopUp = {
  width: 1920,
  height: 1080,
};

// !!! удаление всех property со значаниями null, undefined, empty objects
// !!! example { a: 'aaa', b: '', c: undefined, d: false, e: true, f: null, g: 0 } => return { a: 'aaa', b: '', d: false, e: true, g: 0 }
export function removeEmptyKeysFromObject<T extends object>(obj: T, forTest?: string): T {
  // if (forTest && !obj) console.log('removeEmptyKeysFromObject no have obj:', obj);
  // if (!obj) return obj;
  // const result = Object.entries(obj).reduce((acc, [k, v]) =>
  //   (!isNull(v) && (UtilsService.isObject(v) ? !UtilsService.isEmptyObj(v!) : true) && typeof v !== 'undefined')
  //   || typeof v === 'boolean' ? { ...acc, [k]: v } : acc, {}) as T;
  // return result;
  return UtilsService.removeEmptyKeysFromObject(obj);
}

export function isNull(value: any): boolean {
  return typeof value === 'object' && !value;
}

// !!! сравниваю 2 объекта. Если есть различия то выводится console.log // если одинаковые то вернется true
export function compareTwoObject<T extends object, O extends object>(obj_1?: T, obj_2?: O): boolean {
  if (!obj_1 || !obj_2) return false;
  const arr_difference_values: Array<{ obj_1: Array<any>, obj_2: Array<any> }> = [];
  const arr_1 = Object.entries(obj_1);
  const arr_2 = Object.entries(obj_2);

  arr_1?.forEach(el_1 => {
    const key_1 = el_1[0];
    const value_1 = el_1[1];
    arr_2?.forEach(el_2 => {
      const key_2 = el_2[0];
      const value_2 = el_2[1];
      if (key_1 === key_2) {
        if (value_1 !== value_2) {
          arr_difference_values.push({ obj_1: el_1, obj_2: el_2 });
        }
      }
    });
  });

  arr_difference_values?.forEach((el) => console.log('compareTwoObject difference:', el));
  return !arr_difference_values.length;
}

export function calculatePopupSizes(): { widthForPopup: string, heightForPopup: string } {
  const { width: screenWidth, height: screenHeight } = getMaxSizesForPopUp();
  let widthForPopup, heightForPopup;
  if (screenWidth > 1339) {
    widthForPopup = `${screenWidth - 100}px`; // было 80. PopupNewAnnouncementGeneralComponent не влезал при изменении размера экрана
    heightForPopup = `${screenHeight - 80}px`;
  } else {
    widthForPopup = `${screenWidth + screenWidth / 6}px`;
    heightForPopup = `${screenHeight + screenHeight / 10}px`;
  }
  return { widthForPopup, heightForPopup };
}

export function getMaxSizesForPopUp(): { width: number, height: number } {
  return {
    width: window.innerWidth < MaxSizeForAnnouncementPopUp.width ? window.innerWidth : MaxSizeForAnnouncementPopUp.width,
    height: window.innerHeight < MaxSizeForAnnouncementPopUp.height ? window.innerHeight : MaxSizeForAnnouncementPopUp.height,
  };
}

// === OTHER ========================================

