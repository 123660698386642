<div *ngIf='{ desktop: (deviceS.isDesktop$|async)!,
              mobile: (deviceS.isMobile$|async)!,
             } as obj' class='wrapperPopup'>
  <headTitle text='Add a payment method' svgRight='cross_grey&24' (svgRightEmit)='close()' typeText='text4-blueDark'>
  </headTitle>

  <div class="horizontal-lines">
    <div class="line line--first"></div>
    <div class="line line--second" [ngClass]="{'line--second-green': !dataPopup.isSelectPaymentType}"></div>
    <div class="line line--third"></div>
  </div>
  <ng-container *ngIf='dataPopup.isSelectPaymentType; else viewAccountsTpl'>



    <div class='content'>
      <!-- Debit Card Option -->
      <div class='payment-type g-flex g-flex--align-center' (click)='onSelectPaymentMethod(ACCOUNT_METHODS.INSTANT)'
        [ngClass]="{'selected-method': paymentMethod === ACCOUNT_METHODS.INSTANT}">
        <div class='instantPay'>Debit Card*</div>
      </div>

      <!-- Bank Account Option -->
      <div class='payment-type bank g-flex g-flex--align-center' (click)='onSelectPaymentMethod(ACCOUNT_METHODS.STANDARD)'
        [ngClass]="{'selected-method': paymentMethod === ACCOUNT_METHODS.STANDARD}">
        <div class='instantPay'>Bank Account</div>
      </div>

      <span class="instantPay-disclaimer">*Recommended for instant payouts</span>

      <btn [text]='"Continue"' [disabled]='!paymentMethod' bcg='blueDark' (click)='goToNextStep()'>
      </btn>
    </div>


  </ng-container>

  <ng-template #viewAccountsTpl>

    <div class='content' [formGroup]='paymentMethod === ACCOUNT_METHODS.INSTANT ? formCard : formBank'>
      <div *ngIf='!isEdit' class='content-next'>
        <ng-container [ngSwitch]='paymentMethod'>
          <ng-container *ngSwitchCase='ACCOUNT_METHODS.INSTANT'>
            <field text='Name on Card' class='width100'>
              <input class='input' [formControlName]='FORM_FIELDS.NAME' [id]='FORM_FIELDS.NAME' mask='S* S*'
                placeholder='Name on Card' autofocus />
            </field>

            <field text='Card number' class='width100'>
              <div [id]='FORM_FIELDS.NUMBER' class='input input--stripe'></div>
            </field>

            <field text='Expiry Date' class='width30'>
              <div [id]='FORM_FIELDS.EXPIRE' class='input input--stripe'></div>
            </field>

            <field text='CVV' class='width30'>
              <div [id]='FORM_FIELDS.CVV' class='input input--stripe'></div>
            </field>

            <field text='Zip Code' class='width30'>
              <input class='input' [formControlName]='FORM_FIELDS.ZIP' [id]='FORM_FIELDS.ZIP' mask='00000'
                placeholder='12345' />
            </field>

          </ng-container>

          <ng-container *ngSwitchCase='ACCOUNT_METHODS.STANDARD'>
            <field text='Your routing number' class='width100'>
              <inputCtrl [formControlName]='FORM_FIELDS.ROUTING' [isNumber]='true' [allowFirstZero]='true'
                placeholder='Your routing number' [minlength]='9' [maxLength]='9' [isFocus]='true'></inputCtrl>
            </field>

            <field text='Your account number' class='width100'>
              <inputCtrl [formControlName]='FORM_FIELDS.ACCOUNT' [isNumber]='true' [allowFirstZero]='true'
                placeholder='Your account number'></inputCtrl>
            </field>

            <field text='Confirm account number' class='width100'>
              <inputCtrl [formControlName]='FORM_FIELDS.ACCOUNT_CONFIRM' [isNumber]='true' [allowFirstZero]='true'
                placeholder='Confirm account number'></inputCtrl>
            </field>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <btnWrap class="btnMargin">
      <btn [text]='"Continue"'
        [disabled]='isSaveDisabled() || isButtonDisabled || (paymentMethod === ACCOUNT_METHODS.INSTANT && !isValidForm) || isLoading'
        bcg='blueDark' (click)='onSave()'>
      </btn>

    </btnWrap>

    <spinner *ngIf='((!isEdit && isLoading) || (showSpinner))' [diameter]='20' [isNoMargin]='true'></spinner>
  </ng-template>
</div>