import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-me-profile-billing',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './me-profile-billing.component.html',
  styleUrls: ['./me-profile-billing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeProfileBillingComponent {

}
