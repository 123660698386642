<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              arrTransfers: (payS.transfers$|async),
             } as obj' class='o-wrapPage o-wrapPage--noMarginTopAndNoPadding' wrapPage [widthPage]='1300'>

  <!--  === FILTERS & TITLE ===========================-->
  <div class='o-header'>
    <div class='o-header__linkPage'>
      <app-dropdown [ngModel]='currentLink' [array]='payS.linkPaymentsItems' (changeValObj)='payS.navigate($event)'></app-dropdown>
      <app-filtersSelectedIcon></app-filtersSelectedIcon>
    </div>
    <div class='o-header__content'>
      <div class='o-header__title'>{{ currentLink.titleCase }}</div>
      <div class='o-header__filters'>
        <app-filters (emit)='emitSettingRequest($event)'></app-filters>
      </div>
      <app-filtersSelected></app-filtersSelected>
    </div>
  </div>

  <div class='o-content'>
    <div class='wrapperForBlockChart' *ngIf='{sum: (payS.transfersOfficialSum$|async)!} as objTransferSum'>
      <!--    === 1 block ========================================-->
      <div class='blockChart'>
        <div class='blockChart__header'>
          <div class='blockChart__header__title'>
            <span>Officials Fees Paid</span>
            <span class='o-mg-t-12-important'>{{ (objTransferSum.sum.STRIPE + objTransferSum.sum.OFFLINE)|roundBalance }}</span>
          </div>
        </div>
        <div class='blockChart__chart'>
          <div class='blockChart__chart__info'>
            <meSvg [svgName]='true ? "arrow_topGreen&16":"arrow_bottomRed&20"'></meSvg>
            <span [class]='true ? "o-colorNewGreen":"o-color-red"'>${{ '4,300 ' }}YTD</span>
          </div>
          <meSvg svgName='test_d3js2'></meSvg>
        </div>
      </div>

      <!--    === 2 block ========================================-->
      <div class='blockChart'>
        <div class='blockChart__header'>
          <div class='blockChart__header__title'>
            <span class='notchPay'>Notch Pay<meSvg svgName='lightning_gold&20'></meSvg></span>
            <span class='o-mg-t-12-important'>{{ objTransferSum.sum?.STRIPE|roundBalance }}</span>
          </div>
          <div class='blockChart__header__title o-opacity'>
            <span class='offline'>Offline</span>
            <span class='o-mg-t-12-important'>{{ objTransferSum.sum?.OFFLINE|roundBalance }}</span>
          </div>
        </div>
        <progressBar [value]='75' color='newGreen' bcg='grey_3' [height]='14'></progressBar>
        <div class='blockChart__bottomOfficialsFees'>
          <svgAndText [text]='32 + " Competitions"' leftSvgName='circle_green&8' marginForLeftSvg='8'
                      typeText='text7-blueDark'></svgAndText>
          <svgAndText [text]='12 + " Competitions"' leftSvgName='circle_grey1&8' marginForLeftSvg='8'
                      typeText='text7-blueDark'></svgAndText>
          <svgAndText text='Manage' rightSvgName='arrow_chevronRight&24' marginForRightSvg='4' typeText='text7-blueDark'></svgAndText>
        </div>
      </div>

      <!--    === 3 block ========================================-->
      <div class='blockChart'>
        <div class='blockChart__header'>
          <div class='blockChart__header__title'>
            <!--          Estimated Expenses   -->
            <span>{{ isShowSumFees ? "Projected Outgoings" : "Official Fees" }}</span>
            <span>{{ isShowSumFees ? ((payS.sumFees$|async)!|roundBalance) : ((payS.officialFees$|async)!|roundBalance) }}</span>
          </div>
          <meSvg svgName='question_grey&16' class='o-flex-align-self-start'></meSvg>
        </div>
        <div class='blockChart__header o-mg-t-20'>
          <div class='blockChart__header__title {{isShowSumFees ? "o-hidden":""}}'>
            <span>Assignor Fees</span>
            <span>{{ (payS.assignorFees$|async)!|roundBalance }}</span>
          </div>
          <div class='blockChart__header__arrows'>
            <meSvg svgName='arrow_squareLeft&24' (click)='isShowSumFees=!isShowSumFees' [disabled]='!isShowSumFees'></meSvg>
            <meSvg svgName='arrow_squareRight&24' (click)='isShowSumFees=!isShowSumFees' [disabled]='isShowSumFees'></meSvg>
          </div>
        </div>
      </div>
    </div>

    <!--  === TABLE =====================-->
    <div class='wrapTablePayments'>
      <div class='wrapTablePayments__title'>Recent Transactions</div>
      <div class='wrapTablePayments__header'>
        <!--      <inputCtrl formControlName='search' placeholder='Search' svgPrefix='search_grey&20'></inputCtrl>-->
        <inputCtrl [(ngModel)]='search' placeholder='Search' svgPrefix='search_grey&20'></inputCtrl>
        <div class='wrapTablePayments__header__actions'>
          <meSvg svgName='download_3&44' [disabled]='true'></meSvg>
          <btn text='View All' bcg='white' (emit)='payS.goToAllList(router.url)' btnWidth='93'
               [disabled]='!obj.arrTransfers?.length'></btn>
          <btn text='Mark as paid' bcg='blueDark' (emit)='null' btnWidth='133' [disabled]='true'></btn>
        </div>
      </div>

      <div class='o-table o-mg-t-16'>
        <div class='o-table__header'>
          <div *ngFor='let itemHeader of tableHeader; let idx = index;' class='o-table__header__item widthItem--{{idx+1}}'>
            <meSvg *ngIf='itemHeader.isChx' [svgName]='isSelectAll|getChxSvgName' class='o-table__header__item__chx'
                   (click)='selectAll()'></meSvg>
            {{ itemHeader.text }}
            <!--            <meSvg *ngIf='itemHeader.isActive' [svgName]='"arrow_top&16"' class='o-table__header__item__arrow'></meSvg>-->
          </div>
        </div>
        <div *ngIf='obj.arrTransfers?.length; else nothingList' class='o-table__list'>
          <ng-container *ngFor='let item of obj.arrTransfers; let first=first; let idx=index; let last=last'>
            <div class='o-table__list__item'>
              <div class='widthItem--1'>
                <meSvg [svgName]='item?.isSelect|getChxSvgName' (click)='selectItem(item, $event)'></meSvg>
                <span>{{ item.gameNumber }}</span>
              </div>
              <div class='widthItem--2'>
                <span>{{ item.createdAtFormatted }}</span> &nbsp;
                <span>at {{ item.createdAtFormatted }}</span>
              </div>
              <div class='widthItem--3'>
                <!--                <payoutFormat [payoutFormat]='item.payoutFormat!'></payoutFormat>-->
              </div>
              <!--    andrei     Officials Fees    Assignor Fees     Billed Amount -->
              <div class='widthItem--4'>
                <!--                <span>+{{item.total|currency}}</span>-->
              </div>
              <div class='widthItem--5'>
                <!--                <span>+{{item.total|currency}}</span>-->
              </div>
              <div class='widthItem--6'>
                <span>+{{ item.total|currency }}</span>
              </div>
              <div class='widthItem--7'>
                <btn text='View' bcg='white' [btnWidth]='87' [btnHeight]='36'></btn>
              </div>
              <div class='widthItem--8'>
                <!--              [matMenuTriggerFor]='menuForPoints' (click)='$event.stopPropagation()'-->
                <meSvg svgName='arrow_chevronRight&24'></meSvg>
                <!--              <mat-menu #menuForPoints='matMenu'>-->
                <!--                                  <button (click)='null'></button>-->
                <!--              </mat-menu>-->
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #nothingList>
  <!--  <nothingList typePage='balances'></nothingList>-->
  <nothingList [tableEmpty]='filtersS.searchValue|getTableEmpty'></nothingList>
</ng-template>
