import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationService } from '@services/notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MainService } from '@services/main.service';
import { colorObj } from '@classes/CSS';
import { loadStripe } from '@stripe/stripe-js';

// declare var StripeCheckout: any;
declare var Stripe: any;
// declare var Plaid: any; // for ACH

export type TFieldStripeForm = 'cardNumber' | 'cardExpiry' | 'cardName' | 'cardCvc' | 'zip';

export interface IStripeEvent {
  brand?: string;
  complete?: boolean;
  elementType?: TFieldStripeForm;
  empty?: boolean;
  error?: { code?: string, message?: string, type?: string };
  value?: any;
}

export interface ISendObjForCreatePaymentMethod {
  type?: string;
  card?: any;
  billing_details?: { name?: string }; // email // https://stripe.com/docs/js/setup_intents/collect_bank_account_for_setup?type=cardElement#stripe_collect_bank_account_for_setup-options-params-payment_method_data-billing_details-name
}

export interface IResCreatePaymentMethodSuccess {
  paymentMethod?: {
    billing_details?: { address?: any, email?: null, name?: string, phone?: null };
    card?: { brand?: string, checks?: any, country?: string, exp_month?: number, exp_year?: number };
    created?: number; // 1674098888
    customer?: any; // null
    id?: string; // "pm_1MRonMBIMZTJKM1ctWqa7RQa"
    livemode?: boolean;
    object?: string; // "payment_method"
    type?: string; // "card"
  };
}

export interface IResCreatePaymentMethodError {
  code?: string; // 'incomplete_number'
  message?: string; // 'Номер карты неполон.'
  type?: string; // 'validation_error'
}

export interface IResCreateTokenSuccess {
  token?: {
    card?: { id: string, object: string, address_city: any, address_country: any, address_line1: any };// {id: 'card_1MRbVeBIMZTJKM1cOlI2XYxZ', object: 'card', address_city: null, address_country: null, address_line1: null}
    client_ip?: string; // "49.237.23.107"
    created?: number; // 1674047818
    id?: string; // "tok_1MRbVeBIMZTJKM1c8IXFqEMl"
    livemode?: boolean; // false
    object?: string; // "token"
    type?: string; // "card"
    used?: boolean; // false
  };
}

@Injectable({ providedIn: 'root' })
export class StripeService {
  stripe?: any;
  stripeKey = window.location.href.includes('beta') || window.location.href.includes('staging') ||
  window.location.href.includes('localhost')
    ? 'pk_test_51IipxpBIMZTJKM1cz1agFcNtoxQyQaVHfoSphxhKhFrasxT1GAOlWKrKjH6wzYxrRJO7KvwUymC0yBgztJWM4EAu00MJAmXyge'
    : 'pk_live_51IipxpBIMZTJKM1cOstiJEYyurTlgte6xmLpmsGbGJSQIqNGuawwzPX5XumeHmrJ4HCiMnAY4Nn3Ya2h7Si2wabf000n3fEtgq';

  styles = {
    base: {
      color: colorObj.blueDark, fontWeight: 600, fontSize: '14px', letterSpacing: '0.2px', fontStyle: 'normal',
      fontFamily: 'Gilroy-semibold, Gilroy-bold, Helvetica Neue Bold, Helvetica, Arial, sans-serif',
      ':focus': { color: colorObj.blueDark }, '::placeholder': { color: colorObj.grey_2 },
    },
    invalid: { color: '#ff0000', ':focus': { color: colorObj.blueDark }, '::placeholder': { color: '#ff0000' } },
  };
  classes = { empty: 'o-input--empty', invalid: 'o-input--error', focus: 'o-input--active' };

  constructor(
    private mainS: MainService,
    private notificationS: NotificationService,
  ) {
    this.initStripe();
  }

  getAuctionStripe(): any {
    if (!this.stripe.auction) this.stripe.auction = new Stripe(this.stripeKey);
    return this.stripe.auction;
  }

  initStripe(): void {
    if (!this.stripe) this.stripe = new Stripe(this.stripeKey);
  }

  // async initStripe(): Promise<void> {
  //   // if (!this.stripe) this.stripe = new Stripe(this.stripeKey); 
  //   this.stripe = await loadStripe(this.stripeKey);
  //   if (!this.stripe) {
  //     console.error('Failed to load Stripe');
  //   }
  // }

  createPaymentMethod(sendObj: ISendObjForCreatePaymentMethod): Observable<IResCreatePaymentMethodSuccess | IResCreatePaymentMethodError | null> {
    if (!this.stripe) this.initStripe();
    try {
      return from(this.stripe?.createPaymentMethod(sendObj)).pipe(
        // tap((res: IPaymentMethod | IResCreatePaymentMethodError) => {
        tap((res: any) => {
          if (res?.error) {
            console.error('stripe?.createPaymentMethod(sendObj) res', 'err', res);
            this.showError(false, res);
          }
        }),
      );
    } catch (error: any) {
      console.error('stripe?.createPaymentMethod(sendObj) error', 'err', error);
      this.showError(false, error);
      return of(null);
    }
  }

  showError(type: boolean, err?: HttpErrorResponse): void {
    const message = err?.error?.message || err?.message;
    if (!message) return;
    new Promise(resolve => resolve(err))
      .then(() => this.notificationS.passNote(message, { status: 'error', icon: 'check-circle' }));
  }



}

