import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadTitleComponent } from '../../head-title/head-title.component';
import { MatDialogRef } from '@angular/material/dialog';
import { BtnComponent } from '../../btn/btn.component';
import { AccountPaymentComponent } from '@components/account-payment/account-payment.component';
import { FieldComponent } from '../../__drop_inputs_matSelect/field/field.component';
import { DropFormCtrlComponent } from '../../__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { getDropFromString } from '@components/__drop_inputs_matSelect/dropdown/dropdown';

@Component({
  selector: 'app-popup-new-announcement',
  standalone: true,
  templateUrl: './popup-new-announcement.component.html',
  styleUrls: ['./popup-new-announcement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, HeadTitleComponent, BtnComponent, AccountPaymentComponent, FieldComponent, DropFormCtrlComponent, FormsModule, DropdownComponent, ReactiveFormsModule],
})
export class PopupNewAnnouncementComponent {
  type: string = '';
  arrAssignStatus = ['General'].map(el => getDropFromString(el, ""));

  constructor(
    public dialogRef: MatDialogRef<PopupNewAnnouncementComponent>,
  ) {

  }

  close(data: string | null): void {
    this.dialogRef.close(data);
  }
}
