import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass } from '@classes/Helper-Classes';
import { MeService } from '@services/me.service';
import { DeviceService } from '@services/device.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { arrDropStatusAssign, arrStatusGameString_withoutCanceled, ClassGame } from '@app/dir_group_assignor/games/game';
import { MainService } from '@services/main.service';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { FiltersService } from '@components/filters/filters.service';
import {
  ClassFilterDateRange,
  ClassFilterDrop,
  ClassFilterInput,
  IForClassForFilters,
  TAllInterfacesFilters,
} from '@components/filters/filters';
import { ClassCeilTableHeader, IForClassForTable } from '@components/_table/meTable';
import { urlAssign, urlReportInfo } from '@app/app.module';
import { IResponse } from '@models/response-and-request';
import { MeTableService } from '@components/_table/me-table.service';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AssignService } from '@app/dir_group_assignor/assign/assign.service';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { CdkDropListGroup } from '@angular/cdk/drag-drop';
import { CdkScrollable } from '@angular/cdk/overlay';
import { DataTableDirective } from '@components/_table/directives/data-table.directive';
import { LinkPageSearchFilterComponent } from '@components/__settingsRequest/link-page-search-filter/link-page-search-filter.component';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import { TableHeaderComponent } from '@components/_table/table-header/table-header.component';
import { ItemTableDirective } from '@components/_table/directives/item-table.directive';
import { CeilTableDirective } from '@components/_table/directives/ceil-table.directive';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { TableDoubleCeilComponent } from '@components/_table/table-double-ceil/table-double-ceil.component';
import { GetAgeGenderLevelPipe } from '@pipes/get-age-gender-level.pipe';
import { GetLocNameCourtNamePipe } from '@pipes/location/get-loc-name-court-name.pipe';
import { GetLocationStringPipe } from '@pipes/location/get-location-string.pipe';
import { MatTooltipForLocationPipe } from '@pipes/location/mat-tooltip-for-location.pipe';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';
import { TableEmptyComponent } from '@components/_table/table-empty/table-empty.component';
import {
  CeilAssignGameOfficialComponent,
} from '@app/dir_group_assignor/assign/components/ceil-assign-game-official/ceil-assign-game-official.component';
import { BtnImportGamesComponent } from '@app/dir_group_assignor/games/components/btn-import-games/btn-import-games.component';
import {
  AssignOfficialAvailabilityComponent,
} from '@app/dir_group_assignor/assign/components/assign-official-availability/assign-official-availability.component';
import { ShowBtnPublishPipe } from '@app/dir_group_assignor/assign/pipes/show-btn-publish.pipe';
import { TableInfiniteLoadingComponent } from '@components/_table/table-infinite-loading/table-infinite-loading.component';
import { PaginationWithSettingsComponent } from '@components/__settingsRequest/pagination-with-settings/pagination-with-settings.component';
import { BtnComponent } from '@components/btn/btn.component';

@UntilDestroy()
@Component({
  selector: 'app-assign',
  standalone: true,
  imports: [CommonModule, WrapPageDirective, CdkDropListGroup, CdkScrollable, DataTableDirective, LinkPageSearchFilterComponent, FiltersComponent, FiltersSelectedComponent, TableHeaderComponent, ItemTableDirective, CeilTableDirective, SvgComponent, TableDoubleCeilComponent, GetAgeGenderLevelPipe, GetLocNameCourtNamePipe, GetLocationStringPipe, MatTooltipForLocationPipe, GetTableEmptyPipe, TableEmptyComponent, CeilAssignGameOfficialComponent, BtnImportGamesComponent, AssignOfficialAvailabilityComponent, ShowBtnPublishPipe, TableInfiniteLoadingComponent, PaginationWithSettingsComponent, BtnComponent],
  templateUrl: './assign.component.html',
  styleUrls: ['./assign.component.scss'],
  providers: [FiltersService, MeTableService, SettingsRequestService], // for-filters=== for-table=== for-settings=== for-linkPage===
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignComponent extends HelperClass implements IForClassForFilters, IForClassForTable<ClassGame> {
  @ViewChild('tableList', { static: false }) tableList?: ElementRef;
  @ViewChildren('listItem') listItems!: QueryList<ElementRef>;
  dataTable$ = new BehaviorSubject<IResponse<ClassGame>>({}); // for-table===
  arrContent$ = new BehaviorSubject<Array<ClassGame>>([]); // for-table===
  readonly urlAssign = urlAssign;
  readonly urlReportInfo = urlReportInfo;
  readonly widthPage = 1393;

  constructor(
    public mainS: MainService,
    public gameS: GameService,
    public meS: MeService,
    public deviceS: DeviceService,
    public route: ActivatedRoute,
    public router: Router,
    public filtersS: FiltersService, // for-filters===
    public meTableS: MeTableService<ClassGame>, // for-table===
    public settingsRequestS: SettingsRequestService, // for-settings===
    public assignS: AssignService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.assignS.resetAllForFirstOpenPage(); // !!! т.к. сервис глобальный, то нужно скидывать все значения при повторном открытии страницы
    this.setAllData();
    // this.subscribeToQueryParams();
  }

  // === // for-filters=== for-table=== for-settings=== =======================================================================
  // !!! если есть в фильтрах компетишн, то обязательно надо в AppModule к роуту прикрутить CompetitionsForFiltersResolver
  setAllData(): void {
    this.setDataForTable(); // for-table===
    this.setFilters(); // for-filters===
    this.settingsRequestS.setAllData(this.isFirstLoadPageSub$); // for-settingsDynamicUrl=== создание ссылки, чтобы можно было использовать в сервисе
    this.gameS.setCurrentLink_games(this.settingsRequestS.settings.currentLink_games); // for-currentLink===
    this.subscribeToSettings(); // for-settings===
  }

  // === SETTINGS ===============================
  subscribeToSettings(): void {
    this.settingsRequestS.settings$.pipe(
      switchMap((res) => this.getSubject_forGetGamesList()),
      untilDestroyed(this),
    ).subscribe((res) => {
      this.cd.detectChanges();
    });
  }

  private getSubject_forGetGamesList(): Observable<IResponse<ClassGame>> {
    // const gameStatuses = this.assignS.queryParams.gameStatuses ?? arrStatusGameString_withoutCanceled.join(',');
    const gameStatuses = this.route.snapshot?.queryParams?.gameStatuses ?? arrStatusGameString_withoutCanceled.join(',');
    // const settings: ClassSettingsRequest = { ...this.assignS.queryParams, gameStatuses };
    return this.meTableS.getSubject_forGetGamesList({ gameStatuses });
  }

  // === FILTERS ==========================================
  setFilters(): void {
    const search = new ClassFilterInput({ typeFilter: 'search' });
    const dateRange = new ClassFilterDateRange();
    const competition = new ClassFilterDrop({
      typeFilter: 'competitions',
      arrayForDropdown: this.gameS.arrCompetition$.getValue(),
      disabled: !this.gameS.arrCompetition$.getValue()?.length,
    });
    const assignStatus = new ClassFilterDrop({
      typeFilter: 'assignStatuses',
      arrayForDropdown: arrDropStatusAssign,
      multi: true,
    });
    const arrFilters: Array<TAllInterfacesFilters> = [search, dateRange, competition, assignStatus];
    this.filtersS.setFilters(arrFilters, true, true);
  }

  // === TABLE ======================================================
  setDataForTable(): void {
    this.meTableS.dataTable$ = this.assignS.dataTable$ = this.dataTable$; // !!! создание ссылки. Чтобы можно было использовать в MeTableService
    this.meTableS.arrContent$ = this.assignS.arrContent$ = this.arrContent$; // !!! создание ссылки. Чтобы можно было использовать в MeTableService
    this.meTableS.paddingLeft_forCeil = 16;
    const arrayCeilHeader: Array<ClassCeilTableHeader> = [
      new ClassCeilTableHeader({ text: 'Game' }),
      new ClassCeilTableHeader({ text: 'Date & Time / Age & Level', symbolSeparation: '/' }),
      // !!! style: {paddingLeft: 39} => на странице assign в колонке "Location & Teams" paddingLeft должен быть 39px, потому что там в фигме звездочки нарисованы (чтобы понять нужно посмотреть фигму)
      new ClassCeilTableHeader({ text: 'Location & Teams', symbolSeparation: '&', style: { paddingLeft: 39 } }),
      new ClassCeilTableHeader({
        text: 'Officials',
        sortBy: true,
        style: { paddingLeft: this.meTableS.paddingLeft_forCeil },
      }),
    ];
    const arrWidthCeilTable: Array<number> = [85, 244, 274, 398]; // !!! ширина для каждой ячейки для Desktop => сумма должна совпадать с this.widthPage
    this.meTableS.setArrTypeSorting(['byGameNumber', 'byDate', 'byGameLocation']); // !!! for-sorting===
    this.meTableS.setDataForTable(arrWidthCeilTable, arrayCeilHeader, this.widthPage, true);
  }

  // === btn Publish ============================
  assignOfficials(isPublish: boolean): void {
    if (this.startRequest()) return;
    this.assignS.assignOfficials(isPublish).pipe(
      switchMap((res) => this.getSubject_forGetGamesList()),
      untilDestroyed(this),
    )
      .subscribe((res) => {
        this.endRequest();
      });
  }

}

// !!! после того как assignS.goToAssign() => нужно выделить выбраную игру зеленым бордером
// subscribeToQueryParams(): void {
//   this.route.queryParams.pipe(untilDestroyed(this)).subscribe((queryParams: IGoToAssign) => {
//     const alreadyExistQueryParams: boolean = !!Object.keys(this.assignS.queryParams)?.length; // !!! чтобы не перезатирался assignS.queryParams при первой загрузке страницы
//     if (!queryParams || alreadyExistQueryParams) return;
//     // this.assignS.gameIdFromQueryParams = (queryParams.search && queryParams.gameId) ? queryParams.gameId : undefined;
//     if (queryParams.search && queryParams.gameId) { // !!! for goToAssign()
//       this.assignS.queryParams.gameId = queryParams.gameId; // !!! нужно выбраную игру зеленым бордером
//     } else { // !!! for manageAllAndGoToAssign()
//       this.assignS.queryParams.gameStatuses = queryParams.gameStatuses;
//       this.assignS.queryParams.status = queryParams.status;
//       this.assignS.queryParams.assignStatuses = queryParams.assignStatuses;
//     }
//   });
// };
