<div class='wrapperPopup'>
  <headTitle [text]='announcementItem.subject || ""'
             svgRight='cross_grey&24'
             (svgRightEmit)='close(null)'
             typeText='text4-blueDark'></headTitle>

  <div class="content" style="display: flex;">
    <div class="content-item">
      <div class="o-text7">
        From:
      </div>
      <div class="o-text6">{{announcementItem?.userData?.firstName}} {{announcementItem?.userData?.secondName}}</div>
    </div>
    <div class="content-item">
      <div class="o-text7">Type:</div>
      <div class="o-text6">{{announcementItem.type | titlecaseNoUnderscore: true}}</div>
    </div>
    <div class="content-item">
      <div class="o-text7">Viewable to:</div>
      <div class="o-text6">{{recipientsCounter | async | number:'1.0-0' }} recipients</div>
    </div>
    <div class="content-item">
      <div class="o-text7">Method:</div>
      <div class="o-text6">{{method}}</div>
    </div>
    <div class="content-item" style="flex: 1;">
      <div class="o-text7">Message:</div>
      <div class="o-text6 content-item-message"
           [innerHTML]="sanitizedText"></div>
    </div>
  </div>

  <div class='actionsPopup'>
    <btn text='Close'
         bcg='white'
         (emit)='close(null)'>

    </btn>
    <btn text='Send'
         bcg='blueDark'
         *ngIf="announcementItem.status === 'DRAFT'"
         (emit)='close([announcementItem.id])'>
    </btn>
  </div>
</div>