import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormsModule, NgControl } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TColor } from '@models/ICssStyles';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { GetChxSvgNamePipe } from '@components/__svg_img/svg/pipes/get-chx-svg-name.pipe';

@Component({
  selector: 'chx',
  standalone: true,
  imports: [CommonModule, MatCheckboxModule, FormsModule, SvgComponent, GetChxSvgNamePipe],
  templateUrl: './chx.component.html',
  styleUrls: ['./chx.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChxComponent implements ControlValueAccessor, OnChanges, AfterViewInit {
  @Input() text = '';
  @Input() colorText: TColor = 'blueDark';
  @Input() chxWidth: number | string = 20;

  @Input() cur?: boolean = true; // cursor: cur ? pointer : default

  // === FOR [(ngModel)] ===========================
  @Input() isNgModel = false; // !!! использовать если нет formControlName, тоесть если [(ngModel)]='' (если ngModel не забывать [ngModelOptions]='{standalone: true}')
  @Input() disabled = false; // !!! использовать если ngModel==true

  @Output() changeVal = new EventEmitter<boolean>();

  private onChange!: (value: boolean) => void;
  private onTouched!: () => void;

  constructor(
    public ngControl: NgControl,
    public cd: ChangeDetectorRef,
  ) {
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isNgModel && changes?.disabled?.currentValue) {
      this.setDisabledState(this.disabled);
    }
  }

  ngAfterViewInit() {
    if (this.ngControl?.control) {
      if (this.isNgModel) return; // !!! если true , то значит использовал ngModel. Тоесть из formControl не надо доставать значение
      this.setDisabledState(this.ngControl.control.disabled);
    }
  }

  writeValue(value: boolean): void {
    // console.log('writeValue :', typeof value, value)
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.detectChanges();
  }

  // === GETTERS & SETTERS =======================
  set value(value: boolean) {
    if (!this.onChange) {
      return;
    }

    // this.ngControl.control?.setValue(value); !!! эта штука не нужна, она дублировала функцию onChange и в результате получался двойной вызов в valueChanges
    this.onChange(value); // чтобы в родит.компонент передать
    this.onTouched(); // чтобы в родит.компонент передать
    this.changeVal.emit(value);
    this.cd.detectChanges();
  }

  get value(): boolean {
    return this.ngControl.control?.value;
  }
}
