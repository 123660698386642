import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'excludeArrOfArr', standalone: true })
export class ExcludeArrOfArrPipe implements PipeTransform {

  // example [['id', 'textText111'], ['value', 'textText222']], key: 'id' => return [['value', 'textText222']]
  transform(arr: Array<Array<any>>, key: string): Array<Array<any>> {
    return arr.filter((el) => el[0] !== key);
  }

}
