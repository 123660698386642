import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'getSelectedElems', standalone: true, pure: false })  // !!! NO DELETE pure: false
export class GetSelectedElemsPipe implements PipeTransform { // no work <T> , need check later

  // transform(array?: Array<T & { isSelect?: boolean }>): Array<T> {
  //   if (!array?.length) return [];
  //   const result: Array<T> = [];
  //   array.forEach((el) => {
  //     if (el.isSelect) result.push(el);
  //   });
  //   return result;
  // }

  transform(array?: Array<any>): Array<any> {
    if (!array?.length) return [];
    const result: Array<any> = [];
    array.forEach((el) => {
      if (el.isSelect) result.push(el);
    });
    return result;
  }
}
