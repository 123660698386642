import { ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass } from '@classes/Helper-Classes';
import { MainService } from '@services/main.service';
import { IResponse } from '@models/response-and-request';
import { ClassCompetition } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { BehaviorSubject, switchMap, timer } from 'rxjs';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { DeviceService } from '@services/device.service';
import { FiltersService } from '@components/filters/filters.service';
import { ClassFilterDateRange, ClassFilterDrop, ClassFilterInput, IForClassForFilters } from '@components/filters/filters';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import {  ReactiveFormsModule } from '@angular/forms';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { BtnComponent } from '@components/btn/btn.component';
import { MatMenuModule } from '@angular/material/menu';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MeTableService } from '@components/_table/me-table.service';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { LinkPageSearchFilterComponent } from '@components/__settingsRequest/link-page-search-filter/link-page-search-filter.component';
import { PaginationWithSettingsComponent } from '@components/__settingsRequest/pagination-with-settings/pagination-with-settings.component';
import { DataTableDirective } from '@components/_table/directives/data-table.directive';
import { TableInfiniteLoadingComponent } from '@components/_table/table-infinite-loading/table-infinite-loading.component';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { IForClassForTable, ClassCeilTableHeader } from '@components/_table/meTable';
import { GlobasConst } from '@shared/constants/global-const';
import { StatusComponent } from '@components/status/status.component';
import { CurrencyCentsPipe } from '@app/dir_group_assignor/payments/pipes/currency-cents.pipe';
import { UserGameTransferInfoDto } from '@app/dir_group_assignor/payments/models/user-game-transfer-info-dto';
import { AddFiltersComponent } from '@components/filters/add-filters/add-filters.component';
import { AccountApiService } from '@services/account.api.service';
import { MeService } from '@services/me.service';
import { ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';

@UntilDestroy()
@Component({
  selector: 'app-view-all-transactions',
  standalone: true,
  imports: [StatusComponent, CommonModule, 
    WrapPageDirective, CdkScrollable,  FiltersComponent, FiltersSelectedComponent, 
  TableInfiniteLoadingComponent, PaginationWithSettingsComponent, SvgComponent, BtnComponent, RouterLink,   InputCtrlComponent, LinkPageSearchFilterComponent,   CurrencyCentsPipe, HeadTitleComponent,
  DataTableDirective, ReactiveFormsModule, AddFiltersComponent],
  templateUrl: './view-all-transactions.component.html',
  styleUrls: ['./view-all-transactions.component.scss'],
  providers: [FiltersService, MeTableService, SettingsRequestService],
})
export class ViewAllTransactionsComponent extends HelperClass implements IForClassForFilters, IForClassForTable<UserGameTransferInfoDto> {
  dataTable$ = new BehaviorSubject<IResponse<UserGameTransferInfoDto>>({});
  arrContent$ = new BehaviorSubject<Array<UserGameTransferInfoDto>>([]);

  readonly widthPage = 1028;

  downloadDisabled = false;
  totalAssigningFees: number | undefined;
  availablePayout: number | undefined;


  countAllTransactions: number | undefined;
  constructor(
    public globalConst: GlobasConst,
    private apiCompetitionS: ApiCompetitionService,
    public mainS: MainService,
    public gameS: GameService,
    private route: ActivatedRoute,
    public router: Router,
    public deviceS: DeviceService,
    public filtersS: FiltersService,
    public meTableS: MeTableService<UserGameTransferInfoDto>, 
    public settingsRequestS: SettingsRequestService, 
    public cd: ChangeDetectorRef,
    public accountApiS: AccountApiService,
    public meS: MeService,
    

  ) {
    super(cd);
  }


  ngOnInit() {

    this.mainS.getAssignorFeesListV2({ params: { page: 1, size: 25 } }).subscribe(x => {
      const processed = (x.content || []).map((item: any) => ({
        ...item,
        transferAmount: item.transferTotal?.amount ?? 0,
        assignorName: item.assignorName 
      }));
      this.arrContent$.next(processed);
    });
    // this.mainS.getAssignorFeesListV2({ params: { page: 1, size: 1 } }).subscribe(x => this.countAllTransactions = x.totalElements);
    const transactionId = this.route.snapshot.queryParams['gameId'];
    this.setAllData();
  }

  setAllData(): void {
    this.setDataForTable();
    this.setFilters();
    this.settingsRequestS.setAllData(this.isFirstLoadPageSub$);
    this.subscribeToSettings();
  }

  // === SETTINGS ===============================
  subscribeToSettings(): void {
    this.settingsRequestS.settings$.pipe(
      switchMap((res) => {
      //  this.getSeasons(res.competitions);
        return this.meTableS.getSubject_forAssignorFeesV2();
      }),
      untilDestroyed(this),
    ).subscribe((res) => { });
  }


  setFilters(): void {
    const dateRange = new ClassFilterDateRange({ arrPeriods: ClassFilterDateRange.templatePreviosData });
    const competition = new ClassFilterDrop({ typeFilter: 'competitions' });
    const payoutFormat = new ClassFilterDrop({
      typeFilter: 'payoutFormat',
    });

    const paymentMethod = new ClassFilterDrop({ typeFilter: 'paymentMethod' });
    const userId = this.meS.userId;
    this.accountApiS.getAccounts(userId).pipe(untilDestroyed(this)).subscribe((accounts) => {
      const paymentMethodOptions = accounts.map(account => new ClassDrop({
        titleCase: `****${account.last4}`,
        upperCase: account.id
      }));
      this.filtersS.updateFilter('paymentMethod', {
        arrayForDropdown: paymentMethodOptions
      }, 'setFilters - Payment Methods');
    });

    const status = new ClassFilterDrop({
      typeFilter: 'status',
      arrayForDropdown: [
        new ClassDrop({ titleCase: 'OPEN', upperCase: 'OPEN' }),
        new ClassDrop({ titleCase: 'PENDING', upperCase: 'PENDING' }),
        new ClassDrop({ titleCase: 'FAILED', upperCase: 'FAILED' }),
        new ClassDrop({ titleCase: 'SUCCEEDED', upperCase: 'SUCCEEDED' }),
        new ClassDrop({ titleCase: 'CANCELLED', upperCase: 'CANCELLED' }),
        new ClassDrop({ titleCase: 'REFUNDED', upperCase: 'REFUNDED' }),
        new ClassDrop({ titleCase: 'INCOMPLETE', upperCase: 'INCOMPLETE' }),
      ]
    });


    this.apiCompetitionS.getArrCompetition().toPromise()
      .then((res) => {
        const arrCompetitions = res?.content || [];
        const competitions_filterDrop = new ClassFilterDrop({
          typeFilter: 'competitions',
          arrayForDropdown: arrCompetitions || [],
          valueDrop: this.gameS.arrCompetition$.getValue()?.find(el => el.id === this.settingsRequestS.settings.competitions),
          disabled: false,
        });
        this.filtersS.updateAllFilters([competitions_filterDrop]);
      })
      .catch(err => {
      });
    const seasons = new ClassFilterDrop({ typeFilter: 'seasons', disabled: true });
    const search = new ClassFilterInput({ typeFilter: 'search' });
    this.filtersS.setFilters([search, dateRange, competition, seasons, paymentMethod, status], true, true);
  }

  // savePrevCompetitionId: string | undefined = undefined;
  // getSeasons(competitionId: string | undefined): void {
  //   if (competitionId == null || this.savePrevCompetitionId === competitionId)
  //     return;
  //   this.savePrevCompetitionId = competitionId;
  //   this.apiCompetitionS.methodCompetitionSeasons({ id: competitionId }, 'get').toPromise()
  //     .then((res?: Pick<ClassCompetition, 'seasons'>) => {
  //       const seasons = new ClassFilterDrop({
  //         typeFilter: 'seasons',
  //         disabled: !res?.seasons?.length,
  //         arrayForDropdown: res?.seasons || [],
  //       });
  //       this.filtersS.updateFilter('seasons', seasons);
  //     })
  //     .catch(err => {
  //     });
  // }

  setDataForTable(): void {
    this.meTableS.dataTable$ = this.dataTable$;
    this.meTableS.arrContent$ = this.arrContent$;

    const arrayCeilHeader: Array<ClassCeilTableHeader> = [
      new ClassCeilTableHeader({ text: 'Game' }),
      new ClassCeilTableHeader({ text: 'Game Date' }),
      new ClassCeilTableHeader({ text: 'Competition' }),
      new ClassCeilTableHeader({ text: 'Transaction Date' }),
      new ClassCeilTableHeader({ text: 'Amount' }),
      new ClassCeilTableHeader({ text: 'Status' }),
      new ClassCeilTableHeader({ text: '' }),
    ];

    const arrWidthCeilTable: Array<number> = [120, 371, 194, 120, 115, 135, this.meTableS.minWidthFor_sortByCeil];
    this.meTableS.setArrTypeSorting(['byGameNumber']);
    this.meTableS.setDataForTable(arrWidthCeilTable, arrayCeilHeader, this.widthPage, true);
  }

  goBack() {
    this.router.navigate(['/myWalletOfficial']);
  }

  viewDetailsClicked(gameId: string, isButton: boolean) {
    if (this.deviceS.isMobile || isButton)
      this.router.navigate(['myWalletOfficial/info'], { queryParams: { gameId: gameId } });
  }

  downloadExcel() {
    const filterSettings = this.settingsRequestS.settings;
    const options = { params: filterSettings };
    const fileName = "notch all transactions.xlsx";
    this.mainS.exportToExcel("URL", options, fileName).subscribe();
  }
}


