<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              user: profileUserS.user$|async,
              id: (profileUserS.user$|async)?.id,
              typePageProfile: profileUserS.typePageProfile,
              arrLinkObj: (profileS.arrLinkObj$|async)!,
              currentSubLink: (profileS.currentSubLink$|async)!,
              currentPage_isEdit: !(profileS.currentSubLink$|async)?.upperCase,
            } as obj' class='wrapperProfile wrapperProfileUser'>

  <linkPage *ngIf='obj.desktop' [arrLink]='obj.arrLinkObj'
            [link]='obj.currentSubLink'
            (emit)='profileS.navigateProfile($event,obj.typePageProfile,obj.id)'></linkPage>

  <div class='wrapperProfile__content'>
    <router-outlet></router-outlet>
  </div>
</div>
