<div class="baba">
  <div class='coming-soon'>
    <div class='overview'>
      <div class='overview__content'>
        <img src='assets/businessman-working-on-computer.png' style="width: 100%;">
        <h3 class='overview__content__head'>Excuse our dust!</h3>
        <p class="overview__content__details">This section you're trying to view is currently under construction and can be managed using a desktop web browser. </p>
      </div>
    </div>
  </div>
</div>