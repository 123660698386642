import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';

import { MainService } from '@services/main.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { CustomValidators } from '@classes/CustomValidators';
import { HelperClass } from '@classes/Helper-Classes';
import { BtnComponent } from '@components/btn/btn.component';
import { ErrorComponent } from '@components/__info_text_message_error_warning/error/error.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ChxComponent } from '@components/chx/chx.component';
import { CodeConfirmComponent } from '@components/code-confirm/code-confirm.component';
import { BtnBackComponent } from '@components/btn-back/btn-back.component';
import { LocationComponent } from '@components/__blocks/location/location.component';
import { LocationService } from '@components/__blocks/location/location.service';
import { GoogleSignUpComponent } from '@components/google/components/google-sign-up/google-sign-up.component';
import { ClassUser, UserRegisteredStatus } from '@models/user';
import { PhoneComponent } from '@components/__drop_inputs_matSelect/phone/phone.component';
import { AuthService } from '@app/auth/auth.service';
import { TariffPlanComponent } from '@components/tariff-plan/tariff-plan.component';
import { StripeFormComponent } from '@components/stripe-form/stripe-form.component';
import { RoutesService } from '@services/routes.service';
import { InvalidControlsPipe } from '@pipes/invalid-controls.pipe';
import { SelectDateComponent } from '@components/date-time/select-date/select-date.component';
import { MatMenuModule } from '@angular/material/menu';
import { EmailPassStepComponent } from '@app/auth/commonComponents/email-pass-step/email-pass-step.component';
import { NameStepComponent } from '@app/auth/commonComponents/name-step/name-step.component';
import { LocationStepComponent } from '@app/auth/commonComponents/location-step/location-step.component';
import { PhoneStepComponent } from '@app/auth/commonComponents/phone-step/phone-step.component';
import { CodeConfirmStepComponent } from '@app/auth/commonComponents/code-confirm-step/code-confirm-step.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MeService } from '@services/me.service';

@UntilDestroy()
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatIconModule, FieldComponent, DropFormCtrlComponent, MatProgressSpinnerModule, RouterLink,
    InputCtrlComponent, BtnComponent, ErrorComponent, HeadTitleComponent, MatCheckboxModule, ChxComponent, GoogleSignUpComponent,
    CodeConfirmComponent, BtnBackComponent, LocationComponent, GoogleSignUpComponent, PhoneComponent, TariffPlanComponent,
    StripeFormComponent, InvalidControlsPipe, SelectDateComponent, MatMenuModule, EmailPassStepComponent, NameStepComponent, LocationStepComponent, PhoneStepComponent, CodeConfirmStepComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignupComponent extends HelperClass implements AfterViewInit {
  // form!: FormGroup<ISignupForm>;
  step = 1;
  // user:ClassUser={};

  constructor(
    public meS: MeService,
    public authS: AuthService,
    public mainS: MainService,
    public locationS: LocationService,
    public routesS: RoutesService,
    public cd: ChangeDetectorRef,
    private route: ActivatedRoute,
  ) {
    super(cd);
    this.initStep();
  }

  private initStep() {
    const userRegisteredStatus: string = this.route.snapshot.queryParams['userRegisteredStatus'];
    const stepList = [{
      status: UserRegisteredStatus.PENDING,
      action: () => {
        this.step = 2;//code email
        this.mainS.resendCode(this.meS.meEmail, 'email').subscribe(x => {
          console.log(x);
        });
        // this.user.email=this.meS.meEmail;
      }
    }, {
      status: UserRegisteredStatus.EMAIL_VERIFIED,
      action: () => {
        this.step = 3;//enter name
      }
    }, {
      status: UserRegisteredStatus.NAME_ENTERED,
      action: () => {
        this.step = 4;//enter adress
      }
    },
    {
      status: UserRegisteredStatus.RESIDENTIAL_ADDRESS_ENTERED,
      action: () => {
        this.step = 5;//enter phone
      }
    },
    {
      status: UserRegisteredStatus.MOBILE_ENTERED,
      action: () => {
        this.step = 6;//enter phone validation
        this.mainS.resendCode(this.meS.mePhone, 'phone').subscribe(x => {
          console.log(x);
        });
      }
    },
    ];

    const step = stepList.find(x => x.status === userRegisteredStatus)
    step?.action();
  }
  ngAfterViewInit() {
  }

  changeValNameStep(event: { firstName: string, secondName: string, preferredName?: string }): void {
    this.meS.setMeUser({ ...this.meS.me, ...event });
    this.nextStepEvent();
  }

  phoneStepEvent(phoneNumber: string) {
    this.meS.setMeUser({ ...this.meS.me, phone: phoneNumber });
    this.nextStepEvent();
  }

  emailPassStep(user: ClassUser | undefined) {
    this.meS.setMeUser({ ...this.meS.me, email: user?.email });
    if (user?.emailValidated && !user?.phoneValidated)
      this.step = 3;// UserRegisteredStatus.NAME_ENTERED;   
    else
      this.nextStepEvent();
  }
  // // === FORM =================
  // createForm(): void {
  //   this.form = this.formBuilder.group({
  //     // step == 1
  //     email: ['', [Validators.required, CustomValidators.patternEmail]],
  //     password: ['', [Validators.required, Validators.minLength(7), CustomValidators.patternPassword]],
  //     confirmPassword: ['', [Validators.required, Validators.minLength(7), CustomValidators.patternPassword]],

  //     // step == 2
  //     // validationCode: ['', [Validators.required, Validators.minLength(4)]],

  //     // step == 3
  //     firstName: ['', [Validators.required, Validators.minLength(3)]],
  //     secondName: ['', [Validators.required, Validators.minLength(3)]],
  //     preferredName: ['', [Validators.minLength(3)]],

  //     // step == 5
  //     phone: ['', [Validators.required]],
  //   }, { validators: CustomValidators.password });
  // }

  // get ctrl(): ISignupForm {
  //   return this.form?.controls;
  // }

  // subscribeToForm(): void {
  //   // this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((res) => {
  //   //   // console.log('subscribeToForm :', res)
  //   //   this.cd.detectChanges(); // !!! НЕ УДАЛЯТЬ. После signupWithGoogle не срабатывает пайп invalidControls
  //   // });
  // }


  backStepEvent() {
    this.step -= 1;
  }
  nextStepEvent() {
    this.step += 1;
  }

  emailStepEvent(email: string) {
    this.meS.setMeUser({ ...this.meS.me, email: email });
  }
  // emit(event: any, type: 'back' | 'next' | 'emailPass' | 'phoneStep' | 'codeConfirmStep'): void {


  //   // else if (type == 'emailPass') {
  //   //   const res: ClassUser & { withGoogle: boolean } = event;
  //   //   console.log('res :', res);
  //   //   if (res.firstName) debugger; //this.ctrl.firstName.patchValue(res.firstName);
  //   //   if (res.secondName) debugger; //this.ctrl.secondName.patchValue(res.secondName);
  //   //   if (res.preferredName) debugger; //this.ctrl.preferredName?.patchValue(res.preferredName);
  //   //   if (res.email) this.meS.setMeUser({ ...this.meS.me, ...{ email: res.email } }); //this.ctrl.email.patchValue(res.email);
  //   //   if (res?.emailValidated && !res?.phoneValidated) this.step = 3;
  //   //   else if (res?.emailValidated && res?.phoneValidated) this.step = 7;
  //   //   else this.step += 1;
  //   // }
  //   this.cd.detectChanges();
  // }

}
