import { Pipe, PipeTransform } from '@angular/core';

// andrei delete later
@Pipe({ name: 'formatOrganizationUserId', standalone: true })
export class UssfCertPipe implements PipeTransform {

  // return xxxx-xxxx-xxxx-xxxx // 
  // transform(value: string): string {
  //   return value ? value.replace(/(.{4})/g, '$1-').slice(0, -1) : '-';
  // }
  transform(value: string | undefined): string {
    if (value) {
      return value.replace(/(.{4})/g, '$1-').slice(0, -1);
    } else {
      return '-';
    }
  }
}
