<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
  mobile: (deviceS.isMobile$|async),
  arrCurrentLink: gameS.arrCurrentLinkDrop,
  objCurrentLink: (gameS.currentLink$|async)!,
  currentLink: (gameS.currentLink$|async)?.currentLink!,
  dataTable: (dataTable$|async)!,
  arrContent: (arrContent$|async),
  loadingTable: (meTableS.loadingTable$|async)             
 } as obj' class="myWalletNew" wrapPage cdkScrollable dataTable>



  <div class="switcher-btn-filter">
    <div class="header"> Recent Transactions </div>
    <btn *ngIf="obj.desktop" class="st-btn" [btnHeight]="'100%'" [btnWidth]='93' text='View All' bcg='white'
      (click)="viewAllClicked()"></btn>
  </div>


  <div class='o-content t-wrapperTable' *ngIf="obj.desktop">

    <div class="st-table-header">
      <div class="st-table-header__ceil" style="width:150px;">
        Type
      </div>
      <div class="st-table-header__ceil" style="width: 310px;">
        Description
      </div>
      <div class="st-table-header__ceil" style="width: 200px;">
        Transaction Date
      </div>
      <div class="st-table-header__ceil st-center" style="width: 115px;">
        Amount
      </div>
      <div class="st-table-header__ceil st-center" style="width: 115px;">
        Status
      </div>
    </div>
    <div class='t-table-v2'>
      <ng-container *ngFor='let item of obj.arrContent; let idx=index'>
        <div class='t-table-v2__item ' (click)="viewDetailsClicked(item.gameId,false)">

          <div class='t-table-v2__item__wrapCeil item-game-number' style="width: 150px;">
            <div>
              {{item.displayTransactionType}}
            </div>            
            <meSvg *ngIf="item.transferStatus=='Failed'" [svgName]="'alert-triangle'"></meSvg>
          </div>
          <div class='t-table-v2__item__wrapCeil competitionName-transfer-cents' style="width: 310px;">
            <div class="competition-name" style="font-weight: 600;">
              <div> {{item.competitionName}}</div>
            </div>
            <div>
              {{item.assignorName}}
            </div>
          </div>

          <div class='t-table-v2__item__wrapCeil' style="width: 200px;">
            {{ globalConst.formatToEST(item.transferCreatedAt) }} EST 
          </div>
          <div class='t-table-v2__item__wrapCeil t-amount st-center st-600' style="width: 120px;"
          [ngClass]="{ 'text-red': item.displayTransactionType === 'Paid' }">
       <span>
         {{ item.displayTransactionType === 'Paid' ? '-' : '+' }}
         {{ item.transferAmount | currency: 'USD' }}
       </span>
     </div>
     

          <div class='t-table-v2__item__wrapCeil st-center' style="width: 135px;">
            <status [status]="transactionType === 'OFFLINE' ? 'Offline' : item.displayOperationStatus"></status>
          </div>
          <div class=' t-table-v2__item__wrapCeil st-center' style="width: 90px;">
            <meSvg svgName='arrow-icon' (click)="viewDetailsClicked(item.gameId, true)" class="view-arrow-icon"></meSvg>
          </div>
        </div>
      </ng-container>
      <div class="view-all-table" *ngIf="obj?.arrContent?.length??0>0 && obj.mobile">
        <ng-container [ngTemplateOutlet]="viewAllButton"></ng-container>
      </div>
      <div class="t-table-v2-tableEmpty" *ngIf="(obj?.arrContent?.length??0)==0">
        Transactions will appear here
      </div>
    </div>

  </div>
  <!-- Mobile Layout -->
  <div class="mobile-transactions" *ngIf="obj.mobile">
    <ng-container *ngFor="let item of obj.arrContent">
      <div class="transaction-card" (click)="viewDetailsClicked(item.gameId, false)">
        <div class="transaction-left">
          <div class="transfer-type">{{item.displayTransactionType}}</div>
          <div class="transaction-date">     {{ globalConst.formatToEST(item.transferCreatedAt) }} EST</div>
        </div>
        <div class="transaction-right">
          <div class="amount" [ngClass]="{ 'text-red': item.displayTransactionType === 'Paid' }">
            <span>
              {{ item.displayTransactionType === 'Paid' ? '-' : '+' }}
              {{ item.transferAmount | currency: 'USD' }}
            </span></div>
          <div class="competition-name">{{ item.competitionName }}</div>
        </div>
      </div>
    </ng-container>

    <div class="view-all-table" *ngIf="obj?.arrContent?.length??0>0">
      <ng-container [ngTemplateOutlet]="viewAllButton"></ng-container>
    </div>

    <div class="t-table-v2-tableEmpty" *ngIf="(obj?.arrContent?.length??0)==0">
      Transactions will appear here
    </div>
  </div>


</div>

<ng-template #viewAllButton>
  <btn class="st-btn" [btnHeight]="'100%'" text='View All' bcg='white' (click)="viewAllClicked()">
  </btn>
</ng-template>