import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { arrNavigationCompetitions, TUrlNavigationCompetition } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { str_setup_competition } from '@app/app.module';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CompetitionService } from '@app/dir_group_assignor/competitions/competition.service';

@UntilDestroy()
@Component({
  selector: 'competitionsNavigation[page]',
  standalone: true,
  imports: [CommonModule, RouterLink, MatProgressSpinnerModule, RouterLinkActive],
  templateUrl: './competitions-navigation.component.html',
  styleUrls: ['./competitions-navigation.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush, // !!! не надо здесь. При обновлении страницы проблемы
})
export class CompetitionsNavigationComponent {
  @Input() page!: TUrlNavigationCompetition;
  readonly str_setup_competition = str_setup_competition;
  readonly arrNavigationCompetitions = arrNavigationCompetitions;

  // existIdCompetitionSub$ = new BehaviorSubject<boolean>(false)

  constructor(
    public competitionS: CompetitionService,
    // public cd: ChangeDetectorRef,
  ) {
  }

}