import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltersService } from '@components/filters/filters.service';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { ValueFromSelectedFilterPipe } from '@components/filters/pipes/value-from-selected-filter.pipe';
import { DeviceService } from '@services/device.service';
import { TypeScriptForHtmlForFiltersPipe } from '@components/filters/pipes/type-script-for-html-for-filters.pipe';
import { CanResetFiltersPipe } from '@components/filters/pipes/can-reset-filters.pipe';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { UnsavedChangesService } from '@services/unsaved-changes.service';
import { AssignComponent } from '@app/dir_group_assignor/assign/assign.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';


@Component({
  selector: 'app-filtersSelected',
  standalone: true,
  imports: [CommonModule, SvgComponent, ValueFromSelectedFilterPipe, TypeScriptForHtmlForFiltersPipe, CanResetFiltersPipe, FiltersComponent],
  templateUrl: './filters-selected.component.html',
  styleUrls: ['./filters-selected.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersSelectedComponent {
  @HostBinding('class.o-none') get class_o_none(): boolean {
    return !this.filtersS.getValuesSelectedFilters()?.length || !this.filtersS.showSelectedFilters || this.deviceS.isMobile$.getValue();
  }

  @Input() assignComponent!: AssignComponent;

  constructor(
    public filtersS: FiltersService,
    public deviceS: DeviceService,
    private unsavedChangesService: UnsavedChangesService,
  ) {
  }

  onResetFilters(): void {
    if (this.unsavedChangesService.unsavedChanges) {
      this.unsavedChangesService.openPopup_filters().then((proceed: boolean) => {
        if (proceed) {
          this.assignComponent.assignOfficials(false);
          this.filtersS.resetSelectedFilters();
        } else {
          this.unsavedChangesService.unsavedChanges = false;
          this.filtersS.resetSelectedFilters();
        }
      });
    } else {
      this.filtersS.resetSelectedFilters();
    }
  }
}
