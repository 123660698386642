<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              arrHeaderTable: (meTableS.arrHeaderTable$|async)!,
             } as obj' class='wrapperTableDoubleCeil' ceilTable [idxCeil]='idxCeil' [bordNone_forMobile]='bordNone_forMobile'>

  <!--  // !!! тех.долг. Возможно можно сделать получше этот компонент-->
  <ng-container *ngIf='{ceil: obj.arrHeaderTable[idxCeil]} as objHeader'>

    <div class='t-table__item__wrapCeil wrapperTableDoubleCeil__wrapCeil' [class.o-bordNone]='bordNone_forMobile'>
      <div *ngIf='obj.mobile' class='t-table__item__wrapCeil__titleFromHeader'>
        {{ objHeader.ceil?.text!|split:objHeader.ceil?.symbolSeparation!:0 }}
      </div>

      <div class='t-table__item__wrapCeil__ceilColumn' [matTooltip]='matTooltipText1||""'>
        <div *ngIf='text1' class='t-table__item__wrapCeil__ceilColumn__line1 o-noWrap'>{{ text1 }}</div>
        <div *ngIf='obj.mobile && commaForMobile && text1 && text2' class='comma_forTableCeil'>,</div>
        <div *ngIf='text2' class='t-table__item__wrapCeil__ceilColumn__line2 o-noWrap'>
          <span *ngIf='obj.mobile'>&nbsp;</span>
          {{ text2 }}
        </div>
      </div>
    </div>

    <div class='t-table__item__wrapCeil wrapperTableDoubleCeil__wrapCeil' [class.o-bordNone]='bordNone_forMobile'>
      <div *ngIf='obj.mobile' class='t-table__item__wrapCeil__titleFromHeader'>
        {{ objHeader.ceil?.text!|split:objHeader.ceil?.symbolSeparation!:1 }}
      </div>
      <div class='t-table__item__wrapCeil__ceilColumn' [matTooltip]='matTooltipText2||""'>
        <div *ngIf='text3' class='t-table__item__wrapCeil__ceilColumn__line1 o-noWrap'>{{ text3 }}</div>
        <div *ngIf='obj.mobile && commaForMobile && text3 && text4' class='comma_forTableCeil'>,</div>
        <div *ngIf='text4' class='t-table__item__wrapCeil__ceilColumn__line2 o-noWrap'>
          <span *ngIf='obj.mobile'>&nbsp;</span>
          {{ text4 }}
        </div>
      </div>
    </div>

  </ng-container>
</div>
