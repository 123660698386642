<div *ngIf='{ desktop: (deviceS.isDesktop$|async)!,
              mobile: (deviceS.isMobile$|async)!,
             } as obj' class='wrapperSetStatusForAssign'>

  <div class='titleSetStatusForAssign'>Set Status</div>
  <div class='subTitleSetStatusForAssign'>Are you sure you want to overwrite the assignment status for this official?</div>

  <field text='Select Status'>
    <app-dropdown [(ngModel)]='selectedStatusAssignDrop' [array]='arrDropStatusAssign' placeholder='Select Status'></app-dropdown>
  </field>

  <btnWrap [swapBtn]='obj.mobile'>
    <btn text='Cancel' bcg='white' (emit)='dialogRef.close()'></btn>
    <btn text='Save' bcg='blueDark' (emit)='close()'></btn>
  </btnWrap>

</div>