<div class='wrapperTooltipPhone'>

  <div class='wrapperTooltipPhone__item' (click)='callPhoneNumber(user?.phone)'>
    <img src='assets/phoneGray.svg' alt=''>
    <span>{{ (user?.phone|formatPhone) ? (user?.phone|formatPhone) : " - " }}</span>
  </div>

  <div class='wrapperTooltipPhone__item' (click)='callPhoneNumber(user?.emergencyPhoneNumber)'>
    <img src='assets/vector-red.svg' alt=''>
    <span>{{ (user?.emergencyPhoneNumber|formatPhone) ? (user?.emergencyPhoneNumber|formatPhone) : " - " }}</span>
  </div>

  <div class='wrapperTooltipPhone__emergencyName'>{{ user?.emergencyName }}</div>

</div>
