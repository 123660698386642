import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  set(key: string, data: any): void {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.error('Error saving to localStorage', e);
    }
  }

  get(key: string): any {
    try {
      return JSON.parse(localStorage.getItem(key) || '');
    } catch (e) {
      console.error('Error getting data from localStorage', e);
      return null;
    }
  }

  get jwt_token(): string | null {
    return localStorage.getItem('jwt_token');
  }

  set jwt_token(jwt_token: string | null) {
    if (jwt_token) {
      localStorage.setItem('jwt_token', JSON.stringify(jwt_token));
    } else {
      localStorage.removeItem('jwt_token');
    }
  }
}
