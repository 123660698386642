import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-me-profile-official-preferences',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './me-profile-official-preferences.component.html',
  styleUrls: ['./me-profile-official-preferences.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeProfileOfficialPreferencesComponent {

}
