import { ChangeDetectorRef, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { TAllInterfacesFilters } from '@components/filters/filters';

@Directive({ selector: '[forFilterItem]', standalone: true })
export class ForFilterItemDirective implements OnInit {
  currentFilter?: TAllInterfacesFilters;

  @Input() set filter(filter: TAllInterfacesFilters) {
    this.currentFilter = filter;
  }

  constructor(
    private r: Renderer2,
    private elRef: ElementRef,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    const cssClass_order = `o-order-${this.currentFilter?.index}`;
    this.r.addClass(this.elRef?.nativeElement, cssClass_order);
    if (this.currentFilter?.index === 0) {
      this.r.addClass(this.elRef?.nativeElement, 'firstFilterItem');
    }
    this.cd.markForCheck();
    this.cd.detectChanges();
  }

}
