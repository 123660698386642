import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainService } from '@services/main.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClassGroup, ClassGroupListItem } from '@models/IGroup';
import { BtnComponent } from '@components/btn/btn.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { OtherService } from '@services/other.service';
import { OfficialsService } from '@app/dir_group_assignor/officials/officials.service';
import { ClassUser } from '@models/user';
import { GetChxSvgNamePipe } from '@components/__svg_img/svg/pipes/get-chx-svg-name.pipe';

export interface IPopupGroupList {
  listIds?: Array<string>,
  officials?: Array<ClassUser>,
  officialIds?: Array<ClassUser>,
}

@Component({
  selector: 'app-popup-group-list',
  standalone: true,
  imports: [CommonModule, BtnComponent, BtnWrapComponent, FieldComponent, SvgComponent, HeadTitleComponent, GetChxSvgNamePipe],
  templateUrl: './popup-group-list.component.html',
  styleUrls: ['./popup-group-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupGroupListComponent {
  arrGroupList: Array<ClassGroupListItem> = [];

  // currentGroup?: ClassGroupListItem;

  constructor(
    public mainS: MainService,
    public otherS: OtherService,
    public officialsS: OfficialsService,
    public dialogRef: MatDialogRef<PopupGroupListComponent>,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public dataPopup?: IPopupGroupList,
  ) {
    this.arrGroupList = this.officialsS.groupList;
    // console.log('ADD OFFICIALS TO  GROUP LIST :', dataPopup);
  }

  submit(): void {
    const selectedGroupList: Array<ClassGroup> = this.arrGroupList?.filter((el) => el.active);
    // console.log('ADD OFFICIALS TO  GROUP LIST :', this.arrGroupList?.length, this.arrGroupList)
    // console.log('ADD OFFICIALS TO  GROUP LIST :', selectedGroupList?.length, selectedGroupList);
    const sendObj: IPopupGroupList = {
      listIds: this.arrGroupList.filter(el => el?.active).map((el: ClassGroupListItem) => el.id!),
      // officialIds: this.dataPopup?.officialIds,
      officials: this.dataPopup?.officials,
    };
    // console.log('ADD OFFICIALS TO  GROUP LIST :', sendObj);
    // return;
    this.close(true);
    this.officialsS.methodGroupList(sendObj, 'put');
  }

  selectGroupListItem(groupListItem: ClassGroupListItem): void {
    // this.arrGroupList.forEach(el => el.active = false);
    groupListItem.active = !groupListItem.active;
    // this.currentGroup = this.arrGroupList.find(el => el.active);
    this.cd.detectChanges();
  }

  close(type: boolean): void {
    // this.dialogRef.close(type ? this.currentGroup : false);
    this.dialogRef.close();
  }
}
