import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import { PopupService } from '@services/popup.service';


@Injectable({ providedIn: 'root' })
export class DeviceService {
  static readonly defaultZoomContent = "zoom-content";
  static readonly noneZoomContent = "";
  isZoomContent = new BehaviorSubject<string>(DeviceService.defaultZoomContent);
  isDesktop$ = new BehaviorSubject<boolean>(true);
  // isTablet$ = new BehaviorSubject<boolean>(true); // !!! Manasa 720-1304
  isMobile$ = new BehaviorSubject<boolean>(false);
  public isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);


  // isBigDesktop$ = new BehaviorSubject<boolean>(true); // >1339
  // isDesktop$ = new BehaviorSubject<boolean>(true);    // 1024-1339
  // isTablet$ = new BehaviorSubject<boolean>(true);     // 720-1024 || 580-1024
  // isMobile$ = new BehaviorSubject<boolean>(false);    // <720 || <580


  width$ = new BehaviorSubject<number | null>(null); // ширина экрана

  btnHeightForNothingList: number = this.isMobile ? 36 : 48;

  constructor(
    private popupS: PopupService,
  ) {
    this.listenResize().subscribe((isMobile: boolean) => {
    });
  }

  listenResize(): Observable<boolean> {
    return fromEvent(window, 'resize')
      .pipe(
        debounceTime(200),
        startWith({ target: { innerWidth: window.innerWidth } }),
        tap((e: any) => {
          // console.log('Window Width:', e.target.innerWidth);
          const isMobile = e.target.innerWidth <= 1024;
          // const isDesktop = e.target.innerWidth > 1304;
          // const isTablet = e.target.innerWidth >= 720 && e.target.innerWidth <= 1304;
          // const isMobile = e.target.innerWidth < 720;
          // console.log('Is Desktop:', !isMobile);
          // console.log('Is Tablet:', !isMobile);
          // console.log('Is Mobile:', isMobile);
          this.isDesktop$.next(!isMobile);
          this.isMobile$.next(isMobile);

          if (isMobile && e.target.innerWidth >= 580 && e.target.innerWidth <= 1024) {
            this.popupS.widthForPopup$.next({ minWidth: isMobile ? '50vw' : '400px', maxWidth: isMobile ? '90vw' : '400px' });
          } else {
            this.popupS.widthForPopup$.next({ minWidth: isMobile ? '90vw' : '400px', maxWidth: isMobile ? '90vw' : '400px' });
          }

          this.width$.next(e.target.innerWidth);

          this.btnHeightForNothingList = isMobile ? 36 : 48;
        }),
        map((e: any) => e.target.innerWidth <= 1024),
        // map((e: any) => e.target.innerWidth <= 1339),
      );
  }

  // эти геттеры только чтобы в TS получать, потому что в html не реагирует. В html надо isMobile$|async
  get isDesktop(): boolean {
    return this.isDesktop$.getValue();
  }

  // get isTablet(): boolean {
  //   return this.isTablet$.getValue();
  // }

  get isMobile(): boolean {
    return this.isMobile$.getValue();
  }

}
