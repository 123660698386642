<div class='wrapperPaymentsRewards'>
  <div class='contentPaymentsRewards'>
    <!--    <btn text='Coming Soon' bcg='greenLight2' (emit)='null' btnWidth='144' btnHeight='38' class='contentPaymentsRewards__comingSoon'></btn>-->
    <div class='contentPaymentsRewards__comingSoon'>Coming Soon</div>
    <div class='contentPaymentsRewards__title'>Introducing Notch Rewards</div>
    <div class='contentPaymentsRewards__subTitle'>
      Earn rewards for game assignments and payments that unlock benefits that give you more time, control and a sense of belonging.
    </div>

    <div class='contentPaymentsRewards__btns'>
      <btn text='Go back' bcg='white' (emit)='null' btnWidth='132' svgLeft='arrow_left&20'></btn>
      <btn text='Take me home' bcg='blueDark' (emit)='null' btnWidth='147'></btn>
    </div>
  </div>

  <meSvg svgName='rewards' class='rewardsLogo'></meSvg>
</div>