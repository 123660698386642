<div class='wrapperCompetitions'>
  <competitionsNavigation page='levels'></competitionsNavigation>
  <div class='wrapperCompetitionsBlock wrapperCompetitionsLevels' [formGroup]='form'>
    <div class='headerSetupCompetition'>
      <svgAndText text='Levels' leftSvgName='arrow_squareLeft&24' typeText='text1-blueDark'
                  (leftSvgEmit)='competitionS.goToBack()'></svgAndText>
      <div *ngIf='competitionS.competition?.id' class='headerSetupCompetition__btns'>
        <btn text='Save & Close' bcg='white' btnHeight='36' (click)='competitionsBtnsEmit(false)'
             [disabled]='!form.valid || reqPending || this.isReadOnly'></btn>
        <btn text='Save & Proceed to Next Step' bcg='blueDark' btnHeight='36' (click)='competitionsBtnsEmit(true)'
             [disabled]='!form.valid || reqPending || this.isReadOnly'></btn>
      </div>
    </div>


    <div formArrayName='arrControls' class='contentSetupCompetition'>
      <ng-container *ngFor='let item of arrControls?.controls; let idx = index; let first=first; let last=last'>
        <div *ngIf='item?.controls?.level?.value !== defaultNameMatOption' [formGroupName]='idx'
             class='wrapFieldsSetupCompetition {{last ? "wrapFieldsSetupCompetition--last":""}}'>
          <field text='Level'>
            <inputCtrl formControlName='level' placeholder='Level' [isFocus]='true'></inputCtrl>
          </field>
          <field text='Certification Required?'>
            <app-dropdown formControlName='certificationRequiredDrop' [array]='competitionS.arrYesNoDrop'
                          placeholder='Certification Required?'></app-dropdown>
          </field>
          <deleteItemForCompetitions page='levels' [id]='item?.value?.id!' [disabled]='arrControls?.controls?.length == 1 || isReadOnly'
                                     (responseEmit)='form.controls.arrControls?.removeAt(idx)'></deleteItemForCompetitions>
        </div>
      </ng-container>
      <btnAddAnother [disabled]='isReadOnly' page='competition' (emit)='addNew()'></btnAddAnother>
      <btnWrap *ngIf='!competitionS.competition?.id' type='col'>
        <btn text='Save & Proceed to Next Step' bcg='blueDark' (click)='competitionsBtnsEmit(true)'
             [disabled]='!form.valid || reqPending || isReadOnly'></btn>
        <btn text='Save & Close' bcg='white' (click)='competitionsBtnsEmit(false)' [disabled]='!form.valid || reqPending || isReadOnly'></btn>
      </btnWrap>
    </div>
  </div>
</div>