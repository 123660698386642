import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass } from '@classes/Helper-Classes';
import { DeviceService } from '@services/device.service';
import { GroupProfileService } from '@app/group-profile/group-profile.service';
import { MeService } from '@services/me.service';
import { MeTableService } from '@components/_table/me-table.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LinkPageComponent } from '@components/link-page/link-page.component';
import { BtnComponent } from '@components/btn/btn.component';
import { AdjustmentStatusesService } from '@app/group-profile/components/group-profile-adjustment-statuses/adjustment-statuses.service';
import {
  ClassAdjustmentStatus,
  keyOfClassAdjustmentStatus,
} from '@app/group-profile/components/group-profile-adjustment-statuses/adjustmentStatus';
import { BehaviorSubject, finalize, from, of, switchMap } from 'rxjs';
import { ClassCeilTableHeader } from '@components/_table/meTable';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { DataTableDirective } from '@components/_table/directives/data-table.directive';
import { FiltersService } from '@components/filters/filters.service';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { CeilTableDirective } from '@components/_table/directives/ceil-table.directive';
import {
  IsDefaultAdjustmentStatusPipe,
} from '@app/group-profile/components/group-profile-adjustment-statuses/pipes/is-default-adjustment-status.pipe';
import { ItemTableDirective } from '@components/_table/directives/item-table.directive';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { TableCeilComponent } from '@components/_table/table-ceil/table-ceil.component';
import { TableEmptyComponent } from '@components/_table/table-empty/table-empty.component';
import { TableHeaderComponent } from '@components/_table/table-header/table-header.component';
import { BtnAndLineComponent } from '@components/btn-and-line/btn-and-line.component';
import { ChxComponent } from '@components/chx/chx.component';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { catchError } from 'rxjs/operators';
import { UtilsService } from '@services/utils.service';
import { PopupService } from '@services/popup.service';
import { DisableControlDirectiveDirective } from '@directives/disable-control-directive.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormCustomService } from '@services/form-custom.service';
import { OtherService } from '@services/other.service';
import { const_NA } from '@models/other';
import { ExistChangesArrayPipe } from '@pipes/array/exist-changes-array.pipe';
import { AdminPermissionDirective } from '@directives/admin-permission.directive';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';
import { MainService } from '@services/main.service';
import { UnsavedChangesService } from '@services/unsaved-changes.service';

interface IFormAdjustmentStatus {
  arrControls?: FormArray<FormGroup<IFormItemAdjustmentStatus>>;
}

interface IFormItemAdjustmentStatus {
  id?: FormControl<string>;
  name?: FormControl<string>;
  officialPay?: FormControl<number>;
  assignorFee?: FormControl<number>;
  active?: FormControl<boolean>;
  inUse?: FormControl<boolean>;
}

@UntilDestroy()
@Component({
  selector: 'app-group-profile-adjustment-statuses',
  standalone: true,
  imports: [CommonModule, LinkPageComponent, BtnComponent, WrapPageDirective, DataTableDirective, SvgComponent, CeilTableDirective, IsDefaultAdjustmentStatusPipe, ItemTableDirective, SvgAndTextComponent, TableCeilComponent, TableEmptyComponent, TableHeaderComponent, BtnAndLineComponent, ChxComponent, FormsModule, InputCtrlComponent, ReactiveFormsModule, DisableControlDirectiveDirective, MatTooltipModule, ExistChangesArrayPipe, AdminPermissionDirective, GetTableEmptyPipe],
  templateUrl: './group-profile-adjustment-statuses.component.html',
  styleUrls: ['./group-profile-adjustment-statuses.component.scss'],
  providers: [MeTableService, SettingsRequestService, FiltersService, UnsavedChangesService], // for-table=== // for-settings===
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupProfileAdjustmentStatusesComponent extends HelperClass {
  form!: FormGroup<IFormAdjustmentStatus>;
  matTooltip_for_btn_if_inUse = 'This status is used in the “Needs Approval” reports.';
  readonly defaultAdjustmentStatus: { [key: string]: any } = {
    name: ['', Validators.required],
    officialPay: [0, Validators.required],
    assignorFee: [0, Validators.required],
    active: [true],
  };
  readonly keyOfClassAdjustmentStatus = keyOfClassAdjustmentStatus;

  arrContent$ = new BehaviorSubject<Array<ClassAdjustmentStatus>>([]); // for-table===

  readonly widthPage = 1032;

  constructor(
    public mainS: MainService,
    public adjustmentStatusesS: AdjustmentStatusesService,
    public deviceS: DeviceService,
    public groupProfileS: GroupProfileService,
    public meS: MeService,
    private meTableS: MeTableService<ClassAdjustmentStatus>, // for-table===
    public formBuilder: UntypedFormBuilder,
    private formS: FormCustomService,
    private popupS: PopupService,
    private otherS: OtherService,
    public cd: ChangeDetectorRef,
    private unsavedChangesS: UnsavedChangesService,
  ) {
    super(cd);
    this.createForm();
    this.setAllData();
  }

  cancelCurrentChanges(): void {
    this.otherS.reload();
  }

  addAndUpdateAdjustmentStatuses(): void {
    if (this.startRequest()) return;
    const arrSendObj: Array<ClassAdjustmentStatus> = this.arrControls.getRawValue()?.map(el => {
      return { ...el, assignorFee: +el.assignorFee! || 0, officialPay: +el.officialPay! || 0 };
    });

    this.adjustmentStatusesS.methodAdjustmentStatuses('put', false, arrSendObj)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (res: Array<ClassAdjustmentStatus>) => this.otherS.reload(),
        error: (error: any) => this.endRequest(),
        complete: () => {
          this.adjustmentStatusesS.methodAdjustmentStatuses('get', false);
        }
      });
  }

  setAllData(): void {
    this.setDataForTable();
    this.groupProfileS.setCurrentLink_groupProfile('adjustmentStatuses');
  }

  deleteItem(itemForm: FormGroup<IFormItemAdjustmentStatus>, idx: number): void {
    if (this.startRequest()) return;

    const observable = from(this.popupS.openPopupConfirm({
      textTitle: `Remove Adjustment Status`,
      text: `Are you sure you want to delete this Adjustment Status: "${itemForm.value?.name}"?`,
      textBtnApply: 'Remove',
    }));

    observable.pipe(
      switchMap((res) => {
        if (res) return this.adjustmentStatusesS.deleteAdjustmentStatus(itemForm?.value.id!);
        this.endRequest();
        return of(null);
      }),
      finalize(() => this.endRequest()),
      catchError((err) => {
        this.endRequest();
        return of(err);
      }),
      untilDestroyed(this),
    )
      .subscribe((res) => {
        if (UtilsService.isNull(res)) return;
        this.arrControls.removeAt(idx);
        this.cd.detectChanges();
      });
  }

  // === FORM ==============
  private createForm(): void {
    this.form = this.formBuilder.group({ arrControls: this.formBuilder.array([]) });
    this.adjustmentStatusesS.arrContent$.getValue()?.forEach((el) => this.addNewCtrl(el));
  }

  get arrControls(): FormArray<FormGroup<IFormItemAdjustmentStatus>> {
    return this.form?.controls?.arrControls!;
  }

  addNewCtrl(classAdjustmentStatus?: ClassAdjustmentStatus): void {
    const elemForForm: ClassAdjustmentStatus = classAdjustmentStatus || this.defaultAdjustmentStatus;
    const formGroup = this.formBuilder.group(elemForForm);
    if (elemForForm.name === const_NA) formGroup.disable();
    if (elemForForm.inUse) formGroup.controls.active.disable();
    if (elemForForm.name) formGroup.controls.name.addValidators([Validators.required, Validators.minLength(3)]);

    let previousValue = formGroup.getRawValue();

    formGroup.valueChanges.subscribe((currentValue) => {
      if (JSON.stringify(previousValue) !== JSON.stringify(currentValue)) {
        previousValue = currentValue;
        this.unsavedChangesS.unsavedChanges = true;
      }
    });

    this.arrControls.push(formGroup);
  }

  // === TABLE ======================================================
  setDataForTable(): void {
    const currentValue_arrContent = this.adjustmentStatusesS.arrContent$.getValue();
    this.adjustmentStatusesS.arrContent$ = this.meTableS.arrContent$ = this.arrContent$;
    this.arrContent$.next(currentValue_arrContent);
    const arrayCeilHeader: Array<ClassCeilTableHeader> = [
      new ClassCeilTableHeader({ text: 'Name' }),
      new ClassCeilTableHeader({ text: 'Official Pay', isCentered: true }),
      new ClassCeilTableHeader({ text: 'Assignor Fee', isCentered: true }),
      new ClassCeilTableHeader({ text: 'Status', isCentered: true }),
      new ClassCeilTableHeader({ text: '', isCentered: true }),
    ];
    const arrWidthCeilTable: Array<number> = [555, 132, 132, 121, 92];
    this.meTableS.setDataForTable(arrWidthCeilTable, arrayCeilHeader, this.widthPage, false);
  }

}
