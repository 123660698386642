import { Pipe, PipeTransform } from '@angular/core';
import { ILocation } from '@models/location';
import { OtherService } from '@services/other.service';

@Pipe({ name: 'getLocationString', standalone: true })
export class GetLocationStringPipe implements PipeTransform {

  constructor(
    private otherS: OtherService,
  ) {
  }

  transform(location?: ILocation, fields: Array<keyof ILocation> = ['street', 'city', 'state', 'zipcode']): string {
    return this.otherS.getLocationString(location, fields);
  }

}
