import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperClass, TForPagination } from '@classes/Helper-Classes';
import { SvgComponent } from '../../shared/components/__svg_img/svg/svg.component';
import { SpinnerComponent } from '../../shared/components/spinner/spinner.component';
import { BehaviorSubject } from 'rxjs';
import { MatMenuModule } from '@angular/material/menu';
import { PaginationComponent } from '../../shared/components/pagination/pagination.component';
import { MainService } from '@services/main.service';
import { ClassSettingsRequest, IResponse } from '@models/response-and-request';
import { ISortBy } from '@components/sortBy/sortBy';
import { NothingListComponent } from '../../shared/components/nothing-list/nothing-list.component';
import { Assignors } from '@models/assignors';
import { PhotoComponent } from '../../shared/components/__svg_img/photo/photo.component';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import {
  FiltersSelectedIconComponent,
} from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { FiltersService } from '@components/filters/filters.service';
import { ClassFilterDrop, ClassFilterInput, IForClassForFilters, TAllInterfacesFilters } from '@components/filters/filters';
import { MeService } from '@services/me.service';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { DeviceService } from '@services/device.service';
import { ClassCeilTableHeader } from '@components/_table/meTable';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MeTableService } from '@components/_table/me-table.service';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { BtnComponent } from '@components/btn/btn.component';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';

@UntilDestroy()
@Component({
  selector: 'app-page-my-assignors',
  standalone: true,
  templateUrl: './page-my-assignors.component.html',
  styleUrls: ['./page-my-assignors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FiltersSelectedIconComponent, FiltersComponent, SvgComponent, FiltersSelectedComponent, PaginationComponent, SpinnerComponent, NothingListComponent, PhotoComponent, MatMenuModule, WrapPageDirective, BtnComponent, GetTableEmptyPipe],
  providers: [FiltersService, MeTableService, SettingsRequestService], // for-filters=== for-table=== for-settings===
})
export class MyAssignorComponent extends HelperClass implements IForClassForFilters, OnInit {
  data?: IResponse<Assignors>;
  assignorsListSub$ = new BehaviorSubject<Array<Assignors>>([]);

  tableHeader: Array<ClassCeilTableHeader> = [
    // { text: 'Assignor Name', chx: true, isActive: true },
    // { text: 'Group Name', chx: false, isActive: true },
    // { text: 'Phone', chx: false, isActive: true },
    // { text: 'City / State', chx: false, isActive: true },
    { text: 'Assignor Name', isChx: true },
    { text: 'Group Name' },
    { text: 'Phone' },
    { text: 'City / State' },
  ];

  // readonly class_ngContent_btn_for_nothingList = class_ngContent_btn_for_nothingList;

  constructor(
    public router: Router,
    private mainS: MainService,
    private apiCompetitionS: ApiCompetitionService,
    private meS: MeService,
    public deviceS: DeviceService,
    public filtersS: FiltersService, // for-filters===
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
  }

  ngOnInit() {
    this.setFilters(); // for-filters===
  }

  getAssignorsList(): void {
    this.startLoading(); // !!! if (this.startLoading()) return; // !!! не надо здесь. Потому что фильтры можно быстро выбирать => поступит следующее значение не успеет загрузиться ответ от сервера
    setTimeout(
      () => {
        this.assignorsListSub$.next([
          {
            name: 'Monika Svilpe',
            email: 'monika.svilpe@example.com',
            groupName: 'MN Adult Assigning',
            phone: '(480) 555-0103',
            city: 'Minneapolis, MN',
          },
        ]);
        this.endLoading();
      }, 1000);
  }

  // === FILTERS ==========================================
  settings: ClassSettingsRequest = new ClassSettingsRequest();

  setFilters(): void {
    const search = new ClassFilterInput({ typeFilter: 'search' });
    const groupId = new ClassFilterDrop({ typeFilter: 'groupId', arrayForDropdown: this.meS.me?.officialGroups || [] });
    const competition = new ClassFilterDrop({ typeFilter: 'competitions', disabled: true });
    const arrFilters: Array<TAllInterfacesFilters> = [search, groupId, competition];
    this.filtersS.setFilters(arrFilters, false, false);
  }

  emitSettingRequest(settings: ClassSettingsRequest): void {
    const isNewSelectedGroup = this.settings.groupId !== settings.groupId; // выбрана новая группа в дропдауне
    if (isNewSelectedGroup && settings.groupId) this.getArrCompetition(settings.groupId);
    if (!settings.groupId) { // если удалил группу из фильтра
      const competitions_filterDrop = new ClassFilterDrop({ // надо обнулить фильтр компетишнов и disabled
        typeFilter: 'competitions',
        arrayForDropdown: [],
        valueDrop: undefined,
        disabled: true,
      });
      this.filtersS.updateAllFilters([competitions_filterDrop]);
    }
    this.settings = { ...this.settings, ...settings };
    this.getAssignorsList();
  }

  getArrCompetition(groupId: string): void {
    this.apiCompetitionS.getArrCompetition(groupId ? { params: { groupId } } : undefined).toPromise()
      .then((res) => {
        const arrCompetitions = res?.content || [];
        const competitions_filterDrop = new ClassFilterDrop({
          typeFilter: 'competitions',
          arrayForDropdown: arrCompetitions || [],
          valueDrop: undefined,
          disabled: false,
        });
        this.filtersS.updateAllFilters([competitions_filterDrop]);
      })
      .catch(err => {
      });
  }

  // === SELECT ITEMS ================================
  selectAll(): void {
    this.assignorsListSub$.next(this.selectAllFromHelperClass(this.assignorsListSub$.getValue()));
  }

  selectItem(item: Assignors): void {
    // event.stopPropagation();
    this.selectItemFromHelperClass(item, this.assignorsListSub$.getValue());
  }

  // === PAGINATION ==============================
  paginationMethod(type: TForPagination, size?: number, page?: number, sortBy?: ISortBy): void {
    this.paginationMethodHelperClass(type, this.settings, size, page, sortBy);
    this.getAssignorsList();
  }

}
