import { Pipe, PipeTransform } from '@angular/core';
import { FiltersService } from '@components/filters/filters.service';
import { TAllInterfacesFilters } from '@components/filters/filters';

@Pipe({ name: 'valuesSelectedFilters', standalone: true })
export class ValuesSelectedFiltersPipe implements PipeTransform {

  constructor(
    private filtersS: FiltersService,
  ) {
  }

  transform(selectedFilters: Array<TAllInterfacesFilters> | null): Array<string> {
    return this.filtersS.getValuesSelectedFilters();
  }

}
