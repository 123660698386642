<ng-container *ngIf='{existErrMessage: !!(errMessages|getArrStringFromObject)?.length} as obj'>

  <div class='stripeBlock'>
    <div class='wrapFields'>
      <div *ngFor='let field of fundS.fields' class='wrapFields__block fieldStripe fieldStripe--{{field}}'>
        <ng-container [ngSwitch]='field'>
          <div *ngSwitchCase='"cardNumber"' class='wrapFields__block__title'>Card Number</div>
          <div *ngSwitchCase='"cardExpiry"' class='wrapFields__block__title'>Expiry Date</div>
          <div *ngSwitchCase='"cardCvc"' class='wrapFields__block__title'>CVC</div>
          <div *ngSwitchCase='"cardName"' class='wrapFields__block__title'>Name on Card</div>
          <div *ngSwitchCase='"zip"' class='wrapFields__block__title'>Zip Code</div>
        </ng-container>
        <div [id]='field' *ngIf='field != "cardName" && field != "zip"' class='o-input'></div>
        <input #inputRef [id]='field' *ngIf='field == "cardName"' data-tid='elements_examples.form.name_label'
               class='o-input' type='text' required='true' autocomplete='name'
               (input)='changeInput($event.target)'
               onkeyup='if(value.charAt(0) === " "){value=value.replace(/\s+/gi,"")}'
               placeholder='Name on Card' (focusin)='nameCardActive=true' (focusout)='nameCardActive=false'
               [class]='"o-input--"+({value:inputRef.value,error:!validForm.cardName && !!errMessages.cardName,active:nameCardActive}|getStateInput)'>
        <input #inputZipRef [id]='field' *ngIf='field == "zip"' data-tid='elements_examples.form.name_label'
               class='o-input' type='number' required='true' autocomplete='name'
               (input)='changeInputZip($event.target)'
               onkeyup='if(value.charAt(0) === " "){value=value.replace(/\s+/gi,"")}'
               placeholder='Zip Code' (focusin)='zipActive=true' (focusout)='zipActive=false'
               [class]='"o-input--"+({value:inputZipRef.value,error:!validForm.zip && !!errMessages.zip,active:zipActive}|getStateInput)'>
        <div *ngIf='reqPending' class='forDisabledInput'></div>
      </div>
    </div>

    <div *ngIf='obj.existErrMessage' class='o-flex-column o-mg-b-20' role='alert'>
      <error *ngFor='let message of errMessages|getArrStringFromObject' [text]='message'></error>
    </div>

    <!--    FOR ADMIN -->
    <div *ngIf='isCreatePayMethod' class='btnsBalancePay'>
      <btn *ngIf='textCancel' [text]='textCancel' bcg='white' (emit)='cancel.emit()' btnWidth='(deviceS.isDesktop$|async) ? 79:"100%"' btnHeight='40'></btn>
      <btn *ngIf='textApply' [text]='textApply' bcg='blueDark' (emit)='createPaymentMethod()' [spinner]='reqPending'
      btnWidth='(deviceS.isDesktop$|async) ? 65:"100%"' btnHeight='40' [disabled]='obj.existErrMessage || !(validForm|checkValidAllFieldsFromObject)'></btn>
    </div>

    <!--    FOR OFFICIAL -->
    <ng-container *ngIf='isCreateToken'>
      <btn *ngIf='textCancel' [text]='textCancel' bcg='white' (emit)='cancel.emit()'></btn>
      <btn [text]='textApply!' bcg='green' (emit)='createTokenMethod()' [spinner]='reqPending'
           [disabled]='obj.existErrMessage || !(validForm|checkValidAllFieldsFromObject)'></btn>
    </ng-container>

  </div>

</ng-container>

