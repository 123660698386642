import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDataPopup } from '@services/popup.service';
import { ClassGameOfficial } from '@app/dir_group_assignor/games/game';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';
import { MainService } from '@services/main.service';
import { GetChxSvgNamePipe } from '@components/__svg_img/svg/pipes/get-chx-svg-name.pipe';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationService } from '@services/notification.service';
import { DeviceService } from '@services/device.service';

@UntilDestroy()
@Component({
  selector: 'app-popup-for-official-for-request-to-role',
  standalone: true,
  imports: [CommonModule, HeadTitleComponent, BtnWrapComponent, BtnComponent, GetChxSvgNamePipe, SvgComponent],
  templateUrl: './popup-for-official-for-request-to-role.component.html',
  styleUrls: ['./popup-for-official-for-request-to-role.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupForOfficialForRequestToRoleComponent {
  arrGo: Array<ClassGameOfficial> = [];
  gameOfficialId?: string;
  typeResponse: 'error' | 'success' | null = null;
  reqPending = false;

  constructor(
    public deviceService: DeviceService,
    private mainService: MainService,
    private cd: ChangeDetectorRef,
    public dialogRef: MatDialogRef<PopupForOfficialForRequestToRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public dataPopup: IDataPopup,
  ) {
    if (dataPopup.arrGo) this.arrGo = dataPopup.arrGo;
  }

  requestAssignOfficial(): void {
    if (!this.gameOfficialId || this.reqPending) return;
    this.reqPending = true;
    this.typeResponse = null;

    this.mainService.requestAssignOfficial({ gameOfficialId: this.gameOfficialId }, this.deviceService.isMobile)
      .pipe(untilDestroyed(this)).toPromise()
      .then((res) => {
        this.typeResponse = 'success';
      })
      .catch((err) => {
        this.typeResponse = 'error';
      })
      .finally(() => {
        this.reqPending = false;
        this.cd.detectChanges();
      });
  }

  selectItem(go: ClassGameOfficial): void {
    this.arrGo.forEach((el) => {
      if (go.id == el.id) go.isSelect = !go.isSelect;
      else el.isSelect = false;
    });
    this.gameOfficialId = this.arrGo.find((el) => el.isSelect)?.id;
    this.cd.detectChanges();
  }

  close(type: boolean): void {
    this.dialogRef.close(type);
  }
}
