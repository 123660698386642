import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MainService } from '@services/main.service';
import { IDataPopup } from '@services/popup.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaxCharacterComponent } from '@components/__info_text_message_error_warning/max-character/max-character.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';
import { TClassForText, TColor } from '@models/ICssStyles';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { ErrorComponent } from '@components/__info_text_message_error_warning/error/error.component';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, MaxCharacterComponent, BtnWrapComponent, BtnComponent, HeadTitleComponent, InputCtrlComponent, FieldComponent, ErrorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupComponent {
  textTitle = '';
  typeTextTitle: TClassForText = 'text4-blueDark';
  text = '';

  textBtnApply = '';
  textBtnCancel = '';
  colorBtnApply: TColor = 'blueDark';
  colorBtnCancel: TColor = 'white';

  swapBtn = false; // !!! если нужно поменять кнопки местами, то нужно передать true

  constructor(
    public mainS: MainService,
    public dialogRef: MatDialogRef<PopupComponent>,
    @Inject(MAT_DIALOG_DATA) public dataPopup?: IDataPopup,
  ) {
    if (dataPopup?.textTitle) this.textTitle = dataPopup.textTitle;
    if (dataPopup?.typeTextTitle) this.typeTextTitle = dataPopup.typeTextTitle;
    if (dataPopup?.text) this.text = dataPopup.text;
    if (dataPopup?.textBtnApply) this.textBtnApply = dataPopup.textBtnApply;
    if (dataPopup?.textBtnCancel) this.textBtnCancel = dataPopup.textBtnCancel;
    if (dataPopup?.colorBtnApply) this.colorBtnApply = dataPopup.colorBtnApply;
    if (dataPopup?.colorBtnCancel) this.colorBtnCancel = dataPopup.colorBtnCancel;
    if (dataPopup?.swapBtn) this.swapBtn = dataPopup.swapBtn;
  }

  close(type: boolean): void {
    this.dialogRef.close(type);
  }
}
