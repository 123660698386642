import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass } from '@classes/Helper-Classes';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { CompetitionService } from '@app/dir_group_assignor/competitions/competition.service';
import { DeviceService } from '@services/device.service';
import { MainService } from '@services/main.service';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { MeService } from '@services/me.service';
import { Router } from '@angular/router';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { LineComponent } from '@components/line/line.component';
import { SelectDateComponent } from '@components/date-time/select-date/select-date.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';
import { SelectTimeComponent } from '@components/date-time/select-time/select-time.component';
import { CustomDatesService } from '@classes/CustomDates';
import {
  arrGenderDrop,
  ClassCompetition,
  ClassCompetitionAgeGroup,
  ClassCompetitionLevel,
  ClassCompetitionLocation,
  ClassCompetitionTeam,
  TGameTypeDrop,
  TGenderDrop,
} from '@app/dir_group_assignor/competitions/ClassCompetition';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { ClassGame, ClassGameOfficial, ISendObjForGetGameOfficialsForCreateGame } from '@app/dir_group_assignor/games/game';
import { DateTimeComponent } from '@components/date-time/date-time/date-time.component';
import { AddressCourtNameComponent } from '@components/__drop_inputs_matSelect/address-court-name/address-court-name.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { MatMenuModule } from '@angular/material/menu';
import { Geo } from '@classes/geo';
import { GetFirstLetterEachWordPipe } from '@pipes/string/get-first-letter-each-word.pipe';
import { DateFormattedComponent } from '@components/__date/date-formatted/date-formatted.component';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { ClassDrop, ClassDropForNameDefault } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { urlGameInfo } from '@app/app.module';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

interface IFormCreateGame {
  competition: FormControl<ClassCompetition>;
  gameNumber: FormControl<string>;
  location: FormControl<ClassCompetitionLocation>;
  // courtNames: FormControl<Array<string>>; // для того чтобы отправлять на сервер старый маасив courtNames
  courtNamesDrop: FormControl<Array<ClassDrop>>;  // andrei переделать. Сейчас достаю из game.location.courtNames
  // currentCourtName: FormControl<string>;
  currentCourtNameDrop: FormControl<ClassDrop | null>; // andrei переделать. Сейчас достаю из game.location.currentCourtName
  date: FormControl<string>;
  ageGroup: FormControl<ClassCompetitionAgeGroup>;
  genderDrop: FormControl<TGenderDrop>;
  levels: FormControl<ClassCompetitionLevel>;
  homeTeam: FormControl<ClassCompetitionTeam>;
  awayTeam: FormControl<ClassCompetitionTeam>;
  gameDescription: FormControl<string>;

  gameTypeDrop: FormControl<TGameTypeDrop>;
}

@UntilDestroy()
@Component({
  selector: 'app-create-game',
  standalone: true,
  imports: [CommonModule, HeadTitleComponent, FieldComponent, DropFormCtrlComponent, ReactiveFormsModule, InputCtrlComponent, LineComponent, SelectDateComponent, BtnWrapComponent, BtnComponent, SelectTimeComponent, SvgAndTextComponent, DateTimeComponent, AddressCourtNameComponent, SvgComponent, MatMenuModule, GetFirstLetterEachWordPipe, DateFormattedComponent, DropdownComponent],
  templateUrl: './create-game.component.html',
  styleUrls: ['./create-game.component.scss'],
})
export class CreateGameComponent extends HelperClass implements OnInit {
  @ViewChild('dateTimeRef') dateTimeRef?: DateTimeComponent;
  form!: FormGroup<IFormCreateGame>;
  typePage: 'createGame' | 'gamePreview' = 'createGame';
  game: ClassGame = {};

  arrGO: Array<ClassGameOfficial> = [];

  readonly urlGameInfo = urlGameInfo;

  readonly arrGenderDrop = arrGenderDrop;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public competitionS: CompetitionService,
    private mainS: MainService,
    public geo: Geo,
    public gameS: GameService,
    public meS: MeService,
    public router: Router,
    public customDatesS: CustomDatesService,
    public cd: ChangeDetectorRef,
    public deviceS: DeviceService,
  ) {
    super(cd);
    this.createForm();
    this.getArrCompetition();
  }

  ngOnInit() {
  }

  getArrCompetition(): void {
    this.gameS.getArrCompetition().pipe(untilDestroyed(this)).subscribe((res) => {
    });
  }

  // === FORM =====================
  createForm(): void {
    this.form = this.formBuilder.group({
      competition: ['', [Validators.required]],
      gameNumber: [''], // [Validators.required]
      location: [{ value: '', disabled: !this.ctrl?.competition?.value?.id }, [Validators.required]],
      // courtNames: [''],
      courtNamesDrop: [''],
      // currentCourtName: [{ value: '', disabled: !this.ctrl?.location?.value?.id }, [Validators.required]],
      currentCourtNameDrop: [{ value: '', disabled: !this.ctrl?.location?.value?.id }, [Validators.required]],
      date: [''], // , [Validators.required]
      ageGroup: [{ value: '', disabled: !this.ctrl?.competition?.value?.id }, [Validators.required]],
      genderDrop: ['', [Validators.required]],
      levels: [{ value: '', disabled: !this.ctrl?.competition?.value?.id }, [Validators.required]],
      homeTeam: [{ value: '', disabled: !this.ctrl?.competition?.value?.id }, [Validators.required]],
      awayTeam: [{ value: '', disabled: !this.ctrl?.competition?.value?.id }, [Validators.required]],
      gameDescription: [''],
      // courtName: [''],
      gameTypeDrop: [''],
    });
  }

  enableCtrls(): void {
    this.ctrl.location.enable();
    this.ctrl.ageGroup.enable();
    this.ctrl.levels.enable();
    this.ctrl.homeTeam.enable();
    this.ctrl.awayTeam.enable();
  }

  get ctrl(): IFormCreateGame {
    return this.form?.controls;
  }

  changeCompetition(competition: ClassCompetition): void {
    this.ctrl.courtNamesDrop?.patchValue([]);
    this.ctrl.currentCourtNameDrop?.patchValue(null);
    this.ctrl.currentCourtNameDrop?.enable();
    // this.gameS.arrCourtName$.next([]);

    this.gameS.changeValCompetition(competition).toPromise().then((res) => {
      const defaultLevel = res?.levels?.find(level => level.level == 'ALL');
      this.ctrl.levels?.patchValue(new ClassDropForNameDefault(defaultLevel?.id));
      this.cd.detectChanges();
    });
    this.ctrlReset([this.ctrl.gameTypeDrop, this.ctrl.location, this.ctrl.ageGroup, this.ctrl.levels, this.ctrl.homeTeam, this.ctrl.awayTeam], true);
    this.enableCtrls();
  }

  // === OTHER =====================
  goToPreview(): void {
    this.form.markAllAsTouched();
    this.game = this.form.getRawValue();
    this.game.date = this.dateTimeRef?.dateForSendToServer!;
    this.gameS.checkPreviewGame(this.game);
    this.typePage = 'gamePreview';
    this.geo.checkLocForGoggleMapFromILocation(false, this.game?.location?.address);
    this.getGameOfficialsForCreateGame();
    this.cd.detectChanges();
  }

  resetForm(): void {
    this.form.reset();
    this.cd.detectChanges();
  }

  goToBack(): void {
    window.history.back();
  }

  // === LOCATION =============
  changeLocation(competitionLocation: ClassCompetitionLocation): void {
    this.ctrl.courtNamesDrop?.patchValue([]);
    this.ctrl.currentCourtNameDrop?.patchValue(null);
    this.ctrl.currentCourtNameDrop?.enable();
    // this.gameS.arrCourtName$.next([]);
    // this.gameS.arrCourtName$.next(competitionLocation?.courtNames || []);
    this.ctrl.courtNamesDrop?.patchValue(competitionLocation?.courtNamesDrop || []);
    this.cd.detectChanges();
  }

  // === PREVIEW GAME =============================
  submit(): void {
    this.game = {
      ...this.game,
      competition: { id: this.game?.competition?.id },
      location: { id: this.game?.location?.id },
      ageGroup: { id: this.game?.ageGroup?.id },
      gender: this.game?.gender,
      levels: { id: this.game?.levels?.id },
      homeTeam: { id: this.game?.homeTeam?.id },
      awayTeam: { id: this.game?.awayTeam?.id },
    };

    // this.game.location = {
    //   ...this.game.location,
    //   // currentCourtName: this.ctrl.currentCourtName?.value,
    //   currentCourtName: this.ctrl.currentCourtNameDrop?.value?.upperCase,
    //   courtNames: this.ctrl?.location?.value?.courtNames,
    // };
    // if (this.sendObj.courtNames) delete this.sendObj.courtNames;
    if (this.game.location) {  // andrei переделать. Сейчас достаю из game.location.courtNames
      // sendObj.location.courtNamesDrop = this.ctrl.courtNamesDrop?.value!; // в этом компоненте этот список НЕ изменяем
      this.game.location.currentCourtNameDrop = this.ctrl.currentCourtNameDrop?.value!;
    }

    if (this.startRequest()) return;
    this.mainS.methodGame(this.game, 'post').toPromise()
      .then((res?: ClassGame) => {
        res?.id ? this.router.navigate([urlGameInfo, res?.id]) : this.router.navigate(['/games']);
      })
      .catch(() => {
      })
      .finally(() => this.endRequest());
  }

  getGameOfficialsForCreateGame(): void {
    const sendObj: ISendObjForGetGameOfficialsForCreateGame = {
      competition: { id: this.ctrl.competition?.value?.id! },
      ageGroup: { id: this.ctrl.ageGroup?.value?.id! },
      levels: { id: this.ctrl.levels?.value?.id! },
      gameType: this.ctrl.gameTypeDrop?.value?.upperCase,
    };
    this.mainS.getGameOfficialsForCreateGame(sendObj).toPromise()
      .then((res?: Array<ClassGameOfficial>) => {
        this.arrGO = res || [];
        this.cd.detectChanges();
      })
      .catch((err) => {
      });
  }

}

// matOptionEmit(): void {
//   if (this.ctrl.competition?.value?.id) {
//   this.router.navigate([`competitions/about/${this.ctrl.competition?.value?.id}`]);
// }
// }
// changeVal(event: any, type: 'competition'): void {
//   this.ctrl.courtNamesDrop?.patchValue([]);
//   this.ctrl.currentCourtNameDrop?.patchValue(null);
//   this.ctrl.currentCourtNameDrop?.enable();
//   // this.gameS.arrCourtName$.next([]);
//   if (type == 'competition') {
//   this.gameS.changeValCompetition(event).then((res) => {
//     const defaultLevel = res?.levels?.find(level => level.level == 'ALL');
//     this.ctrl.levels?.patchValue(new ClassDropForNameDefault(defaultLevel?.id));
//     this.cd.detectChanges();
//   });
//   this.ctrlReset([this.ctrl.gameTypeDrop, this.ctrl.location, this.ctrl.ageGroup, this.ctrl.levels, this.ctrl.homeTeam, this.ctrl.awayTeam], true);
//   this.enableCtrls();
// }
// this.cd.detectChanges();
// }
