<div class='wrapperImportGames'>
  <headTitle *ngIf="deviceS.isDesktop$ | async" text='Imports' typeText='text1-blueDark' class='wrapperImportGames__headTitle'></headTitle>
  <div *ngIf="deviceS.isMobile$ | async" class="imports-title">Imports</div>
  <div class='contentImportGames o-wrap'>


    <div class="o-text4-blueDark">
      <span>Please choose from the competitions already added to your group to <br> upload game schedules</span>
    </div>

    <field text='Competition' class='o-mg-t-20'>
      <app-dropdown [(ngModel)]='selectedCompetition' [array]='(gameS.arrCompetition$|async)!' placeholder='Competition'></app-dropdown>
    </field>

  
    <div class="imports-button">
      <btn class="box-item" text='Cancel' bcg='white' (emit)='router.navigate(["/games"])' [disabled]='reqPending'></btn>
      <btn *ngIf="!selectedCompetition" class="box-item" text='Next Step' bcg='blueDark' (emit)='router.navigate(["/games"])' [disabled]='!selectedCompetition'></btn>
    <csv-import-csvbox *ngIf="selectedCompetition" class='o-btn box-item o-btn-square-blueDark' [user]="userData?.id" [selectedCompetition]="selectedCompetition"></csv-import-csvbox>
    </div>
  </div>
</div>

