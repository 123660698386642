<!--*ngIf='competitionS.isLoad.locations; else spinner'-->
<div class='wrapperCompetitions wrapperCompetitionsLocations'>
  <competitionsNavigation page='locations'></competitionsNavigation>
  <div class='wrapperCompetitionsBlock ' [formGroup]='form'>
    <!--  <div [formGroup]='form'>-->
    <div class='headerSetupCompetition'>
      <svgAndText text='New Locations' leftSvgName='arrow_squareLeft&24' typeText='text1-blueDark'
                  (leftSvgEmit)='competitionS.goToBack()'></svgAndText>
      <div *ngIf='competitionS.competition?.id' class='headerSetupCompetition__btns'>
        <btn text='Save & Close' bcg='white' btnHeight='36' (click)='competitionsBtnsEmit(false)'
             [disabled]='!form.valid || reqPending || isReadOnly'></btn>
        <btn text='Save & Proceed to Next Step' bcg='blueDark' btnHeight='36' (click)='competitionsBtnsEmit(true)'
             [disabled]='!form.valid || reqPending || isReadOnly'></btn>
      </div>
    </div>

    <div formArrayName='arrControls' class='contentSetupCompetition'>
      <div *ngFor='let item of arrControls?.controls; let idx=index; let first=first;  let last=last' [formGroupName]='idx'
           class='contentSetupCompetition__itemLocation'>
        <div class='contentSetupCompetition__itemLocation__line1'>
          <field text='Name'>
            <inputCtrl formControlName='name' placeholder='Name' [isFocus]='true'></inputCtrl>
          </field>
          <field text='Address'>
            <inputAddress formControlName='street' (changeVal)='changeAddress(idx,$event.value, $event.ctrl)'></inputAddress>
          </field>
          <deleteItemForCompetitions page='locations' [id]='item?.value?.id!' [disabled]='arrControls?.controls?.length == 1 || isReadOnly'
                                     (responseEmit)='form.controls.arrControls?.removeAt(idx)'></deleteItemForCompetitions>
        </div>
        <field text='Field / Court Name' class='o-w-100 o-mg-t-16'>
          <addressCourtName [location]='arrControls?.controls![idx]?.controls?.address?.value'
                            [disabled]='arrControls?.controls![idx]?.controls?.address?.disabled'
                            [courtNamesDrop]='arrControls?.controls![idx]?.controls?.courtNamesDrop?.value!'
                            (changeValLoc)='changeValLoc(idx,$event)'
                            (changeCourtNamesDrop)='changeValCourtName(idx,$event)'></addressCourtName>
        </field>
        <btnAddAnother [disabled]="isReadOnly" *ngIf='last' page='competition' (emit)='addNew()'></btnAddAnother>
      </div>

      <btnWrap *ngIf='!competitionS.competition?.id' type='col'>
        <btn text='Save & Proceed to Next Step' bcg='blueDark' (click)='competitionsBtnsEmit(true)'
             [disabled]='!form.valid || reqPending  || isReadOnly'></btn>
        <btn text='Save & Close' bcg='white' (click)='competitionsBtnsEmit(false)' [disabled]='!form.valid || reqPending'></btn>
      </btnWrap>
    </div>

  </div>
</div>

<!--<ng-template #spinner>-->
<!--  <mat-spinner diameter='40'></mat-spinner>-->
<!--</ng-template>-->