import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ClassGame } from "@app/dir_group_assignor/games/game";
import { DeviceService } from "./device.service";
import { GameNotesComponent } from "@app/dir_group_assignor/games/components/game-notes/game-notes.component";


@Injectable({ providedIn: 'root' })
export class GameNotesPopupService {
    constructor(
        private dialog: MatDialog,
        private deviceS: DeviceService
    ) {
    }
    public popupGameNotesReadOnly(item: ClassGame) {
        this.popupGameNotesReadOnlyByText(item.gameDescription ?? "");
        // let width = '343px';
        // if (this.deviceS.isDesktop)
        //     width = '480px';
        // return this.dialog.open(GameNotesComponent, { width: width, data: { text: item.gameDescription, isReadOnly: true } });
    }
    public popupGameNotesReadOnlyByText(gameDescription: string | undefined) {
        let width = '343px';
        if (this.deviceS.isDesktop)
            width = '480px';
        return this.dialog.open(GameNotesComponent, { width: width, data: { text: gameDescription ?? "", isReadOnly: true } });
    }

}
