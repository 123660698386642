import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceService } from '@services/device.service';
import { ITableEmpty } from '@components/_table/meTable';

// export const class_ngContent_btn_for_nothingList = 'class_ngContent_btn_for_nothingList';

// !!! если search и нет результата с бэка то новый текст без кнопки.
// !!! если search пустой то старый текст
@Component({
  selector: 'nothingList[tableEmpty]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './nothing-list.component.html',
  styleUrls: ['./nothing-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NothingListComponent implements OnChanges {
  @Input() tableEmpty!: ITableEmpty;

  // readonly class_ngContent_btn_for_nothingList = class_ngContent_btn_for_nothingList;

  // readonly urlSetupCompetition = urlSetupCompetition; // competitions/about/setup-competition
  // readonly titleEmptyList_games = titleEmptyList_games;
  // readonly subTitleEmptyList_games = subTitleEmptyList_games;
  // readonly urlGameImport = urlGameImport;

  constructor(
    // public meS: MeService,
    public deviceS: DeviceService,
    // public sanitizer: DomSanitizer,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes?.tableEmpty?.currentValue) {
    //   console.log('NothingListComponent tableEmpty :', this.tableEmpty);
    // }
  }

}

// ======= OLD ====================================
// readonly urlGameImport = urlGameImport;
// private _headerPage = '';
// @Input() get headerPage(): string {
//   return this._headerPage;
// }
// set headerPage(headerPage: string) {
//   this._headerPage = (headerPage && headerPage.trim());
// }
// private _descriptionPage = '';
// @Input() get descriptionPage(): string {
//   return this._descriptionPage;
// }
// set descriptionPage(descriptionPage: string) {
//   console.log(descriptionPage);
//
//   this._descriptionPage = (descriptionPage && descriptionPage.trim());
// }
// @Input() typePage!: TypePageForNothingList; // Артур Omit<TypePageForNothingList, 'games'>
// @Input() controls: any;
