import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MainService } from '@services/main.service';
import { ClassAdjustmentStatus } from '@app/group-profile/components/group-profile-adjustment-statuses/adjustmentStatus';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AdjustmentStatusesService {
  readonly matTooltip_for_subTitle = 'Example: If a match is canceled due to poor weather and the officials arrive at the site, and your policy is to pay the officials 50% of the game fee (rather than the default 100%), yet the assignor still receives 100% of his or her assignor fee, you would create an adjustment status that reflects this scenario.';

  constructor(
    private mainS: MainService,
  ) {
  }

  // === TABLE =======================
  arrContent$ = new BehaviorSubject<Array<ClassAdjustmentStatus>>([]);

  methodAdjustmentStatuses(httpMethod: 'get', isActive: boolean): Observable<Array<ClassAdjustmentStatus>>
  methodAdjustmentStatuses(httpMethod: 'put', isActive: boolean, arrSendObj: Array<ClassAdjustmentStatus>): Observable<Array<ClassAdjustmentStatus>>
  methodAdjustmentStatuses(httpMethod: 'get' | 'put', isActive: boolean, arrSendObj?: Array<ClassAdjustmentStatus>): Observable<Array<ClassAdjustmentStatus>> {
    return this.mainS.methodAdjustmentStatuses(httpMethod, arrSendObj, isActive)
      .pipe(
        tap((res) => {
          if (httpMethod === 'get') this.arrContent$.next(res || []);
        }),
      );
  }

  deleteAdjustmentStatus(idAdjustmentStatus: string): Observable<any> {
    return this.mainS.deleteAdjustmentStatus(idAdjustmentStatus)
      .pipe(
        tap((res) => {
          const arrContent_withOut_deletedItem = this.arrContent$.getValue().filter((el) => el.id !== idAdjustmentStatus);
          this.arrContent$.next(arrContent_withOut_deletedItem || []);
        }),
      );
  }

}
