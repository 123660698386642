import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MainService } from '@services/main.service';
import { IDataPopup } from '@services/popup.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaxCharacterComponent } from '@components/__info_text_message_error_warning/max-character/max-character.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';
import { TClassForText, TColor } from '@models/ICssStyles';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { ErrorComponent } from '@components/__info_text_message_error_warning/error/error.component';
import { PopupComponent } from '../popup/popup.component';

@Component({
  selector: 'app-popup-confirm-payment',
  templateUrl: './popup-confirm-payment.component.html',
  styleUrls: ['./popup-confirm-payment.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, MaxCharacterComponent, BtnWrapComponent, BtnComponent, HeadTitleComponent, InputCtrlComponent, FieldComponent, ErrorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupConfirmPaymentComponent {


  textBtnApply = '';
  textBtnCancel = '';
  colorBtnApply: TColor = 'blueDark';
  colorBtnCancel: TColor = 'white';

  swapBtn = false; 

  constructor(
    public mainS: MainService,
    public dialogRef: MatDialogRef<PopupConfirmPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { textTitle: string; text: string; showCloseButton: boolean; closeOnApprove?: boolean; },
  ) {
  }

  @Output() approve = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  onApprove(): void {
    if (this.data.closeOnApprove) {
      this.dialogRef.close();
    }
    this.approve.emit();
  }

  onClose(): void {
    this.close.emit();
  }
}
