import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '@services/utils.service';
import { IResponse } from '@models/response-and-request';

@Pipe({ name: 'isLastPage', standalone: true })
export class IsLastPagePipe implements PipeTransform {

  transform<T>(response?: IResponse<T> | null, forTest?: string): boolean {
    return UtilsService.isLastPage(response, forTest);
  }

}
