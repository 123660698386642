<div class='wrapperPopupInvitePeoples'>
  <headTitle [text]='titleText' svgRight='cross_grey&24' (svgRightEmit)='close(null)' typeText='text4-blueDark'></headTitle>

  <div class='subTitlePopupInvitePeoples'>{{subTitleText}}</div>

  <switcherBtn #switcherBtn text1='By email' text2='By CSV' class='o-mg-t-20' [class.o-hiddenAbsolute]='show_switcherBtn'></switcherBtn>

  <!--  ===  EMAIL =========================-->
  <!--  <ng-container *ngIf='linkActive === "email"'>-->
  <ng-container *ngIf='switcherBtn?.btnActive?.btn1'>
    <ng-container *ngFor='let people of newPeoples; let idx = index; let first=first; let last=last'>
      <div class='emailBlock' [class]='idx == 0 ? "o-mg-t-22":"o-mg-t-16"'>
        <div class='emailBlock__title' (click)='null'>Email address
          <svgAndText *ngIf='first' text='Add from' rightSvgName='google&24' typeText='text7-grey1' marginForRightSvg='6'></svgAndText>
        </div>
        <inputCtrl [(ngModel)]='people.email' placeholder='Enter email address' (changeVal)='emailToLowerCase(people,$event)'></inputCtrl>
        <error *ngIf='showError && !people.email' text='The field cannot be empty'></error>
        <error *ngIf='showError && people.email && !(people.email|isCorrectEmail)' text='Incorrect email'></error>
      </div>
      <div *ngIf='last' class='emailActionPopupInvitePeoples'>
        <addAnother (emit)='addPeople()' color='newGreen'></addAnother>
        <span *ngIf='idx' (click)='removeEmail(idx)'>Remove</span>
      </div>
    </ng-container>

    <field *ngIf='show_dropUserRole' text='User Role' class='o-mg-t-12'>
      <app-dropdown [(ngModel)]='selectedUserRoleDrop' [array]='arrUserRoleDrop'
                    placeholder='Select Role'></app-dropdown>
    </field>
    <!--    <svgAndText text='Add another' leftSvgName='plus_green' typeText='text7-newGreen' (click)='addPeople()'-->
    <!--                class='o-mg-t-12 o-cur-pointer'></svgAndText>-->
    <!--    === !!! disabled => если это страница groupProfile_userManagement то надо обязательно чтобы была выбрана роль при открытии попап окна "Customize your invitation" -->
    <btn text='Customize your invitation' [fontSize]='14' bcg='white' svgLeft='magicWand&20' (click)='openPopupCustomizeInvitation()'
         class='o-mg-t-20' [disabled]='typeInvite==="inviteUserToGroup" ? !selectedUserRoleDrop : false'></btn>
  </ng-container>

  <!--  ===  _CSV =========================-->
  <!--  <ng-container *ngIf='linkActive === "csv"'>-->
  <ng-container *ngIf='switcherBtn?.btnActive?.btn2'>
    <div class='o-blockUploadFile' appDnd (fileDropped)='onFileDropped($event)'>
      <input type='file' id='fileDropRef' (change)='fileBrowseHandler($event)'>
      <meSvg svgName='download_6&40'></meSvg>
      <label for='fileDropRef' class='o-blockUploadFile__rowLink'>
        <span class='o-blockUploadFile__rowLink__link'>Click to upload</span>
        <span class='o-blockUploadFile__rowLink__text'>or drag and drop</span>
      </label>
    </div>

    <btn [fontSize]='14' text='Customize your invitation' bcg='white' svgLeft='magicWand&20' (click)='openPopupCustomizeInvitation()'
         class='o-mg-t-20'></btn>

    <div *ngIf='emails.length' class='wrap-emails'>
      <div *ngFor='let item of emails[pageEmails - 1]' class='email'>
        <span>{{item}}</span>
        <meSvg svgName='cross_grey&24' (click)='deleteEmail(item)'></meSvg>
      </div>
    </div>
  </ng-container>

  <div class='actionsPopupInvitePeoples actionsPopupInvitePeoples--{{switcherBtn?.btnActive?.btn1 ? "email":"csv"}}'>
    <!--    <svgAndText *ngIf='switcherBtn?.btnActive?.btn1' text='Copy link' leftSvgName='squareTwo2&20' typeText='text7-blueDark'-->
    <!--                (leftSvgEmit)='getLink()' [disable]='typeInvite==="inviteUserToGroup" ? !selectedUserRoleDrop : false'></svgAndText>-->
    <!--    <meSvg *ngIf='switcherBtn?.btnActive?.btn1' svgName='clock_red&20' class='o-mg-l-7' matTooltip='Link will be expired in 7 days'></meSvg>-->
    <copyLink *ngIf='switcherBtn?.btnActive?.btn1' [showClock]='true' [path]='path' [userRole]='selectedUserRoleDrop?.upperCase!'
              [competition]='competition' [disable]='typeInvite==="inviteUserToGroup" ? !selectedUserRoleDrop : false'></copyLink>
    <div class='btnWrapPopupInvitePeoples'>
      <btn text='Cancel' bcg='white' (emit)='close(null)'></btn>
      <btn *ngIf='typeInvite==="inviteOfficials"' text='Send invite' bcg='blueDark' (emit)='inviteOfficials()'
           [disabled]='reqPending || showError'></btn>
      <btn *ngIf='typeInvite==="inviteUserToGroup"' text='Send invite' bcg='blueDark' (emit)='inviteUserToGroup()'
           [disabled]='reqPending || showError || !selectedUserRoleDrop'></btn>
    </div>

  </div>
  <!--  <error *ngIf='showError && switcherBtn.btnActive.btn1' text='No selected contacts'></error>-->
  <error *ngIf='errorNoContacts.isError && !switcherBtn?.btnActive?.btn2' [text]='errorNoContacts.text'></error>

</div>

<!-- !!!   пока не удалять  ===  GOOGLE =========================-->
<!--  <ng-container *ngIf='linkActive === "google"'>-->
<!--    <div class='google'>-->
<!--      <div class='google__block'>-->
<!--        <div class='google__block__content'>-->
<!--          <img src='assets/google&24.svg' alt=''>-->
<!--          <span>Gmail Contacts</span>-->
<!--        </div>-->
<!--        <div *ngIf='!isConnect' class='o-btn-circle-green o-cur-pointer' (click)='getEmailsFromGoogle()'>Connect</div>-->
<!--        <div *ngIf='isConnect' class='o-btn-circle-bluedark o-cur-pointer' (click)='disconnectGoogle()'>Disconnect</div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div *ngIf='emails.length' class='wrap-emails'>-->
<!--      <div *ngFor='let item of emails[pageEmails - 1]' class='email'>-->
<!--        <span>{{item}}</span>-->
<!--        <meSvg svgName='cross' (click)='deleteEmail(item)'></meSvg>-->
<!--      </div>-->
<!--    </div>-->
<!--  </ng-container>-->

<!--   !!! не удалять пока что-->
<!--  <div *ngIf='(linkActive !== "email") && (emails.length > 1)' class='wrap-emails__pagination'>-->
<!--    <pagination [currentPage]='pageEmails' [pagesCount]='emails.length' (newPage)='updatePageEmails($event)'></pagination>-->
<!--    <pagination [data]='data' (updatePage)='updatePage($event)'></pagination>-->
<!--  </div>-->