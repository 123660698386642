import { Injectable } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { PopupService } from '@services/popup.service';
import { TypePathForTable } from '@app/app.module';
import { tap } from 'rxjs/operators';
import { RoutesService } from '@services/routes.service';
import { DeviceService } from '@services/device.service';

export type TPathUnsavedChanges = Extract<TypePathForTable, ['assign', 'groupProfile_adjustmentStatuses']>

@Injectable({ providedIn: 'root' })
export class UnsavedChangesService {
  private path!: TPathUnsavedChanges;
  private unsavedChangesSub$ = new BehaviorSubject<boolean>(false);
  unsavedChanges$ = this.unsavedChangesSub$.asObservable();

  set unsavedChanges(unsavedChanges: boolean) {
    this.path = this.routesS.path as TPathUnsavedChanges; // !!! NO DELETE !!!
    this.unsavedChangesSub$.next(unsavedChanges);
  }

  get unsavedChanges(): boolean {
    return this.unsavedChangesSub$.getValue();
  }

  constructor(
    private popupS: PopupService,
    private routesS: RoutesService,
    private deviceS: DeviceService,
  ) {
    this.path = this.routesS.path as TPathUnsavedChanges;
  }

  openPopup(): Promise<boolean> {
    switch (this.path) {
      case 'assign':
        return this.openPopup_forAssign();
      case 'groupProfile_adjustmentStatuses':
        return this.openPopup_forAdjustmentStatuses();
      default:
        console.error('=== !!! THIS.PAGE NOT FOUND !!! ===', this.path, this.routesS.path);
        return new Promise(resolve => resolve(false));
    }
  }

  openPopup_filters(): Promise<boolean> {
    switch (this.path) {
      case 'assign':
        return this.openPopup_forAssign_filters();
      default:
        console.error('=== !!! THIS.PAGE NOT FOUND !!! ===', this.path, this.routesS.path);
        return new Promise(resolve => resolve(false));
    }
  }

  openPopup_forAssign(): Promise<boolean> {
    const observable = from(this.popupS.openPopupUnsaved(this.deviceS.isMobile)).pipe(
      tap(res => {
        if (!res) return;
        this.unsavedChanges = false;
      }),
    );
    return observable.toPromise() as Promise<boolean>;
  }

  openPopup_forAssign_filters(): Promise<boolean> {
    const observable = from(this.popupS.openPopupUnsavedForFilter(this.deviceS.isMobile)).pipe(
      tap(res => {
        if (!res) return;
        this.unsavedChanges = false;
      }),
    );
    return observable.toPromise() as Promise<boolean>;
  }

  openPopup_forAdjustmentStatuses(): Promise<boolean> {
    const observable = from(this.popupS.openPopupUnsavedForAdjustmentStatuses(this.deviceS.isMobile)).pipe(
      tap(res => {
        if (!res) return;
        this.unsavedChanges = false;
      }),
    );
    return observable.toPromise() as Promise<boolean>;
  }

}

// private canDeactivateSubject = new Subject<Observable<boolean> | Promise<boolean> | boolean>();
// private unsavedChanges: Observable<boolean> | Promise<boolean> | boolean = false;
// setUnsavedChanges(value: Observable<boolean> | Promise<boolean> | boolean): void {
//     this.unsavedChanges = value;
//     this.canDeactivateSubject.next(value);
// }
// hasUnsavedChanges(): Observable<boolean> | Promise<boolean> | boolean {
//     return this.unsavedChanges;
// }
// }