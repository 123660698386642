import { Pipe, PipeTransform } from '@angular/core';
import { ClassGame, ClassGameOfficial } from '@app/dir_group_assignor/games/game';
import { OtherService } from '@services/other.service';

@Pipe({ name: 'getNoAssignGO', standalone: true })
export class GetNoAssignGOPipe implements PipeTransform {

  constructor(
    private otherS: OtherService,
  ) {
  }

  // !!! возвращаются НЕзаасаненные GameOfficials
  transform(game?: ClassGame): Array<ClassGameOfficial> {
    return this.otherS.getNoAssignGO(game);
  }

}
