<div class='wrapSelectDate' [style]='{w:w}|getStyles'>
  <!--  <div class='er'>========={{(date$|async)}}</div>-->

  <mat-form-field floatLabel='auto' class='o-matForm' [ngClass]='"o-matForm--"+bcg'
                  [class]='"o-matForm--"+({ value:(date$|async)?.toString(),
                                            active:datePicker.opened,
                                            disabled:false,
                                            error:required && !!(dateTouched$|async) && !(date$|async)
                                          }|getStateInput)'>

    <!--    [matDatepicker]='datePicker'-->
    <input matInput [value]='(date$|async)!' [matDatepicker]='$any(datePicker)'
           (dateChange)='valueChangeDate($event.value!)'
           (click)='datePicker.open();test()' readonly class='default-cursor' [placeholder]='placeholder'>

    <!-- [for]='$any(datePicker)' -->
    <mat-datepicker-toggle matPrefix [for]='datePicker'>
      <mat-icon matDatepickerToggleIcon>
        <meSvg svgName='calendar_blueDark&20'
               [stateInput]='{ value:(date$|async)?.toString(),
                               active:datePicker.opened,
                               disabled:false,
                               error:required && !!(dateTouched$|async) && !(date$|async)
                             }|getStateInput'></meSvg>
      </mat-icon>
    </mat-datepicker-toggle>

    <mat-datepicker #datePicker></mat-datepicker>
  </mat-form-field>

  <error *ngIf='required && !!(dateTouched$|async) && !(date$|async)' text='Choose a date'></error>
</div>
