<div class='wrapperBlockChart' [style]='{h:height}|getStyles'>
  <svgAndText [text]='chart.title!' rightSvgName='question_grey&16' typeText='text5-blueDark' marginForRightSvg='auto'
              [matTooltip]='chart.matTooltipQuestion!'></svgAndText>
  <div class='infoBlockChart'>
    <div class='infoBlockChart__fee'>
<!--      <div>{{chart.amount|currency}}k</div>-->
      <div>{{chart.amount|roundBalance}}</div>
      <div>
        <meSvg *ngIf='chart.amount' [svgName]='chart.arrow ? "arrow_topGreen&16":"arrow_bottomRed&20"'></meSvg>
        <span [class]='chart.arrow ? "o-colorGreen":"o-color-red"'>{{chart.percent}}%</span>
      </div>
    </div>
    <div>
      <meSvg *ngIf='chart.amount' [svgName]='chart.arrow ? "test_d3js2":"test_d3js3"'></meSvg>
    </div>
  </div>
</div>