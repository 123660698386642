import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { MeService } from '@services/me.service';
import { catchError, map } from 'rxjs/operators';
import { ReportService } from '@app/dir_group_assignor/reports/report.service';

@Injectable({ providedIn: 'root' })
export class ReportResolver implements Resolve<boolean> {

  constructor(
    private meS: MeService,
    private reportS: ReportService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!this.meS.meId) {
      // !!! NO DELETE. это для страниц авторизации. Тоесть если не залогинен, то не надо this.getReport(), потому что вернется 401 ошибка
      return of(true);
    }
    return this.getReport(route.params?.idReportForPath);
  }

  getReport(reportId: string): Observable<boolean> {
    return this.reportS.getReport(reportId)
      .pipe(
        map(res => true),
        catchError(() => of(false)),
      );
  }

}
