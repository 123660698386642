import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';

// !!! use => EMAIL_REGEXP.test(value)
export const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

// export const EMAIL_REGEXP = `^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$`;

export class CustomValidators {

  // static requiredDrop(formGroup: FormGroup): { [key: string]: string; } | null {
  static requiredDrop(formGroup: AbstractControl): ValidationErrors | null {
    if (!formGroup?.value?.id && !(formGroup?.value as ClassDrop)?.upperCase) {
      return { validatorError: 'Required' };
    }
    return null;
  }

  // вешать на контрол confirm
  static checkPassMatch(control: AbstractControl): { [key: string]: string; } | null {
    if (control?.root?.value?.password !== control.value) {
      return { validatorError: 'Password Mismatch' };
    }
    return null;
  }

  // вешать на саму форму
  // !!! контролы надо обязательно называть так password && confirmPassword
  static password(formGroup: FormGroup): { [key: string]: string; } | null {
    let ctrlPassword!: AbstractControl;
    let ctrlConfirmPassword!: AbstractControl;

    const arrCtrlPass: Array<AbstractControl | null> = Object.entries(formGroup?.controls)?.map((el) => {
      if (el[0] == 'password') ctrlPassword = el[1];
      if (el[0] == 'confirmPassword') ctrlConfirmPassword = el[1];
      if (el[0]?.toLowerCase().includes('password')) {
        return el[1] as AbstractControl;
      }
      return null;
    }).filter((el) => !!el);
    arrCtrlPass.forEach((ctrl: AbstractControl | null) => {
      if (!ctrl) return;
      ctrl.addValidators(Validators.pattern(/^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])).{8,}$/));
      if (ctrl.hasError('pattern')) {
        ctrl.setErrors({ validatorError: 'Password must contain at least one uppercase letter, one lowercase letter, number and special character( . - ? ! @ & +)' });
      }
      if (ctrl?.value?.length < 8) {
        ctrl.setErrors({ minlength: { actualLength: ctrl?.value?.length, requiredLength: 8 } });
      }
    });

    if (ctrlPassword?.value && ctrlConfirmPassword?.value) {
      if (ctrlPassword.value !== ctrlConfirmPassword.value) {
        ctrlConfirmPassword.setErrors({ validatorError: 'Password Mismatch' });
      } else {
        ctrlConfirmPassword.setErrors(null);
      }
    }
    return null;
  }

  // !!! контролы надо обязательно называть так, чтобы совпадали названия и присутствовал confirm в названии
  // !!! например password && confirmPassword // account && confirmAccount
  static checkMatch(errorText: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean; } | null => {
      const ctrlNameConfirm = CustomValidators.getControlName(control);
      if (!ctrlNameConfirm) return null;
      const ctrl_for_confirm = ctrlNameConfirm?.replace('confirm', '')?.toLowerCase();
      const ctrl = ctrlNameConfirm ? CustomValidators.getControlByName(control, ctrl_for_confirm!) : null;
      if ((control && ctrl) && control?.value !== ctrl?.value) {
        // @ts-ignore
        return { validatorError: errorText ? errorText : 'Fields Mismatch' };
      }
      return null;
    };
  }

  static patternPassword(control: AbstractControl): { [key: string]: string; } | null {
    control.addValidators(Validators.pattern(/^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])).{7,}$/));
    if (control.hasError('pattern')) {
      return { validatorError: 'Password must contain at least one uppercase letter, one lowercase letter, number and special character( . - ? ! @ & +)' };
    }
    return null;
  }

  static patternEmail(control: AbstractControl): { [key: string]: string; } | null {
    control.addValidators(Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$'));
    // control.addValidators(Validators.pattern(EMAIL_REGEXP));
    if (control.hasError('pattern')) {
      return { validatorError: 'Invalid email' };
    }
    return null;
  }

  static checkFee(control: AbstractControl): { [key: string]: string; } | null {
    // if (!control.value || !Number(control.value)) {
    if (!control.value) {
      // return { validatorError: 'The amount of fee must be at least 50 cents' };
      return null;
    }
    if (Number(control.value) === 0) {
      return { validatorError: 'Amount cannot be zero' };
    }
    return null;
  }

  // ========================================
  static getControlName(control: AbstractControl): string | null {
    const formGroup = (control?.root as FormGroup)?.controls;
    if (!formGroup) return null;
    return Object.keys(formGroup!).find(name => control === formGroup![name]!) || null;
  }

  static getControlByName(control: AbstractControl, ctrlName: string): AbstractControl | null {
    const formGroup = (control?.root as FormGroup)?.controls;
    if (!formGroup) return null;
    const ctrl = Object.entries(formGroup!).find((el) => el[0]?.includes(ctrlName));
    if (!ctrl) return null;
    return ctrl[1];
  }

}

