<div class='wrapperNameStep' [formGroup]='form'>
  <div class='authText'>
    <div>Let's get started! What is your name?</div>
  </div>
  <field text='First Name*'>
    <inputCtrl formControlName='firstName' placeholder='First Name' [isFocus]='true'></inputCtrl>
  </field>
  <field text='Second Name*'>
    <inputCtrl formControlName='secondName' placeholder='Second Name'></inputCtrl>
  </field>
  <field text='Preferred Name (Optional)'>
    <inputCtrl formControlName='preferredName' placeholder='Preferred Name'></inputCtrl>
  </field>
  <btn text='Continue' [bcg]='authS.bcgApplyBtn' [spinner]='reqPending' (emit)='emitMethod()'
       class='o-mg-t-24 o-mg-b-24' [disabled]='form|invalidControls:["firstName","secondName"]'></btn>
</div>