<div class='wrapperPopupCodeConfirm'>

  <headTitle [text]='text_headTitle' svgRight='cross_grey&24'
             (svgRightEmit)='reqPending ? null : close(false)'
             typeText='text4-blueDark' [textCenter]='true'></headTitle>

  <div class='wrapperPopupCodeConfirm__subTitle'>We've sent a code to <a>{{email || phone}}</a></div>

  <div class="content-code-сonfirm">
    <codeConfirm (emit)='validationCode=$event'></codeConfirm>
    <div class='wrapperPopupCodeConfirm__subTitle code'>Didn’t get a code? <a (click)='resendCode()'>Click to resend.</a></div>
  </div>

  <btnWrap>
    <btn text='Verify' bcg='blueDark' (emit)='verifyCode()' [disabled]='validationCode?.length! < 4 || reqPending'></btn>
    <btn text='Cancel' bcg='white' (emit)='close(false)' [disabled]='reqPending'></btn>
  </btnWrap>
</div>