import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'dateTimeFormat',
    standalone: true,
    pure: false
})
export class DateTimeFormatPipe implements PipeTransform {

    transform(value: any, format: string = 'mediumDateTime'): any {
        const datePipe: DatePipe = new DatePipe('en-US');

        if (format === 'mediumDate') {
            return datePipe.transform(value, 'MMMM d, y');
        } else if (format === 'shortTime') {
            return datePipe.transform(value, 'shortTime');
        } else {
            // Default to medium date and time
            return datePipe.transform(value, 'medium');
        }
    }
}