import { Component, Input, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CSVBoxAngularModule } from "@csvbox/angular";
import { ClassCompetition } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MeService } from '@services/me.service';
import { MainService } from '@services/main.service';
import { NotificationService } from '@services/notification.service';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: "csv-import-csvbox",
  imports: [CSVBoxAngularModule],
  standalone: true,
  template: `
    <csvbox-button
      [licenseKey]="licenseKey"
      [user]="userData"
      [onImport]="onData.bind(this)"
    >
    Next Step
    </csvbox-button>
  `
})
export class CsvImportCsvboxComponent implements CSVBoxAngularModule, OnInit {
constructor(private router: Router,
  private meS: MeService,
  private mainS: MainService,
  private notificationService: NotificationService
) {}

  title = "example";
  licenseKey: string = '';
  defaultLicenseKey: string = 'XqUvS06YtZKgikljI1yuSRzepOmlYy';
  @Input() user: any;
  @Input() selectedCompetition: ClassCompetition | undefined;

  ngOnInit(): void {
    setTimeout(() => {
      const btn = document.querySelector("button[data-csvbox-token]");
      btn && btn.removeAttribute('disabled');
    });
        if (window.location.href.includes('staging')) {
          this.licenseKey = 'krubuqzzb0jZIZd6N23PFKZ2FZmAPh';
        } else if (window.location.href.includes('beta')) {
          this.licenseKey = 'XqUvS06YtZKgikljI1yuSRzepOmlYy';
        } else if(window.location.href.includes('live'))
          this.licenseKey = '4JBlgv6P4JIKPpr7QNJxOtseUMO4DU';
        else {
          this.licenseKey = this.defaultLicenseKey;
        }
        console.log('this.licenseKey :>> ', this.licenseKey);
  }



  onData(result: boolean, data: any) {
    if (result) {
      console.log(data.import_id);
      console.log(data.row_success + " rows uploaded");
      const importId = data.import_id;
      const rowCount = data.row_count
      if (!importId) {
        console.error("Import ID is missing. Cannot proceed with status check.");
        return;
      }
      if (!rowCount) {
        console.error("Row count is missing. Cannot proceed with status check.");
        return;
      }
      this.mainS.csvImportCheckStatus(importId, rowCount).subscribe(
        (response) => {
          console.log("CSV Import Status:", response);
        },
        (error: HttpErrorResponse)  => {
          if (error.status === 409 && error.error?.status === "GAMES_MISSING") {
            const expected = error.error.expectedNumberOfGames;
            const actual = error.error.actualNumberOfGames;
            const message = `Oops! Out of ${expected} games, only ${actual} were successfully imported. Please contact hello@joinnotch.com for assistance.`;
            this.notificationService.passNote(message, { status: 'error' });
          } else {
            const message = `Oops! Something went wrong. All games failed to import. Please check your competition configurations and try to import your CSV file again. If the issue persists, contact hello@joinnotch.com for support.`;
            this.notificationService.passNote(message, { status: 'error' });
          }
        }
      );
      this.router.navigate(['/games']);
    } else {
      console.log(data.import_id);
      const mockError: HttpErrorResponse = new HttpErrorResponse({
        error: { message: "Oops! Something went wrong. Please check your competition configurations and try to import your CSV file again. If the issue persists, contact hello@joinnotch.com for support." },
        status: 400,
        statusText: "Bad Request",
        url: "/csv-import"
      });
      const importId = data.import_id;
      const rowCount = data.row_count
      if (!importId) {
        this.notificationService.showNotification_error(mockError, '/csv-import');
        console.error("Import ID is missing. Cannot proceed with status check.");
        return;
      } else if (!rowCount) {
        console.error("Row count is missing. Cannot proceed with status check.");
        return;
      } else {
        this.mainS.csvImportCheckStatus(importId, rowCount).subscribe(
          (response) => {
            console.log("CSV Import Status:", response);
          },
          (error: HttpErrorResponse)  => {
            if (error.status === 409 && error.error?.status === "GAMES_MISSING") {
              const expected = error.error.expectedNumberOfGames;
              const actual = error.error.actualNumberOfGames;
              const message = `Oops! Out of ${expected} games, only ${actual} were successfully imported. Please contact hello@joinnotch.com for assistance.`;
              this.notificationService.passNote(message, { status: 'error' });
            } else {
              const message = `Oops! Something went wrong. All games failed to import. Please check your competition configurations and try to import your CSV file again. If the issue persists, contact hello@joinnotch.com for support.`;
              this.notificationService.passNote(message, { status: 'error' });
            }
          }
        );
        this.router.navigate(['/games']);
      }
    }
  }

  get userData() {
    console.log('this.selectedCompetition :>> ', this.selectedCompetition);
    return {
      user_id: this.user,
      competition_id: this.selectedCompetition?.id,
      user_role: this.meS.meRole,
    }
  }
}
