<div class='transactionInfo'>
    <div class="st-headTitle-wraper">
        <headTitle [text]="meS.OFFICIAL ? 'My Wallet' : 'Assignor Fees'" svgLeft='arrow_squareLeft&24'
            (svgLeftEmit)='goBack()' typeText='text1-blueDark'>
        </headTitle>

    </div>
    <div class="transactionInfoSub">
        <div class="page-header">
            <div>
                <div class="amount-name">Amount</div>
                <div class="amount"> {{gameData?.transferTotalsInCents | currencyCents}}</div>
                <div class="status">
                    <status [status]="gameData?.overallTransfersStatus"></status>
                </div>

            </div>
        </div>

        <div class="infoTitle">
            <div>Transaction Information</div>
        </div>

        <div class="content">
            <div class='t-table-v2 game-information'  *ngIf="isNotPaidOrIntransit" style="height: fit-content;">
                <div class='t-table-v2__item-two'>
                    <div class='cell-header st-600'>Game#</div>
                    <div>{{gameData?.gameNumber}}</div>
                </div>
                <div class='t-table-v2__item-two'>
                    <div class='cell-header  st-600'>Game Date</div>
                    <div>{{gameData?.gameDate | date: globalConst.dateFormat}}</div>
                </div>
                <div class='t-table-v2__item-two'>
                    <div class='cell-header  st-600'> Competition</div>
                    <div>{{gameData?.competitionName}}</div>
                </div>
                <div class='t-table-v2__item-two'>
                    <div class='cell-header  st-600'>Role</div>
                    <div>{{gameData?.role}}</div>
                </div>
                <div class='t-table-v2__item-two'>
                    <div class='cell-header  st-600'>Age & Level</div>
                    <div>{{gameData?.gameAgeDescription}} - {{gameData?.competitionLevel}}</div>
                </div>
                <div class='t-table-v2__item-two'>
                    <div class='cell-header  st-600'>Game Type</div>
                    <div>{{gameData?.gameType}}</div>
                </div>
                <div class='t-table-v2__item-two'>
                    <div class='cell-header  st-600'>Transaction Date</div>
                    <div>{{gameData?.transactionDate}}</div>
                </div>
                <div class='t-table-v2__item-two'>
                    <div class='cell-header  st-600'>Assignor</div>
                    <div>{{gameData?.gameLocation}}</div>
                </div>
            </div>
            <div class='t-table-v2 game-information'  *ngIf="!isNotPaidOrIntransit" style="height: fit-content;">
                <div class='t-table-v2__item-two'>
                    <div class='cell-header  st-600'>Amount</div>
                    <div>{{gameData?.totalInCents | currencyCents}}</div>
                </div>
                <div class='t-table-v2__item-two'>
                    <div class='cell-header  st-600'>Fee</div>
                    <div>{{gameData?.notchPayFeeInCents | currencyCents}}</div>
                </div>
                <div class='t-table-v2__item-two'>
                    <div class='cell-header  st-600'>To</div>
                    <div>TODO</div>
                </div>
                <div class='t-table-v2__item-two'>
                    <div class='cell-header  st-600'>Status</div>
                    <div>{{gameData?.overallTransfersStatus}}</div>
                </div>
                <div class='t-table-v2__item-two'>
                    <div class='cell-header  st-600'>Initiated</div>
                    <div>TODO</div>
                </div>
                <div class='t-table-v2__item-two'>
                    <div class='cell-header  st-600'>Est. Arrival</div>
                    <div>TODO</div>
                </div>
                <div class='t-table-v2__item-two'>
                    <div class='cell-header  st-600'>Last Updated</div>
                    <div>TODO</div>
                </div>
            </div>

        </div>
    </div>
</div>