import { Injectable } from '@angular/core';
import { MeService } from '@services/me.service';
import { IDataPopup, PopupService } from '@services/popup.service';
import { PopupCreateGroupComponent } from '@components/__popup-windows/popup-create-group/popup-create-group.component';
import { PopupCongratsComponent } from '@app/dir_group_assignor/dashboard/components/popup-congrats/popup-congrats.component';
import { DeviceService } from '@services/device.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { OtherService } from '@services/other.service';
import {
  IPopupInvitePeoples,
  PopupInvitePeoplesComponent,
} from '@components/__popup-windows/popup-invite-peoples/popup-invite-peoples.component';
import { ClassGroup } from '@models/IGroup';
import { MainService } from '@services/main.service';
import { urlDashboard, urlGameImport, urlSetupCompetition } from '@app/app.module';
import { CompetitionService } from '@app/dir_group_assignor/competitions/competition.service';
import {
  blockDashboard_assignOfficialsToGames,
  blockDashboard_availability,
  blockDashboard_createGroup,
  blockDashboard_importGames,
  blockDashboard_inviteOfficials,
  blockDashboard_payouts,
  blockDashboard_setUpCompetition,
  IBlockDashboard,
  IOnboarding,
  lengthArrBlockDashboard_official,
  lengthArrBlockDashboard_subAssignor,
} from '@app/dir_group_assignor/dashboard/dashboard';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  arrBlockDashboard$ = new BehaviorSubject<Array<IBlockDashboard>>([]);
  onboardingSkipped$ = new BehaviorSubject<boolean>(false);

  arrCurrentViewDropdown: Array<string> = ['Sub_Assignor', 'Competition Manager', 'Group_Assignor', 'Competition Payer'];

  // === Total Games =============
  arrHeaderUnacceptedGames: Array<{ title: string, isSelect: boolean, sort: boolean; isActive: boolean; }> = [
    { title: 'Game', isSelect: true, sort: true, isActive: false },
    { title: 'Date & Time / Age & Level', isSelect: false, sort: false, isActive: false },
    { title: 'Location / Teams', isSelect: false, sort: false, isActive: false },
    { title: 'Officials', isSelect: false, sort: false, isActive: false },
    { title: ' ', isSelect: false, sort: false, isActive: false },
  ];
  arrHeaderTotalGames: Array<{ title: string, isSelect: boolean, sort: boolean; isActive: boolean; }> = [
    { title: 'Game', isSelect: true, sort: true, isActive: false },
    { title: 'Date & Time', isSelect: false, sort: false, isActive: false },
    { title: 'Age & Level', isSelect: false, sort: false, isActive: false },
    { title: 'Location', isSelect: false, sort: false, isActive: false },
    { title: 'Teams', isSelect: false, sort: false, isActive: false },
    { title: '', isSelect: false, sort: false, isActive: false },
    { title: 'Officials', isSelect: false, sort: false, isActive: false },
    { title: ' ', isSelect: false, sort: false, isActive: false },
  ];

  // Грант create interface
  arrTotalGames: Array<{
    gameDescription: string,
    game: { title: string | number, isSelect: boolean; },
    date: string,
    age: string,
    level: string,
    location: string,
    teams: string,
    team1: string,
    team2: string;

  }> = [];

  arrUpcomingGames: Array<{
    game: { title: string | number, isSelect: boolean; },
    date: string,
    age: string,
    level: string,
    location: string,
    teams: string,
    team1: string,
    team2: string;
  }> = [];

  constructor(
    private mainS: MainService,
    private meS: MeService,
    private popupS: PopupService,
    public deviceS: DeviceService,
    public competitionS: CompetitionService,
    public otherS: OtherService,
    private router: Router,
    // private dialog: MatDialog,
  ) {
    // console.log('DashboardService router.url:', this.router.url)
    // console.log('DashboardService meS.me:', this.meS.me, this.meS.me?.roleCurrent)
    // this.meS.onboarding$.subscribe((onboarding) => this.getBlockDashboard());
    // this.getBlockDashboard();
    // setTimeout(() => {
    //   this.openPopupCreateGroup();
    // })
    // setTimeout(() => this.inviteOfficials());
  }

  // ============================================================================
  getOnboarding(): Observable<IOnboarding | null> {
    return this.mainS.getOnboarding().pipe(
      tap((res) => {
        // console.log('getOnboarding :', res)
        if (!res) return;

        if (this.mainS.forTestOnboarding && !this.mainS.forProd) {  // !!! FOR TEST Onboarding
          this.onboardingSkipped$.next(false);
          this.meS.setMeUser({ ...this.meS.me, onboardingSkipped: false });
          setTimeout(() => this.mainS.forTestOnboarding = false);
        }

        // console.log('meS.me?.onboardingSkipped :', this.meS.me?.onboardingSkipped)
        // console.log('getOnboarding :', res)
        if (this.otherS.checkAllValuesTrueForObj(res) || this.meS.me?.onboardingSkipped) {
          // console.log('111 checkAllValuesTrueForObj(res) :', this.otherS.checkAllValuesTrueForObj(res))
          // console.log('111 onboardingSkipped :', this.meS.me?.onboardingSkipped)
          this.onboardingSkipped$.next(true);
        } else {
          // console.log('222 checkAllValuesTrueForObj(res) :', this.otherS.checkAllValuesTrueForObj(res))
          // console.log('222 onboardingSkipped :', this.meS.me?.onboardingSkipped)
          // console.log('DashBoardService: router.navigate([urlDashboard])');
          this.router.navigate([urlDashboard]);
        }
        // if (this.meS.OFFICIAL) {
        //   if (this.otherS.checkAllValuesTrueForObj(res) && this.router.url.includes('dashboard')) {
        //     // this.router.navigate(['/myGames']);  // Commented in scope of  Task: /NOT30-156
        //   }
        // } else {
        //   if (!res?.groupCreated) {
        //     this.router.navigate(['/dashboard']);
        //   }
        // }
        // Object.entries(res)?.forEach((el) => console.log('IOnboarding :', el));
        this.meS.onboarding$.next(res);
        // console.log('meS.onboarding$ :', this.meS.onboarding$.getValue())
        // console.log('onboardingSkipped$ :', this.onboardingSkipped$.getValue())
        // this.setCompletedBlockDashboard(res);
      }),
    );
  }

  private checkActiveCurrentStep(step: number): boolean {
    // console.log('checkActiveCurrentStep step:', step)
    // console.log('checkActiveCurrentStep this.meS.onboarding$.getValue():', this.meS.onboarding$.getValue())
    if (!this.meS.onboarding$.getValue()) return false;
    const {
      groupCreated, competitionCreated, gamesCreated,
      officialsInvited, officialsAssigned,
      availability, payouts,
    } = this.meS.onboarding$.getValue() as IOnboarding;
    let active = false;
    if (this.meS.OFFICIAL) {
      if (step == 1) active = !availability;
      if (step == 2) active = !!availability && !payouts;
    } else {
      if (step == 1) active = !groupCreated;
      if (step == 2) active = !!groupCreated && !competitionCreated;
      if (step == 3) active = !!groupCreated && !!competitionCreated && !gamesCreated;
      if (step == 4) active = !!groupCreated && !!competitionCreated && !!gamesCreated && !officialsInvited;
      if (step == 5) active = !!groupCreated && !!competitionCreated && !!gamesCreated && !!officialsInvited && !officialsAssigned;
    }
    return active;
  }

  getBlockDashboard(): void {
    // console.log('getBlockDashboard :', this.meS.meRole, this.meS.me)
    // console.log('getBlockDashboard this.meS.onboarding$.getValue():', this.meS.onboarding$.getValue())
    if (!this.meS.onboarding$.getValue()) return;
    const {
      groupCreated, competitionCreated, gamesCreated,
      officialsInvited, officialsAssigned,
      availability, payouts,
    } = this.meS.onboarding$.getValue() as IOnboarding;
    if (this.meS.OFFICIAL) {
      // console.log('getBlockDashboard OFFICIAL:', )
      this.arrBlockDashboard$.next([
        {
          ...blockDashboard_availability,
          completed: availability,
          active: this.checkActiveCurrentStep(1),
          action: () => this.router.navigate(['/planner'], { queryParams: { type: 'Availability' } }),
        },
        {
          ...blockDashboard_payouts,
          completed: payouts,
          active: this.checkActiveCurrentStep(2),
          action: () => this.router.navigate(['/myWallet']),
        },
      ]);
    } else if (this.meS.GROUP_ASSIGNOR) {
      // console.log('getBlockDashboard GROUP_ASSIGNOR:', )
      this.arrBlockDashboard$.next([
        {
          ...blockDashboard_createGroup,
          completed: groupCreated,
          active: this.checkActiveCurrentStep(1),
          action: this.openPopupCreateGroup.bind(this),
        },
        {
          ...blockDashboard_setUpCompetition,
          completed: competitionCreated,
          active: this.checkActiveCurrentStep(2),
          action: this.goToSetupCompetition.bind(this),
        },
        {
          ...blockDashboard_importGames,
          completed: gamesCreated,
          active: this.checkActiveCurrentStep(3),
          action: () => this.router.navigate([urlGameImport]),
        },
        {
          ...blockDashboard_inviteOfficials,
          completed: officialsInvited,
          active: this.checkActiveCurrentStep(4),
          action: this.inviteOfficials.bind(this),
        },
        {
          ...blockDashboard_assignOfficialsToGames,
          completed: officialsAssigned,
          active: this.checkActiveCurrentStep(5),
          // action: () => this.meS.updateOnboarding({ allCompleted: true }),
          action: () => this.meS.updateOnboarding({ officialsAssigned: true }),
        },
      ]);
    } else {
      console.log('NO NEED onboarding for this userRole :', this.meS.meRole);
    }
    // console.log('arrBlockDashboard$ :', this.arrBlockDashboard$.getValue());
  }

  // setCompletedBlockDashboard(dashboard: IOnboarding): void {
  //   if (!dashboard) return;
  // if (!this.meS.OFFICIAL && dashboard.competitionCreated) {
  //   this.setBlockDashboard({ completed: dashboard.competitionCreated, step: 2, active: false, skipped: false });
  //   this.setBlockDashboard({ step: 3, active: true, skipped: false });
  // }
  // }

  setBlockDashboard(blockDashboard: IBlockDashboard): void {
    const idxCurrentBlockDashboard = this.arrBlockDashboard$.getValue().findIndex((el) => el.step === blockDashboard.step);
    let currentBlockDashboard = this.arrBlockDashboard$.getValue().find((el) => el.step === blockDashboard.step);
    currentBlockDashboard = { ...currentBlockDashboard, ...blockDashboard };
    const arrBlockDashboard: Array<IBlockDashboard> = this.arrBlockDashboard$.getValue();
    if (currentBlockDashboard && (idxCurrentBlockDashboard || idxCurrentBlockDashboard == 0)) {
      arrBlockDashboard[idxCurrentBlockDashboard] = currentBlockDashboard;
      this.arrBlockDashboard$.next(arrBlockDashboard);
    }
  }

  nextStep(currentStep: number, skipped = false): void {
    console.log('nextStep :', currentStep, skipped);
    const currentBlock: IBlockDashboard = this.arrBlockDashboard.find((el) => el.step == currentStep)!;
    // console.log('currentBlock :', currentBlock)
    if (skipped) currentBlock.skipped = true;
    currentBlock.active = false;
    currentBlock.completed = true;

    let currentIdxStep: number = currentStep - 1;
    let nextBlock: IBlockDashboard | undefined = this.arrBlockDashboard[currentIdxStep + 1];

    let typeOnboarding = currentBlock.typeOnboarding;
    this.meS.updateOnboarding({ [typeOnboarding]: true });

    // console.log('this.meS.onboarding$.getValue() :', this.meS.onboarding$.getValue())
    // Object.entries(this.meS.onboarding$.getValue()!)?.forEach((el) => console.log('IOnboarding :', el))

    let allStepsCompleted: boolean;
    if (this.meS.GROUP_ASSIGNOR) {
      allStepsCompleted = !!this.meS.onboarding$.getValue()?.groupCreated &&
        !!this.meS.onboarding$.getValue()?.competitionCreated &&
        !!this.meS.onboarding$.getValue()?.gamesCreated &&
        !!this.meS.onboarding$.getValue()?.officialsInvited &&
        !!this.meS.onboarding$.getValue()?.officialsAssigned;
    } else {
      allStepsCompleted = !!this.otherS.checkAllValuesTrueForObj(this.meS.onboarding$.getValue());
    }
    //  const allStepsCompleted = this.otherS.checkAllValuesTrueForObj(this.meS.onboarding$.getValue());
    // console.log('allStepsCompleted :', allStepsCompleted)
    if (allStepsCompleted) {
      this.onboardingSkipped$.next(true);
      this.updateOnboardingSkipped();
    }

    if (!this.meS.OFFICIAL && currentStep === lengthArrBlockDashboard_subAssignor) { // !!! for last step
      this.meS.updateOnboarding({
        officialsAssigned: true,
        groupCreated: true,
        competitionCreated: true,
        gamesCreated: true,
        officialsInvited: true,
      });
      //  this.openPopupCongrats();
      return;
    }
    if (this.meS.OFFICIAL && currentStep === lengthArrBlockDashboard_official) { // !!! for last step
      this.meS.updateOnboarding({
        availability: true,
        payouts: true,
      });
      console.log('DashBoardService: router.navigate([urlDashboard])');
      this.router.navigate([urlDashboard]);
      return;
    }

    nextBlock.active = true;

    const newArrBlockDashboard: Array<IBlockDashboard> = this.arrBlockDashboard;
    newArrBlockDashboard[currentIdxStep] = currentBlock;
    newArrBlockDashboard[currentIdxStep + 1] = nextBlock;
    this.arrBlockDashboard$.next(newArrBlockDashboard);
  }

  // step 1
  openPopupCreateGroup(): void {
    const dataPopup: IDataPopup = {};
    if (this.deviceS.isDesktop) dataPopup.width = '757px';
    this.popupS.open(PopupCreateGroupComponent, dataPopup).then((res: ClassGroup) => {
      if (res) {
        this.mainS.createGroup(res).toPromise()
          .then((res) => this.nextStep(1))
          .catch((err) => {
          });
      }
    });
  }

  // step 4
  inviteOfficials(): void {
    const dataPopup: IPopupInvitePeoples = {
      width: '480px',
      path: 'dashboard',
    };
    this.popupS.open(PopupInvitePeoplesComponent, dataPopup).then((res: any) => {
      if (res) this.nextStep(4);
    });
  }

  openPopupCongrats(): void {
    // console.log('openPopupCongrats :', )
    this.popupS.open(PopupCongratsComponent, {}).then(() => {
      // this.meS.updateOnboarding({ allCompleted: true });
      // this.meS.updateOnboarding({ officialsAssigned: true });
    });
  }

  goToSetupCompetition(): void {
    this.competitionS.reset();
    this.router.navigate([urlSetupCompetition]);
  }

  // ==================================
  updateOnboardingSkipped(): void {
    // console.log('updateOnboardingSkipped :', )
    this.mainS.updateUser({ onboardingSkipped: true }, true).toPromise()
      .then((res) => {
      })
      .catch(err => {
      });
  }

  // === GETTERS ======================
  get arrBlockDashboard(): Array<IBlockDashboard> {
    return this.arrBlockDashboard$.getValue();
  }
}
