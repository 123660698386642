import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainService } from '@services/main.service';
import { CompetitionService } from '@app/dir_group_assignor/competitions/competition.service';
import { MeService } from '@services/me.service';
import { OtherService } from '@services/other.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeviceService } from '@services/device.service';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { CustomDatesService } from '@classes/CustomDates';
import { IDataPopup } from '@services/popup.service';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { HelperClass } from '@classes/Helper-Classes';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-popup-time',
  standalone: true,
  imports: [CommonModule, SvgComponent, BtnWrapComponent, BtnComponent],
  templateUrl: './popup-time.component.html',
  styleUrls: ['./popup-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupTimeComponent extends HelperClass {
  hours: string = '12';
  minutes: string = '00';
  amPm: 'AM' | 'PM' = 'AM';

  time: string = '12:00 AM';

  constructor(
    public mainS: MainService,
    public competitionS: CompetitionService,
    public meS: MeService,
    public otherS: OtherService,
    public dialogRef: MatDialogRef<PopupTimeComponent>,
    public deviceS: DeviceService,
    public gameS: GameService,
    public customDatesS: CustomDatesService,
    public cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public dataPopup?: IDataPopup,
  ) {
    super(cd);
    if (dataPopup?.time) {
      this.time = dataPopup.time;
      this.amPm = dataPopup?.time?.split(' ')[1]?.trim() as 'AM' | 'PM';
      const arrHoursMinutes = dataPopup?.time?.replace('AM', '')?.replace('PM', '')?.split(':')!;
      this.hours = arrHoursMinutes[0]?.trim();
      this.minutes = arrHoursMinutes[1]?.trim();
    }
  }

  changeHours(isUp = true): void {
    if (isUp) {
      if (this.hours == '12') this.hours = '1';
      else this.hours = (+this.hours + 1)?.toString();
    } else {
      if (this.hours == '1') this.hours = '12';
      else this.hours = (+this.hours - 1)?.toString();
    }
    this.cd.detectChanges();
  }

  changeMinutes(isUp = true): void {
    if (isUp) {
      if (this.minutes == '55') this.minutes = '00';
      else this.minutes = (+this.minutes + 5)?.toString()?.trim();
    } else {
      if (+this.minutes == 0) this.minutes = '55';
      else this.minutes = (+this.minutes - 5)?.toString()?.trim();
    }
    if (this.minutes?.length == 1 && +this.minutes < 10) this.minutes = '0' + this.minutes?.trim();
    this.cd.detectChanges();
  }

  changeAmPm(): void {
    this.amPm == 'AM' ? this.amPm = 'PM' : this.amPm = 'AM';
    this.cd.detectChanges();
  }

  close(isCancel: boolean): void {
    this.time = `${this.hours}:${this.minutes} ${this.amPm}`;
    this.dialogRef.close(isCancel ? null : this.time);
  }

}
