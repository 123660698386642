import { Pipe, PipeTransform } from '@angular/core';
import { ClassGame } from '@app/dir_group_assignor/games/game';
import { UtilsService } from '@services/utils.service';

@Pipe({ name: 'matTooltipForActionsForCanceledGame', standalone: true })
export class MatTooltipForActionsForCanceledGamePipe implements PipeTransform {

  readonly textForMatTooltip = 'This game has already the game report. You should change the status in it.';

  transform(game?: ClassGame): string {
    return UtilsService.isPossibleGameToCancel(game) ? '' : this.textForMatTooltip;
  }

}
