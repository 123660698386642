import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceService } from '@services/device.service';
import { RouterOutlet } from '@angular/router';
import { MeProfileOfficialService } from '@app/profile/me-profile-official/me-profile-official.service';
import { LinkPageComponent } from '@components/link-page/link-page.component';
import { ProfileService } from '@app/profile/profile.service';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { FormsModule } from '@angular/forms';
import { OtherService } from '@services/other.service';

@Component({
  selector: 'app-me-profile-official',
  standalone: true,
  imports: [CommonModule, LinkPageComponent, RouterOutlet, DropFormCtrlComponent, HeadTitleComponent, FormsModule],
  templateUrl: './me-profile-official.component.html',
  styleUrls: ['./me-profile-official.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeProfileOfficialComponent {
  constructor(
    // public meS: MeService,
    public deviceS: DeviceService,
    // private mainS: MainService,
    // public router: Router,
    // private route: ActivatedRoute,
    // private lodashS: LodashService,
    public otherS: OtherService,
    public meProfileOfficialS: MeProfileOfficialService,
    public profileS: ProfileService,
    public cd: ChangeDetectorRef,
  ) {
    this.profileS.setData(this.meProfileOfficialS.typePageProfile);
  }

}
