import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CREDIT_CARD_TYPE } from '@shared/constants/credit-card-type.constant';

@Component({
  selector: 'app-card-logo',
  standalone: true,
  templateUrl: './card-logo.component.html',
  styleUrls: ['./card-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
  ],
})
export class CardLogoComponent {
  @Input() cardBrand?: CREDIT_CARD_TYPE;
  @Input() width: number | null = null;
  @Input() height: number | null = null;
}
