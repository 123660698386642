import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NotificationService } from '@services/notification.service';
import { OtherService } from '@services/other.service';

export interface IErrorResponse {
  errorDetails?: string; // "This card doesn't appear to be a debit card.; code: invalid_card_type; request-id: req_Ik26qx0V0jKVeH"
  message?: string; // "Payment system error"
  type?: string; // "UNKNOWN"
}

@Injectable({ providedIn: 'root' })
export class MainHandlerInterceptor implements HttpInterceptor {

  constructor(
    private otherS: OtherService,
    private notificationS: NotificationService,
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error?.status === 418 && error?.url?.includes('api/core/competitions/v1/all')) {
          console.warn('Suppressed 418 error for competitions endpoint');
          return throwError(error);
        }

        if (error?.status === 404 && error?.url?.includes('/scheduled_payout')) {
          console.warn('Scheduled Payout not found, /v1/scheduled_payout');
          return throwError(error);
        }
        if (error?.status && error?.url?.includes('checkStatus')) {
          console.warn('Removing checkStatus error');
          return throwError(error);
        }

        if (error?.status && error?.url?.includes('api/payments/v1/account/connect/payoutBalances')) {
          console.warn('Cannot invoke \"java.util.List.stream()\" because the return value of \"com.stripe.model.Balance$InstantAvailable.getNetAvailable()\" is null');
          return throwError(error);
        }

        if (error?.status === 404 && (error?.url?.includes('/account/connect/balance') || error?.url?.includes('payments/v1/autotopup/'))) {
          console.warn('Suppressed 404 error for /account/connect/balance endpoint');
          return throwError(error); 
        }

        if (!(error?.url?.includes('users/user-licenses') && error?.status === 404)) {
          this.notificationS.showNotification_error(error, '  MainHandlerInterceptor error ');
        }
        return throwError(error);
      }),
      tap((res: any) => { // res: HttpResponse<>
        // !!! res?.body?.responseBody?.length > 1 => Оливер написал что если репортов для апрува больше 1, то показывать эту нотификацию. А иначе как обычно то что с сервера пришло
        if (res?.url?.includes('api/core/gameReport/v1/approve') && res?.status === 200 && request?.method === 'POST' && res?.body?.responseBody?.length > 1) {
          // body:{message: 'Game reports are approved.', responseBody: Array(2)}
          // headers:HttpHeaders {normalizedNames: Map(0), lazyUpdate: null, lazyInit: ƒ}
          // ok:true
          // status:200
          // statusText:"OK"
          // type:4
          // url:
          this.notificationS.showNotificationForReportApprove();
          return;
        }

        const body = res?.body;
        if (body?.error) {
          this.notificationS.showNotification_error(body, '  MainHandlerInterceptor success ');
        } else {
          this.notificationS.showNotification_success(body, request?.url); // body?.responseBody || body
        }
      }),
    );
  }

}
