import { createSelector } from "@ngrx/store";
import { selectDashboardState } from "@app/store/dashboard/states/dashboard.state";

export const selectHomeAssigner = createSelector(
    selectDashboardState,
    (state) => state?.homeAssignerActiveTile
);
export const selectIsGame = createSelector(
    selectHomeAssigner,
    (state) => state?.isGame
);
export const selectIsUnAssigned = createSelector(
    selectHomeAssigner,
    (state) => state?.isUnassigned
);

export const selectIsDeclined = createSelector(
    selectHomeAssigner,
    (state) => state?.isDeclined
);
export const selectIsPending = createSelector(
    selectHomeAssigner,
    (state) => state?.isPending
);
