import { TStatusPayment } from '@app/dir_group_assignor/payments/modelsForPayment';
import { ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';

// Грант этот IDropItem надо удалить после того как ты в PopupNewAnnouncementGeneralComponent заменишь dropFormCtrl на app-dropdown
export interface IDropItem {
  name?: string; // title
  id?: string; // value
  itemCount?: number;
}

export const errText500 = 'Something went wrong, try again';

export type TCurrentLink_titleCase = 'Current' | 'Past';
export type TCurrentLink = 'current' | 'past';
export type TCurrentLinkDrop = ClassDrop & {
  titleCase: TCurrentLink_titleCase,
  upperCase: TCurrentLink,
};

export const arrCurrentLinkDrop: Array<TCurrentLinkDrop> = [
  { titleCase: 'Current', upperCase: 'current' },
  { titleCase: 'Past', upperCase: 'past' },
];

export const currentLink_current: Extract<TCurrentLink, 'current'> = 'current';
export const currentLink_past: Extract<TCurrentLink, 'past'> = 'past';
export const currentLinkDrop_current: TCurrentLinkDrop = { titleCase: 'Current', upperCase: 'current' };
export const currentLinkDrop_past: TCurrentLinkDrop = { titleCase: 'Past', upperCase: 'past' };

export const arrPaymentStatusLink: Array<{ titleCase: string, upperCase: TStatusPayment | '', count?: number }> = [
  { titleCase: 'All', upperCase: '', count: 0 },
  { titleCase: 'Succeeded', upperCase: 'SUCCEEDED', count: 0 },
  { titleCase: 'Pending', upperCase: 'PENDING', count: 0 },
  { titleCase: 'Incomplete', upperCase: 'INCOMPLETE', count: 0},
  { titleCase: 'Failed', upperCase: 'FAILED', count: 0 }
];
