import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, map } from 'rxjs/operators';
import { MeService } from '@services/me.service';
import { MainService } from '@services/main.service';
import { ClassGroup } from '@models/IGroup';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class CurrentGroupResolver implements Resolve<boolean> {
  constructor(
    private meS: MeService,
    private mainS: MainService,
  ) {
    // console.log('CurrentGroupResolver :', )
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const exist_MeArrCurrentGroup = !!this.meS.meArrCurrentGroup?.length;
    // console.log('exist_MeArrCurrentGroup :', exist_MeArrCurrentGroup)
    const observable$: Observable<Array<ClassGroup>> = exist_MeArrCurrentGroup ? this.meS.meArrCurrentGroup$ : this.mainS.getMeArrCurrentGroup();
    // return this.meS.meCurrentGroup$
    // return this.mainS.getMeArrCurrentGroup()
    return observable$
      .pipe(
        // tap(res => {
        //   console.log('CurrentGroupResolver :', res)
        //   console.log('CurrentGroupResolver meArrCurrentGroup :', this.meS.meArrCurrentGroup)
        //   console.log('CurrentGroupResolver meCurrentGroup :', this.meS.meCurrentGroup)
        // }),
        map(res => true),
        catchError(() => of(false)),
        untilDestroyed(this),
      );
  }

}
