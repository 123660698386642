import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass, TForPagination } from '@classes/Helper-Classes';
import { MainService } from '@services/main.service';
import { ClassSettingsRequest } from '@models/response-and-request';
import { PaymentService } from '@app/dir_group_assignor/payments/payment.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { OtherService } from '@services/other.service';
import { PhotoComponent } from '@components/__svg_img/photo/photo.component';
import { IAssignorFees, IPayer } from '@app/dir_group_assignor/payments/modelsForPayment';
import { GetUserNamePipe } from '@pipes/get-name.pipe';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BtnComponent } from '@components/btn/btn.component';
import { PaginationComponent } from '@components/pagination/pagination.component';
import { ProgressBarComponent } from '@components/progress-bar/progress-bar.component';
import { StrCasePipe } from '@pipes/string/str-case.pipe';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { NothingListComponent } from '@components/nothing-list/nothing-list.component';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { ISortBy } from '@components/sortBy/sortBy';
import {
  tableHeaderOfficialsFees,
} from '@app/dir_group_assignor/payments/components/payments-officials-fees/payments-officials-fees.component';
import { PayoutFormatComponent } from '@app/dir_group_assignor/payments/additional-components/payout-format/payout-format.component';
import { RoundBalancePipe } from '@app/dir_group_assignor/payments/pipes/round-balance.pipe';
import { GetChxSvgNamePipe } from '@components/__svg_img/svg/pipes/get-chx-svg-name.pipe';
import { DropDateRangeComponent } from '@components/__drop_inputs_matSelect/drop-date-range/drop-date-range.component';
import { FiltersService } from '@components/filters/filters.service';
import { DeviceService } from '@services/device.service';
import { ClassFilterDateRange, ClassFilterDrop, IForClassForFilters } from '@components/filters/filters';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import {
  FiltersSelectedIconComponent,
} from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { LinkPageComponent } from '@components/link-page/link-page.component';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MeTableService } from '@components/_table/me-table.service';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';
import { urlSetupCompetition } from '@app/app.module';

@UntilDestroy()
@Component({
  selector: 'app-payer-info',
  standalone: true,
  imports: [CommonModule, HeadTitleComponent, PhotoComponent, GetUserNamePipe, SvgComponent, DropFormCtrlComponent, FormsModule, ReactiveFormsModule, BtnComponent, PaginationComponent, ProgressBarComponent, StrCasePipe, InputCtrlComponent, NothingListComponent, SpinnerComponent, PayoutFormatComponent, RoundBalancePipe, GetChxSvgNamePipe, DropDateRangeComponent, DropdownComponent, FiltersComponent, FiltersSelectedComponent, FiltersSelectedIconComponent, LinkPageComponent, WrapPageDirective, GetTableEmptyPipe],
  templateUrl: './payer-info.component.html',
  styleUrls: ['./payer-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FiltersService, MeTableService, SettingsRequestService], // for-filters=== for-table=== for-settings===
})
export class PayerInfoComponent extends HelperClass implements IForClassForFilters, OnInit {
  readonly tableHeader = tableHeaderOfficialsFees;
  payer$ = new BehaviorSubject<IPayer>({});

  // readonly class_ngContent_btn_for_nothingList = class_ngContent_btn_for_nothingList;
  readonly urlSetupCompetition = urlSetupCompetition;

  constructor(
    private mainS: MainService,
    public payS: PaymentService,
    public otherS: OtherService,
    public route: ActivatedRoute,
    public router: Router,
    public deviceS: DeviceService,
    public filtersS: FiltersService, // for-filters===
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    // this.payS.getLinkPaymentsCamelCase(this.router.url);
    // this.createForm();
    // this.subscribeToForm();
    this.setCompetitionIdFromQueryParams();
    this.subscribeToParams();
  }

  ngOnInit() {
    this.setFilters(); // for-filters===
    this.subscribeToSearch();
  }

  getPayerById(idPayer: string): void {
    this.mainS.getPayerById(idPayer).pipe(untilDestroyed(this)).subscribe(
      (res) => {
        this.payer$.next(res);
        this.cd.detectChanges();
      },
      (err) => {
      },
    );
  }

  setCompetitionIdFromQueryParams(): void {
    this.route.queryParams?.pipe(untilDestroyed(this)).subscribe((res) => {
      // if (res?.competitionId) this.ctrl.competitionId.patchValue(res.competitionId);
      if (res?.competitionId) this.settings.competitions = res.competitionId;
      else this.router.navigate(['/payments/payers']);
    });
  }

  subscribeToParams(): void {
    this.route.params.pipe(untilDestroyed(this)).subscribe((params: Params) => {
      this.getPayerById(params.idPayerForPath);
    });
  }

  getDataForHTML(): void {
    forkJoin([this.payS.getFakeAssignorFees(this.settings)]).pipe(untilDestroyed(this))
      .subscribe((res) => {
        // console.log('getDataForHTML :', res);
      });
  }

  // === FILTERS ==========================================
  settings: ClassSettingsRequest = new ClassSettingsRequest(); // for-filters===
  setFilters(): void {
    const dateRange = new ClassFilterDateRange({ typePeriod: 'current', arrPeriods: this.payS.arrPeriods });
    const officialStatus = new ClassFilterDrop({ typeFilter: 'officialStatus', disabled: true }); // andrei узнать потом какой здесь статус. пока что поставил officialStatus
    this.filtersS.setFilters([dateRange, officialStatus], false, false);
  }

  emitSettingRequest(settings: ClassSettingsRequest): void {
    this.settings = { ...this.settings, ...settings };
    this.getDataForHTML();
  }

  // === SEARCH =======================
  subscribeToSearch(): void {
    this.subscribeToSearchFromHelperClass().pipe(untilDestroyed(this)).subscribe((searchValue: string) => {
      this.settings.search = searchValue;
      // this.getRecentTransactions();
    });
  };

  // === CHECKBOX SELECT ==================================
  selectItem(item: IAssignorFees, event: any): void {
    event.stopPropagation();
    this.selectItemFromHelperClass(item, this.payS.fakeAssignorFees$.getValue());
  }

  selectAll(): void {
    this.payS.fakeAssignorFees$.next(this.selectAllFromHelperClass(this.payS.fakeAssignorFees$.getValue()));
  }

  // === PAGINATION & SORTING =====================
  paginationMethod(type: TForPagination, size?: number, page?: number, sortBy?: ISortBy): void {
    this.paginationMethodHelperClass(type, this.settings, size, page, sortBy);
    this.getDataForHTML();
  }

}

