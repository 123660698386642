import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass } from '@classes/Helper-Classes';
import { MainService } from '@services/main.service';
import { ClassSettingsRequest } from '@models/response-and-request';
import { PaymentService } from '@app/dir_group_assignor/payments/payment.service';
import { ClassCompetition, ClassCompetitionSeason } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { forkJoin } from 'rxjs';
import { OtherService } from '@services/other.service';
import { Router } from '@angular/router';
import { ITestArrForAnalytics, TLinkPaymentsItem } from '@app/dir_group_assignor/payments/modelsForPayment';
import { tap } from 'rxjs/operators';
import { FiltersService } from '@components/filters/filters.service';
import { DeviceService } from '@services/device.service';
import { ClassFilterDateRange, ClassFilterDrop, IForClassForFilters } from '@components/filters/filters';
import { LinkPageComponent } from '@components/link-page/link-page.component';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import {
  FiltersSelectedIconComponent,
} from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import { BtnComponent } from '@components/btn/btn.component';
import { BlockChartComponent } from '@app/dir_group_assignor/payments/additional-components/block-chart/block-chart.component';
import { GetOnlySelectedArrChartsPipe } from '@components/__popup-windows/popup-edit-charts/pipes/get-only-selected-arr-charts.pipe';
import { ProgressBarComponent } from '@components/progress-bar/progress-bar.component';
import { NothingListComponent } from '@components/nothing-list/nothing-list.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { FormsModule } from '@angular/forms';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { ComingSoonMobile } from '@app/dir_group_assignor/dashboard/components/coming-soon-mobile/coming-soon-mobile.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MeTableService } from '@components/_table/me-table.service';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { urlSetupCompetition } from '@app/app.module';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';

@UntilDestroy()
@Component({
  selector: 'app-payments-overview',
  standalone: true,
  imports: [CommonModule, LinkPageComponent, DropdownComponent, FiltersSelectedIconComponent, FiltersComponent, FiltersSelectedComponent, BtnComponent, BlockChartComponent, GetOnlySelectedArrChartsPipe, ProgressBarComponent, NothingListComponent, SvgComponent, FormsModule, WrapPageDirective, ComingSoonMobile, GetTableEmptyPipe],
  templateUrl: './payments-overview.component.html',
  styleUrls: ['./payments-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FiltersService, MeTableService, SettingsRequestService], // for-filters=== for-table=== for-settings===
})
export class PaymentsOverviewComponent extends HelperClass implements IForClassForFilters, OnInit {
  readonly currentLink: TLinkPaymentsItem = { titleCase: 'Overview', upperCase: 'overview' };
  arrForAnalytics: Array<ITestArrForAnalytics> = [
    { title: 'Total Payouts', amount: 0, arrow: true, percent: 21.9, matTooltipQuestion: '', isAdded: true },
    { title: 'Projected Outgoings', amount: 0, arrow: false, percent: 11, matTooltipQuestion: '', isAdded: true },
    { title: 'Notch Pay Balance', amount: 0, arrow: false, percent: 33, matTooltipQuestion: '', isAdded: true },
    { title: 'Assigning Revenue', amount: 0, arrow: true, percent: 21.5, matTooltipQuestion: '', isAdded: true },
    { title: 'Group Assignor Fees', amount: 0, arrow: false, percent: 15.5, matTooltipQuestion: '', isAdded: true },
    { title: 'Sub-Assignor Fees', amount: 0, arrow: false, percent: 22, matTooltipQuestion: '', isAdded: true },
  ];

  // readonly class_ngContent_btn_for_nothingList = class_ngContent_btn_for_nothingList;
  readonly urlSetupCompetition = urlSetupCompetition;

  constructor(
    private mainS: MainService,
    private apiCompetitionS: ApiCompetitionService,
    public payS: PaymentService,
    public otherS: OtherService,
    public router: Router,
    public deviceS: DeviceService,
    public filtersS: FiltersService, // for-filters===
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
  }

  ngOnInit() {
    this.subscribeToArrCompetitions();
    this.setFilters(); // for-filters===
  }

  getDataForHTML(): void {
    if (!this.settings?.competitions) return; // !!! здесь обязательно надо дождаться когда будет competition
    forkJoin(
      [
        this.payS.getFakeAssignorFees(this.settings),
        this.payS.getProjectedOutgoings(this.settings)
          .pipe(
            tap((res) => {
              this.arrForAnalytics = (this.otherS.deepClone(this.arrForAnalytics) as Array<ITestArrForAnalytics>)
                .map((el) => {
                  if (el.title == 'Projected Outgoings') el.amount = (res?.officialFees || 0) + (res?.assignorFees || 0);
                  return el;
                });
            }),
          ),
        this.payS.getBalanceCompetition(this.settings.competitions!)
          .pipe(
            tap((res) => {
              this.arrForAnalytics = (this.otherS.deepClone(this.arrForAnalytics) as Array<ITestArrForAnalytics>)
                .map((el) => {
                  if (el.title == 'Notch Pay Balance') el.amount = res?.current || 0;
                  return el;
                });
            }),
          ),
      ],
    ).pipe(untilDestroyed(this)).subscribe((res) => {
      this.cd.detectChanges();
    });
  }

  // === FILTERS ==========================================
  settings: ClassSettingsRequest = {}; // for-filters===
  setFilters(): void {
    const dateRange = new ClassFilterDateRange({ arrPeriods: this.payS.arrPeriods });
    const competitionFilterDrop = this.payS.competitionFilterDrop$.getValue();
    const seasons = new ClassFilterDrop({ typeFilter: 'seasons', disabled: true });
    const payoutFormat = new ClassFilterDrop({ typeFilter: 'payoutFormat' }); // arrayForDropdown: this.payS.arrPayoutFormatDrop
    this.filtersS.setFilters([dateRange, competitionFilterDrop, seasons, payoutFormat], false, false);
  }

  emitSettingRequest(settings: ClassSettingsRequest): void {
    const isNewSelectedCompetition = this.settings.competitions !== settings.competitions; // выбран новый компетишн в дропдауне
    this.settings = { ...this.settings, ...settings };
    if (isNewSelectedCompetition && settings.competitions) this.getSeasons();
    this.getDataForHTML();
  }

  subscribeToArrCompetitions(): void {
    this.payS.arrCompetition$.pipe(untilDestroyed(this)).subscribe((res) => {
      const competitionFilterDrop = this.payS.competitionFilterDrop$.getValue();
      const seasons = new ClassFilterDrop({ typeFilter: 'seasons', disabled: false });
      this.filtersS.updateAllFilters([competitionFilterDrop, seasons]);
    });
  }

  getSeasons(): void {
    const competitionId = this.settings.competitions!;
    this.apiCompetitionS.methodCompetitionSeasons({id: competitionId}, 'get').toPromise()
      .then((res?: Pick<ClassCompetition, 'seasons'>) => {
        const seasons = new ClassFilterDrop({
          typeFilter: 'seasons',
          disabled: !res?.seasons?.length,
          arrayForDropdown: res?.seasons || [],
        });
        this.filtersS.updateFilter('seasons', seasons);
      })
      .catch(err => {
      });
  }

  // === POPUP ================================================
  openPopupEditCharts(): void {
    this.payS.openPopupEditCharts(this.arrForAnalytics).then((res) => {
      if (!res) return;
      this.arrForAnalytics = this.otherS.deepClone(res);
      this.cd.detectChanges();
    });
  }

}
