import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { CommonModule } from '@angular/common';
import { ISortingOptions, SortingOptions, TypeSortTable } from './sortBy';
import { ClassSettingsRequest } from '@models/response-and-request';
import { MatMenuModule } from '@angular/material/menu';
import { removeEmptyKeysFromObject } from '@models/other';
import { MeTableService } from '@components/_table/me-table.service';
import { DeviceService } from '@services/device.service';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { ForFilterItemDirective } from '@components/filters/directives/for-filter-item.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GetWidthMatMenuPipe } from '@pipes/get-width-mat-menu.pipe';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { GetStateInputPipe } from '@pipes/get-state-input.pipe';
import { GetStylesPipe } from '@pipes/css/get-styles.pipe';
import { GetSvgArrowForDropdownPipe } from '@pipes/svg/get-svg-arrow-for-dropdown.pipe';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'sortBy',
  templateUrl: './sort-by.component.html',
  styleUrls: ['./sort-by.component.scss'],
  standalone: true,
  imports: [CommonModule, MatMenuModule, SvgComponent, DropdownComponent, ForFilterItemDirective, ReactiveFormsModule, FormsModule, GetWidthMatMenuPipe, GetStateInputPipe, GetStylesPipe, GetSvgArrowForDropdownPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortByComponent<T> implements OnInit {
  sortOptions: Array<ISortingOptions> = [];
  // readonly SortingOptions = SortingOptions;
  // current_sortOption?: ISortingOptions;
  // current_typeSortTable?: TypeSortTable;
  sortValueForSendToServer?: Array<TypeSortTable>;
  menuOpen_forMobile = false;

  // @Output() emitSettings = new EventEmitter<ClassSettingsRequest>(); // !!! испольтзуется только там где нет динамических фильтров в url

  constructor(
    public deviceS: DeviceService,
    public meTableS: MeTableService<T>,
    private settingsRequestS: SettingsRequestService, // for-settings===
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.setSortOptions();
    this.subscribeToSettings();
    // this.check_currentTypeSort();
  }

  subscribeToSettings(): void {
    this.settingsRequestS.settingsSub$.pipe(untilDestroyed(this)).subscribe((res: ClassSettingsRequest) => {
      if (!res?.sort) return;
      if (typeof res.sort === 'string') {
        this.sortValueForSendToServer = [res.sort] as Array<TypeSortTable>;
      } else {
        this.sortValueForSendToServer = res.sort;
      }
      this.check_currentTypeSort();
      // console.log('sort :', res.sort);
      // console.log('sortValueForSendToServer :', this.sortValueForSendToServer);
    });
  }


  setSortOptions(): void {
    this.sortOptions = SortingOptions.filter(el => this.meTableS.arrTypeSorting.includes(el.typeSorting!));
    // console.log('sortOptions :', this.sortOptions)
  }

  check_currentTypeSort(): void {
    // console.log('meTableS.currentTypeSort :', this.meTableS.currentTypeSort)
    if (this.meTableS.currentTypeSort) {
      const sortValuesFromUrl: Array<TypeSortTable> = this.meTableS.currentTypeSort;
      this.sortOptions.forEach((el) => {
        el.children?.forEach((item) => {
          // !!! когда поступило значение для sort из url , то нужно выделить зеленым цветом в выпадающем списке
          item.isSelected = item.sortValueForSendToServer?.toString() === sortValuesFromUrl?.toString();
          // if (item.isSelected) this.sortValueForSendToServer = item.sortValueForSendToServer;
        });
      });
    }
    this.cd.detectChanges();
  }

  openSortOptions(element: HTMLElement, option: ISortingOptions): void {
    if (option.children && option.children.length > 0) {
      element.classList.toggle('active');
    }
  }

  selectOption(option: Omit<ISortingOptions, 'field'>, parentOption?: ISortingOptions): void {
    this.unSelectChildOptions(parentOption);
    option.isSelected = !option.isSelected;
    // !!! emitSettingsMethod испольтзуется только там где нет динамических фильтров в url
    this.emitSettingsMethod(removeEmptyKeysFromObject({ sort: option?.sortValueForSendToServer, typeEmitSetting: 'sorting' }));
    this.check_currentTypeSort();
    // this.cd.detectChanges();
  }

  unSelectChildOptions(option?: ISortingOptions): void {
    if (!option) return;
    this.sortOptions.forEach(el => {
      if (el.children && el.children.length > 0) {
        el.children.forEach(elem => elem.isSelected = false);
      }
    });
  }

  emitSettingsMethod(settings: ClassSettingsRequest): void {
    const { sort } = settings;
    this.settingsRequestS.updateSettings({ sort, typeEmitSetting: 'sorting' }, 'sorting');
  }

}

