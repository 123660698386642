import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl } from '@angular/forms';
import { MainService } from '@services/main.service';
import { arrNameCtrlForPassword, OtherService } from '@services/other.service';
import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { CommonModule } from '@angular/common';
import { GetStateInputPipe } from '@pipes/get-state-input.pipe';
import { ErrorComponent } from '@components/__info_text_message_error_warning/error/error.component';
import { TColor } from '@models/ICssStyles';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { StrCasePipe } from '@pipes/string/str-case.pipe';
import { HelperClass } from '@classes/Helper-Classes';
import { DisableAutofillDirective } from '@directives/diable-autofill.directive';
import { GetStylesPipe } from '@pipes/css/get-styles.pipe';
import { TSvgName } from '@components/__svg_img/svg/forSvg';
import { PluralizePipe } from '@pipes/pluralize.pipe';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UtilsService } from '@services/utils.service';

@UntilDestroy()
@Component({
  selector: 'inputCtrl',
  templateUrl: './inputCtrl.component.html',
  styleUrls: ['./inputCtrl.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, GetStateInputPipe, ErrorComponent, MatFormFieldModule, MatIconModule, MatInputModule,
    SvgComponent, StrCasePipe, DisableAutofillDirective, GetStylesPipe, NgxMaskDirective, PluralizePipe, MatTooltipModule],
  providers: [
    provideNgxMask(),
    // NgxMaskModule,
    //   { // Circular dependency in DI detected
    //     provide: NG_VALUE_ACCESSOR,
    //     useExisting: forwardRef(() => InputCtrlComponent),
    //     multi: true,
    //   },
    //   {
    //     provide: NG_ASYNC_VALIDATORS,
    //     useExisting: forwardRef(() => InputCtrlComponent),
    //     multi: true,
    //   },
  ],
})
// !!! этот компонент надо разбивать, он пытается слишком много на себя работы взять, лучше сделать по отдельности 2 компонентика с общим сервисом для работы
export class InputCtrlComponent extends HelperClass implements ControlValueAccessor, OnChanges, OnInit, AfterViewInit {
  @ViewChild('matFormFieldRef') matFormFieldRef?: MatFormField;
  @ViewChild('inputRef') inputRef?: ElementRef;
  @ViewChild('errorFromNgContent') errorFromNgContent?: any;

  @Input() type: 'input' | 'textarea' = 'input';
  @Input() height: string | number = '44'; // вернуть если приходится для textarea передавать

  @Input() fontSize: string | number = 16;

  @Input() placeholder = '';
  @Input() strCase: string | null = ''; // for lodash capitalize
  @Input() isTextCenter = false; // если надо сделать текст внутри инпута по центру, то передать true
  // !!! Если formControlName совпадает с nameField, тогда не надо передавать nameField
  @Input() nameField = ''; // для вывода ошибки и для установки maxLength // если не передать то берется их this.ngControl?.name
  @Input() textRequired = 'This field is required.'; // если поле обязательное, передать сюда текст или можно оставить по умолчанию
  @Input() showErrText = false; // если надо показывать текст ошибку то передать сюда true
  @Input() showLeftCharLength = false; // если надо показывать сколько символов осталось заполнить
  // !!! если использовать [(ngModel)]='' то надо также ставить [ngModelOptions]='{standalone: true}'
  // !!! [required]='true' можно вешать прямо на <inputCtrl>, сюда передавать required не надо. И так норм работает
  @Input() touch = false; // touch используется в ngOnChanges, если передавал [(ngModel)]='' вместо formControlName

  @Input() bcg: TColor | 'transparent' = 'white'; // background // TColor = 'blueDark' | 'grey' | 'grey_1' | 'white' | 'blue' | 'green' | 'red';

  // !!! value в родительской форме в formControlName и в [(ngModel)] и без этого changeVal.emit() норм меняются
  // !!! но если надо также обновить данные где-то ещё (например в сервисе), то можно использовать этот changeVal.emit(value)
  @Output() changeVal = new EventEmitter<string>();

  @Input() maxLength!: number; // Character count => show error if more characters entered
  @Input() fixedHeightWithScroll: boolean = false; // disable auto size and enable scroll for text area

  @Input() isNumber = false;
  @Input() minNumber?: number; // !!! если нужно запретить вводить цифру МЕНЬШЕ указанной в этой переменной
  @Input() maxNumber?: number; // !!! если нужно запретить вводить цифру БОЛЬШЕ указанной в этой переменной
  @Input() allowFirstZero = false; // for isNumber. разрешить ставить первую цифру 0
  @Input() defaultAlwaysZero = false; // если надо чтобы всегда был 0, чтобы 0 не стирался (например в репорте Final Score)
  @Input() showDollar = false; // устанавливается для nameField == "fee" || "amount"
  @Input() showPercent = false;
  @Input() viewPassword = false; // только для Password
  // arrNameCtrlForPassword = ['password', 'confirmPassword', 'confirmpassword', 'currentPassword', 'currentpassword'];
  readonly arrNameCtrlForPassword = arrNameCtrlForPassword;
  @Output() viewPasswordEmit = new EventEmitter<boolean>(); // только для Password

  // !!! иконки для инпута
  @Input() matIconPrefix?: string; // ангуляровские иконки
  @Input() matIconSuffix?: string; // ангуляровские иконки
  @Input() svgPrefix?: TSvgName; // передавать имя файла svg (из assets)
  @Input() svgSuffix?: TSvgName; // передавать имя файла svg (из assets)
  @Input() widthSvgPrefix?: string | number;
  @Input() widthSvgSuffix?: string | number;

  @Input() matTooltip = '';
  // @Input() matTooltip_for_svgPrefix = '';

  @Input() w: string | number = ''; // width // если надо ширину задать

  @Input() mask?: string; // ngx-mask
  @Input() patterns?: any;
  @Input() specialCharacters?: any; // для чего это

  @Input() isFocus = false; // если true передал значит надо фокус сделать на этом поле
  // isFocusOut = false; // пользователь покинул поле

  @Output() svgRightEmit = new EventEmitter();

  @Output() enterEmit = new EventEmitter(); // нажатие на кнопку Enter
  @Output() focusOutEmit = new EventEmitter(); // покинул поле
  @Output() enterAndFocusOutEmit = new EventEmitter(); // покинул поле

  @Input() cssClass: '' | 'filled' | 'disabled' | 'error' | 'empty' = ''; // если надо принудительно css class задать

  @Input() addDollarAndZero_in_methodWriteValue = false; // !!! for page payScales.

  private onChange!: (value: string) => void;
  private onTouched!: () => void;

  @Input() forTest?: any;

  constructor(
    public mainS: MainService,
    public otherS: OtherService,
    public ngControl: NgControl,
    public renderer: Renderer2,
    private focusMonitor: FocusMonitor,
    private r: Renderer2,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  svgRightClick(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.svgRightEmit.emit();
  }

  @HostListener(`keydown.enter`, ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.enterEmit.emit(this.value);
    this.enterAndFocusOutEmit.emit(this.value);
  }

  private lastValidValue: string = '';
  @HostListener('focusin', ['$event.target']) focusin(target: any): void {
    // this.isFocusOut = false;
    if (!target?.value || !this.inputRef) return;
    this.lastValidValue = target.value;
    // !!! если например 11.00 то при нажатии на инпут будут нули и точки стираться => 11
    if (target?.value?.toString()?.includes('.')) {
      if (Number.isInteger(+(target?.value?.replace('$', '')))) {
        this.inputRef.nativeElement.value = Math.floor(target?.value?.replace('$', ''))?.toString();
      }
    }


    if (this.showDollar && this.inputRef?.nativeElement && this.isTextCenter) {
      this.inputRef.nativeElement.value = this.inputRef.nativeElement.value?.replace('$', '');
    }
    if (this.showPercent && this.inputRef?.nativeElement) {
      this.inputRef.nativeElement.value = this.inputRef.nativeElement.value?.replace('%', '');
    }
    // console.log('target?.value 222:', typeof target?.value, target?.value)
    // console.log('inputRef.nativeElement.value 222:', typeof this.inputRef?.nativeElement?.value, this.inputRef?.nativeElement?.value)
  }

  @HostListener('focusout', ['$event.target']) focusout(target: any): void {
    // this.isFocusOut = true;
    this.addZeros();
    this.addDollar();
    // if (this.showDollar && this.inputRef?.nativeElement?.value) {
    //   this.inputRef.nativeElement.value = '$' + this.inputRef.nativeElement.value;
    // }
    if (this.showPercent && this.inputRef?.nativeElement?.value) {
      this.inputRef.nativeElement.value = this.inputRef.nativeElement.value + '%';
    }
    this.focusOutEmit.emit(this.value);
    this.enterAndFocusOutEmit.emit(this.value);
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (this.forTest ) {
    //   console.log('ngOnChanges :', this.forTest, changes)
    // }
    if (changes?.nameField?.currentValue) {
      this.isNumber = ['zipcode', 'duration', 'fee', 'amount', 'officialfee', 'phone'].includes(this.nameField?.toLowerCase());
      if (this.nameField.includes('number')) this.isNumber = true;
    }

    if (changes?.touch?.currentValue) this.touched = true; // чтобы из родит.компонент получить и отреагировать

    if (changes?.isFocus?.currentValue) setTimeout(() => this.inputRef?.nativeElement?.focus());

    // if (changes?.showDollar?.currentValue) {
    //   setTimeout(() => {
    //     if (this.showDollar && this.inputRef?.nativeElement?.value) this.inputRef.nativeElement.value = '$' + this.inputRef.nativeElement.value;
    //   }, 111);
    // }
    if (changes?.showPercent?.currentValue) {
      setTimeout(() => {
        if (this.showPercent && this.inputRef?.nativeElement?.value) this.inputRef.nativeElement.value = this.inputRef.nativeElement.value + '%';
      }, 111);
    }

    if (changes?.cssClass?.currentValue) {
      setTimeout(() => {
        if (this.matFormFieldRef && this.cssClass !== 'disabled') {
          this.r.removeClass(this.matFormFieldRef?._elementRef?.nativeElement, 'o-matForm--disabled');
          this.r.removeClass(this.matFormFieldRef?._elementRef?.nativeElement, 'o-matForm--error');
          this.r.removeClass(this.matFormFieldRef?._elementRef?.nativeElement, 'o-matForm--filled');
          this.r.removeClass(this.matFormFieldRef?._elementRef?.nativeElement, 'o-matForm--empty');
          this.r.addClass(this.matFormFieldRef?._elementRef?.nativeElement, `o-matForm--${this.cssClass}`);
          this.cd.detectChanges();
        }
      }, 111);
    }

    // if (changes?.w?.currentValue) {
    //   console.log('currentValue :', changes?.w?.currentValue);
    //   console.log('previousValue :', changes?.w?.previousValue);
    // this.cd.detectChanges();
    // this.cd.markForCheck();
    // }

    // if (changes?.maxLength?.currentValue) {
    //   console.log('maxLength :', this.maxLength)
    // }
    this.checkLength();
  }

  checkLength(): void {
    if (this.value?.length > this.maxLength) {
      this.ngControl.control?.setErrors({ 'maxlength': true });
    } else {
      if (this.ngControl?.control?.errors) delete this.ngControl?.control?.errors['maxlength'];
    }
  }

  ngOnInit() {
    if (!this.nameField && this.ngControl?.name && typeof this.ngControl?.name == 'string') {
      // this.nameField = this.otherS.toTitleCase(this.ngControl.name?.toString());
      this.nameField = UtilsService.strCase(this.ngControl.name?.toString(), 'camel');
    }
    // !!! https://notch.atlassian.net/browse/NOT30-392 => “Secondaryemail is required.” should be “Secondary email required”
    // if (this.nameField) this.textRequired = this.otherS.toTitleCase(this.nameField) + ' is required.';
    if (this.nameField) {
      const result = UtilsService.capitalizeFirstLetterFromCamelCase(this.nameField);
      this.textRequired = this.otherS.toTitleCase(result) + ' required';
    }
    this.setMaxlength();
  }

  ngAfterViewInit() {
    if (this.ngControl?.control) {
      setTimeout(() => this.setDisabledState(this.ngControl!.control!.disabled));
      this.subscribeToFocused();
      setTimeout(() => {
        this.addZeros();
        this.addDollar();
      });
      this.cd.detectChanges();
    }
  }

  input(target: any): void {
    this.value = target.value;
  }

  modelChange(event: any): void {
    this.value = event;
  }

  writeValue(value: any): void {
    // if (this.forTest) {
    //   console.log('writeValue :', this.forTest, typeof value, value, ' active:', this.active);
    // }
    if (this.addDollarAndZero_in_methodWriteValue && !this.active && this.showDollar) {
      // console.log('writeValue 111:', this.forTest, typeof value, value, ' active:', this.active);
      setTimeout(() => {
        this.addZeros();
        this.addDollar();
        // console.log('writeValue 222:', this.forTest, typeof this.value, this.value, ' active:', this.active);
        this.cd.detectChanges();
      });
    }
    this.checkLength();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.inputRef?.nativeElement) this.disabled = isDisabled;
    this.cd.detectChanges();
  }

  private setMaxlength(): void {
    if (this.maxLength) return; // если передал самостоятельно в @Input() maxLength!: number;
    if (['zipcode', 'duration'].includes(this.nameField?.toLowerCase())) {
      this.maxLength = +this.mainS.maxlengthZipcode_6;
    } else if (this.nameField == 'Notes' || this.type == 'textarea') {
      this.maxLength = +this.mainS.maxlengthTextarea;
    } else {
      this.maxLength = +this.mainS.maxlengthInput;
    }
  }

  subscribeToFocused(): void {
    this.focusMonitor.monitor(this.inputRef?.nativeElement, true).pipe(untilDestroyed(this))
      .subscribe((origin: FocusOrigin) => this.active = !!origin);
  }

  addZeros(): void {
    if (this.showDollar && this.inputRef) {
      const numericValue = this.value ? parseFloat(this.value.toString().replace(/[^0-9.-]+/g, '')) : NaN;
      console.log(numericValue)
      if (isNaN(parseFloat(this.value))) {
        this.inputRef.nativeElement.value = this.lastValidValue.replace(/^\$/, '');;
        // If value is valid, apply amountAddZero directly
      
      } else {
        this.inputRef.nativeElement.value = this.amountAddZero(this.value);
      }
      this.cd.detectChanges();
    }
  }

  // this.inputRef.nativeElement.value = this.lastValidValue.replace(/^\$/, '');;

  addDollar(): void {
    if (this.showDollar && this.inputRef?.nativeElement?.value) {
      this.inputRef.nativeElement.value = '$' + this.inputRef.nativeElement.value;
      // if (this.forTest) {
      //   console.log('addZeros inputRef.nativeElement.value :', typeof this.inputRef?.nativeElement?.value, this.inputRef?.nativeElement?.value)
      // }
      this.cd.detectChanges();
    }
  }

  // === GETTERS & SETTERS =======================
  set value(value: string) {
    // if (this.forTest) {
    //   console.log('SET :', this.forTest, '  value=', value, '  this.value=', this.value, ' nativeElement=', this.inputRef?.nativeElement.value,
    //     '  ngControl=', this.ngControl?.value)
    // }
    if (this.inputRef?.nativeElement) {
      let valueStr: string = value?.toString(); // Не удалять. Сюда может number прийти

      // Enforce 12-digit limit specifically for 'amount'
      if (this.nameField.toLowerCase() === 'amount') {
        valueStr = valueStr.replace(/\D/g, '');
        valueStr = valueStr.slice(0, 12); // Limit to 12 digits
      }

      if (valueStr[0] === ' ') {
        if (this.forTest) {
          console.log('valueStr :', valueStr);
        }
      }
      if (valueStr[0] === ' ') this.inputRef.nativeElement.value = ''; // запрет пробелов в начале строки

      // if (this.forTest) {
      //   console.log('inputRef.nativeElement.value :', typeof this.inputRef?.nativeElement?.value, this.inputRef?.nativeElement?.value)
      // }
      if (this.isNumber) {
        valueStr = valueStr?.replace(/\s+/gi, ''); // запрет пробелов
        valueStr = valueStr?.replace(/[^0-9\.]/g, '').replace(/^[\D]/, ''); // запрет вводить буквы // ломается когда fee
        if (!this.allowFirstZero) { // убирать ноль в начале только там где нужно
          if (valueStr?.length > 1 && valueStr[0] === '0' && valueStr[1] !== '.') valueStr = valueStr?.slice(1); // убрать первый 0, если следующий символ не точка
        }
        valueStr = this.otherS.banTwoDots(valueStr); // // запретить вторую точку в строке
        if (valueStr[0] === '.') valueStr = '0.';
        this.inputRef.nativeElement.value = valueStr;
        if (this.defaultAlwaysZero && !valueStr) this.inputRef.nativeElement.value = 0; // если надо чтобы всегда был 0, чтобы 0 не стирался (например в репорте Final Score)
        if (this.maxNumber) {
          if (this.inputRef.nativeElement.value > this.maxNumber) this.inputRef.nativeElement.value = this.maxNumber;
        }
        if (this.minNumber) {
          if (this.inputRef.nativeElement.value < this.minNumber) this.inputRef.nativeElement.value = this.minNumber;
        }
      }
      // if (this.forTest) {
      //   console.log('inputRef.nativeElement.value :', typeof this.inputRef?.nativeElement?.value, this.inputRef?.nativeElement?.value)
      // }

      if (this.nameField.toLowerCase() == 'email' || this.nameField.toLowerCase() == 'password' || this.nameField == 'confirmPassword') {
        valueStr = valueStr?.replace(/\s+/gi, ''); // запрет пробелов
        this.inputRef.nativeElement.value = valueStr;
      }

      if (this.nameField.toLowerCase() == 'email') {
        this.inputRef.nativeElement.value = valueStr.toLowerCase();
      }

      // !!! this.inputRef?.nativeElement?.value !== '0.00' => при добавлении нового gameOfficial чтобы this.onTouched() не сработал
      if (typeof this.inputRef?.nativeElement?.value == 'string' && this.inputRef?.nativeElement?.value !== '0.00') {
        this.changeVal.emit(this.inputRef.nativeElement.value);
        this.ngControl.control?.setValue(this.inputRef.nativeElement.value); // this.ngControl.control?.patchValue(this.inputRef.nativeElement.value);
        this.onChange(this.inputRef.nativeElement.value); // чтобы в родит.компонент передать
        this.onTouched(); // чтобы в родит.компонент передать
      }
    }
    this.cd.detectChanges();
  }

  get value(): string {
    return this.ngControl.control?.value;
  }

  get validatorError(): boolean {
    if (!this.touched) return false;
    return !!this.ngControl.control?.hasError('validatorError');
  }

  get errMinlength(): boolean {
    if (!this.touched) return false;
    return !!this.ngControl.control?.hasError('minlength');
  }

  get errMaxlength(): boolean {
    if (!this.touched) return false;
    this.checkLength();
    return !!this.ngControl.control?.hasError('maxlength');
  }

  get errRequired(): boolean {
    // if (!this.touched || !this.isFocusOut) return false;
    if (!this.touched) return false;
    return !!this.ngControl.control?.hasError('required');
  }

  get errFeeInvalid(): boolean {
    return false;
  }

  get error(): boolean {
    if (!this.touched) return false;
    return (this.validatorError || this.errRequired || this.errMaxlength || this.errMinlength || this.errorFromNgContent?.nativeElement?.children?.length) || this.errFeeInvalid;
  }

  get valid(): boolean {
    return !!this.ngControl.control?.valid;
  }

  get touched(): boolean {
    return !!this.ngControl.control?.touched;
  }

  set touched(touched: boolean) {
    this.onTouched(); // чтобы в родит.компонент передать
  }

  get untouched(): boolean {
    return !!this.ngControl.control?.untouched;
  }

  set active(value: boolean) {
    this.renderer.setProperty(this.inputRef?.nativeElement, 'active', value);
  }

  get active(): boolean {
    return !!this.inputRef?.nativeElement?.active;
  }

  set disabled(value: boolean) {
    this.renderer.setProperty(this.inputRef?.nativeElement, 'disabled', value);
  }

  get disabled(): boolean {
    return !!this.inputRef?.nativeElement?.disabled;
  }

}
