import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CustomDatesService } from '@classes/CustomDates';
import { OtherService } from '@services/other.service';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { GetWidthMatMenuPipe } from '@pipes/get-width-mat-menu.pipe';
import { DateTimeComponent } from '@components/date-time/date-time/date-time.component';
import { PopupService } from '@services/popup.service';
import { DateRangeDialogComponent } from '@app/dir_officials/page-planner/components/date-range-dialog/date-range-dialog.component';
import { TCurrentLink } from '@classes/dictionary';

// export const arrTChoosePeriod: Array<TChoosePeriod> = [
//   'All' , 'All time' , 'Yesterday' , 'Today'
//   , 'Last 7 days' , 'Last 2 weeks' , 'Last 4 Weeks' , 'Last 4 weeks' , 'Last month' , 'Last 3 months' , 'Last 12 months'
//   , 'Next 7 days' , 'Next 2 weeks' , 'Next 4 weeks' , 'Next month' , 'Next 3 months' , 'Next 12 months'
//   , 'Custom date range' , 'Custom Date Range'
//   , 'Last Month' , 'Last 3 Months' , 'Last 12 Months'
//   , 'Last 30 days' , 'Month to Date' , 'Quarter to Date' , 'Year to Date' , 'Tomorrow' , 'Next 14 Days' , 'Next 30 Days' // for payments
// ]

export type TDefaultName_choosePeriod = 'All time';
export const defaultName_choosePeriod: TDefaultName_choosePeriod = 'All time';

export type TCustomDateRange = 'Custom Date Range';
export const customDateRange: TCustomDateRange = 'Custom Date Range';

// 'All' | 'All time'
// | 'Custom date range' | 'Custom Date Range'
export type TChoosePeriod = TDefaultName_choosePeriod | TCustomDateRange | 'Yesterday' | 'Today'
  | 'Last 7 days' | 'Last 2 weeks' | 'Last 4 Weeks' | 'Last 4 weeks' | 'Last month' | 'Last 3 months' | 'Last 12 months'
  | 'Next 7 days' | 'Next 2 weeks' | 'Next 4 weeks' | 'Next month' | 'Next 3 months' | 'Next 12 months'
  | 'Last Month' | 'Last 3 Months' | 'Last 12 Months'
  | 'Last 30 days' | 'Month to Date' | 'Quarter to Date' | 'Year to Date' | 'Tomorrow' | 'Next 14 Days' | 'Next 30 Days'; // for payments

export const arrPeriodsForCurrent: Array<TChoosePeriod> = [defaultName_choosePeriod, 'Today', 'Next 7 days', 'Next 2 weeks', 'Next month', 'Next 3 months', 'Next 12 months', customDateRange];
export const arrPeriodsForPast: Array<TChoosePeriod> = [defaultName_choosePeriod, 'Yesterday', 'Last 7 days', 'Last 2 weeks', 'Last month', 'Last 3 months', 'Last 12 months', customDateRange];

export interface IDatePeriod {
  from?: string | Date;
  to?: string | Date;
}

// export interface IResponseDropDateRange {
//   datePeriod?: IDatePeriod;
//   period?: TChoosePeriod;
//   period_default?: TChoosePeriod; // !!! если нужно установить значение по умолчанию => например при resetFilters()
//   fromTo_formatted?: string;
// }

@Component({
  selector: 'dropDateRange',
  templateUrl: './drop-date-range.component.html',
  styleUrls: ['./drop-date-range.component.scss'],
  standalone: true,
  imports: [CommonModule, MatMenuModule, SvgComponent, GetWidthMatMenuPipe, DateTimeComponent],
  // changeDetection: ChangeDetectionStrategy.OnPush, // !!! не ставить здесь. После go to assign со страницы games не срабатывает
})
export class DropDateRangeComponent implements OnChanges {
  @Input() format: 'YYYY-MM-DDTHH:mm:ss' | 'YYYY-MM-DD' = 'YYYY-MM-DDTHH:mm:ss';
  @Input() period?: TChoosePeriod; // !!! если нужно поставить дату при загрузке страницы
  @Input() placeholder: string = 'View Date Range';

  @Input() typeView: 'svgCalendar' | 'drop' = 'drop'; // !!! 2 вида отображения - с иконкой календаря и в виде дропдауна

  // !!! === 1 вариант для 'current' & 'past'
  @Input() typePeriod?: TCurrentLink; // 'current' | 'past'

  // !!! === 2 вариант => если передал arrPeriods, то надо использовать именно этот массив, а не дефолтные (например в пайментах)
  // этот 2 вариант для таких массивов , которые отличаются от arrPeriodsForCurrent & arrPeriodsForPast, потому что на некоторых страницах другой список в выпадающем меню
  @Input() arrPeriods?: Array<TChoosePeriod>;
  arrPeriodsForCurrent = arrPeriodsForCurrent;
  arrPeriodsForPast = arrPeriodsForPast;

  @Output() changePeriod = new EventEmitter<IDatePeriod>(); // удалить потом. Нужно использовать emit()
  @Output() choosePeriodString = new EventEmitter<TChoosePeriod>(); // удалить потом. Нужно использовать emit()
  // @Output() emit = new EventEmitter<IResponseDropDateRange>();

  // settings: IDatePeriod = {};
  datePeriod: IDatePeriod = {};

  // value_customDateRange: string = '';
  fromTo_formatted: string = ''; // ММ/DD/YYYY

  isOpenMenu = false;

  constructor(
    private datesS: CustomDatesService,
    private otherS: OtherService,
    public elRef: ElementRef,
    public popupS: PopupService,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  choosePeriod(period?: TChoosePeriod): void {
    if (period === customDateRange) {
      this.openPopupDateRange();
      return;
    }
    setTimeout(() => { // !!! setTimeout NO DELETE. Когда вызываю this.dropDateRangeRef?.choosePeriod() то ngOnChanges отрабатываетт после этого метода. И плэтому typePeriod не успевает измениться. (Проверить переключение current/past)
      this.fromTo_formatted = '';
      this.period = period;
      const todayFormatDate = this.datesS.todayMidnightFormatDate;

      // === FOR arrPeriods =============================================
      if (this.arrPeriods?.length) {
        this.datePeriod.to = todayFormatDate;
        if (period === 'Today') this.datePeriod.from = this.datesS.tomorrowMidnightFormatDate;
      }

      // === FOR CURRENT =============================================
      if (this.typePeriod == 'current') {
        this.datePeriod.from = todayFormatDate;
        if (period === defaultName_choosePeriod || !period) this.datePeriod.to = ''; // !!! для пустого значения тоже при перключении вкладок и при загрузке страницы
        if (period === 'Today') this.datePeriod.to = this.datesS.tomorrowMidnightFormatDate;
      }

      // === FOR PAST =============================================
      if (this.typePeriod == 'past') {
        this.datePeriod.to = todayFormatDate;
        if (period === defaultName_choosePeriod || !period) this.datePeriod.from = ''; // !!! для пустого значения тоже при перключении вкладок и при загрузке страницы
      }

      this.getFormattedDate(period);

      if (this.datePeriod.from) {
        this.datePeriod.from = this.otherS.convertDate(this.datePeriod.from as string, 'User', 'Utc');
        this.datePeriod.from = this.datesS.formatDate(this.format, this.datePeriod.from);
      }
      if (this.datePeriod.to) {
        this.datePeriod.to = this.otherS.convertDate(this.datePeriod.to as string, 'User', 'Utc');
        this.datePeriod.to = this.datesS.formatDate(this.format, this.datePeriod.to);
      }
      // console.log('choosePeriod :', this.datePeriod)
      this.changePeriod.emit(this.datePeriod);
      this.choosePeriodString.emit(this.period);
      this.cd.detectChanges();
    });
  }

  getFormattedDate(period?: TChoosePeriod): void {
    // === FOR CURRENT =============================================
    if (period === 'Next 7 days') this.datePeriod.to = this.datesS.getFormatDatePlusFewDays(this.format, 7);
    if (period === 'Next 14 Days') this.datePeriod.to = this.datesS.getFormatDatePlusFewDays(this.format, 14);
    if (period === 'Next 30 Days') this.datePeriod.to = this.datesS.getFormatDatePlusFewDays(this.format, 30);
    if (period === 'Next 2 weeks') this.datePeriod.to = this.datesS.getFormatDatePlusFewDays(this.format, 14);
    if (period === 'Next 4 weeks') this.datePeriod.to = this.datesS.getFormatDatePlusFewDays(this.format, 28);
    if (period === 'Next month') this.datePeriod.to = this.datesS.getFormatDatePlusFewDays(this.format, 30);
    if (period === 'Next 3 months') this.datePeriod.to = this.datesS.getFormatDatePlusFewDays(this.format, 90);
    if (period === 'Next 12 months') this.datePeriod.to = this.datesS.getFormatDatePlusFewDays(this.format, 365);

    // === FOR PAST =============================================
    if (period === 'Yesterday') {
      this.datePeriod.from = this.datesS.formatDate(this.format, this.datesS.yesterdayMidnightFormatDate);
      this.datePeriod.to = this.datesS.formatDate(this.format, this.datesS.todayMidnightFormatDate);
    }
    if (period === 'Last 7 days') this.datePeriod.from = this.datesS.getFormatDatePlusFewDays(this.format, -7);
    if (period === 'Last 30 days') this.datePeriod.from = this.datesS.getFormatDatePlusFewDays(this.format, -30);
    if (period === 'Last 2 weeks') this.datePeriod.from = this.datesS.getFormatDatePlusFewDays(this.format, -14);
    if (period === 'Last 4 weeks' || period === 'Last 4 Weeks') this.datePeriod.from = this.datesS.getFormatDatePlusFewDays(this.format, -28);
    if (period === 'Last month' || period === 'Last Month') this.datePeriod.from = this.datesS.getFormatDatePlusFewDays(this.format, -30);
    if (period === 'Last 3 months' || period === 'Last 3 Months') this.datePeriod.from = this.datesS.getFormatDatePlusFewDays(this.format, -90);
    if (period === 'Last 12 months' || period === 'Last 12 Months') this.datePeriod.from = this.datesS.getFormatDatePlusFewDays(this.format, -365);
    if (period === 'Last 12 months') this.datePeriod.from = this.datesS.getFormatDatePlusFewDays(this.format, -365);

    // === FOR arrPeriods =============================================
    if (period === 'Month to Date') this.datePeriod.from = this.datesS.formatDate(this.format, this.datesS.firstDayMonth);
    if (period === 'Quarter to Date') this.datePeriod.from = this.datesS.formatDate(this.format, this.datesS.firstDayQuarterMonth);
    if (period === 'Year to Date') this.datePeriod.from = this.datesS.formatDate(this.format, this.datesS.dayYearAgo);
    if (period === 'Tomorrow') {
      this.datePeriod.from = this.datesS.formatDate(this.format, this.datesS.tomorrowDate);
      this.datePeriod.to = this.datesS.formatDate(this.format, this.datesS.afterTomorrowMidnightFormatDate);
    }
  }

  openPopupDateRange(): void {
    this.popupS.open(DateRangeDialogComponent, { width: '330px', currentLink: this.typePeriod })
      .then((resDatePeriod: IDatePeriod | null) => {
        if (resDatePeriod) {
          this.period = customDateRange;
          const onlyOneDate: boolean = !resDatePeriod.from || !resDatePeriod.to; // выбрана только одна дата
          this.datePeriod = {};
          const datePeriodForEmit: IDatePeriod = {}; // from?: string | Date; to?: string | Date;

          if (resDatePeriod.from) datePeriodForEmit.from = this.getFormatDate(resDatePeriod.from as string, 'from');
          if (resDatePeriod.to) datePeriodForEmit.to = this.getFormatDate(resDatePeriod.to as string, 'to');
          if (onlyOneDate) datePeriodForEmit.to = this.getFormatDate(resDatePeriod.from as string, 'to');

          this.check_fromTo_formatted(resDatePeriod);
          this.changePeriod.emit(datePeriodForEmit);
        }
      });
  }

  getFormatDate(date: string, type: 'from' | 'to'): string {
    let result: string | Date = '';
    const arr = date?.split('-'); // ['2023', '12', '01']
    const year = arr[0];
    const month = +arr[1] - 1;
    const day = arr[2];
    const time = type == 'from' ? 'T00:00:00' : 'T23:59:59';
    result = this.datesS.formatDate('YYYY-MM-DD', new Date(+year, +month, +day)) + time;
    result = this.otherS.convertDate(result as string, 'User', 'Utc');
    result = this.datesS.formatDate(this.format, result);
    return result;
  }

  check_fromTo_formatted(datePeriod: IDatePeriod): void {
    if (!datePeriod) return;
    this.fromTo_formatted = '';
    if (datePeriod.from) {
      this.fromTo_formatted = this.getFormatDateForViewInDropDown(datePeriod.from as string); // 2023-12-01 => ММ/DD/YYYY
    }
    if (datePeriod.to) {
      this.fromTo_formatted = this.fromTo_formatted + ' - ' + this.getFormatDateForViewInDropDown(datePeriod.to as string);
    }
  }

  // !!! 2023-12-01 => ММ/DD/YYYY
  getFormatDateForViewInDropDown(dateString: string): string {
    if (!dateString) return '';
    const arr = dateString.split('-'); // ['2023', '12', '01']
    return `${arr[1]}/${arr[2]}/${arr[0]}`;
  }

}
