<div class='wrapTimezone'>
  <mat-form-field floatLabel='auto' class='o-matForm' [ngClass]='"o-matForm--"+bcg'
                  [class]='"o-matForm--"+({ value:value,
                             active:selectRef?.panelOpen,
                             disabled:false,
                             error:errRequired
                           }|getStateInput)'>
    <mat-select #selectRef [formControl]='formCtrl' (selectionChange)='changeVal.emit($event.value)' [compareWith]='otherS.compareObjects'
                disableOptionCentering placeholder='Select timezone' [class.enlargeReverse]='!!selectRef?.panelOpen'>
      <mat-option *ngFor='let timezone of listTimezone' [value]='timezone'>
        {{timezone.abbrev|getStartEndLetter}}   {{timezone.name|getStartEndWordFromString}}
      </mat-option>

      <mat-option [ngStyle]='(elRef|getWidthMatMenu)' class='hiddenMatOption'></mat-option>
    </mat-select>
  </mat-form-field>

  <error *ngIf='errRequired' text='Timezone is required.'></error>
</div>
