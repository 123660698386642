import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnComponent } from '@components/btn/btn.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleSignUpComponent } from '@components/google/components/google-sign-up/google-sign-up.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { InvalidControlsPipe } from '@pipes/invalid-controls.pipe';
import { HelperClass } from '@classes/Helper-Classes';
import { AuthService } from '@app/auth/auth.service';
import { MainService } from '@services/main.service';
import { MeService } from '@services/me.service';
import { LocationService, TFieldLoc } from '@components/__blocks/location/location.service';
import { RoutesService } from '@services/routes.service';
import { BtnBackComponent } from '@components/btn-back/btn-back.component';
import { LocationComponent } from '@components/__blocks/location/location.component';
import { ClassUser, UserRegisteredStatus } from '@models/user';

@Component({
  selector: 'locationStep',
  standalone: true,
  imports: [CommonModule, BtnComponent, FieldComponent, FormsModule, GoogleSignUpComponent, InputCtrlComponent, InvalidControlsPipe, ReactiveFormsModule, BtnBackComponent, LocationComponent],
  templateUrl: './location-step.component.html',
  styleUrls: ['./location-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationStepComponent extends HelperClass {
  // @Input() user?: Pick<ClassUser, 'firstName' | 'secondName' | 'preferredName'>;
  fields: Array<TFieldLoc> = ['street', 'city', 'state', 'zipcode']; // список всех полей которые надо отобразить
  requiredFields: Array<TFieldLoc> = this.fields; // список обязательных полей

  @Output() nextStepEvent = new EventEmitter<void>();
  @Output() backStepEvent = new EventEmitter<void>();
  @Output() emit = new EventEmitter();

  constructor(
    public authS: AuthService,
    public mainS: MainService,
    private meS: MeService,
    public locationS: LocationService,
    public routesS: RoutesService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.locationS.reset();
    this.locationS.setData();
  }

  fillUserInfo(): void {
    const locValid = this.locationS.checkErrorLoc(this.requiredFields);
    if (!locValid) return;
    if (this.startRequest()) return;

    const loc = this.locationS.loc;
    const sendObj: ClassUser = {
      id: this.meS.me?.id,
      userRegisteredStatus: UserRegisteredStatus.RESIDENTIAL_ADDRESS_ENTERED,
      address: {
        street: loc.street,
        city: loc.city,
        state: loc.state,
        zipcode: loc.zipcode,
      },
    };

    this.mainS.fillUserInfo(sendObj).toPromise()
      .then((res) => {
        this.nextStepEvent.emit();
        this.emit.emit()
      })
      .catch((err) => {
      })
      .finally(() => this.endRequest());
  }

}
