import { ChangeDetectorRef, Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { DeviceService } from '@services/device.service';
import { MeTableService } from '@components/_table/me-table.service';
import { ClassCeilTableHeader } from '@components/_table/meTable';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({ selector: '[ceilTable]', standalone: true })
export class CeilTableDirective<T> implements OnChanges, OnInit {
  @Input() idxCeil?: number;
  current_ceilTable?: ClassCeilTableHeader;
  is_firstCeilTable?: boolean;
  is_lastCeilTable?: boolean;

  @Input() isHeaderCeil: boolean = false; // !!! for ceil header нужно передавать сюда true, потому что для них не надо устанавливать min-height

  // !!! для мобилы для последней ячейки не должно быть бордера, т.к. после последней ячейки сразу кнопки (actions for table)
  @Input() set bordNone_forMobile(bordNone_forMobile: boolean | undefined) {
    if (typeof bordNone_forMobile === 'boolean') this.is_bordNone_forMobile = bordNone_forMobile;
  }

  //  переделать. пока что сделал так чтобы быстро
  @Input() forAssignPage?: boolean;

  // @Input() forTest = ''

  is_bordNone_forMobile = false; // !!! по дефолту есть бордер

  constructor(
    private r: Renderer2,
    private elRef: ElementRef,
    private deviceS: DeviceService,
    public meTableS: MeTableService<T>,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (typeof changes?.idxCeil?.currentValue === 'number') {
      this.current_ceilTable = this.meTableS.arrHeaderTable$.getValue().find((el, idx) => this.idxCeil === idx);
      // console.log('current_ceilTable :', this.idxCeil, this.current_ceilTable?.text, this.current_ceilTable, this.current_ceilTable?.style);
      this.is_firstCeilTable = true;
      this.is_lastCeilTable = this.idxCeil === this.meTableS.arrWidthCeilTable.length - 1;
    }
  }

  ngOnInit() {
    this.subscribeToDevice();
  }

  subscribeToDevice(): void {
    this.deviceS.isDesktop$.pipe(untilDestroyed(this)).subscribe((isDesktop) => {
      isDesktop ? this.setStylesForCeilTable_forDesktop() : this.setStylesForCeilTable_forMobile();
      this.setAndDeleteCssClass_bordNone_forMobile(isDesktop);
    });
  }

  setStylesForCeilTable_forDesktop(): void {
    const widthForCurrentCeil: number = this.meTableS.arrWidthCeilTable[this.idxCeil!];
    // this.r.setStyle(this.elRef.nativeElement, 'paddingLeft', this.meTableS.paddingLeft_forCeil + 'px');
    ['width', 'minWidth', 'maxWidth'].forEach(el => {
      this.r.setStyle(this.elRef.nativeElement, el, widthForCurrentCeil + 'px');
    });

    if (this.is_lastCeilTable) {
      this.r.setStyle(this.elRef.nativeElement, 'paddingLeft', 0);
    } else {
      this.r.setStyle(this.elRef.nativeElement, 'paddingLeft', this.meTableS.paddingLeft_forCeil + 'px');
    }

    if (this.current_ceilTable?.style?.paddingLeft) {
      // console.log('current_ceilTable?.style :', this.idxCeil, this.current_ceilTable?.text, this.current_ceilTable?.style);
      this.r.setStyle(this.elRef.nativeElement, 'paddingLeft', this.current_ceilTable.style.paddingLeft + 'px');
    }

    // === HEIGHT =====================
    if (!this.isHeaderCeil) { // !!! for ceil header НЕ надо устанавливать minHeight
      this.r.setStyle(this.elRef.nativeElement, 'minHeight', this.meTableS.minHeightItemTable - 2 + 'px'); // -2 border убрал из высоты
    }

  }

  setStylesForCeilTable_forMobile(): void {
    ['width', 'minWidth', 'maxWidth'].forEach(el => {
      this.r.setStyle(this.elRef.nativeElement, el, '100%');
    });
    this.r.setStyle(this.elRef.nativeElement, 'paddingLeft', 0);
  }

  // !!! для мобилы для последней ячейки не должно быть бордера, т.к. после последней ячейки сразу кнопки (actions for table)
  setAndDeleteCssClass_bordNone_forMobile(isDesktop: boolean): void {
    if (!isDesktop && this.is_bordNone_forMobile) this.r.addClass(this.elRef.nativeElement, 'o-bordNone');
    // if (!isDesktop && this.is_bordNone_forMobile && this.forTest) console.log('=========================== :', this.forTest, this.is_bordNone_forMobile, this.elRef.nativeElement)
    if (isDesktop) this.r.removeClass(this.elRef.nativeElement, 'o-bordNone');
    this.cd.detectChanges()
  }
}
