<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              arrCurrentLink: gameS.arrCurrentLinkDrop,
              objCurrentLink: (gameS.currentLink$|async)!,
              currentLink: (gameS.currentLink$|async)?.currentLink!,
              dataTable: (dataTable$|async)!,
              arrContent: (arrContent$|async),
              loadingTable: (meTableS.loadingTable$|async),
              listGameForPossibleGameToCancelled: (meTableS.selectedItems|getListGameForPossibleGameToCancelled),
              listGameForPossibleGameToDelete: (meTableS.selectedItems|getListGameForPossibleGameToDelete),
             } as obj' class='o-wrapPage' wrapPage [widthPage]='widthPage' cdkScrollable dataTable>
  <!--  obj.listGameForPossibleGameToCancelled => это список игр которые разрешено сделать CANCELLED-->
  <!--  obj.listGameForPossibleGameToDelete => это список игр которые разрешено удалить-->

  <!--  === FILTERS & TITLE ===========================-->
  <div class='o-header'>
    <linkPage-searchFilter></linkPage-searchFilter>
    <div class='o-header__content'>
      <div class='o-header__title'>Games</div>
      <div class='o-header__filters'>
        <app-filters></app-filters>
        <!--        [matTooltip]='obj.matTooltip_adminPermission'-->
        <div class='o-header__actions'>
          <!--          [disabled]='!meTableS.selectedItems_ids?.length'-->
          <meSvg svgName='delete_square1&44' (emit)='deleteGameFromServer()'
                 [disabled]='!obj.listGameForPossibleGameToDelete?.length || meTableS.loadingTable' adminPermission></meSvg>
          <meSvg *ngIf='meTableS.selectedItems.length > 0' svgName='bell_hornSquare&44'
                 (click)='openAnnouncementModal()' adminPermission></meSvg>
          <meSvg svgName='download_3&44' (click)=downloadExcel() [disabled]='(downloadDisabled)'></meSvg>
          <btn *ngIf='!meTableS.selectedItems.length' text='New Game +' bcg='newGreen'
               style="margin-left: 12px;"
               [matMenuTriggerFor]='menuCreateGame' [btnWidth]='131' [disabled]='meTableS.loadingTable || isReadOnly' adminPermission>
          </btn>
          <!-- ==== !!! disabled если выбраны игры которые нельзя сделать CANCELLED -->
          <btn *ngIf='meTableS.selectedItems.length' text='Cancel' bcg='red' (emit)='cancelGames()' [btnWidth]='89'
               [disabled]='!obj.listGameForPossibleGameToCancelled?.length || meTableS.loadingTable' adminPermission></btn>
        </div>
      </div>
      <app-filtersSelected></app-filtersSelected>
    </div>
    <!--    [disabled]='!obj.adminPermission' [matTooltip]='obj.matTooltip_adminPermission'-->
    <btn *ngIf='obj.mobile' text='New Game +' style='margin-top: 24px' bcg='newGreen'
         [disabled]='isReadOnly'
         [matMenuTriggerFor]='menuCreateGame' adminPermission></btn>
  </div>

  <!--  === TABLE  web-ipad ==============================-->

  <div class='o-content t-wrapperTable'>
    <tableHeader></tableHeader>
    <div class='t-table'>
      <ng-container *ngFor='let item of obj.arrContent; let idx=index'>
        <div *ngIf='{ gameCANCELLED: item?.gameStatus == "CANCELLED", report: item?.gameReport } as objGame'
             class='t-table__item gametable' itemTable [item]='item' [urlNavigate]='urlGameInfo'
             [style]='objGame.gameCANCELLED ? styleForGame_CANCELLED : null'>
          <div *ngIf='obj.mobile && objGame.gameCANCELLED' class='cancelled-text'
               [ngClass]="{'cancelled-text-enabled': !objGame.gameCANCELLED}">
            Canceled
          </div>
          <div *ngIf='obj.mobile' style='width:100%;' class="t-table__item__game-number">
            #{{ item.gameNumber }}
          </div>
          <app-tableCeilChx *ngIf='obj.desktop' [item]='item' [text]='item.gameNumber'
                            [path]='urlGames'></app-tableCeilChx>
          <app-tableBtnNumber *ngIf='obj.desktop' [game]='item'></app-tableBtnNumber>
          <app-tableCeil-assignor *ngIf='obj.mobile' [idxCeil]='1' [text1]='item.date_EE_MMMd_yyy'
                                  [text2]='item.date_shortTime_timezone'></app-tableCeil-assignor>
          <app-tableCeil *ngIf='obj.desktop' [idxCeil]='1' [text1]='item.date_EE_MMMd_yyy'
                         [text2]='item.date_shortTime_timezone'></app-tableCeil>
          <app-tableCeil-assignor *ngIf='obj.mobile' [idxCeil]='2' [text1]='item.ageGenderLevel'
                                  [text2]='item.competition?.competitionName || "TBD"'></app-tableCeil-assignor>
          <app-tableCeil-assignor *ngIf='obj.mobile' [idxCeil]='3' [text1]='item.locNameCourtName'
                                  [text2]='item.cityState' [matTooltipText]='item.matTooltipForLocation'></app-tableCeil-assignor>
          <app-tableCeil-assignor *ngIf='obj.mobile' [idxCeil]='4' [text1]='(item.homeTeam?.teamName || "TBD") + " vs."'
                                  [text2]='item.awayTeam?.teamName || "TBD"' [commaForMobile]='false'></app-tableCeil-assignor>
          <app-tableCeil *ngIf='obj.desktop' [idxCeil]='2' [text1]='item.ageGenderLevel'
                         [text2]='item.competition?.competitionName || "TBD"'></app-tableCeil>
          <app-tableCeil *ngIf='obj.desktop' [idxCeil]='3' [text1]='item.locNameCourtName' [text2]='item.cityState'
                         [matTooltipText]='item.matTooltipForLocation'></app-tableCeil>
          <app-tableCeil *ngIf='obj.desktop' [idxCeil]='4' [text1]='(item.homeTeam?.teamName || "TBD") + " vs."'
                         [text2]='item.awayTeam?.teamName || "TBD"' [commaForMobile]='false'></app-tableCeil>
          <app-table-cell-game-notes ceilTable [idxCeil]='5'
                                     stopPropagation
                                     [game]="item">
          </app-table-cell-game-notes>
          <photos *ngIf='obj.desktop' type='game' [game]='item' [showSmallCircle]='true' ceilTable [idxCeil]='6'>
          </photos>

          <!--          === показывать эту иконку только когда назначены все роли (тоесть НЕТ свободных ролей) ===-->
          <app-tableCeil *ngIf='obj.mobile' [idxCeil]='6' [text1]='item.text_assignGO_outOf_goLength'
                         [bordNone_forMobile]='true'
                         [svgRightForMobile]='item.amountFreeRoles ? undefined:"circle_chx3&20"'></app-tableCeil>
          <app-tableCeilActions class="hide-on-mobile" [matMenuTriggerFor]='menuItemTable' [authenticator]="['GROUP_ASSIGNOR', 'SUB_ASSIGNOR']"
                                stopPropagation></app-tableCeilActions>

          <mat-menu #menuItemTable='matMenu'>
            <ng-container [ngTemplateOutlet]='actionsForItemTable'></ng-container>
          </mat-menu>
          <div *ngIf='obj.mobile' class='t-actionsItemTable t-actionsItemTable--{{obj.desktop}}' stopPropagation>
            <btn text='View' bcg='blueDark' (click)='router.navigate([urlGameInfo,item?.id])' btnHeight='36'></btn>

            <btn [authenticator]="['GROUP_ASSIGNOR', 'SUB_ASSIGNOR']" #btnMobileActionsRef text='More Options' bcg='white' [matMenuTriggerFor]='menuItemTable'
                 btnHeight='36'></btn>
          </div>

          <ng-template #actionsForItemTable>
            <button [ngStyle]='(btnMobileActionsRef|getWidthMatMenu)' class='hiddenMatOption'></button>
            <button (click)='router.navigate([urlGameInfo,item?.id])'>View</button>
            <ng-container *ngIf='urlGames|adminPermission'>
              <button *ngIf='!objGame.gameCANCELLED' (click)='goToEditGame(item,idx)'>Edit</button>
              <button (click)='goToEditGame(item,idx, true)'>Clone</button>
              <button *ngIf='item!|isPossibleGameToCancelled' (click)='cancelGames(item,idx)'>Cancel</button>
              <button *ngIf='item!|isPossibleGameToDelete' (click)='deleteGameFromServer(item,idx)'>Delete</button>
              <button *ngIf='!objGame.gameCANCELLED' (click)='assignS.goToAssign(item)'>Go to Assign</button>
            </ng-container>
          </ng-template>
        </div>
      </ng-container>
      <app-tableEmpty [tableEmpty]='filtersS.searchValue|getTableEmpty'>
        <btnImportGames class='class_ngContent_btn_for_nothingList'></btnImportGames>
      </app-tableEmpty>
    </div>
  </div>

  <app-tableInfiniteLoading></app-tableInfiniteLoading>
  <paginationWithSettings *ngIf='meTableS.showPagination'></paginationWithSettings>
</div>

<!--=== MENU FOR CREATE GAME =============================-->
<mat-menu #menuCreateGame='matMenu'>
  <button *ngIf='deviceS.isMobile$|async' style='width: calc(100vw - 34px)' routerLink='/{{urlCreateGame}}'>Single
    Game</button>
  <button *ngIf='deviceS.isMobile$|async' style='width: calc(100vw - 34px)' routerLink='/{{urlGameImport}}'>CSV
    Import</button>
  <button *ngIf='deviceS.isDesktop$|async' routerLink='/{{urlCreateGame}}'>Single Game</button>
  <button *ngIf='deviceS.isDesktop$|async' routerLink='/{{urlGameImport}}'>CSV Import</button>
</mat-menu>