import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';

export type TFormItem = { [key: string]: FormControl<any> };

// export type TFormItem = {[key: string]: FormControl} & {[key: undefined]: FormControl};

@Injectable({ providedIn: 'root' })
export class FormCustomService {

  optionsForPatchValue = { emitEvent: false, onlySelf: true }; // !!! чтобы избежать бесконечный цикл в form.valueChanges при ctrl.patchValue()
  // this.form.updateValueAndValidity({emitEvent: false, onlySelf: true})

  // readonly opts_nonNullable_and_required: FormControlOptions & {nonNullable: true} = { nonNullable: true, validators: Validators.required }

  constructor(
    private formBuilder: FormBuilder, // UntypedFormBuilder || FormBuilder || NonNullableFormBuilder
  ) {
  }

  // createForm<T extends object>(obj: T) {
  //   // new FormGroup<IReportInfoForm> || this.formBuilder.nonNullable.group || this.formBuilder.group
  //   const form = this.formBuilder.nonNullable.group({
  //   });
  //   Object.entries(obj).forEach(([key, value]) => {
  //   })
  //   return form
  // }

  getControl_nonNullable<T>(value: T, disabled = false, required = false): FormControl<T> {
    return new FormControl({ value, disabled }, { nonNullable: true, validators: required ? Validators.required : null });
  }

  // !!! обновить Array<FormGroup>
  // updateArrFormGroup<T, K>(arrNewValues: Array<T & {id?: string}>, arrControls: FormArray<FormGroup<any>>): void {
  //   if (!arrNewValues?.length) return
  //   arrNewValues.forEach((newValue) => {
  //     // const findFormGroup = arrControls.controls.find((formGroup) => (formGroup?.controls as FormGroup<any>)?.id === newValue?.id);
  //     // @ts-ignore andrei позже типизировать правильно
  //     const findFormGroup = arrControls.controls.find((formGroup) => formGroup?.controls?.id === newValue?.id);
  //     if (findFormGroup) findFormGroup.setValue(newValue);
  //   })
  // }

  // !!! need check. не проверял
  setDisabledControls(form: UntypedFormGroup, disabledFields: Array<string>, needUpdateForm = false): void {
    if (!form) return;
    const controls = form.controls;

    Object.entries(controls)?.forEach((ctrl) => {
      if (disabledFields.includes(ctrl[0])) {
        ctrl[1].disable(needUpdateForm ? undefined : this.optionsForPatchValue);
      }
    });
  }

  // сделать метод, который будет возвращать массив НЕвалидных контролов

  // проверка formControls на валидность control.valid
  // !!! вернется true, если хотя бы один formControl НЕвалидный
  // !!! вернется false, если все formControl валидны
  checkInvalidControls(form?: UntypedFormGroup, requiredFields?: Array<string>): boolean {
    if (!form) return true;
    let result: boolean;
    const controls = form.controls;

    let amountValidControls = 0;
    let amountInvalidControls = 0;

    if (requiredFields?.length) { // проверять только formControls, которые передал
      Object.entries(controls)?.forEach((ctrl) => {
        if (requiredFields.includes(ctrl[0])) {
          if (ctrl[1].valid) amountValidControls += 1;
          if (ctrl[1].invalid) amountInvalidControls += 1;
        }
      });
    } else { // проверять все formControls
      Object.entries(controls)?.forEach((ctrl) => {
        if (ctrl[1].valid) amountValidControls += 1;
        if (ctrl[1].invalid) amountInvalidControls += 1;
      });
    }
    result = amountInvalidControls > 0;
    return result;
  }

  setValidatorsRequired(form: UntypedFormGroup, arr: Array<any>): void {
    arr.forEach((ctrl) => {
      const control = form.get(ctrl);
      control?.clearValidators();
      control?.setValidators([Validators.required]);
      control?.updateValueAndValidity();
      control?.markAsPristine();
      control?.markAsUntouched();
    });
  }

  clearValidators(form: UntypedFormGroup, arr: Array<any>): void {
    arr.forEach((ctrl) => {
      const control = form.get(ctrl);
      control?.clearValidators();
      control?.setValue('');
      control?.updateValueAndValidity();
      control?.markAsPristine();
      control?.markAsUntouched();
    });
  }
}
