import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MainService } from '@services/main.service';
import { OtherService } from '@services/other.service';
import { GooglePeopleService } from '@services/google-people.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { IsCorrectEmailPipe } from '@pipes/is-correct-email.pipe';
import { MaxCharacterComponent } from '@components/__info_text_message_error_warning/max-character/max-character.component';
import { MatInputModule } from '@angular/material/input';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { BtnComponent } from '@components/btn/btn.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { PaginationComponent } from '@components/pagination/pagination.component';
import { ErrorComponent } from '@components/__info_text_message_error_warning/error/error.component';
import { HelperClass } from '@classes/Helper-Classes';
import { SwitcherBtnComponent } from '@components/switcher-btn/switcher-btn.component';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { PopupCustomizeInvitationComponent } from '@components/popup-customize-invitation/popup-customize-invitation.component';
import { IDataPopup, PopupService } from '@services/popup.service';
import { AddAnotherComponent } from '@components/add-another/add-another.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DndDirective } from '@directives/dnd.directive';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { arrUserRoleDrop, TUserRoleDrop } from '@models/user';
import { ClassSettingsRequest } from '@models/response-and-request';
import { GroupProfileService } from '@app/group-profile/group-profile.service';
import { IRequest_for_inviteUserToGroup, IRequest_for_inviteUserToGroup_emailsWithRoles } from '@app/group-profile/groupProfile';
import { TPath_for_invitePeoples } from '@app/app.module';
import { ClassCompetition } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { CopyLinkComponent } from '@components/copy-link/copy-link.component';

type TPopupInvitePeoples = 'inviteOfficials' | 'inviteUserToGroup';

export interface IPopupInvitePeoples extends IDataPopup {
  show_switcherBtn?: boolean;
  show_dropUserRole?: boolean,
  typeInvite?: TPopupInvitePeoples;
  // userRoleUpperCase: TUserRoleUpperCase;
  // !!! === на разных страницах отправляется разный запрос на сервер для получения ссылки для регистрации
  path: TPath_for_invitePeoples;
}

@UntilDestroy()
@Component({
  selector: 'app-popup-invite-peoples',
  templateUrl: './popup-invite-peoples.component.html',
  styleUrls: ['./popup-invite-peoples.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, SvgComponent, IsCorrectEmailPipe, MaxCharacterComponent, MatInputModule,
    HeadTitleComponent, FieldComponent, InputCtrlComponent, BtnComponent, BtnWrapComponent, PaginationComponent,
    ErrorComponent, SwitcherBtnComponent, SvgAndTextComponent, AddAnotherComponent, MatTooltipModule, DndDirective, DropdownComponent, CopyLinkComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupInvitePeoplesComponent extends HelperClass implements OnInit {
  @ViewChild('switcherBtn') switcherBtn?: SwitcherBtnComponent;
  path: TPath_for_invitePeoples;

  typeInvite: TPopupInvitePeoples = 'inviteOfficials';
  // type: 'official' | 'admin';
  titleText: string = 'Invite new officials';
  subTitleText: string = 'When an official accepts your invitation, they’ll become a member of your league and added to your contacts.';
  show_switcherBtn = true; // by Email | by CSV

  newPeoples: Array<{ ['email']: string, ['isError']: boolean }> = [{ email: '', isError: false }];
  // linkActive: 'email' | 'csv' | 'google' = 'email';

  emails: Array<any> = [];
  pageEmails = 1;

  invitationText = '';

  isConnect = false;

  showError = false;
  // если нет контактов в _CSV или в Google
  errorNoContacts = {
    isError: false,
    text: 'You do not have contacts in your google addressbook',
  };

  // !!! === for invite admin in group => GroupProfileManagementComponent
  // inviteUserToGroup = false;
  show_dropUserRole = false;
  readonly arrUserRoleDrop = arrUserRoleDrop.filter(el => el.upperCase === 'ADMIN'); // !!! пока что можно пригласить только админа
  selectedUserRoleDrop?: TUserRoleDrop;

  competition?: ClassCompetition; // !!! для получения ссылки для регистрации юзера в компетишн (страница competitions/users)

  constructor(
    public dialogRef: MatDialogRef<PopupInvitePeoplesComponent>,
    // @Inject(MAT_DIALOG_DATA) public dataPopup: { type: 'official' | 'admin', title: string },
    @Inject(MAT_DIALOG_DATA) public dataPopup: IDataPopup & IPopupInvitePeoples,
    public mainS: MainService,
    private groupProfileS: GroupProfileService,
    private otherS: OtherService,
    public googlePeopleS: GooglePeopleService,
    private popupS: PopupService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.path = dataPopup.path;
    // this.getLinkOfficialToGroup()
    // this.type = dataPopup.type;
    if (dataPopup.typeInvite) this.typeInvite = dataPopup.typeInvite;
    if (dataPopup.titleText) this.titleText = dataPopup.titleText;
    if (dataPopup.subTitleText) this.subTitleText = dataPopup.subTitleText;
    if (typeof dataPopup.show_switcherBtn === 'boolean') this.show_switcherBtn = dataPopup.show_switcherBtn;
    if (typeof dataPopup.show_dropUserRole === 'boolean') this.show_dropUserRole = dataPopup.show_dropUserRole;
    if (dataPopup.competition) this.competition = dataPopup.competition;
    // if (typeof dataPopup.inviteUserToGroup === 'boolean') this.inviteUserToGroup = dataPopup.inviteUserToGroup;
    // console.log('dataPopup :', dataPopup)
  }

  uploadedFiles(event: any): void {
    console.log('uploadedFiles :', event);
  }

  ngOnInit(): void {
    this.getIsConnectGoogle();
  }

  emailToLowerCase(people: any, email: string): void {
    people.email = email.toLowerCase();
    this.showError = false;
    // this.cd.detectChanges()
  }

  setDefaultThisProperties(): void {
    this.errorNoContacts.isError = false;
    this.showError = false;
    this.pageEmails = 1;
    this.emails = [];
    this.cd.detectChanges();
  }

  // !!! for page /officials
  inviteOfficials(): void {
    if (this.startRequest()) return;
    const emails = this.getEmailsForSendToServer();
    if (this.showError) {
      this.endRequest();
      return;
    }

    const sendObj: Pick<ClassSettingsRequest, 'emails' | 'invitationText' | 'userRole'> = { emails, invitationText: this.invitationText };
    this.mainS.inviteOfficials(sendObj).toPromise()
      .then((res) => {
        this.close(emails);
      })
      .catch((err) => {
      })
      .finally(() => this.endRequest());
  }


  // !!! for page /groupProfile_userManagement
  inviteUserToGroup(): void {
    if (this.startRequest()) return;
    const emails = this.getEmailsForSendToServer();
    if (this.showError) {
      this.endRequest();
      return;
    }
    const emailsWithRoles: Array<IRequest_for_inviteUserToGroup_emailsWithRoles> = emails.map((el) => {
      const request_for_inviteUserToGroup_emailsWithRoles: IRequest_for_inviteUserToGroup_emailsWithRoles = {
        email: el,
        role: this.selectedUserRoleDrop?.upperCase!,
      };
      return request_for_inviteUserToGroup_emailsWithRoles;
    });

    const sendObj: IRequest_for_inviteUserToGroup = { emailsWithRoles, invitationText: this.invitationText };
    // console.log('sendObj :', sendObj)
    // this.endRequest()
    // return

    this.groupProfileS.inviteUserToGroup(sendObj).toPromise()
      .then((res) => {
        console.log('inviteUserToGroup :', res);
        // this.close(emails);
        this.dialogRef.close(sendObj);
      })
      .catch((err) => {
      })
      .finally(() => this.endRequest());
  }

  getEmailsForSendToServer(): Array<string> {
    let emails: Array<string> = [];
    if (this.switcherBtn?.btnActive.btn1) {
      this.newPeoples.forEach((el) => {
        el.isError = (!el.email || !this.otherS.isCorrectEmail(el.email));
      });
      emails = this.newPeoples.map(el => el.email);
      this.showError = !!this.newPeoples.find(el => el.isError);
    } else {
      emails = this.emails.flat(Infinity);
      this.showError = !emails.length;
    }
    return emails;
  }

  addPeople(): void {
    if (this.newPeoples.length >= 10) return;
    this.newPeoples.push({ email: '', isError: false });
    this.cd.detectChanges();
  }

  removeEmail(idx: number): void {
    if (this.newPeoples.length === 1) return;
    this.newPeoples = this.newPeoples.filter((el, i) => i !== idx);
    this.showError = false;
    this.cd.detectChanges();
  }

  openPopupCustomizeInvitation(): void {
    // const dataPopup: IDataPopup_forCustomizeInvitation = {
    const dataPopup: IDataPopup = {
      width: '480px',
      // userRoleUpperCase: this.typeInvite === 'inviteUserToGroup' ? this.selectedUserRoleDrop?.upperCase! : 'OFFICIAL',
      text: this.invitationText,
      // path: this.path,
    };
    this.popupS.open(PopupCustomizeInvitationComponent, dataPopup).then((res: string) => {
      if (res) this.invitationText = res;
    });
  }

  // ==== _CSV ===========
  fileBrowseHandler(event: any): void {
    this.setDefaultThisProperties();
    if (!event?.target?.files?.length) return;
    const file = event.target.files[0];
    this.getEmailsFromCsv(file);
  }

  onFileDropped(event: any) {
    for (const item of event) {
      this.getEmailsFromCsv(item);
    }
  }

  getEmailsFromCsv(file: any): void {
    if (file) new Blob([file]).text().then((textFromCsv) => {
      this.emails = this.otherS.splitAnArrayIntoSubarrays(textFromCsv.split('\r\n').filter(Boolean), 10);
      if (!this.emails?.length) this.errorNoContacts.isError = true;
      this.cd.detectChanges();
    });
  }

  // === GOOGLE ===============
  getIsConnectGoogle(): void {
    this.googlePeopleS.subIsConnectGoogle.asObservable().pipe(untilDestroyed(this)).subscribe((res: boolean) => {
      this.isConnect = res;
      if (!this.isConnect) this.emails = [];
      this.cd.detectChanges();
    });
  }

  getEmailsFromGoogle(): void {
    this.setDefaultThisProperties();
    this.googlePeopleS.handleClientLoad();
    this.googlePeopleS.subEmailsFromGoogle.asObservable().pipe(untilDestroyed(this)).subscribe((res: Array<string>) => {
      if (!res) return;
      if (this.isConnect) this.emails = this.otherS.splitAnArrayIntoSubarrays(res, 10);
      if (!this.emails?.length) this.errorNoContacts.isError = true;
      this.errorNoContacts.isError = false;
      this.showError = false;
      this.cd.detectChanges();
    });
  }

  disconnectGoogle(): void {
    this.googlePeopleS.disconnect();
  }

  deleteEmail(email: string): void {
    const page = this.pageEmails - 1;
    this.emails[page] = this.emails[page].filter((el: any) => el !== email);
    this.emails = this.emails.filter((el: Array<string>) => el.length);
    this.cd.detectChanges();
  }

  close(data: Array<string> | null): void {
    this.dialogRef.close(data);
  }

}

// getLink(): void {
//   if (this.typeInvite === 'inviteOfficials') this.getLinkOfficialToGroup();
// if (this.typeInvite === 'inviteUserToGroup') this.getLink_for_inviteUserToGroup();
// }
// !!! for page /officials
// getLinkOfficialToGroup(): void {
//   if (this.startRequest()) return;
// this.mainS.getLinkOfficialToGroup().toPromise()// https://v3.beta.joinnotch.com/v1/signup?role=OFFICIAL&groupId=a39041e6-5619-4b2a-bf46-e3e305bf41c3&key=829bf99b-6a0e-464a-a635-83dddbfe6a6e
//   .then((res?: string) => res ? navigator.clipboard.writeText(res) : null)
//   .catch((err) => {
//   })
//   .finally(() => this.endRequest());
// }
// !!! for page /groupProfile_userManagement
// getLink_for_inviteUserToGroup(): void {
//   if (this.startRequest()) return;
// const userRoleUpperCase: TUserRoleUpperCase = this.selectedUserRoleDrop?.upperCase!;
// this.groupProfileS.getLink_for_inviteUserToGroup(userRoleUpperCase).toPromise()// https://v3.beta.joinnotch.com/v1/signup?role=ADMIN&groupId=d58907c2-f437-4045-84a4-68da2ff2aef5&key=4de91826-106d-4845-a736-a74f1bf6074a
//   .then((res?: string) => res ? navigator.clipboard.writeText(res) : null)
//   .catch((err) => {
//   })
//   .finally(() => this.endRequest());
// }

// updatePageEmails(page: any) {
//   this.pageEmails = page;
//   this.cd.detectChanges();
// }

// goToNextLink(link: 'email' | 'csv' | 'google'): void {
//   this.setDefaultThisProperties();
//   this.linkActive = link;
//   this.cd.detectChanges();
// }