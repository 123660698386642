<ng-container *ngIf='!forReportApprove'>
  <div *ngIf='{error: ["ERROR"]|includes:status, success: ["SUCCESS"]|includes:status, blue: ["BLUE"]|includes:status} as obj'
       class='container' (click)='removeMe()'>
    <div class='wrapper wrapper--{{status}}' (mouseenter)='clearTimeoutLink()'>
      <div class='content'>
        <div *ngIf='obj.error' class='titleSticker'>{{ tittleErr }}
          <meSvg svgName='cross_red&24'></meSvg>
        </div>
        <div *ngIf='obj.success' class='titleSticker'>Success!
          <meSvg svgName='cross_green&24'></meSvg>
        </div>
        <div *ngIf='obj.blue' class='titleSticker'>Information!
          <meSvg svgName='cross_blueDark&24'></meSvg>
        </div>
        <span class='messageSticker'>
        {{ message }}
          <!--        <meSvg *ngIf='errorResponse?.errorDetails' svgName='arrow_chevronBottomRed&20'-->
          <!--               (click)='show_errorDetails=!show_errorDetails' [class.o-reverse]='show_errorDetails'></meSvg>-->
      </span>
        <!--      <span *ngIf='show_errorDetails' class='messageSticker' (click)='show_errorDetails=false' stopPropagation>-->
        <!--        {{errorResponse?.errorDetails}}-->
        <!--      </span>-->

        <div class='actionsSticker actionsSticker--{{status}}'>
          <div class='actionsSticker__text actionsSticker__text--{{status}}'>Dismiss</div>
          <div class='actionsSticker__btn actionsSticker__btn--{{status}}'>View changes
            <meSvg *ngIf='obj.error' svgName='arrow_rightRed&20' (click)='removeMe()'></meSvg>
            <meSvg *ngIf='obj.success' svgName='arrow_rightGreen&20' (click)='removeMe()'></meSvg>
            <meSvg *ngIf='obj.blue' svgName='arrow_right&20' (click)='removeMe()'></meSvg>
          </div>
        </div>
      </div>

    </div>
  </div>
</ng-container>

<ng-container *ngIf='forReportApprove'>
  <div class='container' (click)='removeMe()'>
    <div class='wrapper wrapperForReportApprove' (mouseenter)='clearTimeoutLink()'>
      <div class='content'>
        <div class='titleSticker'>
          <meSvg svgName='squareLoadingBlue&32'></meSvg>
          <meSvg svgName='cross_blueDark&24'></meSvg>
        </div>
        <span class='wrapperForReportApprove__text1'>Post game reports are currently being processed.</span>
        <span class='wrapperForReportApprove__text2'>You will receive an email notification once the process is complete.</span>
      </div>
    </div>
  </div>
</ng-container>
