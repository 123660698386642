import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClassGame } from '@app/dir_group_assignor/games/game';
import { BtnComponent } from '@components/btn/btn.component';
import { DeviceService } from '@services/device.service';
import { TColor } from '@models/ICssStyles';

// !!! ONLY FOR MOBILE for table this component
@Component({
  selector: 'app-tableBtnNumber[game]',
  standalone: true,
  imports: [CommonModule, BtnComponent],
  templateUrl: './table-btn-number.component.html',
  styleUrls: ['./table-btn-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 't-tableBtnNumber',
  },
})
export class TableBtnNumberComponent {
  @HostBinding('class.o-none') get hideThisComponent(): boolean {
    return this.deviceS.isDesktop;
  }

  @Input() game!: ClassGame;
  @Input() bcg?: TColor | null; // !!! если надо поменять background

  constructor(
    private deviceS: DeviceService,
  ) {
  }

}
