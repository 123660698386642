<div *ngIf='{ amountSelectedFilters: ((filtersS.arrFilters$|async)|valuesSelectedFilters)?.length } as obj'
   class='wrapperFilersSelectedIcon' (click)='action()'>
   <div *ngIf="!filterText" class="asButton22">
      <meSvg svgName='line_menuForFilters&44' [asButton22]="true" [matBadge]='obj.amountSelectedFilters'
         [matBadgeHidden]='!obj.amountSelectedFilters'></meSvg>
      <div>{{filterText}}</div>
   </div>

   <div *ngIf="filterText" class="asButton22 btn-text" [class.btn-text]="filterText">
      <meSvg svgName='line_menuForFilters&44' [asButton22]="true" [matBadge]='' [matBadgeHidden]='true'></meSvg>
      <div class="filter-text">{{filterText}}</div>
   </div>
</div>