import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  ClassUser,
  TUserRoleUpperCase,
  userRole_ADMIN,
  userRole_GROUP_ASSIGNOR,
  userRole_OFFICIAL,
  userRole_SUB_ASSIGNOR,
} from '@models/user';
import { IOnboarding } from '@app/dir_group_assignor/dashboard/dashboard';
import { OtherService } from '@services/other.service';

// import { jwtDecode } from 'jwt-decode/build/esm';
import { jwtDecode } from 'jwt-decode';
import { UtilsService } from '@services/utils.service';
import { ClassGroup } from '@models/IGroup';
import { TPath } from '@app/app.module';

export interface IJwtTokenDecoder {
  exp?: number;
  iat?: number;
  'role-current'?: TUserRoleUpperCase; // 'OFFICIAL' | 'GROUP_ASSIGNOR' | 'SUB_ASSIGNOR'
  sub?: string; // "test@gmail.com"
  'user-id'?: string;
}

@Injectable({ providedIn: 'root' })
export class MeService {
  me$ = new BehaviorSubject<ClassUser | undefined>(undefined);

  // === IOnboarding ======================================
  // !!! GET /api/core/onboarding/v1 этот запрос нужно отправлять только для 'OFFICIAL' & 'GROUP_ASSIGNOR' // для 'ADMIN' не нужно
  private needGetOnboardingSub$ = new BehaviorSubject<boolean>(false);
  needGetOnboarding$ = this.needGetOnboardingSub$.asObservable();

  get needGetOnboarding(): boolean {
    return this.needGetOnboardingSub$.getValue();
  }

  onboarding$ = new BehaviorSubject<IOnboarding | undefined>(undefined);

  updateOnboarding(onboarding: IOnboarding): void {
    if (!onboarding) return;
    const newOnboarding: IOnboarding = { ...this.onboarding$.getValue(), ...onboarding };
    this.onboarding$.next(newOnboarding);
  }

  constructor(
    private otherS: OtherService,
  ) {
  }

  setMeUser(user?: ClassUser | null, text?: string): void {
    const phone_formatted = UtilsService.getFormatPhone(user?.phone); // +X (XXX) XXX-XXXX
    const dateOfBirth_formatted = this.otherS.getFormatDateOfBirth(user?.dateOfBirth); // MM/dd/YYYY
    const newUser = this.otherS.removePlusFromPhone({ ...this.me$.getValue(), ...user, phone_formatted, dateOfBirth_formatted });
    // this.me$.next(newUser);
    // console.log('setMeUser :', new ClassUser(newUser));
    this.needGetOnboardingSub$.next(newUser.roleCurrent === 'GROUP_ASSIGNOR' || newUser.roleCurrent === 'OFFICIAL');
    this.me$.next(new ClassUser(newUser));
  }

  decode_jwt_token(): IJwtTokenDecoder | null { // !!! если перешел по ссылке для регистрации то токена ещё не существует.
    const jwt_token: string | null = localStorage.getItem('jwt_token');
    if (!jwt_token) return null;
    return jwtDecode(jwt_token);
  }

  // updateMeUser(user?: ClassUser | null): void {
  // }

  // === GETTERS =======================
  get me(): ClassUser | undefined {
    return this.me$.getValue();
  }

  // export type TUserRole = 'OFFICIAL' | 'GROUP_ASSIGNOR' | 'SUB_ASSIGNOR';
  get meRole(): TUserRoleUpperCase {
    return this.me$.getValue()?.roleCurrent!;
  }

  get OFFICIAL(): boolean {
    return this.me$.getValue()?.roleCurrent === userRole_OFFICIAL;
  }

  get ADMIN(): boolean {
    return this.me$.getValue()?.roleCurrent === userRole_ADMIN;
  }

  get GROUP_ASSIGNOR(): boolean {
    return this.me$.getValue()?.roleCurrent === userRole_GROUP_ASSIGNOR;
  }

  get SUB_ASSIGNOR(): boolean {
    return this.me$.getValue()?.roleCurrent === userRole_SUB_ASSIGNOR;
  }

  get meId(): string {
    return this.me$.getValue()?.id!;
  }

  get userId(): string {
    return this.me$.getValue()?.userId!;
  }

  get meEmail(): string {
    return this.me$.getValue()?.email!;
  }

  get mePhone(): string {
    return this.me$.getValue()?.phone!;
  }

  get gameReminderOn(): boolean {
    return this.me$.getValue()?.gameReminderOn!;
  }

  // === ME ARRAY GROUP ===================================
  private meArrCurrentGroupSub$ = new BehaviorSubject<Array<ClassGroup>>([]);
  meArrCurrentGroup$ = this.meArrCurrentGroupSub$.asObservable();

  set meArrCurrentGroup(meArrCurrentGroup: Array<ClassGroup>) {
    this.meArrCurrentGroupSub$.next(meArrCurrentGroup || []);
  }

  get meArrCurrentGroup(): Array<ClassGroup> {
    return this.meArrCurrentGroupSub$.getValue();
  }

  // === ME CURRENT GROUP ===================================
  // this.meS.meCurrentGroup = arrClassGroup[0] // !!! andrei пока что текущая группа устанавливается 1элемент из массива в MainService в getMeArrCurrentGroup()
  private meCurrentGroupSub$ = new BehaviorSubject<ClassGroup | undefined>(undefined); // !!! не менять undefined. CurrentGroupResolver непрнавильно отработает еслт поставить {}
  meCurrentGroup$ = this.meCurrentGroupSub$.asObservable();

  set meCurrentGroup(group: ClassGroup | undefined) {
    this.meCurrentGroupSub$.next(group);
  }

  get meCurrentGroup(): ClassGroup | undefined {
    return this.meCurrentGroupSub$.getValue();
  }

  update_meCurrentGroup(group: ClassGroup): void {
    this.meCurrentGroupSub$.next({ ...this.meCurrentGroup, ...group });
  }

  // !!! это для того чтобы ссылку на meCurrentGroupSub$ создать в GroupProfileService
  // !!! для того чтобы можно было напрямую в сервисе GroupProfileService обращаться с meCurrentGroupSub$
  // getLink_meCurrentGroupSub$(): BehaviorSubject<ClassGroup> {
  //   return this.meCurrentGroupSub$
  // }

  // === ADMIN PERMISSIONS ===========================================
  readonly matTooltip_adminPermission = 'Read only permission';

  // !!! разрешено ли админу действия с данной страницей
  adminPermission(page: TPath): boolean {
    if (this.ADMIN) return false;
    // if (this.ADMIN) return this.matTooltip_adminPermission;
    else return true;
    // else return '';
  }
}
