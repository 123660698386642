import { TClassForText, TColor } from '@models/ICssStyles';
import { TSvgName } from '@components/__svg_img/svg/forSvg';
import { IResponse } from '@models/response-and-request';
import { BehaviorSubject } from 'rxjs';
import {
  rout_groupProfile_adjustmentStatuses,
  rout_groupProfile_userManagement,
  urlAnnouncements,
  urlAssign,
  urlBalances,
  urlCompetition_users,
  urlCompetitionsList,
  urlDashboard,
  urlGames,
  urlMyGames,
  urlMyWallet_1,
  urlOfficials,
  urlOpenGames,
  urlPayments,
  urlPayments_balances,
  urlReports,
} from '@app/app.module';
import { TUserRoleUpperCase } from '@models/user';
import {
  TSelectedTable_dashboard_forOfficial,
} from '@app/dir_group_assignor/dashboard/components/games-table/unaccepted-games/unaccepted-games.component';
import { TUrlWithoutQueryParams } from '@services/routes.service';
import { lineBreak } from '@models/other';
import { urlPayments_assignorFees } from '@app/url-const';

export type TypeEmitSettingRequest = 'filters' | 'resetFilters' | 'pagination' | 'sorting' | 'resetSortByDefault' | 'infiniteLoading' | 'currentLink';
// | 'default'

export interface IForClassForTable<T> {
  dataTable$: BehaviorSubject<IResponse<T>>;
  arrContent$: BehaviorSubject<Array<T>>;

  setDataForTable(): void;
}

export interface IForTable {
  id?: string;
  isSelect?: boolean; // for checkbox
  hiddenChx?: boolean; // для админа если нет пермишенов, то нужно скрывать чекбоксы
}

export interface IChx {
  isSelect?: boolean;
  disabled?: boolean;
}

export interface IBtn {
  bcg: TColor,
  btnText: string,
  height?: number;
  urlForNavigate?: string;

  btnDisabled?: boolean;
  btnMatTooltip?: string;
}

// === HEADER ===============================================
export class ClassCeilTableHeader {
  text?: string;
  isCentered?: boolean; // !!! если нужно сделать по центру. По дефолту к левому краю прижато
  symbolSeparation?: string; // !!! for TableDoubleCeilComponent table openGames & assign. Например Location & Teams здесь symbolSeparation=="&"
  isChx?: boolean;

  // text2?: string; // !!! на странице assign в последней колонке в хэдере 2 текста
  // !!! style можно использовать в исключительных случаях, когда нужно дефолтные стили перезаписать.
  // !!! Например => на странице assign в колонке "Location & Teams" paddingLeft должен быть 39px, потому что там в фигме звездочки нарисованы (чтобы понять нужно посмотреть фигму)
  style?: { [key: string]: any; };

  // sort?: ISort;
  // sortOrder?: SortOrder; // !!! need delete этот говнокод после того как новый компонент для сортировки сделаю
  sortBy?: boolean;

  constructor(ceilTableHeader: ClassCeilTableHeader) {
    Object.entries(ceilTableHeader)?.forEach((el) => {
      const key = el[0] as keyof ClassCeilTableHeader;
      this[key] = el[1];
    });
  }
}

// === ITEM ===============================================
export class ClassItemTable {
  arrayCeil: Array<ClassCeilTable>;
  id: string;
  chx?: IChx;
  navigateToItem?: string; // !!! router.navigate(["game/info/",item?.id]) !!! передавать только 'game/info'
  style?: { [key: string]: any; };

  constructor(itemTable: ClassItemTable) {
    this.id = itemTable.id;
    if (itemTable.chx) this.chx = itemTable.chx;
    this.arrayCeil = itemTable.arrayCeil;
    if (itemTable.navigateToItem) this.navigateToItem = itemTable.navigateToItem;
    if (itemTable.style) this.style = itemTable.style;
  }
}

// === CEIL ===============================================
export interface IForMobileReplaceCeil { // !!! для мобилы содержимое некоторых ceil отличаются от desktop
  btn?: IBtn; // !!! показывать в виде кнопки // height=default 36 // cssClass: TypeCssClass_forBtn
  forDefault?: { // !!! показывать как все остальные ячейки
    text1?: string;
    text2?: string;
    svg?: TSvgName;
  };
}

export class ClassCeilTable {
  text1?: string;
  text2?: string;
  matTooltip_text?: string;
  typeText?: TClassForText; // по дефолту для ItemTable 'text8-blueDark'. Здесь можно изменить для ячейки
  ngContent?: boolean; // !!! если text1 не передал, то можно передавать ngContent
  ngContent_actionsForTable?: boolean; // !!! троеточия для deskTop с выпадающим списком. А для мобилы в виде кнопок
  svgRight?: TSvgName;
  matTooltipSvgRight?: string;
  forMobileReplaceCeil?: IForMobileReplaceCeil;
  forMobile_showBorder?: boolean; // default==true

  style?: { [key: string]: any; };

  constructor(ceilTable: ClassCeilTable) {
    if (ceilTable.text1) this.text1 = ceilTable.text1;
    if (ceilTable.text2) this.text2 = ceilTable.text2;
    if (ceilTable.matTooltip_text) this.matTooltip_text = ceilTable.matTooltip_text;
    this.typeText = ceilTable.typeText ? ceilTable.typeText : 'text8-blueDark';
    this.ngContent = !!ceilTable.ngContent;
    if (ceilTable.ngContent_actionsForTable) this.ngContent_actionsForTable = ceilTable.ngContent_actionsForTable;
    if (ceilTable.svgRight) this.svgRight = ceilTable.svgRight;
    if (ceilTable.matTooltipSvgRight) this.matTooltipSvgRight = ceilTable.matTooltipSvgRight;

    if (ceilTable.forMobileReplaceCeil) {
      this.forMobileReplaceCeil = ceilTable.forMobileReplaceCeil;
      if (ceilTable.forMobileReplaceCeil.btn) {
        ceilTable.forMobileReplaceCeil.btn = {
          ...ceilTable.forMobileReplaceCeil.btn,
          height: ceilTable.forMobileReplaceCeil.btn.height ? ceilTable.forMobileReplaceCeil.btn.height : 36,
        };
      }
    }

    if (typeof ceilTable.forMobile_showBorder == 'boolean') {
      this.forMobile_showBorder = ceilTable.forMobile_showBorder;
    } else {
      this.forMobile_showBorder = true; // если не передал, то установить по дефолту true
    }

    if (ceilTable.style) this.style = ceilTable.style;
  }
}

// === TEXT FOR EMPTY LIST ========================================
export interface ITableEmpty {
  title?: string;
  subTitle?: string;

  // !!! если search есть и нет результата с бэка то новый текст без кнопки.
  // !!! если search пустой то старый текст с кнопками
  showBtn?: boolean;
  // btn?: IBtn;
}

// for subAssignor => dashboard Assign Games reports
// for official => dashboard myGames reports
export const titleEmptyList_games = 'There are no games to display';
export const titleEmptyList_reports = 'There are no post game reports to display';
// for official => openGames
export const titleEmptyList_openGames = 'There are no open games to display';

// for subAssignor => dashboard Assign Games reports
// for official => dashboard openGames myGames reports
// !!! для мобилы не надо добавлять перенос текста. Там автоматически должен текст переноситься.
export const get_subTitleEmptyList_games = (isMobile: boolean) => {
  let arr = ['Try adjusting your search or filter to find what you\'re looking for when', '\n', ' considering date ranges, groups and competitions'];
  arr = isMobile ? arr?.filter(el => el !== lineBreak) : arr;
  return arr.join('');
};

export const get_subTitleEmptyList_games_old = (isMobile: boolean) => {
  let arr = ['Please import a game schedule or try adjusting your search or filter to find what you\'re looking for', '\n', ' when considering date ranges, groups and competitions'];
  arr = isMobile ? arr?.filter(el => el !== lineBreak) : arr;
  return arr.join('');
};
export const subTitleEmptyList_reports_old = 'Check back here after your active games are completed';
export const subTitleEmptyList_gamesForOfficial_old = 'Please check your filters or refresh your browser';

// !!! selectedTable_dashboard_forOfficial => мне пришлось сделать эту херню чтобы не ломать текущий функционал. Т.к. рефакторить код после Гранта очень долго
// !!! если search и нет результата с бэка то новый текст без кнопки.
// !!! если search пустой то старый текст с кнопками
export function getTableEmpty(
  path: TUrlWithoutQueryParams, meRole: TUserRoleUpperCase, search: string | undefined, isMobile: boolean, forTest?: string,
): ITableEmpty {
  let result: ITableEmpty = {};
  const subTitleEmptyList_games = get_subTitleEmptyList_games(isMobile);
  const subTitleEmptyList_games_old = get_subTitleEmptyList_games_old(isMobile);

  // const isOFFICIAL = meRole === 'OFFICIAL';
  const isGames = path.includes(urlGames);
  const isAssign = path.includes(urlAssign);
  const isMyGames = path.includes(urlMyGames);
  const isOpenGames = path.includes(urlOpenGames);
  const isDashboard = path.includes(urlDashboard);
  const isReports = path.includes(urlReports);
  // console.log('getTableEmpty() path:', path, '  search:', search, '  forTest:', forTest);
  // if (isGames) console.log('isGames :', isGames)
  // if (isAssign) console.log('isAssign :', isAssign)
  // if (isMyGames) console.log('isMyGames :', isMyGames)
  // if (isOpenGames) console.log('isOpenGames :', isOpenGames)
  // if (isDashboard) console.log('isDashboard :', isDashboard)
  // if (isReports) console.log('isReports :', isReports)

  if (search) { // new variant => search && !results
    if (isGames || isAssign || isMyGames || isDashboard) result = { title: titleEmptyList_games, subTitle: subTitleEmptyList_games };
    if (isOpenGames) result = { title: titleEmptyList_openGames, subTitle: subTitleEmptyList_games };
    if (isReports) result = { title: titleEmptyList_reports, subTitle: subTitleEmptyList_games };
  } else { // old variant => !search && !results (+show btn)
    // !!! for Dashboard getTableEmpty_forDashboard_forOfficial()
    if (isGames || isAssign || isDashboard) result = { title: titleEmptyList_games, subTitle: subTitleEmptyList_games_old };
    if (isOpenGames || isMyGames) result = { title: titleEmptyList_openGames, subTitle: subTitleEmptyList_gamesForOfficial_old };
    if (isReports) result = { title: titleEmptyList_reports, subTitle: subTitleEmptyList_reports_old };
  }

  // === для этих роутов текст не зависит от search ============================
  if (path.includes(urlPayments_assignorFees)) {
    result = {
      title: 'Transactions will appear here',
      subTitle: '',
    };
  }
  if (path.includes(urlPayments)) {
    result = {
      title: 'There are no competitions to display',
      subTitle: 'Here you\'ll be able to view what your competitions have spent in expenses when they\'re created',
    };
  }
  if (path.includes(urlPayments_balances)) {
    result = { title: 'Transactions will appear here' };
  }
  if (path.includes(urlBalances)) {
    result = { title: 'Transactions will appear here' };
  }
  if (path.includes('planner')) {
    result = { title: titleEmptyList_games, subTitle: 'All your assigned and accepted games will appear here' };
  }
  if (path.includes(urlOfficials)) {
    result = {
      title: 'There are no officials to display',
      subTitle: 'Please choose a way to invite your directory of officials to join your group',
    };
  }
  if (path.includes('payers')) {
    result = {
      title: 'There are no payers to display',
      subTitle: 'Here you\'ll be able to view reports on your competition spend and details of each payout format',
    };
  }
  if (path.includes(rout_groupProfile_userManagement)) {
    result = { title: 'There are no users to display', subTitle: 'Click the button to invite any users' };
  }
  if (path.includes(rout_groupProfile_adjustmentStatuses)) {
    result = { title: 'There are no Adjustment Statuses to display' };
  }
  if (path.includes(urlMyWallet_1)) {
    result = { title: 'Transactions will appear here' };
  }
  if (path.includes(urlCompetition_users)) {
    let arr = ['You’ve not added any administrators to this competition yet.', '\n', ' Once you do, they will be displayed on this page.'];
    arr = isMobile ? arr?.filter(el => el !== lineBreak) : arr;
    result = {
      title: 'No teammates yet',
      subTitle: arr.join(''),
    };
  }
  if (path.includes(urlCompetitionsList)) {
    result = {
      title: 'There are no competitions to display',
      subTitle: 'Here you\'ll be able to view general details of all your competitions when they\'re created',
    };
  }
  if (path.includes(urlAnnouncements)) {
    result = { title: 'Click the button to write your first message', subTitle: 'There are no announcements to display' };
  }
  // if (path === ) {
  //   result = { title: , subTitle:  };
  // }
  return { ...result, showBtn: !search };
}

// !!! мне пришлось сделать эту херню чтобы не ломать текущий функционал. Т.к. рефакторить код после Гранта очень долго
export function getTableEmpty_forDashboard_forRoleOfficial(
  search: string | undefined, selectedTable_dashboard_forOfficial: TSelectedTable_dashboard_forOfficial, isMobile: boolean, forTest?: string,
): ITableEmpty {
  let result: ITableEmpty = {};
  const subTitleEmptyList_games = get_subTitleEmptyList_games(isMobile);
  if (search) {
    result = { title: titleEmptyList_games, subTitle: subTitleEmptyList_games };
  } else {
    let arr = ['We recommend you check your availability settings and go to your Games section to see if there', '\n', ' are any open assignments.'];
    arr = isMobile ? arr?.filter(el => el !== lineBreak) : arr;
    result = {
      title: 'No teammates yet',
      subTitle: arr.join(''),
    };
    if (selectedTable_dashboard_forOfficial === 'accepted') {
      result = {
        title: 'You do not have any Accepted Games',
        subTitle: arr.join(''),
      };
    }
    if (selectedTable_dashboard_forOfficial === 'unaccepted') {
      result = {
        title: 'You do not have any games to accept',
        subTitle: arr.join(''),
      };
    }
    if (selectedTable_dashboard_forOfficial === 'unacceptedselfassigned') {
      result = {
        title: 'You do not have any Games Requested',
        subTitle: arr.join(''),
      };
    }
  }
  return { ...result, showBtn: !search };
}

export function getTableEmpty_forPageOfficials(
  search: string | undefined, is_currentLink_active: boolean, forTest?: string,
): ITableEmpty {
  let result: ITableEmpty = {};
  const title = `There are no ${is_currentLink_active ? 'active' : 'pending'} officials to display`;
  result = { title, subTitle: 'Please choose a way to invite your directory of officials to join your group' };
  return { ...result, showBtn: !search };
}


