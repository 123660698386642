import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass } from '@classes/Helper-Classes';
import { MainService } from '@services/main.service';
import { ClassSettingsRequest } from '@models/response-and-request';
import { PaymentService } from '@app/dir_group_assignor/payments/payment.service';
import { FormsModule } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { OtherService } from '@services/other.service';
import { ClassCompetition, ClassCompetitionSeason } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { TransferModel } from '@models/transfer.model';
import { FiltersService } from '@components/filters/filters.service';
import { DeviceService } from '@services/device.service';
import { ClassFilterDateRange, ClassFilterDrop, IForClassForFilters } from '@components/filters/filters';
import { LinkPageComponent } from '@components/link-page/link-page.component';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import {
  FiltersSelectedIconComponent,
} from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { ProgressBarComponent } from '@components/progress-bar/progress-bar.component';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { RoundBalancePipe } from '@app/dir_group_assignor/payments/pipes/round-balance.pipe';
import { BtnComponent } from '@components/btn/btn.component';
import { GetChxSvgNamePipe } from '@components/__svg_img/svg/pipes/get-chx-svg-name.pipe';
import { NothingListComponent } from '@components/nothing-list/nothing-list.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { TLinkPaymentsItem } from '@app/dir_group_assignor/payments/modelsForPayment';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { ClassCeilTableHeader } from '@components/_table/meTable';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MeTableService } from '@components/_table/me-table.service';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';

export const tableHeaderOfficialsFees: Array<ClassCeilTableHeader> = [
  { text: 'Game', isChx: true },
  { text: 'Transaction Date' },
  { text: 'Payout Format' },
  { text: 'Officials Fees' },
  { text: 'Assignor Fees' },
  { text: 'Billed Amount' },
  { text: '' },
  { text: '' },
];

@UntilDestroy()
@Component({
  selector: 'app-payments-officials-fees',
  standalone: true,
  imports: [CommonModule, LinkPageComponent, DropdownComponent, FiltersSelectedIconComponent, FiltersComponent, FiltersSelectedComponent, SvgComponent, ProgressBarComponent, SvgAndTextComponent, RoundBalancePipe, BtnComponent, GetChxSvgNamePipe, NothingListComponent, FormsModule, InputCtrlComponent, WrapPageDirective, GetTableEmptyPipe],
  templateUrl: './payments-officials-fees.component.html',
  styleUrls: ['./payments-officials-fees.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FiltersService, MeTableService, SettingsRequestService], // for-filters=== for-table=== for-settings===
})
export class PaymentsOfficialsFeesComponent extends HelperClass implements IForClassForFilters, OnInit {
  readonly currentLink: TLinkPaymentsItem = { titleCase: 'Officials Fees', upperCase: 'officialsFees' };
  readonly tableHeader = tableHeaderOfficialsFees;
  // readonly class_ngContent_btn_for_nothingList = class_ngContent_btn_for_nothingList;
  isShowSumFees: boolean = false;

  constructor(
    private mainS: MainService,
    private apiCompetitionS: ApiCompetitionService,
    public payS: PaymentService,
    public router: Router,
    public otherS: OtherService,
    public deviceS: DeviceService,
    public filtersS: FiltersService, // for-filters===
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    // this.payS.getLinkPaymentsCamelCase(this.router.url);
    // this.createForm();
    // this.subscribeToForm();
    // this.subscribeToArrayCompetitions();
  }

  ngOnInit() {
    this.subscribeToArrCompetitions();
    this.setFilters(); // for-filters===
    this.subscribeToSearch();
  }

  getDataForHTML(): void {
    if (!this.settings?.competitions) return; // !!! здесь обязательно надо дождаться когда будет competition
    forkJoin(
      [
        this.payS.getProjectedOutgoings(this.settings),
        this.payS.getTransfersSum({ transferType: 'STRIPE' }),
        this.payS.getTransfersSum({ transferType: 'OFFLINE' }),
        this.payS.getTransfers(this.settings),
      ],
    ).pipe(untilDestroyed(this)).subscribe((res) => {
      // console.log('getDataForHTML :', res);
    });
  }

  // === FILTERS Search только для транзакций. Фильтры для всего ==========================================
  settings: ClassSettingsRequest = {}; // for-filters===
  setFilters(): void {
    const dateRange = new ClassFilterDateRange({ arrPeriods: this.payS.arrPeriods });
    const competitionFilterDrop = this.payS.competitionFilterDrop$.getValue();
    const seasons = new ClassFilterDrop({ typeFilter: 'seasons', disabled: true });
    const payoutFormat = new ClassFilterDrop({ typeFilter: 'payoutFormat' }); // arrayForDropdown: this.payS.arrPayoutFormatDrop
    this.filtersS.setFilters([dateRange, competitionFilterDrop, seasons, payoutFormat], false, false);
  }

  emitSettingRequest(settings: ClassSettingsRequest): void {
    const isNewSelectedCompetition = this.settings.competitions !== settings.competitions; // выбран новый компетишн в дропдауне
    this.settings = { ...this.settings, ...settings };
    if (isNewSelectedCompetition && settings.competitions) this.getSeasons();
    this.getDataForHTML();
  }

  subscribeToArrCompetitions(): void {
    this.payS.arrCompetition$.pipe(untilDestroyed(this)).subscribe((res) => {
      const competitionFilterDrop = this.payS.competitionFilterDrop$.getValue();
      const seasons = new ClassFilterDrop({ typeFilter: 'seasons', disabled: false });
      this.filtersS.updateAllFilters([competitionFilterDrop, seasons]);
    });
  }

  getSeasons(): void {
    const competitionId = this.settings.competitions!;
    this.apiCompetitionS.methodCompetitionSeasons({ id: competitionId }, 'get').toPromise()
      .then((res?: Pick<ClassCompetition, 'seasons'>) => {
        const seasons = new ClassFilterDrop({
          typeFilter: 'seasons',
          disabled: !res?.seasons?.length,
          arrayForDropdown: res?.seasons || [],
        });
        this.filtersS.updateFilter('seasons', seasons);
      })
      .catch(err => {
      });
  }

  // === SEARCH Search только для транзакций. Фильтры для всего ============================================
  subscribeToSearch(): void {
    this.subscribeToSearchFromHelperClass().pipe(
      switchMap((searchValue: string) => {
        this.settings.search = searchValue;
        // return this.payS.getTransfers(this.settings);
        return this.payS.getTransfers({ search: this.settings.search });
      }),
      untilDestroyed(this),
    ).subscribe((res) => {
    });
  }

  // === CHECKBOX SELECT ==================================
  selectItem(item: TransferModel, event: any): void {
    event.stopPropagation();
    this.selectItemFromHelperClass(item, this.payS.transfers$.getValue());
  }

  selectAll(): void {
    this.payS.transfers$.next(this.selectAllFromHelperClass(this.payS.transfers$.getValue()));
  }

}
