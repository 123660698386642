import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { finalize, Observable, of } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';
import { TPath_for_invitePeoples } from '@app/app.module';
import { HelperClass } from '@classes/Helper-Classes';
import { MainService } from '@services/main.service';
import { catchError, tap } from 'rxjs/operators';
import { TUserRoleUpperCase } from '@models/user';
import { ClassCompetition } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';

@Component({
  selector: 'copyLink[path][userRole]',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, SvgAndTextComponent, SvgComponent],
  templateUrl: './copy-link.component.html',
  styleUrls: ['./copy-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyLinkComponent extends HelperClass implements OnChanges {
  @Input() path!: TPath_for_invitePeoples;
  @Input() userRole!: TUserRoleUpperCase;
  @Input() competition?: ClassCompetition;
  @Input() showClock = false;
  @Input() disable?: boolean = false;

  constructor(
    private mainS: MainService,
    private apiCompetitionS: ApiCompetitionService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes?.userRole?.currentValue) {
    //   console.log('userRole :', this.userRole);
    // }
    // if (changes?.disable?.currentValue) {
    //   console.log('disable :', this.disable);
    // }
  }

  getLink(): void {
    // console.log('getLink disable:', this.disable);
    if (this.disable) return;
    if (this.startRequest()) return;
    let observable: Observable<any>;
    if (this.path === 'officials' || this.path === 'dashboard') {
      observable = this.mainS.getLinkOfficialToGroup(); // !!! для получения ссылки для регистрации судьи
    } else if (this.path === 'competitions/users') {
      observable = this.apiCompetitionS.getLink_for_inviteUserToCompetition(this.competition?.id!, this.userRole);
    } else if (this.path === 'groupProfile_userManagement') {
      observable = this.mainS.getLink_for_inviteUserToGroup(this.userRole); // !!! для получения ссылки для регистрации юзера (пока что только для админа)
    } else {
      console.error('CopyLinkComponent getLinkOfficialToGroup:', this.path);
    }

    const promis = observable!.pipe(
      tap(res => console.log('tap :', res)),
      finalize(() => this.endRequest()),
      catchError((err) => {
        this.endRequest();
        return of((err));
      }),
      untilDestroyed(this),
    ).toPromise();

    const clipboardItem = new ClipboardItem({
      'text/plain': promis.then((result) => {
        return new Promise(async (resolve) => {
          const textBlob = new Blob([result], { type: 'text/plain' });
          resolve(textBlob);
        })
      }),
    })

    navigator.clipboard.write([clipboardItem]);
  }
}
