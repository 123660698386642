<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
             } as obj' class='wrapper'>

  <div class='wrapper__title'>Cancelation Details</div>

  <div class='wrapper__content'>
    <field text='Date of Cancelation' class='wrapper__content__date'>
      {{ game?.gameAdjustmentStatus?.cancelDate!|date:'fullDate' }}
    </field>

    <field text='Reason' class='wrapper__content__reason'>
      {{ game|getMatTooltipCancelReason }}
    </field>

    <field *ngIf='!meS.OFFICIAL' text='Adjustment Status' class='wrapper__content__adjustmentStatus'>
      <app-dropdown [ngModel]='game?.gameAdjustmentStatus' [array]='(adjustmentStatusesS.arrContent$|async)!'
                    [disabled]='true'></app-dropdown>
    </field>
  </div>

</div>