import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BtnComponent } from '@components/btn/btn.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { ACCOUNT_METHODS } from '@shared/constants/account-methods.constant';

@Component({
  selector: 'app-instant-success-dialog',
  templateUrl: './instant-success-dialog.component.html',
  styleUrls: ['./instant-success-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    BtnComponent,
    HeadTitleComponent
  ],
})
export class InstantSuccessDialogComponent {
  @Input() popupType: 'payout' | 'paymentMethod' | 'oneTimePay' | 'autopayout' = 'paymentMethod'; 
  @Input() dataPopup!: { method: string; popupType: 'payout' | 'paymentMethod' | 'oneTimePay' | 'autopayout' };
  @Output() closeEvent = new EventEmitter<void>();
  method: string = ACCOUNT_METHODS.STANDARD;

  ACCOUNT_METHODS = ACCOUNT_METHODS;

  constructor(
    public router: Router,
    public cd: ChangeDetectorRef,
  ) {
    if(this.dataPopup){
      this.method = this.dataPopup.method;
      this.popupType = this.dataPopup.popupType || 'paymentMethod';
    }
  }

  close() {
  this.closeEvent.emit();
  }
}
