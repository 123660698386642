<div #elRef *ngIf='{ desktop: (deviceS.isDesktop$|async), mobile: (deviceS.isMobile$|async)} as obj' class='wrapperSortBy'>
  <ng-container *ngIf='obj.desktop'>
    <div class='titleSortBy'>Sort by</div>
    <meSvg [svgName]='"arrow_switch_vertical&18"' [matMenuTriggerFor]='menu' class='o-table__header__item__arrow sort-switcher'></meSvg>
  </ng-container>

  <ng-container *ngIf='obj.mobile'>
    <ng-container *ngIf='{stateInput: {value:sortValueForSendToServer,active:menuOpen_forMobile}|getStateInput} as obj'>
      <div class='o-drop-down o-drop-down--{{obj.stateInput}} o-w-100' [matMenuTriggerFor]='menu' #trigger='matMenuTrigger'
           [class.o-openDrop]='menuOpen_forMobile' (menuOpened)='menuOpen_forMobile=true' (menuClosed)='menuOpen_forMobile=false'>
        <span class='o-noWrap'>Sort By</span>
        <meSvg [svgName]='obj.stateInput|getSvgArrowForDropdown' [revV]='menuOpen_forMobile' class='o-mg-l-auto-important'></meSvg>
      </div>
    </ng-container>
  </ng-container>

<!-- yPosition='above' -->
  <mat-menu #menu='matMenu' xPosition='before'>
    <button [ngStyle]='(elRef|getWidthMatMenu)' class='hiddenMatOption'></button>
    <ul class='sort-wrapper'>
      <li *ngFor='let option of sortOptions' #el (click)='openSortOptions(el, option)'>
        <h4 class='sort-wrapper__title'> {{option.name}}</h4>
        <ul *ngIf='option.children && option.children.length > 0' class='sort-wrapper__item'>
          <li *ngFor='let childOption of option.children'
              [ngClass]="{'isSelected':childOption.isSelected, 'has-svg': !!childOption.svgName}"
              (click)='selectOption(childOption, option)'>
            <meSvg *ngIf='childOption.svgName' [w]='18' [h]='18' [svgName]='childOption.svgName'></meSvg>
            {{childOption.name}}
          </li>
        </ul>
      </li>
    </ul>
  </mat-menu>
</div>
