<div class="defaultPopupWrapper">
  <headTitle text="You're about to switch your payout schedule." typeText="text13-blueDark"
    svgRight='cross_grey&24' (svgRightEmit)="closePopup()">
  </headTitle>

  <div class="subTitle">
    <ng-container *ngIf="(data?.currentType === 'weekly' || data?.currentType === 'biweekly') && data?.newType === 'monthly'">
      Important: To take effect for the current month, this change must be made 7 days before the last day of the month.
      If you switch after this cut-off, the new schedule will start in next month’s payout cycle. Would you like to proceed?
    </ng-container>
    
    <ng-container class="subTitle" *ngIf="data?.currentType === 'monthly' && ( data?.newType === 'weekly' || data?.newType === 'biweekly')">
      Important: A $1.99 fee will apply per payout. To switch back to free monthly payouts, you must do so 7 days before
      the last day of the month for it to take effect this month. Otherwise, the change will apply next cycle.
    </ng-container>

    <ng-container class="subTitle" *ngIf="(data?.currentType === 'biweekly_transition_to_monthly' || data?.currentType === 'weekly_transition_to_monthly') && ( data?.newType === 'weekly' || data?.newType === 'biweekly')">
      Important: A $1.99 fee will apply per payout. To switch back to free monthly payouts, you must do so 7 days before
      the last day of the month for it to take effect this month. Otherwise, the change will apply next cycle.
    </ng-container>

  </div>


<div class="btnWrap">
    <btn text='Confirm Switch' bcg='blueDark' (click)='confirmChange()'></btn>
    <btn text='Cancel' bcg='white' (click)='closePopup()'></btn>
  </div>

</div>
