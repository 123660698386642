<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              isViewAll: (myWalletS.isViewAll$|async),
             } as obj' class='o-wrapPage wrapperWallet' wrapPage [widthPage]='myWalletS.widthPage'>

  <div class='o-content'>
    <ng-container *ngIf='!obj.isViewAll'>
      <div class="header" *ngIf="meS.OFFICIAL">
        <div>My Wallet</div>
        <img src='assets/reload.svg' (click)='otherS.reload()' />
      </div>
      <div class="header" *ngIf="!meS.OFFICIAL"> Assignor Fees </div>
      
      <div class='wallet-container'>
        <div class='wallet-block grey' *ngIf="obj.mobile">
          <div class='wallet-block__first-line'>
            <div class="payout payout-available">
              <div class='o-text4-wallet'>Available Payout</div>
              <div class='o-text0-wallet'>{{ (balance) | currency:'USD' }}</div>
            </div>
          </div>
          <btn text='Instant Payout' bcg='newGreen' svgRight="vector-instant" btnHeight='36'
            [disabled]='balance === 0 || !balance' (click)='onPayout()' class='o-mg-t-auto'></btn>

          <div style="display: flex; flex-direction: row; gap: 12px">
            <btn text='Schedule' bcg='grey_5' btnHeight='36' (click)='goToSchedulePage()' class='o-mg-t-auto'></btn>
            <btn text='Manage' bcg='grey_5' btnHeight='36' (click)='goToManagePage()' class='o-mg-t-auto'></btn>
          </div>

        </div>

        <div class='wallet-block grey' *ngIf="obj.desktop">
          <div class="payout-trasit-section" *ngIf="meS.OFFICIAL">
            <div class="payout">
              <div class='o-text4-wallet'>Available Payout</div>
              <div class='o-text0-wallet'>{{ (balance) | currency:'USD' }}</div>
            </div>
            <div class="payout">
              <div class='o-text4-wallet'>In transit to bank </div>
              <div class='o-text0-wallet'>{{intransit | currency: 'USD' }}</div>
            </div>
          </div>

          <div class="payout-trasit-section" *ngIf="!meS.OFFICIAL">
            <div class="payout">
              <div class='o-text4-wallet'>Total Assigning Fees </div>
              <div class='o-text0-wallet'>{{ totalAssigningFees | currency:'USD' }}</div>
            </div>
            <div class="payout">
              <div class='o-text4-wallet'>Available Payout</div>
              <div class='o-text0-wallet'>{{ (balance) | currency:'USD' }}</div>
            </div>
          </div>

          <div class="pay-button-section">
            <btn text='Instant Payout' bcg='newGreen' svgRight="vector-instant" btnHeight='44' 
              [disabled]='balance === 0 || !balance' (click)='onPayout()' class='o-mg-t-auto'></btn>

            <btn text='Schedule' bcg='grey_5' btnHeight='44' (click)='goToSchedulePage()' class='o-mg-t-auto'></btn>
            <btn text='Manage' bcg='grey_5' btnHeight='44' (click)='goToManagePage()' class='o-mg-t-auto'></btn>
          </div>

        </div>
        
        <!-- Show Auto Payout Banner if Enabled -->
        <div *ngIf="autoPayoutEnabled" class="auto-payout-banner">
          <span class="circle-container"></span>
          <span>Auto Payout: On</span>
          <!-- {{ autoPayoutType | titlecase }} -->
        </div>
        
      </div>
    </ng-container>
    <myWalletTableOfficial></myWalletTableOfficial>
  </div>
</div>