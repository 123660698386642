import { Pipe, PipeTransform } from '@angular/core';
import { TCurrentLink_announcements } from '@app/dir_group_assignor/announcements/models/announcements';
import { getTableEmpty_forPageAnnouncements, ITableEmpty } from '@components/_table/meTable';

@Pipe({ name: 'getTableEmptyForAnnouncements', standalone: true })
export class GetTableEmptyForAnnouncementsPipe implements PipeTransform {

  transform(search: string | undefined, selected_link_announcement: TCurrentLink_announcements, forTest?: string): ITableEmpty {
    return getTableEmpty_forPageAnnouncements(search, selected_link_announcement, forTest);
  }
}
