<div class="wrapper">
  <headTitle text='Needs Attention!' svgLeft='arrow_squareLeft&24' (svgLeftEmit)='goToBack()' typeText='text1-blueDark'></headTitle>
  <div class="attentionWrapper">
    <div *ngFor="let item of attentions"class="attentionWrapper-content">
      <div [innerHTML]="item.innerHTML"></div>
      <div class="attentionWrapper-content_buttons">
        <btn *ngFor="let btn of item.buttons" [text]='btn.text' [bcg]='btn.type' btnHeight='36'></btn>
      </div>
    </div>
  </div>
</div>
a
