<div class="info-list__item">
    <div class="info-list__item__title">
        {{title}} <div class="tooltip-container">
            <img src="assets/question/question_grey&16.svg" alt="tooltip">
            <div class="tooltip-content">{{helpTip}}</div>
        </div>
    </div>
    <div class="info-list__item__amount">
        {{amount}}
    </div>
</div>