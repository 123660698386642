import { Pipe, PipeTransform } from '@angular/core';
import { TSvgName } from '@components/__svg_img/svg/forSvg';
import { ClassUser } from '@models/user';
import { ClassGame } from '@app/dir_group_assignor/games/game';
import { UtilsService } from '@services/utils.service';

export interface ISvgOfficialAvailability {
  width?: number; // !!! for popupForContainerTooltip
  svgName?: TSvgName;
  tooltip?: string;
  textCenter?: boolean;
  // isUpperCaseForFirstLetters?: boolean; // !!! чтобы старый функционал не переделывать в директиве TooltipDirective
}

@Pipe({ name: 'getSvgOfficialAvailability', standalone: true })
export class GetSvgOfficialAvailabilityPipe implements PipeTransform {

  transform(game: ClassGame, official: ClassUser): ISvgOfficialAvailability | null {
    return UtilsService.getSvgOfficialAvailability(game, official);
  }

}
