import { BehaviorSubject, Observable } from 'rxjs';
import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { debounceTime, map } from 'rxjs/operators';
import { ClassSettingsRequest } from '@models/response-and-request';
import { FormControl, Validators } from '@angular/forms';
import { TStatusAssign, TStatusAssignLower } from '@app/dir_group_assignor/games/game';
import { ISortBy } from '@components/sortBy/sortBy';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export type TForPagination = 'updateCountResults' | 'updatePage' | 'sorting';

@UntilDestroy()
@Component({ template: '' })
export abstract class HelperClass implements AfterViewChecked {

  protected constructor(
    public cd: ChangeDetectorRef,
  ) {
    // console.log('=== HelperClass :', this.isFirstLoadPage)
  }

  // !!! === потом убрать это и найти другой способ. Пока что сделал так.
  // !!! === при переключении с десктопа на мобилу чтобы не было ошибки в консоли для search в фильтрах
  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  // === !!! isFirstLoadPage это первая загрузка страницы или нет. После ответа сервера нужно утсановить false ==============================
  isFirstLoadPageSub$ = new BehaviorSubject<boolean>(true);
  isFirstLoadPage$ = this.isFirstLoadPageSub$.asObservable();

  set isFirstLoadPage(isFirstLoadPage: boolean) {
    this.isFirstLoadPageSub$.next(isFirstLoadPage);
  }

  get isFirstLoadPage(): boolean {
    return this.isFirstLoadPageSub$.getValue();
  }

  // === SEARCH =======================
  private searchSub$ = new BehaviorSubject<string>(''); // были ли загружен данные с сервера. если true то уже получены данные с сервера
  search$ = this.searchSub$.asObservable();

  get search(): string {
    return this.searchSub$.getValue();
  }

  set search(value: string) {
    this.searchSub$.next(value);
  }

  subscribeToSearchFromHelperClass(): Observable<string> {
    return this.searchSub$.pipe(
      debounceTime(1000),
      // distinctUntilChanged(),
      untilDestroyed(this),
      map((searchValue: string) => {
        // !!! в играх номер игры может из одной цифры быть. if (searchValue?.trim() && searchValue?.trim()?.length >= 3) return searchValue?.trim();
        // else return '';
        return searchValue?.trim();
      }),
    );
  }

  // andrei delete later
  // === SEARCH 2 - иногда на странице 2 поиска. Например на странице Assign =======================
  private searchSub2$ = new BehaviorSubject<string>(''); // были ли загружен данные с сервера. если true то уже получены данные с сервера
  search2$ = this.searchSub2$.asObservable();

  get search2(): string {
    return this.searchSub2$.getValue();
  }

  set search2(value: string) {
    this.searchSub2$.next(value);
  }

  subscribeToSearch2FromHelperClass(): Observable<string> {
    return this.searchSub2$.pipe(
      debounceTime(1000),
      // distinctUntilChanged(),
      untilDestroyed(this),
      map((searchValue: string) => {
        // if (searchValue?.trim() && searchValue?.trim()?.length >= 3) return searchValue?.trim();
        // else return '';
        return searchValue?.trim();
      }),
    );
  }

  // === FOR REQUEST ========================= для любых экшн действий
  private reqPendingSub$ = new BehaviorSubject(false);

  get reqPending(): boolean {
    return this.reqPendingSub$.getValue();
  }

  set reqPending(value: boolean) {
    this.reqPendingSub$.next(value);
  }

  // !!! вызывать при отправке запроса на сервер
  startRequest(): boolean {
    if (this.reqPending) {
      return true;
    } else {
      this.reqPending = true;
      this.cd.detectChanges();
      return false;
    }
  }

  // !!! вызывать когда ответ с сервера получен
  endRequest(): void {
    this.reqPending = false;
    this.cd.detectChanges();
    this.cd.markForCheck();
  }

  // andrei delete later
  // === FOR LOADING DATA ========================= для загрузки данных с сервера
  loading$ = new BehaviorSubject(false);

  get loading(): boolean {
    return this.loading$.getValue();
  }

  private set loading(value: boolean) {
    this.loading$.next(value);
  }

  startLoading(): boolean {
    if (this.loading) {
      return true;
    } else {
      this.loading = true;
      // this.cd.detectChanges(); // вместо этого использовать  *ngIf='!(loading$|async); else spinner' ... потому что в конструкторе вызываю загрузку с сервера
      return false;
    }
  }

  endLoading(): void {
    this.loading = false;
    this.cd.markForCheck();
  }

  // andrei delete later
  // === CHECKBOX SELECT GAMES || USERS ==================================
  isSelectAll = false;
  private selectedItemsSub$ = new BehaviorSubject<Array<any>>([]);  // !!! массив объектов выбранных ClassUser | ClassGame | Transaction
  selectedItems$ = this.selectedItemsSub$.asObservable();

  get selectedItems(): Array<any> {
    return this.selectedItemsSub$.getValue();
  }

  set selectedItems(arrItems: Array<any>) {
    this.selectedItemsSub$.next(arrItems);
  }

  selectItemFromHelperClass(item: any, arrObj: Array<any>): void { // arrObj: Array<any> = [] // массив  ClassGame | ClassCompetition | ...
    item.isSelect = !item.isSelect;
    if (item.isSelect) {
      this.selectedItems = [...this.selectedItems, item];
    } else {
      this.selectedItems = this.selectedItems?.filter((el) => el?.id !== item?.id);
    }
    if (!this.selectedItems?.length) this.isSelectAll = false;
    if (this.selectedItems?.length === arrObj.length) this.isSelectAll = true; // если включены все
    this.cd.detectChanges();
  }

  selectAllFromHelperClass(arrObj: Array<any>): Array<any> {
    this.isSelectAll = !this.isSelectAll;
    const arrItems = arrObj.map((el) => ({ ...el, isSelect: this.isSelectAll }));
    this.selectedItems = arrItems?.filter((el: any) => el.isSelect) || [];
    if (!this.selectedItems?.length) this.isSelectAll = false;
    this.cd.detectChanges();
    return arrItems;
  }

  // выключить все чекбоксы
  selectOffAllItemsFromHelperClass(arrObj: Array<any>): Array<any> {
    this.isSelectAll = false;
    const arrItems = arrObj.map((el) => ({ ...el, isSelect: false }));
    this.selectedItems = [];
    this.cd.detectChanges();
    return arrItems;
  }

  resetSelectedItem(): void { // после загрузки нового массива с сервера
    this.selectedItems = [];
    this.isSelectAll = false;
    this.cd.detectChanges();
  }

  get idsSelected(): Array<string> {  // массив id выбранных
    return this.selectedItemsSub$.getValue()?.map((el) => el?.id!);
  }

  // !!! example this.ctrlReset([this.ctrl.gameType, this.ctrl.location], true)
  // !!! или так example this.ctrlReset([this.ctrl.gameType, this.ctrl.location], [true,false]);
  // ctrlReset(arrCtrl: Array<FormControl<any>>, isRequired: boolean = false): void {
  ctrlReset(arrCtrl: Array<FormControl<any>>, isRequired: Array<boolean> | boolean = false): void {
    arrCtrl?.forEach((el, idx) => {
      if (!el) return;
      el.reset();
      // el?.patchValue('');
      if (typeof isRequired == 'boolean' && isRequired) {
        el?.addValidators(Validators.required);
      } else if (!isRequired) {
        // el.removeValidators()
      } else { // !!! для массива [true, false]
        if (isRequired[idx]) el?.addValidators(Validators.required);
      }
      // if (isRequired) el?.addValidators(Validators.required);
      el?.updateValueAndValidity();
    });
    this.cd.detectChanges();
  }

  // andrei delete later
  // === PAGINATION & SORTING =====================
  paginationMethodHelperClass(type: TForPagination, settings: ClassSettingsRequest, size?: number, page?: number, sortBy?: ISortBy): ClassSettingsRequest {
    if (type == 'updateCountResults') {
      settings.size = size;
      settings.page = 1;
    }
    if (type == 'updatePage') settings.page = page;
    if (type == 'sorting') settings.sort = sortBy?.value!;
    if (!settings.search?.trim()) delete settings.search;
    return settings;
  }


  // === OTHER ===========================
  amountAddZero(value: number | string): string | number {
    if (value || value == '0') {
      // const valueStr = value?.toString()?.replace('$', '');
      const valueStr = value?.toString();
      return ((+valueStr)?.toFixed(2))?.toString();
    }
    return value;
  }

  // === FOR GAME STATUSES =========================
  // !!! из dropFormCtrl приходит ['Assigned / accepted', 'Assigned / unaccepted', 'Assigned / declined', 'Unassigned']
  // !!! в этом методе преобразуется в строку ACCEPTED,UNACCEPTED,DECLINED,UNASSIGNED для отправки на сервер
  prepareGameStatusesForSendToServer(statuses: Array<TStatusAssignLower>): string | null {
    if (!statuses?.length) return null;
    const arrStatuses: Array<TStatusAssign> = statuses.map((el: TStatusAssignLower) => {
      const statusUpperCase: TStatusAssign = el.toUpperCase() as TStatusAssign;
      if (statusUpperCase.includes('ASSIGNED') && statusUpperCase.includes('UNACCEPTED')) return 'UNACCEPTED';
      else if (statusUpperCase.includes('ASSIGNED') && statusUpperCase.includes('ACCEPTED')) return 'ACCEPTED';
      else if (statusUpperCase.includes('ASSIGNED') && statusUpperCase.includes('DECLINED')) return 'DECLINED';
      else if (statusUpperCase.includes('ASSIGNED') && statusUpperCase.includes('UNPUBLISHED')) return 'UNPUBLISHED';
      return statusUpperCase;
    }) as Array<TStatusAssign>;
    return arrStatuses.join(',');
  }

  // ngOnDestroy() {
  //   this.destroySub.next();
  // }
}

