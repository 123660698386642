<div class='wrapperTotalGamesDashboard__header'>
  {{ getTitleById(selectedTable) }}
  <btn *ngIf='(deviceS.isDesktop$ | async)'
       (click)='routeToMyGames()'
       [text]='viewAllText(gameData.length)'
       bcg='blueDark'
       btnHeight='36'></btn>
</div>
<div class='totalGames'>
  <div class='totalGames__header'>
    <div *ngFor='let itemHeader of headerData; let idx = index; let last = last'
         class='totalGames__header__item item_{{idx + 1}}'>
      {{ itemHeader.title }}
    </div>
  </div>
  <ng-container *ngIf='gameData?.length; else nothingList'>
    <div *ngFor='let gameItem of gameData.slice(0, 5); let idx = index'
         class='totalGames__itemTotalGame'
         [class.cancelled]="gameItem.gameStatus === 'CANCELLED'">
      <div *ngIf='{gameCANCELLED: gameItem.gameStatus == "CANCELLED"} as objGameItem'
           class='totalGames__itemTotalGame__game item_{{idx + 1 }}'>
        {{ gameItem.gameNumber }}
      </div>
      <div class='totalGames__itemTotalGame__date item_{{idx + 1 }}'>
        <dateFormatted [date]='gameItem.date!'
                       type='date'
                       format='EE, MMM d, yyy'></dateFormatted>
        <p class='totalGames__itemTotalGame__date__short'>
          <dateFormatted [date]='gameItem.date!'
                         type='time'
                         format='shortTime'></dateFormatted>
        </p>

        <div>{{ gameItem | getAgeGenderLevel:'ClassGame' }}</div>
        <span>{{ gameItem.competition?.competitionName || 'TBD' }}</span>
      </div>
      <div class='totalGames__itemTotalGame__location item_{{idx + 1 }}  ============unacceptedGames'>
        <span>{{ gameItem | getLocNameCourtName }}</span>
        <p [matTooltip]='gameItem | matTooltipForLocation'>
          {{ gameItem.location?.address | getLocationString:['city', 'state'] }}
        </p>

        <div class='o-table--itemColumn__twoLine'>
          <span>{{ gameItem.homeTeam?.teamName }}</span>
          <span>{{ gameItem.awayTeam?.teamName }}</span>
        </div>
      </div>
      <div class='totalGames__itemTotalGame__officials'>
        <div class='officials widthItem widthItem--4'>
          <ng-container *ngFor='let go of gameItem?.gameOfficials; let idx = index'>
            <ng-container *ngIf='{
                            ACCEPTED: go.status === "ACCEPTED",
                            UNACCEPTED: go.status === "UNACCEPTED",
                            DECLINED: go.status === "DECLINED",
                            UNASSIGNED: go.status === "UNASSIGNED"
                           } as goObj'>
              <div *ngIf='gameItem.isShowAllGO || idx < amountForShowAllGO'
                   class='officialsCeil__item'
                   [class.officialsCeil__item--itemSelected]='go.isSelect'
                   (click)="go.status === 'UNASSIGNED' ? selectOfficial(go, gameItem?.gameOfficials) : null">
                <div [id]="'index_' + idx"
                     [class.activeRole]='(go.official?.userId === meS.userId)'
                     class='officialsCeil__itemCdkDropList'>
                  <div class='officialsCeil__item__left o-text7'>
                    <meSvg [svgName]='go|getSvgForGoStatus'></meSvg>
                    <!-- Миша сказал первые 2 буквы показывать -->
                    Official #{{ go.officialPositionNumber }}
                    ({{ go.officialPositionName|getFirstLetterEachWord:2 }})
                  </div>
                  <div class='officialsCeil__item__right o-noWrap'>
                                        <span *ngIf="go.official && go.status !== 'UNASSIGNED'">{{
                                            go.official |
                                              getUserName
                                          }}</span>
                    <div *ngIf="go.status === 'UNASSIGNED'"
                         class='g-flex g-flex--align-center'>
                      <div *ngIf='go.officialFee; else noPriceTpl'
                           class='g-flex g-flex--align-center'>
                        <div>Available</div>
                        <div class='officialsCeil__fee o-text7'>{{ go.officialFee | currency }}
                        </div>
                      </div>
                      <ng-template #noPriceTpl>
                        <div>-</div>
                      </ng-template>
                    </div>

                    <meSvg svgName='arrow_chevronRight&24'
                           (click)='router.navigate([urlGameInfo, gameItem?.id])'>
                    </meSvg>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <div *ngIf='gameItem.gameOfficials && gameItem.gameOfficials.length > amountForShowAllGO'
               class='goOfficialActions goOfficialActions__btnLine2 o-text7'
               (click)='gameItem.isShowAllGO = !gameItem.isShowAllGO'>
            {{ gameItem.isShowAllGO ? "Show Less" : "Show More" }}
          </div>
        </div>
      </div>
      <div *ngIf='!unAcceptedSelfAssign'
           class='totalGames__itemTotalGame__controls'>
        <button (click)="setStatus($event, gameItem, 'ACCEPTED')"
                class='accept'>Accept
        </button>
        <button (click)="setStatus($event, gameItem, 'DECLINED')"
                class='decline'>Decline
        </button>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #nothingList>
  <!--  <nothingList [typePage]='selectedTable' [descriptionPage]='getDescriptionByProp(selectedTable)'-->
  <!--               [headerPage]='getTitleByProp(selectedTable, gamesHeaderTitles)'>-->
  <!--  <nothingList typePage='games'>-->
  <!--        <div class='buttons'>-->
  <!--            <btn text='Manage Availability'-->
  <!--                [routerLink]="['/planner']"-->
  <!--                [queryParams]="{type:'Availability'}"-->
  <!--                bcg='newGreen'-->
  <!--                (emit)='null'-->
  <!--                [btnWidth]=' 188 '-->
  <!--                [btnHeight]='48 '>-->
  <!--            </btn>-->
  <!--            <btn text='View Games'-->
  <!--                routerLink='/openGames'-->
  <!--                bcg='white'-->
  <!--                (emit)='null'-->
  <!--                [btnWidth]=' 132 '-->
  <!--                [btnHeight]=' 48 '>-->
  <!--            </btn>-->
  <!--        </div>-->
  <!--    </nothingList>-->

  <nothingList [tableEmpty]='filtersS.searchValue|getTableEmptyForDashboard:selectedTable'>
    <div class='buttons'>
      <btn text='Manage Availability' [routerLink]="['/planner']" [queryParams]="{type:'Availability'}" bcg='newGreen' (emit)='null'
           [btnWidth]='188' [btnHeight]='48'></btn>
      <btn text='View Games' routerLink='/openGames' bcg='white' (emit)='null' [btnWidth]='132' [btnHeight]='48'></btn>
    </div>
  </nothingList>
</ng-template>