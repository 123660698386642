import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '@services/utils.service';

@Pipe({ name: 'arrayFromNumber', standalone: true })
export class ArrayFromNumberPipe implements PipeTransform {

  transform(number?: number): Array<number> {
    if (typeof number !== 'number') {
      console.error('ArrayFromNumberPipe number :', number);
      return [];
    }
    if (number < 1) {
      console.error('ArrayFromNumberPipe. number должен быть больше нуля :', number);
      return [];
    }
    return UtilsService.arrayFromNumber(number);
  }

}
