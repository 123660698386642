import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import Median from 'median-js-bridge';
import { BtnComponent } from '@components/btn/btn.component';
import { AppleSigninData, AppleSigninParams, AuthorizationAppleService } from '@services/authorization.apple.service';
declare var LOG: any;

@Component({
  selector: 'app-apple-sing-up',
  standalone: true,
  imports: [CommonModule, BtnComponent],
  templateUrl: './apple-sing-up.component.html',
  styleUrls: ['./apple-sing-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppleSingUpComponent {
  @Input() typeOperation!: 'signup' | 'logout' | 'signin';
  @Output() appleOurResponse = new EventEmitter<any>();
  readonly isNativeApp = Median.isNativeApp();
  readonly isApple = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  readonly showNativeAppleSing;
  constructor(private httpAuthorization: AuthorizationAppleService) {
    this.showNativeAppleSing = this.isNativeApp && this.isApple;
  }

  appleLoginCallback(data: AppleSigninData) {
    try {
      LOG('appleLoginCallback start v3');
      LOG('appleLoginCallback start3' + JSON.stringify(data));

      const userClickCloseAppleSign = data?.error ? true : false;;
      LOG('appleLoginCallback start1 ' + userClickCloseAppleSign);
      if (userClickCloseAppleSign) {
        LOG('appleLoginCallback start userClickCloseAppleSign ');
        return;
      }
      LOG('appleLoginCallback 56756');
      if (this.typeOperation == 'signup') {
        this.httpAuthorization.appleSingUp(data).subscribe(x => this.appleOurResponse.emit(x));
      } else if (this.typeOperation == 'signin') {
        this.httpAuthorization.appleSingIn(data).subscribe(x => this.appleOurResponse.emit(x));
      }
    } catch (error) {
      LOG('appleLoginCallback error ' + error);
    }

    LOG('appleLoginCallback end');
  };

  signInWithApple(): void {
    // const data: AppleSigninData = {
    //   "state": null,
    //   "firstName": "Pavlo",
    //   "code": "c2e4fa0ad5f6041279f793e122a3c1d37.0.sstq.MtCGDcf1qLoTVREKlYSDtg",
    //   "middleName": null,
    //   "email": "wpavel123123@gmail.com",
    //   // "email": "monikasvilpe16+@gmail.com",
    //   "type": "apple",
    //   // "type": undefined,
    //   "idToken": "eyJraWQiOiJUOHRJSjF6U3JPIiwiYWxnIjoiUlMyNTYifQ.eyJpc3MiOiJodHRwczovL2FwcGxlaWQuYXBwbGUuY29tIiwiYXVkIjoiY29tLm5vdGNoLmlvcy5hcHAiLCJleHAiOjE3MzAzODk3MzYsImlhdCI6MTczMDMwMzMzNiwic3ViIjoiMDAwMjMwLjM3OTM1MDNkMzE3NjRhZGY4YzkwYmU0ODY3NzljMDgzLjE1NDgiLCJjX2hhc2giOiJZZlZ3ZktQLXlsUlJ1MFZ6LUJzQ3NnIiwiZW1haWwiOiJ3cGF2ZWwxMjMxMjNAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF1dGhfdGltZSI6MTczMDMwMzMzNiwibm9uY2Vfc3VwcG9ydGVkIjp0cnVlLCJyZWFsX3VzZXJfc3RhdHVzIjoyfQ.ONUTrxFmEYImdTyPTGiy4JhWmw2HK1Z5UPHdywqvltvJqEiePPTzJv1e1CvWVBdGtZeDupU0toejkgB53qyJxHNKdA2Hd8Q6pzvvPo4jHwaxpcTW5RUXRZDNxn72nW5RD5ivkPWhFGrjOOSHMTVVVBvfqZgDCK69xwGLHmPrJ4MZ4j1KUDAIDCMUxSg84mz5xrtwa1mAGXxytitTE04MKG-DLajrsW2kAvY7O2tD_BqYXZds0AKv2OKfqVw0tj-ryIa2Tpkkwc0i7dnjvPZS037r38PliMy0FUzHSI0Za3CFnoMf3aKmaLm-EdtWs6ED5Eb07in8SbDh8n06UhF3Iw",
    //   "lastName": "Varybrus"
    // };
    // // this.httpAuthorization.appleSingUp(data).subscribe(x => this.appleOurResponse.emit(x));
    // this.appleLoginCallback(data);
    // return;

    LOG("click signInWithApple");
    try {
      const appleSigninParams: AppleSigninParams = {
        callback: this.appleLoginCallback.bind(this),
        redirectUri: undefined,
        state: undefined,
      }
      Median.socialLogin.apple.login(appleSigninParams);
    } catch (error) {
      LOG("error signInWithApple " + error);
    }
    LOG("click end signInWithApple");
  }
}