import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeTableService } from '@components/_table/me-table.service';
import { CeilTableDirective } from '@components/_table/directives/ceil-table.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DeviceService } from '@services/device.service';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { TSvgName } from '@components/__svg_img/svg/forSvg';
import { TColor } from '@models/ICssStyles';

@Component({
  selector: 'app-tableCeil[idxCeil][text1]',
  standalone: true,
  imports: [CommonModule, CeilTableDirective, MatTooltipModule, SvgComponent],
  templateUrl: './table-ceil.component.html',
  styleUrls: ['./table-ceil.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableCeilComponent<T> {
  @Input() idxCeil!: number;
  @Input() text1?: string | number | null;
  @Input() text2?: string | number;
  @Input() matTooltipText?: string;

  @Input() isCentered?: boolean; // !!! если нужно сделать по центру. По дефолту к левому краю прижато

  @Input() colorText?: TColor;

  @Input() svgRightForMobile?: TSvgName; // example page games for mobile ceil===5

  @Input() replaceTitleFromHeader_forMobile?: string; // !!! для страницы openGames & assign, потому что там в хэдере для desktop двойное название

  @Input() commaForMobile = true; // !!! для мобилы ножно text1 + ', ' + text2 //Но для teams не нужна запятая. Поэтому для teams передаю false

  // !!! для мобилы для последней ячейки не должно быть бордера, т.к. после последней ячейки сразу кнопки (actions for table)
  // !!! бордер для ячеек есть только для мобилы. Поэтому создал этот флаг. // для desktop бордера для ячеек нет. Для desktop бордер на itemTable
  @Input() bordNone_forMobile = false;

  constructor(
    public meTableS: MeTableService<T>,
    public deviceS: DeviceService,
    public cd: ChangeDetectorRef,
  ) {
  }
}
