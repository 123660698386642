import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceService } from '@services/device.service';
import { MeTableService } from '@components/_table/me-table.service';
import { TPath, urlOpenGames } from '@app/app.module';
import { ITableEmpty } from '@components/_table/meTable';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { IsShowGifLoadingPipe } from '@components/_table/table-empty/is-show-gif-loading.pipe';
import { IsInfiniteLoadingPipe } from '@pipes/settings/is-infinite-loading.pipe';

// !!! если search и нет результата с бэка то новый текст без кнопки.
// !!! если search пустой то старый текст
@Component({
  selector: 'app-tableEmpty[tableEmpty]',
  standalone: true,
  imports: [CommonModule, SpinnerComponent, IsShowGifLoadingPipe, IsInfiniteLoadingPipe],
  templateUrl: './table-empty.component.html',
  styleUrls: ['./table-empty.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableEmptyComponent<T> implements OnChanges {
  @HostBinding('class.o-none') get hideThisComponent(): boolean {
    // !!! этот компонент виден если НЕТ данных с сервера и если загрузка уже завершена (!loadingTable)
    // !!! НО для мобилы для infiniteLoading при ожидании  ответа с севрера НЕ надо показывать этот компонент потому что спиннер для infiniteLoading есть в app-tableInfiniteLoading
    const is_infiniteLoading = this.settingsRequestS.check_infiniteLoading();
    const hiddenForMobile = is_infiniteLoading && this.meTableS.loadingTable && this.deviceS.isMobile;
    return (!!this.meTableS.arrContent?.length && !this.meTableS.loadingTable) || hiddenForMobile;
  }

  @Input() tableEmpty!: ITableEmpty;

  @Input() path?: TPath; // !!! для openGames "assets/calendar/calendar.gif"
  @Input() isRequestAssignOfficial?: boolean; // !!! для openGames
  // @Input() showGifLoading = false; // assets/calendar/calendar.gif

  // readonly class_ngContent_btn_for_nothingList = class_ngContent_btn_for_nothingList;

  readonly urlOpenGames = urlOpenGames;

  // @Output() btnEmit = new EventEmitter<null>();

  constructor(
    public settingsRequestS: SettingsRequestService, // for-settings===
    public meTableS: MeTableService<T>,
    // private router: Router,
    public deviceS: DeviceService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes?.path?.currentValue) {
    //   console.log('path :', this.path, this.path === urlOpenGames, urlOpenGames)
    // }
  }

  // btnClick(): void {
  //   if (this.meTableS.tableEmpty$.getValue()?.btn?.urlForNavigate) { // !!! если передал этот urlForNavigateForBtn то нужно в TableEmptyComponent вызывать router.navigate(['tableEmpty,urlForNavigateForBtn'])
  //     this.router.navigate([this.meTableS.tableEmpty$.getValue()?.btn?.urlForNavigate]);
  //   } else { // !!! если НЕ передал этот urlForNavigateForBtn то просто эмитить, чтобы в родительском компоненте вызвать какое-то действие
  //     this.btnEmit.emit();
  //   }
  // }
}
