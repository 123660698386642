<div class='defaultPopupWrapper'>
  <headTitle text='Edit Fee' svgRight='cross_grey&24' (svgRightEmit)='close(false)' typeText='text4-blueDark'></headTitle>
  <div class='defaultPopupWrapper__subTitle'>Update the official fee below and save changes.</div>

  <field text='Current Official Fee'>
    <inputCtrl [ngModel]='dataPopup.officialFee' [disabled]='true' [showDollar]='true'></inputCtrl>
  </field>
  <field text='Updated Official Fee'>
    <inputCtrl [(ngModel)]='newFee' [isNumber]='true' [showDollar]='true'></inputCtrl>
  </field>

  <btnWrap *ngIf='(deviceS.isDesktop$|async)'>
    <btn text='Cancel' bcg='white' [spinner]='reqPending' (emit)='close(false)'></btn>
    <btn text='Save Changes' bcg='blueDark' [disabled]='reqPending || !newFee' (emit)='submit()'></btn>
  </btnWrap>
  <btnWrap style="margin-top: 12px"  *ngIf='(deviceS.isMobile$|async)'>
    <btn text='Save Changes' bcg='blueDark' [disabled]='reqPending || !newFee' (emit)='submit()'></btn>
    <btn text='Cancel' bcg='white' [spinner]='reqPending' (emit)='close(false)'></btn>  
  </btnWrap>
</div>