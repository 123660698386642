import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass } from '@classes/Helper-Classes';
import { MainService } from '@services/main.service';
import { IResponse } from '@models/response-and-request';
import { Router } from '@angular/router';
import { ClassGame, ClassGameOfficial } from '@app/dir_group_assignor/games/game';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { MeService } from '@services/me.service';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { OtherService } from '@services/other.service';
import { DeviceService } from '@services/device.service';
import { IDataPopup, PopupService } from '@services/popup.service';
import {
  PopupForOfficialForRequestToRoleComponent,
} from '@components/__popup-windows/popup-for-official-for-request-to-role/popup-for-official-for-request-to-role.component';
import { FiltersService } from '@components/filters/filters.service';
import {
  ClassFilterDateRange,
  ClassFilterDrop,
  ClassFilterInput,
  IForClassForFilters,
  TAllInterfacesFilters,
} from '@components/filters/filters';
import { ClassCeilTableHeader, IForClassForTable } from '@components/_table/meTable';
import { MeTableService } from '@components/_table/me-table.service';
import { urlGameInfo, urlOpenGames } from '@app/app.module';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { CdkScrollable } from '@angular/cdk/overlay';
import { DataTableDirective } from '@components/_table/directives/data-table.directive';
import {
  FiltersSelectedIconComponent,
} from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import { TableHeaderComponent } from '@components/_table/table-header/table-header.component';
import { TableBtnNumberComponent } from '@components/_table/table-btn-number/table-btn-number.component';
import { ItemTableDirective } from '@components/_table/directives/item-table.directive';
import { CeilTableDirective } from '@components/_table/directives/ceil-table.directive';
import { TableDoubleCeilComponent } from '@components/_table/table-double-ceil/table-double-ceil.component';
import { GetAgeGenderLevelPipe } from '@pipes/get-age-gender-level.pipe';
import { GetLocationStringPipe } from '@pipes/location/get-location-string.pipe';
import { MatTooltipForLocationPipe } from '@pipes/location/mat-tooltip-for-location.pipe';
import { GetLocNameCourtNamePipe } from '@pipes/location/get-loc-name-court-name.pipe';
import { GetSvgForGoStatusPipe } from '@pipes/gameOfficial/get-svg-for-go-status.pipe';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { GetFirstLetterEachWordPipe } from '@pipes/string/get-first-letter-each-word.pipe';
import { GetUserNamePipe } from '@pipes/get-name.pipe';
import { TooltipDirective } from '@directives/tooltip.directive';
import { TableCeilComponent } from '@components/_table/table-ceil/table-ceil.component';
import { BtnComponent } from '@components/btn/btn.component';
import { IsSelectPipe } from '@pipes/array/is-select.pipe';
import { TableEmptyComponent } from '@components/_table/table-empty/table-empty.component';
import { TableInfiniteLoadingComponent } from '@components/_table/table-infinite-loading/table-infinite-loading.component';
import { PaginationWithSettingsComponent } from '@components/__settingsRequest/pagination-with-settings/pagination-with-settings.component';
import { LinkPageSearchFilterComponent } from '@components/__settingsRequest/link-page-search-filter/link-page-search-filter.component';
import { AdminPermissionDirective } from '@directives/admin-permission.directive';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';

@UntilDestroy()
@Component({
  selector: 'app-open-games',
  standalone: true,
  imports: [CommonModule, WrapPageDirective, CdkScrollable, DataTableDirective, FiltersSelectedIconComponent, FiltersComponent,
    FiltersSelectedComponent, TableHeaderComponent, TableBtnNumberComponent, ItemTableDirective, CeilTableDirective,
    TableDoubleCeilComponent, GetAgeGenderLevelPipe, GetLocationStringPipe, MatTooltipForLocationPipe, GetLocNameCourtNamePipe,
    GetSvgForGoStatusPipe, SvgComponent, GetFirstLetterEachWordPipe, GetUserNamePipe, TooltipDirective, TableCeilComponent,
    BtnComponent, IsSelectPipe, TableEmptyComponent, TableInfiniteLoadingComponent, PaginationWithSettingsComponent, LinkPageSearchFilterComponent, AdminPermissionDirective, GetTableEmptyPipe],
  templateUrl: './page-open-games.component.html',
  styleUrls: ['./page-open-games.component.scss'],
  providers: [FiltersService, MeTableService, SettingsRequestService], // for-filters=== for-table=== for-settings===
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// !!! IForClassForFilters for-filters=== // !!! IForClassForTable for-table===
export class PageOpenGamesComponent extends HelperClass implements IForClassForFilters, IForClassForTable<ClassGame> {
  dataTable$ = new BehaviorSubject<IResponse<ClassGame>>({}); // for-table===
  arrContent$ = new BehaviorSubject<Array<ClassGame>>([]); // for-table===
  readonly widthPage = 1300;
  // readonly class_ngContent_btn_for_nothingList = class_ngContent_btn_for_nothingList;
  readonly urlOpenGames = urlOpenGames;
  readonly urlGameInfo = urlGameInfo;
  amountForShowAllGO = 4;
  selectedGameId: string = '';

  isRequestAssignOfficial = false;
  readonly delayForRequest = 3200; // Чтобы анимация при запросе на сервер gif отобразилась минимум весь цикл
  officialHasNoAvailabilityByGame = false;

  constructor(
    public meS: MeService,
    private mainS: MainService,
    public gameS: GameService,
    public otherS: OtherService,
    public router: Router,
    public deviceS: DeviceService,
    private popupS: PopupService,
    public filtersS: FiltersService, // for-filters===
    public meTableS: MeTableService<ClassGame>, // for-table===
    private settingsRequestS: SettingsRequestService, // for-settings===
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.mainS.getOfficialByUserId(this.meS.userId).subscribe((official) => {
      this.officialHasNoAvailabilityByGame = !official.availabilities?.length || !official.availabilities?.some(
        a => a.enabled && (a.times?.length ?? 0) > 0
      );
      this.setAllData();
    });
  }

  // !!! если есть в фильтрах компетишн, то обязательно надо в AppModule к роуту прикрутить CompetitionsForFiltersResolver
  setAllData(): void {
    this.setDataForTable(); // for-table===
    this.setFilters(); // for-filters===
    this.settingsRequestS.setAllData(this.isFirstLoadPageSub$); // for-settingsDynamicUrl=== создание ссылки, чтобы можно было использовать в сервисе
    this.gameS.setCurrentLink_games(this.settingsRequestS.settings.currentLink_games); // for-currentLink===
    this.subscribeToSettings(); // for-settings===
  }

  // === SETTINGS ===============================
  subscribeToSettings(): void {
    this.settingsRequestS.settings$.pipe(
      // !!! myGames:false добавляется в get_defaultSettings_games()
      switchMap((res) => this.getSubject_forGetGamesList()),
      untilDestroyed(this),
    ).subscribe((res) => {
      this.cd.detectChanges();
    });
  }

  // === FILTERS ==========================================
  setFilters(): void {
    const search = new ClassFilterInput({ typeFilter: 'search' });
    const dateRange = new ClassFilterDateRange();
    const groupId = new ClassFilterDrop({ typeFilter: 'groupId', arrayForDropdown: this.meS.me?.officialGroups });
    const competition = new ClassFilterDrop({
      typeFilter: 'competitions',
      arrayForDropdown: this.gameS.arrCompetition$.getValue(),
      disabled: !this.settingsRequestS.settings.groupId,
      // valueDrop: this.gameS.arrCompetition$.getValue()?.find(el => el.id === this.settingsRequestS.settings.competitions),
    });
    const distance = new ClassFilterDrop({ typeFilter: 'distance' });
    const zipcode = new ClassFilterInput({ typeFilter: 'zipcode', valueString: this.meS.me?.address?.zipcode });
    const arrFilters: Array<TAllInterfacesFilters> = [search, dateRange, groupId, competition, distance, zipcode];
    this.filtersS.setFilters(arrFilters, true, true);
  }

  // === TABLE ======================================================
  setDataForTable(): void {
    this.meTableS.dataTable$ = this.dataTable$; // !!! создание ссылки. Чтобы можно было использовать в MeTableService
    this.meTableS.arrContent$ = this.arrContent$; // !!! создание ссылки. Чтобы можно было использовать в MeTableService
    this.meTableS.paddingLeft_forCeil = 16;
    const arrayCeilHeader: Array<ClassCeilTableHeader> = [
      new ClassCeilTableHeader({ text: 'Game' }),
      new ClassCeilTableHeader({ text: 'Date & Time / Age & Level', symbolSeparation: '/' }),
      new ClassCeilTableHeader({ text: 'Location & Teams', symbolSeparation: '&' }),
      new ClassCeilTableHeader({ text: 'Officials' }),
      new ClassCeilTableHeader({ sortBy: true }),
    ];
    const arrWidthCeilTable: Array<number> = [135, 261, 297, 486, 121]; // !!! ширина для каждой ячейки для Desktop => сумма должна совпадать с this.widthPage
    this.meTableS.setArrTypeSorting(['byGameNumber', 'byDate', 'byGameLocation']); // !!! for-sorting===
    this.meTableS.setDataForTable(arrWidthCeilTable, arrayCeilHeader, this.widthPage, true);
  }

  // === OTHER ACTIONS ============================
  selectOfficial(game: ClassGameOfficial, gameOfficials?: ClassGameOfficial[], gameId: string = ''): void {
    if (this.dataTable$.getValue()?.content && this.selectedGameId && this.selectedGameId !== gameId) {
      const selectedGame = this.dataTable$.getValue()?.content?.find(el => el.id === this.selectedGameId);
      selectedGame?.gameOfficials?.forEach(elem => elem.isSelect = false);
    }

    this.selectedGameId = gameId;
    const isSelected = game.isSelect;

    if (gameOfficials) {
      gameOfficials.forEach(it => it.isSelect = false);
    }

    game.isSelect = !isSelected;
  }

  requestAssignOfficial(gameOfficials?: ClassGameOfficial[]): void {
    if (!gameOfficials) return;
    const selected = gameOfficials.find(it => it.isSelect);
    if (selected?.id) {
      this.isRequestAssignOfficial = true;
      this.mainS.requestAssignOfficial({ gameOfficialId: selected.id }, false)
        .pipe(
          switchMap(res => this.getSubject_forGetGamesList()),
          untilDestroyed(this),
        )
        .subscribe((res) => {
          this.isRequestAssignOfficial = false;
          // this.cd.detectChanges();
        });
    }
  }

  requestForMobile_openPopup(arrGo?: Array<ClassGameOfficial>): void {
    const dataPopup: IDataPopup = { arrGo: arrGo?.filter(el => !el.official) };
    this.popupS.open(PopupForOfficialForRequestToRoleComponent, dataPopup).then((resPopup: boolean) => {
      if (!resPopup) return;
      this.isRequestAssignOfficial = true;
      this.getSubject_forGetGamesList().pipe(untilDestroyed(this))
        .subscribe((res) => {
          // this.cd.detectChanges();
          this.isRequestAssignOfficial = false;
        });
    });
  }

  private getSubject_forGetGamesList(): Observable<IResponse<ClassGame>> {
    return this.meTableS.getSubject_forGetGamesList(null, this.delayForRequest).pipe(untilDestroyed(this));
  }

}
