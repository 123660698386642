import { ChangeDetectorRef, Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { DeviceService } from '@services/device.service';
import { AssignService } from '@app/dir_group_assignor/assign/assign.service';
import { ClassGame, ClassGameOfficial } from '@app/dir_group_assignor/games/game';
import { ClassUser } from '@models/user';
import { IDataPopup_forPopupConfirm, PopupService } from '@services/popup.service';
import { UnsavedChangesService } from '@services/unsaved-changes.service';
import { MeTableService } from '@components/_table/me-table.service';
import { from } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MainService } from '@services/main.service';

// !!! NO DELETE THIS COMMENTS
// !!! official.availabilityByGame.availabilityStatus =>
// !!! 'NOT_SET', => question_grey_filled&24 (знак вопроса в сером круге)
// !!! 'AVAILABLE', => circle_chx2&16 (галочка в зеленом круге)
// !!! 'UNAVAILABLE', => circle_crossRed2&16 (крестик в зеленом круге)
// !!! 'ASSIGNED_TO_ANOTHER_GAME', => circle_chx7&16 (галочка в оранжевом круге)
// !!! === если в выбраную игру (selectedGame) асайнить судью со статусом official.availabilityByGame.availabilityStatus =>
// => NOT_SET || AVAILABLE || ASSIGNED_TO_ANOTHER_GAME => нет попап окна
// => UNAVAILABLE => есть попап окно textForPopupForAssign_2
// !!! === если игра НЕ выбрана ИЛИ если в другую игру (НЕ selectedGame) асайнить судью => с любым статусом есть попап окно textForPopupForAssign_1

@UntilDestroy()
@Directive({ selector: '[appDirectiveForAssignGameOfficial]', exportAs: 'appDirectiveForAssignGameOfficial', standalone: true })
export class DirectiveForAssignGameOfficialDirective {
  @Input() game!: ClassGame; // !!! ONLY for AssignGameOfficialComponent // !!! no exist in AssignOfficialAvailabilityComponent
  @Input() go!: ClassGameOfficial; // !!! ONLY for AssignGameOfficialComponent // !!! no exist in AssignOfficialAvailabilityComponent
  official!: ClassUser;

  readonly textForPopup_1 = 'You are assigning an official without checking their availability status.';
  readonly textForPopup_2 = 'The official has a direct game conflict or indicated they are unavailable on this date and time.';

  constructor(
    private mainS: MainService,
    private assignS: AssignService,
    private popupS: PopupService,
    private unsavedChangesS: UnsavedChangesService,
    private r: Renderer2,
    private elRef: ElementRef,
    private deviceS: DeviceService,
    private meTableS: MeTableService<ClassGame>, // for-table===
    private cd: ChangeDetectorRef,
  ) {
  }

  // !!! only FOR MOBILE => срабатывает когда нажал на судью
  selectOfficial_forMobile(official: ClassUser): void {
    // const isUserAssignedInGame_andSavedInServer = UtilsService.isUserAssignedInCurrentSelectedGame_andSavedInServer(this.assignS.selectedGame!, official.id!);
    // if (isUserAssignedInGame_andSavedInServer) return; // !!! если судья заасайнен и сохранен в текущую выбраную игру то нельзя его выбрать для ассайна
    this.official = official;
    this.game = this.assignS.selectedGame!;
    this.go = this.assignS.selectedArrGo[0];
    if (official?.id === this.go?.official?.id) return; // !!! если судья уже заасайнен на эту роль
    this.cdkDropListDropped_and_touchend();
    this.assignS.showTableOfficialsForMobile = false; // !!! закрыть окно с судьями
    this.cd.detectChanges();
  }

  // !!! срабатывает когда отпускаешь кнопку мыши (тоесть происходит ассайн судьи на роль)
  @HostListener('cdkDropListDropped', ['$event']) cdkDropListDropped(event: any) {
    const finalElem: HTMLDivElement = this.elRef.nativeElement;
    this.official = event.item?.data as ClassUser; // !!! перетаскиваемый судья
    this.removeCssClassesFromGO(finalElem);
    this.cdkDropListDropped_and_touchend();
  }

  cdkDropListDropped_and_touchend(): void {
    if (this.go?.official?.id === this.official?.id) return; // !!! если этот судья уже заасайнен в данной роли

    // !!! текущий выбраный gameId === поступившему gameItem.id (тоесть если в текущую выбраную игру происходит ассайн, то будет true)
    // !!! для мобилы это всегда будет true
    const currentSelectedGameId_isEqual_inputGameId = this.assignS.selectedGame?.id === this.game.id;
    const availabilityStatus = this.official.availabilityByGame?.availabilityStatus;

    // !!! если игра НЕ выбрана ИЛИ если в другую игру (НЕ selectedGame) асайнить судью => с любым статусом есть попап окно textForPopupForAssign_1
    if (!this.assignS.selectedGame || !currentSelectedGameId_isEqual_inputGameId) { // !!! only for desktop, потому что
      this.openPopupConfirm(this.textForPopup_1);
    } else { // !!! если в выбраную игру (selectedGame) асайнить судью со статусом official.availabilityByGame.availabilityStatus =>
      if (availabilityStatus === 'UNAVAILABLE') { // => UNAVAILABLE => есть попап окно textForPopupForAssign_2
        this.openPopupConfirm(this.textForPopup_2);
      } else { // => NOT_SET || AVAILABLE || ASSIGNED_TO_ANOTHER_GAME => нет попап окна
        this.assignOfficialInGO();
      }
    }
  }

  // !!! срабатывает когда наводишь элемент на конечный контейнер
  @HostListener('cdkDropListEntered', ['$event']) cdkDropListEntered(event: any) {
    if (this.game?.gameStatus == 'CLOSED') return; // !!! 5.12.23 нужно запретить все действия если игра CLOSED
    const finalElem: HTMLDivElement = this.elRef.nativeElement; // || event.container.element.nativeElement
    const official = event.item.data as ClassUser;
    if (this.go?.official?.id === official?.id) { // !!! если этот судья уже заасайнен в данной роли
      this.r.addClass(finalElem, 'banFinalElement');
    } else {
      this.r.addClass(finalElem, 'activeFinalElement');
    }
    this.cd.detectChanges();
  }

  // !!! срабатывает когда покидаешь конечный контейнер
  @HostListener('cdkDropListExited', ['$event']) cdkDropListExited(event: any) {
    if (this.game?.gameStatus == 'CLOSED') return; // !!! 5.12.23 нужно запретить все действия если игра CLOSED
    const finalElem: HTMLDivElement = this.elRef.nativeElement;
    this.removeCssClassesFromGO(finalElem);
    this.cd.detectChanges();
  }

  // !!! Для перетаскиваемого элемента. срабатывает когда начинаешь перетаскивать
  @HostListener('cdkDragStarted', ['$event']) cdkDragStarted(event: any) {
    this.official = event.source.data;
  }

  private openPopupConfirm(subTitleForPopupConfirm: string): void {
    const dataPopup: IDataPopup_forPopupConfirm = {
      textTitle: 'Are you sure you would like to proceed with this assignment?',
      text: subTitleForPopupConfirm,
      textBtnApply: 'Proceed',
      swapBtn: this.deviceS.isMobile,
      marginTopForBtn: 24,
    };

    from(this.popupS.openPopupConfirm(dataPopup, true)).pipe(untilDestroyed(this))
      .subscribe((resPopup: boolean) => {
        if (resPopup) this.assignOfficialInGO();
      });
  }

  private assignOfficialInGO(): void {
    this.assignS.haveDragDrop = true; // this.disabledBtn = false;
    this.unsavedChangesS.unsavedChanges = true;

    const findGame = this.assignS.arrContent$.getValue()?.find(el => el.id === this.game?.id) as ClassGame;
    if (!findGame) console.error('assignOfficialInGO() findGame :', findGame);

    // !!! массив ролей
    // !!! 1. роль в которой заасайнен текущий судья (которого хотят заасайнить в другую роль)
    // !!! 2. роль на которую хотят заасайнить судью, если на этой роли есть уже заасайненый другой судья
    // const arrayAssignedGO: Array<ClassGameOfficial | null> = findGame.gameOfficials?.map(el => {
    //   if (el.official_isSaveInServer) {
    //     if (this.official?.id === el.official?.id || el.id === this.go?.id) return el;
    //   }
    //   return null;
    // }).filter(Boolean) || [];
    // if (arrayAssignedGO?.length) {
    //   this.mainS.removeOfficials(arrayAssignedGO.map(el => el?.id!)).toPromise()
    //     .then((res) => this.cd.detectChanges())
    //     .catch((err) => {
    //     });
    // }

    const updated_go: ClassGameOfficial = { ...this.go, official: this.official, status: 'UNPUBLISHED' };
    const updated_gameOfficials: Array<ClassGameOfficial> = this.game.gameOfficials?.map(go => {
      if (updated_go.id === go.id) { // ассайн на новую роль
        return updated_go;
      } else {
        let updated_go_2 = go;
        // !!! перед тем как асайнить судью на новую роль, надо сначала удалить этого судью из прошлой роли.
        // !!! это нужно когда судья ещё не сохранен на сервере и этого судью асайнишь на другую роль
        // if (updated_go_2.official?.id === this.official.id && !updated_go_2.official_isSaveInServer) {
        if (updated_go_2.official?.id === this.official.id) {
          updated_go_2 = { ...updated_go_2, official: undefined, status: 'UNASSIGNED' };
        }
        return updated_go_2;
      }
    }) || [];
    const updated_game: ClassGame = { ...this.game, gameOfficials: updated_gameOfficials };
    this.meTableS.replaceElemArrayById(updated_game);
    this.cd.detectChanges();
  }

  private removeCssClassesFromGO(finalElem: HTMLDivElement): void {
    this.r.removeClass(finalElem, 'banFinalElement');
    this.r.removeClass(finalElem, 'activeFinalElement');
  }

}
