import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router, RouterLink } from '@angular/router';
import { HelperClass } from '@classes/Helper-Classes';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { ErrorComponent } from '@components/__info_text_message_error_warning/error/error.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { AuthService } from '../auth.service';
import { CustomValidators } from '@classes/CustomValidators';
import { BtnComponent } from '@components/btn/btn.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterLink,
    MatIconModule,
    MatProgressSpinnerModule,
    BtnComponent,
    ErrorComponent,
    FieldComponent,
    InputCtrlComponent,
    SvgComponent,
  ],
})

export class ForgotPasswordComponent extends HelperClass implements OnInit {
  form!: FormGroup<any>;
  isEmailSended: boolean = false;
  userEmail: string = '';
  errorMessage: string = '';
  isUserExists: boolean = false;
  cooldownTime: number = 15000;
  isResendDisabled: boolean = false;

  constructor(
    public cdr: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
  ) {
    super(cdr);
    this.createForm();
  }

  ngOnInit() {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((res => {
      this.errorMessage = '';
    }));
  }

  createForm(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, CustomValidators.patternEmail]],
    });
  }

  submitResetPassword() {
    if (!this.isResendDisabled) {
      this.isResendDisabled = true;

      setTimeout(() => {
        this.isResendDisabled = false;
        this.cdr.detectChanges();
      }, this.cooldownTime);

      const email = this.form.value?.email;
      this.errorMessage = '';

      if (!email) {
        return;
      }

      this.authService.resetPassword(email).pipe(untilDestroyed(this)).subscribe({
        next: (res: any) => this.handleSuccessResponse(res, email),
        error: (error: any) => this.handleErrorResponse(error),
      });
    }
  }

  private handleSuccessResponse(res: any, email: string) {
    if (res && res.status === 200) {
      this.isEmailSended = true;
      this.userEmail = email;
      this.cdr.detectChanges();
    } else {
      this.handleUnexpectedResponseStatus(res);
    }
  }

  private handleUnexpectedResponseStatus(res: any) {
    this.isEmailSended = false;
    console.error('Unexpected response status:', res ? res.status : 'unknown');
  }

  private handleErrorResponse(error: any) {
    this.errorMessage = error?.error?.message || 'The user does not exist';
    this.cdr.detectChanges();
    this.router.navigate(['/forgot-password']);
    console.error('Error:', error);
  }

  navigateToLogin() {
    this.router.navigate(['/login']).then(() => {
      window.location.href = '/login'; 
    });
  }
}
