import React from 'react';

export function ReactComponent({ embeddableToken, variables, onVariableUpdated, setLoadFinished }: 
                        { embeddableToken: string, variables: any | undefined,  
                          onVariableUpdated: (newVariables:any)=>void , setLoadFinished: (value:boolean)=>void 
                        }) {

    function Embeddable(props: { token: string, variables: any, onVariablesChange?: (newVariables:any)=>void, onComponentsLoad: ()=>void }) {
        const ref: any = React.useRef(null);
        function handleVariableChange(e: any) {
            if(props.onVariablesChange) {
                props.onVariablesChange(
                    Object.fromEntries(
                        e.detail.map((c: any) => [c.variableName, c.newValue])
                    )
                )
            }
        }
        React.useEffect(() => {
            if (ref.current) {
                ref.current.addEventListener('variablesChange', handleVariableChange);
                ref.current.addEventListener('componentsLoad', props.onComponentsLoad);
            }
            return () => {
                if (ref.current) {
                    ref.current.removeEventListener('componentsLoad', props.onComponentsLoad);
                    ref.current.removeEventListener('variablesChange', handleVariableChange);
                }
            };
                        
        }, []);
        return React.createElement('em-beddable', {
            ref,
            token: props.token,
            variables: JSON.stringify(props.variables)
        });
    }

    return (
        <div className='o-content' style={{ width: "100%" }}>            
            <Embeddable 
                token={embeddableToken} 
                variables={variables} 
                onVariablesChange={onVariableUpdated} 
                onComponentsLoad={() => setLoadFinished(true)}
            />            
        </div>
    )
}