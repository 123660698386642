import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { OtherService } from '@services/other.service';
import { BaseApi } from '@services/base-api';
import { Injectable } from '@angular/core';
import {
  AnnouncementItem,
  Announcements,
  arrCurrentLinkDrop_announcements,
  currentLink_all_announcements,
  currentLink_archived_announcements,
  currentLink_sent_announcements,
  ICurrentLink_announcements,
} from '@app/dir_group_assignor/announcements/models/announcements';

@Injectable()
export class AnnouncementsService extends BaseApi {
  readonly apiAnnouncement = '/api/notification/v1/announcement';
  readonly apiOfficials = '/api/core/officials/v1';

  // arrCurrentLink: Array<TAnnouncementLinks> = ['view all', 'sent', 'archived'];
  arrCurrentLinkDrop_announcements = arrCurrentLinkDrop_announcements;
  private currentLinkSub$ = new BehaviorSubject<ICurrentLink_announcements>({});
  currentLink$ = this.currentLinkSub$.asObservable();

  constructor(
    public httpClient: HttpClient,
    public otherService: OtherService,
  ) {
    super(httpClient, otherService);
  }

  updateCurrentLink(currentLink: ICurrentLink_announcements) {
    this.currentLinkSub$.next({ ...this.currentLinkSub$.getValue(), ...currentLink });
  }

  getListAnnouncement(paramsObject: { [key: string]: any; } = { page: 0, size: 10 }): Observable<Announcements> {
    const endPoint = `${this.apiAnnouncement}/all`;

    const params = this.buildHttpParams(paramsObject);

    return this.get(endPoint, { params }).pipe(
      map((announcements: Announcements) => announcements),
    );
  }

  getAnnouncementById(annoncementUUID: string): Observable<AnnouncementItem> {
    const endPoint = `${this.apiAnnouncement}/${annoncementUUID}`;

    return this.get(endPoint);
  }

  createAnnouncement(newAnnouncement: AnnouncementItem): Observable<any> {
    const url = `${this.apiAnnouncement}`;
    const formData = this.prepareAnnouncementData(newAnnouncement);

    return this.http.post(url, formData);
  }

  updateDraftAnnouncement(newAnnouncement: AnnouncementItem): Observable<AnnouncementItem> {
    return this.updateOrCreateDraftAnnouncement('put', newAnnouncement.id, newAnnouncement);
  }

  createDraftAnnouncement(announcementId: string, newAnnouncement: AnnouncementItem): Observable<AnnouncementItem> {
    return this.updateOrCreateDraftAnnouncement('post', announcementId, newAnnouncement);
  }

  deleteAnnouncement(announcementUUID: string): Observable<string> {
    const endPoint = `${this.apiAnnouncement}/${announcementUUID}`;
    return this.delete(endPoint);
  };

  deleteAllAnnouncement(before: string): Observable<null> {
    const endPoint = `${this.apiAnnouncement}/all`;
    return this.delete(endPoint);
  };

  draftAnnouncement(announcement: AnnouncementItem): Observable<string> {
    const endPoint = `${this.apiAnnouncement}/draft/${announcement.id}`;
    return this.put(endPoint, { ...announcement });
  }

  archiveAnnouncement(announcementIds: string[], restore?: boolean): Observable<null> {
    let queryString = announcementIds.map(id => `ids=${id}`).join('&');
    if(restore) {
      queryString += `&restore=${restore}`
    }
    const endPoint = `${this.apiAnnouncement}/archive?${queryString}`;
    return this.http.put<any>(endPoint, null);
  }

  getOfficialsLists(): Observable<any> {
    const endPoint = `${this.apiOfficials}/lists`;
    return this.http.get<any>(endPoint);
  }

  ///////////////////////////////////////////
  //////////// Private functions ///////////
  //////////////////////////////////////////

  private buildHttpParams(paramsObject: { [key: string]: any; }): HttpParams {
    let params = new HttpParams()
      .set('page', (paramsObject.page !== undefined ? paramsObject.page : 0).toString())
      .set('size', (paramsObject.size !== undefined ? paramsObject.size : 10).toString());

    for (const key in paramsObject) {
      if (paramsObject.hasOwnProperty(key) && key !== 'page' && key !== 'size') {
        params = params.set(key, paramsObject[key].toString());
      }
    }

    return params;
  }

  private updateOrCreateDraftAnnouncement(httpMethod: 'put' | 'post', announcementId: string, newAnnouncement: AnnouncementItem): Observable<any> {
    const url = `${this.apiAnnouncement}/draft/${announcementId}`;
    const formData = this.prepareAnnouncementData(newAnnouncement);

    return this.http[httpMethod](url, formData);
  }

  private prepareAnnouncementData(newAnnouncement: AnnouncementItem): FormData {
    if (newAnnouncement.text) {
      newAnnouncement.text = newAnnouncement.text.replace(/\n/g, '<br>');
      // if (!this.mainS.forProd) newAnnouncement.emails = ['sardaryanhrant@gmail.com']; // на прод не надо. Грант сказал
    }

    const formData = this.createFormData(newAnnouncement);
    this.appendFilesToFormData(newAnnouncement.files, formData);

    return formData;
  }

  private createFormData(newAnnouncement: AnnouncementItem): FormData {
    const formData = new FormData();
    const jsonBlob = new Blob([JSON.stringify(newAnnouncement)], { type: 'application/json' });
    formData.append('json', jsonBlob);
    return formData;
  }

  private appendFilesToFormData(files: File[] | undefined, formData: FormData): void {
    files?.forEach((file: File) => formData.append('file', file, file.name));
  }

  // === GETTERS & SETTERS ==========================================
  set currentLinkObj(currentLinkObj: ICurrentLink_announcements) {
    const currentLink = currentLinkObj.currentLink;
    const newCurrentLink: ICurrentLink_announcements = { currentLink };
    this.currentLinkSub$.next(newCurrentLink);
  }

  get currentLinkObj(): ICurrentLink_announcements {
    return this.currentLinkSub$.getValue();
  }

  get is_currentLink_all(): boolean {
    return this.currentLinkObj.currentLink?.upperCase === currentLink_all_announcements;
  }

  get is_currentLink_sent(): boolean {
    return this.currentLinkObj.currentLink?.upperCase === currentLink_sent_announcements;
  }

  get is_currentLink_archived(): boolean {
    return this.currentLinkObj.currentLink?.upperCase === currentLink_archived_announcements;
  }
}
