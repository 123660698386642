import Median from 'median-js-bridge';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnComponent } from '@components/btn/btn.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { GoogleSignUpComponent } from '@components/google/components/google-sign-up/google-sign-up.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { InvalidControlsPipe } from '@pipes/invalid-controls.pipe';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { IResFromGoogleForSignup } from '@services/google-people.service';
import { ClassUser, TUserSignup } from '@models/user';
import { HelperClass } from '@classes/Helper-Classes';
import { AuthService } from '@app/auth/auth.service';
import { AuthorizationClientType, MainService } from '@services/main.service';
import { MeService } from '@services/me.service';
import { LocationService } from '@components/__blocks/location/location.service';
import { CustomValidators } from '@classes/CustomValidators';
import { OtherService } from '@services/other.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppleSingUpComponent } from '@app/auth/login/apple-sing-up/apple-sing-up.component';
import { GoogleAuthorizationComponent, IGoogleNativeResponse } from "../../login/google-authorization/google-authorization.component";
import { ILoginResponseDto } from '@app/auth/auth_models';

declare var LOG: any;
interface IForm {
  email: FormControl<string>;
  password: FormControl<string>;
  confirmPassword: FormControl<string>;
}

@UntilDestroy()
@Component({
  selector: 'emailPassStep',
  standalone: true,
  imports: [CommonModule, BtnComponent, FieldComponent, GoogleSignUpComponent, InputCtrlComponent, InvalidControlsPipe, ReactiveFormsModule, RouterLink, AppleSingUpComponent, GoogleAuthorizationComponent],
  templateUrl: './email-pass-step.component.html',
  styleUrls: ['./email-pass-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailPassStepComponent extends HelperClass {
  form!: FormGroup<IForm>;
  viewPassword = false;

  // !!! если TUserSignup то вход регистрация без гугла. Если IResponseEmailPass то с помощью гугла
  @Output() emit = new EventEmitter<ClassUser>();
  readonly isNativeApp = Median.isNativeApp();
  constructor(
    public authS: AuthService,
    private formBuilder: UntypedFormBuilder,
    public mainS: MainService,
    private meS: MeService,
    public locationS: LocationService,
    // public routesS: RoutesService,
    public router: Router,
    public otherS: OtherService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.createForm();
    // this.subscribeToForm();
  }

  // goToLogin(): void {
  //   this.router.navigate(['/login']);
  // }

  goToLogin(): void {
    const currentUrlTree = this.router.parseUrl(this.router.url);
    const queryParams = { ...currentUrlTree.queryParams };

    this.router.navigate(['/login'], { queryParams });
  }

  signUp(): void {
    if (this.startRequest()) return;
    let sendObj: Pick<ClassUser, 'email' | 'password'> = { email: this.ctrl.email.value, password: this.ctrl.password.value };

    this.mainS.signUp(sendObj).toPromise()
      .then((res?: TUserSignup) => {
        if (res?.status == 'REGISTERED' && res?.id && res?.emailValidated && res?.phoneValidated) {
          this.mainS.confirmMe();
          this.endRequest();
          return;
        }
        this.emit.emit({ ...res, email: this.ctrl.email.value });
      })
      .catch((err) => {
      })
      .finally(() => this.endRequest());
  }

  signupWithGoogleNative(res: IGoogleNativeResponse): void {
    LOG("signupWithGoogleNative");
    this.signupWithGoogle({ id_token: res.idToken }, res.clientType);
  }

  signupWithGoogle(resFromGoogleForSignup: IResFromGoogleForSignup, clientType: AuthorizationClientType): void {
    LOG("signupWithGoogle resFromGoogleForSignup=" + JSON.stringify(resFromGoogleForSignup))
    if (this.startRequest()) return;
    const sendObj: Pick<ClassUser, 'email'> = { email: resFromGoogleForSignup?.id_token! };
    this.mainS.signupWithGoogle(sendObj, clientType).toPromise()
      .then((res?: TUserSignup) => {
        const { fullName: preferredName, givenName: firstName, familyName: secondName, email } = resFromGoogleForSignup;
        const meUser: ClassUser = { ...res, firstName, secondName, preferredName, email, id: res?.id };
        this.meS.setMeUser(meUser, 'SignupComponent signupWithGoogle');
        // if (res?.status == 'REGISTERED' && res?.id && res?.emailValidated && res?.phoneValidated) {
        if (res?.status == 'REGISTERED') {
          this.mainS.confirmMe();
          this.endRequest();
          return;
        }
        this.ctrl.email.patchValue(email!);
        this.emit.emit({ ...meUser, emailValidated: true });
      })
      .catch((err) => {
      })
      .finally(() => {
        this.form.markAsUntouched();
        this.endRequest();
      });
  }

  ourAuthorizationResponse(res: ClassUser): void {
    LOG("appleOurResponse start res=" + JSON.stringify(res))
    if (res == null)
      return;
    if (this.startRequest())
      return;
    try {
      const meUser: ClassUser = res;
      this.meS.setMeUser(meUser, 'SignupComponent signupWithApple');
      LOG("appleOurResponse 1")
      // if (res?.status == 'REGISTERED' && res?.id && res?.emailValidated && res?.phoneValidated) {
      if (res?.status == 'REGISTERED') {
        this.mainS.confirmMe();
        this.endRequest();
        return;
      }
      this.ctrl.email.patchValue(res.email!);
      this.emit.emit({ ...meUser, emailValidated: true });
      LOG("appleOurResponse 2 end")
    }
    finally {
      this.form.markAsUntouched();
      this.endRequest();
    }
    LOG("appleOurResponse END")
  }

  createForm(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, CustomValidators.patternEmail]],
      password: ['', [Validators.required, Validators.minLength(7), CustomValidators.patternPassword]],
      confirmPassword: ['', [Validators.required, Validators.minLength(7), CustomValidators.patternPassword]],
    }, { validators: CustomValidators.password });
  }

  get ctrl(): IForm {
    return this.form?.controls;
  }
}

