import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-forTest',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './for-test.component.html',
  styleUrls: ['./for-test.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForTestComponent {
  constructor() {
  }
}
