import { createReducer, on } from "@ngrx/store";
import { initialDashboardState } from "@app/store/dashboard/states/initial.states";
import { DashboardSubAssignorActions } from "@app/store/dashboard/actions/dashboard.actions";

export const dashboardGameTableReducer = createReducer(
    initialDashboardState.dashboardGameTable,
    on(DashboardSubAssignorActions.setTotalTableElements, (state, {totalGames}) => ({
        ...state,
        totalGames: totalGames
    })),
    on(DashboardSubAssignorActions.loadAllGamesActionSuccess, (state, {games}) => ({
        // Передача параметров в стор
        ...state,
        games: games
    })),
);
