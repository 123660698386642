<div *ngIf='{ currentCtrl_isPassword: arrNameCtrlForPassword.includes(nameField.toLowerCase()),
            } as obj' class='wrapInput' [style]='{w:w}|getStyles'>
  <!--  Тут для себя комментарий-->
  <!--    То что передается в height цифрой используется как название класса-->
  <mat-form-field #matFormFieldRef appearance='outline' class='o-matForm {{"o-matForm--"+bcg}} {{"o-matForm--"+type}}
                {{height ? "o-matForm--"+ height : "" }}
                {{"o-matForm--"+({value:value,active:active,disabled:disabled,error:error}|getStateInput:forTest)}}'>

    <input *ngIf='type == "input"' disableAutofill matInput #inputRef [(ngModel)]='value'
           [placeholder]='strCase ? (placeholder|strCase:"capitalize") : placeholder' [maxLength]='maxLength'
           [ngClass]='bcg' [class.o-text-center]='isTextCenter'
           [type]='obj.currentCtrl_isPassword && !viewPassword ? "password":"text"'
           [style]='{fs:fontSize}|getStyles' [mask]='mask' [patterns]='patterns' [validation]='true'
           [specialCharacters]='specialCharacters'
           [matTooltip]='matTooltip' />

    <textarea *ngIf='type == "textarea"' matInput #inputRef [(ngModel)]='value' [placeholder]='placeholder'
              [maxLength]='maxLength' [ngClass]='bcg' [cdkTextareaAutosize]='!fixedHeightWithScroll' #autosize='cdkTextareaAutosize'
              [cdkAutosizeMinRows]='fixedHeightWithScroll ? "" : 1' [cdkAutosizeMaxRows]='fixedHeightWithScroll ? "" : 12'
              [class]="fixedHeightWithScroll ? 'fixedHeightWithScroll' : ''"></textarea>

    <mat-icon *ngIf='matIconPrefix' matPrefix>{{ matIconPrefix }}</mat-icon>
    <mat-icon *ngIf='matIconSuffix' matSuffix>{{ matIconSuffix }}</mat-icon>
    <mat-icon *ngIf='obj.currentCtrl_isPassword'
              (click)='viewPasswordEmit.emit(!viewPassword);viewPassword = !viewPassword' matSuffix
              class='o-cur-pointer'>{{ viewPassword ? 'visibility' : 'visibility_off' }}
    </mat-icon>

    <!--  !!!  <span *ngIf='showDollar && (value || value=="0") && !isTextCenter' matTextPrefix=''>$</span>-->
    <!--    [matTooltip]='matTooltip_for_svgPrefix'-->
    <meSvg *ngIf='svgPrefix' matPrefix [svgName]='svgPrefix' [w]='widthSvgPrefix'
           [stateInput]='({value:value,active:active,disabled:disabled,error:error}|getStateInput)'
    ></meSvg>
    <meSvg *ngIf='svgSuffix' matSuffix [svgName]='svgSuffix' [w]='widthSvgSuffix'
           [stateInput]='({value:value,active:active,disabled:disabled,error:error}|getStateInput)'
           (click)='svgRightClick($event)'></meSvg>
  </mat-form-field>

  <div #errorFromNgContent>
    <ng-content select='error'></ng-content>
  </div>

  <ng-container *ngIf='showErrText'>
    <error *ngIf='errRequired' [text]='textRequired' class='errRequired'></error>
    <error *ngIf='errMinlength' class='errMinlength'
           text='You entered {{ngControl.control?.errors?.minlength?.actualLength}} characters. The minimum number of characters must be {{ngControl.control?.errors?.minlength?.requiredLength}}.'>
    </error>
    <error *ngIf='errMaxlength' text='{{nameField|titlecase}} must be at most {{maxLength-1}} characters.' class='errMaxlength'></error>
    <error *ngIf='!!value && errFeeInvalid' text='The amount of fee must be at least 50 cents.'></error>
    <error *ngIf='validatorError' [text]='ngControl.control?.errors?.validatorError' class='validatorError'></error>
  </ng-container>

  <ng-content select='.ngContent_inputCtrl'></ng-content>
</div>
