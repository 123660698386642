import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-info-list-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './info-list-item.component.html',
  styleUrls: ['./info-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoListItemComponent {
  @Input() title = "";
  @Input() amount: string | null = "";
  @Input() helpTip = "";
}
