import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  arrTypeFilter_forDateRange,
  arrTypeFilter_forDrop,
  arrTypeFilter_forInput,
  ClassFilterDateRange,
  ClassFilterDrop,
  ClassFilterInput,
  TAllInterfacesFilters,
  TypeFilter,
  TypeFilter_dateRange,
  TypeFilter_drop,
  TypeFilter_string,
  TypesAllFilters,
} from '@components/filters/filters';
import {
  ClassDrop,
  getCurrentAgeItemByFromAgeAndToAge,
  getCurrentDistanceItem,
} from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { customDateRange } from '@components/__drop_inputs_matSelect/date-range/dateRange';
import { MeService } from '@services/me.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { arrDropStatusAssign, getArrDropStatusAssignFromString } from '@app/dir_group_assignor/games/game';
import { arrPayoutFormatDrop, ClassCompetition } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { UtilsService } from '@services/utils.service';
import { ClassGroupListItem } from '@models/IGroup';
import { DeviceService } from '@services/device.service';
import { TypeSortTable } from '@components/sortBy/sortBy';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { TPath } from '@app/app.module';

@UntilDestroy()
@Injectable()
export class FiltersService {
  filtersRef?: FiltersComponent;

  needUseDynamicFilters = false;

  showFilters_forMobile$ = new BehaviorSubject<boolean>(false); // !!! for mobile => Show filter selection window
  showSelectedFilters = false; // !!! показать выбраные фильтры (for desktop)
  sortBy_forMobile = false; // !!! нужно ли показывать сортировку для мобилы
  selected_sortBy?: TypeSortTable; // !!! текущий выбраный sortBy

  // !!! all filters that are on the current page
  private arrFiltersSub$ = new BehaviorSubject<Array<TAllInterfacesFilters>>([]);
  arrFilters$ = this.arrFiltersSub$.asObservable();

  readonly matTooltip_for_search = 'Jump to game numbers, locations, team and official names';
  readonly arrPathExcludes_for_matTooltip_for_search: Array<TPath> = ['competitions', 'officials']; // !!! для этих страниц НЕ надо показывать matTooltip_for_search

  get arrFilters(): Array<TAllInterfacesFilters> {
    return this.arrFiltersSub$.getValue();
  }

  get searchValue(): string | undefined {
    const searchFilter = this.arrFiltersSub$.getValue().find((el) => el.typeFilter === 'search') as ClassFilterInput | undefined;
    return searchFilter?.valueString;
  }

  constructor(
    private deviceS: DeviceService,
    private meS: MeService,
    private gameS: GameService,
    private settingsRequestS: SettingsRequestService, // for-settings===
  ) {
    this.subscribeToDevice();
    this.subscribeToSettings();
  }

  subscribeToDevice(): void {
    this.deviceS.isDesktop$.pipe(untilDestroyed(this)).subscribe((isDesktop) => {
      if (isDesktop) this.showFilters_forMobile$.next(false); // !!! When switching to a desktop, you need to close the window with filters
    });
  }

  // !!! andrei эта проверка иногда не работает. потом проверить когда время будет
  checkExistChangesFilter(newFilter: TAllInterfacesFilters): boolean {
    const currentFilter = this.arrFiltersSub$.getValue().find(el => el.typeFilter === newFilter.typeFilter);
    const existChanges = !UtilsService.compareTwoObjects(
      UtilsService.removeEmptyKeysFromObject(currentFilter!),
      UtilsService.removeEmptyKeysFromObject(newFilter),
    );
    return existChanges;
  }

  // === SETTINGS ===========================
  async subscribeToSettings(): Promise<void> {
    this.settingsRequestS.settingsSub$.pipe(untilDestroyed(this)).subscribe(async (res) => {
      // !!! === for sortBy === чтобы для мобилы показывать сортировку как выбраный фильтр
      if (res.sort) {
        this.selected_sortBy = res.sort as TypeSortTable;
      } else {
        this.selected_sortBy = undefined;
      }
      // console.log('subscribeToSettings selected_sortBy :', this.selected_sortBy)

      const currentArrFilters = this.arrFiltersSub$.getValue();
      // console.log('subscribeToSettings :', Object.entries(res), currentArrFilters);
      let newArrFilters: Array<TAllInterfacesFilters> = [];
      if (res.search) {
        const currentFilter = currentArrFilters.find(el => el.typeFilter === 'search');
        if (!currentFilter) return;
        const newFilter = new ClassFilterInput({ ...currentFilter as ClassFilterInput, valueString: res.search });
        if (this.checkExistChangesFilter(newFilter)) newArrFilters.push(newFilter);
      } else {
        const filterWithoutValue = this.resetValueDrop('search'); // !!! сбрасывать значение в дропдауне после нажатия на кнопку "назад" в браузере
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }

      if (res.zipcode) {
        const currentFilter = currentArrFilters.find(el => el.typeFilter === 'zipcode');
        if (!currentFilter) return;
        const newFilter = new ClassFilterInput({ ...currentFilter as ClassFilterInput, valueString: res.zipcode });
        if (this.checkExistChangesFilter(newFilter)) newArrFilters.push(newFilter);
      } else {
        const filterWithoutValue = this.resetValueDrop('zipcode'); // !!! сбрасывать значение в дропдауне после нажатия на кнопку "назад" в браузере
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }

      if (res.from || res.to) {
        const currentFilter = currentArrFilters.find(el => el.typeFilter === 'dateRange') as ClassFilterDateRange;
        if (!currentFilter) return;
        const newFilter = new ClassFilterDateRange({
          ...currentFilter as ClassFilterDateRange,
          typePeriod: res.currentLink_games || 'current',
          period: 'Custom Date Range',
          datePeriod: {},
          // arrPeriods: currentFilter?.typePeriod === "current" ? arrPeriodsForCurrent : arrPeriodsForPast,
        });
        // if (currentFilter?.typePeriod) { // !!! при переключении current/past чтобы изменялся выпадающий список
        //   newFilter.arrPeriods = currentFilter?.typePeriod === 'current' ? arrPeriodsForCurrent : arrPeriodsForPast;
        // }
        if (res.from) newFilter.datePeriod!.from = res.from;
        if (res.to) newFilter.datePeriod!.to = res.to;
        // !!! this.settingsRequestS.is_currentPath_games => есть по дефолту from||to
        // !!! this.settingsRequestS.is_currentPath_reports
        // !!! this.settingsRequestS.is_currentPath_officials => нет вообще на странице from||to

        // === !!! 28.03.24 убрал это. Причина = Кнопка назад в браузере
        // if (this.settingsRequestS.is_currentPath_games) {
        //   console.log('res.from :', res.from)
        //   console.log('res.to :', res.to)
        // }
        if (this.settingsRequestS.is_currentPath_games && res.from && res.to) { // было так if (res.from && res.to)
          newFilter.fromTo_formatted = UtilsService.check_fromTo_formatted({ from: res.from, to: res.to });
          // console.log('1111111111111:', newFilter.fromTo_formatted)
        } else if (this.settingsRequestS.is_currentPath_games && (res.from || res.to)) { // === !!! 28.03.24 добавил это. Причина = Кнопка назад в браузере
          // delete newFilter.fromTo_formatted
          newFilter.fromTo_formatted = UtilsService.check_fromTo_formatted({ from: res.from, to: res.to });
          // console.log('2222222222:', newFilter.fromTo_formatted)
        }
        if (this.settingsRequestS.is_currentPath_reports && (res.from || res.to)) {
          newFilter.fromTo_formatted = UtilsService.check_fromTo_formatted({ from: res.from, to: res.to });
          // console.log('newFilter.fromTo_formatted 222:', newFilter.fromTo_formatted)
        }
        // if (this.settingsRequestS.is_currentPath_games || this.settingsRequestS.is_currentPath_reports) {
        //   newFilter.fromTo_formatted = UtilsService.check_fromTo_formatted({ from: res.from, to: res.to });
        //   // console.log('newFilter.fromTo_formatted 111:', newFilter.fromTo_formatted)
        // }


        // if (newFilter) console.log('newFilter :', Object.entries(newFilter))
        // назначить fromTo_formatted = если res.from===defaultSettings.from || res.to===defaultSettings.to
        if (this.checkExistChangesFilter(newFilter)) {
          // console.log('newFilter :', Object.entries(newFilter))
          newArrFilters.push(newFilter);
        } else {
          // console.log('НЕТ ИЗМЕНЕНИЙ В ТЕКЩЕМ ФИЛЬТРЕ newFilter :', Object.entries(newFilter))
        }
      } else {
        const filterWithoutValue = this.resetValueDrop('dateRange'); // !!! сбрасывать значение в дропдауне после нажатия на кнопку "назад" в браузере
        // console.log('filterWithoutValue :', filterWithoutValue)
        // if (filterWithoutValue) console.log('filterWithoutValue :', Object.entries(filterWithoutValue))
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }

      if (res.groupId) {
        const current_groupFilter = currentArrFilters.find(el => el.typeFilter === 'groupId')!;
        const new_groupFilter = new ClassFilterDrop({
          ...current_groupFilter,
          typeFilter: 'groupId',
          arrayForDropdown: this.meS.me?.officialGroups || [],
          valueDrop: this.meS.me?.officialGroups?.find(group => group.id === res.groupId),
        });

        await this.gameS.getArrCompetition(res.groupId).toPromise()
          .then((res) => {
            const competitionFilter = currentArrFilters.find(el => el.typeFilter === 'competitions') as ClassFilterDrop;

            const newCompetitionFilter = new ClassFilterDrop({
              ...competitionFilter,
              typeFilter: 'competitions',
              arrayForDropdown: res?.content || [],
              disabled: false,
            });
            newArrFilters.push(new_groupFilter);
            newArrFilters.push(newCompetitionFilter);
          });
      } else {
        const filterWithoutValue = this.resetValueDrop('groupId'); // !!! сбрасывать значение в дропдауне после нажатия на кнопку "назад" в браузере
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }

      if (res.competitions) {
        // if (false) {
        // !!! если есть competitionFilter_fromNewArrFilters то значит он был уже добавлен выше if(res.groupId){}
        let competitionFilter_fromNewArrFilters = newArrFilters.find(el => el.typeFilter === 'competitions') as ClassFilterDrop;
        let idx_competitionFilter_fromNewArrFilters = newArrFilters.findIndex(el => el.typeFilter === 'competitions');
        // console.log('competitionFilter_fromNewArrFilters 111:', idx_competitionFilter_fromNewArrFilters, competitionFilter_fromNewArrFilters);
        if (!competitionFilter_fromNewArrFilters) {
          competitionFilter_fromNewArrFilters = currentArrFilters.find(el => el.typeFilter === 'competitions') as ClassFilterDrop;
          idx_competitionFilter_fromNewArrFilters = currentArrFilters.findIndex(el => el.typeFilter === 'competitions');
        }
        competitionFilter_fromNewArrFilters = UtilsService.removeEmptyKeysFromObject(competitionFilter_fromNewArrFilters);
        // console.log('competitionFilter_fromNewArrFilters 222:', competitionFilter_fromNewArrFilters);

        const arrCompetition = competitionFilter_fromNewArrFilters.arrayForDropdown as Array<ClassCompetition> || this.gameS.arrCompetition$.getValue();
        // console.log('arrCompetition :', arrCompetition);
        const newFilter = new ClassFilterDrop({
          typeFilter: 'competitions',
          arrayForDropdown: arrCompetition,
          ...competitionFilter_fromNewArrFilters,
          valueDrop: arrCompetition?.find(el => el.id === res.competitions),
        });
        // console.log('newFilter :', idx_competitionFilter_fromNewArrFilters, Object.entries(newFilter));
        if (this.checkExistChangesFilter(newFilter)) {
          newArrFilters = UtilsService.replaceElemArrayByIdx(newArrFilters, newFilter, idx_competitionFilter_fromNewArrFilters);
        }
      } else {
        const filterWithoutValue = this.resetValueDrop('competitions'); // !!! сбрасывать значение в дропдауне после нажатия на кнопку "назад" в браузере
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }
      //  delete раньше было
      // if (res.competitions) {
      // if (false) {
      //   const arrCompetition = this.gameS.arrCompetition$.getValue();
      //   // const competition = new ClassFilterDrop({
      //   //   typeFilter: 'competitions',
      //   //   arrayForDropdown: arrCompetition,
      //   //   // valueDrop: arrCompetition.find(el => el.id === this.settingsRequestS.settings.competitions),
      //   //   valueDrop: arrCompetition.find(el => el.id === res.competitions),
      //   // });
      //   // newArrFilters.push(competition);
      //   const currentFilter = currentArrFilters.find(el => el.typeFilter === 'competitions');
      //   if (!currentFilter) return;
      //   const newFilter = new ClassFilterDrop({
      //     ...currentFilter,
      //     typeFilter: 'competitions',
      //     arrayForDropdown: arrCompetition,
      //     // valueDrop: arrCompetition.find(el => el.id === this.settingsRequestS.settings.competitions),
      //     valueDrop: arrCompetition?.find(el => el.id === res.competitions),
      //   });
      //   if (this.checkExistChangesFilter(newFilter)) newArrFilters.push(newFilter);
      // }

      if (res.assignStatuses) {
        const currentFilter = currentArrFilters.find(el => el.typeFilter === 'assignStatuses');
        if (!currentFilter) return;
        const newFilter = new ClassFilterDrop({
          ...currentFilter,
          typeFilter: 'assignStatuses',
          arrayForDropdown: arrDropStatusAssign,
          multi: true,
          valueDrop: getArrDropStatusAssignFromString(res.assignStatuses),
        });
        // console.error('res.assignStatuses :', res.assignStatuses);
        // (newFilter.valueDrop as Array<IDropStatusAssign>)?.forEach(qwe => {
        //   console.error('============= :', qwe.upperCase);
        // })
        if (this.checkExistChangesFilter(newFilter)) newArrFilters.push(newFilter);
      } else {
        const filterWithoutValue = this.resetValueDrop('assignStatuses'); // !!! сбрасывать значение в дропдауне после нажатия на кнопку "назад" в браузере
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }

      if (res.distance) {
        const currentFilter = currentArrFilters.find(el => el.typeFilter === 'distance');
        if (!currentFilter) return;
        const newFilter = new ClassFilterDrop({
          ...currentFilter,
          typeFilter: 'distance',
          valueDrop: getCurrentDistanceItem(res.distance),
        });
        if (this.checkExistChangesFilter(newFilter)) newArrFilters.push(newFilter);
      } else {
        const filterWithoutValue = this.resetValueDrop('distance'); // !!! сбрасывать значение в дропдауне после нажатия на кнопку "назад" в браузере
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }

      // if (res.fromAge) console.log('  res.fromAge :', res.fromAge)
      // if (res.toAge) console.log('  res.toAge :', res.toAge)
      if (res.fromAge || res.toAge) {
        // if (res.ages) {
        //   console.log('res.ages :', res.ages)
        // console.log('fromAge :', res.fromAge, '  toAge :', res.toAge);
        const currentFilter = currentArrFilters.find(el => el.typeFilter === 'ages');
        if (!currentFilter) return;
        const newFilter = new ClassFilterDrop({
          ...currentFilter,
          typeFilter: 'ages',
          // valueDrop: getCurrentAgeItem(res.ages),
          valueDrop: getCurrentAgeItemByFromAgeAndToAge(res.fromAge, res.toAge),
        });
        // const currentAgeItemByFromAgeAndToAge = getCurrentAgeItemByFromAgeAndToAge(res.fromAge, res.toAge)
        // if (currentAgeItemByFromAgeAndToAge)
        if (this.checkExistChangesFilter(newFilter)) {
          // console.log('ExistChanges newFilter :', Object.entries(newFilter));
          newArrFilters.push(newFilter);
        }
      } else {
        const filterWithoutValue = this.resetValueDrop('ages'); // !!! сбрасывать значение в дропдауне после нажатия на кнопку "назад" в браузере
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }
      // if (res.activeCompetitions) {}
      // if (res.competitionPreferences) {}
      // if (res.location) {}
      if (res.officialList) {
        // const officialList = new ClassFilterDrop({ typeFilter: 'officialList', arrayForDropdown: this.officialsS.groupList, multi: true }); // 'active'
        // arrFilters.push(officialList);
        const currentFilter = currentArrFilters.find(el => el.typeFilter === 'officialList');
        if (!currentFilter) return;
        const arrId_officialList_fromQueryParams = res.officialList.split(',');
        // console.log('arrId_officialList_fromQueryParams :', arrId_officialList_fromQueryParams)
        // console.log('currentFilter :', Object.entries(currentFilter))
        // console.log('arrayForDropdown :', (currentFilter as ClassFilterDrop).arrayForDropdown)
        const arrDrop_officialList_fromQueryParams = (currentFilter as ClassFilterDrop).arrayForDropdown
          ?.filter((arrGroupListItem: ClassGroupListItem) => arrId_officialList_fromQueryParams.includes(arrGroupListItem.id!)) as Array<ClassDrop>;
        // console.log('arrDrop_officialList_fromQueryParams :', arrDrop_officialList_fromQueryParams)
        const newFilter = new ClassFilterDrop({
          ...currentFilter,
          typeFilter: 'officialList',
          valueDrop: arrDrop_officialList_fromQueryParams,
          valueFromUrl: res.officialList,
        });
        // console.log('newFilter :', newFilter.valueFromUrl,  Object.entries(newFilter))
        // console.log('this.checkExistChangesFilter(newFilter) :', this.checkExistChangesFilter(newFilter))
        if (this.checkExistChangesFilter(newFilter)) newArrFilters.push(newFilter);
      } else {
        const filterWithoutValue = this.resetValueDrop('officialList'); // !!! сбрасывать значение в дропдауне после нажатия на кнопку "назад" в браузере
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }

      if (res.officialStatus) {
        const currentFilter = currentArrFilters.find(el => el.typeFilter === 'officialStatus');
        if (!currentFilter) return;
        const newFilter = new ClassFilterDrop({ ...currentFilter, typeFilter: 'officialStatus' });
        if (this.checkExistChangesFilter(newFilter)) newArrFilters.push(newFilter);
      } else {
        const filterWithoutValue = this.resetValueDrop('officialStatus'); // !!! сбрасывать значение в дропдауне после нажатия на кнопку "назад" в браузере
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }

      if (res.seasons) {
        const currentFilter = currentArrFilters.find(el => el.typeFilter === 'seasons');
        if (!currentFilter) return;
        const newFilter = new ClassFilterDrop({ ...currentFilter, typeFilter: 'seasons' });
        if (this.checkExistChangesFilter(newFilter)) newArrFilters.push(newFilter);
      } else {
        const filterWithoutValue = this.resetValueDrop('seasons'); // !!! сбрасывать значение в дропдауне после нажатия на кнопку "назад" в браузере
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }

      if (res.payoutFormat) {

        // const newFilter = new ClassFilterDrop({
        //   typeFilter: 'competitions',
        //   arrayForDropdown: arrCompetition,
        //   ...competitionFilter_fromNewArrFilters,
        //   valueDrop: arrCompetition?.find(el => el.id === res.competitions),
        // });

        const currentFilter = currentArrFilters.find(el => el.typeFilter === 'payoutFormat');
        if (!currentFilter) return;
        const newFilter = new ClassFilterDrop({
          ...currentFilter,
          typeFilter: 'payoutFormat',
          arrayForDropdown: arrPayoutFormatDrop,
          valueDrop: arrPayoutFormatDrop?.find(el => el.upperCase === <any>res.payoutFormat),
        });
        if (this.checkExistChangesFilter(newFilter)) newArrFilters.push(newFilter);
      } else {
        const filterWithoutValue = this.resetValueDrop('payoutFormat'); // !!! сбрасывать значение в дропдауне после нажатия на кнопку "назад" в браузере
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }

      // console.log('subscribeToSettings :', newArrFilters?.length, newArrFilters);
      // newArrFilters?.forEach((el) => {
      //   // if (el.typeFilter === 'ages' || el.typeFilter === 'assignStatuses') {
      //   if (el.typeFilter === 'dateRange') {
      //     // if (el.typeFilter === 'competitions' || el.typeFilter === 'search') {
      //     console.log('subscribeToSettings RESULT newArrFilters :', el.typeFilter, Object.entries(el));
      //   }
      // });

      // !!! newArrFilters всегда существует потому что в фильтре dataRange меняется typePeriod=>current|past
      // !!! из-за этого в FiltersComponent вызывается subscribeToSelectedFilters()
      if (newArrFilters?.length) this.updateAllFilters(newArrFilters, '  subscribeToSettings ');
    });
  }

  // === SET / UPDATE FILTERS =================================================
  // !!! === is called only 1 time when the page loads
  setFilters(allFilters: Array<TAllInterfacesFilters>, needUseDynamicFilters: boolean, sortBy_forMobile: boolean): void {
    this.needUseDynamicFilters = needUseDynamicFilters;
    this.sortBy_forMobile = sortBy_forMobile;
    const newArrFilters: Array<TAllInterfacesFilters> = [];
    allFilters.forEach((el, idx) => {
      const newValue: TAllInterfacesFilters = this.getUpdatedFilter(el, { index: el?.index || idx, forTest: '  setFilters ' });
      newArrFilters.push(newValue);
    });
    if (newArrFilters?.length) this.arrFiltersSub$.next(newArrFilters);
    // if (this.needUseDynamicFilters) this.subscribeToSettings();
  }

  updateAllFilters(allFilters: Array<TAllInterfacesFilters>, forTest?: string): void {
    let newArrFilters: Array<TAllInterfacesFilters> = this.arrFiltersSub$.getValue() || [];
    newArrFilters?.forEach((el, idx) => {
      const newElem = allFilters?.find(item => item?.typeFilter == el?.typeFilter);
      if (!newElem) return;
      if (el.typeFilter === newElem.typeFilter) {
        const newValue: TAllInterfacesFilters = this.getUpdatedFilter(el, { ...newElem, forTest: ' updateAllFilters => ' + forTest });
        newArrFilters = this.replaceFilterInArray(newArrFilters, newValue);
      }
    });
    newArrFilters?.forEach((el) => {
      // if (el.typeFilter === 'ages' || el.typeFilter === 'assignStatuses' || el.typeFilter === 'competitions') {
      // if (el.typeFilter === 'search' || el.typeFilter === 'competitions') {
      // if (el.typeFilter === 'officialList' || el.typeFilter === 'search') {
      //     console.log('updateAllFilters RESULT newArrFilters :', forTest, el.typeFilter, Object.entries(el));
      // }
    });
    // console.log('updateAllFilters RESULT newArrFilters :', newArrFilters?.length, newArrFilters)
    this.arrFiltersSub$.next(newArrFilters);
  }

  private getUpdatedFilter(oldFilter: TAllInterfacesFilters, newFilter: TAllInterfacesFilters): TAllInterfacesFilters {
    let newValue: TAllInterfacesFilters = { ...oldFilter, ...newFilter };
    if (this.is_TypeFilter_string(oldFilter.typeFilter!)) newFilter = new ClassFilterInput(newFilter as ClassFilterInput);
    if (this.is_TypeFilter_dateRange(oldFilter.typeFilter!)) newFilter = new ClassFilterDateRange(newFilter as ClassFilterDateRange);
    if (this.is_TypeFilter_drop(oldFilter.typeFilter!)) newFilter = new ClassFilterDrop(newFilter as ClassFilterDrop);
    return newValue;
  }

  replaceFilterInArray(arrFilters: Array<TAllInterfacesFilters>, filter: TAllInterfacesFilters): Array<TAllInterfacesFilters> {
    const arrFiltersWithoutFindFilter = arrFilters?.filter(el => el.typeFilter !== filter.typeFilter);
    const findFilter = this.findFilter(filter.typeFilter as TypeFilter_string & TypeFilter_dateRange & TypeFilter_drop);
    const newValueFilter: TAllInterfacesFilters = { ...findFilter, ...filter };
    return [...arrFiltersWithoutFindFilter, newValueFilter];
  }

  updateFilter(typeFilter: TypeFilter_string, filter: Partial<ClassFilterInput>, forTest?: string): ClassFilterInput
  updateFilter(typeFilter: TypeFilter_dateRange, filter: Partial<ClassFilterDateRange>, forTest?: string): ClassFilterDateRange
  updateFilter(typeFilter: TypeFilter_drop, filter: Partial<ClassFilterDrop>, forTest?: string): ClassFilterDrop
  updateFilter(typeFilter: TypesAllFilters, filter: TAllInterfacesFilters, forTest?: string): TAllInterfacesFilters {
    const arrFiltersWithoutFindFilter = this.arrFiltersSub$.getValue()?.filter(el => el.typeFilter !== typeFilter);
    const findFilter = this.findFilter(typeFilter as TypeFilter_string & TypeFilter_dateRange & TypeFilter_drop); // as TypesAllFilters
    const newValueFilter: TAllInterfacesFilters = { ...findFilter, ...filter, typeFilter, forTest };
    // console.log('newValueFilter :', Object.entries(newValueFilter))
    // console.log('arrFiltersWithoutFindFilter :', Object.entries(arrFiltersWithoutFindFilter))
    // console.log('RESULT :', Object.entries([...arrFiltersWithoutFindFilter, newValueFilter]))
    this.arrFiltersSub$.next([...arrFiltersWithoutFindFilter, newValueFilter]);
    return newValueFilter;
  }

  resetValueDrop(typeFilter: TypeFilter_string): ClassFilterInput
  resetValueDrop(typeFilter: TypeFilter_dateRange): ClassFilterDateRange
  resetValueDrop(typeFilter: TypeFilter_drop): ClassFilterDrop
  resetValueDrop(typeFilter: TypesAllFilters): TAllInterfacesFilters | null {
    const findFilter = this.findFilter(typeFilter as TypeFilter_string & TypeFilter_dateRange & TypeFilter_drop); // as TypesAllFilters
    // const currentFilter = this.arrFiltersSub$.getValue()?.find(el => el.typeFilter === typeFilter);
    if (!findFilter) return null;
    // @ts-ignore
    const updatedFilter = this.updateFilter(typeFilter, {
      ...findFilter,
      valueDrop: undefined,
      valueString: undefined,
      datePeriod: undefined,
      fromTo_formatted: undefined,
      valueFromUrl: undefined,
    });
    return updatedFilter;
  }

  getValuesSelectedFilters(): Array<string> {
    // console.log('isMobile :', this.deviceS.isMobile)
    // console.log('showFilters_forMobile$ :', this.showFilters_forMobile$.getValue()) // !!! for mobile => показать окно выбора фильтров
    // console.log('sortBy_forMobile :', this.sortBy_forMobile) // !!! нужно ли показывать сортировку для мобилы
    const result: Array<string> = [];
    this.arrFiltersSub$.getValue().forEach(el => {
      const valueString = this.getValue_fromSelectedFilter(el);
      if (valueString) {
        if (el.typeFilter === 'dateRange' && ((el.datePeriod?.from && !el.datePeriod?.to) || (!el.datePeriod?.from && el.datePeriod?.to))) {
          return;
        }
        result.push(valueString);
      }
    });

    // !!! для мобилы нужно показывать выбраный фильтр сортировки
    if (this.deviceS.isMobile && this.sortBy_forMobile && this.selected_sortBy) {
      result.push(this.selected_sortBy);
    }
    // console.log('result :', result)
    return result;
  }
  lastValidDateRange: string | null = null;
  getValue_fromSelectedFilter(filter: TAllInterfacesFilters): string {
    const typeFilter: TypeFilter = filter.typeFilter!;
    let result: string = '';
    if (this.is_TypeFilter_string(typeFilter!)) {
      const valueString = (filter as ClassFilterInput).valueString;
      if (valueString) result = valueString;
    }
    if (this.is_TypeFilter_dateRange(typeFilter)) {
      if (window.location.href.includes('/payment/')) {
        const period = (filter as ClassFilterDateRange).period;
        if (period && period !== customDateRange) {
          result = period;
          this.lastValidDateRange = period;
        }
        if (period == customDateRange) {
          result = (filter as ClassFilterDateRange).fromTo_formatted!;
        }
        if (!result && this.lastValidDateRange) {
          //  console.log('Using last valid date range:', this.lastValidDateRange);
          result = this.lastValidDateRange;
        }
      } else {
        const period = (filter as ClassFilterDateRange).period;
        if (period) result = period;
        if (period == customDateRange) result = (filter as ClassFilterDateRange).fromTo_formatted!;
        // console.log('getValue_fromSelectedFilter :', Object.entries(filter), result)
      }
    }
    if (this.is_TypeFilter_drop(typeFilter)) {
      const valueDrop = (filter as ClassFilterDrop).valueDrop;
      if (valueDrop) {
        if ((valueDrop as Array<ClassDrop>)?.length || (valueDrop as Array<ClassDrop>)?.length === 0) {
          let strResult: string = '';
          (valueDrop as Array<ClassDrop>)?.forEach(elDrop => {
            const titleCase: string | undefined = (elDrop as ClassDrop).titleCase; // TDefaultNameMatOption
            if (titleCase) strResult = strResult?.trim() ? strResult?.trim() + ',  ' + titleCase : titleCase;
          });
          if (strResult) result = strResult;
        } else {
          const titleCase: string | undefined = (valueDrop as ClassDrop).titleCase; // TDefaultNameMatOption
          if (titleCase) result = titleCase;
        }
      }
    }
    // console.log('getValue_fromSelectedFilter :', result)
    return result;
  }

  removeAllParamsAndRefresh(): void {
    const url = new URL(window.location.href);
    window.location.href = `${url.origin}${url.pathname}`;
    window.history.replaceState({}, '', `${url.origin}${url.pathname}`);
  }

  removeDateParamsAndRefresh(): void {

    const url = new URL(window.location.href);
    const params = url.searchParams;
    params.delete('from');
    params.delete('to');
    params.delete('search');
    const updatedUrl = `${url.origin}${url.pathname}${params.toString() ? '?' + params.toString() : ''}`;
    window.location.href = updatedUrl;

  }

  // !!! arrAllInterfacesFilters => передать если после reset нужно поставить новые значения
  resetSelectedFilters(arr_newValues?: Array<TAllInterfacesFilters>): void {
    this.showSelectedFilters = false;
    const allInterfacesFilters: Array<TAllInterfacesFilters> = [];
    this.arrFiltersSub$.getValue()?.forEach((el) => {
      const findFilter = arr_newValues?.find((item) => item.typeFilter === el.typeFilter);
      const newValue = findFilter || this.deleteSelectedFilter(el, false);
      // if (newValue?.typeFilter === 'dateRange' || newValue?.typeFilter === 'search') {
      //   console.log('resetSelectedFilters :', newValue?.typeFilter , newValue)
      // }
      if (el.typeFilter === 'search' && window.location.href.includes('/payment/')) {
        this.removeAllParamsAndRefresh();
      }
      if (newValue) allInterfacesFilters.push(newValue);
      if (newValue) console.log(newValue)
    });
    // allInterfacesFilters.forEach(el => {
    //   if (el?.typeFilter === 'dateRange' || el?.typeFilter === 'search') {
    //     console.log('el :', el.typeFilter, el)
    //   }
    // })
    this.arrFiltersSub$.next(allInterfacesFilters);

    // !!! для мобилы нужно сбрасывать сортировку по дефолту
    if (this.deviceS.isMobile) this.settingsRequestS.resetSortByDefault();
  }

  // !!! можно ли удалить фильтры. Если например 1 значение компетишна и его удалить нельзя, то вернется false
  // !!! если вернется true знаит можно удалить
  canResetFilters(): boolean {
    let amountFiltersWithNoDelete = 0; // количество фильтров с noDelete
    let amountSelectedFilters = this.getValuesSelectedFilters()?.length; // количество выбраных фильтров
    this.arrFiltersSub$.getValue().forEach((el) => {
      const filterDrop = el as ClassFilterDrop; // для dataRange & Search не надо. Т.к. там всегда можно удалить фильтр
      if (filterDrop.noDelete) amountFiltersWithNoDelete += 1;
    });
    return amountFiltersWithNoDelete !== amountSelectedFilters; // количество фильтров с noDelete === количеству выбраных фильтров
  }

  deleteSelectedFilter(filter: TAllInterfacesFilters, update_arrFiltersSub = true): TAllInterfacesFilters | null {
    if (!filter) return null;
    const typeFilter = filter.typeFilter as TypesAllFilters;
    const index = filter.index;
    const currentValueFilter = this.getCurrentFilter(typeFilter as TypeFilter_string & TypeFilter_dateRange & TypeFilter_drop);
    let newValue: TAllInterfacesFilters | null = null;
    if (this.is_TypeFilter_string(typeFilter)) {
      newValue = { ...currentValueFilter, valueString: undefined, index } as ClassFilterInput;
    }
    if (this.is_TypeFilter_dateRange(typeFilter) && window.location.href.includes('/payment/')) {
      this.removeDateParamsAndRefresh();
      newValue = { ...currentValueFilter, period: undefined, datePeriod: '', fromTo_formatted: '', index } as ClassFilterDateRange;
    }
    if (this.is_TypeFilter_dateRange(typeFilter)) {
      newValue = { ...currentValueFilter, period: undefined, datePeriod: '', fromTo_formatted: '', index } as ClassFilterDateRange;
    }
    if (this.is_TypeFilter_drop(typeFilter)) {
      const currentFilterDrop = currentValueFilter as ClassFilterDrop;
      if (currentFilterDrop.noDelete) { // !!! если есть noDelete то оставлять этот фильтр без изменений
        newValue = currentValueFilter;
      } else if (currentFilterDrop.default_valueDrop) { // !!! если есть default_valueDrop то значение этого фильтра записать default_valueDrop
        newValue = { ...currentFilterDrop, valueDrop: currentFilterDrop.default_valueDrop, index };
      } else { // !!! обнулить значение valueDrop
        newValue = { ...currentFilterDrop, valueDrop: undefined, index };
      }
    }
    if (newValue) {
      const arrFiltersWithoutCurrent: Array<TAllInterfacesFilters> = this.arrFiltersSub$.getValue().filter(el => el.typeFilter !== typeFilter);
      if (update_arrFiltersSub) this.arrFiltersSub$.next([...arrFiltersWithoutCurrent, newValue!]);
    }
    return newValue!;
  }

  getCurrentFilter(typeFilter: TypeFilter_string): ClassFilterInput
  getCurrentFilter(typeFilter: TypeFilter_dateRange): ClassFilterDateRange
  getCurrentFilter(typeFilter: TypeFilter_drop): ClassFilterDrop
  getCurrentFilter(typeFilter: TypesAllFilters): TAllInterfacesFilters {
    return this.arrFiltersSub$.getValue()?.find((el) => el.typeFilter === typeFilter)!;
  }

  findFilter(typeFilter: TypeFilter_string): ClassFilterInput
  findFilter(typeFilter: TypeFilter_dateRange): ClassFilterDateRange
  findFilter(typeFilter: TypeFilter_drop): ClassFilterDrop
  findFilter(typeFilter: TypesAllFilters): TAllInterfacesFilters | undefined {
    return this.arrFiltersSub$.getValue()?.find((item) => item.typeFilter === typeFilter);
  }

  // ========================================================================
  is_TypeFilter_string(typeFilter: TypeFilter): boolean {
    return arrTypeFilter_forInput.includes(typeFilter as TypeFilter_string);
  }

  is_TypeFilter_dateRange(typeFilter: TypeFilter): boolean {
    return arrTypeFilter_forDateRange.includes(typeFilter as TypeFilter_dateRange);
  }

  is_TypeFilter_drop(typeFilter: TypeFilter): boolean {
    return arrTypeFilter_forDrop.includes(typeFilter as TypeFilter_drop);
  }

}

// !!! не забывать в компонентах вызывать reset()
// !!! т.к. MeTableService FiltersService SettingsRequestService глобальные, то при загрузке компонента нужно всё сбросить по дефолту
// reset(): void {
//   this.showFilters_forMobile$.next(false)
//   this.showSelectedFilters = false
//   this.sortBy_forMobile = false
//   this.arrFiltersSub$.next([])
//   // this.settingsRequestS.reset()
// }
