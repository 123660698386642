import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomDatesService } from '@classes/CustomDates';

export type THours = '00' | '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09' | '10' | '11' | '12'
export type TMinutes = '00' | '15' | '30' | '45'
export type TMeridiem = 'AM' | 'PM'

export interface ITime {
  hourValue: THours;
  minuteValue: TMinutes;
  meridiem: TMeridiem;
}

export class TimeClass implements ITime {
  constructor(
    public hourValue: THours = '01',
    public minuteValue: TMinutes = '00',
    public meridiem: TMeridiem = 'AM',
  ) {
  }
}

// @Injectable({ providedIn: 'root' })
@Injectable()
export class DateTimeService {
  private dateSub$ = new BehaviorSubject<Date | undefined>(undefined);
  private timeSub$ = new BehaviorSubject<ITime>({ hourValue: '01', minuteValue: '00', meridiem: 'AM' });
  //   === | string == <input [value]='(dateTimeS.date$|async)'
  date$: Observable<Date | undefined> = this.dateSub$.asObservable();
  time$: Observable<ITime> = this.timeSub$.asObservable();

  // === SelectTimeComponent =================
  readonly arrHours: Array<THours> = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  readonly arrMinutes: Array<TMinutes> = ['00', '15', '30', '45'];

  //   сделать сервис для этого. потому что не только с локацией надо проверять это. Там проверять обязательные поля
  dateTouched$ = new BehaviorSubject<boolean>(false); // когда выбрал веню или адрес с гугла получил, то все поля адреса надо пометить как затронутые, чтобы показать ошибки
  // dateValid$ = new BehaviorSubject<boolean>(true); // если все поля обязательные заполнены и валидны => вернется true

  constructor(
    private datesS: CustomDatesService,
  ) {
  }

  reset(): void {
    this.dateSub$.next(undefined);
    this.timeSub$.next(new TimeClass());
    this.dateTouched$.next(false);
  }

  // вызывать только перед отправкой формы на сервер. Потому что после вызова этой функции сразу подсветятся ошибки на полях
  // проверка на правильность заполнения и все ли обязательные поля заполнены
  public checkErrorDate(): boolean {
    const dateValid = !!this.dateSub$.getValue();
    this.dateTouched$.next(true);
    return dateValid;
  }

  // если дата поступила с сервера в таком виде "7 Jan 2023 2:30 PM CST", тогда надо convertStrFromServerToDate = true передать
  setDateTime(date: Date | string, convertStrFromServerToDate: boolean): void {
    if (convertStrFromServerToDate) {
      this.dateSub$.next(this.datesS.convertStrFromServerToDate(date as string, true));
    } else {
      this.dateSub$.next(this.datesS.convertToDate(date));
    }
    // при загрузке страницы время надо брать из даты (если дата есть)
    this.timeSub$.next({
      hourValue: this.datesS.getHours(this.date!) as THours,
      minuteValue: this.datesS.getMinutes(this.date!, true) as TMinutes,
      meridiem: this.datesS.getMeridiem(this.date!) as TMeridiem,
    });
  }

  setTime(type: 'hourValue' | 'minuteValue' | 'meridiem', value: THours | TMinutes | TMeridiem): void {
    this.timeSub$.next({ ...this.timeSub$.getValue(), [type]: value });
  }

  valueChangeDate(date: Date): void { // date == object Thu Dec 08 2022 00:00:00 GMT+0600 (Киргизия)
    // после выбора даты не надо время сбрасывать (не менять объект time$) и надо к дате время текущее/выбраное прибавлять
    const datePlusTime = this.datesS.datePlusHoursMinutesWithMeridiem(date, this.time);
    this.dateSub$.next(datePlusTime);
  }

  // после выбора времени надо в дату записывать новые значения, чтобы при отправке формы на сервер дата была со временем
  valueChangeTime(type: 'hourValue' | 'minuteValue' | 'meridiem', value: THours | TMinutes | TMeridiem) {
    // если дата уже выбрана или установлена при загрузке страницы
    // вытащить из текущей даты текущее время и установить timeSub$

    if (this.date) { // 1 => изменил время при редактировании игры - дата существует
      const newTime = {
        ...this.datesS.getTimeFromDate(this.date),
        [type]: value,
      };
      this.timeSub$.next(newTime); // обновить время

      const datePlusTime = this.datesS.datePlusHoursMinutesWithMeridiem(this.date, newTime); // прибавить к полночи текущее время
      this.dateSub$.next(datePlusTime); // обновить дату с изменённым временем
    } else { // 2 => изменил время при создании игры - даты нет
      this.setTime(type, value); // обновить время
    }
  }

  get date(): Date | undefined {
    return this.dateSub$.getValue();
  }

  get time(): ITime { // {hourValue: '03', minuteValue: '15', meridiem: 'AM'}
    return this.timeSub$.getValue();
  }

  get meridiem(): TMeridiem {
    return this.time.meridiem;
  }
}
