import { Pipe, PipeTransform } from '@angular/core';
import { ClassGame } from '@app/dir_group_assignor/games/game';
import { UtilsService } from '@services/utils.service';

@Pipe({ name: 'getMatTooltipCancelReason', standalone: true })
export class GetMatTooltipCancelReasonPipe implements PipeTransform {

  transform(game?: ClassGame): string {
    return UtilsService.getMatTooltipCancelReason(game);
  }

}
