<div class='wrapperForGameBlock' [formGroup]='form' [class.wrapperForGameBlock--desktop]='(deviceS.isDesktop$ | async)'>
  <headTitle [text]='isClone ? "Clone Game":"Edit Game"' svgRight='cross_grey&24' (svgRightEmit)='close()'
             typeText='text4-blueDark'></headTitle>

  <div class='contentCreateGame'>
    <!--    === Competition & Game # =================================-->
    <div class='blockCreateGame'>
      <field text='Competition'>
        <app-dropdown formControlName='competition' [array]='(gameS.arrCompetition$|async)!' placeholder='Select Competition'
                      (changeValObj)='changeCompetition($event)'></app-dropdown>
      </field>
      <field text='Game #' svgName='question_grey&16'
             matTooltipSVG='You can enter a game number or leave it blank and our system will generate a game number.'>
        <inputCtrl formControlName='gameNumber' placeholder='Game #' [isNumber]='true'></inputCtrl>
      </field>
    </div>

    <!--    !!! пока не удалять !!! -->
    <div class='blockCreateGame'>
      <field text='Payout Format'>
        <app-dropdown formControlName='payoutFormatDrop' [array]='arrPayoutFormatDrop' placeholder='Select Payout Format'></app-dropdown>
      </field>
    </div>


    <!--    ===  dateTime =================================-->
    <div class='blockCreateGame'>
      <!--      (emit)='changeDateTime($event)'-->
      <dateTime #dateTimeRef [valueStrFromServer]='ctrl.date?.value' [errorClass]='isClone'></dateTime>
    </div>

    <!--    === gameType & location  =================================-->
    <div class='blockCreateGame'>
      <field text='Game Type'>
        <app-dropdown formControlName='gameTypeDrop' [array]='competitionS.arrGameTypeDrop' placeholder='Select Game Type'></app-dropdown>
      </field>
      <field text='Location'>
        <app-dropdown formControlName='location' [array]='(gameS.locations$|async)!' placeholder='Select Game Location'
                      (changeValObj)='changeLocation($event)'></app-dropdown>
      </field>
    </div>

    <!--    === currentCourtName &  ageGroup =================================-->
    <div class='blockCreateGame'>
      <field text='Field or Court'>
        <!--        <dropFormCtrl formControlName='currentCourtName' [listStr]='(gameS.arrCourtName$|async)!' placeholder='Select Field or Court'></dropFormCtrl>-->
        <app-dropdown formControlName='currentCourtNameDrop' [array]='ctrl.courtNamesDrop.value'
                      placeholder='Select Field or Court' forTest='currentCourtNameDrop'></app-dropdown>
      </field>
      <field text='Age Groups'>
        <app-dropdown formControlName='ageGroup' [array]='(gameS.ageGroups$|async)!' placeholder='Select Age Groups'></app-dropdown>
      </field>
    </div>

    <!--    ===  levels & gender =================================-->
    <div class='blockCreateGame'>
      <field text='Level'>
        <app-dropdown formControlName='levels' [array]='(gameS.levels$|async)!' placeholder='Select Level'></app-dropdown>
      </field>
      <field text='Gender'>
        <app-dropdown formControlName='genderDrop' [array]='arrGenderDrop' placeholder='Select Gender'></app-dropdown>
      </field>
    </div>

    <!--    === homeTeam & awayTeam  =================================-->
    <div class='blockCreateGame'>
      <field text='Home Team'>
        <app-dropdown formControlName='homeTeam' [array]='(gameS.teams$|async)!' placeholder='Select Home Team'></app-dropdown>
      </field>
      <field text='Away Team'>
        <app-dropdown formControlName='awayTeam' [array]='(gameS.teams$|async)!' placeholder='Select Away Team'></app-dropdown>
      </field>
    </div>

    <!--    ===  duration =================================-->
    <!--    !!! пока не удалять =================-->
    <!--    <div class='blockCreateGame'>-->
    <!--      <field text='Duration' svgName='question_grey&16'-->
    <!--             matTooltipSVG='Duration is the total duration of the game including game periods and period lengths saved in the competition.'>-->
    <!--        <inputCtrl formControlName='duration' placeholder='Duration' [isNumber]='true'></inputCtrl>-->
    <!--      </field>-->
    <!--    </div>-->

    <!--    !!! пока не удалять =================-->
    <!--    <field text='Description' class='o-w-100'>-->
    <!--      <inputCtrl type='textarea' formControlName='gameDescription' placeholder='Description' height='150'></inputCtrl>-->
    <!--    </field>-->

    <toggle *ngIf='!isClone' formControlName='officialAssignments' text='Maintain current Official assignments (including status)'></toggle>

    <btnWrap *ngIf='(deviceS.isDesktop$ | async)'>
      <btn text='Cancel' bcg='white' (emit)='close()'></btn>
      <btn [text]='isClone ? "Clone Game":"Save Updates"' bcg='blueDark' (emit)='submit()'
           [disabled]='!form?.valid || !dateTimeRef?.dateValid'></btn>
    </btnWrap>
    <btnWrap *ngIf='(deviceS.isMobile$ | async)'>
      <btn [text]='isClone ? "Clone Game":"Save Updates"' bcg='blueDark' (emit)='submit()'
           [disabled]='!form?.valid || !dateTimeRef?.dateValid'></btn>
      <btn text='Cancel' bcg='white' (emit)='close()'></btn>
    </btnWrap>
  </div>
</div>
