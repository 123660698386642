import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceService } from '@services/device.service';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { MeTableService } from '@components/_table/me-table.service';
import { ClassGame } from '@app/dir_group_assignor/games/game';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-tableInfiniteLoading',
  standalone: true,
  imports: [CommonModule, SpinnerComponent],
  templateUrl: './table-infinite-loading.component.html',
  styleUrls: ['./table-infinite-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// !!! этот компонент только для мобилы. При скролле показывать спиннер или текст если больше не осталось данных на сервере
export class TableInfiniteLoadingComponent<T> implements OnInit {
  @HostBinding('class.o-none') get hideThisComponent(): boolean { // !!! this component only for mobile
    // return this.deviceS.isDesktop;
    // const hiddenForMobile_forOpenGames = this.deviceS.isMobile && this.meTableS.path === urlOpenGames;
    // return this.deviceS.isDesktop || hiddenForMobile_forOpenGames;
    return this.deviceS.isDesktop;
  }

  // readonly urlOpenGames = urlOpenGames;

  showSpinner = false;

  // showText_contentEnded = false; // пока не удалять. возможно понадобится текст показывать

  constructor(
    private deviceS: DeviceService,
    private meTableS: MeTableService<ClassGame>,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.subscribeToDataTable();
  }

  subscribeToDataTable(): void {
    this.meTableS.dataTable$.pipe(untilDestroyed(this)).subscribe((res) => {
      // console.log('subscribeToDataTable :', res)
      if (!res) return;
      if (res.content?.length) {
        this.showSpinner = (res.number || 0) + 1 !== res.totalPages;
      } else {
        this.showSpinner = false;
      }
      this.cd.detectChanges();
    });
  }

}
