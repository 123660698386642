import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MainService } from '@services/main.service';
import { Router } from '@angular/router';
import { TFieldStripeForm } from '@services/stripe.service';
import { RoutesService } from '@services/routes.service';
import { IPaymentMethod, TypePay } from '@app/dir_group_assignor/payments/modelsForPayment';

export interface IFormLinkBank {
  routing?: string;
  account?: string;
  confirmAccount?: string;
}

export interface ILinkCardBank {
  bank?: boolean;
  card?: boolean;
}

export type TLinkName = 'bank' | 'card';
export type TLinkNameTitleCase = 'Bank' | 'Card';

@Injectable({ providedIn: 'root' })
export class FundService {
  private linkSub$ = new BehaviorSubject<ILinkCardBank>({ bank: true, card: false }); // текущая активная вкладка
  link$ = this.linkSub$.asObservable();
  private reqPendingSub$ = new BehaviorSubject<boolean>(false);
  reqPending$ = this.reqPendingSub$.asObservable();

  tokenFromStripe?: string; // token полученный из страйпа stripe?.createToken(this.cardNumber?.stripeElem, additionalData)

  formLinkBank?: IFormLinkBank;

  // fields: Array<TFieldStripeForm> = ['cardNumber', 'cardExpiry', 'cardName', 'cardCvc']; // какие поля и в каком порядке отображать для страйповской формы.
  fields: Array<TFieldStripeForm> = []; // какие поля и в каком порядке отображать для страйповской формы.

  constructor(
    private mainS: MainService,
    private router: Router,
    public routesS: RoutesService,
  ) {
  }

  setStripeFields(stripeFields: Array<TFieldStripeForm>): void {
    this.fields = stripeFields; // какие поля и в каком порядке отображать для страйповской формы.
  }

  // если в пришел из пайментов и надо в дропдаун сразу поставить выбранный метод
  // checkLocalStorage(): { fromPaymentsPayMethodId: string | null, fromPaymentsPayMethodType: string | null, fromPaymentsAddPayMethod: string | 'ACH' | 'CARD' | null } {
  checkLocalStorage(): { fromPaymentsPayMethodId: string | null, fromPaymentsPayMethodType: string | null } {
    const fromPaymentsPayMethodId = localStorage.getItem('fromPaymentsPayMethodId');
    localStorage.removeItem('fromPaymentsPayMethodId');
    const fromPaymentsPayMethodType = localStorage.getItem('fromPaymentsPayMethodType');
    localStorage.removeItem('fromPaymentsPayMethodType');
    // const fromPaymentsAddPayMethod = localStorage.getItem('fromPaymentsAddPayMethod'); // чтобы в Funds сразу был открыт - добавление карты / банка
    // localStorage.removeItem('fromPaymentsAddPayMethod');
    // return { fromPaymentsPayMethodId, fromPaymentsPayMethodType, fromPaymentsAddPayMethod };
    return { fromPaymentsPayMethodId, fromPaymentsPayMethodType };
  }

  setLink(link: ILinkCardBank): void {
    this.linkSub$.next({ ...{ bank: false, card: false }, ...link });
  }

  setReqPending(type: boolean): void {
    this.reqPendingSub$.next(type);
  }

  // для судьи // в 3 случаях вызывается этот метод
  // 1. hasConnectAccount из <stripeForm></stripeForm> приходит {token: string} после stripe?.createToken(cardNumber, additionalData) и на сервер отправляется только {token: string}
  // 2. hasConnectAccount && link.bank // создать ещё один платежный метод. На свервер отправляется {routing, account}
  // 3. !hasConnectAccount // создать впервые платежный метод // sendObj поступает сюда, там много полей - дата рождения, телефон, имя и т.д. (там 3 шага)
  createPayMethod(sendObjFromComponent?: any): void {
    if (this.reqPending) return;
    this.setReqPending(true);

    let sendObj: any = sendObjFromComponent || {};

    if (this.link.bank && this.formLinkBank) sendObj = { ...sendObj, ...this.formLinkBank };
    if (this.link.card && this.tokenFromStripe) {
      sendObj.token = this.tokenFromStripe; // после переключения bank=>card и card=>bank tokenFromStripe должен стираться
    }

    if (sendObj.account) sendObj.accountNumber = sendObj.account;
    if (sendObj.routing) sendObj.routingNumber = sendObj.routing;
    delete sendObj.account;
    delete sendObj.routing;

    // for official => создает платежный метод (для получения выплат)
    this.mainS.createStripePayMethodForOfficial(sendObj).toPromise()
      .then((res) => this.routesS.navigate('/payments'))
      .catch(() => {
      })
      .finally(() => this.setReqPending(false));
  }

  // чтобы иконку карты/банка устанавливать
  checkPayLogo(payMethod?: IPaymentMethod): 'bankTransfer' | 'stripe' | string {
    if (!payMethod) return '';
    let result: 'bankTransfer' | 'stripe' | string = '';
    // const nameMethod = payMethod?.name?.replace(/ /g, '')?.toLowerCase() || ''; // "visa" || "STRIPE TEST BANK"
    // if (payMethod?.type == 'CARD') result = nameMethod;
    // if (payMethod?.type == 'ACH') result = 'bankTransfer';
    // if (payMethod?.type == 'ACH' && nameMethod?.includes('stripe')) result = 'stripe';
    // if (!payMethod?.type) result = '';

    const typePay: TypePay = payMethod?.cardDto ? 'CARD' : 'ACH';
    const nameCard: string = typePay == 'CARD' ? payMethod?.cardDto?.brand! : payMethod?.bankAccountDto?.bankName!;
    const nameMethod = nameCard?.replace(/ /g, '')?.toLowerCase() || ''; // "visa" || "STRIPE TEST BANK"
    if (typePay == 'CARD') result = nameMethod;
    if (typePay == 'ACH') result = 'bankTransfer';
    if (typePay == 'ACH' && nameMethod?.includes('stripe')) result = 'stripe';
    // if (!payMethod?.type) result = '';
    return result?.toLowerCase();
  }

  // === GETTERS ==================
  // get linkName(): TLinkName {
  //   if (this.linkSub$.getValue().bank) return 'bank';
  //   if (this.linkSub$.getValue().card) return 'card';
  // }

  // get linkNameTitle(): TLinkNameTitleCase {
  //   if (this.linkSub$.getValue().bank) return 'Bank';
  //   if (this.linkSub$.getValue().card) return 'Card';
  // }

  get link(): ILinkCardBank {
    return this.linkSub$.getValue();
  }

  get reqPending(): boolean {
    return this.reqPendingSub$.getValue();
  }
}

// @Injectable({ providedIn: 'root' })
// export class FundService {
//   private linkSub$ = new BehaviorSubject<ILinkCardBank>({ bank: true, card: false }); // текущая активная вкладка
//   link$ = this.linkSub$.asObservable();
//   private reqPendingSub$ = new BehaviorSubject<boolean>(false);
//   reqPending$ = this.reqPendingSub$.asObservable();
//
//   tokenFromStripe?: string; // token полученный из страйпа stripe?.createToken(this.cardNumber?.stripeElem, additionalData)
//
//   formLinkBank?: IFormLinkBank;
//
//   // fields: Array<TFieldStripeForm> = ['cardNumber', 'cardExpiry', 'cardName', 'cardCvc']; // какие поля и в каком порядке отображать для страйповской формы.
//   fields: Array<TFieldStripeForm> = []; // какие поля и в каком порядке отображать для страйповской формы.
//
//   constructor(
//     private mainS: MainService,
//     private router: Router,
//     public routesS: RoutesService,
//   ) {
//   }
//
//   setStripeFields(stripeFields: Array<TFieldStripeForm>): void {
//     this.fields = stripeFields; // какие поля и в каком порядке отображать для страйповской формы.
//   }
//
//   // если в пришел из пайментов и надо в дропдаун сразу поставить выбранный метод
//   // checkLocalStorage(): { fromPaymentsPayMethodId: string | null, fromPaymentsPayMethodType: string | null, fromPaymentsAddPayMethod: string | 'ACH' | 'CARD' | null } {
//   checkLocalStorage(): { fromPaymentsPayMethodId: string | null, fromPaymentsPayMethodType: string | null } {
//     const fromPaymentsPayMethodId = localStorage.getItem('fromPaymentsPayMethodId');
//     localStorage.removeItem('fromPaymentsPayMethodId');
//     const fromPaymentsPayMethodType = localStorage.getItem('fromPaymentsPayMethodType');
//     localStorage.removeItem('fromPaymentsPayMethodType');
//     // const fromPaymentsAddPayMethod = localStorage.getItem('fromPaymentsAddPayMethod'); // чтобы в Funds сразу был открыт - добавление карты / банка
//     // localStorage.removeItem('fromPaymentsAddPayMethod');
//     // return { fromPaymentsPayMethodId, fromPaymentsPayMethodType, fromPaymentsAddPayMethod };
//     return { fromPaymentsPayMethodId, fromPaymentsPayMethodType };
//   }
//
//   setLink(link: ILinkCardBank): void {
//     this.linkSub$.next({ ...{ bank: false, card: false }, ...link });
//   }
//
//   setReqPending(type: boolean): void {
//     this.reqPendingSub$.next(type);
//   }
//
//   // для судьи // в 3 случаях вызывается этот метод
//   // 1. hasConnectAccount из <stripeForm></stripeForm> приходит {token: string} после stripe?.createToken(cardNumber, additionalData) и на сервер отправляется только {token: string}
//   // 2. hasConnectAccount && link.bank // создать ещё один платежный метод. На свервер отправляется {routing, account}
//   // 3. !hasConnectAccount // создать впервые платежный метод // sendObj поступает сюда, там много полей - дата рождения, телефон, имя и т.д. (там 3 шага)
//   createPayMethod(sendObjFromComponent?: any): void {
//     if (this.reqPending) return;
//     this.setReqPending(true);
//
//     let sendObj: any = sendObjFromComponent || {};
//
//     if (this.link.bank && this.formLinkBank) sendObj = { ...sendObj, ...this.formLinkBank };
//     if (this.link.card && this.tokenFromStripe) {
//       sendObj.token = this.tokenFromStripe; // после переключения bank=>card и card=>bank tokenFromStripe должен стираться
//     }
//
//     if (sendObj.account) sendObj.accountNumber = sendObj.account;
//     if (sendObj.routing) sendObj.routingNumber = sendObj.routing;
//     delete sendObj.account;
//     delete sendObj.routing;
//
//     // !!! for official => создает платежный метод (для получения выплат)
//     // this.mainS.createStripePayMethodForOfficial(sendObj).toPromise()
//     //   .then((res) => this.routesS.navigate('/payments'))
//     //   .catch(() => {
//     //   })
//     //   .finally(() => this.setReqPending(false));
//   }
//
//   // чтобы иконку карты/банка устанавливать
//   checkPayLogo(payMethod?: any): 'bankTransfer' | 'stripe' | string { // payMethod: IPaymentMethod
//     if (!payMethod) return '';
//     let result: 'bankTransfer' | 'stripe' | string = '';
//     const nameMethod = payMethod?.name?.replace(/ /g, '')?.toLowerCase() || ''; // "visa" || "STRIPE TEST BANK"
//     if (payMethod?.type == 'CARD') result = nameMethod;
//     if (payMethod?.type == 'ACH') result = 'bankTransfer';
//     if (payMethod?.type == 'ACH' && nameMethod?.includes('stripe')) result = 'stripe';
//     if (!payMethod?.type) result = '';
//     return result?.toLowerCase();
//   }
//
//   // === GETTERS ==================
//   get linkName(): TLinkName {
//     if (this.linkSub$.getValue().bank) return 'bank';
//     if (this.linkSub$.getValue().card) return 'card';
//   }
//
//   get linkNameTitle(): TLinkNameTitleCase {
//     if (this.linkSub$.getValue().bank) return 'Bank';
//     if (this.linkSub$.getValue().card) return 'Card';
//   }
//
//   get link(): ILinkCardBank {
//     return this.linkSub$.getValue();
//   }
//
//   get reqPending(): boolean {
//     return this.reqPendingSub$.getValue();
//   }
// }
