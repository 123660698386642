import { Pipe, PipeTransform } from '@angular/core';
import { OtherService } from '@services/other.service';

// взять только первую и последнюю букву из строки. Тоесть всё что посередине - убрать
@Pipe({ name: 'getStartEndLetter', standalone: true })
export class GetStartEndLetterPipe implements PipeTransform {

  constructor(
    private otherS: OtherService,
  ) {
  }

  transform(str: string): string {
    return this.otherS.getStartEndLetterFromString(str);
  }

}
