import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeviceService } from '@services/device.service';
import { OtherService } from '@services/other.service';
import { ProfileService } from '@app/profile/profile.service';
import { TypePageProfile } from '@app/profile/profile';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';

@Component({
  selector: 'titleAndNavigationForProfile[typePageProfile]',
  standalone: true,
  imports: [CommonModule, DropFormCtrlComponent, HeadTitleComponent, FormsModule, DropdownComponent, ReactiveFormsModule],
  templateUrl: './title-and-navigation-for-profile.component.html',
  styleUrls: ['./title-and-navigation-for-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleAndNavigationForProfileComponent {
  @Input() typePageProfile!: TypePageProfile;
  @Input() idUser?: string; // !!! for route '/profileUser'
  @Input() showNavigation = true; // !!! для страниц редактирования нет дропдауна с навигацией

  constructor(
    public deviceS: DeviceService,
    public otherS: OtherService,
    public profileS: ProfileService,
    public cd: ChangeDetectorRef,
  ) {
  }
}
