import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Route, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@guards/auth.guard';

import { AppComponent } from './app.component';
import { AuthInterceptor } from '@shared/interceptors/auth.interceptor';
import { RouteReusableStrategy } from '@app/core/route-reusable-strategy';
import { AuthComponent } from '@app/auth/auth.component';
import { SignupComponent } from '@app/auth/signup/signup.component';
import { LoginComponent } from '@app/auth/login/login.component';
import { ForgotPasswordComponent } from '@app/auth/forgot-password/forgot-password.component';
import { CreatePasswordComponent } from '@app/auth/create-password/create-password.component';
import { addUserRoleInRoutes } from '@services/routes.service';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { ShellComponent } from '@app/shell/shell.component';
import { DashboardComponent } from '@app/dir_group_assignor/dashboard/dashboard.component';
import {
  CompetitionsUsersComponent,
} from '@app/dir_group_assignor/competitions/components/competitions-users/competitions-users.component';
import { AssignComponent } from '@app/dir_group_assignor/assign/assign.component';
import { UserResolver } from '@resolvers/user.resolver';
import { GamesComponent } from '@app/dir_group_assignor/games/components/games/games.component';
import { GameInfoComponent } from '@app/dir_group_assignor/games/components/game-info/game-info.component';
import { CreateGameComponent } from '@app/dir_group_assignor/games/components/create-game/create-game.component';
import { OfficialsComponent } from '@app/dir_group_assignor/officials/officials.component';
import { SignupOfficialComponent } from '@app/auth/signup-official/signup-official.component';
import { CompetitionsListComponent } from '@app/dir_group_assignor/competitions/components/competitions-list/competitions-list.component';
import {
  CompetitionsGeneralInfoComponent,
} from '@app/dir_group_assignor/competitions/components/competitions-general-info/competitions-general-info.component';
import {
  CompetitionsOfficialLabelsComponent,
} from '@app/dir_group_assignor/competitions/components/competitions-official-labels/competitions-official-labels.component';
import {
  CompetitionsGroupsComponent,
} from '@app/dir_group_assignor/competitions/components/competitions-groups/competitions-groups.component';
import {
  CompetitionsLevelsComponent,
} from '@app/dir_group_assignor/competitions/components/competitions-levels/competitions-levels.component';
import {
  CompetitionsPayScalesComponent,
} from '@app/dir_group_assignor/competitions/components/competitions-pay-scales/competitions-pay-scales.component';
import {
  CompetitionsSeasonsComponent,
} from '@app/dir_group_assignor/competitions/components/competitions-seasons/competitions-seasons.component';
import {
  CompetitionsLocationsComponent,
} from '@app/dir_group_assignor/competitions/components/competitions-locations/competitions-locations.component';
import {
  CompetitionsTeamsComponent,
} from '@app/dir_group_assignor/competitions/components/competitions-teams/competitions-teams.component';
import { MyWalletComponent } from '@app/dir_officials/page-my-wallet/components/my-wallet/my-wallet.component';
import { MyAssignorComponent } from '@app/dir_officials/page-my-assignors/page-my-assignors.component';
import { AnnouncementsComponent } from './dir_group_assignor/announcements/announcements.component';
import { ReportsComponent } from '@app/dir_group_assignor/reports/components/reports/reports.component';
import { AttentionsComponent } from './dir_group_assignor/dashboard/attentions/attentions.component';
import { ImportGamesComponent } from '@app/dir_group_assignor/games/components/import-games/import-games.component';
import { ReportInfoComponent } from '@app/dir_group_assignor/reports/components/report-info/report-info.component';
import { PageMyGamesComponent } from '@app/dir_officials/page-my-games/page-my-games.component';
import { PageOpenGamesComponent } from '@app/dir_officials/page-open-games/page-open-games.component';
import { PaymentsComponent } from '@app/dir_group_assignor/payments/payments.component';
import { PaymentsOverviewComponent } from '@app/dir_group_assignor/payments/components/payments-overview/payments-overview.component';
import {
  PaymentsOfficialsFeesComponent,
} from '@app/dir_group_assignor/payments/components/payments-officials-fees/payments-officials-fees.component';
import { PaymentsBalancesComponent } from '@app/dir_group_assignor/payments/components/payments-balances/payments-balances.component';
import { PaymentsPayersComponent } from '@app/dir_group_assignor/payments/components/payments-payers/payments-payers.component';
import { PaymentsRewardsComponent } from '@app/dir_group_assignor/payments/components/payments-rewards/payments-rewards.component';
import {
  PaymentsBalancePayComponent,
} from '@app/dir_group_assignor/payments/components/payments-balances/payments-balance-pay/payments-balance-pay.component';
import { SuccessMessageComponent } from '@components/message-from-server/components/success-message/success-message.component';
import { ErrorMessageComponent } from '@components/message-from-server/components/error-message/error-message.component';
import {
  PaymentsBalancesAllComponent,
} from '@app/dir_group_assignor/payments/components/payments-balances/payments-balances-all/payments-balances-all.component';
import { PayerInfoComponent } from '@app/dir_group_assignor/payments/components/payments-payers/payer-info/payer-info.component';
import {
  PaymentsOfficialsFeesAllComponent,
} from '@app/dir_group_assignor/payments/components/payments-officials-fees/payments-officials-fees-all/payments-officials-fees-all.component';
import {
  PaymentsAssignorFeesComponent,
} from '@app/dir_group_assignor/payments/components/payments-assignor-fees/payments-assignor-fees.component';
// import {
//   PaymentsAssignorFeesAllComponent,
// } from '@app/dir_group_assignor/payments/components/payments-assignor-fees/payments-assignor-fees-all/payments-assignor-fees-all.component';
import { MeProfileInfoComponent } from '@app/profile/me-profile/me-profile-info/me-profile-info.component';
import { MeProfilePreferencesComponent } from '@app/profile/me-profile/me-profile-preferences/me-profile-preferences.component';
import { MeProfileBillingComponent } from '@app/profile/me-profile/me-profile-billing/me-profile-billing.component';
import { MeProfileAccountComponent } from '@app/profile/me-profile/me-profile-account/me-profile-account.component';
import { MeProfileCertificateComponent } from '@app/profile/me-profile/me-profile-certificate/me-profile-certificate.component';
import { MeProfileComponent } from '@app/profile/me-profile/meProfile.component';
import { rout_meProfile, rout_meProfileOfficial, rout_profileUser } from '@app/profile/profile';
import { MeProfileOfficialComponent } from '@app/profile/me-profile-official/me-profile-official.component';
import {
  MeProfileOfficialInfoComponent,
} from '@app/profile/me-profile-official/me-profile-official-info/me-profile-official-info.component';
import {
  MeProfileOfficialQuestionsComponent,
} from '@app/profile/me-profile-official/me-profile-official-questions/me-profile-official-questions.component';
import {
  MeProfileOfficialPreferencesComponent,
} from '@app/profile/me-profile-official/me-profile-official-preferences/me-profile-official-preferences.component';
import { ProfileUserComponent } from '@app/profile/profile-user/profile-user.component';
import { ProfileUserInfoComponent } from '@app/profile/profile-user/profile-user-info/profile-user-info.component';
import { ProfileUserAvailabilityComponent } from '@app/profile/profile-user/profile-user-availability/profile-user-availability.component';
import { DatePipe } from '@angular/common';
import { UnsavedChangesGuard } from './core/guards/unsaved-changes.guard';
import { BanRouteGuard } from '@guards/ban-route.guard';
import { CompetitionsForFiltersResolver } from '@resolvers/competitions-for-filters.resolver';
import { StoreModule } from '@ngrx/store';
import { appReducer } from '@app/store/app.reducer';
import { EffectsModule } from '@ngrx/effects';
import { appEffects } from '@app/store/app.effect';
import {
  DashboardGroupAssignorModule,
} from '@app/dir_group_assignor/dashboard/components/dashboard-group-assignor/dashboard-group-assignor.module';
import { MainHandlerInterceptor } from '@interceptors/main-handler-interceptor.service';
import { GroupProfileInfoComponent } from '@app/group-profile/components/group-profile-info/group-profile-info.component';
import { GroupProfileManagementComponent } from '@app/group-profile/components/group-profile-management/group-profile-management.component';
import {
  GroupProfileCsvImportHistoryComponent,
} from '@app/group-profile/components/group-profile-csv-import-history/group-profile-csv-import-history.component';
import { CurrentGroupResolver } from '@app/group-profile/resolvers/current-group.resolver';
import { ForTestComponent } from '@components/for-test/for-test.component';
import {
  GroupProfileAdjustmentStatusesComponent,
} from '@app/group-profile/components/group-profile-adjustment-statuses/group-profile-adjustment-statuses.component';
import { ReportResolver } from '@resolvers/report.resolver';
import { AdjustmentStatusesResolver } from '@resolvers/adjustment-statuses.resolver';
import {
  DashboardGameInfoComponent,
} from './dir_group_assignor/dashboard/components/dashboard-group-assignor/components/dashboard-game-info/dashboard-game-info.component';
import { CompetitionResolver } from '@app/dir_group_assignor/competitions/resolvers/competition.resolver';
import {
  TUrlCompetition_about,
  TUrlCompetition_ageGroups,
  TUrlCompetition_levels,
  TUrlCompetition_list,
  TUrlCompetition_locations,
  TUrlCompetition_officialLabels,
  TUrlCompetition_payScales,
  TUrlCompetition_seasons,
  TUrlCompetition_teams,
  TUrlCompetition_users,
  TUrlCompetitions,
} from '@app/dir_group_assignor/competitions/ClassCompetition';
import { ReportingComponent } from './dir_group_assignor/reporting/reporting.component';
import { PaymentBalancesMobileComponent } from './dir_group_assignor/payments/components/payments-balances/payment-balances-mobile/payment-balances-mobile.component';
import { urlPayments_allTransactions, urlPayments_assignorFees, urlPayments_paymentDetails } from './url-const';
 import { PaymentsBalanceAutoTopupComponent } from './dir_group_assignor/payments/components/payments-balances/payments-balance-auto-topup/payments-balance-auto-topup.component';
import { PaymentsAllTransactionsComponent } from './dir_group_assignor/payments/components/payments-all-transactions/payments-all-transactions.component';

// =======================================
export type TPath = 'dashboard' | 'myWallet' | 'announcements'
  | 'meProfile' | 'meProfileOfficial' | 'profileUser' | 'groupProfile'
  | 'games' | 'assign' | 'myGames' | 'openGames' | 'competitions' | 'competitions/list' | 'competitions/users'
  | 'officials' | 'reports' | 'balances' | 'reporting'
  | 'groupProfile_userManagement'
  | 'myAssignors' | 'planner' | 'payers'
  | 'payments' | 'payments/balances'
  | 'groupProfile_adjustmentStatuses';

export type TPath_for_invitePeoples = Extract<TPath, 'officials' | 'competitions/users' | 'groupProfile_userManagement' | 'dashboard'>

// === for filters =========================
// export type TypePathForTable = 'games' | 'assign' | 'myGames' | 'openGames' | 'payments' | 'competitions/list' | 'officials' | 'reports';
export type TypePathForTable = Extract<TPath, 'games' | 'assign' | 'myGames' | 'openGames' | 'payments' | 'competitions/list' | 'officials' | 'reports' | 'groupProfile'>;
export const arrPathForGames: Array<TypePathForTable> = ['games', 'assign', 'myGames', 'openGames'];

export function currentPath_isGames(currentPath: TypePathForTable): boolean {
  return arrPathForGames.includes(currentPath);
}

// === for auth =========================
export const authRoutes = ['auth', 'signup', 'login', 'registration', 'forgot-password', 'resetPassword'];
export const banRoutes_for_OFFICIAL: Array<TPath> = ['games', 'assign', 'competitions', 'officials', 'payments', 'announcements', 'reporting'];
export const banRoutes_for_GROUP_ASSIGNOR: Array<TPath> = ['myGames', 'openGames', 'myWallet', 'myAssignors', 'planner'];

const auth_routes: Routes = [
  {
    path: '', loadComponent: () => AuthComponent, data: { title: 'Notch :: Auth' },
    loadChildren: () => [...addUserRoleInRoutes([
      { path: 'signup', loadComponent: () => SignupComponent, data: { title: 'Notch :: Signup' } },
      // { path: 'signupAdmin', loadComponent: () => SignupAdminComponent, data: { title: 'Notch :: Signup Admin' } },
      { path: 'signupOfficial', loadComponent: () => SignupOfficialComponent, data: { title: 'Notch :: Signup Official' } },
      { path: 'login', loadComponent: () => LoginComponent, data: { title: 'Notch :: Login' } },
      { path: 'forgot-password', loadComponent: () => ForgotPasswordComponent, data: { title: 'Notch :: Forgot Password' } },
      { path: 'resetPassword', loadComponent: () => CreatePasswordComponent, data: { title: 'Notch :: Create Password' } },

    ]),
    ],
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: '' }, // pathMatch: 'full'
];


// === COMPETITIONS =============================
export const idCompetitionForPath = 'idCompetitionForPath';
export const urlCompetitions: TUrlCompetitions = 'competitions';
export const urlCompetitionsList: TUrlCompetition_list = `${urlCompetitions}/list`;
export const urlCompetition_about: TUrlCompetition_about = `${urlCompetitions}/about`;
export const urlCompetition_officialLabels: TUrlCompetition_officialLabels = `${urlCompetitions}/officialLabels`;
export const urlCompetition_groups: TUrlCompetition_ageGroups = `${urlCompetitions}/ageGroups`;
export const urlCompetition_levels: TUrlCompetition_levels = `${urlCompetitions}/levels`;
export const urlCompetition_payScales: TUrlCompetition_payScales = `${urlCompetitions}/payScales`;
export const urlCompetition_seasons: TUrlCompetition_seasons = `${urlCompetitions}/seasons`;
export const urlCompetition_locations: TUrlCompetition_locations = `${urlCompetitions}/locations`;
export const urlCompetition_teams: TUrlCompetition_teams = `${urlCompetitions}/teams`;
export const urlCompetition_users: TUrlCompetition_users = `${urlCompetitions}/users`;
export const str_setup_competition = 'setup-competition';
export const urlSetupCompetition: 'competitions/about/setup-competition' = `${urlCompetitions}/about/${str_setup_competition}`;

let competitions: Routes = [
  { path: urlCompetitionsList, loadComponent: () => CompetitionsListComponent, data: { title: 'Notch :: Competitions' } },
  {
    path: `${urlCompetition_about}/:${idCompetitionForPath}`,
    loadComponent: () => CompetitionsGeneralInfoComponent,
    data: { title: 'Notch :: Competitions General Info' },
  },
  {
    path: `${urlCompetition_officialLabels}/:${idCompetitionForPath}`,
    loadComponent: () => CompetitionsOfficialLabelsComponent,
    data: { title: 'Notch :: Competitions Official Labels' },
  },
  {
    path: `${urlCompetition_groups}/:${idCompetitionForPath}`,
    loadComponent: () => CompetitionsGroupsComponent,
    data: { title: 'Notch :: Competitions Groups' },
  },
  {
    path: `${urlCompetition_levels}/:${idCompetitionForPath}`,
    loadComponent: () => CompetitionsLevelsComponent,
    data: { title: 'Notch :: Competitions Levels' },
  },
  {
    path: `${urlCompetition_payScales}/:${idCompetitionForPath}`,
    loadComponent: () => CompetitionsPayScalesComponent,
    data: { title: 'Notch :: Competitions Pay Scales' },
  },
  {
    path: `${urlCompetition_seasons}/:${idCompetitionForPath}`,
    loadComponent: () => CompetitionsSeasonsComponent,
    data: { title: 'Notch :: Competitions Seasons' },
  },
  {
    path: `${urlCompetition_locations}/:${idCompetitionForPath}`,
    loadComponent: () => CompetitionsLocationsComponent,
    data: { title: 'Notch :: Competitions Locations' },
  },
  {
    path: `${urlCompetition_teams}/:${idCompetitionForPath}`,
    loadComponent: () => CompetitionsTeamsComponent,
    data: { title: 'Notch :: Competitions Teams' },
  },
  {
    path: `${urlCompetition_users}/:${idCompetitionForPath}`,
    loadComponent: () => CompetitionsUsersComponent,
    data: { title: 'Notch :: Competitions Users' },
  },
  // {
  //   path: urlCompetition_users_invite,
  //   loadComponent: () => CompetitionsUsersComponent,
  //   data: { title: 'Notch :: Competitions Users' },
  // },
  { path: urlCompetitions, redirectTo: urlCompetitionsList, pathMatch: 'full' },
];
competitions = competitions.map((el: Route) => {
  if (el.path === urlCompetitionsList) { // !!! для списка не нужен CompetitionResolver
    return el;
  } else {
    return { ...el, resolve: { ...el.resolve, competitionResolver: CompetitionResolver } };
  }
});

// === GAME ====================
// !!! example use [routerLink]='"/"+urlGameImport' || routerLink='/{{urlGameImport}}' || (click)='router.navigate([urlGameImport])'
export const urlGames: Extract<TypePathForTable, 'games'> = 'games';
export const idGameForPath = 'idGameForPath';
export const urlGameInfo: 'games/info/' = `${urlGames}/info/`;
export const urlMyGameInfo = 'myGames/info/';
export const urlCreateGame: 'games/createGame' = `${urlGames}/createGame`;
export const urlGameImport: 'games/import' = `${urlGames}/import`;
const games: Routes = [
  {
    path: urlGames,
    loadComponent: () => GamesComponent,
    data: { title: 'Notch :: Games' },
    resolve: { hero: CompetitionsForFiltersResolver },
  },
  {
    path: `${urlMyGameInfo}:${idGameForPath}`,
    loadComponent: () => GameInfoComponent,
    data: { title: 'Notch :: Game Info' },
    resolve: { adjustmentStatusesResolver: AdjustmentStatusesResolver },
  },
  {
    path: `${urlGameInfo}:${idGameForPath}`,
    loadComponent: () => GameInfoComponent,
    data: { title: 'Notch :: Game Info' },
    resolve: { adjustmentStatusesResolver: AdjustmentStatusesResolver },
  },
  {
    path: urlCreateGame,
    loadComponent: () => CreateGameComponent,
    data: { title: 'Notch :: Create Game' },
  },
  { path: urlGameImport, loadComponent: () => ImportGamesComponent, data: { title: 'Notch :: Import Games' } },
];

export const urlMyGames: Extract<TypePathForTable, 'myGames'> = 'myGames';
const myGames: Routes = [
  {
    path: urlMyGames,
    loadComponent: () => PageMyGamesComponent,
    data: { title: 'Notch :: My Games' },
    resolve: { hero: CompetitionsForFiltersResolver },
  },
];

export const urlOpenGames: Extract<TypePathForTable, 'openGames'> = 'openGames';
const openGames: Routes = [
  {
    path: urlOpenGames,
    loadComponent: () => PageOpenGamesComponent,
    data: { title: 'Notch :: Open Games' },
    resolve: { hero: CompetitionsForFiltersResolver },
  },
];

// const postGames: Routes = [
//   { path: 'postGames', loadComponent: () => PagePostGamesComponent, data: { title: 'Notch :: Post Game' } },
//   { path: `postGame/info/:${idGameForPath}`, loadComponent: () => PostGameInfoComponent, data: { title: 'Notch' } },
// ];

export const urlAssign: Extract<TypePathForTable, 'assign'> = 'assign';
const assign: Routes = [
  {
    path: urlAssign,
    loadComponent: () => AssignComponent,
    data: { title: 'Notch :: Assign' },
    canDeactivate: [UnsavedChangesGuard],
    resolve: { hero: CompetitionsForFiltersResolver },
  },
];

export const urlOfficials: Extract<TypePathForTable, 'officials'> = 'officials';
const officials: Routes = [
  {
    path: urlOfficials, loadComponent: () => OfficialsComponent, data: { title: 'Notch :: Officials' },
  },
];

// === PROFILE ROUTES =================================
const profile_routes: Routes = [
  { // !!! ME profile ADMIN
    path: rout_meProfile, loadComponent: () => MeProfileComponent, loadChildren: () => [
      { path: 'info', loadComponent: () => MeProfileInfoComponent },
      { path: 'certifications', loadComponent: () => MeProfileCertificateComponent },
      { path: 'account', loadComponent: () => MeProfileAccountComponent }, // !!! одинаковый для судьи и админа
      { path: 'billing', loadComponent: () => MeProfileBillingComponent },
      { path: 'systemPreferences', loadComponent: () => MeProfilePreferencesComponent },
      { path: '', redirectTo: 'info', pathMatch: 'full' },
    ],
  },
  { // !!! ME profile OFFICIAL
    path: rout_meProfileOfficial, loadComponent: () => MeProfileOfficialComponent, loadChildren: () => [
      { path: 'info', loadComponent: () => MeProfileOfficialInfoComponent },
      { path: 'certifications', loadComponent: () => MeProfileCertificateComponent },
      { path: 'accountSettings', loadComponent: () => MeProfileAccountComponent }, // !!! одинаковый для судьи и админа
      { path: 'questions', loadComponent: () => MeProfileOfficialQuestionsComponent },
      { path: 'systemPreferences', loadComponent: () => MeProfileOfficialPreferencesComponent },
      { path: '', redirectTo: 'info', pathMatch: 'full' },
    ],
  },
  { // !!! profile another OFFICIAL
    path: rout_profileUser, loadComponent: () => ProfileUserComponent, loadChildren: () => [
      { path: 'contactInfo/:idUser', loadComponent: () => ProfileUserInfoComponent },
      { path: 'certifications/:idUser', loadComponent: () => MeProfileCertificateComponent },
      { path: 'availability/:idUser', loadComponent: () => ProfileUserAvailabilityComponent },
      { path: '', redirectTo: 'contactInfo/:idUser', pathMatch: 'full' },
    ],
  },
];

// === GROUP PROFILE ROUTES =================================
export const rout_groupProfile = 'groupProfile';
export const rout_groupProfile_info: 'groupProfile_info' = `${rout_groupProfile}_info`;
export const rout_groupProfile_adjustmentStatuses: 'groupProfile_adjustmentStatuses' = `${rout_groupProfile}_adjustmentStatuses`;
// export const rout_groupProfile_adjustmentStatuses_list: 'groupProfile_adjustmentStatuses/list' = `${rout_groupProfile_adjustmentStatuses}/list`;
// export const rout_groupProfile_adjustmentStatuses_edit: 'groupProfile_adjustmentStatuses/edit' = `${rout_groupProfile_adjustmentStatuses}/edit`;
// export const rout_groupProfile_adjustmentStatuses_addNew: 'groupProfile_adjustmentStatuses/addNew' = `${rout_groupProfile_adjustmentStatuses}/addNew`;
export const rout_groupProfile_userManagement: 'groupProfile_userManagement' = `${rout_groupProfile}_userManagement`;
export const rout_groupProfile_csvImportHistory: 'groupProfile_csvImportHistory' = `${rout_groupProfile}_csvImportHistory`;

const groupProfile_routes: Routes = [
  {
    path: rout_groupProfile_info,
    loadComponent: () => GroupProfileInfoComponent,
    resolve: { currentGroupResolver: CurrentGroupResolver },
  },
  {
    path: rout_groupProfile_adjustmentStatuses,
    loadComponent: () => GroupProfileAdjustmentStatusesComponent,
    resolve: { currentGroupResolver: CurrentGroupResolver, AdjustmentStatusesResolver },
    // loadChildren: () => [
    //   { path: rout_groupProfile_adjustmentStatuses_list, loadComponent: () => GroupProfileAdjustmentStatusesListComponent },
    //   { path: rout_groupProfile_adjustmentStatuses_edit, loadComponent: () => GroupProfileAdjustmentStatusesEditComponent },
    //   { path: rout_groupProfile_adjustmentStatuses_addNew, loadComponent: () => GroupProfileAdjustmentStatusesAddNewComponent },
    //   { path: '', redirectTo: rout_groupProfile_adjustmentStatuses, pathMatch: 'full' },
    // ],
  },
  // {
  //   path: rout_groupProfile_adjustmentStatuses_list,
  //   loadComponent: () => GroupProfileAdjustmentStatusesListComponent,
  //   resolve: { currentGroupResolver: CurrentGroupResolver },
  // },
  // {
  //   path: rout_groupProfile_adjustmentStatuses_edit,
  //   loadComponent: () => GroupProfileAdjustmentStatusesEditComponent,
  //   resolve: { currentGroupResolver: CurrentGroupResolver },
  // },
  // {
  //   path: rout_groupProfile_adjustmentStatuses_addNew,
  //   loadComponent: () => GroupProfileAdjustmentStatusesAddNewComponent,
  //   resolve: { currentGroupResolver: CurrentGroupResolver },
  // },
  {
    path: rout_groupProfile_userManagement,
    loadComponent: () => GroupProfileManagementComponent,
    resolve: { competitionsForFiltersResolver: CompetitionsForFiltersResolver, currentGroupResolver: CurrentGroupResolver },
  },
  {
    path: rout_groupProfile_csvImportHistory,
    loadComponent: () => GroupProfileCsvImportHistoryComponent,
    resolve: { currentGroupResolver: CurrentGroupResolver },
  },
];

// === DASHBOARD =============================================
export const urlDashboard = `dashboard`;
export const urlDashboardGame: 'dashboard/game/' = `${urlDashboard}/game/`;
const common_routes: Routes = [
  {
    path: urlDashboard,
    loadComponent: () => DashboardComponent,
    data: { title: 'Notch :: Dashboard' },
    resolve: { hero: CompetitionsForFiltersResolver },
  },
  {
    path: `${urlDashboardGame}:${idGameForPath}`,
    loadComponent: () => DashboardGameInfoComponent,
    data: { title: 'Notch :: Dashboard Game Info' },
  },
  { path: urlDashboard + `/attentions`, loadComponent: () => AttentionsComponent, data: { title: 'Notch :: Attentions' } },
  { path: '', redirectTo: urlDashboard, pathMatch: 'full' },
];

// === REPORTING =============================================
export const urlReporting = `reporting`;
const reporting: Routes = [
  {
    path: urlReporting,
    loadComponent: () => ReportingComponent,
    data: { title: 'Notch :: Dashboard' }
  }
];

// ===  WALLET ==========================
// andrei переделать
export const urlMyWallet_1 = `myWallet`;
export const urlMyWallet = `/myWallet`;
// export const urlMyWalletAll = `/myWallet/all`;
const wallet: Routes = [
  { path: urlMyWallet.slice(1), loadComponent: () => MyWalletComponent, data: { title: 'Notch :: My Wallet' } },
];

const assignor: Routes = [
  { path: 'myAssignors', loadComponent: () => MyAssignorComponent, data: { title: 'Notch :: My Assignors' } },
];

export const urlAnnouncements = `announcements`;
const announcements: Routes = [
  { path: urlAnnouncements, loadComponent: () => AnnouncementsComponent, data: { title: 'Notch :: Announcements' } },
];

const planner: Routes = [
  {
    path: 'planner',
    loadComponent: () => import('@app/dir_officials/page-planner/page-planner.component').then(m => m.PagePlannerComponent),
    data: { title: 'Notch :: Planner' },
  },
];

// === REPORT ====================
export const urlReports: Extract<TypePathForTable, 'reports'> = 'reports';
export const idReportForPath = 'idReportForPath';
export const urlReportInfo: 'reports/info/' = `${urlReports}/info/`;
const reports: Routes = [
  {
    path: urlReports,
    loadComponent: () => ReportsComponent,
    data: { title: 'Notch :: Reports' },
    resolve: { hero: CompetitionsForFiltersResolver },
  },
  {
    path: `${urlReportInfo}:${idReportForPath}`,
    loadComponent: () => ReportInfoComponent,
    data: { title: 'Notch' },
    resolve: { hero: ReportResolver, AdjustmentStatusesResolver },
  },
];

// === PAYMENTS ==================================
export const urlPayments: Extract<TypePathForTable, 'payments'> = 'payments';
export const urlPayments_balances: 'payments/balances' = `${urlPayments}/balances`;
export const idPayerForPath = 'idPayerForPath';
const payments: Routes = [
  {
    path: urlPayments, loadComponent: () => PaymentsComponent, data: { title: 'Notch :: Payments' }, loadChildren: () => [
      { path: 'overview', loadComponent: () => PaymentsOverviewComponent, data: { title: 'Notch :: Payments Overview' } },
      { path: 'assignorFees', loadComponent: () => PaymentsAssignorFeesComponent, data: { title: 'Notch :: Payments Assignor Fees' } },
      { path: 'assignorFeesTrasaction', loadComponent: () => PaymentsAllTransactionsComponent, data: { title: 'Notch :: Payments all transactions' } },
      // {
      //   path: 'assignorFees/all',
      //   loadComponent: () => PaymentsAssignorFeesAllComponent,
      //   data: { title: 'Notch :: Payments Assignor Fees' },
      // },
      { path: 'officialsFees', loadComponent: () => PaymentsOfficialsFeesComponent, data: { title: 'Notch :: Payments Officials Fees' } },
      {
        path: 'officialsFees/all',
        loadComponent: () => PaymentsOfficialsFeesAllComponent,
        data: { title: 'Notch :: Payments Officials Fees' },
      },
      { path: 'balances', loadComponent: () => PaymentsBalancesComponent, data: { title: 'Notch :: Payments Balances' } },
      { path: 'balances/all', loadComponent: () => PaymentsBalancesAllComponent, data: { title: 'Notch :: Payments Balances' } },
      { path: 'balances/pay', loadComponent: () => PaymentsBalancePayComponent, data: { title: 'Notch :: Payments Balance Pay' } },
       { path: 'balances/autoTopup', loadComponent: () => PaymentsBalanceAutoTopupComponent, data: { title: 'Notch :: Payments Balance Auto Topup' } },
      // { path: 'payers', loadComponent: () => PaymentsPayersComponent, data: { title: 'Notch :: Payments Payers' } },
      { path: `payers/info/:${idPayerForPath}`, loadComponent: () => PayerInfoComponent, data: { title: 'Notch :: Payer' } },
      { path: 'rewards', loadComponent: () => PaymentsRewardsComponent, data: { title: 'Notch :: Payments Rewards' } },
      { path: '**', redirectTo: 'overview', pathMatch: 'full' },
    ],
  },
];

const other: Routes = [
  { path: 'message/paymentResult/Succeeded', loadComponent: () => SuccessMessageComponent },
  { path: 'message/paymentResult/Failed', loadComponent: () => ErrorMessageComponent },
];

export const urlBalances: Extract<TPath, 'balances'> = 'balances';
const routes: Routes = [
  {
    path: '',
    loadComponent: () => ShellComponent,
    children: addUserRoleInRoutes([
      { path: 'forTest', loadComponent: () => ForTestComponent },
      { path: urlBalances, loadComponent: () => PaymentsBalancesComponent, data: { title: 'Notch :: Payments Balances' } },
      { path: urlBalances, loadComponent: () => PaymentsAssignorFeesComponent, data: { title: 'Notch :: Payments Balances' } },
      { path: urlBalances, loadComponent: () => PaymentsAllTransactionsComponent, data: { title: 'Notch :: Payments Balances' } },
      {
        path: urlPayments_assignorFees,
        loadComponent: () => import('@app/dir_group_assignor/payments/components/payments-assignor-fees/payments-assignor-fees.component').then(m => m.PaymentsAssignorFeesComponent),
        data: { title: 'Notch :: Payments Assignor Fees' }
      },
      {
        path: urlPayments_allTransactions,
        loadComponent: () => PaymentsAllTransactionsComponent, data: { title: 'Notch ::All Transactions' }
      },
      {
        path: urlPayments_paymentDetails,
        loadComponent: () => import('@app/dir_group_assignor/payments/components/payment-details/payment-details.component').then(m => m.PaymentDetailsComponent),
        data: { title: 'Notch :: Payments Assignor Fees' }
      },
      ...common_routes, ...competitions, ...games, ...assign, ...officials,
      ...profile_routes, ...groupProfile_routes,
      ...wallet, ...assignor, ...announcements, ...reports, ...myGames, ...openGames, ...payments, ...planner,
      ...other, ...reporting]),
    canActivate: [AuthGuard, BanRouteGuard],
    resolve: {
      hero: UserResolver,
    },
  },
  ...auth_routes,
  // ...addUserRoleInRoutes(auth_routes),
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatDialogModule,
    MatNativeDateModule,
    HammerModule,
    MatDatepickerModule,
    DashboardGroupAssignorModule,
    PaymentBalancesMobileComponent,
    PaymentsBalancesComponent,
    // NgxMaskModule.forRoot(),
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      scrollOffset: [0, 62],
      anchorScrolling: 'enabled', // Enable scrolling to anchors
    }),
    StoreModule.forRoot({ app: appReducer }, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot(appEffects),
  ],
  exports: [RouterModule],
  // providers: [provideRouter(routes), provideHttpClient(), provideAnimations()],
  providers: [
    // provideRouter(UserResolver),
    UserResolver,
    AuthGuard,
    { // from CoreModule
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MainHandlerInterceptor,
      multi: true,
    },
    { // from CoreModule
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    // { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    DatePipe,
    // UtilsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

