<div class="wrapperPopup popupConfirm">
  <headTitle [text]="data.textTitle" *ngIf="data.showCloseButton" svgRight='cross_grey&24' (svgRightEmit)='onClose()'></headTitle>
  <headTitle [text]="data.textTitle" *ngIf="!data.showCloseButton"></headTitle>

  <div class="wrapperPopup__text">{{ data.text }}</div>

  <btnWrap class="web">
    <btn class="assignremove" text="Cancel" bcg="grey" (emit)="onClose()" btnWidth="170"></btn>
  </btnWrap>

  <btnWrap class="mobile">
    <btn class="assignReturn" text="Confirm" bcg="blueDark" (emit)="onApprove()" btnWidth="100%"></btn>
  </btnWrap>  
</div>


