import { Injectable } from '@angular/core';
import { MainService } from '@services/main.service';
import { PopupService } from '@services/popup.service';
import { CustomDatesService } from '@classes/CustomDates';
import { IPopupGroupList, PopupGroupListComponent } from '@components/popup-group-list/popup-group-list.component';
import { ClassGroupListItem } from '@models/IGroup';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { ClassUser } from '@models/user';
import { THttpMethod } from '@services/base-api';
import { OtherService } from '@services/other.service';
import {
  IPopupCreateGroupList,
  PopupCreateGroupListComponent,
} from '@components/popup-create-group-list/popup-create-group-list.component';
import {
  arrCurrentLinkDrop_officials,
  currentLinkActive_officials,
  currentLinkDropActive_officials,
  currentLinkDropPending_officials,
  currentLinkPending_officials,
  ICurrentLink_officials,
  TCurrentLink_officials,
  TCurrentLinkDrop_officials,
} from '@app/dir_group_assignor/officials/officials';

// !!! здесь все зависимости от CurrentLink = 'active' | 'pending'
// export interface ICurrentLinkOfficials {
//   currentLink?: TCurrentLinkOfficials;
// }

@Injectable({ providedIn: 'root' })
export class OfficialsService {
  popupAddOfficialsToGroupRef?: MatDialogRef<PopupCreateGroupListComponent, any>;
  popupGroupListComponentRef?: MatDialogRef<PopupGroupListComponent, any>;

  // === CURRENT LINK ===============================================
  arrCurrentLinkDrop_officials = arrCurrentLinkDrop_officials;
  private currentLinkSub$ = new BehaviorSubject<ICurrentLink_officials>({});
  currentLink$ = this.currentLinkSub$.asObservable();

  updateCurrentLink(currentLink: ICurrentLink_officials) {
    this.currentLinkSub$.next({ ...this.currentLinkSub$.getValue(), ...currentLink });
  }

  set currentLinkObj(currentLinkObj: ICurrentLink_officials) {
    // const currentLink = currentLinkObj.currentLink?.toLowerCase() as TCurrentLinkOfficials;
    const currentLink = currentLinkObj.currentLink;
    const newCurrentLink: ICurrentLink_officials = { currentLink };
    this.currentLinkSub$.next(newCurrentLink);
  }

  get currentLinkObj(): ICurrentLink_officials {
    return this.currentLinkSub$.getValue();
  }

  // !!! вызывать после того как прочитаны queryParams из url (если из нет то по дефолту установится currentLinkDrop_current)
  setCurrentLink_officials(currentLink?: TCurrentLink_officials): void {
    if (currentLink && currentLink === this.currentLinkObj.currentLink?.upperCase) return; // !!! если текущее значение === значению которое поступило, то не надо заново устанавливать
    const currentLinkDrop: TCurrentLinkDrop_officials = currentLink === 'ACTIVE' ? currentLinkDropActive_officials : currentLinkDropPending_officials;
    this.currentLinkObj = { currentLink: currentLinkDrop };
  }

  // === GROUP LIST ====================
  private groupListSub$ = new BehaviorSubject<Array<ClassGroupListItem>>([]);
  groupList$ = this.groupListSub$.asObservable();

  get groupList(): Array<ClassGroupListItem> {
    return this.groupListSub$.getValue();
  }

  set groupList(groupList: Array<ClassGroupListItem>) {
    this.groupListSub$.next(groupList);
  }

  // === GROUP LIST ITEM ====================
  private currentGroupSub$ = new BehaviorSubject<ClassGroupListItem | null>(null);
  currentGroup$ = this.currentGroupSub$.asObservable();

  // get currentGroup(): ClassGroupListItem | null {
  //   return this.currentGroupSub$.getValue();
  // }
  // set currentGroup(currentGroup: ClassGroupListItem | null) {
  //   this.currentGroupSub$.next(currentGroup);
  // }

  selectedOfficials: Array<ClassUser> = [];

  constructor(
    private mainS: MainService,
    private otherS: OtherService,
    private popupS: PopupService,
    private customDatesS: CustomDatesService,
    private dialog: MatDialog,
  ) {
  }

  methodGroupList(sendData: IPopupCreateGroupList | IPopupGroupList | string | null, httpMethod: THttpMethod): void {
    this.mainS.methodGroupList(sendData, httpMethod).toPromise()
      .then((res?: Array<ClassGroupListItem> | ClassGroupListItem) => {
        if (!res) return;
        if (httpMethod == 'get') this.groupList = res as Array<ClassGroupListItem>;
        if (httpMethod == 'post') {
          this.groupList = [...this.groupList, {
            ...res as ClassGroupListItem,
            officialCount: (sendData as IPopupCreateGroupList)?.listOfficials?.length,
          }];
        }
        if (httpMethod == 'put') {
          const officials: Array<ClassUser> = (sendData as IPopupGroupList)?.officials!;
          // !!! не удалять пока this.groupList = [...this.groupList, { officialIds: officials.map(el => el.id!), officialCount: officials?.length}];
          // !!! тимур присылает в ответ {listIds, officialIds} количество которое я отправил. Мне надо итоговое количество судей для отображения в PopupGroupListComponent при добавлении судей в группу
          // !!! поэтому пока что сделал чтобы заново запрос для получения групп лотправлялся
          this.methodGroupList(null, 'get');
        }
      })
      .catch((res) => {
      });
  }

  // !!! CREATE GROUP
  openPopupCreateGroup(selectedOfficials: Array<ClassUser>): void {
    if (selectedOfficials?.length) this.selectedOfficials = selectedOfficials;
    const dataPopup: IPopupCreateGroupList = { listOfficials: this.selectedOfficials };
    this.popupAddOfficialsToGroupRef = this.dialog.open(PopupCreateGroupListComponent, {
      panelClass: 'panelClass_popupAddOfficialsToGroup',
      width: '400px',
      minWidth: '400px',
      maxWidth: '400px',
      data: dataPopup,
    });
    this.popupAddOfficialsToGroupRef?.afterClosed()?.toPromise().then((res: any) => {
      if (!res) return;
    });
  }

  // !!! ADD OFFICIALS TO  GROUP LIST
  openPopupGroupList(selectedOfficials: Array<ClassUser>): void {
    if (selectedOfficials?.length) this.selectedOfficials = selectedOfficials;
    const dataPopup: IPopupGroupList = { officials: this.selectedOfficials };
    this.popupGroupListComponentRef = this.dialog.open(PopupGroupListComponent, {
      panelClass: 'panelClass_popupGroupList',
      width: '400px',
      minWidth: '400px',
      maxWidth: '400px',
      data: dataPopup,
    });

    this.popupGroupListComponentRef.afterClosed().toPromise().then((res: ClassGroupListItem) => {
      if (!res) return;
    });
  }

  // === GETTERS & SETTERS ==========================================
  // get currentLink_officials(): TCurrentLink_officials {
  //   return this.currentLinkObj.currentLink?.upperCase!;
  // }

  get is_currentLink_active(): boolean {
    return this.currentLinkObj.currentLink?.upperCase === currentLinkActive_officials;
  }

  get is_currentLink_pending(): boolean {
    return this.currentLinkObj.currentLink?.upperCase === currentLinkPending_officials;
  }

}

// === CURRENT LINK ===============================================
// arrCurrentLink: Array<TCurrentLinkOfficials> = ['active', 'pending'];
// private currentLinkSub$ = new BehaviorSubject<ICurrentLinkOfficials>({});
// currentLink$ = this.currentLinkSub$.asObservable();
// updateCurrentLink(currentLink: ICurrentLinkOfficials) {
//   this.currentLinkSub$.next({ ...this.currentLinkSub$.getValue(), ...currentLink });
// }
// set currentLinkObj(currentLinkObj: ICurrentLinkOfficials) {
//   const currentLink = currentLinkObj.currentLink?.toLowerCase() as TCurrentLinkOfficials;
//   const newCurrentLink: ICurrentLinkOfficials = { currentLink };
//   this.currentLinkSub$.next(newCurrentLink);
// }
// get currentLinkObj(): ICurrentLinkOfficials {
//   return this.currentLinkSub$.getValue();
// }
