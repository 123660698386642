import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'getFirstLetterEachWord', standalone: true })
export class GetFirstLetterEachWordPipe implements PipeTransform {

  // !! получить первую букву каждого слова
  // !!! andrei потом сделать чтобы для любого количества amountLetters работало
  // transform(str?: string, amountLetters: number = 1): string {
  transform(str?: string, amountLetters: 1 | 2 = 1): string {
    if (!str?.trim()) return '';
    let result: string = '';
    const arr: Array<string> = str.trim()?.split(' ')?.filter(Boolean);
    // arr?.forEach((el) => result = result + el[0]?.toUpperCase());
    arr?.forEach((el) => {
      // if (result) result = result + (el[0]?.toUpperCase() || '')
      // else result = el[0]?.toUpperCase() || ''
      if (result) result = result + (el[0]?.toUpperCase() || '');
      else result = el[0]?.toUpperCase() || '';

      if (amountLetters == 2) result = result + (el[1] || '');
    });
    return result;
  }

}
