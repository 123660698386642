import { ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { ClassUser } from '@models/user';

// export type TAnnouncementLinks = 'view all' | 'sent' | 'archived';

export interface ICurrentLink_announcements {
  // currentLink?: TAnnouncementLinks;
  currentLink?: TCurrentLinkDrop_announcements;
}

export type TCurrentLinkTitleCase_announcements = 'View All' | 'Sent' | 'Archived';
export type TCurrentLink_announcements = 'ALL' | 'SENT' | 'ARCHIVE';
export type TCurrentLinkDrop_announcements = ClassDrop & {
  titleCase: TCurrentLinkTitleCase_announcements,
  upperCase: TCurrentLink_announcements,
};



export const arrCurrentLinkDrop_announcements: Array<TCurrentLinkDrop_announcements> = [
  { titleCase: 'View All', upperCase: 'ALL' },
  { titleCase: 'Sent', upperCase: 'SENT' },
  { titleCase: 'Archived', upperCase: 'ARCHIVE' },
];

export const currentLink_all_announcements: Extract<TCurrentLink_announcements, 'ALL'> = 'ALL';
export const currentLink_sent_announcements: Extract<TCurrentLink_announcements, 'SENT'> = 'SENT';
export const currentLink_archived_announcements: Extract<TCurrentLink_announcements, 'ARCHIVE'> = 'ARCHIVE';
export const currentLinkDrop_viewAll_announcements: TCurrentLinkDrop_announcements = {
  titleCase: 'View All',
  upperCase: 'ALL',
};
export const currentLinkDrop_sent_announcements: TCurrentLinkDrop_announcements = {
  titleCase: 'Sent',
  upperCase: 'SENT',
};
export const currentLinkDrop_archived_announcements: TCurrentLinkDrop_announcements = {
  titleCase: 'Archived',
  upperCase: 'ARCHIVE',
};

export interface AnnouncementItem {
  id: string;
  subject: string | null;
  type: string;
  status: string;
  date: string;
  lastModified: Date;
  time: string;
  from: string;
  method: string;
  message: string;
  draft: boolean;
  urgent: boolean;
  sms: boolean;
  email: boolean;
  text: string | null;
  recipientGroup: any | null;
  emails: Array<string> | null;
  officialListIds?: string[];
  excludeEmails?: [];
  userId?: string;
  userData?: ClassUser | null;
  files?: File[];
  attachments?: File[];
  attachmentRemove?: string[];
  isDrafted?: boolean;
  gameIds?: string[];

  isSelect?: boolean; // checked
}

export interface Announcements {
  content: AnnouncementItem[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalPages: number;
  totalElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
    sort: Array<{
      ascending: boolean;
      descending: boolean;
      direction: 'ASC' | 'DESC';
      ignoreCase: boolean;
      nullHandling: string;
      property: string;
    }>;
  };
  sort: Array<{
    'direction': 'ASC' | 'DESC';
    'property': string;
    'ignoreCase': boolean;
    'nullHandling': string;
    'ascending': boolean;
    'descending': boolean;
  }>;
}
