<div *ngIf='{ desktop: (deviceS.isDesktop$|async)!,
              mobile: (deviceS.isMobile$|async)!,
              dataTable: (dataTable$|async)!,
              arrContent: (arrContent$|async),
              loadingTable: (meTableS.loadingTable$|async),
              isViewAll: (myWalletS.isViewAll$|async)!,
              showInfiniteLoading: (deviceS.isMobile$|async)! && (myWalletS.isViewAll$|async)!,
              showBtnViewAll: !!(arrContent$|async)?.length && !(meTableS.loadingTable$|async),
             } as obj' class='wrapperMyWalletTable' wrapPage [widthPage]='myWalletS.widthPage' cdkScrollable dataTable
  [needScroll]='obj.showInfiniteLoading' (emitInfiniteLoading)='emitSettingRequest($event,"infiniteLoading")'>

  <div class='o-header'>
    <div class='o-header__content'>
      <div class='o-header__title titleMyWalletTable' [class.titleMyWalletTable--isViewAll]='obj.isViewAll'>
        <meSvg *ngIf='obj.isViewAll' svgName='arrow_squareLeft&24' (click)='myWalletS.isViewAll=false' [cur]='true'>
        </meSvg>
        Transactions
      </div>
      <!--      <div class='o-header__filters'>-->
      <div class='o-header__wrapActions'>
        <switcherBtn text1='Notch Pay' text2='Offline' [width]='225' (emit)='switcherBtnEmit($event)'></switcherBtn>
        <div class='o-header__actions'>
          <meSvg *ngIf='!obj.isViewAll' svgName='download_3&44' (click)='null' [disabled]='true'></meSvg>
          <inputCtrl [(ngModel)]='search' placeholder='Search' svgPrefix='search_grey&20' w='270'></inputCtrl>
          <btn *ngIf='obj.isViewAll' text='Download' bcg='blueDark' [btnWidth]='112' (click)='null' [disabled]='true'>
          </btn>
          <btn *ngIf='!obj.isViewAll' text='View All' bcg='white' [btnWidth]='93' (click)='myWalletS.isViewAll=true'
            [disabled]='!obj.showBtnViewAll'></btn>
        </div>
      </div>
    </div>
  </div>

  <!--  === TABLE ==============================-->
  <div class='o-content t-wrapperTable'>
    <tableHeader></tableHeader>
    <div class='t-table t-table--{{obj.desktop}}'>
      <ng-container *ngIf='obj.desktop'>
        <ng-container *ngFor='let item of obj.arrContent; let idx=index'>
          <div *ngIf='obj.isViewAll || idx < 5' class='t-table__item' itemTable [item]='item'>
            <app-tableCeil *ngIf='obj.desktop' [idxCeil]='0' [text1]='item.gameNumber'></app-tableCeil>
            <app-tableCeil [idxCeil]='1' [text1]='item.officialPositionName'></app-tableCeil>
            <app-tableCeil [idxCeil]='2' [text1]='item.ageGenderLevel' [text2]='item.competitionName'></app-tableCeil>
            <app-tableCeil [idxCeil]='3' [text1]='item.createdAt_formatted'></app-tableCeil>
            <app-tableCeil [idxCeil]='4' [text1]='item.total_formatted' [isCentered]='true'></app-tableCeil>
            <div *ngIf='obj.desktop' ceilTable [idxCeil]='5' class='t-table__item__wrapCeil t-isCentered'>
              <status [status]="settings.transferType === 'OFFLINE' ? 'Offline' : item.status"></status>
            </div>
            <app-tableCeilActions *ngIf='obj.desktop' stopPropagation [disabled]='true'></app-tableCeilActions>
            <mat-menu #menuItemTable='matMenu'>
              <ng-container [ngTemplateOutlet]='actionsForItemTable'></ng-container>
            </mat-menu>
            <ng-template #actionsForItemTable>
              <button [ngStyle]='{width: "200px"}' class='hiddenMatOption'></button>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf='obj.mobile'>
        <ng-container *ngFor='let item of obj.arrContent|getArrayForMyWalletTableForMobile; let idx=index'>
          <myWalletItemTableForMobile [myWalletItemTableForMobile]='item'
            [transactionType]='settings.transferType?.toString()'></myWalletItemTableForMobile>
        </ng-container>
      </ng-container>
      <app-tableEmpty [tableEmpty]='search|getTableEmpty:"myWalletTable"'></app-tableEmpty>
    </div>
  </div>
  <pagination *ngIf='meTableS.showPagination && obj.isViewAll' [data]='obj.dataTable'
    (emit)='emitSettingRequest($event,"pagination")'></pagination>
</div>