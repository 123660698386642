import { TransferType } from "../../models/transfer-type";

export function ConverTransfersStatusToVisualText(transfersStatus: TransfersStatus | string | undefined) {
    if (transfersStatus == 'SUCCEEDED')
        return 'Paid';
    return transfersStatus;
}

export type TransfersStatus = "SUCCEEDED" | "FAILED" | 'OFFLINE' | 'RESTORED' | 'PAID' | 'INTRANSIT';
export class GameTransfersDetailsDto {
    overallTransfersStatus?: TransfersStatus;
    gameNumber?: number;
    gameAgeDescription?: string;
    gameId?: string; // UUID as string in TS
    gameGender?: string;
    gameDate?: string; // LocalDate as ISO string
    gameTime?: string; // LocalTime as ISO string
    gameLocation?: string;
    gameStatus?: GameStatus;

    competitionId?: string; // UUID as string in TS
    competitionName?: string;
    competitionLevel?: string;
    payoutFormat?: string;

    transfers?: TransferDetailsDto[];
    transferTotalsInCents?: number;
    notchPayFeeInCents?: number;
    totalInCents?: number;
    gameType?: String;
    transactionDate?: String;
    role?: String;
}

export class TransferDetailsDto {
    transferId?: string;
    transferErrorMessage?: string | null;
    transferStatus?: TransfersStatus;
    transferTotalInCents?: number;
    transferType?: TransferType;
    recipient?: string;
    description?: string;
}

export enum GameStatus {
    Active = 'Active',
    Closed = 'Closed',
    Cancelled = 'Cancelled',
}