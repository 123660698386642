import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssignGameOfficialComponent } from '@app/dir_group_assignor/assign/components/assign-game-official/assign-game-official.component';
import { BtnComponent } from '@components/btn/btn.component';
import { GetSelectedElemsPipe } from '@pipes/get-selected-elems.pipe';
import { ShowBtnShowMoreForGOPipe } from '@app/dir_group_assignor/assign/pipes/show-btn-show-more-for-go.pipe';
import { MeTableService } from '@components/_table/me-table.service';
import { ClassGame } from '@app/dir_group_assignor/games/game';
import { AssignService } from '@app/dir_group_assignor/assign/assign.service';
import { HelperClass } from '@classes/Helper-Classes';
import { untilDestroyed } from '@ngneat/until-destroy';
import { CeilTableDirective } from '@components/_table/directives/ceil-table.directive';
import { DeviceService } from '@services/device.service';

@Component({
  selector: 'ceilAssignGameOfficial[game]',
  standalone: true,
  imports: [CommonModule, GetSelectedElemsPipe, CeilTableDirective, ShowBtnShowMoreForGOPipe, AssignGameOfficialComponent, BtnComponent],
  templateUrl: './ceil-assign-game-official.component.html',
  styleUrls: ['./ceil-assign-game-official.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CeilAssignGameOfficialComponent extends HelperClass implements OnChanges {
  @Input() game!: ClassGame;

  constructor(
    public deviceS: DeviceService,
    public meTableS: MeTableService<ClassGame>, // for-table===
    public assignS: AssignService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes.game?.currentValue) {
    //   if (this.assignS.gameId === this.game?.id) {
    //     console.log('CEIL onChanges game :', this.game);
    //     this.game.gameOfficials?.forEach((go) => {
    //       if (this.assignS.goId === go?.id) {
    //         console.log('CEIL onChanges go :', !!go?.official, go);
    //       }
    //     });
    //   }
    // }
  }

  getListOfficials(): void {
    if (this.startRequest()) return;
    this.assignS.btnInspectIsActive = true;
    this.assignS.getListOfficials(false)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((res) => {
        if (this.deviceS.isMobile) {
          this.assignS.showTableOfficialsForMobile = true;
        }
        this.endRequest();
      });
  }

  setStatus(): void {
    if (this.startRequest()) return;
    this.assignS.openPopupSetStatus()
      .pipe(
        // switchMap((res: Array<IForApiMethod_updateOfficials> | null) => {
        //   return res ? this.getSubject_forGetGamesList() : of(null); // !!! после установки статуса заново получаем список игр
        // }),
        untilDestroyed(this),
      )
      .subscribe((res) => {
        this.endRequest();
      });
  }

  deleteOfficials(): void {
    if (this.startRequest()) return;
    this.assignS.openPopupRemoveOfficials()
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((res) => {
        this.endRequest();
      });
  }

  methodShowMore(): void {
    this.game.isShowAllGO = !this.game.isShowAllGO;
    this.cd.detectChanges(); // !!! NO DELETE
  }

}
