import { Pipe, PipeTransform } from '@angular/core';
import { ClassGame } from '@app/dir_group_assignor/games/game';
import { OtherService } from '@services/other.service';
import { ClassTransfer, TransferModel } from '@models/transfer.model';

@Pipe({ name: 'getAgeGenderLevel', standalone: true })
export class GetAgeGenderLevelPipe implements PipeTransform {

  constructor(
    private otherS: OtherService,
  ) {
  }

  transform(data?: ClassGame | TransferModel | ClassTransfer, type?: 'ClassGame' | 'TransferModel' | 'ClassTransfer'): string {
    return this.otherS.getAgeGenderLevel(data, type);
  }

}
