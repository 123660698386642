import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnComponent } from '@components/btn/btn.component';
import { GetFirstLetterPipe } from '@pipes/get-first-letter.pipe';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { MatMenuModule } from '@angular/material/menu';
import { PaginationComponent } from '@components/pagination/pagination.component';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { StatusComponent } from '@components/status/status.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { HelperClass, TForPagination } from '@classes/Helper-Classes';
import { ClassSettingsRequest } from '@models/response-and-request';
import { PaymentService } from '@app/dir_group_assignor/payments/payment.service';
import { forkJoin } from 'rxjs';
import { IPayment } from '@app/dir_group_assignor/payments/modelsForPayment';
import { ISortBy } from '@components/sortBy/sortBy';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RoundBalancePipe } from '@app/dir_group_assignor/payments/pipes/round-balance.pipe';
import { GetChxSvgNamePipe } from '@components/__svg_img/svg/pipes/get-chx-svg-name.pipe';
import { GetAgeGenderLevelPipe } from '@pipes/get-age-gender-level.pipe';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import { FiltersSelectedIconComponent } from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { LinkPageComponent } from '@components/link-page/link-page.component';
import { DeviceService } from '@services/device.service';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { Router } from '@angular/router';
import { ClassCeilTableHeader } from '@components/_table/meTable';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const tableHeaderPaymentsBalancesAll: Array<ClassCeilTableHeader> = [
  { text: 'Type', isChx: true },
  { text: 'Competition' },
  { text: 'Total Amount' },
  { text: 'Status' },
  { text: 'Date initiated' },
  { text: 'Status' },
  { text: '' },
];

@UntilDestroy()
@Component({
  selector: 'app-payments-balances-all',
  standalone: true,
  imports: [CommonModule, BtnComponent, GetFirstLetterPipe, HeadTitleComponent, InputCtrlComponent, MatMenuModule, PaginationComponent, SpinnerComponent, StatusComponent, SvgComponent, FormsModule, RoundBalancePipe, GetChxSvgNamePipe, ReactiveFormsModule, GetAgeGenderLevelPipe, DropdownComponent, FiltersComponent, FiltersSelectedComponent, FiltersSelectedIconComponent, LinkPageComponent, WrapPageDirective],
  templateUrl: './payments-balances-all.component.html',
  styleUrls: ['./payments-balances-all.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentsBalancesAllComponent extends HelperClass {
  readonly tableHeader = tableHeaderPaymentsBalancesAll;
  settings: ClassSettingsRequest = new ClassSettingsRequest();

  constructor(
    public payS: PaymentService,
    public router: Router,
    public deviceS: DeviceService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
  }

  ngOnInit() {
    this.subscribeToSearch();
  }

  getDataForHTML(): void {
    // if (!this.settings?.competitions) return; // !!! здесь обязательно надо дождаться когда будет competition
    forkJoin([this.payS.getPayments(this.settings, false)]).pipe(untilDestroyed(this))
      .subscribe((res) => {
        // console.log('getDataForHTML :', res);
      });
  }

  // === SEARCH =======================
  subscribeToSearch(): void {
    this.subscribeToSearchFromHelperClass().pipe(untilDestroyed(this)).subscribe((searchValue: string) => {
      if (!searchValue) delete this.settings.search;
      this.settings.search = searchValue;
      this.getDataForHTML();
    });
  };

  // === CHECKBOX SELECT ==================================
  selectItem(item: IPayment, event: any): void {
    event.stopPropagation();
    this.selectItemFromHelperClass(item, this.payS.payments$.getValue());
  }

  selectAll(): void {
    this.payS.payments$.next(this.selectAllFromHelperClass(this.payS.payments$.getValue()));
  }

  // === PAGINATION & SORTING =====================
  paginationMethod(type: TForPagination, size?: number, page?: number, sortBy?: ISortBy): void {
    this.settings = { ...this.settings, ...this.paginationMethodHelperClass(type, this.settings, size, page, sortBy) };
    this.getDataForHTML();
  }

}
