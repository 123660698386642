import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GameNotesPopupService } from '@services/game-notes-popup.service';
import { TStatusGame } from '@app/dir_group_assignor/games/game';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { DeviceService } from '@services/device.service';
import { StopPropagationDirective } from '@directives/stop-propagation.directive';

@Component({
  selector: 'app-table-cell-game-notes',
  standalone: true,
  imports: [CommonModule, SvgComponent, StopPropagationDirective],
  templateUrl: './table-cell-game-notes.component.html',
  styleUrls: ['./table-cell-game-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableCellGameNotesComponent {
  @Input() gameDescription!: string | undefined;
  @Input() gameStatus!: TStatusGame | undefined

  constructor(public gameNotesPopupService: GameNotesPopupService, public deviceService: DeviceService) {

  }

  public isVisible(): boolean {
    const isEmpty = this.gameDescription == null || this.gameDescription?.length == 0;
    const isMobileCancel = this.deviceService.isMobile && this.gameStatus == 'CANCELLED';
    return (isEmpty || isMobileCancel) == false;
  }

}
