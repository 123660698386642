import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetStateInputPipe } from '@pipes/get-state-input.pipe';

interface ICodeConfirm {
  '0'?: string;
  '1'?: string;
  '2'?: string;
  '3'?: string;
}

@Component({
  selector: 'codeConfirm',
  standalone: true,
  imports: [CommonModule, GetStateInputPipe],
  templateUrl: './code-confirm.component.html',
  styleUrls: ['./code-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeConfirmComponent implements AfterViewInit {
  @ViewChildren('inputRef') inputRef?: QueryList<ElementRef>;
  arrInputRef: Array<any> = [];
  arrNumbers: Array<keyof ICodeConfirm> = ['0', '1', '2', '3'];
  code: ICodeConfirm = {};

  @Output() emit = new EventEmitter<string>();

  constructor(
    private r: Renderer2,
  ) {
  }

  focus(inputRef: any, type: boolean) {
    const cssClass = 'activeCodeField';
    type ? this.r.addClass(inputRef, cssClass) : this.r.removeClass(inputRef, cssClass);
  }

  ngAfterViewInit() {
    // @ts-ignore
    this.arrInputRef = this.inputRef?._results?.map(el => el.nativeElement);
    this.arrInputRef[0]?.focus();
  }

  inputCode(item: keyof ICodeConfirm, event: any, inputRef: any): void {
    if (!event.data && event.inputType == 'deleteContentBackward') return; // нажал удаление цифры. это обрабатывается в this.keydown()
    let value: string = event?.target?.value || inputRef?.value;
    const nextInput = this.arrInputRef[+item + 1];

    // !!! если значение уже есть, то его надо перезаписать && и только одна цифра должна быть
    if (value?.length > 1) inputRef.value = value.slice(-1);

    this.code[item] = inputRef?.value;
    const fullCode: string = Object.values(this.code)?.join('');
    nextInput?.focus();
    this.emit.emit(fullCode);
  }

  keydown(item: keyof ICodeConfirm, event: any, inputRef: any): void {
    const btnIsDelete = event.keyCode == 8 || event.keyCode == 46; // нажал удаление цифры
    if (!btnIsDelete) return;

    // !!! если нажал удалить в поле в котором есть значение, то просто удалить значение БЕЗ переноса фокуса на другое поле
    // !!! если нажал удалить в пустом поле, то надо сделать фокус на предыдущем поле и удалить значаение
    const existValue: boolean = !!event?.target?.value || event?.target?.value === '0';

    const previousInput = this.arrInputRef[+item - 1];
    // const currentInput = event?.target; // this.arrInputRef[+item - 1]
    // const nextInput = this.arrInputRef[+item + 1];

    setTimeout(() => {
      if (existValue) { // !!! если нажал удалить в поле в котором есть значение, то просто удалить значение БЕЗ переноса фокуса на другое поле
        this.code[item] = '';
      } else { // !!! если нажал удалить в пустом поле, то надо сделать фокус на предыдущем поле и удалить значаение
        previousInput?.focus();
        // @ts-ignore
        this.code[item - 1] = '';
        if (previousInput) previousInput.value = '';
      }
      const fullCode: string = Object.values(this.code)?.join('');
      this.emit.emit(fullCode);
    });
  }
}
