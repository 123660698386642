import { Pipe, PipeTransform } from '@angular/core';
import { ClassGame } from '@app/dir_group_assignor/games/game';
import { UtilsService } from '@services/utils.service';

@Pipe({ name: 'getListGameForPossibleGameToDelete', standalone: true })
export class GetListGameForPossibleGameToDeletePipe implements PipeTransform {

  // !!! вернуть ТОЛЬКО те игры, которые можно удалить
  transform(arrGame?: Array<ClassGame>): Array<ClassGame> {
    return UtilsService.getListGameForPossibleGameToDelete(arrGame);
  }

}
