<div class='wrapperPopup'>
  <div>
    <headTitle text='New Announcement' svgRight='cross_grey&24' (svgRightEmit)='close("")' typeText='text4-blueDark'>
    </headTitle>
    <div *ngIf="typeAnnouncement === 'availability'" class='o-text8'>
      This message will allow you to communicate with the following officials in the specified date range:
      <ul>
        <li>Available - Officials available in the specified date range</li>
        <li>Unavailable - Officials unavailable at least one day in the specified date range</li>
        <li>Availability unknown - Officials with availability not set in the specified date range</li>
      </ul>
    </div>
  </div>

  <div class='content' [formGroup]='form'>
    <div class='content-line' *ngIf="typeAnnouncement === 'dateCompetition' || typeAnnouncement === 'availability'">
      <field text='Start Date'>
        <selectDate placeholder='Date Range'></selectDate>
      </field>

      <field text='End Date'>
        <selectDate placeholder='Date Range'></selectDate>
      </field>

      <field text='Competitions' *ngIf="typeAnnouncement === 'dateCompetition'">
        <!--        <dropFormCtrl formControlName='competition' [listStr]='competitionsList' placeholder='Select Competition' [multi]="true"></dropFormCtrl>-->
        <app-dropdown formControlName='competition' [array]='competitionsListDrop' placeholder='Select Competition'
                      [multi]='true'></app-dropdown>
      </field>

      <field text='Availability Status' *ngIf="typeAnnouncement === 'availability'">
        <dropFormCtrl formControlName='status' [listStr]='statusList' placeholder='Select status' [multi]='true'></dropFormCtrl>
        <!--        // Грант, замени плиз dropFormCtrl на app-dropdown -->
        <!--        <app-dropdown formControlName='statusDrop' [array]='statusListDrop' placeholder='Select status' [multi]='true'></app-dropdown>-->
      </field>
    </div>

    <field *ngIf="typeAnnouncement === 'general' && dataPopup?.announcementItem?.type !== 'BY_GAMES'">
      <app-dropdown formControlName='recipientGroup' placeholder='Send to' [array]='sendToListObj' (changeValObj)='changeVal($event)'
                    [width]='538'>
      </app-dropdown>
    </field>

    <field *ngIf='showRecipients' text='Viewable to/Recipients*' class='o-w-100'>
      <div class='officialLists' *ngIf='officialLists?.length'>
        <span *ngFor='let officialList of officialLists.slice(0, 8)' (click)='removeEmailFromList(officialList.email!)'>
          {{ officialList?.firstName }} {{ officialList?.secondName }}
          <meSvg [w]='14' [h]='14' [svgName]="'cross_grey&24'" class='o-table__header__item__chx'>
          </meSvg>
        </span>
        <span *ngIf='officialLists.length > 7' (click)='openRecepientsPopup()' class='remaining-number'>
          + {{ currentOptionInDropDown !== 'All Users' ? officialLists.length - 8 : officialLists.length - 7 }}
        </span>
      </div>
      <addressCourtName *ngIf='officialLists?.length === 0' (click)='openRecepientsPopup()' placeholder='Viewable to/Recipients*'>
      </addressCourtName>
    </field>

    <field class='o-w-100' *ngIf='!isSms'>
      <inputCtrl formControlName='subject' placeholder='Subject Line'></inputCtrl>
    </field>

    <field text='Message' class='o-w-100 message-area-field' [svgName]="isEmail ? 'attach' : undefined" (emitData)='emitFile($event)'>
      <div class='file-list' *ngIf='files.length'>
        <span *ngFor='let file of files'>{{ file.name }}
          <meSvg (click)='deleteFileByName(file.name, file?.id)' [h]='14' [w]='14' [svgName]="'cross_red&24'"
                 class='o-table__header__item__chx'>
          </meSvg>
        </span>
      </div>
      <ng-container ngProjectAs='.ngTitleRight'>
        <div class='chx-group'>
          <chx formControlName='urgent' [disabled]='isUrgentDisable' [style.opacity]="isUrgentDisable ? '0.5': '1'" text='Urgent'>
          </chx>
          <chx formControlName='email' text='Email'>
          </chx>
          <chx (changeVal)='getChecked()' formControlName='sms' text='SMS'>
          </chx>
        </div>
      </ng-container>

      <inputCtrl style='flex: 1' [maxLength]='messageMaxLength' [showLeftCharLength]='true' (changeVal)='getMessage($event)'
                 type='textarea' formControlName='text' placeholder='Compose a message....'></inputCtrl>
      <span [ngClass]="{'error': (messageMaxLength - messageTextLength) === 0}" class='message-text-length'
            *ngIf='(messageMaxLength - messageTextLength) >=0'>
        <b>{{ messageMaxLength - messageTextLength }}</b> character left
      </span>
    </field>
    <div class='actionsPopup'>
      <btn [disabled]='isSendDisable' svgLeft='save&20' text='Save as draft' bcg='white' (emit)='close("DRAFT")'></btn>
      <btn [disabled]='isSendDisable' text='Send' bcg='blueDark' (emit)='close("SENT")'>
      </btn>
    </div>
  </div>
</div>