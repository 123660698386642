<img [src]="'/assets/success-' + (method === ACCOUNT_METHODS.STANDARD ? 'card' : 'bank') + '.svg'" alt="" />

<div class="content">
  <div class="content__title o-text2">
    Your money’s nearly there!
  </div>

  <ng-container *ngIf="method === ACCOUNT_METHODS.INSTANT; else cardTpl">
    <div class="content__text o-text11">
      We need a couple of minutes to complete your transfer before your funds are available to use in your bank account
      linked to your debit card. Keep up the good work! 🎉
    </div>

    <div class="content__text o-text11">
      Wohoo! 🎉
    </div>
  </ng-container>

  <ng-template #cardTpl>
    <div class="content__text o-text11">
      We're working on your payment. Keep up the good work.
    </div>
  </ng-template>
</div>

<btn class="btn" text="Got it!" bcg="blueDark" btnHeight="48" (click)="close()">
</btn>