<!-- Payout Popup -->
<div class="SuccessPopup" *ngIf="popupType === 'payout'; else checkOneTimePay">
  <img class="SuccessImage"
    [src]="'/assets/Instant-' + (method === ACCOUNT_METHODS.STANDARD ? 'Payout' : 'Payout') + '.svg'" alt="" />

  <div class="content">
    <div class="content__title o-text-instant-title">
      Payout on the way!
    </div>

    <div class="content__text o-text-instant">
      Your instant payout has been initiated! Funds typically arrive within minutes but may take up to 10 minutes,
      depending on your bank.
    </div>
  </div>

  <btn class="btn" text="Done" bcg="blueDark" btnHeight="48" (click)="close()">
  </btn>
</div>

<!-- Check for One-Time Pay, if not, fallback to Payment Method Success -->
<ng-template #checkOneTimePay>
 <div class="SuccessPopup" *ngIf="popupType === 'oneTimePay'; else checkAutoPayout">
    <img class="SuccessImage" src="/assets/one-Time-Payout.svg" alt="One-Time Payout" />

    <div class="content">
      <div class="content__title o-text-instant-title">
        Payout on the way!
      </div>

      <div class="content__text o-text-instant">
        Your one-time payout has been sent! Please allow 1-2 business days for the funds to reach your bank.
      </div>
    </div>

    <btn class="btn" text="Done" bcg="blueDark" btnHeight="48" (click)="close()">
    </btn>
  </div>
</ng-template>

<ng-template #checkAutoPayout>
  <div class="SuccessPopup" *ngIf="popupType === 'autopayout'; else paymentMethodSuccess">
    <img class="SuccessImage" src="/assets/auto-payout.svg" alt="Auto Payout" />

    <div class="content">
      <div class="content__title o-text-instant-title">
        Payout in progress!
      </div>

      <div class="content__text o-text-instant">
        Your auto payout has been scheduled! Please allow 1-3 business days for the funds to reach your bank.
      </div>
    </div>

    <btn class="btn" text="Done" bcg="blueDark" btnHeight="48" (click)="close()">
    </btn>
  </div>
</ng-template>

<!-- Fallback Payment Method Success Popup -->
<ng-template #paymentMethodSuccess>
  <div class="SuccessPopup">
    <headTitle text='' style="margin-top: 20px;" svgRight='cross_grey&24' (svgRightEmit)='close()'
      typeText='text4-blueDark'>
    </headTitle>

    <img class="SuccessImage" [src]="'/assets/payout-success-icon.svg'" alt="" style="margin-top: 70px" />

    <div class="content">
      <div class="content__text o-text-instant">
        Payment method added successfully!
      </div>
    </div>

    <btn class="btn" text="Done" bcg="blueDark" btnHeight="48" (click)="close()">
    </btn>
  </div>
</ng-template>
