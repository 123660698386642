import { Pipe, PipeTransform } from '@angular/core';
import { OtherService } from '@services/other.service';
import { ClassGame } from '@app/dir_group_assignor/games/game';

@Pipe({ name: 'matTooltipForLocation', standalone: true })
export class MatTooltipForLocationPipe implements PipeTransform {

  constructor(
    private otherS: OtherService,
  ) {
  }

  // return {Location Name}, {Address Line 1}, {Address Line 2}, {City}, {State} {Zip Code}
  transform(game?: ClassGame): string {
    return this.otherS.getMatTooltipForLocation(game);
  }

}
