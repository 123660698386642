<div *ngIf='(deviceS.isDesktop$ | async) as obj'>
  <div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
             } as obj' class='o-wrapPage o-wrapPage--noMarginTopAndNoPadding' wrapPage [widthPage]='1200'>

    <!--  === FILTERS & TITLE ===========================-->
    <div class='o-header'>
      <div class='o-header__linkPage'>
        <app-dropdown [ngModel]='currentLink' [array]='payS.linkPaymentsItems' (changeValObj)='payS.navigate($event)'></app-dropdown>
        <app-filtersSelectedIcon></app-filtersSelectedIcon>
      </div>
      <div class='o-header__content'>
        <div class='o-header__title'>{{ currentLink.titleCase }}</div>
        <div class='o-header__filters'>
          <app-filters (emit)='emitSettingRequest($event)'></app-filters>
        </div>
        <app-filtersSelected></app-filtersSelected>
      </div>
    </div>

    <div class='o-content'>
      <div class='contentPaymentsOverview'>
        <div class='blockPayments'>
          <div class='blockPayments__head'>
            <div class='blockPayments__head__title'>Analytics</div>
            <div class='blockPayments__head__action'>
              <btn text='Edit Charts' bcg='white' svgLeft='settings_1&16' btnWidth='146' (emit)='openPopupEditCharts()'></btn>
            </div>
          </div>

          <div class='contentAnalytics'>
            <blockChart *ngFor='let chart of (arrForAnalytics|getOnlySelectedArrCharts)' [chart]='chart'></blockChart>
          </div>
        </div>

        <div class='blockPayments'>
          <div class='blockPayments__head'>
            <div class='blockPayments__head__title'>Payers</div>
            <div class='blockPayments__head__action'>
              <btn text='View All' bcg='white' btnWidth='84' (emit)='payS.goToAllList(router.url)'
                   [disabled]='!payS.testArrForCompetitions?.length'></btn>
            </div>
          </div>

          <div class='contentCompetitions o-wrap'>
            <ng-container *ngIf='payS.testArrForCompetitions?.length; else nothingList'>
              <div *ngFor='let testCompetitions of payS.testArrForCompetitions' class='contentCompetitions__block'>
                <div class='contentCompetitions__block__head'>
                  <meSvg *ngIf='testCompetitions.crown' [svgName]='testCompetitions.crown'></meSvg>
                  {{ testCompetitions.title }}
                  <span>${{ testCompetitions.amount }}K</span>
                </div>
                <progressBar [value]='testCompetitions.percent||0' color='newGreen' bcg='grey_3' class='o-mg-t-12' height='8'></progressBar>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<coming-soon-mobile *ngIf='(deviceS.isMobile$ | async) as obj'></coming-soon-mobile>

<ng-template #nothingList>
  <!--  <nothingList typePage='payments'></nothingList>-->
  <nothingList [tableEmpty]='filtersS.searchValue|getTableEmpty'>
    <btn bcg='newGreen' text='New Competition +' [btnHeight]='deviceS.btnHeightForNothingList'
         (emit)='router.navigate([urlSetupCompetition])' class='class_ngContent_btn_for_nothingList'></btn>
  </nothingList>
</ng-template>
