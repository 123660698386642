import { agesItems, ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { TKeyofSettingsRequest } from '@models/response-and-request';
import {
  arrPeriodsForCurrent,
  arrPeriodsForPast,
  IDatePeriod,
  TChoosePeriod,
} from '@components/__drop_inputs_matSelect/date-range/dateRange';
import { TSvgName } from '@components/__svg_img/svg/forSvg';
import { currentLink_past, TCurrentLink } from '@classes/dictionary';

export interface IForClassForFilters {
  setFilters(): void;
}

export type TKeyofSettingsRequest_forFilters = Extract<TKeyofSettingsRequest, 'search' | 'competitions' | 'assignStatuses' | 'groupId'
  | 'levels' | 'distance' | 'zipcode' | 'activeCompetitions' | 'competitionPreferences' | 'location' | 'officialList' | 'officialStatus'
  | 'seasons' | 'payoutFormat'>;
export type TypeFilter = TKeyofSettingsRequest_forFilters | 'dateRange' | 'ages'; // | 'fromAge' | 'toAge'
export type TypeFilter_string = Extract<TypeFilter, 'search' | 'zipcode'>;
export type TypeFilter_dateRange = Extract<TypeFilter, 'dateRange'>;
export type TypeFilter_drop = Extract<TypeFilter, 'competitions' | 'assignStatuses' | 'groupId' | 'levels' | 'distance'
  | 'activeCompetitions' | 'competitionPreferences' | 'location' | 'ages' | 'officialList' | 'officialStatus' | 'seasons' | 'payoutFormat'>;
export type TypesAllFilters = TypeFilter_string | TypeFilter_dateRange | TypeFilter_drop;

export const arrTypeFilter: Array<TypeFilter> = [
  'search', 'dateRange', 'competitions', 'assignStatuses', 'groupId', 'levels', 'distance', 'zipcode', 'activeCompetitions',
  'competitionPreferences', 'location', 'ages', 'officialList', 'officialStatus', 'seasons', 'payoutFormat',
]; // 'fromAge', 'toAge',
export const arrTypeFilter_forInput: Array<TypeFilter_string> = ['search', 'zipcode'];
export const arrTypeFilter_forDateRange: Array<TypeFilter_dateRange> = ['dateRange'];
export const arrTypeFilter_forDrop: Array<TypeFilter_drop> = [
  'competitions', 'assignStatuses', 'groupId', 'levels', 'distance', 'activeCompetitions', 'competitionPreferences',
  'location', 'ages', 'officialList', 'officialStatus', 'seasons', 'payoutFormat',
];

// === interfaces ==============================================
export type TAllInterfacesFilters = ClassFilterInput | ClassFilterDateRange | ClassFilterDrop;

export interface IFilter {
  typeFilter?: TypeFilter;
  visible?: boolean; // !!! по дефолту true здесь в классе назначается. при переключении вкладок на странице officials разные фильтры. Чтобы вместо setFilters делать updateFilters
  disabled?: boolean;
  index?: number; // !!! в каком порядке показывать фильтры
  // isChanges?: boolean; // !!! был ли изменён фильтр пользователем

  valueFromUrl?: string; // !!! value from QueryParams
  forTest?: string; // andrei== delete
}

// === Classes ========================================================
export class ClassFilterInput implements IFilter {
  typeFilter?: TypeFilter_string; // 'search'
  valueString?: string;
  index?: number; // !!! в каком порядке показывать фильтры
  disabled?: boolean;
  visible?: boolean; // !!! By default, true is assigned here in the class. When switching tabs on the Officials page, there are different filters. To make updateFilters instead of setFilters

  // isChanges?: boolean; // !!! был ли изменён фильтр пользователем
  valueFromUrl?: string; // !!! value from QueryParams
  forTest?: string; // andrei== delete

  constructor(filter?: ClassFilterInput) {
    this.visible = typeof filter?.visible === 'boolean' ? this.visible = filter.visible : true;
    this.typeFilter = filter?.typeFilter;
    this.valueString = filter?.valueString || '';
    if (typeof filter?.index == 'number') this.index = filter?.index;
    if (typeof filter?.disabled) this.disabled = filter?.disabled;

    if (filter?.valueFromUrl) this.valueFromUrl = filter?.valueFromUrl;
    if (filter?.forTest) this.forTest = filter?.forTest;
  }
}

export class ClassFilterDateRange implements IFilter {
  // static readonly templatePreviosData: Array<TChoosePeriod> = ['Today', 'Last 7 days', 'Last 30 days', 'Last 3 months', 'Last 12 months', 'Month to Date', 'Quarter to Date', 'Year to Date', 'Custom Date Range'];
  static readonly templatePreviosData: Array<TChoosePeriod> = ['Today', 'Last 7 days', 'Last 30 days', 'Last 3 months', 'Last 12 months', 'Month to Date', 'Quarter to Date', 'Year to Date'];
  typeFilter?: TypeFilter_dateRange = 'dateRange'; // 'dateRange'
  typePeriod?: TCurrentLink; // 'current' | 'past'
  arrPeriods?: Array<TChoosePeriod>;
  period?: TChoosePeriod; // !!! если нужно поставить период при загрузке страницы
  datePeriod?: IDatePeriod; // !!! {from, to} для отправки на сервер
  fromTo_formatted?: string;
  index?: number; // !!! в каком порядке показывать фильтры
  disabled?: boolean;
  visible?: boolean; // !!! по дефолту true здесь в классе назначается. при переключении вкладок на странице officials разные фильтры. Чтобы вместо setFilters делать updateFilters
  noDelete?: boolean;

  // isChanges?: boolean; // !!! был ли изменён фильтр пользователем
  valueFromUrl?: string; // !!! value from QueryParams
  forTest?: string; // andrei== delete

  constructor(filter?: ClassFilterDateRange) {
    this.visible = typeof filter?.visible === 'boolean' ? this.visible = filter.visible : true;
    this.typePeriod = filter?.typePeriod;
    this.period = filter?.period;
    this.arrPeriods = this.typePeriod === currentLink_past ? arrPeriodsForPast : arrPeriodsForCurrent;
    if (filter?.arrPeriods) this.arrPeriods = filter.arrPeriods;
    this.datePeriod = filter?.datePeriod;
    this.fromTo_formatted = filter?.fromTo_formatted;
    if (typeof filter?.index == 'number') this.index = filter?.index;
    if (typeof filter?.disabled) this.disabled = filter?.disabled;
    if (filter?.noDelete) this.noDelete = filter?.noDelete;
    if (filter?.valueFromUrl) this.valueFromUrl = filter?.valueFromUrl;
    if (filter?.forTest) this.forTest = filter?.forTest;
  }

}

export class ClassFilterDrop implements IFilter {
  typeFilter?: TypeFilter_drop; // 'competitions' | 'assignStatus'
  arrayForDropdown?: Array<ClassDrop>; // [listObj]='objDrop.itemDrop.listObj'
  valueDrop?: Array<ClassDrop> | ClassDrop; // !!! если нужно выбрать элемент при загрузке страницы
  default_valueDrop?: Array<ClassDrop> | ClassDrop; // !!! если нужно после resetFilters поставить значение по умолчанию. Например на странице payments всегда должен быть выбран компетишн, т.к. получение данные с сервера по idCompetition
  multi?: boolean; // if [multi]='true' for dropFormCtrl => Array<IDropItem>
  index?: number; // !!! в каком порядке показывать фильтры
  disabled?: boolean;
  visible?: boolean; // !!! по дефолту true здесь в классе назначается. при переключении вкладок на странице officials разные фильтры. Чтобы вместо setFilters делать updateFilters
  noDelete?: boolean; // !!! запретить удаление фильтра. Например на странице payments всегда должен быть выбран компетишн, т.к. получение данные с сервера по idCompetition
  svgPrefix?: TSvgName; // в самом дропдауне
  // isChanges?: boolean; // !!! был ли изменён фильтр пользователем
  valueFromUrl?: string; // !!! value from QueryParams
  forTest?: string; // andrei== delete

  constructor(filter?: ClassFilterDrop) {
    // if (typeof filter?.visible === 'boolean') this.visible = filter.visible
    this.visible = typeof filter?.visible === 'boolean' ? this.visible = filter.visible : true;
    this.typeFilter = filter?.typeFilter!;
    this.arrayForDropdown = filter?.arrayForDropdown || [];
    this.valueDrop = filter?.valueDrop;
    if (filter?.default_valueDrop) this.default_valueDrop = filter?.default_valueDrop;
    this.multi = filter?.multi;
    if (typeof filter?.index == 'number') this.index = filter?.index;
    if (typeof filter?.disabled) this.disabled = filter?.disabled;
    if (filter?.noDelete) this.noDelete = filter?.noDelete;
    if (filter?.svgPrefix) this.svgPrefix = filter?.svgPrefix;

    if (filter?.valueFromUrl) this.valueFromUrl = filter?.valueFromUrl;
    if (filter?.forTest) this.forTest = filter?.forTest;

    if (filter?.typeFilter === 'ages') {
      this.arrayForDropdown = agesItems;
    }
  }
}

