<div *ngIf='{desktop: (deviceS.isDesktop$|async), mobile: (deviceS.isMobile$|async)} as obj'>
  <div class='overviewDashboard__overview__row'>
    <div *ngFor='let item of arrForColumnComingSoon; let i = index'
         [hidden]='selectedTable !== item.id && obj.mobile'
         class='tile__item official'
         (click)='selectTable(item)'
         (swipeleft)='onSwipe($event, i)' (swiperight)='onSwipe($event, i)'
         [ngClass]='{ isActive: selectedTable === item.id}'>
      {{ item.title }}
      <p class='o-text1'>{{ item.value }}</p>
      <!--            <meSvg class="dashboard-tooltip-description" matTooltip='{{getTooltipText(item.id)}}' [w]="20" [h]="20" svgName='question_grey&20'></meSvg>-->
    </div>
    <div class='overviewDashboard__selector' *ngIf='obj.mobile'>
      <div *ngFor='let item of arrForColumnComingSoon' class='overviewDashboard__selector__item'>
        <meSvg (click)='selectTable(item)' class='o-cur-pointer' width='10' height='10'
               [svgName]='selectedTable === item.id ? "circle_blue1&8":"circle_grey2&8"'></meSvg>
      </div>
    </div>
  </div>
</div>
