import { Pipe, PipeTransform } from '@angular/core';
import { OtherService } from '@services/other.service';

@Pipe({ name: 'checkValidAllFieldsFromObject', standalone: true })
export class CheckValidAllFieldsFromObjectPipe implements PipeTransform {

  constructor(
    private otherS: OtherService,
  ) {
  }

  // object == {cardNumber: false, cardExpiry: false, name: false, cardCvc: false}
  // проверка всех полей в объекте // если valid => вернется true
  transform(object: any): boolean {
    return this.otherS.checkValidAllFieldsFromObject(object);
  }

}
