import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ClassUser, TUserRoleUpperCase } from '@models/user';
import { MainService } from '@services/main.service';
import { TypePageProfile } from '@app/profile/profile';

@Injectable({ providedIn: 'root' })
export class ProfileUserService {
  readonly typePageProfile: TypePageProfile = 'profileUser';
  user$ = new BehaviorSubject<ClassUser | null>(null);

  constructor(
    private mainS: MainService,
    // private profileS: ProfileService,
    // private router: Router,
  ) {
  }

  // !!! userRole если передал роль юзера то нужно проверять. Для адимна/групасайнера другой запрос нужно отправлять для получения getUserById()
  getContactById(idUser: string, userRole?: TUserRoleUpperCase): void {
    if (this.user$.getValue()?.id === idUser) return; // !!! чтобы при переключении вкладок повторно не запрашивать сервер
    this.user$.next(null);
    // console.log('userRole :', idUser, userRole)
    const forViewOfficialProfile = userRole === 'OFFICIAL' || !userRole;
    // console.log('forViewOfficialProfile :', forViewOfficialProfile)
    const observable$ = forViewOfficialProfile ? this.mainS.getOfficialById(idUser) : this.mainS.getUserById(idUser);
    observable$.toPromise()
      .then((res?: ClassUser | null) => {
        // console.log('res :', res)
        if (res) this.user$.next(res);
      })
      .catch((err) => {
      });
  }

  get id(): string {
    return this.user$.getValue()?.id || '';
  }

  // get userId(): string {
  //   return this.user$.getValue()?.userId || '';
  // }
}
