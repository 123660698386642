import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeTableService } from '@components/_table/me-table.service';
import { DeviceService } from '@services/device.service';
import { CeilTableDirective } from '@components/_table/directives/ceil-table.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SplitPipe } from '@pipes/string/split.pipe';

@Component({
  selector: 'app-tableDoubleCeil',
  standalone: true,
  imports: [CommonModule, CeilTableDirective, MatTooltipModule, SplitPipe],
  templateUrl: './table-double-ceil.component.html',
  styleUrls: ['./table-double-ceil.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableDoubleCeilComponent<T> {
  @Input() idxCeil!: number;
  @Input() text1?: string;
  @Input() text2?: string;
  @Input() matTooltipText1?: string;

  @Input() text3?: string;
  @Input() text4?: string;
  @Input() matTooltipText2?: string;

  // @Input() matTooltipText?: string; // for matTooltipText1 & matTooltipText2

  // !!! для мобилы для последней ячейки не должно быть бордера, т.к. после последней ячейки сразу кнопки (actions for table)
  // !!! бордер для ячеек есть только для мобилы. Поэтому создал этот флаг. // для desktop бордера для ячеек нет. Для desktop бордер на itemTable
  @Input() bordNone_forMobile = false;

  @Input() commaForMobile = true; // !!! для мобилы ножно text1 + ', ' + text2 //Но для teams не нужна запятая. Поэтому для teams передаю false

  // @Input() forTest = ''

  constructor(
    public meTableS: MeTableService<T>,
    public deviceS: DeviceService,
    public cd: ChangeDetectorRef,
  ) {
  }
}
