import { Injectable } from '@angular/core';
import { MeService } from '@services/me.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClassCompetition } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { MainService } from '@services/main.service';
import { IRequestOptions, IResponse } from '@models/response-and-request';
import { OtherService } from '@services/other.service';
import { IDataPopup, PopupService } from '@services/popup.service';
import { TChoosePeriod } from '@components/__drop_inputs_matSelect/drop-date-range/drop-date-range.component';
import { PopupConfirmComponent } from '@components/__popup-windows/popup-confirm/popup-confirm.component';
import {
  arrReportStatus_forAdmin,
  arrReportStatus_forOfficial,
  ClassReport,
  ClassReportStatusDrop,
  getArrReportStatusDrop,
  getReportStatusDrop,
  TReportStatus,
} from '@app/dir_group_assignor/reports/report';
import { tap } from 'rxjs/operators';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';

// !!! здесь все зависимости от TCurrentLinkReports = 'due' | 'flagged' | 'Needs Approval' | 'Approved' | 'Archived'
export interface ICurrentLinkReports {
  // currentLink?: ILinkReport;
  currentLink?: ClassReportStatusDrop;
  defaultPeriod?: TChoosePeriod; // for dropDateRange
  arrPeriods?: Array<TChoosePeriod>; // for dropDateRange
}

const arrPeriods: Array<TChoosePeriod> = ['All time', 'Yesterday', 'Last 7 days', 'Last 2 weeks', 'Last month', 'Last 3 months', 'Last 12 months'];

@Injectable({ providedIn: 'root' })
export class ReportService {
  // === CURRENT LINK ===============================================
  // arrCurrentLinkSub$ = new BehaviorSubject<Array<ILinkReport>>(arrReportsLink);
  // arrCurrentLinkSub$ = new BehaviorSubject<Array<ILinkReport>>(arrReportsLink);
  arrCurrentLinkSub$ = new BehaviorSubject<Array<ClassReportStatusDrop>>(getArrReportStatusDrop(this.meS.OFFICIAL));
  private currentLinkSub$ = new BehaviorSubject<ICurrentLinkReports>({});
  currentLink$ = this.currentLinkSub$.asObservable();

// (IDrop & {titleCase: TReportStatusForHtml, upperCase: TReportStatus, count?: number})
  updateCurrentLink(currentLink: ICurrentLinkReports) {
    this.currentLinkSub$.next({ ...this.currentLinkSub$.getValue(), ...currentLink });
  }

  set currentLinkObj(currentLinkObj: ICurrentLinkReports) {
    const currentLink = currentLinkObj.currentLink;
    const newCurrentLink: ICurrentLinkReports = {
      currentLink,
      defaultPeriod: 'All time',
      arrPeriods: arrPeriods,
    };
    this.currentLinkSub$.next(newCurrentLink);
  }

  get currentLinkObj(): ICurrentLinkReports {
    return this.currentLinkSub$.getValue();
  }

  // !!! вызывать после того как прочитаны queryParams из url (если из нет то по дефолту установится currentLinkDrop_current)
  setCurrentLink_reports(currentLink?: TReportStatus): void {
    if (currentLink && currentLink === this.currentLinkObj.currentLink?.upperCase) return; // !!! если текущее значение === значению которое поступило, то не надо заново устанавливать
    if (!currentLink) {
      this.currentLinkObj = { currentLink: new ClassReportStatusDrop('DUE') };
    } else {
      this.currentLinkObj = { currentLink: getReportStatusDrop(currentLink!, this.meS.OFFICIAL) };
    }
  }

  getAmountReportsStatuses(): Observable<Array<ClassReportStatusDrop>> {
    return this.mainS.getAmountReportsStatuses()
      .pipe(
        tap((res) => {
          const newArrLink: Array<ClassReportStatusDrop> = this.meS.OFFICIAL ? arrReportStatus_forOfficial : arrReportStatus_forAdmin;
          res?.forEach((el) => {
            newArrLink?.forEach((item) => {
              if (el.upperCase === item.upperCase) item.count = el.count || 0;
            });
          });
          this.arrCurrentLinkSub$.next([...newArrLink]);
        }),
        // untilDestroyed(this),
      );
  }


  // === CURRENT REPORT (for /report/info =========================================
  private reportSub$ = new BehaviorSubject<ClassReport | undefined>(undefined);
  report$ = this.reportSub$.asObservable();
  // show_gameDetails: boolean = false; //  !this.report?.game?.gameAdjustmentStatus?.id && !!this.report;
  // show_canceledDetails: boolean = false; //  !!this.report?.game?.gameAdjustmentStatus?.id;
  officialOnFirstPosition = false; // !!! gameOfficial.officialPositionNumber

  // updateReport(report: ClassReport | undefined) {
  //   this.reportSub$.next({ ...this.report, ...report });
  // }

  set report(report: ClassReport | undefined) {
    this.reportSub$.next(report);
  }

  get report(): ClassReport | undefined {
    return this.reportSub$.getValue();
  }

  getReport(reportId: string): Observable<ClassReport> {
    if (!reportId) console.error('ReportService.getReport() reportId :', reportId);
    return this.mainS.methodReport({ id: reportId }, 'get')
      .pipe(
        tap((res) => this.updateReport_afterResponseServer(res)),
      );
  }

  updateReport(sendObj: ClassReport) {
    return this.mainS.methodReport(sendObj, 'put')
      .pipe(
        tap((res) => {
          this.updateReport_afterResponseServer({ ...sendObj, ...res });
          this.checkAttendance();
        }),
      );
  }

  private updateReport_afterResponseServer(report: ClassReport): void {
    window.scrollTo(0, 0);
    this.report = report;
    // this.show_gameDetails = (!report?.game?.gameAdjustmentStatus?.id || !report?.game?.gameAdjustmentStatus?.adjustmentStatusId) && !!report;
    // this.show_canceledDetails = report?.game?.gameStatus === 'CANCELLED'; // миша сказал поставить только CANCELLED 23.05.24 // !!report && !!report?.game?.gameAdjustmentStatus?.id && report?.game?.gameStatus === 'CANCELLED';
    this.officialOnFirstPosition = !!report?.game?.gameOfficials?.some(go => {
      return go?.official?.userId === this.meS.me?.userId && go.officialPositionNumber === 1;
    });
    // if (this.report) {
    //   console.log('updateReport_afterResponseServer :', Object.entries(this.report))
    //   console.log('updateReport_afterResponseServer game :', this.report.game)
    // }
  }

  checkAttendance(): void {
    const updated_attendance = this.report?.attendance;
    updated_attendance?.forEach((el, idx) => {
      // attendanceItem.stateOfficialAttendance = 'Present'; // !!! потому что админу не нужно подтверждать своё действие о замене. Поэтому статус сразу Present
      if (el.participated && !el.switched && !el.switchIdentifier && !el.switchedReferee) el.stateOfficialAttendance = 'Present';
      // if (el.participated && el.switched && (el.switchIdentifier || el.switchedReferee)) el.stateOfficialAttendance = 'Replace';
      if (el.participated && el.switched && (el.switchIdentifier || el.switchedReferee)) el.stateOfficialAttendance = 'Present'; // !!! потому что админу не нужно подтверждать своё действие о замене. Поэтому статус сразу Present
      if (!el.participated && !el.switched && !el.switchIdentifier && !el.switchedReferee) el.stateOfficialAttendance = 'Absent';
    });

    const updatedReport = { ...this.report, attendance: updated_attendance };
    this.reportSub$.next(updatedReport);
    // if (this.report) {
    //   console.log('checkAttendance :', Object.entries(this.report));
    //   console.log('checkAttendance game :', this.report.game);
    // }
  }

  // ============================================
  arrCompetitionSub$ = new BehaviorSubject<Array<ClassCompetition>>([]);

  competitionSub$ = new BehaviorSubject<ClassCompetition | null>(null);

  set competition(competition: ClassCompetition | null) {
    this.competitionSub$.next(competition);
  }

  get competition(): ClassCompetition | null {
    return this.competitionSub$.getValue();
  }

  constructor(
    private mainS: MainService,
    private apiCompetitionS: ApiCompetitionService,
    private otherS: OtherService,
    private popupS: PopupService,
    // private lodashS: LodashService,
    // private customDatesS: CustomDatesService,
    private meS: MeService,
  ) {
    // this.otherS.getDropItem();
  }

  reset(): void {
    this.arrCompetitionSub$.next([]);
    this.competitionSub$.next(null);
  }

  getArrCompetition(groupId?: string): void {
    let requestOptions: IRequestOptions | undefined = undefined;
    if (groupId) requestOptions = { params: { groupId } };
    this.apiCompetitionS.getArrCompetition(requestOptions).toPromise()
      .then((res?: IResponse<ClassCompetition>) => {
        if (res?.content) {
          const newArr = res?.content?.map((el, idx) => {
            return { ...el, title: el?.competitionName, name: el?.competitionName, value: el?.id };
          });
          // newArr?.unshift(new ClassDropForNameDefault());
          // this.competition = new ClassDropForNameDefault();
          const newArrUnique = this.otherS.getArrayUniqueObjectsByID([...this.arrCompetitionSub$.getValue(), ...newArr]);
          this.arrCompetitionSub$.next(newArrUnique);
        }
      })
      .catch(() => {
      });
  }

  changeValCompetition(competition: ClassCompetition): void {
    if (!competition) return;
    this.competitionSub$.next(competition);
    this.getCompetition(competition?.id!, true);
  }

  getCompetition(IdCompetition: string, isNewArr = false): void {
    this.apiCompetitionS.getCompetition(IdCompetition).toPromise()
      .then((res?: ClassCompetition) => {
        const newCompetition: ClassCompetition = {
          ...{
            ...this.competition,
            title: this.competition?.competitionName || res?.competitionName,
            name: this.competition?.competitionName || res?.competitionName,
            value: this.competition?.id || res?.id,
          }, ...res,
        };
        this.competitionSub$.next(newCompetition);
      })
      .catch(() => {
      });
  }

  // !!! по idOfficial ищу в gameOfficial и добавляю этот gameOfficial в attendanceItem. Это нужно чтобы получить название роли и т.д. Потому что в attendance Этой инфы нет
  // addGoInAttendance(report?: ClassReport): Array<ClassAttendance> {
  //   const arrAttendance: Array<ClassAttendance> = report?.attendance || [];
  //   report?.game?.gameOfficials?.forEach((go) => {
  //     arrAttendance?.forEach((itemAttendance) => {
  //       // if (go?.official?.id === itemAttendance?.official?.id) {
  //       if (go?.id === itemAttendance?.idGameOfficial) {
  //         itemAttendance.go = this.otherS.deepClone(go);
  //       }
  //     });
  //   });
  //   return arrAttendance;
  // }

  // === POPUP ====================================
  // !!! reportItem если передал то это на конкретном репорте нажал Approve. Если не передал то это Approve All
  openPopupApprove(arrIdsReport: Array<string>): Promise<boolean> {
    const textForPopup = arrIdsReport.length > 1
      ? `Are you ready to approve your bulk selection of game reports? Your approval will be irreversible.`
      : `Are you ready to approve this game report? Your approval will be irreversible.`;
    const dataPopup: IDataPopup = {
      width: '400px',
      textTitle: 'Fast-Track Approval',
      // text: `Are you ready to approve this game report${arrIdsReport.length > 1 ? 's' : ''}? Your approval will be irreversible.`,
      text: textForPopup,
      textBtnCancel: 'Approve Later',
      textBtnApply: 'Approve & Pay',
    };
    return this.popupS.open(PopupConfirmComponent, dataPopup);
  }

}
