import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass } from '@classes/Helper-Classes';
import { DeviceService } from '@services/device.service';
import { MainService } from '@services/main.service';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { MeService } from '@services/me.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomDatesService } from '@classes/CustomDates';
import { ClassGame } from '@app/dir_group_assignor/games/game';
import { BtnComponent } from '@components/btn/btn.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { Geo } from '@classes/geo';
import { PopupService } from '@services/popup.service';
import { PhotoComponent } from '@components/__svg_img/photo/photo.component';
import { GetUserNamePipe } from '@pipes/get-name.pipe';
import { GetFirstLetterEachWordPipe } from '@pipes/string/get-first-letter-each-word.pipe';
import { GetLocationStringPipe } from '@pipes/location/get-location-string.pipe';
import { StatusComponent } from '@components/status/status.component';
import { DateFormattedComponent } from '@components/__date/date-formatted/date-formatted.component';
import { FormatPhonePipe } from '@pipes/phone/format-phone.pipe';
import { GetSvgForGoStatusPipe } from '@pipes/gameOfficial/get-svg-for-go-status.pipe';
import { urlGameInfo, urlGames, urlReportInfo } from '@app/app.module';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TooltipDirective } from '@directives/tooltip.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AdminPermissionDirective } from '@directives/admin-permission.directive';

@UntilDestroy()
@Component({
  selector: 'app-game-info',
  standalone: true,
  imports: [CommonModule, BtnComponent, BtnWrapComponent, HeadTitleComponent, SvgAndTextComponent, SvgComponent,
    PhotoComponent, GetUserNamePipe, GetFirstLetterEachWordPipe, GetLocationStringPipe, StatusComponent, DateFormattedComponent, FormatPhonePipe, 
    GetSvgForGoStatusPipe, TooltipDirective, MatTooltipModule, AdminPermissionDirective],
  templateUrl: './dashboard-game-info.component.html',
  styleUrls: ['./dashboard-game-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardGameInfoComponent extends HelperClass implements OnInit {
  game?: ClassGame;
  isEditable = false;
  simplifiedView?: boolean;
  readonly urlGames = urlGames;
  readonly urlGameInfo = urlGameInfo;
  readonly urlReportInfo = urlReportInfo;
  currentPhonePopup = -1;
  isMobile = false;

  constructor(
    private mainS: MainService,
    public gameS: GameService,
    public meS: MeService,
    private route: ActivatedRoute,
    public router: Router,
    public customDatesS: CustomDatesService,
    public geo: Geo,
    public popupS: PopupService,
    public cd: ChangeDetectorRef,
    public deviceService: DeviceService,
    private elementRef: ElementRef,    
  ) {
    super(cd);
    this.deviceService.isMobile$.subscribe((_isMobile: boolean) => {
      this.isMobile = _isMobile;
    });
  }

  ngOnInit() {
    this.route.params.pipe(untilDestroyed(this)).subscribe((params) => {
      this.getGame(params?.idGameForPath);
    });

    this.isEditable = this.meS.meRole !== 'OFFICIAL';
  }

  ngAfterViewInit() {
    document.addEventListener('touchstart', (event: TouchEvent) => {
      const phonePopup = this.elementRef.nativeElement.querySelector('.official-mobile__texts .phone-popup.mobile-mode');
      const target = (event && event.target as any) || null;
      const id = (target['id']) || '';
      if (phonePopup && !phonePopup.contains(event.target) && (id.indexOf('phone-image') === -1)) {
        setTimeout(() => {
          this.hidePhoneNumber();
        }, 100);
        // Testing the timeout for closing popup
      }
    });
  }

  updateView(): void {
    this.gameS.checkPreviewGame(this.game!);
    this.cd.detectChanges();
  }

  calculateAge(dateOfBirth: string): number {
    const birthDate = new Date(dateOfBirth);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - birthDate.getFullYear();

    // Adjust age based on the month and day
    if (currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
      return age - 1;
    }

    return age;
  }


  getGame(gameId: string): void {
    if (!gameId || this.startRequest()) return;
    this.mainS.methodGame({ id: gameId }, 'get').toPromise()
      .then((res?: ClassGame) => {
        if (!res) return;
        this.game = res;
        this.geo.checkLocForGoggleMapFromILocation(false, this.game?.location?.address);
        this.updateView();
      })
      .catch((err) => {
      })
      .finally(() => this.endRequest());
  }

  goToEditGame(): void {
    this.gameS.goToEditGame(this.game!, false).then((res?: ClassGame) => {
      if (!res) return;
      this.game = res;
      this.gameS.checkPreviewGame(this.game!);      
      this.cd.detectChanges();
    });
  }

  // === OTHER =====================
  goToBack(): void {
    window.history.back();
  }

  showPhoneNumber: number | boolean = false;
  showImageHover: boolean = true;
  popupHovered: boolean = false;


  callPhoneNumber(phoneNumber: string | undefined) {
    if (phoneNumber) {
      window.location.href = 'tel:' + phoneNumber;
    }
    this.showPhoneNumber = false;
  }

  setPhoneNumPopup(go: number) {
    this.showPhoneNumber = go;
  }

  shouldShowOpoup(go: number) {
    return this.showPhoneNumber === go;
  }

  hidePhoneNumber(): void {
    this.showPhoneNumber = true;
    document.querySelectorAll('.official-mobile__texts').forEach(e => {
      e.classList.remove('hover');
    });
  }

  popupLeave(event: any) {
    const { target } = event;
    target.parentElement.classList.remove('hover');
    this.showPhoneNumber = false;
  }

  resetPopup() {
    if (this.isMobile) return;
    this.showPhoneNumber = false;
    this.currentPhonePopup = -1;
  }

}


