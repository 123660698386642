import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { ILocation } from '@models/location';
import { HelperClass } from '@classes/Helper-Classes';
import { LocationService, TFieldLoc } from '@components/__blocks/location/location.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceService } from '@services/device.service';
import { ProfileService } from '@app/profile/profile.service';
import { OtherService } from '@services/other.service';
import { ClassUser } from '@models/user';
import { TGameType } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { ClassGroup } from '@models/IGroup';
import { UntilDestroy } from '@ngneat/until-destroy';
import { GroupProfileService } from '@app/group-profile/group-profile.service';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import {
  TitleAndNavigationForProfileComponent,
} from '@app/profile/additional_components/title-and-navigation-for-profile/title-and-navigation-for-profile.component';
import { PhotoComponent } from '@components/__svg_img/photo/photo.component';
import { ContactItemComponent } from '@app/profile/additional_components/contact-item/contact-item.component';
import { GetLocationStringPipe } from '@pipes/location/get-location-string.pipe';
import { FieldRowComponent } from '@components/__drop_inputs_matSelect/field-row/field-row.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { BtnComponent } from '@components/btn/btn.component';
import { LocationComponent } from '@components/__blocks/location/location.component';
import { MeService } from '@services/me.service';
import { LinkPageComponent } from '@components/link-page/link-page.component';
import { UtilsService } from '@services/utils.service';

interface IGroupProfileForm {
  name: FormControl<string>;
  companyLegalName: FormControl<string>;
  logo: FormControl<string>;
  email: FormControl<string>;
  phone: FormControl<string>;
  gameType: FormControl<TGameType>;
  groupAddress?: FormControl<ILocation>;
  groupAssignor?: FormControl<ClassUser>;
  groupAssignorId: FormControl<string>;
  mailingAddress: FormControl<string>;
  groupTermsConditions?: FormControl<string>;
}

@UntilDestroy()
@Component({
  selector: 'app-group-profile-info',
  standalone: true,
  imports: [CommonModule, HeadTitleComponent, TitleAndNavigationForProfileComponent, PhotoComponent, ContactItemComponent, GetLocationStringPipe, FieldRowComponent, InputCtrlComponent, BtnComponent, LocationComponent, ReactiveFormsModule, LinkPageComponent],
  templateUrl: './group-profile-info.component.html',
  styleUrls: ['./group-profile-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupProfileInfoComponent extends HelperClass {
  isEdit = false;
  form!: FormGroup<IGroupProfileForm>;
  fields: Array<TFieldLoc> = ['street', 'streetLine2', 'city', 'state', 'zipcode']; // список всех полей которые надо отобразить
  requiredFields: Array<TFieldLoc> = []; // список обязательных полей

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public deviceS: DeviceService,
    // public profileS: ProfileService,
    public groupProfileS: GroupProfileService,
    public meS: MeService,
    public otherS: OtherService,
    private formBuilder: UntypedFormBuilder,
    public locationS: LocationService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.groupProfileS.setCurrentLink_groupProfile('info'); // for-currentLink===
    // this.getUserIdFromQueryParams();
    this.createForm();
    this.locationS.reset();
    // this.locationS.setData(this.groupProfileS.group$.getValue()?.groupAddress || undefined);
    this.locationS.setData(this.meS.meCurrentGroup?.groupAddress || undefined);
    this.createForm();
  }

  submit(): void {
    if (this.startRequest()) return;
    this.form.markAllAsTouched();
    // const locValid = this.locationS.checkErrorLoc(this.requiredFields);
    // if (!locValid) return;

    const formValue = this.form.getRawValue();
    const group: ClassGroup = {
      ...formValue,
      // address: this.otherS.removeEmptyKeysFromObject(address),
    } as ClassGroup;

    const loc: ILocation = this.locationS.loc;
    const address: ILocation = { // GroupAddressDto
      // mailingAddress: this.ctrl.mailingAddress.value,
      // streetAddress: loc.street,
      street: loc.street,
      city: loc.city,
      state: loc.state,
      zipcode: loc.zipcode,
      streetLine2: loc.streetLine2,
      timezone: loc.timezone,
    };

    group.groupAddress = { ...group.groupAddress, ...UtilsService.removeEmptyKeysFromObject(address) };

    console.log('group :', group);
    console.log('group groupAddress :', group.groupAddress);
    this.isEdit = false;
    // this.groupProfileS.group$.next(group);
    this.meS.update_meCurrentGroup(group); // this.meS.meCurrentGroup = group
    this.endRequest();
    return;

    // this.mainS.updateGroup(user).toPromise()
    //   .then(() => {
    //     // this.updateInitialValueForm(this.form)
    //     // this.router.navigate([rout_groupProfile]);
    // this.groupProfileS.group$.next(group)
    //     this.isEdit = false;
    //   })
    //   .catch(() => {
    //   })
    //   .finally(() => this.endRequest());
  }

  // getUserIdFromQueryParams(): void {
  //   this.route.params.pipe(
  //     switchMap((res) => {
  //       if (res?.idGroup) return this.groupProfileS.getGroupById(res.idGroup);
  //       else return of(null);
  //     }),
  //     untilDestroyed(this),
  //   )
  //     .subscribe((group) => {
  //       if (!group) return;
  //       if (group) {
  //         this.createForm();
  //         this.cd.markForCheck();
  //       }
  //     });
  // }

  // === FORM ==================================
  createForm(): void {
    // const group: ClassGroup | null = this.groupProfileS.group$.getValue();
    const group: ClassGroup = this.meS.meCurrentGroup!;
    this.form = this.formBuilder.group({
      name: [group?.name, [Validators.required]],
      companyLegalName: [group?.companyLegalName, [Validators.required]],
      logo: [group?.logo, [Validators.required]],
      email: [group?.email, [Validators.required]],
      phone: [group?.phone, [Validators.required]],
      gameType: [group?.gameType, [Validators.required]],
      groupAddress: [group?.groupAddress, [Validators.required]],
      groupAssignor: [group?.groupAssignor, [Validators.required]],
      groupAssignorId: [group?.groupAssignorId, [Validators.required]],
      mailingAddress: [group?.mailingAddress, [Validators.required]],
      groupTermsConditions: [group?.groupTermsConditions, [Validators.required]],
    });
  }

  get ctrl(): IGroupProfileForm {
    return this.form?.controls;
  }

}
