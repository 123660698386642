import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { FiltersService } from '@components/filters/filters.service';
import { MatBadgeModule } from '@angular/material/badge';
import { ValuesSelectedFiltersPipe } from '@components/filters/pipes/values-selected-filters.pipe';
import { DeviceService } from '@services/device.service';

@Component({
  selector: 'app-filtersSelectedIcon',
  standalone: true,
  imports: [CommonModule, SvgComponent, MatBadgeModule, ValuesSelectedFiltersPipe],
  templateUrl: './filters-selected-icon.component.html',
  styleUrls: ['./filters-selected-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersSelectedIconComponent {
  @HostBinding('class.o-none') get class_o_none(): boolean {
    // 1. for Desktop => isDesktop$ && !this.filtersS.getValuesSelectedFilters()?.length => openSelectedFilters()
    // 2. for Mobile  => !isDesktop$ => openWindowForSelectFilters()
    if (this.deviceS.isDesktop$.getValue()) {
      return !this.filtersS.getValuesSelectedFilters()?.length;
    }
    return false;
  }

  @Input() filterText: string = '';

  constructor(
    public filtersS: FiltersService,
    public deviceS: DeviceService,
  ) {
  }

  action(): void {
    this.deviceS.isDesktop$.getValue()
      ? this.filtersS.showSelectedFilters = !this.filtersS.showSelectedFilters
      : this.filtersS.showFilters_forMobile$.next(true);
  }
}
