import { ITimezone } from '@classes/geo';

export const shortListTimezone: ReadonlyArray<ITimezone> = [
  { abbrev: 'HT', name: 'Hawaii Daylight Time', value: -9, id: 'US/Aleutian' },
  { abbrev: 'AT', name: 'Alaska Daylight Time', value: -8, id: 'US/Alaska' },
  { abbrev: 'PT', name: 'Pacific Daylight Time', value: -7, id: 'US/Pacific' },
  { abbrev: 'MT', name: 'Mountain Daylight Time', value: -6, id: 'US/Mountain' },
  { abbrev: 'CT', name: 'Central Daylight Time', value: -5, id: 'US/Central' },
  { abbrev: 'ET', name: 'Eastern Daylight Time', value: -4, id: 'US/Eastern' },
];

export const fullListTimezone: ReadonlyArray<ITimezone> = [
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'Africa/Abidjan', value: 0 },
  { abbrev: 'GMT', name: 'Ghana Mean Time', id: 'Africa/Accra', value: 0 },
  { abbrev: 'EAT', name: 'Eastern African Time', id: 'Africa/Addis_Ababa', value: 3 },
  { abbrev: 'CET', name: 'Central European Time', id: 'Africa/Algiers', value: 1 },
  { abbrev: 'EAT', name: 'Eastern African Time', id: 'Africa/Asmara', value: 3 },
  { abbrev: 'EAT', name: 'Eastern African Time', id: 'Africa/Asmera', value: 3 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'Africa/Bamako', value: 0 },
  { abbrev: 'WAT', name: 'Western African Time', id: 'Africa/Bangui', value: 1 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'Africa/Banjul', value: 0 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'Africa/Bissau', value: 0 },
  { abbrev: 'CAT', name: 'Central African Time', id: 'Africa/Blantyre', value: 2 },
  { abbrev: 'WAT', name: 'Western African Time', id: 'Africa/Brazzaville', value: 1 },
  { abbrev: 'CAT', name: 'Central African Time', id: 'Africa/Bujumbura', value: 2 },
  { abbrev: 'EET', name: 'Eastern European Time', id: 'Africa/Cairo', value: 2 },
  { abbrev: 'WEST', name: 'Western European Summer Time', id: 'Africa/Casablanca', value: 1 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Africa/Ceuta', value: 2 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'Africa/Conakry', value: 0 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'Africa/Dakar', value: 0 },
  { abbrev: 'EAT', name: 'Eastern African Time', id: 'Africa/Dar_es_Salaam', value: 3 },
  { abbrev: 'EAT', name: 'Eastern African Time', id: 'Africa/Djibouti', value: 3 },
  { abbrev: 'WAT', name: 'Western African Time', id: 'Africa/Douala', value: 1 },
  { abbrev: 'WEST', name: 'Western European Summer Time', id: 'Africa/El_Aaiun', value: 1 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'Africa/Freetown', value: 0 },
  { abbrev: 'CAT', name: 'Central African Time', id: 'Africa/Gaborone', value: 2 },
  { abbrev: 'CAT', name: 'Central African Time', id: 'Africa/Harare', value: 2 },
  { abbrev: 'SAST', name: 'South Africa Standard Time', id: 'Africa/Johannesburg', value: 2 },
  { abbrev: 'EAT', name: 'Eastern African Time', id: 'Africa/Juba', value: 3 },
  { abbrev: 'EAT', name: 'Eastern African Time', id: 'Africa/Kampala', value: 3 },
  { abbrev: 'CAT', name: 'Central African Time', id: 'Africa/Khartoum', value: 2 },
  { abbrev: 'CAT', name: 'Central African Time', id: 'Africa/Kigali', value: 2 },
  { abbrev: 'WAT', name: 'Western African Time', id: 'Africa/Kinshasa', value: 1 },
  { abbrev: 'WAT', name: 'Western African Time', id: 'Africa/Lagos', value: 1 },
  { abbrev: 'WAT', name: 'Western African Time', id: 'Africa/Libreville', value: 1 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'Africa/Lome', value: 0 },
  { abbrev: 'WAT', name: 'Western African Time', id: 'Africa/Luanda', value: 1 },
  { abbrev: 'CAT', name: 'Central African Time', id: 'Africa/Lubumbashi', value: 2 },
  { abbrev: 'CAT', name: 'Central African Time', id: 'Africa/Lusaka', value: 2 },
  { abbrev: 'WAT', name: 'Western African Time', id: 'Africa/Malabo', value: 1 },
  { abbrev: 'CAT', name: 'Central African Time', id: 'Africa/Maputo', value: 2 },
  { abbrev: 'SAST', name: 'South Africa Standard Time', id: 'Africa/Maseru', value: 2 },
  { abbrev: 'SAST', name: 'South Africa Standard Time', id: 'Africa/Mbabane', value: 2 },
  { abbrev: 'EAT', name: 'Eastern African Time', id: 'Africa/Mogadishu', value: 3 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'Africa/Monrovia', value: 0 },
  { abbrev: 'EAT', name: 'Eastern African Time', id: 'Africa/Nairobi', value: 3 },
  { abbrev: 'WAT', name: 'Western African Time', id: 'Africa/Ndjamena', value: 1 },
  { abbrev: 'WAT', name: 'Western African Time', id: 'Africa/Niamey', value: 1 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'Africa/Nouakchott', value: 0 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'Africa/Ouagadougou', value: 0 },
  { abbrev: 'WAT', name: 'Western African Time', id: 'Africa/Porto-Novo', value: 1 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'Africa/Sao_Tome', value: 0 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'Africa/Timbuktu', value: 0 },
  { abbrev: 'EET', name: 'Eastern European Time', id: 'Africa/Tripoli', value: 2 },
  { abbrev: 'CET', name: 'Central European Time', id: 'Africa/Tunis', value: 1 },
  { abbrev: 'CAT', name: 'Central African Time', id: 'Africa/Windhoek', value: 2 },
  { abbrev: 'HDT', name: 'Hawaii Daylight Time', id: 'America/Adak', value: -9 },
  { abbrev: 'AKDT', name: 'Alaska Daylight Time', id: 'America/Anchorage', value: -8 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/Anguilla', value: -4 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/Antigua', value: -4 },
  { abbrev: 'BRT', name: 'Brasilia Time', id: 'America/Araguaina', value: -3 },
  { abbrev: 'ART', name: 'Argentine Time', id: 'America/Argentina/Buenos_Aires', value: -3 },
  { abbrev: 'ART', name: 'Argentine Time', id: 'America/Argentina/Catamarca', value: -3 },
  { abbrev: 'ART', name: 'Argentine Time', id: 'America/Argentina/ComodRivadavia', value: -3 },
  { abbrev: 'ART', name: 'Argentine Time', id: 'America/Argentina/Cordoba', value: -3 },
  { abbrev: 'ART', name: 'Argentine Time', id: 'America/Argentina/Jujuy', value: -3 },
  { abbrev: 'ART', name: 'Argentine Time', id: 'America/Argentina/La_Rioja', value: -3 },
  { abbrev: 'ART', name: 'Argentine Time', id: 'America/Argentina/Mendoza', value: -3 },
  { abbrev: 'ART', name: 'Argentine Time', id: 'America/Argentina/Rio_Gallegos', value: -3 },
  { abbrev: 'ART', name: 'Argentine Time', id: 'America/Argentina/Salta', value: -3 },
  { abbrev: 'ART', name: 'Argentine Time', id: 'America/Argentina/San_Juan', value: -3 },
  { abbrev: 'ART', name: 'Argentine Time', id: 'America/Argentina/San_Luis', value: -3 },
  { abbrev: 'ART', name: 'Argentine Time', id: 'America/Argentina/Tucuman', value: -3 },
  { abbrev: 'ART', name: 'Argentine Time', id: 'America/Argentina/Ushuaia', value: -3 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/Aruba', value: -4 },
  { abbrev: 'PYT', name: 'Paraguay Time', id: 'America/Asuncion', value: -4 },
  { abbrev: 'EST', name: 'Eastern Standard Time', id: 'America/Atikokan', value: -5 },
  { abbrev: 'HDT', name: 'Hawaii Daylight Time', id: 'America/Atka', value: -9 },
  { abbrev: 'BRT', name: 'Brasilia Time', id: 'America/Bahia', value: -3 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'America/Bahia_Banderas', value: -5 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/Barbados', value: -4 },
  { abbrev: 'BRT', name: 'Brasilia Time', id: 'America/Belem', value: -3 },
  { abbrev: 'CST', name: 'Central Standard Time', id: 'America/Belize', value: -6 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/Blanc-Sablon', value: -4 },
  { abbrev: 'AMT', name: 'Amazon Time', id: 'America/Boa_Vista', value: -4 },
  { abbrev: 'COT', name: 'Colombia Time', id: 'America/Bogota', value: -5 },
  { abbrev: 'MDT', name: 'Mountain Daylight Time', id: 'America/Boise', value: -6 },
  { abbrev: 'ART', name: 'Argentine Time', id: 'America/Buenos_Aires', value: -3 },
  { abbrev: 'MDT', name: 'Mountain Daylight Time', id: 'America/Cambridge_Bay', value: -6 },
  { abbrev: 'AMT', name: 'Amazon Time', id: 'America/Campo_Grande', value: -4 },
  { abbrev: 'EST', name: 'Eastern Standard Time', id: 'America/Cancun', value: -5 },
  { abbrev: 'VET', name: 'Venezuela Time', id: 'America/Caracas', value: -4 },
  { abbrev: 'ART', name: 'Argentine Time', id: 'America/Catamarca', value: -3 },
  { abbrev: 'GFT', name: 'French Guiana Time', id: 'America/Cayenne', value: -3 },
  { abbrev: 'EST', name: 'Eastern Standard Time', id: 'America/Cayman', value: -5 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'America/Chicago', value: -5 },
  { abbrev: 'MDT', name: 'Mountain Daylight Time', id: 'America/Chihuahua', value: -6 },
  { abbrev: 'EST', name: 'Eastern Standard Time', id: 'America/Coral_Harbour', value: -5 },
  { abbrev: 'ART', name: 'Argentine Time', id: 'America/Cordoba', value: -3 },
  { abbrev: 'CST', name: 'Central Standard Time', id: 'America/Costa_Rica', value: -6 },
  { abbrev: 'MST', name: 'Mountain Standard Time', id: 'America/Creston', value: -7 },
  { abbrev: 'AMT', name: 'Amazon Time', id: 'America/Cuiaba', value: -4 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/Curacao', value: -4 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'America/Danmarkshavn', value: 0 },
  { abbrev: 'MST', name: 'Mountain Standard Time', id: 'America/Dawson', value: -7 },
  { abbrev: 'MST', name: 'Mountain Standard Time', id: 'America/Dawson_Creek', value: -7 },
  { abbrev: 'MDT', name: 'Mountain Daylight Time', id: 'America/Denver', value: -6 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'America/Detroit', value: -4 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/Dominica', value: -4 },
  { abbrev: 'MDT', name: 'Mountain Daylight Time', id: 'America/Edmonton', value: -6 },
  { abbrev: 'ACT', name: 'Acre Time', id: 'America/Eirunepe', value: -5 },
  { abbrev: 'CST', name: 'Central Standard Time', id: 'America/El_Salvador', value: -6 },
  { abbrev: 'PDT', name: 'Pacific Daylight Time', id: 'America/Ensenada', value: -7 },
  { abbrev: 'MST', name: 'Mountain Standard Time', id: 'America/Fort_Nelson', value: -7 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'America/Fort_Wayne', value: -4 },
  { abbrev: 'BRT', name: 'Brasilia Time', id: 'America/Fortaleza', value: -3 },
  { abbrev: 'ADT', name: 'Atlantic Daylight Time', id: 'America/Glace_Bay', value: -3 },
  { abbrev: 'WGST', name: 'Western Greenland Summer Time', id: 'America/Godthab', value: -2 },
  { abbrev: 'ADT', name: 'Atlantic Daylight Time', id: 'America/Goose_Bay', value: -3 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'America/Grand_Turk', value: -4 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/Grenada', value: -4 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/Guadeloupe', value: -4 },
  { abbrev: 'CST', name: 'Central Standard Time', id: 'America/Guatemala', value: -6 },
  { abbrev: 'ECT', name: 'Ecuador Time', id: 'America/Guayaquil', value: -5 },
  { abbrev: 'GYT', name: 'Guyana Time', id: 'America/Guyana', value: -4 },
  { abbrev: 'ADT', name: 'Atlantic Daylight Time', id: 'America/Halifax', value: -3 },
  { abbrev: 'CDT', name: 'Cuba Daylight Time', id: 'America/Havana', value: -4 },
  { abbrev: 'MST', name: 'Mountain Standard Time', id: 'America/Hermosillo', value: -7 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'America/Indiana/Indianapolis', value: -4 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'America/Indiana/Knox', value: -5 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'America/Indiana/Marengo', value: -4 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'America/Indiana/Petersburg', value: -4 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'America/Indiana/Tell_City', value: -5 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'America/Indiana/Vevay', value: -4 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'America/Indiana/Vincennes', value: -4 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'America/Indiana/Winamac', value: -4 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'America/Indianapolis', value: -4 },
  { abbrev: 'MDT', name: 'Mountain Daylight Time', id: 'America/Inuvik', value: -6 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'America/Iqaluit', value: -4 },
  { abbrev: 'EST', name: 'Eastern Standard Time', id: 'America/Jamaica', value: -5 },
  { abbrev: 'ART', name: 'Argentine Time', id: 'America/Jujuy', value: -3 },
  { abbrev: 'AKDT', name: 'Alaska Daylight Time', id: 'America/Juneau', value: -8 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'America/Kentucky/Louisville', value: -4 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'America/Kentucky/Monticello', value: -4 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'America/Knox_IN', value: -5 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/Kralendijk', value: -4 },
  { abbrev: 'BOT', name: 'Bolivia Time', id: 'America/La_Paz', value: -4 },
  { abbrev: 'PET', name: 'Peru Time', id: 'America/Lima', value: -5 },
  { abbrev: 'PDT', name: 'Pacific Daylight Time', id: 'America/Los_Angeles', value: -7 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'America/Louisville', value: -4 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/Lower_Princes', value: -4 },
  { abbrev: 'BRT', name: 'Brasilia Time', id: 'America/Maceio', value: -3 },
  { abbrev: 'CST', name: 'Central Standard Time', id: 'America/Managua', value: -6 },
  { abbrev: 'AMT', name: 'Amazon Time', id: 'America/Manaus', value: -4 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/Marigot', value: -4 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/Martinique', value: -4 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'America/Matamoros', value: -5 },
  { abbrev: 'MDT', name: 'Mountain Daylight Time', id: 'America/Mazatlan', value: -6 },
  { abbrev: 'ART', name: 'Argentine Time', id: 'America/Mendoza', value: -3 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'America/Menominee', value: -5 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'America/Merida', value: -5 },
  { abbrev: 'AKDT', name: 'Alaska Daylight Time', id: 'America/Metlakatla', value: -8 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'America/Mexico_City', value: -5 },
  { abbrev: 'PMDT', name: 'Pierre & Miquelon Daylight Time', id: 'America/Miquelon', value: -2 },
  { abbrev: 'ADT', name: 'Atlantic Daylight Time', id: 'America/Moncton', value: -3 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'America/Monterrey', value: -5 },
  { abbrev: 'UYT', name: 'Uruguay Time', id: 'America/Montevideo', value: -3 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'America/Montreal', value: -4 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/Montserrat', value: -4 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'America/Nassau', value: -4 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'America/New_York', value: -4 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'America/Nipigon', value: -4 },
  { abbrev: 'AKDT', name: 'Alaska Daylight Time', id: 'America/Nome', value: -8 },
  { abbrev: 'FNT', name: 'Fernando de Noronha Time', id: 'America/Noronha', value: -2 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'America/North_Dakota/Beulah', value: -5 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'America/North_Dakota/Center', value: -5 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'America/North_Dakota/New_Salem', value: -5 },
  { abbrev: 'WGST', name: 'Western Greenland Summer Time', id: 'America/Nuuk', value: -2 },
  { abbrev: 'MDT', name: 'Mountain Daylight Time', id: 'America/Ojinaga', value: -6 },
  { abbrev: 'EST', name: 'Eastern Standard Time', id: 'America/Panama', value: -5 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'America/Pangnirtung', value: -4 },
  { abbrev: 'SRT', name: 'Suriname Time', id: 'America/Paramaribo', value: -3 },
  { abbrev: 'MST', name: 'Mountain Standard Time', id: 'America/Phoenix', value: -7 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'America/Port-au-Prince', value: -4 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/Port_of_Spain', value: -4 },
  { abbrev: 'ACT', name: 'Acre Time', id: 'America/Porto_Acre', value: -5 },
  { abbrev: 'AMT', name: 'Amazon Time', id: 'America/Porto_Velho', value: -4 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/Puerto_Rico', value: -4 },
  { abbrev: 'America/Punta_Arenas', name: 'America/Punta_Arenas', id: 'America/Punta_Arenas', value: -3 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'America/Rainy_River', value: -5 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'America/Rankin_Inlet', value: -5 },
  { abbrev: 'BRT', name: 'Brasilia Time', id: 'America/Recife', value: -3 },
  { abbrev: 'CST', name: 'Central Standard Time', id: 'America/Regina', value: -6 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'America/Resolute', value: -5 },
  { abbrev: 'ACT', name: 'Acre Time', id: 'America/Rio_Branco', value: -5 },
  { abbrev: 'ART', name: 'Argentine Time', id: 'America/Rosario', value: -3 },
  { abbrev: 'PDT', name: 'Pacific Daylight Time', id: 'America/Santa_Isabel', value: -7 },
  { abbrev: 'BRT', name: 'Brasilia Time', id: 'America/Santarem', value: -3 },
  { abbrev: 'CLT', name: 'Chile Time', id: 'America/Santiago', value: -4 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/Santo_Domingo', value: -4 },
  { abbrev: 'BRT', name: 'Brasilia Time', id: 'America/Sao_Paulo', value: -3 },
  { abbrev: 'EGST', name: 'Eastern Greenland Summer Time', id: 'America/Scoresbysund', value: 0 },
  { abbrev: 'MDT', name: 'Mountain Daylight Time', id: 'America/Shiprock', value: -6 },
  { abbrev: 'AKDT', name: 'Alaska Daylight Time', id: 'America/Sitka', value: -8 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/St_Barthelemy', value: -4 },
  { abbrev: 'NDT', name: 'Newfoundland Daylight Time', id: 'America/St_Johns', value: -2 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/St_Kitts', value: -4 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/St_Lucia', value: -4 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/St_Thomas', value: -4 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/St_Vincent', value: -4 },
  { abbrev: 'CST', name: 'Central Standard Time', id: 'America/Swift_Current', value: -6 },
  { abbrev: 'CST', name: 'Central Standard Time', id: 'America/Tegucigalpa', value: -6 },
  { abbrev: 'ADT', name: 'Atlantic Daylight Time', id: 'America/Thule', value: -3 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'America/Thunder_Bay', value: -4 },
  { abbrev: 'PDT', name: 'Pacific Daylight Time', id: 'America/Tijuana', value: -7 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'America/Toronto', value: -4 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/Tortola', value: -4 },
  { abbrev: 'PDT', name: 'Pacific Daylight Time', id: 'America/Vancouver', value: -7 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'America/Virgin', value: -4 },
  { abbrev: 'MST', name: 'Mountain Standard Time', id: 'America/Whitehorse', value: -7 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'America/Winnipeg', value: -5 },
  { abbrev: 'AKDT', name: 'Alaska Daylight Time', id: 'America/Yakutat', value: -8 },
  { abbrev: 'MDT', name: 'Mountain Daylight Time', id: 'America/Yellowknife', value: -6 },
  { abbrev: 'AWST', name: 'Australian Western Standard Time', id: 'Antarctica/Casey', value: 11 },
  { abbrev: 'DAVT', name: 'Davis Time', id: 'Antarctica/Davis', value: 7 },
  { abbrev: 'DDUT', name: 'Dumont-dUrville Time', id: 'Antarctica/DumontDUrville', value: 10 },
  { abbrev: 'MIST', name: 'Macquarie Island Standard Time', id: 'Antarctica/Macquarie', value: 10 },
  { abbrev: 'MAWT', name: 'Mawson Time', id: 'Antarctica/Mawson', value: 5 },
  { abbrev: 'NZST', name: 'New Zealand Standard Time', id: 'Antarctica/McMurdo', value: 12 },
  { abbrev: 'CLT', name: 'Chile Time', id: 'Antarctica/Palmer', value: -3 },
  { abbrev: 'ROTT', name: 'Rothera Time', id: 'Antarctica/Rothera', value: -3 },
  { abbrev: 'NZST', name: 'New Zealand Standard Time', id: 'Antarctica/South_Pole', value: 12 },
  { abbrev: 'SYOT', name: 'Syowa Time', id: 'Antarctica/Syowa', value: 3 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Antarctica/Troll', value: 2 },
  { abbrev: 'VOST', name: 'Vostok Time', id: 'Antarctica/Vostok', value: 6 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Arctic/Longyearbyen', value: 2 },
  { abbrev: 'AST', name: 'Arabia Standard Time', id: 'Asia/Aden', value: 3 },
  { abbrev: 'ALMT', name: 'Alma-Ata Time', id: 'Asia/Almaty', value: 6 },
  { abbrev: 'EEST', name: 'Eastern European Summer Time', id: 'Asia/Amman', value: 3 },
  { abbrev: 'ANAT', name: 'Anadyr Time', id: 'Asia/Anadyr', value: 12 },
  { abbrev: 'AQTT', name: 'Aqtau Time', id: 'Asia/Aqtau', value: 5 },
  { abbrev: 'AQTT', name: 'Aqtobe Time', id: 'Asia/Aqtobe', value: 5 },
  { abbrev: 'TMT', name: 'Turkmenistan Time', id: 'Asia/Ashgabat', value: 5 },
  { abbrev: 'TMT', name: 'Turkmenistan Time', id: 'Asia/Ashkhabad', value: 5 },
  { abbrev: 'Asia/Atyrau', name: 'Asia/Atyrau', id: 'Asia/Atyrau', value: 5 },
  { abbrev: 'AST', name: 'Arabia Standard Time', id: 'Asia/Baghdad', value: 3 },
  { abbrev: 'AST', name: 'Arabia Standard Time', id: 'Asia/Bahrain', value: 3 },
  { abbrev: 'AZT', name: 'Azerbaijan Time', id: 'Asia/Baku', value: 4 },
  { abbrev: 'ICT', name: 'Indochina Time', id: 'Asia/Bangkok', value: 7 },
  { abbrev: 'Asia/Barnaul', name: 'Asia/Barnaul', id: 'Asia/Barnaul', value: 7 },
  { abbrev: 'EEST', name: 'Eastern European Summer Time', id: 'Asia/Beirut', value: 3 },
  { abbrev: 'KGT', name: 'Kirgizstan Time', id: 'Asia/Bishkek', value: 6 },
  { abbrev: 'BNT', name: 'Brunei Time', id: 'Asia/Brunei', value: 8 },
  { abbrev: 'IST', name: 'India Standard Time', id: 'Asia/Calcutta', value: 5 },
  { abbrev: 'YAKT', name: 'Yakutsk Time', id: 'Asia/Chita', value: 9 },
  { abbrev: 'CHOT', name: 'Choibalsan Time', id: 'Asia/Choibalsan', value: 8 },
  { abbrev: 'CST', name: 'China Standard Time', id: 'Asia/Chongqing', value: 8 },
  { abbrev: 'CST', name: 'China Standard Time', id: 'Asia/Chungking', value: 8 },
  { abbrev: 'IST', name: 'India Standard Time', id: 'Asia/Colombo', value: 5 },
  { abbrev: 'BDT', name: 'Bangladesh Time', id: 'Asia/Dacca', value: 6 },
  { abbrev: 'EEST', name: 'Eastern European Summer Time', id: 'Asia/Damascus', value: 3 },
  { abbrev: 'BDT', name: 'Bangladesh Time', id: 'Asia/Dhaka', value: 6 },
  { abbrev: 'TLT', name: 'Timor-Leste Time', id: 'Asia/Dili', value: 9 },
  { abbrev: 'GST', name: 'Gulf Standard Time', id: 'Asia/Dubai', value: 4 },
  { abbrev: 'TJT', name: 'Tajikistan Time', id: 'Asia/Dushanbe', value: 5 },
  { abbrev: 'Asia/Famagusta', name: 'Asia/Famagusta', id: 'Asia/Famagusta', value: 3 },
  { abbrev: 'EEST', name: 'Eastern European Summer Time', id: 'Asia/Gaza', value: 3 },
  { abbrev: 'CST', name: 'China Standard Time', id: 'Asia/Harbin', value: 8 },
  { abbrev: 'EEST', name: 'Eastern European Summer Time', id: 'Asia/Hebron', value: 3 },
  { abbrev: 'ICT', name: 'Indochina Time', id: 'Asia/Ho_Chi_Minh', value: 7 },
  { abbrev: 'HKT', name: 'Hong Kong Time', id: 'Asia/Hong_Kong', value: 8 },
  { abbrev: 'HOVT', name: 'Hovd Time', id: 'Asia/Hovd', value: 7 },
  { abbrev: 'IRKT', name: 'Irkutsk Time', id: 'Asia/Irkutsk', value: 8 },
  { abbrev: 'EET', name: 'Eastern European Time', id: 'Asia/Istanbul', value: 3 },
  { abbrev: 'WIB', name: 'West Indonesia Time', id: 'Asia/Jakarta', value: 7 },
  { abbrev: 'WIT', name: 'East Indonesia Time', id: 'Asia/Jayapura', value: 9 },
  { abbrev: 'IDT', name: 'Israel Daylight Time', id: 'Asia/Jerusalem', value: 3 },
  { abbrev: 'AFT', name: 'Afghanistan Time', id: 'Asia/Kabul', value: 4 },
  { abbrev: 'PETT', name: 'Petropavlovsk-Kamchatski Time', id: 'Asia/Kamchatka', value: 12 },
  { abbrev: 'PKT', name: 'Pakistan Time', id: 'Asia/Karachi', value: 5 },
  { abbrev: 'XJT', name: 'Xinjiang Standard Time', id: 'Asia/Kashgar', value: 6 },
  { abbrev: 'NPT', name: 'Nepal Time', id: 'Asia/Kathmandu', value: 5 },
  { abbrev: 'NPT', name: 'Nepal Time', id: 'Asia/Katmandu', value: 5 },
  { abbrev: 'YAKT', name: 'Yakutsk Time', id: 'Asia/Khandyga', value: 9 },
  { abbrev: 'IST', name: 'India Standard Time', id: 'Asia/Kolkata', value: 5 },
  { abbrev: 'KRAT', name: 'Krasnoyarsk Time', id: 'Asia/Krasnoyarsk', value: 7 },
  { abbrev: 'MYT', name: 'Malaysia Time', id: 'Asia/Kuala_Lumpur', value: 8 },
  { abbrev: 'MYT', name: 'Malaysia Time', id: 'Asia/Kuching', value: 8 },
  { abbrev: 'AST', name: 'Arabia Standard Time', id: 'Asia/Kuwait', value: 3 },
  { abbrev: 'CST', name: 'China Standard Time', id: 'Asia/Macao', value: 8 },
  { abbrev: 'CST', name: 'China Standard Time', id: 'Asia/Macau', value: 8 },
  { abbrev: 'MAGT', name: 'Magadan Time', id: 'Asia/Magadan', value: 11 },
  { abbrev: 'WITA', name: 'Central Indonesia Time', id: 'Asia/Makassar', value: 8 },
  { abbrev: 'PST', name: 'Philippines Standard Time', id: 'Asia/Manila', value: 8 },
  { abbrev: 'GST', name: 'Gulf Standard Time', id: 'Asia/Muscat', value: 4 },
  { abbrev: 'EEST', name: 'Eastern European Summer Time', id: 'Asia/Nicosia', value: 3 },
  { abbrev: 'KRAT', name: 'Krasnoyarsk Time', id: 'Asia/Novokuznetsk', value: 7 },
  { abbrev: 'NOVT', name: 'Novosibirsk Time', id: 'Asia/Novosibirsk', value: 7 },
  { abbrev: 'OMST', name: 'Omsk Time', id: 'Asia/Omsk', value: 6 },
  { abbrev: 'ORAT', name: 'Oral Time', id: 'Asia/Oral', value: 5 },
  { abbrev: 'ICT', name: 'Indochina Time', id: 'Asia/Phnom_Penh', value: 7 },
  { abbrev: 'WIB', name: 'West Indonesia Time', id: 'Asia/Pontianak', value: 7 },
  { abbrev: 'KST', name: 'Korea Standard Time', id: 'Asia/Pyongyang', value: 9 },
  { abbrev: 'AST', name: 'Arabia Standard Time', id: 'Asia/Qatar', value: 3 },
  { abbrev: 'Asia/Qostanay', name: 'Asia/Qostanay', id: 'Asia/Qostanay', value: 6 },
  { abbrev: 'QYZT', name: 'Qyzylorda Time', id: 'Asia/Qyzylorda', value: 5 },
  { abbrev: 'MMT', name: 'Myanmar Time', id: 'Asia/Rangoon', value: 6 },
  { abbrev: 'AST', name: 'Arabia Standard Time', id: 'Asia/Riyadh', value: 3 },
  { abbrev: 'ICT', name: 'Indochina Time', id: 'Asia/Saigon', value: 7 },
  { abbrev: 'SAKT', name: 'Sakhalin Time', id: 'Asia/Sakhalin', value: 11 },
  { abbrev: 'UZT', name: 'Uzbekistan Time', id: 'Asia/Samarkand', value: 5 },
  { abbrev: 'KST', name: 'Korea Standard Time', id: 'Asia/Seoul', value: 9 },
  { abbrev: 'CST', name: 'China Standard Time', id: 'Asia/Shanghai', value: 8 },
  { abbrev: 'SGT', name: 'Singapore Time', id: 'Asia/Singapore', value: 8 },
  { abbrev: 'SRET', name: 'Srednekolymsk Time', id: 'Asia/Srednekolymsk', value: 11 },
  { abbrev: 'CST', name: 'China Standard Time', id: 'Asia/Taipei', value: 8 },
  { abbrev: 'UZT', name: 'Uzbekistan Time', id: 'Asia/Tashkent', value: 5 },
  { abbrev: 'GET', name: 'Georgia Time', id: 'Asia/Tbilisi', value: 4 },
  { abbrev: 'IRDT', name: 'Iran Daylight Time', id: 'Asia/Tehran', value: 4 },
  { abbrev: 'IDT', name: 'Israel Daylight Time', id: 'Asia/Tel_Aviv', value: 3 },
  { abbrev: 'BTT', name: 'Bhutan Time', id: 'Asia/Thimbu', value: 6 },
  { abbrev: 'BTT', name: 'Bhutan Time', id: 'Asia/Thimphu', value: 6 },
  { abbrev: 'JST', name: 'Japan Standard Time', id: 'Asia/Tokyo', value: 9 },
  { abbrev: 'Asia/Tomsk', name: 'Asia/Tomsk', id: 'Asia/Tomsk', value: 7 },
  { abbrev: 'WITA', name: 'Central Indonesia Time', id: 'Asia/Ujung_Pandang', value: 8 },
  { abbrev: 'ULAT', name: 'Ulaanbaatar Time', id: 'Asia/Ulaanbaatar', value: 8 },
  { abbrev: 'ULAT', name: 'Ulaanbaatar Time', id: 'Asia/Ulan_Bator', value: 8 },
  { abbrev: 'XJT', name: 'Xinjiang Standard Time', id: 'Asia/Urumqi', value: 6 },
  { abbrev: 'VLAT', name: 'Ust-Nera Time', id: 'Asia/Ust-Nera', value: 10 },
  { abbrev: 'ICT', name: 'Indochina Time', id: 'Asia/Vientiane', value: 7 },
  { abbrev: 'VLAT', name: 'Vladivostok Time', id: 'Asia/Vladivostok', value: 10 },
  { abbrev: 'YAKT', name: 'Yakutsk Time', id: 'Asia/Yakutsk', value: 9 },
  { abbrev: 'MMT', name: 'Myanmar Time', id: 'Asia/Yangon', value: 6 },
  { abbrev: 'YEKT', name: 'Yekaterinburg Time', id: 'Asia/Yekaterinburg', value: 5 },
  { abbrev: 'AMT', name: 'Armenia Time', id: 'Asia/Yerevan', value: 4 },
  { abbrev: 'AZOST', name: 'Azores Summer Time', id: 'Atlantic/Azores', value: 0 },
  { abbrev: 'ADT', name: 'Atlantic Daylight Time', id: 'Atlantic/Bermuda', value: -3 },
  { abbrev: 'WEST', name: 'Western European Summer Time', id: 'Atlantic/Canary', value: 1 },
  { abbrev: 'CVT', name: 'Cape Verde Time', id: 'Atlantic/Cape_Verde', value: -1 },
  { abbrev: 'WEST', name: 'Western European Summer Time', id: 'Atlantic/Faeroe', value: 1 },
  { abbrev: 'WEST', name: 'Western European Summer Time', id: 'Atlantic/Faroe', value: 1 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Atlantic/Jan_Mayen', value: 2 },
  { abbrev: 'WEST', name: 'Western European Summer Time', id: 'Atlantic/Madeira', value: 1 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'Atlantic/Reykjavik', value: 0 },
  { abbrev: 'GST', name: 'South Georgia Standard Time', id: 'Atlantic/South_Georgia', value: -2 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'Atlantic/St_Helena', value: 0 },
  { abbrev: 'FKT', name: 'Falkland Is. Time', id: 'Atlantic/Stanley', value: -3 },
  { abbrev: 'AEST', name: 'Australian Eastern Standard Time (New South Wales)', id: 'Australia/ACT', value: 10 },
  { abbrev: 'ACST', name: 'Australian Central Standard Time (South Australia)', id: 'Australia/Adelaide', value: 9 },
  { abbrev: 'AEST', name: 'Australian Eastern Standard Time (Queensland)', id: 'Australia/Brisbane', value: 10 },
  { abbrev: 'ACST', name: 'Australian Central Standard Time (South Australia/New South Wales)', id: 'Australia/Broken_Hill', value: 9 },
  { abbrev: 'AEST', name: 'Australian Eastern Standard Time (New South Wales)', id: 'Australia/Canberra', value: 10 },
  { abbrev: 'AEST', name: 'Australian Eastern Standard Time (New South Wales)', id: 'Australia/Currie', value: 10 },
  { abbrev: 'ACST', name: 'Australian Central Standard Time (Northern Territory)', id: 'Australia/Darwin', value: 9 },
  { abbrev: 'ACWST', name: 'Australian Central Western Standard Time', id: 'Australia/Eucla', value: 8 },
  { abbrev: 'AEST', name: 'Australian Eastern Standard Time (Tasmania)', id: 'Australia/Hobart', value: 10 },
  { abbrev: 'LHST', name: 'Lord Howe Standard Time', id: 'Australia/LHI', value: 10 },
  { abbrev: 'AEST', name: 'Australian Eastern Standard Time (Queensland)', id: 'Australia/Lindeman', value: 10 },
  { abbrev: 'LHST', name: 'Lord Howe Standard Time', id: 'Australia/Lord_Howe', value: 10 },
  { abbrev: 'AEST', name: 'Australian Eastern Standard Time (Victoria)', id: 'Australia/Melbourne', value: 10 },
  { abbrev: 'AEST', name: 'Australian Eastern Standard Time (New South Wales)', id: 'Australia/NSW', value: 10 },
  { abbrev: 'ACST', name: 'Australian Central Standard Time (Northern Territory)', id: 'Australia/North', value: 9 },
  { abbrev: 'AWST', name: 'Australian Western Standard Time', id: 'Australia/Perth', value: 8 },
  { abbrev: 'AEST', name: 'Australian Eastern Standard Time (Queensland)', id: 'Australia/Queensland', value: 10 },
  { abbrev: 'ACST', name: 'Australian Central Standard Time (South Australia)', id: 'Australia/South', value: 9 },
  { abbrev: 'AEST', name: 'Australian Eastern Standard Time (New South Wales)', id: 'Australia/Sydney', value: 10 },
  { abbrev: 'AEST', name: 'Australian Eastern Standard Time (Tasmania)', id: 'Australia/Tasmania', value: 10 },
  { abbrev: 'AEST', name: 'Australian Eastern Standard Time (Victoria)', id: 'Australia/Victoria', value: 10 },
  { abbrev: 'AWST', name: 'Australian Western Standard Time', id: 'Australia/West', value: 8 },
  { abbrev: 'ACST', name: 'Australian Central Standard Time (South Australia/New South Wales)', id: 'Australia/Yancowinna', value: 9 },
  { abbrev: 'ACT', name: 'Acre Time', id: 'Brazil/Acre', value: -5 },
  { abbrev: 'FNT', name: 'Fernando de Noronha Time', id: 'Brazil/DeNoronha', value: -2 },
  { abbrev: 'BRT', name: 'Brasilia Time', id: 'Brazil/East', value: -3 },
  { abbrev: 'AMT', name: 'Amazon Time', id: 'Brazil/West', value: -4 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'CET', value: 2 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'CST6CDT', value: -5 },
  { abbrev: 'ADT', name: 'Atlantic Daylight Time', id: 'Canada/Atlantic', value: -3 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'Canada/Central', value: -5 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'Canada/Eastern', value: -4 },
  { abbrev: 'MDT', name: 'Mountain Daylight Time', id: 'Canada/Mountain', value: -6 },
  { abbrev: 'NDT', name: 'Newfoundland Daylight Time', id: 'Canada/Newfoundland', value: -2 },
  { abbrev: 'PDT', name: 'Pacific Daylight Time', id: 'Canada/Pacific', value: -7 },
  { abbrev: 'CST', name: 'Central Standard Time', id: 'Canada/Saskatchewan', value: -6 },
  { abbrev: 'MST', name: 'Mountain Standard Time', id: 'Canada/Yukon', value: -7 },
  { abbrev: 'CLT', name: 'Chile Time', id: 'Chile/Continental', value: -4 },
  { abbrev: 'EAST', name: 'Easter Is. Time', id: 'Chile/EasterIsland', value: -6 },
  { abbrev: 'CDT', name: 'Cuba Daylight Time', id: 'Cuba', value: -4 },
  { abbrev: 'EEST', name: 'Eastern European Summer Time', id: 'EET', value: 3 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'EST5EDT', value: -4 },
  { abbrev: 'EET', name: 'Eastern European Time', id: 'Egypt', value: 2 },
  { abbrev: 'IST', name: 'Irish Summer Time', id: 'Eire', value: 1 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'Etc/GMT', value: 0 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'Etc/GMT+0', value: 0 },
  { abbrev: 'Etc/GMT+1', name: 'Etc/GMT+1', id: 'Etc/GMT+1', value: -1 },
  { abbrev: 'Etc/GMT+10', name: 'Etc/GMT+10', id: 'Etc/GMT+10', value: -10 },
  { abbrev: 'Etc/GMT+11', name: 'Etc/GMT+11', id: 'Etc/GMT+11', value: -11 },
  { abbrev: 'Etc/GMT+12', name: 'Etc/GMT+12', id: 'Etc/GMT+12', value: -12 },
  { abbrev: 'Etc/GMT+2', name: 'Etc/GMT+2', id: 'Etc/GMT+2', value: -2 },
  { abbrev: 'Etc/GMT+3', name: 'Etc/GMT+3', id: 'Etc/GMT+3', value: -3 },
  { abbrev: 'Etc/GMT+4', name: 'Etc/GMT+4', id: 'Etc/GMT+4', value: -4 },
  { abbrev: 'Etc/GMT+5', name: 'Etc/GMT+5', id: 'Etc/GMT+5', value: -5 },
  { abbrev: 'Etc/GMT+6', name: 'Etc/GMT+6', id: 'Etc/GMT+6', value: -6 },
  { abbrev: 'Etc/GMT+7', name: 'Etc/GMT+7', id: 'Etc/GMT+7', value: -7 },
  { abbrev: 'Etc/GMT+8', name: 'Etc/GMT+8', id: 'Etc/GMT+8', value: -8 },
  { abbrev: 'Etc/GMT+9', name: 'Etc/GMT+9', id: 'Etc/GMT+9', value: -9 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'Etc/GMT-0', value: 0 },
  { abbrev: 'Etc/GMT-1', name: 'Etc/GMT-1', id: 'Etc/GMT-1', value: 1 },
  { abbrev: 'Etc/GMT-10', name: 'Etc/GMT-10', id: 'Etc/GMT-10', value: 10 },
  { abbrev: 'Etc/GMT-11', name: 'Etc/GMT-11', id: 'Etc/GMT-11', value: 11 },
  { abbrev: 'Etc/GMT-12', name: 'Etc/GMT-12', id: 'Etc/GMT-12', value: 12 },
  { abbrev: 'Etc/GMT-13', name: 'Etc/GMT-13', id: 'Etc/GMT-13', value: 13 },
  { abbrev: 'Etc/GMT-14', name: 'Etc/GMT-14', id: 'Etc/GMT-14', value: 14 },
  { abbrev: 'Etc/GMT-2', name: 'Etc/GMT-2', id: 'Etc/GMT-2', value: 2 },
  { abbrev: 'Etc/GMT-3', name: 'Etc/GMT-3', id: 'Etc/GMT-3', value: 3 },
  { abbrev: 'Etc/GMT-4', name: 'Etc/GMT-4', id: 'Etc/GMT-4', value: 4 },
  { abbrev: 'Etc/GMT-5', name: 'Etc/GMT-5', id: 'Etc/GMT-5', value: 5 },
  { abbrev: 'Etc/GMT-6', name: 'Etc/GMT-6', id: 'Etc/GMT-6', value: 6 },
  { abbrev: 'Etc/GMT-7', name: 'Etc/GMT-7', id: 'Etc/GMT-7', value: 7 },
  { abbrev: 'Etc/GMT-8', name: 'Etc/GMT-8', id: 'Etc/GMT-8', value: 8 },
  { abbrev: 'Etc/GMT-9', name: 'Etc/GMT-9', id: 'Etc/GMT-9', value: 9 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'Etc/GMT0', value: 0 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'Etc/Greenwich', value: 0 },
  { abbrev: 'UTC', name: 'Coordinated Universal Time', id: 'Etc/UCT', value: 0 },
  { abbrev: 'UTC', name: 'Coordinated Universal Time', id: 'Etc/UTC', value: 0 },
  { abbrev: 'UTC', name: 'Coordinated Universal Time', id: 'Etc/Universal', value: 0 },
  { abbrev: 'UTC', name: 'Coordinated Universal Time', id: 'Etc/Zulu', value: 0 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Amsterdam', value: 2 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Andorra', value: 2 },
  { abbrev: 'Europe/Astrakhan', name: 'Europe/Astrakhan', id: 'Europe/Astrakhan', value: 4 },
  { abbrev: 'EEST', name: 'Eastern European Summer Time', id: 'Europe/Athens', value: 3 },
  { abbrev: 'BST', name: 'British Summer Time', id: 'Europe/Belfast', value: 1 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Belgrade', value: 2 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Berlin', value: 2 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Bratislava', value: 2 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Brussels', value: 2 },
  { abbrev: 'EEST', name: 'Eastern European Summer Time', id: 'Europe/Bucharest', value: 3 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Budapest', value: 2 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Busingen', value: 2 },
  { abbrev: 'EEST', name: 'Eastern European Summer Time', id: 'Europe/Chisinau', value: 3 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Copenhagen', value: 2 },
  { abbrev: 'IST', name: 'Irish Summer Time', id: 'Europe/Dublin', value: 1 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Gibraltar', value: 2 },
  { abbrev: 'BST', name: 'British Summer Time', id: 'Europe/Guernsey', value: 1 },
  { abbrev: 'EEST', name: 'Eastern European Summer Time', id: 'Europe/Helsinki', value: 3 },
  { abbrev: 'BST', name: 'British Summer Time', id: 'Europe/Isle_of_Man', value: 1 },
  { abbrev: 'EET', name: 'Eastern European Time', id: 'Europe/Istanbul', value: 3 },
  { abbrev: 'BST', name: 'British Summer Time', id: 'Europe/Jersey', value: 1 },
  { abbrev: 'EET', name: 'Eastern European Time', id: 'Europe/Kaliningrad', value: 2 },
  { abbrev: 'EEST', name: 'Eastern European Summer Time', id: 'Europe/Kiev', value: 3 },
  { abbrev: 'Europe/Kirov', name: 'Europe/Kirov', id: 'Europe/Kirov', value: 3 },
  { abbrev: 'WEST', name: 'Western European Summer Time', id: 'Europe/Lisbon', value: 1 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Ljubljana', value: 2 },
  { abbrev: 'BST', name: 'British Summer Time', id: 'Europe/London', value: 1 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Luxembourg', value: 2 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Madrid', value: 2 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Malta', value: 2 },
  { abbrev: 'EEST', name: 'Eastern European Summer Time', id: 'Europe/Mariehamn', value: 3 },
  { abbrev: 'MSK', name: 'Moscow Standard Time', id: 'Europe/Minsk', value: 3 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Monaco', value: 2 },
  { abbrev: 'MSK', name: 'Moscow Standard Time', id: 'Europe/Moscow', value: 3 },
  { abbrev: 'EEST', name: 'Eastern European Summer Time', id: 'Europe/Nicosia', value: 3 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Oslo', value: 2 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Paris', value: 2 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Podgorica', value: 2 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Prague', value: 2 },
  { abbrev: 'EEST', name: 'Eastern European Summer Time', id: 'Europe/Riga', value: 3 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Rome', value: 2 },
  { abbrev: 'SAMT', name: 'Samara Time', id: 'Europe/Samara', value: 4 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/San_Marino', value: 2 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Sarajevo', value: 2 },
  { abbrev: 'Europe/Saratov', name: 'Europe/Saratov', id: 'Europe/Saratov', value: 4 },
  { abbrev: 'MSK', name: 'Moscow Standard Time', id: 'Europe/Simferopol', value: 3 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Skopje', value: 2 },
  { abbrev: 'EEST', name: 'Eastern European Summer Time', id: 'Europe/Sofia', value: 3 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Stockholm', value: 2 },
  { abbrev: 'EEST', name: 'Eastern European Summer Time', id: 'Europe/Tallinn', value: 3 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Tirane', value: 2 },
  { abbrev: 'EEST', name: 'Eastern European Summer Time', id: 'Europe/Tiraspol', value: 3 },
  { abbrev: 'Europe/Ulyanovsk', name: 'Europe/Ulyanovsk', id: 'Europe/Ulyanovsk', value: 4 },
  { abbrev: 'EEST', name: 'Eastern European Summer Time', id: 'Europe/Uzhgorod', value: 3 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Vaduz', value: 2 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Vatican', value: 2 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Vienna', value: 2 },
  { abbrev: 'EEST', name: 'Eastern European Summer Time', id: 'Europe/Vilnius', value: 3 },
  { abbrev: 'MSK', name: 'Moscow Standard Time', id: 'Europe/Volgograd', value: 4 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Warsaw', value: 2 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Zagreb', value: 2 },
  { abbrev: 'EEST', name: 'Eastern European Summer Time', id: 'Europe/Zaporozhye', value: 3 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Europe/Zurich', value: 2 },
  { abbrev: 'BST', name: 'British Summer Time', id: 'GB', value: 1 },
  { abbrev: 'BST', name: 'British Summer Time', id: 'GB-Eire', value: 1 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'GMT', value: 0 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'GMT0', value: 0 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'Greenwich', value: 0 },
  { abbrev: 'HKT', name: 'Hong Kong Time', id: 'Hongkong', value: 8 },
  { abbrev: 'GMT', name: 'Greenwich Mean Time', id: 'Iceland', value: 0 },
  { abbrev: 'EAT', name: 'Eastern African Time', id: 'Indian/Antananarivo', value: 3 },
  { abbrev: 'IOT', name: 'Indian Ocean Territory Time', id: 'Indian/Chagos', value: 6 },
  { abbrev: 'CXT', name: 'Christmas Island Time', id: 'Indian/Christmas', value: 7 },
  { abbrev: 'CCT', name: 'Cocos Islands Time', id: 'Indian/Cocos', value: 6 },
  { abbrev: 'EAT', name: 'Eastern African Time', id: 'Indian/Comoro', value: 3 },
  { abbrev: 'TFT', name: 'French Southern & Antarctic Lands Time', id: 'Indian/Kerguelen', value: 5 },
  { abbrev: 'SCT', name: 'Seychelles Time', id: 'Indian/Mahe', value: 4 },
  { abbrev: 'MVT', name: 'Maldives Time', id: 'Indian/Maldives', value: 5 },
  { abbrev: 'MUT', name: 'Mauritius Time', id: 'Indian/Mauritius', value: 4 },
  { abbrev: 'EAT', name: 'Eastern African Time', id: 'Indian/Mayotte', value: 3 },
  { abbrev: 'RET', name: 'Reunion Time', id: 'Indian/Reunion', value: 4 },
  { abbrev: 'IRDT', name: 'Iran Daylight Time', id: 'Iran', value: 4 },
  { abbrev: 'IDT', name: 'Israel Daylight Time', id: 'Israel', value: 3 },
  { abbrev: 'EST', name: 'Eastern Standard Time', id: 'Jamaica', value: -5 },
  { abbrev: 'JST', name: 'Japan Standard Time', id: 'Japan', value: 9 },
  { abbrev: 'MHT', name: 'Marshall Islands Time', id: 'Kwajalein', value: 12 },
  { abbrev: 'EET', name: 'Eastern European Time', id: 'Libya', value: 2 },
  { abbrev: 'MEST', name: 'Middle Europe Summer Time', id: 'MET', value: 2 },
  { abbrev: 'MDT', name: 'Mountain Daylight Time', id: 'MST7MDT', value: -6 },
  { abbrev: 'PDT', name: 'Pacific Daylight Time', id: 'Mexico/BajaNorte', value: -7 },
  { abbrev: 'MDT', name: 'Mountain Daylight Time', id: 'Mexico/BajaSur', value: -6 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'Mexico/General', value: -5 },
  { abbrev: 'NZST', name: 'New Zealand Standard Time', id: 'NZ', value: 12 },
  { abbrev: 'CHAST', name: 'Chatham Standard Time', id: 'NZ-CHAT', value: 12 },
  { abbrev: 'MDT', name: 'Mountain Daylight Time', id: 'Navajo', value: -6 },
  { abbrev: 'CST', name: 'China Standard Time', id: 'PRC', value: 8 },
  { abbrev: 'PDT', name: 'Pacific Daylight Time', id: 'PST8PDT', value: -7 },
  { abbrev: 'WSST', name: 'West Samoa Standard Time', id: 'Pacific/Apia', value: 13 },
  { abbrev: 'NZST', name: 'New Zealand Standard Time', id: 'Pacific/Auckland', value: 12 },
  { abbrev: 'BST', name: 'Bougainville Standard Time', id: 'Pacific/Bougainville', value: 11 },
  { abbrev: 'CHAST', name: 'Chatham Standard Time', id: 'Pacific/Chatham', value: 12 },
  { abbrev: 'CHUT', name: 'Chuuk Time', id: 'Pacific/Chuuk', value: 10 },
  { abbrev: 'EAST', name: 'Easter Is. Time', id: 'Pacific/Easter', value: -6 },
  { abbrev: 'VUT', name: 'Vanuatu Time', id: 'Pacific/Efate', value: 11 },
  { abbrev: 'PHOT', name: 'Phoenix Is. Time', id: 'Pacific/Enderbury', value: 13 },
  { abbrev: 'TKT', name: 'Tokelau Time', id: 'Pacific/Fakaofo', value: 13 },
  { abbrev: 'FJT', name: 'Fiji Time', id: 'Pacific/Fiji', value: 12 },
  { abbrev: 'TVT', name: 'Tuvalu Time', id: 'Pacific/Funafuti', value: 12 },
  { abbrev: 'GALT', name: 'Galapagos Time', id: 'Pacific/Galapagos', value: -6 },
  { abbrev: 'GAMT', name: 'Gambier Time', id: 'Pacific/Gambier', value: -9 },
  { abbrev: 'SBT', name: 'Solomon Is. Time', id: 'Pacific/Guadalcanal', value: 11 },
  { abbrev: 'ChST', name: 'Chamorro Standard Time', id: 'Pacific/Guam', value: 10 },
  { abbrev: 'HST', name: 'Hawaii Standard Time', id: 'Pacific/Honolulu', value: -10 },
  { abbrev: 'HST', name: 'Hawaii Standard Time', id: 'Pacific/Johnston', value: -10 },
  { abbrev: 'LINT', name: 'Line Is. Time', id: 'Pacific/Kiritimati', value: 14 },
  { abbrev: 'KOST', name: 'Kosrae Time', id: 'Pacific/Kosrae', value: 11 },
  { abbrev: 'MHT', name: 'Marshall Islands Time', id: 'Pacific/Kwajalein', value: 12 },
  { abbrev: 'MHT', name: 'Marshall Islands Time', id: 'Pacific/Majuro', value: 12 },
  { abbrev: 'MART', name: 'Marquesas Time', id: 'Pacific/Marquesas', value: -9 },
  { abbrev: 'SST', name: 'Samoa Standard Time', id: 'Pacific/Midway', value: -11 },
  { abbrev: 'NRT', name: 'Nauru Time', id: 'Pacific/Nauru', value: 12 },
  { abbrev: 'NUT', name: 'Niue Time', id: 'Pacific/Niue', value: -11 },
  { abbrev: 'NFT', name: 'Norfolk Time', id: 'Pacific/Norfolk', value: 11 },
  { abbrev: 'NCT', name: 'New Caledonia Time', id: 'Pacific/Noumea', value: 11 },
  { abbrev: 'SST', name: 'Samoa Standard Time', id: 'Pacific/Pago_Pago', value: -11 },
  { abbrev: 'PWT', name: 'Palau Time', id: 'Pacific/Palau', value: 9 },
  { abbrev: 'PST', name: 'Pitcairn Standard Time', id: 'Pacific/Pitcairn', value: -8 },
  { abbrev: 'PONT', name: 'Pohnpei Time', id: 'Pacific/Pohnpei', value: 11 },
  { abbrev: 'PONT', name: 'Pohnpei Time', id: 'Pacific/Ponape', value: 11 },
  { abbrev: 'PGT', name: 'Papua New Guinea Time', id: 'Pacific/Port_Moresby', value: 10 },
  { abbrev: 'CKT', name: 'Cook Is. Time', id: 'Pacific/Rarotonga', value: -10 },
  { abbrev: 'ChST', name: 'Chamorro Standard Time', id: 'Pacific/Saipan', value: 10 },
  { abbrev: 'SST', name: 'Samoa Standard Time', id: 'Pacific/Samoa', value: -11 },
  { abbrev: 'TAHT', name: 'Tahiti Time', id: 'Pacific/Tahiti', value: -10 },
  { abbrev: 'GILT', name: 'Gilbert Is. Time', id: 'Pacific/Tarawa', value: 12 },
  { abbrev: 'TOT', name: 'Tonga Time', id: 'Pacific/Tongatapu', value: 13 },
  { abbrev: 'CHUT', name: 'Chuuk Time', id: 'Pacific/Truk', value: 10 },
  { abbrev: 'WAKT', name: 'Wake Time', id: 'Pacific/Wake', value: 12 },
  { abbrev: 'WFT', name: 'Wallis & Futuna Time', id: 'Pacific/Wallis', value: 12 },
  { abbrev: 'CHUT', name: 'Chuuk Time', id: 'Pacific/Yap', value: 10 },
  { abbrev: 'CEST', name: 'Central European Summer Time', id: 'Poland', value: 2 },
  { abbrev: 'WEST', name: 'Western European Summer Time', id: 'Portugal', value: 1 },
  { abbrev: 'KST', name: 'Korea Standard Time', id: 'ROK', value: 9 },
  { abbrev: 'SGT', name: 'Singapore Time', id: 'Singapore', value: 8 },
  { abbrev: 'AST', name: 'Atlantic Standard Time', id: 'SystemV/AST4', value: -4 },
  { abbrev: 'ADT', name: 'Atlantic Daylight Time', id: 'SystemV/AST4ADT', value: -3 },
  { abbrev: 'CST', name: 'Central Standard Time', id: 'SystemV/CST6', value: -6 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'SystemV/CST6CDT', value: -5 },
  { abbrev: 'EST', name: 'Eastern Standard Time', id: 'SystemV/EST5', value: -5 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'SystemV/EST5EDT', value: -4 },
  { abbrev: 'HST', name: 'Hawaii Standard Time', id: 'SystemV/HST10', value: -10 },
  { abbrev: 'MST', name: 'Mountain Standard Time', id: 'SystemV/MST7', value: -7 },
  { abbrev: 'MDT', name: 'Mountain Daylight Time', id: 'SystemV/MST7MDT', value: -6 },
  { abbrev: 'PST', name: 'Pacific Standard Time', id: 'SystemV/PST8', value: -8 },
  { abbrev: 'PDT', name: 'Pacific Daylight Time', id: 'SystemV/PST8PDT', value: -7 },
  { abbrev: 'AKST', name: 'Alaska Standard Time', id: 'SystemV/YST9', value: -9 },
  { abbrev: 'AKDT', name: 'Alaska Daylight Time', id: 'SystemV/YST9YDT', value: -8 },
  { abbrev: 'EET', name: 'Eastern European Time', id: 'Turkey', value: 3 },
  { abbrev: 'UTC', name: 'Coordinated Universal Time', id: 'UCT', value: 0 },
  { abbrev: 'AKDT', name: 'Alaska Daylight Time', id: 'US/Alaska', value: -8 },
  { abbrev: 'HDT', name: 'Hawaii Daylight Time', id: 'US/Aleutian', value: -9 },
  { abbrev: 'MST', name: 'Mountain Standard Time', id: 'US/Arizona', value: -7 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'US/Central', value: -5 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'US/East-Indiana', value: -4 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'US/Eastern', value: -4 },
  { abbrev: 'HST', name: 'Hawaii Standard Time', id: 'US/Hawaii', value: -10 },
  { abbrev: 'CDT', name: 'Central Daylight Time', id: 'US/Indiana-Starke', value: -5 },
  { abbrev: 'EDT', name: 'Eastern Daylight Time', id: 'US/Michigan', value: -4 },
  { abbrev: 'MDT', name: 'Mountain Daylight Time', id: 'US/Mountain', value: -6 },
  { abbrev: 'PDT', name: 'Pacific Daylight Time', id: 'US/Pacific', value: -7 },
  { abbrev: 'SST', name: 'Samoa Standard Time', id: 'US/Samoa', value: -11 },
  { abbrev: 'UTC', name: 'Coordinated Universal Time', id: 'UTC', value: 0 },
  { abbrev: 'UTC', name: 'Coordinated Universal Time', id: 'Universal', value: 0 },
  { abbrev: 'MSK', name: 'Moscow Standard Time', id: 'W-SU', value: 3 },
  { abbrev: 'WEST', name: 'Western European Summer Time', id: 'WET', value: 1 },
  { abbrev: 'UTC', name: 'Coordinated Universal Time', id: 'Zulu', value: 0 },
];
