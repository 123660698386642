import { Pipe, PipeTransform } from '@angular/core';
import { AssignService } from '@app/dir_group_assignor/assign/assign.service';
import { ClassGame } from '@app/dir_group_assignor/games/game';

@Pipe({ name: 'showBtnShowMoreForGO', standalone: true })
export class ShowBtnShowMoreForGOPipe implements PipeTransform {

  constructor(
    private assignS: AssignService,
  ) {
  }

  // Показывать ли кнопку "Show more" для списка ролей (go)
  transform(game: ClassGame): boolean {
    return game?.gameOfficials?.length ? game!.gameOfficials!.length > this.assignS.amountForShowAllGO : false;
  }

}
