import { Pipe, PipeTransform } from '@angular/core';
import { FiltersService } from '@components/filters/filters.service';
import { RoutesService } from '@services/routes.service';

@Pipe({ name: 'getMatTooltipForSearch', standalone: true })
export class GetMatTooltipForSearchPipe implements PipeTransform {

  constructor(
    private routesS: RoutesService,
    private filtersS: FiltersService,
  ) {
  }

  transform(nothing: string = ''): string {
    if (this.filtersS.arrPathExcludes_for_matTooltip_for_search.includes(this.routesS.path!)) { // !!! для этих страниц НЕ надо показывать matTooltip_for_search
      return '';
    } else {
      return this.filtersS.matTooltip_for_search;
    }
  }

}
