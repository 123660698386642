import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'split', standalone: true })
export class SplitPipe implements PipeTransform {

  // !!! в HTML нельзя использовать string.split()
  // !!! поэтому этот pipe помогает в этом
  // !!! idx если передал то пернется строка. Если не передал то вернется массив
  transform(str: string, symbolSeparation: string, idx: number): string
  transform(str: string, symbolSeparation: string, idx?: undefined): Array<string>
  transform(str: string, symbolSeparation: string, idx?: number | undefined): string | Array<string> {
    // if (!str) return str; // !!! на всякий случай
    if (!str) { // !!! на всякий случай
      if (typeof idx === 'number') return '';
      else return [];
    }
    const arr = str.split(symbolSeparation)?.map(el => el?.trim());
    if (typeof idx === 'number') return arr[idx];
    else return arr;
  }

}
