import { Pipe, PipeTransform } from '@angular/core';
import { OtherService } from '@services/other.service';
import { ClassGameOfficial } from '@app/dir_group_assignor/games/game';
import { TSvgName } from '@components/__svg_img/svg/forSvg';

@Pipe({ name: 'getSvgForGoStatus', standalone: true, pure: false }) // !!! NO DELETE pure: false
export class GetSvgForGoStatusPipe implements PipeTransform {

  constructor(
    private otherS: OtherService,
  ) {
  }

  transform(go: ClassGameOfficial): TSvgName {
    return this.otherS.getSvgForGoStatus(go);
  }

}
