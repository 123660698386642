import { Injectable } from '@angular/core';
import { ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { UtilsService } from '@services/utils.service';

@Injectable({ providedIn: 'root' })
export class DropdownService {

  constructor() {
  }

  // !!! key => по какому ключу поставить titleCase
  getArrayDropItemById<T>(content?: Array<T & { id?: string }>, key?: keyof T): Array<ClassDrop & T & { id?: string }> {
    return UtilsService.getArrayDropItemById(content, key);
  }

}
