import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { PaymentService } from '@app/dir_group_assignor/payments/payment.service';
import { LinkPageComponent } from '@components/link-page/link-page.component';
import { WrapPageDirective } from '@directives/wrap-page.directive';

@Component({
  selector: 'app-payments',
  standalone: true,
  imports: [CommonModule, LinkPageComponent, RouterOutlet, WrapPageDirective],
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentsComponent {

  constructor(
    // private mainS: MainService,
    // private meS: MeService,
    public payS: PaymentService,
    // private router: Router,
    // private route: ActivatedRoute,
    // private lodashS: LodashService,
    public cd: ChangeDetectorRef,
  ) {
  }

}
