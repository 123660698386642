<ng-container *ngIf='{stateInput: {value:valueDateRange,active:active,disabled:disabled,error:errRequired}|getStateInput} as obj'>
  <div class='o-drop-down o-drop-down--{{obj.stateInput}}' [matMenuTriggerFor]='menu'
       (menuOpened)='isOpenMenu=true' (menuClosed)='isOpenMenu=false' [class.o-openDrop]='isOpenMenu'
       [style]='{w:width}|getStyles'>
    <meSvg *ngIf='typeView=="svgCalendar"' svgName='calendar_blueDark&20'></meSvg>

    <span *ngIf='{value: valueDateRange?.fromTo_formatted || valueDateRange?.period} as objValue' class='o-noWrap'
          [class.o-colorPlaceholder]='!objValue.value'>
      <!--            Миша сказал что для фильтров не надо выбраное значение показывать в дропдауне. всегда placeholder показывать-->
      {{ showAlwaysPlaceholder ? placeholder : (objValue.value || placeholder) }}
    </span>

    <meSvg *ngIf='typeView=="drop"' [svgName]='obj.stateInput|getSvgArrowForDropdown'
           [revV]='isOpenMenu' class='o-mg-l-auto-important'></meSvg>
  </div>

  <!--  yPosition="above" below -->
  <!-- <mat-menu #menu='matMenu'>
    <button *ngFor='let periodItem of valueDateRange?.arrPeriods?.length ? valueDateRange?.arrPeriods
      : (valueDateRange?.typePeriod === "current" ? arrPeriodsForCurrent : arrPeriodsForPast)'
            (click)='valueDateRange?.period == "Custom Date Range" || valueDateRange?.period !== periodItem ? choosePeriod(periodItem):null'
            [ngClass]='{"o-colorGreenLight o-cur-default": valueDateRange?.period === periodItem}'>{{ periodItem }}
    </button>
    <button [ngStyle]='(elRef|getWidthMatMenu)' class='hiddenMatOption'></button>
  </mat-menu> -->
  <mat-menu #menu='matMenu'>
    <div *ngIf="!isPaymentRoute">
    <button *ngFor='let periodItem of valueDateRange?.arrPeriods?.length ? valueDateRange?.arrPeriods : (valueDateRange?.typePeriod === "current" ? arrPeriodsForCurrent : arrPeriodsForPast)'
            (click)='onPeriodItemClick(periodItem)'
            [ngClass]='{"o-colorGreenLight o-cur-default": valueDateRange?.period === periodItem}'>{{ periodItem }}
    </button>
  </div>
  <div *ngIf="isPaymentRoute">
    <button *ngFor='let periodItem of valueDateRange?.arrPeriods?.length ? valueDateRange?.arrPeriods : (valueDateRange?.typePeriod === "current" ? arrPeriodsForCurrent : arrPeriodsForPast)'
    (click)='onPeriodItemClick(periodItem)'
    [ngClass]='{
      "o-colorGreenLight o-cur-default": filterDateRange?.period === periodItem }'>{{ periodItem }}
    </button>
  </div>

    <button [ngStyle]='(elRef|getWidthMatMenu)' class='hiddenMatOption'></button>
  </mat-menu>

</ng-container>