// !!! если здесь изменить цвет, то не забыть поменять в src/theme/variables.scss
export const colorObj = {
  white: '#ffffff',
  blueDark: '#07254a',
  blueDarkLight: '#344054',
  greenLight: '#a9cf4a',   // нету в фигме
  greenLight2: '#DEF9E5',
  green: '#12B76A',
  newGreen: '#64E986',
  yellow: '#F3E351',
  red: '#F12B2C',
  grey_1: '#98A2B3',
  grey_2: '#D0D5DD',
  grey_3: '#EAECF0',
  grey_3_5: '#E7ECF1',
  grey_4: '#F6F7F8',
  'success-50': '#ECFDF3',
  'success-700': '#027A48',
  'gray-100': '#F2F4F7',
  bordColor: '#DFE0EB',
};