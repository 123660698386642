import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetFirstLetterPipe } from '@pipes/get-first-letter.pipe';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { GetStylesPipe } from '@pipes/css/get-styles.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GetUserNamePipe } from '@pipes/get-name.pipe';
import { OtherService } from '@services/other.service';
import { ClassGame, ClassGameOfficial, TStatusAssign, TStatusAssignForSendToServer } from '@app/dir_group_assignor/games/game';
import { TSvgName } from '@components/__svg_img/svg/forSvg';
import { ClassUser } from '@models/user';

export type TypePageForPhotos = 'game';
export type TPositionForPhotos = 'flex-start' | 'center' | 'flex-end';

export type TPositionSvgIcon = 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom';

export interface IPhoto {
  name?: string;
  photo?: string;
  matTooltip?: string;
  widthPhoto?: number; // установить размер лого. По дефолту 32px

  svgIcon?: TSvgName; // !!! маленькая SVG иконка поверх фото
  positionSvgIcon?: TPositionSvgIcon; // !!! по дефолту будет в правом нижнем углу 'rightBottom'

  textForPlus?: string; // если isPlus то сюда надо вставить количество оставшихся
  status?: TStatusAssignForSendToServer | TStatusAssign;
}

@Component({
  // selector: 'photos[photos]',
  selector: 'photos[type]',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss'],
  standalone: true,
  imports: [CommonModule, GetFirstLetterPipe, SvgComponent, GetStylesPipe, MatTooltipModule, GetUserNamePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotosComponent implements OnChanges {
  @Input() type!: TypePageForPhotos;
  @Input() position: TPositionForPhotos = 'flex-start'; // фотки в начале, в середине или в конце
  @Input() widthPhoto: number | string = 32; // если передано, то устанавливаем размер лого
  @Input() amountPhotos: number | string = 5; // количество фото (вместе с плюсом)
  photos: Array<IPhoto> = [];
  readonly unAssignedUserUrl = '../../../../../assets/user/user_White2&16.svg';

  @Input() game?: ClassGame; // !!! если понадобится для других компонентов использовать этот PhotosComponent, то нужно дополнительные данные сюда передать вместо game

  @Input() showSmallCircle = false;

  constructor(
    private otherS: OtherService,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.type?.currentValue) {
      // console.log(this.game?.gameOfficials);

      if (this.type == 'game') this.getPhotosFromGameOfficials(this.game!);
    }
  }

  // !!! FOR GAMES
  getPhotosFromGameOfficials(game: ClassGame): void {
    const photos: Array<IPhoto> = [];
    const officials: Array<ClassUser | undefined> = game?.gameOfficials?.map((el) => el.official) || [];

    officials.forEach((el: ClassUser | undefined, idx) => {
      if (idx + 1 > +this.amountPhotos) return;

      const go: ClassGameOfficial = game?.gameOfficials![idx];
      const statusGO = game?.gameOfficials![idx]?.status!; // TStatusAssign
      // let svgIcon: TSvgName | undefined = undefined;
      // if (statusGO === 'UNASSIGNED') svgIcon = 'circle_empty2&20';
      // if (statusGO === 'UNACCEPTED' || statusGO === 'ASSIGNED / UNACCEPTED') svgIcon = 'circle_emptyGrey2&20';
      // if (statusGO === 'ACCEPTED' || statusGO === 'ASSIGNED / ACCEPTED') svgIcon = 'circle_chx4';
      // if (statusGO === 'DECLINED' || statusGO === 'ASSIGNED / DECLINED') svgIcon = 'circle_crossRed&20';

      let svgIcon: TSvgName = this.otherS.getSvgForGoStatus(go);

      let name: string;
      let matTooltip: string;
      const statusTooltip = this.otherS.toTitleCase(statusGO);

      statusGO !== 'UNASSIGNED' && el !== undefined
        ? (name = this.otherS.getUserName(el) || 'NO NAME', matTooltip = name + ' - ' + statusTooltip)
        : (name = '', matTooltip = statusTooltip);

      if (this.amountPhotos === idx + 1) {
        photos.push({ textForPlus: `+${ officials.length }` });
      } else {
        photos.push({ name, status: statusGO, photo: el?.pictureUrl, svgIcon, positionSvgIcon: 'rightBottom', matTooltip });
      }
    });

    this.photos = photos;
    this.cd.detectChanges();
  }
}
