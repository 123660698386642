import { Pipe, PipeTransform } from '@angular/core';
import { ITestArrForAnalytics } from '@app/dir_group_assignor/payments/modelsForPayment';

@Pipe({ name: 'getOnlySelectedArrCharts', standalone: true })
export class GetOnlySelectedArrChartsPipe implements PipeTransform {

  transform(arr: Array<ITestArrForAnalytics>): Array<ITestArrForAnalytics> {
    return arr?.filter((el) => !!el.isAdded);
  }

}
