import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainService } from '@services/main.service';
import { ClassSettingsRequest } from '@models/response-and-request';
import { MeService } from '@services/me.service';
import { DashboardService } from '@app/dir_group_assignor/dashboard/dashboard.service';
import { DeviceService } from '@services/device.service';
import { FiltersService } from '@components/filters/filters.service';
import { HelperClass } from '@classes/Helper-Classes';
import { CustomDatesService } from '@classes/CustomDates';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { GetGreetPipe } from '@app/dir_group_assignor/dashboard/pipes/get-greet.pipe';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { BtnComponent } from '@components/btn/btn.component';
import { GetChxSvgNamePipe } from '@components/__svg_img/svg/pipes/get-chx-svg-name.pipe';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import {
  FiltersSelectedIconComponent,
} from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { ClassFilterDateRange, ClassFilterDrop, ClassFilterInput } from '@components/filters/filters';
import { GreetComponent } from '@app/dir_group_assignor/dashboard/components/greet/greet.component';
import { ForTestService } from '@classes/forTest';
import { ComingSoonMobile } from '../coming-soon-mobile/coming-soon-mobile.component';
import {
  GroupAssignorTilesComponent,
} from '@app/dir_group_assignor/dashboard/components/dashboard-group-assignor/components/group-assignor-tiles/group-assignor-tiles.component';
import {
  DashboardHomeGroupAssignorComponent,
} from '@app/dir_group_assignor/dashboard/components/dashboard-group-assignor/components/dashboard-home-group-assignor/dashboard-home-group-assignor.component';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store/app.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MeTableService } from '@components/_table/me-table.service';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { ForFilterItemDirective } from '@components/filters/directives/for-filter-item.directive';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { DashboardSubAssignorActions } from '@app/store/dashboard/actions/dashboard.actions';
import {
  GroupAssignorDashboardService,
} from '@app/dir_group_assignor/dashboard/components/dashboard-group-assignor/services/group-assignor-dashboard.service';
import { Router } from '@angular/router';
import { LinkPageSearchFilterComponent } from '@components/__settingsRequest/link-page-search-filter/link-page-search-filter.component';
import { GameNotesPopupService } from '@services/game-notes-popup.service';

interface IFormDashboard {
  currentViewDropdown: FormControl<string>; // andrei ===
  gameStatus: FormControl<string>;
  selfAssigned?: FormControl<boolean>;
  currentUser?: FormControl<ClassDrop>;
}

@UntilDestroy()
@Component({
  selector: 'app-dashboardGroupAssignor',
  standalone: true,
  imports: [
    CommonModule, ReactiveFormsModule, GetGreetPipe, SvgComponent, BtnComponent,
    ReactiveFormsModule, FormsModule, GetChxSvgNamePipe, SpinnerComponent, WrapPageDirective, FiltersComponent,
    FiltersSelectedComponent, FiltersSelectedIconComponent, GreetComponent, ComingSoonMobile, GroupAssignorTilesComponent,
    DashboardHomeGroupAssignorComponent, DropdownComponent, FieldComponent, ForFilterItemDirective, InputCtrlComponent,
    LinkPageSearchFilterComponent,
  ],
  templateUrl: './dashboard-group-assignor.component.html',
  styleUrls: ['./dashboard-group-assignor.component.scss'],
  providers: [FiltersService, MeTableService, SettingsRequestService], // for-filters=== for-table=== for-settings===
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardGroupAssignorComponent extends HelperClass implements OnInit { // IForClassForFilters
  form!: FormGroup<IFormDashboard>;
  selectedTable: any = 'totalGames';
  commingSoon = true; //After comming soon related content & logic can be removed from UI
  private readonly forTestS = inject(ForTestService);
  tittleName: string = 'Total Games';
  currentUsers: Array<ClassDrop> = [{
    titleCase: '',
    upperCase: '',
  }];
  currentUserName: string = '';


  constructor(
    private formBuilder: UntypedFormBuilder,
    public mainS: MainService,
    public meS: MeService,
    public customDatesS: CustomDatesService,
    public dashboardS: DashboardService,
    public deviceS: DeviceService,
    public filtersS: FiltersService, // for-filters===
    public cd: ChangeDetectorRef,
    private readonly gameS: GameService,
    private readonly settingsRequestS: SettingsRequestService,
    private readonly store: Store<AppState>,
    private readonly subDashboardService: GroupAssignorDashboardService,
    private router: Router,
    public gameNotesPopupService: GameNotesPopupService,
  ) {
    super(cd);

    this.setFilters(); // for-filters===
    this.createForm();
    this.subDashboardService.getMyGroups().pipe(untilDestroyed(this)).subscribe(res => {
      if (!res) return;
      const { name } = res[0];
      this.currentUserName = name ?? '';
      this.currentUsers[0].titleCase = `Current view as Group Assignor for ${name}`;
      this.currentUsers[0].upperCase = `Current view as Group Assignor for ${name}`;
      this.form.controls.currentUser?.setValue(this.currentUsers[0]);
      this.cd.detectChanges();
    });
  }

  ngOnInit(): void {
  }

  // === FILTERS ==========================================
  settings: ClassSettingsRequest = new ClassSettingsRequest({ size: 5, from: this.customDatesS.todayMidnightFormatDate }); // for-filters===

  emitSettingRequest(settings: ClassSettingsRequest): void {
    this.settings = { ...this.settings, ...settings };
    this.store.dispatch(DashboardSubAssignorActions.loadAllData({ sortParams: {}, settingParams: this.settings }));
    this.cd.detectChanges();
  }

  setFilters(): void {
    const search = new ClassFilterInput({ typeFilter: 'search' });
    const dateRange = new ClassFilterDateRange();
    const competition = new ClassFilterDrop({
      typeFilter: 'competitions',
      arrayForDropdown: this.gameS.arrCompetition$.getValue(),
    });
    this.filtersS.setFilters([search, dateRange, competition], false, false);
  }

  // === FORM =======================
  private createForm(): void {
    this.form = this.formBuilder.group({
      currentViewDropdown: [],
      gameStatus: 'ACCEPTED',
      selfAssigned: null,
      currentUsers: {
        tittleCase: '',
        upperCase: '',
      },
    });

  }

  get ctrl(): IFormDashboard {
    return this.form?.controls;
  }
}
