import { inject, Injectable } from '@angular/core';
import { arrStatusGameString, ClassGame } from '@app/dir_group_assignor/games/game';
import { MainService } from '@services/main.service';
import { ClassSettingsRequest, IResponse } from '@models/response-and-request';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GroupAssignorDashboardService {

  private mainService = inject(MainService);

  getGamesList(settings: ClassSettingsRequest, sortingParams: any, isGameTable: boolean = false): Observable<IResponse<ClassGame> | undefined> {
    const gameStatuses: string = isGameTable ? 'ACTIVE' :
      arrStatusGameString?.filter((el) => el !== 'CANCELLED' && el === 'ACTIVE')?.join(',');
    settings.size = 5;
    return this.mainService.getGamesList({
      params: {
        ...settings,
        gameStatuses, ...sortingParams,
      },
    });
  };


  // Использовать для названия гнруппы
  getMyGroups() {
    return this.mainService.getMeArrCurrentGroup();
  }
}
