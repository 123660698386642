import { Directive, Input, ElementRef, Renderer2, OnInit, Injectable } from '@angular/core';
import { TUserRoleUpperCase } from '@models/user';
import { MeService } from '@services/me.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatorService {
  public isAllow(roles: TUserRoleUpperCase[]) {
    return roles.some((role) => role === this.meService.meRole);
  }

  public isDenied(roles: TUserRoleUpperCase[]) {
    return this.isAllow(roles) == false;
  }
  constructor(private meService: MeService) { }
}

@Directive({
  selector: '[authenticator]',
  standalone: true,
})
export class AuthenticatorDirective implements OnInit {
  @Input('authenticator') allowedRoles: TUserRoleUpperCase[] = [];

  constructor(private el: ElementRef, private renderer: Renderer2, private authService: AuthenticatorService) { }

  ngOnInit() {
    const isVisible = this.authService.isAllow(this.allowedRoles);
    if (isVisible == false) {
      this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
    }
  }
}

@Directive({
  selector: '[authenticatorDisable]',
  standalone: true,
})
export class AuthenticatorDisableDirective implements OnInit {
  @Input('authenticator') allowedRoles: TUserRoleUpperCase[] = [];

  constructor(private el: ElementRef, private renderer: Renderer2, private authService: AuthenticatorService) { }

  ngOnInit() {
    const isAllow = this.authService.isAllow(this.allowedRoles);
    if (isAllow == false) {
      this.renderer.setStyle(this.el.nativeElement, 'disable', 'true');
    }
  }
}
