import {
    DashboardState,
    DashboardSubAssignorState, IDashboardGameTable,
} from "@app/store/dashboard/models/dashboard.models";
import {
    IActiveTile
} from "@app/dir_group_assignor/dashboard/components/dashboard-group-assignor/models/group-assignor.models";

export const initialDashboardSubAssignorState: DashboardSubAssignorState = {
    selfAssigned: false,
    currentViewDropdown: '',
    gameStatus: '',
    unassignedData: null,
    assignData: null,
    declinedData: null
}

export const initialDashboardHomeAssignerActiveTileState: IActiveTile = {
    isGame: true,
    isUnassigned: false,
    isDeclined: false,
    isPending: false
}

export const initialDashboardGameTable: IDashboardGameTable = {
    totalGames: 0,
}

// Initial all states for dashboard
export const initialDashboardState: DashboardState = {
    subAssignor: initialDashboardSubAssignorState,
    homeAssignerActiveTile: initialDashboardHomeAssignerActiveTileState,
    dashboardGameTable: initialDashboardGameTable
};


