import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass } from '@classes/Helper-Classes';
import { CompetitionService } from '@app/dir_group_assignor/competitions/competition.service';
import { MainService } from '@services/main.service';
import { IResponse } from '@models/response-and-request';
import { ClassCompetition } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { Router } from '@angular/router';
import { urlCompetition_about, urlSetupCompetition } from '@app/app.module';
import { debounceTime, switchMap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { DeviceService } from '@services/device.service';
import { FiltersService } from '@components/filters/filters.service';
import { ClassFilterInput, IForClassForFilters, TAllInterfacesFilters } from '@components/filters/filters';
import { ClassCeilTableHeader, IForClassForTable } from '@components/_table/meTable';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MeTableService } from '@components/_table/me-table.service';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { CdkScrollable } from '@angular/cdk/overlay';
import { DataTableDirective } from '@components/_table/directives/data-table.directive';
import {
  FiltersSelectedIconComponent,
} from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { BtnComponent } from '@components/btn/btn.component';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import { TableHeaderComponent } from '@components/_table/table-header/table-header.component';
import { TableCeilComponent } from '@components/_table/table-ceil/table-ceil.component';
import { ItemTableDirective } from '@components/_table/directives/item-table.directive';
import { CeilTableDirective } from '@components/_table/directives/ceil-table.directive';
import { TableEmptyComponent } from '@components/_table/table-empty/table-empty.component';
import { TableInfiniteLoadingComponent } from '@components/_table/table-infinite-loading/table-infinite-loading.component';
import { PaginationWithSettingsComponent } from '@components/__settingsRequest/pagination-with-settings/pagination-with-settings.component';
import { ComingSoonMobile } from '@app/dir_group_assignor/dashboard/components/coming-soon-mobile/coming-soon-mobile.component';
import { LinkPageSearchFilterComponent } from '@components/__settingsRequest/link-page-search-filter/link-page-search-filter.component';
import { TableCeilChxComponent } from '@components/_table/table-ceil-chx/table-ceil-chx.component';
import { AdminPermissionDirective } from '@directives/admin-permission.directive';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';

@UntilDestroy()
@Component({
  selector: 'app-competitions-list',
  standalone: true,
  imports: [CommonModule, WrapPageDirective, CdkScrollable, DataTableDirective, FiltersSelectedIconComponent, FiltersComponent, BtnComponent, FiltersSelectedComponent, TableHeaderComponent, TableCeilComponent, ItemTableDirective, CeilTableDirective, TableEmptyComponent, TableInfiniteLoadingComponent, PaginationWithSettingsComponent, ComingSoonMobile, LinkPageSearchFilterComponent, TableCeilChxComponent, AdminPermissionDirective, GetTableEmptyPipe],
  templateUrl: './competitions-list.component.html',
  styleUrls: ['./competitions-list.component.scss'],
  providers: [FiltersService, MeTableService, SettingsRequestService], // for-filters=== for-table=== for-settings===
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetitionsListComponent extends HelperClass implements IForClassForFilters, IForClassForTable<ClassCompetition> {
  dataTable$ = new BehaviorSubject<IResponse<ClassCompetition>>({}); // for-table===
  arrContent$ = new BehaviorSubject<Array<ClassCompetition>>([]); // for-table===
  // readonly class_ngContent_btn_for_nothingList = class_ngContent_btn_for_nothingList;
  readonly urlSetupCompetition = urlSetupCompetition;
  readonly widthPage = 1171;

  constructor(
    public competitionS: CompetitionService,
    public mainS: MainService,
    public router: Router,
    public deviceS: DeviceService,
    public filtersS: FiltersService, // for-filters===
    public meTableS: MeTableService<ClassCompetition>, // for-table===
    private settingsRequestS: SettingsRequestService, // for-settings===
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.setAllData();
    // this.route.params.pipe(untilDestroyed(this)).subscribe((params) => this.competitionS.checkParams(params));
  }

  // !!! если есть в фильтрах компетишн, то обязательно надо в AppModule к роуту прикрутить CompetitionsForFiltersResolver
  setAllData(): void {
    this.setDataForTable(); // for-table===
    this.setFilters(); // for-filters===
    this.settingsRequestS.setAllData(this.isFirstLoadPageSub$); // for-settingsDynamicUrl=== создание ссылки, чтобы можно было использовать в сервисе
    this.subscribeToSettings(); // for-settings===
  }

  // === SETTINGS ===============================
  subscribeToSettings(): void {
    this.settingsRequestS.settings$.pipe(
      switchMap((res) => this.meTableS.getSubject_forGetCompetitionList()),
      untilDestroyed(this),
    ).subscribe((res) => {
      // this.meTableS.setDataAfterResponseServer(res, this.settingsRequestS.settings.typeEmitSetting); // for-table===
      // this.meTableS.endLoadingTable();
      this.cd.detectChanges();
    });
  }

  // === FILTERS ==========================================
  setFilters(): void {
    const search = new ClassFilterInput({ typeFilter: 'search' });
    // !!! NO DELETE const activeCompetitions = new ClassFilterDrop({ typeFilter: 'activeCompetitions', disabled: true });
    const arrFilters: Array<TAllInterfacesFilters> = [search]; // activeCompetitions
    this.filtersS.setFilters(arrFilters, true, true);
  }

  // === TABLE ======================================================
  setDataForTable(): void {
    this.meTableS.dataTable$ = this.dataTable$; // !!! создание ссылки. Чтобы можно было использовать в MeTableService
    this.meTableS.arrContent$ = this.arrContent$; // !!! создание ссылки. Чтобы можно было использовать в MeTableService
    this.meTableS.paddingLeft_forCeil = 16;
    const arrayCeilHeader: Array<ClassCeilTableHeader> = [
      new ClassCeilTableHeader({ text: 'Name' }),
      new ClassCeilTableHeader({ text: 'Teams' }),
      new ClassCeilTableHeader({ text: 'Locations' }),
      new ClassCeilTableHeader({ text: 'Sub-Assignor(s)' }),
      new ClassCeilTableHeader({ text: 'Admins' }),
      new ClassCeilTableHeader({ sortBy: true }),
    ];
    const arrWidthCeilTable: Array<number> = [378, 112, 140, 187, 187, 167]; // !!! ширина для каждой ячейки для Desktop => сумма должна совпадать с this.widthPage
    this.meTableS.setArrTypeSorting(['byCompetitionName']); // !!! for-sorting===
    this.meTableS.setDataForTable(arrWidthCeilTable, arrayCeilHeader, this.widthPage, true);
  }

  // === OTHER ACTIONS ============================
  goToCompetition(competition: ClassCompetition): void {
    this.competitionS.reset(); // не удалять . users problem после того как заходишь на другой компетишн
    // this.competitionS.resetIsLoad();
    this.router.navigate([urlCompetition_about, competition?.id]);
  }

}
