import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetTimezoneFromDateFromServerPipe } from '@pipes/date/get-timezone-from-date-from-server.pipe';
import { TClassForText } from '@models/ICssStyles';

@Component({
  selector: 'dateFormatted[date][type]',
  standalone: true,
  imports: [CommonModule, GetTimezoneFromDateFromServerPipe],
  templateUrl: './date-formatted.component.html',
  styleUrls: ['./date-formatted.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateFormattedComponent {
  @Input() date!: string;
  @Input() type!: 'date' | 'time';
  @Input() format: string = ''; // 'fullDate' | 'shortTime' | 'EE, MMM d, yyy'
  @Input() typeText: TClassForText = 'text8-blueDark';
}
