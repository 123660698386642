<ng-container *ngIf='{isCreateGroup: (meS.onboarding$|async)?.groupCreated} as obj'>
  <div class='wrapperMenuMeUser {{obj.isCreateGroup ? "":"wrapperNoCreateGroup"}}'>
    <div class='itemMenuMeUserMeInfo'>
      <photo [data]='meS.me' class='mePhoto' w='40'></photo>
      <div class='itemMenuMeUserMeInfo__nameRole'>
        <span class='itemMenuMeUserMeInfo__nameRole__name o-noWrap'>{{meS.me|getUserName}}</span>
        <span class='itemMenuMeUserMeInfo__nameRole__role'>{{meS.meRole|strCase:'capitalize'}}</span>
      </div>
    </div>

    <div (click)='goToMyProfile()' class='itemMenuMeUser'>
      <svgAndText text='Settings' leftSvgName='settings_1&16' typeText='text7-blueDark'></svgAndText>
    </div>

    <div *ngIf='meS.GROUP_ASSIGNOR' (click)='obj.isCreateGroup ? goToMyGroup():null' class='itemMenuMeUser'>
      <svgAndText text='Admin Tools' leftSvgName='settings_2&16' typeText='text7-blueDark'></svgAndText>
    </div>
    <!-- <div *ngIf='meS.GROUP_ASSIGNOR' (click)='obj.isCreateGroup ? null:null' class='itemMenuMeUser'>
      <svgAndText text='Manage Plan' leftSvgName='dollarBlue' typeText='text7-blueDark'></svgAndText>
    </div> -->

    <!--        <div *ngIf="!meS.OFFICIAL" (click)="obj.isCreateGroup ? null:null" class="itemMenuMeUser">-->
    <!--          <svgAndText text="View Credit Usage" leftSvgName="moneyBlue&16" typeText="text7-blueDark"></svgAndText>-->
    <!--        </div>-->

    <div *ngIf='meS.OFFICIAL && !mainS.forProd' (click)="router.navigate(['myAssignors'])" class='itemMenuMeUser'>
      <svgAndText text='My Assignors' leftSvgName='user_users&16' typeText='text7-blueDark'></svgAndText>
    </div>

    <div (click)="router.navigate([_urlPayments_assignorFees])" class='itemMenuMeUser'
      style="border-top: 1px solid #D0D5DD;"  *ngIf='meS.GROUP_ASSIGNOR'>
      <svgAndText text='Assignor Fees' leftSvgName='dollarBlue' typeText='text7-blueDark'></svgAndText>
    </div>

    <!-- <div *ngIf='meS.GROUP_ASSIGNOR' class='itemMenuMeUserPlan'>
      <svgAndText text='Credit Usage' leftSvgName='layersTwoBlue&16' typeText='text7-blueDark'></svgAndText>
      <div class='itemMenuMeUserPlan__creditsText'>250 of 500 game credits</div>
      <div class='itemMenuMeUserPlan__creditsLine'></div>
      <progressBar [value]='50' color='newGreen' bcg='grey_3' height='8' class='o-mg-t-6'></progressBar>
      <div class='itemMenuMeUserPlan__btn' (click)='obj.isCreateGroup ? null:null'>Buy Credits</div>
    </div> -->

    <!--    // !!! НЕ УДАЛЯТЬ ПОКА ЧТО -->
    <!--    <div (click)='obj.isCreateGroup ? mainS.switchRole():null' class='itemMenuMeUser'>-->
    <!--      <svgAndText [text]='meS.me?.roleCurrent=="OFFICIAL" ? "Switch to group assignor":"Switch to official"' leftSvgName='arrow_switch&16'-->
    <!--                  typeText='text7-blueDark'></svgAndText>-->
    <!--    </div>-->
    <div (click)='doLogOut()' class='itemMenuMeUser o-cur-pointer-important'>
      <svgAndText text='Log out' leftSvgName='arrow_logout&16' typeText='text7-blueDark'></svgAndText>
    </div>
  </div>
</ng-container>