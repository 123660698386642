import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeService } from '@services/me.service';
import { DashboardService } from '@app/dir_group_assignor/dashboard/dashboard.service';
import { MainService } from '@services/main.service';
import { MatDialog } from '@angular/material/dialog';
import {
  CongratsForOfficialAfterAddNewGroupComponent,
} from '@components/__congrats/congrats-for-official-after-add-new-group/congrats-for-official-after-add-new-group.component';
import { StepsOnboardingComponent } from '@app/dir_group_assignor/dashboard/components/steps-onboarding/steps-onboarding.component';
import { DashboardOfficialComponent } from '@app/dir_group_assignor/dashboard/components/dashboard-official/dashboard-official.component';
import {
  DashboardGroupAssignorComponent,
} from '@app/dir_group_assignor/dashboard/components/dashboard-group-assignor/dashboard-group-assignor.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { DashboardAdminComponent } from '@app/dir_group_assignor/dashboard/components/dashboard-admin/dashboard-admin.component';
import { urlGames } from '@app/app.module';

@UntilDestroy()
@Component({
  selector: 'dashboard',
  standalone: true,
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  imports: [CommonModule, StepsOnboardingComponent, ReactiveFormsModule, FormsModule, DashboardOfficialComponent, DashboardGroupAssignorComponent, DashboardAdminComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {

  constructor(
    public mainS: MainService,
    public meS: MeService,
    public dashboardS: DashboardService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    // console.log('DashboardComponent :', this.router.url)
    if (this.meS.needGetOnboarding) this.getOnboarding();
  }

  ngOnInit(): void {
    this.checkLocalStorage_for_queryParams_forRegistration();
    //  это временно. Пока что страница дашборда не готова
    if (this.meS.ADMIN) this.router.navigate([urlGames]);
  }

  checkLocalStorage_for_queryParams_forRegistration(): void {
    const groupId = localStorage.getItem('temp_queryParams_forRegistration_groupId');
    // console.log('checkLocalStorage_for_queryParams_forRegistration groupId :', groupId)
    // const key =  localStorage.getItem('temp_queryParams_forRegistration_key')
    // const role =  localStorage.getItem('temp_queryParams_forRegistration_role')
    if (groupId) {
      this.mainS.getGroupById(groupId).pipe(untilDestroyed(this)).subscribe((group) => {
        const dataPopup = { data: { group } };
        this.dialog.open(CongratsForOfficialAfterAddNewGroupComponent, dataPopup).afterClosed().toPromise().then((res) => {
          localStorage.removeItem('temp_queryParams_forRegistration_groupId');
          // localStorage.removeItem('temp_queryParams_forRegistration_key')
          // localStorage.removeItem('temp_queryParams_forRegistration_role')
        });
      });
    }
  }

  getOnboarding(): void {
    this.dashboardS.getOnboarding().pipe(untilDestroyed(this))
      .subscribe((res) => {
        // console.log('dashboardS.getBlockDashboard() :', res);
        this.dashboardS.getBlockDashboard();
      });
    // .subscribe((res) => this.dashboardS.getBlockDashboard());
  }
}
