<div class='wrapperPopupConfirm'>
  <headTitle *ngIf='textTitle' [text]='textTitle' svgRight='cross_grey&24' (svgRightEmit)='close(false)'
             typeText='text4-blueDark'></headTitle>

  <headTitle *ngIf='textTitle2' [text]='textTitle2' typeText='text4-blueDark'></headTitle>

  <div *ngIf='text' class='subtitlePopupConfirm'>{{ text }}</div>

  <btnWrap [swapBtn]='swapBtn' [style]='{marginTop: marginTopForBtn+"px"}'>
    <btn [text]='textBtnCancel' [bcg]='colorBtnCancel' (emit)='close(false)'></btn>
    <btn [text]='textBtnApply' [bcg]='colorBtnApply' (emit)='close(true)'></btn>
  </btnWrap>
</div>