import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TClassForText } from '@models/ICssStyles';
import { TSvgName } from '@components/__svg_img/svg/forSvg';

@Component({
  selector: 'headTitle[text]',
  templateUrl: './head-title.component.html',
  styleUrls: ['./head-title.component.scss'],
  standalone: true,
  imports: [CommonModule, SvgComponent, MatTooltipModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadTitleComponent implements OnChanges {
  @Input() type: 'col' | 'row' = 'row';
  @Input() text = '';
  @Input() isPopup = false; // для попап окон всегда font-size=22px
  @Input() svgLeft?: TSvgName;
  @Input() svgRight?: TSvgName;
  @Input() svgLeftWidth: string = ''; // '32';
  @Input() svgRightWidth: string = ''; // '24';
  isCross = false; // for align-self: flex-start => для попап окон крестик чтобы вверху был

  @Input() titleArrow: string = '';
  @Input() titleCross: string = '';

  @Input() typeText: TClassForText = 'text2-blueDark';
  @Input() textCenter = false;

  @Input() svgRightMargin?: string | 'right' = 'right'; // отступ от текста до иконки. Если right то к правому краю прижать

  @Output() svgLeftEmit = new EventEmitter();
  @Output() svgRightEmit = new EventEmitter();

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.svgRight?.currentValue) {
      if (this.svgRight?.includes('cross')) this.isCross = true;
    }
  }
}
