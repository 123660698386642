import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from '@components/pagination/pagination.component';
import { ClassSettingsRequest } from '@models/response-and-request';
import { MeTableService } from '@components/_table/me-table.service';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { UtilsService } from '@services/utils.service';

@Component({
  selector: 'paginationWithSettings',
  standalone: true,
  imports: [CommonModule, PaginationComponent],
  templateUrl: './pagination-with-settings.component.html',
  styleUrls: ['./pagination-with-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationWithSettingsComponent<T> {

  @Output() updatePage = new EventEmitter<number>();

  constructor(
    public meTableS: MeTableService<T>,
    private settingsRequestS: SettingsRequestService, // for-settings===
  ) {
  }

  emitPagination(settings: ClassSettingsRequest): void {
    const { page, size } = settings;
    const newSettings: ClassSettingsRequest = { page, size, typeEmitSetting: 'pagination' };
    this.settingsRequestS.updateSettings(UtilsService.removeEmptyKeysFromObject(newSettings), 'pagination');
    this.updatePage.emit(page);
  }

}
