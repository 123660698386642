import { ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';

// export type TAge = '15' | '16' | '17' | '18' | '25' | '26' | '35' | '36' | '45' | '46';
export type TAgeForOfficials = '<15' | '16-17' | '18-25' | '26-35' | '36-45' | '46>';

export interface ICurrentLink_officials {
  currentLink?: TCurrentLinkDrop_officials;
}

export type TCurrentLinkTitleCase_officials = 'Active' | 'Pending';
export type TCurrentLinkLowerCase_officials = 'active' | 'pending'; // !!! для отправки на сервер используется LowerCase в queryParams
export type TCurrentLink_officials = 'ACTIVE' | 'PENDING';
export type TCurrentLinkDrop_officials = ClassDrop & {
  titleCase: TCurrentLinkTitleCase_officials,
  upperCase: TCurrentLink_officials,
};

export const arrCurrentLinkDrop_officials: Array<TCurrentLinkDrop_officials> = [
  { titleCase: 'Active', upperCase: 'ACTIVE' },
  { titleCase: 'Pending', upperCase: 'PENDING' },
];

export const currentLinkActive_officials: Extract<TCurrentLink_officials, 'ACTIVE'> = 'ACTIVE';
export const currentLinkPending_officials: Extract<TCurrentLink_officials, 'PENDING'> = 'PENDING';
export const currentLinkDropActive_officials: TCurrentLinkDrop_officials = {
  titleCase: 'Active',
  upperCase: 'ACTIVE',
};
export const currentLinkDropPending_officials: TCurrentLinkDrop_officials = {
  titleCase: 'Pending',
  upperCase: 'PENDING',
};
