<!-- <img
  [src]="'assets/logoPaySystem/' + (cardBrand ? cardBrand : 'pay-system-unknown') + '.svg'"
  [width]="!height ? width : 'auto'"
  [height]="!width ? height : 'auto'"
  [style.width]="!height ? width + 'px' : 'auto'"
  [style.height]="!width ? height + 'px' : 'auto'"
  alt=""> -->

  <img
  [src]="'assets/logoPaySystem/' + (cardBrand ? cardBrand : 'pay-system-unknown') + '.svg'"
  [width]="width ? width : 65"
  [height]="height ? height : 44"
  [style.width.px]="width ? width : 65"
  [style.height.px]="height ? height : 44"
  alt="Card Logo">


