<div class='wrapDropFormCtrl'
     [style]='{w:w, h:h}|getStyles:forTest'
     (click)='test()'>
  <ng-container *ngIf='{
      cssClass: "o-matForm--"+({value:value,active:active,disabled:disabled,error:errRequired}|getStateInput)
  } as obj'>
    <mat-form-field floatLabel='auto'
                    class='o-matForm {{obj.cssClass}} {{obj.cssClass}}--{{typeText}}'
                    [ngClass]='"o-matForm--"+bcg+" o-matForm-height-"+h'
                    >

      <meSvg *ngIf='svgPrefix'
             matPrefix
             [svgName]='svgPrefix'
             [w]='widthSvgPrefix'></meSvg>
      <meSvg *ngIf='svgSuffix'
             matSuffix
             [svgName]='svgSuffix'
             [w]='widthSvgSuffix'>
      </meSvg>

      <mat-select #selectRef
                  (selectionChange)='changeSelect($event)'
                  [class.enlargeReverse]='!!selectRef?.panelOpen'
                  [(ngModel)]='value'
                  [placeholder]='placeholder'
                  [multiple]='multi'
                  disableOptionCentering
                  [disabled]='!!ngControl.control?.disabled && !matOption'
                  [class.o-text-center-important]='isTextCenter'
                  (openedChange)='checkClosedDrop($event)'
                  [compareWith]='!listObjDropItem ? otherS.compareString : otherS.compareObjects'>

        <mat-option [ngStyle]='(elRef|getWidthMatMenu)' class='hiddenMatOption'></mat-option>

        <matOption *ngIf='matOption'
                   [textError]='matOptionTextError'
                   [text]='matOptionText'
                   (clickEmit)='clickCustomMatOption()'
                   [isTextCenter]='isTextCenterOtherMatOption'>
        </matOption>

        <ng-container *ngFor='let item of (listObjDropItem || listStr)'>
          <!--        !!! defaultNameMatOption == 'ALL'-->
          <ng-container
            *ngIf='{defaultNameMatOption: listObjDropItem ? ((item.name || item.title)==defaultNameMatOption) : false} as objMatOption'>

            <!--            !!! пока не удалять class='{{listObjDropItem?.length ? (item.idCopyForDropFormCtrl ? "hiddenMatOption":"") : ""}}' -->
            <mat-option *ngIf='!item.children'
                        [value]='item'
                        (click)='getMatOptionId(item.id)'
                        [class.o-text-center-important]='isTextCenter || objMatOption.defaultNameMatOption'>
              <div class='matOptionWithSvg'
                   [class.o-text-center-important]='isTextCenter || objMatOption.defaultNameMatOption'>

                <!-- !!! titlecase не добавлять Sub-Assignor => Sub-assignor -->
                <!--              {{(listObjDropItem ? (item.name || item.title) : item)}}-->
                <!--              {{objMatOption.defaultNameMatOption ? ('-&#45;&#45; ' + item.name + ' -&#45;&#45;') : (listObjDropItem ? (item.name || item.title) : item)}}-->
                {{
                objMatOption.defaultNameMatOption
                  ? ('--- ' + (replaceDefaultNameMatOption ? replaceDefaultNameMatOption : item.name) + ' ---')
                  : (listObjDropItem ? (item.name || item.title) : item)
                }}
                <ng-container *ngIf='item.itemCount'>
                  ({{item.itemCount}})
                </ng-container>
                <!--                <meSvg *ngIf='svgRightMatOption' [svgName]='svgRightMatOption'-->
                <!--                       [class]='item?.toLowerCase() === svgMatOptionCondition?.toLowerCase() ? "":"o-hidden"'></meSvg>-->
                <meSvg *ngIf='svgRightMatOption'
                       [svgName]='svgRightMatOption'>
                </meSvg>
                <meSvg *ngIf='listStr?.length ? item?.toLowerCase() == "notch pay" : false'
                       svgName='lightning_gold&20'>
                </meSvg>
              </div>
            </mat-option>

            <ng-container *ngIf='item.children'>
              <mat-optgroup (click)='getMatOptionId(item.id)'
                            [label]='item.name'>
                <meSvg [svgName]='"arrow_chevronRight&24"'></meSvg>
                <div [class.group-options--isVisible]='item.id === subGroupVisibleId'
                     class='group-options'>
                  <mat-option *ngFor='let option of item.children'
                              [value]='option.listName'>
                    {{option.listName}}
                    <span>({{option.officialCount}})</span>
                  </mat-option>
                </div>
              </mat-optgroup>
            </ng-container>
          </ng-container>
        </ng-container>

        <mat-option *ngIf='addNewItem'
                    class='otherMatOption otherMatOption--{{multi}}'>Other
          <input matInput
                 [(ngModel)]='newItemValue'
                 (ngModelChange)='newItemChange($event)'
                 (click)='$event.stopPropagation();$event.preventDefault()'>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <error *ngIf='errRequired && showErrText'
           [text]='textRequired'>
    </error>
  </ng-container>
</div>