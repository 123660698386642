import { Pipe, PipeTransform } from '@angular/core';
import { TStateInput } from '@pipes/get-state-input.pipe';
import { TSvgName } from '@components/__svg_img/svg/forSvg';

@Pipe({ name: 'getSvgArrowForDropdown', standalone: true })
export class GetSvgArrowForDropdownPipe implements PipeTransform {

  transform(stateInput: TStateInput): TSvgName {
    if (stateInput === 'active' || stateInput === 'filled') {
      return 'arrow_chevronBottom&20';
    } else if (stateInput === 'disabled' || stateInput === 'empty') {
      return 'arrow_chevronBottomGrey&20';
    } else if (stateInput === 'error') {
      return 'arrow_chevronBottomRed&20';
    }
    return 'arrow_chevronBottom&20';
  }

}
