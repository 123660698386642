import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { urlGameImport } from '@app/app.module';
import { AdminPermissionDirective } from '@directives/admin-permission.directive';
import { BtnComponent } from '@components/btn/btn.component';
import { Router } from '@angular/router';
import { DeviceService } from '@services/device.service';

@Component({
  selector: 'btnImportGames',
  standalone: true,
  imports: [CommonModule, AdminPermissionDirective, BtnComponent],
  templateUrl: './btn-import-games.component.html',
  styleUrls: ['./btn-import-games.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnImportGamesComponent {

  readonly urlGameImport = urlGameImport;

  constructor(
    public deviceS: DeviceService,
    public router: Router,
  ) {
  }

}
