import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TClassForText } from '@models/ICssStyles';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { GetStylesPipe } from '@pipes/css/get-styles.pipe';
import { TSvgName } from '@components/__svg_img/svg/forSvg';

@Component({
  selector: 'svgAndText[text]',
  standalone: true,
  imports: [CommonModule, SvgComponent, GetStylesPipe],
  templateUrl: './svg-and-text.component.html',
  styleUrls: ['./svg-and-text.component.scss'],
})
export class SvgAndTextComponent {
  @Input() text!: string;
  @Input() typeText: TClassForText = 'text6-blueDark';

  @Input() leftSvgName?: TSvgName;
  @Input() rightSvgName?: TSvgName;

  @Input() marginForLeftSvg: number | string = 14; // auto можно использовать
  @Input() marginForRightSvg: number | string = 14; // auto можно использовать
  @Input() disable?: boolean = false;

  @Output() leftSvgEmit = new EventEmitter();
  @Output() rightSvgEmit = new EventEmitter();

  @Input() w?: string | number = ''; // если передано, то устанавливаем размер SVG
  // @Input() cur?: boolean = true; // cursor: cur ? pointer : default

  emitRightSvg() {
    if (this.disable) return;
    this.rightSvgEmit.emit();
  }

  emitLeftSvg() {
    if (this.disable) return;
    this.leftSvgEmit.emit();
  }
}
