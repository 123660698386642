import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from '@components/__info_text_message_error_warning/error/error.component';

@Component({
  selector: 'matOption',
  templateUrl: './mat-option.component.html',
  styleUrls: ['./mat-option.component.scss'],
  standalone: true,
  imports: [CommonModule, ErrorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatOptionComponent {
  @Input() text?: string;
  @Input() textError?: string;
  @Input() isTextCenter = false;
  @Output() clickEmit = new EventEmitter();
}
