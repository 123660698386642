import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  CompetitionsNavigationComponent,
} from '@app/dir_group_assignor/competitions/helperComponentsCompetitions/competitions-navigation/competitions-navigation.component';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { BtnComponent } from '@components/btn/btn.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { BtnAddAnotherComponent } from '@components/btn-add-another/btn-add-another.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { HelperClass } from '@classes/Helper-Classes';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { CompetitionService } from '@app/dir_group_assignor/competitions/competition.service';
import { MainService } from '@services/main.service';
import { ActivatedRoute } from '@angular/router';
import {
  ClassCompetition,
  ClassCompetitionOfficialLabels,
  ClassCompetitionOfficialLabelsItem,
} from '@app/dir_group_assignor/competitions/ClassCompetition';
import { THttpMethod } from '@services/base-api';
import { StrCasePipe } from '@pipes/string/str-case.pipe';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { CheckActiveService } from '@app/dir_group_assignor/competitions/services/checkActiveService';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';
import { AuthenticatorDirective, AuthenticatorService } from '@directives/authenticator-hide.directive';

interface IFormCompetitionsOfficialLabels {
  arrTableHeader?: FormControl<Array<string>>;
  arrControls?: FormArray<FormGroup<IFormItemCompetitionsOfficialLabels>>;
}

interface IFormItemCompetitionsOfficialLabels {
  id?: FormControl<string>;
  competitionId?: FormControl<string>;
  officialPosition?: FormControl<string | number>; // officialId // crewSize
  labels?: FormArray<FormGroup<IFormItemCeilCompetitionsOfficialLabels>>;
}

interface IFormItemCeilCompetitionsOfficialLabels {
  id?: FormControl<string>;
  indexNumber?: FormControl<number | string>;
  label?: FormControl<string>;
}

@UntilDestroy()
@Component({
  selector: 'competitionsOfficialLabels',
  standalone: true,
  imports: [AuthenticatorDirective, CommonModule, MatProgressSpinnerModule, CompetitionsNavigationComponent, SvgAndTextComponent, BtnComponent, FieldComponent, InputCtrlComponent, BtnAddAnotherComponent, DropFormCtrlComponent, BtnWrapComponent, ReactiveFormsModule, StrCasePipe, SvgComponent],
  templateUrl: './competitions-official-labels.component.html',
  styleUrls: ['./competitions-official-labels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetitionsOfficialLabelsComponent extends HelperClass implements OnInit {
  form!: FormGroup<IFormCompetitionsOfficialLabels>;
  isReadOnly!: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder, // UntypedFormBuilder, FormBuilder
    public competitionS: CompetitionService,
    public mainS: MainService,
    private apiCompetitionS: ApiCompetitionService,
    public route: ActivatedRoute,
    public cd: ChangeDetectorRef,
    private readonly checkActiveService: CheckActiveService,
    private authenticatorService: AuthenticatorService
  ) {
    super(cd);
    this.createForm();
  }

  ngOnInit() {
    this.subscribeToHeaderOfficialLabels();
    this.isReadOnly = !this.authenticatorService.isAllow(['GROUP_ASSIGNOR', 'SUB_ASSIGNOR']);
    this.isReadOnly ? this.form.disable() : this.form.enable();
  }

  addNew(item: ClassCompetitionOfficialLabels, idxOfficialLabel: number, str?: string): void {
    const newFormGroup: FormGroup<IFormItemCompetitionsOfficialLabels> = this.formBuilder.group({
      id: [item.id],
      competitionId: [item.competitionId],
      officialPosition: [item.officialPosition],
      labels: this.formBuilder.array([]),
    });

    item.labels?.forEach((el, idx) => {
      (newFormGroup.get('labels') as FormArray<FormGroup<IFormItemCeilCompetitionsOfficialLabels>>).push(this.formBuilder.group({
        id: [el?.id || ''],
        indexNumber: [el?.indexNumber || ''],
        label: [{ value: el?.label || '', disabled: !!el?.disabled }, idxOfficialLabel <= idx ? Validators.required : null], // !!! пока не удалять  label: [el?.label || '', idxOfficialLabel <= idx ? Validators.required : null],
      }));
    });

    this.arrControls.push(newFormGroup);
    this.arrControls?.controls?.forEach((el, idx) => {
      el.controls?.officialPosition?.patchValue(idx + 1); // officialId
    });
  }

  // === FORM ==============
  private createForm(): void {
    this.form = this.formBuilder.group({
      arrTableHeader: new FormControl([]) as FormControl<Array<string>>,
      arrControls: this.formBuilder.array([]),
    });
    this.form.controls.arrTableHeader?.setValue(this.competitionS.arrTableHeaderOfficialLabels);

    // const competition = this.competitionS.competition;
    // const officialLabels_length = competition.officialLabels?.length || 0
    // // const newOfficialLabels = new ClassCompetitionOfficialLabels({},this.competitionS.competition?.id!)
    // const newSubItem = new ClassCompetitionOfficialLabels({
    //   // indexNumber: maxCrewSizeSubItem,
    //   // id: labelId,
    //   // label: labelValue,
    //   // disabled: !!(competition?.maxCrewSize && (competition?.maxCrewSize < 4)) && (competition?.maxCrewSize < maxCrewSizeSubItem),
    //   // @ts-ignore
    //   disabled: !!(competition?.maxCrewSize && (competition?.maxCrewSize < 4)) && (competition?.maxCrewSize < officialLabels_length),
    // }, this.competitionS.competition?.id!);
    // // !!! если на сервере сохранено меньше чем минимальное количество competitionS.amountOfficialLabels, то надо добавить недостающие (disabled) просто для отображения
    // if (this.competitionS.amountOfficialLabels > this.competitionS.officialLabels?.length) {
    // }

    this.competitionS.officialLabels?.forEach((el) => {
      const labels: FormArray<FormGroup<IFormItemCeilCompetitionsOfficialLabels>> = this.formBuilder.array([]);
      this.arrControls.push(this.formBuilder.group({
        ...el, ...labels,
        officialPosition: '', // officialId: '',
        competitionId: this.competitionS.competition?.id || '',
      }));
    });

    // !!! from setForm()
    this.competitionS.officialLabels?.forEach((el, idxArrControls) => {
      this.arrControls.clear();
      this.competitionS.competition?.officialLabels?.forEach((item, idx) => this.addNew(item, idx));
    });
    setTimeout(() => this.cd.detectChanges());
  }

  get arrControls(): FormArray<FormGroup<IFormItemCompetitionsOfficialLabels>> {
    return this.form.controls?.arrControls!;
  }

  // === LABELS ===========================
  getArrLabels(item: FormGroup<IFormItemCompetitionsOfficialLabels>): Array<FormGroup<IFormItemCeilCompetitionsOfficialLabels>> {
    const result = (item.get('labels') as FormArray)?.controls as Array<FormGroup<IFormItemCeilCompetitionsOfficialLabels>>;
    return result;
  }

  // === HEADERS ===========================
  private subscribeToHeaderOfficialLabels(): void {
    this.competitionS.arrTableHeaderOfficialLabels$.pipe(untilDestroyed(this)).subscribe((res) => {
      this.form.controls.arrTableHeader?.setValue(this.competitionS.arrTableHeaderOfficialLabels);
      this.cd.detectChanges();
    });
  }

  // === competitions Btns Emit =========================
  async competitionsBtnsEmit(isNext: boolean): Promise<void> {
    const competitionId = this.competitionS?.competition?.id;
    const isActiveModal = await this.checkActiveService.checkActive(competitionId, true);
    if (isActiveModal) {
      if (!competitionId) this.methodCompetitionOfficialLabels(isNext, 'post'); // create
      if (competitionId) this.methodCompetitionOfficialLabels(isNext, 'put'); // update
    }
  }

  methodCompetitionOfficialLabels(isNext = false, httpMethod: THttpMethod): void {
    if (this.startRequest()) return;
    const sendObj: { id: string, officialLabels: Array<ClassCompetitionOfficialLabels> } = {
      id: this.competitionS.competition?.id!,
      officialLabels: this.form.getRawValue().arrControls
        ?.map((el, idx) => {
          if (idx >= 0 && idx < (this.competitionS?.competition?.maxCrewSize || 4)) {
            const newEl = {
              ...el,
              officialPosition: idx + 1, // officialId: idx,
              labels: el?.labels?.map((l, i) => {
                if (i >= 0 && i < (this.competitionS?.competition?.maxCrewSize || 4)) {
                  if (!l?.label) return null;
                  if (i || i === 0) return l;
                  return null;
                } else return null;
              })?.filter(Boolean),
            };
            return newEl;
          } else return null;
        })?.filter(Boolean) as Array<ClassCompetitionOfficialLabels>,
    };
    this.competitionS.addCompetitionIdBeforeSendToServer(sendObj.officialLabels);

    // this.apiCompetitionS.methodCompetitionOfficialLabels(sendObj, this.competitionS.competition?.id!, httpMethod).toPromise()
    this.apiCompetitionS.methodCompetitionOfficialLabels(sendObj, httpMethod).toPromise()
      .then((res?: Pick<ClassCompetition, 'officialLabels'>) => {
        if (!res?.officialLabels) return;
        this.competitionS.competition.officialLabels = res.officialLabels;
        isNext ? this.competitionS.nextStep('officialLabels') : this.competitionS.goToDashboard();
      })
      .catch((err: any) => {
      })
      .finally(() => this.endRequest());
  }

}

// сначала дожидаюсь получения с сервера компетишна. Потом получаю с сервера массив данных для текущей страницы. Сохранение данных в сервисе.
// потом здесь в компоненте устанавливается значения для реактивной формы.
// subscribeToCompetition(): void {
//   this.competitionS.competition$.pipe(untilDestroyed(this)).subscribe((competition?: ClassCompetition) => {
//     if (!competition) return;
//     // если нет массива данных, то получить с севрера getCompetitionOfficialLabels().
//     // если нет массива в ответе с сервера то устанавливается массив по дефолту. Если есть массив в ответе с сервера то устаннавливается этот ответ с сервера
//     // Из сервиса сюда возвращается массив данных. И устанавливается реактивная форма
//     if (this.competitionS.officialLabels?.length) this.setForm('111');
//     this.competitionS.getCompetitionOfficialLabels().toPromise().then((res?: Array<ClassCompetitionOfficialLabels>) => this.setForm('222'));
//   });
// }
// setForm(str: string): void {
//   this.competitionS.officialLabels?.forEach((el, idxArrControls) => {
//     this.arrControls.clear();
//     this.competitionS.competition?.officialLabels?.forEach((item, idx) => this.addNew(item, idx, str));
//   });
//   setTimeout(() => this.cd.detectChanges());
// }
