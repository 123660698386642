<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              typePageProfile: meProfileOfficialS.typePageProfile,
              arrLinkObj: (profileS.arrLinkObj$|async)!,
              currentSubLink: (profileS.currentSubLink$|async)!,
            } as obj' class='wrapperProfile wrapperMeProfileOfficial'>

  <linkPage *ngIf='obj.desktop' [arrLink]='(profileS.arrLinkObj$|async)!'
            [link]='(profileS.currentSubLink$|async)!'
            (emit)='profileS.navigateProfile($event,meProfileOfficialS.typePageProfile)'></linkPage>

  <div class='wrapperProfile__content'>
    <router-outlet></router-outlet>
  </div>
</div>
