import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadTitleComponent } from "../../head-title/head-title.component";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BtnComponent } from "../../btn/btn.component";
import { AccountPaymentComponent } from "@components/account-payment/account-payment.component";
import { FieldComponent } from "../../__drop_inputs_matSelect/field/field.component";
import { InputCtrlComponent } from "../../__drop_inputs_matSelect/inputCtrl/inputCtrl.component";
import { FormsModule } from '@angular/forms';
import { ClassCeilTableHeader } from '@components/_table/meTable';

interface TableItem {
  email: string;
  firstName: string;
  phone: string;
  preferredName: null;
  secondName: string;
}

@Component({
  selector: 'app-popup-recepients',
  standalone: true,
  templateUrl: './popup-recepients.component.html',
  styleUrls: ['./popup-recepients.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, HeadTitleComponent, BtnComponent, AccountPaymentComponent, FieldComponent, InputCtrlComponent, FormsModule]
})
export class PopupRecepients {
  search: string = '';
  tableHeader: Array<ClassCeilTableHeader> = [
    // { text: 'Name', chx: false, isActive: false },
    // { text: 'Email(s)', chx: false, isActive: false },
    { text: 'Name' },
    { text: 'Email(s)' },
  ];

  tableItems: TableItem[] = [];
  filteredTable: TableItem[] | null = null;
  excludeEmails: string[] = [];
  private _originalClose: Function;

  constructor(
    public dialogRef: MatDialogRef<PopupRecepients>,
    @Inject(MAT_DIALOG_DATA) public dataPopup: { isEdit: boolean; officialsLists: []; },
    private _dialogRef: MatDialogRef<PopupRecepients>
  ) {
    this.tableItems = dataPopup?.officialsLists || [];
    this.tableItems = dataPopup?.officialsLists || [];

    this._originalClose = this._dialogRef.close;
    this._dialogRef.close = this.closeDialog.bind(this);
  }

  closeDialog(e = null) {
    this._originalClose.bind(this._dialogRef)({ excludeEmails: this.excludeEmails, recipients: this.tableItems });
  }

  removeEmailFromList(email: string) {
    this.tableItems = this.tableItems.filter((listItem: TableItem) => listItem.email !== email);
    this.excludeEmails.push(email);
  }

  changeVal(word: string): void {
    if (word === '') {
      this.filteredTable = null;
      return;
    }
    const words = word.split(' ');
    words.forEach(letters => {
      this.filteredTable = this.tableItems.filter((listItem: TableItem) =>
        this.doesEmailMatch(listItem, letters) ||
        this.doesNameMatch(listItem.firstName, letters) ||
        this.doesNameMatch(listItem.secondName, letters)
      );
    })
  }

  private doesEmailMatch(listItem: TableItem, email: string): boolean {
    return listItem.email.includes(email);
  }

  private doesNameMatch(name: string, email: string): boolean {
    return name !== undefined && name !== null && name.toLowerCase().includes(email.toLowerCase());
  }
}
