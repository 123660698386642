import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnComponent } from '@components/btn/btn.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClassGroup } from '@models/IGroup';

@Component({
  selector: 'congrats-for-official-after-add-new-group',
  standalone: true,
  imports: [CommonModule, BtnComponent],
  templateUrl: './congrats-for-official-after-add-new-group.component.html',
  styleUrls: ['./congrats-for-official-after-add-new-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CongratsForOfficialAfterAddNewGroupComponent {
  groupName: string = '';

  constructor(
    public dialogRef: MatDialogRef<CongratsForOfficialAfterAddNewGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public dataPopup: { group: ClassGroup },
  ) {
    this.groupName = dataPopup.group?.name || '';
  }

  close(): void {
    this.dialogRef.close(null);
  }
}
