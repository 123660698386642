import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormCustomService } from '@services/form-custom.service';

@Pipe({ name: 'invalidControls', standalone: true, pure: false }) // !!! no delete pure: false
export class InvalidControlsPipe implements PipeTransform {

  constructor(
    private formS: FormCustomService,
  ) {
  }

  // проверка formControls на валидность control.valid
  // !!! вернется true, если хотя бы один formControl НЕвалидный
  // !!! вернется false, если все formControl валидны
  transform(form?: UntypedFormGroup, requiredFields?: Array<string>): boolean {
    return this.formS.checkInvalidControls(form, requiredFields);
  }

}
