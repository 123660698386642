export class EmbeddableTabbedList {
    embeddableId: string = "";
    tabName?: string;
}

export const embeddableTabList: Array<EmbeddableTabbedList> = [
    {
        tabName: "Games",
        embeddableId: "0d8b4f38-1218-49a6-8143-b369e6131f24"
    },
    {
        tabName: "Officials",
        embeddableId: "a94842a0-03dc-459d-8b24-ffa09b752d0a"
    },
    {
        tabName: "Financial",
        embeddableId: "53dfb2bc-e9a6-4a98-9049-11d1ba208d2c"
    }
]