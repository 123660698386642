import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeTableService } from '@components/_table/me-table.service';
import { CeilTableDirective } from '@components/_table/directives/ceil-table.directive';
import { GetChxSvgNamePipe } from '@components/__svg_img/svg/pipes/get-chx-svg-name.pipe';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { IForTable } from '@components/_table/meTable';
import { StopPropagationDirective } from '@directives/stop-propagation.directive';
import { PhotoComponent } from '@components/__svg_img/photo/photo.component';
import { RouterLink } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DeviceService } from '@services/device.service';
import { GetMatTooltipCancelReasonPipe } from '@pipes/game_and_report/get-mat-tooltip-cancel-reason.pipe';
import { TPath, urlGames, urlMyGames } from '@app/app.module';
import { UtilsService } from '@services/utils.service';

@Component({
  selector: 'app-tableCeilChx[item][text]',
  standalone: true,
  imports: [CommonModule, CeilTableDirective, GetChxSvgNamePipe, SvgComponent, StopPropagationDirective, PhotoComponent, RouterLink, MatTooltipModule, GetMatTooltipCancelReasonPipe],
  templateUrl: './table-ceil-chx.component.html',
  styleUrls: ['./table-ceil-chx.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush, // !!! не нужно здесь
})
export class TableCeilChxComponent<T> implements OnChanges {
  @Input() item!: T & IForTable;
  @Input() text?: string | number;
  @Input() path?: TPath;

  @Input() hiddenChx?: boolean; // если нужно скрывать чекбоксы то передать true

  readonly arrPath_for_show_matTooltip_canceledDetails: Array<TPath> = [urlGames, urlMyGames];
  matTooltip_canceledDetails: string = '';

  constructor(
    public deviceS: DeviceService,
    public meTableS: MeTableService<T>,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.path?.currentValue) {
      this.check_id_show_matTooltip_canceledDetails();
    }
  }

  private check_id_show_matTooltip_canceledDetails(): void {
    if (this.arrPath_for_show_matTooltip_canceledDetails.includes(this.path!)) {
      this.matTooltip_canceledDetails = UtilsService.getMatTooltipCancelReason(this.item);
    }
  }

}
