import { Injectable } from '@angular/core';
import { ClassUser, IAvailabilitySameDate } from '@models/user';
import { ClassGame, ClassGameOfficial } from '@app/dir_group_assignor/games/game';
import {
  ClassCompetition,
  ClassCompetitionAgeGroup,
  ClassCompetitionLevel,
  ClassCompetitionLocation,
  ClassCompetitionTeam,
} from '@app/dir_group_assignor/competitions/ClassCompetition';
import { ILocation } from '@models/location';
import { ClassGroup } from '@models/IGroup';
import { IAssignorFees, IBalance, IPayer, IPaymentMethod } from '@app/dir_group_assignor/payments/modelsForPayment';
import { ClassAttendance, ClassReport } from '@app/dir_group_assignor/reports/report';
import { ClassTransfer } from '@models/transfer.model';
import { IResponse } from '@models/response-and-request';
import { ClassAdjustmentStatus } from '@app/group-profile/components/group-profile-adjustment-statuses/adjustmentStatus';
import { IOnboarding } from '@app/dir_group_assignor/dashboard/dashboard';

@Injectable({ providedIn: 'root' })
export class ForTestService {
  private amount = 1; // !!! чтобы у фейковых данных id разные были

  get me(): ClassUser {
    return {
      'id': '71843f70-6ec9-431f-8361-a750968f9863',
      'firstName': 'First Name',
      'secondName': 'First Name',
      'preferredName': 'First Name',
      'emailValidated': true,
      'email': 'nikk.zhur+123123@gmail.com',
      'address': {
        'id': '66ba10a2-676f-4da2-b843-8d9445684acd',
        'city': 'Нью-Мексико',
        'state': 'Нью-Мексико',
        'street': 'Альбукерке',
        'streetLine2': 'wwwwwwwwwww',
        'zipcode': '111111',
      },
      'phone': '15555555555',
      'phoneValidated': true,
      'signType': 'LOCAL',
      'enabled': true,
      'userRegisteredStatus': 'REGISTERED',
      'roleCurrent': 'GROUP_ASSIGNOR',
      'dateOfBirth': '1992-02-05',
      'onboardingSkipped': true,
    };
  }

  get onboarding(): IOnboarding {
    return {
      'groupCreated': true,
      'competitionCreated': true,
      'gamesCreated': true,
      'officialsInvited': true,
      'officialsAssigned': true,
    };
  }

  get fakeAvailabilitySameDate(): IAvailabilitySameDate {
    return {
      competitionName: 'test competitionName',
      duration: 33,
      gameAddress: '1 - 1,  Beaumont, California',
      gameAssignStatus: 'UNACCEPTED',
      gameTime: '2:30 AM',
      groupName: 'test groupName',
    };
  }

  get fakeResponse(): IResponse<any> {
    return {
      content: [], // Array<T>;
      number: 1, // number; // текущая страница . с нуля начинается
      page: 1, // number; // запрашиваемая страница
      numberOfElements: 7, // number; // сколько получил элементов
      size: 22, // number; // сколько запросили элементов
      totalElements: 77, // number; // сколько всего элементов на сервере
      totalPages: 4, // number; // сколько всего страниц на сервере
    };
  }

  get fakeUser(): ClassUser {
    return new ClassUser({
      address: {
        id: 'assdasdasdasd',
        street: 'fake street', // string;
        city: 'fake city', // string;
        zipcode: '77777', // string;
        state: 'fake state', // string;
      },
      email: 'fake email', // string;
      id: '8fb53003-8e90-42d4-a798-6ae0035a9fbf', // string;
      firstName: 'fake name', // string;
      phone: '777777777', // string;
      pictureUrl: 'https://d2t8ebd4zpmh57.cloudfront.net/83517f21-33ec-4d49-a73d-50e19a208342.jpg', // string;
      roleCurrent: 'SUB_ASSIGNOR',
      // dateOfBirth: '1996-05-08',
      dateOfBirth: new Date().toString(),
    });
  }

  get fakeGameOfficial(): ClassGameOfficial {
    return new ClassGameOfficial({
      gameId: '34ca9e1a-8fdf-44f4-b02c-ebc43cd67425',
      id: '0840b675-c90d-4e6c-b6aa-d5d469486238',
      officialFee: 0,
      officialPositionName: 'a_5',
      officialPositionNumber: 1,
      // status: 'UNASSIGNED',
      status: 'UNACCEPTED',
      selfAssigned: true,
      official: this.fakeUser,
    });
  }

  get fakeGame(): ClassGame {
    return new ClassGame({
      ageGroup: this.fakeCompetitionAgeGroup,
      awayTeam: this.fakeCompetitionTeam,
      competition: this.fakeCompetition,
      // competitionId: 'da6c7635-9d53-45f3-b043-de28f5d46635',
      date: '29 Sep 2023 12:00 AM PDT',
      duration: 22,
      gameDescription: 'sss',
      gameNumber: '222',
      gameOfficials: [this.fakeGameOfficial],
      gameStatus: 'ACTIVE',
      gender: 'FEMALE', // 'Female'
      homeTeam: this.fakeCompetitionTeam,
      id: '34ca9e1a-8fdf-44f4-b02c-ebc43cd67425',
      levels: this.fakeCompetitionLevel,
      location: this.fakeCompetitionLocation,
      officialAssignments: false,
    });
  }

  get arrFakeGame(): Array<ClassGame> {
    const game = new ClassGame({
      ageGroup: this.fakeCompetitionAgeGroup,
      awayTeam: this.fakeCompetitionTeam,
      competition: this.fakeCompetition,
      // competitionId: 'da6c7635-9d53-45f3-b043-de28f5d46635',
      // date: '29 Sep 2023 12:00 AM PDT',
      date: '17 Nov 2024 1:30 PM CST',
      duration: 22,
      gameDescription: 'sss',
      gameNumber: '222',
      gameOfficials: [this.fakeGameOfficial],
      gameStatus: 'ACTIVE',
      gender: 'FEMALE', // 'Female'
      homeTeam: this.fakeCompetitionTeam,
      id: '34ca9e1a-8fdf-44f4-b02c-ebc43cd67425',
      levels: this.fakeCompetitionLevel,
      location: this.fakeCompetitionLocation,
      officialAssignments: false,
    });
    return [{ ...game, id: game.id + '1' }, { ...game, id: game.id + '2' }, { ...game, id: game.id + '3' }];
  }

  get fake_arrTotalGames(): Array<any> {
    return [
      {
        game: { title: 100, isSelect: false },
        date: new Date().toString(),
        age: 'U13 - M - Regional',
        level: 'TCSL Winter League',
        location: 'West St. Paul Dome',
        teams: 'West St. Paul, MN',
        team1: 'United FC Rush U14 Girls Select',
        team2: 'Edina Soccer Club 2011 Girls Green',
      },
      {
        game: { title: 100, isSelect: false },
        date: new Date().toString(),
        age: 'U13 - M - Regional',
        level: 'TCSL Winter League',
        location: 'West St. Paul Dome',
        teams: 'West St. Paul, MN',
        team1: 'United FC Rush U14 Girls Select',
        team2: 'Edina Soccer Club 2011 Girls Green',
      },
    ];
  }

  get fakeCompetitionAgeGroup(): ClassCompetitionAgeGroup {
    return new ClassCompetitionAgeGroup({
      gameAgeDescription: 'Age Group 1',
      gamePeriod: 'QUARTERS',
      id: '541f5165-8c26-4881-90f1-98f402e7ccfc',
      officialSelfAssign: false,
      periodLength: 11,
      totalDurations: 22,
      titleCase: 'titleCase',
      upperCase: '541f5165-8c26-4881-90f1-98f402e7ccfc',
    }, 'testIdCompetition');
  }

  get fakeCompetitionTeam(): ClassCompetitionTeam {
    return new ClassCompetitionTeam({
      id: 'b2b75b26-aa98-4e5e-8e13-9d2ad5d05ceb',
      teamName: 'Team 2',
      titleCase: 'titleCase',
      upperCase: 'b2b75b26-aa98-4e5e-8e13-9d2ad5d05ceb',
    }, 'testIdCompetition');
  }

  get fakeCompetition(): ClassCompetition {
    return new ClassCompetition({
      competitionName: 'test Competition 1',
      competitionType: 'AMATEUR / ADULT',
      creatorId: '9459ea89-0627-4a56-8d52-81f80bcc7fe8',
      gameType: 'LEAGUE',
      groupId: 'a39041e6-5619-4b2a-bf46-e3e305bf41c3',
      id: '79c02e25-8083-4215-a0ac-3313edcdad8f',
      maxCrewSize: 5,
      // payoutFormat: 'OFFLINE CASH',
      sports: 'BASKETBALL,BASEBALL',
      titleCase: 'titleCase',
      upperCase: '79c02e25-8083-4215-a0ac-3313edcdad8f',
    });
  }

  get fakeCompetitionLevel(): ClassCompetitionLevel {
    return new ClassCompetitionLevel({
      certificationRequired: false,
      id: '433581b3-eb4a-4726-980f-a55f7ae0c7f8',
      level: 'Level 2',
      titleCase: 'titleCase',
      upperCase: '433581b3-eb4a-4726-980f-a55f7ae0c7f8',
    }, 'testIdCompetition');
  }

  get fakeCompetitionLocation(): ClassCompetitionLocation {
    return new ClassCompetitionLocation({
      address: this.fakeLocation,
      courtNames: ['test courtNames_1', 'test courtNames_2'],
      currentCourtName: 'test currentCourtName',
      id: 'id_fakeCompetitionLocation',
      name: 'name fakeCompetitionLocation',
      titleCase: 'titleCase',
      upperCase: 'id_fakeCompetitionLocation',
    }, 'testIdCompetition');
  }

  get fakeLocation(): ILocation {
    return {
      city: ' 1 World Way',
      id: '1f112575-3393-4a79-a2d6-82bd10afbf64',
      latitude: 33.9437902,
      longitude: -118.4091036,
      street: 'Международный аэропорт Лос-Анджелес (LAX)',
      timezone: { id: 'America/Los_Angeles', abbrev: 'PDT', name: 'Pacific Daylight Time', value: -7 },
      zipcode: '90045',
    };
  }

  get fakeGroup(): ClassGroup {
    return new ClassGroup({
      id: 'idTest', //  string;
      name: 'fakeGroup', //  string;
      companyLegalName: 'companyLegalName', //  string;
      logo: '', //  string;
      email: 'fakeGroup@jhjg.ru', //  string;
      phone: '+1987349753', //  string;
      gameType: 'Playoff', //  string; // TGameType
      groupAddress: this.fakeLocation, //  ILocation;
      groupAssignor: this.fakeUser, //  ClassUser; // ТИмур для страницы редактирования группы
      groupAssignorId: 'groupAssignorId', //  string;
      mailingAddress: 'mailingAddress', //  string; //  Тимур page==Group Profile
      groupTermsConditions: 'groupTermsConditions', //  string;
    });
  }

  get fakeAssignorFees(): IAssignorFees {
    return {
      competition: this.fakeCompetition,
      game: this.fakeGame,
      amount: 60,
      status: 'PAID',
      payoutFormat: 'Notch Pay',
    };
  }

  get fakePayer(): IPayer {
    return {
      id: '111',
      game: this.fakeGame,
      competition: this.fakeCompetition,
      totalAmount: 33,
      payer: this.fakeUser,
      payoutFormat: 'Notch Pay',

      // recentTransactions: [fakeBalance, fakeBalance, fakeBalance],
      officialsFees: 30,
      assignorFees: 50,
      billedAmount: 300,
    };
  }

  //   get fakeTransaction(): ITransaction {
  //     return {
  //   title: 'Payment In',
  //   amount: 300,
  //   reference: 'Game #153',
  //   status: 'Canceled',
  //   date: 'May 11, 2023, 08:30 AM CDT',
  // };
  //   }

  get fakeBalance(): IBalance {
    return {
      available: '11',
      payoutsDue: '22',
      pending: '33',
      current: '44',
      // lowBalance: true,
    };
  }

  get fakePaymentMethod(): IPaymentMethod {
    return {
      id: 'testId',
      last4: '4242',
      cardDto: { expiryDate: '0555', brand: 'visa' },
      // bankAccountDto: {},
      //     ==================
      //   expiryDate: '0555', // "0330"
      //   last4: '3333', // "4242"
      //   method: 'pm_1L14LsBIMZTJKM1cmR0q3ViF', // "pm_1L14LsBIMZTJKM1cmR0q3ViF"
      //   name: 'masterCard', // "visa" || "STRIPE TEST BANK"
      //   type: 'CARD', // from 2 version 'ACH' | 'CARD';
      //   // defaultMethod: '',
      //       =====================
      //   expiryDate: '0339', // "0330"
      //   last4: '4243', // "4242"
      //   method: 'pm_1L14LsBIMZTJKM1cmR0q3ViF__BANK1', // "pm_1L14LsBIMZTJKM1cmR0q3ViF"
      //   name: 'webMoney', // "visa" || "STRIPE TEST BANK"
      //   type: 'ACH', // from 2 version 'ACH' | 'CARD';
      //   // defaultMethod: '',
      //       ==================
      //   expiryDate: '0557', // "0330"
      //   last4: '3334', // "4242"
      //   method: 'pm_1L14LsBIMZTJKM1cmR0q3ViF___BANK2', // "pm_1L14LsBIMZTJKM1cmR0q3ViF"
      //   name: 'payPal', // "visa" || "STRIPE TEST BANK"
      //   type: 'ACH', // from 2 version 'ACH' | 'CARD';
      //   // defaultMethod: '',
    };
  }

  get fakeAttendance(): ClassAttendance {
    return {
      official: this.fakeUser,
      participated: false,
      read: false,
      reporter: false,
      switched: false,
    };
  }

  get fakeReport(): ClassReport {
    return new ClassReport({
      id: 'fakeIdReport',
      // date: '11 Sep 2023 1:00 AM PDT',
      payment: 'Notch Pay',
      status: 'DUE',
      game: this.fakeGame,
      // competition: fakeCompetition,
      attendance: [this.fakeAttendance, this.fakeAttendance],
      gameIsCompleted: false,
      // adjustmentStatus: 'TEAM_FORFEIT_OFFICIALS_ON_SITE',
      // adjustmentStatus: 'ASSIGNOR_FEE_2_3',
    });
  }

  get fakeTransferModel(): ClassTransfer {
    this.amount += 1;
    return new ClassTransfer({
      'id': 'id_' + this.amount,
      'competitionId': 'competitionId',
      'competitionName': 'competitionName',
      'gameId': 'gameId',
      'level': 'level',
      'gameAgeDescription': 'gameAgeDescription',
      'gender': 'CO-ED',
      status: 'CANCELLED',
      // 'statusFormatted': '',
      // 'type': '',
      'createdAt': this.amount == 2 || this.amount == 3 ? '5 September 2024 12:00 AM PDT' : this.amount + ' September 2024 12:00 AM PDT',
      // 'finalizedAt': '',
      'total': 100 + (this.amount * 100),
      'officialPositionName': 'officialPositionName',
      'gameNumber': 1 + this.amount,
      // 'createdAtFormatted': '',
      // 'errorMessage': '',
    });
  }

  get fakeAdjustmentStatus(): ClassAdjustmentStatus {
    this.amount += 1;
    return new ClassAdjustmentStatus({
      active: true,
      assignorFee: 77 + this.amount,
      id: 'ID-fakeAdjustmentStatus-' + this.amount,
      name: 'fakeAdjustmentStatus-' + this.amount,
      officialPay: 7 + this.amount,
    });
  }

  // get fake(): Class {
  //   return new Class({
  //   });
  // }

  // get fake(): Class {
  //   return new Class({
  //   });
  // }

}

