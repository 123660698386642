import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountExternalModel } from '@models/account-external.model';
import { catchError, map, Observable, throwError } from 'rxjs';
import { MeService } from '@services/me.service';

@Injectable({
  providedIn: 'root',
})
export class AccountApiService {
  url = '/api/payments/v1/account';

  constructor(
    private http: HttpClient,
    private meS: MeService,
  ) {
  }

  getAccounts(userId: string): Observable<AccountExternalModel[]> {
    return this.http.get<AccountExternalModel[]>(`${this.url}/connect/external/all`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        userId,
      },
    })
      .pipe(
        map(data => data?.length ? data.map(it => new AccountExternalModel(it)) : []),
      );
  }

  fetchClientSecret(): Observable<string> {
    return this.http.get<{ clientSecret: string }>(`${this.url}/connect/session`, {
      headers: { 'Content-Type': 'application/json' }
    }).pipe(
      map(response => {
        if (response.clientSecret) {
          return response.clientSecret;
        } else {
          throw new Error('Client secret not found in response');
        }
      }),
      catchError(error => {
        console.error('Error fetching client secret:', error);
        return throwError(() => new Error('Failed to fetch client secret'));
      })
   );
  }

  // getBalance(userId: string): Observable<number> {
  getBalance(): Observable<number> {
    const userId = this.meS.meId;
    const options = { headers: { 'Content-Type': 'application/json' }, params: { userId } };
    return this.http.get<number>(`${this.url}/connect/balance`, options);
  }

  getBalanceInstant(): Observable<{ standardAvailable: { amount: number, currency: string }, instantAvailable: { amount: number, currency: string } }> {
    const userId = this.meS.meId;
    const options = { headers: { 'Content-Type': 'application/json' }, params: { userId } };
    return this.http.get<{ standardAvailable: { amount: number, currency: string }, instantAvailable: { amount: number, currency: string } }>(
      `${this.url}/connect/payoutBalances`, 
      options
    );
  }
  connectExternal(token: string): Observable<any> {
    return this.http.post(`${this.url}/connect/external`, { token }, {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'text',
    });
  }

  connectBank(accountNumber: string, routingNumber: string): Observable<any> {
    return this.http.post(`${this.url}/connect/external`, { accountNumber, routingNumber }, {
      headers: {
        'Content-Type': 'application/problem+json',
      },
        responseType: 'text' as 'json' 
    });
  }

  deleteExternal(userId: string, id: string): Observable<any> {
    return this.http.delete(`${this.url}/connect/external`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        userId,
        id,
      },
    });
  }

  setDefault(userId: string, id: string): Observable<any> {
    return this.http.put(`${this.url}/connect/external/default`, null, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        userId,
        id,
      },
    });
  }
}
