import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainService } from '@services/main.service';
import { CompetitionService } from '@app/dir_group_assignor/competitions/competition.service';
import { MeService } from '@services/me.service';
import { OtherService } from '@services/other.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { DeviceService } from '@services/device.service';
import { ClassGame } from '@app/dir_group_assignor/games/game';
import {
  arrGenderDrop,
  arrPayoutFormatDrop,
  ClassCompetition,
  ClassCompetitionAgeGroup,
  ClassCompetitionLevel,
  ClassCompetitionLocation,
  ClassCompetitionTeam,
  ClassPayoutFormat,
  TGameTypeDrop,
  TGenderDrop,
} from '@app/dir_group_assignor/competitions/ClassCompetition';
import { HelperClass } from '@classes/Helper-Classes';
import { BtnComponent } from '@components/btn/btn.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { LineComponent } from '@components/line/line.component';
import { SelectDateComponent } from '@components/date-time/select-date/select-date.component';
import { SelectTimeComponent } from '@components/date-time/select-time/select-time.component';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { CustomDatesService } from '@classes/CustomDates';
import { ChxComponent } from '@components/chx/chx.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ToggleComponent } from '@components/toggle/toggle.component';
import { IDataPopup, PopupService } from '@services/popup.service';
import { DateTimeComponent } from '@components/date-time/date-time/date-time.component';
import { Router } from '@angular/router';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { ClassDrop, ClassDropForNameDefault } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize, from, of, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Median from "median-js-bridge";

interface IFormEditGame {
  id: FormControl<string>;
  competition: FormControl<ClassCompetition>;
  gameNumber: FormControl<string>;
  payoutFormatDrop: FormControl<ClassPayoutFormat>;
  location: FormControl<ClassCompetitionLocation>;
  date: FormControl<string>;
  genderDrop: FormControl<TGenderDrop>;
  ageGroup: FormControl<ClassCompetitionAgeGroup>;
  levels: FormControl<ClassCompetitionLevel>;
  homeTeam: FormControl<ClassCompetitionTeam>;
  awayTeam: FormControl<ClassCompetitionTeam>;

  duration: FormControl<number | string>;
  officialAssignments: FormControl<boolean>;

  gameTypeDrop: FormControl<TGameTypeDrop>;
  gameDescription: FormControl<string>;

  courtNamesDrop: FormControl<Array<ClassDrop>>; // andrei переделать. Сейчас достаю из game.location.courtNames
  currentCourtNameDrop: FormControl<ClassDrop | null>; // andrei переделать. Сейчас достаю из game.location.currentCourtName
}

@UntilDestroy()
@Component({
  selector: 'app-edit-game',
  standalone: true,
  imports: [CommonModule, BtnComponent, BtnWrapComponent, DropFormCtrlComponent, FieldComponent, FormsModule, HeadTitleComponent, InputCtrlComponent, LineComponent, ReactiveFormsModule, SelectDateComponent, SelectTimeComponent, ChxComponent, MatSlideToggleModule, ToggleComponent, DateTimeComponent, DropdownComponent],
  templateUrl: './edit-game.component.html',
  styleUrls: ['./edit-game.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditGameComponent extends HelperClass implements OnInit {
  @ViewChild('dateTimeRef') dateTimeRef?: DateTimeComponent;
  form!: FormGroup<IFormEditGame>;
  game?: ClassGame;
  isClone = false;
  isRunningOnMobileApp: boolean = false; 

  readonly arrGenderDrop = arrGenderDrop;
  readonly arrPayoutFormatDrop = arrPayoutFormatDrop;

  constructor(
    public mainS: MainService,
    public competitionS: CompetitionService,
    public meS: MeService,
    public otherS: OtherService,
    public dialogRef: MatDialogRef<EditGameComponent>,
    private formBuilder: UntypedFormBuilder,
    public deviceS: DeviceService,
    public gameS: GameService,
    public customDatesS: CustomDatesService,
    public router: Router,
    public cd: ChangeDetectorRef,
    public popupS: PopupService,
    @Inject(MAT_DIALOG_DATA) public dataPopup?: IDataPopup,
  ) {
    super(cd);
    this.game = new ClassGame(dataPopup?.game);
    this.isClone = !!dataPopup?.isClone;
    this.getArrCompetition();
    this.getArrDataForDropDownByCurrentCompetition();
  }

  ngOnInit() {
    this.createForm();
    if (Median.isNativeApp()) {
      document.querySelectorAll('.mdc-dialog__container').forEach(e => {
        e.classList.add('mobile-app');
      });
      this.isRunningOnMobileApp = true;
    }
  }

  getArrCompetition(): void {
    this.gameS.getArrCompetition().pipe(untilDestroyed(this)).subscribe((res) => {
    });
  }

  // !!! получить список для выпадающих списков для дропдаунов из текущего выбраного компетишна
  // !!! тоесть получаем с сервера компетишн по текущему id из компетишна.
  // !!! это нужно, потому что в объекте компетишна которые находится в объекте игры НЕТ этих массивов для выпадающих списков (я имею ввиду нет ageGroups, levels и т.д)
  getArrDataForDropDownByCurrentCompetition(): void {
    this.gameS.getCompetition(this.game?.competition, this.game).toPromise().then((res?: ClassCompetition) => {
    });
    if (!this.dataPopup?.game?.competition?.id) {
      this.ctrlReset([this.ctrl.gameTypeDrop, this.ctrl.location, this.ctrl.ageGroup, this.ctrl.levels, this.ctrl.homeTeam, this.ctrl.awayTeam], true);
    }
  }

  submit(): void {
    this.form.markAllAsTouched();
    const sendObj: ClassGame = { ...this.dataPopup?.game, ...this.form.getRawValue() };
    sendObj.date = this.dateTimeRef?.dateForSendToServer!;

    if (this.dateTimeRef?.getDatePlusTime() &&
      this.dateTimeRef?.getDatePlusTime().setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) &&
      new Date(sendObj.date).setHours(0, 0, 0, 0) != new Date(this.game?.date ?? 0).setHours(0, 0, 0, 0)) {
      const observable = from(this.popupS.openPopupConfirm({
        textTitle: `Are you sure you want to set the date for this game in the past?`,
        text: `By saving these changes, the system will generate a post game report and notify assigned official #1.`,
        textBtnApply: 'Confirm',
        swapBtn: false,
      }));

      observable.pipe(
        switchMap((res) => {
          if (res) this.changeGame(sendObj);
          this.endRequest();
          return of(null);
        }),
        finalize(() => this.endRequest()),
        catchError((err) => {
          this.endRequest();
          return of(err);
        }),
        untilDestroyed(this),
      )
        .subscribe((res) => {
          this.cd.detectChanges();
        });
    } else {
      this.changeGame(sendObj);
    }
  }

  changeGame(sendObj: ClassGame): void {
    if (sendObj.location) {  // andrei переделать. Сейчас достаю из game.location.courtNames
      sendObj.location.currentCourtNameDrop = this.ctrl.currentCourtNameDrop?.value!;
      // @ts-ignore
      delete sendObj.courtNamesDrop; // andrei переделать. Сейчас достаю из game.location.courtNames . и поэтому приходится удалять здесь
    }

    if (!sendObj?.id) console.error('EditGameComponent submit() sendObj?.id :', sendObj?.id, sendObj);
    if (this.startRequest()) return;
    // // !!! если isClone то при клонировании id игры НЕ отправлять и запрос должен быть POST - для создания игры
    this.mainS.methodGame(sendObj, this.isClone ? 'post' : 'put', this.isClone).toPromise()
      .then((res?: ClassGame) => {
        if (!res) console.error('EditGameComponent :', this.isClone ? ' CLONE game' : ' EDIT game', this.isClone ? 'post' : 'put', ' NO HAVE RESPONSE', res);
        this.dialogRef.close(res);
      })
      .catch(() => {
      })
      .finally(() => this.endRequest());
  }

  // === LOCATION =============
  changeLocation(competitionLocation: ClassCompetitionLocation): void {
    this.ctrl.courtNamesDrop?.patchValue([]);
    this.ctrl.currentCourtNameDrop?.patchValue(null);
    this.ctrl.currentCourtNameDrop?.enable();
    this.ctrl.courtNamesDrop?.patchValue(competitionLocation?.courtNamesDrop || []);
    this.cd.detectChanges();
  }

  changeCompetition(competition: ClassCompetition): void {
    this.ctrl.courtNamesDrop?.patchValue([]);
    this.ctrl.currentCourtNameDrop?.patchValue(null);
    this.ctrl.currentCourtNameDrop?.enable();

    this.gameS.changeValCompetition(competition).toPromise().then((res) => {
      const defaultLevel = res?.levels?.find(level => level.level == 'ALL');
      this.ctrl.levels?.patchValue(new ClassDropForNameDefault(defaultLevel?.id));
      this.cd.detectChanges();
    });
    this.ctrlReset([this.ctrl.gameTypeDrop, this.ctrl.location, this.ctrl.ageGroup, this.ctrl.levels, this.ctrl.homeTeam, this.ctrl.awayTeam], true);
    this.ctrl.competition?.patchValue(competition);
    this.cd.detectChanges();
  }

  // === FORM ==============
  private createForm(): void {
    const gameNumberValue = this.game?.gameStatus !== 'CANCELLED' && this.isClone ? '' : this.game?.gameNumber;
    this.form = this.formBuilder.group({
      id: [this.game?.id],
      competition: [this.game?.competition, [Validators.required]],
      gameNumber: [{ value: gameNumberValue, disabled: this.game?.gameStatus == 'CANCELLED' }],
      payoutFormatDrop: [{ value: '', disabled: true }],
      location: [this.game?.location, [Validators.required]],
      date: [this.isClone ? '' : this.game?.date], // !!! при клонировании дату сбрасывать  [Validators.required]
      genderDrop: [this.game?.genderDrop, [Validators.required]],
      ageGroup: [this.game?.ageGroup, [Validators.required]],
      levels: [this.game?.levels, [Validators.required]],
      homeTeam: [this.game?.homeTeam, [Validators.required]],
      awayTeam: [this.game?.awayTeam, [Validators.required]],

      duration: [{ value: this.game?.duration, disabled: true }, [Validators.required]],
      officialAssignments: [typeof this.game?.officialAssignments == 'boolean' ? this.game?.officialAssignments : true],

      gameDescription: [this.game?.gameDescription],
      gameTypeDrop: [this.game?.gameTypeDrop],

      courtNamesDrop: [this.game?.location?.courtNamesDrop],
      currentCourtNameDrop: [{
        value: this.game?.location?.currentCourtNameDrop,
        disabled: !this.game?.location?.id,
      }, [Validators.required]],
    });
    this.cd.detectChanges();
  }

  get ctrl(): IFormEditGame {
    return this.form?.controls;
  }

  close(): void {
    this.dialogRef.close();
  }

}

// matOptionEmit(): void {
//   if (this.ctrl.competition?.value?.id) {
//   this.close();
//   this.router.navigate([`competitions/about/${this.ctrl.competition?.value?.id}`]);
// }
// }
