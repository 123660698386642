import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '@services/utils.service';

@Pipe({ name: 'isLessAge18', standalone: true })
export class IsLessAge18Pipe implements PipeTransform {

  // !!! dateFromServer == 2023-08-17
  // !!! если меньше 18 лет, то вернется true
  transform(dateFromServer: string): boolean | null {
    return UtilsService.isLessAge18(dateFromServer);
  }

}
