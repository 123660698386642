<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
            } as obj'
     class='wrapperMeProfileOfficialQuestions'>

  <div *ngIf='obj.desktop' class='headerContact'>
    <headTitle [text]='isEdit ? "Edit Questions":"Questions"' typeText='text1-blueDark' svgLeft='arrow_squareLeft&24'
               (svgLeftEmit)='otherS.locationBack()'></headTitle>
    <ng-container [ngTemplateOutlet]='actionsTemplate'></ng-container>
  </div>

  <titleAndNavigationForProfile *ngIf='obj.mobile && !isEdit'
                                [typePageProfile]='meProfileOfficialS.typePageProfile'></titleAndNavigationForProfile>

  <div *ngIf='!isEdit' class='contentContact' [class.contentContact--desktop]='obj.desktop'>
    <contactItem text1='Years of experience' [text2]='"154"'></contactItem>
    <contactItem text1='Certification(s)' [text2]='"Fri, Jan 6, 2023"'></contactItem>
    <contactItem text1='USSF ID' [text2]='"12-345-6789"'></contactItem>
    <contactItem text1='Center Referee Preference' [text2]='"Any level"'></contactItem>
    <contactItem text1='Assistant Referee Preference' [text2]='"Any level"'></contactItem>
    <contactItem text1='Assistant Referee Only?' [text2]='"No"'></contactItem>
    <contactItem text1='Competitions' [text2]='"All"'></contactItem>
    <contactItem text1='Schedule Notes' [text2]='"Available after 5 PM"'></contactItem>
    <contactItem text1='Reviewed Payment Terms?' [text2]='"Yes"'></contactItem>
  </div>

  <!--  === FOR EDIT ============================================-->
  <div *ngIf='isEdit' class='contentContact' [class.contentContact--desktop]='obj.desktop'>
    <!--    <fieldRow text='Years of Experience' class='o-itemEditContact o-pad-b-0'>-->
    <!--      <dropFormCtrl formControlName='certified' [listStr]='["Yes", "No"]'></dropFormCtrl>-->
    <!--    </fieldRow>-->
    <!--    <fieldRow text='2022 Certification' class='o-itemEditContact o-pad-b-0'>-->
    <!--      <div>-->
    <!--        <dropFormCtrl formControlName='certified' [listStr]='["Yes", "No"]'></dropFormCtrl>-->
    <!--        <p class='o-text8 additional-text'>Are you currently "APPROVED" on the Minnesota State Referee Committee website? If you are not-->
    <!--          certified and/or refereeing Lakeville in-house ONLY, select "No".</p>-->
    <!--      </div>-->
    <!--    </fieldRow>-->
    <!--    <fieldRow text='2022 Center Referee Preferences' class='o-itemEditContact o-pad-b-0'>-->
    <!--      <dropFormCtrl formControlName='certified' [listStr]='["Yes", "No"]'></dropFormCtrl>-->
    <!--    </fieldRow>-->
    <!--    <fieldRow text='2022 Assistant Referee Preferences' class='o-itemEditContact o-pad-b-0'>-->
    <!--      <dropFormCtrl formControlName='certified' [listStr]='["Yes", "No"]'></dropFormCtrl>-->
    <!--    </fieldRow>-->
    <!--    <fieldRow text='Assistant Referee Only?' class='o-itemEditContact o-pad-b-0'>-->
    <!--      <div>-->
    <!--        <dropFormCtrl formControlName='certified' [listStr]='["Yes", "No"]'></dropFormCtrl>-->
    <!--        <p class='o-text8 additional-text'>Do you only want to be assigned Assistant Referee positions?</p>-->
    <!--      </div>-->
    <!--    </fieldRow>-->
    <!--    <fieldRow text='Competitions' class='o-itemEditContact o-pad-b-0'>-->
    <!--      <dropFormCtrl formControlName='certified' [listStr]='["Yes", "No"]'></dropFormCtrl>-->
    <!--    </fieldRow>-->
  </div>

  <!--  === ACTIONS BUTTONS ===========================================-->
  <ng-template #actionsTemplate>
    <div class='headerContact__btns'>
      <btn *ngIf='!isEdit' text='Edit' bcg='blueDark' [btnWidth]='obj.mobile ? "100%":58' [btnHeight]='obj.mobile ? 36:""'
           (click)='isEdit=true' [disabled]='true'></btn>
      <btn *ngIf='isEdit' text='Cancel' bcg='white' [btnWidth]='obj.mobile ? "100%":89' (click)='isEdit=false'></btn>
      <btn *ngIf='isEdit' text='Save' bcg='blueDark' [btnWidth]='obj.mobile ? "100%":65' (click)='submit()'></btn>
    </div>
  </ng-template>
</div>
