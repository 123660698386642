// roundMiles.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundMiles',
  standalone: true,
  pure: false,
})
export class RoundMilesPipe implements PipeTransform {
  // transform(distance: string | undefined): number | undefined {
  //     if (distance !== undefined) {
  //         const roundedDown = Math.floor(+distance);
  //         const decimalPart = +distance - roundedDown;
  //
  //         return decimalPart < 0.5 ? roundedDown : Math.ceil(+distance);
  //     } else {
  //         return undefined;
  //     }
  // }

  transform(distance?: number): string {
    let result = '';
    if (typeof distance === 'number') {
      const roundedDown = Math.floor(+distance);
      const decimalPart = +distance - roundedDown;
      result = (decimalPart < 0.5 ? roundedDown : Math.ceil(+distance)).toString();
    }
    return result;
  }

}