<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              search: filtersS.findFilter("search"),
              dateRange: filtersS.findFilter("dateRange"),
              groupId: filtersS.findFilter("groupId"),
              competitions: filtersS.findFilter("competitions"),
              levels: filtersS.findFilter("levels"),
              ages: filtersS.findFilter("ages"),
              assignStatuses: filtersS.findFilter("assignStatuses"),
              distance: filtersS.findFilter("distance"),
              zipcode: filtersS.findFilter("zipcode"),
              activeCompetitions: filtersS.findFilter("activeCompetitions"),
              competitionPreferences: filtersS.findFilter("competitionPreferences"),
              location: filtersS.findFilter("location"),
              officialList: filtersS.findFilter("officialList"),
              officialStatus: filtersS.findFilter("officialStatus"),
              seasons: filtersS.findFilter("seasons"),
              payoutFormat: filtersS.findFilter("payoutFormat"),
              showFilters_forMobile: (filtersS.showFilters_forMobile$|async),
            } as obj' class='wrapperFilters' [formGroup]='form'>
  <div *ngIf='obj.mobile && obj.showFilters_forMobile' class='wrapperFilters__bcgForMobile'></div>

  <!--  *ngIf='obj.desktop || obj.showFilters_forMobile'-->
  <div class='wrapperFilters__content' [class.hiddenFilters]='!obj.desktop && !obj.showFilters_forMobile'>
    <headTitle *ngIf='obj.mobile' text='Filter' svgRight='cross_grey&24' typeText='text4-blueDark'
      (svgRightEmit)='filtersS.showFilters_forMobile$.next(false)'></headTitle>

    <!--  === TypeFilter_string ===================================================================================-->
    <!--=== search =====================================-->
    <!--        <ng-template #filtersTemplate_searchRef>-->
    <!--          <inputCtrl *ngIf='obj.search?.typeFilter == "search" && obj.search.visible' formControlName='search' placeholder='Search'-->
    <!--                     svgPrefix='search_grey&20'-->
    <!--                     [w]='obj.desktop ? 270:"100%"' (changeVal)='changeFilter_TypeFilter_string(obj.search?.typeFilter!,$event)'-->
    <!--                     forFilterItem [filter]='obj.search'-->
    <!--                     [class.hiddenFilters]='obj.showFilters_forMobile' [isFocus]='true'-->
    <!--                     [matTooltip]='""|getMatTooltipForSearch'-->
    <!--                     class='ngContent_trigger'-->
    <!--          ></inputCtrl>-->
    <!--        </ng-template>-->
    <ng-template #filtersTemplate_searchRef>
      <ng-container *ngIf='!(""|getMatTooltipForSearch)'>
        <inputCtrl *ngIf='obj.search?.typeFilter == "search" && obj.search.visible' formControlName='search'
          placeholder='Search' svgPrefix='search_grey&20' [w]='obj.desktop ? 270:"100%"'
          (changeVal)='changeFilter_TypeFilter_string(obj.search?.typeFilter!,$event)' forFilterItem
          [filter]='obj.search' [class.hiddenFilters]='obj.showFilters_forMobile' [isFocus]='!obj.mobile'></inputCtrl>
      </ng-container>

      <ng-container *ngIf='(""|getMatTooltipForSearch)'>
        <!--        forTest='search=>'-->
        <app-containerTooltip #containerTooltipRef position='bottomLeft' [width]='216'>
          <inputCtrl *ngIf='obj.search?.typeFilter == "search" && obj.search.visible' formControlName='search'
            placeholder='Search' svgPrefix='search_grey&20' [w]='obj.desktop ? 270:"100%"'
            (changeVal)='changeFilter_TypeFilter_string(obj.search?.typeFilter!,$event);containerTooltipRef.showTooltip=false'
            forFilterItem [filter]='obj.search' [class.hiddenFilters]='obj.showFilters_forMobile' [isFocus]='true'
            class='ngContent_trigger' (focus)="handleFocus()" [isFocus]='false' #inputField></inputCtrl>
          <tooltipSearch class='ngContent_tooltip' (emit)='containerTooltipRef.showTooltip=false'></tooltipSearch>
        </app-containerTooltip>
      </ng-container>
    </ng-template>
    <ng-container [ngTemplateOutlet]='filtersTemplate_searchRef'></ng-container>

    <!--=== zipcode =====================================-->
    <inputCtrl *ngIf='obj.zipcode?.typeFilter == "zipcode" && obj.zipcode.visible' formControlName='zipcode'
      placeholder='ZIP code' (changeVal)='changeFilter_TypeFilter_string(obj.zipcode?.typeFilter!,$event)' forFilterItem
      [filter]='obj.zipcode' [w]='obj.desktop ? 120:"100%"' [isNumber]='true'></inputCtrl>

    <!--  === TypeFilter_dateRange =================================================================================-->
    <app-dateRange *ngIf='obj.dateRange?.typeFilter == "dateRange" && obj.dateRange.visible' formControlName='dateRange'
      (emit)='changeFilter_TypeFilter_dateRange($event)' [showAlwaysPlaceholder]='showAlwaysPlaceholder' forFilterItem
      [filter]='obj.dateRange' [width]='obj.desktop ? 175:"100%"'></app-dateRange>

    <!--  === TypeFilter_drop =======================================================================================-->
    <!--=== groupId =====================================-->
    <app-dropdown *ngIf='obj.groupId?.typeFilter == "groupId" && obj.groupId.visible' formControlName='groupId'
      placeholder='Group' [array]='obj.groupId?.arrayForDropdown!'
      (changeVal)='changeFilter_TypeFilter_drop(obj.groupId?.typeFilter!,$event)' [multi]='!!obj.groupId?.multi'
      [showAlwaysPlaceholder]='showAlwaysPlaceholder' forFilterItem [filter]='obj.groupId'
      [width]='obj.desktop ? 102:"100%"'></app-dropdown>

    <!--=== competition  =====================================-->
    <!--    [width]='obj.desktop ? 143:"100%"' -->
    <app-dropdown *ngIf='obj.competitions?.typeFilter == "competitions" && obj.competitions.visible'
      formControlName='competitions' [placeholder]='selectedCompetition || "Competition"'
      [array]='obj.competitions?.arrayForDropdown!'
      (changeVal)='changeFilter_TypeFilter_drop(obj.competitions?.typeFilter!,$event)'
      [multi]='!!obj.competitions?.multi' [showAlwaysPlaceholder]='showAlwaysPlaceholder' forFilterItem
      [filter]='obj.competitions' [width]='obj.competitions?.svgPrefix ? "" : (obj.desktop ? 143:"100%")'
      [svgPrefix]='obj.competitions?.svgPrefix'></app-dropdown>

    <!--=== statusAssign  =====================================-->
    <app-dropdown *ngIf='obj.assignStatuses?.typeFilter == "assignStatuses" && obj.assignStatuses.visible'
      formControlName='assignStatuses' placeholder='Assignment Status' [array]='obj.assignStatuses?.arrayForDropdown!'
      (changeVal)='changeFilter_TypeFilter_drop(obj.assignStatuses?.typeFilter!,$event)'
      [multi]='!!obj.assignStatuses?.multi' [showAlwaysPlaceholder]='showAlwaysPlaceholder' forFilterItem
      [filter]='obj.assignStatuses' [width]='obj.desktop ? 184:"100%"'></app-dropdown>

    <!--=== distance =====================================-->
    <app-dropdown *ngIf='obj.distance?.typeFilter == "distance" && obj.distance.visible' formControlName='distance'
      placeholder='Distance' [array]='distanceItems'
      (changeVal)='changeFilter_TypeFilter_drop(obj.distance?.typeFilter!,$event)' [multi]='!!obj.distance?.multi'
      [showAlwaysPlaceholder]='showAlwaysPlaceholder' forFilterItem [filter]='obj.distance'
      [width]='obj.desktop ? 119:"100%"' [needSortByName]='false'></app-dropdown>

    <!--=== ages =====================================-->
    <app-dropdown *ngIf='obj.ages?.typeFilter == "ages" && obj.ages.visible' formControlName='ages' placeholder='By Age'
      [array]='agesItems' (changeVal)='changeFilter_TypeFilter_drop(obj.ages?.typeFilter!,$event)'
      [multi]='!!obj.ages?.multi' [showAlwaysPlaceholder]='showAlwaysPlaceholder' forFilterItem [filter]='obj.ages'
      [width]='obj.desktop ? 107:"100%"' [needSortByName]='false'></app-dropdown>

    <!--    === activeCompetitions =====================================-->
    <app-dropdown *ngIf='obj.activeCompetitions?.typeFilter == "activeCompetitions" && obj.activeCompetitions.visible'
      formControlName='activeCompetitions' placeholder='Active Competitions'
      [array]='obj.activeCompetitions?.arrayForDropdown!'
      (changeVal)='changeFilter_TypeFilter_drop(obj.activeCompetitions?.typeFilter!,$event)'
      [multi]='!!obj.activeCompetitions?.multi' [showAlwaysPlaceholder]='showAlwaysPlaceholder' forFilterItem
      [filter]='obj.activeCompetitions' [width]='obj.desktop ? 195:"100%"'></app-dropdown>

    <!--    === competitionPreferences =====================================-->
    <app-dropdown
      *ngIf='obj.competitionPreferences?.typeFilter == "competitionPreferences" && obj.competitionPreferences.visible'
      formControlName='competitionPreferences' placeholder='Competition Preferences'
      [array]='obj.competitionPreferences?.arrayForDropdown!'
      (changeVal)='changeFilter_TypeFilter_drop(obj.competitionPreferences?.typeFilter!,$event)'
      [multi]='!!obj.competitionPreferences?.multi' [showAlwaysPlaceholder]='showAlwaysPlaceholder' forFilterItem
      [filter]='obj.competitionPreferences' [width]='obj.desktop ? 224:"100%"'></app-dropdown>

    <!--    === location =====================================-->
    <app-dropdown *ngIf='obj.location?.typeFilter == "location" && obj.location.visible' formControlName='location'
      placeholder='location' [array]='obj.location?.arrayForDropdown!'
      (changeVal)='changeFilter_TypeFilter_drop(obj.location?.typeFilter!,$event)' [multi]='!!obj.location?.multi'
      [showAlwaysPlaceholder]='showAlwaysPlaceholder' forFilterItem [filter]='obj.location'
      [width]='obj.desktop ? 118:"100%"'></app-dropdown>

    <!--    === officialList =====================================-->
    <app-dropdown *ngIf='obj.officialList?.typeFilter == "officialList" && obj.officialList.visible'
      formControlName='officialList' placeholder='My Lists' [array]='obj.officialList?.arrayForDropdown!'
      (changeVal)='changeFilter_TypeFilter_drop(obj.officialList?.typeFilter!,$event)'
      [multi]='!!obj.officialList?.multi' [showAlwaysPlaceholder]='showAlwaysPlaceholder' forFilterItem
      [filter]='obj.officialList' [width]='obj.desktop ? 112:"100%"' forTest=';kjlj'></app-dropdown>

    <!--    === officialStatus =====================================-->
    <app-dropdown *ngIf='obj.officialStatus?.typeFilter == "officialStatus" && obj.officialStatus.visible'
      formControlName='officialStatus' placeholder='Status' [array]='obj.officialStatus?.arrayForDropdown!'
      (changeVal)='changeFilter_TypeFilter_drop(obj.officialStatus?.typeFilter!,$event)'
      [multi]='!!obj.officialStatus?.multi' [showAlwaysPlaceholder]='showAlwaysPlaceholder' forFilterItem
      [filter]='obj.officialStatus' [width]='obj.desktop ? 103:"100%"'></app-dropdown>

    <!--    === seasons =====================================-->
    <app-dropdown *ngIf='obj.seasons?.typeFilter == "seasons" && obj.seasons.visible' formControlName='seasons'
      placeholder='By Season' [array]='obj.seasons?.arrayForDropdown!'
      (changeVal)='changeFilter_TypeFilter_drop(obj.seasons?.typeFilter!,$event)' [multi]='!!obj.seasons?.multi'
      [showAlwaysPlaceholder]='showAlwaysPlaceholder' forFilterItem [filter]='obj.seasons'
      [width]='obj.desktop ? 129:"100%"'></app-dropdown>

    <!--    === payoutFormat =====================================-->
    <!--    svgName='lightning_gold&20'-->
    <!--      [svgRightMatOption]='"lightning_gold&20"' -->
    <!--    [array]='obj.payoutFormat?.arrayForDropdown!'-->
    <app-dropdown *ngIf='obj.payoutFormat?.typeFilter == "payoutFormat" && obj.payoutFormat.visible'
      formControlName='payoutFormat' placeholder='Payout Format' [array]='arrPayoutFormatDrop'
      (changeVal)='changeFilter_TypeFilter_drop(obj.payoutFormat?.typeFilter!,$event)'
      [multi]='!!obj.payoutFormat?.multi' [showAlwaysPlaceholder]='showAlwaysPlaceholder' forFilterItem
      [filter]='obj.payoutFormat' [width]='obj.desktop ? 158:"100%"'></app-dropdown>

    <!--=== levels пока что нету нигде  =====================================-->
    <!-- [disabled]='!selectedCompetition || selectedCompetition.id === "ALL"'-->
    <!--    <app-dropdown *ngIf='obj.levels?.typeFilter == "levels"' formControlName='levels' placeholder='Level'-->
    <!--                  [array]='obj.levels?.arrayForDropdown!'-->
    <!--                  (changeVal)='changeFilter_TypeFilter_drop(obj.levels?.typeFilter!,$event)'-->
    <!--                  [showAlwaysPlaceholder]='showAlwaysPlaceholder' forFilterItem [filter]='obj.levels'></app-dropdown>-->

    <!--    === sortBy =====================================-->
    <!--    (emitSettings)='emitSortBy($event)'-->
    <sortBy *ngIf='obj.mobile && filtersS.sortBy_forMobile' class='o-order-9'></sortBy>

    <app-filtersSelectedIcon [filterText]="''" *ngIf='obj.desktop && showElement'
      class='o-order-10'></app-filtersSelectedIcon>

    <btnWrap *ngIf='obj.mobile' type='col' class='o-order-10'>
      <btn text='Apply Filters' bcg='blueDark' (emit)='filtersS.showFilters_forMobile$.next(false)'></btn>
      <btn text='Reset Filters' bcg='white' (emit)='onResetFilters()'></btn>
    </btnWrap>
  </div>
</div>