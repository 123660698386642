import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IncludesPipe } from '@pipes/includes.pipe';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { selfRequestErrText } from '@services/other.service';
import { AppComponent } from '@app/app.component';
import { IErrorResponse } from '@interceptors/main-handler-interceptor.service';
import { StopPropagationDirective } from '@directives/stop-propagation.directive';

// временно типизировал чтобы ничего не менять в уже существующем коде
export type TStatusSticker = 'success' | 'error' | 'blue';
export type TIconSticker = 'check-circle';

export interface ISticker {
  message?: string;
  status?: TStatusSticker;
  icon?: TIconSticker;
  tittleErr?: string;
  settings?: { status?: TStatusSticker; icon?: TIconSticker; };
  parentRef?: AppComponent;
  uniqueKey?: number;

  errorResponse?: IErrorResponse;

  forReportApprove?: boolean; // for api/core/gameReport/v1/approve нужно показывать другой контент для нотификации
}

@Component({
  selector: 'app-sticker',
  templateUrl: './sticker.component.html',
  styleUrls: ['./sticker.component.scss'],
  standalone: true,
  imports: [CommonModule, IncludesPipe, SvgComponent, StopPropagationDirective],
})
export class StickerComponent implements OnInit {
  message: string = '';
  status: TStatusSticker = 'success';
  icon: TIconSticker = 'check-circle';
  settings: ISticker = {};
  tittleErr: string = 'Oops!';
  errorResponse?: IErrorResponse;
  // show_errorDetails = false;

  setTimeoutLink: any;
  timeForNotification = this.settings?.status === 'success' ? 1000 : 5000;

  forReportApprove: boolean = false; // for api/core/gameReport/v1/approve нужно показывать другой контент для нотификации

  ngOnInit() {
    if (this.message === selfRequestErrText) {
      this.tittleErr = 'Request limit reached:';
    }
    this.setTimeoutLink = setTimeout(() => {
      this.settings.parentRef!.removeComponent(this.settings.uniqueKey!);
    }, this.timeForNotification);
  }

  clearTimeoutLink(): void {
    clearTimeout(this.setTimeoutLink);
  }

  removeMe(): void {
    this.settings.parentRef!.removeComponent(this.settings.uniqueKey!);
  }
}
