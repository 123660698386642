import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ClassGame, ClassGameOfficial } from '@app/dir_group_assignor/games/game';
import { TransferModel } from '@models/transfer.model';
import { ILocation } from '@models/location';
import { shortStates } from '@models/ShortStates';
import { IDatePeriod } from '@components/__drop_inputs_matSelect/date-range/dateRange';
import { IObject } from '@classes/other';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IQueryParams_forRegistration } from '@app/auth/auth_models';
import { ClassUser, TUserRoleUpperCase, TypeLastAvailability } from '@models/user';
import { const_NA, TypeNA } from '@models/other';
import { ClassDrop, defaultNameMatOption } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { IAvailabilitySameDate } from '@app/dir_group_assignor/assign/models/availability-same-date.model';
import { IResponse } from '@models/response-and-request';
import { ISvgOfficialAvailability } from '@app/dir_group_assignor/assign/pipes/get-svg-official-availability.pipe';
import { arrTypeStringCaseNoAddCase, TypeStringCase } from '@services/lodash.service';
import { ITimezone } from '@classes/geo';
import { fullListTimezone, shortListTimezone } from '@models/fullListTimezone';
import { TSvgName } from '@components/__svg_img/svg/forSvg';
import * as lodash from 'lodash';

@Injectable({ providedIn: 'root' })
export class UtilsService {
  showError(message: any) {
    throw new Error('Method not implemented.');
  }

  static datePipe = new DatePipe('en-US');
  constructor() { }

  // !!! с сервера приходит в таком формате "30 Nov 2023 12:00 AM PST" . Этот метод возвращает таймзону (например PST)
  static getTimezone_abbrev(dateFromServer: string): string {
    if (!dateFromServer) return dateFromServer;
    const arr = dateFromServer?.split(' ');
    if (!arr?.length) {
      console.error('UtilsService.getTimezoneFromDateFromServer() :', dateFromServer, arr);
      return '';
    }
    const timezone_abbrev = arr[arr.length - 1];
    return timezone_abbrev;
  }

  static formattedLetters(text: string): string {
    return text.toLowerCase().split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
  }

  static isNumber(value: any): boolean {
    return !!value && typeof +value === 'number' && !isNaN(+value);
  }

  // return +X (XXX) XXX-XXXX // !!! работает только для телефонов у которых префикс из одной цифры
  static getFormatPhone(phone?: string): string {
    if (!phone) return '';
    let result = phone?.replace('+', '');
    const prefix = '+' + result[0];
    let nextThreeNumbers_1 = '(' + result.slice(1, 4) + ')';
    let nextThreeNumbers_2 = result.slice(4, 7);
    let lastNumbers = result.slice(7);
    result = `${prefix} ${nextThreeNumbers_1} ${nextThreeNumbers_2}-${lastNumbers}`;
    return result;
  }

  // === ORIGINAL PIPES FROM ANGULAR ========================
  static datePipe_transform(dateFromServer: string, format: string = 'EE, MMM d, yyy', timezone_abbrev: string = this.getTimezone_abbrev(dateFromServer)): string | null {
    if (!UtilsService.datePipe) return null;
    const result = UtilsService.datePipe?.transform(dateFromServer, format, timezone_abbrev);
    if (!result) {
      console.error('UtilsService.datePipe_transform() :', dateFromServer, '  timezone_abbrev:', timezone_abbrev, '  result', result);
      return '';
    }
    return result;
  }

  static currencyPipe_transform(value: number): string {
    // if (!UtilsService.currencyPipe) return null;
    // const result = UtilsService.currencyPipe?.transform(value);
    const result = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    if (!result) {
      console.error('UtilsService.currencyPipe_transform() :', value, '  result', result);
      return '';
    }
    return result;
  }

  // === ARRAY ==========================================
  // !!! если надо добавить объект в массив объектов, но объекты НЕ должны повторяться (сравнение происходит по id)
  // !!! id объязательно должен присутствовать в объектах
  static addInArrayUniqueObjects<T>(oldArrObj: Array<T>, newArrObj: Array<T>): Array<T> {
    if (!oldArrObj?.length && !newArrObj?.length) return [];
    if (!oldArrObj?.length) return newArrObj || [];
    if (!newArrObj?.length) return oldArrObj || [];
    const result: Array<T> = oldArrObj;
    newArrObj?.forEach(newObj => {
      // !!! если такого объекта ещё нету в result, то надо добавить
      if (!result.find(resultObj => (resultObj as { id?: string }).id === (newObj as { id?: string }).id)) result.push(newObj);
    });
    return result;
  }

  static arrayFromNumberIdx(number?: number): Array<number> { // return [0,1,2,3,4,5]
    if (typeof number !== 'number') return [];
    return Array.from(Array(number).keys());
  }

  static arrayFromNumber(number?: number): Array<number> { // return [1,2,3,4,5]
    if (typeof number !== 'number') return [];
    return Array.from(Array(number).keys()).map(el => el + 1);
  }

  // === OBJECT =============================================
  //   delete глубокое клонирование массива объектов и объекта
  static deepClone<T extends object>(data: T): T
  static deepClone<T extends object>(data: Array<T>): Array<T>
  static deepClone<T extends object>(data: Array<T> | T): Array<T> | T {
    // return JSON.parse(JSON.stringify(data));
    // return this.lodashS._.cloneDeep(data);
    return lodash.cloneDeep(data);
  }

  // !!! вернуть объект только с нужными ключами, которые передал в массиве ключей arrKeys
  // !!! example obj={a:1, b:2, c:3} arrKeys=['b'] => return {b:2}
  static getObjectByKeys<T extends object, K extends keyof T>(obj: T, arrKeys: Array<K>): T {
    const cloneObj = UtilsService.deepClone(obj) as T;
    const arrObject = arrKeys.map((key) => ({ [key]: cloneObj[key] }));
    const result = Object.assign({}, ...arrObject);
    return result;
  }

  // !!! The object must have an "id" !!!
  // !!! needCheck_length == true => Should the lengths of both arrays be considered? 
  //     (i.e., if the lengths are different, it returns false, meaning the arrays are NOT equal)
  // !!! Pass two arrays of objects
  // !!! If all values in the objects are the same, it returns true
  // !!! arrIncludesKeys: If an array of keys is provided, only those keys from arrKeys should be compared
  static compareTwoArrayObject<T extends object, K extends keyof T>(arr_1: Array<T>, arr_2: Array<T>, needCheck_length: boolean, arrIncludesKeys?: Array<K>): boolean {
    if (needCheck_length && arr_1?.length !== arr_2?.length) return false;

    let result = true;
    arr_1.forEach((el_1: any) => {
      const findElem_from_arr2 = arr_2.find((el_2: any) => el_1.id === el_2.id);
      if (!findElem_from_arr2) result = false; // !!! If one array contains an object that is not present in the other array, then the two arrays are different
      if (!result) return; // !!! If at least one element's objects are not equal, stop processing. Return false from this method.
      result = UtilsService.compareTwoObjects(el_1, findElem_from_arr2, arrIncludesKeys);
    });
    return result;
  }

  static sortArrayObjectByKey<T extends object, K extends keyof T>(arr: Array<T>, key: K): Array<T> {
    if (!arr?.length) return arr;
    return arr.sort((a: any, b: any) => a[key] - b[key]);
  }

  // !!! если все значения у объектов одинаковы, то возвращается true
  // !!! arrIncludesKeys если передал массив ключей, то нужно сравнивать только по эти ключам из массива arrKeys
  static compareTwoObjects<T extends object, K extends keyof T>(obj_1: T, obj_2: T, arrIncludesKeys?: Array<K>): boolean {
    // return JSON.stringify(obj_1) === JSON.stringify(obj_2);
    // return this.lodashS._.isEqual(obj_1, obj_2);
    let result = false;
    if (arrIncludesKeys?.length) {
      const obj1_onlyPropertiesNeed = UtilsService.getObjectByKeys(obj_1, arrIncludesKeys); // !!! объект только с нуэными property по ключам из массива arrKeys
      const obj2_onlyPropertiesNeed = UtilsService.getObjectByKeys(obj_2, arrIncludesKeys); // !!! объект только с нуэными property по ключам из массива arrKeys
      result = lodash.isEqual(obj1_onlyPropertiesNeed, obj2_onlyPropertiesNeed);
    } else {
      result = lodash.isEqual(obj_1, obj_2);
    }
    return result;
  }

  // заменить объекты в массиве. Поиск происходит по ID
  static replaceItemsInArrayById<T extends IObject>(arr: Array<T>, arrObjects: Array<T>, forTest?: string): Array<T> {
    if (forTest) {
      if (!arr) console.log('UtilsService.replaceItemsInArrayById arr:', forTest, arr);
      if (!arrObjects) console.log('UtilsService.replaceItemsInArrayById arrObjects:', forTest, arrObjects);
    }
    const result = [...arr];
    arrObjects.forEach((obj) => {
      const index = result.findIndex(el => el?.id == obj?.id);
      if (index != -1) result.splice(index, 1, obj);
    });
    return result;
  }

  // заменить объект в массиве. Поиск происходит по ID
  static replaceElemArrayById<T extends IObject>(arr: Array<T>, newObj: T): Array<T> {
    if (!arr?.length || !newObj?.id) return arr;
    let idx = arr?.findIndex(el => el.id == newObj.id);
    idx = idx >= 0 ? idx : 0;
    const result = [...arr];
    result[idx] = newObj;
    return result;
  }

  // заменить объект в массиве. Поиск происходит по Idx
  static replaceElemArrayByIdx<T extends object>(arr: Array<T>, newObj: T, idx: number): Array<T> {
    if (!arr?.length || !newObj) return arr;
    // const idx = arr.findIndex(el => el.id == newObj.id);
    const result = [...arr];
    result[idx] = newObj;
    return result;
  }

  // !!! чтобы НЕ менять ссылку на obj_1 и заменить все значения в obj_1 из obj_2
  static mergeObjects<T extends object>(obj_1: T, obj_2: T): T {
    Object.entries(obj_2).forEach((el) => {
      const key = el[0] as keyof T;
      obj_1[key] = el[1] as T[keyof T]; // as TValueObj<T>
    });
    return obj_1;
  }

  static deletePropertyFromObject<T extends object>(obj: T, arrKeys: Array<keyof T>): T {
    const result: T = UtilsService.deepClone(obj);
    arrKeys?.forEach((key) => {
      delete result[key];
    });
    return result;
  }

  // static isObject<T extends object>(obj?: T): boolean {
  static isObject(obj: any): boolean {
    if (Array.isArray(obj)) return false;
    return lodash.isObject(obj);
  }

  // проверка - пустой ли объект // если пустой => вернется true
  static isEmptyObj<T extends Object>(obj?: T): boolean {
    if (!obj) return true;
    for (let key in obj) {
      return false;
    }
    return true;
  }

  // !!! example => collection === [['a','aaa'], ['b', 'bbb']] => return {a: 'aaa', b: 'bbb'}
  static getObjectFromCollection(collection: Array<[string, any]>): Object | {} {
    if (!collection?.length) return {};
    return collection.reduce((akk, v) => ({ ...akk, [v[0]]: v[1] }), {});
  }

  // static mergeArrayObjects<T extends object>(arr_1: Array<T>, arr_2: Array<T>, key: keyof T): Array<T> {
  //   arr_1.map((el_1) => {
  //     const findObjFromArray_2 = arr_2.find(el_2 => el_2[key])
  //     return UtilsService.mergeObjects()
  //   });
  //   return obj_1
  // }

  // === DATE ===============================================
  // === эта функция отнимает разницу времени для таймзоны клиента.
  // Тоесть new Date() переводит дату. А если нужно не переводить дату, то нужно использовать эту функцию.
  // тоесть какая дата(string) в эту фунциию поступила, то такая же дата(Date) отсюда и вернется
  // static setOffset(date = new Date()): Date {
  static setOffset(dateString: string): Date {
    const myTZO = -180; //если нужен часовой пояс мск например
    let date = new Date(dateString);
    const localDate = new Date(date.getTime() + (60000 * (date.getTimezoneOffset() - myTZO)));
    return localDate;
  }

  static checkIsToday(date: string | Date): boolean {
    let d = new Date(date);
    const today = new Date();
    return d.getDate() === today.getDate() && d.getMonth() === today.getMonth() && d.getFullYear() === today.getFullYear();
  }

  // !!! input 2023-12-01 => return ММ/DD/YYYY
  static check_fromTo_formatted(datePeriod: IDatePeriod): string {
    if (!datePeriod) return '';
    const from: string = (datePeriod.from as string)?.split('T')[0];
    const to: string = (datePeriod.to as string)?.split('T')[0];
    let fromTo_formatted = '';

    const getFormatDateForViewInDropDown = (dateString: string): string => {
      if (!dateString) return '';
      const arr = dateString.split('-'); // ['2023', '12', '01']
      return `${arr[1]}/${arr[2]}/${arr[0]}`;
    };

    if (from) fromTo_formatted = getFormatDateForViewInDropDown(from as string);
    if (to) fromTo_formatted = fromTo_formatted + ' - ' + getFormatDateForViewInDropDown(to as string);
    return fromTo_formatted;
  }

  static compareDate(date_1: string | Date, date_2: string | Date): boolean {
    return new Date(date_1).getTime() > new Date(date_2).getTime();
  }

  // === GAME ==================================
  static getAgeGenderLevel(data?: ClassGame | TransferModel, type?: 'ClassGame' | 'TransferModel'): string {
    if (!data) return '';
    let result: string = '';
    if (type == 'ClassGame') {
      let game: ClassGame = data as ClassGame;
      const gameAgeDescription = game?.ageGroup?.gameAgeDescription || 'TBD';
      const gender = this.getFirstLetter(game?.gender) || 'TBD';
      const level = this.getLevelValue(game?.levels?.level, 1);
      // result = `${gameAgeDescription} - ${gender} - ${level}`;
      result = `${gameAgeDescription} - ${gender}`;
      // result = result + (level && level !== 'TBD' ? ` - ${level}` : ''); // !!! Миша сказал если левела нет или если левел равен TBD, то не показывать его
      result = result + (level ? ` - ${level}` : ''); // !!! Миша сказал если левела нет или если левел равен TBD, то не показывать его
    }
    if (type == 'TransferModel') {
      let transferModel: TransferModel = data as TransferModel;
      const gameAgeDescription = transferModel?.gameAgeDescription || 'TBD';
      const gender = this.getFirstLetter(transferModel?.gender) || 'TBD';
      const level = this.getLevelValue(transferModel?.level, 1);
      // result = `${gameAgeDescription} - ${gender} - ${level}`;
      result = `${gameAgeDescription} - ${gender}`;
      // result = result + (level && level !== 'TBD' ? ` - ${level}` : ''); // !!! Миша сказал если левела нет или если левел равен TBD, то не показывать его
      result = result + (level ? ` - ${level}` : ''); // !!! Миша сказал если левела нет или если левел равен TBD, то не показывать его
    }
    return result;
  }

  static getMatTooltipCancelReason(game?: ClassGame): string {
    if (game?.gameStatus !== 'CANCELLED') return '';
    return game.gameAdjustmentStatus?.cancelReason || 'No reason provided';
  }

  // variant 1. for list    if (N/A || ALL || TBD || '') => return ''
  // variant 2. for detail  if (N/A || ALL || TBD || '') => return N/A
  static getLevelValue(level: string | undefined, variant: 1 | 2): string {
    if (!level || level == const_NA || level == 'ALL' || level == 'TBD' || level == '') {
      const result: '' | TypeNA = variant == 1 ? '' : const_NA;
      return result;
    } else {
      return level;
    }
  }

  static getLocNameCourtName(game?: ClassGame): string {
    if (!game) return '';
    const locationName = game.location?.name || 'TBD';
    const currentCourtName = game.location?.currentCourtName ? `- ${game.location?.currentCourtName}` : '';
    return `${locationName}  ${currentCourtName}`;
  }

  static getLocationString(location?: ILocation, fields: Array<keyof ILocation> = ['street', 'city', 'state', 'zipcode']): string {
    if (!location) return '';
    const arrValues: Array<string> = [];
    fields.forEach(keyOfLoc => {
      const valueField: string = location[keyOfLoc]?.toString()?.trim() || '';
      if (keyOfLoc == 'state') {
        arrValues.push(this.getShortState(valueField));
        return;
      }
      if (valueField) arrValues.push(valueField);
    });
    return arrValues?.join(', ');
  }

  static getShortState(state?: string): string {
    if (!state) return '';
    let shortState = '';
    if (state in shortStates) {
      // @ts-ignore
      shortState = shortStates[state];
    } else {
      shortState = state;
    }
    return shortState;
  }

  // return {Location Name}, {Address Line 1}, {Address Line 2}, {City}, {State} {Zip Code}
  static getMatTooltipForLocation(game?: ClassGame): string {
    // if (!game?.location) return '';
    // let result = game.location?.name || '';
    // if (game.location?.address) {
    //   const { street, streetLine2, city, state, zipcode } = game.location?.address;
    //   result = result + `${street ? ', ' + street.trim() : ''}${streetLine2 ? ', ' + streetLine2.trim() : ''}${city ? ', ' + city.trim() : ''}${state ? ', ' + state.trim() : ''}${zipcode ? ', ' + zipcode.trim() : ''}`;
    // }
    // return result;
    if (!game?.location) return '';
    const locationName = game.location?.name || '';
    const courtName = game.location?.currentCourtName || '';
    // let result = game.location?.name || '';
    let result = `${locationName}${courtName ? ' - ' + courtName.trim() : ''}`;
    if (game.location?.address) {
      const { street, streetLine2, city, state, zipcode } = game.location?.address;
      result = result + `${street ? ', ' + street.trim() : ''}${streetLine2 ? ', ' + streetLine2.trim() : ''}${city ? ', ' + city.trim() : ''}${state ? ', ' + state.trim() : ''}${zipcode ? ', ' + zipcode.trim() : ''}`;
    }
    return result;
  }

  // === for GameOfficial ==================
  // !!! возвращаются GameOfficials в которых есть заасайненные судьи (используется для отображения в играх для админа)
  static getAssignGO(game?: ClassGame): Array<ClassGameOfficial> {
    return game?.gameOfficials?.filter((el) => el.official) || [];
  }

  // !!! возвращаются НЕзаасаненные GameOfficials (используется для отображения в играх для судьи)
  static getNoAssignGO(game?: ClassGame): Array<ClassGameOfficial> {
    return game?.gameOfficials?.filter((el) => !el.official) || [];
  }

  // !!! количество свободных(НЕзаасайненых ролей)
  static getAmountFreeRoles(game?: ClassGame): number {
    return UtilsService.getNoAssignGO(game)?.length;
  }

  // 'PENDING' // For official. Такого статуса нет, но нужно показывать PENDING если в GameOfficial selfAssigned==true & status=='UNACCEPTED'
  static isPendingGoForOfficial(go?: ClassGameOfficial): boolean {
    if (!go) return false;
    return !!go.selfAssigned && go.status == 'UNACCEPTED';
  }

  // !!! for official. Получить ClassGameOfficial в котором заасайнен
  static getMeGoFromGame(meId: string, game?: ClassGame): ClassGameOfficial | undefined {
    if (!game) return undefined;
    return game?.gameOfficials?.find((el) => el.official?.id == meId);
  }

  // !!! разрешить этого судью заассайнить на эту роль(go)
  // static isAllowAssign(game: ClassGame, go: ClassGameOfficial, official: ClassUser): boolean {
  //   if (game?.gameStatus === 'CLOSED') return false; // !!! 5.12.23 нужно запретить все действия если игра CLOSED
  //   const isUserAssignedInGame_andSavedInServer: boolean = UtilsService.isUserAssignedInGame_andSavedInServer(game, official?.id!); // !!! этот судья уже заасайнен в этой игре И сохранен на сервере
  //   const thisGoAlreadyAssignAndSaved: boolean = !!go.official && !!go.official_isSaveInServer; // !!! эта роль заасайнена в этой игре и сохранена на сервере
  //   return !(thisGoAlreadyAssignAndSaved || isUserAssignedInGame_andSavedInServer);
  // }

  // !!! сохранен ли заасайненый судья на сервере в этой роли(go)
  static isSaveInServer_officialAssign(go: ClassGameOfficial): boolean {
    // return !!go.official && !!go.official_isSaveInServer;
    return !!go.official && !!go.officialId_savedInServer;
  }

  // !!! заасайнен ли судья в текущую выбраную игру И сохранен на сервере
  static isUserAssignedInCurrentSelectedGame_andSavedInServer(game: ClassGame, idOfficial: string): boolean {
    if (!game) return false;
    return UtilsService.isUserAssignedInGame_andSavedInServer(game, idOfficial);
  }

  // !!! заасайнен ли судья в игру И сохранен на сервере
  static isUserAssignedInGame_andSavedInServer(game: ClassGame, idOfficial: string): boolean {
    if (!game) return false;
    // return !!game.gameOfficials?.some((go) => idOfficial && idOfficial === go.official?.id && go.official_isSaveInServer);
    return !!game.gameOfficials?.some((go) => idOfficial && idOfficial === go.official?.id && go.officialId_savedInServer);
  }

  // !!! заасайнен ли судья в игру И НЕ сохранен на сервере
  static isUserAssignedInGame_and_not_savedInServer(game: ClassGame, idOfficial: string): boolean {
    // return !!game.gameOfficials?.some((go) => idOfficial && idOfficial === go.official?.id && !go.official_isSaveInServer);
    return !!game.gameOfficials?.some((go) => idOfficial && idOfficial === go.official?.id && !go.officialId_savedInServer);
  }

  // === OfficialAvailability ===============================
  static text_for_lastAvailabilityReview = 'Official last reviewed availability: ';
  static text_for_lastAvailabilityUpdated = 'Official last updated availability: ';

  static getSvgOfficialAvailability(game: ClassGame, official: ClassUser): ISvgOfficialAvailability | null {
    const officialAssignedAndSaved = UtilsService.isUserAssignedInCurrentSelectedGame_andSavedInServer(game, official.id!);
    const svgNameForRedCross: TSvgName = 'circle_crossRed2&16';
    // let result: ISvgOfficialAvailability = userAssignedAndSaved ? {
    //   svgName: svgNameForRedCross,
    //   tooltip: 'Already assigned',
    //   textCenter: true,
    // } : {};
    let result: ISvgOfficialAvailability = { tooltip: UtilsService.get_arrayToMultiLine(official, officialAssignedAndSaved) };
    const availabilitySameDate = official.availabilityByGame?.availabilitySameDate;
    const availabilityStatus = official.availabilityByGame?.availabilityStatus;
    // const lastAvailabilityReview = official.lastAvailabilityReview;

    if (availabilityStatus === 'AVAILABLE') {
      result = {
        ...result,
        svgName: 'circle_chx2&16',
        // tooltip: this.get_title_div('Available') + this.get_div_lastAvailability(official),
        width: 68,
      };
      return result;
    }
    if (availabilitySameDate && availabilityStatus === 'ASSIGNED_TO_ANOTHER_GAME') {
      result = {
        ...result,
        svgName: 'circle_chx7&16', // оранжевый
        // tooltip: UtilsService.get_arrayToMultiLine(official),
        // isUpperCaseForFirstLetters: false,
        width: 263,
      };
      return result;
    }
    if (availabilityStatus === 'UNAVAILABLE' && !availabilitySameDate) {
      result = {
        ...result,
        svgName: svgNameForRedCross,
        // tooltip: this.get_title_div('Unavailable'),
        // textCenter: true,
        width: 82,
      };
      return result;
    }
    if (availabilitySameDate && availabilityStatus === 'UNAVAILABLE') {
      result = {
        ...result,
        svgName: svgNameForRedCross,
        // tooltip: UtilsService.get_arrayToMultiLine(official),
        // isUpperCaseForFirstLetters: false,
        width: 263,
      };
      return result;
    }
    if (availabilityStatus === 'NOT_SET') {
      result = {
        ...result,
        svgName: 'question_grey_filled&24',
        // tooltip: this.get_title_div('Official has not set their availability') + this.get_div_lastAvailability(official),
        // isUpperCaseForFirstLetters: false,
        width: 216,
      };
      return result;
    }
    return null;
  }

  // !!! этот говнокод писал Грант в пайпе. Я просто перенес этот гавнокод сюда. переписывать у меня нет времени.
  static get_arrayToMultiLine(official: ClassUser, officialAssignedAndSaved: boolean): string {
    const availabilitySameDate = official.availabilityByGame?.availabilitySameDate;
    // const availabilityStatus = official.availabilityByGame?.availabilityStatus;
    // const lastAvailabilityReview = official.lastAvailabilityReview;

    let result_div = this.get_title_div(official, officialAssignedAndSaved);

    if (officialAssignedAndSaved) {
      return result_div + this.get_div_lastAvailability(official);
    }

    // if (availabilitySameDate?.length) {
    availabilitySameDate?.forEach((el: IAvailabilitySameDate, idx: number) => {
      let separatedAddress = '';
      if (el?.gameAddress) {
        const gameAddressArr = el?.gameAddress.split(',');
        separatedAddress += gameAddressArr[0].trim() + ', ';
        separatedAddress += gameAddressArr[1].trim() + ', ';
        separatedAddress += gameAddressArr[2].trim() + '\n';
      }
      const time = el?.gameTime ? '<div><span class="dot">. </span>' + el.gameTime.trim() + ' - ' : '';
      const status = el?.gameAssignStatus ? el.gameAssignStatus.trim() + '</div>' : '';
      const competition = el?.competitionName ? '<div><span class="dot">. </span>' + el.competitionName.trim() + '</div>' : '';
      const group = el?.groupName ? '<div><span class="dot">. </span>' + el.groupName.trim() + '</div>' : '';
      const address = separatedAddress ? '<div><span class="dot">. </span>' + separatedAddress + '</div>' : '';
      const line = idx !== availabilitySameDate.length - 1 ? '<hr style="margin:10px 0">' : '';
      result_div = result_div + time + status + competition + address + group + line;
      // return result_div;
    });
    // .join('<hr style="margin:10px 0">');
    // return result;
    // } else {
    //   return 'Available but assigned to another game on the same day';
    // }
    return result_div + this.get_div_lastAvailability(official);
  }

  static get_div_lastAvailability(official: ClassUser): string {
    // const lastAvailabilityReview = official.lastAvailabilityReview;
    // const lastAvailabilityUpdated = official.lastAvailabilityUpdated;
    const lastDateAvailability = official.lastDateAvailability;
    let div_lastAvailability = '';

    if (lastDateAvailability) {
      const formattedDate_for_lastAvailability = this.get_formattedDate_for_lastAvailability(official, lastDateAvailability);
      let text = lastDateAvailability === 'lastAvailabilityReview' ? this.text_for_lastAvailabilityReview : this.text_for_lastAvailabilityUpdated;
      div_lastAvailability = '<div class="div_lastAvailability"><i class="material-icons">alarm</i>' + text + '<br>' + formattedDate_for_lastAvailability + '</div>';
    }
    return div_lastAvailability;
  }

  static get_formattedDate_for_lastAvailability(official: ClassUser, typeLastAvailability: TypeLastAvailability): string {
    if (!official) return '';
    const date_lastAvailability = official[typeLastAvailability];
    return (date_lastAvailability ? this.datePipe_transform(date_lastAvailability + 'Z', 'MMMM d, y, h:mm a')! : '');
    // if (!official) return '';
    // // const lastAvailabilityReview = official.lastAvailabilityReview;
    // const date_lastAvailability = official[typeLastAvailability];
    // let result = '';
    // if (date_lastAvailability) {
    //   if (!this.userTimezone()) {
    //     console.error('get_formattedDate_for_lastAvailability() UtilsService.userTimezone() :', this.userTimezone());
    //   }
    //   const timezone_abbrev = this.userTimezone()?.abbrev;
    //   if (!timezone_abbrev) {
    //     console.error('get_formattedDate_for_lastAvailability() timezone_abbrev :', timezone_abbrev);
    //   }
    //   result = date_lastAvailability ? this.datePipe_transform(date_lastAvailability + 'Z', 'MMMM d, y, h:mm a') + ' ' + timezone_abbrev : '';
    // }
    // return result;
  }

  // static get_title_div(title: string): string {
  //   return '<div class="title_div">' + title + '</div>';
  // }
  static get_title_div(official: ClassUser, officialAssignedAndSaved: boolean): string {
    const availabilitySameDate = official.availabilityByGame?.availabilitySameDate;
    const availabilityStatus = official.availabilityByGame?.availabilityStatus;
    // const lastAvailabilityReview = official.lastAvailabilityReview;
    let title = '';
    if (availabilityStatus === 'AVAILABLE') title = 'Available';
    // if (availabilitySameDate && availabilityStatus === 'ASSIGNED_TO_ANOTHER_GAME') title = ''
    if (availabilityStatus === 'UNAVAILABLE' && !availabilitySameDate) title = 'Unavailable';
    // if (availabilitySameDate && availabilityStatus === 'UNAVAILABLE') title = ''
    if (availabilityStatus === 'NOT_SET') title = 'Official has not set their availability';

    if (officialAssignedAndSaved) title = 'Already assigned';
    return title ? '<div class="title_div">' + title + '</div>' : '';
  }

  // !!! ONLY FOR GAMES => if arrContent$: Array<ClassGame> ==================
  // !!! arrExcludesIds => массив id которые не нужно изменять
  static resetSelectedGameOfficials(arrContent: Array<ClassGame>, arrExcludesIds?: Array<string>): Array<ClassGame> {
    if (!arrContent?.length) return [];
    // const updatedArrContent = arrContent;
    // updatedArrContent.forEach((game: ClassGame) => {
    //   // game.gameOfficials?.forEach((go) => go.isSelect = false);
    //   if (game.gameOfficials) game.gameOfficials = UtilsService.resetSelectedItems(game.gameOfficials);
    // });
    // return updatedArrContent
    return arrContent.map((game: ClassGame) => {
      return { ...game, gameOfficials: UtilsService.resetSelectedItems(game.gameOfficials!, arrExcludesIds) };
    });
  }

  // !!! arrExcludesIds => массив id которые не нужно изменять
  static resetSelectedItems<T>(arrContent: Array<T & { isSelect?: boolean; id?: string }>, arrExcludesIds?: Array<string>): Array<T> {
    if (!arrContent?.length) return [];
    // return arrContent.map((el) => ({ ...el, isSelect: false }));
    return arrContent.map((el) => {
      if (el.id && arrExcludesIds?.includes(el.id)) return el;
      return { ...el, isSelect: false };
    });
  }

  static getSelectedItems<T>(arrContent?: Array<T & { isSelect?: boolean }>): Array<T> {
    // if (!arrContent?.length) return [];
    return arrContent?.filter((el) => el.isSelect) || [];
  }

  // !!! если данную игру разрешено сделать CANCELLED то вернется true
  static isPossibleGameToCancel(game?: ClassGame): boolean {
    if (!game) return false;
    // 1 нет репорта и игра не кенселлед => return true
    // 2 нет репорта и игра кенселлед => return false
    // 3 есть репорт и игра не кенселлед => return false
    // 4 есть репорт и игра кенселлед => return false
    return !game.gameReport?.id && game.gameStatus !== 'CANCELLED';
  }

  // !!! разрешено ли менять fee. если разрешено, то вернется true
  static isPossibleEditFee(game?: ClassGame): boolean {
    if (!game) return false;

    if (game.gameStatus === 'CLOSED') return false; // запрещено

    if (game.gameStatus === 'CANCELLED') {
      if (game.gameAdjustmentStatus?.officialPay) {
        return game.gameReport?.status !== 'APPROVED';
      } else {
        return false;
      }
    } else { // разрешено если не CANCELLED
      return true;
    }
  }

  // !!! вернуть ТОЛЬКО те игры, которые можно сделать CANCELLED
  static getListGameForPossibleGameToCancelled(arrGame?: Array<ClassGame>): Array<ClassGame> {
    return arrGame?.filter(el => UtilsService.isPossibleGameToCancel(el)) || [];
  }

  // !!! если данную игру разрешено удалить то вернется true
  static isPossibleGameToDelete(game: ClassGame): boolean {
    if (game.gameReport?.id) return false; // !!! 11.06.24 Миша сказал добавить проверку на наличие репорта. Если репорт есть, то удалить нельзя
    let result = true;
    game?.gameOfficials?.forEach((el) => {
      if (!result) return; // !!! чтобы лишний раз не пробегать по массиву. Тоесть тут достаточно если хотя бы 1 судья заасайнен в игру
      if (el.official) result = false; // !!! если хотя бы 1 судья заасайнен в игру, то нельзя удалить игру
    });
    return result;
  }

  // !!! вернуть ТОЛЬКО те игры, которые можно удалить
  static getListGameForPossibleGameToDelete(arrGame?: Array<ClassGame>): Array<ClassGame> {
    return arrGame?.filter(el => UtilsService.isPossibleGameToDelete(el)) || [];
  }

  // === NUMBER ==========================================================
  // !!! удаление доллара из строки и перевод в number
  // !!! example str=='$15.30' return 15.30
  static getNumberFromStringWithDollar(stringWithDollar?: string | number): number | undefined {
    if (typeof stringWithDollar === 'number') {
      return stringWithDollar;
    } else if (typeof stringWithDollar === 'string') {
      const stringWithoutDollar = stringWithDollar?.trim()?.replace('$', '');
      if (typeof +stringWithoutDollar === 'number' && !!+stringWithoutDollar && +stringWithoutDollar !== 0) {
        return +stringWithoutDollar;
      } else {
        return undefined;
      }
    } else {
      return stringWithDollar;
    }
  }

  // === STRING ==========================================================
  // !!! addDashInsteadOfSpace = если надо добавить тире вместо пробела
  static getStrCase(str: string, type: TypeStringCase): string {
    const tempType = arrTypeStringCaseNoAddCase.includes(type) ? type : type + 'Case';
    if (type == 'capitalize') {
      // return this._.startCase(this._.toLower(str)) // remove спец символы
      return str.replace(/\w+/g, lodash.capitalize);
    }
    // @ts-ignore
    return lodash[tempType](str);
  }

  static strCase(string: any, type: TypeStringCase, addDashInsteadOfSpace = false): string {
    if (!string?.trim()) return '';
    string = string?.replace('_', ' ')?.replace('-', ' ');
    const result: string = UtilsService.getStrCase(string, type);
    if (addDashInsteadOfSpace) return result?.replace(' ', '-');
    else return result;
  }

  // !!! example str=='firstName' => return First name
  static capitalizeFirstLetterFromCamelCase(str: string): string {
    if (!str) return '';
    let lowerCase = UtilsService.strCase(str, 'lower');
    return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
  }

  // !!! example 'firstName' => 'FirstName' || 'first Name' => 'First Name' || 'first name' => 'First name'
  static capitalizeFirstLetter(str: string): string {
    if (!str) return '';
    // return UtilsService.lodash.capitalize(str)           // !!! example 'firstName' => 'Firstname' || 'first Name' => 'First name' || 'first name' => 'First name'
    return str.charAt(0).toUpperCase() + str.slice(1); // !!! example 'firstName' => 'FirstName' || 'first Name' => 'First Name' || 'first name' => 'First name'
  }

  // === OTHER ==============================================
  // !!! example => 'Monica Jon'|getFirstLetter => 'MJ'
  // !!! amountLetters сколько букв взять из слова
  static getFirstLetter(value?: string, amountLetters = 1, forTest?: string): string {
    if (!value) return '';
    let result = '';

    value?.trim()?.split(' ')?.filter(Boolean)?.forEach((el: string, i: number) => {
      if (i > (amountLetters - 1)) return; // чтобы максимум 2 буквы показывать в иконке
      result += el[0]?.toUpperCase();
    });
    return result;
  }

  // !!! этот метод для того чтобы апдейтить объект НЕ изменяя ссылку на него
  // !!! добавляет property которых нет в исходном объекте. Тоесть существующие property в исходном объекте НЕ изменяются
  // !!! example obj=={a:1} objNew=={a:2,b:3} => в итоге obj=={a:1,b:3}
  static addOnlyNewKeys_withoutReplaceKeysObject<T extends object>(obj: T | undefined | null, objNew: T): void {
    if (!obj || !objNew) return;
    Object.entries(obj).forEach((el_obj_1) => {
      // const key_obj_1 = el_obj_1[0] as keyof T;
      Object.entries(objNew).forEach((el_obj_2) => {
        const key_obj_2 = el_obj_2[0] as keyof T;
        // if (key_obj_1 === key_obj_2) {
        if (key_obj_2 in obj) {
          return; // существующие property в исходном объекте НЕ изменяются
        } else {
          obj[key_obj_2] = el_obj_2[1]; // !!! добавление новых property которых нет в изначальном объекте obj
        }
      });
    });
  }

  // !!! этот метод для того чтобы апдейтить объект НЕ изменяя ссылку на него
  // !!! objNew => здесь передать property которые нужно заменить
  static replaceKeysObject<T extends object>(obj: T | undefined | null, objNew: T): void {
    if (!obj || !objNew) return;
    const returnedTarget = Object.assign(obj, objNew);
    // Object.entries(obj).forEach((el_obj_1) => {
    //   const key_obj_1 = el_obj_1[0] as keyof T;
    //   Object.entries(objNew).forEach((el_obj_2) => {
    //     const key_obj_2 = el_obj_2[0] as keyof T;
    //     if (key_obj_1 === key_obj_2) {
    //       // @ts-ignore
    //       obj[key_obj_1] = el_obj_2[1]; // !!! замена property которые есть в изначальном объекте obj и в новом объекте objNew
    //     } else {
    //       obj[key_obj_2] = el_obj_2[1]; // !!! добавление новых property которых нет в изначальном объекте obj
    //     }
    //   });
    // });
  }

  // !!! удаление всех полей из объекта.
  // !!! это нужно чтобы НЕ менять ссылку на объект, но нужно просто очистить его
  // !!! objNew => это если нужно добавить новые поля в объект
  static removeAllKeysFromObject<T extends object>(obj: T, objNew?: T): void {
    if (!obj) return;
    Object.entries(obj).forEach((el) => {
      const key = el[0] as keyof T;
      delete obj[key];
    });
    if (!objNew) return;
    Object.entries(objNew).forEach((el) => {
      const key = el[0] as keyof T;
      obj[key] = el[1];
    });
  }

  // удаление пустых property из объекта перед отправкой на сервер
  // static removeEmptyKeysFromObject<T extends object>(obj: T, delete_defaultNameMatOption = true, forTest?: string): T {
  static removeEmptyKeysFromObject<T extends object>(obj: T, forTest?: string): T {
    // if (!obj) return obj;
    // return Object.entries(obj)
    //   .filter(el => {
    //     if (delete_defaultNameMatOption) return el[1] !== defaultNameMatOption;
    //     else return el;
    //   }) // delete ALL defaultNameMatOption
    //   .reduce((acc, [k, v]) => v || v === 0 || typeof v === 'boolean' ? { ...acc, [k]: v } : acc, {}) as T;

    // if (forTest && !obj) console.log('removeEmptyKeysFromObject no have obj:', obj);
    // if (!obj) return obj;
    // const result = Object.entries(obj).reduce((acc, [k, v]) =>
    //   (!isNull(v) && (UtilsService.isObject(v) ? !UtilsService.isEmptyObj(v!) : true) && typeof v !== 'undefined')
    //   || typeof v === 'boolean' ? { ...acc, [k]: v } : acc, {}
    // ) as T;
    // return result;

    if (forTest && !obj) console.log('removeEmptyKeysFromObject no have obj:', obj);
    if (!obj) return obj;
    const result = UtilsService.deepClone(obj);
    // result.search = '';
    // (result as any).test_number = 777;
    // (result as any).test_string = '';
    // (result as any).test_object =  {};
    // (result as any).test_boolean =  false;
    // (result as any).test_null =  null;
    // (result as any).test_undefined = undefined;
    // (result as any).test_array = [];
    Object.entries(obj).forEach((el) => {
      const key = el[0] as keyof T;
      const value = el[1];
      if (typeof value === 'string' && value?.trim() && key !== 'search') return;
      if (typeof value === 'string' && key === 'search') return;
      if (typeof value === 'number') return;
      if (typeof value === 'boolean') return;
      if (UtilsService.isObject(value) && !UtilsService.isEmptyObj(value!)) return;
      if (Array.isArray(value)) return;
      delete result[key];
    });
    return result;
  }

  // удаление всех (КРОМЕ null!!!) пустых полей из объекта (delete undefined, '')
  static removeEmptyKeysFromObject_withoutNull<T extends object>(obj: T, delete_defaultNameMatOption = true): T {
    if (!obj) return obj;
    return Object.entries(obj)
      .filter(el => {
        if (delete_defaultNameMatOption) return el[1] !== defaultNameMatOption;
        else return el;
      }) // delete ALL defaultNameMatOption
      .reduce((acc, [k, v]) => v || v === 0 || UtilsService.isNull(v) || typeof v === 'boolean' ? { ...acc, [k]: v } : acc, {}) as T;
  }

  // === for ClassUser =======================================
  // !!! dateFromServer == 2023-08-17
  static getAgeByDateOfBirth(dateFromServer: string): number | string {
    if (!dateFromServer) return '-';
    const now = new Date(); // this.datesS.convertStrFromServerToDate(dateFromServer)
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()); //Текущя дата без времени
    const dob = new Date(dateFromServer); //Дата рождения
    const dobnow = new Date(today.getFullYear(), dob.getMonth(), dob.getDate()); //ДР в текущем году
    let age; //Возраст
    age = today.getFullYear() - dob.getFullYear();  //Возраст = текущий год - год рождения
    if (today < dobnow) age = age - 1;  //Если ДР в этом году ещё предстоит, то вычитаем из age один год
    return age;
  }

  static isLessAge18(dateFromServer: string): boolean | null {
    if (!dateFromServer)
      return null;

    const age = UtilsService.getAgeByDateOfBirth(dateFromServer);
    const numericAge = typeof age === 'string' ? parseFloat(age) : age;
    if (!isNaN(numericAge)) {
      return numericAge < 18;
    }

    return null;
  }

  // !!! key => по какому ключу поставить titleCase
  static getArrayDropItemById<T>(content?: Array<T & { id?: string }>, key?: keyof T): Array<ClassDrop & T & { id?: string }> {
    if (content?.length) return content.map(el => ({ ...el, ...UtilsService.getDropItemById(el, key) }));
    return content || [];
  }

  static getDropItemById<T>(elem: T & { id?: string }, key?: keyof T): ClassDrop & T & { id?: string } {
    if (!elem || !elem?.id) return elem;
    const drop = new ClassDrop({
      titleCase: key ? elem[key] as string : '',
      upperCase: elem.id,
    });
    return { ...elem, ...drop };
  }

  // === Settings & Response =============================
  // !!! если true то на сервере больше не осталось данных
  static isLastPage<T>(response?: IResponse<T> | null, forTest?: string): boolean {
    if (!response) return false;
    if (typeof response?.number !== 'number' || typeof response?.totalPages !== 'number') {
      console.error('UtilsService.isLastPage :', forTest, 'response :', response);
      return false;
    }
    return response.number + 1 >= response.totalPages;
  }

  // !!! need correct rename this method
  // !!! проверка - если на сервере есть данные
  // static isExistDataInServer<T>(res?: IResponse<T>, forTest?: string): boolean {
  //   let result = true;
  //   // console.log('isExistDataInServer :', !!(res && (res?.number !== 0) && (res?.number! + 1 > res?.totalPages!) && res?.totalElements && !res.content?.length), res)
  //   // return !!(res && (res?.number !== 0) && (res?.number! + 1 > res?.totalPages!) && res?.totalElements && !res.content?.length);
  //   if (!res || !res?.content?.length) result = false;
  //   // if (!res || !res?.content?.length) console.log('1111111111 :', res)
  //   if (this.isLastPage(res) && !res?.content?.length) result = false
  //   // if (this.isLastPage(res) && !res?.content?.length) console.log('22222 :', res)
  //   // if (typeof res?.totalElements !== 'number'
  //   //   || typeof res?.totalPages !== 'number'
  //   //   || typeof res?.number !== 'number'
  //   //   || typeof res?.pageable?.pageSize !== 'number') {
  //   //   console.error('isExistDataInServer check typeof :', res)
  //   //   return false;
  //   // }
  //   // const totalElements = res?.totalElements
  //   // const totalPages = res?.totalPages
  //   // const number = res?.number + 1;
  //   // const size = res?.pageable?.pageSize
  //   // const isLastPage = this.isLastPage(res)
  //
  //   // console.log(forTest, '  isExistDataInServer result:', result, res)
  //   return result
  // }

  // === timezone ===================================
  // return например '+8'
  static userTimezoneValue(): string {
    return (new Date().getTimezoneOffset() / -60).toString();
  }

  static userTimezone(): ITimezone {
    let findTimezone = shortListTimezone.find((el: ITimezone) => el.value.toString() === this.userTimezoneValue());
    if (!findTimezone) findTimezone = fullListTimezone.find((el: ITimezone) => el.value.toString() === this.userTimezoneValue()); // первую попавшуюся из массива
    return findTimezone!;
  }

  static findTimezoneFromList(timezone: Partial<ITimezone>): ITimezone {
    let findTimezone = shortListTimezone.find((el: ITimezone) => el.id === timezone.id || el.value === timezone.value);
    if (!findTimezone) findTimezone = fullListTimezone.find((el: ITimezone) => el.id === timezone.id || el.value === timezone.value); // первую попавшуюся из массива
    return findTimezone!;
  }

  // === OTHER ============================================
  static isNull(value: any): boolean {
    return typeof value === 'object' && !value;
  }

  static responseFromServerHandler(resultObservable: Observable<any>): Observable<any> {
    return resultObservable!.pipe(
      map((res: any) => {
        // console.log('BASE API :', res)
        if (res?.error) {
          return res;
        } else {
          // this.otherS.showNotification(true, res); // !!! теперь это в MainHandlerInterceptor
          // return this.forDownloadCSV ? res : res?.responseBody;
          return res?.responseBody || res;
        }
      }),
    );
  }

  // === queryParams_forRegistration ===
  static setQueryParams_forRegistration(queryParams_forRegistration?: IQueryParams_forRegistration): void {
    // console.log('setQueryParams_forRegistration :', queryParams_forRegistration)
    if (!queryParams_forRegistration) return;
    const { role, groupId, competitionId, email, key } = queryParams_forRegistration;
    if (role) localStorage.setItem('forRegistration_role', role);
    if (groupId) localStorage.setItem('forRegistration_groupId', groupId);
    if (competitionId) localStorage.setItem('forRegistration_competitionId', competitionId);
    if (email) localStorage.setItem('forRegistration_email', email);
    if (key) localStorage.setItem('forRegistration_key', key);
  }

  static getQueryParams_forRegistration(): IQueryParams_forRegistration | null {
    let result: IQueryParams_forRegistration = {
      role: localStorage.getItem('forRegistration_role') as TUserRoleUpperCase,
      groupId: localStorage.getItem('forRegistration_groupId')!,
      competitionId: localStorage.getItem('forRegistration_competitionId')!,
      email: localStorage.getItem('forRegistration_email')!,
      key: localStorage.getItem('forRegistration_key')!,
    };
    result = UtilsService.removeEmptyKeysFromObject(result);
    // console.log('getQueryParams_forRegistration :', UtilsService.isEmptyObj(result) ? null : result)
    return UtilsService.isEmptyObj(result) ? null : result;
  }

  // !!! prepare sendObj for send to server
  // !!! example params=={ aaa: false, bbb: 'testBbb' } => return 'aaa=false&bbb=testBbb'
  // static serializeParams(params?: { [key: string]: any; }): string {
  static sendObjParamsToString(params?: { [key: string]: any; }): string {
    if (!params) return '';
    return Object.entries(params)
      // .filter(([_, value]) => value !== undefined)
      .filter(([key, value]) => Boolean(value))
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');
    // return Object.keys(params)
    //   .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    //   .join('&');
  }

}

