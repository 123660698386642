import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainService } from '@services/main.service';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { OtherService } from '@services/other.service';
import { MeService } from '@services/me.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDataPopup, PopupService } from '@services/popup.service';
import { HelperClass } from '@classes/Helper-Classes';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { DeviceService } from '@services/device.service';
import { ClassAdjustmentStatus } from '@app/group-profile/components/group-profile-adjustment-statuses/adjustmentStatus';
import { ClassGame, IClassGame_forCancelGameForSendToServer } from '@app/dir_group_assignor/games/game';
import { AdjustmentStatusesService } from '@app/group-profile/components/group-profile-adjustment-statuses/adjustment-statuses.service';
import { untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-popup-cancel-game',
  standalone: true,
  imports: [CommonModule, HeadTitleComponent, FieldComponent, DropFormCtrlComponent, ReactiveFormsModule, FormsModule, InputCtrlComponent, BtnWrapComponent, BtnComponent, DropdownComponent],
  templateUrl: './popup-cancel-game.component.html',
  styleUrls: ['./popup-cancel-game.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupCancelGameComponent extends HelperClass implements OnInit {
  // arrAdjustmentStatusDrop = arrAdjustmentStatusDrop; // arrAdjustmentStatus = arrAdjustmentStatus;
  // adjustmentStatusDrop?: ClassAdjustmentStatus; // adjustmentStatus?: IAdjustmentStatus;
  cancelReason: string = '';

  games: Array<ClassGame> = [];

  arrAdjustmentStatus: Array<ClassAdjustmentStatus> = [];
  gameAdjustmentStatus?: ClassAdjustmentStatus; // adjustmentStatus?: IAdjustmentStatus;

  constructor(
    private mainS: MainService,
    public gameS: GameService,
    public otherS: OtherService,
    private meS: MeService,
    public dialogRef: MatDialogRef<PopupCancelGameComponent>,
    @Inject(MAT_DIALOG_DATA) public dataPopup: IDataPopup, // dataPopup.games
    public popupS: PopupService,
    public deviceS: DeviceService,
    public adjustmentStatusesS: AdjustmentStatusesService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.games = dataPopup.games!;
  }

  ngOnInit() {
    this.getAdjustmentStatuses();
  }

  cancelGames(): void {
    if (this.startRequest()) return;
    const gameAdjustmentStatus: ClassAdjustmentStatus = {
      adjustmentStatusId: this.gameAdjustmentStatus?.id,
      cancelReason: this.cancelReason,
    };
    this.games = this.games?.map(el => ({ ...el, gameAdjustmentStatus }));
    this.mainS.cancelGames(this.games).pipe(untilDestroyed(this))
      .subscribe((res: Array<string> | null) => {
        this.endRequest();
        if (!res) return;
        this.close(this.games.map(el => ({ ...el, gameStatus: 'CANCELLED' })));
      });
  }

  // === AdjustmentStatuses ===============
  getAdjustmentStatuses(): void {
    this.adjustmentStatusesS.methodAdjustmentStatuses('get', true)
      .subscribe((res: Array<ClassAdjustmentStatus>) => {
        this.arrAdjustmentStatus = res || [];
        this.cd.detectChanges();
      });
  }

  close(games?: Array<ClassGame>): void {
    this.dialogRef.close(games);
  }
}

// cancelGames_withPopupConfirm(): void {
//   if (this.startRequest()) return;
// const dataPopup: IDataPopup = {
//   textTitle: 'Cancel game',
//   text: 'Are you sure you want to cancel this game?',
//   textBtnApply: 'Yes',
//   textBtnCancel: 'No',
// };
// from(this.popupS.openPopupConfirm(dataPopup))
//   .pipe(
//     switchMap((res: boolean) => {
//       const gameAdjustmentStatus: ClassAdjustmentStatus = {
//         adjustmentStatusId: this.gameAdjustmentStatus?.id,
//         cancelReason: this.cancelReason,
//       };
//       this.games = this.games?.map(el => ({ ...el, gameAdjustmentStatus }));
//       return res ? this.mainS.cancelGames(this.games) : of(null);
//     }),
//     untilDestroyed(this),
//   )
//   // .subscribe((res: Array<IResponsePopupAddCrewMember> | null) => {
//   .subscribe((res: Array<string> | null) => {
//     this.endRequest();
//     if (!res) return;
//     this.close(this.games.map(el => ({ ...el, gameStatus: 'CANCELLED' })));
//   });
// }
