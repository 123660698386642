<div class='wrapperDateTime'>
  <field text='Date'>
    <!--    <selectDate #selectDateRef placeholder='Date'></selectDate>-->
    <!--  ===== DATE ==============================-->
    <mat-form-field floatLabel='auto' class='o-matForm' [ngClass]='"o-matForm--"+bcg'
                    [class]='"o-matForm--"+({ value:(date$|async)?.toString(),
                                            active:datePicker.opened,
                                            disabled:false,
                                            error:(required && !!(dateTouched$|async) && !(date$|async)) || (errorClass && !(date$|async))
                                          }|getStateInput)'>

      <input matInput [value]='(date$|async)!' [matDatepicker]='$any(datePicker)'
             (dateChange)='valueChangeDate($event.value!)'
             (click)='test();datePicker.open()' readonly class='default-cursor' placeholder='Date'>
      <mat-datepicker-toggle matPrefix [for]='$any(datePicker)'>
        <mat-icon matDatepickerToggleIcon>
          <meSvg svgName='calendar_blueDark&20' [stateInput]='{ value:(date$|async)?.toString(),
                                                  active:datePicker.opened,
                                                  disabled:false,
                                                  error:required && !!(dateTouched$|async) && !(date$|async)
                                                }|getStateInput'></meSvg>
        </mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
    </mat-form-field>
    <!--    <error *ngIf='required && !!(dateTouched$|async) && !(date$|async)' text='Choose a date'></error>-->
  </field>


  <!--  ===== TIME ==============================-->
  <field text='Time'>
    <!--      <inputCtrl [ngModel]='timeValue' placeholder='Time' svgPrefix='clock_grey' (click)='openPopupTime()'-->
    <!--                 disabled [cssClass]='cssClassTime'></inputCtrl>-->
    <div class='timeField o-input' [class]='"o-input--"+({value:timeValue}|getStateInput)' (click)='openPopupTime()'>
      <meSvg svgName='clock_grey&20'></meSvg>
      {{timeValue || "Time"}}
    </div>
  </field>

  <!-- !!! пока не удалять !!!  ===== TIME ==============================-->
  <!--    <field text='Time'>-->
  <!--      <mat-form-field appearance='outline' class='o-matForm {{"o-matForm&#45;&#45;"+({value:timeValue}|getStateInput)}}'>-->
  <!--        <input disableAutofill matInput #inputRef [ngxTimepicker]='timePicker' [ngModelOptions]='{standalone: true}'-->
  <!--               [(ngModel)]='timeValue' placeholder='Time' (ngModelChange)='ngModelChangeTime($event)'>-->
  <!--        <meSvg matPrefix svgName='clock_grey'></meSvg>-->
  <!--      </mat-form-field>-->
  <!--      <ngx-material-timepicker-toggle [for]='timePicker' class='o-hidden o-pos-absolute o-none'></ngx-material-timepicker-toggle>-->
  <!--      <ngx-material-timepicker #timePicker class='o-hidden o-pos-absolute o-none'></ngx-material-timepicker>-->
  <!--    </field>-->
</div>