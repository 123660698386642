import { inject, Injectable } from '@angular/core';
import { MainService } from '@services/main.service';
import { PopupService } from '@services/popup.service';
import { PopupComponent } from '@components/__popup-windows/popup/popup.component';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';

@Injectable({ providedIn: 'root' })
export class CheckActiveService {

  #pay_scales_text = 'Your changes saved to the competition will be applied to new games';
  #new_pay_scale_text: string = 'Your changes saved to the competition will be applied to new games and not active games currently in the system.'

  // private mainService = inject(MainService);
  private apiCompetitionS = inject(ApiCompetitionService);
  private readonly popupS = inject(PopupService);

  constructor() {
  }

  public async checkActive(competitionId?: string, isPayScalesText: boolean = false): Promise<boolean> {
    const {activeGames, allGames} = await this.apiCompetitionS.checkActiveGamesByCompetition(competitionId);
    if( allGames === 0 && activeGames === 0 ) {
      return true;
    }
    let popupText = activeGames === 0 || activeGames === undefined ? this.#pay_scales_text :
      `Your changes saved to the competition will be applied immediately to ${activeGames} active games that matched the previous records.`;
    if(isPayScalesText) {
      popupText = this.#new_pay_scale_text
    }
    return this.popupS.open(PopupComponent, {
      width: '400px', height: activeGames === 0 ? '204px' : '228px', textTitle: 'Are you sure you want to proceed?',
      text: popupText,
      textBtnApply: 'Proceed',
      textBtnCancel: 'Go Back',
      swapBtn: true
    }, false, 'checkActiveGames');
  }
}
