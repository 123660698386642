import { Pipe, PipeTransform } from '@angular/core';
import { ClassGameOfficial } from '@app/dir_group_assignor/games/game';
import { OtherService } from '@services/other.service';

@Pipe({ name: 'isPendingGoForOfficial', standalone: true })
export class IsPendingGoForOfficialPipe implements PipeTransform {

  constructor(
    private otherS: OtherService,
  ) {
  }

// 'PENDING' // For official. Такого статуса нет, но нужно показывать PENDING если в GameOfficial selfAssigned==true & status=='UNACCEPTED'
  transform(go?: ClassGameOfficial): boolean {
    return this.otherS.isPendingGoForOfficial(go);
  }

}
