import { Pipe, PipeTransform } from '@angular/core';
import { LodashService, TypeStringCase } from '@services/lodash.service';
import { OtherService } from '@services/other.service';

@Pipe({ name: 'strCase', standalone: true })
export class StrCasePipe implements PipeTransform {

  constructor(
    private lodashS: LodashService,
    private otherS: OtherService,
  ) {
  }

  // !!! addDashInsteadOfSpace = если надо добавить тире вместо пробела
  transform(string: any, type: TypeStringCase, addDashInsteadOfSpace = false): string {
    return this.otherS.strCase(string, type);
  }

}
