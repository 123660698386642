import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetGreetPipe } from '@app/dir_group_assignor/dashboard/pipes/get-greet.pipe';
import { MeService } from '@services/me.service';

@Component({
  selector: 'app-greet',
  standalone: true,
  imports: [CommonModule, GetGreetPipe],
  templateUrl: './greet.component.html',
  styleUrls: ['./greet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GreetComponent {
  @Input() forMobileFlexRow = false;

  constructor(
    public meS: MeService,
    // public deviceS: DeviceService,
  ) {
  }
}
