<ng-container *ngIf='(deviceS.isDesktop$ | async) as obj'>
  <div *ngIf='{desktop: (deviceS.isDesktop$|async),
               mobile: (deviceS.isMobile$|async),
               dataTable: (dataTable$|async)!,
               arrContent: (arrContent$|async),
               loadingTable: (meTableS.loadingTable$|async),
               } as obj' class='o-wrapPage' wrapPage [widthPage]='widthPage' cdkScrollable dataTable>

    <!--  === FILTERS & TITLE ===========================-->
    <!--    o-header&#45;&#45;noHaveToggleCurrentPast-->
    <div class='o-header o-header--noHaveLinkPage'>
      <!--      <div class='o-header__linkPage'>-->
      <!--        <app-filtersSelectedIcon></app-filtersSelectedIcon>-->
      <!--      </div>-->
      <linkPage-searchFilter [haveLinkPage]='false'></linkPage-searchFilter>
      <div class='o-header__content'>
        <div class='o-header__title'>Competitions</div>
        <div class='o-header__filters'>
          <!--        <app-filters (emit)='emitSettingRequest($event)'></app-filters>-->
          <app-filters></app-filters>
          <div class='o-header__actions'>
            <btn text='New Competition +' bcg='newGreen' (emit)='competitionS.reset(); router.navigate([urlSetupCompetition])'
                 [btnWidth]='178'></btn>
          </div>
        </div>
        <app-filtersSelected></app-filtersSelected>
      </div>
    </div>

    <!--  === TABLE ==============================-->
    <div class='o-content t-wrapperTable'>
      <tableHeader></tableHeader>
      <div class='t-table'>
        <ng-container *ngFor='let item of obj.arrContent; let idx=index'>
          <div class='t-table__item' itemTable [item]='item'>
            <app-tableCeilChx [item]='item' [text]='item.competitionName' [hiddenChx]='true'></app-tableCeilChx>
            <!--            <app-tableCeil [idxCeil]='0' [text1]='item.competitionName'></app-tableCeil>-->
            <app-tableCeil [idxCeil]='1' [text1]='item.teamsSize || 0'></app-tableCeil>
            <app-tableCeil [idxCeil]='2' [text1]='item.locationsSize || 0'></app-tableCeil>
            <app-tableCeil [idxCeil]='3' [text1]='item.subAssignors_string'>
              <div *ngIf='item.subAssignors && item.subAssignors!.length > 1' class='ngContent_row amountUsersInCompetition'>
                +{{ item.subAssignors!.length - 1 }}
              </div>
            </app-tableCeil>
            <app-tableCeil [idxCeil]='4' [text1]='item.admins_string'>
              <div *ngIf='item.admins && item.admins!.length > 1' class='ngContent_row amountUsersInCompetition'>
                +{{ item.admins!.length - 1 }}
              </div>
            </app-tableCeil>
            <div class='t-header__ceil btnTableForOpenGames' [class.o-bord-none]='obj.mobile' ceilTable [idxCeil]='5'>
              <btn text='View' bcg='white' btnHeight='36' btnWidth='87' (emit)='goToCompetition(item)'></btn>
            </div>
          </div>
        </ng-container>
        <!--        <app-tableEmpty></app-tableEmpty>-->
        <app-tableEmpty [tableEmpty]='filtersS.searchValue|getTableEmpty'>
          <btn bcg='newGreen' text='New Competition +' [btnHeight]='deviceS.btnHeightForNothingList'
               (emit)='router.navigate([urlSetupCompetition])' class='class_ngContent_btn_for_nothingList' adminPermission></btn>
        </app-tableEmpty>
      </div>
    </div>

    <!--  === OLD TABLE // need delete after check ==============================-->
    <ng-container>
      <!--    <div class='o-content'>-->
      <!--      <div *ngIf='(arrCompetitionsSub$|async)?.length; else nothingList' class='o-table'>-->
      <!--        <div class='o-table__header'>-->
      <!--          <div *ngFor='let itemHeader of tableHeader; let idx = index;' class='o-table__header__item widthItem&#45;&#45;{{idx+1}}'>-->
      <!--            {{itemHeader.text}}-->
      <!--            <meSvg *ngIf='itemHeader.sortOrder' [svgName]='itemHeader.sortOrder | ascDesc' class='o-table__header__item__arrow'></meSvg>-->
      <!--            <app-sortBy *ngIf='itemHeader.sortBy' [arrTypeSorting]='["byCompetitionName"]'-->
      <!--                          (emitSortOptions)='emitedSortOptions($event)'></app-sortBy>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class='o-table__list'>-->
      <!--          <div *ngFor='let competitionItem of (arrCompetitionsSub$|async); let first = first; let idx = index; let last = last'-->
      <!--               class='o-table__list__item o-table__list__item&#45;&#45;{{competitionItem?.isSelect ? "isSelect":""}}'>-->
      <!--            <div class='o-table__list__item__name widthItem&#45;&#45;1'>-->
      <!--              {{competitionItem.competitionName}}-->
      <!--            </div>-->
      <!--            <div class='widthItem&#45;&#45;2'>{{competitionItem.teamsSize || 0}}</div>-->
      <!--            <div class='widthItem&#45;&#45;3'>{{competitionItem.locationsSize || 0}}</div>-->
      <!--            <div class='widthItem&#45;&#45;4 o-arrStrWrap'>-->
      <!--              <ng-container-->
      <!--                *ngFor='let subAssignorItem of competitionItem.subAssignors; let firstSubAssignorItem=first; let lastSubAssignorItem=last; let idxSubAssignorItem=index'>-->
      <!--                <span *ngIf='firstSubAssignorItem && idxSubAssignorItem==0'>-->
      <!--                  {{subAssignorItem}}{{lastSubAssignorItem ? "" : ", "}}&nbsp;-->
      <!--                </span>-->
      <!--                <span *ngIf='competitionItem.subAssignors!.length > 1 && idxSubAssignorItem==1'>-->
      <!--                  +{{competitionItem.subAssignors!.length - 1}}-->
      <!--                </span>-->
      <!--              </ng-container>-->
      <!--            </div>-->
      <!--            <div class='widthItem&#45;&#45;5 o-arrStrWrap'>-->
      <!--              <ng-container-->
      <!--                *ngFor='let adminItem of competitionItem.admins; let firstAdminItem=first; let lastAdminItem=last; let idxAdminItem=index'>-->
      <!--                <ng-container *ngIf='{showAmount: competitionItem.admins!.length > 1 && idxAdminItem==1} as objAdminItem'>-->
      <!--                  <span *ngIf='firstAdminItem && idxAdminItem==0'>{{adminItem}}</span>-->
      <!--                  <span *ngIf='objAdminItem.showAmount'>+{{competitionItem.admins!.length - 1}}</span>-->
      <!--                </ng-container>-->
      <!--              </ng-container>-->
      <!--            </div>-->
      <!--            <div class='widthItem&#45;&#45;6'>-->
      <!--              <btn text='View' bcg='white' btnHeight='36' btnWidth='87' (emit)='goToCompetition(competitionItem)'></btn>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <pagination *ngIf='(arrCompetitionsSub$|async)?.length' [data]='data'-->
      <!--                  (updatePage)='paginationMethod("updatePage",undefined,$event)'-->
      <!--                  (updateResults)='paginationMethod("updateCountResults",$event)'></pagination>-->
      <!--    </div>-->
    </ng-container>

    <app-tableInfiniteLoading></app-tableInfiniteLoading>
    <paginationWithSettings *ngIf='meTableS.showPagination'></paginationWithSettings>
  </div>
</ng-container>
<coming-soon-mobile *ngIf='(deviceS.isMobile$ | async) as obj'></coming-soon-mobile>

<!--<ng-template #spinner>-->
<!--  <spinner></spinner>-->
<!--</ng-template>-->
<!--<ng-template #nothingList>-->
<!--  <div class='nothingList'>Nothing here now</div>-->
<!--</ng-template>-->