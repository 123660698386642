import { Pipe, PipeTransform } from '@angular/core';
import { FiltersService } from '@components/filters/filters.service';
import { TAllInterfacesFilters } from '@components/filters/filters';

@Pipe({ name: 'valueFromSelectedFilter', standalone: true })
export class ValueFromSelectedFilterPipe implements PipeTransform {

  constructor(
    private filtersS: FiltersService,
  ) {
  }

  transform(selectedFilter: TAllInterfacesFilters): string {
    return this.filtersS.getValue_fromSelectedFilter(selectedFilter);
  }
}
