import { createReducer, on } from "@ngrx/store";
import { initialDashboardState } from "@app/store/dashboard/states/initial.states";
import { DashboardSubAssignorActions } from "@app/store/dashboard/actions/dashboard.actions";

export const subAssignorReducer = createReducer(
    initialDashboardState.subAssignor,
    on(DashboardSubAssignorActions.setCurrentViewDropDownAction, (state, {currentViewDropDown}) => ({
        ...state,
        currentViewDropdown: currentViewDropDown
    })),
    on(DashboardSubAssignorActions.setGameStatusAction, (state, {gameStatus}) => ({
        ...state,
        gameStatus: gameStatus
    })),
    on(DashboardSubAssignorActions.setSelfAssignedAction, (state, {selfAssigned}) => ({
        ...state,
        selfAssigned: selfAssigned
    })),
// Примечание: state должен иметь тип DashboardSubAssignorState
    on(DashboardSubAssignorActions.loadAssignDataSuccess, (state, { assignData }) => ({
        ...state,
        assignData: { ...state.assignData, ...assignData }
    })),
    on(DashboardSubAssignorActions.loadDeclinedAssignDataSuccess, (state, { declinedData }) => ({
        ...state,
        declinedData: { ...state.declinedData, ...declinedData }
    })),
    on(DashboardSubAssignorActions.loadUnassignedAssignDataSuccess, (state, { unassignedData }) => ({
        ...state,
        unassignedData: { ...state.unassignedData, ...unassignedData }
    }))

);
