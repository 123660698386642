import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FundService } from '@services/funds.service';

@Component({
  selector: 'toggleBankCard',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toggle-bank-card.component.html',
  styleUrls: ['./toggle-bank-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleBankCardComponent {
  // @Output() emit = new EventEmitter();

  constructor(
    public fundS: FundService,
  ) {
  }

  toggleLink(isBank: boolean): void {
    if (isBank == this.fundS.link.bank) return; // если текущая страница совпадает с toggleToBank
    if (!isBank == this.fundS.link.card) return; // если текущая страница совпадает с toggleToBank
    this.fundS.setLink({ bank: isBank, card: !isBank });
    // this.emit.emit()
  }
}
