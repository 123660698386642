import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass } from '@classes/Helper-Classes';
import { MainService } from '@services/main.service';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { OtherService } from '@services/other.service';
import { MeService } from '@services/me.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { BtnComponent } from '@components/btn/btn.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { FormsModule } from '@angular/forms';
import { DeviceService } from '@services/device.service';

export interface IPopupEditFeeForGO {
  id: string;
  officialFee: number;
}

@Component({
  selector: 'app-popup-edit-fee-for-go',
  standalone: true,
  imports: [CommonModule, HeadTitleComponent, FieldComponent, InputCtrlComponent, BtnComponent, BtnWrapComponent, FormsModule],
  templateUrl: './popup-edit-fee-for-go.component.html',
  styleUrls: ['./popup-edit-fee-for-go.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupEditFeeForGOComponent extends HelperClass {
  newFee: number;

  constructor(
    private mainS: MainService,
    public gameS: GameService,
    public otherS: OtherService,
    private meS: MeService,
    public dialogRef: MatDialogRef<PopupEditFeeForGOComponent>,
    @Inject(MAT_DIALOG_DATA) public dataPopup: IPopupEditFeeForGO,
    public cd: ChangeDetectorRef,
    public deviceS: DeviceService,
  ) {
    super(cd);
    this.newFee = dataPopup.officialFee;
  }

  submit(): void {
    if (this.startRequest()) return;
    const sendObj: IPopupEditFeeForGO = { officialFee: this.newFee, id: this.dataPopup.id };
    this.mainS.editFeeForGO(sendObj).toPromise()
      .then((res: any) => this.close(true))
      .catch(() => {
      })
      .finally(() => this.endRequest());
  }

  close(type: boolean): void {
    const result: IPopupEditFeeForGO | null = type ? { officialFee: this.newFee, id: this.dataPopup.id } : null;
    this.dialogRef.close(result);
  }
}
