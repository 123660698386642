import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BtnComponent } from '@components/btn/btn.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { ACCOUNT_METHODS } from '@shared/constants/account-methods.constant';

@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    BtnComponent,
    HeadTitleComponent
  ],
})
export class SuccessDialogComponent {
  method: string = ACCOUNT_METHODS.STANDARD;
  popupType: string | undefined;

  ACCOUNT_METHODS = ACCOUNT_METHODS;

  constructor(
    public dialogRef: MatDialogRef<SuccessDialogComponent, void>,
    @Inject(MAT_DIALOG_DATA) public dataPopup: { method: string;  popupType?: string },
    public cd: ChangeDetectorRef,
  ) {
    this.method = this.dataPopup.method;
    this.popupType = this.dataPopup.popupType;
  }

  close() {
    this.dialogRef.close();
    location.reload()
  }
}
