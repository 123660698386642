import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GooglePeopleService, IResFromGoogleForSignup } from '@services/google-people.service';
import { BtnComponent } from '@components/btn/btn.component';
import { HelperClass } from '@classes/Helper-Classes';
import { MeService } from '@services/me.service';
import { MainService } from '@services/main.service';
import { UntilDestroy } from '@ngneat/until-destroy';

// import { GoogleApis } from 'googleapis';
// import { google } from 'googleapis';

declare var gapi: any;

@UntilDestroy()
@Component({
  selector: 'googleSignup[type]',
  standalone: true,
  imports: [CommonModule, BtnComponent],
  templateUrl: './google-sign-up.component.html',
  styleUrls: ['./google-sign-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleSignUpComponent extends HelperClass {
  @Input() type!: 'signup' | 'logout' | 'signin';
  @Output() emit = new EventEmitter<IResFromGoogleForSignup>();

  constructor(
    // private googleApis: GoogleApis,
    public meS: MeService,
    public mainS: MainService,
    private googlePeopleS: GooglePeopleService,
    public cd: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    super(cd);
    this.googlePeopleS.initForSignup();
  }

  // https://stackoverflow.com/questions/72820938/how-to-implement-sign-in-with-google-in-angular
  // https://stackoverflow.com/questions/73266027/use-google-api-with-sign-in-with-google
  googleSignup(): void {
    setTimeout(() => { // !!! не удалять. Иногда не успевает загружаться
      this.googlePeopleS.signup()
        .then((res) => { // !!! profile.getId() res?.getBasicProfile()      
          this.ngZone.run(() => {
            const profile = res?.getBasicProfile();
            // console.log('profile :', profile, '  signup res', res, ' res?.getBasicProfile() :', res?.getBasicProfile(), ' res?.getAuthResponse() :', res?.getAuthResponse(), ' :',)
            // console.log('ID: ' + profile.getId()); // 103357886199176909752
            const resFromGoogleForSignup: IResFromGoogleForSignup = {
              fullName: profile.getName(),
              givenName: profile.getGivenName(),
              familyName: profile.getFamilyName(),
              imageURL: profile.getImageUrl(),
              email: profile.getEmail(),
              id_token: res?.getAuthResponse()?.id_token,
            };
            this.emit.emit(resFromGoogleForSignup);
          });
        })
        .catch((err: any) => {
          console.error('signup err', err);
        });
    }, 111);
  }

  googleLogout(): void {
    this.googlePeopleS.logout()
      .then((res: any) => {
        this.ngZone.run(() => {
          console.log('logout res', res);
          this.emit.emit();
        });
      })
      .catch((err: any) => {
        console.error('logout err', err);
      });
  }

}
