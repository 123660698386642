import { ClassGame } from "./game";

export class GameNotesService {
    public readonly maxStringLength: number = 510;
    public readonly isOver: boolean = false;
    public readonly readOnlyText: string | undefined;
    public readonly hasValue: boolean = false;
    constructor(
        public readonly text: string | undefined,
        public readonly isReadOnly: boolean
    ) {
        if (text) {
            this.isOver = this.text!.length > this.maxStringLength;

            this.readOnlyText = this.text;
            if (this.isOver == true)
                this.readOnlyText = this.text!.slice(0, this.maxStringLength) + "...";
            this.hasValue = true;
        } else {
            this.hasValue = false;
            this.isOver = false;
            this.readOnlyText = "";
        }
    }

    static CreateByGame(game: ClassGame) {
        const isReadOnly = game.gameStatus != "ACTIVE";
        return new GameNotesService(game.gameDescription, isReadOnly);
    }
}
