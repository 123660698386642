import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClassUser } from '@models/user';
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { MainService } from '@services/main.service';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { MeService } from '@services/me.service';
import { DeviceService } from '@services/device.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AssignService } from '@app/dir_group_assignor/assign/assign.service';
import { FiltersService } from '@components/filters/filters.service';
import { MeTableService } from '@components/_table/me-table.service';
import { ClassGame } from '@app/dir_group_assignor/games/game';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { HelperClass } from '@classes/Helper-Classes';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { switchMap } from 'rxjs';
import { UtilsService } from '@services/utils.service';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { ContainerTooltipComponent } from '@components/__tooltip/container-tooltip/container-tooltip.component';
import { TooltipDefaultComponent } from '@components/__tooltip/tooltip-default/tooltip-default.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { FormsModule } from '@angular/forms';
import {
  DirectiveForAssignGameOfficialDirective,
} from '@app/dir_group_assignor/assign/directives/directive-for-assign-game-official.directive';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/overlay';
import { GetSvgOfficialAvailabilityPipe } from '@app/dir_group_assignor/assign/pipes/get-svg-official-availability.pipe';
import { GetAgeByDateOfBirthPipe } from '@pipes/get-age-by-date-of-birth.pipe';
import { RoundMilesPipe } from '@pipes/round-miles.pipe';
import { GetUserNamePipe } from '@pipes/get-name.pipe';
import {
  IsUserAssignedInSelectedGameAndSavedInServerPipe,
} from '@app/dir_group_assignor/assign/pipes/is-user-assigned-in-selected-game-and-saved-in-server.pipe';
import { TooltipDirective } from '@directives/tooltip.directive';
import { TooltipPhoneComponent } from '@components/__tooltip/tooltip-phone/tooltip-phone.component';
import { IsLastPagePipe } from '@pipes/settings/is-last-page.pipe';
import { SpinnerComponent } from '@components/spinner/spinner.component';

// !!! если выбрана НЕзанятая роль (unassigned GO) то надо показывать судей с иконками (которые получены с gameId)
// !!! если роль вообще не выбрана ИЛИ если выбрана занятая роль то надо показывать без иконок (которые получены при загрузке страницы без gameId)
export interface OrganizationUserData {
  organization: string | null;
  organizationUserId: string | null;
  refereeExperience: string | null;
}
export interface License {
  license: {
    name: string;
    issueDate?: string;
    expiryDate?: string;
  };
}

export interface IUserAssign {
  age?: string | number;
  miles?: string | number;
  mainLicense?: License;
}
@UntilDestroy()
@Component({
  selector: 'assignOfficialAvailability',
  standalone: true,
  imports: [CommonModule, SvgComponent, ContainerTooltipComponent, TooltipDefaultComponent, InputCtrlComponent, FormsModule, CdkDropList, DirectiveForAssignGameOfficialDirective, CdkDrag, CdkScrollable, GetSvgOfficialAvailabilityPipe, GetAgeByDateOfBirthPipe, RoundMilesPipe, GetUserNamePipe, IsUserAssignedInSelectedGameAndSavedInServerPipe, TooltipDirective, TooltipPhoneComponent, IsLastPagePipe, SpinnerComponent],
  templateUrl: './assign-official-availability.component.html',
  styleUrls: ['./assign-official-availability.component.scss'],
  // providers: [CdkDropService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignOfficialAvailabilityComponent extends HelperClass implements AfterViewInit {
  @ViewChild('cdkDropListRef') cdkDropListRef?: CdkDropList<any>;
  @ViewChildren('queryListTooltip') queryListTooltip?: QueryList<TooltipDirective>;
  officialAvailabilityText = 'Select an unassigned role to view officials and their availability on the selected date by nearest distance to the game location.';

  constructor(
    public mainS: MainService,
    public gameS: GameService,
    public meS: MeService,
    public deviceS: DeviceService,
    public router: Router,
    public route: ActivatedRoute,
    public filtersS: FiltersService, // for-filters===
    public meTableS: MeTableService<ClassGame>, // for-table===
    public settingsRequestS: SettingsRequestService, // for-settings===
    public assignS: AssignService,
    private scroll: ScrollDispatcher,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.subscribeToSearch();
    this.subscribeToScroll();
  }

  ngAfterViewInit(): void {
    this.assignS.cdkDropListRef = this.cdkDropListRef;
  }
  
  generateTooltip(userItem: IUserAssign & ClassUser): string {
    const licenseName = userItem.mainLicense?.license?.name || 'No license';
    const experience = userItem.organizationUserData?.refereeExperience !== undefined 
      ? `${userItem.organizationUserData.refereeExperience} years` 
      : 'No experience';
    return `<ul><li>Referee Grade: ${licenseName}</li><li>Referee Experience: ${experience}</li></ul>`;
  }
  
  subscribeToSearch(): void {
    this.subscribeToSearch2FromHelperClass().pipe(
      switchMap((searchValue: string) => {
        this.assignS.settingsOfficials.search = searchValue?.trim();
        return this.assignS.getListOfficials(false);
      }),
      untilDestroyed(this),
    )
      .subscribe((res) => {
        this.cd.detectChanges();
      });
  };

  // !!! чтобы пропадал тултип при скроллинге на мобиле, т.к. некоректно отображается при скролле
  subscribeToScroll(): void {
    this.scroll.scrolled().pipe(untilDestroyed(this)).subscribe((data) => {
      if (this.queryListTooltip) {
        Array.from(this.queryListTooltip)?.forEach((dir) => {
          // if (dir?.isShowTooltip && dir?.tooltipElement) {
          if (dir?.isShowTooltip) {
            dir.hideTooltip();
            this.cd.detectChanges();
          }
        });
      }
    });
  };

  // !!! при нажатии на Show More отправить запрос на сервер, чтобы добавить судей полученных с сервера к существуещему списку судей
  add_getListOfficials(): void {
    if (UtilsService.isLastPage(this.assignS.dataOfficials)) return; // !!! если это последняя страница
    this.assignS.getListOfficials(true).pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.cd.detectChanges();
      });
  };

}
