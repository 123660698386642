import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl } from '@angular/forms';
import { MAT_SELECT_CONFIG, MatSelect, MatSelectChange, MatSelectModule } from '@angular/material/select';
import { OtherService } from '@services/other.service';
import { MainService } from '@services/main.service';
import { MatMenu } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionComponent } from '@components/mat-option/mat-option.component';
import { ErrorComponent } from '@components/__info_text_message_error_warning/error/error.component';
import { GetWidthMatMenuPipe } from '@pipes/get-width-mat-menu.pipe';
import { GetStateInputPipe } from '@pipes/get-state-input.pipe';
import { TClassForText, TColor } from '@models/ICssStyles';
import { MatInputModule } from '@angular/material/input';
import { GetStylesPipe } from '@pipes/css/get-styles.pipe';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { NoSpaceDirective } from '@directives/no-space.directive';
import { TSvgName } from '@components/__svg_img/svg/forSvg';
import { defaultNameMatOption } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { UntilDestroy } from '@ngneat/until-destroy';

// !!! НЕ ИСПОЛЬЗОВАТЬ этот компонент. Вместо этого компонента есть app-dropdown
@UntilDestroy()
@Component({
  selector: 'dropFormCtrl',
  templateUrl: './dropFormCtrl.component.html',
  styleUrls: ['./dropFormCtrl.component.scss'],
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatSelectModule, MatOptionComponent, ErrorComponent,
    GetWidthMatMenuPipe, GetStateInputPipe, FormsModule, MatInputModule, GetStylesPipe, SvgComponent, NoSpaceDirective],
  providers: [
    //   {
    //     provide: NG_VALUE_ACCESSOR,
    //     useExisting: forwardRef(() => DropFormCtrlComponent),
    //     multi: true,
    //   },
    // {
    //   provide: MAT_SELECT_CONFIG,
    //   // provide: MAT_MENU_DEFAULT_OPTIONS,
    //   useValue: { overlayPanelClass: 'forOverlayPanelDropFormCtrl' },
    // },
    // {
    //   provide: NG_VALUE_ACCESSOR,
    //   useExisting: forwardRef(() => DropFormCtrlComponent),
    //   multi: true,
    // },
    {
      provide: MAT_SELECT_CONFIG,
      // provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'forOverlayPanelDropFormCtrl' },
    },
  ],
})
export class DropFormCtrlComponent implements ControlValueAccessor, AfterViewInit, AfterViewChecked, OnChanges, OnInit {

  // !!! andrei переделать этот компонент
  // !!! чтобы по несколько раз не передавать значение в родительский компонент
  // !!! всё равно эмитится несколько раз ...
  @ViewChild('selectRef') selectRef?: MatSelect;
  @Input() placeholder: string = '';
  @Input() isTextCenter = false; // если надо сделать текст внутри инпута по центру, то передать true

  @Input() typeText: TClassForText = 'text6-blueDark'; // !!! сделано только для text7-blueDark &--filled. Для других типов придется добавить в material.scss в &--empty &--filled &--active &--disabled &--error

  @Input() svgPrefix?: TSvgName; // в самом дропдауне
  @Input() svgSuffix?: TSvgName; // в самом дропдауне
  @Input() svgRightMatOption?: TSvgName; // в выпадающем списке
  @Input() svgMatOptionCondition?: string; // в выпадающем списке
  @Input() widthSvgPrefix?: string | number; // размер для svg в самом дропдауне
  @Input() widthSvgSuffix?: string | number; // размер для svg в самом дропдауне

  @Input() listStr?: Array<string>; // передавать смассив строк

  private _listObj!: Array<any>; // должны быть такие значение value:string или id // title или name
  @Input()
  set listObj(listObj: Array<any>) {
    this._listObj = listObj;
  }

  get listObj() {
    return this._listObj;
  }

  listObjDropItem?: Array<any>; // должны быть такие значение value:string или id // title или name
  @Input() hasChildDropDown?: boolean = false;

  @Input() multi = false; // если true передал, то надо чтобы в formControlName был массив !

  @Input() nameField?: string;
  @Input() textRequired = 'This field is required.'; // если поле обязательное, передать сюда текст или можно оставить по умолчанию

  // !!! если использовать [(ngModel)]='locationS.venueName' то надо также ставить [ngModelOptions]='{standalone: true}'
  // !!! [required]='true' можно вешать прямо на <inputCtrl>, сюда передавать required не надо. И так норм работает - у Вити спросить как это работает
  @Input() touch = false; // используется в ngOnChanges, если передавал [(ngModel)]='' вместо formControlName

  @Input() bcg: TColor = 'white'; // background // TColor = 'blueDark' | 'grey' | 'grey_1' | 'white' | 'blue' | 'green' | 'red';

  @Input() w: string | number = ''; // width // to set width
  @Input() h: string | number = ''; // height // to set height

  // !!! value в родительской форме в formControlName и без этого норм меняются
  // !!! но если надо также обновить данные где-то ещё (например в сервисе), то можно использовать этот changeVal.emit(value)
  @Output() changeVal = new EventEmitter<any>();

  // === matOption ======================
  @Input() matOption = false; // если надо дополнительный mat-option добавить, которого нет в массиве
  @Input() matOptionText?: string;
  @Input() matOptionTextError?: string;
  @Input() isTextCenterOtherMatOption = false;
  @Output() matOptionEmit = new EventEmitter();

  @Output() otherMatOptionEmit = new EventEmitter<string>();

  @Input() showErrText = false; // если надо показывать текст ошибку то передать сюда true

  @Input() addNewItem = false;
  newItemValue = '';

  subGroupVisibleId = '';

  defaultNameMatOption = defaultNameMatOption;
  @Input() replaceDefaultNameMatOption: string = ''; // если надо заменить 'ALL' на другую строку. Например на для странице создания игры Level 'ALL' надо заменить на 'N/A'

  private onChange!: (value: string) => void;
  private onTouched!: () => void;

  @Input() forTest: any = false;

  constructor(
    public otherS: OtherService,
    public mainS: MainService,
    public ngControl: NgControl,
    public elRef: ElementRef,
    // private renderer: Renderer2,
    private cd: ChangeDetectorRef,
  ) {
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  test(item?: any, menu?: MatMenu) {
    if (this.forTest) {
      //   console.log('value :', this.forTest, this.value)
      //   console.log('ngControl.valueAccessor :', this.forTest, this.ngControl.value)
      //   this.listObjDropItem?.forEach(el => {
      //     console.log('el :', el)
      //   })
    }
  }

  newItemChange(e: string): void {
    if (!e?.trim() || typeof e !== 'string') return;
    this.value = e?.trim();
  }

  checkClosedDrop(isOpened?: boolean): void {
    if (typeof isOpened !== 'boolean') return;
    if (!isOpened) {
      if (typeof this.value == 'string' && this.value?.trim()) {
        // if (this.forTest) {
        //   console.log('checkClosedDrop :', this.test, '  value:', typeof this.value, this.value);
        // }
        this.otherMatOptionEmit.emit(this.value?.trim());
        this.onChange(this.value?.trim()); // не менять !!! без этого в род.компоненте не меняется значение formControl
        this.changeVal.emit(this.value?.trim());
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (this.forTest) {
    //   console.log('ngOnChanges :', changes);
    // }
    if (changes?.listStr?.currentValue) {
      if (this.listStr) this.listStr = this.otherS.deepClone(this.listStr);
      // if (this.forTest) {
      // }
    }

    if (changes?.touch?.currentValue) this.touched = true; // чтобы из родит.компонент получить и отреагировать
    // if (changes?.touch?.currentValue) this.onTouched(); // чтобы в родит.компонент передать

    if (changes?.listObj?.currentValue) {
      this.listObjDropItem = this.otherS.getArrDropItem(changes?.listObj?.currentValue, 'DropFormCtrlComponent changes?.listObj?.currentValue');
    }
    this.cd.detectChanges();
  }

  ngOnInit() {
    if (this.nameField) this.textRequired = this.nameField + ' is required.';
  }

  ngAfterViewInit() {
    const overlayContainer = document.querySelector('.cdk-overlay-container');
    if (overlayContainer) {
      if (this.hasChildDropDown) {
        overlayContainer.classList.add('has-children');
      } else {
        overlayContainer.classList.remove('has-children');
      }
    }

    if (this.ngControl?.control) {
      if (this.nameField?.toLowerCase()?.includes('team') || this.placeholder?.toLowerCase()?.includes('team')) {
      }
    }
    if (this.forTest) {
      // console.log('ngAfterViewInit :', this.test, '  this.value', this.value);
    }
  }

  ngAfterViewChecked() {
    if (this.forTest) {
      // console.log('ngAfterViewChecked :', this.test, '  this.value', this.value);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.nameField?.toLowerCase()?.includes('team') || this.placeholder?.toLowerCase()?.includes('team')) {
    }
  }

  changeSelect(e: MatSelectChange) {
    // if (this.forTest) {
    //   console.log('changeSelect :', this.test, '   e?.value:', e?.value, '  value:', typeof this.value, this.value);
    // }
    // !!! andrei переделать
    let forOnlyOneEmitValue = false;
    if (typeof e?.value == 'string' && e?.value?.trim()) {
      forOnlyOneEmitValue = true;
      this.onChange(e.value?.trim()); // не менять !!! без этого в род.компоненте не меняется значение formControl
      this.changeVal.emit(e.value?.trim());
      this.checkClosedDrop();
    }
    if (forOnlyOneEmitValue) return;
    if (typeof this.value == 'string' && this.value?.trim()) {
      this.onChange(this.value?.trim()); // не менять !!! без этого в род.компоненте не меняется значение formControl
      this.changeVal.emit(this.value?.trim());
      this.checkClosedDrop();
    }
  }

  // если multiple = true , то value = массив
  writeValue(value: string | { id?: string; value?: string; name?: string; title?: string; } | Array<any>): void {
    // if (this.forTest) {
    //   console.log('writeValue :', this.test, value, '  this.value', this.value);
    // }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  clickCustomMatOption(): void {
    // if (this.forTest) {
    //   console.log('SET value :', this.test, this.value);
    // }
    this.selectRef?.close();
    this.matOptionEmit.emit();
  }

  getMatOptionId(matOptionId: string) {
    (this.subGroupVisibleId === matOptionId)
      ? this.subGroupVisibleId = ''
      : this.subGroupVisibleId = matOptionId;
  }

  // === GETTERS & SETTERS =======================
  set value(value: any) {

    // if (this.forTest) {
    //   console.log('SET value :', this.test, '  value:', typeof value, value);
    // }
    if (typeof value === 'undefined') return;
    this.changeVal.emit(value);
    this.ngControl.control?.setValue(value);  // this.ngControl.control?.patchValue(value);
    this.onChange(value); // чтобы в родит.компонент передать
    this.onTouched();  // чтобы в родит.компонент передать
  }

  get value(): any {
    if (this.forTest) {
    }
    return this.ngControl.control?.value;
  }

  get errRequired(): boolean {
    return !!this.ngControl.control?.hasError('required') && !!this.ngControl.control?.touched;
  }

  get valid(): boolean {
    return !!this.ngControl.control?.valid;
  }

  get invalid(): boolean {
    return !!this.ngControl.control?.invalid;
  }

  get touched(): boolean {
    return !!this.ngControl.control?.touched;
  }

  set touched(touched: boolean) {
    this.onTouched(); // чтобы в родит.компонент передать
  }

  get untouched(): boolean {
    return !!this.ngControl.control?.untouched;
  }

  get active(): boolean {
    return !!this.selectRef?.panelOpen;
  }

  get disabled(): boolean {
    return !!this.ngControl?.control?.disabled;
  }

}


// keydownForOtherMatOption111(event?: any) {
//   if (event.key === 'Enter') {
//     if (typeof this.value == 'string') {
//       this.otherMatOptionEmit.emit(this.value?.trim());
//       this.onChange(this.value?.trim()); // не менять !!! без этого в род.компоненте не меняется значение formControl
//       this.changeVal.emit(this.value?.trim());
//       this.cd.detectChanges();
//     }
//   }
// }
// keydownForOtherMatOption(event?: any) {
//   if (event.key === 'Enter') {
//     this.otherMatOptionEmit.emit(this.value?.trim());
//     this.matOptionEmit.emit(); ////////
//     this.onChange(this.value?.trim()); // не менять !!! без этого в род.компоненте не меняется значение formControl
//     this.changeVal.emit(this.value?.trim());
//     this.checkClosedDrop();
//     this.cd.detectChanges();
//     this.cd.markForCheck();
//   }
// }