import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { GetStylesPipe } from '@pipes/css/get-styles.pipe';
import { BehaviorSubject } from 'rxjs';
import { NothingListComponent } from '@components/nothing-list/nothing-list.component';

export type TypePositionTooltip =
  'beforeBottomLeft' | // !!! снизу и слева от триггера
  'bottomLeft' | // !!! снизу прижат к левому краю триггера
  'bottom' | // !!! снизу по центру триггера
  'bottomRight' | // !!! снизу прижат к правому краю триггера
  'afterBottomRight' // !!! снизу и справа от триггера

@Component({
  selector: 'app-containerTooltip[position][width]',
  standalone: true,
  imports: [CommonModule, SvgComponent, GetStylesPipe, NothingListComponent],
  templateUrl: './container-tooltip.component.html',
  styleUrls: ['./container-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContainerTooltipComponent implements AfterViewInit {
  @ViewChild('triggerRef') triggerRef?: ElementRef;
  @Input() position!: TypePositionTooltip; // !!! сейчас сделаны css-стили только для bottom-left
  @Input() width!: number; // !!! for popupForContainerTooltip
  @Input() forTest = '';
  stylePosition: { [key: string]: string } = {};

  showTooltipSub$ = new BehaviorSubject<boolean>(false);

  get showTooltip(): boolean {
    return this.showTooltipSub$.getValue();
  }

  set showTooltip(showTooltip: boolean) {
    this.showTooltipSub$.next(showTooltip);
  }

  @Input() forTest_notClosedPopup = false;

  constructor(
    private cd: ChangeDetectorRef,
  ) {
  }

  ngAfterViewInit() {
    this.checkPosition();
  }

  @HostListener('window:scroll', ['$event']) onScroll(event: any) {
    if (!this.showTooltip) return;
    this.showTooltip = false; // !!! чтобы пропадал тултип при скроллинге на мобиле
    this.cd.detectChanges();
  }

  checkPosition(): void {
    if (!this.width || !this.position) return;
    const width_triggerRef = this.triggerRef?.nativeElement?.offsetWidth;
    const height_triggerRef = this.triggerRef?.nativeElement?.offsetHeight;
    const differentWidthBetweenTriggerAndTooltip = width_triggerRef - this.width;
    if (this.position === 'beforeBottomLeft') this.stylePosition = { left: -this.width + 8 + 'px', top: height_triggerRef + 'px' };
    if (this.position === 'bottomLeft') this.stylePosition = { left: 0 + 'px', top: height_triggerRef + 'px' };
    if (this.position === 'bottom') {
      this.stylePosition = {
        left: (differentWidthBetweenTriggerAndTooltip / 2) + 'px',
        top: height_triggerRef + 'px',
      };
    }
    if (this.position === 'bottomRight') {
      this.stylePosition = {
        top: height_triggerRef + 'px',
        right: differentWidthBetweenTriggerAndTooltip < 0 ? '' : '0px',
      };
    }
    if (this.position === 'afterBottomRight') this.stylePosition = { top: height_triggerRef + 'px', left: width_triggerRef + 'px' };
    this.cd.detectChanges();
  }
}
