<div class='wrapperPopupAssignNewRole'>
  <headTitle text='Assign New Role' svgRight='cross_grey&24' (svgRightEmit)='close(false)' typeText='text4-blueDark'></headTitle>

  <field text='Select Role' class='o-mg-t-20'>
    <app-dropdown [(ngModel)]='selectedUserRoleDrop' [array]='arrUserRoleDrop'
                  placeholder='Select Role'></app-dropdown>
  </field>

  <field text='Select Competition' class='o-mg-t-12'>
    <app-dropdown [(ngModel)]='arrSelectedCompetition' [array]='(gameS.arrCompetition$|async)!' [multi]='true'
                  placeholder='Select Competition'></app-dropdown>
  </field>

  <btnWrap class='o-mg-t-32'>
    <!--    <div class='btnWrapPopupAssignNewRole'>-->
    <btn text='Cancel' bcg='white' (emit)='close(false)'></btn>
    <btn text='Save' bcg='blueDark' (emit)='submit()'
         [disabled]='reqPending || !arrSelectedCompetition?.length || !selectedUserRoleDrop'></btn>
    <!--    </div>-->
  </btnWrap>

</div>
