import { createReducer, on } from "@ngrx/store";
import { initialDashboardState } from "@app/store/dashboard/states/initial.states";
import { DashboardHomeAssignerActions } from "@app/store/dashboard/actions/dashboard-home-assigner-actions";

export const homeAssignerReducer = createReducer(
    initialDashboardState.homeAssignerActiveTile,
    on(DashboardHomeAssignerActions.setActiveTileGameAction, state => ({
        ...state,
        isGame: true,
        isDeclined: false,
        isPending: false,
        isUnassigned: false
    })),
    on(DashboardHomeAssignerActions.setActiveTilePendingAction, state => ({
        ...state,
        isPending: true,
        isGame: false,
        isUnassigned: false,
        isDeclined: false
    })),
    on(DashboardHomeAssignerActions.setActiveTileRolesAction, state => ({
        ...state,
        isUnassigned: true,
        isGame: false,
        isDeclined: false,
        isPending: false,
    })),
    on(DashboardHomeAssignerActions.setActiveTileDeclinedAction, state => ({
        ...state,
        isDeclined: true,
        isGame: false,
        isUnassigned: false,
        isPending: false,
    }))
);
