import { Pipe, PipeTransform } from '@angular/core';
import { OtherService } from '@services/other.service';

@Pipe({ name: 'isSelect', standalone: true, pure: false })
export class IsSelectPipe<T> implements PipeTransform {

  constructor(
    private otherS: OtherService,
  ) {
  }

  // !!! если хотя бы 1 элемент в массиве isSelect==true, то вернется true
  transform(array?: Array<T & { isSelect?: boolean; }>): boolean {
    return this.otherS.isSelect(array);
  }

}
