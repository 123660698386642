import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';
import { ITestArrForAnalytics } from '@app/dir_group_assignor/payments/modelsForPayment';

@Component({
  selector: 'app-popup-edit-charts',
  standalone: true,
  imports: [CommonModule, HeadTitleComponent, SvgComponent, CdkDropList, CdkDrag, BtnWrapComponent, BtnComponent],
  templateUrl: './popup-edit-charts.component.html',
  styleUrls: ['./popup-edit-charts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupEditChartsComponent {
  arrForAnalytics: Array<ITestArrForAnalytics> = [];

  constructor(
    public dialogRef: MatDialogRef<PopupEditChartsComponent>,
    public cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public dataPopup: { arrForAnalytics: Array<ITestArrForAnalytics> },
  ) {
    this.arrForAnalytics = dataPopup.arrForAnalytics;
  }

  addItem(item: ITestArrForAnalytics): void {
    if (item.title == 'Total Payouts' || item.title == 'Projected Outgoings') return;
    item.isAdded = !item.isAdded;
    this.cd.detectChanges();
  }

  close(type: boolean): void {
    this.dialogRef.close(type ? this.arrForAnalytics : null);
  }

  // === DRAG ON DROP ===============================================================
  // !!! срабатывает когда отпускаешь кнопку мыши
  dragDropEventEnd(event: CdkDragDrop<Array<ITestArrForAnalytics>>): void {
    moveItemInArray(this.arrForAnalytics, event.previousIndex, event.currentIndex);
    this.cd.detectChanges();
  }
}
