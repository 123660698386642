<div *ngIf='{ gameCANCELLED: game.gameStatus == "CANCELLED",
              existSelectedElems: (game?.gameOfficials|getSelectedElems)?.length,
              selectedElems: (game?.gameOfficials|getSelectedElems),
              selectedArrGo_assigned_and_savedInServer: assignS.selectedArrGo_assigned_and_savedInServer,
              exist_selectedArrGo_assigned_and_savedInServer: !!assignS.selectedArrGo_assigned_and_savedInServer?.length,
              exist_selectedArrGo_assigned_and_not_savedInServer: !!assignS.selectedArrGo_assigned_and_not_savedInServer?.length,
              selectedGameId_isEqual_currentGameId: assignS.selectedGame?.id === game?.id,
              showBtnShowMoreForGO: game|showBtnShowMoreForGO,
             } as obj' ceilTable [idxCeil]='3'
     class='t-table__item__wrapCeil__ceilColumn wrapperCeilAssignGameOfficial'>

  <ng-container *ngFor='let go of game?.gameOfficials;let idxRole=index;let last=last'>
    <assignGameOfficial *ngIf='game.isShowAllGO || idxRole < assignS.amountForShowAllGO'
                        [game]='game' [go]='go' [idxGo]='idxRole'></assignGameOfficial>
  </ng-container>


  <div class='goOfficialActions'>
    <ng-container *ngIf='obj.selectedGameId_isEqual_currentGameId'>
      <!-- !!! эти кнопки для заасайненых ролей которые сохранены на сервере -->
      <div *ngIf='obj.exist_selectedArrGo_assigned_and_savedInServer' class='goOfficialActions__btns'>
        <btn *ngIf='assignS.isShowBtnInspect' text='See Avail.' bcg='white' (emit)='getListOfficials()' btnHeight='36' [fontSize]='14'
             [disabled]='reqPending'></btn>
        <btn text='Set Status' bcg='white' (emit)='setStatus()' btnHeight='36' [fontSize]='14' [disabled]='reqPending'></btn>
        <btn text='Remove' bcg='white' (emit)='deleteOfficials()' btnHeight='36' [fontSize]='14' [disabled]='reqPending'></btn>
      </div>

      <!-- !!! эта кнопка для заасайненых ролей которые НЕ сохранены на сервере. Тоесть удаление без запроса на сервер -->
      <div *ngIf='obj.exist_selectedArrGo_assigned_and_not_savedInServer' class='goOfficialActions__btns'>
        <btn text='Remove' bcg='white' (emit)='assignS.deleteOfficialsWithoutRequest()'
             btnHeight='36' [fontSize]='14' [disabled]='reqPending'></btn>
      </div>
    </ng-container>

    <div *ngIf='obj.showBtnShowMoreForGO' class='goOfficialActions__showMore' (click)='methodShowMore()'>
      {{ game.isShowAllGO ? "Show Less" : "Show More" }}
    </div>
  </div>

</div>