<ng-container *ngIf='{typeView: alwaysType ? alwaysType : ((deviceS.isDesktop$|async) ? type : "col")} as obj'>
  <div class='wrapperBtnWrap wrapperBtnWrap--{{obj.typeView}} wrapperBtnWrap--{{obj.typeView}}--amountBtn_{{amountBtn}}
        {{obj.typeView=="col" || !chet ? "o-flex-column btn-w-100" : "o-flex-row o-flex-align-items o-flex-space-between btn-w-50-12px"}}
        {{obj.typeView=="col" || !chet ? "marginCol-"+marginCol : ""}}
        {{isAnnouncement ? " announcement" : ""}}
        {{(obj.typeView=="col" && swapBtn) ? " wrapperBtnWrap--reverseBtn" : ""}}'
       [class.o-flex-row-reverse]='obj.typeView=="row" ? swapBtn : false'
       [class.o-flex-column-reverse]='obj.typeView=="col" ? swapBtn : false'>
    <ng-content #contentChildren></ng-content>
  </div>
</ng-container>
