import { ElementRef, HostListener, Pipe, PipeTransform } from '@angular/core';
import { IWidth } from '@models/ICssStyles';

@Pipe({ name: 'getWidthMatMenu', standalone: true, pure: false }) // !!! NO DELETE pure: false
export class GetWidthMatMenuPipe implements PipeTransform {
  private elRef?: ElementRef;
  private forWidthOverlayPane?: number; // для того чтобы ширина выпадающего списка была равна ширине mat-form-field

  @HostListener('window:resize', ['$event.target']) onResize(): void { // для того чтобы ширина выпадающего списка была равна ширине mat-form-field
    // if (this.elRef?.nativeElement) this.forWidthOverlayPane = this.elRef?.nativeElement?.offsetWidth;
    this.setWidth();
    // console.log('elRef :', this.elRef);
    // console.log('forWidthOverlayPane :', this.forWidthOverlayPane);
  }

  transform(element: any, forTest?: string | boolean): IWidth { // { width: string, minWidth?: string, maxWidth?: string }
    // if (forTest) {
    //   console.error('===== transform :', forTest, element);
    // }
    if (!element) return { width: 'initial', minWidth: 'initial', maxWidth: 'initial' };
    if (element?.nativeElement) {
      this.elRef = element; // для обычных тегов (div например)
    } else {
      this.elRef = element?.elRef; // !!! для компонентов в конструкторе которого объявлено public elRef: ElementRef,
    }

    this.setWidth(element);

    if (typeof this.forWidthOverlayPane !== 'number') console.log('GetWidthMatMenuPipe. NO HAVE this.forWidthOverlayPane :', this.forWidthOverlayPane);
    const result = { width: this.forWidthOverlayPane! - 1 + 'px' };
    // if (forTest) {
    //   console.error('===== result :', result)
    // }
    // return { width: this.forWidthOverlayPane + 'px', minWidth: this.forWidthOverlayPane + 'px', maxWidth: this.forWidthOverlayPane + 'px' };
    return result;
  }

  setWidth(element?: any): void {
    if (this.elRef?.nativeElement) this.forWidthOverlayPane = this.elRef?.nativeElement?.offsetWidth;
    if (!this.elRef?.nativeElement) this.forWidthOverlayPane = element?.offsetWidth;
    // console.log('elRef :', this.elRef);
    // console.log('forWidthOverlayPane :', this.forWidthOverlayPane);
  }

}
