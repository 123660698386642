import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssignService } from '@app/dir_group_assignor/assign/assign.service';
import { ClassGame, ClassGameOfficial } from '@app/dir_group_assignor/games/game';
import { UntilDestroy } from '@ngneat/until-destroy';
import { HelperClass } from '@classes/Helper-Classes';
import { DeviceService } from '@services/device.service';
import { UtilsService } from '@services/utils.service';
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import {
  DirectiveForAssignGameOfficialDirective,
} from '@app/dir_group_assignor/assign/directives/directive-for-assign-game-official.directive';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { GetSvgForGoStatusPipe } from '@pipes/gameOfficial/get-svg-for-go-status.pipe';
import { GetFirstLetterEachWordPipe } from '@pipes/string/get-first-letter-each-word.pipe';
import { GetUserNamePipe } from '@pipes/get-name.pipe';

@UntilDestroy()
@Component({
  selector: 'assignGameOfficial[game][go][idxGo]',
  standalone: true,
  imports: [CommonModule, CdkDrag, CdkDropList, DirectiveForAssignGameOfficialDirective, SvgComponent, GetSvgForGoStatusPipe, GetFirstLetterEachWordPipe, GetUserNamePipe],
  templateUrl: './assign-game-official.component.html',
  styleUrls: ['./assign-game-official.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignGameOfficialComponent extends HelperClass implements OnChanges {
  @ViewChild('finalElem') finalElem?: HTMLDivElement; // в директиве буду css класс назначать
  @Input() game!: ClassGame;
  @Input() go!: ClassGameOfficial;
  @Input() idxGo!: number;

  constructor(
    public deviceS: DeviceService,
    public assignS: AssignService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes.game?.currentValue) {
    //   if (this.assignS.gameId === this.game?.id) {
    //     console.log('goComponent onChanges game :', this.game);
    //     this.game.gameOfficials?.forEach((go) => {
    //       if (this.assignS.goId === go?.id) {
    //         console.log('goComponent onChanges go :', !!go?.official, go);
    //       }
    //     });
    //   }
    // }
    // if (changes.go?.currentValue) {
    //   if (this.assignS.goId === this.go?.id) {
    //     console.log('goComponent onChanges go :', !!this.go?.official, this.go);
    //   }
    // }
  }

  selectGO_forDesktop(): void {
    if (this.game.gameStatus === 'CLOSED') return;
    const current_go_isSaveInServer_officialAssign = UtilsService.isSaveInServer_officialAssign(this.go); // !!! сохранен ли заасайненый судья на сервере в этой роли(go) на которую нажал сейчас
    const updated_gameOfficials: Array<ClassGameOfficial> = UtilsService.deepClone(this.game.gameOfficials || []); // !!! NO DELETE deepClone
    // console.log('current_go_isSaveInServer_officialAssign :', current_go_isSaveInServer_officialAssign) //  delete log
    updated_gameOfficials.forEach((el) => {
      // !!! 1 вариант => нажал на занятая роль сохранена на сервере UNPUBLISHED
      // => другие такие же роли НЕ переключать isSelect. все другие isSelect=false
      // => if (current_go_isSaveInServer_officialAssign && !otherGo_isSaveInServer_officialAssign) isSelect=false
      // !!! 2 вариант => нажал на занятая роль НЕ сохранена на сервере UNPUBLISHED
      // => все другие isSelect=false
      // !!! 3 вариант => нажал на свободная роль НЕ сохранена на сервере UNASSIGNED
      // => все другие isSelect=false
      if (this.assignS.selectedGame?.id === this.game?.id) { // !!! нажал на роль в текущей игре
        if (el.id === this.go.id) { // это роль(go) на который сейчас нажал
          el.isSelect = !el.isSelect;
        } else { // все остальные роли(go) в этой игре
          const otherGo_isSaveInServer_officialAssign = UtilsService.isSaveInServer_officialAssign(el); // !!! сохранен ли заасайненый судья на сервере в этой роли(go)
          if (current_go_isSaveInServer_officialAssign && !otherGo_isSaveInServer_officialAssign) el.isSelect = false; // !!! 1 вариант
          if (!current_go_isSaveInServer_officialAssign) el.isSelect = false;
        }
      } else { // !!! нажал на роль в ДРУГОЙ игре ИЛИ впервые нажал на роль (тоесть текущей выбраной игры ещё нет)
        el.isSelect = el.id === this.go.id; // выбрать тот GO на котором нажал
      }
    });
    this.assignS.selectGO({ ...this.game, gameOfficials: updated_gameOfficials }, this.go, false);
  }

  selectGO_forMobile(): void {
    if (this.game.gameStatus === 'CLOSED') return;
    const updated_gameOfficials: Array<ClassGameOfficial> = UtilsService.deepClone(this.game.gameOfficials || []); // !!! NO DELETE deepClone
    const current_go_isSaveInServer_officialAssign = UtilsService.isSaveInServer_officialAssign(this.go); // !!! сохранен ли заасайненый судья на сервере в этой роли(go) на которую нажал сейчас

    const isUserAssignedInGame_andSavedInServer = UtilsService.isUserAssignedInGame_andSavedInServer(this.game, this.go.official?.id!);

    updated_gameOfficials.forEach((el) => {
      if (el.id === this.go.id) { // это роль(go) на который сейчас нажал
        if (isUserAssignedInGame_andSavedInServer) {
          el.isSelect = !el.isSelect; // !!! для ролей которые сохранены на сервере чтобы показывать/скрывать кнопки (remove & set status)
        } else {
          el.isSelect = true;
        }
      } else { // все остальные роли(go) в этой игре
        const otherGo_isSaveInServer_officialAssign = UtilsService.isSaveInServer_officialAssign(el); // !!! сохранен ли заасайненый судья на сервере в этой роли(go)
        if (current_go_isSaveInServer_officialAssign && !otherGo_isSaveInServer_officialAssign) el.isSelect = false; // !!! 1 вариант
        if (!current_go_isSaveInServer_officialAssign) el.isSelect = false;
      }
    });

    this.assignS.selectGO({ ...this.game, gameOfficials: updated_gameOfficials }, this.go, false); // !!! перед открытием окна с судьями надо выбрать игру и роль
    if (isUserAssignedInGame_andSavedInServer) return; // !!! если нажал на заасайненую и сохраненную на сервере роль, то НЕ надо открывать окно со списком судей
    this.assignS.showTableOfficialsForMobile = true;
    this.cd.detectChanges();
    this.cd.markForCheck();
  }

}
