import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass } from '@classes/Helper-Classes';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { PopupService } from '@services/popup.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OfficialsService } from '@app/dir_group_assignor/officials/officials.service';
import { ClassUser } from '@models/user';
import { BtnComponent } from '@components/btn/btn.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { GetUserNamePipe } from '@pipes/get-name.pipe';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { PhotoComponent } from '@components/__svg_img/photo/photo.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DeviceService } from '@services/device.service';

export interface IPopupCreateGroupList {
  listName?: string;
  listOfficials?: Array<ClassUser>;
}

export interface IFormPopupCreateGroupList {
  listName?: FormControl<string>;
  listOfficials?: FormControl<Array<ClassUser>>;
}

@UntilDestroy()
@Component({
  selector: 'app-popup-create-group-list',
  standalone: true,
  imports: [CommonModule, BtnComponent, BtnWrapComponent, FieldComponent, FormsModule, GetUserNamePipe, InputCtrlComponent, PhotoComponent, ReactiveFormsModule, SvgComponent],
  templateUrl: './popup-create-group-list.component.html',
  styleUrls: ['./popup-create-group-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupCreateGroupListComponent extends HelperClass {
  form!: FormGroup<IFormPopupCreateGroupList>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public popupS: PopupService,
    public dialogRef: MatDialogRef<PopupCreateGroupListComponent>,
    public officialsS: OfficialsService,
    public cd: ChangeDetectorRef,
    public deviceS: DeviceService,
    @Inject(MAT_DIALOG_DATA) public dataPopup?: IPopupCreateGroupList, // {listOfficials: Array<ClassUser>},
  ) {
    super(cd);
    // console.log('CREATE GROUP :', dataPopup)
    this.createForm();
  }

  submit(): void {
    // console.log('CREATE GROUP :', this.form?.getRawValue())
    // return
    this.close(true);
    this.officialsS.methodGroupList(this.form?.getRawValue(), 'post');
    // if (this.startRequest()) return;
    // this.mainS.methodGroupList(this.form?.getRawValue(), 'post').toPromise()
    //   .then((res?: Array<ClassGroupListItem> | ClassGroupListItem) => {
    //     if (!res) return;
    //     this.officialsS.groupList = [...this.officialsS.groupList, res as ClassGroupListItem];
    //     this.close(true);
    //   })
    //   .catch((res) => {
    //   })
    //   .finally(() => this.endRequest());
  }

  deleteItem(idxUser: number): void {
    // this.listOfficials = this.listOfficials?.filter((el, idx) => idx !== idxUser);
    const listOfficials = this.ctrl.listOfficials?.value?.filter((el, idx) => idx !== idxUser);
    this.ctrl.listOfficials?.patchValue(listOfficials || []);
    this.cd.detectChanges();
  }

  // === FORM =================
  createForm(): void {
    // const officialIds: Array<string> = this.dataPopup?.officialIds ? this.dataPopup?.officialIds  :  this.dataPopup?.listOfficials?.map(el => el.id!) || []
    this.form = this.formBuilder.group({
      listName: [this.dataPopup?.listName || '', [Validators.required, Validators.minLength(3)]],
      // officialIds: [officialIds, Validators.required],
      listOfficials: [this.dataPopup?.listOfficials || [], Validators.required],
    });
  }

  get ctrl(): IFormPopupCreateGroupList {
    return this.form?.controls;
  }

  close(type: boolean): void {
    this.dialogRef.close(type);
  }

}
