<div *ngIf='{ arrContent: (meTableS.arrContent$|async)!,
              arrContentLength: (meTableS.arrContent$|async)?.length,
              loading: (meTableS.loadingTable$|async),
             } as obj' class='t-header'>

  <div *ngFor='let ceilHeader of (meTableS.arrHeaderTable$|async); let idxCeil=index' class='t-header__ceil'
       [class.t-isCentered]='ceilHeader.isCentered'
       ceilTable [idxCeil]='idxCeil' [isHeaderCeil]='true'
       [style]='ceilHeader.style'>

    <meSvg *ngIf='ceilHeader.isChx' [svgName]='(meTableS.isSelectAll$|async)!|getChxSvgName' class='t-header__ceil__chx'
           (click)='selectAll()' [disabled]='obj.loading || !obj.arrContentLength'>
    </meSvg>

    <span *ngIf='ceilHeader.text'>{{ ceilHeader.text }}</span>

    <sortBy *ngIf='ceilHeader.sortBy' [style]='{paddingRight: meTableS.paddingLeft_forCeil+"px"}'></sortBy>

  </div>
</div>

