import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainService } from '@services/main.service';
import { IResponse } from '@models/response-and-request';
import { BehaviorSubject, finalize, switchMap } from 'rxjs';
import { ReportService } from '@app/dir_group_assignor/reports/report.service';
import { MeService } from '@services/me.service';
import { Router } from '@angular/router';
import { DeviceService } from '@services/device.service';
import { FiltersService } from '@components/filters/filters.service';
import {
  ClassFilterDateRange,
  ClassFilterDrop,
  ClassFilterInput,
  IForClassForFilters,
  TAllInterfacesFilters,
} from '@components/filters/filters';
import { ClassReport, ClassReportStatusDrop, getArrReportStatusDrop } from '@app/dir_group_assignor/reports/report';
import { ClassCeilTableHeader, IForClassForTable } from '@components/_table/meTable';
import { arrPeriodsForPast } from '@components/__drop_inputs_matSelect/date-range/dateRange';
import { urlReportInfo } from '@app/app.module';
import { MeTableService } from '@components/_table/me-table.service';
import { HelperClass } from '@classes/Helper-Classes';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { CdkScrollable } from '@angular/cdk/overlay';
import { DataTableDirective } from '@components/_table/directives/data-table.directive';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { FormsModule } from '@angular/forms';
import {
  FiltersSelectedIconComponent,
} from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { BtnComponent } from '@components/btn/btn.component';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import { TableHeaderComponent } from '@components/_table/table-header/table-header.component';
import { TableCeilChxComponent } from '@components/_table/table-ceil-chx/table-ceil-chx.component';
import { ItemTableDirective } from '@components/_table/directives/item-table.directive';
import { TableBtnNumberComponent } from '@components/_table/table-btn-number/table-btn-number.component';
import { TableCeilComponent } from '@components/_table/table-ceil/table-ceil.component';
import { CeilTableDirective } from '@components/_table/directives/ceil-table.directive';
import { StopPropagationDirective } from '@directives/stop-propagation.directive';
import { StatusComponent } from '@components/status/status.component';
import { MatMenuModule } from '@angular/material/menu';
import { TableCeilActionsComponent } from '@components/_table/table-ceil-actions/table-ceil-actions.component';
import { TableInfiniteLoadingComponent } from '@components/_table/table-infinite-loading/table-infinite-loading.component';
import { TableEmptyComponent } from '@components/_table/table-empty/table-empty.component';
import { PaginationWithSettingsComponent } from '@components/__settingsRequest/pagination-with-settings/pagination-with-settings.component';
import { ComingSoonMobile } from '@app/dir_group_assignor/dashboard/components/coming-soon-mobile/coming-soon-mobile.component';
import { LinkPageSearchFilterComponent } from '@components/__settingsRequest/link-page-search-filter/link-page-search-filter.component';
import { IOutputObjStyles } from '@pipes/css/get-styles.pipe';
import { AdminPermissionDirective } from '@directives/admin-permission.directive';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';
import { colorObj } from '@classes/CSS';

@UntilDestroy()
@Component({
  selector: 'app-reports',
  standalone: true,
  imports: [CommonModule, WrapPageDirective, CdkScrollable, DataTableDirective, DropdownComponent,
    FormsModule, FiltersSelectedIconComponent, FiltersComponent, SvgComponent, BtnComponent, FiltersSelectedComponent,
    TableHeaderComponent, TableCeilChxComponent, ItemTableDirective, TableBtnNumberComponent, TableCeilComponent, CeilTableDirective,
    StopPropagationDirective, StatusComponent, MatMenuModule, TableCeilActionsComponent, TableInfiniteLoadingComponent,
    TableEmptyComponent, PaginationWithSettingsComponent, ComingSoonMobile, LinkPageSearchFilterComponent, AdminPermissionDirective, GetTableEmptyPipe],
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  providers: [FiltersService, MeTableService, SettingsRequestService], // for-filters=== for-table=== for-settings===
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// !!! IForClassForFilters for-filters===  // !!! IForClassForTable for-table=====
export class ReportsComponent extends HelperClass implements IForClassForFilters, IForClassForTable<ClassReport> {
  dataTable$ = new BehaviorSubject<IResponse<ClassReport>>({}); // for-table===
  arrContent$ = new BehaviorSubject<Array<ClassReport>>([]); // for-table===
  readonly urlReportInfo = urlReportInfo;
  readonly widthPage = 1300;
  arrReportStatusDrop: Array<ClassReportStatusDrop> = [];

  readonly textForBtn_approve = 'Approve & Pay'; // было Approve // https://notch.atlassian.net/browse/NOT30-516

  constructor(
    public mainS: MainService,
    private gameS: GameService,
    public reportS: ReportService,
    public meS: MeService,
    public router: Router,
    public deviceS: DeviceService,
    public filtersS: FiltersService, // for-filters===
    public meTableS: MeTableService<ClassReport>, // for-table===
    public settingsRequestS: SettingsRequestService, // for-settings===
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.arrReportStatusDrop = getArrReportStatusDrop(this.meS.OFFICIAL);
    this.reportS.getAmountReportsStatuses().pipe(untilDestroyed(this)).subscribe((res) => {
    });
    this.setAllData();
  }

  // !!! если есть в фильтрах компетишн, то обязательно надо в AppModule к роуту прикрутить CompetitionsForFiltersResolver
  setAllData(): void {
    // this.meTableS.needSave_selectedItems_byChangeSettings = true;
    this.setDataForTable(); // for-table===
    this.setFilters(); // for-filters===
    this.settingsRequestS.setAllData(this.isFirstLoadPageSub$); // for-settingsDynamicUrl=== создание ссылки, чтобы можно было использовать в сервисе
    this.reportS.setCurrentLink_reports(this.settingsRequestS.settings.currentLink_reports); // for-currentLink===
    this.subscribeToSettings(); // for-settings===
  }

  // === SETTINGS ===============================
  subscribeToSettings(): void {
    this.settingsRequestS.settings$.pipe(
      switchMap((res) => this.meTableS.getSubject_forGetReports()),
      untilDestroyed(this),
    ).subscribe((res) => {
      this.cd.detectChanges();
    });
  }

  // === FILTERS ==========================================
  setFilters(): void {
    const search = new ClassFilterInput({ typeFilter: 'search' });
    const dateRange = new ClassFilterDateRange({ arrPeriods: arrPeriodsForPast }); // typePeriod: 'past',
    const groupId = new ClassFilterDrop({ typeFilter: 'groupId', arrayForDropdown: this.meS.me?.officialGroups });
    const competition = new ClassFilterDrop({
      typeFilter: 'competitions',
      arrayForDropdown: this.gameS.arrCompetition$.getValue(),
      disabled: !this.gameS.arrCompetition$.getValue()?.length,
      // valueDrop: this.gameS.arrCompetition$.getValue()?.find(el => el.id === this.settingsRequestS.settings.competitions),
    });
    const arrFilters: Array<TAllInterfacesFilters> = this.meS.OFFICIAL ? [search, dateRange, groupId, competition] : [search, dateRange, competition];
    this.filtersS.setFilters(arrFilters, true, true);
  }

  // === TABLE ======================================================
  readonly styleForGame_CANCELLED: IOutputObjStyles = { color: colorObj.red }; // or #F12B2C50 opacity: 0.5
  setDataForTable(): void {
    this.meTableS.dataTable$ = this.dataTable$; // !!! создание ссылки. Чтобы можно было использовать в MeTableService
    this.meTableS.arrContent$ = this.arrContent$; // !!! создание ссылки. Чтобы можно было использовать в MeTableService
    const arrayCeilHeader: Array<ClassCeilTableHeader> = [
      new ClassCeilTableHeader({ text: 'Game', isChx: true }),
      new ClassCeilTableHeader({ text: 'Date & Time' }),
      new ClassCeilTableHeader({ text: 'Ages' }),
      new ClassCeilTableHeader({ text: 'Competition' }),
      new ClassCeilTableHeader({ text: 'Teams' }),
      new ClassCeilTableHeader({ text: 'Status' }),
      new ClassCeilTableHeader({ sortBy: true }),
    ];
    // !!! у 4 колонки отнял ширину в пользу последней. 318 - (98-54) = 274 // 21.05.24 https://notch.atlassian.net/browse/NOT30-516 текст не влезал Approve & Pay
    const arrWidthCeilTable: Array<number> = [153, 160, 145, 274, 297, 173, this.meTableS.minWidthFor_sortByCeil]; // !!! ширина для каждой ячейки для Desktop => сумма должна совпадать с this.widthPage
    this.meTableS.setArrTypeSorting(['byGameNumber_forReport', 'byDate_forReport']); // !!! for-sorting===
    this.meTableS.setDataForTable(arrWidthCeilTable, arrayCeilHeader, this.widthPage, true, null, true);
  }

  // === OTHER ACTIONS ============================
  // !!! reportItem если передал то это на конкретном репорте нажал Approve. Если не передал то это Approve All
  openPopupApprove(reportItem?: ClassReport): void {
    if (this.meS.OFFICIAL) return;
    // let arrIdsReport: Array<string> = reportItem ? [reportItem.id!] : this.meTableS.selectedItems_ids;
    let arrReport: Array<ClassReport> = reportItem ? [reportItem] : this.meTableS.selectedItems;
    // const arrIdsReport: Array<string> = arrReport.map(el => { // !!! убрать репорты со статусом PROCESSING
    //   if (el.status === 'NEEDS_APPROVAL') return el.id!;
    //   else return '';
    // }).filter(Boolean);

    // !!! оставить только репорты со статусом NEEDS_APPROVAL
    const arrIdsReport: Array<string> = arrReport.filter(el => el.status === 'NEEDS_APPROVAL').map(el => el.id!);
    if (!arrIdsReport?.length) return;
    if (this.startRequest()) return;
    this.reportS.openPopupApprove(arrIdsReport).then((resPopup: boolean) => {
      if (!resPopup) return this.endRequest();
      this.mainS.approveReport(arrIdsReport, 2000).pipe(
        switchMap((res) => this.reportS.getAmountReportsStatuses()),
        switchMap((res) => this.meTableS.getSubject_forGetReports()),
        finalize(() => this.endRequest()),
        untilDestroyed(this),
      ).subscribe((res) => {
      });
    });
  }

}
