import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass } from '@classes/Helper-Classes';
import { PhoneComponent } from '@components/__drop_inputs_matSelect/phone/phone.component';
import { LocationService } from '@components/__blocks/location/location.service';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@app/auth/auth.service';
import { MainService } from '@services/main.service';
import { MeService } from '@services/me.service';
import { RoutesService } from '@services/routes.service';
import { arrEmergencyRelationshipDrop, ClassUser, UserRegisteredStatus } from '@models/user';
import { CustomValidators } from '@classes/CustomValidators';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { BtnComponent } from '@components/btn/btn.component';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { LocationComponent } from '@components/__blocks/location/location.component';
import { SelectDateComponent } from '@components/date-time/select-date/select-date.component';
import { ChxComponent } from '@components/chx/chx.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { InvalidControlsPipe } from '@pipes/invalid-controls.pipe';
import { GoogleSignUpComponent } from '@components/google/components/google-sign-up/google-sign-up.component';
import { BtnBackComponent } from '@components/btn-back/btn-back.component';
import { CodeConfirmComponent } from '@components/code-confirm/code-confirm.component';
import { EmailPassStepComponent } from '@app/auth/commonComponents/email-pass-step/email-pass-step.component';
import { CodeConfirmStepComponent } from '@app/auth/commonComponents/code-confirm-step/code-confirm-step.component';
import { NameStepComponent } from '@app/auth/commonComponents/name-step/name-step.component';
import { LocationStepComponent } from '@app/auth/commonComponents/location-step/location-step.component';
import { PhoneStepComponent } from '@app/auth/commonComponents/phone-step/phone-step.component';
import { CustomDatesService } from '@classes/CustomDates';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { arrYesNoDrop, ClassDrop, ClassYesNo } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { arrSignupCertDropDown } from '@app/dir_group_assignor/games/game';
import { GetAgeByDateOfBirthPipe } from '@pipes/get-age-by-date-of-birth.pipe';
import { UtilsService } from '@services/utils.service';
import { IsLessAge18Pipe } from '@pipes/user/is-less-age18.pipe';
import { FormCustomService } from '@services/form-custom.service';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { tr } from 'date-fns/locale';
import { AppleSingUpComponent } from '../login/apple-sing-up/apple-sing-up.component';

type InvetationStatus = "ok" | "expired"
interface ISignupOfficialForm {
  // step == 1
  email: FormControl<string>;
  password: FormControl<string>;
  confirmPassword: FormControl<string>;

  // step == 3
  firstName: FormControl<string>;
  secondName: FormControl<string>;
  preferredName?: FormControl<string>;

  // step == 5
  phone: FormControl<string>;

  // step == 7
  socialSecurity: FormControl<string>;
  dateOfBirth: FormControl<string>;
  chxStep_8: FormControl<boolean>;

  // step == 8
  emergencyName: FormControl<string>;
  emergencyRelationshipDrop: FormControl<ClassDrop>;
  emergencyPhoneNumber: FormControl<string>;
  secondaryEmail: FormControl<string>; // add 27.06.24

  // step == 9
  startOfficiating: FormControl<string>;
  // certified: FormControl<'Yes' | 'No'>;
  certifiedDrop: FormControl<ClassYesNo>;
  // certificationName: FormControl<string>;
  certificationNameDrop: FormControl<ClassDrop>;
  CertificateRegistration: FormControl<string>;

  // step == 10
  acceptTerms: FormControl<boolean>;
}

@UntilDestroy()
@Component({
  selector: 'app-signup-official',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FieldComponent, InputCtrlComponent, BtnComponent, PhoneComponent, RouterLink, LocationComponent, SelectDateComponent, ChxComponent, DropFormCtrlComponent, InvalidControlsPipe, AppleSingUpComponent, BtnBackComponent, CodeConfirmComponent, EmailPassStepComponent, CodeConfirmStepComponent, NameStepComponent, LocationStepComponent, PhoneStepComponent, DropdownComponent, GetAgeByDateOfBirthPipe, IsLessAge18Pipe, SvgComponent],
  templateUrl: './signup-official.component.html',
  styleUrls: ['./signup-official.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignupOfficialComponent extends HelperClass implements OnInit {
  form!: FormGroup<ISignupOfficialForm>;
  step: UserRegisteredStatus = UserRegisteredStatus.PENDING;

  readonly arrSignupCertDropDown = arrSignupCertDropDown;
  readonly arrEmergencyRelationshipDrop = arrEmergencyRelationshipDrop;
  readonly arrYesNoDrop = arrYesNoDrop;

  groupName: string = '';
  certificateRegistrationError?: number;
  certificateRegistrationErrorMessage?: string;

  private readonly errorMessages: { [key: number]: string } = {
    409: 'A user with this USSF ID already exists - Please check and try again or contact your assignor if you believe this is an error',
    422: 'We couldn’t verify this USSF ID - Please check and try again',
  };

  UserRegisteredStatus = UserRegisteredStatus;

  constructor(
    public authS: AuthService,
    private formBuilder: UntypedFormBuilder,
    public mainS: MainService,
    public meS: MeService,
    public locationS: LocationService,
    public routesS: RoutesService,
    public customDatesS: CustomDatesService,
    public formCustomS: FormCustomService,
    public cd: ChangeDetectorRef,
    public route: ActivatedRoute,
  ) {

    super(cd);
    this.createForm(this.meS.me);
  }

  async ngOnInit(): Promise<void> {
    try {
      this.startRequest();
      // if (this.meS.me?.id == null) {        
      await this.checkInvetationActive();
      // }
      this.initPage();
      this.getGroupNameById();
    } finally {
      this.endRequest();
    }
  }

  private initPage() {
    this.subscribeToForm();
    this.initStep();
  }
  private async checkInvetationActive(): Promise<boolean> {
    const invitationId = this.route.snapshot.queryParamMap.get("invitationId");
    if (!invitationId) {
      this.step = UserRegisteredStatus.INVITATION_EXPIRED;
      return false;
    }

    try {
      const invetationStatus = await this.mainS.getInvitationStatus(invitationId);
      if (invetationStatus == "expired") {
        this.step = UserRegisteredStatus.INVITATION_EXPIRED;
        return false;
      }

      if (invetationStatus == "ok") {
        return true;
      }
      location.reload();
      throw new Error("invetationStatus Unknown");
    } catch (error) {
      this.step = UserRegisteredStatus.INVITATION_EXPIRED;
      return false;
    }


  }

  public isShowStep(statusStep: UserRegisteredStatus[]): boolean {
    return statusStep.some(x => x == this.step);
  }

  private initStep() {
    const userRegisteredStatus: string = this.route.snapshot.queryParams['userRegisteredStatus'];
    const customStepList = [{
      status: UserRegisteredStatus.PENDING,
      action: () => {
        if (this.meS.me?.emailValidated) {
          this.step = UserRegisteredStatus.NAME_ENTERED;//code email
        } else {
          this.step = UserRegisteredStatus.EMAIL_VERIFIED;//code email
          this.mainS.resendCode(this.meS.meEmail, 'email').toPromise();
        }
      }
    }, {
      status: UserRegisteredStatus.MOBILE_ENTERED,
      action: () => {
        this.mainS.resendCode(this.meS.mePhone, 'phone').subscribe(x => {
          console.log(x);
        });
        this.step = UserRegisteredStatus.MOBILE_VERIFIED;//code email
      }
    },
    ];

    if (userRegisteredStatus) {
      if (userRegisteredStatus != null && this.meS.me?.id == null) {
        this.routesS.navigate(['/login']);
        return;
      }
      const customStep = customStepList.find(x => x.status === userRegisteredStatus);
      if (customStep) {
        customStep.action();
      }
      else {
        this.step = this.stepList.find(x => x == userRegisteredStatus)!;
        this.nextStepEvent()
      }
    }
  }

  signUpOfficial(): void {
    this.form.markAllAsTouched();
    this.mainS.confirmMe(this.meS.meId);
  }

  emailPassStep(user: ClassUser | undefined) {
    this.meS.setMeUser({ ...this.meS.me, email: user?.email });
    if (user?.emailValidated && !user?.phoneValidated)
      this.step = UserRegisteredStatus.NAME_ENTERED;
    else if (user?.emailValidated && user?.phoneValidated)
      this.step = UserRegisteredStatus.DATE_OF_BIRTH
    else
      this.nextStepEvent();
  }

  emit(event: any): void {
    if (event == 'back') this.backStepEvent(); // if (type == 'back' || event == 'back') this.step -= 1;
    if (event == 'next') this.nextStepEvent(); // if (type == 'next' || event == 'next') this.step += 1;

    if (this.step === UserRegisteredStatus.PENDING) {
      const res: ClassUser & { withGoogle: boolean } = event;
    }

    if (this.step === UserRegisteredStatus.MOBILE_ENTERED) { // if (type == 'phone')
      const res: string = event;
      this.ctrl.phone.patchValue(res);
      this.nextStepEvent();
    }
  }

  changeValNameStep(event: { firstName: string, secondName: string, preferredName?: string }): void {
    this.ctrl.firstName?.patchValue(event.firstName);
    this.ctrl.secondName?.patchValue(event.secondName);
    this.ctrl.preferredName?.patchValue(event.preferredName || '');
    this.meS.setMeUser({ ...this.meS.me, ...event });
    this.nextStepEvent();
  }

  isCertificateRegistrationEmpty(): boolean {
    const value = this.form.get('CertificateRegistration')?.value;
    return typeof value === 'string' && value.trim() === '';
  }

  removeHyphens(event: any) {
    event.target.value = event.target.value.replace(/-/g, '');
  }

  isNextButtonDisabled(): boolean {
    const startOfficiatingInvalid = this.form.get('startOfficiating')?.invalid;
    const certifiedInvalid = this.form.get('certifiedDrop')?.invalid;
    const certificationNameInvalid = this.form.get('certificationNameDrop')?.invalid;

    const certified = this.form.get('certifiedDrop')?.value?.valueBoolean;
    const certificationName = this.form.get('certificationNameDrop')?.value?.upperCase;
    let certificateRegistration = this.form.get('CertificateRegistration')?.value?.trim() || '';

    certificateRegistration = certificateRegistration.replace(/-/g, '');
    const ussfCertificationRequired = certified !== undefined && certificationName === 'UNITED STATES SOCCER FEDERATION (USSF)';
    const ussfCertificationPleaseSelect = certified && certificationName === 'PLEASE SELECT';
    const certificateRegistrationInvalid = ussfCertificationRequired && (!certificateRegistration || !/^\d{16}$/.test(certificateRegistration));
    const certifiedNo = certified === false;
    const startOfficiatingValid = !startOfficiatingInvalid;
    return (certifiedInvalid || certificationNameInvalid || ussfCertificationPleaseSelect || certificateRegistrationInvalid) && !(certifiedNo);
  }

  // === FORM =================
  createForm(user: ClassUser | undefined): void {
    this.form = this.formBuilder.group({
      // step == 7
      socialSecurity: ['', [Validators.required]],
      dateOfBirth: ['', [Validators.required]],
      chxStep_8: [false, [Validators.required]],

      // step == 8
      emergencyName: ['', [Validators.required]],
      emergencyRelationshipDrop: ['', [Validators.required]],
      emergencyPhoneNumber: ['', [Validators.required]],
      secondaryEmail: [''],

      // step == 9
      startOfficiating: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      certifiedDrop: [new ClassYesNo(false), [Validators.required]],
      certificationNameDrop: [arrSignupCertDropDown[0]],
      CertificateRegistration: [''],

      // step == 10
      acceptTerms: [false, [Validators.required]],
    }, { validators: CustomValidators.password });
  }

  get ctrl(): ISignupOfficialForm {
    return this.form?.controls;
  }

  subscribeToForm(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res?.dateOfBirth) {
        const isLessAge18 = UtilsService.isLessAge18(res?.dateOfBirth);
        if (isLessAge18) {
          this.ctrl.secondaryEmail.addValidators([Validators.required, CustomValidators.patternEmail]);
          this.ctrl.secondaryEmail.updateValueAndValidity(this.formCustomS.optionsForPatchValue);
        } else {
          this.ctrl.secondaryEmail.clearValidators();
          this.ctrl.secondaryEmail.updateValueAndValidity(this.formCustomS.optionsForPatchValue);
        }
      }
    });
  }

  nextStep_for_startOfficiating(): void {
    if (this.isNextButtonDisabled()) return; // If the next button is disabled due to validation errors, do nothing
    if (this.ctrl.certificationNameDrop.value?.upperCase !== 'UNITED STATES SOCCER FEDERATION (USSF)' || !this.ctrl.certifiedDrop.value?.valueBoolean) {
      this.saveOfficial(this.saveUserStatus());
      this.nextStepEvent();
      return;
    }
    if (this.isCertificateRegistrationEmpty()) {
      console.error('CertificateRegistration should not be empty');
      return;
    }

    if (this.ctrl.certifiedDrop.value?.valueBoolean && this.ctrl.certificationNameDrop.value?.upperCase === 'UNITED STATES SOCCER FEDERATION (USSF)') {
      // const certificateRegistration = this.ctrl.CertificateRegistration.value;
      const certificateRegistration = this.ctrl.CertificateRegistration.value.replace(/-/g, '');
      const email = this.meS.meEmail;// this.ctrl.email.value; // Assuming email is a required field
      if (certificateRegistration && email) {
        this.startRequest();
        this.mainS.addOfficialSignupOrganizationId(certificateRegistration, email).pipe(
          finalize(() => {
            this.endRequest();
          }),
        ).subscribe(
          (res) => {
            console.log('Organization ID added successfully:', res);
            this.saveOfficial(this.saveUserStatus());
            this.nextStepEvent();
          },
          (error) => {
            console.error('Error adding organization ID:', error);
            if (error.status === 409 || error.status === 422) {
              this.certificateRegistrationError = error.status;
              this.certificateRegistrationErrorMessage = this.errorMessages[error.status];
            } else {
              console.error('Unknown error occurred');
            }
          },
        );
      } else {
        console.error('CertificateRegistration or email should not be empty');
        return;
      }
    } else {
      this.saveOfficial(this.saveUserStatus());
      this.nextStepEvent();
    }
  }

  private readonly stepList = [
    UserRegisteredStatus.PENDING,//0
    UserRegisteredStatus.EMAIL_VERIFIED,//1
    UserRegisteredStatus.NAME_ENTERED,//2
    UserRegisteredStatus.RESIDENTIAL_ADDRESS_ENTERED,//3
    UserRegisteredStatus.MOBILE_ENTERED,//4
    UserRegisteredStatus.MOBILE_VERIFIED,//4
    UserRegisteredStatus.DATE_OF_BIRTH,//6
    UserRegisteredStatus.EMERGENCY_CONTACT,
    UserRegisteredStatus.START_OFFICIATING,
    UserRegisteredStatus.REGISTERED];

  nextStepEvent(): void { // proceedToNextStep():
    const stepIndex = this.stepList.indexOf(this.step);
    this.step = this.stepList[stepIndex + 1];
  }

  saveUserStatus() {
    this.mainS.UpdateUserInfo({
      id: this.meS.me?.id,
      userRegisteredStatus: this.step
    }).toPromise();
  }
  saveUser() {
    this.mainS.UpdateUserInfo({
      ...this.form.value,
      id: this.meS.me?.id,
      userRegisteredStatus: this.step
    }).toPromise();
  }

  saveOfficial(cb: any = null): void {
    if (this.startRequest())
      return;
    const certificateRegistration = this.ctrl.CertificateRegistration.value.replace(/-/g, '');
    const sendObj: ClassUser = {
      userId: this.meS.meId,
      groupId: this.authS.queryParams_forRegistration?.groupId!,
      firstName: this.meS.me?.firstName,
      secondName: this.meS.me?.secondName,
      preferredName: this.meS.me?.preferredName,
      email: this.meS.me?.email,
      phone: this.meS.me?.phone,
      emergencyName: this.ctrl.emergencyName.value, // step == 7
      emergencyRelationship: this.ctrl.emergencyRelationshipDrop.value?.upperCase, // step == 7
      emergencyPhoneNumber: this.ctrl.emergencyPhoneNumber.value, // step == 7
      dateOfBirth: this.ctrl.dateOfBirth.value, // step == 8 2023-09-05
      socialSecurity: this.ctrl.socialSecurity.value, // step == 8
      secondaryEmail: this.ctrl.secondaryEmail.value || this.meS.me?.email,  // !!! если возраст больше 18, то надо всё равно вставить сюда емайл который на 1 шаге ввел
      startOfficiating: this.ctrl.startOfficiating.value, // step == 9
      certified: this.ctrl.certifiedDrop.value?.valueBoolean, // step == 9
      certificationName: this.ctrl.certificationNameDrop.value?.upperCase, // step == 9
      CertificateRegistration: certificateRegistration,
      acceptTerms: this.ctrl.acceptTerms.value, // step == 10
    };
    this.mainS.signUpOfficial(sendObj).toPromise()
      .then((res) => {
        cb && cb();
      })
      .catch((err) => {
      })
      .finally(() => {
        this.endRequest();
        // this.form.markAsUntouched();
        this.cd.detectChanges();
      });
  }

  backStepEvent(): void { // proceedToNextStep():
    const stepIndex = this.stepList.indexOf(this.step);
    if (stepIndex == 0)
      return;
    this.step = this.stepList[stepIndex - 1];
  }

  phoneStepEvent(phoneNumber: string) {
    this.meS.setMeUser({ ...this.meS.me, phone: phoneNumber });
    this.nextStepEvent();
  }

  getGroupNameById(): void {
    const groupId = this.authS.queryParams_forRegistration?.groupId!;
    this.mainS.getGroupNameById(groupId).subscribe(
      (res) => {
        this.groupName = res;
      },
      (err) => {
      },
    );
  }
}
