<div class='wrapperPopup'>
  <headTitle [text]='textTitle' svgRight='cross_grey&24' (svgRightEmit)='close(false)' [typeText]='typeTextTitle'></headTitle>

  <div *ngIf='text' class='wrapperPopup__text'>{{ text }}</div>

  <btnWrap [isAnnouncement]='true' class='web' [swapBtn]='swapBtn'>
    <btn class='assignremove' [text]='textBtnCancel' [bcg]='colorBtnCancel' (emit)='close(false)' btnWidth='170'></btn>
    <btn class='assignReturn' [text]='textBtnApply' [bcg]='colorBtnApply' (emit)='close(true)' btnWidth='170'></btn>
  </btnWrap>

  <btnWrap [isAnnouncement]='true' class='mobile' [swapBtn]='swapBtn'>
    <btn class='assignReturn' [text]='textBtnApply' [bcg]='colorBtnApply' (emit)='close(true)' btnWidth='100%'></btn>
    <btn class='assignremove' [text]='textBtnCancel' [bcg]='colorBtnCancel' (emit)='close(false)' btnWidth='100%'></btn>
  </btnWrap>
</div>

