import { Component, Input, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CSVBoxAngularModule } from "@csvbox/angular";
import { ClassCompetition } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
@Component({
  selector: "csv-import-csvbox",
  imports: [CSVBoxAngularModule],
  standalone: true,
  template: `
    <csvbox-button
      [licenseKey]="licenseKey"
      [user]="userData"
      [onImport]="onData.bind(this)"
    >
    Next Step
    </csvbox-button>
  `
})
export class CsvImportCsvboxComponent implements CSVBoxAngularModule, OnInit {
constructor(private router: Router) {}

  title = "example";
  licenseKey: string = ''; // Initialize licenseKey as an empty string
  defaultLicenseKey: string = 'XqUvS06YtZKgikljI1yuSRzepOmlYy';
  //staging
 // licenseKey: string = 'krubuqzzb0jZIZd6N23PFKZ2FZmAPh';
 //beta
 // licenseKey: string ='XqUvS06YtZKgikljI1yuSRzepOmlYy';
  @Input() user: any;
  @Input() selectedCompetition: ClassCompetition | undefined;
  // user: any = { user_id: '1cdc9ed0-c256-4c69-99f7-7be73d8e6542', competition_id: '070a6105-35a0-4dca-8ba3-bd006caf3963' };

  // ngOnInit(): void {
  //   setTimeout(() => {
  //    const btn = document.querySelector("button[data-csvbox-token]");
  //    btn && btn.removeAttribute('disabled');
  //   })
  //  }

  ngOnInit(): void {
    setTimeout(() => {
      const btn = document.querySelector("button[data-csvbox-token]");
      btn && btn.removeAttribute('disabled');
      // if (this.selectedCompetition?.id) {
      //   btn && btn.removeAttribute('disabled');
      // }
    });
        // Set licenseKey based on window.location.href.includes
        if (window.location.href.includes('staging')) {
          this.licenseKey = 'krubuqzzb0jZIZd6N23PFKZ2FZmAPh';
        } else if (window.location.href.includes('beta')) {
          this.licenseKey = 'XqUvS06YtZKgikljI1yuSRzepOmlYy';
        } else if(window.location.href.includes('live'))
          this.licenseKey = '4JBlgv6P4JIKPpr7QNJxOtseUMO4DU';
        else {
          this.licenseKey = this.defaultLicenseKey;
        }
        console.log('this.licenseKey :>> ', this.licenseKey);
  }



  onData(result: boolean, data: any) {

    if (result) {
      console.log("Sheet uploaded successfully");
      console.log(data.row_success + " rows uploaded");
      // navigate this.router.navigate(['/games']);
      this.router.navigate(['/games']);
      console.log("navigate to games page");
    } else {
      console.log("There was some problem uploading the sheet");
    }
  }

  get userData() {
    console.log('this.selectedCompetition :>> ', this.selectedCompetition);
    return {
      user_id: this.user,
      competition_id: this.selectedCompetition?.id,

    }
  }
}
