import { Pipe, PipeTransform } from '@angular/core';
import { OtherService } from '@services/other.service';

// взять только первое и последнее слово из строки. Тоесть всё что посередине - убрать
@Pipe({ name: 'getStartEndWordFromString', standalone: true })
export class GetStartEndWordFromStringPipe implements PipeTransform {

  constructor(
    private otherS: OtherService,
  ) {
  }

  transform(str: string): string {
    return this.otherS.getStartEndWordFromString(str);
  }

}
