import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnComponent } from '@components/btn/btn.component';
import { GetGreetPipe } from '@app/dir_group_assignor/dashboard/pipes/get-greet.pipe';
import { GetSubtitleDashboardPipe } from '@app/dir_group_assignor/dashboard/pipes/get-subtitle-dashboard.pipe';
import { DashboardService } from '@app/dir_group_assignor/dashboard/dashboard.service';
import { DeviceService } from '@services/device.service';
import { GreetComponent } from '@app/dir_group_assignor/dashboard/components/greet/greet.component';

@Component({
  selector: 'app-stepsOnboarding',
  standalone: true,
  imports: [CommonModule, BtnComponent, GetGreetPipe, GetSubtitleDashboardPipe, GreetComponent],
  templateUrl: './steps-onboarding.component.html',
  styleUrls: ['./steps-onboarding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepsOnboardingComponent {
  constructor(
    public dashboardS: DashboardService,
    public deviceS: DeviceService,
  ) {
  }
}
