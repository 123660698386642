import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MainService } from '@services/main.service';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { arrUserRoleDrop, ClassUser, TUserRoleDrop } from '@models/user';
import { ClassCompetition } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { FormsModule } from '@angular/forms';
import { BtnComponent } from '@components/btn/btn.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { HelperClass } from '@classes/Helper-Classes';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IDataPopup } from '@services/popup.service';

@UntilDestroy()
@Component({
  selector: 'app-popup-assign-new-role',
  standalone: true,
  imports: [CommonModule, HeadTitleComponent, FieldComponent, DropdownComponent, FormsModule, BtnComponent, BtnWrapComponent],
  templateUrl: './popup-assign-new-role.component.html',
  styleUrls: ['./popup-assign-new-role.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupAssignNewRoleComponent extends HelperClass {
  user: ClassUser;
  readonly arrUserRoleDrop = arrUserRoleDrop;
  selectedUserRoleDrop?: TUserRoleDrop;
  arrSelectedCompetition: Array<ClassCompetition> = [];

  constructor(
    public dialogRef: MatDialogRef<PopupAssignNewRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public dataPopup: IDataPopup,
    public mainS: MainService,
    public gameS: GameService, // !!! перенести потом массив компетишнов в CompetitionService
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.user = dataPopup.user!;
  }

  submit(): void {
    // if (this.startRequest()) return;
    // this.mainS.assignNewRole
    console.log('selectedUserRoleDrop :', this.selectedUserRoleDrop);
    console.log('arrSelectedCompetition :', this.arrSelectedCompetition);
  }

  close(type: boolean): void {
    this.dialogRef.close(type);
  }

}
