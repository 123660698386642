<div class='wrapperStatus wrapperStatus--{{statusClass}}'>
  <!--  {{status|titlecase}}-->
  {{(strCase ? (status|strCase:strCase) : status) | titlecase}}
</div>


<!-- <div *ngIf="text==null" class='wrapperStatus wrapperStatus--{{statusClass}}'></div>
  {{(strCase ? (status|strCase:strCase) : status) | titlecase}}
</div>

<div *ngIf="text!=null" class='color--{{color}}'>
  {{text}}
</div> -->