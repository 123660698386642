import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  arrTypeFilter_forDateRange,
  arrTypeFilter_forDrop,
  arrTypeFilter_forInput,
  ClassFilterDateRange,
  ClassFilterDrop,
  ClassFilterInput,
  TAllInterfacesFilters,
  TypeFilter,
  TypeFilter_dateRange,
  TypeFilter_drop,
  TypeFilter_string,
  TypesAllFilters,
} from '@components/filters/filters';
import {
  ClassDrop,
  getCurrentAgeItemByFromAgeAndToAge,
  getCurrentDistanceItem,
} from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { customDateRange } from '@components/__drop_inputs_matSelect/date-range/dateRange';
import { MeService } from '@services/me.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { arrDropStatusAssign, getArrDropStatusAssignFromString } from '@app/dir_group_assignor/games/game';
import { arrPayoutFormatDrop, ClassCompetition } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { UtilsService } from '@services/utils.service';
import { ClassGroupListItem } from '@models/IGroup';
import { DeviceService } from '@services/device.service';
import { TypeSortTable } from '@components/sortBy/sortBy';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { TPath } from '@app/app.module';
import { AddFiltersComponent } from './add-filters/add-filters.component';

@UntilDestroy()
@Injectable()
export class FiltersService {
  filtersRef?: FiltersComponent;
  filterAddsRef?: AddFiltersComponent;

  needUseDynamicFilters = false;

  showFilters_forMobile$ = new BehaviorSubject<boolean>(false); 
  showSelectedFilters = false; 
  sortBy_forMobile = false; 
  selected_sortBy?: TypeSortTable; 

  arrFiltersSub$ = new BehaviorSubject<Array<TAllInterfacesFilters>>([]);
  arrFilters$ = this.arrFiltersSub$.asObservable();

  readonly matTooltip_for_search = 'Jump to game numbers, locations, team and official names';
  readonly arrPathExcludes_for_matTooltip_for_search: Array<TPath> = ['competitions', 'officials']; 

  get arrFilters(): Array<TAllInterfacesFilters> {
    return this.arrFiltersSub$.getValue();
  }

  get searchValue(): string | undefined {
    const searchFilter = this.arrFiltersSub$.getValue().find((el) => el.typeFilter === 'search') as ClassFilterInput | undefined;
    return searchFilter?.valueString;
  }

  constructor(
    private deviceS: DeviceService,
    private meS: MeService,
    private gameS: GameService,
    private settingsRequestS: SettingsRequestService, // for-settings===
  ) {
    this.subscribeToDevice();
    this.subscribeToSettings();
  }

  subscribeToDevice(): void {
    this.deviceS.isDesktop$.pipe(untilDestroyed(this)).subscribe((isDesktop) => {
      if (isDesktop) this.showFilters_forMobile$.next(false); 
    });
  }

  checkExistChangesFilter(newFilter: TAllInterfacesFilters): boolean {
    const currentFilter = this.arrFiltersSub$.getValue().find(el => el.typeFilter === newFilter.typeFilter);
    const existChanges = !UtilsService.compareTwoObjects(
      UtilsService.removeEmptyKeysFromObject(currentFilter!),
      UtilsService.removeEmptyKeysFromObject(newFilter),
    );
    return existChanges;
  }

  // === SETTINGS ===========================
  async subscribeToSettings(): Promise<void> {
    this.settingsRequestS.settingsSub$.pipe(untilDestroyed(this)).subscribe(async (res) => {
      if (res.sort) {
        this.selected_sortBy = res.sort as TypeSortTable;
      } else {
        this.selected_sortBy = undefined;
      }

      const currentArrFilters = this.arrFiltersSub$.getValue();
      let newArrFilters: Array<TAllInterfacesFilters> = [];
      if (res.search) {
        const currentFilter = currentArrFilters.find(el => el.typeFilter === 'search');
        if (!currentFilter) return;
        const newFilter = new ClassFilterInput({ ...currentFilter as ClassFilterInput, valueString: res.search });
        if (this.checkExistChangesFilter(newFilter)) newArrFilters.push(newFilter);
      } else {
        const filterWithoutValue = this.resetValueDrop('search'); 
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }

      if (res.zipcode) {
        const currentFilter = currentArrFilters.find(el => el.typeFilter === 'zipcode');
        if (!currentFilter) return;
        const newFilter = new ClassFilterInput({ ...currentFilter as ClassFilterInput, valueString: res.zipcode });
        if (this.checkExistChangesFilter(newFilter)) newArrFilters.push(newFilter);
      } else {
        const filterWithoutValue = this.resetValueDrop('zipcode'); 
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }

      if (res.from || res.to) {
        const currentFilter = currentArrFilters.find(el => el.typeFilter === 'dateRange') as ClassFilterDateRange;
        if (!currentFilter) return;
        const newFilter = new ClassFilterDateRange({
          ...currentFilter as ClassFilterDateRange,
          typePeriod: res.currentLink_games || 'current',
          period: 'Custom Date Range',
          datePeriod: {},
        });
        if (res.from) newFilter.datePeriod!.from = res.from;
        if (res.to) newFilter.datePeriod!.to = res.to;
        if (this.settingsRequestS.is_currentPath_games && res.from && res.to) { 
          newFilter.fromTo_formatted = UtilsService.check_fromTo_formatted({ from: res.from, to: res.to });
        } else if (this.settingsRequestS.is_currentPath_games && (res.from || res.to)) { 
          newFilter.fromTo_formatted = UtilsService.check_fromTo_formatted({ from: res.from, to: res.to });
        }
        if (this.settingsRequestS.is_currentPath_reports && (res.from || res.to)) {
          newFilter.fromTo_formatted = UtilsService.check_fromTo_formatted({ from: res.from, to: res.to });
        }
        if (this.checkExistChangesFilter(newFilter)) {
          newArrFilters.push(newFilter);
        } else {
        }
      } else {
        const filterWithoutValue = this.resetValueDrop('dateRange'); 
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }

      if (res.groupId) {
        const current_groupFilter = currentArrFilters.find(el => el.typeFilter === 'groupId')!;
        const new_groupFilter = new ClassFilterDrop({
          ...current_groupFilter,
          typeFilter: 'groupId',
          arrayForDropdown: this.meS.me?.officialGroups || [],
          valueDrop: this.meS.me?.officialGroups?.find(group => group.id === res.groupId),
        });

        await this.gameS.getArrCompetition(res.groupId).toPromise()
          .then((res) => {
            const competitionFilter = currentArrFilters.find(el => el.typeFilter === 'competitions') as ClassFilterDrop;

            const newCompetitionFilter = new ClassFilterDrop({
              ...competitionFilter,
              typeFilter: 'competitions',
              arrayForDropdown: res?.content || [],
              disabled: false,
            });
            newArrFilters.push(new_groupFilter);
            newArrFilters.push(newCompetitionFilter);
          });
      } else {
        const filterWithoutValue = this.resetValueDrop('groupId');
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }

      if (res.competitions) {
        let competitionFilter_fromNewArrFilters = newArrFilters.find(el => el.typeFilter === 'competitions') as ClassFilterDrop;
        let idx_competitionFilter_fromNewArrFilters = newArrFilters.findIndex(el => el.typeFilter === 'competitions');
        if (!competitionFilter_fromNewArrFilters) {
          competitionFilter_fromNewArrFilters = currentArrFilters.find(el => el.typeFilter === 'competitions') as ClassFilterDrop;
          idx_competitionFilter_fromNewArrFilters = currentArrFilters.findIndex(el => el.typeFilter === 'competitions');
        }
        competitionFilter_fromNewArrFilters = UtilsService.removeEmptyKeysFromObject(competitionFilter_fromNewArrFilters);
        const arrCompetition = competitionFilter_fromNewArrFilters.arrayForDropdown as Array<ClassCompetition> || this.gameS.arrCompetition$.getValue();
        const newFilter = new ClassFilterDrop({
          typeFilter: 'competitions',
          arrayForDropdown: arrCompetition,
          ...competitionFilter_fromNewArrFilters,
          valueDrop: arrCompetition?.find(el => el.id === res.competitions),
        });
        if (this.checkExistChangesFilter(newFilter)) {
          newArrFilters = UtilsService.replaceElemArrayByIdx(newArrFilters, newFilter, idx_competitionFilter_fromNewArrFilters);
        }
      } else {
        const filterWithoutValue = this.resetValueDrop('competitions'); 
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }


      if (res.assignStatuses) {
        const currentFilter = currentArrFilters.find(el => el.typeFilter === 'assignStatuses');
        if (!currentFilter) return;
        const newFilter = new ClassFilterDrop({
          ...currentFilter,
          typeFilter: 'assignStatuses',
          arrayForDropdown: arrDropStatusAssign,
          multi: true,
          valueDrop: getArrDropStatusAssignFromString(res.assignStatuses),
        });

        if (this.checkExistChangesFilter(newFilter)) newArrFilters.push(newFilter);
      } else {
        const filterWithoutValue = this.resetValueDrop('assignStatuses'); 
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }

      if (res.distance) {
        const currentFilter = currentArrFilters.find(el => el.typeFilter === 'distance');
        if (!currentFilter) return;
        const newFilter = new ClassFilterDrop({
          ...currentFilter,
          typeFilter: 'distance',
          valueDrop: getCurrentDistanceItem(res.distance),
        });
        if (this.checkExistChangesFilter(newFilter)) newArrFilters.push(newFilter);
      } else {
        const filterWithoutValue = this.resetValueDrop('distance'); 
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }
      if (res.fromAge || res.toAge) {
        const currentFilter = currentArrFilters.find(el => el.typeFilter === 'ages');
        if (!currentFilter) return;
        const newFilter = new ClassFilterDrop({
          ...currentFilter,
          typeFilter: 'ages',
          valueDrop: getCurrentAgeItemByFromAgeAndToAge(res.fromAge, res.toAge),
        });
        if (this.checkExistChangesFilter(newFilter)) {
          newArrFilters.push(newFilter);
        }
      } else {
        const filterWithoutValue = this.resetValueDrop('ages'); 
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }
      if (res.officialList) {
        const currentFilter = currentArrFilters.find(el => el.typeFilter === 'officialList');
        if (!currentFilter) return;
        const arrId_officialList_fromQueryParams = res.officialList.split(',');
        const arrDrop_officialList_fromQueryParams = (currentFilter as ClassFilterDrop).arrayForDropdown
          ?.filter((arrGroupListItem: ClassGroupListItem) => arrId_officialList_fromQueryParams.includes(arrGroupListItem.id!)) as Array<ClassDrop>;
        const newFilter = new ClassFilterDrop({
          ...currentFilter,
          typeFilter: 'officialList',
          valueDrop: arrDrop_officialList_fromQueryParams,
          valueFromUrl: res.officialList,
        });

        if (this.checkExistChangesFilter(newFilter)) newArrFilters.push(newFilter);
      } else {
        const filterWithoutValue = this.resetValueDrop('officialList'); 
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }

      if (res.officialStatus) {
        const currentFilter = currentArrFilters.find(el => el.typeFilter === 'officialStatus');
        if (!currentFilter) return;
        const newFilter = new ClassFilterDrop({ ...currentFilter, typeFilter: 'officialStatus' });
        if (this.checkExistChangesFilter(newFilter)) newArrFilters.push(newFilter);
      } else {
        const filterWithoutValue = this.resetValueDrop('officialStatus'); 
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }

      if (res.seasons) {
        const currentFilter = currentArrFilters.find(el => el.typeFilter === 'seasons');
        if (!currentFilter) return;
        const newFilter = new ClassFilterDrop({ ...currentFilter, typeFilter: 'seasons' });
        if (this.checkExistChangesFilter(newFilter)) newArrFilters.push(newFilter);
      } else {
        const filterWithoutValue = this.resetValueDrop('seasons'); 
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }

      if (res.payoutFormat) {
        const currentFilter = currentArrFilters.find(el => el.typeFilter === 'payoutFormat');
        if (!currentFilter) return;
        const newFilter = new ClassFilterDrop({
          ...currentFilter,
          typeFilter: 'payoutFormat',
          arrayForDropdown: arrPayoutFormatDrop,
          valueDrop: arrPayoutFormatDrop?.find(el => el.upperCase === <any>res.payoutFormat),
        });
        if (this.checkExistChangesFilter(newFilter)) newArrFilters.push(newFilter);
      } else {
        const filterWithoutValue = this.resetValueDrop('payoutFormat'); 
        if (filterWithoutValue) newArrFilters.push(filterWithoutValue);
      }
      if (newArrFilters?.length) this.updateAllFilters(newArrFilters, '  subscribeToSettings ');
    });
  }

  // === SET / UPDATE FILTERS =================================================
  // !!! === is called only 1 time when the page loads
  setFilters(allFilters: Array<TAllInterfacesFilters>, needUseDynamicFilters: boolean, sortBy_forMobile: boolean): void {
    this.needUseDynamicFilters = needUseDynamicFilters;
    this.sortBy_forMobile = sortBy_forMobile;
    const newArrFilters: Array<TAllInterfacesFilters> = [];
    allFilters.forEach((el, idx) => {
      const newValue: TAllInterfacesFilters = this.getUpdatedFilter(el, { index: el?.index || idx, forTest: '  setFilters ' });
      newArrFilters.push(newValue);
    });
    if (newArrFilters?.length) this.arrFiltersSub$.next(newArrFilters);
  }

  updateAllFilters(allFilters: Array<TAllInterfacesFilters>, forTest?: string): void {
    let newArrFilters: Array<TAllInterfacesFilters> = this.arrFiltersSub$.getValue() || [];
    newArrFilters?.forEach((el, idx) => {
      const newElem = allFilters?.find(item => item?.typeFilter == el?.typeFilter);
      if (!newElem) return;
      if (el.typeFilter === newElem.typeFilter) {
        const newValue: TAllInterfacesFilters = this.getUpdatedFilter(el, { ...newElem, forTest: ' updateAllFilters => ' + forTest });
        newArrFilters = this.replaceFilterInArray(newArrFilters, newValue);
      }
    });
    newArrFilters?.forEach((el) => {

    });
    this.arrFiltersSub$.next(newArrFilters);
  }

  private getUpdatedFilter(oldFilter: TAllInterfacesFilters, newFilter: TAllInterfacesFilters): TAllInterfacesFilters {
    let newValue: TAllInterfacesFilters = { ...oldFilter, ...newFilter };
    if (this.is_TypeFilter_string(oldFilter.typeFilter!)) newFilter = new ClassFilterInput(newFilter as ClassFilterInput);
    if (this.is_TypeFilter_dateRange(oldFilter.typeFilter!)) newFilter = new ClassFilterDateRange(newFilter as ClassFilterDateRange);
    if (this.is_TypeFilter_drop(oldFilter.typeFilter!)) newFilter = new ClassFilterDrop(newFilter as ClassFilterDrop);
    return newValue;
  }

  replaceFilterInArray(arrFilters: Array<TAllInterfacesFilters>, filter: TAllInterfacesFilters): Array<TAllInterfacesFilters> {
    const arrFiltersWithoutFindFilter = arrFilters?.filter(el => el.typeFilter !== filter.typeFilter);
    const findFilter = this.findFilter(filter.typeFilter as TypeFilter_string & TypeFilter_dateRange & TypeFilter_drop);
    const newValueFilter: TAllInterfacesFilters = { ...findFilter, ...filter };
    return [...arrFiltersWithoutFindFilter, newValueFilter];
  }

  updateFilter(typeFilter: TypeFilter_string, filter: Partial<ClassFilterInput>, forTest?: string): ClassFilterInput
  updateFilter(typeFilter: TypeFilter_dateRange, filter: Partial<ClassFilterDateRange>, forTest?: string): ClassFilterDateRange
  updateFilter(typeFilter: TypeFilter_drop, filter: Partial<ClassFilterDrop>, forTest?: string): ClassFilterDrop
  updateFilter(typeFilter: TypesAllFilters, filter: TAllInterfacesFilters, forTest?: string): TAllInterfacesFilters {
    const arrFiltersWithoutFindFilter = this.arrFiltersSub$.getValue()?.filter(el => el.typeFilter !== typeFilter);
    const findFilter = this.findFilter(typeFilter as TypeFilter_string & TypeFilter_dateRange & TypeFilter_drop); // as TypesAllFilters
    const newValueFilter: TAllInterfacesFilters = { ...findFilter, ...filter, typeFilter, forTest };

    this.arrFiltersSub$.next([...arrFiltersWithoutFindFilter, newValueFilter]);
    return newValueFilter;
  }

  resetValueDrop(typeFilter: TypeFilter_string): ClassFilterInput
  resetValueDrop(typeFilter: TypeFilter_dateRange): ClassFilterDateRange
  resetValueDrop(typeFilter: TypeFilter_drop): ClassFilterDrop
  resetValueDrop(typeFilter: TypesAllFilters): TAllInterfacesFilters | null {
    const findFilter = this.findFilter(typeFilter as TypeFilter_string & TypeFilter_dateRange & TypeFilter_drop); 
    if (!findFilter) return null;
    // @ts-ignore
    const updatedFilter = this.updateFilter(typeFilter, {
      ...findFilter,
      valueDrop: undefined,
      valueString: undefined,
      datePeriod: undefined,
      fromTo_formatted: undefined,
      valueFromUrl: undefined,
    });
    return updatedFilter;
  }

  getValuesSelectedFilters(): Array<string> {
    const result: Array<string> = [];
    this.arrFiltersSub$.getValue().forEach(el => {
      const valueString = this.getValue_fromSelectedFilter(el);
      if (valueString) {
        if (el.typeFilter === 'dateRange' && ((el.datePeriod?.from && !el.datePeriod?.to) || (!el.datePeriod?.from && el.datePeriod?.to))) {
          return;
        }
        result.push(valueString);
      }
    });

    if (this.deviceS.isMobile && this.sortBy_forMobile && this.selected_sortBy) {
      result.push(this.selected_sortBy);
    }
    return result;
  }
  lastValidDateRange: string | null = null;
  getValue_fromSelectedFilter(filter: TAllInterfacesFilters): string {
    const typeFilter: TypeFilter = filter.typeFilter!;
    let result: string = '';
    if (this.is_TypeFilter_string(typeFilter!)) {
      const valueString = (filter as ClassFilterInput).valueString;
      if (valueString) result = valueString;
    }
    if (this.is_TypeFilter_dateRange(typeFilter)) {
      if (window.location.href.includes('/payment/') || window.location.href.includes('/reporting') || window.location.href.includes('myWalletOfficial/all')) {
        const period = (filter as ClassFilterDateRange).period;
        if (period && period !== customDateRange) {
          result = period;
          this.lastValidDateRange = period;
        }
        if (period == customDateRange) {
          result = (filter as ClassFilterDateRange).fromTo_formatted!;
        }
        if (!result && this.lastValidDateRange) {
          result = this.lastValidDateRange;
        }
      } else {
        const period = (filter as ClassFilterDateRange).period;
        if (period) result = period;
        if (period == customDateRange) result = (filter as ClassFilterDateRange).fromTo_formatted!;
      }
    }
    if (this.is_TypeFilter_drop(typeFilter)) {
      const valueDrop = (filter as ClassFilterDrop).valueDrop;
      if (valueDrop) {
        if ((valueDrop as Array<ClassDrop>)?.length || (valueDrop as Array<ClassDrop>)?.length === 0) {
          let strResult: string = '';
          (valueDrop as Array<ClassDrop>)?.forEach(elDrop => {
            const titleCase: string | undefined = (elDrop as ClassDrop).titleCase; 
            if (titleCase) strResult = strResult?.trim() ? strResult?.trim() + ',  ' + titleCase : titleCase;
          });
          if (strResult) result = strResult;
        } else {
          const titleCase: string | undefined = (valueDrop as ClassDrop).titleCase;
          if (titleCase) result = titleCase;
        }
      }
    }
    return result;
  }

  removeAllParamsAndRefresh(): void {
    const url = new URL(window.location.href);
    window.location.href = `${url.origin}${url.pathname}`;
    window.history.replaceState({}, '', `${url.origin}${url.pathname}`);
  }

  removeDateParamsAndRefresh(): void {

    const url = new URL(window.location.href);
    const params = url.searchParams;
    params.delete('from');
    params.delete('to');
    params.delete('search');
    const updatedUrl = `${url.origin}${url.pathname}${params.toString() ? '?' + params.toString() : ''}`;
    window.location.href = updatedUrl;

  }

  resetSelectedFilters(arr_newValues?: Array<TAllInterfacesFilters>): void {
    this.showSelectedFilters = false;
    const allInterfacesFilters: Array<TAllInterfacesFilters> = [];
    this.arrFiltersSub$.getValue()?.forEach((el) => {
      const findFilter = arr_newValues?.find((item) => item.typeFilter === el.typeFilter);
      const newValue = findFilter || this.deleteSelectedFilter(el, false);

      if (el.typeFilter === 'search' && window.location.href.includes('/payment/')) {
        this.removeAllParamsAndRefresh();
      }
      if (newValue) allInterfacesFilters.push(newValue);
      if (newValue) console.log(newValue)
    });

    this.arrFiltersSub$.next(allInterfacesFilters);

    if (this.deviceS.isMobile) this.settingsRequestS.resetSortByDefault();
  }

  canResetFilters(): boolean {
    let amountFiltersWithNoDelete = 0; 
    let amountSelectedFilters = this.getValuesSelectedFilters()?.length; 
    this.arrFiltersSub$.getValue().forEach((el) => {
      const filterDrop = el as ClassFilterDrop; 
      if (filterDrop.noDelete) amountFiltersWithNoDelete += 1;
    });
    return amountFiltersWithNoDelete !== amountSelectedFilters; 
  }

  deleteSelectedFilter(filter: TAllInterfacesFilters, update_arrFiltersSub = true): TAllInterfacesFilters | null {
    if (!filter) return null;
    const typeFilter = filter.typeFilter as TypesAllFilters;
    const index = filter.index;
    const currentValueFilter = this.getCurrentFilter(typeFilter as TypeFilter_string & TypeFilter_dateRange & TypeFilter_drop);
    let newValue: TAllInterfacesFilters | null = null;
    if (this.is_TypeFilter_string(typeFilter)) {
      newValue = { ...currentValueFilter, valueString: undefined, index } as ClassFilterInput;
    }
    if (this.is_TypeFilter_dateRange(typeFilter) && (window.location.href.includes('/payment/') || window.location.href.includes('myWalletOfficial/all'))) {
      this.removeDateParamsAndRefresh();
      newValue = { ...currentValueFilter, period: undefined, datePeriod: '', fromTo_formatted: '', index } as ClassFilterDateRange;
    }
    if (this.is_TypeFilter_dateRange(typeFilter)) {
      newValue = { ...currentValueFilter, period: undefined, datePeriod: '', fromTo_formatted: '', index } as ClassFilterDateRange;
    }
    if (this.is_TypeFilter_drop(typeFilter)) {
      const currentFilterDrop = currentValueFilter as ClassFilterDrop;
      if (currentFilterDrop.noDelete) { 
        newValue = currentValueFilter;
      } else if (currentFilterDrop.default_valueDrop) { 
        newValue = { ...currentFilterDrop, valueDrop: currentFilterDrop.default_valueDrop, index };
      } else { 
        newValue = { ...currentFilterDrop, valueDrop: undefined, index };
      }
    }
    if (newValue) {
      const arrFiltersWithoutCurrent: Array<TAllInterfacesFilters> = this.arrFiltersSub$.getValue().filter(el => el.typeFilter !== typeFilter);
      if (update_arrFiltersSub) this.arrFiltersSub$.next([...arrFiltersWithoutCurrent, newValue!]);
    }
    return newValue!;
  }

  getCurrentFilter(typeFilter: TypeFilter_string): ClassFilterInput
  getCurrentFilter(typeFilter: TypeFilter_dateRange): ClassFilterDateRange
  getCurrentFilter(typeFilter: TypeFilter_drop): ClassFilterDrop
  getCurrentFilter(typeFilter: TypesAllFilters): TAllInterfacesFilters {
    return this.arrFiltersSub$.getValue()?.find((el) => el.typeFilter === typeFilter)!;
  }

  findFilter(typeFilter: TypeFilter_string): ClassFilterInput
  findFilter(typeFilter: TypeFilter_dateRange): ClassFilterDateRange
  findFilter(typeFilter: TypeFilter_drop): ClassFilterDrop
  findFilter(typeFilter: TypesAllFilters): TAllInterfacesFilters | undefined {
    return this.arrFiltersSub$.getValue()?.find((item) => item.typeFilter === typeFilter);
  }

  // ========================================================================
  is_TypeFilter_string(typeFilter: TypeFilter): boolean {
    return arrTypeFilter_forInput.includes(typeFilter as TypeFilter_string);
  }

  is_TypeFilter_dateRange(typeFilter: TypeFilter): boolean {
    return arrTypeFilter_forDateRange.includes(typeFilter as TypeFilter_dateRange);
  }

  is_TypeFilter_drop(typeFilter: TypeFilter): boolean {
    return arrTypeFilter_forDrop.includes(typeFilter as TypeFilter_drop);
  }

}

