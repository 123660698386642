import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnComponent } from '@components/btn/btn.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { HelperClass } from '@classes/Helper-Classes';
import { OtherService } from '@services/other.service';
import { PopupService } from '@services/popup.service';
import { ActivatedRoute } from '@angular/router';
import { ProfileUserService } from '@app/profile/profile-user/profile-user.service';
import { GetLocationStringPipe } from '@pipes/location/get-location-string.pipe';
import { DeviceService } from '@services/device.service';
import {
  TitleAndNavigationForProfileComponent,
} from '@app/profile/additional_components/title-and-navigation-for-profile/title-and-navigation-for-profile.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

interface ItemContactAvailability {
  title: string;
  time: string;
}

interface ItemContactNotAvailability {
  time: string;
  value: string;
}

@UntilDestroy()
@Component({
  selector: 'app-profile-user-availability',
  standalone: true,
  imports: [CommonModule, BtnComponent, HeadTitleComponent, GetLocationStringPipe, TitleAndNavigationForProfileComponent],
  templateUrl: './profile-user-availability.component.html',
  styleUrls: ['./profile-user-availability.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileUserAvailabilityComponent extends HelperClass implements OnInit {
  arrAvailable: Array<ItemContactAvailability> = [];
  arrNotAvailable: Array<ItemContactNotAvailability> = [];

  constructor(
    public otherS: OtherService,
    public popupS: PopupService,
    private route: ActivatedRoute,
    public deviceS: DeviceService,
    public profileUserS: ProfileUserService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.getUserIdFromQueryParams();
  }

  ngOnInit() {
    this.getFakeArrAvailable();
    this.getFakeArrNotAvailable();
  }

  getUserIdFromQueryParams(): void {
    this.route.params.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res?.idUser) this.profileUserS.getContactById(res.idUser);
    });
  }

  // !!! delete
  getFakeArrAvailable(): void {
    this.arrAvailable = [
      { title: 'Monday', time: 'Not Available' },
      { title: 'Tuesday', time: 'Not Available' },
      { title: 'Wednesday', time: 'Not Available' },
      { title: 'Thursday', time: '5:00 - 11:59 PM' },
      { title: 'Friday', time: '5:00 - 11:59 PM' },
      { title: 'Saturday', time: '5:00 - 11:59 PM' },
      { title: 'Sunday', time: '5:00 - 11:59 PM' },
    ];
    this.cd.detectChanges();
  }

  // !!! delete
  getFakeArrNotAvailable(): void {
    this.arrNotAvailable = [
      { time: '12/23/22 - 12/31/22', value: 'Not Available' },
      { time: '01/01/23 - 01/04/23', value: 'Not Available' },
      { time: '01/01/23 - 01/04/23', value: 'Not Available' },
    ];
    this.cd.detectChanges();
  }

}
