import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MainService } from '@services/main.service';
import { MeService } from '@services/me.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterOutlet, AsyncPipe, MatMenuModule],
  encapsulation: ViewEncapsulation.None,
})
export class AuthComponent {
  @ViewChild('wrapperAuthRef') wrapperAuthRef?: ElementRef;
  @ViewChild('imageBcgRef') imageBcgRef?: ElementRef;
  @ViewChild('contentAuthRef') contentAuthRef?: ElementRef;

  constructor(
    public meS: MeService,
    public mainS: MainService,
    // private routesS: RoutesService,
  ) {
    // console.log('this.routesS.getData_fromQueryParams() :', this.routesS.getData_fromQueryParams())
    // localStorage.clear(); // !!! переход по этой ссылке при копирование на странице /officials не работал
    // console.log('AuthComponent :', )
  }

  forTestDeleteUser() {
    if (this.meS.me?.email) this.mainS.forTestDeleteUser(this.meS.me?.email);
  }
}
