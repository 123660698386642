import { Injectable } from '@angular/core';
import { ClassAvailability } from '@models/ClassAvailability';
import { IUnavailableDate } from '@models/IUnavailableDate';
import { IEmbeddableAuthResponseDto, ILoginResponseDto, IRequest_forRegistrationAndLogin } from '@app/auth/auth_models';
import { delay, Observable, of, switchMap } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { BaseApi, THttpMethod } from './base-api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IntercomService } from '@services/intercom.service';
import { OtherService } from '@services/other.service';
import { MeService } from '@services/me.service';
import { RoutesService } from '@services/routes.service';
import { LocalStorageService } from '@services/local-storage.service';
import { ClassUser, TUserRoleUpperCase, TUserSignup, UserRegisteredStatus } from '@models/user';
import { NavigationEnd, Router } from '@angular/router';
import { ClassGroup, ClassGroupListItem, getArrClassGroup } from '@models/IGroup';
import { LodashService } from '@services/lodash.service';
import {
  ClassGame,
  ClassGameOfficial,
  IClassGame_forCancelGameForSendToServer,
  IForApiMethod_updateOfficials,
  IOfficialsAssign,
  ISendObjForGetGameOfficialsForCreateGame,
} from '@app/dir_group_assignor/games/game';
import {
  ClassReport,
  ClassReportStatusDrop,
  InviteRefereeToGame,
  IResponse_getAmountReportsStatuses,
} from '@app/dir_group_assignor/reports/report';
import { IPopupCreateGroupList } from '@components/popup-create-group-list/popup-create-group-list.component';
import { IPopupGroupList } from '@components/popup-group-list/popup-group-list.component';
import { IPopupEditFeeForGO } from '@app/dir_group_assignor/games/components/popup-edit-fee-for-go/popup-edit-fee-for-go.component';
import {
  IBalance,
  IBalanceOperation,
  IPayer,
  IPaymentMethod,
  IResCreatePaymentForCompetition,
  IResponseGetProjectedOutgoings,
  ISendObjCreatePaymentForCompetition,
  ISendObjCreatePaymentMethod,
} from '@app/dir_group_assignor/payments/modelsForPayment';
import { IOnboarding } from '@app/dir_group_assignor/dashboard/dashboard';
import { DropdownService } from '@components/__drop_inputs_matSelect/dropdown/dropdown.service';
import { TCurrentLink_officials, TCurrentLinkLowerCase_officials } from '@app/dir_group_assignor/officials/officials';
import { ForTestService } from '@classes/forTest';
import { ClassSettingsRequest, fakeSettingsRequest, IRequestOptions, IResponse } from '@models/response-and-request';
import { urlDashboard } from '@app/app.module';
import { IRequest_for_inviteUserToGroup } from '@app/group-profile/groupProfile';
import { AuthService } from '@app/auth/auth.service';
import {
  IRequestPopupAddCrewMember,
  IResponsePopupAddCrewMember,
} from '@app/dir_group_assignor/games/components/popup-add-crew-member/popup-add-crew-member.component';
import { IOfficialListItem } from '@components/__popup-windows/popup-new-announcement-general/models/form-models';
import { ClassAdjustmentStatus } from '@app/group-profile/components/group-profile-adjustment-statuses/adjustmentStatus';
import { UtilsService } from '@services/utils.service';
import { const_NA } from '@models/other';
import { environment } from '@env/environment';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';
import { NotificationService } from './notification.service';
import { ta } from 'date-fns/locale';
import { HttpParams } from '@angular/common/http';
import { GameStatus, GameTransfersDetailsDto } from '@app/dir_group_assignor/payments/components/payments-assignor-fees/game-transfers-details-dto';
import { TransferType } from '@app/dir_group_assignor/payments/models/transfer-type';
import { UserGameTransferInfoDto } from '@app/dir_group_assignor/payments/models/user-game-transfer-info-dto';

declare var LOG: any;
export type AuthorizationClientType = 'ios' | 'web';

@Injectable({ providedIn: 'root' })
export class MainService extends BaseApi {
  forProd = environment.production;
  forTestOnboarding = false;
  forTest: Array<string> = ['']; // для тестов
  hostname = window.location.hostname;
  isBetaEnv = this.hostname.includes('beta') || this.hostname.includes('localhost') || this.hostname.includes('staging');

  readonly optionsForDownloadCSV = {
    Accept: 'text/csv; charset=utf-8', 'Content-Type': 'text/csv; charset=utf-8',
    responseType: 'text' as any,
    forDownloadCSV: true,
  };
  totalElements: number | undefined;
  public settingsOfficials: { availability: boolean } = { availability: false };

  constructor(
    private notificationService: NotificationService,
    public http: HttpClient,
    private intercomS: IntercomService,
    public otherS: OtherService,
    private meS: MeService,
    private routesS: RoutesService,
    private localStorageS: LocalStorageService,
    private lodashS: LodashService,
    private router: Router,
    private dropdownS: DropdownService,
    private authS: AuthService,
    private apiCompetitionS: ApiCompetitionService,
    private forTestS: ForTestService,
  ) {
    super(http, otherS);
    console.log('forProd :', this.forProd);
  }

  getAvailablePayout(): Observable<number | undefined> {
    return this.get(`/api/payments/v1/account/connect/balance`);
  }

  getTotalAssigningFees(options: { params?: ClassSettingsRequest }): Observable<number | undefined> {
    options.params = ClassSettingsRequest.preparePropertyFromDropForSendToServer(options.params);
    return this.get(`/api/payments/v1/transfer/total-assigning-fees`, options).pipe(map(x => x.total));
  }

  getAssignorFeesList(options: { params: any }): Observable<IResponse<UserGameTransferInfoDto>> {
    options.params = ClassSettingsRequest.preparePropertyFromDropForSendToServer(options.params);
    return this.get(`/api/core/gamePayment/v1/transfer/user`, options).pipe(
      map((res: IResponse<UserGameTransferInfoDto>) => {
        this.totalElements = res.totalElements;
        return res;
      }),
    );
  }


  getAssignorFeesListV2(options: { params: any }): Observable<IResponse<UserGameTransferInfoDto>> {
    options.params = ClassSettingsRequest.preparePropertyFromDropForSendToServer(options.params);
    return this.get(`/api/core/gamePayment/v2/transfer/user`, options).pipe(
      map((res: IResponse<UserGameTransferInfoDto>) => {
        this.totalElements = res.totalElements;
        return res;
      }),
    );
  }

  // если надо добавить "1" к номеру телефона перед отправкой на сервер.
  checkPhone(sendObj: any): any {
    const tempSendObj = { ...sendObj };
    if (tempSendObj.phone) tempSendObj.phone = '1' + sendObj.phone;
    return tempSendObj;
  }

  // === CASH =========================================================
  private cachedData: Map<string, any> = new Map();

  // apiString => какой ключ надо удалить // например передать this.apiOfficials, который хранит в себе такую строку '/api/core/officials/v1'
  deleteApiCache(apiString: string): void {
    this.cachedData.forEach((value, key) => {
      if (key.includes(apiString)) this.cachedData.delete(key);
    });
  }

  deleteAllCache(): void {
    this.cachedData.clear();
  }

  // === FOR SCRIPTS ========================
  scriptLinkedin = false;
  scriptHubspot = false;
  scriptGoogleTag = false;

  reqPending = false;

  maxlengthInput = '64';
  maxlengthTextarea = '255';
  maxlengthZipcode_5 = '5';
  maxlengthZipcode_6 = '6';

  get exist_jwt_token(): boolean {
    // console.log('this.localStorageS.jwt_token :', this.localStorageS.jwt_token)
    return !!this.localStorageS.jwt_token;
  }

  forTestDeleteUser(email: string): void {
    this.get(`/api/auth/v1/deleteUser?email=${email}`).subscribe((res) => {
    });
  }

  // === DASHBOARD ==============================
  getOnboarding(): Observable<IOnboarding | null> {
    // return of(this.forTestS.onboarding)
    return this.get(`/api/core/onboarding/v1`)
      .pipe(
        map((res: IOnboarding) => {
          // console.log('getOnboarding forTestOnboarding :', this.forTestOnboarding)
          // console.log('getOnboarding meS.meRole :', this.meS.meRole)
          // if (!this.forTestOnboarding && !this.forProd) return res;
          if (this.forTestOnboarding && !this.forProd) {
            setTimeout(() => this.forTestOnboarding = false);
            if (this.meS.OFFICIAL) { // !!! FOR TEST Onboarding
              return { ...res, availability: false, payouts: false };
            } else {
              return {
                ...res, groupCreated: true, competitionCreated: true, gamesCreated: true, officialsInvited: false, officialsAssigned: false,
              };
            }
          } else {
            return res;
          }
        }),
        // tap(res => console.log('getOnboarding :', res)),
        catchError((error: any) => {
          // console.log('getOnboarding error :', error)
          return of(null);
        }),
      );
  }

  getMe(): Observable<ClassUser | null> {
    if (!this.exist_jwt_token) return of(null);
    // return of(this.forTestS.me)
    return this.get(`/api/users/v1/me`)
      .pipe(
        // map(res => ({...res, onboardingSkipped: false})), // for test
        tap((user: ClassUser) => {
          // console.log('GET ME  :', user.roleCurrent, user)
          //   if (!user) return;
          //   const id: string = user.roleCurrent === 'OFFICIAL' ? '' : user.id!; // для судьи в этом запросе приходит не id, а userId. Для судьи id приходит в следующем запросе getOfficialByUserId()
          //   const userId: string = user.roleCurrent === 'OFFICIAL' ? user.id! : '';
          //   this.meS.setMeUser({...user, id, userId}, 'MainService getMe');
          //   this.intercomS.intercom(user?.firstName!, user?.email!);
        }),
        switchMap((user: ClassUser) => {
          // console.log('GET ME  switchMap:', user.roleCurrent, user)
          if (!user?.id) return of(null);
          if (user.roleCurrent === 'OFFICIAL') {
            return this.getOfficialByUserId(user.id, user);
          } else {
            // console.log('GET ME  switchMap:', user.roleCurrent, user)
            return of(new ClassUser(user));
          }
        }),
        tap((user: ClassUser | null) => {
          if (user) {
            // console.log('GET ME  tap:', user.roleCurrent, user)
            this.meS.setMeUser(user, 'MainService getMe');
            this.intercomS.intercom(user?.firstName!, user?.email!);
          }
        }),
        catchError((err: any) => {
          // console.log('GET ME  catchError:', err)
          this.meS.setMeUser(undefined);
          localStorage.removeItem('jwt_token');
          this.routesS.navigate('login');
          return of(null);
        }),
      );
  }

  getOfficialByUserId(userId: string, userForGetMe?: ClassUser, needUse_cachedData = false, withLastReviewed = false): Observable<ClassUser> {
    let endPoint = `${this.apiOfficials}?userId=${userId}`;
    if (withLastReviewed) endPoint = endPoint + `&withLastReviewed=${true}`;
    if (needUse_cachedData) {
      const cachedData = this.cachedData.get(endPoint);
      if (cachedData) return of(cachedData);
    }

    return this.get(endPoint).pipe(
      map((res: ClassUser) => {
        const existingUser = this.meS.me;
        const result = new ClassUser({
          ...existingUser,
          ...userForGetMe,
          ...res
        });
        this.cachedData.set(endPoint, result);
        if (this.meS.userId === userId) {
          this.meS.setMeUser(result, 'MainService getOfficialByUserId()');
        }
        return result;
      }),
    );
  }

  getOfficialByUserIdCert(userId: string, userForGetMe?: ClassUser): Observable<ClassUser> {
    return this.get(`/api/core/officials/v1/${userId}`).pipe(
      map((user: ClassUser) => new ClassUser({ ...userForGetMe, ...user })),
    );
  }

  getUserById(userId: string, needUse_cachedData = false): Observable<ClassUser> {
    const endPoint = `/api/users/v1/${userId}`;
    if (needUse_cachedData) {
      const cachedData = this.cachedData.get(endPoint);
      console.log('getUserById cachedData :', cachedData);
      if (cachedData) return of(cachedData);
    }

    return this.get(endPoint)
      .pipe(
        map((res: ClassUser) => {
          const result = new ClassUser(res);
          this.cachedData.set(endPoint, result);
          return result;
        }),
      );
  }

  setTokenBeforeLogin(res: { token: string; }, redirectUrl = '', isReload = false): void {
    // console.log('setTokenBeforeLogin :', res, redirectUrl);
    // console.log('MAinService confirmMe meS.me ', this.meS.meRole, this.meS.me);
    if (!res?.token) {
      console.error('NO TOKEN !!! :', res);
      return;
    }
    localStorage.setItem('jwt_token', res.token);
    if (redirectUrl) {
      // console.error('setTokenBeforeLogin :', isReload, redirectUrl)
      this.router.navigate([redirectUrl]);
    }
    if (isReload) setTimeout(() => this.otherS.reload(), 100);
  }

  // !!! добавлять в адресную строку роль юзера
  checkUserRole(event: NavigationEnd, queryParams: any): void { // id, url, urlAfterRedirects // example queryParams == {email:"mike@peller.tech",group_id:"1235",role:"official"}
    // console.log('queryParams :', queryParams);
    const me = this.meS.me;
    // console.log('checkUserRole me :', me);

    // this.meS.me$?.subscribe((me?: ClassUser) => {
    // const url = event.urlAfterRedirects; // /admin/profile/account || /official/profile/account
    // const urlIncludesSue = url?.includes('/sue/');
    // const urlIncludesAdmin = url?.includes('/admin/');
    // const urlIncludesOfficial = url?.includes('/official/');
    // const isAdmin = me?.roleCurrent == 'LEAGUE_ADMIN';
    // const isOfficial = me?.roleCurrent == 'OFFICIAL';
    // const role: TMeUserRole = isAdmin ? 'admin' : 'official';
    //
    // if (!urlIncludesSue && !authRoutes.includes(event?.url?.split('?')[0])) {
    //   if ((!urlIncludesAdmin && !urlIncludesOfficial) || (urlIncludesAdmin && isOfficial) || (urlIncludesOfficial && isAdmin)) {
    //     const prevUrl = '?prevUrl=%2Fadmin%2F';
    //     const arrPath = url?.split(prevUrl); // ["http://localhost:4400/admin/mainpage","games"]
    //     this.routesS.previousUrl = '/' + (arrPath[1] || '');
    //     this.routesS.navigate(`${role}/${url?.split('?')[0]}`);
    //   }
    // }
    // });
  }

  // === AUTH ========================

  changeUserRegisteredStatus(status: UserRegisteredStatus): void {
    const sendObj: ClassUser = { userRegisteredStatus: status, id: this.meS.meId };
    this.post(`/api/auth/v1/fill/user/info`, sendObj).toPromise();
  }

  UpdateUserInfo(sendObj: ClassUser): Observable<ClassUser> {
    return this.post(`/api/auth/v1/fill/user/info`, sendObj);
  }

  //old
  fillUserInfo(sendObj: ClassUser): Observable<ClassUser> { // sendObj: {id, firstName, secondName, preferredName, address: {id, city, state, street, streetLine2, zipcode} }
    sendObj = ClassUser.preparePropertyFromDropForSendToServer(sendObj);
    return this.post(`/api/auth/v1/fill/user/info`, sendObj).pipe(
      map((res: { id: string; }) => {
        if (res) this.meS.setMeUser(sendObj, 'MainService fillUserInfo'); /////// если ошибка ...
        return new ClassUser(res);
      }),
    );
  }

  login(sendObj: IRequest_forRegistrationAndLogin): Observable<ILoginResponseDto> {
    const request_forRegistrationAndLogin = {
      ...ClassUser.preparePropertyFromDropForSendToServer(sendObj),
      ...this.authS.queryParams_forRegistration,
    } as IRequest_forRegistrationAndLogin;
    return this.post(`/api/auth/v1/signin`, request_forRegistrationAndLogin);
  }

  embeddableAuth(groupId: string, embeddableId: string): Observable<IEmbeddableAuthResponseDto> {
    return this.post(`/api/auth/v1/embeddadble`, { groupId, embeddableId });
  }

  confirmMe(userId?: string): void {
    this.get(`/api/auth/v1/confirm?value=${userId || this.meS.meId}`).toPromise()
      .then((res: { token: string; }) => {
        this.setTokenBeforeLogin(res, urlDashboard);
      })
      .catch((err) => console.log('get(`/api/auth/v1/confirm?value=meId`)', 'err', err));
  }

  signUp(sendObj: Pick<ClassUser, 'email' | 'password'>): Observable<TUserSignup> { // {"email": "ene@asd.sd", "password": "password"}
    sendObj = ClassUser.preparePropertyFromDropForSendToServer(sendObj);
    const request_forRegistrationAndLogin: IRequest_forRegistrationAndLogin = { ...sendObj, ...this.authS.queryParams_forRegistration };

    if (!request_forRegistrationAndLogin.role || (request_forRegistrationAndLogin.role && request_forRegistrationAndLogin.role.toUpperCase() === 'GROUP-ASSIGNOR')) {
      delete request_forRegistrationAndLogin.role;
    }
    return this.post(`/api/auth/v1/signup`, request_forRegistrationAndLogin)
      .pipe(
        tap((res: ClassUser) => {
          const user: ClassUser = { ...res, id: res?.id };
          if (request_forRegistrationAndLogin.role) user.roleCurrent = request_forRegistrationAndLogin.role as TUserRoleUpperCase;
          if (request_forRegistrationAndLogin.groupId || res.groupId) user.groupId = request_forRegistrationAndLogin.groupId || res.groupId;
          this.saveGroupId(request_forRegistrationAndLogin.role, user.groupId, res.id);
          this.meS.setMeUser(user, 'MainService signUp');
        }),
        map(res => new ClassUser(res)),
      );
  }

  public saveGroupId(role: TUserRoleUpperCase | undefined, groupId: string | undefined, userId: string | undefined): void {
    if (role == 'OFFICIAL') {
      const dto = {
        groupId: groupId,
        userId: userId
      };
      this.post(`/api/auth/v1/createOfficial`, dto).toPromise();
    }
  }

  public signupWithGoogle(sendObj: Pick<ClassUser, 'email'>, clientType: AuthorizationClientType): Observable<TUserSignup> {
    sendObj = ClassUser.preparePropertyFromDropForSendToServer(sendObj);
    const request_forRegistrationAndLogin = { ...sendObj, ...this.authS.queryParams_forRegistration, clientType };
    LOG('request_forRegistrationAndLogin', JSON.stringify(request_forRegistrationAndLogin));

    if (!request_forRegistrationAndLogin.role || (request_forRegistrationAndLogin.role && request_forRegistrationAndLogin.role.toUpperCase() === 'GROUP-ASSIGNOR')) {
      delete request_forRegistrationAndLogin.role;
    }

    return this.post(`/api/auth/v1/signupWithGoogle`, request_forRegistrationAndLogin)
      .pipe(
        tap((res: ClassUser) => {
          const user: ClassUser = { ...res, id: res?.id };
          if (request_forRegistrationAndLogin.role) user.roleCurrent = request_forRegistrationAndLogin.role as TUserRoleUpperCase;
          if (request_forRegistrationAndLogin.groupId || res.groupId) user.groupId = request_forRegistrationAndLogin.groupId || res.groupId;
          this.meS.setMeUser(user, 'MainService signupWithGoogle');
          this.saveGroupId(request_forRegistrationAndLogin.role, user.groupId, res.id);
        }),
        map(res => new ClassUser(res)),
      );
  }

  signinWithGoogle(emailOrTokenId: string, clientType: AuthorizationClientType, groupId: string | undefined, role: string | undefined): Observable<ILoginResponseDto> { // !!! email == id_token from google
    const body = {
      email: emailOrTokenId,
      clientType,
      groupId,
      role,
      ...this.authS.queryParams_forRegistration,
    }
    return this.post(`/api/auth/v1/signinWithGoogle`, body);
  }

  signUpOfficial(sendObj: ClassUser): Observable<ClassUser> {
    if (this.meS.me?.groupId)
      sendObj.groupId = this.meS.me?.groupId;
    sendObj = ClassUser.preparePropertyFromDropForSendToServer(sendObj);
    return this.post(`/api/auth/v1/createOfficial`, sendObj)
      .pipe(
        tap((res: ClassUser) => {
          const user: ClassUser = { roleCurrent: 'OFFICIAL' };
          if (sendObj.groupId || res.groupId)
            user.groupId = sendObj.groupId || res.groupId;
          this.meS.setMeUser(user, 'MainService signUp');
        }),
        map(res => new ClassUser(res)),
      );
  }

  logout(): void {
    this.logoutSilent();
    this.routesS.navigate(['/login']);
  }

  logoutSilent(): void {
    this.deleteAllCache();
    localStorage.clear(); // !!! чтобы groupId/key и т.д. очистить, чтобы не отправлялось на сервер
    localStorage.removeItem('jwt_token');
    this.meS.clear();
  }

  switchRole(): void {
    // let userInfo = { ...this.meS.me, roleCurrent: this.meS.me?.roleCurrent === 'GROUP_ASSIGNOR' ? 'OFFICIAL' : 'GROUP_ASSIGNOR' };
    // if (userInfo.phone && !userInfo.phone.includes('+1')) userInfo.phone = '+1' + userInfo.phone;
    const sendObj: ClassUser = {
      id: this.meS.me?.id,
      roleCurrent: this.meS.me?.roleCurrent === 'GROUP_ASSIGNOR' ? 'OFFICIAL' : 'GROUP_ASSIGNOR',
    };

    // this.updateUser(sendObj).toPromise()
    //   .then((res?: ClassUser) => {
    //     // this.webSocketS.reloadActivityLog();
    //     this.routesS.navigate(['/dashboard'], { queryParams: { a: 1 } }); // queryParams => чтобы перезагрузить страницу после переключения роли
    //     setTimeout(() => this.otherS.reload());
    //   })
    //   .catch(() => {
    //   });
  }

  // updateUser => если надо обновить именно объект юзера (напимер судья на дашбоарде проскипал все шаги и надо отправлять userId & PUT api/users/v1/update
  updateUser(sendObj: ClassUser, updateUser = false): Observable<ClassUser> {
    // console.log('updateUser 111:', sendObj.certifiedDrop, sendObj, Object.entries(sendObj))
    // certified certificationName emergencyRelationship при отправке доставать из certifiedDrop certificationNameDrop emergencyRelationshipDrop
    // для других методов тоже доставать значения из Drop
    if (this.meS.me?.id) sendObj.id = this.meS.me?.id;
    if (updateUser) {
      if (this.meS.me?.userId) sendObj.id = this.meS.me?.userId;
    }

    let url = this.meS.OFFICIAL ? `${this.apiOfficials}` : 'api/users/v1/update';
    if (updateUser) url = 'api/users/v1/update';

    sendObj = ClassUser.preparePropertyFromDropForSendToServer(sendObj);
    // const updated_sendObj = UtilsService.deepClone(ClassUser.preparePropertyFromDropForSendToServer(UtilsService.deepClone(sendObj))) ;
    // for test return of(new ClassUser({ ...sendObj }));
    // console.log('updateUser 222:', sendObj.certifiedDrop, sendObj, Object.entries(sendObj))

    // !!! for test
    // const updatedClassUser = new ClassUser({ ...sendObj })
    // console.log('updatedUser 333:', updatedClassUser.certifiedDrop, updatedClassUser, Object.entries(updatedClassUser))
    // this.meS.setMeUser(updatedClassUser);
    // return of({...this.meS.me, ...updatedClassUser})

    return this.put(url, UtilsService.removeEmptyKeysFromObject(sendObj)).pipe(
      map((res: ClassUser) => {
        // if (res) this.meS.setMeUser({ ...sendObj, ...res });
        // return new ClassUser({ ...sendObj, ...res })
        const updatedClassUser = new ClassUser({ ...sendObj, ...res });
        // console.log('updatedUser :', updatedClassUser.certifiedDrop, updatedClassUser, Object.entries(updatedClassUser))
        this.meS.setMeUser(updatedClassUser);
        return updatedClassUser;
      }),
    );
  }

  changePass(sendObj: { currentPass: string; newPass: string; }): Observable<null> {
    return this.post('/api/auth/v1/changePass', sendObj);
  }

  // отправка кода на телефон
  sendCodeOnPhone(phone: string): Observable<null> {
    // console.log('sendCodeOnPhone :', typeof phone, phone);
    return this.post(`/api/auth/v1/send/verify/phone`, phone);
  }

  async getInvitationStatus(invitationId: string | null): Promise<string> {
    return await this.get(`/api/auth/v1/getInvitationStatus?invitationId=${invitationId}`).toPromise();
  }

  resendCode(email_or_phone: string, type: 'email' | 'phone'): Observable<any> { // email_or_phone == aaa@gmail.com || 79675711111
    // return of("");
    return this.post(`/api/auth/v1/resend/verify/${type}`, email_or_phone);
  }

  verifyCode(sendObj: { resource: string, code: string; }, type: 'email' | 'phone'): Observable<any> { // "resource": "vo666id@gmail.com" or "9123412312312"
    // console.log('verifyCode :', sendObj);
    // console.log('verifyCode resource :', typeof sendObj?.resource, sendObj?.resource);
    // console.log('verifyCode code :', typeof sendObj?.code, sendObj?.code);
    // console.log('meS.meId :', this.meS.meId)
    // console.log('meS.userId :', this.meS.userId)
    if (type == 'phone') sendObj.resource = sendObj.resource?.toString()?.replace('+', '');
    return this.post(`/api/auth/v1/verify/code/${type}`, { ...sendObj, id: this.meS.userId || this.meS.meId }); // !!! meId для регистрации. userId для при апдейте профиля
  }

  uploadPic(sendObj: any, options: any): Observable<{ url: string; }> {
    return this.post(`/api/files/v1`, sendObj, options);
  }

  getGroupById(idGroup: string): Observable<ClassGroup> {
    return this.get(`/api/core/groups/v1/${idGroup}`).pipe(
      map(res => new ClassGroup(res)),
    );
  }

  // !!! без jwt_token получить
  getGroupNameById(idGroup: string): Observable<string> {
    return this.get(`/api/core/v1/anonymous/group/${idGroup}`);
  }

  createGroup(sendObj: ClassGroup): Observable<ClassGroup> {
    sendObj = ClassGroup.preparePropertyFromDropForSendToServer(sendObj);
    if (sendObj.gameType) sendObj.gameType = sendObj.gameType.toUpperCase();
    return this.post(`/api/core/groups/v1`, sendObj).pipe(
      //   tap((res: ClassGroup) => {
      //     if (res?.id) this.meS.setMeUser({ groupCurrent: res?.id });
      //   }),
      map(res => new ClassGroup(res)),
    );
  }

  getMeArrCurrentGroup(): Observable<Array<ClassGroup>> { // getCurrentGroup()
    if (!this.meS.me?.id) {
      console.log(' getMeArrCurrentGroup() no have me id :');
      return of([]);
    }
    return this.get(`api/core/groups/v1/myGroups`).pipe(
      map((arrGroup) => getArrClassGroup(arrGroup)),
      tap((arrClassGroup) => {
        // this.meS.meArrCurrentGroup$.next(arrClassGroup || []);
        // this.meS.meCurrentGroup$.next(arrClassGroup[0]);
        // console.log('getMeArrCurrentGroup() :', arrClassGroup)
        this.meS.meArrCurrentGroup = arrClassGroup;
        this.meS.meCurrentGroup = arrClassGroup[0]; // !!! andrei пока что текущая группа устанавливается 1элемент из массива
        // console.log('meS.meCurrentGroup :', this.meS.meCurrentGroup)
      }),
    );
  }

  // пригласить юзера в группу
  inviteUserToGroup(sendObj: IRequest_for_inviteUserToGroup): Observable<null> {
    return this.post(`api/core/groups/users/v1/invite`, sendObj);
  }

  getListUsersForGroup(options: { params?: ClassSettingsRequest }, idGroup: string): Observable<IResponse<ClassUser>> {
    // console.log('getListUsersForGroup :', options.params)
    return of({ ...fakeSettingsRequest, content: [this.forTestS.fakeUser, this.forTestS.fakeUser] }).pipe(delay(555));
    // return this.get(`api/core/`);
  }

  // !!! POST => return вновь созданные
  // methodAdjustmentStatuses(httpMethod: Exclude<THttpMethod, 'delete'>, arrAdjustmentStatus?: Array<ClassAdjustmentStatus>, isActive = false): Observable<Array<ClassAdjustmentStatus>> {
  // methodAdjustmentStatuses(httpMethod: Omit<THttpMethod, 'get' | 'put'>, arrAdjustmentStatus?: Array<ClassAdjustmentStatus>, isActive = false): Observable<Array<ClassAdjustmentStatus>> {
  methodAdjustmentStatuses(httpMethod: 'get' | 'put', arrAdjustmentStatus?: Array<ClassAdjustmentStatus>, isActive = false): Observable<Array<ClassAdjustmentStatus>> {
    if (this.meS.meRole == 'ADMIN')
      return of([]);
    const url = `/api/core/groups/statuses/v1`;
    let result: Observable<Array<ClassAdjustmentStatus>>;

    // andrei добавить сортировку по имени
    if (httpMethod == 'get') {
      // result = this.get(`${url}?active=true`);
      const urlForGet = isActive ? `${url}?active=true` : `${url}`;
      result = this.get(`${urlForGet}`);
    } else { // post || put
      const arrSendObj = arrAdjustmentStatus!.map(el => ClassAdjustmentStatus.preparePropertyFromDropForSendToServer(el));
      result = this[httpMethod as 'put'](url, arrSendObj);
    }

    return result.pipe(
      map((res: Array<ClassAdjustmentStatus>) => {
        let result = res?.map((el) => new ClassAdjustmentStatus(el)) || [];
        // result.push(this.forTestS.fakeAdjustmentStatus)
        // result.unshift(this.forTestS.fakeAdjustmentStatus)
        // const adjustmentStatus_NA = result.find(el => el.name === const_NA)!;
        // result = result.filter(el => el.name !== const_NA);
        // result.unshift(adjustmentStatus_NA)
        result = result.sort((el1, el2) => { // !!! N/A должен быть всегда первым в списке
          if (el1.name === const_NA) return -1;
          else return 1;
        });
        return result;
      }),
    );
  }

  deleteAdjustmentStatus(id: string): Observable<string> { // возвращается id удаленного
    return this.delete(`/api/core/groups/statuses/v1/${id}`);
  }

  // === !!! GET LINK FOR REGISTRATION ===============================
  // !!! для получения ссылки для регистрации юзера (пока что только для админа)
  // !!! для регистрации судьи есть другой метод this.getLinkOfficialToGroup()
  getLink_for_inviteUserToGroup(userRoleUpperCase: TUserRoleUpperCase): Observable<string> {
    return this.post(`api/core/groups/users/v1/linkUserToGroup?role=${userRoleUpperCase}`);
  }

  // !!! для получения ссылки для регистрации судьи
  getLinkOfficialToGroup(): Observable<string> { // https://v3.beta.joinnotch.com/v1/signup?role=OFFICIAL&groupId=a39041e6-5619-4b2a-bf46-e3e305bf41c3&key=829bf99b-6a0e-464a-a635-83dddbfe6a6e
    return this.get(`${this.apiOfficials}/linkOfficialToGroup`);
  }


  getPaymentDetails(gameId: string): Observable<GameTransfersDetailsDto> {
    return this.get(`/api/core/gamePayment/v1/transfer/user/${gameId}`);
  }

  // === GAMES ==============================
  readonly urlGame = '/api/core/games/v1';
  readonly urlGO = `${this.urlGame}/gameOfficials`;

  getGamesList(options: { params?: ClassSettingsRequest }): Observable<IResponse<ClassGame>> {
    options.params = ClassSettingsRequest.preparePropertyFromDropForSendToServer(options.params);
    // options.params = UtilsService.removeEmptyKeysFromObject(options.params!);
    if (options?.params?.gameStatus) {
      if (!options.params.gameStatuses) options.params.gameStatuses = options.params.gameStatus;
      delete options.params.gameStatus; // !!! Тимур сказал НЕ нужно это в этом запросе 1.07.24
    }
    if (options?.params?.status) delete options.params.status; // !!! Тимур сказал НЕ нужно это в этом запросе 1.07.24

    return this.get(`${this.urlGame}/all`, options).pipe(
      map((res: IResponse<ClassGame>) => {
        const arrGames: Array<ClassGame> = res?.content?.map(el => new ClassGame(el)) || [];
        // arrGames?.forEach((el, i) => {
        //   if (el?.gameAdjustmentStatus) {
        //     console.error('=== :', i, el.gameNumber, el)
        //   }
        // })
        return { ...res, content: arrGames };
      }),
    );
  }

  // !!! если isClone то при клонировании id игры НЕ отправлять и запрос должен быть POST - как для создания игры
  methodGame(sendObj: ClassGame, httpMethod: THttpMethod, isClone = false): Observable<ClassGame> {
    const url = `${this.urlGame}`;
    // !!! если isClone то при клонировании id игры НЕ отправлять и запрос должен быть POST - для создания игры
    if (isClone)
      delete sendObj.id;
    let result: Observable<ClassGame>;
    if (httpMethod == 'post')
      sendObj.assignor = { id: this.meS.meId };


    if (httpMethod === 'get') {
      result = this.get(`${url}/${sendObj.id}`);
    } else if (httpMethod == 'delete') {
      result = this.delete(`${url}/${sendObj.id}`);
    } else { // post | put (create & clone | edit)
      sendObj = ClassGame.preparePropertyFromDropForSendToServer(sendObj!);
      result = this[httpMethod](url, sendObj);
    }
    return result.pipe(
      map((res) => {
        return new ClassGame(res)
      }),
    );
  }

  deleteGames(arrGames: Array<ClassGame>): Observable<Array<string>> {
    const gamesPossibleGameToDelete = UtilsService.getListGameForPossibleGameToDelete(arrGames); // !!! удалить можно только те игры, в которых нет заасайненого судьи
    const idsGames = gamesPossibleGameToDelete?.map(el => el.id!);
    return this.post(`${this.urlGame}/delete`, idsGames);
  }

  cancelGames(games: Array<IClassGame_forCancelGameForSendToServer>): Observable<Array<string>> { // Array<string> = массив ids game
    let gamesForSendToServer = games?.map(el => ClassGame.preparePropertyFromDropForSendToServer(el));
    gamesForSendToServer = UtilsService.getListGameForPossibleGameToCancelled(gamesForSendToServer); // !!! Миша сказал cancelGames только для тех игр у которых НЕТ репорта (это для кнопки которая рядом с фильтрами)
    gamesForSendToServer = gamesForSendToServer?.map(el => {
      return { id: el?.id, gameStatus: 'CANCELLED', gameAdjustmentStatus: el.gameAdjustmentStatus };
    });
    return this.post(`${this.urlGame}/cancel`, gamesForSendToServer);
  }

  assignOfficials(sendObj: IOfficialsAssign): Observable<null> {
    return this.post(`${this.urlGame}/assignOfficials`, sendObj);
  }

  updateOfficials(arrGO: Array<IForApiMethod_updateOfficials>): Observable<Array<IForApiMethod_updateOfficials>> { // only {id: string, status: TStatusAssignForSendToServer}
    arrGO = arrGO?.map(el => ClassGameOfficial.preparePropertyFromDropForSendToServer(el));
    const url = `${this.urlGame}/assign/updateOfficials`;
    return this.post(url, arrGO);
  }

  requestAssignOfficial(data: { gameOfficialId: string; }, hideNotif: boolean): Observable<null> {
    const url = `${this.urlGame}/assign/request`;
    if (hideNotif) {
      this.notificationService.setPreventSuccessNotificationByUrl(url)
    }

    return this.post(url, data).pipe(tap(() => {
      this.notificationService.deletePreventSuccessNotificationByUrl(url);
    }));
  }

  setOfficialStatus(data: { gameId: string, status: string; }): Observable<Pick<ClassGameOfficial, 'gameId' | 'id' | 'status'>> {
    return this.post(`${this.urlGame}/gameOfficial/editStatus`, data);
  }

  sendDeleteEmail(message: string): Observable<any> {
    const url = `${this.urlGame}/send-test-email`;
    const params = { message };
    return this.http.post(url, null, { params });
  }

  // !!! удаление судей с ролей
  removeOfficials(ids: Array<string>): Observable<Array<string>> { // !!! ids gameOfficials
    const url = `${this.urlGame}/assign/removeOfficials`;
    return this.post(url, ids);
  }

  // !!! только эти поля возвращаются officialFee officialPositionName officialPositionNumber
  getGameOfficialsForCreateGame(sendObj: ISendObjForGetGameOfficialsForCreateGame): Observable<Array<ClassGameOfficial>> {
    const url = `${this.urlGame}/gamePreview`;
    return this.post(url, sendObj).pipe(
      map((res?: Array<ClassGameOfficial>) => {
        return res?.map(el => new ClassGameOfficial(el)) || [];
      }),
    );
  }

  editFeeForGO(sendObj: IPopupEditFeeForGO): Observable<any> {
    const url = `${this.urlGO}/editFee`;
    return this.post(url, sendObj);
  }

  // !!! в ответ приходит оставшееся количество GameOfficials
  removeRoleForGO(sendObj: Pick<ClassGameOfficial, 'id'>): Observable<Array<IResponsePopupAddCrewMember>> {
    const url = `${this.urlGO}/removeRole`;
    return this.post(url, sendObj);
  }

  uploadCsv(sendObj: any, competitionId: string, options: any): Observable<any> {
    return this.post(`api/core/csv/v1/import/games?competitionId=${competitionId}`, sendObj, options);
  }

  addCrewMember(sendObj: IRequestPopupAddCrewMember): Observable<Array<IResponsePopupAddCrewMember>> {
    // !!! Тимур сказал не отправлять центы
    // if (sendObj.officialFee) sendObj.officialFee = (+sendObj.officialFee * 100).toString();
    const url = `/api/core/games/v1/gameOfficials/addRole`;
    return this.post(url, sendObj);
  }

  // === OFFICIALS =================================================
  readonly apiOfficials = '/api/core/officials/v1';

  //  /api/core/officials/v1/contact/all'
  getAllOfficials(sendObj: { certifiedOnly: boolean }): Observable<Array<IOfficialListItem>> {
    const endPoint = `${this.apiOfficials}/contact/all`;
    const endpointWithParams = `${endPoint}?${UtilsService.sendObjParamsToString(sendObj)}`;
    return this.get(endpointWithParams);
  }

  // inviteOfficials(sendObj: { emails: Array<string>, invitationText: string; }): Observable<any> {
  inviteOfficials(sendObj: Pick<ClassSettingsRequest, 'emails' | 'invitationText' | 'userRole'>): Observable<any> {
    return this.post(`${this.apiOfficials}/inviteOfficials`, sendObj);
  }
  private lastParamsStringWithoutPage = '';

  getListOfficials(options: IRequestOptions, type: TCurrentLink_officials, needUse_cachedData = false): Observable<IResponse<ClassUser>> {
    const currentUrl = this.router.url;
    const currentLinkLowerCase_officials = type.toLowerCase() as TCurrentLinkLowerCase_officials;
    if (currentUrl.includes('officials')) {
      // Specific handling for 'officials' URLs
      const params = options?.params as ClassSettingsRequest;
      if (params.page && params.page > 0) {
        params.page = params.page - 1; // Adjust to 0-based indexing
      } else {
        params.page = 0; // Default to 0
      }
    } else {
      let page: any = undefined;
      let otherParams: Record<string, any> = {};
      if (options.params && typeof options.params === 'object') {
        if ('page' in options.params) {
          page = (options.params as { [param: string]: any }).page;
        }
        otherParams = { ...options.params };
        delete otherParams.page;
      }

      const currentParamsStringWithoutPage = JSON.stringify(otherParams);

      // Reset the page to 0 if parameters (other than `page`) have changed
      if (currentParamsStringWithoutPage !== this.lastParamsStringWithoutPage) {
        if (options.params && typeof options.params === 'object') {
          (options.params as { [param: string]: any }).page = 0; // Reset `page` to 0
        }
        this.lastParamsStringWithoutPage = currentParamsStringWithoutPage; // Update tracked params
      }
    }


    const queryString = UtilsService.sendObjParamsToString(options.params);
    const endPoint = `${this.apiOfficials}/${currentLinkLowerCase_officials}?${queryString}&availability=${this.settingsOfficials.availability}`;

    // const endPoint = `${this.apiOfficials}/${currentLinkLowerCase_officials}?${queryString}&availability=false`;
    if (needUse_cachedData) {
      const cachedData = this.cachedData.get(endPoint);
      if (cachedData) return of(cachedData);
    }

    return this.get(endPoint).pipe(
      map((res: IResponse<ClassUser>) => {
        const result = { ...res, content: res.content?.map(el => new ClassUser(el)) };
        this.cachedData.set(endPoint, result);
        // for test
        // const idUser = '66cda0be-11bc-4e5e-8e78-2684f0cdb2d2';
        // result.content = res.content?.map((el) => {
        //   const updatedUser = new ClassUser(el);
        //   // if (updatedUser.id === idUser) {
        //   updatedUser.availabilityByGame = {
        //     ...el.availabilityByGame!,
        //     availabilitySameDate: [...el.availabilityByGame?.availabilitySameDate || [], this.forTestS.fakeAvailabilitySameDate],
        //   };
        //   // }
        //   return updatedUser;
        // });
        return result;
      }),
    );
  }

  getOfficialById(idOfficial: string, needUse_cachedData = false): Observable<ClassUser> {
    const endPoint = `${this.apiOfficials}/${idOfficial}`;
    if (needUse_cachedData) {
      const cachedData = this.cachedData.get(endPoint);
      console.log('getOfficialById cachedData :', cachedData);
      if (cachedData) return of(cachedData);
    }
    return this.get(endPoint).pipe(
      map((user: ClassUser) => {
        const result = new ClassUser(user);
        this.cachedData.set(endPoint, result);
        return result;
      }),
    );
  }

  deleteOfficial(idOfficial?: string): Observable<any> {
    return this.delete(`${this.apiOfficials}/${idOfficial}`);
  }

  // удаление списка приглашений  в таблице PENDING
  deleteOfficialsForPagePending(arrIds: Array<string>): Observable<any> {
    return this.post(`${this.apiOfficials}/deleteInvitations`, arrIds);
  }

  setOfficialAvailabilities(availabilities: ClassAvailability[]): Observable<any> {
    return this.put(`${this.apiOfficials}/availability`, availabilities);
  }

  setOfficialUnavailableDates(unavailableDates: IUnavailableDate[]): Observable<any> {
    return this.put(`${this.apiOfficials}/unavailableDates`, unavailableDates);
  }

  deleteOfficialUnavailableDates(unavailableDateId: string): Observable<any> {
    return this.delete(`${this.apiOfficials}/unavailableDates/${unavailableDateId}`);
  }

  // для попап окна group list .. officials/v1/lists
  methodGroupList(sendData: IPopupCreateGroupList | IPopupGroupList | string | null, httpMethod: THttpMethod): Observable<Array<ClassGroupListItem> | ClassGroupListItem> {
    const url = `${this.apiOfficials}/lists`;
    let result: Observable<Array<ClassGroupListItem> | ClassGroupListItem>;
    const isString = typeof sendData === 'string';
    let sendObj: ClassGroupListItem | IPopupGroupList | null = !isString ? sendData : null;
    if (!isString && httpMethod == 'post') {
      sendObj = {
        listName: (sendData as IPopupCreateGroupList)?.listName,
        officialIds: (sendData as IPopupCreateGroupList)?.listOfficials?.map(el => el.id!),
      };
    }
    if (!isString && httpMethod == 'put') {
      sendObj = {
        listIds: (sendData as IPopupGroupList)?.listIds!,
        officialIds: (sendData as IPopupGroupList)?.officials?.map(el => el.id!),
      };
      delete (sendData as IPopupGroupList)?.officials;
    }
    const id = isString ? sendData : null;

    if (httpMethod == 'get') {
      result = this.get(`${url}`);
    } else if (httpMethod == 'delete') {
      result = this.delete(`${url}/${id}`);
    } else if (httpMethod == 'put') { // put
      result = this[httpMethod](url, sendObj as IPopupGroupList);
    } else { // post
      result = this[httpMethod](url, sendObj as IPopupCreateGroupList);
    }

    return result.pipe(
      map((res: Array<ClassGroupListItem> | ClassGroupListItem) => {
        const responseArray: Array<ClassGroupListItem> | null = httpMethod == 'get' ? res as Array<ClassGroupListItem> : null;
        const responseObject: ClassGroupListItem | null = httpMethod !== 'get' ? res as ClassGroupListItem : null;
        if (responseArray) {
          return responseArray?.map((el: ClassGroupListItem) => new ClassGroupListItem(el));
        } else {
          return new ClassGroupListItem(responseObject!);
        }
      }),
    );
  }


  // === REPORTS ====================
  readonly urlReports = '/api/core/gameReport/v1';

  getReports(options: IRequestOptions): Observable<IResponse<ClassReport>> {
    // const fakeReport_NEEDS_APPROVAL: ClassReport = {...this.forTestS.fakeReport, status: 'NEEDS_APPROVAL'}
    // return of({ content: [fakeReport_NEEDS_APPROVAL, this.forTestS.fakeReport, this.forTestS.fakeReport] });
    options.params = UtilsService.removeEmptyKeysFromObject(options.params!);
    if (!(options.params instanceof ClassSettingsRequest)) {
      options.params = new ClassSettingsRequest(options.params as ClassSettingsRequest);
    }
    options.params = ClassSettingsRequest.preparePropertyFromDropForSendToServer(options.params);
    return this.get(this.urlReports, options).pipe(
      map((res: IResponse<ClassReport>) => {
        const arrayReports: Array<ClassReport> = res?.content?.map(el => new ClassReport(el)) || [];
        const result: IResponse<ClassReport> = { ...res, content: arrayReports };
        // for test
        // result.content = result.content?.map((el, idx) => {
        //   if (idx === 0) return {...el, status: 'PROCESSING'}
        //   return el
        // })
        return result;
      }),
    );
  }

  getAmountReportsStatuses(): Observable<Array<ClassReportStatusDrop>> {
    const observable: Observable<Array<ClassReportStatusDrop>> = this.meS.OFFICIAL ? this.get(this.urlReports + '/official/filtersCount') : this.get(this.urlReports + '/filtersCount');
    return observable.pipe( // было this.get(this.urlReports + '/filtersCount')
      map((res: Array<IResponse_getAmountReportsStatuses>) => {
        return res?.map(el => new ClassReportStatusDrop(el.status, el.count || 0, this.meS.OFFICIAL)) || [];
      }),
    );
  }

  // !!! POST нету потому что репорт создается автоматически на сервере при окончании игры
  methodReport(sendObj: ClassReport, httpMethod: Exclude<THttpMethod, 'post'>): Observable<ClassReport> {
    let result: Observable<ClassReport>;
    if (httpMethod == 'get' || httpMethod == 'delete') { // get report by id || delete by id
      result = this[httpMethod](`${this.urlReports}/${sendObj.id}`);
    } else { // put => Update report
      const isDraft = !!sendObj.isDraft;
      delete sendObj.isDraft;
      sendObj = ClassReport.preparePropertyFromDropForSendToServer(sendObj);
      // console.log('sendObj :', Object.entries(sendObj))
      // console.log('attendance :', sendObj.attendance)
      // result = of(sendObj)
      result = this.put(`${this.urlReports}?isDraft=${isDraft}`, sendObj);
    }

    return result.pipe(
      map(res => new ClassReport(res)),
    );
  }

  // !!! delay нужен для того чтобы после апрува, запрашивать заново список репортов или репорт по id
  approveReport(arrIdsReport: Array<string>, delayNumber: number): Observable<Array<string>> {
    return this.post(`${this.urlReports}/approve`, arrIdsReport).pipe(delay(delayNumber));
  }

  // ONLY OFFICIAL DUE DRAFT
  replaceOfficial(sendObj: { reportId: string, idOldUser: string, idNewUser: string; }): Observable<any> {
    return this.put(`/api/report/${sendObj.reportId}/replace/suggest?from=${sendObj.idOldUser}&to=${sendObj.idNewUser}`);
  }

  // FOR ADMIN // админ подтверждает замену судьи (статус должен быть 'COMPLETE' | 'REVIEW')
  confirmReplaceOfficial(reportId: string, idOldUser: string, idNewUser: string): Observable<ClassReport> {
    return this.put(`/api/report/${reportId}/replace?from=${idOldUser}&to=${idNewUser}`).pipe(
      map(res => new ClassReport(res)),
    );
  }

  // FOR ADMIN // админ ОТМЕНЯЕТ замену судьи (статус должен быть 'COMPLETE' | 'REVIEW')
  undoReplaceOfficial(reportId: string, idOldUser: string): Observable<ClassReport> {
    return this.put(`/api/report/${reportId}/replace/cancel?from=${idOldUser}`).pipe(
      map(res => new ClassReport(res)),
    );
  }

  inviteRefereeToGame(sendObj: InviteRefereeToGame): Observable<{ id?: string, name?: string; }> { // id: userId
    return this.post(`/api/admins/gameinvite`, sendObj);
  }


  // === PAYOUTS / PAYMENTS / BALANCE ==================
  // !!! пока не удалять
  // createPaymentMethodForGroup(sendObj: ISendObjCreatePaymentMethod): Observable<IResCreatePaymentMethod> {
  //   return this.post(`/api/payments/v1/payment/method/group/${this.meS.groupCurrent}`, sendObj)
  // }
  // getPaymentMethodsGroup(): Observable<Array<IResCreatePaymentMethod>> {
  //   return this.get(`/api/payments/v1/payment/methods/group/${this.meS.groupCurrent}`);
  // }

  // createPaymentMethodForCompetition(sendObj: ISendObjCreatePaymentMethod, idCompetition: string): Observable<IResCreatePaymentMethod> {
  createPaymentMethodForCompetition(sendObj: ISendObjCreatePaymentMethod, idCompetition: string): Observable<IPaymentMethod> {
    return this.post(`/api/payments/v1/payment/method/competition/${idCompetition}`, sendObj);
  }

  createPaymentMethodForCompetitionPrepare(
    idCompetition: string
  ): Observable<{ clientSecret: string, setupIntentId: string }> {
    return this.post(
      `/api/payments/v1/payment/method/competition/${idCompetition}/prepare`,
    );
  }


  retrievePaymentMethodForCompetition(
    competitionId: string,
    setupIntentId: string
  ): Observable<any> {
    return this.get(`/api/payments/v1/payment/method/competition/${competitionId}/retrieve/${setupIntentId}`, {
      params: {},
    });
  }



  getPaymentMethodsCompetition(idCompetition: string): Observable<Array<IPaymentMethod> | null> {
    if (!idCompetition || this.meS.SUB_ASSIGNOR) return of(null);
    return this.get(`/api/payments/v1/payment/methods/competition/${idCompetition}`).pipe(catchError((error: any) => of(null)));
  }

  createPaymentForCompetition(sendObj: ISendObjCreatePaymentForCompetition, idCompetition: string, paymentMethodId: string): Observable<IResCreatePaymentForCompetition> {
    const prepareFunc = (obj: ISendObjCreatePaymentForCompetition, type: 'toServer' | 'fromServer'): ISendObjCreatePaymentForCompetition => {
      if (obj.amount) obj.amount = type == 'toServer' ? obj.amount * 100 : obj.amount / 100;
      if (obj.fee?.processingFee) obj.fee.processingFee = type == 'toServer' ? obj.fee.processingFee * 100 : obj.fee.processingFee / 100;
      if (obj.fee?.transactionFee) obj.fee.transactionFee = type == 'toServer' ? obj.fee.transactionFee * 100 : obj.fee.transactionFee / 100;
      return obj;
    };
    sendObj = prepareFunc(sendObj, 'toServer');
    return this.post(`/api/payments/v1/payment/competition/${idCompetition}?paymentMethodId=${paymentMethodId}`, sendObj).pipe(
      tap((res) => {
        // if (res.message)
      }),
      // map((res) => {
      //   const obj: ISendObjCreatePaymentForCompetition = prepareFunc(res,'fromServer');
      //   return obj
      // })
    );
  }

  createPaymentFee(
    competitionId: string,
    sendObj: { baseAmount: { amount: number; currency: string }; paymentMethodType: 'CARD' | 'BANK' }
  ): Observable<any> {
    sendObj.baseAmount.currency = sendObj.baseAmount.currency || 'USD';

    const prepareFunc = (
      obj: { baseAmount: { amount: number; currency: string }, paymentMethodType: 'CARD' | 'BANK' },
      type: 'toServer' | 'fromServer'
    ) => {
      return obj;
    };

    sendObj = prepareFunc(sendObj, 'toServer');
    return this.post(`/api/payments/v1/payment/competition/${competitionId}/fees`, sendObj).pipe(
      tap((res) => {
      }),
    );
  }


  confirmPaymentForCompetition(paymentId: string): Observable<any> {
    return this.post(`/api/payments/v1/payment/${paymentId}/confirm`, {}).pipe(
      tap((res) => {
      })
    );
  }

  confirmPaymentMethodAdd(paymentId: string, competitionId: String): Observable<any> {
    return this.post(`/api/payments/v1/payment/competition/${competitionId}/method/${paymentId}/confirm`, {}).pipe(
      tap((res) => {
      })
    );
  }

  // !!! для того чтобы этот токен отправить в Plaid
  getLinkToken(): Observable<{ token: string; }> {
    return this.get(`/api/payments/v1/payment/plaid/token`);
  }

  getBalanceCompetition(idCompetition: string): Observable<IBalance | null> {
    if (!idCompetition) return of(null);
    return this.get(`/api/payments/v1/payment/balance/competition/${idCompetition}`).pipe(
      map((res) => this.prepareBalance(res, 'fromServer')),
    );
  }

  getBalanceWithOutCompetition(): Observable<IBalance | null> {
    return this.http.get<IBalance>(`/api/payments/v1/payment/balance/all`).pipe(
      map((res) => this.prepareBalance(res, 'fromServer')),
    );
  }

  prepareBalance(balance: IBalance, type: 'toServer' | 'fromServer'): IBalance {
    const result: IBalance = { ...balance };
    if (result.available) result.available = type == 'toServer' ? +result.available * 100 : +result.available / 100;
    if (result.payoutsDue) result.payoutsDue = type == 'toServer' ? +result.payoutsDue * 100 : +result.payoutsDue / 100;
    if (result.pending) result.pending = type == 'toServer' ? +result.pending * 100 : +result.pending / 100;
    if (result.current) result.current = type == 'toServer' ? +result.current * 100 : +result.current / 100;
    return result;
  }

  deletePaymentMethodsCompetition(idCompetition: string, methodId: string): Observable<null> {
    return this.delete(`/api/payments/v1/payment/method/competition/${idCompetition}?methodId=${methodId}`);
  }

  // getPaymentsCompetition(options: IRequestOptions, competitionId?: string): Observable<IResponse<IPayment> | null> {
  //   return this.get(`/api/payments/v1/payment/competition/${competitionId}/all`, options)
  //     .pipe(
  //       map((res: IResponse<IPayment> | null) => {
  //         const content: Array<IPayment> = res?.content?.length ? res.content.map((el: IPayment) => {
  //           return { ...el, totalAmount: el.totalAmount ? el.totalAmount / 100 : 0, fee: el.fee ? el.fee / 100 : 0 };
  //         }) : [];
  //         return { ...res, content };
  //       }),
  //       catchError((error: any) => of(null)),
  //     );
  // }

  // !!! если нужно  + и - платежи и Трансферы в одной куче
  // getPaymentsCompetition(settingsRequest: ClassSettingsRequest, competitionId: string): Observable<IResponse<IBalanceOperation> | null> {
  //   return this.get(`/api/payments/v1/operation/competition/${competitionId}/all`, { params: settingsRequest })
  //     .pipe(
  //       map((res: IResponse<IBalanceOperation> | null) => {
  //         const content: Array<IBalanceOperation> = res?.content?.length ? res.content.map((el: IBalanceOperation) => {
  //           return { ...el, total_formatted: el.total ? el.total / 100 : 0, fee_formatted: el.fee ? el.fee / 100 : 0 };
  //         }) : [];
  //         return { ...res, content };
  //       }),
  //       catchError((error: any) => of(null)),
  //     );
  // }

  getPaymentsCompetition(settingsRequest: ClassSettingsRequest, competitionId: string): Observable<IResponse<IBalanceOperation> | null> {
    settingsRequest = ClassSettingsRequest.preparePropertyFromDropForSendToServer(settingsRequest);
    return this.get(`/api/payments/v1/operation/competition/${competitionId}/all`, { params: settingsRequest })
      .pipe(
        map((res: any | null) => {
          const balanceOperations = res?.balanceOperations;
          const content: Array<IBalanceOperation> = balanceOperations?.content?.length ? balanceOperations.content.map((el: IBalanceOperation) => {
            return { ...el, total_formatted: el.total ? el.total / 100 : 0, fee_formatted: el.fee ? el.fee / 100 : 0 };
          }) : [];

          const response: IResponse<IBalanceOperation> = {
            content,
            number: balanceOperations?.pageable?.pageNumber,
            size: balanceOperations?.pageable?.pageSize,
            totalElements: balanceOperations?.totalElements,
            totalPages: balanceOperations?.totalPages,
            empty: balanceOperations?.empty,
            first: balanceOperations?.first,
            last: balanceOperations?.last,
            pageable: balanceOperations?.pageable,
            sort: balanceOperations?.sort,
            numberOfElements: balanceOperations?.numberOfElements,
            statusCounts: res?.statusCounts
          };

          return response;
        }),
        catchError((error: any) => of(null)),
      );
  }

  // !!! если нужно  + и - платежи и Трансферы в одной куче
  // getPayments(options?: ClassSettingsRequest): Observable<IResponse<IBalanceOperation> | null> {
  //   return this.get(`/api/payments/v1/operation/all`, { params: options })
  //     .pipe(
  //       map((res: IResponse<IBalanceOperation> | null) => {
  //         const content: Array<IBalanceOperation> = res?.content?.length ? res.content.map((el: IBalanceOperation) => {
  //           return { ...el, total_formatted: el.total ? el.total / 100 : 0, fee_formatted: el.fee ? el.fee / 100 : 0 };
  //         }) : [];
  //         return { ...res, content };
  //       }),
  //       catchError((error: any) => of(null)),
  //     );
  // }

  getPayments(options?: ClassSettingsRequest, ignoreDefaultPage: boolean = false): Observable<IResponse<IBalanceOperation> | null> {
    options = ClassSettingsRequest.preparePropertyFromDropForSendToServer(options, ignoreDefaultPage);
    return this.get(`/api/payments/v1/operation/all`, { params: options })
      .pipe(
        map((res: any | null) => {
          const balanceOperations = res?.balanceOperations;
          const content: Array<IBalanceOperation> = balanceOperations?.content?.length ? balanceOperations.content.map((el: IBalanceOperation) => {
            return { ...el, total_formatted: el.total ? el.total / 100 : 0, fee_formatted: el.fee ? el.fee / 100 : 0 };
          }) : [];

          const response: IResponse<IBalanceOperation> = {
            content,
            number: balanceOperations?.pageable?.pageNumber,
            size: balanceOperations?.pageable?.pageSize,
            totalElements: balanceOperations?.totalElements,
            totalPages: balanceOperations?.totalPages,
            empty: balanceOperations?.empty,
            first: balanceOperations?.first,
            last: balanceOperations?.last,
            pageable: balanceOperations?.pageable,
            sort: balanceOperations?.sort,
            numberOfElements: balanceOperations?.numberOfElements,
            statusCounts: res?.statusCounts
          };

          return response;
        }),
        catchError((error: any) => of(null)),
      );
  }

  getPayerById(idPayer: string): Observable<IPayer> {
    return of(this.forTestS.fakeUser);
    // return this.get(`/api/payments/v1/payment/balance/competition/${idPayer}`).pipe(
    //   map((res) => this.prepareBalance(res, 'fromServer')),
    // );
  }


  // !!! для страницы payments/officialsFees => Official Fees = res.officialFees && Assignor Fees = res.assignorFees && Projected Outgoings = res.officialFees+res.assignorFees
  // !!! для страницы payments/overview => Projected Outgoings = res.officialFees+res.assignorFees
  // getProjectedOutgoings(options: IOptionsGetProjectedOutgoings): Observable<IResponseGetProjectedOutgoings | null> {
  getProjectedOutgoings(sendObj: ClassSettingsRequest): Observable<IResponseGetProjectedOutgoings | null> {
    // console.log('getProjectedOutgoings 111:', Object.entries(sendObj));
    // console.log('getProjectedOutgoings 222:', Object.entries(UtilsService.removeEmptyKeysFromObject(sendObj)));
    return this.get(`/api/core/gamePayment/v1/projectedOutgoings`, { params: UtilsService.removeEmptyKeysFromObject(sendObj) })
      .pipe(
        map((res) => {
          if (!res) return null;
          return {
            officialFees: res.officialFees ? res.officialFees / 100 : 0,
            assignorFees: res.assignorFees ? res.assignorFees / 100 : 0,
            totalFees: res.totalFees ? res.totalFees / 100 : 0,
            groupAssignorFees: res.groupAssignorFees ? res.groupAssignorFees / 100 : 0,
          };
        }),
        catchError((error: any) => of(null)),
      );
  }

  // !!! FROM 2 VERSION
  // for official => создает платежный метод (для получения выплат)
  // если из страйповской формы вводил cardNumber и т.д., то ко всем существующим полям (имя, телефон и т.д.) добавляется ещё поле token: string
  createStripePayMethodForOfficial(sendObj: any): Observable<any> {
    return this.post(`/api/payments/connectAccount`, this.checkPhone(sendObj));
  }

  // === ME USER ==========================
  // короче давай всегда будет input=1555 или input=andrew77333@gmail.com
  // phone формата +12223334445
  // новый email отправлять также на роут GET users/validate. Затем на новую почту приходит код. И отправляешь на PUT users/email?code=
  // POST /api/users/validate + body: {"email"} или body: {"+155"} , короче просто строка в боди
  validatePhoneEmail(sendObj: { phone?: string, email?: string; }): Observable<null> {
    let type = '';
    if (sendObj.phone) type = sendObj.phone;
    if (sendObj.email) type = sendObj.email;
    return this.post(`api/users/validate`, type);
  }

  public resendOfficials(officialId: string, gameId: string): Observable<{ message: string, responseBody: string }> {
    return this.http.post<{
      message: string,
      responseBody: string
    }>(`api/core/games/v1/assignOfficials/resend?officialId=${officialId}&gameId=${gameId}`,
      { officialId: officialId, gameId: gameId });
  }

  addUserOrganizationId(value: string): Observable<any> {
    const organization = 'US_SOCCER_LEARNING_CENTRE';
    const apiUrl = `/api/certifications/v1/users/add-organization-id`;
    const body = { organization, value };
    return this.http.post(apiUrl, body);
  }

  getUserLicenses(): Observable<any> {
    const apiUrl = `/api/certifications/v1/users/user-licenses?organization=US_SOCCER_LEARNING_CENTRE`;
    return this.http.get(apiUrl);
  }

  deleteUserLicenses(): Observable<any> {
    const apiUrl = `/api/certifications/v1/users`;
    return this.http.delete(apiUrl);
  }

  deletOfficialeUserLicenses(userId: string): Observable<any> {
    const apiUrl = `/api/certifications/v1/users/${userId}`;
    return this.http.delete(apiUrl);
  }

  getUsers(): Observable<any> {
    const apiUrl = `/api/certifications/v1/users`;
    return this.http.get(apiUrl);
  }

  getOfficialUserLicenses(userId: string): Observable<any> {
    const apiUrl = `/api/certifications/v1/users/user-licenses/${userId}?organization=US_SOCCER_LEARNING_CENTRE`;
    return this.http.get(apiUrl);
  }

  addOfficialUserOrganizationId(value: string, userId: string): Observable<any> {
    const organization = 'US_SOCCER_LEARNING_CENTRE';
    const apiUrl = `/api/certifications/v1/users/add-official-organization-id`;
    const body = { organization, value, userId };
    return this.http.post(apiUrl, body);
  }


  addOfficialSignupOrganizationId(value: string, email: string): Observable<any> {
    const organization = 'US_SOCCER_LEARNING_CENTRE';
    const apiUrl = `/api/certifications/v1/users/add-organization-id-by-email`;
    const body = { organization, value, email };
    return this.http.post(apiUrl, body);
  }

  exportGamesToExcel(options: { params?: ClassSettingsRequest }, fileName: string): Observable<void> {
    let httpParams = new HttpParams();
    if (options.params) {
      const params = options.params as Record<string, any>;
      Object.keys(params).forEach(key => {
        const value = params[key];
        if (key !== 'page' && key !== 'size' && value !== null && value !== undefined) {
          httpParams = httpParams.append(key, value.toString());
        }
      });
    }

    return this.http.get(`${this.urlGame}/export-excel`, {
      params: httpParams,
      responseType: 'blob'
    })
      .pipe(
        map(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }),
        catchError(error => {
          console.error('There was a problem with the fetch operation:', error);
          throw error;
        })
      );
  }

  exportAdminGamesToExcel(options: { params?: ClassSettingsRequest }, fileName: string): Observable<void> {
    let httpParams = new HttpParams();
    if (options.params) {
      const params = options.params as Record<string, any>;

      Object.keys(params).forEach(key => {
        const value = params[key];

        if (key !== 'page' && key !== 'size' && value !== null && value !== undefined) {
          httpParams = httpParams.append(key, value.toString());
        }
      });
    }

    return this.http.get(`${this.urlGame}/export-excel-admin`, {
      params: httpParams,
      responseType: 'blob',
      headers: new HttpHeaders({
        'user-role': 'ADMIN'
      })
    })
      .pipe(
        map(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }),
        catchError(error => {
          console.error('There was a problem with the fetch operation:', error);
          throw error;
        })
      );
  }


  exportToExcel(url: string, options: { params?: ClassSettingsRequest }, fileName: string): Observable<void> {
    let httpParams = new HttpParams();
    if (options.params) {
      const params = options.params as Record<string, any>;

      Object.keys(params).forEach(key => {
        const value = params[key];

        if (key !== 'page' && key !== 'size' && value !== null && value !== undefined) {
          httpParams = httpParams.append(key, value.toString());
        }
      });
    }
    return this.http.get(url, {
      params: httpParams,
      responseType: 'blob'
    })
      .pipe(
        map(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }),
        catchError(error => {
          console.error('There was a problem with the fetch operation:', error);
          throw error;
        })
      );
  }

  autoTopupCreate(competitionId: string, payload: any): Observable<any> {
    return this.http.post(`/api/payments/v1/autotopup`, payload);
  }

  autoTopupUpdate(competitionId: string, payload: any): Observable<any> {
    return this.http.put(`/api/payments/v1/autotopup`, payload);
  }

  getAutoTopupDetails(competitionId: string): Observable<any> {
    return this.http.get(`/api/payments/v1/autotopup/${competitionId}`);
  }

  getAutoTopupHistory(competitionId: string): Observable<any> {
    return this.http.get(`/api/payments/v1/autotopup/${competitionId}/history`);
  }

  getAutoTopupEnable(competitionId: string): Observable<any> {
    return this.http.get(`/api/payments/v1/autotopup/${competitionId}/enabled`);
  }

  csvImportCheckStatus(importId: string, rowCount: number): Observable<any> {
    return this.http.get(`/api/core/csv/v1/imports/${importId}/checkStatus?expectedNumberOfGames=${rowCount}`);
  }

  updateScheduledPayout(payload: any): Observable<any> {
    return this.http.put(`/api/payments/v1/scheduled_payout`, payload);
  }

  getScheduledPayout(): Observable<any> {
    return this.http.get(`/api/payments/v1/scheduled_payout`);
  }
}
