import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({selector: '[phone]',standalone: true})
export class PhoneDirective {
  // regex = /[^\d]/g // only numbers
  regex = /[^\+|^\d]/g // only plus & numbers

  constructor(
    private el: ElementRef,
    private r: Renderer2,
  ) {
  }

  @HostListener('input', ['$event']) inputHandler(e: any) {
    const result = this.el.nativeElement.value?.replace(this.regex,'')
    this.el.nativeElement.value = result.replace('+','')
  }

}
