<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              user: (profileUserS.user$|async),
              fullAddress: (profileUserS.user$|async)?.address|getLocationString,
              width1_forContactItem: 239,
             } as obj'
     class='wrapperProfileUser'>

  <div *ngIf='obj.desktop' class='headerContact'>
    <headTitle text='Contact Info' typeText='text1-blueDark' svgLeft='arrow_squareLeft&24'
               (svgLeftEmit)='otherS.locationBack()'></headTitle>
    <ng-container [ngTemplateOutlet]='actionsTemplate'></ng-container>
  </div>

  <titleAndNavigationForProfile *ngIf='obj.mobile' [typePageProfile]='profileUserS.typePageProfile'
                                [idUser]='profileUserS.id'></titleAndNavigationForProfile>

  <div class='contentContact' [class.contentContact--desktop]='obj.desktop'>
    <photo [data]='obj.user' [w]='obj.desktop ? "160" : "96"' [fs]='obj.desktop ? "48" : "32"' typeText='text1-blueDark' class='contentContact__photo'></photo>
    <div class='contentContact__name'>{{obj.user?.firstName}} {{obj.user?.secondName}}</div>

    <div *ngIf='userRole !== "ADMIN" && obj.mobile' class='headerContact__btns'>
      <btn text='Edit' bcg='blueDark' [btnWidth]='"100%"' [btnHeight]='36'
           (click)='profileS.goToEdit(profileUserS.typePageProfile)' [disabled]='true'></btn>
    </div>

    <div [class.contentContact--mobile]='obj.mobile'>
      <contactItem text1='Phone Number' [text2]='obj.user?.phone|formatPhone' [width1]='obj.width1_forContactItem'></contactItem>
      <contactItem text1='Email' [text2]='obj.user?.email' [width1]='obj.width1_forContactItem'></contactItem>
      <contactItem text1='Residential Address' [text2]='obj.fullAddress' [width1]='obj.width1_forContactItem'></contactItem>
      <!--    === !!! userRole !== "ADMIN" => если GROUP_ASSIGNOR смотрит ADMIN, то в этом случае надо скрыть некоторую инфу ===-->
      <ng-container *ngIf='userRole !== "ADMIN"'>
        <contactItem text1='Date of Birth' [text2]='obj.user?.dateOfBirth_formatted' [width1]='obj.width1_forContactItem'></contactItem>
        <contactItem text1='Age' [text2]='(obj.user?.dateOfBirth!|getAgeByDateOfBirth)?.toString()'
                     [width1]='obj.width1_forContactItem'></contactItem>
        <!-- <contactItem text1='What year did you start officiating?' [text2]='obj.user?.startOfficiating'
                     [width1]='obj.width1_forContactItem'></contactItem> -->
                     
        <!-- <contactItem text1='Cert ID' [text2]='(obj.user?.organizationUserData?.organizationUserId| formatOrganizationUserId)' [width1]='obj.width1_forContactItem'></contactItem> -->
        <ng-container>
          <div class="certId-container" [style.border-bottom]="obj.user?.organizationUserData?.organizationUserId !== null ? 'none' : ' 1px solid #D0D5DD'">
            <contactItem text1='Cert ID' [text2]='(obj.user?.organizationUserData?.organizationUserId| formatOrganizationUserId)' [width1]='obj.width1_forContactItem'></contactItem>
            <div   *ngIf="obj.user?.organizationUserData?.organizationUserId !== null" class="status-container">
              <div *ngIf="obj.user?.certified" class="eligible-button">Eligible</div>
              <div *ngIf="!obj.user?.certified" class="not-eligible-button">Not Eligible</div>
            </div>
          </div>
        </ng-container>
        <contactItem text1='Governing Body' [text2]="obj.user?.organizationUserData?.organization == 'US_SOCCER_LEARNING_CENTRE' ? 'United States Soccer Federation (USSF)' : (obj.user?.organizationUserData?.organization || '-')" 
                     [width1]='obj.width1_forContactItem'></contactItem>
        <contactItem text1='Referee Grade' [text2]='(obj.user?.mainLicense?.license?.name)' 
                     [width1]='obj.width1_forContactItem'></contactItem>
        <contactItem text1='Social Security Number (SSN)' [text2]='obj.user?.socialSecurity'
                     [width1]='obj.width1_forContactItem'></contactItem>
        <contactItem text1='Emergency Contact' [text2]='obj.user?.emergencyName' [width1]='obj.width1_forContactItem'></contactItem>
        <contactItem text1='Phone No.' [text2]='obj.user?.emergencyPhoneNumber|formatPhone'
                     [width1]='obj.width1_forContactItem'></contactItem>
        <contactItem text1='Relationship' [text2]='obj.user?.emergencyRelationship' [width1]='obj.width1_forContactItem'
                     class='o-bordNone'></contactItem>
      </ng-container>
    </div>
    
  </div>

  <ng-container *ngIf='obj.mobile' [ngTemplateOutlet]='actionsTemplate'></ng-container>

  <!--  === ACTIONS BUTTONS ===========================================-->
  <ng-template #actionsTemplate>
    <!--    === !!! userRole !== "ADMIN" => если GROUP_ASSIGNOR смотрит ADMIN, то в этом случае надо скрыть некоторую инфу ===-->
    <div *ngIf='userRole !== "ADMIN" && !obj.mobile' class='headerContact__btns'>
      <btn text='Edit' bcg='blueDark' [btnWidth]='58' 
           (click)='profileS.goToEdit(profileUserS.typePageProfile)' [disabled]='true'></btn>
    </div>
  </ng-template>
</div>