import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OtherService } from '@services/other.service';

declare var gapi: any;

export interface IResFromGoogleForSignup {
  fullName?: string;
  givenName?: string;
  familyName?: string;
  imageURL?: string;
  email?: string;
  id_token?: string;
}

@Injectable({ providedIn: 'root' })
export class GooglePeopleService {
  apiKey = 'AIzaSyDn8BKUdA8K5Pgs9KdvCJf-0djrVXlTLWQ';
  clientId = '545905996894-tmq61fqbr0g3a3404ccinfarjqr1bg2j.apps.googleusercontent.com';
  // clientSecret = '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';

  discoveryDocs = ['https://people.googleapis.com/$discovery/rest?version=v1'];
  // scopes = 'profile email openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/user.organization.read https://www.googleapis.com/auth/user.gender.read https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/directory.readonly https://www.googleapis.com/auth/user.emails.read https://www.googleapis.com/auth/contacts https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.addresses.read https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/contacts https://www.googleapis.com/auth/contacts.readonly';
  scopes = 'https://www.googleapis.com/auth/contacts.readonly';

  access_token = '';
  subIsConnectGoogle = new BehaviorSubject<boolean>(false);

  emailsFromGoogle: Array<string> = [];
  subEmailsFromGoogle = new BehaviorSubject<Array<string>>(this.emailsFromGoogle);

  options = {
    apiKey: this.apiKey,
    discoveryDocs: this.discoveryDocs,
    clientId: this.clientId,
    scope: this.scopes,
    // access_type: 'offline', // не запрашивать каждый раз доступ
    // prompt: 'select_account', //  consent select_account
    approval_prompt: 'force', // при каждом входе запрашивать scopes
  };

  // === GOOGLE CALENDAR ==================
  discoveryDocsCalendar = ['https://people.googleapis.com/$discovery/rest?version=v1'];
  scopesCalendar = 'https://www.googleapis.com/auth/calendar';

  access_token_calendar = '';
  subIsConnectGoogleCalendar = new BehaviorSubject<boolean>(false);

  listCalendar: Array<any> = [];
  subIsCompleted = new BehaviorSubject<string>('');

  optionsCalendar = {
    apiKey: this.apiKey,
    discoveryDocs: this.discoveryDocsCalendar,
    clientId: this.clientId,
    scope: this.scopesCalendar,
    // access_type: 'offline', // не запрашивать каждый раз доступ
    // prompt: 'select_account', //  consent select_account
    approval_prompt: 'force', // при каждом входе запрашивать scopes
  };

  sendObj: any;
  email?: string;

  constructor(
    private otherS: OtherService,
  ) {
  }

  private auth2: any;
  // private auth2_typeScript: GoogleAuth;
  //   после 31 марта 2023 // https://developers.google.com/identity/sign-in/web/sign-in

  // https://stackoverflow.com/questions/38091215/import-gapi-auth2-in-angular-2-typescript
  // "compilerOptions": {
  //   "types": ["jest", "lodash", "gapi", "gapi.auth2"]
  // }

  initForSignup(): void {
    const options = {
      apiKey: this.apiKey,
      discoveryDocs: this.discoveryDocs,
      clientId: this.clientId,
      approval_prompt: 'force', // при каждом входе запрашивать scopes
      // https://developers.google.com/identity/protocols/oauth2/scopes // list scopes
      // https://www.googleapis.com/auth/admin.directory.customer.readonly //	Просмотр информации о клиенте
      // https://www.googleapis.com/auth/admin.directory.customer	// Просмотр и управление информацией о клиентах
      // https://www.googleapis.com/auth/cloud-platform.read-only	// Просматривайте свои данные в сервисах Google Cloud и смотрите адрес электронной почты своей учетной записи Google.
      scope: 'profile email', // this.scopes,
      longTitle: true,
    };
    gapi.load('client:auth2', () => {
      gapi.client.init(options)
        .then((res: any) => {
          this.auth2 = gapi.auth2.getAuthInstance();
        })
        .catch((err: any) => {
          console.log('gapi.client.init  err:', err);
        });
    });
  }

  signup(): Promise<any> {
    if (!this.auth2) console.error('auth2 :', this.auth2); // no delete
    return this.auth2.signIn();
  }

  logout(): Promise<any> {
    return this.auth2.signOut();
  }

  // ==================================

  handleClientLoad(): void {
    gapi.load('client:auth2', () => {
      gapi.client.init(this.options)
        .then(() => this.handleAuthClick())
        .catch(() => {
        });
    });
  }

  handleAuthClick(): void {
    gapi.auth2.getAuthInstance().signIn()
      .then((res: any) => {
        this.access_token = res.xc.access_token;
        if (this.access_token) this.subIsConnectGoogle.next(true);
        this.getContacts();
      })
      .catch(() => {
      });
  }

  disconnect() {
    gapi.load('client:auth2', () => {
      gapi.client.init(this.options)
        .then(() => {
          gapi.auth2.getAuthInstance().disconnect()
            .then((res: any) => {
              this.subIsConnectGoogle.next(false);
            })
            .catch(() => {
            });
          // gapi.auth2.getAuthInstance().signOut()
          //   .then((res: any) => )
          //   .catch((err: any) => )
        })
        .catch(() => {
        });
    });
  }

  getContacts(): void {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `https://people.googleapis.com/v1/people/me/connections?personFields=emailAddresses&key=${this.apiKey}`);
    xhr.setRequestHeader('Content-Type', 'text/plain;charset=UTF-8');
    xhr.setRequestHeader('authorization', 'Bearer ' + this.access_token);
    xhr.send();

    xhr.onload = (res: any) => {
      this.emailsFromGoogle = JSON.parse(res.target.response).connections;
      this.emailsFromGoogle = this.emailsFromGoogle.filter((el: any) => {
        if (el?.emailAddresses?.length) {
          if (el.emailAddresses[0]?.metadata?.source?.type === 'CONTACT') {
            return el.emailAddresses[0]?.value;
          }
        }
      });
      this.emailsFromGoogle = this.emailsFromGoogle.map((el: any) => el.emailAddresses[0].value);
      this.subEmailsFromGoogle.next(this.emailsFromGoogle);
    };
  }

  // === GOOGLE CALENDAR ==================
  handleClientLoadCalendar(sendObj: any, email: string): void {
    // this.game = game;
    this.sendObj = sendObj;
    this.email = email;
    gapi.load('client:auth2', () => {
      gapi.client.init(this.optionsCalendar)
        .then(() => this.handleAuthClickCalendar())
        .catch(() => {
        });
    });
  }

  handleAuthClickCalendar(): void {
    gapi.auth2.getAuthInstance().signIn()
      .then((res: any) => {
        this.access_token_calendar = res.xc.access_token;
        if (this.access_token_calendar) this.subIsConnectGoogleCalendar.next(true);
        this.getListCalendar();
      })
      .catch(() => {
      });
  }

  disconnectCalendar() {
    gapi.load('client:auth2', () => {
      gapi.client.init(this.optionsCalendar)
        .then((res: any) => {
          gapi.auth2.getAuthInstance().disconnect()
            .then((res: any) => {
              this.subIsConnectGoogleCalendar.next(false);
            })
            .catch(() => {
            });
        })
        .catch(() => {
        });
    });
  }

  getListCalendar(): void {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `https://content.googleapis.com/calendar/v3/users/me/calendarList?payload&key=${this.apiKey}`);
    xhr.setRequestHeader('Content-Type', 'text/plain;charset=UTF-8');
    xhr.setRequestHeader('authorization', 'Bearer ' + this.access_token_calendar);
    xhr.send();

    xhr.onload = (res: any) => {
      this.listCalendar = JSON.parse(res.target.response).items; // summary
      this.listCalendar = JSON.parse(res.target.response)?.items?.filter((el: any) => el.primary)?.map((el: any) => ({
        id: el.id,
        summary: el.summary,
      })); // summary
      this.addToGoogleCalendar();
    };
  }

  addToGoogleCalendar(): void {
    if (!this.listCalendar[0]?.id) return;
    const xhr = new XMLHttpRequest();
    xhr.open('POST', `https://content.googleapis.com/calendar/v3/calendars/${this.listCalendar[0].id}/events?alt=json&key=${this.apiKey}`);
    xhr.setRequestHeader('Content-Type', 'text/plain;charset=UTF-8');
    xhr.setRequestHeader('authorization', 'Bearer ' + this.access_token_calendar);

    xhr.send(JSON.stringify(this.sendObj));

    xhr.onload = (res: any) => {
      if (res?.target?.status) this.subIsCompleted.next(res.target.status);
    };
  }


}

