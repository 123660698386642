import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TClassForText } from '@models/ICssStyles';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormControl } from '@angular/forms';
import { TSvgName } from '@components/__svg_img/svg/forSvg';

@Component({
  selector: 'field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  standalone: true,
  imports: [CommonModule, SvgComponent, MatTooltipModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldComponent implements OnChanges {
  @Input() page?: 'createGame' | 'registration'; // дизайн разный на этих страницах. Сейчас другой дизайн только для 'contact'
  @Input() text?: string;
  haveText = false; // !!! иногда если пустую строку передал, то нужно проверить
  @Input() typeText: TClassForText = 'text7-blueDark';
  @Input() margin?: string | number; // margin-bottom от текста скинуть // по-умолчанию везде margin-bottom: 15px;

  // можно передавать звёздочку так text=="anyText*" или так [ctrl]='ctrl.email' email: ['', [Validators.required]],
  @Input() ctrl?: FormControl; // если required, то показывать *
  star?: boolean; // символ *

  @Input() svgName?: TSvgName;
  @Input() matTooltipSVG?: string;

  @Output() svgEmit = new EventEmitter();
  @Output() emitData: EventEmitter<{}> = new EventEmitter<{}>();
  @Input() w?: string | number = ''; // если передано, то устанавливаем размер SVG

  @Input() forTest?: string;

  constructor(
    public cd: ChangeDetectorRef,
  ) {
  }

  emitFile(file: any) {
    if (file.hasOwnProperty('file')) this.emitData.emit({ file: file.file });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.star = this.text?.includes('*');

    if (this.star) this.text = this.text?.replace('*', '');

    if (changes.text?.currentValue) {
      if (typeof this.text === 'string') {
        this.haveText = true;
      }
      // if (this.forTest) {
      //   console.log('forTest :', this.forTest, this.haveText, typeof this.text, this.text)
      // }
    }

    // if (changes?.svgName?.currentValue) {
    //   console.log('ngOnChanges svgName :', this.svgName)
    // }
  }
}
