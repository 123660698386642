import { Pipe, PipeTransform } from '@angular/core';
import {
  TSelectedTable_dashboard_forOfficial,
} from '@app/dir_group_assignor/dashboard/components/games-table/unaccepted-games/unaccepted-games.component';
import { getTableEmpty_forDashboard_forRoleOfficial, ITableEmpty } from '@components/_table/meTable';
import { DeviceService } from '@services/device.service';

// !!! нет у меня времени разбираться в гавнокоде в дашборде после Гранта
@Pipe({ name: 'getTableEmptyForDashboard', standalone: true })
export class GetTableEmptyForDashboardPipe implements PipeTransform {

  constructor(
    // private meS: MeService,
    private deviceS: DeviceService,
  ) {
  }

// !!! selectedTable_dashboard_forOfficial => мне пришлось сделать эту херню чтобы не ломать текущий функционал. Т.к. рефакторить код после Гранта очень долго
  transform(
    search: string | undefined, selectedTable_dashboard_forOfficial: TSelectedTable_dashboard_forOfficial, forTest?: string
  ): ITableEmpty {
    // if (this.meS.OFFICIAL) {
    //   return getTableEmpty_forDashboard_forRoleOfficial(search, selectedTable_dashboard_forOfficial);
    // } else {
    // }
    // console.log('PIPE getTableEmptyForDashboard :', ' search:', search, ' selectedTable_dashboard_forOfficial:', selectedTable_dashboard_forOfficial, ' forTest:', forTest)
    return getTableEmpty_forDashboard_forRoleOfficial(search, selectedTable_dashboard_forOfficial, this.deviceS.isMobile, forTest);
  }
}
